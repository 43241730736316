export const alertsType = {
    GET_REQUEST: 'ALERTS_GET_REQUEST',
    GET_ALERTS_SUCCESS: 'ALERTS_GET_SUCCESS',
    GET_ALERTS_FAILURE: 'ALERTS_GET_FAILURE',

    RESOLVE_ALERT: "RESOLVE_ALERT",
    RESOLVE_ALERT_SUCCESS: "RESOLVE_ALERT_SUCCESS",
    RESOLVE_ALERT_FAILURE: "RESOLVE_ALERT_FAILURE",

    ACK_ALERT: "ACK_ALERT",
    ACK_ALERT_SUCCESS: "ACK_ALERT_SUCCESS",
    ACK_ALERT_FAILURE: "ACK_ALERT_FAILURE",

    GET_ALERT_TYPES: "GET_ALERT_TYPES",
    GET_ALERT_TYPES_SUCCESS: "GET_ALERT_TYPES_SUCCESS",
    GET_ALERT_TYPES_FAILURE: "GET_ALERT_TYPES_FAILURE"
}