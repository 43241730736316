import { makeStyles } from '@mui/styles';



export const useStyles = makeStyles({
    DroppableColumn: {
        height: "100%"
    },
    infoTag: {
        background: '#0F8D480D',
        padding: '8px 10px',
        margin: '0 20px',
        marginBottom: '20px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '4px',
        img: {
            borderRadius: '50%',
            background: '#fff',
            padding: '4px',
            marginRight: '10px',
        }
    }
});
