/***
 *
 * Controller class for user.
 * @file AutomationRuleDetails.js
 * @description AutomationRuleDetails component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './AutomationRuleDetails.style.js';
import './AutomationRuleDetails.scss';
import { Box } from '@mui/material';
import PageHeader from '../PageHeader/index.js';
import CustomTypography from '../CustomTypography/index.js';
import ImageWrapper from '../ImageWrapper/index.js';
import StyledTabs from '../StyledTabs/index.js';
import GroupsIcon from "../../assets/icons/group.svg";
import Sensor from "../../assets/icons/sensor.svg";
import { automationActions, deviceActions, groupActions, sceneActions } from '../../redux/actions';
import { useDispatch, useSelector } from "react-redux";
import StyledPopup from '../StyledPopup/index.js';
import { findArrayByType, formatedDate, getMessageStr, history, isAuthorised } from '../../helpers';
import SwitchButton from '../SwitchButton/index.js';
import _ from 'lodash';
import RulesIcon from '../../assets/icons/rules.svg';
import Delete from '../../assets/icons/delete.svg';
import Edit from '../../assets/icons/edit-2.svg';
import { useQuery } from '../../hooks/useQuery.js';
import DataNotFound from '../../pages/DataNotFound/index.js';
import { features, permissions } from '../../constants/permission.constants.js';
import StyledButton from '../StyledButton/index.js';
import StyledChip from '../StyledChip/index.js';
import RuleActions from '../RuleActions/index.js';
import ItemView from '../ItemView/index.js';
import CustomAccordion from '../CustomAccordion/index.js';

const AutomationRuleDetails = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const query = useQuery();

  const ruleId = query.get("ruleId") || null;

  const automationRuleApiData = useSelector((state) => state?.automation);
  const _detailedDeviceList = useSelector((state) => state?.devices?.detailedDeviceList);
  const _multipleGroupsList = useSelector((state) => state?.groups?.multipleGroupsList);
  const _multipleScenesList = useSelector((state) => state?.scenes?.multipleScenesList);
  const _automationResponse = automationRuleApiData?.automationRuleById || {};
  const automationData = _automationResponse || {};

  const [tabs] = useState([{ label: "Triggers", icon: Sensor }, { label: "Actions", icon: GroupsIcon }]);
  const [tabIndex, setTabIndex] = useState(0);
  const [ruleStatusToggle, setRuleStatusToggle] = useState(false);
  const [deleteRuleConfirmation, setDeleteRuleConfirmation] = useState(false);
  const [ruleStatusConfirmation, setRuleStatusConfirmation] = useState(false);
  const [ruleActions, setRuleActions] = useState([]);
  const [mergedActions, setMergedActions] = useState([]);

  const summaryHeaders = ["Rule ID", "Configured on Space", "Last Modified On", "Starts At", "Expires At", "Managed By"]
  let ruleSummaryData = { ruleId: "", space: "", updateTime: "", startsAt: "", expiresAt: "", ruleManagedBy: "" };

  // let startsAtTime = automationData?.startsAtSecs ? formatedDate({ date: automationData.startsAtSecs * 1000, format: "MMM d, yyyy 'at' hh:mm a" }) : '-';
  // let expiresAtTime = automationData?.expiresAtSecs ? formatedDate({ date: automationData.expiresAtSecs * 1000, format: "MMM d, yyyy 'at' hh:mm a" }) : '-';
  let updatedAtTime = automationData?.updatedAt ? formatedDate({ date: automationData.updatedAt * 1000 }) : '-';

  ruleSummaryData = {
    // ruleId: automationData?.ruleId || '-',
    spaceName: automationData?.spaceName || '-',
    updateTime: updatedAtTime,
    // startsAt: startsAtTime,
    // expiresAt: expiresAtTime,
    ruleManagedBy: automationData?.ruleManagedBy === 'controllerManaged' ? 'Controller' : (automationData?.ruleManagedBy === 'cloudManaged' ? 'Cloud' : '-')
  }

  const summaryHeader = {
    // ruleId: { label: 'Rule ID' },
    spaceName: { label: "Configured on Space" },
    updateTime: { label: "Last Modified On" },
    // startsAt: { label: "Starts At" },
    // expiresAt: { label: "Expires At" },
    ruleManagedBy: { label: "Managed By" },
  }

  const filterAutomationData = () => {
    const actionsList = _automationResponse?.onTrigger || [];
    let filteredData = []

    if (!_.isEmpty(actionsList)) {
      filteredData = actionsList?.map((action) => {
        if (action.hasOwnProperty('payload')) {
          const payloadData = (typeof action.payload !== 'object') ? JSON.parse(action.payload) : action.payload;
          return {
            ...action,
            payload: payloadData
          }
        }
        else {
          return action;
        }

      });
    }

    setRuleActions([...filteredData]);
  }

  useEffect(() => {
    if (_automationResponse && Object.keys(_automationResponse).length > 0) {
      filterAutomationData();
    }
  }, [_automationResponse])

  const goTo = (route) => {
    history.push(route);
  };

  const deleteRuleHandler = () => {
    setDeleteRuleConfirmation(true);
  }

  const handleDelete = (event) => {
    event.preventDefault();
    dispatch(automationActions.deleteAutomationRuleById(automationData.ruleId, goTo("/spaces/rules")));
  }

  const editRuleStatusHandler = () => {
    setRuleStatusConfirmation(true);
  }

  const handleEditRuleStatus = (event, checked) => {
    event.preventDefault();
    let statusMessage = !checked ? 'enable' : 'disable';
    setRuleStatusToggle(!checked);

    dispatch(automationActions.updateAutomationRule(ruleSummaryData?.ruleId, { status: statusMessage }));
    setRuleStatusConfirmation(false);
  }

  const prepareSummaryResponse = () => {
    return (
      Object.keys(ruleSummaryData).map((key, index) => {
        return (
          <Box key={index}>
            <CustomTypography
              content={summaryHeaders[index]}
              weight={600}
              lineHeight={19}
              size={10}
              textTransform="uppercase"></CustomTypography>
            <CustomTypography
              content={ruleSummaryData[key]}
              weight={400}
              lineHeight={19}
              size={14}></CustomTypography>
          </Box>
        )
      })
    )
  }

  const prepareTimeString = (timeList = []) => {
    console.log("peter", timeList);
    var timeString = "";

    timeList.forEach((time, index) => {
      if (index !== 0) {
        timeString += " | "
      }

      timeString += `${time.slice(0, 2)}:${time.slice(2)}`
    });
    return timeString;
  }

  const prepareTriggerInfo = () => {
    const triggerDetails = findArrayByType(_automationResponse?.triggers, 'DEVICE_STATUS') || [];
    const timeTriggerDetails = findArrayByType(_automationResponse?.triggers, 'TIME') || [];

    if (!_.isEmpty(triggerDetails) || !_.isEmpty(timeTriggerDetails)) {
      const trigger = !_.isEmpty(triggerDetails) ? triggerDetails[0] : timeTriggerDetails[0];
      return (
        <Box key={"rule-triggers-device-status"} sx={{ p: '20px 30px', border: "1px solid #bcbcbc", borderRadius: "4px", p: 2 }}>
          <Box><CustomTypography content={trigger?.type === "TIME" ? "Time Trigger" : "Device Status Trigger"} fontWeight={600} fontSize="14px" /></Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: "1px solid #bcbcbc", borderRadius: "4px", p: 1, maxWidth: '305px' }}>
            <Box sx={{ display: 'flex', maxWidth: '230px', whiteSpace: 'nowrap' }}>
              {trigger?.type === "TIME"
                ? <Box sx={{ textWrap: "wrap" }}>
                  <CustomTypography content={"Time"} fontWeight={500} fontSize="16px" />
                  <CustomTypography content={prepareTimeString(trigger?.timeOfDay)} fontWeight={400} fontSize="14px" />
                </Box>
                : <>
                  <CustomTypography content={trigger?.name} fontWeight={400} fontSize="14px" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} />
                  <CustomTypography content={trigger?.property ? " | " + (trigger?.property).toUpperCase() : ""} fontWeight={400} fontSize="14px" />
                </>
              }
            </Box>
          </Box>
        </Box>
      )
    }
  }

  const handleTabChange = (event, index) => {
    event.preventDefault();
    setTabIndex(index);
  }

  useEffect(() => {
    if (ruleId) {
      dispatch(automationActions?.getAutomationRuleById(+ruleId));
    }
  }, [ruleId, dispatch]);

  useEffect(() => {
    setRuleStatusToggle(automationData?.status === "enable");
  }, [automationData])

  useEffect(() => {
    if (!_.isEmpty(ruleActions)) {
      const ruleDeviceActions = ruleActions?.filter(item => item.type === "DEVICE_ACTION").map(deviceItem => deviceItem?.deviceId) || [];
      const ruleGroupActions = ruleActions?.filter(item => item.type === "GROUP_ACTION").map(groupItem => groupItem?.groupId) || [];
      const ruleSceneActions = ruleActions?.filter(item => item.type === "SCENE_ACTION").map(sceneItem => sceneItem?.sceneId) || [];

      if (!_.isEmpty(ruleDeviceActions)) {
        dispatch(deviceActions.getDetailedDeviceList({
          "deviceIds": ruleDeviceActions.join(','),
        }));
      }
      if (!_.isEmpty(ruleGroupActions)) {
        dispatch(groupActions.getMultipleGroups({
          "groupIds": ruleGroupActions?.join(','),
          "keys": "devices,name,groupId,groupType"
        }));
      }

      if (!_.isEmpty(ruleSceneActions)) {
        dispatch(sceneActions.getMultipleScenes({
          "sceneIds": ruleSceneActions.join(','),
          "keys": "name,sceneType,actions,sceneId"
        }));
      }
    }
  }, [ruleActions])

  useEffect(() => {
    if (!_.isEmpty(ruleActions)) {
      let actions = [...ruleActions];

      const ruleDeviceActions = actions?.filter(item => item.type === "DEVICE_ACTION") || [];
      const ruleGroupActions = actions?.filter(item => item.type === "GROUP_ACTION") || [];
      const ruleSceneActions = actions?.filter(item => item.type === "SCENE_ACTION") || [];

      let mergedDeviceList = ruleDeviceActions?.map((action) => {
        let deviceDetails = _detailedDeviceList?.find(detailedDevice => detailedDevice?.deviceId === action?.deviceId) || {};
        return { ...deviceDetails, ...action };
      }) || [];

      let mergedGroupList = ruleGroupActions?.map((action) => {
        let groupDetails = _multipleGroupsList?.find(group => group?.groupId === action?.groupId) || {};
        return { ...groupDetails, ...action };
      }) || [];

      let mergedSceneList = ruleSceneActions?.map((action) => {
        let sceneDetails = _multipleScenesList?.find(scene => scene?.sceneId === action?.sceneId) || {};
        return { ...sceneDetails, ...action };
      }) || [];

      setMergedActions([...mergedDeviceList, ...mergedGroupList, ...mergedSceneList]);
    }
  }, [_detailedDeviceList, _multipleGroupsList, _multipleScenesList])

  useEffect(() => {
    setTimeout(() => {
      setRuleStatusToggle(ruleStatusConfirmation)
    }, 100);
  }, [ruleStatusConfirmation])

  return (
    <Box className={classes?.AutomationRuleDetails || ""} data-testid="AutomationRuleDetails">
      {/* <DataNotFound
        show={(Object.keys(automationData).length > 0) ? false : true}
        customsx={{ image: { height: '202px', width: '100%' }, label: {}, content: {}, btns: {} }}
        data={{
          label: "Rules",
          content: getMessageStr('ruleById-get-404'),
          action: [
            {
              id: "1001",
              type: "button",
              label: "Go Back",
              onClick: () => { goTo("/spaces/rules") },
            }]
        }}
      > */}
      <Box>
        <PageHeader bckbtn={true} textTransform={'none'} title={automationData?.ruleName} icon={RulesIcon}>
          {isAuthorised(features?.device, permissions?.edit) &&
            <>
              <StyledButton iconButton tooltip='Edit Rule' sx={{ p: '0' }} onClick={() => goTo("/spaces/rules?op=edit")}>
                <ImageWrapper height={20} src={Edit} />
              </StyledButton>
              <StyledButton iconButton tooltip='Delete Rule' sx={{ p: '0', ml: 2.5, mr: 2 }} onClick={deleteRuleHandler}>
                <ImageWrapper height={20} src={Delete} />
              </StyledButton>
              {/* <StyledButton iconButton tooltip={ruleStatusToggle ? 'Disable Rule' : 'Enable Rule'} sx={{ p: '0' }} onClick={editRuleStatusHandler} >
                  <SwitchButton type='iphone' setToggle={ruleStatusToggle} />
                </StyledButton> */}
            </>
          }
        </PageHeader>

        {/* <Box className={classes?.CustomSummaryHeader || ''}>
          <CustomTypography
            content="Summary"
            weight={700}
            size={12}
            lineHeight={14.63}
            color="#000"
          />
        </Box>

        <Box className={classes?.CustomSummaryContent}>
            {prepareSummaryResponse()}
          </Box>
        <Box sx={{ p: '0 1rem' }}></Box> */}

        <CustomAccordion title="Rule Details">
          <ItemView data={ruleSummaryData} headers={summaryHeader} />
        </CustomAccordion>
        <Box sx={{ py: 1 }}>
          <StyledTabs tabs={tabs} onClick={(event, index) => { handleTabChange(event, index) }} />
        </Box>
        <Box>
          {
            tabIndex === 1
              ? <RuleActions trigger={{ type: automationData?.triggers?.[0]?.[0]?.type, property: automationData?.triggers?.[0]?.[0]?.property }} actionsData={mergedActions} />
              : prepareTriggerInfo()
          }
        </Box>
        <StyledPopup title={"Delete Rule"} open={deleteRuleConfirmation} onClose={() => setDeleteRuleConfirmation(false)} state="timeout"
          data={{
            content: getMessageStr('rule-delete-confirmation'),
            actions: [
              {
                id: "1002",
                type: "button",
                label: "Cancel",
                onClick: (event, data) => {
                  setDeleteRuleConfirmation(false)
                }
              },
              {
                id: "1001",
                type: "button",
                label: "Confirm",
                onClick: (event, data) => {
                  handleDelete(event);
                }
              }
            ]
          }}
        />

        <StyledPopup open={ruleStatusConfirmation} onClose={() => setRuleStatusConfirmation(false)} state="timeout"
          data={{
            content: `${ruleStatusToggle ? getMessageStr('rule-edit-statusDisable') : getMessageStr('rule-edit-statusEnable')}`,
            actions: [
              {
                id: "1003",
                type: "button",
                label: "No",
                onClick: (event, data) => {
                  setRuleStatusConfirmation(false)
                }
              },
              {
                id: "1004",
                type: "button",
                label: "Yes",
                onClick: (event, data) => {
                  handleEditRuleStatus(event, ruleStatusToggle)
                }
              }
            ]
          }}
        />
      </Box>
      {/* </DataNotFound> */}
    </Box>
  )
};

AutomationRuleDetails.propTypes = {};

AutomationRuleDetails.defaultProps = {};

export default AutomationRuleDetails;
