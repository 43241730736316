/***
 *
 * Controller class for user.
 * @file Property.js
 * @description Property component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import { Box, Grid, Slider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomTypography from '../../../CustomTypography';
import InputSpinner from '../../../InputSpinner';
// import PropTypes from 'prop-types';
import './Property.scss';
import StyledColorPicker from '../../../StyledColorPicker';
import StyledTextfield from '../../../StyledTextfield';
import { PropertyCss } from './Property.style';


const Property = ({ name, value, onChange, ...props }) => {
  const [type, setType] = useState(null);
  const [sliderval, setSliderVal] = useState(30);
  const types = {
    // "color": ["stroke", "color", "fill"],
    "color": ["color", "fill"],
    "number":  props?.shapetype === 'circle' ? ['radius']: ['height', 'width'],
    "inputSpinner": ["opacity", "transparency"],
    "dropdown": ["strokeWidth"]
  }

  const spinnerValueChange = (key, val) => {
    onChange({ "target": { "name": key, "value": key === "dash" ? [val] : val } });
  }

  const handleSlider = (name, val) => {
    setSliderVal(parseInt(val));
    onChange({ "target": { "name": name, "value": name === "dash" ? [val / 100] : val / 100 } });

  }

  useEffect(() => {
    if (name === 'opacity' || name === 'transparency') {
      setSliderVal(parseInt((value) * 100));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useEffect(() => {
    // console.log('property-->',types, name);
    Object.keys(types).forEach(key => {
      if (types[key].includes(name)) {
        setType(key);
      }
    })
    if (name === 'transparency') setSliderVal(parseInt((value) * 100));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  const handleChange = (e) => {
    let value = e.target?.value;
    onChange({
      target: {
        name: name,
        value: !isNaN(value) ? Number(value) >=30 ? Number(value) : 30 : value
      }
    });
  }

  return (
    <>
      {name !== 'uniq_id' && name !== 'spaceId' && type !== null ?
        <Grid sx={{ mb: 2 }} item container xs={12} className="form-group row">
          <Grid item xs={12}>
            <label className="control-label text-capitalize" htmlFor={name}>{name}</label>
          </Grid>
          <Grid item xs={12} className="">
            {(() => {
              switch (type) {
                case "inputSpinner":
                  return (
                    <Box display={'flex'} alignItems="center" >
                      <Slider sx={{ width: '170px',ml:'10px', mr:'7px' }} value={sliderval} max={100} min={10} onChange={(e) => { handleSlider(name, e.target.value) }} />
                      <CustomTypography styles={{ marginLeft: '10px' }} content={`${sliderval}%`} color="#202020" size={15} weight={600} />
                    </Box>
                  )
                case "dropdown":
                  return <InputSpinner value={0} min={0} max={10} changedValue={(val) => spinnerValueChange(name, val)} />
                // return <select className="" id={name} name={name} onChange={onChange}>
                //         {[0, 1, 2, 3, 4, 5, 6, 7, 8]?.map(o=>{
                //           return <option key={o} value={o}>{o}</option>
                //         })}
                //       </select>
                case "number":
                  return <Box display={'flex'} alignItems={'center'}>
                    <StyledTextfield
                      id={name}
                      name={name}
                      value={Math.round( Number(value) >=30 ? ((Number(value) * 100) / 100) : 30)}
                      onChange={handleChange}
                      type={type}
                      inputProps={{
                      min:30,
                      max:10000}}
                      sx={{...PropertyCss?.root, pr:1}}
                    />
                    <CustomTypography content={'px'} size={14}/>
                  </Box>
                case 'color':
                  return <>

                    <StyledColorPicker
                      id={name}
                      customsx={{ ...PropertyCss?.root, ...PropertyCss?.input }}
                      name={name}
                      value={!isNaN(value)?'':value }
                      onChange={(e) => { handleChange(e) }}
                      format='hex'
                    />


                  </>
                default: return <></>
              }
            })()}
          </Grid>
        </Grid>
        : ""
      }
    </>
  )
};

Property.propTypes = {};

Property.defaultProps = {};

export default Property;
