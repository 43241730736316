import { areaTypes } from "../types";
import { request, success, failure, getMessage } from '../../helpers';

import { appActions } from './app.actions';
import { areaService, delay } from "../../services";

const createArea = (gatewayId, payload, cb = () => { }) => {
    return dispatch => {
        dispatch(request(areaTypes.ADD_REQUEST));
        areaService.createArea(gatewayId, payload)
            .then(
                ({ data: area, status }) => {
                    dispatch(success(areaTypes.ADD_SUCCESS, { payload: area }));
                    getMessage(`area-post-${status}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiSuccessMessage(msg));
                    })
                    cb();
                },
                ({ error, code }) => {
                    dispatch(failure(areaTypes.ADD_FAILURE, error.toString()));
                    getMessage(`area-post-${code}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    })
                }
            );
    };
}

const editArea = (areaId, payload, cb = () => { }) => {
    return dispatch => {
        dispatch(request(areaTypes.UPDATE_REQUEST));
        areaService.editArea(areaId, payload)
            .then(
                ({ data: area, status }) => {
                    dispatch(success(areaTypes.UPDATE_SUCCESS, { payload: area }));
                    getMessage(`area-put-${status}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiSuccessMessage(msg));
                    })
                    cb();
                },
                ({ error, code }) => {
                    dispatch(failure(areaTypes.UPDATE_FAILURE, error.toString()));
                    getMessage(`area-put-${code}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    })
                }
            );
    };
}

const getAreaDetails = (areaId) => {
    return dispatch => {
        dispatch(request(areaTypes.GET_AREA_REQUEST));
        areaService.getAreaDetails(areaId)
            .then(
                area => {
                    dispatch(success(areaTypes.GET_AREA_SUCCESS, { payload: area }))
                },
                ({ error, code }) => {
                    dispatch(failure(areaTypes.GET_AREA_FAILURE, error.toString()));
                    getMessage(`area-put-${code}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    })
                }
            );
    };
}

const getAreasByControllerId = (controllerId) => {
    return dispatch => {
        dispatch(request(areaTypes.GET_AREAS_REQUEST));
        areaService.getAreasByControllerId(controllerId)
            .then(
                areas => dispatch(success(areaTypes.GET_AREAS_SUCCESS, { payload: areas, deviceId: controllerId })),
                ({ error, code }) => {
                    dispatch(failure(areaTypes.GET_AREAS_FAILURE, error.toString()));
                    getMessage(`areaByController-get-${code}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    })
                }
            );
    };
}

const getAllAreasBySpace = (spaceId) => {
    return dispatch => {
        if (spaceId) {
            dispatch(request(areaTypes.GET_AREAS_REQUEST));
            areaService.getAllAreasBySpace(spaceId)
                .then(
                    areas => dispatch(success(areaTypes.GET_AREAS_SUCCESS, { payload: areas, spaceId: spaceId })),
                    ({ error, code }) => {
                        dispatch(failure(areaTypes.GET_AREAS_FAILURE, error.toString()));
                        getMessage(`areaBySpace-get-${code}`).then(delay(500)).then((msg) => {
                            dispatch(appActions.apiErrorMessage(msg));
                        })
                    }
                );
        }
    };
}

const getAreaDevicesDetails = (payload) => {
    return dispatch => {
        dispatch(request(areaTypes.GET_AREA_DEVICES_DETAILS_REQUEST));
        areaService.getAreaDevicesDetails(payload)
            .then(
                devices => dispatch(success(areaTypes.GET_AREA_DEVICES_DETAILS_SUCCESS, { payload: devices })),
                ({ error, code }) => {
                    dispatch(failure(areaTypes.GET_AREA_DEVICES_DETAILS_FAILURE, error.toString()));
                    getMessage(`areaDeviceDetails-get-${code}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    })
                }
            );
    };
}

const deleteArea = (areaId, cb = () => { }) => {
    return dispatch => {
        dispatch(request(areaTypes.DELETE_REQUEST));
        areaService.deleteArea(areaId)
            .then(
                ({ data: area, status }) => {
                    dispatch(success(areaTypes.DELETE_SUCCESS, { payload: area, areaId: areaId }));
                    getMessage(`area-delete-${status}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiSuccessMessage(msg));
                    })
                    cb();
                },
                ({ error, code }) => {
                    dispatch(failure(areaTypes.DELETE_FAILURE, error.toString()));
                    getMessage(`area-delete-${code}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    })
                }
            );
    };
}

const syncById = (id) => {
    return dispatch => {
        dispatch(request(areaTypes.AREA_SYNC_REQUEST));
        areaService.syncById(id)
            .then(
                ({ data, status }) => {
                    dispatch(success(areaTypes.AREA_SYNC_SUCCESS));
                    dispatch(areaActions.getAreaDetails(id)); // Added this to update area details after syncing is done
                    getMessage(`syncArea-put-${status}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiSuccessMessage(msg));
                    })
                },
                ({ error, code }) => {
                    dispatch(failure(areaTypes.AREA_SYNC_FAILURE, error.toString()));
                    getMessage(`syncArea-put-${code}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    })
                }
            );
    };
}

export const areaActions = {
    getAreasByControllerId,
    createArea,
    editArea,
    getAreaDetails,
    getAllAreasBySpace,
    getAreaDevicesDetails,
    deleteArea,
    syncById
};