import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme)=>({
    PageSetion: {
        '& .header': {
            background: theme?.bg?.accordionTtitle || '#0F8D480D',
            padding: theme?.padding?.accordionTtitle || '13px 20px',
            fontWeight: theme?.fontWeight?.accordionTtitle || 700,
            fontSize: theme?.fontSize?.accordionTtitle || '0.75rem',
            lineHeight: theme?.lineHeight?.accordionTtitle || '15px',
            color: '#000000'
        }
    },
    ContentBox: {
        overflow: "hidden",
        display: "block",
        padding: "20px 0"
    }
}));
