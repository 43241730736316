/***
 *
 * Controller class for user.
 * @file TemperatureSlider.js
 * @description TemperatureSlider component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './TemperatureSlider.style.js';
import './TemperatureSlider.scss';
import { Box } from '@mui/material';

import { TableHelper } from '../TableHelper/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { isAuthorised, tempControl } from '../../helpers';
import { SliderCss } from './TemperatureSlider.style.js';
import { features, permissions } from '../../constants/permission.constants.js';
import StyledSpinner from '../StyledSpinner/index.js';
const TemperatureSlider = ({ data, params = { value: true, compo: true }, showLoader = false, mqttClient,subscribe=true, ...props }) => {
    const classes = useStyles()
    const deviceModels = useSelector((state) => state.devices.deviceModels);
    //   const deviceData=useSelector((state)=>state.devices.deviceList.find((device)=>device?.deviceId===data?.deviceId))
    //   const loadingStatus=deviceData?.loading
    const dispatch = useDispatch();

    const [slider, setSlider] = useState(data);
    const [config, setConfig] = useState(null);
    const [loader, setLoader] = useState(false)


    useEffect(() => {
        setSlider(data);
        // console.log("DeviceId step 1 --- ", config, data);
    }, [data]);

    useEffect(() => {
        if (showLoader) {
            setLoader(data?.loading)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.loading])

    // useEffect(()=>{
    //     setLoading(loadingStatus)
    // },[loadingStatus]);

    useEffect(() => {
        const modelKlv = deviceModels.find((d) => (d.modelNumber?.substring(d.modelNumber?.length - 3, d.modelNumber?.length) === slider?.configuration?.daliDriver))?.controlCapabilities?.find(
            (c) => c?.capability?.trim()?.toLowerCase() === "kelvin"
        );
        if (modelKlv) {
            const klvValue =
                slider?.deviceStatus?.status?.kelvin ||
                modelKlv?.attributes[0]?.defaultValue;
            const klvMax = modelKlv?.attributes[0]?.range?.max[0]?.value;
            const klvMin = modelKlv?.attributes[0]?.range?.min[0]?.value;
            setConfig({ value: klvValue, min: klvMin, max: klvMax, suffix: "K", disabled: slider?.status === "active" ? !isAuthorised(features?.device, permissions?.configureTemperature) : true, params });
        } else {
            setConfig({ customsx: { value: { color: "grey" } }, suffix: "K", disabled: true, params: { icon: false, label: false, value: false, compo: false, switchButton: false } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deviceModels, slider?.deviceStatus?.status?.kelvin, slider?.modelId, slider?.status])

    return (
        <Box className={classes?.TemperatureSlider || ""} data-testid="TemperatureSlider">
            <Box sx={{ position: "relative" }} >
                {loader &&
                    <Box sx={{ position: 'absolute', top: 0, bottom: 0, width: "100%", backgroundColor: "rgba(255, 255, 255, 0.8)", zIndex: 1000, ...props?.customsx?.loader }}>
                        <StyledSpinner size={24} sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
                    </Box>
                }
                {
                    //  loading?
                    //  <StyledSpinner/>
                    //  :
                    <TableHelper.TableSlider
                        data={slider}
                        customData={config}
                        params={config?.params}
                        onChange={(e, value, obj, setter) => {
                            console.log("DeviceId ---- ", config, value, obj, slider)
                            tempControl(obj, value, dispatch, mqttClient, () => { setter(slider?.deviceStatus?.status?.kelvin) },subscribe)
                        }}
                        {...props}
                        customsx={{ ...props?.customsx,value:{minWidth:"46px",...props?.customsx?.value},compo:{...SliderCss,...props?.customsx?.compo} }}
                    />
                }
            </Box>
        </Box>
    )
};

TemperatureSlider.propTypes = {};

TemperatureSlider.defaultProps = {};

export default TemperatureSlider;
