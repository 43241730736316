import _ from "lodash";
import { failure, getFwUpdateStatus, getMessage, request, success } from "../../helpers";
import { delay, fotaService } from "../../services";
import { fotaTypes } from "../types";
import { appActions } from "./app.actions";

const upgradeFirmware = (deviceIds = [], gatewayId = "", firmwareId = "", cb = () => { }) => {
  return dispatch => {
    dispatch(request(fotaTypes.UPGRADE_FIRMWARE_REQUEST));
    fotaService.upgradeFirmware(deviceIds, gatewayId, firmwareId)
      .then(
        ({ data, status }) => {
          getMessage(`upgradeFirmware-get-${status}`).then(delay(500)).then((msg) => {
            dispatch(appActions.apiSuccessMessage(msg));
          })
          dispatch(success(fotaTypes.UPGRADE_FIRMWARE_SUCCESS, { payload: { ...data } }));
          cb();
        },
        ({ error, code }) => {
          dispatch(failure(fotaTypes.UPGRADE_FIRMWARE_FAILURE, error?.toString()));
          getMessage(`upgradeFirmware-get-${code}`).then(delay(500)).then((msg) => {
            dispatch(appActions.apiErrorMessage(msg));
          })
        }
      );
  };
}

// const getNextApplicableFirmware = (modelId = "", firmwareVersion = "") => {
//   return dispatch => {
//     dispatch(request(fotaTypes.GET_NEXT_APPLICABLE_FIRMWARE_REQUEST));
//     fotaService.getNextApplicableFirmware(modelId, firmwareVersion)
//       .then(
//         ({ data, status }) => {
//           // getMessage(`nextApplicableFirmware-get-${status}`).then(delay(500)).then((msg) => {
//           //   dispatch(appActions.apiSuccessMessage(msg));
//           // })
//           dispatch(success(fotaTypes.GET_NEXT_APPLICABLE_FIRMWARE_SUCCESS, { payload: { ...data } }));
//         },
//         ({ error, code }) => {
//           dispatch(failure(fotaTypes.GET_NEXT_APPLICABLE_FIRMWARE_FAILURE, error?.toString()));
//           if(code!==404){
//             getMessage(`nextApplicableFirmware-get-${code}`).then(delay(500)).then((msg) => {
//               dispatch(appActions.apiErrorMessage(msg));
//             })
//           }
//         }
//       );
//   };
// }
const getNextApplicableFirmwares = (params) => {
  return dispatch => {
    dispatch(request(fotaTypes.GET_NEXT_APPLICABLE_FIRMWARES_REQUEST));
    fotaService.getNextApplicableFirmwares(params)
      .then(
        ({ data, status }) => {
          // getMessage(`nextApplicableFirmware-get-${status}`).then(delay(500)).then((msg) => {
          //   dispatch(appActions.apiSuccessMessage(msg));
          // })
          const currentDate = new Date();
          data = data?.map(d => {
            if (d?.hasOwnProperty("lastFwStatus")) {
              const updateDate = new Date(d?.lastFwStatus?.lastUpdatedOn);
              const { status, disableUpgrade, statusExpired } = getFwUpdateStatus({ ...d?.lastFwStatus, ts: d?.lastFwStatus?.lastUpdatedOn });
              d["fwUpdateStatus"] = status;
              d["statusExpired"] = statusExpired;
              d["disableUpgrade"] =  disableUpgrade;
            }
            else {
              d["statusExpired"] = false;
              d["fwUpdateStatus"] = "-";
              d["disableUpgrade"] = false;
            }

            // d["fwUpdateStatus"] = d?.hasOwnProperty("lastFwStatus") ? d?.lastFwStatus?.status : "-"
            return d;
          })
          dispatch(success(fotaTypes.GET_NEXT_APPLICABLE_FIRMWARES_SUCCESS, { payload: [...data] }));
        },
        ({ error, code }) => {
          dispatch(failure(fotaTypes.GET_NEXT_APPLICABLE_FIRMWARES_FAILURE, error?.toString()));
          getMessage(`nextApplicableFirmware-get-${code}`).then(delay(500)).then((msg) => {
            dispatch(appActions.apiErrorMessage(msg));
          })
        }
      );
  };
}

const getFirmwareDistribution = (params, tenantId = null, cb = () => { }) => {
  return dispatch => {
    dispatch(request(fotaTypes.GET_FIRMWARE_DISTRIBUTION_REQUEST));
    fotaService.getFirmwareDistribution(params, tenantId)
      .then(
        ({ data, status }) => {
          cb(data);
          getMessage(`firmwareDistribution-get-${status}`).then(delay(500)).then((msg) => {
            dispatch(appActions.apiSuccessMessage(msg));
          })
          dispatch(success(fotaTypes.GET_FIRMWARE_DISTRIBUTION_SUCCESS, { payload: [...data] }));
        },
        ({ error, code }) => {
          dispatch(failure(fotaTypes.GET_FIRMWARE_DISTRIBUTION_FAILURE, error?.toString()));
          getMessage(`firmwareDistribution-get-${code}`).then(delay(500)).then((msg) => {
            dispatch(appActions.apiErrorMessage(msg));
          })
        }
      );
  };
}

const getFirmwareList = (params, cb = () => { }) => {
  return (dispatch, getState) => {
    dispatch(request(fotaTypes.GET_FIRMWARE_LIST_REQUEST));
    fotaService.getFirmwareList(params)
      .then(
        ({ data, status }) => {
          const deviceModels = getState()?.devices?.deviceModels;
          data = data?.map(d => {
            const modelList = d?.modelIds?.map(m => deviceModels?.find(dm => dm?.modelId === m)?.modelNumber);
            return {
              ...d,
              firmwareVersion: `Firmware V${d?.fwVersion}`,
              modelIdList: modelList?.join("|"),
              tagList: _.isEmpty(d?.tag) || (d?.tag?.length === 1 && d?.tag[0] === "") ? "-" : d?.tag?.join("|")
            }
          })
          cb(data);
          getMessage(`firmwareList-get-${status}`).then(delay(500)).then((msg) => {
            dispatch(appActions.apiSuccessMessage(msg));
          })
          dispatch(success(fotaTypes.GET_FIRMWARE_LIST_SUCCESS, { payload: data }));
        },
        ({ error, code }) => {
          dispatch(failure(fotaTypes.GET_FIRMWARE_LIST_FAILURE, error?.toString()));
          getMessage(`firmwareList-get-${code}`).then(delay(500)).then((msg) => {
            dispatch(appActions.apiErrorMessage(msg));
          })
        }
      );
  };
}

const deleteFirmware = (fwId, cb = () => { }) => {
  return dispatch => {
    dispatch(request(fotaTypes.DELETE_FIRMWARE_REQUEST));
    fotaService.deleteFirmware(fwId)
      .then(
        ({ data, status }) => {
          getMessage(`firmware-delete-${status}`).then(delay(500)).then((msg) => {
            dispatch(appActions.apiSuccessMessage(msg));
          })
          dispatch(success(fotaTypes.DELETE_FIRMWARE_SUCCESS, { payload: { fwId } }));
          cb();
        },
        ({ error, code }) => {
          dispatch(failure(fotaTypes.DELETE_FIRMWARE_FAILURE, error?.toString()));
          getMessage(`firmware-delete-${code}`).then(delay(500)).then((msg) => {
            dispatch(appActions.apiErrorMessage(msg));
          })
        }
      );
  };
}


export const fotaActions = {
  upgradeFirmware,
  getNextApplicableFirmwares,
  getFirmwareDistribution,
  getFirmwareList,
  deleteFirmware,
}