/***
 *
 * Controller class for user.
 * @file StyledDashboardCard.js
 * @description StyledDashboardCard component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './StyledDashboardCard.style.js';
import './StyledDashboardCard.scss';
import { Box, Card, Grid } from '@mui/material';
import CustomTypography from '../CustomTypography/index.js';
import { Link } from 'react-router-dom';

const StyledDashboardCard = ({ children, heading, headingChildren, moreBtn, sx = {}, headingStyle={} }) => {
  const classes = useStyles()

  return (
    <Card className={classes?.StyledDashboardCard || ""} data-testid="StyledDashboardCard">
      <Box sx={{ minHeight: 24, mb: 2, ...sx }}>
        <Grid container item xs>
          <Grid item md={headingChildren?6:12} xs={12} sx={{display: "flex", alignItems: "center"}}>
            <Box sx={{ width: "max-content", maxWidth: moreBtn?"calc(100% - 80px)":"100%" }}><CustomTypography className={classes?.Heading} weight={600} content={heading} styles={{...headingStyle}} /></Box>        
            <Box sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
              {moreBtn && <Link className={classes?.Link} to={moreBtn?.url}>{moreBtn?.label || "More"}</Link>}
            </Box>
          </Grid>
          {headingChildren && 
            <Grid item md={6} xs={12}>
              <Box sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
                {headingChildren && <Box>{headingChildren}</Box>}
              </Box>
            </Grid>
          }          
        </Grid>
      </Box>
      {children}
    </Card>
  )
};

StyledDashboardCard.propTypes = {};

StyledDashboardCard.defaultProps = {};

export default StyledDashboardCard;
