/***
 *
 * Controller class for user.
 * @file FirmwareUpgrade.js
 * @description FirmwareUpgrade component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './FirmwareUpgrade.style.js';
import './FirmwareUpgrade.scss';
import { Box, Checkbox, Grid } from '@mui/material';
import Listing from '../../assets/icons/list.svg'
import StyledButton from '../StyledButton/index.js';
import CustomLabel from '../CustomLabel/index.js';
import PageHeader from '../PageHeader/index.js';
import CustomDropDown from '../CustomDropDown/index.js';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMessageStr } from '../../helpers/message.helper.js';
import { fotaActions } from '../../redux/actions/fota.actions.js';
import DataNotFound from '../../pages/DataNotFound';
import CustomTable from '../CustomTable/index.js';
import { TableHelper } from '../TableHelper/index.js';
import { features, permissions } from '../../constants/permission.constants.js';
import { isAuthorised } from '../../helpers/users.helper.js';
import UpgradeIcon from '../../assets/icons/upload-2.svg'
import CustomTypography from '../CustomTypography/index.js';
import _ from 'lodash';
import { subscribe, unsubscribeAll } from '../../services/index.js';
import { fwStatusExpiredIcon, nameEditor } from '../../helpers/device.helper.js';
import StyledSpinner from '../StyledSpinner/index.js';



const FirmwareUpgrade = ({ mqttClient }) => {
  const classes = useStyles();
  const dispatch = useDispatch();


  const deviceCategories = useSelector(state => state?.devices?.deviceCategories);
  const deviceModels = useSelector(state => state?.devices?.deviceModels);
  const gateways = useSelector(state => state?.devices?.gatewayList);
  const deviceList = useSelector(state => state?.fota?.deviceList);
  const fwUpdateStatus = useSelector((state) => state?.fota?.lastAction);

  const [selection, setSelection] = useState({ gateway: "", model: "", category: "" });
  const [selectedDevices, setSelectedDevices] = useState({});
  const [error, setError] = useState({ category: null, gateway: null, model: null });
  const [nextApplicableVersionList, setNextApplicableVersionList] = useState([]);
  const [nextApplicableVersion, setNextApplicableVersion] = useState(null);
  const [bulkUpgrade, setBulkUpgrade] = useState(false);
  const [firmwareVersionObj, setFirmwareVersionObj] = useState({})
  const [selectedFwVersion, setSelectedFwVersion] = useState({})
  const [table, setTable] = useState({ headers: {}, data: null, searching: [] })
  // const gwList = [];
  // for (let i = 1; i <= 30; i++) {
  //   gwList?.push({ id: i, label: `gateway${i}` })
  // }

  const dropdowns = [

    {
      label: "Select Category",
      name: "category",
      list: deviceCategories?.filter(c => c?.category?.toLowerCase()?.includes("gateway") || c?.category?.toLowerCase()?.includes("controller"))?.map(c => { return { id: c?.category, label: c?.label } }),
      disabled: () => { return false }
    },
    {
      label: "Select Model",
      name: "model",
      list: deviceModels?.filter(m => m?.category === selection?.category)?.map(m => { return { id: m?.modelId, label: m?.modelNumber } }),
      disabled: () => { return selection?.category === "" }
    },
    {
      label: "Select Parent Gateway",
      name: "gateway",
      list: gateways?.map(g => { return { id: g?.deviceId, label: g?.name } }),
      // list: gwList,
      disabled: () => { return selection?.category === "VortexGateway" }
    },
  ]

  const tableHeaders = (selectedFwVersion, selectedDevices) => {
    return {
      ...(() => {
        if (nextApplicableVersion?.version !== "") {
          return {
            checked: {
              label: "",
              type: "component",
              component: (row) => {
                return (
                  <TableHelper.TableCheckbox
                    sx={{ p: 0 }}
                    tooltip={{ show: true, placement: "right", text: row?.data?.hasOwnProperty("availableFirmware") ? (row?.data?.deviceStatus?.connectionStatus?.onlineStatus ? (row?.data?.disableUpgrade ? "Firmware upgrade is in progress" : "Select the device.") : "The device is offline.") : "The device has the latest firmware" }}
                    disabled={!row?.data?.deviceStatus?.connectionStatus?.onlineStatus || !row?.data?.hasOwnProperty("availableFirmware") || row?.data?.disableUpgrade}
                    value={selectedDevices[row?.data?.deviceId]}
                    data={row?.data}
                    onClick={(e) => {
                      const deviceId = row?.data?.deviceId;
                      setSelectedDevices((selectedDevices) => {
                        return { ...selectedDevices, [deviceId]: !selectedDevices[deviceId] }
                      });
                    }}
                  />
                )
              },
              style: { minWidth: 20 }
            }
          }
        }
        else {
          return {}
        }
      })(),


      name: {
        label: "name",
        style: { minWidth: 200 },
        type: "component",
        component: (row, sx = {}) => {
          return nameEditor(row, deviceCategories?.find(c => c?.category === row?.data?.category), sx, null, `/spaces/devices?pt=${row?.data?.category?.toLowerCase()?.includes("gateway") ? "gateway" : row?.data?.category?.toLowerCase()?.includes("controller") ? "controller" : "aggregator"}`, false)
        },
      },
      ...(() => {
        if (selection?.category?.toLowerCase()?.includes("gateway")) {
          return { fwVersion: { label: 'Current FW Version', style: { minWidth: 200 } } }
        }
        else {
          return { fwVersion3rdParty: { label: 'Current FW Version', style: { minWidth: 200 } }, }
        }
      })(),
      ...(() => {
        return {
          nextApplicableVersion: {
            label: "Next Applicable Version",
            type: "component",
            component: (row) => {
              if (nextApplicableVersion?.version === "") {
                if (row?.data?.hasOwnProperty("availableFirmware")) {
                  return <CustomTypography fontWeight={400} size={14} lineHeight={19} content={row?.data?.availableFirmware[0]?.fwVersion} />
                  // return <TableHelper.TableDropDown
                  //   tooltip={{ show: true, placement: "right", text: row?.data?.hasOwnProperty("availableFirmware") ? (row?.data?.deviceStatus?.connectionStatus?.onlineStatus ? (row?.data?.disableUpgrade ? "Firmware upgrade is in progress" : "Select the firmware version.") : "The device is offline.") : "The device has the latest firmware" }}
                  //   data={row?.data}
                  //   name="fwVersion"
                  //   customsx={{ minWidth: "80px" }}
                  //   disabled={(!row?.data?.deviceStatus?.connectionStatus?.onlineStatus) || row?.data?.disableUpgrade}
                  //   value={selectedFwVersion?.hasOwnProperty(row?.data?.deviceId) ? selectedFwVersion[row?.data?.deviceId] : (row?.data?.category?.toLowerCase()?.includes("gateway") ? row?.data?.fwVersion?.toString() : row?.data?.fwVersion3rdParty?.toString())}
                  //   onChange={(e) => {
                  //     setSelectedFwVersion((s) => {
                  //       return { ...s, [row?.data?.deviceId]: e?.target?.value }
                  //     })
                  //   }}
                  //   optionList={row?.data?.hasOwnProperty("availableFirmware") ? row?.data?.availableFirmware?.map(f => { return { label: f?.fwVersion, id: f?.fwVersion, fwId: f?.fwId } }) : [row?.data?.category?.toLowerCase()?.includes("gateway") ? { label: row?.data?.fwVersion?.toString(), id: row?.data?.fwVersion } : { label: row?.data?.fwVersion3rdParty?.toString(), id: row?.data?.fwVersion3rdParty }]}
                  // >
                  // </TableHelper.TableDropDown>
                }
                else {
                  return <CustomTypography fontWeight={400} size={14} lineHeight={19} content={getMessageStr("fota-upgrade-noAvailableFw")} />
                }
              }
              else {
                return <CustomTypography fontWeight={400} size={14} lineHeight={19} content={selectedFwVersion[row?.data?.deviceId]} />
              }
            },
            style: { minWidth: 220 }
          }
        }
      })(),
      fwUpdateStatus: {
        label: "FW Update Status",
        type: "component",
        component: (row) => {
          const status = row?.data?.fwUpdateStatus?.split(":");
          return <CustomTypography fontWeight={400} size={14} lineHeight={19} >
            {
              row?.data?.fwUpdateStatus === "-" ?
                row?.data?.fwUpdateStatus
                :
                <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                  {row?.data?.fwUpdateStatus}
                  {row?.data?.disableUpgrade &&
                    <StyledSpinner type={"line"} size={8}></StyledSpinner>
                  }
                  {row?.data?.statusExpired &&
                    fwStatusExpiredIcon()}
                  {/* {status[0]}
                  <CustomTypography fontWeight={600} sx={{ margin: "0px 2px" }} size={14} lineHeight={19} content={":"} />
                  {status[1] + ":" + status[2]} */}
                </Box>
            }
          </CustomTypography >
        },
        style: { minWidth: 400 }
      },
      userActions: {
        label: "Actions",
        actions: [
          {
            label: "Upgrade Firmware",
            type: "upgrade",
            icon: UpgradeIcon,
            disabled: (row) => {
              if (row?.deviceStatus?.connectionStatus?.onlineStatus && row?.hasOwnProperty("availableFirmware") && (!row?.disableUpgrade)) {
                return !isAuthorised(features?.device, permissions?.upgradeFirmware);
              }
              return true;
            },
            onClick: (event, device) => {
              setSelectedFwVersion((s) => {
                dispatch(fotaActions?.upgradeFirmware([device?.deviceId], device?.gwDeviceId || device?.deviceId, device?.availableFirmware?.find(fw => fw?.fwVersion === s[device?.deviceId])?.fwId))
                return s;
              })
              // upgradeFirmware(device)
              // dispatch(fotaActions?.upgradeFirmware([device?.deviceId], device?.gwDeviceId || device?.deviceId, device?.availableFirmware?.find(fw => fw?.fwVersion === getSelectedFwVersion(device?.deviceId))?.fwId))
            }
          }],
        style: { textAlign: "right" },

      }
    }
  }

  useEffect(() => {
    if (fwUpdateStatus?.includes("fw_updated") && selection?.category !== "") {
      dispatch(fotaActions?.getNextApplicableFirmwares(
        {
          all: false,
          category: selection?.category,
          modelId: selection?.model,
          ...(() => {
            return selection?.category !== "VortexGateway" ? { gwDeviceId: selection?.gateway } : {}
          })()
        }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fwUpdateStatus])

  useEffect(() => {
    if (!_.isEmpty(mqttClient) && mqttClient !== undefined && !_.isEmpty(gateways)) {
      subscribe(gateways?.map(g => g?.mqttTopics?.comStatus), dispatch, mqttClient, "fwUpdateStatus")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gateways, mqttClient])

  useEffect(() => {
    return () => {
      unsubscribeAll(mqttClient, dispatch)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mqttClient])

  useEffect(() => {
    if (selection?.category !== "") {
      const fwVersionObj = {};
      // const selectedVersion = {};
      // const selectedDeviceObj = {};
      deviceList?.forEach(d => {
        //   if (d?.hasOwnProperty("availableFirmware")) {
        //     selectedVersion[d?.deviceId] = d?.availableFirmware[0]?.fwVersion;
        //   }
        // selectedDeviceObj[d?.deviceId] = false;
        d?.availableFirmware?.forEach(fw => {
          if (fwVersionObj?.hasOwnProperty(fw?.fwId)) {
            fwVersionObj[fw?.fwId].devices?.push(d);
          }
          else {
            fwVersionObj[fw?.fwId] = {}
            fwVersionObj[fw?.fwId].version = fw?.fwVersion;
            fwVersionObj[fw?.fwId].devices = [d];
          }
        })
      });
      // setSelectedFwVersion(selectedVersion);
      setFirmwareVersionObj(fwVersionObj);
      // setSelectedDevices({ ...selectedDeviceObj });
      // setNextApplicableVersion({ version: "", id: "" })
      setBulkUpgrade(false);
      setNextApplicableVersion({ version: "", id: "" });
      setNextApplicableVersionList(Object?.keys(fwVersionObj)?.sort((a, b) => { return Number(b) - Number(a) })?.map(v => { return { label: fwVersionObj[v]?.version?.toString(), id: fwVersionObj[v]?.version, fwId: v } }));
      // setTable({ headers: tableHeaders(), data: deviceList, searching: ["name", "fwUpdateStatus", selection?.category === "VortexGateway" ? "fwVersion" : "fwVersion3rdParty"] })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceList])


  useEffect(() => {
    if (selection?.category !== "") {
      const obj = {}
      const selectedDeviceObj = {};
      if (nextApplicableVersion?.version === "") {
        const dList = []
        deviceList?.forEach(d => {
          dList?.push(d);
          selectedDeviceObj[d?.deviceId] = false;
          if (d?.hasOwnProperty("availableFirmware")) {
            obj[d?.deviceId] = d?.availableFirmware[0]?.fwVersion;
          }
        });
        setTable({ headers: tableHeaders(obj, selectedDeviceObj), data: dList, searching: ["name", "fwUpdateStatus", selection?.category === "VortexGateway" ? "fwVersion" : "fwVersion3rdParty"] })
      }
      else {
        firmwareVersionObj[nextApplicableVersion?.id]?.devices?.forEach(d => {
          selectedDeviceObj[d?.deviceId] = false;
          obj[d?.deviceId] = Number(nextApplicableVersion?.version);
        })
        setTable({ headers: tableHeaders(obj, selectedDeviceObj), data: firmwareVersionObj[nextApplicableVersion?.id]?.devices, searching: ["name", "fwUpdateStatus", selection?.category === "VortexGateway" ? "fwVersion" : "fwVersion3rdParty"] })
      }
      setSelectedDevices({ ...selectedDeviceObj });
      setSelectedFwVersion(obj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextApplicableVersion])

  return (
    <Box className={classes?.FirmwareUpgrade || ""} data-testid="FirmwareUpgrade">
      <PageHeader
        backbtn={false}
        title={"Firmware Management"}
        icon={Listing}
      >
      </PageHeader>
      <Box className="searchContainer">
        <Grid container spacing={2} >
          {
            dropdowns?.map((d, ind) => {
              return <Grid item xs={12} key={ind} md={4} sx={{ minHeight: "109px" }}>
                <CustomLabel
                  required={true}
                  style={{ fontSize: "12px", marginBottom: "8px", fontWeight: 700 }}
                >
                  {d?.label}
                </CustomLabel>
                <CustomDropDown
                  name={d?.name}
                  error={error[d?.name] ? true : false}
                  message={error[d?.name]}
                  value={selection[d?.name]}
                  customsx={{ menuList: { maxHeight: "180px" } }}
                  isNoneAsItem={_.isEmpty(d?.list)}
                  list={d?.list}
                  disabled={d?.disabled()
                  }
                  onChange={(e) => {
                    setTable({ ...table, data: null });
                    setError({ ...error, gateway: e?.target?.value === "VortexGateway" ? null : error?.gateway, [d?.name]: null });
                    setSelection({
                      ...selection, [d?.name]: e?.target?.value,
                      model: d?.name === "category" ? "" : d?.name === "model" ? e?.target?.value : selection?.model,
                      gateway: e?.target?.value === "VortexGateway" ? "" : d?.name === "gateway" ? e?.target?.value : selection?.gateway
                    })
                  }}
                />
              </Grid>
            })
          }

        </Grid>
        <Box className="searchButtonContainer">
          <StyledButton

            onClick={(e) => {
              const err = { category: selection?.category === "" ? getMessageStr("fota-upgrade-categoryRequired") : null, gateway: selection?.gateway === "" ? (selection?.category !== "VortexGateway" ? getMessageStr("fota-upgrade-gatewayRequired") : null) : null, model: selection?.model === "" ? getMessageStr("fota-upgrade-modelRequired") : null, }
              setError(err);
              if (err?.category === null && err?.model === null && err?.gateway === null) {
                dispatch(fotaActions?.getNextApplicableFirmwares(
                  {
                    all: false,
                    category: selection?.category,
                    modelId: selection?.model,
                    ...(() => {
                      return selection?.category !== "VortexGateway" ? { gwDeviceId: selection?.gateway } : {}
                    })()
                  }))
              }
            }}
          >
            {"Search"}
          </StyledButton>
        </Box>
      </Box>

      {table?.data !== null &&
        <DataNotFound
          show={table?.data?.length > 0 ? false : true}
          customsx={{ image: { height: '202px', width: '100%' }, label: {}, content: {}, btns: {} }}
          data={{
            content: getMessageStr("fota-upgrade-listEmpty")
          }}
        >
          <CustomTable
            headerList={table?.headers}
            cellMatrix={table?.data}
            searching={{ reset: true, columns: table?.searching }}
            pagination={true}
            enableScrollToTop={false}
            sorting={{ column: "deviceId" }}
            removeSortFrom={["checked", "userActions"]}
          >
            {(!selection?.category?.toLowerCase()?.includes("gateway")) &&
              <Grid container>
                <Grid item xs={12} md={"auto"} sx={{ display: "flex", alignItems: "center" }}>
                  <CustomLabel style={{ fontSize: "12px", margin: "0px", fontWeight: 700 }}>
                    {"Bulk Upgrade"}
                  </CustomLabel>
                  <Checkbox
                    checked={bulkUpgrade}
                    id="checkbox"
                    disabled={_.isEmpty(nextApplicableVersionList)}
                    sx={{ marginRight: "16px" }}
                    onClick={(e) => {
                      if (!bulkUpgrade === false) {
                        setNextApplicableVersion({ version: "", id: "" });
                      }
                      else {
                        setNextApplicableVersion({ version: nextApplicableVersionList[0]?.label, id: nextApplicableVersionList[0]?.fwId })
                      }
                      setBulkUpgrade((bulkUpgrade) => {
                        return !bulkUpgrade
                      })
                      // handleDayOfWeek(day?.label, day?.value)
                    }}
                    inputProps={{ "aria-label": "check-box" }}
                    // sx={{ '& .MuiSvgIcon-root': { fontSize: 14 } }}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} md={"auto"} sx={{ display: { xs: "block", sm: "flex" }, alignItems: "center" }}>
                  <CustomLabel
                    style={{ fontSize: "12px", margin: "0px 16px 0px 0px", fontWeight: 700 }}
                  >
                    {"Select Next Applicable Version"}
                  </CustomLabel>
                  <Box sx={{ minWidth: { xs: "100px", sm: "150px", md: "200px" }, maxWidth: { xs: "150px", sm: "250px" } }}>
                    <CustomDropDown
                      name={"category"}
                      value={nextApplicableVersion?.version}
                      isNoneAsItem={false}
                      disabled={bulkUpgrade === false}
                      list={nextApplicableVersionList}
                      onChange={(e) => {
                        const fw = nextApplicableVersionList?.find(v => v?.id === e?.target?.value) || { label: "", id: "" }
                        setNextApplicableVersion({ version: fw?.label, id: fw?.fwId })
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>}
          </CustomTable>
          {(!selection?.category?.toLowerCase()?.includes("gateway")) &&
            <StyledButton
              sx={{ float: "right", marginTop: "16px" }}
              disabled={bulkUpgrade === false || Object?.keys(selectedDevices)?.filter(d => selectedDevices[d])?.length === 0}
              onClick={(e) => {
                dispatch(fotaActions?.upgradeFirmware(Object?.keys(selectedDevices)?.filter(d => selectedDevices[d]), selection?.gateway, Number(nextApplicableVersion?.id)))
              }}
            >
              {"Upgrade Selected"}
            </StyledButton>}
        </DataNotFound>}
    </Box >
  )
};

FirmwareUpgrade.propTypes = {};

FirmwareUpgrade.defaultProps = {};

export default FirmwareUpgrade;
