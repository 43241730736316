import { authenticationTypes } from '../types';

// let user = localStorage.getItem('user');
// const initialState = user ? 
// { loggedIn: true, user: JSON.parse(JSON.stringify(user)) } 
// : { 
//     reqOTP: null, 
//     validateOTP:null,
//     resetPassword: null,
//     error:null    
//   };
  
const initialState = { 
    reqOTP: null, 
    validateOTP:null,
    resetPassword: null,
    error:null,
    loggedIn: null, 
    user: JSON.parse(localStorage.getItem('user') || "{}"),     
    authenticated:null,
    portal:JSON.parse(localStorage.getItem('miraie'))?.portal,
  };

export function authentication(state = initialState, action) {
    switch (action.type) {
        case authenticationTypes.LOGIN_REQUEST:
        case authenticationTypes.RESET_PASSWORD_REQUEST:       
            return {
                ...state,
                loading: true
            };
        case authenticationTypes.LOGIN_SUCCESS:
            return {
                ...state,
                ...action.payload,                
                loading: false,
            };
        case authenticationTypes.UPDATE_USER:
            return {
                ...state,
                user: action.payload,              
                loading: false,
            };
        case authenticationTypes.RESET_PASSWORD_SUCCESS:
            return {
                loggedIn: false,
                resetPassword: action?.payload,
                loading: false
            };
        case authenticationTypes.LOGIN_FAILURE:
        case authenticationTypes.RESET_PASSWORD_FAILURE:
            // console.log('error reducer==>', action?.payload);
            return {
                loading: false,
                error: action.payload
            };
        case authenticationTypes.LOGOUT:
            return {};
        default:
            return state
    }
}
