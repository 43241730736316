export const ticketingType = {
    GET_REQUEST: 'TICKET_GET_REQUEST',
    GET_TICKETS_SUCCESS: 'TICKET_GET_SUCCESS',
    GET_TICKETS_FAILURE: 'TICKET_GET_FAILURE',

    CREATE_TICKET: "CREATE_TICKET",
    CREATE_TICKETS_SUCCESS: 'TICKET_POST_CREATE_SUCCESS',
    CREATE_TICKETS_FAILURE: 'TICKET_POST_CREATE_FAILURE',

    UPDATE_TICKET: "UPDATE_TICKET",
    UPDATE_TICKETS_SUCCESS: 'UPDATE_TICKETS_SUCCESS',
    UPDATE_TICKETS_FAILURE: 'UPDATE_TICKETS_FAILURE',

    ADD_ATTACHMENTS: "ADD_ATTACHMENTS",
    ADD_ATTACHMENTS_SUCCESS: 'ADD_ATTACHMENTS_SUCCESS',
    ADD_ATTACHMENTS_FAILURE: 'ADD_ATTACHMENTS_FAILURE',

    DELETE_ATTACHMENTS: "DELETE_ATTACHMENTS",
    DELETE_ATTACHMENTS_SUCCESS: "DELETE_ATTACHMENTS_SUCCESS",
    DELETE_ATTACHMENTS_FAILURE: "DELETE_ATTACHMENTS_FAILURE",

    GET_ASSIGNEES: "GET_ASSIGNEES",
    GET_TICKETS_ASSIGNEES_SUCCESS: 'GET_TICKETS_ASSIGNEES_SUCCESS',
    GET_TICKETS_ASSIGNEES_FAILURE: 'GET_TICKETS_ASSIGNEES_FAILURE',

    GET_TICKETS_DETAILS_SUCCESS: "GET_TICKETS_DETAILS_SUCCESS",
    GET_TICKETS_DETAILS_FAILURE: "GET_TICKETS_DETAILS_FAILURE",

    POST_COMMENT: "POST_COMMENT",
    POST_COMMENT_SUCCESS: "POST_COMMENT_SUCCESS",
    POST_COMMENT_FAILURE: "POST_COMMENT_FAILURE",

    SUBSCRIBE_TICKET: "SUBSCRIBE_TICKET",
    SUBSCRIBE_TICKET_SUCCESS: "SUBSCRIBE_TICKET_SUCCESS",
    SUBSCRIBE_TICKET_FAILURE: "SUBSCRIBE_TICKET_FAILURE",

    UN_SUBSCRIBE_TICKET: "UN_SUBSCRIBE_TICKET",
    UN_SUBSCRIBE_TICKET_SUCCESS: "UN_SUBSCRIBE_TICKET_SUCCESS",
    UN_SUBSCRIBE_TICKET_FAILURE: "UN_SUBSCRIBE_TICKET_FAILURE",

    DELETE_COMMENT: "DELETE_COMMENT",
    DELETE_COMMENT_SUCCESS: "DELETE_COMMENT_SUCCESS",
    DELETE_COMMENT_FAILURE: "DELETE_COMMENT_FAILURE",

    EDIT_COMMENT: "EDIT_COMMENT",
    EDIT_COMMENT_SUCCESS: "EDIT_COMMENT_SUCCESS",
    EDIT_COMMENT_FAILURE: "EDIT_COMMENT_FAILURE",

    GET_STATISTICS: "GET_STATISTICS",
    GET_STATISTICS_SUCCESS: "GET_STATISTICS_SUCCESS",
    GET_STATISTICS_FAILURE: "GET_STATISTICS_FAILURE",

    ADD_ATTACHMENTS_TO_COMMENT: "ADD_ATTACHMENTS_TO_COMMENT",
    ADD_ATTACHMENTS_TO_COMMENT_SUCCESS: "ADD_ATTACHMENTS_TO_COMMENT_SUCCESS",
    ADD_ATTACHMENTS_TO_COMMENT_FAILURE: "ADD_ATTACHMENTS_TO_COMMENT_FAILURE",

    DELETE_ATTACHMENTS_TO_COMMENT: "DELETE_ATTACHMENTS_TO_COMMENT",
    DELETE_ATTACHMENTS_TO_COMMENT_SUCCESS: "DELETE_ATTACHMENTS_TO_COMMENT_SUCCESS",
    DELETE_ATTACHMENTS_TO_COMMENT_FAILURE: "DELETE_ATTACHMENTS_TO_COMMENT_FAILURE",

    GET_ATTACHMENTS_TO_COMMENT: "GET_ATTACHMENTS_TO_COMMENT",
    GET_ATTACHMENTS_TO_COMMENT_SUCCESS: "GET_ATTACHMENTS_TO_COMMENT_SUCCESS",
    GET_ATTACHMENTS_TO_COMMENT_FAILURE: "GET_ATTACHMENTS_TO_COMMENT_FAILURE",

    GET_ATTACHMENTS: "GET_ATTACHMENTS",
    GET_ATTACHMENTS_SUCCESS: "GET_ATTACHMENTS_SUCCESS",
    GET_ATTACHMENTS_FAILURE: "GET_ATTACHMENTS_FAILURE",

    GET_TICKET_TYPES: "GET_TICKET_TYPES",
    GET_TICKET_TYPES_SUCCESS: "GET_TICKET_TYPES_SUCCESS",
    GET_TICKET_TYPES_FAILURE: "GET_TICKET_TYPES_FAILURE",

    GET_TICKET_STATES: "GET_TICKET_STATES",
    GET_TICKET_STATES_SUCCESS: "GET_TICKET_STATES_SUCCESS",
    GET_TICKET_STATES_FAILURE: "GET_TICKET_STATES_FAILURE",

    GET_TICKET_SEVERITIES: "GET_TICKET_SEVERITIES",
    GET_TICKET_SEVERITIES_SUCCESS: "GET_TICKET_SEVERITIES_SUCCESS",
    GET_TICKET_SEVERITIES_FAILURE: "GET_TICKET_SEVERITIES_FAILURE",

    GET_TICKET_DETAILS: "GET_TICKET_DETAIL",
}