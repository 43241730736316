import CriticalIcon from '../assets/icons/critical-2.svg';
import MajorIcon from '../assets/icons/major-2.svg';
import MinorIcon from '../assets/icons/minor-2.svg';

export const alertConstants = {
    SUCCESS: 'ALERT_SUCCESS',
    ERROR: 'ALERT_ERROR',
    CLEAR: 'ALERT_CLEAR'
};

export const alertSeverity = {
    major: { icon: MajorIcon, label: "Major", color: "#E07B04" },
    minor: { icon: MinorIcon, label: "Minor", color: "#0062BC" },
    critical: { icon: CriticalIcon, label: "Critical", color: "#DD0606" },
    //to be removed
    high: { icon: MajorIcon, label: "Major", color: "#E07B04" },
};

export const alertStatus = {
    Acknowledged: { color: "#E07B04" },
    Resolved: { color: "#0F8D48" },
    Open: { color: "#0062BC" }
}
