import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    ControllerDropDown: {
        // width: '100%',
        "& .MuiSelect-select": {
            // padding: '0 31px 0 15px !important',
            height: "22px !important",
            padding: "3px 20px 3px 7px !important",
            fontSize: "0.75rem !important",
            lineHeight: "22px !important",
            minWidth: "max-content"
        },
        '& svg': {
            right: "-2px",
            height: "24px !important"
        }
    }
});
