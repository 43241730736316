/***
 *
 * Controller class for user.
 * @file StyledTabPanel.js
 * @description StyledTabPanel component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './StyledTabPanel.style.js';
import './StyledTabPanel.scss';
import { Box, Tab, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ImageWrapper from '../ImageWrapper/index.js';

const StyledTabPanel = ({ children, tabs = [], selectedIndex = 0, tabsStyle = {}, panelStyle = {}, onClick = () => { }, ...props }) => {
  const classes = useStyles()
  const [value, setValue] = React.useState(selectedIndex);

  const handleChange = (event, newValue) => {
    onClick(event, newValue);
    setValue(newValue);
  };

  useEffect(() => {
    setValue(selectedIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex])

  return (
    <Box className={classes?.StyledTabPanel || ""} style={{ ...tabsStyle }} data-testid="StyledTabPanel">
      <TabContext value={value}>
        <Box className={classes?.tabs || ""} sx={{ borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="tabPanel example">
            {tabs?.map((t, i) => {
              return (
                <Tab
                  sx={{ mx: 2 }}
                  key={"tab-" + i}
                  id={"tab-" + i}
                  label={
                    <Box sx={{ px: 0 }} style={{ textTransform: "capitalize" }}>
                      <ImageWrapper
                        alt={`${t?.label}`}
                        style={{
                          float: "left",
                          height: "19px",
                          width: "19px",
                          marginRight: "5px"
                        }}
                        src={`${t?.icon}`}
                      />
                      <Typography variant="caption" sx={{ lineHeight: "20px", display: "inline-block", fontWeight: 600, color: "#444" }}>{`${t?.label}`}</Typography>
                    </Box>
                  }
                />
              )
            })}
          </TabList>
        </Box>
        <TabPanel value={value} style={{ ...panelStyle }}>{children}</TabPanel>
      </TabContext>
    </Box>
  )
};

StyledTabPanel.propTypes = {};

StyledTabPanel.defaultProps = {};

export default StyledTabPanel;
