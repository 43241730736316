/***
 *
 * Controller class for user.
 * @file CustomTable.js
 * @description CustomTable component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import _ from 'lodash';
import { style } from './CustomTable.style.js';
import { styled } from '@mui/material/styles';
import { TableCell, TableRow, TableContainer, Paper, Table, TableHead, TableBody, Box, Pagination as TablePagination, Avatar, CardHeader, Collapse, TableSortLabel, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { formatedDate, getInitials, stringToColor, tableHelper } from '../../helpers';
import PopoverContainer from '../PopoverContainer/index.js';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { ReactComponent as SortingUp } from '../../assets/icons/sorting-up.svg';
import { ReactComponent as SortingDown } from '../../assets/icons/sorting-down.svg';
import { ReactComponent as SortingNone } from '../../assets/icons/sorting-none.svg';

import './CustomTable.scss';
import { visuallyHidden } from '@mui/utils';
import ImageWrapper from '../ImageWrapper/index.js';
import StyledPopup from '../StyledPopup/index.js';
import CustomDropDown from '../CustomDropDown/index.js';
import CustomTypography from '../CustomTypography/index.js';
import StyledSpinner from '../StyledSpinner/index.js';
import { ScrollToTop, getUrl, tableSort } from '../../services/util.service.js';
import StyledTextfield from '../StyledTextfield/index.js';

import ClearIcon from '@mui/icons-material/Clear';
import useWindowDimensions from '../../hooks/useWindowDimensions.js';
import StyledButton from '../StyledButton/index.js';



const Search = ({ data = [], filteredData = () => { }, columns = { id: 1, label: "title" }, searchValue = "", tempSearchValue = "", setTempSearchValue = () => { }, searchIn = [], placeholder = "Search...", onChange = () => { } }) => {
  const [value, setValue] = useState("");
  const [timer, setTimer] = useState(null);
  const searchData = (fieldquery) => {
    const query = fieldquery?.toLowerCase() || null;
    const includeKeys = (obj, keys) => {
      let includedKeys = [];
      // console.log("SearchBox step - 1 ", keys);
      if (keys && keys.length > 0) {
        // console.log("SearchBox step - 2 ", keys);
        keys?.forEach(k => {
          // console.log("SearchBox step - 3 ", obj, obj[k]);
          includedKeys[k] = obj?.hasOwnProperty(k) ? (typeof obj[k] === "string" ? obj[k]?.toLowerCase() : obj[k]?.toString()?.toLowerCase()) : "";
        })
      } else {
        // console.log("SearchBox step - 4 ", obj);
        includedKeys = obj;
      }

      return includedKeys;
    };
    // console.log("Search filter step 1 ------ ", fieldquery, data);
    // setValue(query);
    if (!query || (query && query === "")) {
      setTimeout(() => filteredData(data), 1000);
      // console.log("Search filter step 2 ------ ", fieldquery, data);
      // return data;
    } else {
      let fd = [];
      if (searchIn && searchIn.length > 0) {
        fd = data.filter(item => Object.values(includeKeys(item, searchIn)).find(q => q?.toLowerCase()?.includes(query)));
      } else {
        fd = data.filter(item => Object.values(item).find(q => typeof (q) === 'string' && q?.toLowerCase()?.includes(query)));
      }

      // console.log("Search filter step 3 ------ ", fieldquery, fd);
      // filteredData(fd);
      setTimeout(() => filteredData(fd), 200);
      // console.log('search==>',fd);
    }
  };
  const handleChange = (e) => {
    setValue(e.target.value);//value should not be null otherwise textbox value will not change
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      onChange(e, e?.target?.value);
      searchData(e.target.value);
    }, 400);
    setTimer(newTimer);
  }

  useEffect(() => {
    //filtering based on tempSearchValue if reload is done after an action
    handleChange({ target: { value: tempSearchValue || searchValue } })//filtering data if data changes or seachValue changes
    setTimeout(() => {
      setTempSearchValue(null);
    }, 1000);
  }, [data, searchValue])


  return (
    <StyledTextfield
      type="text"
      placeholder={placeholder}
      onChange={handleChange}
      value={value}
      fullWidth
      sx={{ '& .MuiOutlinedInput-root': { borderRadius: "4px", height: '33px', pr: '0' }, background: "#fff" }}
      InputProps={{
        endAdornment: (
          <>
            <StyledButton iconButton size='small' disabled={!_.isEmpty(value) ? false : true} sx={{ borderRadius: "3px" }} onClick={() => {
              setTimeout(() => {
                setValue("");
                onChange({}, "");
                searchData(null);
              }, 100);
            }} ><ClearIcon /></StyledButton>
          </>
        )
      }}
    />
  );
}

const ExpandableTableRow = ({ children, columnCount, expandableRow, expandComponent, row, rowIdentifier = null, expandedRows = {}, setExpandedRows = () => { }, ...otherProps }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (rowIdentifier && row[rowIdentifier] && expandedRows[row[rowIdentifier]]) {//checking if row was expanded 
      setIsExpanded(true);
    }
    else {
      setIsExpanded(false);// if expanded is false collapse the row
    }
  }, [row])
  // useEffect(() => {
  //   setIsExpanded(false);
  // }, [expandableRow, currentPage, order])

  return (expandableRow ? (
    <>
      <TableRow {...otherProps}>
        <TableCell padding="checkbox">
          <StyledButton iconButton onClick={() => {
            setIsExpanded(!isExpanded);
            //setting expanded variable based on rowIdentifier
            setExpandedRows({ ...expandedRows, [row[rowIdentifier]]: !isExpanded })
          }}>
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </StyledButton>
        </TableCell>
        {children}
      </TableRow>
      <TableRow>{/* <TableCell sx={{p: 0, m: 0}} padding="checkbox" />*/}
        <TableCell sx={{ p: 0, m: 0 }} colSpan={columnCount + 1}>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            {expandComponent}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  ) : (<TableRow {...otherProps}>{children}</TableRow>));
};

const CustomTableCell = styled(TableCell)(({ customStyle = style, props }) => ({ ...style.tableCell, ...customStyle, ...props }));

const CustomTableRow = styled(ExpandableTableRow)((style, props) => ({ ...style.tableRow, ...props }));

const ITEM_PER_PAGE = 10;

const renderImage = (image = {}) => {
  return (
    (() => {
      if (image?.path || image?.alt) {
        switch (image?.type) {
          case "avatar":
            return (
              <Avatar
                variant={image?.variant}
                src={image?.path}
                sx={image?.sx}
                style={{ backgroundColor: stringToColor(image?.alt), ...image?.style }}
                alt={image?.alt}
                children={getInitials(image?.alt)}
              />
            );
          default:
            return (
              <>
                <ImageWrapper
                  width={image?.width}
                  height={image?.height}
                  src={image?.path}
                  style={{ ...{ maxWidth: "30px", maxHeight: "30px" }, ...image?.style }}
                  alt={image?.alt}
                />
              </>
            )
        }
      }
    })()
  );
}

const render = (multiKeys = {}, row) => {
  // const multiKeys = {
  //   name: { type: "text", style: ""  },
  //   userImage: { type: "image|avatar", style: {}, sx: {} }
  // }

  // console.log("multiKeys ---- ", multiKeys, row)
  let html = [];
  for (let key in multiKeys) {
    if (key === "label") { continue; }
    if (multiKeys[key]?.type === "text") {
      html.push(<Box key={"renddd-" + key} component="span" style={{ ...(multiKeys[key]?.style || {}), marginRight: "5px" }}><CustomTypography className="a3333" content={row[key]} component="span" /></Box>);
    } else if (multiKeys[key]?.type === "avatar") {
      html.push(
        multiKeys[key]?.url ? <Link key={"renddd-" + key} className="imageLink" to={getUrl(multiKeys[key]?.url, row, multiKeys[key]?.urlParams)}>
          {renderImage({ ...multiKeys[key], path: row[key], alt: multiKeys[key]?.alt || row[multiKeys[key]["key"]] })}
        </Link> : renderImage({ ...multiKeys[key], path: row[key], alt: multiKeys[key]?.alt || row[multiKeys[key]["key"]] })
      );
    } else if (multiKeys[key]?.type === "image") {
      html.push(
        multiKeys[key]?.url ? <Link key={"renddd-" + key} className="imageLink" to={getUrl(multiKeys[key]?.url, row, multiKeys[key]?.urlParams)}>
          {renderImage({ ...multiKeys[key], path: row[key], alt: multiKeys[key]?.alt || row[multiKeys[key]["key"]] })}
        </Link> : renderImage({ ...multiKeys[key], path: row[key], alt: multiKeys[key]?.alt || row[multiKeys[key]["key"]] })
      );
    } else if (multiKeys[key]?.type === "link") {
      html.push(<Link key={"renddd-" + key} className="link" to={getUrl(multiKeys[key]?.url, row, multiKeys[key]?.urlParams)}>{row[key]?.text || row[key]}</Link>);
    } else {
      html.push(<Box key={"renddd-" + key} component="span" style={{ ...(multiKeys[key]?.style || {}), marginRight: "5px" }}><CustomTypography className="a4444" content={row[key]} component="span" /></Box>);
    }
  }

  return html;
}

// renderTextImge(headerList[key], key === headerList[key]?.pathKey ? null : row[key], headerList[key]?.pathKey ? row[headerList[key]?.pathKey] : null)
const renderTextImge = (col = {}, text, path = null) => {
  let image = col?.image || col?.icon;
  if (path) { image["path"] = path; }

  return (
    <>
      {image?.type === "avatar" ? (
        <CardHeader
          avatar={
            <Avatar alt={image?.alt || (path || image?.path)} src={path || image?.path} />
          }
          title={text}
        />
      ) : (
        <Box style={{ display: (!image?.position || image?.position === "left" || image?.position === "right") ? "flex" : "block", ...style }}>
          {image && (!image?.position || image?.position === "left" || image?.position === "top") &&
            <Box sx={{
              width: image?.position === "top" ? "100%" : "fit-content",
              paddingRight: "5px"
            }}>{image && renderImage(image)}</Box>
          }
          {text && <Box sx={{ display: "block", marginY: "auto " }}><CustomTypography className="a5555" content={text} variant="body2" /></Box>}
          {image && (image?.position === "bottom" || image?.position === "right") &&
            <Box sx={{
              width: image?.position === "bottom" ? "100%" : "fit-content",
              paddingLeft: "5px"
            }}>{image && renderImage(image)}</Box>
          }
        </Box>
      )}
    </>

  )
}

const ToolTipComp = ({ component: Component, row, ...props }) => {
  const [data, setData] = useState(row);
  useEffect(() => {
    setData(row);
  }, [data])
  return <Component data={data} {...props} />;
}

const ColumnComp = ({ component: Component, row, ...props }) => {
  const [data, setData] = useState(row);
  useEffect(() => {
    setData(row);
  }, [data])
  return <Component data={data} {...props} />;
}

const CustomTable = ({
  children,
  expandable = false,
  headerList = {},
  cellMatrix = [],
  pagination = false,
  searching = false, // {reset: true, columns: ["name",...]}
  searchFor = null,
  onSync,
  removeSortFrom = ["userActions"], // add column name on which you don't want to apply sorting like "userActions" right now on "userActions" sorting is disabled.
  // loader = false,
  rowIdentifier = null,
  enableLoading = false,
  enableScrollToTop = true,
  sorting = null, //{ column: null, order: "asc" }  
  rowConfig = null, //{ highlight: { bgKey: "<column name>", bgValue: "<column value>", bgColor: "#ff0000" } }
  sx = {},
  ...props
}) => {
  const [data, setData] = useState(null);
  const [paginatedData, setPaginatedData] = useState(null);
  const [page, setPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(ITEM_PER_PAGE);
  const [filterData, setfilterData] = useState(null);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [colToolTip, setColToolTip] = useState(null)
  const [showToolTip, setShowToolTip] = useState(null);
  // const [openNodeAction, setOpenNodeAction] = useState();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(null);
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState({ show: false });
  const [searchStr, setSearchStr] = useState("");
  const [tempSearchStr, setTempSearchStr] = useState("");//temp var to hold search value when action is performed and search value needs to be preserved
  const [tableLoader, setTableLoader] = useState(true);
  const [tableRowLoader, setTableRowLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const windowDimensions = useWindowDimensions();
  const [expandedRows, setExpandedRows] = useState({});


  // const createSortHandler = (property) => (event) => {
  //   onRequestSort(event, property);
  // };


  // const handleClick = (event, act, data, params) => {

  // }

  const handlePageChange = (e, page) => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 400)
    setPage(page);
    data?.jump(page);
    if (enableScrollToTop) {
      ScrollToTop();
    }
  }

  const openToolTip = (event, toolTip, data) => {
    // console.log(event.currentTarget, toolTip, data);
    if (toolTip) {
      setShowToolTip(event.currentTarget);
      setColToolTip({ toolTip, data });
    }

  }

  const closeToolTip = (event) => {
    setShowToolTip(null);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const Contain = (key, value) => {
    if (key.includes(value)) {
      return key;
    } else {
      return value;
    }
  }

  // useEffect(() => {
  //   setLoader(true);
  // }, [])

  useEffect(() => {
    setfilterData(cellMatrix || []);
    // cellMatrix=cellMatrix?.map((d, ind) => {
    //   d.rowId = ind;//adding rowId to identify each row
    //   return d;
    // }) || []
    closeToolTip(null);
    setPage(1);
    setCurrentPage(1);
    setItemPerPage(ITEM_PER_PAGE);
    setExpandedRows({})
    // setTableLoader(true);
    // // console.log("TABLLEE", cellMatrix);
    // if (cellMatrix) {
    setTimeout(() => {
      setTableLoader(false);
    }, 800)
    // }
    setTableRowLoader(false);
  }, [cellMatrix])

  /////// normal data useEffect //////
  // useEffect(() => {
  //   setData(null);
  //   setTimeout(() => {
  //     if (filterData && Array.isArray(filterData)) { setCount(!_.isEmpty(filterData) ? Math.ceil((filterData?.length || 0) / itemPerPage) : 1); }
  //     if (Array.isArray(filterData) && pagination) {
  //       setData(tableHelper?.pagination(filterData, itemPerPage, currentPage, setCurrentPage));
  //     } else {
  //       setData({
  //         currentData: () => {
  //           return Array.isArray(filterData) ? filterData : [];
  //         }
  //       });
  //     }
  //   }, 0);
  // }, [filterData, currentPage, itemPerPage]);

  useEffect(() => {
    // else{
    //   setOrderBy(null);
    //   setOrder("asc")
    // }
    // if (!_.isEmpty(data)) {
    setPaginatedData(data?.currentData() || []);
    // }
  }, [data, sorting])


  ///// filterData useEffect ///////
  useEffect(() => {
    if (pagination) {
      setCount(Math.ceil(filterData?.length / itemPerPage));
      setData(tableHelper.pagination(filterData, itemPerPage, currentPage, setCurrentPage));
    } else {
      setData({
        currentData: () => { return filterData }
      })
    }
  }, [filterData, currentPage, itemPerPage])


  // const handleConfirmationAction = (event, data, showPopup = false, cb) => {
  //   if (showPopup) {
  //     setOpenConfirmationPopup({ show: true, fun: { name: cb, params: { event, data } } });
  //   } else {
  //     cb(event, data);
  //   }
  // }

  const handleConfirmation = (confirmed = false) => {
    if (confirmed) {
      openConfirmationPopup?.fun?.name(openConfirmationPopup?.fun?.params?.event, openConfirmationPopup?.fun?.params?.data);
      setOpenConfirmationPopup({ show: false, fun: null });
    } else {
      setOpenConfirmationPopup({ show: false, fun: null });
    }
  }

  const rowsPerPage = (e) => {
    // console.log('rows per page===>', e.target.value);
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 400)
    setPage(1);
    data?.jump(1);
    setItemPerPage(Number(e.target.value));
    if (enableScrollToTop) {
      ScrollToTop();
    }
  }

  // const showContent = (content, colConfig, row) => {
  //   if (colConfig?.toolTip) {
  //     return <Box sx={{ display: "inline-block" }}
  //       onMouseEnter={(event) => openToolTip(event, colConfig?.toolTip, row)}
  //       onMouseLeave={closeToolTip}
  //     >{content}</Box>
  //   } else {
  //     return content;
  //   }
  // }

  // useEffect(() => {
  //   // console.log('Searching in useeffect2----- ', searching, filterData)
  //   setfilterData(cellMatrix);
  //   setSearchStr("");
  // }, [searching])

  useEffect(() => {
    if (sorting) {
      setOrderBy(sorting?.column || (headerList && Object.keys(headerList)[0]) || "");
      setOrder(sorting?.order || "asc");
    }
    else {
      setOrderBy(null);
      setOrder("asc")
    }
    if (searching?.reset) {
      setSearchStr("");//clearing search string when table is reloaded
    }
  }, [headerList]);

  useEffect(() => {
    if (enableLoading) {
      setTableLoader(true);
      if (cellMatrix) {
        setTimeout(() => {
          setTableLoader(false);
        }, 1000)
      }
    }
  }, [enableLoading])

  return (
    <Box>
      {/* {JSON.stringify(searchStr)} */}
      {(searching || onSync || children) &&
        <Box sx={{ pb: 1.5, display: 'flex' }}>
          <Box sx={{ width: "100%" }}>{children}</Box>
          <Box sx={{ display: "flex", justifyContent: 'flex-end', alignItems: 'end' }}>
            {searching &&
              <Search data={cellMatrix} placeholder="Filter" filteredData={(res) => { setfilterData(res) }} searchValue={searchStr} tempSearchValue={tempSearchStr} setTempSearchValue={setTempSearchStr} searchIn={searching?.columns} width="200px" inline onChange={(e, value) => {
                setSearchStr(value);
                setPage(1);
                // setTableLoader(true);
                // setTimeout(() => {
                //   setTableLoader(false);
                // }, 1000)
                setLoader(true);
                setTimeout(() => {
                  setLoader(false);
                }, 1000)
                data?.jump(1);
              }} />
            }
            {onSync &&
              <StyledButton onClick={(event) => onSync(event)} variant='outlined' sx={{ ml: 1 }}>Sync</StyledButton>
            }
          </Box>
        </Box>
      }

      {/* <pre>{JSON.stringify(headerList, null, 2)}</pre> */}
      {/* {orderBy} -- {order} -- {sorting?.column} */}
      <Box sx={{ position: 'relative', minHeight: 200 }}>
        {(tableLoader) &&
          <Box sx={{ position: 'absolute', top: 0, bottom: 0, width: "100%", backgroundColor: "#f6faf8", zIndex: 1011 }}>
            <StyledSpinner sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          </Box>
        }
        <TableContainer component={Paper} sx={{ boxShadow: 'none', borderRadius: 0, overflowX: 'auto', minHeight: 70 }} data-testid="CustomTable">
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                {/* {expandable && <CustomTableCell></CustomTableCell>}*/}
                {headerList && Array.isArray(Object.keys(headerList)) && Object.keys(headerList).length > 0 && Object.keys(headerList)?.map((key, index100) => (
                  <CustomTableCell
                    colSpan={expandable && index100 === 0 ? 2 : 1}
                    key={"hff-" + index100}
                    style={{ textAlign: (key === "userActions" ? "right" : "left"), ...headerList[key]?.style, [`&.MuiTableCellHead`]: { ...sx?.heading } }}
                    sortDirection={([...removeSortFrom, ...["userActions"]]?.includes(key) && orderBy === key) ? order : false}
                  >
                    {[...removeSortFrom, ...["userActions"]]?.includes(key) ? (
                      headerList[key]?.label
                    ) : (
                      <TableSortLabel
                        active={orderBy === (headerList[key]["sortKey"] ? headerList[key]["sortKey"] : key)}
                        direction={orderBy === (headerList[key]["sortKey"] ? headerList[key]["sortKey"] : key) ? order : 'asc'}
                        onClick={(event) => handleRequestSort(event, headerList[key]["sortKey"] ? headerList[key]["sortKey"] : key)}
                        sx={{ display: "flex", alignItems: "baseline" }}
                      >
                        {/* {key + "-" + orderBy + "===" + key + "-" + headerList[key]["sortKey"] + "  " + JSON.stringify(key + "-" + orderBy === key + "-" + headerList[key]["sortKey"]) + "  " + JSON.stringify(orderBy === key) + "  "} */}

                        {headerList[key]?.label}

                        {/* {const aaa = key?.includes("multiKeys") ? (key + "-" + orderBy === key + "-" + headerList[key]["sortKey"]) : orderBy === key} */}
                        {/* {(key?.includes("multiKeys") ? key + "-" + orderBy : orderBy) === (key?.includes("multiKeys") ? key + "-" + headerList[key]["sortKey"] : key) ? ( */}
                        {/* key?.includes("multiKeys") && headerList[key]["sortKey"] ? (key + "-" + orderBy === key + "-" + headerList[key]["sortKey"]) :  */}
                        {(headerList[key]["sortKey"] ? (key + "-" + orderBy === key + "-" + headerList[key]["sortKey"]) : orderBy === key) ? (
                          <span>
                            {order === 'desc' ? <SortingDown style={{ marginLeft: "8px" }} /> : <SortingUp style={{ marginLeft: "8px" }} />}
                            <Box component="span" sx={visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                          </span>
                        ) : (<span><SortingNone style={{ marginLeft: "8px" }} /></span>)}
                      </TableSortLabel>
                    )}
                  </CustomTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody style={{ position: "relative" }}>
              {(!tableLoader && loader) &&
                <Box sx={{ position: 'absolute', top: 0, bottom: 0, width: "100%", backgroundColor: "#fffffff0", zIndex: 1002 }}>
                  <StyledSpinner sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
                </Box>
              }
              {(!tableLoader && _.isEmpty(paginatedData)) && (
                <CustomTableRow
                  key={'no-records'}
                  row={{}}
                >
                  <CustomTableCell colSpan={Object.keys(headerList)?.length + (expandable ? 1 : 0)} sx={{ textAlign: "center" }}>No Records Found</CustomTableCell>
                </CustomTableRow>
              )}
              {(!_.isEmpty(paginatedData)) && (
                tableSort(paginatedData, { order, orderBy })?.map((row, index2) => (
                  <CustomTableRow
                    expandedRows={expandedRows}
                    setExpandedRows={setExpandedRows}
                    row={row}
                    key={"ctrowee-" + index2}
                    className={index2 % 2 > 0 ? "stripedRow" : ""}
                    style={{ position: "relative", backgroundColor: (rowConfig?.highlight?.bgValue && rowConfig?.highlight?.bgValue !== "" && rowConfig?.highlight?.bgValue === row[rowConfig?.highlight?.bgKey]) ? (rowConfig?.highlight?.bgColor || "rgb(247 57 57 / 38%)") : "" }}
                    expandableRow={(rowConfig?.disabled && rowConfig?.disabled?.value === row[rowConfig?.disabled?.key]) ? false : expandable}
                    columnCount={Object.keys(headerList)?.length}
                    rowIdentifier={rowIdentifier}
                    expandComponent={(rowConfig?.disabled && rowConfig?.disabled?.value === row[rowConfig?.disabled?.key]) ? <></> : <ColumnComp component={expandable?.component} data={row} {...props} />}>
                    {(rowConfig?.disabled && rowConfig?.disabled?.value === row[rowConfig?.disabled?.key] && expandable) &&
                      <CustomTableCell key={"bddssss-" + index2} sx={{ minWidth: "48px" }}>
                        {/* <Box sx={{ position: 'absolute', left: 0, top: 0, bottom: 0, width: "100%", backgroundColor: "rgba(0, 0, 0, 0.3)", zIndex: 1 }}></Box> */}
                      </CustomTableCell>
                    }
                    {headerList && Array.isArray(Object.keys(headerList)) && Object.keys(headerList).length > 0 && Object.keys(headerList)?.map((key, index3) => (
                      <CustomTableCell key={"bddss-" + index3} style={{ ...headerList[key]?.style }} sx={{ position: "relative" }}>
                        {/* {JSON.stringify(row?.loading)} */}
                        {(tableRowLoader === index2 || row?.loading) &&
                          <Box sx={{ position: 'absolute', left: 0, top: 0, bottom: 0, width: "100%", backgroundColor: "rgba(255, 255, 255, 0.88)", zIndex: 1001 }}>
                            {index3 === 0 &&
                              <StyledSpinner sx={{ position: "absolute", top: "50%", left: "20px", transform: "translate(-0px, -50%)" }} size="20px" />
                            }
                          </Box>
                        }

                        {/* {JSON.stringify(rowConfig) + " -- " + JSON.stringify(row[rowConfig?.disabled?.key])} */}
                        {/* -------------------------------- Table columns ----------------------------- */}
                        <Box
                          onMouseEnter={(event) => openToolTip(event, headerList[key]?.toolTip, row)}
                          onMouseLeave={closeToolTip}
                          sx={{ display: "inline-block", width: "auto", height: "fit-content" }}
                        >
                          {(() => {
                            switch (key) {

                              /* --------------------------------- Action column start ---------------------------- */
                              case "userActions":
                                return (
                                  <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                    {headerList[key]?.actions?.map((act, ind) => {
                                      return (
                                        <Box sx={{ ml: 0.5, display: ((typeof act?.hidden === "function" ? act?.hidden(row) : act?.hidden) ? 'none' : 'initial') }} key={"actss-" + ind} className="action-buttons" >
                                          {act?.component ? (
                                            <Box onClick={act?.disabled ? null : (event) => {
                                              if (act?.loading) {
                                                setTableRowLoader(index2);
                                                setTimeout(() => {
                                                  if (!row?.loading) {
                                                    setTableRowLoader(null);
                                                  }
                                                }, 1000);
                                              }
                                              act?.onClick && act?.onClick(event, row)
                                            }}
                                              {...(() => {
                                                if (act?.toolTip || act?.label) {
                                                  return {
                                                    onMouseEnter: (event) => (openToolTip(event, { content: act?.toolTip || act?.label || "" }, row)),
                                                    onMouseLeave: closeToolTip
                                                  }
                                                }
                                              })()}
                                            >
                                              {typeof act?.component === "function" ? act?.component({
                                                data: row,
                                                onSave: () => {
                                                  setLoader(true);
                                                  setTimeout(() => { setLoader(false) }, 2000)
                                                  setTempSearchStr(searchStr);//setting temp var with search value so that it is preserved
                                                }
                                              }) : act?.component}
                                            </Box>
                                          ) : (
                                            <StyledButton
                                              iconButton
                                              className={act?.type}
                                              onClick={(event) => {
                                                if (act?.loading) {
                                                  setTableRowLoader(index2);
                                                  setTimeout(() => {
                                                    if (!row?.loading) {
                                                      setTableRowLoader(null);
                                                    }
                                                  }, 1000);
                                                }
                                                // setTempSearchStr(searchStr);//setting temp var with search value so that it is preserved
                                                act?.onClick && act?.onClick(event, row)
                                              }}
                                              disabled={typeof act?.disabled === "function" ? act?.disabled(row) : act?.disabled}
                                              sx={{ padding: "5px" }}
                                              {...(() => {
                                                if (act?.toolTip || act?.label) {
                                                  return {
                                                    tooltip: act?.label ? (typeof act?.label === "function" ? act?.label(row) : act?.label) : (typeof act?.toolTip === "function" ? act?.toolTip(row) : act?.toolTip),
                                                    // onMouseEnter: (event) => (openToolTip(event, { content: act?.toolTip || act?.label || "" }, row)),
                                                  }
                                                }
                                              })()}
                                            >
                                              <ImageWrapper className="action-icons pointer" src={typeof act?.icon === "function" ? act?.icon(row) : act?.icon || `/image/icons/${act?.type}.svg`} alt={act?.label} />
                                            </StyledButton>
                                          )}
                                        </Box>
                                      )
                                    })}
                                  </Box>
                                );

                              /* --------------------------------- Action column end ---------------------------- */

                              /* --------------------------------- Multikeys columns start ---------------------------- */
                              case Contain(key, "multiKeys"):
                                return <Box sx={{ display: "flex", alignItems: "center" }}>{render(headerList?.multiKeys, row)}</Box>;
                              /* --------------------------------- Multikeys columns end ---------------------------- */

                              /* --------------------------------- Other columns start ---------------------------- */
                              default:
                                return (() => {
                                  switch (headerList[key]?.type) {
                                    case "link":
                                      return (
                                        <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                                          {(headerList[key]?.image && (
                                            <ImageWrapper src={row[key]?.img || headerList[key]?.image?.img} alt='lk-img' style={headerList[key]?.image?.style} />
                                          ))}
                                          <Link className="link" to={getUrl(headerList[key]?.url, row, headerList[key]?.urlParams)}>{row[key]?.text || row[key]}</Link>
                                        </Box>
                                      );
                                    case "list":
                                      return (
                                        <CustomTypography className="a1111" variant="caption" content={row[key]?.join(headerList[key]?.separator)} />
                                      );
                                    case "html":
                                      return (
                                        <Box>
                                          {headerList[key]?.content}
                                        </Box>
                                      );
                                    case "component":
                                      return (
                                        <ColumnComp component={headerList[key]?.component} onChange={headerList[key]?.onChange} onClick={headerList[key]?.onClick} data={row} customData={headerList[key]?.customData} />
                                      );
                                    case "date":
                                    case "datetime":
                                    case "dateTime":
                                    case "DateTime":
                                      return formatedDate({ date: row[key], format: headerList[key]?.format, locale: headerList[key]?.locale });
                                    default:
                                      return renderTextImge(headerList[key], (key === headerList[key]?.pathKey && !headerList[key]?.displayText) ? null : row[key], headerList[key]?.pathKey ? row[headerList[key]?.pathKey] : null)
                                  }
                                })();
                              /* --------------------------------- Other column end ---------------------------- */
                            }
                          })()}
                        </Box>
                      </CustomTableCell>
                    ))}
                  </CustomTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {pagination && !_.isEmpty(paginatedData) &&
          <Grid container sx={{ mt: 2, justifyContent: "space-between", alignItems: "center" }}>
            <Grid item xs={12} sm="auto">
              <Box display={'flex'} alignItems='center'>
                <CustomTypography className="a2222" content="Rows per page" styles={{ marginRight: '5px' }} size={15} />
                <CustomDropDown
                  value={itemPerPage}
                  name="pagesdropdown"
                  onChange={rowsPerPage}
                  onOpen={null}
                  isNoneAsItem={false}
                  customsx={{
                    minWidth: "40px",
                    width: "max-content",
                    ...style.rowsPerPage
                  }}
                  customdropsx={{ width: 'max-content', minWidth: "40px", pr: '10px' }}
                  list={[{ id: '5', label: '5' }, { id: '10', label: '10' }, { id: '15', label: '15' }, { id: '25', label: '25' }, { id: '50', label: '50' }, { id: '100', label: '100' }]}>
                </CustomDropDown>
              </Box>
            </Grid>
            <Grid item xs={12} sm="auto" mt={{ xs: 2, sm: 0 }} sx={{ justifyContent: "center", display: "flex" }}>
              <TablePagination
                count={count}
                sx={{ ul: style.ul }}
                page={page}
                size={'medium'}
                variant={'text'}
                onChange={handlePageChange}
              />
            </Grid>
          </Grid>
        }
      </Box>

      <PopoverContainer anchorEl={showToolTip} onClose={() => setShowToolTip(null)}>
        {colToolTip?.toolTip?.type && colToolTip?.toolTip?.type === "component" ? (
          <ToolTipComp component={colToolTip?.toolTip?.content} label={colToolTip?.toolTip?.label} data={colToolTip?.data} />
        ) : (
          <Box sx={{ textAlign: "center" }}>
            {
              typeof colToolTip?.toolTip?.content === "function" ?
                colToolTip?.toolTip?.content(colToolTip?.data) :
                colToolTip?.toolTip?.content}
          </Box>
        )}
      </PopoverContainer>
      <StyledPopup open={openConfirmationPopup.show} onClose={() => setOpenConfirmationPopup({ show: false, fun: null })} state="timeout"
        data={{
          content: <p>Are you sure?<br /> You want to perform this operation.</p>,
          actions: [
            {
              id: "1001",
              type: "button",
              label: "Yes",
              onClick: (event, data) => {
                handleConfirmation(true)
              }
            },
            {
              id: "1002",
              type: "button",
              label: "No",
              onClick: (event, data) => {
                handleConfirmation(false);
              }
            }
          ]
        }} />

    </Box >
  )
};

CustomTable.propTypes = {};

CustomTable.defaultProps = {};

export default CustomTable;

