import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    ResetCred: {
        width: "100%",
        "& .btn": {
            width: "100%",
            minHeight:"45px"
        }
    }
});
