
export const deviceTypes = {

    ///// GATEWAYS
    GET_REQUEST: 'DEVICE_GET_REQUEST',
    GET_SUCCESS: 'DEVICE_GET_SUCCESS',
    GET_FAILURE: 'DEVICE_GET_FAILURE',
    GET_MQTT_TOPIC_REQUEST: 'MQTT_TOPIC_GET_REQUEST',
    GET_MQTT_TOPIC_SUCCESS: 'MQTT_GET_TOPIC_SUCCESS',
    GET_MQTT_TOPIC_FAILURE: 'MQTT_GET_TOPIC_FAILURE',

    GET_DEVICETREE_SUCCESS: 'GET_DEVICETREE_SUCCESS',

    GET_ALL_GATEWAYS_REQUEST: 'GET_ALL_GATEWAYS_REQUEST',
    GET_ALL_GATEWAYS_SUCCESS: 'GET_ALL_GATEWAYS_SUCCESS',
    GET_ALL_GATEWAYS_FAILURE: 'GET_ALL_GATEWAYS_FAILURE',

    GET_GATEWAY_REQUEST: 'GET_GATEWAY_REQUEST',
    GET_GATEWAY_SUCCESS: 'GET_GATEWAY_SUCCESS',
    GET_GATEWAY_FAILURE: 'GET_GATEWAY_FAILURE',

    ADD_GATEWAY_REQUEST: 'ADD_GATEWAY_REQUEST',
    ADD_GATEWAY_SUCCESS: 'ADD_GATEWAY_SUCCESS',
    ADD_GATEWAY_FAILURE: 'ADD_GATEWAY_FAILURE',

    UPDATE_GATEWAY_REQUEST: 'UPDATE_GATEWAY_REQUEST',
    UPDATE_GATEWAY_SUCCESS: 'UPDATE_GATEWAY_SUCCESS',
    UPDATE_GATEWAY_FAILURE: 'UPDATE_GATEWAY_FAILURE',

    DELETE_GATEWAY_REQUEST: 'DELETE_GATEWAY_REQUEST',
    DELETE_GATEWAY_SUCCESS: 'DELETE_GATEWAY_SUCCESS',
    DELETE_GATEWAY_FAILURE: 'DELETE_GATEWAY_FAILURE',

    //// controllers ////
    GET_ALL_CONTROLLERS_REQUEST: 'GET_ALL_CONTROLLERS_REQUEST',
    GET_ALL_CONTROLLERS_SUCCESS: 'GET_ALL_CONTROLLERS_SUCCESS',
    GET_ALL_CONTROLLERS_FAILURE: 'GET_ALL_CONTROLLERS_FAILURE',

    GET_CONTROLLER_REQUEST: 'GET_CONTROLLER_REQUEST',
    GET_CONTROLLER_SUCCESS: 'GET_CONTROLLER_SUCCESS',
    GET_CONTROLLER_FAILURE: 'GET_CONTROLLER_FAILURE',

    ADD_CONTROLLER_REQUEST: 'ADD_CONTROLLER_REQUEST',
    ADD_CONTROLLER_SUCCESS: 'ADD_CONTROLLER_SUCCESS',
    ADD_CONTROLLER_FAILURE: 'ADD_CONTROLLER_FAILURE',

    UPDATE_CONTROLLER_REQUEST: 'UPDATE_CONTROLLER_REQUEST',
    UPDATE_CONTROLLER_SUCCESS: 'UPDATE_CONTROLLER_SUCCESS',
    UPDATE_CONTROLLER_FAILURE: 'UPDATE_CONTROLLER_FAILURE',

    DELETE_CONTROLLER_REQUEST: 'DELETE_CONTROLLER_REQUEST',
    DELETE_CONTROLLER_SUCCESS: 'DELETE_CONTROLLER_SUCCESS',
    DELETE_CONTROLLER_FAILURE: 'DELETE_CONTROLLER_FAILURE',

    ///// devices//////
    GET_ALL_DEVICES_REQUEST: 'GET_ALL_DEVICES_REQUEST',
    GET_ALL_DEVICES_SUCCESS: 'GET_ALL_DEVICES_SUCCESS',
    GET_ALL_DEVICES_FAILURE: 'GET_ALL_DEVICES_FAILURE',
    CLEAR_ALL_DEVICES: 'CLEAR_ALL_DEVICES',

    GET_DEVICE_REQUEST: 'GET_DEVICE_REQUEST',
    GET_DEVICE_SUCCESS: 'GET_DEVICE_SUCCESS',
    GET_DEVICE_FAILURE: 'GET_DEVICE_FAILURE',


    GET_PARENT_DEVICE_REQUEST: 'GET_PARENT_DEVICE_REQUEST',
    GET_PARENT_DEVICE_SUCCESS: 'GET_PARENT_DEVICE_SUCCESS',
    GET_PARENT_DEVICE_FAILURE: 'GET_PARENT_DEVICE_FAILURE',

    MOVE_DEVICE_REQUEST: 'MOVE_DEVICE_REQUEST',
    MOVE_DEVICE_SUCCESS: 'MOVE_DEVICE_SUCCESS',
    MOVE_DEVICE_FAILURE: 'MOVE_DEVICE_FAILURE',

    ADD_DEVICE_REQUEST: 'ADD_DEVICE_REQUEST',
    ADD_DEVICE_SUCCESS: 'ADD_DEVICE_SUCCESS',
    ADD_DEVICE_FAILURE: 'ADD_DEVICE_FAILURE',

    UPDATE_DEVICE_REQUEST: 'UPDATE_DEVICE_REQUEST',
    UPDATE_DEVICE_SUCCESS: 'UPDATE_DEVICE_SUCCESS',
    UPDATE_DEVICE_FAILURE: 'UPDATE_DEVICE_FAILURE',

    // UPDATE_DEVICE_FW_STATUS: 'UPDATE_DEVICE_FW_STATUS',

    DELETE_DEVICE_REQUEST: 'DELETE_DEVICE_REQUEST',
    DELETE_DEVICE_SUCCESS: 'DELETE_DEVICE_SUCCESS',
    DELETE_DEVICE_FAILURE: 'DELETE_DEVICE_FAILURE',

    MOVE_REQUEST: 'MOVE_DEVICE_REQUEST',
    MOVE_SUCCESS: 'MOVE_DEVICE_SUCCESS',
    MOVE_FAILURE: 'MOVE_DEVICE_FAILURE',

    GET_DEVICES_BY_SPACEID_REQUEST: 'GET_DEVICES_BY_SPACEID_REQUEST',
    GET_DEVICES_BY_SPACEID_SUCCESS: 'GET_DEVICES_BY_SPACEID_SUCCESS',
    GET_DEVICES_BY_SPACEID_FAILURE: 'GET_DEVICES_BY_SPACEID_FAILURE',

    GET_DEVICE_MODELS_REQUEST: 'GET_DEVICE_MODELS_REQUEST',
    GET_DEVICE_MODELS_SUCCESS: 'GET_DEVICE_MODELS_SUCCESS',
    GET_DEVICE_MODELS_FAILURE: 'GET_DEVICE_MODELS_FAILURE',

    GET_DEVICE_CATEGORIES_REQUEST: "GET_DEVICE_CATEGORIES_REQUEST",
    GET_DEVICE_CATEGORIES_SUCCESS: "GET_DEVICE_CATEGORIES_SUCCESS",
    GET_DEVICE_CATEGORIES_FAILURE: "GET_DEVICE_CATEGORIES_FAILURE",

    UPDATE_DEVICE_STATUS: "UPDATE_DEVICE_STATUS",
    UPDATE_CONTROLLER_STATUS: "UPDATE_CONTROLLER_STATUS",
    UPDATE_CONNECTIVITY_STATUS: "UPDATE_CONNECTIVITY_STATUS",
    UPDATE_CONNECTIVITY_LOST_STATUS: "UPDATE_CONNECTIVITY_LOST_STATUS",



    UPDATE_DEVICE_CONFIGURATION_REQUEST: "UPDATE_DEVICE_CONFIGURATION_REQUEST",
    UPDATE_DEVICE_CONFIGURATION_SUCCESS: "UPDATE_DEVICE_CONFIGURATION_SUCCESS",
    UPDATE_DEVICE_CONFIGURATION_FAILURE: "UPDATE_DEVICE_CONFIGURATION_FAILURE",

    //For getting list of details of all the devices provided only deviceIds
    GET_DETAILED_DEVICE_LIST_REQUEST: 'GET_DETAILED_DEVICE_LIST_REQUEST',
    GET_DETAILED_DEVICE_LIST_SUCCESS: 'GET_DETAILED_DEVICE_LIST_SUCCESS',
    GET_DETAILED_DEVICE_LIST_FAILURE: 'GET_DETAILED_DEVICE_LIST_FAILURE',

    //For getting device logs
    GET_DEVICE_LOGS_REQUEST: 'GET_DEVICE_LOGS_REQUEST',
    GET_DEVICE_LOGS_SUCCESS: 'GET_DEVICE_LOGS_SUCCESS',
    GET_DEVICE_LOGS_FAILURE: 'GET_DEVICE_LOGS_FAILURE',

    //clear device logs
    CLEAR_DEVICE_LOGS: 'CLEAR_DEVICE_LOGS',


    // Mqtt 
    // Device Pairing
    START_PAIRING: "START_PAIRING",
    STOP_PAIRING: "STOP_PAIRING",

    //device loading
    START_LOADING: "START_LOADING",
    STOP_LOADING: "STOP_LOADING",


    DEVICES_BREADCRUMBS: 'devices_breadcrumbs',

    // factory reset of device
    FACTORY_RESET_REQUEST: 'FACTORY_RESET_REQUEST',
    FACTORY_RESET_SUCCESS: 'FACTORY_RESET_SUCCESS',
    FACTORY_RESET_FAILURE: 'FACTORY_RESET_FAILURE',

    // get gateway log
    GET_GATEWAYLOG_REQUEST: 'GET_GATEWAYLOG_REQUEST',
    GET_GATEWAYLOG_SUCCESS: 'GET_GATEWAYLOG_SUCCESS',
    GET_GATEWAYLOG_FAILURE: 'GET_GATEWAYLOG_FAILURE',
    // find sensors
    FIND_SENSORS_REQUEST: 'FIND_SENSORS_REQUEST',
    FIND_SENSORS_SUCCESS: 'FIND_SENSORS_SUCCESS',
    FIND_SENSORS_FAILURE: 'FIND_SENSORS_FAILURE',
    //stop find sensors
    STOP_FIND_SENSORS_REQUEST: 'STOP_FIND_SENSORS_REQUEST',
    STOP_FIND_SENSORS_SUCCESS: 'STOP_FIND_SENSORS_SUCCESS',
    STOP_FIND_SENSORS_FAILURE: 'STOP_FIND_SENSORS_FAILURE',
    //fetch sensors
    FETCH_SENSORS_REQUEST: 'FETCH_SENSORS_REQUEST',
    FETCH_SENSORS_SUCCESS: 'FETCH_SENSORS_SUCCESS',
    FETCH_SENSORS_FAILURE: 'FETCH_SENSORS_FAILURE',
    //bind sensors
    BIND_SENSORS_REQUEST: 'BIND_SENSORS_REQUEST',
    BIND_SENSORS_SUCCESS: 'BIND_SENSORS_SUCCESS',
    BIND_SENSORS_FAILURE: 'BIND_SENSORS_FAILURE',
    //clear sensorList variable
    CLEAR_SENSOR_LIST: 'CLEAR_SENSOR_LIST',

    //gateway log dates
    GET_GATEWAYLOG_DATES_REQUEST: 'GET_GATEWAYLOG_DATES_REQUEST',
    GET_GATEWAYLOG_DATES_SUCCESS: 'GET_GATEWAYLOG_DATES_SUCCESS',
    GET_GATEWAYLOG_DATES_FAILURE: 'GET_GATEWAYLOG_DATES_FAILURE',

    //assign a gateway
    ASSIGN_GATEWAY_REQUEST: 'ASSIGN_GATEWAY_REQUEST',
    ASSIGN_GATEWAY_SUCCESS: 'ASSIGN_GATEWAY_SUCCESS',
    ASSIGN_GATEWAY_FAILURE: 'ASSIGN_GATEWAY_FAILURE',

    //get assigned gateways
    GET_ASSIGNED_GATEWAYS_REQUEST: 'GET_ASSIGNED_GATEWAYS_REQUEST',
    GET_ASSIGNED_GATEWAYS_SUCCESS: 'GET_ASSIGNED_GATEWAYS_SUCCESS',
    GET_ASSIGNED_GATEWAYS_FAILURE: 'GET_ASSIGNED_GATEWAYS_FAILURE',


    //get gateway statistics
    GET_GATEWAY_STATISTICS_REQUEST: 'GET_GATEWAY_STATISTICS_REQUEST',
    GET_GATEWAY_STATISTICS_SUCCESS: 'GET_GATEWAY_STATISTICS_SUCCESS',
    GET_GATEWAY_STATISTICS_FAILURE: 'GET_GATEWAY_STATISTICS_FAILURE',

    GET_CAMERA_STREAM_REQUEST: 'GET_CAMERA_STREAM_REQUEST',
    GET_CAMERA_STREAM_SUCCESS: 'GET_CAMERA_STREAM_SUCCESS',
    GET_CAMERA_STREAM_FAILURE: 'GET_CAMERA_STREAM_FAILURE',

    STOP_CAMERA_STREAM_REQUEST: 'STOP_CAMERA_STREAM_SUCCESS',
    STOP_CAMERA_STREAM_SUCCESS: 'STOP_CAMERA_STREAM_REQUEST',
    STOP_CAMERA_STREAM_FAILURE: 'STOP_CAMERA_STREAM_FAILURE',

    CLEAR_DETAILED_DEVICE_LIST: 'CLEAR_DETAILED_DEVICE_LIST',


}
