/***
 *
 * Controller class for user.
 * @file GroupList.js
 * @description GroupList component
 * @author ayeshakumar
 * @since 23 AUG 2022
 *
 */

import React, { useState, useEffect } from "react";
import DeviceTypes from "../DeviceTypes";
import CustomTypography from "../CustomTypography";

// import PropTypes from 'prop-types';
import "./GroupList.scss";
import { Box } from "@mui/material";
import { Link } from 'react-router-dom';
import CreateGroups from "../../pages/CreateGroups";
import CustomTable from "../CustomTable";
import PageHeader from "../PageHeader";
import { useDispatch, useSelector } from "react-redux";
import { formatedDate, getMessageStr, history, isAuthorised } from "../../helpers";
import { useQuery } from "../../hooks";
import { TableHelper } from "../TableHelper";
import { groupActions } from "../../redux/actions";
import ImageWrapper from "../ImageWrapper";
import ListView from "../../assets/icons/list-view.svg";
import CardView from "../../assets/icons/card-view.svg";
import _ from "lodash";
import LightIcon from '../../assets/icons/bulb.svg';
import StyledCard from "../StyledCard";
import DataNotFound from "../../pages/DataNotFound";
import groupIcon from "../../assets/icons/group.svg";
import SyncIcon from '../../assets/icons/sync.svg';
import GridView from "../GridView";
import { subscribe, unsubscribeAll } from "../../services";
import { features, permissions } from "../../constants";
import StyledButton from "../StyledButton";

const cardIconsList = [
  {
    "src": LightIcon,
    "key": "light",
    "borderInfo": "1px solid #fdd600"
  }
]


const filterCardIconsList = (group) => {
  let data = group?.devices || [];

  const filteredKeys = data.map((item) => {
    if (item?.deviceId) {
      return "light";
    }
    return null;
  }).filter((key, index, array) => key && array.indexOf(key) === index);
  const filteredIcons = cardIconsList.filter((item) => filteredKeys.includes(item.key));
  return filteredIcons || [];
}

const GroupCard = ({ groupDetails, ...props }) => {

  const dispatch = useDispatch();

  const [group, setGroup] = useState({});

  useEffect(() => {
    setGroup(groupDetails)
  }, [groupDetails])

  return <StyledCard
    disabled={group?.status === "inactive"}
    config={{
      heading: {
        style: { textTransform: 'normal' }
      }
    }}
    data={{
      // ...(() => {
      //   return group?.managedBy === "cloud" ? {} :
      //     {
      status: {
        text: group?.synced ? 'Synced' : 'Requires Sync',
        active: group?.synced
      },
      //     }
      // })(),
      heading: {
        label: group.name,
        to: group?.status !== "inactive" && `?id=${group.groupId}`,
        actions: (
          isAuthorised(features?.device, permissions?.syncGroup) && group.managedBy !== 'cloud' && group?.status === "active" && !group?.synced &&
          <StyledButton
            onClick={(event) => { event.preventDefault(); dispatch(groupActions.syncById(group.groupId)) }}
            sx={{ p: 0 }}
            iconButton
            tooltip="Sync Group"
          >
            <ImageWrapper width={16} height={16} className="pointer" src={SyncIcon} />
          </StyledButton>
        )
      },
      mediaList: {
        max: 6,
        images: filterCardIconsList(group)
      },
      info: {
        text: `${_.capitalize(group?.managedBy)} managed`,
        secondText: `Last Updated On: ${group?.lastUpdatedOn && formatedDate({ date: group?.lastUpdatedOn, format: "MMM dd, yyyy" })}`,
        count: <DeviceTypes data={group} />
      }
    }}
  />
}

const GroupList = ({ mqttClient, ...props }) => {
  const query = useQuery();
  const dispatch = useDispatch();
  const lastAction = useSelector((state) => state?.groups?.lastAction) || null;
  const groupListData = useSelector((state) => state?.groups?.list) || [];
  const gatewayList = useSelector((state) => state?.devices?.gatewayList) || [];
  const currentSpaceId = useSelector((state) => state?.spaces?.selectedSpace?.spaceId);
  const groupDeleted = useSelector((state) => state?.groups?.groupDeleted)

  const [groupOperation, setGroupOperation] = useState();
  const [groupList, setGroupList] = useState([]);
  const [listActive, setListActive] = useState(false);
  const [gatewayIdList, setGatewayIdList] = useState([]);

  const goTo = (route) => {
    history.push(route);
  };

  useEffect(() => {
    return () => {
      unsubscribeAll(mqttClient, dispatch)
    }
  }, [mqttClient])

  useEffect(() => {
    setGroupOperation(query.get("op"));
  }, [query]);

  useEffect(() => {
    dispatch(groupActions?.getAllGroupsBySpace(currentSpaceId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSpaceId, groupDeleted]);

  useEffect(() => {
    const gwIdList = new Set();
    groupListData?.forEach(g => {
      g?.managedBy !== "cloud" && gwIdList?.add(g?.gatewayIds[0]);
    })
    setGatewayIdList(gwIdList);
    setGroupList(groupListData || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastAction]);

  useEffect(() => {
    if (!_.isEmpty(mqttClient) && !_.isEmpty(gatewayList) && !_.isEmpty(gatewayIdList)) {
      gatewayList?.forEach(g => {
        if (gatewayIdList?.has(g?.deviceId)) {
          subscribe(g?.mqttTopics?.comStatus, dispatch, mqttClient, "groupSyncMonitor")
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gatewayIdList, gatewayList])

  return (
    <>
      {groupOperation === "create" ? (
        <CreateGroups />
      ) : (
        <div className="GroupList" data-testid="GroupList">
          <DataNotFound
            show={groupList?.length === 0 ? true : false}
            customsx={{ image: { height: '202px', width: '100%' }, label: {}, content: {}, btns: {} }}
            data={{
              label: "Groups",
              content: (getMessageStr("group-getAll-noGroup") + (isAuthorised(features?.device, permissions?.add) ? getMessageStr("group-getAll-createGroup") : ".")), //WGJGBM group-getAll-noGroup
              action:
                isAuthorised(features?.device, permissions?.add) &&
                [
                  {
                    id: "1001",
                    type: "button",
                    label: "Create Group",
                    onClick: (event, data) => { goTo("/spaces/groups?op=create") },
                  }]
            }
            }
          >
            <Box>
              <PageHeader title={"Groups"} icon={groupIcon}>
                <Box sx={{ textAlign: "right" }}>
                  <StyledButton
                    iconButton
                    tooltip={listActive ? 'Card View' : 'Table View'}
                    onClick={(e) => {
                      setListActive(!listActive);
                    }}
                  >
                    <ImageWrapper src={listActive ? CardView : ListView}></ImageWrapper>
                  </StyledButton>
                  {
                    isAuthorised(features?.device, permissions?.add) &&
                    <StyledButton
                      sx={{ ml: 1 }} onClick={(event) => goTo("/spaces/groups?op=create")}>
                      Create Group
                    </StyledButton>
                  }
                </Box>
              </PageHeader>
              {listActive ? (
                <CustomTable
                  headerList={{
                    name: {
                      label: "Group Name",
                      type: "component",
                      component: (row) => {
                        return row?.data?.status === "inactive" ?
                          <CustomTypography fontWeight={400} size={14} lineHeight={19} content={row?.data?.name} />
                          :
                          <Link className="link" to={`/spaces/groups?id=${row?.data?.groupId}`}>{row?.data?.name}</Link>
                      },
                      toolTip: {
                        type: "component",
                        content: TableHelper.TableLinkTooltipContent
                      }
                    },
                    groupType: {
                      label: 'Group Type',
                      type: "list",
                      separator: "|"
                      // component: (row) => {
                      //   return <CustomTypography fontWeight={400} size={14} lineHeight={19} content={`${row?.data?.groupType?.join(' | ') || ''}`} />
                      // }
                    },
                    devices: {
                      label: "Device Count",
                      type: "component",
                      component: DeviceTypes,
                    },
                    managedBy: {
                      label: "Managed By",
                    },
                    synced: {
                      label: "Sync Status",
                      type: "component",
                      component: (row) => {
                        return (
                          // row?.data?.managedBy === "cloud" ?
                          //   <Box>-</Box>
                          //   :
                          <TableHelper.TableStatus type={row?.data?.synced ? "synced" : "needsSync"} variant={"filled"} label={row?.data?.synced ? "Synced" : "Needs Sync"} />
                        )
                      },
                      style: { maxWidth: 180 },
                    },
                    userActions: {
                      label: "Actions",
                      actions: [
                        {
                          label: "Sync Group",
                          type: "refresh",
                          icon: SyncIcon,
                          onClick: (event, data) => {
                            event.preventDefault();
                            dispatch(
                              groupActions.syncById(data.groupId)
                            );
                          },
                          hidden: (row) => {
                            return !isAuthorised(features?.device, permissions?.syncGroup) || row?.managedBy === 'cloud' || row?.synced || row?.status === "inactive"
                          },
                        },
                      ],
                      style: { textAlign: "right" },
                    }

                  }}
                  sorting={{ column: 'name' }}
                  removeSortFrom={["groupType", "devices", "userActions"]}
                  cellMatrix={groupList || []}
                  pagination={true}
                  searching={true}
                  rowConfig={{ highlight: { bgKey: "status", bgValue: "inactive" }, disabled: { key: "status", value: "inactive" } }}
                />
              ) : (
                <GridView
                  data={groupList}
                  card={({ data: group }) => {
                    return <GroupCard groupDetails={group} />
                  }}
                ></GridView>
              )}
            </Box>
          </DataNotFound>
        </div >
      )}
    </>
  );
};

GroupList.propTypes = {};

GroupList.defaultProps = {};

export default GroupList;
