/***
 *
 * Controller class for user.
 * @file PopoverContainer.js
 * @description PopoverContainer component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { style } from './PopoverContainer.style.js';
import { Paper, Popper, ClickAwayListener } from '@mui/material';
import { styled } from '@mui/material/styles'

const Arrow = styled("span")(style.arrow);

const CustomPopper = styled(Popper)(({ theme })=>({
  zIndex: "1280",
  '&[data-popper-placement*="bottom"]>span': {
      top: 0,
      left: 0,
      marginTop: "-12px",
      width: '54px',
      height: '13px',
      "&::before": {
          borderWidth: "0 15px 13px 15px",
          borderColor: `transparent transparent ${theme?.arrow?.borderColor || '#fff'} transparent`
      }
  },
  '&[data-popper-placement*="top"]>span': {
      bottom: 0,
      left: 0,
      marginBottom: "-13px",
      width: '54px',
      height: '13px',
      "&::before": {
          borderWidth: "13px 15px 0 15px",
          borderColor: `${theme?.arrow?.borderColor || '#fff'} transparent transparent transparent`
      }
  },
  '&[data-popper-placement*="right"]>span': {
      top: "-1px !important",
      left: 0,
      marginLeft: "-10px",
      width: '13px',
      height: '28px',
      "&::before": {
          borderWidth: "15px 13px 15px 0",
          borderColor: `transparent ${theme?.arrow?.borderColor || '#fff'} transparent transparent`
      }
  },
  '&[data-popper-placement*="left"]>span': {
      top: "-1px !important",
      right: "0px",
      marginRight: "-10px",
      width: '13px',
      height: '28px',
      "&::before": {
          borderWidth: "15px 0 15px 13px",
          borderColor: `transparent transparent transparent ${theme?.arrow?.borderColor || '#fff'}`
      }
  }
}));


const PopoverContainer = ({ children, placement=null, anchorEl = null, onClose=()=>{}, ...props}) => {
  // console.log('tooltip', children, placement);
  const open = Boolean(anchorEl);
  const [arrowPlacement, setArrowPlacemant] = useState(placement || "bottom")
  const [arrowRef, setArrowRef] = useState(null);

  useEffect(()=>{
    if(placement) {
      setArrowPlacemant(placement);
    }    
  }, [placement])

  return (
    <CustomPopper
      {...props}
      data-testid="PopoverContainer"
      placement={arrowPlacement}
      disablePortal={false}
      open={open}
      anchorEl={anchorEl}
      modifiers={[
        {
          name: 'flip',
          enabled: true,
        },
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            // altAxis: true,
            // altBoundary: true,
            // tether: true,
            boundariesElement: 'viewport',
            escapeWithReference: true ,
            padding: 8
          },
        },
        {
          name: 'arrow',
          enabled: true,
          options: {
            element: arrowRef,
          },
        },
        {
          name: "offset",
          options: {
            offset: [0, 13],
          },
        },
      ]}
    >
      <Arrow ref={setArrowRef} />
      <ClickAwayListener onClickAway={onClose}>
        <Paper
          elevation={3}
          sx={{...style.paper, minWidth: "60px"}}
        >
          {children}
        </Paper>
      </ClickAwayListener>
    </CustomPopper>
  )
};

PopoverContainer.propTypes = {};

PopoverContainer.defaultProps = {};

export default PopoverContainer;
