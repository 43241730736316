import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    TicketsList: {
        '& .btnSet': { display: "flex", justifyContent: "flex-end" },
        '& .ticketLink': { color: "#0f8d48" },
        '& .ticketAvatar': { display: 'inline-flex', fontSize: '1.125rem' },

    },
    btnIcon: {
        filter: "invert(99%) sepia(90%) saturate(39%) hue-rotate(74deg) brightness(108%) contrast(100%)"
    },
    short: {
        marginLeft: "8px !important",
        "@media (max-width:769px)": {
            display: "none",
            // marginLeft: "0px"
        },
    },
});
