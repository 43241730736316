import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    FirmwareUpgrade: {
        "& .searchContainer": {
            border: "1px solid #EAEAEA",
            padding: "20px 24px 20px 24px",
            marginBottom: "16px",
            // marginLeft: "-12px",
        },
        "& .searchButtonContainer": {
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            minWidth: "100%"
        },
    }
});
