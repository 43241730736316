/***
 *
 * Controller class for user.
 * @file AreaConfirmation.js
 * @description AreaConfirmation component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import './AreaConfirmation.scss';
import _ from 'lodash';
import CustomTable from '../CustomTable';
import StyledTabs from '../StyledTabs';
import { Box } from '@mui/material';
import { TableHelper } from '../TableHelper';
import CustomTypography from '../CustomTypography';
import groupIcon from '../../assets/icons/group.svg';
import sensorIcon from '../../assets/icons/sensor.svg';

const AreaConfirmation = ({ selectedItems }) => {
  const [tabsArray, ] = useState([{ icon: sensorIcon, label: 'Sensors' }, { icon: groupIcon, label: 'Groups' }])
  const [tableHeaders, setTableHeaders] = useState({})
  const [tableData, setTableData] = useState([])
  const [groupsUnderArea, setGroupsUnderArea] = useState([])
  const [sensorsUnderArea, setSensorsUnderArea] = useState([])
  const [sensorHeaders] = useState({
    name: {
      label: "Sensor Name",
    },
    status: {
      label: "Status",
      type: "component",
      component: (row) => {
        return (
          <TableHelper.TableStatus type={row?.data?.status} label={row?.data?.status === "active" ? "Active" :row?.data?.status === "inactive"?"Inactive":"Deleted" } />

        )
      },
    },
    spaceName: {
      label: "Location"
    }
  })
  const [groupHeaders] = useState({
    name: {
      label: "Group Name",
    },
    synced: {
      label: "Sync Status",
      type: "component",
      component: (row) => {

        return row?.data.hasOwnProperty('synced') ? <TableHelper.TableStatus type={row?.data?.synced ? "synced" : "needsSync"} variant={"filled"} label={row?.data?.synced ? "Synced" : "Needs Sync"} /> : <CustomTypography content={'-'} />
      }
    }
  })

  useEffect(() => {
    if (!_.isEmpty(selectedItems)) {
      let sensorlist = selectedItems.filter(item => item.hasOwnProperty('deviceId'))
      let groupList = selectedItems.filter(item => item.hasOwnProperty('groupId'))
      setSensorsUnderArea(sensorlist)
      setGroupsUnderArea(groupList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //For Initial Setting of Table Details
  useEffect(() => {
    setTableHeaders(sensorHeaders)
    setTableData(sensorsUnderArea)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sensorsUnderArea])

  const handleToggle = (e, index) => {
    if (index === 0) {
      setTableHeaders(sensorHeaders)
      setTableData(sensorsUnderArea)
    }
    else {
      setTableHeaders(groupHeaders)
      setTableData(groupsUnderArea)
    }
  }

  return (
    <div className="AreaConfirmation" data-testid="AreaConfirmation">
      <Box sx={{ mb: 2 }}>
        <StyledTabs
          tabs={tabsArray}
          onClick={(e, value) => {
            handleToggle(e, value);
          }}
        />
      </Box>
      <CustomTable
        headerList={tableHeaders}
        cellMatrix={tableData || []}
        pagination={true}
        sorting={true}
      />
    </div>
  )
};

AreaConfirmation.propTypes = {};

AreaConfirmation.defaultProps = {};

export default AreaConfirmation;
