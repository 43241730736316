/***
 *
 * Controller class for user.
 * @file Comments.js
 * @description Comments component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import { React, useState, useRef } from 'react';
import { useSelector } from "react-redux";
// import PropTypes from 'prop-types';
import { useStyles } from './Comments.style.js';
import './Comments.scss';
import { Avatar, Box, Grid, InputAdornment, Typography, Chip } from '@mui/material';
import StyledTextfield from '../StyledTextfield/index.js';
import { ReactComponent as EditGrey } from '../../assets/icons/edit-2.svg';
import { ReactComponent as Delete } from '../../assets/icons/delete.svg';
import { ReactComponent as CancelIcon } from '../../assets/icons/cancel.svg';
import { ReactComponent as AttachmentIcon } from '../../assets/icons/attachment.svg';
import PopoverContainer from '../PopoverContainer/index.js';
import { formatedDate } from '../../helpers/date.helper.js';
// import StyledChip from '../StyledChip/index.js';
import { isAuthorised } from '../../helpers/users.helper.js';
import { features, permissions } from '../../constants/permission.constants.js';
import { ticketingActions } from '../../redux/actions/ticketing.actions.js';
import _ from 'lodash';
import StyledButton from '../StyledButton/index.js';
import { stringAvatar } from "../../helpers/users.helper.js";
import { getMessageStr } from '../../helpers/message.helper.js';


const Comments = ({ ticketId, comments, commentIdx, fileObj, openDeletePopup, editedComment, setCommentIdx = () => { }, handleFileChange = () => { }, doComment = () => { }, getCommentAttachment = () => { }, removeImage = () => { }, handleEditComment = () => { }, postEditedComment = () => { }, setEditedComment = () => { } }) => {
  const classes = useStyles();
  const inputRef = useRef(null);

  const userProfile = useSelector(state => state?.users?.userSelfProfile);
  const [showToolTip, setShowToolTip] = useState(null);
  const [comment, setComment] = useState("")
  const [error, setError] = useState({ error: false, errorText: null, type: null })

  const handleClick = () => {
    inputRef.current.click();
  };

  const writeComment = (comment, type) => {
    if (comment.length > 280) {
      setError({ error: true, type, errorText: getMessageStr('ticket-comment-maxLength') })
    } else if (comment.length < 2) {
      setError({ error: true, type, errorText: getMessageStr('ticket-comment-minLength') })
    }
    else {
      setError({ error: false, type: null, errorText: null })
    }
    if (type === "edit")
      setEditedComment(comment)
    else
      setComment(comment)
  }

  return (
    <Box className={classes?.Comments || ""} data-testid="Comments">
      <Grid sx={{ mt: 2 }}>
        {isAuthorised(features?.ticket, permissions?.addComments) &&
          <>
            <Typography style={{ fontWeight: 600, fontSize: "0.9em", paddingBottom: "10px" }}>Comments</Typography>
            <Grid container item spacing={1} display={'flex'} >
              <Grid item>
                <Avatar className={classes.avatar} {...stringAvatar(userProfile ? userProfile?.name : "")} />
              </Grid>
              <Grid item xs lg>
                <StyledTextfield error={error?.type === "create" && error?.error} helperText={error?.type === "create" && error?.errorText} fullWidth hiddenLabel value={comment} onChange={(e) => { writeComment(e.target.value, "create") }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <StyledButton
                          edge="end"
                          iconButton
                          aria-label="Attach file"
                          onClick={handleClick}
                        >
                          {fileObj ?
                            <>
                              <img className={classes?.commentImage} alt='file-object' src={fileObj ? URL.createObjectURL(fileObj) : null} />
                              <CancelIcon className={classes?.cancelIcon} onClick={(e) => { removeImage() }} />
                            </>
                            :
                            <>
                              <input
                                style={{ display: 'none' }}
                                ref={inputRef}
                                type="file"
                                onChange={(e) => { handleFileChange(e, "comment") }}
                                accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                              />
                              <AttachmentIcon />
                              <Typography style={{ fontWeight: 200, fontSize: "0.5em", marginLeft: "10px" }}>
                                Attach Files
                              </Typography>
                            </>
                          }
                        </StyledButton>
                      </InputAdornment>
                    ),
                  }} />
              </Grid>
              <Grid item >
                <StyledButton sx={{ height: "40px" }} disabled={comment?.length < 2 || comment?.length > 280} variant='outlined' onClick={(e) => { doComment(comment); setComment("") }}>POST</StyledButton>
              </Grid>

            </Grid>

          </>

        }
        {isAuthorised(features?.ticket, permissions?.view) &&
          <Grid>
            {comments && comments.length > 0 ? comments?.map((comment, idx) => {
              return (
                <Grid item key={'grid-' + idx}>
                  <Box style={{ display: "flex", paddingTop: "10px" }}>
                    <Box>
                      <Avatar style={{ marginRight: "10px" }} className={classes.avatar} {...stringAvatar(comment?.createdByUserName ? comment?.createdByUserName : "")}
                        onMouseEnter={(e) => {
                          setShowToolTip(e.currentTarget)
                        }}
                        onMouseLeave={() => {
                          setShowToolTip(null)
                        }} />
                      <PopoverContainer anchorEl={showToolTip} onClose={() => setShowToolTip(null)}>
                        {comment?.createdByUserName}
                      </PopoverContainer>
                    </Box>
                    <Box sx={{ width: "100%" }}>
                      <Grid container item sx={{ display: "flex" }}>
                        <Grid item>
                          <Typography className={classes?.commentor}>  {comment?.lastModifiedByUserName} </Typography>
                        </Grid>
                        <Grid item display={'flex'}>
                          <Typography className={classes?.commentDate}> {formatedDate({ date: comment.lastModifiedAt, format: "MMM d, yyyy 'at' hh:mm a" })} </Typography>
                        </Grid>
                      </Grid>
                      <Box sx={{ display: "flex" }}>
                        <Box style={{ width: "100%" }}>
                          {
                            commentIdx === idx ?
                              <StyledTextfield id="outlined-error"
                                error={error?.type === "edit" && error?.error} helperText={error?.type === "edit" && error?.errorText} fullWidth hiddenLabel value={editedComment} onChange={(e) => { writeComment(e.target.value, "edit") }} />
                              :
                              <Typography className={classes?.commentDesc}>
                                {comment.description}
                                <Box>
                                  {comment.attachments && comment.attachments.length > 0 ? comment.attachments.map((attachment, index) => {
                                    return (
                                      <Chip
                                        key={'chip-' + index}
                                        variant="outlined"
                                        color="primary"
                                        label={attachment.fileName}
                                        readOnly={true}
                                        sx={{ marginRight: "10px", marginBottom: "10px", cursor: "pointer " }}
                                        deleteIcon={isAuthorised(features?.ticket, permissions?.edit) && comment.createdByUserName === userProfile?.name ? <Delete /> : undefined}
                                        onClick={(e) => {
                                          ticketingActions.getCommentAttachment(ticketId, comment?.commentId, attachment?.id).then(({ data }) => {
                                            if (data && !_.isEmpty(data)) {
                                              const link = document.createElement("a");
                                              link.href = data?.signedUrl;
                                              link.setAttribute("download", data?.fileName);
                                              document.body.appendChild(link);
                                              link.click();
                                            }
                                          })
                                        }}
                                        onDelete={isAuthorised(features?.ticket, permissions?.edit) && comment.createdByUserName === userProfile?.name ? () => openDeletePopup({ open: true, tag: "Comment Attachment", data: { comment, attachment } }) : undefined} />
                                    )
                                  }) : null}
                                </Box>
                              </Typography>
                          }
                        </Box>
                        <Box>
                          {commentIdx === idx ?
                            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                              <StyledButton variant='outlined' sx={{ ml: 2, height: "40px" }} onClick={(e) => { postEditedComment(comment, idx) }} >POST</StyledButton>
                              <StyledButton variant='outlined' sx={{ ml: 2, height: "40px" }} onClick={(e) => { setCommentIdx(null) }} >Cancel</StyledButton>
                            </Box>
                            :
                            isAuthorised(features?.ticket, permissions?.edit) && comment.createdByUserName === userProfile?.name ?
                              <Box sx={{ paddingLeft: "8px" }}>
                                <EditGrey style={{ cursor: "pointer" }} onClick={(e) => { handleEditComment(comment, idx) }} />
                                <Delete style={{ cursor: "pointer" }} onClick={(e) => { setCommentIdx(comment); openDeletePopup({ open: true, tag: "comment", data: null }); }} />
                              </Box>
                              :
                              <></>
                          }
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              )
            }) :
              <Box sx={{ marginTop: "20px" }}>
                <Typography className={classes.noComment}>No Comments </Typography>
              </Box>
            }
          </Grid>
        }
      </Grid>
    </Box>
  )
};

Comments.propTypes = {};

Comments.defaultProps = {};

export default Comments;
