/***
 *
 * Controller class for user.
 * @file StyledScheduler.js
 * @description StyledScheduler component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './StyledScheduler.style.js';
import './StyledScheduler.scss';
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, Radio, RadioGroup } from '@mui/material';
import CustomTypography from '../CustomTypography/index.js';
import ChipInput from '../ChipInput/index.js';
import { ReactComponent as Cancel } from "../../assets/icons/cancel.svg"
import StyledChip from '../StyledChip/index.js';
import _, { isEmpty } from 'lodash';
import StyledDateTimePicker from '../StyledDateTimePicker/index.js';
import StyledTextfield from '../StyledTextfield/index.js';
import { dayOfWeek as weekDays, hours, minutes } from '../../constants/scheduler.constants.js';
import { daysOfMonth, months } from '../../constants/scheduler.constants.js';
import CustomLabel from '../CustomLabel/index.js';
import StyledButton from '../StyledButton/index.js';

// export const weekDays=
const StyledScheduler = ({ setSchedule = () => { }, schedule = null, customsx = {}, params = { time: true, hours: false, minutes: false, timeDuration: false, durationInMins: false, month: true, dayOfWeek: true, dayOfMonth: true, day: false }, actionButtons = [], header = "", display = "stack", ...props }) => {
  const classes = useStyles()
  const [daysOfWeek, setDaysOfWeek] = useState(weekDays?.map(w => { return { ...w } }));
  const [timeList, setTimeList] = useState([]);
  const [timeDurationList, setTimeDurationList] = useState([]);
  const [durationInMins, setDurationInMins] = useState(null);
  const [monthList, setMonthList] = useState([]);
  const [dayList, setDayList] = useState([]);
  const [hourList, setHourList] = useState([]);
  const [minuteList, setMinuteList] = useState([]);
  const [currentTime, setCurrentTime] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [scheduleTime, setScheduleTime] = useState(null);
  const [error, setError] = useState(null);
  const [dayChoice, setDayChoice] = useState("dayOfMonth");

  useEffect(() => {
    // setDurationInMinsError(false);
    setError({});
    if (!_.isEmpty(schedule) && typeof schedule === "object") {
      let selectedWeekDays = daysOfWeek.map(d => {
        if (schedule?.weekDay?.find(day => day === d?.id)) {
          d.value = true;
          return d
        }
        return d
      })
      let weekdaysSelected = true;
      selectedWeekDays?.forEach(w => {
        if (w?.id <= 5 && w?.id >= 1 && w?.value === false) {
          weekdaysSelected = false;
        }
      })
      if (weekdaysSelected) {
        selectedWeekDays = selectedWeekDays?.map(w => {
          if (w?.id === "7" && w?.label === "WORK WEEK") {
            w.value = true;
          }
          return w;
        });
      }
      setDaysOfWeek(selectedWeekDays);
      // const scheduleMinutes = minutes?.filter(min => schedule?.minutes?.find(m => m === min?.label)) || [];
      // setMinuteList(scheduleMinutes?.map(m => m?.label));
      setMinuteList(schedule?.minutes || []);
      // console.log("MONTH",scheduleMinutes)


      // const scheduleHours = hours?.filter(hour => schedule?.hours?.find(h => h === hour?.label)) || [];
      // setHourList(scheduleHours?.map(h => h?.label));
      setHourList(schedule?.hours || [])


      setTimeList(schedule?.time?.filter(t => !t?.includes("-"))?.map(t => { return { id: t, label: t?.substring(0, 2) + ":" + t?.substring(2, 4) } }) || []);
      setTimeDurationList(schedule?.time?.filter(t => t?.includes("-")).map(t => { return { id: t, label: t?.substring(0, 2) + ":" + t?.substring(2, 4) + "-" + t?.substring(5, 7) + ":" + t?.substring(7, 9) } }) || []);

      setDurationInMins(schedule?.durationInMins || null);

      // const daysOfMonthObj = daysOfMonth?.filter(d => schedule?.day?.find(day => day === d?.label)) || [];
      // setDayList(daysOfMonthObj?.map(d => d?.label))
      setDayList(schedule?.day || [])

      scheduleTimeMessage(schedule?.hours || [], schedule?.minutes || []);
      console.log("MONTH",schedule)
      const scheduleMonths = months?.filter(m => schedule?.month?.find(month => month === m?.key)) || [];
      setMonthList(scheduleMonths?.map(m => m?.label));
      // setMonthList(schedule?.month || []);
      setDayChoice(isEmpty(schedule?.weekDay) ? "dayOfMonth" : "weekDay")

    }
    else {
      setCurrentTime(null)
      setStartTime(null);
      setEndTime(null);
      setDaysOfWeek(weekDays?.map(w => { return { ...w } }));
      setTimeList([]);
      setTimeDurationList([]);
      setMonthList([]);
      setDayList([]);
      setMinuteList([]);
      setHourList([]);
      setDurationInMins(null);
      setScheduleTime(null);
      setDayChoice("dayOfMonth")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const scheduleTimeMessage = (hourList, minuteList) => {
    console.log("onChanging", hourList, minuteList)
    if (!_.isEmpty(hourList) && !_.isEmpty(minuteList)) {
      const m = hourList?.map(h => minuteList?.map(m => {
        // h = h < 10 ? `0${h}` : h;
        // m = m < 10 ? `0${m}` : m;
        return ` ${h}:${m}`
      }))
      console.log("SSSSSS", m)
      setScheduleTime(`Schedule will be triggered at${m}`);
    }

  }

  const handleDayOfWeek = (day, value) => {
    if (day === "WORK WEEK") {
      setDaysOfWeek(daysOfWeek.map(d => {
        if (d?.label !== "SAT" && d?.label !== "SUN") {
          d.value = !value
          return d
        }
        else {
          // d.value=value
          return d
        }

      }))

    }
    else {
      let weekDaysSelected = true;//assume all weekdays are selected
      let weekDays = daysOfWeek.map(d => {//set value that was selected
        if (d?.label === day) {
          d.value = !value
          return d;
        }
        return d;
      });
      weekDays?.forEach(d => {//check if all wekdays are selected
        if (d?.id >= 1 && d?.id <= 5 && d?.value === false) {
          weekDaysSelected = false;
        }
      });
      weekDays = weekDays?.map(d => {
        if (d?.label === "WORK WEEK") {
          d.value = weekDaysSelected;
        }
        return d;
      });
      setDaysOfWeek(weekDays);
    }

  }


  const deleteTime = (label, valueList, setter) => {
    setter(valueList?.filter(t => t?.label !== label))
  }
  const getTimeFormat = (time) => {
    let hour = time?.$H
    let minute = time?.$m
    if (minute < 10) {
      minute = "0" + minute
    }
    if (hour < 10) {
      hour = "0" + hour
    }
    let timeFormat = {
      id: `${hour}${minute}`,
      label: hour + ":" + minute
    }
    return timeFormat
  }
  const saveTime = () => {
    let time = getTimeFormat(currentTime);
    if (timeList?.filter((t) => t?.id === time?.id)?.length === 0) {
      setTimeList([...timeList, time])
    }
    setError({ ...error, time: null });
  }
  const saveTimeDuration = () => {
    let startTimeFormat = getTimeFormat(startTime);
    let endTimeFormat = getTimeFormat(endTime);
    let timeduration = {
      id: startTimeFormat?.id + "-" + endTimeFormat?.id,
      label: startTimeFormat?.label + "-" + endTimeFormat?.label
    }
    if (timeDurationList?.filter((t) => t?.id === timeduration?.id)?.length === 0) {
      setTimeDurationList([...timeDurationList, timeduration])
    }
  }

  const daysOfWeekCheckboxes = () => {
    return (
      <Box>
        <Grid item xs={12} >
          <FormGroup sx={{ display: "flex", flexDirection: "row" }}>

            {daysOfWeek.map((day, ind) => {
              return day?.label !== "WORK WEEK" &&
                < span
                  key={"checkkkkkkk-" + ind}
                >
                  <FormControlLabel control={<Checkbox
                    checked={day?.value}
                    id="checkbox"
                    onClick={(e) => {
                      handleDayOfWeek(day?.label, day?.value)
                    }}

                    inputProps={{ "aria-label": "check-box" }}
                    // sx={{ '& .MuiSvgIcon-root': { fontSize: 14 } }}
                    size="small"
                    disabled={params?.dayOfWeek === "disabled" ? true : false} />}
                    label={day?.label} />
                </span>
            }
            )}
          </FormGroup>
        </Grid>
        <Grid item xs={12} >
          <FormGroup style={{"maxWidth":"fit-content"}}>
            <FormControlLabel control={<Checkbox
              checked={daysOfWeek?.find(d => d?.label === "WORK WEEK")?.value}
              id="checkbox"
              onClick={(e) => {
                handleDayOfWeek("WORK WEEK", daysOfWeek?.find(d => d?.label === "WORK WEEK")?.value)
              }}
              inputProps={{ "aria-label": "check-box" }}
              // sx={{ '& .MuiSvgIcon-root': { fontSize: 14 } }}
              size="small"
              disabled={params?.dayOfWeek === "disabled" ? true : false} />}
              label={daysOfWeek[daysOfWeek?.length - 1]?.label} />
          </FormGroup>
        </Grid>
      </Box>
    )
  }
  const saveSchedule = () => {
    const monthIds = monthList?.map((month) => {
      return months?.find(m => m?.label === month)?.key || ""
    })
    let daysOfWeekIds = daysOfWeek.filter(d => d?.label !== "WORK WEEK" && d?.value === true)
    daysOfWeekIds = daysOfWeekIds?.map((d) => d?.id)
    const t = timeList?.map(t => t?.id) || [];
    const td = timeDurationList?.map(t => t?.id) || [];
    const timeIds = [...t, ...td]

    let schedule = { day: dayList, month: monthIds, weekDay: daysOfWeekIds, time: timeIds, dayChoice };
    if (params?.day) {
      if (dayChoice === "month") {
        schedule.day = dayList;
      }
      else {
        schedule.weekDay = daysOfWeekIds
      }
    }
    else {
      schedule.day = dayList;
      schedule.weekDay = daysOfWeekIds
    }

    if (params?.minutes) {
      schedule.minutes = minuteList;
    }
    if (params?.hours) {
      schedule.hours = hourList;
    }
    if (params?.durationInMins === true) {
      schedule.durationInMins = parseInt(durationInMins)
    }


    setError({ ...error, time: _.isEmpty(t) ? "Please select the time" : null, hours: _.isEmpty(schedule?.hours) ? "Please select the hour" : null, minutes: _.isEmpty(schedule?.minutes) ? "Please select the minute" : null });

    if (!error?.durationInMins && (params?.time ? !_.isEmpty(t) : true) && (params?.hours ? !_.isEmpty(schedule?.hours) : true) && (params?.minutes ? !_.isEmpty(schedule?.minutes) : true)) {
      setSchedule(schedule);
      const saveButton = actionButtons?.find(b => b?.name?.toLowerCase() === "save")
      saveButton?.action();
    }
  }

  const changeDurationInMins = (value) => {
    setDurationInMins(value);

    if (value < 1 || value > 10080) {
      // setDurationInMinsError(true);
      setError({ ...error, durationInMins: "Duration can be between 1 and 10080 minutes" })
    }
    else {
      // setDurationInMinsError(false);
      setError({ ...error, durationInMins: null })

    }
  }

  return (
    <Box className={classes?.StyledScheduler || ""} data-testid="StyledScheduler">
      {header && <CustomTypography
        weight={500}
        color={"#000"}
        size={18}
        lineHeight={22}
        content={header}
        styles={customsx?.header ? customsx?.header : { marginBottom: "8px" }}
      />}

      <Grid container={display === "grid" ? true : false} sx={classes?.borderBox} item >
        {(params?.time || params?.hours || params?.minutes) &&
          <Box className="scheduleBox" sx={{ ...customsx?.body }}>
            {(params?.time || params?.minutes || params?.hours) &&
              <Grid container item xs={12} spacing={1}>
                <Grid item xs={12}>
                  <CustomLabel
                    required={true}
                    style={{ fontSize: 16, fontWeight: 600, color: "#000000CC", ...customsx?.subheader }}>
                    {"Set Time"}
                  </CustomLabel>
                </Grid>
                {params?.time &&
                  <>
                    <Grid item xs={12} md={5} lg={5}>
                      <StyledDateTimePicker
                        disabled={params?.time === "disabled" ? true : false}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            width: "161px",
                          }
                        }}
                        picker="TimePicker"
                        error={error?.time ? true : false}
                        message={error?.time}
                        value={currentTime}
                        placeholder="Select date"
                        onChange={(e) => { setCurrentTime(e?.target?.value) }} />

                    </Grid>
                    <Grid item xs={12} md={2} lg={2}>
                      <StyledButton onClick={saveTime} sx={{ height: "40px", width: "100%" }} disabled={currentTime ? false : true}> Add </StyledButton>
                    </Grid>
                    <Grid item xs={12}>
                      {timeList?.map((t, ind) =>
                        <StyledChip
                          variant="outlined"
                          key={ind}
                          color="primary"
                          label={t?.label}
                          deleteIcon={<Cancel />}
                          onDelete={(e) => { deleteTime(t?.label, timeList, setTimeList) }} />
                      )}
                      {/* {error?.time && <p style={{color:"red",fontSize:"small"}}>{error?.time}</p>} */}
                    </Grid></>}

                {params?.hours &&
                  <Grid item xs={12} md={5}>
                    {/* <CustomTypography
                      weight={400}
                      color={"#333333"}
                      size={14}
                      lineHeight={17}
                      content="Select Hour"
                      sx={{ mb: 1 }}
                    /> */}
                    <CustomLabel
                      required={true}
                    >
                      Select Hour
                    </CustomLabel>
                    <ChipInput placeholder="Add Hours "
                      // width="170px"
                      options={hours}
                      value={hourList}
                      error={error?.hours ? true : false}
                      helperText={error?.hours}
                      disabled={params?.hours === "disabled" ? true : false}
                      onChange={(e) => { setHourList(e?.target?.value); setError({ ...error, hours: null }); scheduleTimeMessage(e?.target?.value, minuteList); }}
                      chipPosition="bottom">
                    </ChipInput>

                  </Grid>
                }
                {
                  params?.minutes &&
                  <Grid item xs={12} md={5}>
                    {/* <CustomTypography
                      weight={400}
                      color={"#333333"}
                      size={14}
                      lineHeight={17}
                      content="Select Minute"
                      sx={{ mb: 1 }}
                    /> */}
                    <CustomLabel
                      required={true}
                    >
                      Select Minute
                    </CustomLabel>
                    <ChipInput placeholder="Add Minutes"
                      // width="170px"
                      options={minutes}
                      value={minuteList}
                      error={error?.minutes ? true : false}
                      helperText={error?.minutes}
                      disabled={params?.minutes === "disabled" ? true : false}
                      onChange={(e) => { setMinuteList(e?.target?.value); setError({ ...error, minutes: null }); scheduleTimeMessage(hourList, e?.target?.value); }}
                      chipPosition="bottom">
                    </ChipInput>
                  </Grid>
                }
                {(params?.minutes || params?.hours) && scheduleTime && (!_.isEmpty(minuteList) && !_.isEmpty(hourList)) &&
                  <CustomTypography
                    weight={300}
                    color={"#000000CC"}
                    size={13}
                    content={scheduleTime}>
                  </CustomTypography>}
              </Grid>
            }
            {params?.timeDuration &&
              <Box sx={{ mt: 1 }}>
                <Grid container item xs={12} spacing={1}>
                  <Grid item xs={12}>
                    <CustomTypography
                      weight={600}
                      color={"#000000CC"}
                      size={16}
                      lineHeight={20}
                      content="Set Time Duration"
                      styles={{ marginBottom: "0px", marginTop: "10px", ...customsx?.subheader }}
                    />
                  </Grid>
                  <Grid item xs={12} md={5} lg={5}  >
                    <CustomTypography
                      weight={400}
                      color={"#333333"}
                      size={14}
                      lineHeight={17}
                      content="Start Time"
                      styles={{ marginBottom: "8px" }}
                    />

                    <StyledDateTimePicker
                      picker="TimePicker"
                      value={startTime}
                      disabled={params?.timeDuration === "disabled" ? true : false}
                      placeholder="Select date"
                      onChange={(e) => { setStartTime(e?.target?.value) }} />
                  </Grid>

                  <Grid item xs={12} md={5} lg={5}  >
                    <CustomTypography
                      weight={400}
                      color={"#333333"}
                      size={14}
                      lineHeight={17}
                      content="End Time"
                      styles={{ marginBottom: "8px" }}
                    />
                    <StyledDateTimePicker
                      picker="TimePicker"
                      value={endTime}
                      placeholder="Select date"
                      disabled={params?.timeDuration === "disabled" ? true : false}
                      onChange={(e) => { setEndTime(e?.target?.value) }} />
                  </Grid>

                  <Grid item xs={12} md={2} lg={2}>
                    <StyledButton onClick={saveTimeDuration} sx={{ mt: 3, height: "60%" }} disabled={startTime && endTime ? false : true}> Add </StyledButton>
                  </Grid>
                </Grid>
                <Box sx={{ mt: 1 }}>
                  {timeDurationList?.map((t, ind) =>
                    <StyledChip
                      variant="outlined"
                      key={ind}
                      color="primary"
                      label={t?.label}
                      sx={{}}
                      deleteIcon={<Cancel />}
                      onDelete={(e) => { deleteTime(t?.label, timeDurationList, setTimeDurationList) }} />
                  )}
                </Box>
              </Box>
            }
            {
              params?.durationInMins &&
              <Grid item container xs={12} sx={{ mt: 1 }} spacing={1}>
                <Grid item xs={12}>
                  <CustomTypography
                    weight={600}
                    color={"#000000CC"}
                    size={16}
                    lineHeight={20}
                    content="Set Duration in Minutes"
                    styles={{ marginBottom: "0px", marginTop: "10px", ...customsx?.subheader }}
                  />
                </Grid>

                <Grid item xs={12} md={5} lg={5}>
                  <StyledTextfield
                    fullWidth
                    error={error?.durationInMins ? true : false}
                    helperText={error?.durationInMins}
                    value={durationInMins}
                    disabled={params?.durationInMins === "disabled" ? true : (timeList?.length > 0 || timeDurationList?.length > 0) ? false : true}
                    type="number"
                    inputProps={{ min: 1, max: 10080 }}
                    onChange={(event, objkey = null, data = "") => { changeDurationInMins(event?.target?.value) }}
                  >
                  </StyledTextfield>
                </Grid>
              </Grid>
            }
          </Box>
        }
        {params?.dayOfMonth &&
          <Box className="scheduleBox" sx={{ ...customsx?.body }} >
            <Grid container item xs={12} spacing={1}>
              <Grid item xs={12}>
                <CustomTypography
                  weight={600}
                  color={"#000000CC"}
                  size={16}
                  lineHeight={20}
                  content="Days of Month"
                  styles={{ marginBottom: "0px", ...customsx?.subheader }}
                />

              </Grid>
              <Grid item xs={12} >
                <ChipInput
                  placeholder="Add Days "
                  // width="170px"
                  options={daysOfMonth}
                  value={dayList}
                  disabled={params?.dayOfMonth === "disabled" ? true : false}
                  onChange={(e) => { setDayList(e?.target?.value) }}
                  chipPosition="bottom"
                />
              </Grid>
            </Grid>
          </Box>
        }
        {
          params?.dayOfWeek &&
          <Box className="scheduleBox" sx={{ ...customsx?.body }} >

            <Grid container item xs={12} >
              <Grid item xs={12}>
                <CustomTypography
                  weight={600}
                  color={"#000000CC"}
                  size={16}
                  lineHeight={20}
                  content="Week Days"
                  styles={{ marginBottom: "0px", ...customsx?.subheader }}
                />
              </Grid>
              {
                daysOfWeekCheckboxes()
              }

            </Grid>
          </Box>
        }
        {
          params?.day &&
          <Box className="scheduleBox" sx={{ ...customsx?.body }} >

            <Grid container item xs={12} >
              <Grid item xs={12}>
                <CustomTypography
                  weight={600}
                  color={"#000000CC"}
                  size={16}
                  lineHeight={20}
                  content="Set Day"
                  styles={{ marginBottom: "0px", ...customsx?.subheader }}
                />
              </Grid>
              <FormControl >
                <RadioGroup
                  row
                  className="dayChoice"
                  name="row-radio-buttons-group"
                  value={dayChoice}
                >
                  <FormControlLabel value="dayOfMonth" control={<Radio onChange={(e, value) => { setDayChoice(value ? "dayOfMonth" : "weekDay"); setDaysOfWeek(daysOfWeek?.map(d => { d.value = false; return d; })) }} />} label="Day of Month" />
                  <FormControlLabel value="weekDay" control={<Radio onChange={(e, value) => { setDayChoice(value ? "weekDay" : "dayOfMonth"); setDayList([]) }} />} label="Day of Week" />
                </RadioGroup>
              </FormControl>
              {
                dayChoice === "dayOfMonth" ?
                  <Grid item xs={12} >
                    <ChipInput
                      placeholder="Add Days "
                      // width="170px"
                      options={daysOfMonth}
                      value={dayList}
                      disabled={params?.dayOfMonth === "disabled" ? true : false}
                      onChange={(e) => { setDayList(e?.target?.value) }}
                      chipPosition="bottom"
                    />
                  </Grid> :
                  daysOfWeekCheckboxes()
              }
            </Grid>
          </Box>
        }
        {
          params?.month &&
          <Box className="scheduleBox" sx={{ ...customsx?.body }} >

            <Grid container item xs={12} spacing={1}>
              <Grid item xs={12}>
                <CustomTypography
                  weight={600}
                  color={"#000000CC"}
                  size={16}
                  lineHeight={20}
                  content="Months"
                  styles={{ marginBottom: "0px", ...customsx?.subheader }}
                />
              </Grid>

              <Grid item xs={12}  >
                <ChipInput placeholder="Add Months "
                  // width={"170px"}
                  options={months}
                  key={"month"}
                  onChange={(e) => { setMonthList(e?.target?.value) }}
                  disabled={params?.month === "disabled" ? true : false}
                  value={monthList}
                  chipPosition="bottom"
                />
              </Grid>

            </Grid>

          </Box>
        }


      </Grid>
      <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%", mt: 3 }}>
        {actionButtons?.map((button, index) =>
          button?.name.toLowerCase() === "save" ?
            <StyledButton key={"btnnnnnn-" + index} sx={{ ml: 1 }} variant={button?.variant || "contained"} onClick={saveSchedule}> {button?.label} </StyledButton> :
            <StyledButton key={"btnnnnnn-" + index} sx={{ ml: 1 }} variant={button?.variant || "contained"} onClick={button?.action}> {button?.label} </StyledButton>
        )}
      </Box>
    </Box>
  )
};

StyledScheduler.propTypes = {};

StyledScheduler.defaultProps = {};

export default StyledScheduler;
