/***
 *
 * Controller class for user.
 * @file StyledChip.js
 * @description StyledChip component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './StyledChip.style.js';
import './StyledChip.scss';
import { Avatar, Chip } from '@mui/material';
import { ReactComponent as DeleteIcon } from '../../assets/icons/cancel.svg';
import { ReactComponent as DeleteIconTrash } from '../../assets/icons/delete.svg';

const StyledChip = ({color="lightGreen", variant="outlined", image=null, rounded=true, sx={}, style={}, trashIcon=false, ...props}) => {
  const classes = useStyles("kkk");

  return (
        <Chip
          className={classes?.StyledChip || ""} 
          data-testid="StyledChip"
          variant={variant}
          color={color}
          sx={{ marginRight: "10px", marginTop: "5px", marginBottom: "5px", ...{borderRadius: (rounded ? "30px" : "4px")}, ...sx }}
          style={{...style}}
          deleteIcon={!trashIcon ? <DeleteIcon /> : <DeleteIconTrash />}
          avatar={image && <Avatar src={image} sx={{borderRadius: (rounded ? "30px" : "3px")}} />}
          {...props} />
    )
};


{/* <Chip deleteIcon={<DoneIcon />} onDelete={handleDelete} avatar={<Avatar src="/static/images/avatar/1.jpg" />} /> */}


StyledChip.propTypes = {};

StyledChip.defaultProps = {};

export default StyledChip;
