/**
 * 
 * @name = Private Routes
 * @description = Private Routes vary based on the Apps, For example, 
 * Dashboard, User Profile, App Settings, Home etc. In simple words, 
 * These routes can be accessed only after login.
 * 
 * @author = Naveen Kumar
 * @since = 07 Oct, 2021
 * 
 */

import { Route, Redirect } from 'react-router-dom';
// import SideBar from '../components/SideBar';
//  import TopNavigation from '../components/TopNavigation';
import MainLayout from '../layouts/MainLayout';
import { getWindowDimensions } from '../services';


const PrivateRoute = ({ children, isAuthenticated, routes, ...rest }) => {
    return (
        <>
            <Route
                {...rest}
                render={
                    ({ location }) => (isAuthenticated ? (
                        <>
                            {/* ----- {JSON.stringify(routes)} ----- */}
                            <MainLayout routes={routes} style={{ backgroundColor: (location?.pathname === "/" || location?.pathname === "/spaces/dashboard") ? "transparent" : "#fff", padding: location?.pathname === "/" ? (getWindowDimensions()?.width > 800 ? "10px" : "0px") : "" }}>
                                {children}
                            </MainLayout>
                        </>
                    ) : (<Redirect to={{ pathname: '/login', state: { from: location } }} />))
                }
            />
        </>
    );
}

export default PrivateRoute;

