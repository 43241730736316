import { makeStyles } from '@mui/styles';

export const customsx = {
    StyledChip: {
        margin: "10px", marginBottom: "10px", height: "24px !important", paddingRight: "3px !important",
        width:'min-content'
    }
}

export const useStyles = makeStyles({
    UserProfile: {

    },   
    label2:{
        color:'#0F8D48', 
        textDecoration:'underline', 
        cursor:'pointer'
    },
    subHeading:{
        background: "rgba(15, 141, 72, 0.05)",
        padding: "1rem 2rem 1rem 1rem"
    }
});

