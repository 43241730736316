export const getSessionItem=(name)=>{
    return sessionStorage.getItem(name);
};
export const getLocalItem=(name)=>{
    return localStorage.getItem(name);
};

export const setSessionItem=(name,value)=>{
    sessionStorage.setItem(name, value);
};
export const setLocalItem=(name,value)=>{
    localStorage.setItem(name,value);
};

export const removeLocalItem=(name)=>{
    localStorage.removeItem(name);
};
export const removeSessionItem=(name)=>{
    sessionStorage.removeItem(name);
};

export const clearSessionStorage=()=>{
    sessionStorage.clear();
};
export const clearLocalStorage=()=>{
    localStorage.clear();
};
