import { makeStyles } from "@mui/styles";


export const useStyles = makeStyles((theme)=>({
    TopNavigation: {
        height: "57px",
        padding: "0",
        paddingRight:'0 !important',
        width: "100%",
        '& .MuiToolbar-regular': {
            "['@media (min-width:600px)']": {
                minHeight: "57px"
            },
            minHeight: "57px",
            '& .logo': {
                padding: "0px"
            }
        }
    },
    vBar: {
        height: "29px",
        borderRight: "1px solid #00000099",
        marginLeft: "10px",
        marginRight: "10px"
    },
    Avatar:{
        '&.MuiAvatar-root':{
            width:'70px',
            height:'70px',
            border:'5px solid #7cc7ff'
        }
    }
}));