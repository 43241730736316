import _ from 'lodash';
import { sceneTypes } from '../types';
let initialState = {
    loading: false,
    sceneList: [],
    scene: {},
    item: null,
    error: null,
    lastAction: null,
    sceneDeleted: null,
    multipleScenesList:[]
}

export function scenes(state = initialState, action) {
    switch (action.type) {
        case sceneTypes.ADD_REQUEST:
        case sceneTypes.GET_SCENES_REQUEST:
        case sceneTypes.GET_SCENE_DETAILS_REQUEST:
        case sceneTypes.UPDATE_REQUEST:
        case sceneTypes.SCENE_SYNC_REQUEST:
        case sceneTypes.SYNC_SCENES_BY_DEVICE_REQUEST:
        case sceneTypes.DELETE_SCENES_BY_DEVICE_REQUEST:
        case sceneTypes.DELETE_REQUEST:
        case sceneTypes.SCENE_APPLY_REQUEST:
        case sceneTypes.GET_MULTIPLE_SCENES_REQUEST:
            return {
                ...state,
                loading: true
            };
        case sceneTypes.GET_SCENE_DETAILS_SUCCESS:
            return {
                ...state,
                scene: action.payload,
                sceneList: _.isEmpty(state.sceneList)
                    ? [action.payload]
                    : state?.sceneList?.map((item) =>
                        item.sceneId === action.payload.sceneId ? action.payload : item
                    ),
                lastAction: "get_scene" + Date.now(),
                loading: false
            }
        case sceneTypes.GET_SCENES_SUCCESS:
            return {
                ...state,
                sceneList: action.payload,
                loading: false,
                lastAction: "get_scene_list" + Date.now(),
            }
        case sceneTypes.UPDATE_SUCCESS:
            return {
                ...state,
                sceneList: state?.sceneList?.map(s => { if (s.sceneId === action.payload.sceneId) { return action.payload; } else { return s; } }),
                item: action.payload,
                scene: action?.payload,
                lastAction: "update_scene" + Date.now(),
                loading: false
            }
        case sceneTypes.ADD_SUCCESS:
            return {
                ...state,
                item: action.payload,
                lastAction: "add_scene" + Date.now(),
                loading: false
            }
        case sceneTypes.SCENE_SYNC_SUCCESS:
            return {
                ...state,
                loading: false,
            }
        case sceneTypes.DELETE_SUCCESS:
            return {
                ...state,
                sceneList: state?.sceneList?.filter(s => s.sceneId !== action.payload),
                item: "",
                lastAction: "delete_scene" + Date.now(),
                loading: false
            }
        case sceneTypes?.UPDATE_DELETE_SCENE_STATUS:
            return {
                ...state,
                sceneDeleted: action?.payload?.deleteSceneStatus
            }

        case sceneTypes.SYNC_SCENES_BY_DEVICE_SUCCESS:
            return {
                ...state,
                loading: false,
                lastAction: "sync_scenes_by_device" + Date.now()
            }
        case sceneTypes.DELETE_SCENES_BY_DEVICE_SUCCESS:
            return {
                ...state,
                loading: false,
                lastAction: "delete_scenes_by_device" + Date.now(),
                sceneDeleted: "delete_scenes_by_device" + Date.now()
            }
        case sceneTypes.SCENE_APPLY_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case sceneTypes.UPDATE_SCENE_STATUS:
            return {
                ...state,
                sceneList: state?.sceneList?.map(s => {
                    if (s?.sceneId === action?.payload?.sceneId) {
                        s.synced = action?.payload?.syncStatus
                    }
                    return s;
                }),
                scene: state?.scene?.sceneId === action?.payload?.sceneId ? { ...state?.scene, synced: action?.payload?.syncStatus } : state?.scene,
                loading: false
            };
        case sceneTypes.GET_SCENES_FAILURE:
            return {
                ...state,
                error: action.payload.error,
                sceneList: [],
                loading: false
            };
        case sceneTypes.GET_MULTIPLE_SCENES_SUCCESS:
            return {
                ...state,
                multipleScenesList: [...action?.payload],
                loading: false
            };
        case sceneTypes.ADD_FAILURE:
        case sceneTypes.UPDATE_FAILURE:
        case sceneTypes.SCENE_SYNC_FAILURE:
        case sceneTypes.DELETE_FAILURE:
        case sceneTypes.SYNC_SCENES_BY_DEVICE_FAILURE:
        case sceneTypes.DELETE_SCENES_BY_DEVICE_FAILURE:
        case sceneTypes.SCENE_APPLY_FAILURE:
        case sceneTypes.GET_MULTIPLE_SCENES_FAILURE:
            return {
                ...state,
                error: action.payload.error,
                loading: false
            };
        default:
            return state
    }
}