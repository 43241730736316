import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme)=>({
    StyledSpaceTreeDropDown: {
        position: 'relative',
        '& .ChipArea': {
            width: '-webkit-fill-available',
            position: "relative",
            border: '1px solid #0000003b',
            borderRadius: theme?.borderRadius?.input || "4px",
            // padding: theme?.padding?.input || "8px 10px",
            padding: "1px 10px",
            paddingRight: "40px",
            fontSize: theme?.fontSize?.input || "0.875rem",
            lineHeight: theme?.lineHeight?.input || "18px",
            minHeight: theme?.minHeight?.input || "40px",            
            '&:hover': {
                borderColor: "#000000",
                cursor: "pointer"
            },
            '&:focus': {
                borderColor: "#0F8D48",
                borderWidth: "2px"
            },
            '& .placeholder': {
                color: "#d3d3d3",
                display: "inline-block",
                lineHeight: "35px"
            }
        },
        '& .disabled': {
            '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.38)',
            'cursor': "pointer !important",
            '&:hover': {
                'borderColor': "rgba(0, 0, 0, 0.25) !important",
                'cursor': "default !important"
            },
            '&:focus': {
                'borderColor': "#0F8D48"
            },
            '& .placeholder': {
                'color': "#d3d3d3",
                display: "inline-block",
                lineHeight: "38px"
            },
            '&::after': {
                borderTopColor: "rgba(0, 0, 0, 0.26) !important"
            }
        },
        '& .selectedBorder': {
            borderColor: "#0F8D48",
            borderWidth: "2px",
            // minHeight: "40px",
            lineHeight: "36px !important",
            '&:hover': {
                borderColor: "#0F8D48 !important"
            },
            '& .placeholder': {
                lineHeight: "36px !important"
            },
        },
        '& .StyledSpaceTreeDropDownList': {
            position: "absolute",
            minHeight: "40px",
            top: "calc(100% + 0px)",
            background: "#fff",
            zIndex: 1060,
            boxShadow: "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
            borderRadius: "4px",
            width: "100%",
            padding: "0px 0px 10px 0px"
        }
    }
}));
