/***
 *
 * Controller class for user.
 * @file Reports.js
 * @description Reports component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React from 'react';
import { useStyles } from './Reports.style.js';
import { useQuery } from "../../hooks";

import ReportList from "../../components/ReportList/index.js";
import ReportDetails from '../../components/ReportDetails/index.js';


const Reports = () => {
  const styles = useStyles()
  const query = useQuery();
  const op = query.get("op") || null;
  const rId = query.get("reportId") || null;

  return (
    <div className={styles?.Reports || ""} data-testid="Reports">
    {
    (()=>{
      switch(op){
        case 'create':
          return <ReportDetails op={op}/> 
        case 'view':
        case 'edit':
          return <ReportDetails op={op} rId={rId}/>
        default:
          return <ReportList />
      }
    })()
    }
    </div>
  );
};

Reports.propTypes = {};

Reports.defaultProps = {};

export default Reports;
