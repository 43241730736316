/***
 *
 * Controller class for user.
 * @file StyledStatus.js
 * @description StyledStatus component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './StyledStatus.style.js';
import './StyledStatus.scss';
import { Box } from '@mui/material';
import StyledChip from '../StyledChip/index.js';

const StyledStatus = ({statusMessage = '', statusColor = '#0F8D48', sx={}}) => {
  const classes = useStyles();
  const [message, setMessage] = useState('');

  useEffect(() => {
    if(statusMessage) {
      let updatedMessage = statusMessage.charAt(0).toUpperCase() + statusMessage.slice(1);;
      setMessage(updatedMessage);
    }
  }, [statusMessage])

  return (
    <Box className={classes?.StyledStatus || ""} data-testid="StyledStatus" sx={sx}>
      <StyledChip 
        label={message} 
        variant='outlined' 
        icon={<span className={classes?.dotStatus} style={{backgroundColor: `${statusColor}` }}></span>} 
        sx={{'& .MuiChip-label':{color: `${statusColor}`, p:0, mr: '6px', ml: '8px', fontSize: '0.625rem', lineHeight: '10px'}, mt: 0, mr: 0, mb: 0, maxHeight: '18px', border: `1.5px solid ${statusColor}`, fontWeight: '500'}} 
      />
    </Box>
  )
};

StyledStatus.propTypes = {};

StyledStatus.defaultProps = {};

export default StyledStatus;
