/***
 *
 * Controller class for user.
 * @file DeviceConfigurations.js
 * @description DeviceConfigurations component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './DeviceConfigurations.style.js';
import FormWrapper from '../FormWrapper'
import './DeviceConfigurations.scss';
import { Box, Grid } from '@mui/material';
import CustomAccordion from '../CustomAccordion/index.js';
import ImageWrapper from '../ImageWrapper/index.js';
import _ from 'lodash';
import { deviceActions, groupActions, sceneActions } from '../../redux/actions/index.js';
import { useDispatch, useSelector } from 'react-redux';
import CustomTypography from '../CustomTypography/index.js';
import CustomTable from '../CustomTable/index.js';
import StyledChip from '../StyledChip/index.js';
import { SWITCH_ACTION_TYPES as switchActionTypes } from '../../constants/device.constants.js';
import { SWITCH_FUNCTION_TYPES as switchFunctionTypes } from '../../constants/device.constants.js';
import ChipInput from '../ChipInput/index.js';
import Temperature from "../../assets/icons/temperature.svg"
import Delete from "../../assets/icons/delete.svg"
import { ReactComponent as Cancel } from "../../assets/icons/cancel.svg"
import PIRIcon from '../../assets/icons/group.svg'
import ALSIcon from '../../assets/icons/alsIcon.svg'
import CustomDropDown from '../CustomDropDown/index.js';
import FourButtonSwitch from "../../assets/icons/4buttonswitch.svg"
import SixButtonSwitch from "../../assets/icons/6buttonswitch.svg"
import EightButtonSwitch from "../../assets/icons/8buttonswitch.svg"
import TenButtonSwitch from "../../assets/icons/10buttonswitch.svg"
import groupIcon from '../../assets/icons/group.svg'
import lightIcon from '../../assets/icons/bulb.svg'
import sceneIcon from '../../assets/icons/scene.svg'
import { useRef } from 'react';
import CustomLabel from '../CustomLabel/index.js';
import AddIcon from "../../assets/icons/add.svg";
import { getMessageStr } from '../../helpers/message.helper.js';
import StyledPopupWrapper from '../StyledPopupWrapper/index.js';
import { confirmationDialog, disableDeviceOperation } from '../../helpers/device.helper.js';
import { useQuery } from '../../hooks/useQuery.js';
import { isAuthorised } from '../../helpers/users.helper.js';
import { features, permissions } from '../../constants/permission.constants.js';
import StyledButton from '../StyledButton/index.js';

const switchIcons = {
  "4buttonswitch": FourButtonSwitch,
  "6buttonswitch": SixButtonSwitch,
  "8buttonswitch": EightButtonSwitch,
  "10buttonswitch": TenButtonSwitch,

}
let defaultSensorConfig = {};
const frmConfig = (deviceModel) => {
  const capabilities = {
    pirSleep: deviceModel?.configCapabilities?.find(c => c?.capability === "pirSleep")?.attributes[0]?.range,
    pirWaitWatch: deviceModel?.configCapabilities?.find(c => c?.capability === "pirWaitWatch")?.attributes[0]?.range,
    pirRetransmissionTimeout: deviceModel?.configCapabilities?.find(c => c?.capability === "pirRetransmissionTimeout")?.attributes[0]?.range,
    tempThreshold: deviceModel?.configCapabilities?.find(c => c?.capability === "tempThreshold")?.attributes[0]?.range,
    humThreshold: deviceModel?.configCapabilities?.find(c => c?.capability === "humThreshold")?.attributes[0]?.range,
    tempHumFreq: deviceModel?.configCapabilities?.find(c => c?.capability === "tempHumFreq")?.attributes[0]?.range,
    alsThreshold: deviceModel?.configCapabilities?.find(c => c?.capability === "alsThreshold")?.attributes[0]?.range,
    alsSensingTime: deviceModel?.configCapabilities?.find(c => c?.capability === "alsSensingTime")?.attributes[0]?.range,
    alsMultiplicationFactor: deviceModel?.configCapabilities?.find(c => c?.capability === "alsMultiplicationFactor")?.attributes[0]?.range,
    alsC: deviceModel?.configCapabilities?.find(c => c?.capability === "alsC")?.attributes[0]?.range,
    alsM: deviceModel?.configCapabilities?.find(c => c?.capability === "alsM")?.attributes[0]?.range
  }

  return [
    {
      section: { heading: "Passive Infrared (PIR) Configuration", icon: PIRIcon, style: { margin: "8px" } },
      columns: [
        // {
        //   id: "1",
        //   type: "number",
        //   label: "PIR Sleep (sec)",
        //   name: "pirSleep",
        //   placeholder: "Enter Passive Infrared Sleep",
        //   required: true,
        //   disabledOnEdit: false,
        // min: capabilities?.pirSleep?.min[0]?.value,
        // max: capabilities?.pirSleep?.max[0]?.value,
        //   validation: {
        //     req: [true, getMessageStr("sensor-config-pirSleepRequired")],
        //     range: [[capabilities?.pirSleep?.min[0]?.value, capabilities?.pirSleep?.max[0]?.value], getMessageStr("sensor-config-pirSleepRange",{min:capabilities?.pirSleep?.min[0]?.value,max:capabilities?.pirSleep?.max[0]?.value})],
        //     customRegex: [/^-?\d*$/, getMessageStr("sensor-config-pirSleepInteger")]
        //   },
        //   columnSize: 4
        // },
        {
          id: "12",
          type: "number",
          label: "PIR Unoccupancy Timeout (sec)",
          name: "pirWaitWatch",
          placeholder: "Enter Passive Infrared Unoccupancy Timeout",
          required: true,
          disabledOnEdit: false,
          min: capabilities?.pirWaitWatch?.min[0]?.value,
          max: capabilities?.pirWaitWatch?.max[0]?.value,
          validation: {
            req: [true, getMessageStr("sensor-config-pirUnoccupancyRequired")],
            range: [[capabilities?.pirWaitWatch?.min[0]?.value, capabilities?.pirWaitWatch?.max[0]?.value], getMessageStr("sensor-config-pirUnoccupancyRange", { min: capabilities?.pirWaitWatch?.min[0]?.value, max: capabilities?.pirWaitWatch?.max[0]?.value })],
            customRegex: [/^-?\d*$/, getMessageStr("sensor-config-pirUnoccupancyInteger")]
          },
          columnSize: 4
        },
        {
          id: "11",
          type: "number",
          label: "PIR Periodic Transmission (sec)",
          name: "pirRetransmissionTimeout",
          placeholder: "Enter Passive Infrared Periodic Transmission",
          required: true,
          disabledOnEdit: false,
          min: capabilities?.pirRetransmissionTimeout?.min[0]?.value,
          max: capabilities?.pirRetransmissionTimeout?.max[0]?.value,
          validation: {
            req: [true, getMessageStr("sensor-config-pirTransmissionRequired")],
            range: [[capabilities?.pirRetransmissionTimeout?.min[0]?.value, capabilities?.pirRetransmissionTimeout?.max[0]?.value], getMessageStr("sensor-config-pirTransmissionRange", { min: capabilities?.pirRetransmissionTimeout?.min[0]?.value, max: capabilities?.pirRetransmissionTimeout?.max[0]?.value })],
            customRegex: [/^-?\d*$/, getMessageStr("sensor-config-pirTransmissionInteger")]
          },
          columnSize: 4
        },
      ]
    },
    {
      section: { heading: "Temperature & Humidity Configuration", icon: Temperature, style: { margin: "8px" } },
      columns: [
        {
          id: "13",
          type: "number",
          label: "Temperature Threshold (°C)",
          name: "tempThreshold",
          required: true,
          placeholder: "Enter Temperature Threshold",
          disabledOnEdit: false,
          min: 0,
          max: 5,
          min: capabilities?.tempThreshold?.min[0]?.value,
          max: capabilities?.tempThreshold?.max[0]?.value,
          validation: {
            req: [true, getMessageStr("sensor-config-tempThresholdRequired")],
            range: [[capabilities?.tempThreshold?.min[0]?.value, capabilities?.tempThreshold?.max[0]?.value], getMessageStr("sensor-config-tempThresholdRange", { min: capabilities?.tempThreshold?.min[0]?.value, max: capabilities?.tempThreshold?.max[0]?.value })]
          },
          columnSize: 4
        },
        {
          id: "15",
          type: "number",
          label: "Humidity Threshold (%RH)",
          name: "humThreshold",
          required: true,
          placeholder: "Enter Humidity Threshold",
          disabledOnEdit: false,
          min: capabilities?.humThreshold?.min[0]?.value,
          max: capabilities?.humThreshold?.max[0]?.value,
          validation: {
            req: [true, getMessageStr("sensor-config-humThresholdRequired")],
            range: [[capabilities?.humThreshold?.min[0]?.value, capabilities?.humThreshold?.max[0]?.value], getMessageStr("sensor-config-humThresholdRange", { min: capabilities?.humThreshold?.min[0]?.value, max: capabilities?.humThreshold?.max[0]?.value })],
            customRegex: [/^-?\d*$/, getMessageStr("sensor-config-humThresholdInteger")]
          },
          columnSize: 4
        },
        {
          id: "16",
          type: "number",
          label: "Temperature Humidity Frequency (sec)",
          name: "tempHumFreq",
          required: true,
          placeholder: "Enter Temperature Humidity Frequency",
          disabledOnEdit: false,
          min: capabilities?.tempHumFreq?.min[0]?.value,
          max: capabilities?.tempHumFreq?.max[0]?.value,
          validation: {
            req: [true, getMessageStr("sensor-config-temFreqRequired")],
            range: [[capabilities?.tempHumFreq?.min[0]?.value, capabilities?.tempHumFreq?.max[0]?.value], getMessageStr("sensor-config-temFreqRange", { min: capabilities?.tempHumFreq?.min[0]?.value, max: capabilities?.tempHumFreq?.max[0]?.value })],
            customRegex: [/^-?\d*$/, getMessageStr("sensor-config-temFreqInteger")]
          },
          columnSize: 4
        },
      ]
    },
    {
      section: { heading: "Ambient Light (ALS) Configuration", icon: ALSIcon, style: { margin: "8px" } },
      columns: [
        {
          id: "18",
          type: "number",
          label: "ALS Threshold (lux)",
          name: "alsThreshold",
          required: true,
          placeholder: "Enter Ambient Light Threshold (lux)",
          disabledOnEdit: false,
          min: capabilities?.alsThreshold?.min[0]?.value,
          max: capabilities?.alsThreshold?.max[0]?.value,
          validation: {
            req: [true, getMessageStr("sensor-config-alsThresholdRequired")],
            range: [[capabilities?.alsThreshold?.min[0]?.value, capabilities?.alsThreshold?.max[0]?.value], getMessageStr("sensor-config-alsThresholdRange", { min: capabilities?.alsThreshold?.min[0]?.value, max: capabilities?.alsThreshold?.max[0]?.value })],
            customRegex: [/^-?\d*$/, getMessageStr("sensor-config-alsThresholdInteger")]
          },
          columnSize: 4
        },
        {
          id: "19",
          type: "number",
          label: "ALS Sensing Time (sec)",
          name: "alsSensingTime",
          required: true,
          placeholder: "Enter Ambient Light Sensing Time",
          disabledOnEdit: false,
          min: capabilities?.alsSensingTime?.min[0]?.value,
          max: capabilities?.alsSensingTime?.max[0]?.value,
          validation: {
            req: [true, getMessageStr("sensor-config-alsTimeRequired")],
            range: [[capabilities?.alsSensingTime?.min[0]?.value, capabilities?.alsSensingTime?.max[0]?.value], getMessageStr("sensor-config-alsTimeRange", { min: capabilities?.alsSensingTime?.min[0]?.value, max: capabilities?.alsSensingTime?.max[0]?.value })],
            customRegex: [/^-?\d*$/, getMessageStr("sensor-config-alsTimeInteger")]
          },
          columnSize: 4
        },
        // {
        //   id: "20",
        //   type: "number",
        //   label: "Multiplication Factor",
        //   name: "alsMultiplicationFactor",
        //   required: true,
        //   placeholder: "Enter Multiplication Factor",
        //   disabledOnEdit: false,
        // min: capabilities?.alsMultiplicationFactor?.min[0]?.value,
        // max: capabilities?.alsMultiplicationFactor?.max[0]?.value,
        //   validation: {
        //     req: [true, getMessageStr("sensor-config-alsMulRequired")],
        //     range: [[capabilities?.alsMultiplicationFactor?.min[0]?.value, capabilities?.alsMultiplicationFactor?.max[0]?.value], getMessageStr("sensor-config-alsMulRange",{min:capabilities?.alsMultiplicationFactor?.min[0]?.value,max:capabilities?.alsMultiplicationFactor?.max[0]?.value})],
        //     customRegex: [/^-?\d*$/, getMessageStr("sensor-config-alsMulInteger")]
        //   },
        //   columnSize: 4
        // },
        // {
        //   id: "23",
        //   type: "number",
        //   label: "M",
        //   name: "alsM",
        //   required: true,
        //   placeholder: "Enter M",
        //   disabledOnEdit: false,
        // min: capabilities?.alsM?.min[0]?.value,
        // max: capabilities?.alsM?.max[0]?.value,
        //   validation: {
        //     req: [true, getMessageStr("sensor-config-alsMRequired")],
        //     range: [[capabilities?.alsM?.min[0]?.value,capabilities?.alsM?.max[0]?.value], getMessageStr("sensor-config-alsMRange",{min:capabilities?.alsM?.min[0]?.value,max:capabilities?.alsM?.max[0]?.value})]
        //   },
        //   columnSize: 3
        // },
        // {
        //   id: "24",
        //   type: "number",
        //   label: "C",
        //   name: "alsC",
        //   required: true,
        //   placeholder: "Enter C",
        //   disabledOnEdit: false,
        // min: capabilities?.alsC?.min[0]?.value,
        // max: capabilities?.alsC?.max[0]?.value,
        //   validation: {
        //     req: [true, getMessageStr("sensor-config-alsCRequired")],
        //     range: [[capabilities?.alsC?.min[0]?.value, capabilities?.alsC?.max[0]?.value], getMessageStr("sensor-config-alsCRange",{min:capabilities?.alsC?.min[0]?.value,max:capabilities?.alsC?.max[0]?.value})]
        //   },
        //   columnSize: 3
        // },
      ]

    }
  ]
};

const transformConfig = (configs) => {
  if (_.isArray(configs)) {
    configs.sort((a, b) => a?.configId - b?.configId)
    configs = configs?.map((c, index) => {
      c.configId = index + 1
      return c;
    })
    const newConfigs = configs?.map((config) => {
      const newConfig = {}
      for (const prop in config) {
        if (typeof config[prop] === "object" && prop !== "trigger") {
          for (const p in config[prop]) {
            newConfig[p] = config[prop][p]
          }

        }
        else {
          newConfig[prop] = config[prop]
        }
      }
      return newConfig
    })
    return newConfigs || []
  }

}
const buildTemplate = (deviceModel) => {
  let template = []
  const capabilities = deviceModel?.statusCapabilities
  if (capabilities?.find((c) => c?.capability === "occupancy")) {
    template.push(frmConfig(deviceModel)?.find((f) => f?.section?.heading?.includes("Passive Infrared")))
    defaultSensorConfig.pirEnabled = deviceModel?.configCapabilities?.find(c => c?.capability === "pirEnabled")?.attributes[0]?.defaultValue;;
    defaultSensorConfig.pirRetransmissionTimeout = deviceModel?.configCapabilities?.find(c => c?.capability === "pirRetransmissionTimeout")?.attributes[0]?.defaultValue;
    defaultSensorConfig.pirSleep = deviceModel?.configCapabilities?.find(c => c?.capability === "pirSleep")?.attributes[0]?.defaultValue;
    defaultSensorConfig.pirWaitWatch = deviceModel?.configCapabilities?.find(c => c?.capability === "pirWaitWatch")?.attributes[0]?.defaultValue;
  }
  if (capabilities?.find((c) => c?.capability === "temperature")) {
    template.push(frmConfig(deviceModel)?.find((f) => f?.section?.heading?.includes("Temperature & Humidity")))
    defaultSensorConfig.humThreshold = deviceModel?.configCapabilities?.find(c => c?.capability === "humThreshold")?.attributes[0]?.defaultValue;;
    defaultSensorConfig.tempHumFreq = deviceModel?.configCapabilities?.find(c => c?.capability === "tempHumFreq")?.attributes[0]?.defaultValue;;
    defaultSensorConfig.tempThreshold = deviceModel?.configCapabilities?.find(c => c?.capability === "tempThreshold")?.attributes[0]?.defaultValue;;
    defaultSensorConfig.thEnabled = deviceModel?.configCapabilities?.find(c => c?.capability === "thEnabled")?.attributes[0]?.defaultValue;;
  }
  if (capabilities?.find((c) => c?.capability === "ambientLight")) {
    template.push(frmConfig(deviceModel)?.find((f) => f?.section?.heading?.includes("Ambient Light")))
    defaultSensorConfig.alsC = deviceModel?.configCapabilities?.find(c => c?.capability === "alsC")?.attributes[0]?.defaultValue;
    defaultSensorConfig.alsEnabled = deviceModel?.configCapabilities?.find(c => c?.capability === "alsEnabled")?.attributes[0]?.defaultValue;
    defaultSensorConfig.alsM = deviceModel?.configCapabilities?.find(c => c?.capability === "alsM")?.attributes[0]?.defaultValue;
    defaultSensorConfig.alsMultiplicationFactor = deviceModel?.configCapabilities?.find(c => c?.capability === "alsMultiplicationFactor")?.attributes[0]?.defaultValue;
    defaultSensorConfig.alsSensingTime = deviceModel?.configCapabilities?.find(c => c?.capability === "alsSensingTime")?.attributes[0]?.defaultValue;
    defaultSensorConfig.alsThreshold = deviceModel?.configCapabilities?.find(c => c?.capability === "alsThreshold")?.attributes[0]?.defaultValue;
  }
  return template;
}

const buildSwitchConfig = (switchButtonMap, buttonCount) => {
  const buttonMap = []
  for (let i = 1; i <= buttonCount; i++) {
    let button = { buttonNumber: i }
    const switchButton = switchButtonMap?.find(b => b?.buttonNumber === i)
    if (switchButton) {
      button = { ...switchButton }
    }
    buttonMap.push(button)

  }
  return buttonMap;
}

const DeviceConfigurations = ({ deviceData, setDialog, setDialogContents, ...props }) => {
  const classes = useStyles();
  const actionButtonsRef = useRef();
  const query = useQuery();
  const category = query.get("category") || "";

  const deviceModel = useSelector((state) =>
    state.devices.deviceModels.find((d) => d.modelId === deviceData?.modelId)
  );
  const [_deviceConfigs, set_DeviceConfigs] = useState(null)/// _<item> indicates that we're accessing data that was passed as a prop to the component; just naming convention; shudn't be confused with lodash
  const [deviceConfigs, setDeviceConfigs] = useState(null);
  const [popup, setPopup] = useState(false);
  const [popupContent, setPopupContent] = useState(null);

  const [changedSwitchConfig, setChangedSwitchConfig] = useState(null);
  // const [snackBar, setSnackBar] = useState({ show: false });
  const dispatch = useDispatch()
  let frmTemplate = buildTemplate(deviceModel);
  const [switchButtonMap, setSwitchButtonMap] = useState(null)
  const [error, setError] = useState(null)
  const [selectedScenes, setSelectedScenes] = useState([])
  const sceneList = useSelector((state) => state?.scenes?.sceneList)
  const groupList = useSelector((state) => state?.groups?.list)
  const deviceList = useSelector((state) => state?.devices?.deviceList)?.filter(d => d?.category.includes("LightDriver"))
  const controllerId = useSelector((state) => state?.devices?.parentDevice?.parentDeviceId)
  const [currentConfig, setCurrentConfig] = useState({});
  const [entityList, setEntityList] = useState([])
  const scenes = _.isArray(sceneList) && sceneList?.map((s, ind) => {
    return {
      id: s?.sceneId,
      name: s?.name,
      label: <Box><ImageWrapper src={sceneIcon} alt="scene" width={16} height={16} style={{ margin: "0px 4px" }} />{s?.name}</Box>,
      type: "scene"
    }
  })
  const groupsAndDevices = [
    ...groupList?.map(g => {
      return {
        id: g?.groupId,
        name: g?.name,
        label: <Box><ImageWrapper src={groupIcon} alt="group" width={16} height={16} style={{ margin: "0px 4px" }} />{g?.name}</Box>,
        icon: groupIcon,
        type: "group",
        groupType: g?.groupType
      }
    }),
    ...deviceList?.map(d => {
      return {
        id: d?.deviceId,
        icon: lightIcon,
        category: d?.category,
        name: d?.name || "-",
        label: <Box><ImageWrapper src={lightIcon} alt="light" width={16} height={16} />{d?.name}</Box>,
        type: "device"
      }
    })];

  const switchConfigHeaders = {
    buttonNumber: {
      label: "Button Name",
      type: "component",
      component: (row) => {
        return <Box className={`_${deviceData?.configuration?.buttonCount}ButtonSwitch`}>
          <ImageWrapper className={`_${row?.data?.buttonNumber}button`} src={switchIcons[`${deviceData?.configuration?.buttonCount}buttonswitch`]} alt={`${deviceData?.configuration?.buttonCount} button switch`} style={{ margin: "10px" }} />
          <span style={{ width: "100px" }}>Button {row?.data?.buttonNumber}</span>
        </Box>
      },
      style: { minWidth: 170 },
    },
    actionType: {
      type: "component",
      label: "Button Action - Function Type",
      style: {},
      component: (row) => {
        const data = row?.data
        const functionLabel = switchFunctionTypes.find(t => t.id === data?.functionType)?.label || ""
        const actionLabel = switchActionTypes.find(t => t.id === data?.actionType)?.label || ""
        const eList = [...groupsAndDevices, ...scenes];
        const entity = eList?.find(e => e?.id === data?.entityId)?.label || "";
        const chipLabel = actionLabel + " - " + functionLabel
        return (
          data?.actionType && <StyledChip
            variant="outlined"
            color="primary"
            // label={chipLabel}
            label={<CustomTypography
              size={12}
              lineHeight={32}
              weight={400}
              color={"#000000cc"}
            >
              <Box sx={{ display: "flex" }}>
                {chipLabel}
                {entity ? <>{" ("}{entity}{")"}</> : ""}
              </Box>
            </CustomTypography >}
            sx={{ marginRight: "10px", marginBottom: "10px" }}
            {...(() => {
              if (isAuthorised(features?.device, permissions?.configureButton) && !disableDeviceOperation(deviceData, "deviceConfig")) {
                return {
                  deleteIcon: < Cancel />,
                  onDelete: () => { deleteSwitchConfig(data?.buttonNumber) }
                }
              }
              else
                return {}
            })()}

          ></StyledChip >)
      }
    },
    ...(() => {
      if (isAuthorised(features?.device, permissions?.configureButton) && !disableDeviceOperation(deviceData, "deviceConfig")) {
        return {
          userActions: {
            label: "Actions",
            actions: [
              {
                label: "Add configuration",
                icon: AddIcon,
                type: "add",
                onClick: (event, data) => {
                  setChangedSwitchConfig({
                    buttonNumber: data?.buttonNumber,
                    actionType: switchActionTypes[0]?.id,
                    entity: null
                  });
                  setError({});
                  setPopupContent("switchConfig")
                  setPopup(true);
                },
                hidden: (row) => {
                  return (!isAuthorised(features?.device, permissions?.configureButtons) || disableDeviceOperation(deviceData, "deviceConfig"))
                },
                disabled: (row) => {
                  if (row?.actionType) {
                    return true;
                  }
                  return false;
                }
              }
            ],

            style: { textAlign: "right" },
          },
        }
      }

    })()

  }


  useEffect(() => {
    frmTemplate = buildTemplate(deviceModel)
    defaultSensorConfig = {}
  }, [deviceModel])

  useEffect(() => {
    setError(null);
    set_DeviceConfigs(deviceData?.configuration?.configs || [])
    setSwitchButtonMap(buildSwitchConfig(deviceData?.configuration?.buttonMap, deviceData?.configuration?.buttonCount));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceData?.configuration])

  useEffect(() => {
    console.log("here", deviceData?.configuration?.sceneIds, deviceData?.configuration?.sceneIds?.map(s => scenes?.find(scene => scene?.id === s)?.id))
    if (!_.isEmpty(sceneList)) {
      setSelectedScenes(deviceData?.configuration?.sceneIds?.map(s => scenes?.find(scene => scene?.id === s)?.name))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceData?.configuration?.sceneIds, sceneList])


  useEffect(() => {
    dispatch(deviceActions.getParentDevice(deviceData?.parentDeviceId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceData?.parentDeviceId])

  useEffect(() => {
    setDeviceConfigs(transformConfig(_deviceConfigs))
  }, [_deviceConfigs])

  useEffect(() => {
    if (controllerId && category === "Switch") {
      dispatch(sceneActions.getScenesByDeviceId("controller", controllerId))
      dispatch(deviceActions.getAllChildren(controllerId, "device"))
      dispatch(groupActions.getGroupsByDeviceId("controller", controllerId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controllerId])


  const saveSwitchConfig = () => {
    let buttonMaps = switchButtonMap?.filter(m => m?.actionType)
    const sceneAction = buttonMaps?.find(m => m?.functionType === "SCENE_NXT" || m?.functionType === "SCENE_PREV")
    if (sceneAction && _.isEmpty(selectedScenes)) {
      setError({ ...error, sceneList: getMessageStr("switch-config-sceneRequired") })
    }
    else {
      buttonMaps = buttonMaps?.map(m => {
        const obj = {}
        for (const key in m) {
          if (key !== "entityId" && key !== "entityType") {
            obj[key] = m[key];
          }
        }
        return obj;
      })
      dispatch(deviceActions.updateDeviceConfiguration(deviceData?.deviceId, { 'sceneIds': selectedScenes?.map(s => scenes?.find(scene => scene?.name === s)?.id), 'buttonMap': buttonMaps }, deviceData?.category.toLowerCase()))
    }
  }
  const updateSwitchConfig = () => {
    const id = changedSwitchConfig?.functionType;
    const c = id === "SCENE_NXT" || id === "SCENE_PREV" || id === "MASTER_ON_OFF"
    //conditions for no error
    if (!error?.functionType && id && (((id === "LIGHT_ON_OFF" || id === "LIGHT_DIM_UP" || id === "LIGHT_DIM_DOWN" || id === "SET_SCENE") && changedSwitchConfig?.entity !== null) || c)) {
      setSwitchButtonMap(switchButtonMap?.map(m => {
        if (m?.buttonNumber === changedSwitchConfig?.buttonNumber) {
          m.actionType = changedSwitchConfig?.actionType
          m.functionType = changedSwitchConfig?.functionType
          if (changedSwitchConfig?.entity) {
            const entity = entityList?.find(e => e?.id === changedSwitchConfig?.entity)
            m[entity?.type + "Id"] = changedSwitchConfig?.entity;
            m["entityId"] = changedSwitchConfig?.entity;
            m["entityType"] = entityList?.find(e => e?.id === changedSwitchConfig?.entity)?.type;
          }
          return m;
        }
        return m;
      }))
      setPopup(false);

    }
    else {//in case of error
      const fError = changedSwitchConfig?.functionType ? error?.functionType : getMessageStr("switch-config-functionTypeRequired");
      const eError = changedSwitchConfig?.entity ? error?.entity : getMessageStr("switch-config-entityRequired");
      setError({ ...error, entity: eError, functionType: fError })
    }
  }
  const deleteSwitchConfig = (buttonNumber) => {
    setSwitchButtonMap(switchButtonMap?.map(m => {
      if (m?.buttonNumber === buttonNumber) {
        return { buttonNumber: m?.buttonNumber };
      }
      return m;
    }))
  }

  const handleChangedConfig = (prop, value) => {
    const config = { ...changedSwitchConfig }
    config[prop] = value
    if (prop === "functionType") {
      config.entity = null
    }
    setChangedSwitchConfig(config)
    if (prop === "functionType") {
      setError({ ...error, functionType: null })
      if (value === "SET_SCENE" || value === "SCENE_NXT" || value === "SCENE_PREV") {
        if (_.isEmpty(scenes)) {
          setError({ ...error, functionType: getMessageStr("switch-config-noScene") })
        }
      }
      if (value === "SET_SCENE") {
        setEntityList(scenes)
      }
      else if (value === "LIGHT_ON_OFF") {
        setEntityList(groupsAndDevices)
        if (_.isEmpty(groupsAndDevices)) {
          setError({ ...error, functionType: getMessageStr("switch-config-noDeviceOrGroup") })
        }
      }
      else if (value === "LIGHT_DIM_UP" || value === "LIGHT_DIM_DOWN") {
        const devAndGrpList = groupsAndDevices?.filter(d => (d?.type === "device" && !(d?.category?.includes("Relay")) || (d?.type === "group" && !d?.groupType?.includes("LW_RELAY"))))//removing relay lights in case of dim up or dim down function
        if (_.isEmpty(devAndGrpList)) {
          setError({ ...error, functionType: getMessageStr("switch-config-noDeviceOrGroup") })
        }
        setEntityList(devAndGrpList)
      }
    }
    if (prop === "entity") {
      setError({ ...error, entity: null })
    }
  }


  const openScheduler = (event, config) => {
    setCurrentConfig(config);
    setPopupContent("schedule")
    setPopup(true);

  }

  const saveSchedule = (schedule) => {
    const sched = {
      minutes: schedule?.minutes,
      hours: schedule?.hours,
      type: "Time",
      month: schedule?.month,
      dayOfWeek: schedule?.weekDay || [],
      dayOfMonth: schedule?.day || []
    }
    setError(error?.map((e, key) => {
      if (key === currentConfig?.configId) {
        return false
      }
      else {
        return e;
      }
    }))
    setDeviceConfigs(deviceConfigs?.map(config => {
      if (config?.configId === currentConfig?.configId) {
        config.trigger = sched
        return config
      }
      return config
    }))
  }
  const saveSensorConfiguration = (e) => {
    const err = [];
    //Commenting to remove sensor config temporarily(search this comment to find all instances)
    // for (const configIndex in deviceConfigs) {
    //   const config = deviceConfigs[configIndex];
    //   if (_.isEmpty(config?.trigger)) {
    //     err[config?.configId] = true;
    //   }
    // }
    setError(err);
    actionButtonsRef?.current?.handleButton(e, {
      checkValidation: true,
      onClick: (event, data) => {
        let savedConfigs = [];
        for (const configIndex in deviceConfigs) {
          const config = deviceConfigs[configIndex];
          const configId = config?.configId;
          const changedConfig = {
            "configId": config?.configId,
            //Commenting to remove sensor config temporarily(search this comment to find all instances)
            // "trigger": config?.trigger,
          }
          const capabilities = deviceModel?.statusCapabilities;
          if (capabilities?.find((c) => c?.capability === "occupancy")) {
            changedConfig.pir = {};
          }
          if (capabilities?.find((c) => c?.capability === "temperature")) {
            changedConfig.th = {};

          }
          if (capabilities?.find((c) => c?.capability === "ambientLight")) {
            changedConfig.als = {};
          }
          for (const prop in config) {
            if (prop !== "th" && prop !== "als" && prop !== "pir") {
              if (prop.startsWith("pir") && config[prop] !== "") {
                changedConfig["pir"][prop] = typeof config[prop] === "boolean" ? config[prop] : Number(config[prop])
              }
              else if (prop.startsWith("als") && config[prop] !== "") {
                if (prop !== "alsC" && prop !== "alsM") {
                  changedConfig["als"][prop] = typeof config[prop] === "boolean" ? config[prop] : Number(config[prop])
                }
                else {
                  changedConfig["als"][prop] = config[prop]
                }
              }
              else if (prop.startsWith("th") || prop.startsWith("temp") || prop.startsWith("hum")) {
                if (prop !== "configId" && config[prop] !== "")
                  changedConfig["th"][prop] = typeof config[prop] === "boolean" ? config[prop] : Number(config[prop])
              }
            }
          }
          savedConfigs.push(changedConfig)
        }
        // console.log("CONFIGG", savedConfigs)
        //Commenting to remove sensor config temporarily(search this comment to find all instances)
        // if (_.isEmpty(err?.filter(e => e === true))) {
        set_DeviceConfigs(savedConfigs);
        dispatch(deviceActions.updateDeviceConfiguration(deviceData?.deviceId, { 'configs': savedConfigs }, deviceData?.category.toLowerCase()))
        // }
      }
    });
  }
  const revertConfiguration = () => {
    setError(error?.map((e) => {
      return false;
    }))
    setDeviceConfigs(transformConfig(_deviceConfigs))
  }
  const setConfig = (config) => {
    // const changedConfig = savedConfigs.find((c) => config?.configId === c?.configId)
    deviceConfigs.splice(config?.configId - 1, 1, config)
    setDeviceConfigs(deviceConfigs)
  }
  const actionButtons = [{
    heading: null,
    columns: [
      {
        heading: null,
        buttons: [
          {
            id: "revertbtn",
            type: "button",
            size: 'large',
            display: true,
            checkValidation: false,
            label: "Revert",
            onClick:
              revertConfiguration,

          },
          {
            id: "savebtn",
            type: "button",
            checkValidation: true,
            display: true,
            label: "Save",
            onClick:
              saveSensorConfiguration,

          }
        ],
        columnSize: 12,
      },
    ],
  },
  ]
  const addConfiguration = () => {
    if (deviceConfigs?.length < 5) {
      const newConfig = {
        "configId": deviceConfigs.length + 1,
        ...defaultSensorConfig
        //Commenting to remove sensor config temporarily(search this comment to find all instances)
        // "trigger": {},
      }
      setDeviceConfigs([...deviceConfigs, newConfig])
    }
  }
  const deleteConfiguration = (configId) => {
    let configs = deviceConfigs.filter((c) => c?.configId !== configId)
    setError(error?.map((e, key) => {
      if (key === configId) {
        return false
      }
      else {
        return e;
      }
    }))
    // savedConfigs.splice(configId-1, 1 )
    for (let i = configId - 1; i < configs.length; i++) {
      configs[i].configId = i + 1
      // savedConfigs[i].configId=i+1
    }
    setDeviceConfigs(configs)
  }
  return (
    <Box className={classes?.DeviceConfigurations || ""} data-testid="DeviceConfigurations">
      {deviceData?.category === "Sensor" &&
        <Box>
          {(isAuthorised(features?.device, permissions?.addConfig) && !disableDeviceOperation(deviceData, "deviceConfig")) &&
            <StyledButton
              sx={{ mb: 2, ml: 2 }}
              onClick={addConfiguration}
              disabled={deviceConfigs?.length === 5 ? true : false}
            >
              Add Config
            </StyledButton>
          }
          {deviceConfigs?.map((config, index) =>
            <Box sx={{ mb: 1 }} key={index}>
              <CustomAccordion title={"Config " + config?.configId} defaultState={index === 0 ? true : false}
                component={isAuthorised(features?.device, permissions?.configureButtons) &&
                  <Box sx={{ display: "flex" }}>
                    {/* Commenting to remove sensor config temporarily(search this comment to find all instances) */}
                    {/* {
                    error && error[config?.configId] &&
                    <CustomTypography
                      weight={700}
                      color={"#FF0000"}
                      size={12}
                      lineHeight={15}
                      styles={{ margin: "8px" }}
                      content={"Config is not Scheduled"}
                    />
                  } */}
                    {deviceConfigs?.length !== 1 && isAuthorised(features?.device, permissions?.configureButton) && !disableDeviceOperation(deviceData, "deviceConfig") &&
                      <StyledButton
                        iconButton
                        className="deleteButton"
                        onClick={() => {
                          confirmationDialog("Delete Configuration", () => {
                            deleteConfiguration(config?.configId);
                            setDialog(false);
                          },
                            "sensorConfig", setDialog, setDialogContents, `${config?.configId}`)
                        }}
                      >
                        <ImageWrapper className="action-icons pointer" alt={"Delete Config"} src={Delete} />
                      </StyledButton>

                    }
                  </Box>
                }>
                {/* Commenting to remove sensor config temporarily(search this comment to find all instances) */}
                {/* <StyledButton
                  sx={{ mt: 1 }}
                  onClick={(event) => {
                    openScheduler(event, config)
                  }}>Schedule Config
                </StyledButton> */}
                {/* <CustomTypography
                  weight={700}
                  color={_.isEmpty(config?.trigger) ? '#FF0000' : "#000"}
                  size={12}
                  lineHeight={15}
                  styles={{ margin: "8px" }}
                  content={_.isEmpty(config?.trigger) ? "Config is not Scheduled" : "Config is Scheduled"}
                /> */}
                <FormWrapper
                  operation={(isAuthorised(features?.device, permissions?.configureSensor) && !disableDeviceOperation(deviceData, "deviceConfig")) ? "create" : "view"}
                  formFields={frmTemplate}
                  ref={actionButtonsRef}
                  // formButtons={actionButtons}
                  formData={config}
                  setPayload={setConfig}
                  customsx={{ formbtn: { mt: '66px' }, label: { fontSize: "12px" } }}
                />
              </CustomAccordion>
            </Box>
          )}
          {
            isAuthorised(features?.device, permissions?.configureButtons) && !disableDeviceOperation(deviceData, "deviceConfig") &&
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 5 }}>
              <StyledButton
                sx={{ ml: 1 }}
                onClick={revertConfiguration}
              >
                Revert
              </StyledButton>
              <StyledButton
                sx={{ ml: 1, minWidth: "87px" }}
                onClick={(e) => {
                  saveSensorConfiguration(e);
                }}
              >
                Save
              </StyledButton>
            </Box>}
        </Box>
      }
      {
        deviceData?.category === "Switch" &&
        <Box>
          {
            isAuthorised(features?.device, permissions?.configureSwitch) && !disableDeviceOperation(deviceData, "deviceConfig") &&
            <>
              <Box sx={{ width: "100%" }}>
                <CustomTypography
                  weight={700}
                  color={"#000"}
                  size={12}
                  lineHeight={15}
                  styles={{ margin: "8px" }}
                  content="Select Scenes"
                />
                <ChipInput placeholder="Add Scenes "
                  value={selectedScenes}
                  rounded
                  options={scenes?.map(s => {
                    const scene = { ...s };
                    scene.label = scene?.name;
                    scene.id = scene?.name;
                    return scene;
                  })}
                  key="scenes"
                  error={error?.sceneList ? true : false}
                  helperText={error?.sceneList}
                  onChange={(e) => {
                    console.log(e?.target?.value)
                    setSelectedScenes(e?.target?.value)
                    if (!_.isEmpty(e?.target?.value)) {
                      setError({ ...error, sceneList: null })
                    }
                  }} />
              </Box>
              <Box sx={{ display: "flex", alignItems: "flex-end", justifyContent: "right" }}>
                <StyledButton
                  sx={{ height: "fit-content" }} onClick={saveSwitchConfig}
                >
                  Save
                </StyledButton>
              </Box>
            </>}
          <Box sx={{ mt: 2 }}>
            <CustomTable
              headerList={switchConfigHeaders}
              cellMatrix={switchButtonMap}
              enableScrollToTop={false}
            >
            </CustomTable>
          </Box>

        </Box>
      }

      <StyledPopupWrapper
        open={popup}
      >
        {/* {popupContent === "schedule" &&
          <Box className={classes?.SchedulerContainer}>
            <StyledScheduler
              header="Set Schedule"
              display="stack"
              params={{ hours: true, minutes: true, month: true, day: true }}
              actionButtons={[{ name: "cancel", label: "Cancel", variant: "outlined", action: () => { setPopup(false) } }, { name: "save", label: "Save", action: () => { setPopup(false) } }]}
              schedule={{ ...currentConfig?.trigger, day: currentConfig?.trigger?.dayOfMonth, weekDay: currentConfig?.trigger?.dayOfWeek }}
              setSchedule={(schedule) => { saveSchedule(schedule) }}
            ></StyledScheduler>
          </Box>
        } */}
        {
          popupContent === "switchConfig" &&
          <Box className={classes?.SwitchConfigContainer}>
            <CustomTypography
              weight={600}
              color={"#0F8D48"}
              size={14}
              lineHeight={17}
              sx={{ mb: 2 }}
              content="Configure Button Actions"
            />
            <Box sx={{ mb: 2, textAlign: "left" }}>
              <CustomLabel >
                {"Select Button Action"}
              </CustomLabel>
              <CustomDropDown
                value={changedSwitchConfig?.actionType}
                isNoneAsItem={false}
                list={switchActionTypes}
                customsx={{ width: "100%" }}
                name="actionType"
                disabled={false}
                onChange={(e) => { handleChangedConfig("actionType", e?.target?.value) }}
              />
            </Box>

            <Box sx={{ mb: 2, textAlign: "left" }}>
              <CustomLabel required={true}>
                {"Select Button Function"}
              </CustomLabel>
              <CustomDropDown
                value={changedSwitchConfig?.functionType}
                name="functionType"
                placeholder="Select Function Type"
                error={error?.functionType ? true : false}
                message={error?.functionType}
                list={switchFunctionTypes}
                onChange={(e) => { handleChangedConfig("functionType", e?.target?.value) }}
              />
            </Box>

            {changedSwitchConfig?.functionType && changedSwitchConfig?.functionType !== "MASTER_ON_OFF" && changedSwitchConfig?.functionType !== "SCENE_NXT" && changedSwitchConfig?.functionType !== "SCENE_PREV" &&
              <Box sx={{ textAlign: "left" }}>
                <CustomLabel required={true}>
                  {"Select entity"}
                </CustomLabel>

                <CustomDropDown
                  id={"selectEntity"}
                  value={changedSwitchConfig?.entity || ""}
                  customsx={{ menuItem: { icon: { height: "16px", width: "16px" } } }}
                  name="entity"
                  error={error?.entity ? true : false}
                  message={error?.entity}
                  placeholder="Select Entity"
                  list={entityList}
                  onChange={(e) => { handleChangedConfig("entity", e?.target?.value) }}
                />
              </Box>}

            <Grid container spacing={1} sx={{ mt: 2 }}>
              <Grid item xs={6}>
                <StyledButton
                  variant='outlined'
                  sx={{ width: "100%" }}
                  onClick={() => {
                    setPopup(false);
                  }}
                >
                  Cancel
                </StyledButton>
              </Grid>
              <Grid item xs={6}>
                <StyledButton
                  sx={{ width: "100%" }}
                  onClick={updateSwitchConfig}>
                  Add
                </StyledButton>
              </Grid>
            </Grid>
          </Box>

        }
      </StyledPopupWrapper>
    </Box >
  )
};

DeviceConfigurations.propTypes = {};

DeviceConfigurations.defaultProps = {};

export default DeviceConfigurations;
