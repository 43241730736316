/***
 *
 * Controller class for user.
 * @file Spaces.js
 * @description Spaces component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React from 'react';
import AddEditSpace from '../../components/AddEditSpace/index.js';
import SpaceDetails from '../../components/SpaceDetails/index.js';
import { useQuery } from '../../hooks';
// import PropTypes from 'prop-types';
import { useStyles } from './Spaces.style.js';

const Spaces = () => {
  const styles = useStyles();
  const query = useQuery();
  const parentId = query.get("parentId") || null;
  const id = query.get("id") || null;
  const operation = query.get("op") || null;
  // console.log("bbbbbbb", parentId, id)

  // useEffect(() => {
    
  // },[id])
    return (
        <div className={styles?.Spaces || ""} data-testid="Spaces">
          {operation === 'view'?
          <SpaceDetails id={id}/>
          :
          <AddEditSpace parentId={parentId} id={id} operation={operation} />}
          {/* this should be the condition operation === 'details' */}
        </div>
    )
};

Spaces.propTypes = {};

Spaces.defaultProps = {};

export default Spaces;
