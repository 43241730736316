/***
 *
 * Controller class for user.
 * @file CustomizedTree.js
 * @description CustomizedTree component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMessageStr, history, isAuthorised, selectSpace } from '../../helpers';
import PopoverContainer from '../PopoverContainer';
// import PropTypes from 'prop-types';
import './CustomizedTree.scss';
import { appActions, spaceActions } from '../../redux/actions';
import StyledSpinner from '../StyledSpinner';
import StyledPopup from '../StyledPopup';
import ImageWrapper from '../ImageWrapper';
import { findNode } from '../../services';

import DeleteIcon from "../../assets/icons/delete.svg";
import EditIcon from '../../assets/icons/edit-2.svg';
import AddIcon from '../../assets/icons/add-2.svg';
import ViewIcon from '../../assets/icons/view.svg';
import CustomTypography from '../CustomTypography';
import caretRightIcon from '../../assets/icons/caret-right.svg';
import VericalThreeDotsIcon from '../../assets/icons/three-vertical-dots.svg';
import { useQuery } from '../../hooks';
import { features, permissions } from '../../constants';


const CustomizedTree = ({ data, activeSpace, actButtons = false, listId = "tree", style = {}, onClick, selectSpaceMenu = true, keys = { selection: "spaceId", children: "Spaces" } }) => {
  const dispatch = useDispatch();
  const treeRef = useRef();
  const btnCaret = useRef();
  // const spaces = useSelector(state => state?.spaces?.list);
  const spaceTypes = useSelector(state => state?.spaceDTypes?.list);
  const loading = useSelector(state => state?.spaces?.loading);

  const [selectedSpace, setSelectedSpace] = useState();
  const [openNodeAction, setOpenNodeAction] = useState();
  const [confirmationPopup, setConfirmationPopup] = useState({ show: false });
  const [toolTip, setToolTip] = useState({ show: false, message: "" });
  const query = useQuery();

  const IconButtonArray = [
    {
      id: 'view_button',
      name: 'details',
      iconsx: { marginTop: '1px' },
      onClick: (e) => {
        handleClick(e, "view");
      },
      display: true,
      permission: isAuthorised(features.space),
      img: ViewIcon,
      iconLabel: 'View'
    },
    {
      id: 'create_button',
      name: 'create',
      iconsx: {},
      onClick: (e) => {
        handleClick(e, "create");
      },
      display: true,
      permission: isAuthorised(features.space, permissions.add),
      img: AddIcon,
      iconLabel: 'Create'
    },
    {
      id: 'edit_button',
      name: 'edit',
      iconsx: {},
      onClick: (e) => {
        handleClick(e, "edit");
      },
      display: !selectedSpace?.isRootSpace,
      permission: isAuthorised(features.space, permissions.edit),
      img: EditIcon,
      iconLabel: 'Edit'
    },
    {
      id: 'delete_button',
      name: 'delete',
      iconsx: {},
      onClick: (e) => {
        handleClick(e, "delete");
      },
      display: !selectedSpace?.isRootSpace,
      permission: isAuthorised(features.space, permissions.delete),
      img: DeleteIcon,
      iconLabel: 'Delete'
    },
  ]

  // useEffect(()=>{
  //   console.log("ddddhh", btnCaret, btnCaret.current, treeRef.current.querySelector('ul'));
  //   btnCaret?.current?.click();
  // }, [expand, btnCaret])

  const treeNode = (val, idKey = "tree", expand = false) => {
    const type = spaceTypes?.find(sp => sp.spaceType === val?.type);

    const handleExpand = (event) => {
      // onClick(event, data);

      // dispatch(appActions?.selectLeftMenu(1061));
      const target = event.currentTarget;
      const exp = target?.getAttribute('aria-expanded');
      if (exp !== "true") {
        // target?.querySelector('.caret')?.classList?.add('rotate');
        target?.classList?.add('rotate');
      } else {
        // target?.querySelector('.caret')?.classList?.remove('rotate');
        target?.classList?.remove('rotate');
      }
    }

    return (
      <li key={idKey} id={idKey}>
        <div style={{ display: 'flex' }}>
          <div className="w-100">
            <div style={{ width: 'max-content' }} className="pointer">
              {/* {JSON.stringify(type)} */}
              {val && val[keys?.children] && val[keys?.children]?.length > 0 ? (
                <>
                  <img
                    alt=""
                    ref={btnCaret}
                    className={expand ? "caret rotate pointer" : "caret pointer"}
                    src={caretRightIcon}
                    data-toggle="collapse"
                    data-target={"#collapse-" + listId + idKey}
                    aria-expanded={expand} aria-controls={"collapse-" + listId + idKey}
                    onClick={(event) => handleExpand(event, val)} />

                  <ImageWrapper className="icon-2" src={val?.leftIcon?.src || type?.icons} />
                </>
              ) : (
                <>
                  <div className="caret" style={{ width: "10px", height: "10px", display: "inline-block" }}></div>
                  <ImageWrapper className="icon-2" src={val?.leftIcon?.src || type?.icons} />
                </>
              )}
              {/* - {val?.Spaces?.length} -  */}
              {val && <a
                className={val[keys?.selection] === activeSpace[keys?.selection] ? "selected" : ""}
                style={{ cursor: 'pointer', lineHeight: "12px" }}
                onClick={(event) => {
                  onClick(event, val);
                  selectSpaceMenu && dispatch(appActions?.selectLeftMenu(1061));
                }
                }>
                <span className="tree-item-ppp">{val?.name}</span>
              </a>}
              {
                val?.rightIcon
                &&
                (
                  // val?.rightIcon?.tooltip ?
                  //   <Tooltip arrow title={val?.rightIcon?.tooltip?.title} {...val?.rightIcon?.tooltip}>
                  //     <span>
                  //       <ImageWrapper className={"icon-3 " + val?.rightIcon?.class} src={val?.rightIcon?.src} />
                  //     </span>
                  //   </Tooltip>
                  //   :
                  <ImageWrapper className={"icon-3 " + val?.rightIcon?.class} src={val?.rightIcon?.src} />
                )
              }
            </div>
          </div>
          {actButtons &&
            <span style={{ width: "10px", cursor: 'pointer', align: 'center', display: 'inline-block', position: 'relative' }} onClick={(event) => {
              setOpenNodeAction(event.currentTarget);
              setSelectedSpace(val);
            }}>
              <ImageWrapper className="right-menu" src={VericalThreeDotsIcon} />
            </span>
          }
        </div>
        <ul className={expand ? "show" : "collapse"} id={"collapse-" + listId + idKey}>
          {val?.hasOwnProperty(keys?.children) && val[keys?.children] && Array.isArray(val[keys?.children]) ? (
            val[keys?.children]?.map((v, i) => treeNode(v, `${idKey}-${i}`, false))
          ) : (loading &&
            <li><StyledSpinner type="line" size={12} /></li>
          )}
        </ul>
      </li>
    );
  }

  const deleteSpace = (id, parentId, cb = () => { }) => {
    dispatch(spaceActions?.deleteSpace(id, parentId, cb));
  };


  const handleClick = (event, operation) => { // Additional parameter "operation" added as event.target as no 'name' attribute
    //const operation = event.target.name;
    switch (operation) {
      case "create":
        history.push(`/spaces?parentId=${selectedSpace?.spaceId}&op=${operation}`);
        break;
      case "edit":
        history.push(`/spaces?id=${selectedSpace?.spaceId}&op=${operation}`);
        break;
      case "delete":
        openConfirmationPopup(selectedSpace?.spaceId);
        break;
      default:
        history.push(`/spaces?op=${operation}&id=${selectedSpace?.spaceId}`);
        break;
    }

    selectSpace(selectedSpace, dispatch);
    setOpenNodeAction(false);
  }

  const openConfirmationPopup = (id) => {
    setConfirmationPopup({ show: true, id: id });
  }

  const handleConfirmation = (confirmed = false) => {
    if (confirmed) {
      deleteSpace(selectedSpace?.spaceId, selectedSpace?.parentSid, (spaceId) => {
        let selectedSp = data[0];
        findNode(data, keys?.children, { key: "spaceId", value: selectedSpace?.parentSid }, (resp) => {
          selectedSp = resp || data[0];
        });
        dispatch(spaceActions.selectSpace(selectedSp));
        dispatch(spaceActions.getAllSpaces());
        dispatch(spaceActions.associateAssetwithSpace(selectedSpace))
        const op = query.get("op");
        const id = query.get("id");
        if (op === "view" && id === selectedSpace?.spaceId) {
          history.push(`/spaces?op=view&id=${selectedSp?.spaceId}`);
        }
      });

      setConfirmationPopup({ show: false });
    } else {
      setConfirmationPopup({ show: false });
    }
  }


  const closeToolTip = () => {
    setToolTip({ show: false, message: "" });
  }




  return (
    <div className="CustomizedTree" style={{ ...style }} data-testid="CustomizedTree">
      <div ref={treeRef} className="tree">
        <ul id="nav-tree">
          {data && data.length > 0 ? data?.map((v, i) => treeNode(v, "tree-" + i, true)) : <></>}
        </ul>
      </div>

      {actButtons &&
        <>
          <PopoverContainer anchorEl={openNodeAction} sx={{ width: "max-content" }} onClose={() => setOpenNodeAction(null)}>
            <Grid container item className="left-menu-action-button">
              {
                IconButtonArray.map((item) => (
                  <>
                    {item?.display && item?.permission &&
                      <IconButton sx={{ padding: "5px", display: "flex", flexDirection: "column", height: 42, width: 42, ...item.iconsx }} name={item.name} onClick={(e) => { item?.onClick(e) }} >
                        <ImageWrapper className="action-icons pointer" src={item?.img} alt="" style={{ width: "24px" }} />
                        <CustomTypography component="div" size={12} color={"#555"} sx={{ display: 'block' }} content={item?.iconLabel} />
                      </IconButton>
                    }
                  </>
                ))
              }
            </Grid>
          </PopoverContainer>

          {/* Tooltip popup */}
          <PopoverContainer anchorEl={toolTip?.show} onClose={closeToolTip}>
            <Box sx={{ textAlign: "center" }}>
              {toolTip?.message || ""}
            </Box>
          </PopoverContainer>

          <StyledPopup
            open={confirmationPopup.show}
            onClose={() => setConfirmationPopup({ show: false })}
            state="timeout"
            data={{
              content: getMessageStr("space-delete-confirmation", selectedSpace?.name),
              actions: [
                {
                  id: "1002",
                  type: "button",
                  label: "Cancel",
                  onClick: () => {
                    handleConfirmation(false);
                  }
                },
                {
                  id: "1001",
                  type: "button",
                  label: "Confirm",
                  onClick: () => {
                    handleConfirmation(true);
                  }
                }
              ]
            }} />
        </>
      }
    </div>
  )
};

CustomizedTree.propTypes = {};

CustomizedTree.defaultProps = {};

export default CustomizedTree;
