import { getMessageStr } from "../helpers/message.helper";

export const ticketEntityOptions = {
  spaces: {
    id: "2",
    type: "treedropdown",
    label: "Space",
    name: "space",
    placeholder: "Select space",
    multiselect: false,
    options: [],
    columnSize: 4,
  },
  devices: {
    id: "3",
    type: "searchSelect",
    label: "Device",
    name: "device",
    placeholder: "Enter device name",
    searchIn: ['name', 'deviceId'],
    customsx: { container: { padding: "0px", background: "none" } },
    options: [],
    columnSize: 4,
  },
  users: {
    id: "3",
    type: "select",
    label: "User",
    name: "user",
    placeholder: "Enter username",
    options: [],
    columnSize: 4,
  },
}

export const ticketFormConfig = (op = "createTicket") => {
  const attachmentSection = {
    section: { heading: null },
    columns: [
      {
        id: "7",
        type: "file",
        label: "Attachments",
        allowedFileType: ".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf",
        name: "attachments",
        attachment: true,
        multiple: true,
        columnSize: 12,
        placeholder: "Select a file to upload.",
        format: ["xlsx", "xls", "png", "jpeg", "jpg", "doc", "docx", "ppt", "pptx", "txt", "pdf"],
        validation: {
          fileSize: [5000, getMessageStr("ticket-createTicket-attachmentCheck")],
        },
      }
    ],
  };
  const statusSection = {
    section: { heading: null },
    columns: [
      {
        id: "3",
        type: "select",
        label: "Status",
        name: "state",
        required: true,
        placeholder: "Enter",
        list: [],
        columnSize: 6,
        validation: {
          req: [true, getMessageStr('ticket-createTicket-stateMandatory')],
        }
      }
    ],
  };
  const config = [
    {
      section: { heading: null },
      columns: [
        {
          id: "1",
          type: "text",
          name: "synopsis",
          label: "Title",
          required: true,
          placeholder: "Enter title",
          columnSize: 12,
          validation: {
            req: [true, getMessageStr('ticket-createTicket-titleMandatory')],
            minLen: [2, getMessageStr('ticket-createTicket-titleMinLen')],
            maxLen: [30, getMessageStr("ticket-createTicket-titleMaxLen")],
            alphaNumChars: ['- ', getMessageStr('ticket-createTicket-titleAllowedCharacters')]
          },
        },
        {
          id: "6",
          type: "texteditor",
          label: "Description",
          name: "description",
          required: true,
          minRows: 3,
          columnSize: 12,
          validation: {
            req: [true, getMessageStr('ticket-createTicket-descMandatory')],
            minLen: [2, getMessageStr('ticket-createTicket-descMinLen')],
            maxLen: [280, getMessageStr("ticket-createTicket-descMaxLen")],
          },
        },
      ],
    },
    {
      section: { heading: null },
      columns: [
        {
          id: "1",
          type: "select",
          label: "Ticket Type",
          name: "ticketType",
          placeholder: "Enter",
          options: [],
          required: true,
          columnSize: op === "createTicket" ? 4 : 6,
          validation: {
            req: [true, getMessageStr('ticket-createTicket-typeMandatory')],
          },
        },
        {
          id: "2",
          type: "select",
          label: "Entity Type",
          name: "entityType",
          placeholder: "Enter",
          required: true,
          options: [],
          columnSize: op === "createTicket" ? 4 : 6,
          validation: {
            req: [true, getMessageStr('ticket-createTicket-entityMandatory')],
          },
        },
      ],
    },
    {
      section: { heading: null },
      columns: [
        {
          id: "1",
          type: "select",
          label: "Severity",
          name: "severity",
          placeholder: "Enter",
          required: true,
          list: [],
          columnSize: op === "createTicket" ? 4 : 6,
          validation: {
            req: [true, getMessageStr('ticket-createTicket-severityMandatory')],
          },
        },
        {
          id: "2",
          type: "select",
          label: "Assigned To ",
          name: "assignedTo",
          placeholder: "Enter",
          options: [],
          columnSize: op === "createTicket" ? 4 : 6,
        },
      ],
    },

  ];

  config?.push(op === "createTicket" ? attachmentSection : statusSection)
  return config;
}
