import { ticketingType } from '../types/ticketing.type';

let initialState = {
    loading: false,
    ticketList: [],
    createTicketsData: {},
    assigneeData: [],
    ticketDetails: {},
    postComment: {},
    getTicketComment: {},
    subscribeTicketData: {},
    unsubscribeTicketData: {},
    addAttachmentsData: {},
    deleteAttachmentData: {},
    addAttachmentToCommentData: {},
    editComment: {},
    statistics: {},
    entityTypes: {},
    states: [],
    severities: [],
    attachmentData: {},
    commentAttachmentData: {},
    deleteCommentAttachmentData: {}
}



export function ticketing(state = initialState, action) {
    // console.log("ticketing action", action);
    switch (action?.type) {
        case ticketingType.GET_REQUEST:
            return {
                ...state,
                loading: true
            };
        case ticketingType.GET_TICKET_DETAILS:
            return {
                ...state,
                loading: false,
                ticketDetails: {}
            }
        case ticketingType.GET_TICKETS_SUCCESS:
            return {
                ...state,
                loading: false,
                ticketList: action.payload,
                ticketDetails: {}
            };
        case ticketingType.CREATE_TICKETS_SUCCESS:
            return {
                ...state,
                loading: false,
                createTicketsData: action.payload
            }
        case ticketingType.CREATE_TICKETS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case ticketingType.GET_TICKETS_ASSIGNEES_SUCCESS:
            return {
                ...state,
                loading: false,
                assigneeData: action.payload
            }
        case ticketingType.GET_TICKETS_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                ticketDetails: action.payload
            }

        case ticketingType.POST_COMMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                postComment: action.payload
            }
        case ticketingType.SUBSCRIBE_TICKET_SUCCESS:
            return {
                ...state,
                loading: false,
                subscribeTicketData: action.payload
            }
        case ticketingType.UN_SUBSCRIBE_TICKET_SUCCESS:
            return {
                ...state,
                loading: false,
                unsubscribeTicketData: action.payload
            }
        case ticketingType.UPDATE_TICKETS_SUCCESS:
            return {
                ...state,
                loading: false,
                ticketDetails: action.payload
            }
        case ticketingType.ADD_ATTACHMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                addAttachmentsData: action.payload
            }
        case ticketingType.DELETE_COMMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                deleteCommment: action.payload
            }
        case ticketingType.EDIT_COMMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                editComment: action.payload
            }
        case ticketingType.GET_STATISTICS_SUCCESS:
            return {
                ...state,
                loading: false,
                statistics: action.payload
            }
        case ticketingType.DELETE_ATTACHMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                deleteAttachmentData: action.payload
            }
        case ticketingType.ADD_ATTACHMENTS_TO_COMMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                addAttachmentToCommentData: action.payload
            }
        case ticketingType.DELETE_ATTACHMENTS_TO_COMMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                deleteCommentAttachmentData: action.payload
            }

        case ticketingType.GET_ATTACHMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                attachmentData: action.payload
            }
        case ticketingType.GET_ATTACHMENTS_TO_COMMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                commentAttachmentData: action.payload
            }
        case ticketingType.GET_TICKET_TYPES_SUCCESS:
            return {
                ...state,
                loading: false,
                entityTypes: action.payload
            }
        case ticketingType.GET_TICKET_STATES_SUCCESS:
            return {
                ...state,
                loading: false,
                states: action.payload
            }
        case ticketingType.GET_TICKET_SEVERITIES_SUCCESS:
            return {
                ...state,
                loading: false,
                severities: action.payload
            }
        default:
            return state
    }
}

