import { defaultSelectedMenu } from '../../config/appConfig';
import { getLocalItem } from '../../helpers';
import { appTypes, notificationTypes } from '../types';

const initialState = {
    selectedLeftMenu: defaultSelectedMenu.left,
    selectedTopMenu: defaultSelectedMenu.top,
    loading: false,
    theme: "light",
    messageId: null,
    messageType: null,
    message: null,
    messages: [],
    otp: null,
    validateOtp: null,
    forceExit: true,
    sideBarOpen: false,
    webConfig: JSON.parse(getLocalItem('appConfig'))?.webConfig,
    clientId: JSON.parse(getLocalItem('appConfig'))?.clientId,
    portal: JSON.parse(getLocalItem('appConfig'))?.portal,
    notificationToken: JSON.parse(getLocalItem('miraie'))?.notificationToken || null
}

export function appReducer(state = initialState, action) {
    switch (action?.type) {
        case appTypes.GENERATE_OTP_REQUEST:
        case appTypes.VALIDATE_OTP_REQUEST:
        case appTypes.WEB_CONFIG_REQUEST:
        case notificationTypes.SUBSCRIBE_NOTIFICATION_REQUEST:
        case notificationTypes.UNSUBSCRIBE_NOTIFICATION_REQUEST:
            return {
                ...state,
                loading: true
            }
        case appTypes.GENERATE_OTP_SUCCESS:
            return {
                ...state,
                otp: action?.payload,
                loading: false
            };
        case appTypes.VALIDATE_OTP_SUCCESS:
            return {
                ...state,
                validateOtp: action?.payload,
                loading: false
            };
        case appTypes.CLEAR_VALIDATE_OTP:
            return {
                ...state,
                validateOtp: null,
                loading: false
            };
        case appTypes.CLEAR_OTP:
            return {
                ...state,
                otp: null,
            };
        case appTypes.SELECT_LEFT_MENU:
            // console.log("SelectedMenu left reducer ---- ", action);
            return {
                ...state,
                selectedLeftMenu: action?.payload,
            };
        case appTypes.SELECT_TOP_MENU:
            return {
                ...state,
                selectedTopMenu: action?.payload,
            };
        case appTypes.THEME:
            return {
                ...state,
                theme: action?.payload,
            };
        case appTypes.API_SUCCESS:
            return {
                ...state,
                messageId: 'success-' + (new Date()).getTime() + Math.floor(Math.random() * (100 - 10 + 1) + 10),
                messageType: action?.message ? 'success' : null,
                message: action?.message,
                messages: (state?.messages.find(m => m.message === action?.message) ? [...state?.messages] : [...state?.messages, {
                    messageId: 'success-' + (new Date()).getTime() + Math.floor(Math.random() * (100 - 10 + 1) + 10),
                    messageType: action?.message ? 'success' : null,
                    message: action?.message,
                }])
            };
        case appTypes.WEB_CONFIG_SUCCESS:
            return {
                ...state,
                loading: false,
                ...action?.payload
                // webConfig:JSON.parse(getLocalItem('appConfig'))?.webConfig,
                // clientId:JSON.parse(getLocalItem('appConfig'))?.clientId
            }
        case notificationTypes.SUBSCRIBE_NOTIFICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                notificationToken: action?.payload
            }
        case notificationTypes.UNSUBSCRIBE_NOTIFICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                notificationToken: null
            }
        case appTypes.API_ERROR:
            return {
                ...state,
                messageId: 'error-' + (new Date()).getTime() + Math.floor(Math.random() * (100 - 10 + 1) + 10),
                messageType: action?.message ? 'error' : null,
                message: action?.message,
                messages: (state?.messages?.find(m => m?.message === action?.message) ? [...state?.messages] : [...state?.messages, {
                    messageId: 'error-' + (new Date()).getTime() + Math.floor(Math.random() * (100 - 10 + 1) + 10),
                    messageType: action?.message ? 'error' : null,
                    message: action?.message,
                }])
            };
        case appTypes.MESSAGE_POP:
            return {
                ...state,
                messages: state?.messages?.filter(m => m.messageId !== action?.id)
            }
        case appTypes.API_CLEAR:
            // console.log("SelectedMenu clear")
            return {
                ...state,
                loading: false,
                forceExit: true
            };
        case appTypes.FORCE_EXIT:
            return {
                loading: false,
                forceExit: action?.payload
            }
        case appTypes.SIDE_BAR_EXPANDED:
            return {
                ...state,
                sideBarOpen: action?.payload
            }
        case appTypes.GENERATE_OTP_FAILURE:
        case appTypes.VALIDATE_OTP_FAILURE:
        case notificationTypes.SUBSCRIBE_NOTIFICATION_FAILURE:
        case notificationTypes.UNSUBSCRIBE_NOTIFICATION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action?.payload
            };
        case appTypes.WEB_CONFIG_FAILURE:
            return {
                ...state,
                loading: false,
                error: action?.payload
            }
        default:
            return state
    }
}