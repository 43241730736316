import React from "react";
import CanvasArea from '../../components/CanvasArea'
const Canvas= ()=>{
    return(
        <div className="CanvasPage" data-testid="CanvasPage">
        {/* {t("title")} */}
        <CanvasArea />
      </div>
    )
}

Canvas.defaultProps={};

export default Canvas