import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    DeviceConfigurations: {
        "& ._4ButtonSwitch ._1button": {
            transform: "rotate(0deg)"
        }, "& ._4ButtonSwitch ._2button": {
            transform: "rotate(90deg)"
        }, "& ._4ButtonSwitch ._3button": {
            transform: "rotate(180deg)"
        }, "& ._4ButtonSwitch ._4button": {
            transform: "rotate(270deg)"
        },
        "& ._8ButtonSwitch ._1button": {
            transform: "rotate(0deg)"
        },
        "& ._8ButtonSwitch ._2button": {
            transform: "rotate(45deg)"
        },
        "& ._8ButtonSwitch ._3button": {
            transform: "rotate(90deg)"
        },
        "& ._8ButtonSwitch ._4button": {
            transform: "rotate(135deg)"
        },
        "& ._8ButtonSwitch ._5button": {
            transform: "rotate(180deg)"
        },
        "& ._8ButtonSwitch ._6button": {
            transform: "rotate(225deg)"
        },
        "& ._8ButtonSwitch ._7button": {
            transform: "rotate(270deg)"
        },
        "& ._8ButtonSwitch ._8button": {
            transform: "rotate(315deg)"
        },

        "& ._6ButtonSwitch ._1button": {
            transform: "rotate(0deg)"
        },
        "& ._6ButtonSwitch ._2button": {
            transform: "rotate(60deg)"
        },
        "& ._6ButtonSwitch ._3button": {
            transform: "rotate(120deg)"
        },
        "& ._6ButtonSwitch ._4button": {
            transform: "rotate(180deg)"
        },
        "& ._6ButtonSwitch ._5button": {
            transform: "rotate(240deg)"
        },
        "& ._6ButtonSwitch ._6button": {
            transform: "rotate(300deg)"
        },

        "& ._10ButtonSwitch ._1button": {
            transform: "rotate(0deg)"
        },
        "& ._10ButtonSwitch ._2button": {
            transform: "rotate(36deg)"
        },
        "& ._10ButtonSwitch ._3button": {
            transform: "rotate(72deg)"
        },
        "& ._10ButtonSwitch ._4button": {
            transform: "rotate(108deg)"
        },
        "& ._10ButtonSwitch ._5button": {
            transform: "rotate(144deg)"
        },
        "& ._10ButtonSwitch ._6button": {
            transform: "rotate(180deg)"
        },
        "& ._10ButtonSwitch ._7button": {
            transform: "rotate(216deg)"
        },
        "& ._10ButtonSwitch ._8button": {
            transform: "rotate(252deg)"
        },
        "& ._10ButtonSwitch ._9button": {
            transform: "rotate(288deg)"
        },
        "& ._10ButtonSwitch ._10button": {
            transform: "rotate(324deg)"
        },
        "& .deleteButton":{
            padding:"0px"
        }
    },
    "SchedulerContainer": {
        "max-width": "545px"
    },
    "SwitchConfigContainer": {
        "max-width": "342px"
    },
});
