/***
 *
 * Controller class for user.
 * @file VerifyPopup.js
 * @description VerifyPopup component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import { Box } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import CustomTypography from '../../../components/CustomTypography/index.js';
import StyledSpinner from '../../../components/StyledSpinner/index.js';
import StyledTextfield from '../../../components/StyledTextfield/index.js';
import { userActions } from '../../../redux/actions/user.actions.js';
// import PropTypes from 'prop-types';
// import { useStyles } from './VerifyPopup.style.js';
import _ from 'lodash';
import StyledSelectTextField from '../../../components/StyledSelectTextField/index.js';
import { getMobileNumber } from '../../../helpers/users.helper.js';
import { countryCodes } from '../../../constants/user.constants.js';
import StyledButton from '../../../components/StyledButton/index.js';
import Validator from '../../../helpers/validator.helper.js';

const VerifyPopup = ({ closePopUp, type, value, onVerifiedOTP = () => { }, onCancel = () => { }, validation }) => {
  // const classes = useStyles()

  const [fieldObj, setFieldObj] = useState({ email: value?.email || '', mobile: value?.mobile || '', otp: '' });
  const [showbutton, setShowButton] = useState(false);
  const [confirmBtn, setConfirmBtn] = useState(false);
  const [loader, showLoader] = useState(false);
  const [err, setErr] = useState(null);



  const dispatch = useDispatch();

  useEffect(() => {
    if (!closePopUp) {
      setFieldObj({
        email: '', mobile: '', otp: ''
      })
      setShowButton(false);
      setConfirmBtn(false);
    }
  }, [closePopUp])

  useEffect(() => {
    if (fieldObj[type]) {
      setShowButton(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldObj])


  const handleVerificationChange = (e, value) => {
    const target = e.target;
    const name = target.name;
    value = value ? value : target?.value
    console.log("changing value", value)
    setFieldObj({ ...fieldObj, [name]: value });
  }

  const handleConfirm = (e, step) => {
    const valErr = Validator(fieldObj[type], validation?.type, validation?.valObj)
    setErr(valErr)
    if (!valErr) {
      if (step === 'otp') {
        dispatch(userActions.generateOTP('user', { [type]: fieldObj[type], transactionType: "UpdateProfile" }, (res) => {
          setShowButton(false);
          setConfirmBtn(true);
        }));
      }
      else {
        dispatch(userActions.validateOTP('user', { [type]: fieldObj[type], transactionType: "UpdateProfile", otp: fieldObj['otp'] }, (res) => {
          showLoader(false);
          onVerifiedOTP()
        }))
      }
    }

  }

  return (
    <Box display={'flex'} width={"100%"} flexDirection={'column'}>
      {!confirmBtn ?
        <>
          <CustomTypography styles={{ paddingBottom: '20px', textAlign: 'left' }} content={`Please enter ${type} to verify`} />
          {type === 'email' ?
            <StyledTextfield
              value={fieldObj[type]}
              error={err ? true : false}
              helperText={err}
              name={type}
              sx={{ width: 'auto', minWidth: '150px', pb: '24px' }}
              onChange={handleVerificationChange}
            />
            :
            <StyledSelectTextField
              value={fieldObj[type]}
              name={type}
              defaultSelect='+91'
              sx={{ width: 'auto', minWidth: '150px', pb: '24px' }}
              setterFunction={(code, phone) => { handleVerificationChange({ target: { name: type } }, code + phone) }}
              partition={(data) => { return getMobileNumber(data) }}
              list={countryCodes}
              defaultText=''
              message={err}
              error={err ? true : false}
              placeholder="Enter Mobile"
              disabledOnEdit={false}
              requireOpen={true}
              isNoneAsItem={false}
              customboxsx={{ mb: 3 }}
              hideInputArrows={true}
            // width={'20%'}
            />
          }
          {showbutton &&
            <Box display={'flex'} justifyContent={'flex-end'}>
              <StyledButton sx={{ width: '30%', mr: 2 }} onClick={(e) => handleConfirm(e, 'otp')}>Confirm {type}</StyledButton>
              <StyledButton sx={{ width: '30%' }} onClick={() => onCancel()} variant='outlined' >Cancel</StyledButton>
            </Box>
          }
        </>
        :
        <>
          <CustomTypography styles={{ paddingBottom: '20px', textAlign: 'left' }} content={`An OTP has been sent to your ${type}`} />
          <StyledTextfield
            value={fieldObj['otp']}
            name={'otp'}
            sx={{ width: 'auto', minWidth: '150px', pb: '24px' }}
            onChange={handleVerificationChange}
            placeholder="Enter OTP..."
          />
          {!loader ?
            <Box display={'flex'} justifyContent={'flex-end'}>
              <StyledButton sx={{ width: '30%', mr: 2 }} onClick={(e) => handleConfirm(e, 'validateOtp')}>Confirm OTP</StyledButton>
              <StyledButton sx={{ width: '30%' }} onClick={() => onCancel()} variant='outlined' >Cancel</StyledButton>
            </Box>
            :
            <Box display={'flex'} justifyContent='center'>
              <StyledSpinner />
            </Box>
          }
        </>

      }


    </Box>
  )
};

VerifyPopup.propTypes = {};

VerifyPopup.defaultProps = {};

export default VerifyPopup;
