/***
 *
 * Controller class for user.
 * @file Scenes.js
 * @description Scenes component
 * @author Shubham Singh
 * @since 12 Oct 2021
 * 
 */

import React, { useState, useEffect } from "react";
// import PropTypes from 'prop-types';
import { useStyles } from './Scenes.style.js';
import CreateScenes from "../../pages/CreateScenes";
import { useDispatch } from "react-redux";
import { useQuery } from "../../hooks";
import { deviceActions, dragAndDropActions } from "../../redux/actions";
import SceneDetails from '../../pages/SceneDetails';
import SceneList from "../../components/SceneList/index.js";

const Scenes = (props) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const query = useQuery();
  const sceneId = query.get("id") || null;

  const [sceneOperation, setSceneOperation] = useState();

  useEffect(() => {
    setSceneOperation(query.get("op"));
  }, [query]);

  // useEffect(() => {
  //   dispatch(deviceActions?.getAllDevices({ category: "VortexGateway" }, "gateway"))
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  // useEffect(() => {
  //   if (props?.data) {
  //     setSceneList(props?.data);
  //   }
  // }, [props?.data]);

  useEffect(() => {
    if (sceneOperation === null || sceneOperation === undefined) {
      dispatch(dragAndDropActions.emptyLists());
      dispatch(dragAndDropActions.setErrorMessage(''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sceneOperation, query])


  return (
    <div className={styles.Scenes} data-testid="Scenes">
      {sceneOperation === "create" || sceneOperation === "edit" ? (
        <CreateScenes sceneOperation={sceneOperation} />
      ) : (
        sceneId ? <SceneDetails mqttClient={props?.mqttClient} /> : <SceneList mqttClient={props?.mqttClient} />
      )}
    </div>
  );
};

Scenes.propTypes = {};

Scenes.defaultProps = {};

export default Scenes;
