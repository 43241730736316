// api/appsupport/v1/alerts/space/s123


import { API_URL } from '../config/appConfig'
import { API } from '.'
import { handleResponse } from '../helpers';


const getAlerts = async (space, ackStatus, status) => {
    return await API.get(`${API_URL.support}/alerts/search`, "getAllAlerts", { space, ackStatus, status }).then((res) => {
        return handleResponse(res, true);
    });
}

const resolveAlert = async (alertId) => {
    return await API.put(`${API_URL.support}/alerts/${alertId}/resolve`, "resolveAlerts").then((res) => {
        return handleResponse(res, true);
    });
}

const acknowledgeAlert = async (alertId) => {
    return await API.put(`${API_URL.support}/alerts/${alertId}/acknowledge`, "acknowledgeAlerts").then((res) => {
        return handleResponse(res, true);
    });
}

const getAlertTypes = async () => {
    return await API.get(`${API_URL.support}/alerts/alertTypes`, "getAlertTypes").then((res) => {
        return handleResponse(res, true);
    });
}



export const alertsService = {
    getAlerts,
    resolveAlert,
    acknowledgeAlert,
    getAlertTypes
}
