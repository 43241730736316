/***
 *
 * Controller class for user.
 * @file CustomLabel.js
 * @description CustomLabel component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './CustomLabel.style.js';
import './CustomLabel.scss';
import { FormLabel } from '@mui/material';

const CustomLabel = ({children, customstyle={}, style={}, ...props}) => {
  const classes = useStyles()

    return (
        <FormLabel 
          className={classes?.CustomLabel || ""} 
          data-testid="CustomLabel" 
          component="label"   
          style={{ ...style}}       
          sx={{
            '	.MuiFormLabel-asterisk':{
              color:'#FF0000',
              ml:'3px'             
            },           
          }}
          {...props}
        >
          {children}
        </FormLabel>
    )
};

CustomLabel.propTypes = {};

CustomLabel.defaultProps = {};

export default CustomLabel;
