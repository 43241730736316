import { appActions, mqttActions, sceneActions } from "../redux/actions";
import { deviceService, mqttRequest } from "../services";
import { getMessage, getMessageStr } from "./message.helper";
import { delay } from "../services/util.service";
import { sceneTypes } from "../redux/types";
import { sceneDeleteMessageLatency, setMqttTimeout } from "../config/mqttConfig";
let sceneDeleteTimeout = null;
export const applyScene = async (scene, dispatch, mqttClient, data = null) => {
  if (scene?.managedBy === 'cloud') {
    dispatch(sceneActions.applyScene(scene?.sceneId));
    return;
  }
  const sceneType = scene?.managedBy
  let payload = {};
  const id = sceneType === "controller" ? scene?.controllerIds[0] : scene.gatewayIds[0]
  console.log("scene apply", id, scene)
  Promise.resolve().then(() => {
    if (data === null) {
      return deviceService.getDevice(id);
    }
    return { data: data };
  }
  ).then(({ data: deviceData, status }) => {
    // console.log("APPLYING SCENE", deviceData, scene)
    payload = {
      subTopic: deviceData?.mqttTopics?.status,
      pubTopic: deviceData?.mqttTopics?.control,
      deviceId: sceneType === "controller" ? deviceData?.deviceId : null,
      deviceConfig: { sceneId: scene.sceneId },
      onRecieve: (message) => {
        if (message?.sceneId === scene.sceneId) {
          if (message?.status === "success") {
            dispatch(appActions?.apiSuccessMessage(getMessageStr("sceneApply-receive-applied", (scene?.name ? scene?.name : "") + ` (${scene?.sceneId})`)))
          }
          else {
            dispatch(appActions?.apiErrorMessage(getMessageStr("sceneApply-receive-error", (scene?.name ? scene?.name : "") + ` (${scene?.sceneId})`)))
          }
          clearTimeout(timeout);
          // unsubscribe(deviceData?.mqttTopics?.status, mqttClient);
          dispatch(mqttActions.deleteMqttRequest(message?.sid));
        }
      }
    }
    const messageSid=mqttRequest(payload, mqttClient, dispatch);
    const timeout = setTimeout(() => {
      // unsubscribe(data?.mqttTopics?.status, mqttClient);
      dispatch(mqttActions.deleteMqttRequest(messageSid));
      dispatch(appActions?.apiErrorMessage(getMessageStr("sceneApply-receive-timeout", (scene?.name ? scene?.name : "") + ` (${scene?.sceneId})`)))
    }, setMqttTimeout(60000))
  },
    ({ error, code }) => {
      getMessage(`device-get-${code}`).then(delay(500)).then((msg) => {
        dispatch(appActions.apiErrorMessage((scene?.name ? scene?.name : "") + ` (${scene?.sceneId})` + " scene apply operation failed : " + msg));
      },)
    })
}

export const updateSceneStatus = (msg, dispatch) => {
  if (msg?.status?.includes("scene_sync_failure") || msg?.status === "scene_sync_success" || msg?.status === "dbus_failure") {
    dispatch({
      type: sceneTypes.UPDATE_SCENE_STATUS,
      payload: { syncStatus: msg?.status === "scene_sync_success", ...msg },
    });
  }
}

export const updateSceneDeleteStatus = (msg, dispatch) => {
  if (msg?.status === "scene_delete_success" || msg?.status === "scene_sync_success" || msg?.status === "incorrect_sceneid") {
    clearTimeout(sceneDeleteTimeout)
    setTimeout(() => {
      dispatch({
        type: sceneTypes.UPDATE_DELETE_SCENE_STATUS,
        payload: { deleteSceneStatus: "delete_scene" + Date.now() },
      });
    }, sceneDeleteMessageLatency)
  }
}

export const setSceneDeleteTimeout = (ms, dispatch) => {
  sceneDeleteTimeout = setTimeout(() => {
    dispatch({
      type: sceneTypes.UPDATE_DELETE_SCENE_STATUS,
      payload: { deleteSceneStatus: "delete_scene" + Date.now() },
    });
    console.log("scene delete timeout")
  }, ms)
}

