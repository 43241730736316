/***
 *
 * Controller class for user.
 * @file AddEditSpaceType.js
 * @description AddEditSpaceType component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 *
 */

import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
// import PropTypes from 'prop-types';
import { Box } from "@mui/material";
import { useStyles } from "./AddEditSpaceType.style.js";
import PageHeader from "../PageHeader/index.js";
import "./AddEditSpaceType.scss";
import { spaceTypeActions } from '../../redux/actions';
import FormWrapper from "../FormWrapper/index.js";
import spaceIcon from '../../assets/img/space-types.png';
import { getMessageStr } from "../../helpers/message.helper.js";


const initialState = {
  spaceType: "",
  icons: "",
  img: "",
};

const AddEditSpaceType = ({ selectedSpaceType = null, setOpenPopover = () => { } }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  //const spaceDTypes = useSelector(state => state?.spaceDTypes);
  const [formData, setFormData] = useState({});
  const [payload, setPayload] = useState({});
  const frmConfig = [
    {
      section: { heading: null },
      columns: [
        {
          id: "1",
          type: "text",
          label: "Name",
          name: "spaceType",
          placeholder: "Enter",
          columnSize: 12,
          required: true,
          validation: {
            req: [true, getMessageStr('spaceType-add-nameEmpty')],
            minLen: [2, getMessageStr('spaceType-add-minCharacter')],
            maxLen: [30, getMessageStr('spaceType-add-maxCharacter')],
            alphaNumChars: ['-', getMessageStr('spaceType-add-alphanumchars')]
          },
          disabledOnEdit: true
        },
      ],
    },
    {
      section: { heading: null },
      columns: [
        {
          id: "4",
          type: "file",
          allowedFileType: 'image/*',
          label: "Add Icon",
          name: "icons",
          required: true,
          format: ['jpg', 'jpeg', 'JPEG', 'svg', 'png', 'PNG'],
          helperText: null,
          validation: {
            req: [true, getMessageStr('spaceType-add-iconRequired')],
            fileSize: [50, getMessageStr('spaceType-add-iconFileSize')],/// in kB , for MB use size>=1000
          },
          placeholder: "Upload a File with Size Less than 50KB",
          columnSize: 6,
          confirm: true,
          preview: { width: '150px', maxHeight: '150px' },
          multiple: false,
          onTop: false,
          style: { padding: 30 },
          // disabled: payload?.icons?.file?.icons?.length>=1
        },
        {
          id: "6",
          type: "file",
          label: "Add Image",
          allowedFileType: 'image/*',
          name: "img",
          format: ['jpg', 'jpeg', 'JPEG', 'svg', 'png', 'PNG'],
          value: "",
          helperText: null,
          validation: {
            fileSize: [1000, getMessageStr('spaceType-add-imageFileSize')], //// image size is 1MB
          },
          style: { padding: 30 },
          placeholder: "Upload a File with Size Less than 1MB",
          columnSize: 6,
          preview: { width: '150px', maxHeight: '150px' },
          confirm: true,
          multiple: false,
          onTop: false,
        }
      ],
    },

  ];


  useEffect(() => {
    if (selectedSpaceType !== null) {
      setFormData({ spaceType: selectedSpaceType.spaceType, icons: (selectedSpaceType.icons === null ? "" : selectedSpaceType.icons), img: (selectedSpaceType.img === null ? "" : selectedSpaceType.img) })
    }
    else {
      setFormData(initialState)
    }
  }, [selectedSpaceType])

  const handleSubmit = () => {
    let data = {
      'spaceType': payload?.spaceType,
      'icons': payload?.icons?.base64 || payload?.icons || '',
      'img': payload?.img?.base64 || ""
    }

    if (selectedSpaceType !== null) {
      delete data.spaceType;
      dispatch(spaceTypeActions.editSpaceType(selectedSpaceType.spaceType, data, () => {
        dispatch(spaceTypeActions.getSpaceTypes());
      }));
    }
    else {
      dispatch(spaceTypeActions.addSpaceType(data, () => {
        dispatch(spaceTypeActions.getSpaceTypes());
      }));
    }
    setFormData({});
    setPayload({});
  };


  const actionButtons = [{
    heading: null,
    columns: [
      {
        heading: null,
        buttons: [
          {
            id: "cancel",
            type: "button",
            variant: 'outlined',
            display: true,
            checkValidation: false,
            label: "Cancel",
            onClick: (e, data) => {
              setOpenPopover({ target: null, selectedSpaceType: null });
            }
          },
          {
            id: "save",
            type: "button",
            color: 'primary',
            checkValidation: true,
            display: true,
            label: 'Save',
            onClick: (e, data) => {
              handleSubmit();
              setOpenPopover({ target: null, selectedSpaceType: null });
            }
          }
        ],
        columnSize: 12,
      },
    ],
  },
  ]



  return (
    <Box className={classes?.AddEditSpaceType || ""} data-testid="AddEditSpaceType">
      <PageHeader
        title={`${selectedSpaceType !== null ? 'Edit' : 'Create'} Space Type`}
        icon={spaceIcon}
      ></PageHeader>
      <Box sx={{ justifyContent: 'center', width: '100%' }}>
        <FormWrapper setPayload={(data) => {
          const file = data["img"];
          setPayload(data);
        }} formData={formData} formFields={frmConfig} formButtons={actionButtons} operation={selectedSpaceType !== null ? "edit" : "create"} />
      </Box>
    </Box>
  );
};

AddEditSpaceType.propTypes = {};

AddEditSpaceType.defaultProps = {};

export default AddEditSpaceType;
