import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    StyledFileUpload: {
        '& .uploaded-list': {

            '& .uploaded-list-item': {
                '& .item-kkk': {
                    display: "flex",
                    alignItems:'center',
                    justifyContent:'space-between',
                    width:'100%',
                    '& .icon-kkk': {
                        marginRight: "7px",
                        display: "flex",
                        alignItems:'center',
                    },
                    '& .text-kkk': {
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        marginLeft:'10px'
                    },
                    '& .delete-icon-kkk': {
                        width: "max-content",
                        marginLeft: "15px"
                    }
                }
            }
        }
    },
    DropableArea: {
        width: "max-content",
        height: "100%",
        maxWidth: "100%",
        // padding: "3px",
        border: "1px dotted #D9D9D9",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minWidth: "100%",
        minHeight: "200px",
        position: "relative",
        overflow: "hidden",
        '& .uDefault': {
            position: "absolute",
            top: 0,
            bottom: 0,
            width: "100%",
            maxHeight: "100%",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
        },
        '& .uploadDefault': {
            opacity: "0.4",
            transition: "0.3s",
            corsur: "pointer",
            backgroundColor: "#0F8D480D",
            padding: "30px",
            '&:hover': {
                color: "#fff",
                opacity: "0.9",
                backgroundColor: "#e6fcee"
            }
        },
        '& .uploadDefault-hover': {
            position: "absolute",
            textAlign: "center",
            opacity: "0",
            transition: "0.3s",
            corsur: "pointer",
            backgroundColor: "#0F8D480D",
            padding: "30px",
            zIndex: "1",
            '&:hover': {
                opacity: "0.9",
                backgroundColor: "#e6fcee"
            }
        },
        '& .delete-icon': {
            position: "absolute",
            top: 5,
            right: 5,
            opacity: "0.95",
            transition: "0.3s",
            backgroundColor: "#e7e7e7",
            // borderRadius: "3px",
            padding: "5px",
            zIndex: "1081",
            '&:hover': {
                backgroundColor: "#fff",
                opacity: "1"
            }
        },
        '& .icon': {
            width: "40px",
            maxHeight: "100px",
            display: "block",
            margin: "0 auto 15px",
        },
        '& .placeholder': {
            fontSize: '0.75rem',
            color: "#000",
            display: "block"
        }
    }
});
