/***
 *
 * Controller class for user.
 * @file DesignSystem.js
 * @description DesignSystem component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import { Input } from '@material-ui/core';
import { VisibilityOff } from '@mui/icons-material';
import { Avatar, Box, Button, CardHeader, Grid, IconButton, InputAdornment, Modal, Paper, Stack } from '@mui/material';
import React, { useState } from 'react';
import ChipInput from '../ChipInput';
import CustomAccordion from '../CustomAccordion';
import CustomDropDown from '../CustomDropDown';
import CustomStepper from '../CustomStepper';
import CustomTable from '../CustomTable';
import PageHeader from '../PageHeader';
import PopoverContainer from '../PopoverContainer';
import SearchBox from '../SearchBox';
import StyledTextfield from '../StyledTextfield';
// import PropTypes from 'prop-types';
import './DesignSystem.scss';
import InputMask from 'react-input-mask';
import StyledChip from '../StyledChip';
import { ReactComponent as LeftImage } from '../../assets/icons/lamp.svg';
import CanvasArea from '../CanvasArea';
import StyledSpinner from '../StyledSpinner';
import TestComponent from '../TestComponent';
import CustomTypography from '../CustomTypography';
import StyledPopup from '../StyledPopup';
import { useSelector } from 'react-redux';
import StyledTabs from '../StyledTabs';
import CustomizedTree from '../CustomizedTree';
import SwitchButton from '../SwitchButton';
import SpaceDeviceTree from '../SpaceDeviceTree';
import CustomChipInput from '../CustomChipInput';
import StyledEditor from '../StyledEditor';
import StyledScheduler from '../StyledScheduler';
import ImageWrapper from '../ImageWrapper';
import StyledMultiSelect from '../StyledMultiSelect';
import StyledDateTimePicker from '../StyledDateTimePicker';
import StyledTextEditor from '../StyledTextEditor';
import StyledSpaceTreeDropDown from '../StyledSpaceTreeDropDown';
import pdfIcon from '../../assets/icons/pdf-icon.svg';
import StyledCard from '../StyledCard';
import StyledCardWrapper from '../StyledCardWrapper';
import DataNotFound from '../../pages/DataNotFound';
import CodeSnippets from '../CodeSnippets';
import {
  FloatingMenu,
  MainButton,
  ChildButton,
} from 'react-floating-button-menu';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import GradeOutlinedIcon from '@mui/icons-material/GradeOutlined';
import Draggable from 'react-draggable';
import FormWrapper from '../FormWrapper';
import User from '../../assets/icons/user.svg';
import deviceIcon from '../../assets/icons/bulb.svg';
import dashboardIcon from '../../assets/icons/dashboard.svg';
import groupIcon from '../../assets/icons/group.svg';
import wifiOn from '../../assets/icons/wifi-on.svg';
import { useRef } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import StyledBadge from '../StyledBadge';
import StyledButton from '../StyledButton';
import StreamVideo from '../StreamVideo';

const resp = [
  { id: 12, image: User, category: "abc 12", name: "space name 12", location: "gurgaon", date: "01/11/2022", date1: "01/1/2022", status: "active" },
  { id: 13, category: "abc 13", name: "space name 13", location: "delhi", date: "01/2/2022", date1: "01/4/2022", status: "active" },
  { id: 15, category: "abc 12", name: "space name 15", location: "gurgaon", date: "01/7/2022", date1: "01/3/2022", status: "active" },
  { id: 19, category: "abc 13", name: "space name 19", location: "rewari", date: "2/1/2022", date1: "01/6/2022", status: "active" },
  { id: 20, category: "abc 13", name: "space name 19", location: "rewari", date: "2/6/2022", date1: "03/11/2022", status: "active" },
];

const opts = [
  { id: 15, label: "Gurgaon" },
  { id: 20, label: "Rewari" },
  { id: 21, label: "Delhi" },
];

const spaces = [
  { spaceId: 1, icon: "", name: "Space 1", Spaces: [] },
  {
    spaceId: 2, icon: "", name: "Space 2", Spaces: [
      {
        spaceId: 3, icon: "", name: "Space 2-1", Spaces: [
          { spaceId: 4, icon: "", name: "Space 2-1-1", Spaces: [] },
          {
            spaceId: 5, icon: "", name: "Space 2-1-2", Spaces: [
              { spaceId: 6, icon: "", name: "Space 2-1-3-1", Spaces: [] },
              { spaceId: 7, icon: "", name: "Space 2-1-3-2", Spaces: [] },
            ]
          },
          {
            spaceId: 8, icon: "", name: "Space 2-1-3", Spaces: [
              { spaceId: 9, icon: "", name: "Space 2-1-3-1", Spaces: [] },
              { spaceId: 10, icon: "", name: "Space 2-1-3-2", Spaces: [] },
            ]
          },
        ]
      }
    ]
  },
];

const sync = () => {

}

const frmConfig = [
  {
    section: { heading: null },
    columns: [
      {
        id: "1",
        type: "text",
        label: "Username",
        name: "username",
        placeholder: "Enter",
        required: true,
        validation: {
          req: [true, 'Required'],
          minLen: [3, 'Requires minimum 3 characters'],
          maxLen: [20, 'Requires maximum 20 characters'],
          alphaNum: [true, 'Only # _ - and "space" are allowed']
        },
        columnSize: 4
      },
      {
        id: "11",
        type: "text",
        label: "Name",
        name: "name",
        placeholder: "Enter",
        required: true,
        disabledOnEdit: false,
        validation: {
          req: [true, 'Required'],
          minLen: [10, 'Requires minimum 10 characters'],
          maxLen: [20, 'Requires maximum 20 characters'],
          alphaNum: [true, 'Only # _ - and "space" are allowed']
        },
        columnSize: 4
      },
    ]
  }, {
    section: { heading: null },
    columns: [
      {
        id: "13",
        type: "text",
        label: "Email",
        name: "email",
        columnSize: 4
      }
    ]
  }, {
    section: { heading: null },
    columns: [
      {
        id: "18",
        type: "select",
        label: "Select Label",
        name: "select",
        defaultValue: "No",
        isNoneAsItem: false,
        options: [],
        columnSize: 4
      },
      {
        id: "19",
        type: "datetimepicker",
        picker: "datePicker",
        label: "Date Picker",
        name: 'startdate',
        required: true,
        customsx: {
          datePicker: {
            '.css-9ltu22-MuiInputBase-root-MuiOutlinedInput-root.Mui-error.MuiOutlinedInput-notchedOutline': {
              borderColor: '#C4C4C4'
            }
          }
        },
        validation: {
          req: [true, 'required']
        },
        columnSize: 4
      },
    ]
  }, {
    section: { heading: null },
    columns: [
      {
        id: "17",
        type: "chipInput",
        label: "Permissions",
        name: "roles",
        placeholder: "Add permission",
        disabledOnEdit: false,
        visibleIn: ['edit', 'view', 'create'],
        columnSize: 4
      }, {
        id: "18",
        type: "radiobutton",
        label: "Radio Label",
        name: "radiobtns",
        columnSize: 4,
        required: true,
        // selectedColor:'#0f8d48',
        // selectedWeight:700,        
        validation: {
          req: [true, 'required']
        }
      }, {
        id: "19",
        type: "checkbox",
        label: "Checkbox Label",
        name: "checkbox",
        columnSize: 4,
        selectedColor: '#0f8d48',
        selectedWeight: 700,
        required: true,
        validation: {
          req: [true, 'required']
        }
      }
    ]
  }, {
    section: { heading: null },
    columns: [
      {
        id: "20",
        type: "colorPicker",
        label: "Picker",
        name: "primarycolor",
        format: 'hex', /// default
        placeholder: "Add Color",
        disabledOnEdit: false,
        columnSize: 6,
        required: true,
        validation: {
          req: [true, 'required'],
          hex: [true, 'Invalid color']
        }
      }, {
        id: "20",
        type: "colorPicker",
        label: "Picker",
        name: "secondarycolor",
        format: 'hex', /// default
        placeholder: "Add Color",
        disabledOnEdit: false,
        columnSize: 6,
        required: true,
        validation: {
          req: [true, 'required']
        }
      }
    ]
  }, {
    section: { heading: null },
    columns: [
      {
        id: "23",
        type: "switchButton",
        label: "Enable",
        name: "enable",
        formLabelsx: { mb: '4px' },
        disabledOnEdit: false,
        btntype: 'iphone',
        columnSize: 4,
        required: true,
        validation: {
          req: [true, 'required']
        }
      }
    ]
  }
];

// const cardIconsList = [
//   {
//     "imageSource": relaylightIcon,
//     "borderInfo": "1px solid #fdd600"
//   },
//   {
//     "imageSource": relaylightIcon,
//     "borderInfo": "1px solid #d7aaff"
//   },
//   {
//     "imageSource": relaylightIcon,
//     "borderInfo": "1px solid #ffa573"
//   },
//   {
//     "imageSource": relaylightIcon,
//     "borderInfo": "1px solid #b4e051"
//   },
//   {
//     "imageSource": relaylightIcon,
//     "borderInfo": "1px solid #7cc7ff"
//   },
//   {
//     "imageSource": relaylightIcon,
//     "borderInfo": "1px solid #fcddec"
//   }
// ];

const frmOptions = {
  dropdowns: {
    //// key here must be same as name in frm config
    roles: { list: [{ label: 'option1', value: 'option1' }, { label: 'option2', value: 'option2' }, { label: 'option3', value: 'option3' }, { label: 'option4', value: 'option4' }] },
    radiobtns: { list: [{ label: 'option1', value: 'option1' }, { label: 'option2', value: 'option2' }, { label: 'option3', value: 'option3' }, { label: 'option4', value: 'option4' }] },
    checkbox: { list: [{ label: 'option1', value: 'option1' }, { label: 'option2', value: 'option2' }, { label: 'option3', value: 'option3' }, { label: 'option4', value: 'option4' }] },
    select: { list: [{ label: 'option1', value: 'option1' }, { label: 'option2', value: 'option2' }, { label: 'option3', value: 'option3' }, { label: 'option4', value: 'option4' }] }
  }
}

// data for StyledSpaceTree
export const spaceList = [];

const DesignSystem = () => {

  const actionButtonsRef = useRef();

  const [popoverAnchor, setPopoverAnchor] = useState(null)
  const [popoverTree, setPopoverTree] = useState(null)
  const [maskInput, setMaskInput] = useState('');
  const [modalOpen, setOpenModal] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [tableData, setTableData] = useState(resp);
  const [spacevalue, setSpaceValue] = React.useState('Select space');
  const [toggleswitch, setToggleSwitch] = useState(false);
  const spaceList = useSelector((state) => state?.spaces?.list);
  const [openFloat, setOpenFloat] = useState(false);
  const [floatPopOver, setFloatPopOver] = useState(null);
  const [currentFloater, setCurrentFloater] = useState('')


  const handleMaskInput = (e) => {
    setMaskInput(e.target.value)
  }
  const handlefocus = (e) => {
    console.log('focus-->', e.target.value);
    // setMaskInput(e.target.value)
  }


  const style = {
    bgcolor: 'background.paper',
    border: '2px solid #ededef',
    position: 'absolute',
    transform: "translate(-50%, -50%)",
    left: "50%",
    top: '50%',
    margin: '0 auto',
    height: '600px',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  // const customCardIconsStyle = {
  //   width: '44px',
  //   padding: '12px',
  //   backgroundColor: 'white',
  //   marginLeft: '-6px',
  //   borderRadius: '50%',
  //   boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08), 0px 4px 24px rgba(0, 0, 0, 0.08)'
  // };


  return (
    <Box className="DesignSystem" data-testid="DesignSystem">
      <Box sx={{ width: "100%" }}>
        <PageHeader icon={deviceIcon} title="Page title" bckbtn={true}>
          <Box>
            <Button size="small" sx={{ mr: 1 }} disabled>Disabled</Button>
            <Button color='primary' size="medium" sx={{ mr: 1 }}>medium</Button>
            <Button color='secondary' size="large" sx={{ mr: 1 }}>large</Button>
          </Box>
        </PageHeader>
      </Box>

      <Box sx={{ width: '100%', height: '200px', position: 'relative', overflow: 'hidden', mb: 3 }}>
        <StyledSpinner type="pageLoader" />
      </Box>
      <Box sx={{ mb: 3 }}>
        <StyledSpinner />
      </Box>
      <Box sx={{ mb: 3 }}>
        <StyledSpinner type="line" />
      </Box>
      <Box sx={{ my: 2 }}>
        <Grid container spacing={3}>
          <Grid item md={4} xs={12}>
            <StyledCardWrapper
              heading={{ text: "heading", position: "pageTop" }}
              media={{ src: "jkjk", width: "100%" }}
              content={{ text: "The text-overflow property specifies how overflowed content that is not displayed should be signaled to the user. It can be clipped, display an ellipsis (...), or display a custom string." }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <StyledCardWrapper sx={{ borderLeft: "3px green solid" }}>
              <Box style={{ backgroundImage: `url(${wifiOn})`, backgroundSize: '120px', backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom right', height: '100%' }}>
                <Box style={{ backgroundColor: "rgb(255 255 255 / 94%)", minHeight: "100px" }}>
                  <CardHeader
                    sx={{ p: 0 }}
                    avatar={
                      <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                      >
                        <Avatar sx={{ bgcolor: "grey" }} aria-label="recipe" variant="rounded">R</Avatar>
                      </StyledBadge>
                    }
                    action={
                      <IconButton aria-label="settings">
                        <MoreVertIcon />
                      </IconButton>
                    }
                    title="Shrimp and Chorizo Paella"
                    titleTypographyProps={{ fontWeight: "600", color: "#0F8D48", fontSize: '0.9rem' }}
                    subheader="September 14, 2016"
                    subheaderTypographyProps={{ fontSize: '0.75rem' }}
                  />
                  <Box pt={1.5}>
                    <Stack spacing={0.2} direction="column" alignItems="left">
                      <Stack direction="row" alignItems="left">
                        <Stack pr={1.5} sx={{ minWidth: "fit-content" }}>
                          <CustomTypography size={12} sx={{ fontWeight: 'bold' }} >Model Number: </CustomTypography>
                        </Stack>
                        <Stack sx={{ width: "auto", overflow: "auto" }}>
                          <CustomTypography size={12}>GS-GW-1</CustomTypography>
                        </Stack>
                      </Stack>

                      <Stack direction="row" alignItems="left">
                        <Stack pr={1.5} sx={{ minWidth: "fit-content" }}>
                          <CustomTypography size={12} sx={{ fontWeight: 'bold' }} >Mac Address: </CustomTypography>
                        </Stack>
                        <Stack sx={{ width: "auto", overflow: "auto" }}>
                          <CustomTypography size={12} sx={{ wordWrap: "break-word" }}>0987654321098765432109876543210987654321</CustomTypography>
                        </Stack>
                      </Stack>

                      <Stack direction="row" alignItems="left">
                        <Stack pr={1.5} sx={{ minWidth: "fit-content" }}>
                          <CustomTypography size={12} sx={{ fontWeight: 'bold' }} >IP address: </CustomTypography>
                        </Stack>
                        <Stack sx={{ width: "auto", overflow: "auto" }}>
                          <CustomTypography size={12}>1.1.1.1</CustomTypography>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Box>
                </Box>
              </Box>
            </StyledCardWrapper>
          </Grid>
          <Grid item md={4} xs={12}>
            <StyledCardWrapper sx={{ borderLeft: "3px red solid" }}>
              <Box style={{ backgroundImage: `url(${wifiOn})`, backgroundSize: '120px', backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom right', height: '100%' }}>
                <Box style={{ backgroundColor: "rgb(255 255 255 / 94%)", minHeight: "100px" }}>
                  <CardHeader
                    sx={{ p: 0 }}
                    avatar={
                      <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                        bgcolor='#ff0000'
                      >
                        <Avatar sx={{ bgcolor: "grey" }} aria-label="recipe" variant="rounded">R</Avatar>
                      </StyledBadge>
                    }
                    action={
                      <IconButton aria-label="settings">
                        <MoreVertIcon />
                      </IconButton>
                    }
                    title="Shrimp and Chorizo Paella"
                    titleTypographyProps={{ fontWeight: "600", color: "#0F8D48", fontSize: '0.9rem' }}
                    subheader="September 14, 2016"
                    subheaderTypographyProps={{ fontSize: '0.75rem' }}
                  />
                  <Box pt={1.5}>
                    <Stack spacing={0.2} direction="column" alignItems="left">
                      <Stack direction="row" alignItems="left">
                        <Stack pr={1.5} sx={{ minWidth: "fit-content" }}>
                          <CustomTypography size={12} sx={{ fontWeight: 'bold' }} >Model Number: </CustomTypography>
                        </Stack>
                        <Stack sx={{ width: "auto", overflow: "auto" }}>
                          <CustomTypography size={12}>GS-GW-1</CustomTypography>
                        </Stack>
                      </Stack>

                      <Stack direction="row" alignItems="left">
                        <Stack pr={1.5} sx={{ minWidth: "fit-content" }}>
                          <CustomTypography size={12} sx={{ fontWeight: 'bold' }} >Mac Address: </CustomTypography>
                        </Stack>
                        <Stack sx={{ width: "auto", overflow: "auto" }}>
                          <CustomTypography size={12} sx={{ wordWrap: "break-word" }}>0987654321098765432109876543210987654321</CustomTypography>
                        </Stack>
                      </Stack>

                      <Stack direction="row" alignItems="left">
                        <Stack pr={1.5} sx={{ minWidth: "fit-content" }}>
                          <CustomTypography size={12} sx={{ fontWeight: 'bold' }} >IP address: </CustomTypography>
                        </Stack>
                        <Stack sx={{ width: "auto", overflow: "auto" }}>
                          <CustomTypography size={12}>1.1.1.1</CustomTypography>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Box>
                </Box>
              </Box>
            </StyledCardWrapper>
          </Grid>
          <Grid item md={4} xs={12}>
            <StyledCard />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Button color='primary' size='small' variant='outlined'>Success popup</Button>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Button onClick={() => setOpenModal(true)} color='primary' size='small' variant='outlined'>Demo canvas</Button>
      </Box>

      <Box sx={{ p: 1, border: '1px solid grey' }}>
        StyledButton Component
        <Box sx={{ m: 1 }}>
          <StyledButton sx={{ mr: 2 }}>Contained Button</StyledButton>
          <StyledButton sx={{ mr: 2 }} variant='outlined'>Outlined Button</StyledButton>
          <StyledButton sx={{ mr: 2 }} color='warning'>Warning Colored Button</StyledButton>
          <StyledButton sx={{ mr: 2 }} tooltip='your button description goes here...'>Button with Tooltip</StyledButton>
          <StyledButton sx={{ mr: 2 }} size='large'>Large Button</StyledButton>
        </Box>
        <Box sx={{ m: 1 }}>
          <StyledButton sx={{ mr: 2 }} fullWidth>Full Width Button</StyledButton>
        </Box>
        <Box sx={{ m: 1 }}>
        </Box>
        <Box sx={{ m: 1 }}>
          Icon Buttons:
          <StyledButton iconButton tooltip='Sample Icon Button'>
            <ImageWrapper src={User} />
          </StyledButton>
          <StyledButton iconButton tooltip='Icon button tooltip with right side placement' placement='right'>
            <ImageWrapper src={groupIcon} />
          </StyledButton>
        </Box>
      </Box>

      <Box sx={{ py: 1 }}>
        <Button color='primary' size="small" sx={{ mr: 1 }}>small</Button>
        <Button color='primary' size="medium" sx={{ mr: 1 }}>medium</Button>
        <Button color='secondary' size="large" sx={{ mr: 1 }}>large</Button>
        <Button color='primary' size="extra-large" variant='outlined' sx={{ mr: 1 }}>extra-large</Button>
        <Button sx={{ mr: 1 }} color='primary' size="medium" onClick={(event) => {
          setPopoverAnchor(event.currentTarget)
        }}>
          open popover container
        </Button>
        <PopoverContainer anchorEl={popoverAnchor} onClose={() => setPopoverAnchor(null)}>
          <CustomTable headerList={{ name: { label: 'heading 1', type: "link", url: "/spaces/devices", urlParams: { id: "id" } }, category: { label: 'heading 2' }, status: { label: 'heading 3' }, location: { label: 'heading 4' } }} cellMatrix={resp} />
        </PopoverContainer>
      </Box>
      <Box sx={{ py: 1 }}>
        <CustomTypography content="Multi Select" weight={700} size={13} lineHeight={19} color="#202020" />
        <StyledMultiSelect name="multiselect" id="multiselect" options={opts.map(r => { return r?.label || "" })} />
      </Box>
      <Box sx={{ py: 1 }}>
        <StyledTextfield fullWidth value={'normal textfield'} />
      </Box>
      <Box sx={{ py: 1 }} >
        <InputMask placeholder='DD/MM/YYYY' mask={"99/99/9999"} onFocus={handlefocus} value={maskInput} onChange={handleMaskInput}>
          {(inputProps) => <StyledTextfield fullWidth {...inputProps} />}
        </InputMask>
      </Box>
      <Box sx={{ py: 1 }} >
        <Input placeholder="Placeholder"  >
          {(inputProps) => <StyledTextfield {...inputProps} />}
        </Input>
      </Box>
      <Box sx={{ py: 1 }} >
        <StyledSpaceTreeDropDown
          multiselect={true}
          value={spaceList}
        />
      </Box>
      <Box sx={{ py: 1 }}>
        <CustomDropDown
          value={"yolo"}
          name="timeInFilter"
          onChange={null}
          onOpen={null}
          list={[{ id: 'yo', label: 'yo' }, { id: 'yolo', label: 'yolo' }, { id: 'boi', label: 'boi' }]}>
        </CustomDropDown>
      </Box>
      <Box sx={{ py: 1 }}>
        <CustomAccordion
          title="Parent test accordian"
          LeftIcon={LeftImage}
          bordered
          contentBg={"#FFFFFF"}
          component={<button type="button">Ok</button>}
        >
          <div>child1</div><div>child2</div>
        </CustomAccordion>
      </Box>
      <Box sx={{ py: 1 }}>
        <StyledChip
          variant="outlined"
          color="primary"
          label={"Green chip"}
          sx={{ marginRight: "10px", marginBottom: "10px" }}
          onDelete={() => { }} />
        <StyledChip
          variant="outlined"
          color="error"
          label={"Red chip"}
          sx={{ marginRight: "10px", marginBottom: "10px" }}
          onDelete={() => { }} />
        <StyledChip
          variant="filled"
          label={"Red chip"}
          sx={{ marginRight: "10px", marginBottom: "10px" }}
          rounded={false}
          image={pdfIcon}
          trashIcon={true}
          onDelete={() => { }} />
        <StyledChip
          variant="filled"
          label={"Red chip"}
          sx={{ marginRight: "10px", marginBottom: "10px" }}
          image={pdfIcon}
          trashIcon={true}
          onDelete={() => { }} />
      </Box>

      <Box sx={{ py: 1 }}>
        <ChipInput placeholder="add a chip" rounded options={[{ id: 1, location: "One" }, { id: 2, location: "Two" }, { id: 3, location: "three" }]} columns={{ id: "id", label: "location" }} onChange={(e) => {
          console.log("chip values", e?.target?.value)
        }} />
      </Box>
      <Box sx={{ py: 1 }}>
        <ChipInput type="text" placeholder="add a chip" rounded options={[{ id: 1, location: "One" }, { id: 2, location: "Two" }, { id: 3, location: "three" }]} columns={{ id: "id", label: "location" }} onChange={(e) => { console.log("chip values", e?.target?.value) }} />
      </Box>
      <Box sx={{ py: 1 }}>
        <CustomTypography content="Chip input with textbox" weight={700} size={13} lineHeight={19} color="#202020" />
        <CustomChipInput chipsPosition="left" placeholder="add a chip by textbox" rounded onKeyDown={(chips) => { console.log("chip --- ", chips) }} />
      </Box>
      <Box sx={{ py: 1 }}>
        <CustomTypography content="Date Picker" weight={700} size={13} lineHeight={19} color="#202020" />
        <StyledDateTimePicker
          picker="DatePicker"
          value=""
          placeholder="Select date"
          disablePast={true}
          minDate="01/07/2023"
          maxDate="01/10/2023"
          name="myDatePicker"
          onChange={(e) => { console.log("Date --- ", e?.target?.name, ": ", e?.target?.value) }}
        />
        <a rel="" type="" href="https://mui.com/x/api/date-pickers/calendar-picker/">DateTimePicker params/props ref</a>
      </Box>
      <Box sx={{ py: 1 }}>
        <StyledTextfield fullWidth value={'text field with icon that is clickable'} InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                edge="end"
                aria-label="Toggle password visibility"
                onClick={() => console.log('hi')}
              >
                <VisibilityOff />
                {/* {values.showPassword ? <VisibilityOff /> : <Visibility />} */}
              </IconButton>
            </InputAdornment>
          ),
        }} />
      </Box>
      <Box sx={{ py: 1 }}>
        <SearchBox data={resp} placeholder="Search here..." rounded columns={{ id: "id", label: "location" }} />
      </Box>
      <Box sx={{ py: 1 }}></Box>
      <Box sx={{ py: 1 }}>
        <CustomTypography content={`${spacevalue}`} weight={700} size={13} lineHeight={19} color="#202020" />
        {/* <CustomizedTree isRadioButton={true} data={spaces} setterFunction={setSpaceValue} /> */}
      </Box>
      <Box sx={{ py: 1 }}>
        <StyledTabs tabs={[{ icon: dashboardIcon, label: 'Tabe 1' }, { icon: deviceIcon, label: 'Tabe 2' }, { icon: groupIcon, label: 'Tabe 3' }]} onClick={(event, data) => { console.log(event, data) }} />
      </Box>
      {/* <Box sx={{py: 1}}>
         <StyledTabPanel tabs={[{icon:dashboardIcon, label: 'Tabe 1'}, {icon: deviceIcon, label: 'Tabe 2'}, {icon: groupIcon, label: 'Tabe 3'}]} >Hello</StyledTabPanel>
       </Box> */}
      <Box sx={{ py: 1 }}>
        {
          JSON.stringify(spacevalue, null, 3)
        }
        <CustomTypography content={`${Array.isArray(spacevalue) ? spacevalue?.name?.join(' ') : spacevalue?.name}`} weight={700} size={13} lineHeight={19} color="#202020" />

        {/* {<StyledTree type='checkbox' data={CHECKBOX_DATA} idKey="spaceId" childrenKey="Spaces"   setterFunction={(value)=>setSpaceValue(value)} />} */}
        {<SpaceDeviceTree type='checkbox' data={[]} idKey="spaceId" childrenKey="Spaces" setterFunction={(value) => setSpaceValue(value)} />}
      </Box>
      <Box sx={{ py: 1 }}>
        <CustomTypography content={toggleswitch ? 'ON' : "OFF"} size={14} weight={500} />
        <SwitchButton type='iphone' onChange={(e, checked) => { setToggleSwitch(checked) }} />
      </Box>
      <Box sx={{ py: 1 }}><SearchBox data={resp} autoComplete={true} filteredData={setTableData} /></Box>
      <Box sx={{ py: 1 }}>
        <CustomTypography content="Rich text editor" size={14} weight={500} />
        <StyledTextEditor />
      </Box>
      <Box sx={{ py: 1 }}>
        Always create  state variables that have been created in the component where CustomTable is being used.Use this state variable for headerList and cellMatrix props.
        <CustomTable
          headerList={
            {
              name: {
                label: 'heading 1',
                type: "link",
                url: "/spaces",
                urlParams: { spaceId: "id", op: "category" },
                toolTip: {
                  content: "Hello"
                }
              },
              multiKeys: {
                label: 'heading 11',
                name: {},
                image: {
                  type: "image",
                  height: "50px",
                  position: "left"
                },
                location: {},
              },
              multiKeys1: {
                label: 'heading 11',
                name: {},
                image: {
                  type: "image",
                  height: "50px",
                  position: "left"
                },
                location: {},
                sortKey: "name",
              },
              category: {
                label: 'heading 2',
                icon: {
                  path: "/smLogo.png",
                  type: "avatar"
                },
                toolTip: {
                  content: "heading 2"
                }
              },
              status: {
                label: 'heading 3',
                type: "html",
                content: <h1>Hi</h1>
              },
              location: {
                label: 'heading 4',
                toolTip: {
                  type: "component",
                  content: TestComponent
                },
              },
              location1: {
                label: 'heading 5',
                type: 'component',
                component: TestComponent,
                toolTip: {
                  type: "component",
                  content: TestComponent
                },
                sortKey: "status"
              },
              date: {
                label: 'date',
                type: "date",
                format: 'dd-MM-yyyy',//optional
                locale: "en"//optional
              },
              date1: {
                label: 'date3',
                type: "date",
              },
              userActions: {
                label: "Actions",
                actions: [
                  {
                    label: "add",
                    type: "add",
                    onClick: (event, data) => {
                      console.log("table action -- ", event, data)
                    },
                  },
                  {
                    label: "edit",
                    type: "edit",
                    onClick: (event, data) => {
                      console.log("table action -- ", event, data)
                    },
                  },
                  {
                    label: "identify",
                    type: "identify",
                    onClick: (event) => {
                      setPopoverTree(event.currentTarget)
                    }
                  },
                  {
                    label: "move",
                    type: "move",
                    onClick: (event) => {
                      setPopoverTree(event.currentTarget)
                    },
                  },
                  {
                    label: "refresh",
                    type: "refresh",
                    onClick: (event) => {
                      setPopoverTree(event.currentTarget)
                    }
                  },
                  {
                    label: "pairing",
                    type: "pairing",
                    onClick: (event) => {
                      setPopoverTree(event.currentTarget)
                    }
                  },
                  {
                    label: "unpairing",
                    type: "unpairing",
                    onClick: (event) => {
                      setPopoverTree(event.currentTarget)
                    }
                  },
                  {
                    label: "system reset",
                    type: "systemReset",
                    onClick: (event) => {
                      setPopoverTree(event.currentTarget)
                    },
                  },
                  {
                    label: "factory reset",
                    type: "factoryReset",
                    onClick: (event) => {
                      setPopoverTree(event.currentTarget)
                    }
                  },
                  {
                    label: "delete",
                    type: "delete",
                    onClick: (event) => {
                      setPopoverTree(event.currentTarget)
                    },
                  },
                  {
                    label: "toggle",
                    type: "toggle",
                    value: false,
                    onChange: (event) => {
                      setPopoverTree(event.currentTarget)
                    },
                  }
                ],
                style: { textAlign: "right" },
              }
            }}
          expandable={{ component: TestComponent }}
          cellMatrix={tableData}
          pagination={true}
          searching={{ columns: ["name", "category"] }}
          sorting={{ column: "name", order: "asc" }}
          onSync={sync}
          rowConfig={{ highlight: { bgKey: "name", bgValue: "space name 15" } }}
        >
        </CustomTable>
      </Box>
      <Box sx={{ py: 1 }}>CustomStepper
        <CustomStepper labels={['first step', 'second step']} activeStep={0} />
      </Box>

      <StreamVideo
      src="https://live-par-2-cdn-alt.livepush.io/live/bigbuckbunnyclip/index.m3u8"
      ></StreamVideo>

      <Box sx={{ py: 1 }}>
        {/* <PhysicalParamters /> */}
      </Box>
      <Modal open={modalOpen} onClose={() => setOpenModal(false)}>
        <Box sx={{ ...style, width: '90%', overflowY: "scroll" }}>
          <CanvasArea />
        </Box>
      </Modal>
      <Box sx={{ py: 1 }}>
        <Button variant='outlined' onClick={() => setOpenPopup(true)}>open Popup</Button>
      </Box>

      <StyledPopup open={openPopup} onClose={() => setOpenPopup(false)} state="timeout"
        data={{
          title: "Commission",
          content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse non dolor.",
          actions: [
            {
              id: "1001",
              type: "button",
              label: "Ok",
              onClick: (event, data) => {
                console.log(event, data);
                setOpenPopup(false);
              }
            }
          ]
        }} />

      <PopoverContainer anchorEl={popoverTree} style={{ width: 500 }}>
        {/* <StyledTree type="checkbox" data={CHECKBOX_DATA} disableData={['7','11']} /> */}
        <Button variant='outlined' onClick={() => setPopoverTree(null)}> Cancel</Button>
      </PopoverContainer>
      <StyledEditor data={{ deviceId: "" }} value="Device name"></StyledEditor>
      <StyledScheduler header="Schedule Header" type="form" display="stack" customsx={{ body: { background: "rgba(15, 141, 72, 0.05)", margin: "16px", padding: "16px", "maxWidth": "100%" } }} actionButtons={[{ name: "cancel", label: "Cancel", action: () => { } }, { name: "save", label: "Save", action: () => { } }]} schedule={{ day: ["3", "5"], weekDay: ["2", "5"], month: ["9", "7"], time: ["0943", '1517-0205', '1517-0305'], durationInMins: "9" }} params={{ time: true, month: true, dayOfMonth: true, dayOfWeek: "disabled", timeDuration: true, durationInMins: true }} setSchedule={(schedule) => { console.log(schedule) }}></StyledScheduler>

      <Box sx={{ py: 1 }}>
        <CustomTypography content="Data not found page" variant='subtitle2' />
        <CodeSnippets>
          <DataNotFound
            customsx={{ image: { height: '202px', width: '100%' }, label: {}, content: {}, btns: {} }}
            data={{
              label: "No Data Found",
              content: "You have not added any Scenes Yet, create a New Scene to see the details",
              action: [
                {
                  id: "1001",
                  type: "button",
                  label: "Create Scene",
                  onClick: () => { },
                }]
            }
            }
          />
        </CodeSnippets>
      </Box>

      <Box sx={{ py: 1 }}>
        <CustomTypography content="Custom Floating Icon btn" variant='subtitle2' />
        <FloatingMenu
          slideSpeed={500}
          direction="down"
          spacing={8}
          isOpen={openFloat}
        >
          <MainButton
            iconResting={<AddCircleOutlineOutlinedIcon style={{ fontSize: '1.25rem' }} nativeColor="white" />}
            iconActive={<CancelOutlinedIcon style={{ fontSize: '1.25rem' }} nativeColor="white" />}
            backgroundColor="blue"
            onClick={() => setOpenFloat(!openFloat)}
            size={56}
          />
          <ChildButton
            icon={<GradeOutlinedIcon style={{ fontSize: '1.25rem' }} nativeColor="black" />}
            backgroundColor="white"
            size={40}
            onClick={(e) => { setFloatPopOver(e.currentTarget); setCurrentFloater('first') }}
          />
          <ChildButton
            icon={<GradeOutlinedIcon style={{ fontSize: '1.25rem' }} nativeColor="black" />}
            backgroundColor="red"
            size={40}
            id={'second_child'}
            onClick={(e) => { setFloatPopOver(e.currentTarget); setCurrentFloater('second') }}
          />
          <ChildButton
            icon={<GradeOutlinedIcon style={{ fontSize: '1.25rem' }} nativeColor="black" />}
            backgroundColor="white"
            size={40}
            onClick={(e) => { setFloatPopOver(e.currentTarget); setCurrentFloater('third') }}
          />
        </FloatingMenu>
        <PopoverContainer placement={'right'} anchorEl={floatPopOver} onClose={() => { setFloatPopOver(null); setCurrentFloater('') }} >
          <p>{`Hi this popover is opened. ${currentFloater}`}</p>
        </PopoverContainer>
      </Box>

      <Box sx={{ py: 1 }}>
        <Draggable>
          <Paper sx={{ p: 2, width: '400px', cursor: 'move', userSelect: 'none' }}><h3>How to make elements draggable</h3><h5>Just wrap any element in Draggable and it will become draggable. Remember to import from <b>react-draggable</b>. It is only drag'n'move not drag'n'drop.</h5></Paper>
        </Draggable>
      </Box>

      <Box sx={{ py: 1, maxWidth: '500px' }}>
        <h6>No need to make explicit popover mouse event calls for imagewrapper. Just pass enablePopOver=true and hoverLabel in it. see below </h6>
        <ImageWrapper src={pdfIcon} enablePopOver={true} widht={30} height={30} hoverLabel={"Pdf Icon"} />
      </Box>

      <Box sx={{ py: 1 }}>
        <FormWrapper
          ref={actionButtonsRef}
          operation={'create'} /// for reference only; pass 'page' operation in it         
          formFields={frmConfig}
          formOptions={frmOptions}
        // formData={{checkbox:['option1','option2']}}
        />

        <button type="button" onClick={(e) => actionButtonsRef?.current?.handleButton(e, {
          checkValidation: true,
          onClick: (event, data) => {
            console.log("submitted");
          }
        })}>Save</button>

        <button type="button" onClick={(e) => actionButtonsRef?.current?.handleButton(e, {
          checkValidation: true,
          onClick: (event, data) => {
            console.log("Canceled");
          }
        })}>Cancel</button>
      </Box>
    </Box>
  )
};

DesignSystem.propTypes = {};

DesignSystem.defaultProps = {};

export default DesignSystem;
