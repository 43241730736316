import { failure, getMessage, request, success } from "../../helpers";
import { delay, spaceTypeService } from "../../services";
import { spaceDataTypes } from "../types";
import { appActions } from "./app.actions";

const getSpaceTypes = () => {
    return dispatch => {
        dispatch(request(spaceDataTypes.GET_ALL_REQUEST));
        spaceTypeService.getAllSpaceTypes()
            .then(
                spaceDType => {
                    // console.log('spaceDType====', spaceDType);
                    dispatch(success(spaceDataTypes.GET_ALL_SUCCESS, { payload: spaceDType }));
                },
                ({ error, code }) => {
                    dispatch(failure(spaceDataTypes.GET_ALL_FAILURE, error.toString()));
                    getMessage(`spaceType-get-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    };
}

const addSpaceType = (payload, cb = () => { }) => {
    return dispatch => {
        dispatch(request(spaceDataTypes.ADD_REQUEST));
        spaceTypeService.addSpaceType(payload)
            .then(
                ({ data: spaceDType, status }) => {
                    dispatch(success(spaceDataTypes.ADD_SUCCESS, { payload: spaceDType }));
                    getMessage(`spaceType-post-${status}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiSuccessMessage(err));
                    });
                    cb();
                },
                ({ error, code }) => {
                    dispatch(failure(spaceDataTypes.ADD_FAILURE, error.toString()));
                    getMessage(`spaceType-post-${code}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    })
                }
            );
    };
}

const editSpaceType = (spaceType, payload, cb = () => { }) => {
    return dispatch => {
        dispatch(request(spaceDataTypes.UPDATE_REQUEST));
        spaceTypeService.editSpaceType(spaceType, payload)
            .then(
                ({ data: spaceDType, status }) => {
                    dispatch(success(spaceDataTypes.UPDATE_SUCCESS, { spaceType, payload: spaceDType }));
                    getMessage(`spaceType-put-${status}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiSuccessMessage(err));
                    });
                    cb();
                },
                ({ error, code }) => {
                    dispatch(failure(spaceDataTypes.UPDATE_FAILURE, error.toString()));
                    getMessage(`spaceType-put-${code}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    })
                }
            );
    };
}


const deleteSpaceType = (spaceType) => {
    return dispatch => {
        dispatch(request(spaceDataTypes.DELETE_REQUEST));
        spaceTypeService.deleteSpaceType(spaceType)
            .then(({ data, status }) => {
                dispatch(success(spaceDataTypes.DELETE_SUCCESS, { payload: { spaceType } }));
                getMessage(`spaceType-delete-${status}`).then(delay(500)).then((err) => {
                    dispatch(appActions.apiSuccessMessage(err));
                })
            },
                ({ error, code }) => {
                    dispatch(failure(spaceDataTypes.DELETE_FAILURE, error.toString()));
                    getMessage(`spaceType-delete-${code}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    })
                }
            );
    };
}

//Not used anywhere
export const emptySpaceTypeState = (type, payload = null) => {
    if (!type)
        return null;

    return dispatch => {
        dispatch(success(spaceDataTypes[type], { payload: payload }))
    }
}


export const spaceTypeActions = {
    getSpaceTypes,
    deleteSpaceType,
    addSpaceType,
    editSpaceType,
    emptySpaceTypeState
}
