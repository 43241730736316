import { fotaTypes } from "../types";

let initialState = {
    error: null,
    loading: false,
    deviceList: [],
    lastAction: null,
    list: [],
    firmwareDistribution: [],
}

export function fota(state = initialState, action) {
    switch (action.type) {
        case fotaTypes.UPGRADE_FIRMWARE_REQUEST:
        case fotaTypes.GET_NEXT_APPLICABLE_FIRMWARES_REQUEST:
        case fotaTypes.GET_FIRMWARE_DISTRIBUTION_REQUEST:
        case fotaTypes.GET_FIRMWARE_LIST_REQUEST:
        case fotaTypes.DELETE_FIRMWARE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case fotaTypes.UPGRADE_FIRMWARE_SUCCESS:
            return {
                ...state,
                loading: false,
                lastAction: "upgrade_device_success"
            };
        case fotaTypes.GET_NEXT_APPLICABLE_FIRMWARES_SUCCESS:
            return {
                ...state,
                loading: false,
                deviceList: action?.payload?.map(a => {
                    return JSON.parse(JSON.stringify(a))
                }),
                lastAction: "fetch_next_applicable_versions_deviceList"
            }
        case fotaTypes.GET_FIRMWARE_DISTRIBUTION_SUCCESS:
            return {
                ...state,
                loading: false,
                firmwareDistribution: action?.payload,
                lastAction: "fetch_firmware_distribution"
            }
        case fotaTypes.UPDATE_FW_STATUS:
            return {
                ...state,
                deviceList: state?.deviceList?.map(d => {
                    if (d?.deviceId === action?.payload?.deviceId) {
                        d["fwUpdateStatus"] = action?.payload?.status;
                        d["disableUpgrade"] = action?.payload?.disableUpgrade;
                        d["statusExpired"] = action?.payload?.statusExpired;
                    }
                    return JSON.parse(JSON.stringify(d));
                }),
                lastAction: "fw_update_in_progress" + Date.now()
                // lastAction: action?.payload?.status?.toLowerCase()?.includes("upgrade ended") ? ("fw_updated " + Date.now()) : ("fw_update_in_progress " + Date.now())
            }
        case fotaTypes.UPDATE_CONNECTIVITY_STATUS:
            let fwUpdated = false;
            return {
                ...state,
                deviceList: state?.deviceList?.map(d => {
                    if (d?.deviceId === action?.payload?.deviceId) {
                        const versionProp = d?.category?.toLowerCase()?.includes("gateway") ? "fwVersion" : "fwVersion3rdParty";
                        if (action?.payload?.hasOwnProperty(versionProp) && d[versionProp] !== action?.payload[versionProp]) {
                            fwUpdated = true;
                            d[versionProp] = action?.payload[versionProp];
                        }
                        if (action?.payload?.hasOwnProperty("ipAddress") && d?.hasOwnProperty("ipAddress")) {
                            d["ipAddress"] = action?.payload?.ipAddress;
                        }
                        d["deviceStatus"] = {
                            ...d?.deviceStatus,
                            connectionStatus: {
                                ...d?.deviceStatus?.connectionStatus,
                                ts: action?.payload?.ts,
                                onlineStatus: action?.payload?.onlineStatus,
                                nextUpdate: action?.payload?.nextUpdate
                            }
                        }
                    }
                    return d;
                }),
                lastAction: fwUpdated ? "fw_updated " + Date.now() : "connectivity_status_updated" + Date?.now()
            }
        case fotaTypes.GET_FIRMWARE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                list: action?.payload,
                lastAction: "fetch_firmware_list"
            }
        case fotaTypes.DELETE_FIRMWARE_SUCCESS:
            return {
                ...state,
                loading: false,
                list: state?.list?.filter(f => f?.fwId !== action?.payload?.fwId),
                lastAction: "delete_firmware"
            }
        case fotaTypes.GET_FIRMWARE_DISTRIBUTION_FAILURE:
            return {
                ...state,
                loading: false,
                firmwareDistribution: [],
                error: action.payload,
            }
        case fotaTypes.GET_FIRMWARE_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                list: [],
                error: action.payload,
            }
        case fotaTypes.GET_NEXT_APPLICABLE_FIRMWARES_FAILURE:
        case fotaTypes.DELETE_FIRMWARE_FAILURE:
        case fotaTypes.UPGRADE_FIRMWARE_FAILURE:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
}
