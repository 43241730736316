/***
 *
 * Controller class for user.
 * @file StyledColorPicker.js
 * @description StyledColorPicker component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect, useRef } from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './StyledColorPicker.style.js';
import './StyledColorPicker.scss';
import { Box } from '@mui/material';
import { MuiColorInput, matchIsValidColor } from 'mui-color-input';
import ErrorMessage from '../ErrorMessage/index.js';
import dotted from '../../assets/icons/dotted-slant.svg'
import { colorfulGradient } from '../../constants/theme.constants.js';

// available format: hex, hex8, hsl, hsv and rgb

const StyledColorPicker = ({id, name, onChange=()=>{}, format='hex', value='',error = false, message = "", disabled = false, customsx, placeholder }) => {
  const classes = useStyles();
  const [color, setColor] = React.useState(value)
  const inputRef = useRef();
  const handleChange = (color) => {
    setColor(color);
    onChange({
      target: {
        name: name,
        value: color
      }
    });
  }

  useEffect(()=>{
    setColor(value);
  },[value])

  return (
    <Box id={id} className={classes?.StyledColorPicker || ""} data-testid="StyledColorPicker">
      <MuiColorInput fallbackValue="#ffffff" value={color} format={format} error={error} placeholder={placeholder} disabled={disabled} sx={{'.MuiColorInput-Button':{opacity:disabled? 0.5:1, background: !value && `url(${dotted}) no-repeat center !important`},   ...customsx}} 
      className={classes.ColorPicker} isAlphaHidden={!(format === 'rgb')} onChange={handleChange} 
      inputRef={inputRef} inputProps={{id:'colorInput'}}
      />
      {error && <ErrorMessage message={message || ''} />}
    </Box>
  )
};

StyledColorPicker.propTypes = {};

StyledColorPicker.defaultProps = {};

export default StyledColorPicker;
