export const timezones=[
   "(GMT-11:00) Pacific/Niue",
   "(GMT-10:00) Pacific/Hawaii Time",
   "(GMT-09:00) America/Alaska Time",
   "(GMT-08:00) America/Pacific Time",
   "(GMT-08:00) Pacific Time - America/Vancouver",
   "(GMT-07:00) Mountain Time - America/Arizona",
   "(GMT-06:00) Central Time - America/Mexico City",
   "(GMT-05:00) Eastern Time - America/Toronto",
   "(GMT-04:00) America/Bermuda",,
   "(GMT-03:00) America/Santiago",
   "(GMT-02:00) America/Sao Paulo",
   "(GMT-01:00) Atlantic/Cape Verde",
   "(GMT+00:00) London",
   "(GMT+01:00) Europe/Amsterdam",
   "(GMT+02:00) Asia/Jerusalem",
   "(GMT+03:00) Asia/Qatar",
   "(GMT+03:00) Europe/Moscow",
   "(GMT+04:00) Indian/Mauritius",
   "(GMT+04:30) Asia/Kabul",
   "(GMT+05:00) Asia/Karachi",
   "(GMT+05:30) India Standard Time",
   "(GMT+05:45) Asia/Katmandu",
   "(GMT+06:00) Asia/Dhaka",
   "(GMT+07:00) Asia/Bangkok",
   "(GMT+08:00) Asia/Hong Kong",
   "(GMT+08:30) Asia/Pyongyang",
   "(GMT+09:00) Asia/Tokyo",
   "(GMT+09:30) Central Time - Australia/Darwin",
   "(GMT+10:00) Eastern Time - Australia/Brisbane",
  "(GMT+10:30) Central Time - Australia/Adelaide",
   "(GMT+11:00) Eastern Time - Melbourne, Sydney",
   "(GMT+10:00) Pacific/Guam",
   "(GMT+13:00) Pacific/Auckland",
   "(GMT+13:00) Pacific/Fiji"
  ]

export const countryCodes=[
   { label: 'Andorra (AD) +376', id: '+376' },
   { label: 'United Arab Emirates (AE) +971', id: '+971' },
   { label: 'Afghanistan (AF) +93', id: '+93' },
   { label: 'Antigua and Barbuda (AG) +1-268', id: '+1-268' },
   { label: 'Anguilla (AI) +1-264', id: '+1-264' },
   { label: 'Albania (AL) +355', id: '+355' },
   { label: 'Armenia (AM) +374', id: '+374' },
   { label: 'Angola (AO) +244', id: '+244' },
   { label: 'Antarctica (AQ) +672', id: '+672' },
   { label: 'Argentina (AR) +54', id: '+54' },
   { label: 'American Samoa (AS) +1-684', id: '+1-684' },
   { label: 'Austria (AT) +43', id: '+43' },
   { label: 'Australia (AU) +61', id: '+61' },
   { label: 'Aruba (AW) +297', id: '+297' },
   { label: 'Alland Islands (AX) +358', id: '+358' },
   { label: 'Azerbaijan (AZ) +994', id: '+994' },
   { label: 'Bosnia and Herzegovina (BA) +387', id: '+387' },
   { label: 'Barbados (BB) +1-246', id: '+1-246' },
   { label: 'Bangladesh (BD) +880', id: '+880' },
   { label: 'Belgium (BE) +32', id: '+32' },
   { label: 'Burkina Faso (BF) +226', id: '+226' },
   { label: 'Bulgaria (BG) +359', id: '+359' },
   { label: 'Bahrain (BH) +973', id: '+973' },
   { label: 'Burundi (BI) +257', id: '+257' },
   { label: 'Benin (BJ) +229', id: '+229' },
   { label: 'Saint Barthelemy (BL) +590', id: '+590' },
   { label: 'Bermuda (BM) +1-441', id: '+1-441' },
   { label: 'Brunei Darussalam (BN) +673', id: '+673' },
   { label: 'Bolivia (BO) +591', id: '+591' },
   { label: 'Brazil (BR) +55', id: '+55' },
   { label: 'Bahamas (BS) +1-242', id: '+1-242' },
   { label: 'Bhutan (BT) +975', id: '+975' },
   { label: 'Bouvet Island (BV) +47', id: '+47' },
   { label: 'Botswana (BW) +267', id: '+267' },
   { label: 'Belarus (BY) +375', id: '+375' },
   { label: 'Belize (BZ) +501', id: '+501' },
   { label: 'Canada (CA) +1', id: '+1' },
   { label: 'Cocos (Keeling) Islands (CC) +61', id: '+61' },
   { label: 'Congo, Democratic Republic of the (CD) +243', id: '+243' },
   { label: 'Central African Republic (CF) +236', id: '+236' },
   { label: 'Congo, Republic of the (CG) +242', id: '+242' },
   { label: 'Switzerland (CH) +41', id: '+41' },
   { label: "Cote d'Ivoire (CI) +225", id: '+225' },
   { label: 'Cook Islands (CK) +682', id: '+682' },
   { label: 'Chile (CL) +56', id: '+56' },
   { label: 'Cameroon (CM) +237', id: '+237' },
   { label: 'China (CN) +86', id: '+86' },
   { label: 'Colombia (CO) +57', id: '+57' },
   { label: 'Costa Rica (CR) +506', id: '+506' },
   { label: 'Cuba (CU) +53', id: '+53' },
   { label: 'Cape Verde (CV) +238', id: '+238' },
   { label: 'Curacao (CW) +599', id: '+599' },
   { label: 'Christmas Island (CX) +61', id: '+61' },
   { label: 'Cyprus (CY) +357', id: '+357' },
   { label: 'Czech Republic (CZ) +420', id: '+420' },
   { label: 'Germany (DE) +49', id: '+49' },
   { label: 'Djibouti (DJ) +253', id: '+253' },
   { label: 'Denmark (DK) +45', id: '+45' },
   { label: 'Dominica (DM) +1-767', id: '+1-767' },
   { label: 'Dominican Republic (DO) +1-809', id: '+1-809' },
   { label: 'Algeria (DZ) +213', id: '+213' },
   { label: 'Ecuador (EC) +593', id: '+593' },
   { label: 'Estonia (EE) +372', id: '+372' },
   { label: 'Egypt (EG) +20', id: '+20' },
   { label: 'Western Sahara (EH) +212', id: '+212' },
   { label: 'Eritrea (ER) +291', id: '+291' },
   { label: 'Spain (ES) +34', id: '+34' },
   { label: 'Ethiopia (ET) +251', id: '+251' },
   { label: 'Finland (FI) +358', id: '+358' },
   { label: 'Fiji (FJ) +679', id: '+679' },
   { label: 'Falkland Islands (Malvinas) (FK) +500', id: '+500' },
   { label: 'Micronesia, Federated States of (FM) +691', id: '+691' },
   { label: 'Faroe Islands (FO) +298', id: '+298' },
   { label: 'France (FR) +33', id: '+33' },
   { label: 'Gabon (GA) +241', id: '+241' },
   { label: 'United Kingdom (GB) +44', id: '+44' },
   { label: 'Grenada (GD) +1-473', id: '+1-473' },
   { label: 'Georgia (GE) +995', id: '+995' },
   { label: 'French Guiana (GF) +594', id: '+594' },
   { label: 'Guernsey (GG) +44', id: '+44' },
   { label: 'Ghana (GH) +233', id: '+233' },
   { label: 'Gibraltar (GI) +350', id: '+350' },
   { label: 'Greenland (GL) +299', id: '+299' },
   { label: 'Gambia (GM) +220', id: '+220' },
   { label: 'Guinea (GN) +224', id: '+224' },
   { label: 'Guadeloupe (GP) +590', id: '+590' },
   { label: 'Equatorial Guinea (GQ) +240', id: '+240' },
   { label: 'Greece (GR) +30', id: '+30' },
   {
     label: 'South Georgia and the South Sandwich Islands (GS) +500',
     id: '+500'
   },
   { label: 'Guatemala (GT) +502', id: '+502' },
   { label: 'Guam (GU) +1-671', id: '+1-671' },
   { label: 'Guinea-Bissau (GW) +245', id: '+245' },
   { label: 'Guyana (GY) +592', id: '+592' },
   { label: 'Hong Kong (HK) +852', id: '+852' },
   { label: 'Heard Island and McDonald Islands (HM) +672', id: '+672' },
   { label: 'Honduras (HN) +504', id: '+504' },
   { label: 'Croatia (HR) +385', id: '+385' },
   { label: 'Haiti (HT) +509', id: '+509' },
   { label: 'Hungary (HU) +36', id: '+36' },
   { label: 'Indonesia (ID) +62', id: '+62' },
   { label: 'Ireland (IE) +353', id: '+353' },
  { label: 'Israel (IL) +972', id: '+972' },
  { label: 'Isle of Man (IM) +44', id: '+44' },
  { label: 'India (IN) +91', id: '+91' },
  { label: 'British Indian Ocean Territory (IO) +246', id: '+246' },
  { label: 'Iraq (IQ) +964', id: '+964' },
  { label: 'Iran, Islamic Republic of (IR) +98', id: '+98' },
  { label: 'Iceland (IS) +354', id: '+354' },
  { label: 'Italy (IT) +39', id: '+39' },
  { label: 'Jersey (JE) +44', id: '+44' },
  { label: 'Jamaica (JM) +1-876', id: '+1-876' },
  { label: 'Jordan (JO) +962', id: '+962' },
  { label: 'Japan (JP) +81', id: '+81' },
  { label: 'Kenya (KE) +254', id: '+254' },
  { label: 'Kyrgyzstan (KG) +996', id: '+996' },
  { label: 'Cambodia (KH) +855', id: '+855' },
  { label: 'Kiribati (KI) +686', id: '+686' },
  { label: 'Comoros (KM) +269', id: '+269' },
  { label: 'Saint Kitts and Nevis (KN) +1-869', id: '+1-869' },
  {
    label: "Korea, Democratic People's Republic of (KP) +850",
    id: '+850'
  },
  { label: 'Korea, Republic of (KR) +82', id: '+82' },
  { label: 'Kuwait (KW) +965', id: '+965' },
  { label: 'Cayman Islands (KY) +1-345', id: '+1-345' },
  { label: 'Kazakhstan (KZ) +7', id: '+7' },
  { label: "Lao People's Democratic Republic (LA) +856", id: '+856' },
  { label: 'Lebanon (LB) +961', id: '+961' },
  { label: 'Saint Lucia (LC) +1-758', id: '+1-758' },
  { label: 'Liechtenstein (LI) +423', id: '+423' },
  { label: 'Sri Lanka (LK) +94', id: '+94' },
  { label: 'Liberia (LR) +231', id: '+231' },
  { label: 'Lesotho (LS) +266', id: '+266' },
  { label: 'Lithuania (LT) +370', id: '+370' },
  { label: 'Luxembourg (LU) +352', id: '+352' },
  { label: 'Latvia (LV) +371', id: '+371' },
  { label: 'Libya (LY) +218', id: '+218' },
  { label: 'Morocco (MA) +212', id: '+212' },
  { label: 'Monaco (MC) +377', id: '+377' },
  { label: 'Moldova, Republic of (MD) +373', id: '+373' },
  { label: 'Montenegro (ME) +382', id: '+382' },
  { label: 'Saint Martin (French part) (MF) +590', id: '+590' },
  { label: 'Madagascar (MG) +261', id: '+261' },
  { label: 'Marshall Islands (MH) +692', id: '+692' },
  {
    label: 'Macedonia, the Former Yugoslav Republic of (MK) +389',
    id: '+389'
  },
  { label: 'Mali (ML) +223', id: '+223' },
  { label: 'Myanmar (MM) +95', id: '+95' },
  { label: 'Mongolia (MN) +976', id: '+976' },
  { label: 'Macao (MO) +853', id: '+853' },
  { label: 'Northern Mariana Islands (MP) +1-670', id: '+1-670' },
  { label: 'Martinique (MQ) +596', id: '+596' },
  { label: 'Mauritania (MR) +222', id: '+222' },
  { label: 'Montserrat (MS) +1-664', id: '+1-664' },
  { label: 'Malta (MT) +356', id: '+356' },
  { label: 'Mauritius (MU) +230', id: '+230' },
  { label: 'Maldives (MV) +960', id: '+960' },
  { label: 'Malawi (MW) +265', id: '+265' },
  { label: 'Mexico (MX) +52', id: '+52' },
  { label: 'Malaysia (MY) +60', id: '+60' },
  { label: 'Mozambique (MZ) +258', id: '+258' },
  { label: 'Namibia (NA) +264', id: '+264' },
  { label: 'New Caledonia (NC) +687', id: '+687' },
  { label: 'Niger (NE) +227', id: '+227' },
  { label: 'Norfolk Island (NF) +672', id: '+672' },
  { label: 'Nigeria (NG) +234', id: '+234' },
  { label: 'Nicaragua (NI) +505', id: '+505' },
  { label: 'Netherlands (NL) +31', id: '+31' },
  { label: 'Norway (NO) +47', id: '+47' },
  { label: 'Nepal (NP) +977', id: '+977' },
  { label: 'Nauru (NR) +674', id: '+674' },
  { label: 'Niue (NU) +683', id: '+683' },
  { label: 'New Zealand (NZ) +64', id: '+64' },
  { label: 'Oman (OM) +968', id: '+968' },
  { label: 'Panama (PA) +507', id: '+507' },
  { label: 'Peru (PE) +51', id: '+51' },
  { label: 'French Polynesia (PF) +689', id: '+689' },
  { label: 'Papua New Guinea (PG) +675', id: '+675' },
  { label: 'Philippines (PH) +63', id: '+63' },
  { label: 'Pakistan (PK) +92', id: '+92' },
  { label: 'Poland (PL) +48', id: '+48' },
  { label: 'Saint Pierre and Miquelon (PM) +508', id: '+508' },
  { label: 'Pitcairn (PN) +870', id: '+870' },
  { label: 'Puerto Rico (PR) +1', id: '+1' },
  { label: 'Palestine, State of (PS) +970', id: '+970' },
  { label: 'Portugal (PT) +351', id: '+351' },
  { label: 'Palau (PW) +680', id: '+680' },
  { label: 'Paraguay (PY) +595', id: '+595' },
  { label: 'Qatar (QA) +974', id: '+974' },
  { label: 'Reunion (RE) +262', id: '+262' },
  { label: 'Romania (RO) +40', id: '+40' },
  { label: 'Serbia (RS) +381', id: '+381' },
  { label: 'Russian Federation (RU) +7', id: '+7' },
  { label: 'Rwanda (RW) +250', id: '+250' },
  { label: 'Saudi Arabia (SA) +966', id: '+966' },
  { label: 'Solomon Islands (SB) +677', id: '+677' },
  { label: 'Seychelles (SC) +248', id: '+248' },
  { label: 'Sudan (SD) +249', id: '+249' },
  { label: 'Sweden (SE) +46', id: '+46' },
  { label: 'Singapore (SG) +65', id: '+65' },
  { label: 'Saint Helena (SH) +290', id: '+290' },
  { label: 'Slovenia (SI) +386', id: '+386' },
  { label: 'Svalbard and Jan Mayen (SJ) +47', id: '+47' },
  { label: 'Slovakia (SK) +421', id: '+421' },
  { label: 'Sierra Leone (SL) +232', id: '+232' },
  { label: 'San Marino (SM) +378', id: '+378' },
  { label: 'Senegal (SN) +221', id: '+221' },
  { label: 'Somalia (SO) +252', id: '+252' },
  { label: 'Suriname (SR) +597', id: '+597' },
  { label: 'South Sudan (SS) +211', id: '+211' },
  { label: 'Sao Tome and Principe (ST) +239', id: '+239' },
  { label: 'El Salvador (SV) +503', id: '+503' },
  { label: 'Sint Maarten (Dutch part) (SX) +1-721', id: '+1-721' },
  { label: 'Syrian Arab Republic (SY) +963', id: '+963' },
  { label: 'Swaziland (SZ) +268', id: '+268' },
  { label: 'Turks and Caicos Islands (TC) +1-649', id: '+1-649' },
  { label: 'Chad (TD) +235', id: '+235' },
  { label: 'French Southern Territories (TF) +262', id: '+262' },
  { label: 'Togo (TG) +228', id: '+228' },
  { label: 'Thailand (TH) +66', id: '+66' },
  { label: 'Tajikistan (TJ) +992', id: '+992' },
  { label: 'Tokelau (TK) +690', id: '+690' },
  { label: 'Timor-Leste (TL) +670', id: '+670' },
  { label: 'Turkmenistan (TM) +993', id: '+993' },
  { label: 'Tunisia (TN) +216', id: '+216' },
  { label: 'Tonga (TO) +676', id: '+676' },
  { label: 'Turkey (TR) +90', id: '+90' },
  { label: 'Trinidad and Tobago (TT) +1-868', id: '+1-868' },
  { label: 'Tuvalu (TV) +688', id: '+688' },
  { label: 'Taiwan, Republic of China (TW) +886', id: '+886' },
  { label: 'United Republic of Tanzania (TZ) +255', id: '+255' },
  { label: 'Ukraine (UA) +380', id: '+380' },
  { label: 'Uganda (UG) +256', id: '+256' },
  { label: 'United States (US) +1', id: '+1' },
  { label: 'Uruguay (UY) +598', id: '+598' },
  { label: 'Uzbekistan (UZ) +998', id: '+998' },
  { label: 'Holy See (Vatican City State) (VA) +379', id: '+379' },
  {
    label: 'Saint Vincent and the Grenadines (VC) +1-784',
    id: '+1-784'
  },
  { label: 'Venezuela (VE) +58', id: '+58' },
  { label: 'British Virgin Islands (VG) +1-284', id: '+1-284' },
  { label: 'US Virgin Islands (VI) +1-340', id: '+1-340' },
  { label: 'Vietnam (VN) +84', id: '+84' },
  { label: 'Vanuatu (VU) +678', id: '+678' },
  { label: 'Wallis and Futuna (WF) +681', id: '+681' },
  { label: 'Samoa (WS) +685', id: '+685' },
  { label: 'Kosovo (XK) +383', id: '+383' },
  { label: 'Yemen (YE) +967', id: '+967' },
  { label: 'Mayotte (YT) +262', id: '+262' },
  { label: 'South Africa (ZA) +27', id: '+27' },
  { label: 'Zambia (ZM) +260', id: '+260' },
  { label: 'Zimbabwe (ZW) +263', id: '+263' }
 ]
   