/***
 *
 * Controller class for user.
 * @file FormWrapper.js
 * @description FormWrapper component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 *
 */

import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './FormWrapper.style.js';
import './FormWrapper.scss';
import { Box, Grid, Typography } from '@mui/material';
import StyledTextfield from '../StyledTextfield/index.js';
import CustomDropDown from '../CustomDropDown/index.js';
import _ from 'lodash';
import CustomLabel from '../CustomLabel/index.js';
import Validator from '../../helpers/validator.helper.js';
import InputMask from 'react-input-mask';
import ChipInput from '../ChipInput/index.js';
import StyledTextArea from '../StyledTextArea/index.js';
import ImageWrapper from '../ImageWrapper/index.js';
import StyledMultiSelect from '../StyledMultiSelect/index.js';
import CustomChipInput from '../CustomChipInput/index.js';
import { ScrollToTop, getExtn, getImageMeta } from '../../services/util.service.js';
import StyledDateTimePicker from '../StyledDateTimePicker/index.js';
import StyledTextEditor from '../StyledTextEditor/index.js';
import StyledFileUpload from '../StyledFileUpload/index.js';
import StyledSpaceTreeDropDown from '../StyledSpaceTreeDropDown/index.js';
import StyledSelectTextField from '../StyledSelectTextField/index.js';
import StyledRadioButton from '../StyledRadioButton'
import StyledCheckBox from '../StyledCheckBox'
import StyledColorPicker from '../StyledColorPicker/index.js';
import SwitchButton from '../SwitchButton/index.js';
import StyledButton from '../StyledButton/index.js';
import SearchWrapper from '../SearchWrapper/index.js';

// const initialState = (config = null, st = {}) => {
//   if (config) {
//     if (isArray(config)) {
//       config
//         ?.filter((c) => !c?.actionButtons)
//         ?.map((conf) => {
//           initialState(conf, st);
//         });
//     } else if (isObject(config) && config?.hasOwnProperty("columns")) {
//       initialState(config?.columns, st);
//     }
//     if (isObject(config) && config?.hasOwnProperty("name")) {
//       st[config?.name] = config?.value || "";
//     }
//   }
//   return st;
// };


function getType(formFields) {
  let obj = {};
  console.log("formFields --- ", formFields)
  if (formFields) {
    formFields?.forEach(frmObj =>
      frmObj?.columns?.forEach(cl => {
        if (cl?.rows && Array.isArray(cl?.rows)) { //// if rows exists
          cl?.rows.forEach(({ columns }) => {  /// columns is array
            Array.isArray(columns) && columns.forEach(col => {
              return !col?.disabled && col?.validation && !_.isEmpty(col?.validation) && (obj[col?.name] = { type: col?.type, validation: col?.validation })
            })
          })
        }
        else
          return !cl?.disabled && cl?.validation && !_.isEmpty(cl?.validation) && (obj[cl?.name] = { type: cl.type, validation: cl.validation })
      }
      )
    );
  }

  return obj
}

function isBase64mime(string) {
  var regex = '(?:[A-Za-z0-9+\\/]{4})*(?:[A-Za-z0-9+\\/]{2}==|[A-Za-z0-9+\/]{3}=)?'
  var mimeRegex = '(data:\\w+\\/[a-zA-Z\\+\\-\\.]+;base64,)'
  regex = mimeRegex + regex;
  return new RegExp('^' + regex + '$', 'gi').test(string);
}

function dataURLtoFile(dataurl, filename) {

  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

// function getNumValue(value, min=null,max=null){
//   if((min!==null || min!==undefined) && Number(value)<0 && min>=0) return min;  
//   if((max!==null || max!==undefined) && Number(value)>max) return max;
//   return Number(value);
// }

const FormWrapper = forwardRef(({ children, formFields = [], formButtons = null, formData, formOptions, operation = "create", setPayload = () => { }, style = {}, customsx = { formbtn: {}, formdiv: {}, label: {}, label2: {} }, FilesObj = () => { } }, ref) => {
  const classes = useStyles()
  const [frm, setFrm] = useState({});
  const [types, setTypes] = useState(getType(formFields));
  const [validation, setValidateFields] = useState({});
  const [fileObj, setFileObj] = useState({});
  const [files, setFiles] = useState({});
  const [originalData, setOriginalData] = useState(formData);

  useEffect(() => {
    setTypes(getType(formFields));
  }, [formFields])

  function findPosition(obj) {
    var currenttop = 0;
    if (obj.offsetParent) {
      do {
        currenttop += obj.offsetTop;
      } while ((obj = obj.offsetParent));
      return currenttop;
    }
  }

  const handleChange = (event, objkey = null, data = "", textType = 'string') => {
    const target = event?.target;
    const key = target?.name || objkey;
    let value = target?.value;
    if (!_.isEmpty(target?.value) && textType === 'number') { /// important because zipcode was showing abnormal behaviour on type= number; taking junk value
      if (isNaN(target?.value)) {
        return;
      }
      if (target?.value.split('.')[1] && target?.value.split('.')[1].length > 3) {
        value = parseFloat(Number(value)?.toFixed(3)).toString();
      }
    }
    setFrm({ ...(frm || {}), [key]: (target?.value !== undefined && target?.value !== null) ? value : data });
    validation[key] = "";
    setValidateFields({
      ...validation
    })
    console.log('frm data-->', key, target?.value, data, event);
  }

  const handleNumberChange = (event, truncate) => {
    const target = event?.target;
    const key = target?.name;
    let value = target?.value;
    const regex = new RegExp("^0+(?!$)", 'g'); //// remove leading zeroes from string
    value = target?.value.replaceAll(regex, "");
    if (truncate && target?.value?.split('.')[1]) {
      value = Math.trunc(target?.value).toString();
    }
    setFrm({ ...(frm || {}), [key]: (target?.value !== undefined && target?.value !== null) ? value : '' });
    validation[key] = "";
    setValidateFields({
      ...validation
    })

  }


  const handleKeyDown = (event, objkey = null) => {
    const target = event?.target;
    const key = target?.name || objkey;
    setFrm({ ...(frm || {}), [key]: target?.value });
    validation[key] = "";
    setValidateFields({
      ...validation
    })
    console.log('frm data-->', key, target?.value);
  }




  const handleDeleteFile = (val, ind) => {
    console.log('deletedddd', val, ind, fileObj, files);
    let key = val?.key;
    let arr1 = fileObj[key].filter((item, index) => index !== ind);
    let arr2 = files[key].filter((item, index) => index !== ind);
    files[key] = arr2;
    fileObj[key] = arr1;
    setFileObj({ ...fileObj });
    setFiles({ ...files });
    setFrm({ ...frm, [key]: _.isEmpty(arr2) ? null : { "file": arr2, "base64": frm[key] ? frm[key]["base64"] : null } });
  }

  const handleFileChange = (e, type, misc, frmvalidation, format = [], multiple = true, imageResolution = {}) => {
    const target = e.target;
    const key = target.getAttribute('name');
    const currFiles = files;
    const fileNames = Array.from(target.files).map(file => file?.name);
    let filterFiles = target.files;
    if (!multiple && currFiles[key]?.length > 1) {
      return;
    }
    if (multiple && currFiles[key] && currFiles[key]?.length > 0) {
      const duplicateFile = currFiles[key]?.filter(file => fileNames.includes(file?.name));
      if (!_.isEmpty(duplicateFile)) {
        alert(duplicateFile?.length > 1 ? `${duplicateFile[0]?.name} and some other files are already present` : `${duplicateFile[0]?.name} already present`);
        filterFiles = Array.from(target.files).filter(file => !duplicateFile.map(df => df?.name).includes(file?.name));
        return;
      }
    }

    if (!currFiles[key]) {
      currFiles[key] = [];
    }
    if (type === 'drag') {
      let arrrr = [...currFiles[key], ...misc]
      currFiles[key] = arrrr;
      if (key === null) {
        delete currFiles[key];
      }
    }


    else if (type === 'upload') {
      currFiles[key].push(...filterFiles);
    }

    else if (type === 'paste') {
      const clipboardItems = e.clipboardData.files;
      const items = [].slice.call(clipboardItems).filter((item) => {
        if (misc.find(it => item?.type.indexOf(it) !== -1)) {
          return item;
        }
      });
      currFiles[key].push(...items)
    }

    let obj = fileObj
    let form = frm;
    if (currFiles[key].length > 0 && currFiles[key].length <= (frmvalidation?.filelength && Array.isArray(frmvalidation?.filelength) ? frmvalidation?.filelength[0] : 10)) {
      let fileArray = [];
      for (let i = 0; i < currFiles[key].length; i++) {
        let extn = getExtn(currFiles[key][i]?.name);
        // console.log('my file:::', currFiles[key][i]);
        if (currFiles[key][i].size > (frmvalidation?.fileSize && Array.isArray(frmvalidation?.fileSize) ? frmvalidation?.fileSize[0] : 1000) * 1000) {
          currFiles[key].pop();
          setValidateFields({
            ...validation,
            [key]: (frmvalidation?.fileSize && Array.isArray(frmvalidation?.fileSize) ? frmvalidation?.fileSize[1] : 'Max 1Mb file size is allowed')
          })
          setTimeout(() => {
            setValidateFields({
              ...validation,
              [key]: ''
            })
          }, 6000)
          return;
        }
        else if (!_.isEmpty(format) && !format.includes(extn)) {
          currFiles[key].pop();
          const extnSet = new Set(format.map(item => item.toLowerCase()));
          const uniqformat = Array.from(extnSet);
          setValidateFields({
            ...validation,
            [key]: `Invalid format. Only ${uniqformat.join(', ').substring(0, uniqformat.join(', ').length)} ${uniqformat.length > 1 ? 'are' : 'is'} allowed`
          })
          setTimeout(() => {
            setValidateFields({
              ...validation,
              [key]: ''
            })
          }, 6000)
          return;
        }

        else {
          let currFileObject = {
            name: currFiles[key][i]?.name,
            isImg: currFiles[key][i]?.type.search('image') !== -1,
            extn: extn,
            eventType: type,
            isProgress: false,
            key: key,
            targetFiles: target?.files
          }; /// custom file obj for file component

          let fileReader = new FileReader();
          let imgurl = null;
          fileReader.readAsDataURL(currFiles[key][i]);

          fileReader.onload = (e) => {
            if (!_.isEmpty(imageResolution) && currFileObject?.isImg) {
              imgurl = e.target.result;
              getImageMeta(imgurl, (err, img) => {
                if (imageResolution?.minWidth > img.naturalWidth) {
                  currFiles[key].pop();
                  setValidateFields({
                    ...validation,
                    [key]: `Upload image of width greater than ${imageResolution?.minWidth}px`
                  });
                  setTimeout(() => { setValidateFields({ ...validation, [key]: '' }) }, 4000); return;
                }
                else if (imageResolution?.minHeight > img.naturalHeight) {
                  currFiles[key].pop();
                  setValidateFields({
                    ...validation,
                    [key]: `Upload image of height greater than ${imageResolution?.minWidth}px`
                  })
                  setTimeout(() => { setValidateFields({ ...validation, [key]: '' }) }, 4000); return;
                }
                else if (imageResolution?.maxWidth < img.naturalWidth) {
                  currFiles[key].pop();
                  setValidateFields({
                    ...validation,
                    [key]: `Upload image of width lesser than ${imageResolution?.maxWidth}px`
                  })
                  setTimeout(() => { setValidateFields({ ...validation, [key]: '' }) }, 4000); return;
                }
                else if (imageResolution?.maxHeight < img.naturalHeight) {
                  currFiles[key].pop();
                  setValidateFields({
                    ...validation,
                    [key]: `Upload image of height lesser than ${imageResolution?.maxHeight}px`
                  })
                  setTimeout(() => { setValidateFields({ ...validation, [key]: '' }) }, 4000); return;
                }
                form[key] = { "file": currFiles, "base64": e.target.result };
                currFileObject['data'] = e.target.result;
                setFrm({ ...frm, [key]: { "file": currFiles, "base64": e.target.result } });
                fileArray.push(currFileObject);
                validation[key] = "";
                setValidateFields({ ...validation });
              }); //// call back for dmns of image
            }
            else {
              form[key] = { "file": currFiles, "base64": e.target.result };
              currFileObject['data'] = e.target.result;
              setFrm({ ...frm, [key]: { "file": currFiles, "base64": e.target.result } });
              fileArray.push(currFileObject);
              validation[key] = "";
              setValidateFields({ ...validation });
            }
          }; /// File reader function

        } /// end of else part

      } //// end of for loop

      obj[key] = fileArray
      setFileObj({ ...obj });
    }
    else {
      currFiles[key].pop();
      setValidateFields({
        ...validation,
        [key]: (frmvalidation?.filelength && Array.isArray(frmvalidation?.filelength) ? frmvalidation?.filelength[1] : 'Max 10 files allowed')
      })
      setTimeout(() => {
        setValidateFields({
          ...validation,
          [key]: ''
        })
      }, 3000)
      return;
    }
    // console.log('currr files==', currFiles[key].length, files);
    setFiles({ ...currFiles });
  }

  const handleBtnPress = (event, btn) => {
    let obj = validation;
    if (btn?.checkValidation) {
      for (let item of Object.keys(types)) {

        // obj[item] = Validator(frm[item], types[item].type, { value: frm[item], ...(types[item]?.validation || {}) }); ///// ask naveen sir
        obj[item] = "";
        if (types[item]?.validation?.req || (frm[item] && frm[item] !== "" && types[item]?.validation !== {})) {
          obj[item] = Validator(frm[item], types[item]?.type, types[item]?.validation, item)
        }
      }

      setValidateFields({ ...obj });
      console.log('validation==>', validation, types, frm, obj, Object.values(obj).every(item => item?.length === 0));

      if (Object.values(obj).every(item => item?.length === 0)) {
        btn?.onClick(event, frm, obj);
      } else {
        let pos = 1e7, ele = null;
        Object.keys(obj).forEach(item => {
          if (!_.isEmpty(obj[item])) {
            ele = document.querySelector(`[name=${item}]`);
            if (ele) {
              pos = Math.min(findPosition(ele), pos);
            }
          }
        })
        ScrollToTop(pos - 150);
      }
    } else {
      btn?.onClick(event, frm);
      setValidateFields({})
    }
  }

  useImperativeHandle(ref, (event, btn) => ({
    handleButton(event, btn) {
      handleBtnPress(event, btn)
    }
  }))


  useEffect(() => {
    if (!_.isEmpty(formData)) {
      setFrm(formData);
      setOriginalData(formData); //// for StyleSelectTextField
      let obj = fileObj;
      const currFiles = files;
      Object.keys(formData).map(key => {
        let str = formData[key], fileArray = [];
        if (!currFiles[key]) {
          currFiles[key] = [];
        }
        if (isBase64mime(str)) {
          let extension = str.split(';')[0].split('/')[1];
          let currFileObject = {
            name: `${key}.${extension}`,
            data: str,
            isImg: str.search('image') !== -1,
            extn: extension,
            eventType: null,
            isProgress: false,
            key: key,
            targetFiles: [dataURLtoFile(str, `${key}.${extension}`)]
          };
          fileArray.push(currFileObject);
          obj[key] = fileArray;
          setFileObj({ ...obj });
          currFiles[key].push(dataURLtoFile(str, `${key}.${extension}`))
        }
      })
    } else {
      setFileObj({});
    }
    // console.log('my form data===>', formData);
  }, [formData])

  useEffect(() => {
    console.log("frm1===>", frm);
    if (!_.isEmpty(frm)) {
      setPayload(frm);
    }
    else {
      setFileObj({});
    }
  }, [frm]);

  useEffect(() => {
    FilesObj(fileObj);
  }, [fileObj])



  const rows = (frmRows, index) => {
    return (
      <>
        {frmRows?.map((rw, index2) => {
          return (
            <Grid className='frm-row' key={"row-2-area-" + index + "-" + index2} container item md={rw?.rowSize} xs={12} style={{ ...(rw?.style || {}) }} spacing={3}>
              {columns(rw?.columns, index2)}
            </Grid>
          )
        })}
      </>
    )
  }

  const columns = (rowColumns, index) => {
    return (
      rowColumns?.map((cl, index2) => {
        return ( /// removed display: grid
          <Grid className='frm-column' style={{ alignItems: "end" }} key={"container-2-area-" + index + "-" + index2} item md={cl?.columnSize} sm={cl?.columnSize} xs={12}>
            <Box style={{ ...(cl?.style || {}) }}>
              {cl?.rows ? (
                rows(cl?.rows, index2)
              ) : (
                <>
                  {cl?.label &&
                    <Box sx={cl?.formLabelsx} className={'form-label'}>
                      {typeof cl?.label === 'string' ? (
                        <CustomLabel required={cl?.required && ['edit', 'create'].includes(operation) ? true : false} htmlFor={cl?.id} style={{ height: "auto", ...customsx?.label, ...cl?.label?.style }}>{cl?.label}</CustomLabel>
                      ) : (cl?.label?.component ? (
                        cl?.label?.component
                      ) : (
                        <CustomLabel required={cl?.required && ['edit', 'create'].includes(operation) ? true : false} htmlFor={cl?.id} style={{ height: "auto", ...customsx?.label, ...cl?.label?.style }}>{cl?.label?.text}</CustomLabel>
                      ))}
                    </Box>}

                  <Box sx={{ width: "100%" }}>
                    {(() => {
                      switch (cl?.type) {
                        case "text":
                        case "tel":
                        case "email":
                        case "password":
                          return (
                            <StyledTextfield
                              key={"text-" + index2}
                              type={cl?.type}
                              error={['edit', 'create'].includes(operation) && !_.isEmpty(validation[cl?.name])}
                              helperText={['edit', 'create'].includes(operation) && !_.isEmpty(validation[cl?.name]) ? validation[cl?.name] : ""}
                              id={cl?.id}
                              name={cl?.name}
                              width={cl?.width}
                              // defaultValue={cl?.defaultValue}
                              fullWidth
                              color={!_.isEmpty(validation[cl?.name]) ? 'error' : ""}
                              value={frm[cl?.name] || ""}
                              placeholder={cl?.placeholder}
                              disabled={cl?.disabled || (operation === "create" ? false : (operation === "edit" ? cl?.disabledOnEdit : true))}
                              onChange={(e) => handleChange(e, null, '', cl?.textType)}
                              InputProps={cl?.InputProps}
                            />
                          );
                        case "number":
                          return (
                            <StyledTextfield
                              key={"number-" + index2}
                              type={cl?.type}
                              error={['edit', 'create'].includes(operation) && !_.isEmpty(validation[cl?.name])}
                              helperText={['edit', 'create'].includes(operation) && !_.isEmpty(validation[cl?.name]) ? validation[cl?.name] : ""}
                              id={cl?.id}
                              name={cl?.name}
                              width={cl?.width}
                              hideInputArrows={cl?.hideInputArrows}
                              // defaultValue={cl?.initialvalue}
                              fullWidth
                              inputProps={{
                                min: cl?.min,
                                max: cl?.max
                              }}
                              color={!_.isEmpty(validation[cl?.name]) ? 'error' : ""}
                              value={frm?.hasOwnProperty(cl?.name) ? frm[cl?.name] : ''}
                              placeholder={cl?.placeholder}
                              disabled={cl?.disabled || (operation === "create" ? false : (operation === "edit" ? cl?.disabledOnEdit : true))}
                              onChange={e => handleNumberChange(e, cl?.truncate)}
                            />
                          );
                        case "select":
                          return (
                            <CustomDropDown
                              key={"select-" + index2}
                              error={['edit', 'create'].includes(operation) && !_.isEmpty(validation[cl?.name])}
                              message={['edit', 'create'].includes(operation) && !_.isEmpty(validation[cl?.name]) ? validation[cl?.name] : ""}
                              value={frm[cl?.name] || ""}
                              id={cl?.id}
                              name={cl?.name}
                              fullWidth
                              width={cl?.width}
                              onChange={handleChange}
                              onOpen={null}
                              defaultValue={cl?.defaultValue}
                              isNoneAsItem={cl?.isNoneAsItem}
                              disabled={cl?.disabled || (operation === "create" ? false : (operation === "edit" ? cl?.disabledOnEdit : true))}
                              list={formOptions?.dropdowns[cl?.name]?.list || cl?.options || []}
                              createNewButton={formOptions?.dropdowns[cl?.name]?.createNewButton}
                            />
                            // </CustomDropDown>
                          )
                        case "multiselect":
                          return (
                            <StyledMultiSelect
                              key={"multiselect-" + index2}
                              error={['edit', 'create'].includes(operation) && !_.isEmpty(validation[cl?.name])}
                              message={['edit', 'create'].includes(operation) && !_.isEmpty(validation[cl?.name]) ? validation[cl?.name] : ""}
                              value={frm[cl?.name] || ""}
                              id={cl?.id}
                              name={cl?.name}
                              fullWidth
                              width={cl?.width}
                              onChange={handleChange}
                              disabled={cl?.disabled || (operation === "create" ? false : (operation === "edit" ? cl?.disabledOnEdit : true))}
                              options={formOptions?.dropdowns[cl?.name]?.list || cl?.options || []}
                            />
                          )
                        case 'mask':
                          return (
                            <InputMask
                              key={"mask-" + index2}
                              error={!_.isEmpty(validation[cl?.name])}
                              width={cl?.width}
                              name={cl?.name}
                              fullWidth
                              helperText={!_.isEmpty(validation[cl?.name]) ? validation[cl?.name] : ""}
                              maskChar={cl?.maskChar}
                              formatChars={cl?.formatChars}
                              placeholder={cl?.placeholder}
                              mask={cl?.mask}
                              value={frm[cl?.name] || ""}
                              disabled={cl?.disabled || (operation === "create" ? false : (operation === "edit" ? cl?.disabledOnEdit : true))}
                              onChange={handleChange}>
                              {(inputProps) => <StyledTextfield fullWidth disableUnderline {...inputProps} disabled={cl?.disabled || (operation === "create" ? false : (operation === "edit" ? cl?.disabledOnEdit : true))} />}
                            </InputMask>
                          );
                        case "chipInput":
                        case "chipinput":
                          return (
                            <>
                              {
                                cl?.visibleIn?.includes(operation) &&
                                <>
                                  <ChipInput
                                    key={"dropdown-chip-input-" + index2}
                                    rounded={cl?.rounded}
                                    width={cl?.width || "300px"}
                                    name={cl?.name}
                                    fullWidth
                                    page={operation}
                                    componentType={cl?.componentType}
                                    componentProps={cl?.componentProps}
                                    addBtn={cl?.addBtn}
                                    addBtnStyle={cl?.addBtnStyle}
                                    message={['edit', 'create'].includes(operation) && !_.isEmpty(validation[cl?.name]) ? validation[cl?.name] : ""}
                                    helperText={!_.isEmpty(validation[cl?.name]) ? validation[cl?.name] : ""}
                                    placeholder={cl?.placeholder}
                                    value={frm[cl?.name] || []}
                                    options={formOptions?.dropdowns[cl?.name]?.list || []}
                                    disabled={cl?.disabled || (operation === "create" ? false : (operation === "edit" ? cl?.disabledOnEdit : true))}
                                    // disabled={(operation==="view")}
                                    onChange={handleChange}
                                    onDelete={cl?.onDelete ? (data) => {
                                      cl?.onDelete(data);
                                    } : () => { }}
                                    error={!_.isEmpty(validation[cl?.name])}
                                  />
                                </>
                              }
                            </>
                          )
                        case 'freeTextChipInput':
                        case 'freetextchipinput':
                          return (
                            <>
                              {
                                cl?.visibleIn.includes(operation) &&
                                <CustomChipInput
                                  key={"text-chip-input-" + index2}
                                  rounded
                                  width={cl?.width || "300px"}
                                  name={cl?.name}
                                  fullWidth
                                  helperText={!_.isEmpty(validation[cl?.name]) ? validation[cl?.name] : ""}
                                  placeholder={cl?.placeholder}
                                  value={frm[cl?.name] || ""}
                                  chipsPosition={cl?.chipsPosition}
                                  // data={formOptions?.dropdowns[cl?.name]?.list || []}
                                  disabled={cl?.disabled || (operation === "create" ? false : (operation === "edit" ? cl?.disabledOnEdit : true))}
                                  // onChange={(e, key, data) => { handleChange(e, key, data) }}
                                  onKeyDown={(data, key, event) => { handleKeyDown(data, key, event?.key, cl?.type, cl?.validation); }}
                                  error={!_.isEmpty(validation[cl?.name])}
                                />
                              }
                            </>
                          )
                        case "treeDropDown":
                        case "treedropdown":
                          return (
                            <StyledSpaceTreeDropDown
                              key={"tree-drop-down-" + index2}
                              multiselect={cl?.hasOwnProperty('multiselect') ? cl?.multiselect : true}
                              name={cl?.name}
                              value={frm[cl?.name] || ""}
                              placeholder={cl?.placeholder}
                              disabled={cl?.disabled || (operation === "create" ? false : (operation === "edit" ? cl?.disabledOnEdit : true))}
                              style={cl?.input?.style}
                              sx={cl?.sx}
                              helper={{ text: cl?.helperText, color: cl?.helperColor, sx: cl?.customsx?.helperText }}
                              error={['edit', 'create'].includes(operation) && !_.isEmpty(validation[cl?.name])}
                              message={['edit', 'create'].includes(operation) && !_.isEmpty(validation[cl?.name]) ? validation[cl?.name] : ""}
                              onChange={handleChange}
                            />
                          )
                        case "file":
                          return (
                            // <DragAndDrop
                            //   key={"dnd-" + index2}
                            //   onUpload={(e, files) => { handleFileChange(e, "drag", files, cl?.validation?.fileSize) }}
                            //   enable={false}
                            //   dragFiles={files}
                            //   name={cl?.name}
                            //   multiple={cl?.multiple}
                            //   formats={cl?.format}
                            //   id={cl?.id}
                            //   validation={cl?.validation?.fileSize}
                            //   disabled={cl?.disabled || (operation === "create" ? false : (operation === "edit" ? cl?.disabledOnEdit : true))}
                            // >
                            <StyledFileUpload
                              id={cl?.id}
                              name={cl?.name}
                              fileObj={fileObj}
                              multiple={cl?.multiple || false}
                              allowedFileType={cl?.allowedFileType}
                              error={!_.isEmpty(validation[cl?.name]) || cl?.error}
                              message={validation[cl?.name] || cl?.message || ""}
                              deleteConfirmation={cl?.confirm}
                              customsx={cl?.customsx}
                              disabled={cl?.disabled || (operation === "create" ? false : (operation === "edit" ? cl?.disabledOnEdit : true))}
                              helper={{ text: cl?.helperText, color: cl?.helperColor, sx: cl?.customsx?.helperText }}
                              onClick={(e) => { document?.getElementById(`input__${cl?.id}`).click(); }}
                              handleChange={(e) => { handleFileChange(e, 'upload', null, cl?.validation, cl?.format, cl?.multiple, cl?.imageResolution) }}
                              onDelete={handleDeleteFile}
                              attachment={cl?.attachment || false}
                              style={{ ...cl?.input?.style }}
                              preview={cl?.preview}
                              placeholder={cl?.placeholder || "Select or Drag and Drop File(s)."}
                            />
                            // </DragAndDrop>
                          )
                        case "datetimepicker":
                        case "dateTimePicker":
                        case "DateTimePicker":
                          return (
                            <StyledDateTimePicker
                              {...cl}
                              key={"datePicker-" + index2}
                              error={['edit', 'create'].includes(operation) && !_.isEmpty(validation[cl?.name])}
                              message={['edit', 'create'].includes(operation) && !_.isEmpty(validation[cl?.name]) && (!cl?.disabledOnCreate || !cl?.disabledOnEdit) ? validation[cl?.name] : ""}
                              style={cl?.input?.style}
                              name={cl?.name}
                              width={cl?.width}
                              fullWidth
                              value={frm[cl?.name] || ""}
                              picker={cl?.picker}
                              sx={cl?.sx}
                              format={cl?.format}
                              placeholder={cl?.placeholder}
                              defaultValue={cl?.defaultValue || ""}
                              disabled={cl?.disabled || (operation === "create" ? cl?.disabledOnCreate || false : (operation === "edit" ? cl?.disabledOnEdit : true))}
                              onChange={handleChange}
                            />
                          )
                        case "textarea":
                          return (
                            <StyledTextArea
                              key={"textarea-" + index2}
                              error={!_.isEmpty(validation[cl?.name])}
                              message={!_.isEmpty(validation[cl?.name]) ? validation[cl?.name] : ""}
                              aria-label="minimum height"
                              minRows={cl?.minRows || 1}
                              style={cl?.input?.style}
                              name={cl?.name}
                              width={cl?.width}
                              fullWidth
                              value={frm[cl?.name] || ""}
                              placeholder={cl?.placeholder}
                              disabled={cl?.disabled || (operation === "create" ? false : (operation === "edit" ? cl?.disabledOnEdit : true))}
                              onChange={handleChange}
                            />
                          )
                        case "textEditor":
                        case "texteditor":
                          return (
                            <StyledTextEditor
                              id={cl?.id}
                              name={cl?.name}
                              value={frm[cl?.name] || ""}
                              width={cl?.width}
                              style={cl?.input?.style}
                              placeholder={cl?.placeholder}
                              error={!_.isEmpty(validation[cl?.name])}
                              message={!_.isEmpty(validation[cl?.name]) ? validation[cl?.name] : ""}
                              helperText={cl?.helperText}
                              helperColor={cl?.helperColor}
                              disabled={cl?.disabled || (operation === "create" ? false : (operation === "edit" ? cl?.disabledOnEdit : true))}
                              onChange={handleChange}
                            />
                          )
                        case 'selectTextField':
                          return (
                            <>
                              <StyledSelectTextField
                                key={"select-" + index2}
                                error={['edit', 'create'].includes(operation) && !_.isEmpty(validation[cl?.name])}
                                message={['edit', 'create'].includes(operation) && !_.isEmpty(validation[cl?.name]) ? validation[cl?.name] : ""}
                                value={frm[cl?.name] || ""}
                                id={cl?.id}
                                name={cl?.name}
                                fullWidth
                                originalData={originalData}
                                width={cl?.width}
                                setterFunction={(select, text) => { handleChange({ target: { name: cl?.name, value: select + text } }) }}
                                onOpen={null}
                                defaultSelect={cl?.defaultSelect}
                                defaultText={cl?.defaultText}
                                isNoneAsItem={cl?.isNoneAsItem}
                                placeholder={cl?.placeholder}
                                requireOpen={cl?.requireOpen}
                                partition={cl?.partition}
                                helperText={['edit', 'create'].includes(operation) && !_.isEmpty(validation[cl?.name]) ? validation[cl?.name] : ""}
                                disabled={cl?.disabled || (operation === "create" ? false : (operation === "edit" ? cl?.disabledOnEdit : true))}
                                list={formOptions?.dropdowns[cl?.name]?.list || cl?.options || []}
                                textType={cl?.textType}
                                hideInputArrows={cl?.hideInputArrows}
                              />
                            </>
                          )
                        case 'radiobutton':
                          return (
                            <>
                              <StyledRadioButton
                                id={cl?.id}
                                name={cl?.name}
                                value={frm[cl?.name] || ""}
                                options={(cl?.options && cl?.options.length > 0 && cl?.options) || formOptions?.dropdowns[cl?.name]?.list || []}
                                labelPlacement={cl?.labelPlacement || "right"}
                                error={!_.isEmpty(validation[cl?.name])}
                                message={['edit', 'create'].includes(operation) && !_.isEmpty(validation[cl?.name]) ? validation[cl?.name] : ""}
                                helperText={cl?.helperText}
                                helperColor={cl?.helperColor}
                                selectedWeight={cl?.selectedWeight}
                                selectedColor={cl?.selectedColor}
                                customsx={cl?.customsx}
                                label={cl?.label}
                                disabled={(operation === "create" ? false : (operation === "edit" ? cl?.disabledOnEdit : true))}
                                onChange={handleChange}
                              />
                            </>
                          )
                        case 'checkbox':
                          return (
                            <StyledCheckBox
                              id={cl?.id}
                              name={cl?.name}
                              value={frm[cl?.name] || ""}
                              options={(cl?.options && cl?.options.length > 0 && cl?.options) || formOptions?.dropdowns[cl?.name]?.list || []}
                              labelPlacement={cl?.labelPlacement || "right"}
                              error={!_.isEmpty(validation[cl?.name])}
                              message={['edit', 'create'].includes(operation) && !_.isEmpty(validation[cl?.name]) ? validation[cl?.name] : ""}
                              helperText={cl?.helperText}
                              helperColor={cl?.helperColor}
                              selectedWeight={cl?.selectedWeight}
                              selectedColor={cl?.selectedColor}
                              customsx={cl?.customsx}
                              disabled={cl?.disabled || (operation === "create" ? false : (operation === "edit" ? cl?.disabledOnEdit : true))}
                              onChange={handleChange}
                            />
                          )

                        case 'colorPicker':
                          return (
                            <StyledColorPicker
                              id={cl?.id}
                              name={cl?.name}
                              format={cl?.format}
                              value={frm[cl?.name] || ""}
                              placeholder={cl?.placeholder}
                              error={!_.isEmpty(validation[cl?.name])}
                              message={['edit', 'create'].includes(operation) && !_.isEmpty(validation[cl?.name]) ? validation[cl?.name] : ""}
                              customsx={cl?.customsx}
                              disabled={cl?.disabled || (operation === "create" ? false : (operation === "edit" ? cl?.disabledOnEdit : true))}
                              onChange={handleChange}
                            />
                          )

                        case 'switchButton':
                          return (
                            <SwitchButton
                              id={cl?.id}
                              name={cl?.name}
                              type={cl?.btntype}
                              size={cl?.size}
                              setToggle={frm[cl?.name] || cl?.toggle}
                              customsx={cl?.customsx}
                              disabled={cl?.disabled || (operation === "create" ? false : (operation === "edit" ? cl?.disabledOnEdit : true))}
                              onFrmWrapperChange={handleChange}
                              sx={{ minHeight: "40px", display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                            />
                          )
                        case 'searchSelect':
                          return (
                            <SearchWrapper
                              placeholder={cl?.placeholder || ''}
                              id={cl?.id}
                              name={cl?.name}
                              customsx={cl?.customsx || { container: {} }}
                              data={formOptions?.dropdowns[cl?.name]?.list || [{ name: 'No Item Available' }]}
                              autoComplete={cl?.hasOwnProperty('autocomplete') ? cl?.autocomplete : true}
                              onChange={(e, value) => { handleChange({ ...e, target: { ...e?.target, value: null } }, cl?.name, value) }}
                              autoFill={cl?.hasOwnProperty('autofill') ? cl?.autofill : false}
                              searchValue={frm[cl?.name] || ""}
                              searchIn={cl?.searchIn || ["name"]}
                              disabled={cl?.disabled || (operation === "create" ? false : (operation === "edit" ? cl?.disabledOnEdit : true))}
                              // freeSolo={cl?.hasOwnProperty('freeSolo') ? cl?.freeSolo : true}
                            />
                          )
                        default:
                          return <></>;
                      }
                    })()}
                  </Box>
                </>
              )}
            </Box>
          </Grid>
        );
      })
    )
  }


  return (
    <Grid container item md={12} sx={{ ...customsx.formdiv }} className={classes?.FormWrapper || ""} data-testid="FormWrapper">
      {formFields?.map((frmObj, index) => {
        return (
          <Grid key={"container-1-area-" + index} container sx={{ mb: 2, ...(frmObj?.style || {}) }} style={{ ...(frmObj?.style || {}) }} item xs={12}>
            <Box className="sss" display={"flex"} alignItems={"center"} sx={{ mt: 3, width: '100%', ...(frmObj?.section?.heading?.style || {}) }}>
              {typeof frmObj?.section?.heading === "string" ? (
                <>
                  {frmObj?.section?.icon && <ImageWrapper style={{ marginRight: "10px" }} src={frmObj?.section?.icon} />}
                  <Typography variant="h5" sx={{ fontSize: '0.875rem', fontWeight: "bold" }}>{frmObj?.section?.heading}</Typography>
                </>
              ) : (frmObj?.section?.heading?.component ? (
                frmObj?.section?.heading?.component
              ) : (
                <>
                  {frmObj?.section?.heading?.icon && <ImageWrapper style={{ marginRight: "10px" }} src={frmObj?.section?.heading?.icon} />}
                  <Typography variant="h5" sx={{ fontSize: '0.875rem', fontWeight: "bold" }}>{frmObj?.section?.heading?.text}</Typography>
                </>
              ))}
            </Box>

            <Box style={{ width: "100%", ...(frmObj?.section?.style || {}) }}>
              {frmObj?.rows ? (
                <Grid item xs={frmObj?.section?.columnSize || 12} display={'flex'} alignItems={'baseline'}>
                  {rows(frmObj?.rows, index)}
                </Grid>
              ) : (
                <Grid container item xs={frmObj?.section?.columnSize || 12} spacing={3} display={'flex'} alignItems={'baseline'}>
                  {columns(frmObj?.columns, index)}
                </Grid>
              )}

            </Box>

          </Grid>
        );
      })}

      <Grid container item xs={12}>
        {children}
      </Grid>

      <Grid container item xs={12} className="buttons" sx={{}}>
        {formButtons && formButtons?.map((sect, index) => {
          return (
            <Grid key={"container-3-area-" + index} container item xs={12}>
              {sect?.heading &&
                <Typography variant="h6" sx={{ mt: 2 }}>{sect?.heading}</Typography>}
              {sect?.columns?.map((col, ii) => {
                return (
                  <Grid key={"container-4-area-" + ii} item xs={col?.columnSize}>
                    {
                      col?.heading &&
                      <Typography variant="h6" sx={{ mt: 2 }}>{col?.heading}</Typography>
                    }
                    {
                      col?.buttons?.filter(b => b?.display)?.length > 0 &&
                      <Box sx={{ display: "flex", justifyContent: 'right', width: "100%", my: 4, ...customsx.formbtn }}>
                        {col?.buttons?.map((btn, iii) => (
                          <>
                            {btn?.display &&
                              <StyledButton key={"act-1-area-" + iii} type={btn?.type || "button"} color={btn?.color ? btn.color : 'primary'} variant={btn?.variant ? btn.variant : 'contained'} size={btn?.size ? btn?.size : "medium"} sx={{ ml: 1.5 }}
                                onClick={(e) => handleBtnPress(e, btn)}>{btn?.label}</StyledButton>
                            }
                          </>
                        ))}
                      </Box>
                    }
                  </Grid>
                );
              })}
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  )
});

FormWrapper.propTypes = {};

FormWrapper.defaultProps = {};

export default FormWrapper;
