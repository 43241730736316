/***
 *
 * Controller class for user.
 * @file TestLayout.js
 * @description TestLayout component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import { Box, Paper } from '@mui/material';
import React from 'react';
import ImageWrapper from '../../components/ImageWrapper/index.js';
// import PropTypes from 'prop-types';
import { useStyles } from './TestLayout.style.js';
import OfficeImg from '../../assets/defaults/office.png';

const TestLayout = () => {
  const classes = useStyles();

  return (
    <Box className={classes?.TestLayout || ""} data-testid="TestLayout">
      <Paper sx={{overflow: "auto", maxWidth:'300px', maxHeight:'300px'}}>
        <ImageWrapper width="1280px" src={OfficeImg} />
      </Paper>
    </Box>
  )
};

TestLayout.propTypes = {};

TestLayout.defaultProps = {};

export default TestLayout;
