/***
 *
 * Controller class for user.
 * @file RuleDetails.js
 * @description RuleDetails component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import { Box } from '@mui/material';
import React from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './RuleDetails.style.js';

const RuleDetails = () => {
  const classes = useStyles();

  return (
    <Box className={classes?.RuleDetails || ""} data-testid="RuleDetails">
      RuleDetails page
    </Box>
  )
};

RuleDetails.propTypes = {};

RuleDetails.defaultProps = {};

export default RuleDetails;
