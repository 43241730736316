/***
 *
 * Controller class for user.
 * @file RoleSettings.js
 * @description RoleSettings component
 * @author Shubham Singh
 * @since 12 Oct 2021
 * 
 */

import { React, useEffect, useState } from 'react';
import './RoleSettings.scss';
import PageHeader from '../../components/PageHeader';
import { useStyles } from './RoleSettings.style.js';
import { Box, Checkbox, Grid } from "@mui/material";
import { roleActions } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '../../hooks';
import CustomAccordion from '../../components/CustomAccordion';
import Delete from '../../assets/icons/delete.svg';
import Edit from '../../assets/icons/edit-2.svg';
import { history } from '../../helpers/history.helper.js';
import ImageWrapper from '../../components/ImageWrapper';
import StyledSnackBar from "../../components/StyledSnackBar/index.js";
import StyledPopup from '../../components/StyledPopup';
import CustomTypography from '../../components/CustomTypography';
import userPermission from '../../assets/icons/user-permission.svg'
import StyledTextfield from '../../components/StyledTextfield';
import { features, permissionEntities1, permissionEntitiesTMS, permissions } from '../../constants';
import DataNotFound from "../DataNotFound";
import { getMessageStr, isAuthorised } from '../../helpers';
import StyledButton from '../../components/StyledButton';

const RoleSettings = () => {
  const classes = useStyles();
  const query = useQuery();
  const dispatch = useDispatch();

  const rolesData = useSelector(state => state?.roles?.item) || {};
  const allowedTenants = useSelector(state => state?.users?.userSelfProfile?.roles)?.filter(item => item.includes("tenantadmin")) || [];
  const _portal = useSelector((state) => state?.app?.portal);

  const roleName = query.get("name") || null;
  const page = query.get("page") || null;
  const initialFrm = { name: '', entities: {} }

  const [snackBar, setSnackBar] = useState({ show: false, type: 'error', message: 'Permission Name is Required' });
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [frm, setFrm] = useState(initialFrm);
  const [formError, setFormError] = useState(false);
  const [nameHelperText, setNameHelperText] = useState('');

  const goTo = (route) => {
    history.push(route);
  };

  const closeSnackBar = () => {
    setSnackBar({
      show: false,
      type: 'error',
      message: ''
    });
  };

  const checkValidName = (name = '') => {
    return (/^[A-Za-z0-9-]*$/.test(name));
  };

  const handleNameChange = (name) => {
    setFrm({ ...frm, name: name });
    if (!checkValidName(name)) {
      setFormError(true);
      setNameHelperText(getMessageStr("permission-addPermission-nameAllowedChars"));
    }
    else if (name.length < 2) {
      setFormError(true);
      setNameHelperText(getMessageStr("permission-addPermission-nameMinLength"));
    }
    else if (name.length > 30) {
      setFormError(true);
      setNameHelperText(getMessageStr("permission-addPermission-nameMaxLength"));
    }
    else {
      setFormError(false);
      setNameHelperText('');
    }
  };

  const handleEditPermission = () => {
    history.push(`/user-management/permissions?page=edit&name=${frm?.name}`);
  };

  const handleDeletePermission = () => {
    dispatch(roleActions?.deleteRole(_portal === "mss" ? frm?.name : rolesData?.id, _portal, () => {
      setTimeout(() => {
        history.push(`/user-management/permissions`)
      }, 100);
    }));
  };

  const handleCancelPermission = () => {
    history.goBack();
  };

  const hasNonEmptyAllow = (obj, parentKey) => {
    if (_portal === "tms") {
      for (const key in obj) {
        if (key === "sys" || parentKey === "app") {
          for (const entity in obj[key]) {
            const value = obj[key][entity] || { allow: [] };
            if (value.allow.length > 0) {
              return true;
            }
          }
        }
        else {
          for (const tenants in obj[key]) {
            for (const entity in obj[key][tenants]) {
              const value = obj[key][tenants][entity];
              if (value.allow.length > 0) {
                return true;
              }
            }
          }
        }
      }
    }
    else {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key] || { allow: [] };
          if (value.allow.length > 0) {
            return true;
          }
        }
      }
    }

    return false;
  };

  const isPermissionValid = (obj) => {
    let flag = true;
    if (!('idp' in obj) && !('app' in obj)) {
      return false;
    }

    if ('idp' in obj) {
      const idp = obj.idp;
      if (!hasNonEmptyAllow(idp, 'idp')) {
        flag = false;
      }
      else {
        return true;
      }
    }
    if ('app' in obj) {
      const app = obj.app;
      if (!hasNonEmptyAllow(app, 'app')) {
        flag = false;
      }
      else {
        return true;
      }
    }
    return flag;
  };

  const filterPermissionPayload = (payload) => {
    const filteredData = {};

    if (_portal === "tms") {
      for (const parentKey in payload) {
        const parentObj = payload[parentKey];
        const filteredParent = {};

        for (const subParentKey in parentObj) {
          if (subParentKey !== "tenant") {
            const subParentObj = parentObj[subParentKey];
            const filteredSubParent = {};

            for (const childKey in subParentObj) {
              const childObj = subParentObj[childKey];

              if (childObj.allow.length > 0) {
                filteredSubParent[childKey] = childObj;
              }
            }

            if (Object.keys(filteredSubParent).length > 0) {
              filteredParent[subParentKey] = filteredSubParent;
            }
          }

          if (subParentKey === "tenant") {
            const subParentObj = parentObj[subParentKey];
            const filteredSubParent = {};

            for (const childKey in subParentObj) {
              const childObj = subParentObj[childKey];
              const filteredEntities = {};

              for (const entities in childObj) {
                const entitiesObj = childObj[entities];

                if (entitiesObj.allow.length > 0) {
                  filteredEntities[entities] = entitiesObj;
                }
              }

              if (Object.keys(filteredEntities).length > 0) {
                filteredSubParent[childKey] = filteredEntities;
              }
            }

            if (Object.keys(filteredSubParent).length > 0) {
              filteredParent[subParentKey] = filteredSubParent;
            }
          }
        }

        if (Object.keys(filteredParent).length > 0) {
          filteredData[parentKey] = filteredParent;
        }
      }
    }
    else if (_portal === "mss") {
      for (const parentKey in payload) {
        const parentObj = payload[parentKey];
        const filteredParent = {};

        for (const subParentKey in parentObj) {
          const subParentObj = parentObj[subParentKey];

          if (subParentObj.allow.length > 0) {
            filteredParent[subParentKey] = subParentObj;
          }
        }

        if (Object.keys(filteredParent).length > 0) {
          filteredData[parentKey] = filteredParent;
        }
      }
    }

    return filteredData;
  };

  const preparePayload = () => {
    let payloadObject = { name: frm?.name };
    const entities = filterPermissionPayload(frm?.entities);
    payloadObject = { ...payloadObject, ...entities };

    return payloadObject;
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (frm?.name === '') {
      setFormError(true);
      setNameHelperText(getMessageStr("permission-addPermission-nameRequired"));
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    else if (!checkValidName(frm?.name)) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      return;
    }
    else if (!isPermissionValid({ ...frm?.entities })) {
      setSnackBar({
        show: true,
        type: 'error',
        message: getMessageStr("permission-addPermission-entitySelected")
      });
    }
    else {
      let payload = preparePayload();

      if (page === 'create') {
        dispatch(roleActions.addRole(payload, _portal, () => {
          setTimeout(() => {
            goTo(`/user-management/permissions`);
          }, 100);
        }));
      }
      else {
        dispatch(roleActions.updateRole(rolesData?.name, payload, () => {
          setTimeout(() => {
            goTo(`/user-management/permissions?page=view&name=${frm?.name}`);
          }, 100)
        }));
      }
    }
  };

  const prepareTmsObject = () => {
    const newPermissionObj = {
      ...permissionEntitiesTMS,
      idp: {
        ...permissionEntitiesTMS.idp,
        tenant: {
          ...allowedTenants.reduce((result, tenant) => {
            result[tenant] = {
              user: { allow: [] },
              config: { allow: [] },
              token: { allow: [] },
              client: { allow: [] },
              role: { allow: [] },
              tenant: { allow: [] }
            };
            return result;
          }, {})
        }
      },
      app: {
        ...permissionEntitiesTMS.app,
        ...allowedTenants.reduce((result, tenant) => {
          result[tenant] = {
            space: { allow: [] },
            device: { allow: [] },
            ticket: { allow: [] },
            report: { allow: [] },
            config: { allow: [] }
          };
          return result;
        }, {})
      }
    };
    return newPermissionObj;
  }

  const preparePermissions = () => {
    if (page === "view") {
      if (_portal === "tms") {
        return filterPermissionPayload({ idp: { ...rolesData.idp }, app: { ...rolesData.app } });
      }

      let permissionObj = {}
      if (rolesData?.idp) {
        permissionObj["idp"] = { ...rolesData.idp }
      }
      if (rolesData?.app) {
        permissionObj["app"] = { ...rolesData.app }
      }
      return permissionObj;
    }

    const allPermissions = _portal === "mss" ? { ...permissionEntities1 } : prepareTmsObject();
    const permissionList = { ...allPermissions };

    for (const key in allPermissions) {
      if (key === 'idp' || key === 'app') {
        const entities = allPermissions[key];
        const rolesDataEntities = rolesData[key] || {};
        for (const entity in entities) {
          if (_portal === "tms") {
            for (const item in entities[entity]) {
              if (entity === "sys") {
                permissionList[key][entity][item]["allow"] = [];

                if (rolesDataEntities[entity]?.hasOwnProperty(item)) {
                  permissionList[key][entity][item] = { ...rolesDataEntities[entity][item] };
                }
              }
              else if (entity === "tenant") {
                for (const ent in entities[entity][item]) {
                  permissionList[key][entity][item][ent]["allow"] = [];
                }
              }
            }
          }
          else if (_portal === "mss") {
            permissionList[key][entity].allow = [];

            if (rolesDataEntities?.hasOwnProperty(entity)) {
              permissionList[key][entity] = { ...rolesDataEntities[entity] };
            }
          }
        }
      }
    }
    return permissionList;
  };

  const checkboxChangeHandler = (event, key = 'app', item = 'space', type = 'view', entity = "") => {
    event.preventDefault();

    const entitiesList = { ...frm.entities };
    if (_portal === "tms") {
      if (entity === "sys" || key === "app") {
        if (!entitiesList[key][entity][item]?.allow.includes(type)) {
          entitiesList[key][entity][item].allow.push(type);
        }
        else {
          let index = entitiesList[key][entity][item].allow.indexOf(type);
          entitiesList[key][entity][item].allow.splice(index, 1);
        }
      }
      else {
        if (entitiesList[key].hasOwnProperty("tenant")) {
          if (!entitiesList[key]["tenant"][entity][item]?.allow.includes(type)) {
            entitiesList[key]["tenant"][entity][item].allow.push(type);
          }
          else {
            let index = entitiesList[key]["tenant"][entity][item].allow.indexOf(type);
            entitiesList[key]["tenant"][entity][item].allow.splice(index, 1);
          }
        }
      }
    }
    else {
      if (!entitiesList[key][item]?.allow.includes(type)) {
        entitiesList[key][item].allow.push(type);
      }
      else {
        let index = entitiesList[key][item].allow.indexOf(type);
        entitiesList[key][item].allow.splice(index, 1);
      }
    }
    setFrm({ ...frm, entities: { ...entitiesList } });
  };


  const isChecked = (key, entity, item, type) => {
    if (_portal === "tms") {
      if (entity === "sys" || key === "app") {
        return frm?.entities[key][entity][item]?.allow?.includes(type);
      }
      else {
        return frm?.entities[key]["tenant"][entity][item]?.allow?.includes(type);
      }
    }
    else {
      return frm?.entities[key][item]?.allow?.includes(type);
    }
  }

  const renderCheckbox = (key, item, type, entity = "") => {
    return (
      <Checkbox
        key={'checkbox-' + key + '-' + entity + '-' + item + '-' + type}
        id={'checkbox-' + key + '-' + entity + '-' + item + '-' + type}
        disabled={page === 'view'}
        checked={isChecked(key, entity, item, type) || false}
        onClick={(event) => checkboxChangeHandler(event, key, item, type, entity)}
        aria-label='checkbox'
      />
    )
  };

  const renderCheckboxes = (key, entity, entities) => {
    return (
      <CustomAccordion
        key={`permission-accordion-${key}-${entity}`}
        title={entity.includes("tenantadmin") ? entity.split("_")[1].toUpperCase() : entity.toUpperCase()}
        defaultState={false}
        bordered
        contentBg="#FFFFFF"
      >
        <Box sx={{ display: 'flex' }}>
          <Box className={classes?.permissionTypeHeader}>
            <Box>View</Box>
            <Box>Operate</Box>
            <Box>Manage</Box>
          </Box>
        </Box>
        {Object.keys(entities).map((item) => (
          <Box className={classes?.permissionContainer} key={`${entity}-${item}-permission-container`}>
            <Box className={classes?.entityLabelContainer}>{item.toUpperCase()}</Box>
            <Box className={classes?.entityCheckboxContainer}>
              {renderCheckbox(key, item, 'view', entity)}
              {renderCheckbox(key, item, 'operate', entity)}
              {renderCheckbox(key, item, 'manage', entity)}
            </Box>
          </Box>
        ))}
      </CustomAccordion>
    );
  };

  const renderPermissionEntities = () => {
    return Object.keys(frm?.entities).map((key) => {
      const entities = frm?.entities[key];
      return (
        <CustomAccordion
          key={`permission-accordion-${key}`}
          id={`permission-accordion-${key}`}
          title={key.toUpperCase()}
          bordered
          contentBg="#FFFFFF"
        >
          {_portal === 'tms'
            ? Object.keys(entities).map((entity) => {
              if (entity !== 'tenant') {
                return renderCheckboxes(key, entity, entities[entity]);
              }
              return Object.keys(entities[entity]).map((ent) => renderCheckboxes(key, ent, entities[entity][ent]));
            })
            : (
              <>
                <Box sx={{ display: 'flex' }}>
                  <Box className={classes?.permissionTypeHeader}>
                    <Box>View</Box>
                    <Box>Operate</Box>
                    <Box>Manage</Box>
                  </Box>
                </Box>
                {Object.keys(entities).map((item) => (
                  <Box className={classes?.permissionContainer} key={`permission-${entities}-${item}-container`}>
                    <Box className={classes?.entityLabelContainer}>{item.toUpperCase()}</Box>
                    <Box className={classes?.entityCheckboxContainer}>
                      {renderCheckbox(key, item, 'view')}
                      {renderCheckbox(key, item, 'operate')}
                      {renderCheckbox(key, item, 'manage')}
                    </Box>
                  </Box>
                ))}
              </>
            )}
        </CustomAccordion>
      );
    });
  };

  useEffect(() => {
    const allowedPermissions = preparePermissions();
    setFrm({
      ...initialFrm,
      name: rolesData?.name || '',
      entities: allowedPermissions
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolesData, page])

  useEffect(() => {
    if (roleName && page !== 'create') {
      dispatch(roleActions?.getRole(roleName, 'permissions', _portal));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleName]);

  return (
    <>
      <PageHeader bckbtn={true} backfunc={() => { goTo('/user-management/permissions') }} title={`${page} permission`} icon={userPermission}>
        {
          (isAuthorised(features?.role, permissions?.edit, _portal) && page === 'view') &&
          <Grid>
            {Object.keys(frm?.entities).length > 0 &&
              <StyledButton iconButton tooltip='Edit' onClick={handleEditPermission}>
                <ImageWrapper width={18} height={18} src={Edit} />
              </StyledButton>
            }
            <StyledButton iconButton tooltip='Delete' onClick={(e) => { setOpenDeletePopup(true) }}>
              <ImageWrapper src={Delete} />
            </StyledButton>
          </Grid>
        }
      </PageHeader>

      <form name="form" onSubmit={handleFormSubmit} >
        <Box className={classes?.permissionNameWrapper}>
          <label style={{ fontFamily: 'Montserrat', marginRight: "8px" }}>Permission Name<label style={{ color: "#BC2200" }}>{page !== "view" ? '*' : ''}</label> : </label>
          {page === 'view' && <CustomTypography sx={{ marginLeft: '6px' }} content={frm?.name} weight={500} />}
          {['edit', 'create'].includes(page) &&
            <StyledTextfield
              sx={{ minWidth: '295px', maxWidth: '295px' }}
              value={frm?.name || ''}
              name="permissionName"
              placeholder="Enter Permission Name"
              onChange={(event) => { handleNameChange(event.target.value) }}
              error={formError}
              helperText={nameHelperText}
            />
          }
        </Box>

        <DataNotFound
          show={(Object.keys(frm?.entities).length > 0) ? false : true}
          hideLoader={page === "edit" ? true : false}
          data={{
            label: "No Data Found",
            content: "No entities found for this permission.",
            action: isAuthorised(features?.role, permissions?.edit, _portal) ? [
              {
                id: 1,
                type: "button",
                label: "Add permissions",
                onClick: () => { handleEditPermission() },
              }
            ] : []
          }}
          style={{ minHeight: "350px" }}
        >
          {renderPermissionEntities()}
        </DataNotFound>

        {
          page !== 'view' &&
          <Box sx={{ width: "100%", textAlign: "right", mt: 2.5 }}>
            <StyledButton variant="outlined" sx={{ minWidthwidth: '90px' }} onClick={handleCancelPermission}>Cancel</StyledButton>
            <StyledButton type="submit" sx={{ ml: 2, minWidth: '90px' }} >Save</StyledButton>
          </Box>
        }
      </form>

      <StyledSnackBar
        open={snackBar?.show}
        onClose={closeSnackBar}
        autoHideDuration={5000}
        data={{
          type: snackBar?.type,
          message: snackBar?.message,
        }}
      />

      <StyledPopup open={openDeletePopup} onClose={() => setOpenDeletePopup(false)} state="timeout"
        data={{
          title: `Delete Permission?`,
          content: `Are you sure you want to delete this Permission ?`,
          label: 'Warning',
          actions: [
            {
              id: "1001",
              type: "button",
              label: "Cancel",
              styles: { minWidth: 100 },
              onClick: (event, data) => {
                setOpenDeletePopup(false);
              }
            }, {
              id: "1001",
              type: "button",
              label: "OK",
              styles: { minWidth: 100 },
              onClick: (event, data) => {
                handleDeletePermission();
                setOpenDeletePopup(false);
              }
            }]
        }}
        customsx={{ label: { color: 'red', fontWeight: 700, fontSize: '0.9375rem', paddingBottom: '5px' }, btns: { display: 'flex', justifyContent: 'center' } }}
      />
    </>
  )
};

RoleSettings.propTypes = {};

RoleSettings.defaultProps = {};

export default RoleSettings;
