import _ from "lodash"
import { convertTSIntoDate } from "./users.helper";
import { hexToRgbA } from "../services";

export const colorPallete=[
"#D90707", /// red
'#FFBF00',
'#7FFFD4',
'#A4C639',
'#FBCEB1',
'#E9D66B',
'#E52B50',
'#FF9966',
'#6D351A',
'#007FFF'
]


export const getShapeType=(shapes, type)=>{
  if(_.isEmpty(shapes) || !Array.isArray(shapes)) return [];

  return shapes.filter((obj)=> obj?.type === type);
}

export const pointInCircle = (x0,y0,radius,X,Y) =>{ 
  // let xcoord = X-x0 > 0 ?  X-x0 -30 : X - x0 + 25;
  let xcoord = X-x0 ;
  // let ycoord = Y-y0 > 0 ?  Y-y0 - 100 : Y - y0 + 90;
  let ycoord = Y-y0-100;
  if((Math.pow((xcoord),2) + Math.pow((ycoord),2)) <= radius*radius ){
    return true
  }
  return false;
 
}

export const pointInPolygon = (polygon, point, scrollX=0, scrollY=0 ) => {
  //A point is in a polygon if a line from the point to infinity crosses the polygon an odd number of times
  let odd = false;
  //For each edge (In this case for each point of the polygon and the previous one)
  for (let i = 0, j = polygon?.length - 1; i < polygon?.length; i++) {
      //If a line from the point into infinity crosses this edge
      if (((polygon[i][1] - scrollY > point[1]) !== (polygon[j][1] - scrollY > point[1])) // One point needs to be above, one below our y coordinate
          // ...and the edge doesn't cross our Y corrdinate before our x coordinate (but between our x coordinate and infinity)
          && (point[0] < (((polygon[j][0] - scrollX) - ( polygon[i][0] - scrollX)) * (point[1] - polygon[i][1] - scrollY ) / ((polygon[j][1] - scrollY) -( polygon[i][1] - scrollY)) + polygon[i][0] - scrollX))) {
          // Invert odd
          odd = !odd;
      }
      j = i;
  }
  //If the number of crossings was odd, the point is in the polygon
  return odd;
};

export const outsidePolygon = (points, point, scrollX,  scrollY) => { 
  return !pointInPolygon(points, point,scrollX,scrollY);
  // return  hitTopLeft(points, point, scrollX,  scrollY) ||  hitTopRight(points, point, scrollX,  scrollY) ||
  //   hitBottomLeft(points, point, scrollX,  scrollY) || hitBottomRight(points, point, scrollX,  scrollY)  ;
}

function lineFromPoints(P, Q)
{
    let a = Q[1] - P[1]
    let b = P[0] - Q[0]
    let c = a*(P[0]) + b*(P[1])
  return [a,b,-c];
 
}

function getDistance(point, pos){
  let x=point[0], y=point[1], x0=pos[0], y0=pos[1];
  let xsquare = Math.pow(x-x0,2), ysquare = Math.pow(y-y0,2);
let dist= Math.sqrt(xsquare+ysquare)	;
return dist;

}

function perpendicularDistance(intercepts,point){
  let [a,b,c]=intercepts;
  return (Math.abs(a*point[0] + b*point[1] + c)/Math.sqrt(a*a + b*b));

}

export const closestPointsInPolygon=(polygon, pos)=>{
  let obj={}, obj1={}, intercepts={}, str='';

polygon.forEach((point,index,array)=>{
  str = `S${index+1}`;
  if(index!==polygon.length-1){
    intercepts[str]= { intrcpts: lineFromPoints(array[index], array[index+1]), points: [array[index], array[index+1]] };
  }else{
    intercepts[str]= {intrcpts: lineFromPoints(polygon[polygon.length-1],polygon[0],), points: [polygon[polygon.length- 1],polygon[0]]};
  }
})



  // polygon.forEach(point=>{
  //   let dist = getDistance(point, pos);
  //   obj[dist]=point;
  // });
  polygon.forEach((point,ind)=>{
    str = `S${ind+1}`;
    let dist = perpendicularDistance(intercepts[str].intrcpts,pos );
    obj[dist]=point;
    obj1[dist]=str;
  })

  const arr1 = Object.keys(obj1).sort((a,b) => a-b);
  // const point1=obj[arr[0]];
  // const point2=obj[arr[1]];
  let closestSet=obj1[arr1[0]];
  let closestPoints=intercepts[closestSet]?.points;
  const [a1,b1,c1]=lineFromPoints(closestPoints[0],closestPoints[1]);
  let m2=(b1/a1), a2=-b1,b2=a1;
  let d= (pos[1] - m2*pos[0]);
  let c2= -1*a1*d;
  let determinant = a1*b2 - a2*b1;
  let intersection=[];
  if(determinant === 0){
    intersection=[1e7,1e7];
  }
  else{
    let x = (b2*c1 - b1*c2)/determinant;
    let y = (a1*c2 - a2*c1)/determinant;
    intersection=[Math.abs(x), Math.abs(y)];
  }

  // console.log('intersection',intersection, determinant, a2,b2,c2);


  const point1=closestPoints[0];
  const point2=closestPoints[1];
  const closest={x1:point1[0],y1:point1[1],x2:point2[0],y2:point2[1]};
  const slope= (closest.y2 - closest.y1)/ (closest.x2 - closest.x1);
  const dist1 = getDistance(point2,point1)

  return {x1:point1[0],y1:point1[1],x2:point2[0],y2:point2[1],dist1:dist1, slope:slope, intersection:intersection}

}

export const pointCollinearity =(point1, point2, point)=>{
  return Math.sign((point2[0] - point1[0])*(point[1] - point1[1]) - (point2[1] - point1[1])*(point[0] - point1[0]));
}

export const onLoadImage = (imageLayout) => {
  return new Promise((res, rej) => {
    var img = new Image();
    img.src = imageLayout;
    if (img.complete) {
      res(img)
    }
    else {
      img.onload = () => res(img);
      img.onerror = () => rej(false);
    }
  })
}


export function convertData(data, type) {
  if (type === 'date') {
    return convertTSIntoDate(data).split('-').reverse().join('-');
  }
  else if (type === 'array') {
    return data.join(' ');
  }

  return data
}

export function getDepthLevel(Path) {
  return Path?.split('.').length - 1;
}

export function dragBoundPolygontoShape(parentShape,points,pos,maxW,maxH){
let newpos={x:pos?.x, y:pos?.y};
console.log('bounddddd',pos,points);
  if(parentShape?.type === 'rect' && points?.length>0){
    let xrect= parentShape?.x, yrect= parentShape?.y, PW=parentShape?.width, PH=parentShape?.height;
    points?.forEach(([x,y])=>{
      if(x<xrect){
        newpos['x']=-95;
      }if(x>xrect+PW){
        newpos['x']=PW-xrect-maxW;
      }if(y<yrect){
        newpos['y']=yrect-95;
      }if(y>yrect+PH){
        newpos['y']=PH-yrect-maxH;
      }
    })
  }

  return newpos;

}


export const dragBoundFunc = (stageWidth, stageHeight, vertexRadius, pos, X=0,Y=0, R=0, type='') => { //// only for polygon
  let x = pos.x;
  let y = pos.y;
  if(type === 'rect' || type === ''){
  if (pos.x + vertexRadius > X+stageWidth) x = X+stageWidth;
  if (pos.x - vertexRadius < X) x = X;
  if (pos.y + vertexRadius > Y+stageHeight) y = Y+stageHeight;
  if (pos.y - vertexRadius < Y) y = Y;
  }
  else if(type === 'circle' ){
   let scale = R/(Math.sqrt((x-X)*(x-X) + (y-Y)*(y-Y)));
   if(scale<1){
    x = (x-X) * scale + X ;
    y = (y-Y) * scale + Y ;
   }
  }
  return { x, y };
};

export const dragBoundCircle = (sw, sh, radius, pos) => { //// only for cirlce
  let x = pos.x;
  let y = pos.y;
  if (pos.x + radius > sw) x = sw - radius;
  if (pos.x - radius < 0) x = radius;
  if (pos.y + radius > sh) y = sh - radius - 200;
  if (pos.y - radius < 0) y = radius;

  return { x, y }
}

export const dragBoundRect = (SW, SH, w, h, pos) => { //// only for rectangle
  let x = pos.x;
  let y = pos.y;
  // console.log('stage ref:::::',SW, SH,w ,h, pos);
  if (pos.x + w > SW) x = SW - w;
  if (pos.x < 0) x = 0;
  if (pos.y + h > SH) y = SH - h - 200;
  if (pos.y < 0) y = 0;

  return { x, y }
}

export const dragBoundRectParent = (PW, PH, w, h, PX, PY, pos, r = 0) => {
  let x = pos.x + 2;
  let y = pos.y + 2;
  let border = 3;
  if (pos.x + w + r + border > PW + PX) x = PW + PX - w - border;
  if (pos.x - r - border < PX) x = PX + r + border;
  if (pos.y + h + border > PH + PY) y = PH + PY - h - border;
  if (pos.y - r - border < PY) y = PY + r + border;

  return { x, y }
}

export const dragBoundCirlceParentToRect = (PR, w, h, PX, PY, shapePos,) => {
  let x = shapePos.x;
  let y = shapePos.y;
  let midx = w / 2, midy = h / 2, midDiagonal = Math.sqrt(midx * midx + midy * midy);
  let pos = shapePos;
  let scale = PR - 2;

  scale = (scale - midDiagonal) / (Math.sqrt(Math.pow(x + midx - PX, 2) + Math.pow(y + midy - PY, 2)));
  if (scale < 1) {
      pos['x'] = (x + midx - PX) * scale + PX - midx;
      pos['y'] = (y + midy - PY) * scale + PY - midy;
  }

  return pos;
}

export const dragBoundCirlceParentToCircle = (PR, PX, PY, shapePos, radius) => {
  let x = shapePos.x;
  let y = shapePos.y;
  let pos = shapePos;
  let scale ;

  scale = (PR - radius ) / (Math.sqrt(Math.pow(x  - PX, 2) + Math.pow(y  - PY, 2)));
  if (scale < 1) {
      pos['x'] = (x - PX) * scale + PX;
      pos['y'] = (y - PY) * scale + PY;
  }

  return pos;
}


export const dragBoundPolygonRect = (xcoords, ycoords, PW, PH, parentShape, pospolygon, rectclient) => {
  let pos = { ...pospolygon };
  if (xcoords?.find(x => x < parentShape?.x)) pos['x'] = parentShape?.x - (rectclient.x - pospolygon?.x);
  if (ycoords?.find(y => y < parentShape?.y)) pos['y'] = parentShape?.y - (rectclient.y - pospolygon?.y);
  if (xcoords?.find(x => x > parentShape?.x + PW)) pos['x'] = parentShape?.x + PW - (rectclient.x - pospolygon?.x - rectclient?.height);
  if (ycoords?.find(y => y > parentShape?.y + PH)) pos['y'] = parentShape?.y + PH - (rectclient.x - pospolygon?.x - rectclient?.height);

  console.log('possssss', rectclient.x - pospolygon?.x, pospolygon, rectclient);
  return pos;
}



export const fillColorInShapes = (shapeProps, selectedSpace, spaceIds, isShapePresent) => {
  // console.log('fill color:::',spaceIds, isShapePresent, shapeProps?.spaceId);
  if (!isShapePresent) {
      if (shapeProps?.spaceId === selectedSpace?.parentSid)
          return 'rgba(255,255,255,0.5)';
      else
          return 'rgba(0,0,0,0.5)';
  }
  // if((shapeProps?.spaceId === selectedSpace?.spaceId) || (selectedSpace?.Spaces?.length>0)) return shapeProps?.fill 
  if (!_.isEmpty(spaceIds?.find(s => s?.spaceId === shapeProps?.spaceId))) return hexToRgbA(shapeProps?.fill, shapeProps?.transparency);

  else return 'rgba(0,0,0,0.5)';

}

export function haveIntersection(r1, r2, type) {
  if (type === 'Polygon') { console.log('r1 r2 came::', r1, r2); return false };

  if (type === 'Circle') {
      let R = r2?.height / 2, Xc = r2.x + R, Yc = r2.y + R;
      let X1 = r1.x, Y1 = r1.y, X2 = r1.x + r1.width, Y2 = r1.y + r1.height;
      let Xn = Math.max(X1, Math.min(Xc, X2));
      let Yn = Math.max(Y1, Math.min(Yc, Y2));
      let Dx = Xn - Xc;
      let Dy = Yn - Yc;
      return (Dx * Dx + Dy * Dy) <= R * R;

  }

  return !(
      r2.x > r1.x + r1.width ||
      r2.x + r2.width < r1.x ||
      r2.y > r1.y + r1.height ||
      r2.y + r2.height < r1.y
  );
}

export const minMax = (points) => {
  return points.reduce((acc, val) => {
      acc[0] = acc[0] === undefined || val < acc[0] ? val : acc[0];
      acc[1] = acc[1] === undefined || val > acc[1] ? val : acc[1];
      return acc;
  }, []);
};

