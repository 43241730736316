import { areaDeleteMessageLatency } from "../config/mqttConfig";
import { areaTypes } from "../redux/types";

let areaDeleteTimeout = null;

export const setAreaDeleteTimeout = (ms, dispatch) => {
    areaDeleteTimeout = setTimeout(() => {
        console.log("area delete timeout")
        dispatch({
            type: areaTypes?.UPDATE_DELETE_AREA_STATUS,
            payload: { deleteAreaStatus: "delete_area" + Date.now() },
        });
    }, ms)
}

export const updateAreaDeleteStatus = (msg, dispatch) => {
    if (msg?.status === "area_delete_success" || msg?.status === "area_sync_success" || msg?.status === "incorrect_areaid") {
        clearTimeout(areaDeleteTimeout);
        setTimeout(() => {
            dispatch({
                type: areaTypes.UPDATE_DELETE_AREA_STATUS,
                payload: { deleteAreaStatus: "delete_area" + Date.now() },
            });
        }, areaDeleteMessageLatency)
    }
}