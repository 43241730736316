/***
 *
 * Controller class for user.
 * @file GroupDragDropWrapper.js
 * @description GroupDragDropWrapper component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './GroupDragDropWrapper.style.js';
import './GroupDragDropWrapper.scss';
import { Box, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Light from '../../assets/icons/light.svg';
import _ from 'lodash';
import { dragAndDropActions } from '../../redux/actions/dragAndDrop.actions.js';
import { deviceActions } from '../../redux/actions/index.js';
import SpaceTree from '../SpaceTree/index.js';
import DragAndDropColumnsWrapper from '../DragAndDropColumnsWrapper/index.js';
import { disableDevFeatures } from '../../config/appConfig.js';

const GroupDragDropWrapper = ({ ifEdit = false, ifMovedToNextStep, setNotFoundDevicesInDropList }) => {
  const classes = useStyles()
  const dispatch = useDispatch();

  const preSelectedGroup = useSelector(state => state?.groups?.group);
  const deviceList = useSelector(state => state?.devices?.deviceList);
  const groupDevices = useSelector((state) => state?.devices?.detailedDeviceList);
  const dropList = useSelector(state => state?.dragAndDrops?.dropList);

  const [dropListIds, setDropListIds] = useState([])
  const [loading, setLoading] = useState(false)
  const [completeList, setCompleteList] = useState([])
  const [isSpaceSelected, setIsSpaceSelected] = useState(false)

  useEffect(() => {
    if (ifEdit && !ifMovedToNextStep) { //&& !_.isEmpty(groupDevices)
      let initialDropList = preSelectedGroup?.devices?.map(item => {
        item.id = item?.deviceId;
        item.icon = Light
        item.type = 'LIGHT'
        let obj={...item};
        let detailedDevice = groupDevices.find(device => device?.deviceId === item?.deviceId) || {};
        obj = { ...obj, ...detailedDevice };

        if (!disableDevFeatures?.itemId && (!detailedDevice?.hasOwnProperty("name"))) {
            obj.name = (detailedDevice?.name || item?.name || '') + ' (' + item?.deviceId + ')'
        }
        // obj.name = obj?.name || '';
        if (obj?.status === 'deleted' || !obj?.hasOwnProperty("modelId")) {//checking modelId as device can be deleted in cloud in that case we dont get data
          obj.redChip = true;
        }
        return obj;
      });
      //dispatch action to set drop lit complete
      dispatch(dragAndDropActions.setDropList(initialDropList));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupDevices])

  useEffect(() => {
    let droppedIds = dropList.map(item => item?.id)
    let notFoundDevicesInDropList = dropList.filter(item => { return (item?.deviceId !== undefined && item?.redChip) }) || [];
    setNotFoundDevicesInDropList(notFoundDevicesInDropList);
    setDropListIds(droppedIds)
    if(!_.isEmpty(dropList)){
      dispatch(dragAndDropActions.setErrorMessage('')); //WGJGBM< group-emptyMessage
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropList])

  const onClick = (event, data) => {
    if (!isSpaceSelected) { setIsSpaceSelected(true) }
    setLoading(true)
    //(()=>{
    dispatch(deviceActions.getDevicesBySpaceId(data?.spaceId));
    setTimeout(() => {
      setLoading(false)
    }, 2000)
    //})
  }

  useEffect(() => {
    if (isSpaceSelected || ifMovedToNextStep) {
      let devices = deviceList.filter(device => {
        if (device.category.includes('LightDriver') && device?.status==="active") {
            device.id = device?.deviceId;
            device.name = device?.name || '';
            if (!disableDevFeatures?.itemId && device.name.slice(-1) !== ')') {
              device.name = device.name + ' (' + device?.id + ')';
            }
            device.icon = Light;
            device.type = 'LIGHT';
            return device;
        }
      })
      setCompleteList([...devices])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceList, isSpaceSelected])

  // change in space I want to update 
  useEffect(() => {
    let dragListToBeSet = [...completeList]
    if (!_.isEmpty(dropListIds)) {
      dragListToBeSet = dragListToBeSet.filter(item => !dropListIds.includes(item?.id));
    }
    //dispatch to drag list complete
    dispatch(dragAndDropActions.setDragList(dragListToBeSet));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completeList, dropListIds])


  return (
    <Box className={classes?.GroupDragDropWrapper || ""} data-testid="GroupDragDropWrapper">
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <SpaceTree onClick={onClick} />
        </Grid>
        <Grid item xs={12}  md={9}>
          <DragAndDropColumnsWrapper forList={'Devices'} typeCreated={'Group'} loading={loading} />
        </Grid>
      </Grid>
    </Box>
  )
};

GroupDragDropWrapper.propTypes = {};

GroupDragDropWrapper.defaultProps = {};

export default GroupDragDropWrapper;
