/***
 *
 * Controller class for user.
 * @file Rules.js
 * @description Rules component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import { Box } from '@mui/material';
import React from 'react';
import { useStyles } from './Rules.style.js';
import { useQuery } from '../../hooks/useQuery.js';
import RuleAddEdit from '../RuleAddEdit/index.js';
import RuleDetails from '../RuleDetails/index.js';
import RulesList from '../RulesList/index.js';
import AutomationRuleDetails from '../../components/AutomationRuleDetails/index.js';

const Rules = () => {
  const classes = useStyles();
  const query = useQuery();
  const queryParam = query.get('op') || null;

  return (
    <Box className={classes?.Rules || ""} data-testid="Rules">
      {(() => {
        switch(queryParam) {
          case 'create':
          case 'edit':
            return <RuleAddEdit />;
          case 'view':
            return <AutomationRuleDetails />;
          default:
            return <RulesList />;
        }
      })()}
    </Box>
  )
};

Rules.propTypes = {};

Rules.defaultProps = {};

export default Rules;
