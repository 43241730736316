import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    SearchWrapper: {
        padding: "10px 24px",
        background: "#0F8D480D",
        '& .space-search': {
            '& .MuiOutlinedInput-root': {
                padding: "0px",
                '& .MuiOutlinedInput-input': {                  
                  fontSize: '0.875rem',
                  padding: "7px 15px",
                }
              }
        }
    }
});
