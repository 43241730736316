/***
 *
 * Controller class for user.
 * @file RuleStatus.js
 * @description RuleStatus component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
// import { useStyles } from './RuleStatus.style.js';
import './RuleStatus.scss';
// import ImageWrapper from '../ImageWrapper/index.js';
// import StyledChip from '../StyledChip/index.js';
import StyledStatus from '../StyledStatus/index.js';

const RuleStatus = ({data}) => {
    // const classes = useStyles();
    const [statusColor, setStatusColor] = useState('');
    const [statusMessage, setStatusMessage] = useState('');    

    const iconChecker = (status, startDate, expireDate) => {
      const dateToday = new Date().getTime();
      startDate = startDate * 1000;
      expireDate = expireDate * 1000;

      if(expireDate < dateToday) {
        setStatusColor('#EF0000');
        setStatusMessage('Expired');
        // return StatusExpired;
      } else if(status === 'enable' && startDate <= dateToday) {
        setStatusColor('#0F8D48');
        setStatusMessage('Enabled');
        // return StatusEnabled;
      } else if(status === 'enable' && startDate > dateToday) {
        setStatusColor('#E07B04');
        setStatusMessage('Yet To Start');
        // return StatusYetToStart;
      } else if(status === 'disable'){
        setStatusColor('#666666');
        setStatusMessage('Disabled');
        // return StatusDisabled;
      } else {
        return '';
      }
    }

    useEffect(() => {
      if(data) {
        iconChecker(data.status, data.startsAtSecs, data.expiresAtSecs)
      }
    }, [data])

    return (
      <StyledStatus statusMessage={statusMessage} statusColor={statusColor} sx={{display: "flex"}} />
    )
};

RuleStatus.propTypes = {};

RuleStatus.defaultProps = {};

export default RuleStatus;
