import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    StyledTextArea: {
        padding: theme?.padding?.textarea || "8px 10px",
        fontSize: theme?.fontSize?.textarea || "0.875rem",
        lineHeight: theme?.lineHeight?.textarea || "20px",
        minHeight: theme?.minHeight?.textarea || "24px",
        borderColor: theme?.borderColor?.textarea || "rgba(0, 0, 0, 0.23)",
        borderRadius: theme?.borderRadius?.textarea || "4px",
        width: theme?.width?.textarea || "100%"
        // '& .MuiOutlinedInput-root': {
        //     '& .MuiOutlinedInput-input': {
        //         padding: theme?.padding?.textarea || "8px 10px",
        //         fontSize: theme?.fontSize?.textarea || "0.875rem",
        //         lineHeight: theme?.lineHeight?.textarea || "18px",
        //         height: theme?.height?.textarea || "24px",
        //     },
        //     '& fieldset': {
        //         top: 0,
        //         '& legend': {
        //             display: "none"
        //         }
        //     }
        // },
        // '& .MuiFormHelperText-root': {
        //     marginLeft: 0
        // }
    }
}));
