/***
 *
 * Controller class for user.
 * @file SpaceTypes.js
 * @description SpaceTypes component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import { Box } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import PropTypes from 'prop-types';
import AddEditSpaceType from '../../components/AddEditSpaceType/index.js';
import CustomTable from '../../components/CustomTable/index.js';
import PageHeader from '../../components/PageHeader/index.js';
import { spaceTypeActions } from '../../redux/actions/spaceType.actions.js';
import './SpaceTypes.scss';
import spaceIcon from '../../assets/img/space-types.png';
import DataNotFound from '../DataNotFound/index.js';
import StyledPopupWrapper from '../../components/StyledPopupWrapper/index.js';
import StyledPopup from '../../components/StyledPopup/index.js';
import { getMessageStr } from '../../helpers/message.helper.js';
import DeleteIcon from "../../assets/icons/delete.svg"
import EditIcon from "../../assets/icons/edit-2.svg"
import { isAuthorised } from '../../helpers/users.helper.js';
import { features, permissions } from '../../constants/permission.constants.js';
import StyledButton from '../../components/StyledButton/index.js';


const SpaceTypes = () => {

  const spaceTypesData = useSelector(state => state?.spaceDTypes?.list);
  const dispatch = useDispatch();
  const [openPopover, setOpenPopover] = useState({ show: false, target: null, selectedSpaceType: null });
  const [spaceTypeList, setSpaceTypeList] = useState(spaceTypesData);
  const [dialog, setDialog] = useState(false);
  const [dialogContents, setDialogContents] = useState(null);

  const deleteSpaceType = (event, data) => {
    setDialogContents((dialogContents) => ({
      ...dialogContents,
      state: "timeout",
      content: {
        title: "Delete Space Type",
        content: getMessageStr(`spaceType-delete-confirmation`, data.spaceType),
        actions: [
          {
            id: "1001",
            type: "button",
            label: "Cancel",
            onClick: (event, data) => {
              setDialog(false);
            },
          },
          {
            id: "1002",
            type: "button",
            label: "Confirm",
            onClick: (event) => {
              dispatch(spaceTypeActions.deleteSpaceType(data.spaceType));
              setDialog(false);
            },
          },
        ],
      },
    }));
    setDialog(true);
  }

  useEffect(() => {
    setSpaceTypeList(spaceTypesData || []);
  }, [spaceTypesData]);

  return (
    <Box className="SpaceTypes" data-testid="SpaceTypes">
      <PageHeader
        title={`Space Types`}
        icon={spaceIcon}
      >
      {isAuthorised(features.space, permissions.add) &&<StyledButton color="primary" size="medium" onClick={(e) => { setOpenPopover({ show: true, target: e.currentTarget, selectedSpaceType: null }); }}>Add Space Type</StyledButton>}
      </PageHeader>

      <Box width={'100%'} className="ListOfSpaceTypes" data-testid="ListOfSpaceTypes">
        <DataNotFound
          show={spaceTypeList ? false : true}
          customsx={{ image: { height: "202px", width: "100%" } }}
          data={{
            label: "SpaceTypes",
            content: "No Spacetype found. Create a new spacetype",
            action: [
              {
                label: "Create SpaceType",
                onClick: (e) => {
                  setOpenPopover({ show: true, target: e.currentTarget, selectedSpaceType: null });                },
              }
            ]
          }}
        >
          <CustomTable
            headerList={{
              icons: { label: "Icon", icon: { type: "image", alt: "icon" }, pathKey: "icons", style: { width: "100px" } },
              spaceType: {
                label: "space type"
              },
              userActions: {
                label: "Actions",
                actions: [
                  {
                    label: "Delete Space Type",
                    type: "delete",
                    icon: DeleteIcon,
                    onClick: (event, data) => deleteSpaceType(event, data),
                    hidden: !isAuthorised(features.space, permissions.delete) && 'none'
                  },
                  {
                    label: "Edit Space Type",
                    type: "edit",
                    icon: EditIcon,
                    onClick: (event, data) => { setOpenPopover({ show: true, target: event.currentTarget, selectedSpaceType: data }); },
                    hidden: !isAuthorised(features.space, permissions.edit) && 'none'
                  },
                ],
                style: { textAlign: "right" },
              }
            }}
            cellMatrix={spaceTypeList} pagination={true} searching={{ columns: ['spaceType'] }}
            sorting={{ column: 'spaceType' }}
            removeSortFrom={["icons", "userActions"]}
          />
        </DataNotFound>

        <StyledPopupWrapper open={openPopover?.show} closeButton={false} onClose={() => setOpenPopover(null)}>
          <AddEditSpaceType selectedSpaceType={openPopover?.selectedSpaceType} setOpenPopover={setOpenPopover} />
        </StyledPopupWrapper>
      </Box>
      <StyledPopup
        open={dialog}
        state={dialogContents?.state}
        data={dialogContents?.content}
      />
    </Box>
  )
};

SpaceTypes.propTypes = {};

SpaceTypes.defaultProps = {};

export default SpaceTypes;
