export const inlineUserMessage = {
    "user-addUserFormValidation-Name": "Name is required",
    "user-addUserFormValidation-firstName": "Please enter the user's first name",
    "user-addUserFormValidation-lastName": "Please enter the user's last name",
    "user-addUserFormValidation-userName": "Username is required",
    "user-addUserFormValidation-email": "Email is required",
    "user-addUserFormValidation-contact": "Contact is required",
    "user-addUserFormValidation-addPermission": "Permission is required",
    "user-addUserFormValidation-firstNameMinLength": "User's first name should have minimum 2 characters",
    "user-addUserFormValidation-firstNameMaxLength": "User's first name should have maximum 30 characters",
    "user-addUserFormValidation-firstNameAplhaValidation": "Invalid first name. Only alphabets are allowed",
    "user-addUserFormValidation-lastNameMinLength": "User's last name should have minimum 2 characters",
    "user-addUserFormValidation-lastNameMaxLength": "User's last name should have maximum 30 characters",
    "user-addUserFormValidation-lastNameAplhaValidation": "Invalid last name. Only alphabets are allowed",
    "user-addUserFormValidation-userNameMinLength": "User name should have minimum 2 characters",
    "user-addUserFormValidation-userNameMaxLength": "User name should have maximum 30 characters",
    "user-addUserFormValidation-NameMinLength": "Name should have minimum 2 characters",
    "user-addUserFormValidation-NameMaxLength": "Name should have maximum 30 characters",
    "user-addUserFormValidation-userNameSpaceValidation": 'Spaces not allowed',
    "user-addUserFormValidation-userNameAplhaValidation": "Username should only contains alphanumerics and [-]",
    "user-addUserFormValidation-NameAplhaValidation": "Name should only contain alphabets and [space]",
    "user-addUserFormValidation-emailInvalid": "Invalid email id",
    "user-addUserFormValidation-contactInvalid": "Contact number should have 10 digits",
    "user-addUserFormValidation-employeeId": 'Employee ID is required',
    "user-addUserFormValidation-employeeIdMinLength": 'Employee Id should contain minimum 2 characters',
    "user-addUserFormValidation-employeeIdMaxLength": 'Employee Id should contain maximum 30 characters',
    "user-addUserFormValidation-employeeIdInvalid": 'Employee ID should only contain alphanumerics and [-]',
    "user-addUserFormValidation-tempExpiryDate": 'Date is required',
    "permission-addPermission-nameAllowedChars": "Permission Name should only contain 'a-z', 'A-Z', '0-9', characters.",
    "permission-addPermission-nameMinLength": "Permission name should have minimum 2 characters.",
    "permission-addPermission-nameMaxLength": "Permission name should not exceed more than 30 characters.",
    "permission-addPermission-nameRequired": "Permission Name is required",
    "permission-addPermission-entitySelected": "No Permission is Selected",
    "preLogin-fail-401": "MSS Service is temporarily unavailable. Please try after sometime.",

}


export const userMessages = (item) => {
    return {
        //// login
        "login-post-200": "Login success.",
        "login-post-403": `${item?.message?.toLowerCase()?.includes("expired") ? "Password has expired, please reset the password and try again." : item?.remainingAttempts === 0 ? "Account is locked due to multiple wrong attempt of the password, please reset the password and try again." : "Unable to login due to invalid credentials, please try again with correct credentials. You have " + item?.remainingAttempts + " attempt(s) left."}`,
        "login-post-400": `${item?.remainingAttempts !== null ? (item?.remainingAttempts === 0 ? "Account is locked due to multiple wrong attempt of the password, please reset the password and try again." : "Unable to login due to invalid credentials, please try again with correct credentials. You have " + item?.remainingAttempts + " attempt(s) left.") : "Unable to login due to invalid credentials, please try again with correct credentials."}`,
        "login-post-401": `${item?.remainingAttempts !== null ? (item?.message?.toLowerCase()?.includes("expired") ? "Password has expired, please reset the password and try again." : item?.remainingAttempts === 0 ? "Account is locked due to multiple wrong attempt of the password, please reset the password and try again." : "Unable to login due to invalid credentials, please try again with correct credentials. You have " + item?.remainingAttempts + " attempt(s) left.") : "Unable to login due to invalid credentials, please try again with correct credentials."}`,
        "login-post-404": `${item?.remainingAttempts !== null ? (item?.remainingAttempts === 0 ? "Account is locked due to multiple wrong attempt of the password, please reset the password and try again." : "Unable to login due to invalid credentials, please try again with correct credentials. You have " + item?.remainingAttempts + " attempt(s) left.") : "Unable to login due to invalid credentials, please try again with correct credentials."}`,
        "login-post-500": "MSS service is temporarily unavailable due to internal error. Please try again later.",
        "login-post-501": "MSS service is temporarily unavailable. Please try again later.",
        "login-post-502": "MSS service is temporarily unavailable. Please try again later.",
        "login-post-503": "MSS Service is temporarily unavailable. Please try again later.",

        //// otp
        "generateOTP-post-200": "OTP sent successfully to your registered Email/Mobile number.",
        "generateOTP-post-400": "Neither email or mobile is verified. Please request your admin to reset your password.",
        "generateOTP-post-409": "Unable to send the OTP, Email/Mobile already exists for another user.",
        "generateOTP-post-500": "Unable to generate the OTP due to internal error, please try again later.",
        "validateOTP-post-200": 'OTP successfully validated.',
        "validateOTP-post-400": "Unable to verify the OTP, please recheck the OTP and try again.",
        "validateOTP-post-403": "Unable to verify the OTP, please recheck the OTP and try again.",
        "validateOTP-post-500": "Unable to validate the OTP due to internal error, please try again later.",

        //// reset password
        "resetPassword-post-200": 'Password successfully changed, please try to relogin with new password.',
        "resetPassword-post-400": 'Unable to change the password, password matches old password or recheck your password pattern and try again.',
        "resetPassword-post-401": 'Unable to change the password, please check your old password and try again.',
        "resetPassword-post-403": 'Unable to change the password, please verify your access and try again.',
        "resetPassword-post-404": 'Unable to change the password, please try again later.',
        "resetPassword-post-500": 'Unable to change the password due to internal error, please try again later.',

        //// change user password by tenant admin
        "changePassword-post-200": "Password successfully changed.",
        "changePassword-post-400": "Unable to change the password, password matches old password or recheck your password pattern and try again.",
        "changePassword-post-401": "Unable to change the password, please verify your access and try again.",
        "changePassword-post-403": "Unable to change the password, please verify your access and try again.",
        "changePassword-post-404": "Unable to change the password, please try again with correct password pattern.",
        "changePassword-post-500": "Unable to change the password due to internal error, please try again later.",

        //// chnage self password
        "changeSelfPassword-put-200": "Password successfully changed, please login with new password.",
        "changeSelfPassword-put-403": "Unable to change the password, please try again with correct old password.",
        "changeSelfPassword-put-400": "Unable to change the password, password matches old password or recheck your password pattern and try again.",
        "changeSelfPassword-put-404": "Unable to change the password, please try again later.",
        "changeSelfPassword-put-500": "Unable to change the password due to internal error, please try again later.",

        //// get self profile
        "getSelfProfile-get-200": "Profile successfully retrieved.",
        "getSelfProfile-get-400": "Unable to retrieve the user profile, please try again later.",
        "getSelfProfile-get-500": "Unable to retrieve the user profile due to internal error, please try again later.",

        //// update user self profile
        "updateSelfProfile-put-200": "Profile successfully updated.",
        "updateSelfProfile-put-400": "Unable to update the profile, please verify your inputs and try again.",
        "updateSelfProfile-put-404": "Unable to update the profile, please try again later.",
        "updateSelfProfile-put-500": "Unable to update the profile due to internal error, please try again later",

        //// add user
        "addUser-post-200": "User successfully added.",
        "addUser-post-400": "Unable to add the user, please check the inputs and try again.",
        "addUser-post-401": "Unable to add the user, please verify your access and try again.",
        "addUser-post-403": "Unable to add the user, please verify your access and try again.",
        "addUser-post-404": "Unable to add the user, please try again later.",
        "addUser-post-409": "Unable to add the user probably due to User Id/Email/Mobile already exists, please check the inputs and try again.",
        "addUser-post-500": "Unable to add the user due to internal error, please try again later.",

        //// update user
        "update-put-200": "User successfully updated.",
        "update-put-400": "Unable to update the user, please check the inputs and try again.",
        "update-put-401": "Unable to update the user, please verify your access and try again.",
        "update-put-403": "Unable to update the user, please verify your access and try again.",
        "update-put-404": "Unable to update the user, please try again later.",
        "update-put-409": "Unable to update the user probably due to Email/Mobile already exists, please check the inputs and try again.",
        "update-put-500": "Unable to update the user due to internal error, please try again later.",

        //// delete user
        "deleteUser-delete-200": 'User successfully deleted.',
        "deleteUser-delete-400": 'Unable to delete the user, please check the inputs and try again.',
        "deleteUser-delete-401": 'Unable to delete the user, please verify your access and try again.',
        "deleteUser-delete-403": 'Unable to delete the user, please verify your access and try again.',
        "deleteUser-delete-404": 'Unable to delete the user, please try again later.',
        "deleteUser-delete-500": 'Unable to delete the user due to internal error, please try again later.',

        //// change user status
        "changeUserStatus-put-200": "User status successfully updated.",
        "changeUserStatus-put-400": "Unable to update the user status, please check the inputs and try again.",
        "changeUserStatus-put-401": "Unable to update the user status, please verify your access and try again.",
        "changeUserStatus-put-403": "Unable to update the user status, please verify your access and try again.",
        "changeUserStatus-put-404": "Unable to update the user status, please try again later.",
        "changeUserStatus-put-500": "Unable to update the user status due to internal error, please try again later.",

        //// register user
        "register-post-200": "User successfully registered.",
        "register-post-400": "Unable to register the user, please check the inputs and try again.",
        "register-post-401": "Unable to register the user, please verify your access and try again.",
        "register-post-403": "Unable to register the user, please verify your access and try again.",
        "register-post-404": "Unable to register the user, please try again later.",
        "register-post-409": "Unable to register the user probably due to User Id/Email/Mobile already exists, please check the inputs and try again.",
        "register-post-500": "Unable to register the user due to internal error, please try again later.",

        //// user activity logs
        "getUserActivityLogs-get-400": "Unable to retrieve the activity logs, please try again later.",
        "getUserActivityLogs-get-401": "Unable to retrieve the activity logs, please verify your access and try again.",
        "getUserActivityLogs-get-403": "Unable to retrieve the activity logs, please verify your access and try again.",
        "getUserActivityLogs-get-404": "Unable to retrieve the activity logs, please try again later.",
        "getUserActivityLogs-get-500": "Unable to retrieve the activity logs due to internal error, please try again later.",

        //// user permission and scopes
        "addPermission-post-200": 'Permission successfully added.',
        "addPermission-post-400": 'Unable to add the permission probably due to same permission name exists, please check the input and try again.',
        "addPermission-post-401": 'Unable to add the permission, please verify your access and try again.',
        "addPermission-post-403": 'Unable to add the permission, please verify your access and try again.',
        "addPermission-post-404": 'Unable to add the permission, please try again later.',
        "addPermission-post-409": 'Unable to add the permission probably due to same permission name exists, please check the input and try again.',
        "addPermission-post-500": 'Unable to add the permission due to internal error, please try again later.',

        "getUserPermissionById-get-400": "Unable to retrieve the permission details, please try again later.",
        "getUserPermissionById-get-401": "Unable to retrieve the permission details, please verify your access and try again.",
        "getUserPermissionById-get-403": "Unable to retrieve the permission details, please verify your access and try again.",
        "getUserPermissionById-get-404": "Unable to retrieve the permission details, please try again later.",
        "getUserPermissionById-get-500": "Unable to retrieve the permission details due to internal error, please try again later.",

        "deletePermission-delete-200": 'Permission successfully deleted.',
        "deletePermission-delete-400": 'Unable to delete the permission, please try again later.',
        "deletePermission-delete-401": 'Unable to delete the permission, please verify your access and try again.',
        "deletePermission-delete-403": 'Unable to delete the permission, please verify your access and try again.',
        "deletePermission-delete-404": 'Unable to delete the permission, please try again later.',
        "deletePermission-delete-409": 'Unable to delete the permission, please try again later.',
        "deletePermission-delete-500": 'Unable to delete the permission due to internal error, please try again later.',

        "updateUserPermissions-put-200": "Permission successfully updated.",
        "updateUserPermissions-put-400": "Unable to update the Permission, please check the inputs and try again.",
        "updateUserPermissions-put-401": "Unable to update the Permission, please verify your access and try again.",
        "updateUserPermissions-put-403": "Unable to update the Permission, please verify your access and try again.",
        "updateUserPermissions-put-404": "Unable to update the Permission, please try again later.",
        "updateUserPermissions-put-409": "Unable to add the permission probably due to same permission name exists, please check the input and try again.",
        "updateUserPermissions-put-500": "Unable to update the Permission due to internal error, please try again later.",

        "updateUserScopes-put-200": "User scopes successfully updated.",
        "updateUserScopes-put-400": "Unable to update the User scopes, please check the inputs and try again.",
        "updateUserScopes-put-401": "Unable to update the User scopes, please verify your access and try again.",
        "updateUserScopes-put-403": "Unable to update the User scopes, please verify your access and try again.",
        "updateUserScopes-put-404": "Unable to update the User scopes, please try again later.",
        "updateUserScopes-put-500": "Unable to update the User scopes due to internal error, please try again later.",

        //// user and userlist
        "getAll-get-400": "Unable to retrieve the User(s), please try again later.",
        "getAll-get-401": "Unable to retrieve the User(s), please verify your access and try again.",
        "getAll-get-403": "Unable to retrieve the User(s), please verify your access and try again.",
        "getAll-get-404": "Unable to retrieve the User(s), please try again later.",
        "getAll-get-500": "Unable to retrieve the User(s) due to internal error, please try again later.",

        "getById-get-400": "Unable to retrieve the User details, please try again later.",
        "getById-get-401": "Unable to retrieve the User details, please verify your access and try again.",
        "getById-get-403": "Unable to retrieve the User details, please verify your access and try again.",
        "getById-get-404": "Unable to retrieve the User details, please try again later.",
        "getById-get-500": "Unable to retrieve the User details due to internal error, please try again later.",

        "updateUserRoles-put-200": "User roles updated sucessfully ",
        "updateUserRoles-put-400": "Unable to update user's roles, please try again later.",
        "updateUserRoles-put-401": "Unable to update user's roles, please verify your access and try again.",
        "updateUserRoles-put-403": "Unable to update user's roles, please verify your access and try again.",
        "updateUserRoles-put-404": "Unable to update user's roles, please try again later.",
        "updateUserRoles-put-500": "Unable to update user's roles due to internal error, please try again later.",
    }
}
