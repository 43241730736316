/***
 *
 * Controller class for user.
 * @file ControllerRangeSlider.js
 * @description ControllerRangeSlider component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect, useState } from 'react';
import { useStyles } from './ControllerRangeSlider.style.js';
import './ControllerRangeSlider.scss';
import { Box, Slider } from '@mui/material';
import CustomTypography from '../../CustomTypography/index.js';
import TempGreen from '../../../assets/icons/temperature-2.svg'
import ImageWrapper from '../../ImageWrapper/index.js';

const ControllerRangeSlider = ({
    prefix = "", suffix = "", minDistance = null,
    customsx = { icon: {}, label: {}, value: {}, compo: {} },
    params = { icon: true, label: true, value: true }, value = [0, 0], name = "slider", icon, label,
    disabled = false, setterFunction = () => { }, data
}) => {
    const classes = useStyles();
    const [valueSlider, setValueSlider] = useState(value || data?.value);

    useEffect(() => {
        if (value[0] > 0 && value[1] > 0) {
            setValueSlider(value);
        }
    }, [value])

    const handleSliderChange = (event, newValue, activeThumb) => {
        const key = event.target.name;
        if (key === name) {
            setValueSlider(newValue);
            setterFunction(event, newValue);
            if (minDistance && minDistance !== 0) {
                if (activeThumb === 0) {
                    setValueSlider([Math.min(newValue[0], valueSlider[1] - minDistance), valueSlider[1]]);
                    setterFunction(event, [Math.min(newValue[0], valueSlider[1] - minDistance), valueSlider[1]]);
                } else {
                    setValueSlider([valueSlider[0], Math.max(newValue[1], valueSlider[0] + minDistance)]);
                    setterFunction(event, [valueSlider[0], Math.max(newValue[1], valueSlider[0] + minDistance)])
                }
            }
        }


    };

    return (
        <Box className={classes?.ControllerRangeSlider + " ControllerRangeSlider"} >
            <Box className="labels" >
                {params.icon && <ImageWrapper className="range-slider-icon" style={{ paddingRight: '10px', ...customsx.icon }} src={icon || TempGreen} />}
                {params.label && <CustomTypography className="range-slider-label" styles={{ ...customsx.label }} content={label || 'range slider'} color="#000" weight={600} size={12} lineHeight={22} />}
            </Box>
            <Box sx={{ width: "100%" }}>
                <Box className="controls">
                    {params.value && <CustomTypography className="range-slider-value" styles={{ marginRight: "8px", ...customsx.value }} content={`${prefix}${valueSlider.join(`${suffix} - `)}${suffix}`} weight={600} size={12} lineHeight={19} color={'#0F8D48'} />}
                    <Slider
                        name={name}
                        value={valueSlider}
                        onChange={handleSliderChange}
                        aria-labelledby="intensity-slider"
                        max={data?.max}
                        min={data?.min}
                        disabled={disabled}
                        disableSwap
                        className={classes?.Slider || ""}
                        sx={{ width: "100%", padding: "7px 0px !important", minWidth: "70px", maxWidth: "400px", ...customsx.compo }}
                    />
                </Box>
            </Box>
        </Box>
    )
};

ControllerRangeSlider.propTypes = {};

ControllerRangeSlider.defaultProps = {};

export default ControllerRangeSlider;
