import { API_URL } from '../config/appConfig'
import { API } from '.'
import { handleResponse } from '../helpers';

const getAllRoles = (portal = "mss") => {
    const url = portal === 'tms'? `${API_URL.permission}/system/permission`:`${API_URL.permission}/permission`
    return API.get(url, "getAllRoles", {}).then((res) => handleResponse(res, true));
}

const getRole = (name, portal = "mss") => {
    const url = portal === 'tms'? `${API_URL.permission}/system/permission/${name}`:`${API_URL.permission}/permission/${name}`
    return API.get(url, "getRole", {}).then((res) => handleResponse(res, true));
}

const addRole = (payload, portal = "mss")=> {
    const url = portal === 'tms'? `${API_URL.permission}/system/permission`:`${API_URL.permission}/permission`
    return API.post(url, "addRole", payload).then((res) => handleResponse(res, true));
}
const updateRole=(name, payload, portal = "mss")=>{
    const url = portal === 'tms'? `${API_URL.permission}/system/permission/${name}`:`${API_URL.permission}/permission/${name}`
    return API.put(url, "editRole", payload).then((res) => handleResponse(res, true));
}
const deleteRole=(permission, portal = "mss")=>{
    const url = portal === 'tms'? `${API_URL.permission}/system/permission/${permission}`:`${API_URL.permission}/permission/${permission}`
    return API.delete(url, "deleteRole").then((res) => handleResponse(res, true));
}
export const roleService = {
    getAllRoles,
    getRole,
    addRole,
    updateRole,
    deleteRole
};