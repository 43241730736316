export const sceneTypes = {
    GET_SCENES_REQUEST: 'SCENE_GET_REQUEST',
    GET_SCENES_SUCCESS: 'SCENE_GET_SUCCESS',
    GET_SCENES_FAILURE: 'SCENE_GET_FAILURE',

    GET_ALL_SCENE_BY_SPACE_REQUEST: 'GET_ALL_SCENE_BY_SPACE_REQUEST',
    GET_ALL_SCENE_BY_SPACE_SUCCESS: 'GET_ALL_SCENE_BY_SPACE_SUCCESS',
    GET_ALL_SCENE_BY_SPACE_FAILURE: 'GET_ALL_SCENE_BY_SPACE_FAILURE',

    GET_SCENE_DETAILS_REQUEST: 'GET_SCENE_DETAILS_REQUEST',
    GET_SCENE_DETAILS_SUCCESS: 'GET_SCENE_DETAILS_SUCCESS',
    GET_SCENE_DETAILS_FAILURE: 'GET_SCENE_DETAILS_FAILURE',

    ADD_REQUEST: 'SCENE_ADD_REQUEST',
    ADD_SUCCESS: 'SCENE_ADD_SUCCESS',
    ADD_FAILURE: 'SCENE_ADD_FAILURE',

    UPDATE_REQUEST: 'SCENE_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'SCENE_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'SCENE_UPDATE_FAILURE',

    DELETE_REQUEST: 'SCENE_DELETE_REQUEST',
    DELETE_SUCCESS: 'SCENE_DELETE_SUCCESS',
    DELETE_FAILURE: 'SCENE_DELETE_FAILURE',

    SCENE_SYNC_REQUEST: 'SCENE_SYNC_REQUEST',
    SCENE_SYNC_SUCCESS: 'SCENE_SYNC_SUCCESS',
    SCENE_SYNC_FAILURE: 'SCENE_SYNC_FAILURE',

    SCENE_APPLY_REQUEST: 'SCENE_APPLY_REQUEST',
    SCENE_APPLY_SUCCESS: 'SCENE_APPLY_SUCCESS',
    SCENE_APPLY_FAILURE: 'SCENE_APPLY_FAILURE',

    //For Scene Sync By Controller Id
    SYNC_SCENES_BY_DEVICE_REQUEST: 'SYNC_SCENES_BY_DEVICE_REQUEST',
    SYNC_SCENES_BY_DEVICE_SUCCESS: 'SYNC_SCENES_BY_DEVICE_SUCCESS',
    SYNC_SCENES_BY_DEVICE_FAILURE: 'SYNC_SCENES_BY_DEVICE_FAILURE',

    DELETE_SCENES_BY_DEVICE_REQUEST:"DELETE_SCENES_BY_DEVICE_REQUEST",
    DELETE_SCENES_BY_DEVICE_SUCCESS:"DELETE_SCENES_BY_DEVICE_SUCCESS",
    DELETE_SCENES_BY_DEVICE_FAILURE:"DELETE_SCENES_BY_DEVICE_FAILURE",

    UPDATE_SCENE_STATUS: "UPDATE_SCENE_STATUS",
    UPDATE_DELETE_SCENE_STATUS: "UPDATE_DELETE_SCENE_STATUS",

    GET_MULTIPLE_SCENES_REQUEST: 'GET_MULTIPLE_SCENES_REQUEST',
    GET_MULTIPLE_SCENES_SUCCESS: 'GET_MULTIPLE_SCENES_SUCCESS',
    GET_MULTIPLE_SCENES_FAILURE: 'GET_MULTIPLE_SCENES_FAILURE',
}