import React, { useEffect } from 'react';
import DataNotFound from '../DataNotFound';
// import PropTypes from 'prop-types';
import './NotFoundPage.scss';
import noPageFound from '../../../src/assets/icons/nopagefound.svg';
import { history, setSessionItem } from '../../helpers';

const NotFoundPage = () => {
  useEffect(()=>{
    setSessionItem("hideDataNotFound", false);
  }, [])
  return (
    <div className="NotFoundPage" data-testid="NotFoundPage">
      {/* <pre>{JSON.stringify(history, null, 2)}</pre> */}
      <DataNotFound 
        data={{
          image: noPageFound,
          label: 'Page not Found',
          content: "Uh oh, we can’t seem to find the page you’re looking for. Trying going back to the previous page",
          action: [
            {
              id: "1001",
              type: "button",
              label: "Go to home page",
              onClick: (event, data) => { history.replace("/"); },
            }
          ]
        }}
        style={{height: "100vh"}}
      />
    </div>);
}

NotFoundPage.propTypes = {};

NotFoundPage.defaultProps = {};

export default NotFoundPage;
