/***
 *
 * Controller class for user.
 * @file DragAndDropColumnsWrapper.js
 * @description DragAndDropColumnsWrapper component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './DragAndDropColumnsWrapper.style.js';
import './DragAndDropColumnsWrapper.scss';
import { Box, Grid } from '@mui/material';
import { onDragEnd } from '../../helpers/dragAndDrop.helper.js';
import { DragDropContext } from 'react-beautiful-dnd';
import DraggableColumn from '../DraggableColumn/index.js';
import DroppableColumn from '../DroppableColumn/index.js';
import { useDispatch, useSelector } from 'react-redux';

//Note: dragList and dropList should have id key (whose value can be deviceId, groupId, etc according to the case), type key (which will have the type of device or group) and icon key (which will have icon component corresponding to the type of object)
const DragAndDropColumnsWrapper = ({ forList = 'Devices', typeCreated = 'Groups', loading = false, ...props }) => {
  const classes = useStyles()
  const dispatch = useDispatch();

  const dragList = useSelector(state => state?.dragAndDrops?.dragList);
  const dropList = useSelector(state => state?.dragAndDrops?.dropList);

  const [checkedItems, setCheckedItems] = useState([]);

  return (
    <Box className={classes?.DragAndDropColumnsWrapper + " DragAndDropColumnsWrapper" || ""} data-testid="DragAndDropColumnsWrapper">
      <DragDropContext onDragEnd={(result) => { onDragEnd(result, dragList, dropList, checkedItems, setCheckedItems, dispatch) }}>
        <Grid item container sx={{ height: "100%" }} xs={12} spacing={1}>
          <Grid item xs={12} sm={6} md={6}>
            <DraggableColumn forList={forList} checkedItems={checkedItems} setCheckedItems={setCheckedItems} loading={loading} />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <DroppableColumn label={props?.dropColumnLabel} forList={forList} typeCreated={typeCreated} />
          </Grid>
        </Grid>
      </DragDropContext>
    </Box>
  )
};

DragAndDropColumnsWrapper.propTypes = {};

DragAndDropColumnsWrapper.defaultProps = {};

export default DragAndDropColumnsWrapper;
