import { makeStyles } from '@mui/styles';
// import ControllerRangeSlider from '.';

export const useStyles = makeStyles(theme => ({
    Slider: {
        width: "100%",
        "& .css-14pt78w-MuiSlider-rail": {
            background: 'grey',
            height: '3px'
        },
        "& .css-1gv0vcd-MuiSlider-track": {
            height: '4px',
            border: 'none',

        },
        '& .MuiSlider-thumb': {
            height: '12px',
            width: '12px'
        }
    },
    ControllerRangeSlider: {
        display: "flex",
        // [theme?.breakpoints?.down('lg')]: {
        //     flexWrap: "wrap"
        // },
        // flexWrap:"wrap",
        "& .labels": {
            width: "100%",
            maxWidth: "max-content",
            display: "flex",
            height: "100%",
            alignItems: "center",
            minWidth: "max-content"
        },
        "& .controls": {
            display: "flex",
            alignItems: "center",
            height: "100%",
            width: "100%",

        }
    }
}));
