import { tenantTypes } from '../types';

let initialState = { 
    loading: false,
    list: [],
    tenantDetails: null,
    error: null,
    tenantWebConfig:null
};

export function tenants(state = initialState, action) {
    switch (action.type) {
        case tenantTypes.GET_TENANT_REQUEST:
        case tenantTypes.GET_TENANT_BY_NAME_REQUEST:
        case tenantTypes.GET_TENANT_WEBCONFIG_REQUEST:
        case tenantTypes.GET_ALL_TENANT_REQUEST:
        case tenantTypes.CREATE_TENANT_REQUEST:
        case tenantTypes.UPDATE_TENANT_REQUEST :
        case tenantTypes.DELETE_TENANT_REQUEST:
        case tenantTypes.UPDATE_TENANT_WEBCONFIG_REQUEST:
        case tenantTypes.CLEAR_TENANT_DETAILS_REQUEST:   
            return {
                ...state,
                loading: true
            };
        case tenantTypes.CREATE_TENANT_SUCCESS:
            return {
                ...state,
                loading:false,
                list: [...state.list, action?.payload],
                tenantDetails:action?.payload
            };
        case tenantTypes.UPDATE_TENANT_SUCCESS:
            return {
                ...state,
                loading:false,
                list: state.list.map(item=>item?.tenantName === action.payload?.tenantName ? action.payload : item),
                tenantDetails:action?.payload
            };
        case tenantTypes.UPDATE_TENANT_WEBCONFIG_SUCCESS:
            return {
                ...state,
                loading:false,
                tenantWebConfig:action?.payload            
            };
        case tenantTypes.GET_TENANT_WEBCONFIG_SUCCESS:
                return {
                    ...state,
                    loading:false,
                    tenantWebConfig:action?.payload            
            };
        case tenantTypes.GET_ALL_TENANT_SUCCESS:
            return {
                ...state,
                loading:false,
                list: action.payload,
                tenantDetails:null,
                tenantWebConfig:null
            };
        case tenantTypes.GET_TENANT_BY_NAME_SUCCESS:
            return {
                ...state,
                loading:false,
                tenantDetails:action?.payload,
                // list: state?.list && Array.isArray(state?.list) ? state?.list?.map(item=>item?.tenantName === action.payload?.tenantName ? action.payload : item) : [],

            };
        case tenantTypes.DELETE_TENANT_SUCCESS:
            return {
                ...state,
                list: state?.list && Array.isArray(state?.list) ? state?.list?.filter(item => item.tenantName !== action.payload) : [],
                loading: false
            };
        case tenantTypes.CLEAR_TENANT_DETAILS_SUCCESS:
            return {
                ...state,
                tenantDetails:null,
                tenantWebConfig:null
            }
        case tenantTypes.GET_TENANT_FAILURE:
        case tenantTypes.GET_ALL_TENANT_FAILURE:
        case tenantTypes.CREATE_TENANT_FAILURE:
        case tenantTypes.UPDATE_TENANT_FAILURE:
        case tenantTypes.DELETE_TENANT_FAILURE:
            return {
                ...state,
                error: action?.payload,
                loading: false
            };
        default:
            return state
    }
}