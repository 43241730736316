/***
 *
 * Controller class for user.
 * @file UserManagement.js
 * @description UserManagement component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect } from 'react';
import { history, isAuthorised } from '../../helpers';
// import PropTypes from 'prop-types';
import './UserManagement.scss';
import { features } from '../../constants';
import { useSelector } from 'react-redux';

const UserManagement = () => {
    const _portal = useSelector((state)=>state?.app?.portal);

    useEffect(()=>{
        if(isAuthorised(features?.user) || _portal === "tms") {
            history.replace("/user-management/users");
        }
        else {
            history.replace("/user-management/permissions");
        }
    }, []);

    return (
        <div className="UserManagement" data-testid="UserManagement">
          
        </div>
    )
};

UserManagement.propTypes = {};

UserManagement.defaultProps = {};

export default UserManagement;
