// https://sahil29.medium.com/writing-and-maintaining-api-layer-in-react-react-native-1929fc16f611
import axios from 'axios';
// import _ from 'lodash';
import { apiConstants } from '../constants';
import { userService } from './user.service';
import { getLocalItem, setSessionItem } from '../helpers';
import { appConnections } from '../config/appConfig';
import { closeMqttConnection } from './mqtt.service';
import { notificationService } from './notification.service';
// import { host } from '../config/appConfig';
// import { tenantName } from '../config/appConfig';
const qs = require('qs');

const _pendingRequests = {};
let accept = "";
// const auth = localStorage.getItem('miraie');
let CancelToken = axios.CancelToken;

const sanitizeParams = (params) => {
  return params;
}

const getDefaultOptions = (options) => {
  return options;
}
const abortPendingRequests = (key) => {
  if (_pendingRequests[key]) {
    _pendingRequests[key] = 'REQUEST_CANCELLED';
    _pendingRequests[key] = null;
  }
};

const isInvalidToken = (response) => {
  if (response.status !== apiConstants.UNAUTHORIZED) {
    return false;
  }

  const authHeader = response.headers.get('WWW-Authenticate') || '';
  return authHeader.includes('invalid_token');
};

const processResponse = (res) => {
  if (isInvalidToken(res)) {
    return { data: {} };
  }

  if (res.status === apiConstants.NO_CONTENT) {
    const response = Object.assign({}, res, { data: {} });
    return response;
  }
  return res;
};

// const handleResponse = (key, options, response, jsonResponse) => {
//   const jsonRes = _.isEmpty(jsonResponse) ? {} : jsonResponse;
//   const { status } = response;
//   const { errors } = Object.assign({}, jsonRes);
//   const resp = {
//     status,
//     body: jsonResponse,
//     errors,
//     headers: response.headers,
//   };

//   // use any dispatchers/method to communicate this data to the store/view
//   // dispatch(key, resp)
// };

const getHeaders = (accessToken, url = "", headerObj = {}) => {
  // console.log('headerobj===>', headerObj, accept);

  let headers = {
    Accept: accept,

  };

  if (!headerObj['Content-Type'] && !headerObj['multipart/form-data']) {
    headers['Content-Type'] = 'application/json';
  }

  // headerObj['tenantId'] = localStorage.getItem('tenant') || tenantName;
  if ((!headerObj?.hasOwnProperty("tenantId")) && JSON?.parse(localStorage.getItem('appConfig'))?.portal === "mss") {
    headerObj['tenantId'] = localStorage.getItem('tenant');
  }
  delete headerObj['multipart/form-data'];

  headers = {
    ...headers,
    ...headerObj
  }

  // console.log("api.service.js======", headers, headerObj)

  if (accessToken && headers['Authorization']) { headers['Authorization'] = `Bearer ${accessToken}`; }

  if (!headers['Authorization']) { headers['Authorization'] = `Bearer ${accessToken}`; }

  return headers;
}

const makeRequest = async (path, key = "api_call", reqInit, options = {}) => {
  setSessionItem("hideDataNotFound", true)
  abortPendingRequests(key);
  const accessToken = JSON.parse(localStorage.getItem('miraie'))?.accessToken;
  const headers = getHeaders(accessToken, path, options?.headers);
  const option = getDefaultOptions(options);
  const init = Object.assign({}, reqInit, { headers });
  // console.log('make request',path, key, reqInit, init, options, option, auth);
  // console.log('access token--------',accessToken);
  return await axios({
    url: path,
    ...init,
    timeout: 30000,
    // withCredentials: NODE_ENV === 'production',
    cancelToken: new CancelToken(function executor(c) {
      _pendingRequests[key] = c;
    }),
  })
    .then(res => processResponse(res))
    .then(res => {
      // handleResponse(key, option, res, res.data);
      // console.log('res on request111111111===>',res);
      return res;
    })
    .catch((err) => {
      // console.log('Errorr on request===>', err, err?.response);
      // error handling logic
      // alert(err.message)
      // alert(err.status)
      // sessionStorage.setItem("miraieError", JSON.stringify(err));

      // console.log("errr -- ", err?.response?.status)
      if (!["subscribeNotification", 'unsubscribeNotification', "clientId", "webConfig", "preLogin", "login"].includes(key) && err?.response?.status === 401) {
        //logging out
        Promise?.resolve()?.then(() => {
          appConnections?.mqtt && JSON?.parse(localStorage.getItem('appConfig'))?.portal === "mss" && closeMqttConnection();
        })?.then(() => {
          appConnections?.notifications && JSON?.parse(localStorage.getItem('appConfig'))?.portal === "mss" && notificationService?.unsubscribeNotification({ token: JSON.parse(getLocalItem("miraie"))?.notificationToken?.token })
        })?.then(() => {
          userService.logout();
        })
      } else if (err && !err.response) {
        const er = {
          response: {
            error: err,
            status: 503
          }
        }
        err = er;
      } else {
        err.response["error"] = err?.response?.data;
        delete err?.response?.data;
      }
      setSessionItem("hideDataNotFound", false)
      // console.log('Errorr on request 2 ===>', err, err?.response);
      return err?.response;
    });
}

const getParams = (queryParams = {}) => {
  return queryParams;
}

const get = async (path, key, queryParams, body = {}, options = {}) => {
  const getData = {
    method: 'GET',
    params: getParams(queryParams),
    paramsSerializer: (params) => {
      return qs.stringify(sanitizeParams(params), { arrayFormat: 'brackets' });
    },
    data: { ...body }
  };
  return await makeRequest(path, key, getData, options)
}

const post = async (path, key, body, options = {}) => {

  // console.log('body came===>', body);
  const postData = {
    method: 'POST',
    data: { ...body },
    ...options,
    params: getParams(),
    paramsSerializer: (params) => {
      return qs.stringify(sanitizeParams(params), { arrayFormat: 'brackets' });
    }
  };

  return await makeRequest(path, key, postData, options);
}

const updateAssetConfig = async (path, key, body, options = {}) => {
  const postData = {
    method: 'POST',
    data: body,
    ...options,
    params: getParams(),
    paramsSerializer: (params) => {
      return qs.stringify(sanitizeParams(params), { arrayFormat: 'brackets' });
    }
  };

  return await makeRequest(path, key, postData, options);
}

const put = async (path, key, body, options = {}) => {
  const putData = {
    method: 'PUT',
    data: body,
    params: getParams(),
    paramsSerializer: (params) => {
      return qs.stringify(sanitizeParams(params), { arrayFormat: 'brackets' });
    }
  };
  return await makeRequest(path, key, putData, options);
}

const patch = async (path, key, body, options = {}) => {
  const patchData = {
    method: 'PATCH',
    data: body,
    params: getParams(),
    paramsSerializer: (params) => {
      return qs.stringify(sanitizeParams(params), { arrayFormat: 'brackets' });
    }
  };
  return await makeRequest(path, key, patchData, options);
}

const asset = async (path, key, body, options = {}, method = 'POST') => {
  const patchData = {
    method: method,
    data: body,
    params: getParams(),
    ...options,
    paramsSerializer: (params) => {
      return qs.stringify(sanitizeParams(params), { arrayFormat: 'brackets' });
    }
  };
  return await makeRequest(path, key, patchData, options);
  // return await (async () => {
  //   return await axios({
  //     url: path,
  //     ...patchData,
  //     timeout: 30000,
  //     // withCredentials: NODE_ENV === 'production',
  //     cancelToken: new CancelToken(function executor(c) {
  //       _pendingRequests[key] = c;
  //     }),
  //   })
  //     .then(res => processResponse(res))
  //     .then(res => {
  //       // handleResponse(key, option, res, res.data);
  //       // console.log('res on request===>',res);

  //       return res;
  //     })
  //     .catch((err) => {
  //       // console.log('Errorr on request===>', err, err.response);
  //       // error handling logic
  //       // alert(err.message)
  //       // alert(err.status)
  //       // sessionStorage.setItem("miraieError", JSON.stringify(err));
  //       if (err && !err.response) {
  //         const er = {
  //           response: {
  //             error: err,
  //             status: 503
  //           }
  //         }
  //         err = er;
  //       } else {
  //         err.response["error"] = err?.response?.data;
  //         delete err?.response?.data;
  //       }

  //       // console.log('Errorr on request 2 ===>', err, err.response);
  //       return err?.response;
  //     });
  //   //  .catch((err) => { 
  //   //    console.log('axios call error====>',err);

  //   //    if(err && !err.response) {
  //   //      const er = {
  //   //        response: {
  //   //          error: err,
  //   //          status: 503
  //   //        }
  //   //      }
  //   //      err = er;
  //   //    } else {
  //   //      err.response["error"] = err?.response?.data;
  //   //      delete err?.response?.data; 
  //   //    }    
  //   //    return err?.response;
  //   //  });
  // })();
}

const _delete = async (path, key, body, options = {}) => {

  const deleteData = {
    method: 'DELETE',
    params: getParams(),
    paramsSerializer: (params) => {
      return qs.stringify(sanitizeParams(params), { arrayFormat: 'brackets' });
    }
  };
  return await makeRequest(path, key, deleteData, options);
}

export const API = {
  get,
  post,
  put,
  patch,
  delete: _delete,
  asset,
  updateAssetConfig
}
