/***
 *
 * Controller class for user.
 * @file StyledBreadcrumbs.js
 * @description StyledBreadcrumbs component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React from 'react';
// import PropTypes from 'prop-types';
// import { useStyles } from './StyledBreadcrumbs.style.js';
import './StyledBreadcrumbs.scss';
import { Breadcrumbs, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const StyledBreadcrumbs = ({data=[], fontSize, onClick=() => {}, ...props}) => {

  return (
    Array.isArray(data) && 
    <Breadcrumbs className="StyledBreadcrumbs" data-testid="StyledBreadcrumbs" aria-label="breadcrumb" {...props} sx={{      
      ...props?.sx,
      '& li': {
        fontSize: (fontSize / 16) + 'rem',
        lineHeight: fontSize + 2 + 'px',
      }
    }}>
      {data && Array.isArray(data) && data?.filter(d=>d?.label !== "")?.map((d, index)=>{
        return ( 
          index < data?.filter(d=>d?.label !== "")?.length - 1 ? (
              <Link 
                key={"breadcrumb-" + d?.id || index}
                underline="hover" 
                color="inherit" 
                to={d?.url || "/"}
                onClick={(e)=>{ onClick(e, index, d) }}
                disabled={d?.disabled}
                style={{fontSize: (fontSize / 16) + 'rem'}}
              >
                {d?.label}
              </Link>
            ) : (
              <Typography key={"breadcrumb-" + d?.id || index} color="text.primary" sx={{fontSize: (fontSize / 16) + 'rem'}}>{d?.label}</Typography>
            )
        )
      })}
    </Breadcrumbs>
  )
};

StyledBreadcrumbs.propTypes = {};

StyledBreadcrumbs.defaultProps = {};

export default StyledBreadcrumbs;
