export const style = ({
    arrow: {
        position: 'absolute',
        fontSize: '0.4375rem',
        width: '0px',
        height: '0px',

        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid',
        }
    },
    paper: {
        // background: '#FFFFFF',
        // border: '1px solid #D9D9D9',
        // boxShadow: '0px 8px 60px rgba(0, 0, 0, 0.2), 0px 4px 24px rgba(0, 0, 0, 0.08)',
        boxShadow: '0px 0px 40px 0px rgb(0 0 0 / 20%), 0px 0px 8px 0px rgb(0 0 0 / 10%), 0px 0px 10px 0px rgb(0 0 0 / 0%)',
        borderRadius: '10px',
        py: 1.5,
        px: '15px'
    }
})
