import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    Footer: {
        "['@media (min-width:600px)']": {
            minHeight: "30px"
        },
        padding: "0px 15px 0px 0px",
        minHeight: "30px",        
    },
    vBar: {
        height: "20px",
        borderRight: "1px solid #00000099",
        marginLeft: "10px",
        marginRight: "10px"
    },
    copyRight: {
        fontSize: '0.75rem',
        color: "#777777"
    },
    poweredBy: {
        fontSize: '0.75rem',
        color: "#999",
        '& .logo': {
            padding: "0px"
        },
        "& .imageLogo": {
            width: "70px"
        },
        '& .title': {
            alignItems: "end",
            lineHeight: "1rem",
            fontSize: '1rem',
        }
    }
});
