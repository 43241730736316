import { tenantService } from '../../services';
import { request, success, failure, getMessage } from '../../helpers';
import { delay } from '../../services';
import { appActions } from './app.actions';
import { tenantTypes } from '../types/tenant.type';

const getAllTenants = () => {
    return dispatch => {
        dispatch(request(tenantTypes.GET_ALL_TENANT_REQUEST));
        tenantService.getAllTenants().then(
                ({data:tenants,status}) => {
                    dispatch(success(tenantTypes.GET_ALL_TENANT_SUCCESS, { payload: tenants }))
                    // getMessage(`tenant-get-${status}`).then(delay(500)).then((msg)=>{
                    //     dispatch(appActions.apiSuccessMessage(msg))})
            },
            ({error,code}) => {dispatch(failure(tenantTypes.GET_ALL_FAILURE, error.toString()));
                getMessage(`tenant-get-${code}`).then(delay(500)).then((err) => {
                    dispatch(appActions.apiErrorMessage(err));});}
            )}
}

const getTenantById = (tenantId) => {
    return dispatch => {
        dispatch(request(tenantTypes.GET_TENANT_REQUEST));
        tenantService.getTenantById(tenantId).then(
            ({data:tenant,status}) => {
                dispatch(success(tenantTypes.GET_TENANNT_SUCCESS, {payload:tenant}))
                // getMessage(`tenantById-get-${status}`).then(delay(500)).then((msg)=>{
                // dispatch(appActions.apiSuccessMessage(msg))})
            },
            ({error,code}) => { dispatch(failure(tenantTypes.GET_TENANT_FAILURE, error.toString()));
            getMessage(`tenantById-get-${code}`).then(delay(500)).then((err) => {
                dispatch(appActions.apiErrorMessage(err))
            }) })
        }
}

const getTenantByName = (tenantName) => {
    return dispatch => {
        dispatch(request(tenantTypes.GET_TENANT_BY_NAME_REQUEST));
        tenantService.getTenantByName(tenantName).then(
            ({data:tenant,status}) => {
                dispatch(success(tenantTypes.GET_TENANT_BY_NAME_SUCCESS, {payload:tenant}))
                // getMessage(`tenantByName-get-${status}`).then(delay(500)).then((msg)=>{
                // dispatch(appActions.apiSuccessMessage(msg))})
            },
            ({error,code}) => { dispatch(failure(tenantTypes.GET_TENANT_BY_NAME_FAILURE, error.toString()));
            getMessage(`tenantByName-get-${code}`).then(delay(500)).then((err) => {
                dispatch(appActions.apiErrorMessage(err))
            }) })
        }
}

const getTenantWebconfig = (tenantName) => {
    return dispatch => {
        dispatch(request(tenantTypes.GET_TENANT_WEBCONFIG_REQUEST));
        tenantService.getTenantWebconfig(tenantName).then(
            ({data:tenant,status}) => {
                dispatch(success(tenantTypes.GET_TENANT_WEBCONFIG_SUCCESS, {payload:tenant}))
                // getMessage(`getTenantWebconfig-get-${status}`).then(delay(500)).then((msg)=>{
                // dispatch(appActions.apiSuccessMessage(msg))})
            },
            ({error,code}) => { dispatch(failure(tenantTypes.GET_TENANT_WEBCONFIG_FAILURE, error.toString()));
            getMessage(`getTenantWebconfig-get-${code}`).then(delay(500)).then((err) => {
                dispatch(appActions.apiErrorMessage(err))
            }) })
        }
}

export const createTenant = (payload, cb=()=>{}) => {
    return dispatch => {
        dispatch(request(tenantTypes.CREATE_TENANT_REQUEST));
        tenantService.createTenant(payload)
            .then(
                ({data:tenant, status}) => {
                    dispatch(success(tenantTypes.CREATE_TENANT_SUCCESS, {payload: tenant}));
                    cb();
                    getMessage(`createTenant-post-${status}`).then(delay(500)).then((msg)=>{
                        dispatch(appActions.apiSuccessMessage(msg))})
                },
                ({error,code}) => {
                    dispatch(failure(tenantTypes.CREATE_TENANT_FAILURE, error.toString()));
                    getMessage(`createTenant-post-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err))
                    })
                }
            );
    };
};

export const updateTenant = (id,tenant,cb=()=>{}) => {
    return dispatch => {
        dispatch(request(tenantTypes.UPDATE_TENANT_REQUEST));

        tenantService.updateTenant(id, tenant)
            .then(
                ({data:tenant,status}) => { 
                    dispatch(success(tenantTypes.UPDATE_TENANT_SUCCESS, {paylaod: tenant}));
                    cb();
                    getMessage(`updateTenant-put-${status}`).then(delay(500)).then((msg)=>{
                        dispatch(appActions.apiSuccessMessage(msg))})
                },
                ({error,code}) => {
                    dispatch(failure(tenantTypes.UPDATE_TENANT_FAILURE, error.toString()));
                    getMessage(`updateTenant-put-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err))
                    })
                }
            );
    };
};

const updateTenantWebConfig = (tenantName,payload)=>{
    return dispatch => {
        dispatch(request(tenantTypes.UPDATE_TENANT_WEBCONFIG_REQUEST));

        tenantService.updateTenantWebConfig(tenantName, payload)
            .then(
                ({data:tenant,status}) => { 
                    dispatch(success(tenantTypes.UPDATE_TENANT_WEBCONFIG_SUCCESS, {paylaod: tenant}));
                    // getMessage(`updateTenantWebconfig-post-${status}`).then(delay(500)).then((msg)=>{
                    //     dispatch(appActions.apiSuccessMessage(msg))})
                },
                ({error,code}) => {
                    dispatch(failure(tenantTypes.UPDATE_TENANT_WEBCONFIG_FAILURE, error.toString()));
                    getMessage(`updateTenantWebconfig-put-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err))
                    })
                }
            );
    };
}

// prefixed function name with underscore because delete is a reserved word in javascript
const deleteTenant = (tenantName) => {
    return dispatch => {
        dispatch(request(tenantTypes.DELETE_TENANT_REQUEST));
        tenantService.deleteTenant(tenantName)
            .then(
                ({data:tenant,status}) => {dispatch(success(tenantTypes.DELETE_TENANT_SUCCESS, { tenantName }))
                getMessage(`deleteTenant-delete-${status}`).then(delay(500)).then((msg)=>{
                    dispatch(appActions.apiSuccessMessage(msg))})},
                    ({error,code}) =>{
                        dispatch(failure(tenantTypes.DELETE_TENANT_FAILURE, error.toString()));
                        getMessage(`deleteTenant-delete-${code}`).then(delay(500)).then((err) => {
                          dispatch(appActions.apiErrorMessage(err));
                      })
                      }
            );
    };
}

const enableTenant = (tenantName, cb=()=>{})=>{
    return dispatch => {
        dispatch(request(tenantTypes.ENABLE_TENANT_REQUEST));

        tenantService.enableTenant(tenantName)
            .then(
                ({data:tenant,status}) => { 
                    // dispatch(success(tenantTypes.ENABLE_TENANT_SUCCESS, {payload: tenantName}));
                    getMessage(`enableTenant-put-${status}`).then(delay(500)).then((msg)=>{
                        dispatch(appActions.apiSuccessMessage(msg))})
                    cb()
                },
                ({error,code}) => {
                    dispatch(failure(tenantTypes.ENABLE_TENANT_FAILURE, error.toString()));
                    getMessage(`enableTenant-put-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err))
                    })
                }
            );
    };
}

const disableTenant = (tenantName, cb=()=>{})=>{
    return dispatch => {
        dispatch(request(tenantTypes.DISABLE_TENANT_REQUEST));
        tenantService.disableTenant(tenantName)
            .then(
                ({data:tenant,status}) => { 
                    dispatch(success(tenantTypes.DISABLE_TENANT_SUCCESS, {payload: tenantName}));
                    getMessage(`disableTenant-put-${status}`).then(delay(500)).then((msg)=>{
                        dispatch(appActions.apiSuccessMessage(msg))})
                        cb()
                },
                ({error,code}) => {
                    dispatch(failure(tenantTypes.DISABLE_TENANT_FAILURE, error.toString()));
                    getMessage(`disableTenant-put-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err))
                    })
                }
            );
    };
}

const clearTenantDetails = ()=>{
    return dispatch => {
     dispatch(request(tenantTypes.CLEAR_TENANT_DETAILS_REQUEST))
     try{
        dispatch(success(tenantTypes.CLEAR_TENANT_DETAILS_SUCCESS,{}));  
     }
    catch{
        dispatch(failure(tenantTypes.CLEAR_TENANT_DETAILS_FAILURE,"Clearing tenant details failed"));
    }
    }
}


export const tenantActions = {
    createTenant,
    updateTenant,
    getAllTenants,
    getTenantById,
    getTenantByName,
    updateTenantWebConfig,
    getTenantWebconfig,
    deleteTenant,
    disableTenant,
    enableTenant,
    clearTenantDetails
};
