/***
 *
 * Controller class for user.
 * @file StyledPopupWrapper.js
 * @description StyledPopupWrapper component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './StyledPopupWrapper.style.js';
import './StyledPopupWrapper.scss';
import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import StyledSpinner from '../StyledSpinner/index.js';
import StyledButton from '../StyledButton/index.js';

const StyledPopupWrapper = ({children, open=false, scroll="paper", title=null, closeButton=false, icon=null, customsx={dialog:{}, box:{}}, onClose=()=>{}, ...props}) => {
  const classes = useStyles()
  const [openPopup, setOpenPopup] = useState(open);
  const [loading, setLoading] = useState(true);

  const closePopup = () => {
    setOpenPopup(false);
    setTimeout(() => {
      onClose(false);
    }, !isNaN(props?.closeTimeOut) ? props?.closeTimeOut : 2000);
  }
  useEffect(() => {
    setLoading(true);
    setOpenPopup(open);

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [open]);

    return (
        <Dialog 
          className={classes?.StyledPopupWrapper || ""} 
          data-testid="StyledPopupWrapper"
          open={openPopup}
          scroll={scroll}
          sx={{...customsx.dialog}}
          {...props}
        >
          {loading && 
            <Box sx={{ position: 'absolute', top: 0, bottom: 0, width: "100%", backgroundColor: "#f6faf8", zIndex: 1090, minHeight: "50px" }}>
              <StyledSpinner sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
            </Box>
          }

          {title && <DialogTitle className={classes?.title || ""}>{title}</DialogTitle>}
          {closeButton && <StyledButton iconButton sx={{position:'absolute',right:'0'}} onClick={() => closePopup()} className={classes?.closeicon || ""}><CloseIcon /></StyledButton>}
          <DialogContent dividers={scroll === 'paper'}>
            <Box sx={{ ...customsx.box}}>
              {children}
            </Box> 
          </DialogContent>
          
        </Dialog>
    )
};

StyledPopupWrapper.propTypes = {};

StyledPopupWrapper.defaultProps = {};

export default StyledPopupWrapper;
