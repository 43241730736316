import { dragAndDropTypes } from "../types";

let intialState = {
    dragList: [],
    dropList: [],
    selectedItems: [],
    errorMessage: '',
    error: null
}

export function dragAndDrops(state=intialState, action){
    switch(action.type){
        case dragAndDropTypes.SET_DRAG_LIST:
            return {
                ...state,
                dragList: action.payload
            };
        case dragAndDropTypes.SET_DROP_LIST:
            return {
                ...state,
                dropList: action.payload
            };
        case dragAndDropTypes.ADD_TO_DROP_LIST:
            return {
                ...state,
                dropList: [...state.dropList,...action.payload]
            };
        case dragAndDropTypes.DELETE_FROM_DROP_LIST:
            return {
                ...state,
                dropList: [...state.dropList.filter(item=>item?.id!==action.id)]
            };
        case dragAndDropTypes.SELECTED_ITEMS:
            return {
                ...state,
                selectedItems: action.payload
            }
        case dragAndDropTypes.CLEAR_DROP_LIST:
            return {
                ...state,
                dragList: [...state.dropList, ...state.dragList],
                dropList: []
            }
        case dragAndDropTypes.SET_ERROR_MESSAGE:
            return {
                ...state,
                errorMessage: action.message
            }
        case dragAndDropTypes.EMPTY_LISTS:
            return {
                ...state,
                dragList: [],
                dropList: []
            };
        default:
            return state;
    }
}