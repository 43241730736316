/***
 *
 * Controller class for user.
 * @file KonvaImage.js
 * @description KonvaImage component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect, useState } from 'react';
import { Circle, Image, Text } from 'react-konva';
import { Html } from 'react-konva-utils';
import useImage from 'use-image';
import { newHeight, newWidth } from '../../../services/util.service';
// import PropTypes from 'prop-types';
import './KonvaImage.scss';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useRef } from 'react';
import GreenLight from '../../../assets/icons/konva/greenBulb.svg'
import GreyLight from '../../../assets/icons/konva/greyBulb.svg'
import RedLight from '../../../assets/icons/konva/redBulb.svg'

import GreenSwitch from '../../../assets/icons/konva/greenSwitch.svg'
import GreySwitch from '../../../assets/icons/konva/greySwitch.svg'
import RedSwitch from '../../../assets/icons/konva/redSwitch.svg'

import GreenSensor from '../../../assets/icons/konva/greenSensor.svg'
import GreySensor from '../../../assets/icons/konva/greySensor.svg'
import RedSensor from '../../../assets/icons/konva/redSensor.svg'
import StyledSpinner from '../../StyledSpinner';

function getURL(image) {

  const imgUrl = {
    on:
    {
      light: GreenLight,
      sensor: GreenSensor,
      switch: GreenSwitch
    },
    off: {
      light: GreyLight,
      sensor: GreySensor,
      switch: GreySwitch
    },
    inactive:{
      light: RedLight,
      sensor: RedSensor,
      switch: RedSwitch
    }
  }
  let deviceType = '', src = '';
  if (!_.isEmpty(image?.deviceId)) {
    let status = image?.status || image?.deviceData?.status || 'off';
    if(status === 'active'){
      status = image?.deviceStatus?.status?.intensity > 0 ? "on" : "off";
    }


    
    if (image?.category && image?.category?.toLowerCase()?.search('lightdriver') !== -1) deviceType = "light";
    else if (image?.category && image?.category?.toLowerCase()?.search('sensor') !== -1) deviceType = "sensor";
    else if (image?.category && image?.category?.toLowerCase()?.search('switch') !== -1) deviceType = "switch";
    else deviceType ="light"
    src = imgUrl[status];
    if (deviceType && src) {
      return src[deviceType];
    }
  }
  else {
    return image?.src;
  }
}

const KonvaImage = (props) => {
  const _openModal = useSelector(state=>state?.canvas?.modalState);
  const _highlightGroup = useSelector(state => state?.canvas?.highlightgroup);
  const _deviceList = useSelector(state => state?.devices?.deviceList);
  const _selectedSpace = useSelector(state => state?.spaces?.selectedSpace);

  const [imgHit, setImgHit] = useState(null);
  const [image, setImage] = useState(null);
  const [imageProps, setImageProps] = useState(null);
  const [device, setDevice] = useState(props?.device || {});
  const [stroke, setStroke] = useState(true);
  const deviceRef = useRef();
  const borderRef = useRef();
  const crossref= useRef();


  useEffect(() => {
    let iProps = JSON.parse(JSON.stringify(props)) || {};
    if (iProps && iProps?.width && !iProps?.height) {
      iProps["height"] = newHeight(image?.height, image?.width, iProps?.width) || '20px';
      iProps['width'] = newWidth(image?.height, image?.width, iProps?.height) || '20px';
      iProps['name'] = 'image';
    }
    delete iProps?.image;
    setImageProps(iProps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image])

  useEffect(() => {
    setImgHit(props?.image);
  }, [props?.image])

  // for flickering; not needed now
  // useEffect(()=>{
  //   var period = 200;
  //   // gives flicker effect
  //   if(stroke && borderRef){
  //   var anim = new Konva.Animation(frame => {
  //     borderRef?.current?.opacity((Math.sin(frame.time / period) + 1) / 2);
  //   });
  //   anim.start();
  //   return(()=>{
  //     anim.stop();    
  //   })
  // }
  // },[stroke])

  useEffect(() => {
    if (imgHit) {    
      // console.log('hittttt',imgHit);
      if (!_.isEmpty(device)){
        setImage(getURL({ ...imgHit, ...device }));
      }
      else {
        setImage(getURL(imgHit));
      }
    }

  }, [device, imgHit])


  useEffect(() => {
    // console.log('device:::',device, deviceRef);
    if (device && deviceRef.current.attrs?.imageType === 'deviceImage') {
        deviceRef.current.show();
        deviceRef.current.moveToTop();
        crossref?.current?.moveToTop();
    }
    // deviceRef.current.show();
    // deviceRef.current.moveToTop();
  }, [device])


  useEffect(() => {
    // console.log('device obj::::',_deviceList, props?.device);
    if (props?.device) {
      // const deviceObj = _deviceList?.find(d => d?.deviceId === props?.device?.deviceId);
      setDevice({ ...props?.device });
    }
  }, [props?.device])


  useEffect(() => {
    if (_highlightGroup && props?.device) {
      let hgrp = _highlightGroup?.devices.filter(d => d?.deviceId === props?.device?.deviceId);
      setStroke(!_.isEmpty(hgrp));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_highlightGroup])



  return (
    <>    
    {
      props?.image?.shapeType === 'device' && stroke && !_openModal &&
      <Circle
      x={14}
      y={14}
      {...imageProps}
      ref={borderRef}
      radius={14}
      stroke={props?.image?.shapeType === 'device' && stroke  ? _highlightGroup?.color : 'transparent'}
      strokeWidth={7}
      />
    } 
    { _selectedSpace?.spaceId === device?.spaceId &&
     device?.deviceId && !_deviceList?.find(d=>d?.deviceId === device?.deviceId) &&
    <Text
      text='✕'
      x={5}
      y={5}
      ref={crossref}
      scale={2}
      stroke={'red'}
      strokeWidth={2}
      fontSize={20}  
      data-type="device"
      status={device?.status || device?.deviceData?.status}
      category={device?.category || device?.deviceData?.category}  
      {...imageProps}
      deviceId={device?.deviceId}
      name={'image-' + props.image?.spaceId}
      listening={props?.listening || true}  
    />

    }
    
      <Image
        className="KonvaImage"
        imageType={device?.deviceId ? 'deviceImage' : 'layoutImage'}
        data-type="device"
        status={device?.status || device?.deviceData?.status}
        category={device?.category || device?.deviceData?.category}
        image={useImage(image)[0]}
        {...imageProps}
        deviceId={device?.deviceId}
        name={'image-' + props.image?.spaceId}
        listening={props?.listening || true}
        ref={deviceRef}
      />
      <Html>  
        {props?.image?.shapeType === 'device' && device?.loading &&
          <StyledSpinner size={20} />
        }
      </Html>

    </>
  )
};

KonvaImage.propTypes = {};

KonvaImage.defaultProps = {};

export default KonvaImage;
