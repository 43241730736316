import _ from 'lodash';
import { groupTypes } from '../types';
let initialState = {
    loading: false,
    list: [],
    group: {},
    item: null,
    //deviceList: [],
    lastAction: null,
    groupDeleted: null,
    error: null,
    multipleGroupsList: [],
    groupCount: {}
}

export function groups(state = initialState, action) {
    switch (action.type) {
        case groupTypes.GET_GROUPS_REQUEST:
        case groupTypes.GET_GROUP_REQUEST:
        case groupTypes.ADD_REQUEST:
        case groupTypes.UPDATE_REQUEST:
        case groupTypes.SYNC_BY_DEVICE_REQUEST:
        case groupTypes.DELETE_GROUPS_BY_DEVICE_REQUEST:
        case groupTypes.UPDATE_GROUP_CONTROL_REQUEST:
        case groupTypes.GET_GROUP_COUNT_REQUEST:
        // case groupTypes.GET_GROUP_DEVICES_DETAILS_REQUEST:
        case groupTypes.GROUP_SYNC_REQUEST:
        case groupTypes.DELETE_REQUEST:
        case groupTypes.GET_MULTIPLE_GROUPS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case groupTypes.GET_GROUPS_SUCCESS:
            return {
                ...state,
                list: action.payload,
                loading: false,
                lastAction: "get_groups_by_space" + Date.now()

            }
        case groupTypes.GET_GROUP_SUCCESS:
            return {
                ...state,
                item: action.payload,
                group: action?.payload,
                list: _.isEmpty(state.list)
                    ? [action.payload]
                    : state?.list?.map((item) =>
                        item.groupId === action.payload.groupId ? action.payload : item
                    ),
                lastAction: "get_group" + Date.now(),
                loading: false
            }
        case groupTypes.UPDATE_GROUP_STATUS:
            return {
                ...state,
                list: state?.list?.map(g => {
                    if (g?.groupId === action?.payload?.grpId) {
                        g.synced = action?.payload?.syncStatus
                    }
                    return g;
                }),
                group: state?.group?.groupId === action?.payload?.grpId ? { ...state?.group, synced: action?.payload?.syncStatus } : state?.group,
                loading: false
            };
        case groupTypes?.UPDATE_GROUP_CONTROL_SUCCESS:
            return {
                ...state,
                loading: false,
                group: state?.group?.groupId === action?.payload?.groupId ? {
                    ...state?.group,
                    groupStatus: {
                        ...state?.group?.groupStatus,
                        status: {
                            ...state?.group?.groupStatus?.status,
                            ...action?.payload?.configuration,
                        }
                    }
                } : state?.group,
                lastAction: "update_group_control" + Date.now(),
            }
        case groupTypes.ADD_SUCCESS:
            state?.list?.push(action.payload)
            return {
                ...state,
                list: state?.list,
                item: action.payload,
                loading: false,
                lastAction: "add_group" + Date.now(),
            }
        case groupTypes.UPDATE_SUCCESS:
            return {
                ...state,
                list: state?.list?.map(g => { if (g.groupId === action.payload.groupId) { return action.payload; } else { return g; } }),
                item: action.payload,
                group: action?.payload,
                loading: false,
                lastAction: "update_group" + Date.now(),
            }
        case groupTypes?.UPDATE_DELETE_GROUP_STATUS:
            return {
                ...state,
                groupDeleted: action?.payload?.deleteGroupStatus
            }
        case groupTypes.DELETE_SUCCESS:
            return {
                ...state,
                list: state?.list?.filter(g => g.groupId !== action.payload),
                item: "",
                loading: false,
                lastAction: "delete_group" + Date.now(),
            }
        // case groupTypes.GET_GROUP_DEVICES_DETAILS_SUCCESS:
        //     return{
        //         ...state,
        //         deviceList: action.payload,
        //         loading: false
        // }
        case groupTypes.GROUP_SYNC_SUCCESS:
            return {
                ...state,
                loading: false,
            }
        case groupTypes.SYNC_BY_DEVICE_SUCCESS:
            return {
                ...state,
                loading: false,
                lastAction: "sync_group_by_device" + Date.now()
            }
        case groupTypes.DELETE_GROUPS_BY_DEVICE_SUCCESS:
            return {
                ...state,
                loading: false,
                groupDeleted: "delete_group_by_device" + Date.now(),
                lastAction: "delete_group_by_device" + Date.now()
            }
        case groupTypes.GET_MULTIPLE_GROUPS_SUCCESS:
            return {
                ...state,
                multipleGroupsList: [...action?.payload],
                loading: false
            };
        case groupTypes.GET_GROUP_COUNT_SUCCESS:
            return {
                ...state,
                groupCount: action?.payload,
                loading: false
            }
        case groupTypes.GET_GROUPS_FAILURE:
            return {
                ...state,
                error: action.payload.error,
                list: [],
                loading: false
            };
        case groupTypes.GET_GROUP_FAILURE:
            return {
                ...state,
                error: action.payload.error,
                item: {},
                loading: false
            };
        case groupTypes.GET_GROUP_COUNT_FAILURE:
            return {
                ...state,
                error: action.payload.error,
                groupCount: {},
                loading: false,
            }
        case groupTypes.ADD_FAILURE:
        case groupTypes.UPDATE_FAILURE:
        case groupTypes.UPDATE_GROUP_CONTROL_FAILURE:
        case groupTypes.SYNC_BY_DEVICE_FAILURE:
        // case groupTypes.GET_GROUP_DEVICES_DETAILS_FAILURE:
        case groupTypes.GROUP_SYNC_FAILURE:
        case groupTypes.DELETE_GROUPS_BY_DEVICE_FAILURE:
        case groupTypes.DELETE_FAILURE:
        case groupTypes.GET_MULTIPLE_GROUPS_FAILURE:
            return {
                ...state,
                error: action.payload.error,
                loading: false
            };
        default:
            return state
    }
}