import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeviceTypes from "../components/DeviceTypes";
import IntensitySlider from "../components/IntensitySlider";
import LocationSpaceTree from "../components/LocationSpaceTree";
import { TableHelper } from "../components/TableHelper";
import Pairing from "../assets/icons/pairing.svg"
import Unpairing from "../assets/icons/unpairing.svg"
import ViewIcon from '../assets/icons/view.svg'

import {
  applyScene,
  confirmationDialog,
  deleteDevice,
  disableDeviceOperation,
  disableIdentifyDevice,
  disableTemperatureControl,
  getSensorTypes,
  history,
  identifyDevice,
  isAuthorised,
  nameEditor,
  startPairing,
  stopPairing,
  wattageEditor,
} from "../helpers";
import { sceneActions, groupActions, areaActions, deviceActions } from "../redux/actions";
import DeviceSettings from "../components/DeviceSettings";
import IdentifyIcon from "../assets/icons/identify.svg"
import SensorIdentifyIcon from "../assets/icons/identify-2.svg"
import CustomTypography from "../components/CustomTypography";
import ApplyIcon from "../assets/icons/apply.svg"
import SyncIcon from "../assets/icons/sync.svg"
import DeleteIcon from "../assets/icons/delete.svg"
import CheckCircle from "../assets/icons/check-circle.svg";
import AlertIcon from "../assets/icons/alert-icon.svg";
import ConfigureIcon from '../assets/icons/configure.svg'
import { features, permissions } from "../constants";
import { Link } from 'react-router-dom';
import { formatString, mqttRequest } from "../services";
import DebugIcon from '../assets/icons/fix.svg'
import CloseIcon from '../assets/icons/close.svg'
import { defaultTenantName } from "../config/appConfig";

const useTable = ({ gatewayDetails, controller, setDialog, setDialogContents, setDialogWrapper, setDialogWrapperContents, mqttClient }) => {
  const deviceModels = useSelector((state) => state.devices.deviceModels);
  const deviceCategories = useSelector((state) => state.devices.deviceCategories);
  const gwList = useSelector((state) => state?.devices?.gatewayList);
  const dispatch = useDispatch();
  const [currentGateway, setCurrentGateway] = useState(gatewayDetails);
  const lightColumns = {
    // category: { label: "Device type", style: {} },
    name: {
      label: "Light Name",
      type: "component",
      component: (row, sx = {}) => {
        return nameEditor(row, deviceCategories?.find(c => c?.category === row?.data?.category), sx, dispatch)
      },
      style: { minWidth: 200 }
    },
    // deviceId: { label: "Device ID", style: {} },
    lightId: {
      label: "Light Id",
      style: {},
    },
    modelNumber: {
      label: "model number",
      style: { minWidth: 160 },
    },
    wattage: {
      label: "wattage",
      type: "component",
      component: (row) => {
        return wattageEditor(row?.data, dispatch)
      }
    },
    spaceId: {
      label: "Space",
      type: "component",
      component: (row) => <>{row?.data?.spaceName}</>,
      style: {},
    },
    sync: {
      icon: (row) => { return row?.data?.sync ? CheckCircle : AlertIcon },
      label: "Sync Status",
      type: "component",
      component: (row) => <TableHelper.TableStatus type={row?.data?.sync ? "synced" : "needsSync"} variant={"filled"} label={row?.data?.syncStatus} />,
      style: {},
      sortKey: "syncStatus"
    },
    status: {
      label: "Status",
      type: "component",
      component: (row) => <TableHelper.TableStatus type={row?.data?.status} label={formatString(row?.data?.status, "capitalize")} />,
    },
    connectionType: {
      label: "Connection type",
      type: "component",
      component: (row) => <TableHelper.TableStatus type={row?.data?.connectionType} label={formatString(row?.data?.connectionType, "capitalize")} />,
      style: { minWidth: 170 }
    },
  };
  const aggregatorColumns = {
    headerList: {
      // category: {
      //   label: "Device category",
      //   image: { img: Aggregator },
      //   type: "link",
      //   url: "/spaces/devices?pt=aggregator",
      //   urlParams: { id: "deviceId", category: "category" },
      // },
      name: {
        label: "Aggregator name",
        type: "component",
        component: (row, sx = {}) => {
          return nameEditor(row, deviceCategories?.find(c => c?.category === row?.data?.category), sx, dispatch, "/spaces/devices?pt=aggregator")
        },
        style: { minWidth: 250 }

      },
      modelNumber: { label: "Model Number", style: { minWidth: 160 } },
      // deviceId: { label: "Device ID" },
      spaceId: {
        type: "component",
        label: "space",
        style: {},
        component: (row) => <>{row?.data?.spaceName}</>,
      },
      status: {
        label: "Status",
        type: "component",
        component: (row) => <TableHelper.TableStatus type={row?.data?.status} label={formatString(row?.data?.status, "capitalize")} />,
      },
      connectionType: {
        label: "Connection type",
        type: "component",
        component: (row) => <TableHelper.TableStatus type={row?.data?.connectionType} label={formatString(row?.data?.connectionType, "capitalize")} />,
        style: { minWidth: 170 }
      },
      connectionStatus: {
        label: "Connection status",
        type: "component",
        component: (row) => <TableHelper.TableStatus type={row?.data?.connectionStatus} label={row?.data?.connectionStatus === "online" ? "Online" : "Offline"} />,
        style: { minWidth: 170 }
      },
      userActions: {
        label: "Actions",
        actions: [

          {
            type: "editSpace",
            // toolTip: "Move to new Space",
            hidden: (row) => {
              return (!isAuthorised(features?.device, permissions?.moveDevice) || disableDeviceOperation(row, "moveDevice", gwList))
            },
            component: (row) => {
              return <LocationSpaceTree data={row?.data} disableTooltip={row?.cardView} customsx={{ container: { px: row?.cardView && 2, py: row?.cardView && 1 }, icon: { ml: row?.cardView ? 0 : 1, mr: row?.cardView ? 1 : 0 } }} showLabel={row?.cardView} {...row} />
            }
          },
          {
            label: "Delete device",
            icon: DeleteIcon,
            type: "delete",
            hidden: (row) => {
              if (row?.status === "inactive") {
                return !isAuthorised(features?.device, permissions?.delete);
              }
              return true;
            },
            onClick: (event, data) => {
              deleteDevice(data, setDialog, setDialogContents, dispatch);
            },
          },
          {
            type: "viewDetails",
            icon: ViewIcon,
            label: "View Details",
            hidden: !isAuthorised(features?.device),
            onClick: (event, data) => {
              history?.push(`/spaces/devices?pt=aggregator&id=${data?.deviceId}&category=${data?.category}`)
            }
          },
        ],
        style: { textAlign: "right" }
      }
    },
    sorting: { column: 'name' },
    rowConfig: { highlight: { bgKey: "status", bgValue: "inactive" }, disabled: { key: "status", value: "inactive" } }
    // removeSortFrom:["sync"]
  };
  const controllerColumns = {
    headerList: {
      name: {
        label: "Controller name",
        type: "component",
        component: (row, sx = {}) => {
          return nameEditor(row, deviceCategories?.find(c => c?.category === row?.data?.category), sx, dispatch, "/spaces/devices?pt=controller")
        },
        style: { minWidth: 250 }
      },
      modelNumber: { label: "Model Number", style: { minWidth: 170 } },
      category: {
        label: "Category",
        type: "component",
        component: (row) => {
          const categoryLabel = deviceCategories?.find(c => c?.category === row?.data?.category)?.label;
          return row?.cardView ?
            <CustomTypography
              size={12}
              weight={600}
              lineHeight={19}
              content={categoryLabel}
            />
            : <>{categoryLabel}</>
        }
        ,
        style: { minWidth: 250 }
      },
      // deviceId: { label: "Device ID" },
      spaceId: {
        type: "component",
        label: "Space",
        style: {},
        component: (row) => <>{row?.data?.spaceName}</>,

      },
      // sync: {
      //   icon: (row) => { return row?.data?.sync ? CheckCircle : AlertIcon },
      //   label: "Sync Status",
      //   type: "component",
      //   component: (row) => <TableHelper.TableStatus type={row?.data?.sync ? "synced" : "needsSync"} variant={"filled"} label={row?.data?.syncStatus} />,
      //   onChange: () => {
      //   },
      //   sortKey: "syncStatus"
      // },
      status: {
        label: "Status",
        type: "component",
        component: (row) => <TableHelper.TableStatus type={row?.data?.status} label={formatString(row?.data?.status, "capitalize")} />,
      },
      connectionType: {
        label: "Connection type",
        type: "component",
        component: (row) => <TableHelper.TableStatus type={row?.data?.connectionType} label={formatString(row?.data?.connectionType, "capitalize")} />,
        style: { minWidth: 170 }
      },
      connectionStatus: {
        label: "Connection status",
        type: "component",
        component: (row) => <TableHelper.TableStatus type={row?.data?.connectionStatus} label={row?.data?.connectionStatus === "online" ? "Online" : "Offline"} />,
        style: { minWidth: 190 }
      },
      userActions: {
        label: "Actions",
        actions: [

          {
            label: "Delete device",
            icon: DeleteIcon,
            type: "delete",
            hidden: (row) => {
              if (row?.status === "inactive") {
                return !isAuthorised(features?.device, permissions?.delete);
              }
              return true;
            },
            onClick: (event, data) => {
              deleteDevice(data, setDialog, setDialogContents, dispatch);
            },
          },
          {
            type: "editSpace",
            hidden: (row) => {
              return (!isAuthorised(features?.device, permissions?.moveDevice) || disableDeviceOperation(row, "moveDevice", gwList))
            },
            component: (row) => <LocationSpaceTree data={row?.data} disableTooltip={row?.cardView} showLabel={row?.cardView} customsx={{ container: { px: row?.cardView && 2, py: row?.cardView && 1 }, icon: { ml: row?.cardView ? 0 : 1, mr: row?.cardView ? 1 : 0 } }} {...row}></LocationSpaceTree>
          },
          {
            type: "viewDetails",
            icon: ViewIcon,
            hidden: !isAuthorised(features?.device),
            label: "View Details",
            onClick: (event, data) => {
              history?.push(`/spaces/devices?pt=controller&id=${data?.deviceId}&category=${data?.category}`)
            }
          },
        ],
        style: { textAlign: "right" }
      }
    },
    sorting: { column: 'name' },
    rowConfig: { highlight: { bgKey: "status", bgValue: "inactive" }, disabled: { key: "status", value: "inactive" } }
    // removeSortFrom:["sync"]
  };
  const receiverColumns = {
    headerList: {
      name: {
        label: "Receiver name",
        type: "component",
        component: (row, sx = {}) => {
          return nameEditor(row, deviceCategories?.find(c => c?.category === row?.data?.category), sx, dispatch)
        },
        style: { minWidth: 200 }
      },
      modelNumber: { label: 'Model Number', style: { minWidth: 200 } },
      // deviceId: lightColumns?.deviceId,
      macAddress: { label: "Mac Address", style: {} },
      spaceId: lightColumns?.spaceId,
      status: {
        label: "Status",
        type: "component",
        component: (row) => <TableHelper.TableStatus type={row?.data?.status} label={formatString(row?.data?.status, "capitalize")} />,
      },
      connectionType: {
        label: "Connection type",
        type: "component",
        component: (row) => <TableHelper.TableStatus type={row?.data?.connectionType} label={formatString(row?.data?.connectionType, "capitalize")} />,
        style: { minWidth: 170 }
      },
      connectionStatus: controllerColumns?.headerList?.connectionStatus,
      // status: lightColumns?.status,
      userActions: {
        label: "Actions",
        actions: [

          {
            label: (data) => {
              return data?.pairing ? "Stop sensor pairing" : "Start sensor pairing"
            },
            type: "pairing",
            icon: (data) => {
              return data?.pairing ? Unpairing : Pairing
            },
            hidden: (row) => {
              return !isAuthorised(features?.device, permissions?.pairing) || disableDeviceOperation(row, "receiverPairing");
            },
            onClick: (event, data) => {
              data?.pairing ?
                stopPairing(currentGateway, {
                  device: data,
                  dispatch,
                  mqttClient
                }, setDialog, setDialogContents) :
                startPairing({
                  gatewayDetails: currentGateway,
                  device: data,
                  dispatch,
                  mqttClient,
                  setDialog,
                  setDialogContents
                });
            },
          },
          {
            label: "Delete device",
            type: "delete",
            icon: DeleteIcon,
            hidden: (row) => {
              if (row?.status === "inactive") {
                return !isAuthorised(features?.device, permissions?.delete);
              }
              return true;
            },
            onClick: (event, data) => {
              deleteDevice(data, setDialog, setDialogContents, dispatch);
            }
          },

          // {
          //   label: "System Reset",
          //   type: "systemreset",
          //   icon: SystemResetIcon,
          //   onClick: (event, data) => {
          //     systemReset(data, currentGateway, setDialog, setDialogContents, dispatch, mqttClient);
          //   },
          //   hidden: (row) => {
          //     if (row?.status === "inactive") {
          //       return true;
          //     }
          //     return false;
          //   },
          // },
          // {
          //   label: "Factory Reset",
          //   icon: FactoryResetIcon,
          //   type: "factoryreset",
          //   onClick: (event, data) => {
          //     factoryReset(data, setDialog, setDialogContents, dispatch, mqttClient);
          //   },
          //   hidden: (row) => {
          //     if (row?.status === "inactive") {
          //       return true;
          //     }
          //     return false;
          //   },
          // },
          {
            type: "editSpace",
            hidden: (row) => {
              return (!isAuthorised(features?.device, permissions?.moveDevice) || disableDeviceOperation(row, "moveDevice", gwList))
            },
            component: (row) => {
              return <LocationSpaceTree data={row?.data} disableTooltip={row?.cardView} showLabel={row?.cardView} customsx={{ container: { px: row?.cardView && 2, py: row?.cardView && 1 }, icon: { ml: row?.cardView ? 0 : 1, mr: row?.cardView ? 1 : 0 } }} {...row} />
            }
          },
          {
            type: "viewDetails",
            icon: ViewIcon,
            hidden: !isAuthorised(features?.device),
            label: "View Details",
            onClick: (event, data) => {
              history?.push(`/spaces/devices?pt=device&id=${data?.deviceId}&category=${data?.category}`)
            }
          },
        ],
        style: { textAlign: "right" },
      },
    },
    sorting: { column: "status", order: "asc" },
    removeSortFrom: ["userActions"],
    rowConfig: { highlight: { bgKey: "status", bgValue: "inactive" }, disabled: { key: "status", value: "inactive" } }
  }


  const switchColumns = {
    headerList: {
      // category: {
      //   label: "Device Type",
      //   type: "link",
      //   url: "/spaces/devices?pt=device",
      //   urlParams: { id: "deviceId", category: "category" },
      //   style: {  }
      // },
      name: {
        label: "Switch name",
        type: "component",
        component: (row, sx = {}) => {
          return nameEditor(row, deviceCategories?.find(c => c?.category === row?.data?.category), sx, dispatch)
        },
        style: { minWidth: 200 }

      },
      modelNumber: {
        label: "model number",
        style: { minWidth: 180 },
      },
      buttonType: {
        label: "Switch Type",
        type: "component",
        component: (row) => {
          return row?.data?.configuration?.buttonCount ? <>
            {row?.data?.configuration?.buttonCount} - button
          </> : <>-</>
        },
        style: {},
        sortKey: "buttonCount"
      },
      spaceId: lightColumns?.spaceId,
      sync: lightColumns?.sync,
      status: {
        label: "Status",
        type: "component",
        component: (row) => <TableHelper.TableStatus type={row?.data?.status} label={formatString(row?.data?.status, "capitalize")} />,
      },
      connectionType: {
        label: "Connection type",
        type: "component",
        component: (row) => <TableHelper.TableStatus type={row?.data?.connectionType} label={formatString(row?.data?.connectionType, "capitalize")} />,
        style: { minWidth: 170 }
      },
      // status: lightColumns?.status,
      userActions: {
        label: "Actions",
        actions: [

          {
            label: "Delete device",
            type: "delete",
            icon: DeleteIcon,
            hidden: (row) => {
              if (row?.status === "inactive") {
                return !isAuthorised(features?.device, permissions?.delete);
              }
              return true;
            },
            onClick: (event, data) => {
              deleteDevice(data, setDialog, setDialogContents, dispatch);
            }
          },
          {
            type: "editSpace",
            hidden: (row) => {
              return (!isAuthorised(features?.device, permissions?.moveDevice) || disableDeviceOperation(row, "moveDevice", gwList))
            },
            component: (row) => {
              return <LocationSpaceTree data={row?.data} disableTooltip={row?.cardView} showLabel={row?.cardView} customsx={{ container: { px: row?.cardView && 2, py: row?.cardView && 1 }, icon: { ml: row?.cardView ? 0 : 1, mr: row?.cardView ? 1 : 0 } }} {...row} />
            }
          },
          {
            type: "viewDetails",
            hidden: !isAuthorised(features?.device),
            icon: ViewIcon,
            label: "View Details",
            onClick: (event, data) => {
              history?.push(`/spaces/devices?pt=device&id=${data?.deviceId}&category=${data?.category}`)
            }
          },
        ],
        style: { textAlign: "right" },
      }
    },
    sorting: { column: "status", order: "asc" },
    removeSortFrom: ["userActions"],
    rowConfig: { highlight: { bgKey: "status", bgValue: "inactive" }, disabled: { key: "status", value: "inactive" } }
  };
  const sensorColumns = {
    headerList: {
      name: {
        label: "sensor name",
        type: "component",
        component: (row, sx = {}) => {
          return nameEditor(row, deviceCategories?.find(c => c?.category === row?.data?.category), sx, dispatch)
        },
        style: { minWidth: 200 }
      },
      modelNumber: {
        label: "model number",
        style: { minWidth: 160 },
      },
      type: {
        label: "Sensor Type",
        type: "component",
        component: (row) => {
          return <CustomTypography
            size={14}
            weight={400}
            content={getSensorTypes(row?.data, deviceModels)} />
        },
        style: { minWidth: 150 }
      },
      spaceId: lightColumns?.spaceId,
      batteryLevel: {
        label: "Battery status",
        type: "component",
        component: (row) => {
          return <TableHelper.TableBatteryLevel data={row?.data} deviceModel={deviceModels?.find(m => m?.modelId === row?.data?.modelId)}></TableHelper.TableBatteryLevel>
        },
        style: { minWidth: 200 },
      },
      sync: lightColumns?.sync,
      status: {
        label: "Status",
        type: "component",
        component: (row) => <TableHelper.TableStatus type={row?.data?.status} label={formatString(row?.data?.status, "capitalize")} />,
      },
      connectionType: {
        label: "Connection type",
        type: "component",
        component: (row) => <TableHelper.TableStatus type={row?.data?.connectionType} label={formatString(row?.data?.connectionType, "capitalize")} />,
        style: { minWidth: 170 }
      },
      // status: lightColumns?.status,
      connectionStatus: controllerColumns?.headerList?.connectionStatus,
      userActions: {
        label: "Actions",
        actions: [
          {
            label: "Delete device",
            type: "delete",
            icon: DeleteIcon,
            hidden: (row) => {
              if (row?.status === "inactive") {
                return !isAuthorised(features?.device, permissions?.delete);
              }
              return true;
            },
            onClick: (event, data) => {
              deleteDevice(data, setDialog, setDialogContents, dispatch);
            }
          },
          {
            type: "editSpace",
            hidden: (row) => {
              return (!isAuthorised(features?.device, permissions?.moveDevice) || disableDeviceOperation(row, "moveDevice", gwList))
            },
            component: (row) => {
              return <LocationSpaceTree data={row?.data} disableTooltip={row?.cardView} showLabel={row?.cardView} customsx={{ container: { px: row?.cardView && 2, py: row?.cardView && 1 }, icon: { ml: row?.cardView ? 0 : 1, mr: row?.cardView ? 1 : 0 } }}{...row} />
            }
          },
          {
            type: "viewDetails",
            icon: ViewIcon,
            hidden: !isAuthorised(features?.device),
            label: "View Details",
            onClick: (event, data) => {
              history?.push(`/spaces/devices?pt=device&id=${data?.deviceId}&category=${data?.category}`)
            }
          }
        ],
        style: { textAlign: "right" }
      }
    },
    sorting: { column: "status", order: "asc" },
    removeSortFrom: ["userActions", "type"],
    rowConfig: { highlight: { bgKey: "status", bgValue: "inactive" }, disabled: { key: "status", value: "inactive" } }
  }
  const columns = {
    VortexGateway: {
      headerList: {
        // category: {
        //   label: "Device type",
        //   image: { img: Gateway },
        //   type: "link",
        //   url: "/spaces/devices?pt=gateway",
        //   urlParams: { id: "deviceId", category: "category" },
        // },
        name: {
          label: "Gateway name",
          type: "component",
          component: (row, sx = {}) => {
            return nameEditor(row, deviceCategories?.find(c => c?.category === row?.data?.category), sx, dispatch, '/spaces/devices?pt=gateway')
          },
          style: { minWidth: 250 }
        },
        modelNumber: {
          label: 'Model Number',
          style: { minWidth: 160 }
        },
        // deviceId: { label: 'Device ID'
        macAddress: { label: "Mac address" },
        ipAddress: { label: "IP address" },
        status: {
          label: "Status",
          type: "component",
          component: (row) => <TableHelper.TableStatus type={row?.data?.status} label={formatString(row?.data?.status, "capitalize")} />,
        },
        connectionType: {
          label: "Connection type",
          type: "component",
          component: (row) => <TableHelper.TableStatus type={row?.data?.connectionType} label={formatString(row?.data?.connectionType, "capitalize")} />,
          style: { minWidth: 170 }
        },
        connectionStatus: {
          label: "Connection status",
          type: "component",
          component: (row) => <TableHelper.TableStatus type={row?.data?.connectionStatus} label={row?.data?.connectionStatus === "online" ? "Online" : "Offline"} />,
          style: { minWidth: 190 }
        },
        userActions: {
          label: "Actions",
          actions: [
            {
              type: "editSpace",
              // loading:true,
              // toolTip: "Move to new Space",
              hidden: (row) => {
                return (!isAuthorised(features?.device, permissions?.moveDevice) || disableDeviceOperation(row, "moveDevice", gwList))
              },
              component: (row) => {
                return <LocationSpaceTree data={row?.data} disableTooltip={row?.cardView} showLabel={row?.cardView} customsx={{ container: { px: row?.cardView && 2, py: row?.cardView && 1 }, icon: { ml: row?.cardView ? 0 : 1, mr: row?.cardView ? 1 : 0 } }}{...row} />
              }
            },
            {
              label: "Delete device",
              icon: DeleteIcon,
              type: "delete",
              hidden: (row) => {
                if (row?.status === "inactive") {
                  return !isAuthorised(features?.device, permissions?.delete);
                }
                return true;
              },
              onClick: (event, data) => {
                deleteDevice(data, setDialog, setDialogContents, dispatch);
              },
            },
            {
              type: "viewDetails",
              icon: ViewIcon,
              hidden: !isAuthorised(features?.device),
              label: "View Details",
              onClick: (event, data) => {
                history?.push(`/spaces/devices?pt=gateway&id=${data?.deviceId}&category=${data?.category}`)
              }
            },
          ],
          style: { textAlign: "right" }
        }

      },
      sorting: { column: "name", order: "asc" },
      rowConfig: { highlight: { bgKey: "status", bgValue: "inactive" }, disabled: { key: "status", value: "inactive" } }
    },
    GatewayList: {
      headerList: {
        serialNumber: { label: "Serial Number" },
        macAddress: { label: "mac address", type: "list", separator: " | " },
        modelNumber: { label: "model Number" },
        gatewayOnboarded: {
          label: "onboarded status",
          type: "component",
          component: (row) => <TableHelper.TableStatus type={row?.data?.onboarded ? "onboarded" : "notOnboarded"} label={row?.data?.gatewayOnboarded} />,
          style: { minWidth: 135 }
        },
        userActions: {
          label: "actions",
          actions: [
            {
              label: "Debug gateway",
              icon: DebugIcon,
              hidden: (row) => {
                return !row?.debugMode?.enabled
              },
              type: "debug",
              onClick: (event, data) => {
                history?.push(`/debugGateway?serialNumber=${data?.serialNumber}${data?.hasOwnProperty("tenantName") ? `&tenantName=${data?.tenantName}` : ""}`)
                //   setDialogWrapper(true);
                //   setDialogWrapperContents({ title: "Debug Gateway", operation: "debugGateway", data })
              },
            },
            {
              label: "Disconnect gateway",
              icon: CloseIcon,
              hidden: (row) => {
                return !row?.debugMode?.enabled
              },
              type: "disconnect",
              onClick: (event, data) => {
                confirmationDialog("Disconnect Gateway", () => {
                  setTimeout(() => {
                    dispatch(deviceActions?.getAssignedGateways({}, data?.tenantName, false))
                  }, 5000)
                  setDialog(false);
                  mqttRequest({ pubTopic: `${data?.tenantName ? data?.tenantName : defaultTenantName}/${data?.macAddress[0]}/debugCommand`, deviceId: data?.deviceId, deviceConfig: { cmd: "disconnect" } }, mqttClient, dispatch, "disconnectGateway")
                }, "disconnect", setDialog, setDialogContents, data?.macAddress[0]);

              },
            },

          ],
          style: { textAlign: "right" }
        }
      },
      removeSortFrom: ["userActions"],
      searching: { reset: true, columns: ["serialNumber", "macAddress", "modelNumber", 'gatewayOnboarded'] },
      sorting: { column: 'serialNumber' }
    },
    Controller: controllerColumns,
    controllerList: {
      headerList: {
        name: controllerColumns?.headerList?.name,
        modelNumber: controllerColumns?.headerList?.modelNumber,
        category: controllerColumns?.headerList?.category,
        status: controllerColumns?.headerList?.status,
        connectionType: controllerColumns?.headerList?.connectionType,
        connectionStatus: controllerColumns?.headerList?.connectionStatus,
        userActions: {
          label: "Actions",
          actions: [
            {
              type: "editSpace",
              hidden: (row) => {
                return (!isAuthorised(features?.device, permissions?.moveDevice) || disableDeviceOperation(row, "moveDevice", gwList))
              },
              component: (row) => {
                return <LocationSpaceTree data={row?.data} disableTooltip={row?.cardView} showLabel={row?.cardView} customsx={{ container: { px: row?.cardView && 2, py: row?.cardView && 1 }, icon: { ml: row?.cardView ? 0 : 1, mr: row?.cardView ? 1 : 0 } }} />
              }
            },
            {
              label: "Delete device",
              icon: DeleteIcon,
              type: "delete",
              hidden: (row) => {
                if (row?.status === "inactive") {
                  return !isAuthorised(features?.device, permissions?.delete);
                }
                return true;
              },
              onClick: (event, data) => {
                deleteDevice(data, setDialog, setDialogContents, dispatch);
              },
            },
            {
              type: "viewDetails",
              icon: ViewIcon,
              hidden: !isAuthorised(features?.device),
              label: "View Details",
              onClick: (event, data) => {
                history?.push(`/spaces/devices?pt=controller&id=${data?.deviceId}&category=${data?.category}`)
              }
            },
          ],
          style: { textAlign: "right" }
        }
      },
      sorting: controllerColumns?.sorting,
      rowConfig: controllerColumns?.rowConfig
    },
    Camera: {
      headerList: {
        name: {
          label: "Camera name",
          type: "component",
          component: (row, sx = {}) => {
            return nameEditor(row, deviceCategories?.find(c => c?.category?.toLowerCase() === row?.data?.category?.toLowerCase()), sx, dispatch, '/spaces/devices?pt=camera')
          },
          style: { minWidth: 250 }
        },
        modelNumber: {
          label: 'Model Number',
          style: { minWidth: 180 }
        },
        ipAddress: { label: "IP address" },
        status: {
          label: "Status",
          type: "component",
          component: (row) => <TableHelper.TableStatus type={row?.data?.status} label={formatString(row?.data?.status, "capitalize")} />,
        },
        connectionType: {
          label: "Connection type",
          type: "component",
          component: (row) => <TableHelper.TableStatus type={row?.data?.connectionType} label={formatString(row?.data?.connectionType, "capitalize")} />,
          style: { minWidth: 170 }
        },
        userActions: {
          label: "Actions",
          actions: [
            // {
            //   type: "editSpace",
            //   hidden: (row) => {
            //     return (!isAuthorised(features?.device, permissions?.moveDevice) || disableDeviceOperation(row, "moveDevice", gwList))
            //   },
            //   component: (row) => {
            //     return <LocationSpaceTree data={row?.data} disableTooltip={row?.cardView} showLabel={row?.cardView} customsx={{ container: { px: row?.cardView && 2, py: row?.cardView && 1 }, icon: { ml: row?.cardView ? 0 : 1, mr: row?.cardView ? 1 : 0 } }} />
            //   }
            // },
            {
              label: "Delete device",
              icon: DeleteIcon,
              type: "delete",
              hidden: (row) => {
                if (row?.status === "inactive") {
                  return !isAuthorised(features?.device, permissions?.delete);
                }
                return true;
              },
              onClick: (event, data) => {
                deleteDevice(data, setDialog, setDialogContents, dispatch);
              },
            },
            {
              type: "viewDetails",
              icon: ViewIcon,
              hidden: !isAuthorised(features?.device),
              label: "View Details",
              onClick: (event, data) => {
                history?.push(`/spaces/devices?pt=camera&id=${data?.deviceId}&category=${data?.category}`)
              }
            },
          ],
          style: { textAlign: "right" }
        }
      },
      sorting: { column: 'name' },
      rowConfig: { highlight: { bgKey: "status", bgValue: "inactive" }, disabled: { key: "status", value: "inactive" } }
    },
    Aggregator: aggregatorColumns,
    aggregatorList: {
      headerList: {
        name: aggregatorColumns?.headerList?.name,
        modelNumber: aggregatorColumns?.headerList?.modelNumber,
        status: aggregatorColumns?.headerList?.status,
        connectionType: aggregatorColumns?.headerList?.connectionType,
        connectionStatus: aggregatorColumns?.headerList?.connectionStatus,
        userActions: aggregatorColumns?.headerList?.userActions
      },
      sorting: aggregatorColumns?.sorting,
      rowConfig: aggregatorColumns?.rowConfig

    },
    lightdriver: {
      headerList: {
        name: lightColumns?.name,
        lightId: lightColumns?.lightId,
        modelNumber: lightColumns?.modelNumber,
        wattage: lightColumns?.wattage,
        sync: lightColumns?.sync,
        status: lightColumns?.status,
        connectionType: lightColumns?.connectionType,
        // status: lightColumns?.status,
        intensity: {
          label: "Intensity",
          type: "component",
          component: (row) => {
            return (
              <IntensitySlider data={row?.data} mqttClient={mqttClient} subscribe={false} />
            );
          },
          style: {},
        },
        userActions: {
          label: "Actions",
          actions: [

            {
              label: "Delete device",
              icon: DeleteIcon,
              type: "delete",
              hidden: (row) => {
                if (row?.status === "inactive") {
                  return !isAuthorised(features?.device, permissions?.delete);
                }
                return true;
              },
              onClick: (event, data) => {
                deleteDevice(data, setDialog, setDialogContents, dispatch);
              },
            },
            {
              label: "Controls",
              type: "control",
              icon: ConfigureIcon,
              onClick: (event, data) => {
                setDialogWrapperContents({ content: "control", title: "Device Controls", deviceData: data })
                setDialogWrapper(true);
              },
              hidden: (data) => {
                return !isAuthorised(features?.device, permissions?.configureTemperature) || disableTemperatureControl(data, deviceModels);
              },
            },
            {
              label: "Configurations",
              type: "config",
              icon: ConfigureIcon,
              onClick: (event, data) => {
                setDialogWrapperContents({ title: "Device Configurations", content: "config", deviceData: data })
                setDialogWrapper(true);
              },
              hidden: (data, cardView) => {
                if (cardView) {
                  return !isAuthorised(features?.device, permissions?.configureTemperature) || data?.category !== "DaliLightDriver" || data?.status === "inactive"
                }
                return true;
              },
            },
            {
              label: "Identify Device",
              type: "identify",
              icon: IdentifyIcon,
              onClick: (event, data) => {
                identifyDevice({
                  device: data,
                  dispatch,
                  mqttClient
                }, setDialog, setDialogContents, currentGateway);
              },
              hidden: (data) => {
                return !isAuthorised(features?.device, permissions?.identifyLight) || disableIdentifyDevice(data, deviceModels);
              },
            },
            {
              // label: "Move to new Space",
              type: "editSpace",
              hidden: (row) => {
                return (!isAuthorised(features?.device, permissions?.moveDevice) || disableDeviceOperation(row, "moveDevice", gwList))
              },
              component: (row) => {
                return <LocationSpaceTree data={row?.data} disableTooltip={row?.cardView} showLabel={row?.cardView} customsx={{ container: { px: row?.cardView && 2, py: row?.cardView && 1 }, icon: { ml: row?.cardView ? 0 : 1, mr: row?.cardView ? 1 : 0 } }} {...row} />
              }
            },
            {
              type: "viewDetails",
              icon: ViewIcon,
              hidden: !isAuthorised(features?.device),
              label: "View Details",
              onClick: (event, data) => {
                history?.push(`/spaces/devices?pt=device&id=${data?.deviceId}&category=${data?.category}`)
              }
            },
          ],
          style: { textAlign: "right" },
        },
      },
      expandable: { component: DeviceSettings },
      sorting: { column: "status", order: "asc" },
      removeSortFrom: ["userActions", "intensity"],
      rowConfig: { highlight: { bgKey: "status", bgValue: "inactive" }, disabled: { key: "status", value: "inactive" } }
    },
    //----------------- Columns for DaliLightDriver table on controller details page
    DaliLightDriver: {
      headerList: {
        ...lightColumns,
        spaceId: lightColumns?.spaceId,
        sync: lightColumns?.sync,
        // status: lightColumns?.status,
        intensity: {
          label: "Intensity",
          type: "component",
          component: (row) => {
            return (
              <IntensitySlider wrap={false} data={row?.data} mqttClient={mqttClient} />
            );
          },
          // customData:{min:0,max:100,name:"LUMINOSITY",value:23,disabled:true,customsx:{value:{color:"red"},compo:SliderCss}},
          // onChange: (e, value, data) => {
          //   lstControl(e, value, data);
          // },
          style: {},
        },
        userActions: {
          label: "Actions",
          actions: [
            {
              label: "Controls",
              type: "control",
              icon: ConfigureIcon,
              onClick: (event, data) => {
                setDialogWrapperContents({ content: "control", title: "Device Controls", deviceData: data })
                setDialogWrapper(true);
              },
              hidden: (data) => {
                return !isAuthorised(features?.device, permissions?.configureTemperature) || disableTemperatureControl(data, deviceModels);
              },
            },
            {
              label: "Configurations",
              type: "config",
              icon: ConfigureIcon,
              onClick: (event, data) => {
                setDialogWrapperContents({ title: "Device Configurations", content: "config", deviceData: data })
                setDialogWrapper(true);
              },
              hidden: (data, cardView) => {
                if (cardView) {
                  return !isAuthorised(features?.device, permissions?.configureTemperature) || data?.status === "inactive"
                }
                return true;
              },
            },
            {
              label: "Delete device",
              icon: DeleteIcon,
              type: "delete",
              hidden: (row) => {
                if (row?.status === "inactive") {
                  return !isAuthorised(features?.device, permissions?.delete);
                }
                return true;
              },
              onClick: (event, data) => {
                deleteDevice(data, setDialog, setDialogContents, dispatch);
              }
            },
            {
              label: "Identify Device",
              type: "identify",
              icon: IdentifyIcon,
              onClick: (event, data) => {
                identifyDevice({
                  device: data,
                  dispatch,
                  mqttClient
                }, setDialog, setDialogContents, currentGateway);
              },
              hidden: (data) => {
                return !isAuthorised(features?.device, permissions?.identifyLight) || disableIdentifyDevice(data, deviceModels);
              },
            },
            {
              type: "editSpace",
              hidden: (row) => {
                return (!isAuthorised(features?.device, permissions?.moveDevice) || disableDeviceOperation(row, "moveDevice", gwList))
              },
              component: (row) => {
                return <LocationSpaceTree data={row?.data} disableTooltip={row?.cardView} showLabel={row?.cardView} customsx={{ container: { px: row?.cardView && 2, py: row?.cardView && 1 }, icon: { ml: row?.cardView ? 0 : 1, mr: row?.cardView ? 1 : 0 } }} {...row} />
              }
            },
            {
              type: "viewDetails",
              icon: ViewIcon,
              hidden: !isAuthorised(features?.device),
              label: "View Details",
              onClick: (event, data) => {
                history?.push(`/spaces/devices?pt=device&id=${data?.deviceId}&category=${data?.category}`)
              }
            },
          ],
          style: { textAlign: "right" },
        },
      },
      // sorting: {column: "lightId", order: "asc" },
      expandable: { component: DeviceSettings },
      removeSortFrom: ["userActions", "intensity"],
      rowConfig: { highlight: { bgKey: "status", bgValue: "inactive" }, disabled: { key: "status", value: "inactive" } }
    },
    AnalogLightDriver: {
      headerList: {
        ...lightColumns,
        intensity: {
          label: "Intensity",
          type: "component",
          component: (row) => {
            return (
              <IntensitySlider wrap={false} data={row?.data} mqttClient={mqttClient} />
            );
          },
          // customData:{min:0,max:100,name:"LUMINOSITY",value:23,disabled:true,customsx:{value:{color:"red"},compo:SliderCss}},
          // onChange: (e, value, data) => {
          //   lstControl(e, value, data);
          // },
          style: {},
        },
        userActions: {
          label: "Actions",
          actions: [
            {
              label: "Identify Device",
              type: "identify",
              icon: IdentifyIcon,
              onClick: (event, data) => {
                identifyDevice({
                  device: data,
                  dispatch,
                  mqttClient
                }, setDialog, setDialogContents, currentGateway);
              },
              hidden: (data) => {
                return !isAuthorised(features?.device, permissions?.identifyLight) || disableIdentifyDevice(data, deviceModels);
              },
            },
            {
              type: "editSpace",
              hidden: (row) => {
                return (!isAuthorised(features?.device, permissions?.moveDevice) || disableDeviceOperation(row, "moveDevice", gwList))
              },
              component: (row) => {
                return <LocationSpaceTree data={row?.data} disableTooltip={row?.cardView} showLabel={row?.cardView} customsx={{ container: { px: row?.cardView && 2, py: row?.cardView && 1 }, icon: { ml: row?.cardView ? 0 : 1, mr: row?.cardView ? 1 : 0 } }} {...row} />
              }
            },
            {
              type: "viewDetails",
              icon: ViewIcon,
              hidden: !isAuthorised(features?.device),
              label: "View Details",
              onClick: (event, data) => {
                history?.push(`/spaces/devices?pt=device&id=${data?.deviceId}&category=${data?.category}`)
              }
            },
          ],
          style: { textAlign: "right" },
        },
      },
      // sorting: {column: "status", order: "asc" },
      removeSortFrom: ["userActions", "intensity"],
      rowConfig: { highlight: { bgKey: "status", bgValue: "inactive" }, disabled: { key: "status", value: "inactive" } }
    },
    //----------------- Columns for RelayLightDriver table on controller details page
    RelayLightDriver: {
      headerList: {
        name: lightColumns?.name,
        lightId: lightColumns?.lightId,
        modelNumber: lightColumns?.modelNumber,
        wattage: lightColumns?.wattage,
        spaceId: lightColumns?.spaceId,
        status: lightColumns?.status,
        connectionType: lightColumns?.connectionType,
        // status: lightColumns?.status,
        userActions: {
          label: "Actions",
          actions: [
            {
              label: "Switch ON/OFF",
              type: "toggle",
              component: (row) => {
                return (
                  <IntensitySlider wrap={false} params={{ value: false, switchButton: true, compo: false }} data={row?.data} mqttClient={mqttClient} customsx={{ toggle: { mr: 0 } }} />
                );
              },
            },
            {
              type: "editSpace",
              hidden: (row) => {
                return (!isAuthorised(features?.device, permissions?.moveDevice) || disableDeviceOperation(row, "moveDevice", gwList))
              },
              component: (row) => {
                return <LocationSpaceTree data={row?.data} disableTooltip={row?.cardView} showLabel={row?.cardView} customsx={{ container: { px: row?.cardView && 2, py: row?.cardView && 1 }, icon: { ml: row?.cardView ? 0 : 1, mr: row?.cardView ? 1 : 0 } }} {...row} />
              }
            },
            {
              type: "viewDetails",
              icon: ViewIcon,
              hidden: !isAuthorised(features?.device),
              label: "View Details",
              onClick: (event, data) => {
                history?.push(`/spaces/devices?pt=device&id=${data?.deviceId}&category=${data?.category}`)
              }
            },
          ],
          style: { textAlign: "right" },
        },
      },
      // sorting: {column: "status", order: "asc" },
      removeSortFrom: ["userActions", "intensity"],
      rowConfig: { highlight: { bgKey: "status", bgValue: "inactive" }, disabled: { key: "status", value: "inactive" } }
    },
    receiverList: {
      headerList: {
        name: receiverColumns?.headerList?.name,
        modelNumber: receiverColumns?.headerList?.modelNumber,
        macAddress: receiverColumns?.headerList?.macAddress,
        status: receiverColumns?.headerList?.status,
        connectionType: receiverColumns?.headerList?.connectionType,
        connectionStatus: receiverColumns?.headerList?.connectionStatus,
        userActions: receiverColumns?.headerList?.userActions
      },
      sorting: receiverColumns?.sorting,
      removeSortFrom: receiverColumns?.removeSortFrom,
      rowConfig: receiverColumns?.rowConfig
    },
    //----------------- Columns for Receiver table on controller details page
    Receiver: receiverColumns,
    //----------------- Columns for Switch table on controller details page
    Switch: switchColumns,
    switchList: {
      headerList: {
        name: switchColumns?.headerList?.name,
        modelNumber: switchColumns?.headerList?.modelNumber,
        buttonType: switchColumns?.headerList?.buttonType,
        sync: switchColumns?.headerList?.sync,
        status: switchColumns?.headerList?.status,
        connectionType: switchColumns?.headerList?.connectionType,
        userActions: switchColumns?.headerList?.userActions
      },
      sorting: switchColumns?.sorting,
      removeSortFrom: switchColumns?.removeSortFrom,
      rowConfig: switchColumns?.rowConfig
    },
    sensorList: {
      headerList: {
        name: sensorColumns?.headerList?.name,
        modelNumber: sensorColumns?.headerList?.modelNumber,
        type: sensorColumns?.headerList?.type,
        batteryLevel: sensorColumns?.headerList?.batteryLevel,
        sync: sensorColumns?.headerList?.sync,
        status: sensorColumns?.headerList?.status,
        connectionType: sensorColumns?.headerList?.connectionType,
        connectionStatus: sensorColumns?.headerList?.connectionStatus,
        userActions: sensorColumns?.headerList?.userActions
      },
      sorting: sensorColumns?.sorting,
      removeSortFrom: sensorColumns?.removeSortFrom,
      rowConfig: sensorColumns?.rowConfig
    },
    //----------------- Columns for Sensor table on controller details page
    Sensor: sensorColumns,
    //----------------- Columns for wireless Sensor table on aggregator details page
    WirelessSensor: {
      headerList: {
        name: {
          label: "sensor name",
          type: "component",
          component: (row, sx = {}) => {
            return nameEditor(row, deviceCategories?.find(c => c?.category === row?.data?.category), sx, dispatch)
          },
          style: { minWidth: 250 }
        },
        lightId: lightColumns?.lightId,
        modelNumber: sensorColumns?.headerList?.modelNumber,
        spaceId: sensorColumns?.headerList?.spaceId,
        sync: sensorColumns?.headerList?.sync,
        userActions: {
          label: "Actions",
          actions: [
            {
              label: "Delete device",
              type: "delete",
              icon: DeleteIcon,
              hidden: (row) => {
                if (row?.status === "inactive") {
                  return !isAuthorised(features?.device, permissions?.delete);
                }
                return true;
              },
              onClick: (event, data) => {
                deleteDevice(data, setDialog, setDialogContents, dispatch);
              }
            },
            {
              type: "editSpace",
              hidden: (row) => {
                return (!isAuthorised(features?.device, permissions?.moveDevice) || disableDeviceOperation(row, "moveDevice", gwList))
              },
              component: (row) => {
                return <LocationSpaceTree data={row?.data} disableTooltip={row?.cardView} showLabel={row?.cardView} customsx={{ container: { px: row?.cardView && 2, py: row?.cardView && 1 }, icon: { ml: row?.cardView ? 0 : 1, mr: row?.cardView ? 1 : 0 } }} {...row} />
              }
            },
            {
              label: "Identify Light",
              type: "identify",
              icon: IdentifyIcon,
              onClick: (event, data) => {
                identifyDevice({
                  device: data,
                  dispatch,
                  mqttClient
                }, setDialog, setDialogContents, currentGateway);
              },
              hidden: (data) => {
                return !isAuthorised(features?.device, permissions?.identifyLight) || disableIdentifyDevice(data, deviceModels);
              },
            },
            {
              label: "Identify Sensor",
              type: "identify",
              icon: SensorIdentifyIcon,
              onClick: (event, data) => {
                identifyDevice({
                  device: data,
                  dispatch,
                  mqttClient
                }, setDialog, setDialogContents, currentGateway);
              },
              hidden: (data) => {
                return !isAuthorised(features?.device, permissions?.identifyLight) || disableIdentifyDevice(data, deviceModels);
              },
            },
            {
              type: "viewDetails",
              icon: ViewIcon,
              hidden: !isAuthorised(features?.device),
              label: "View Details",
              onClick: (event, data) => {
                history?.push(`/spaces/devices?pt=device&id=${data?.deviceId}&category=${data?.category}`)
              }
            },
          ],
          style: { textAlign: "right" }
        }
      }
    },
    // AQSensor: {
    //   headerList: {
    //     name: sensorColumns?.headerList?.name,
    //     modelNumber: sensorColumns?.headerList?.modelNumber,
    //     macAddress: { label: "Mac Address", style: {} },
    //     batteryLevel: sensorColumns?.headerList?.batteryLevel,
    //     sync: sensorColumns?.headerList?.sync,
    //     status: sensorColumns?.headerList?.status,
    //     connectionType: sensorColumns?.headerList?.connectionType,
    //     connectionStatus: sensorColumns?.headerList?.connectionStatus,
    //     userActions: sensorColumns?.headerList?.userActions
    //   },
    //   sorting: sensorColumns?.sorting,
    //   removeSortFrom: sensorColumns?.removeSortFrom,
    //   rowConfig: sensorColumns?.rowConfig
    // },
    //----------------- Columns for Group table on controller details page
    Group: {
      headerList: {
        name: {
          label: "group name",
          type: "component",
          component: (row) => {
            return row?.data?.status === "inactive" ?
              <CustomTypography fontWeight={400} size={14} lineHeight={19} content={row?.data?.name} />
              :
              <Link className="link" to={`/spaces/groups?id=${row?.data?.groupId}`}>{row?.data?.name}</Link>
          }
        },
        devices: {
          label: "Device Count",
          type: "component",
          component: DeviceTypes,
        },
        sync: {
          label: "Sync Status",
          type: "component",
          component: (row) => <TableHelper.TableStatus type={row?.data?.synced ? "synced" : "needsSync"} variant={"filled"} label={row?.data?.synced ? "Synced" : "Needs Sync"} />,
          style: {},
        },
        userActions: {
          label: "Actions",
          actions: [
            {
              label: "Sync Group",
              type: "refresh",
              icon: SyncIcon,
              onClick: (event, data) => {
                event.preventDefault();
                dispatch(
                  groupActions.syncById(data.groupId)
                );
              },
              hidden: (row) => {
                return !isAuthorised(features?.device, permissions?.syncGroup) || row?.synced || disableDeviceOperation(controller, "groupSync");
              }
            },
            {
              type: "viewDetails",
              icon: ViewIcon,
              label: "View Details",
              hidden: !isAuthorised(features?.device),
              onClick: (event, data) => {
                history?.push(`/spaces/groups?id=${data?.groupId}`)
              }
            },
          ],
          style: { textAlign: "right" },
        },
      },
      sorting: { column: 'name' },
      rowConfig: { highlight: { bgKey: "status", bgValue: "inactive" }, disabled: { key: "status", value: "inactive" } },
      removeSortFrom: ["devices", "userActions"]
    },
    //----------------- Columns for Scene table on controller details page.
    Scene: {
      headerList: {
        name: {
          label: "SCENE NAME",
          type: "component",
          component: (row) => {
            return row?.data?.status === "inactive" ?
              <CustomTypography fontWeight={400} size={14} lineHeight={19} content={row?.data?.name} />
              :
              <Link className="link" to={`/spaces/scenes?id=${row?.data?.sceneId}`}>{row?.data?.name}</Link>
          },
        },
        devices: {
          label: "Device Count",
          type: "component",
          component: DeviceTypes,
        },
        sync: {
          label: "Sync Status",
          type: "component",
          component: (row) => <TableHelper.TableStatus type={row?.data?.synced ? "synced" : "needsSync"} variant={"filled"} label={row?.data?.synced ? "Synced" : "Needs Sync"} />,
          style: {},
        },
        userActions: {
          label: "ACTIONS",
          actions: [
            {
              label: "Sync Scene",
              type: "refresh",
              icon: SyncIcon,
              onClick: (event, data) => {
                event.preventDefault();
                dispatch(
                  sceneActions.syncById(data.sceneId)
                );
              },
              hidden: (row) => {
                return !isAuthorised(features?.device, permissions?.syncScene) || row?.synced || disableDeviceOperation(controller, "sceneSync");
              }
            },
            {
              label: "Apply scene",
              type: "apply",
              icon: ApplyIcon,
              hidden: (row) => {
                return !isAuthorised(features?.device, permissions?.applyScene) || disableDeviceOperation(controller, "sceneApply")
              },
              onClick: (event, data) => {
                // event.preventDefault();
                // setCurrentSceneId(data.sceneId);
                // mqttRequest(
                //   {
                //     subTopic: controller?.mqttTopics?.status,
                //     pubTopic: controller?.mqttTopics?.control,
                //     deviceId: controller?.deviceId,
                //     deviceConfig: { sceneId: data?.sceneId },
                //   },
                //   dispatch
                // );
                applyScene(data, dispatch, mqttClient, controller)
              },
            },
            {
              type: "viewDetails",
              icon: ViewIcon,
              label: "View Details",
              hidden: !isAuthorised(features?.device),
              onClick: (event, data) => {
                history?.push(`/spaces/scenes?id=${data?.sceneId}`)
              }
            },
          ],
          style: { textAlign: "right" },
        },
      },
      sorting: { column: 'name' },
      rowConfig: { highlight: { bgKey: "status", bgValue: "inactive" }, disabled: { key: "status", value: "inactive" } },
      removeSortFrom: ["devices", "userActions"]
    },
    Area: {
      headerList: {
        name: {
          label: "AREA NAME",
          type: "component",
          component: (row) => {
            return row?.data?.status === "inactive" ?
              <CustomTypography fontWeight={400} size={14} lineHeight={19} content={row?.data?.name} />
              :
              <Link className="link" to={`/spaces/areas?areaId=${row?.data?.areaId}`}>{row?.data?.name}</Link>
          },
        },
        // gatewayId: { //Needs to be changed to Name if available
        //   label: "Gateway Area Name",
        // },
        deviceTypes: {
          label: "Device Count",
          type: "component",
          component: DeviceTypes
        },
        syncStatus: {
          label: "Sync Status",
          type: "component",  //Haven't included lightColumns.sync because label was different
          component: (row) => <TableHelper.TableStatus type={row?.data?.synced ? "synced" : "needsSync"} variant={"filled"} label={row?.data?.synced ? "Synced" : "Needs Sync"} />,
          style: {}
        },
        userActions: {
          label: "Actions",
          actions: [
            {
              label: "Sync Area",
              type: "refresh",
              icon: SyncIcon,
              onClick: (event, data) => {
                event.preventDefault();
                dispatch(
                  areaActions.syncById(data.areaId)
                );
              },
              hidden: (row) => !isAuthorised(features?.device, permissions?.syncArea) || disableDeviceOperation(controller, "areaSync")
              // disabled : (row) => {
              //   return row.managedBy === 'cloud'
              // }
            },
            {
              type: "viewDetails",
              icon: ViewIcon,
              label: "View Details",
              hidden: !isAuthorised(features?.device),
              onClick: (event, data) => {
                history?.push(`/spaces/areas?areaId=${data?.areaId}`)
              }
            },
          ],
          style: { textAlign: "right" },
        }
      },
      sorting: { column: 'name' },
      removeSortFrom: ["deviceTypes", "userActions"],
      rowConfig: { highlight: { bgKey: "status", bgValue: "inactive" }, disabled: { key: "status", value: "inactive" } }
    },
  };

  const setGateway = (gateway) => {
    setCurrentGateway(gateway)
  }

  return {
    columns,
    setGateway,
  };
};

export default useTable;
