// import { updateSpaceTreeNode } from '../../services';
import { spaceTypes } from '../types';

let initialState = { 
    loading: false,
    list: [],
    spaceTree:[],
    filteredList: [],
    item: null,
    selectedSpace: null,
    rootSpace: null,
    error: null,
    deviceSpaceMoved:null,
    breadcrumbsData: [],
    assetObj:null,
    allAsset:null,
    givenAsset:null,
    entityObj:null,
    pngAsset:[],
    dwgAsset:[],
    updatedAsset: null,
    spaceLogs:{},
    subSpaceTree:null,
    currentSpace:null,
    spaceConnectedAsset:[]
}



export function spaces(state = initialState, action) {
    //console.log("tttt spaces action ------ ", action);
    switch (action?.type) {
        case spaceTypes.GET_REQUEST:
        case spaceTypes.GET_ALL_REQUEST:
        case spaceTypes.DELETE_REQUEST:
        case spaceTypes.GET_GIVEN_ASSET_REQUEST: 
        case spaceTypes.GET_ALL_ASSET_REQUEST: 
        case spaceTypes.GET_SPACE_LOGS_REQUEST:
        case spaceTypes.UPDATE_ASSET_CONFIGURATION_REQUEST:    
        case spaceTypes.UPDATE_ENTITY_CONFIGURATION_REQUEST:    
        case spaceTypes.GET_CURRENT_SPACE_REQUEST:    
            return {
                ...state,
                loading: true
            };
        case spaceTypes.GET_FILTERED_LIST:
            return {
                ...state,
                filtering: true
            };
        case spaceTypes.GET_ALL_SUCCESS:
            const getRootSpace=action?.payload && Array.isArray(action?.payload) ? action?.payload?.find(s=>s?.isRootSpace) : {}
            return {
                ...state,
                list: [getRootSpace, ...action?.payload.filter(item=>!item?.isRootSpace)], /// this ensures that root space always come in front of the array.
                filteredList: [getRootSpace, ...action?.payload.filter(item=>!item?.isRootSpace)], 
                rootSpace: getRootSpace,
                loading: false
            };
        case spaceTypes.GET_SUCCESS:
            // console.log('action get tree===>',action?.payload,  state.selectedSpace);
            return {
                ...state,
                // list: updateSpaceTreeNode(state?.list, action?.payload?.spaceId, {key: null, data: action?.payload}, action?.payload?.asset),
                list: state.list.map(l=>(l.spaceId === action.payload.spaceId ? action.payload : l)),
                item: action?.payload,               
                // selectedSpace: action?.payload,
                loading: false
            };
        case spaceTypes.GET_FILTERED_SUCCESS:
            return {
                ...state,
                filteredList: action?.payload,               
                filtering: false
            };
        case spaceTypes.ADD_SUCCESS:
            return {
                ...state,
                list: [...state.list, action.payload],
                // list: updateSpaceTreeNode(state?.list, action?.payload?.parentSid, {key: "Spaces", "data": action?.payload, "method": "push", "action": action}),
                item: action?.payload,
                loading: false
            };
        case spaceTypes.UPDATE_SUCCESS:
            return {
                ...state,
                // list: updateSpaceTreeNode(state?.list, action?.payload?.spaceId, {key: "Spaces", "data": action?.payload, "method": "update", "action": action}),
                list: state.list.map(l=>(l.spaceId === action.payload.spaceId ? action.payload : l)),
                filteredList: state.filteredList.map(l=>(l.spaceId === action.payload.spaceId ? action.payload : l)),
                item: action?.payload,
                loading: false
            };
        case spaceTypes.DELETE_SUCCESS:
            return {
                ...state,
                list: state.list.filter(l=>l.spaceId !== action.payload.spaceId),
                filteredList: state.filteredList.filter(l=>l.spaceId !== action.payload.spaceId),
                // list: updateSpaceTreeNode(state?.list, action?.payload?.parentId, {key: "Spaces", "method": "remove", "action": action, removeId: action?.payload?.spaceId }),
                loading: false
            };
        case spaceTypes.SELECT_SPACE_SUCCESS:
            return {
                ...state,
                selectedSpace: state?.list?.find(s=>s?.spaceId === action?.payload?.spaceId) ||  action?.payload,
                loading: false
            }
        case spaceTypes.BREADCRUMBS_SUCCESS:
            return {
                ...state,
                breadcrumbsData: action?.payload,
                loading: false
            }
        case spaceTypes.ADD_ASSET_SUCCESS:
            return {
                ...state,
                assetObj: action?.payload,
                loading: false
            }
            
        case spaceTypes.GET_ALL_ASSET_SUCCESS:
            return {
                ...state,
                allAsset: action?.payload,
                loading: false
            }
        case spaceTypes.GET_GIVEN_ASSET_SUCCESS:
            return{
                ...state,
                givenAsset: action?.payload,
                loading:false
            }    
        case spaceTypes.GET_PNG_ASSET_SUCCESS:
            return {
                ...state,
                pngAsset: state.pngAsset.find(a=>a?.assetId === action?.payload?.assetId) ? [...state.pngAsset] : [...state.pngAsset ,action?.payload],
                loading: false
            }
        case spaceTypes.GET_DWG_ASSET_SUCCESS:
            return {
                ...state,
                dwgAsset: state.dwgAsset.find(a=>a?.assetId === action?.payload?.assetId) ? [...state.dwgAsset] : [...state.dwgAsset ,action?.payload],
                loading: false
            }
        case spaceTypes.GET_SPACE_LOGS_SUCCESS:
            return {
                ...state,
                spaceLogs: action.payload
            }
        case spaceTypes.UPDATE_ASSET_CONFIGURATION_SUCCESS:
            return {
                ...state,
               updatedAsset: action?.payload,
                loading: false
            }
        case spaceTypes.UPDATE_ENTITY_CONFIGURATION_SUCCESS:
            return {
                ...state,
               entityObj: action?.payload,
                loading: false
            }
        case spaceTypes.CREATE_SPACE_TREE_SUCCESS: 
            return {
                ...state,
                spaceTree: action.payload,
                loading: false
            }
        case spaceTypes.UPDATE_SPACE_LIST:
            return{
                ...state,
                list: action?.payload,
                filteredList: action?.payload
            }
        case spaceTypes.CREATE_SUB_SPACE_TREE:
            return{
                ...state,
                subSpaceTree: action?.payload
            }
        case spaceTypes.GET_CURRENT_SPACE_SUCCESS:
            return{
                ...state,
                currentSpace: action?.payload
            }  
        case spaceTypes.SPACE_WITH_ASSET:            
            const filterArray = state?.spaceConnectedAsset?.filter(sca=> state?.list?.find(s=>s?.spaceId === sca?.spaceId));
            return{
                ...state,
                spaceConnectedAsset: action?.payload ? filterArray.find(sa=>sa?.spaceId === action?.payload?.spaceId) ? [...filterArray] : [...filterArray, action?.payload]:[]
                // spaceConnectedAsset: action?.payload 
            }    
        case spaceTypes.GET_FAILURE:
        case spaceTypes.GET_ALL_FAILURE:
        case spaceTypes.GET_GIVEN_ASSET_FAILURE:
        case spaceTypes.GET_SPACE_LOGS_FAILURE:
        case spaceTypes.DELETE_FAILURE:
        case spaceTypes.GET_FILTERED_FAILURE:
        case spaceTypes.UPDATE_ENTITY_CONFIGURATION_FAILURE:
            return {
                ...state,
                error: action?.payload,
                loading: false,
                filtering: false
            };
        default:
            return state
    }
}

