import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    SceneDetails: {
    },

    permissionNameWrapper: {
        display: 'flex',
        marginBottom: '2rem',

        '@media (max-width: 750px)': {
            flexDirection: 'column'
        }
    },

    permissionTypeHeader: {
        display: 'flex', 
        justifyContent: 'space-around', 
        width: '100%', 
        paddingLeft: '110px'
    },

    permissionContainer: {
        display: 'flex', 
        alignItems: 'center', 
        py: 1
    },

    entityLabelContainer: {
        display: 'flex', 
        minWidth: '100px', 
        paddingLeft: '1rem'
    },

    entityCheckboxContainer: {
        display: 'flex', 
        width: '100%', 
        justifyContent: 'space-around'
    },
});