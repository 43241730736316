/***
 *
 * Controller class for user.
 * @file GatewayStatistics.js
 * @description GatewayStatistics component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import { Avatar, Box, CardHeader, Grid, Typography } from '@mui/material';
import React from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './GatewayStatistics.style.js';
import PageHeader from '../../components/PageHeader/index.js';
import StyledButton from '../../components/StyledButton/index.js';
import { getInitials, isAuthorised, stringAvatar } from '../../helpers/users.helper.js';
import { features, permissions } from '../../constants/permission.constants.js';
import Gateway from "../../assets/icons/singleantenna.svg";
import StyledPopupWrapper from '../../components/StyledPopupWrapper/index.js';
import { getMessageStr } from '../../helpers/message.helper.js';
import FormWrapper from '../../components/FormWrapper/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { deviceActions } from '../../redux/actions/index.js';
import StyledDashboardCounter from '../../components/StyledDashboardCounter/index.js';
import CustomAccordion from '../../components/CustomAccordion/index.js';
import { useEffect } from 'react';
import { getDeviceDetailHeaders } from '../../helpers/device.helper.js';
import ItemView from '../../components/ItemView/index.js';
import _ from 'lodash';
import GridView from '../../components/GridView/index.js';
import StyledCardWrapper from '../../components/StyledCardWrapper/index.js';
import ImageWrapper from '../../components/ImageWrapper/index.js';
import panasonicLogo from '../../assets/logo/panasonic.png'
import { Link } from 'react-router-dom';
import { useState } from 'react';
import CustomTypography from '../../components/CustomTypography/index.js';
import DataNotFound from '../DataNotFound/index.js';
import StyledTabPanel from '../../components/StyledTabPanel/index.js';
import gatewayIcon from '../../assets/icons/singleantenna.svg'
import CustomTable from '../../components/CustomTable/index.js';
import useTable from '../../hooks/useTable.js';
import DebugGateway from '../../components/DebugGateway/index.js';
import StyledPopup from '../../components/StyledPopup/index.js';
import { useQuery } from '../../hooks/useQuery.js';
import { history } from '../../helpers/history.helper.js';



const actionButtons = (closeDialog, validateGateway) => {
  return [
    {
      heading: null,
      columns: [
        {
          buttons: [
            {
              id: "1000",
              type: "button",
              display: true,
              variant: 'outlined',
              label: "Cancel",
              onClick: () => {
                closeDialog();
              }
            },
            {
              id: "1000",
              type: "button",
              display: true,
              checkValidation: true,
              label: "Validate",
              onClick: (event, data) => {
                validateGateway(event, data);
              },
            },
          ],
          columnSize: 12,
        },
      ],
    },
  ];
}
const formConfig =
  [{
    section: { heading: null },
    columns: [
      {
        id: "4",
        type: "text",
        label: "MAC Address",
        name: "macAddress",
        placeholder: "",
        required: true,
        validation: {
          req: [true, getMessageStr("device-mac-required")],
          macAddress: [true, getMessageStr("device-mac-invalid")],
          minLen: [17, getMessageStr("device-mac-minlength")],
          maxLen: [24, getMessageStr("device-mac-maxlength")],
        },
        columnSize: 6,
      },
    ]
  }];
const gatewayStats = [{ label: "Total Gateways", key: "totalNOOfGateways", bgColor: ["#FBF6DF", "rgba(251, 246, 223, 0.25)"] }, { label: "Assigned", key: "totalNOOfLinkedGateways", bgColor: ["#E5F2FA", "rgba(229, 242, 250, 0.25)"] }, { label: "Non Assigned", key: "totalNOOfNotLinkedGateways", bgColor: ["#EFE8F8", "rgba(239, 232, 248, 0.25)"] }]

const GatewayCard = ({ data, ...props }) => {
  const tenantStats = [{ label: "Total Gateways", key: "totalNOOfGateways", bgColor: ["#FBF6DF", "rgba(251, 246, 223, 0.25)"] }, { label: "Onboarded", key: "totalNoOfGatewayOnboarded", bgColor: ["#E5F2FA", "rgba(229, 242, 250, 0.25)"] }, { label: "Non Onboarded", formula: (data) => { return ((data?.totalNOOfGateways - data?.totalNoOfGatewayOnboarded) || 0) }, bgColor: ["#EFE8F8", "rgba(239, 232, 248, 0.25)"] }]

  return <StyledCardWrapper className="tenantCard">
    <CardHeader
      className="cardHeader"
      title={<Link className="link" to={`/tenants?op=view&name=${data?.tenantName}`}>{data?.tenantName}</Link>}
      avatar={data?.logoBitmap ?
        <ImageWrapper src={data?.logoBitmap} height={16} width={"auto"} /> :
        <Avatar
          {...stringAvatar(data?.tenantName)}
        />}
      titleTypographyProps={{ fontWeight: "600", fontSize: '0.9rem' }}
    />
    <Grid container spacing={1} sx={{ p: 1 }} className="cardContent">
      {tenantStats?.map((s, ind) => {
        return <Grid item xs={6} md={4} key={ind}>
          <StyledDashboardCounter data={{ label: s?.label, counter: s?.hasOwnProperty("key") ? (data[s?.key] || 0) : s?.formula(data) }} sx={{ background: s?.bgColor, height: "100%" }} counterSx={{ fontSize: "1.5rem !important", marginTop: "8px" }} labelSx={{ fontSize: "0.781rem !important", minHeight: "2rem" }} />
        </Grid>
      })}
    </Grid>
  </StyledCardWrapper>
}

const GatewayStatistics = (props) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const query = useQuery();
  const type = query?.get("type") || "tenantGateways";

  const [dialog, setDialog] = useState(false);
  const [dialogContents, setDialogContents] = useState({})
  const [dialogWrapper, setDialogWrapper] = useState(false);
  const [dialogWrapperContents, setDialogWrapperContents] = useState({})
  const [formData, setFormData] = useState({ macAddress: "" });
  const [table, setTable] = useState({ cellMatrix: [] });
  const [statistics, setStatistics] = useState({
    "totalNOOfGateways": 0,
    "totalNOOfLinkedGateways": 0,
    "totalNOOfNotLinkedGateways": 0,
    "tenant": []
  })
  const tabs = [
    {
      label: "Tenant Gateway View", icon: gatewayIcon, id: "tenantGateways", onClick: () => {
        dispatch(deviceActions?.getGatewayStatistics());
      }
    },
    // {
    //   label: "Assigned Gateways", icon: gatewayIcon, id: "assignedGateways", onClick: () => {
    //   }
    // },
    {
      label: "Unassigned Gateways", icon: gatewayIcon, id: "unassignedGateways", onClick: () => {
        dispatch(deviceActions?.getAssignedGateways({}, null, false));
      }
    }];
  const [selectedTab, setSelectedTab] = useState(tabs[0]?.id)

  const { columns } = useTable({ setDialogWrapper, setDialogWrapperContents, setDialog, setDialogContents, mqttClient: props?.mqttClient });
  const deviceList = useSelector(state => state?.devices?.deviceList)
  const device = useSelector(state => state?.devices?.deviceDetails);
  const statisticsData = useSelector(state => state?.devices?.gatewayStatistics);

  const validateGateway = (e, data) => {
    dispatch(deviceActions?.getGatewayDetails(data?.macAddress))
  }

  useEffect(() => {
    dispatch(deviceActions?.getGatewayStatistics());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setStatistics(statisticsData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statisticsData])

  useEffect(() => {
    setDialogWrapperContents({ ...dialogWrapperContents, data: device })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device])

  useEffect(() => {
    delete columns?.GatewayList?.headerList?.gatewayOnboarded;
    setTable({ cellMatrix: deviceList, ...columns?.GatewayList })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceList])

  useEffect(() => {
    setSelectedTab(type)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  return (
    <Box className={classes?.GatewayStatistics || ""} data-testid="GatewayStatistics">
      <PageHeader
        backbtn={false}
        title={"Gateway Management"}
        icon={Gateway}
      >
        <Box sx={{ textAlign: "right" }}>
          {/* {isAuthorised(features?.device, permissions?.add) && */}
          <StyledButton sx={{ ml: 1 }} onClick={() => {
            setDialogWrapper(true);
            setDialogWrapperContents({ title: "Validate Gateway", operation: "validateGateway", data: null })
          }}>
            Validate Gateway
          </StyledButton>
          {/* } */}
        </Box>
      </PageHeader>
      <Grid container spacing={1.5} sx={{ mb: 2 }}>
        {gatewayStats?.map((s, ind) => {
          return <Grid item xs={6} sm={4} key={ind}>
            <StyledDashboardCounter data={{ label: s?.label, counter: statistics[s?.key] }} sx={{ background: s?.bgColor }} />
          </Grid>
        })}
      </Grid>
      {/* <Box sx={{ background: "rgba(15, 141, 72, 0.05)", p: 1, mb: 2 }}>
        <CustomTypography
          weight={600}
          size={14}
          lineHeight={17}
          styles={{ margin: "8px" }}
          content={"Tenants Gateway View"}
        />
      </Box> */}
      <StyledTabPanel
        tabs={tabs}
        selectedIndex={tabs?.findIndex(t => t?.id === selectedTab)}
        onClick={(e, val) => {
          const tab = tabs[val];
          history?.push(`/gateways?type=${tab?.id}`)
          tab?.onClick()
        }}
      >
        <DataNotFound
          show={selectedTab === "tenantGateways" ? statistics?.tenant?.length === 0 : table?.cellMatrix?.length === 0}
          customsx={{ image: { height: '202px', width: '100%' }, label: {}, content: {}, btns: {} }}
          data={{
            content: getMessageStr(selectedTab === "tenantGateways" ? "tenant-gatewayStatistics-empty" : "tenant-unassignedGateways-empty"),
            action: []
          }}
        >
          {selectedTab === "tenantGateways" ?
            <GridView
              cardsPerRow={{ lg: 6 }}
              data={statistics?.tenant}
              card={({ data, ...props }) => {
                return <GatewayCard data={data}></GatewayCard>
              }}
            >

            </GridView>
            :
            <Box>
              <CustomTable
                {...table}
                pagination={true}
              />
            </Box>
          }

        </DataNotFound>
      </StyledTabPanel>


      <StyledPopupWrapper maxWidth="lg" fullWidth={dialogWrapperContents?.operation === "debugGateway"} title={dialogWrapperContents?.title} open={dialogWrapper}>
        {dialogWrapperContents?.operation === "debugGateway" ?
          <DebugGateway mqttClient={props?.mqttClient}
            setDialog={setDialog}
            setDialogWrapper={setDialogWrapper}
            setDialogContents={setDialogContents}
            gateway={dialogWrapperContents?.data}
            tenantDetails={{ tenantName: "default" }} />
          :
          <Box sx={{ minWidth: { xs: "auto", sm: "650px", md: "800px" } }}>
            <FormWrapper formFields={formConfig} formButtons={actionButtons(() => {
              setDialogWrapperContents({ ...dialogWrapperContents, data: null });
              setDialogWrapper(false);
            }, validateGateway)} formData={formData} customsx={{ formbtn: { my: 2 } }} >
            </FormWrapper>
            {
              dialogWrapperContents?.data !== null && (
                _.isEmpty(dialogWrapperContents?.data) ?
                  <Box>{"No Gateway Details Found"}</Box>
                  :
                  <Box sx={{ width: "100%", maxWidth: "800px" }}>
                    <CustomAccordion
                      title={"Gateway Details"}
                    >
                      <ItemView
                        data={dialogWrapperContents?.data}
                        headers={getDeviceDetailHeaders("gatewayDetails", dialogWrapperContents?.data, dispatch)}
                      />
                    </CustomAccordion>
                  </Box>
              )}
          </Box>
        }
      </StyledPopupWrapper>
      <StyledPopup
        open={dialog}
        onClose={() => { setDialog(false) }}
        state={dialogContents?.state}
        data={dialogContents?.content}
        component={dialogContents?.component}
        customsx={{
          btns: { display: "flex", justifyContent: "center" },
        }}
      />

    </Box>
  )
};

GatewayStatistics.propTypes = {};

GatewayStatistics.defaultProps = {};

export default GatewayStatistics;
