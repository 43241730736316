/***
 *
 * Controller class for user.
 * @file TopBar.js
 * @description TopBar component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React from "react";
import { AppBar, Toolbar, IconButton, MenuItem, Menu, Box } from "@mui/material";
import Badge from '@mui/material/Badge';
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { useStyles } from './TopBar.style';
import { Link as NavLink } from 'react-router-dom';
//  import {Link as MuiLink} from '@mui/material/Link'
// import PropTypes from 'prop-types';
import './TopBar.scss';
import ImageWrapper from "../ImageWrapper";
import Avatar from '@mui/material/Avatar';
import { useDispatch, useSelector } from "react-redux";
import { appActions, userActions } from "../../redux/actions";
import { useEffect } from "react";
import CustomTypography from "../CustomTypography";
import TenantLogo from '../../assets/logo/panasonic.png';
import PopoverContainer from "../PopoverContainer";
import AlertsPopover from "../AlertsPopover";
import { isAuthorised } from "../../helpers";
import StyledButton from "../StyledButton";
import { features, permissions } from "../../constants";

const TopBar = () => {
  const getUserProfile = useSelector(state => state?.users?.userSelfProfile);
  const _theme = useSelector(state => state?.app?.theme);
  const _portal = useSelector(state => state?.app?.portal)
  const _webConfig = useSelector(state => state?.app?.webConfig)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [, setToggleTheme] = React.useState(false);

  const styles = useStyles();
  const dispatch = useDispatch();
  // const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  useEffect(() => {
    if (_theme === 'light') {
      setToggleTheme(false);
    }
    else {
      setToggleTheme(true);
    }
  }, [_theme])

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleLogOut = () => {
    // localStorage.removeItem("miraie");
    // sessionStorage.removeItem("miraie-selected-menu");
    dispatch(userActions?.logout());
    // localStorage.clear();
    // sessionStorage.clear();
    // window.location.href = "/";
  }

  // const handleMobileMenuOpen = (event) => {
  //   setMobileMoreAnchorEl(event.currentTarget);
  // };

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  var getInitials = function (string) {
    var names = string.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  function stringAvatar(name, h = 34, w = 34) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: w,
        height: h,
        fontSize: '1.875rem'
      },
      children: getInitials(name),
    };
  }

  // const SmallAvatar = styled(Avatar)(({ theme }) => ({
  //   width: 24,
  //   height: 24,
  //   border: '2px solid',
  // }));

  // const handleToggleTheme = (e, checked) => {
  //   setToggleTheme(checked);
  //   if (checked) {
  //     dispatch(appActions.setTheme('dark'));
  //   }
  //   else {
  //     dispatch(appActions.setTheme('light'));
  //   }
  // }

  const renderMenu = (
    <PopoverContainer
      className="iconStyles"
      anchorEl={anchorEl}
      // keepMounted

      // transformOrigin={{ vertical: "top", horizontal: "right" }}
      // open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Box sx={{ minWidth: '300px' }} textAlign="center" display="table" justifyContent="center" alignItems="center">
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        // badgeContent={
        //   <SmallAvatar src="../../assets/img/smalledit.jpg" />
        // }
        >
          <Avatar sx={{ fontSize: '2.5rem', height: '70px', width: '70px' }} className={styles.Avatar} {...stringAvatar(`${getUserProfile?.name}`, 120, 120)} />
        </Badge>
        <CustomTypography sx={{ mt: 1 }} content={getUserProfile?.name} size={16} weight={700} lineHeight={19} />
        {getUserProfile?.email &&
          <CustomTypography size={14} color={'#00000099'} content={getUserProfile?.email} />
        }
        {getUserProfile?.lastLoggedInTs && <></>
          // <CustomTypography size={14} color={'#00000099'} sx={{ mb: 1 }} content={`Last login: ${format(getUserProfile?.lastLoggedInTs, "MMM d, yyyy 'at' hh:mm a")}`} />
        }
        <NavLink to={'/profile'} className="navlink" onClick={() => {
          dispatch(appActions.selectTopMenu(1211));
          handleMenuClose()
        }} > <CustomTypography size={14} color={'#0F8D48'} content={'View Profile'} /></NavLink>
        {/* <NavLink to={'/'} className="navlink" ><MenuItem onClick={handleMenuClose}> My account</MenuItem></NavLink> */}
        <StyledButton variant="outlined" sx={{ mt: 1 }} fullWidth onClick={handleLogOut}> Logout</StyledButton>
      </Box>
    </PopoverContainer>
  );

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge overlap="rectangular" badgeContent={11} color="secondary">
            <NotificationsNoneIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          {/* <Avatar {...stringAvatar(`${getUserProfile?.username}`)} /> */}
          {/* <AccountCircle /> */}
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box className="TopBar" data-testid="TopBar">
      <AppBar className={styles?.TopNavigation || ""} position="fixed" elevation={3}>
        <Toolbar className={styles?.mainAppBar || ""}>
          {/* <IconButton
             href="/"
             component={Link}
             className="app-logo logo"
             color="inherit"
             aria-label="open drawer"
             sx={{ padding: "0px" }}>
             <ImageWrapper className="imageLogo" src={LOGO} alt="logo" />
           </IconButton>
           <span className={styles?.vBar || ""} />
           <Typography variant="h6" noWrap component="div" className="title" style={{ width: '100%' }}>
             Spatial Solutions
           </Typography> */}

          <Box className="app-logo logo" sx={{ width: '100%' }}>
            <ImageWrapper src={_portal === "mss" ? _webConfig?.webApp?.logoBitmap : TenantLogo} alt="tenant logo" width={120} />
          </Box>

          <Box className="sectionDesktop" style={{ display: 'flex', minWidth: "min-content", justifyContent: 'flex-end', alignItems: 'center', maxHeight: 38 }}>
            {/* <Box sx={{ mr: 2, fontSize: '0.75rem', textAlign: 'left', color: "#666" }}>
              {getUserProfile?.lastLoggedInTs &&
                <>
                  <strong>Last logged in</strong>
                   <p style={{ width: "max-content", margin: 0 }}>{format(getUserProfile?.lastLoggedInTs, "MMM d, yyyy 'at' hh:mm a")}</p>
                </>
              }
            </Box> */}

            {isAuthorised(features?.alert, permissions?.view) && <AlertsPopover />}

            {/* Switch button for theme change */}
            {/* <SwitchButton type="iphone" setToggle={toggleTheme} onChange={(e, checked) => { handleToggleTheme(e, checked) }} /> */}

            {/* Notification icon */}
            {/* <IconButton aria-label="show 0 new notifications" color="inherit" style={{ color: 'black' }}>
               <Badge overlap="rectangular" badgeContent={0} color="secondary">
                 <NotificationsNoneIcon className="iconStyles" />
               </Badge>
             </IconButton> */}

            <StyledButton
              iconButton
              edge="end"
              aria-label="account of current user"
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
              style={{ color: 'black' }}
            >
              <Avatar {...stringAvatar(`${getUserProfile?.name}`)} />
              {/* <AccountCircle className="iconStyles" /> */}
            </StyledButton>
          </Box>

          {/* <Box className="sectionMobile">
            <IconButton
              aria-label="show more"
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon className="iconStyles" />
            </IconButton>
          </Box> */}
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  )
};

TopBar.propTypes = {};

TopBar.defaultProps = {};

export default TopBar;
