/***
 *
 * Controller class for user.
 * @file MainLayout.js
 * @description MainLayout component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 *
 */

import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from 'react-redux';
import { appActions, deviceActions, userActions } from '../../redux/actions';
import { getWindowDimensions } from '../../services';
import { useQuery } from '../../hooks';
import { getSessionItem, history } from '../../helpers';
// import routes from '../../routes/routes';
import { defaultSelectedMenu } from '../../config/appConfig';
import NotFoundPage from '../../pages/NotFoundPage';
import MssLayout from "../MssLayout";
import TmsLayout from "../TmsLayout";
// import TwoDLayout from '../TwoDLayout';

const screenWidth = getWindowDimensions()?.width;

export const drawerWidth =
  screenWidth > 1200 ? 256 : screenWidth > 600 ? 236 : 208;

const MainLayout = ({ children, ...props }) => {
  const query = useQuery();

  const dispatch = useDispatch();
  const portal = useSelector((state) => state?.app?.portal);

  const [mobileView, setMobileView] = useState(false);
  const [screenSize, setScreenSize] = useState(getWindowDimensions());
  const [expanded, setExpanded] = useState(true);

  // useEffect(() => {
  //   onMessageListener().then((payload) => {
  //     setAlerts([...alerts, {
  //       id: payload.notification.body,
  //       title: payload.notification.title,
  //       body: payload.notification.body,
  //     }]);
  //     console.log("Notification recieved", payload);
  //   })
  //   .catch((err) => console.log("Notification failed: ", err));

  //   history.listen((location, action) => {
  //     dispatch(alertActions.clear());
  //   });
  // }, [dispatch]);

  useEffect(() => {
    setScreenSize(getWindowDimensions());
    dispatch(userActions.getSelfProfile({ portal: portal }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setScreenSize(getWindowDimensions());
  }, [mobileView]);

  useEffect(() => {
    setExpanded(getWindowDimensions().width > 800 ? true : false);
  }, [screenSize]);

  useEffect(() => {
    // To identify the webView on mobile application
    setMobileView(
      query?.get("X-MobileApp-WebView")?.toLowerCase() === "true" ? true : false
    );

    const cRoute = props?.routes?.find(r => (("/" + r.path) === history?.location?.pathname));
    const sMenu = JSON.parse(getSessionItem("miraie-selected-menu") || JSON.stringify(defaultSelectedMenu));

    if (sMenu["left"] !== cRoute?.id) {
      if (!cRoute?.parent) {
        sMenu["left"] = cRoute?.id;
      } else {
        sMenu["left"] = cRoute?.parent;
        sMenu["top"] = cRoute?.id;
      }
    }

    dispatch(appActions.selectLeftMenu(sMenu["left"]));
    dispatch(appActions.selectTopMenu(sMenu["top"]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, query]);
  return (
    <>
      {props?.routes?.find(r => history.location.pathname === `/${r?.path}`) ? (
        portal === "mss" ? (
          <MssLayout expanded={expanded} setExpanded={setExpanded} mobileView={mobileView} drawerWidth={drawerWidth} {...props}>{children}</MssLayout>
        ) : (
          <TmsLayout expanded={expanded} setExpanded={setExpanded} mobileView={mobileView} drawerWidth={drawerWidth} {...props}>{children}</TmsLayout>
        )
      ) : (
        <NotFoundPage />
      )}
    </>
  );
};

MainLayout.propTypes = {};

MainLayout.defaultProps = {};

export default MainLayout;
