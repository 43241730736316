/***
 *
 * Controller class for user.
 * @file LocationSpaceTree.js
 * @description LocationSpaceTree component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import { Box, Grid } from '@mui/material'
import _ from 'lodash'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deviceActions } from '../../redux/actions'
import StyledTree from '../StyledTree'
import CustomTypography from '../CustomTypography'
import ImageWrapper from '../ImageWrapper'
import MoveIcon from '../../assets/icons/move.svg';
import { useStyles } from './LocationSpaceTree.style'
import StyledPopupWrapper from '../StyledPopupWrapper'
import StyledButton from '../StyledButton'


const LocationSpaceTree = ({ data, disabled = false, disableTooltip = false, tooltip = null, showLabel = false, customsx = { container: {}, label: {}, icon: {} }, onSave = () => { }, onCancel = () => { }, ...props }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const spaceList = useSelector((state) => state?.spaces?.list);

  const [openLocationTree, setOpenLocationTree] = React.useState(false);
  const [value, setValue] = useState(data);

  const handlelocation = () => {
    onSave();
    setTimeout(() => {
      dispatch(deviceActions.moveDevice(value.spaceId, data?.deviceId));
    }, 1000)
    setOpenLocationTree(false);
  }
  return (
    <>
      <Grid item container xs={12} md={12} className={classes.LocationSpaceTree} alignItems="center" justifyContent={"space-between"} >
        <Box
          sx={{ display: "flex", alignItems: "center", ...customsx?.container }}
          onClick={(e) => { !disabled && setOpenLocationTree(true) }}
        >
          <StyledButton
            tooltip={!disableTooltip && (tooltip ? tooltip : "Move to new space")}
            iconButton
            sx={{ padding: "0px", ...customsx?.icon }}
            disabled={disabled}
          >
            <ImageWrapper width={14} height={14} src={MoveIcon} className={'move'} />
          </StyledButton>
          {
            showLabel &&
            <CustomTypography
              size={12}
              weight={500}
              lineHeight={15}
              style={{ ...customsx?.label }}
            >
              Move to new space
            </CustomTypography>
          }
        </Box>
      </Grid>
      {/* <PopoverContainer anchorEl={openLocationTree} onClose={() => setOpenLocationTree(false)} style={{ zIndex: 1400 }}> */}
      <StyledPopupWrapper title={"Change Location"} open={openLocationTree} closeButton={false}>
        {/* <CustomTypography content={"Change Location"} size={14} lineHeight={17.07} weight={600} /> */}
        {!_.isEmpty(spaceList) &&
          <StyledTree
            data={spaceList[0]}
            selectedItems={[value?.spaceId]}
            search={false}
            type='radio'
            idKey='spaceId' childrenKey={'Spaces'}
            setterFunction={(value) => { setValue(value); }} />
        }
        <Grid item container xs={12} md={12} sx={{ mt: 1.5 }} justifyContent="flex-end">
          <StyledButton variant='outlined' sx={{ mr: '10px', minWidth: "90px" }} onClick={() => { setOpenLocationTree(false); onCancel() }}>Cancel</StyledButton>
          <StyledButton sx={{ minWidth: "90px" }} onClick={handlelocation}>Save</StyledButton>
        </Grid>
      </StyledPopupWrapper>
    </>
  )
}


LocationSpaceTree.propTypes = {};

LocationSpaceTree.defaultProps = {};

export default LocationSpaceTree;
