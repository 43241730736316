/***
 *
 * Controller class for user.
 * @file GridView.js
 * @description GridView component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './GridView.style.js';
import './GridView.scss';
import { Box, Grid, Pagination } from '@mui/material';
import { useEffect } from 'react';
import StyledSpinner from '../StyledSpinner/index.js';
import { useState } from 'react';
import CustomTypography from '../CustomTypography/index.js';
import CustomDropDown from '../CustomDropDown/index.js';
import { ScrollToTop, delay } from '../../services/util.service.js';
import { useSelector } from 'react-redux';

const GridView = ({ data, card, cardStyle, cardsPerRow = {}, ...props }) => {

  const [gridData, setGridData] = useState({ header: {}, data: [] });
  const [cardsPerPage, setCardsPerPage] = useState(12);
  const [page, setPage] = useState(1);
  const [, setStartIndex] = useState(0);
  const [loader, setLoader] = useState(false);

  const sideMenuOpen = useSelector(state => state?.app?.sideBarOpen)
  const classes = useStyles(sideMenuOpen)();


  const handleChange = (e) => {
    Promise.resolve().then(() => {
      ScrollToTop();
    })
      .then(delay(100))
      .then(() => {
        setLoader(true);
        setCardsPerPage(Number(e?.target?.value));
        setStartIndex(0);
        setPage(1)
      })
      .then(delay(100))
      .then(() => {
        setGridData(data?.slice(0, e?.target?.value));
      })
  }

  useEffect(() => {
    setStartIndex(0);
    setPage(1);
    setCardsPerPage(12);
    setLoader(true);
    setGridData(Array.isArray(data) && data?.slice(0, 12))
  }, [data])

  useEffect(() => {
    // if (loader) {
    //   setTimeout(() => {
    setLoader(false);
    // }, 0)
    // }
  }, [gridData]);



  return (
    <Box className={classes?.GridView || ""} data-testid="GridView" sx={{ position: "relative" }} >
      {loader &&
        <Box sx={{ position: 'absolute', top: 0, bottom: 0, width: "100%", backgroundColor: "rgba(255, 255, 255, 0.95)", zIndex: 1011 }}>
          <StyledSpinner sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
        </Box>
      }
      <Grid container spacing={2}>
        <Grid spacing={{ md: 2, xs: 1.5 }} container item sx={{ width: "-webkit-fill-available !important", pb: 1, mb: -1, pr: 1, mr: -1, overflowX: "auto" }}>
          {Array.isArray(gridData) && gridData?.map((d, ind) => {
            return (
              <Grid key={ind} className="cardItem" item xxl={3} xl={4} lg={4} md={6} sm={6} xs={12} {...cardsPerRow}>
                <Box sx={{ position: "relative" }}>
                  {d?.loading &&
                    <Box sx={{ position: 'absolute', top: 0, bottom: 0, width: "100%", backgroundColor: "rgba(255, 255, 255, 0.8)", zIndex: 1001 }}>
                      <StyledSpinner sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
                    </Box>
                  }
                  {typeof card === "function" ? card({ data: d, ...props }) : card}

                </Box>
              </Grid>
            )
          })}
        </Grid>
        <Grid container item xs={12} spacing={3} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Grid item xs={12} sm="auto">
            {
              data?.length > 4 &&
              <Box display={'flex'} alignItems='center'>
                <CustomTypography content="Cards per page" styles={{ marginRight: '5px' }} size={15} />
                <CustomDropDown
                  value={cardsPerPage}
                  onChange={handleChange}
                  onOpen={null}
                  isNoneAsItem={false}
                  customsx={{
                    minWidth: "40px",
                    width: "max-content",
                    '&.MuiOutlinedInput-root': {
                      '& .MuiOutlinedInput-input': {
                        padding: '5px 25px 5px 8px',
                        paddingRight: "25px !important",
                        lineHeight: '115%',
                        height: "min-content",
                        fontSize: '0.875rem',
                        minWidth: "10px",
                        width: "max-content"
                      },
                      '& .MuiSelect-icon': {
                        right: '0px'
                      }
                    }
                  }}
                  customdropsx={{ width: 'max-content', minWidth: "40px", pr: '10px' }}
                  list={[{ id: '4', label: '4' }, { id: '12', label: '12' }, { id: '20', label: '20' }, { id: '40', label: '40' }, { id: '100', label: '100' }]}>
                </CustomDropDown>
              </Box>
            }
          </Grid>
          <Grid item xs={12} sm="auto" sx={{ justifyContent: "center", display: "flex" }}>
            {
              Math.ceil((data?.length || 0) / cardsPerPage) > 1 &&
              <Pagination
                count={Math.ceil((data?.length || 0) / cardsPerPage)}
                sx={{ ml: 1 }}
                page={page}
                size={'medium'}
                variant={'text'}
                onChange={(e, pageNumber) => {
                  const startingIndex = (pageNumber - 1) * cardsPerPage;
                  setLoader(true);
                  setPage(pageNumber);
                  setStartIndex(startingIndex);
                  setGridData(data?.slice(startingIndex, (startingIndex + cardsPerPage)));
                  ScrollToTop();
                }}
              />
            }
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
};

GridView.propTypes = {};

GridView.defaultProps = {};

export default GridView;

