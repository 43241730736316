/***
 *
 * Controller class for user.
 * @file StyledCard.js
 * @description StyledCard component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './StyledCard.style.js';
import './StyledCard.scss';
import { AvatarGroup, Box } from '@mui/material';
import StyledCardWrapper from '../StyledCardWrapper/index.js';
import ImageWrapper from '../ImageWrapper/index.js';
import CustomTypography from '../CustomTypography/index.js';
import { Link } from 'react-router-dom';
import CheckCircle from "../../assets/icons/check-circle.svg";
import AlertIcon from "../../assets/icons/alert-icon.svg";
import PopoverContainer from '../PopoverContainer/index.js';

const StyledCard = ({
  config = {
    heading: {
      style: { textTransform: 'capitalize' }
    },
    mediaList: {
      style: {}
    },
    info: {
      style: {}
    },
    footer: {
      style: {}
    }
  },
  data = {
    heading: {
      label: "",
      to: ""
    },
    mediaList: {
      max: 6,
      images: []
    },
    info: {
      text: "",
      secondText: null,
      count: null
    },
    footer: {
      lastUpdated: null
    }
  },
  disabled = false,
  onClick = () => { }
}) => {
  const classes = useStyles();
  const [tooltip, setTooltip] = useState({ show: false });

  const handleMouseEnter = (event, content) => {
    setTooltip({
      show: true,
      anchorElement: event.currentTarget,
      content: content
    })
  }
  const handleMouseLeave = () => {
    setTooltip(null);
  }

  return (
    <StyledCardWrapper sx={{
      ...(() => {
        return disabled ? { borderLeft: "3px red solid" } : {}
      })()
    }}>
      <Box className={classes?.StyledCard || ""} data-testid="StyledCard" >
        <Box style={config?.topBar?.style}>{data?.topBar}</Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, width: '100%', minHeight: "34px" }}>
          <Box sx={{ width: '100%', display: "flex" }}>
            <Box className='card-heading'>
              {data?.heading?.to ? (
                <Link className='link' to={data?.heading?.to} style={{ marginRight: "10px", fontSize: '1.125rem', ...config?.heading?.style }} onMouseEnter={(e) => handleMouseEnter(e, data?.heading?.label)} onMouseLeave={handleMouseLeave}>{data?.heading?.label || data?.heading?.to || "Label"}</Link>
              ) : (<CustomTypography content={data?.heading?.label || "Label"} styles={{ marginRight: "10px", fontSize: '1rem', ...config?.heading?.style }} onMouseEnter={(e) => handleMouseEnter(e, data?.heading?.label)} onMouseLeave={handleMouseLeave} />)
              }
            </Box>
            {data?.status &&
              <ImageWrapper height={15} width={15} src={data?.status?.active ? CheckCircle : AlertIcon} onMouseEnter={(e) => handleMouseEnter(e, data?.status?.text)} onMouseLeave={handleMouseLeave} />
            }
          </Box>
          {data?.heading?.actions &&
            <Box sx={{ textAlign: "right", width: "max-content" }}>
              {data?.heading?.actions}
            </Box>
          }
        </Box>
        <Box className="image-array" sx={{ minHeight: "44px" }}>
          <AvatarGroup max={data?.mediaList?.max}>
            {data?.mediaList?.images?.map((img, index) => {
              return (
                <ImageWrapper key={index} src={img?.src} style={{ border: `${img?.borderInfo || "1px solid #e1e1e1"}`, ...config?.mediaList?.style }} />
              )
            })}
          </AvatarGroup>
        </Box>

        {data?.info &&
          <Box sx={{ display: 'flex', py: 1, px: 0.4 }}>
            {(data?.info?.text || data?.info?.secondText) &&
              <Box style={{ width: "100%" }}>
                {data?.info?.text &&
                  <CustomTypography content={data?.info?.text} variant="body2" styles={{ textAlign: 'left', width: "100%", display: "block", fontWeight: "600", color: "#666", ...config?.info?.style }} />
                }
                {data?.info?.secondText &&
                  <CustomTypography content={data?.info?.secondText} variant="body2" styles={{ textAlign: 'left', width: "100%", fontSize: '0.625rem', color: "#555", display: "block", lineHeight: "12px", ...config?.info?.style }} />
                }
              </Box>
            }
            {data?.info?.count &&
              <CustomTypography content={data?.info?.count} variant="body2" styles={{ textAlign: 'right', width: "max-content" }} />
            }
          </Box>
        }

        {data?.footer &&
          <Box sx={{ p: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(15, 141, 72, 0.05)', ...config?.footer?.sx }} style={{ ...config?.footer?.style }}>
            {data?.footer?.content && data?.footer?.content}
          </Box>
        }
      </Box>

      {/* -------------- Tooltip ------------ */}
      {/* {tooltip?.show &&  */}
      <PopoverContainer anchorEl={tooltip?.anchorElement || null} onClose={() => setTooltip(null)}>
        <Box display={"flex"} alignItems={"center"}>
          {tooltip?.content}
          {/* {popoverAnchorToolTipContent?.showSync && <ImageWrapper src={popoverAnchorToolTipContent?.synced ? CheckCircle : AlertIcon} style={{ marginRight: '3px' }} />}
            <CustomTypography content={popoverAnchorToolTipContent?.content} weight={700} size={13} lineHeight={19} color="#202020" /> */}
        </Box>
      </PopoverContainer>
      {/* } */}
    </StyledCardWrapper>
  )
};

StyledCard.propTypes = {};

StyledCard.defaultProps = {};

export default StyledCard;
