function request(type, data = null) {
  let obj = { type: type };
  for (const d in data) {
    obj[d] = data[d];
  }
  return obj;
}

function success(type, data = null) {
  // console.log("Success ----- > ", data);
  let obj = { type: type };
  for (const d in data) {
    obj[d] = data[d];
  }
  // console.log("Success 2 ----- >", obj);
  return obj;
}
function failure(type, data={error: null, id: null}, key='error') {
  if(typeof data === "string") {
    return { type: type, payload: { error: data, key: key } };
  } else if (data?.id) {
    return { type: type, payload: { id: data?.id, error: data?.error } };
  } else {
    return { type: type, payload: { [key]: data?.error } };
  }
}

const statusCheck = (status) => {
  if (
    status === 200 ||
    status === 201 ||
    status === 202 ||
    status === 203 ||
    status === 204 ||
    status === 205 ||
    status === 206
  ) {
    return true;
  }
  return false;
};

export { request, success, failure, statusCheck };
