import { deviceTypes } from "../types";
import { request, success, failure, helperdeviceBreadcrumb, getMessage, formatedDate, getSpaces } from "../../helpers";
import { appActions } from "./app.actions";
import { delay, deviceService, findNode, formatString, tableSort } from '../../services';
import _, { isEmpty } from "lodash";
import { activityLogPageSize } from "../../config/deviceConfig";
// call api if want devices based on category or device id in deviList,gatewayList or controllerList variable
const getAllDevices = (params = {}, type = "device", cb = () => { }) => {
  return (dispatch, getState) => {
    // console.log("getAllDevices 2 in action ---- ")
    dispatch(request(deviceTypes.GET_ALL_DEVICES_REQUEST));
    deviceService.getAllDevices(params)
      .then(devices => {
        // alert(JSON.stringify(devices));
        const spaces = getState().spaces.list;
        return devices.map(d => {
          const space = findNode(spaces, "Spaces", { key: "spaceId", value: d.spaceId });
          d["spaceName"] = space?.name || "";
          return d;
        });
      })
      .then(devices => {
        // alert(JSON.stringify(devices));
        if (type === "gateway") {
          dispatch(success(deviceTypes.GET_ALL_GATEWAYS_SUCCESS, { payload: devices }))
        } else if (type === "controller") {
          dispatch(success(deviceTypes.GET_ALL_CONTROLLERS_SUCCESS, { payload: devices }))
        } else {
          dispatch(success(deviceTypes.GET_ALL_DEVICES_SUCCESS, { payload: devices, deviceType: type }))
        }
        cb(devices)
      }, ({ error, code }) => {
        dispatch(failure(deviceTypes.GET_ALL_DEVICES_FAILURE, error?.toString()));
        getMessage(`gateway-get-${code}`).then(delay(500)).then((msg) => {
          dispatch(appActions.apiErrorMessage(msg));
        },)
      }
      );


  };
}


const getDevice = (parentId, type = "device", dontUpdateDeviceDetails = false, errorMessage = "", getParents = true) => {
  console.log('get device===>', parentId, type);
  return (dispatch, getState) => {
    dispatch(request(deviceTypes.GET_DEVICE_REQUEST));
    deviceService.getDevice(parentId, type)
      .then(({ data: device, status }) => {
        device.lightId = device?.configuration?.lightId;
        device["connectionStatus"] = device?.deviceStatus?.connectionStatus?.onlineStatus ? "Online" : "Offline"
        const deviceModels = getState().devices.deviceModels;
        const model = deviceModels?.find(dm => dm.modelId === device.modelId);
        device["modelNumber"] = device?.configuration?.daliDriver || model?.modelNumber;
        device["fwUpdateStatus"] = device?.hasOwnProperty("lastFwStatus") ? `Version ${device?.lastFwStatus?.fwVersion} : ${device?.lastFwStatus?.status}` : "-"
        device["connectionType"] = model?.wired === 1 ? "wired" : "wireless";
        const lastUpdatedDate = formatedDate({ date: device?.lastUpdatedOn });
        device["lastUpdatedOn"] = lastUpdatedDate;
        const lastConnectedAt = device?.deviceStatus?.connectionStatus?.ts ? formatedDate({ date: device?.deviceStatus?.connectionStatus?.ts, format: "MMM dd, yyyy 'AT' hh:mm a" }) : "";
        device["lastConnectedAt"] = lastConnectedAt;
        const spaces = getState().spaces.list;
        const space = findNode(spaces, "Spaces", { key: "spaceId", value: device.spaceId });
        device["spaceName"] = space?.name || "";
        if (device?.category?.toLowerCase()?.includes("gateway")) {
          device["3rdPartyFwVersion"] = device?.hasOwnProperty("fwVersion3rdParty") ? JSON?.parse(device?.fwVersion3rdParty) : [];
        }
        return device;
      })
      .then(device => {
        if (type === "gateway") {
          dispatch(success(deviceTypes.GET_GATEWAY_SUCCESS, { payload: { device, dontUpdateDeviceDetails } }))
        } else if (type === "controller") {
          dispatch(success(deviceTypes.GET_CONTROLLER_SUCCESS, { payload: { device, dontUpdateDeviceDetails } }))
        }
        else if (type === "aggregator") {
          dispatch(success(deviceTypes.GET_CONTROLLER_SUCCESS, { payload: { device, dontUpdateDeviceDetails } }))
        } else {
          dispatch(success(deviceTypes.GET_DEVICE_SUCCESS, { payload: { device, dontUpdateDeviceDetails } }))
        }
        return device;
      }
      )
      .then(device => {
        let list = [];
        let id = "deviceId";
        let parentType = "gateway";
        switch (type) {
          case "device":
            // list = getState().devices.deviceList;
            list = [];
            list = getState()?.devices?.controllerList;
            parentType = "controller";
            id = "controllerId";
            break;
          case "controller":
          case "aggregator":
            // list = getState().devices.controllerList;
            list = [];
            list = getState()?.devices?.gatewayList;
            parentType = "gateway";
            id = "gwDeviceId";
            break;
          default:
            // list = getState().devices.gatewayList;
            list = [];
        }

        const deviceInMemory = list.find(item => item?.deviceId === device[id]);
        if (type !== "gateway" && getParents && (!deviceInMemory || (deviceInMemory && !deviceInMemory?.deviceId))) {
          dispatch(getDevice(device[id], parentType, true));
        }
      }, ({ error, code }) => {
        dispatch(failure(deviceTypes.GET_DEVICE_FAILURE, { error: { value: error?.toString(), dontUpdateDeviceDetails }, id: parentId }));
        if (errorMessage === "") {
          getMessage(`device-get-${code}`).then(delay(500)).then((msg) => {
            dispatch(appActions.apiErrorMessage(msg));
          },)
        }
        else {
          dispatch(appActions.apiErrorMessage(errorMessage));
        }
      });
  };
}
//discussed with Naveen and made separate action
const syncDeviceDetails = (parentId, type = "device") => {//used on device details page sync
  // console.log('get device===>', type);
  return (dispatch, getState) => {
    dispatch(request(deviceTypes.GET_DEVICE_REQUEST));
    deviceService.getDevice(parentId)
      .then(({ data: device, status }) => {
        device.lightId = device?.configuration?.lightId;
        device["connectionStatus"] = device?.deviceStatus?.connectionStatus?.onlineStatus ? "Online" : "Offline"
        const deviceModels = getState().devices.deviceModels;
        const model = deviceModels?.find(dm => dm.modelId === device.modelId);
        device["modelNumber"] = device?.configuration?.daliDriver || model?.modelNumber;
        device["fwUpdateStatus"] = device?.hasOwnProperty("lastFwStatus") ? `Version ${device?.lastFwStatus?.fwVersion} : ${device?.lastFwStatus?.status}` : "-"
        device["connectionType"] = model?.wired === 1 ? "wired" : "wireless";
        const lastUpdatedDate = formatedDate({ date: device?.lastUpdatedOn });
        device["lastUpdatedOn"] = lastUpdatedDate;
        const spaces = getState().spaces.list;
        const space = findNode(spaces, "Spaces", { key: "spaceId", value: device.spaceId });
        device["spaceName"] = space?.name || "";
        return device;
      })
      .then(({ data: device, status }) => {
        if (type === "gateway") {
          dispatch(success(deviceTypes.GET_GATEWAY_SUCCESS, { payload: { device } }))
        } else if (type === "controller" || type === "aggregator") {
          dispatch(success(deviceTypes.GET_CONTROLLER_SUCCESS, { payload: { device } }))
        }
        else {
          dispatch(success(deviceTypes.GET_DEVICE_SUCCESS, { payload: { device } }))
        }
        getMessage(`syncDevice-get-${status}`).then(delay(500)).then((msg) => {
          dispatch(appActions.apiSuccessMessage(msg));
        },)
      },
        ({ error, code }) => {
          dispatch(failure(deviceTypes.GET_DEVICE_FAILURE, { error: { value: error?.toString(), dontUpdateDeviceDetails: false }, id: parentId }));
          getMessage(`syncDevice-get-${code}`).then(delay(500)).then((msg) => {
            dispatch(appActions.apiErrorMessage(msg));
          },)
        }
      );
  };
}

const getAllChildren = (parentId, type = "controller", params = { depth: 1 }, cb = () => { }) => {
  return (dispatch, getState) => {
    dispatch(request(deviceTypes.GET_ALL_CONTROLLERS_REQUEST));
    deviceService
      .getAllChildren(parentId, params)
      .then((devices) => {
        return devices?.map(d => {
          d["lightId"] = d?.configuration?.lightId;
          d["syncStatus"] = d?.sync ? "Synced" : "Needs Sync";
          d["batteryLevel"] = d?.deviceStatus?.status?.batteryStatus || "";
          d["buttonCount"] = d?.configuration?.buttonCount || "";
          d["connectionStatus"] = d?.deviceStatus?.connectionStatus?.onlineStatus ? "online" : "offline"
          return d;
        });
      })
      .then((devices) => {
        const activeDevices = tableSort(devices?.filter(d => d?.status === "active"), { order: "asc", orderBy: 'lightId' });
        const inactiveDevices = tableSort(devices?.filter(d => d?.status === "inactive"), { order: "asc", orderBy: 'lightId' });
        devices = [...activeDevices, ...inactiveDevices];
        const spaces = getState().spaces.list;
        const deviceModels = getState().devices.deviceModels;
        return devices.map((d) => {
          const lastUpdatedDate = formatedDate({ date: d?.lastUpdatedOn });
          d["lastUpdatedOn"] = lastUpdatedDate;
          const space = findNode(spaces, "Spaces", { key: "spaceId", value: d.spaceId });
          d["spaceName"] = space?.name || "";
          const model = deviceModels?.find(dm => dm.modelId === d.modelId);
          d["modelNumber"] = d?.configuration?.daliDriver || model?.modelNumber;
          d["fwUpdateStatus"] = d?.hasOwnProperty("lastFwStatus") ? `Version ${d?.lastFwStatus?.fwVersion} : ${d?.lastFwStatus?.status}` : "-"
          d["connectionType"] = model?.wired === 1 ? "wired" : "wireless";
          return d;
        });
      })
      .then(
        (devices) => {
          if (type === "controller") {
            dispatch(
              success(deviceTypes.GET_ALL_CONTROLLERS_SUCCESS, {
                payload: devices,
              })
            );
          } else {
            dispatch(success(deviceTypes.GET_ALL_DEVICES_SUCCESS, { payload: devices, deviceType: type }));
          }
          cb();
        },
        ({ error, code }) => {
          dispatch(failure(deviceTypes.GET_ALL_CONTROLLERS_FAILURE, { payload: { error: type } }, type));
          getMessage(`children-get-${code}`).then(delay(500)).then((msg) => {
            dispatch(appActions.apiErrorMessage(msg));
          },)
        }
      );
  };
};

const addDevice = (parentId, payload, cb = () => { }) => {//used to add controller and aggregator
  return (dispatch, getState) => {
    dispatch(request(deviceTypes.ADD_CONTROLLER_REQUEST));
    deviceService
      .addDevice(parentId, payload)
      .then(({ data: device, status }) => {
        const spaces = getState().spaces.list;
        const space = findNode(spaces, "Spaces", {
          key: "spaceId",
          value: device.spaceId,
        });
        device["spaceName"] = space?.name || "";
        device["syncStatus"] = device?.sync ? "Synced" : "Needs Sync";
        device["connectionStatus"] = device?.deviceStatus?.connectionStatus?.onlineStatus ? "online" : "offline"
        const deviceModels = getState().devices.deviceModels;
        const model = deviceModels?.find(dm => dm.modelId === device.modelId);
        device["modelNumber"] = model?.modelNumber;
        device["fwUpdateStatus"] = device?.hasOwnProperty("lastFwStatus") ? `Version ${device?.lastFwStatus?.fwVersion} : ${device?.lastFwStatus?.status}` : "-"
        device["connectionType"] = model?.wired === 1 ? "wired" : "wireless";
        return { device, status };
      })
      .then(
        ({ device, status }) => {
          dispatch(success(deviceTypes.ADD_CONTROLLER_SUCCESS, { payload: device }));
          getMessage(`device-post-${status}`).then(delay(500)).then((msg) => {
            dispatch(appActions.apiSuccessMessage(msg));
          },)
          setTimeout(cb(), 3000);
        },
        ({ error, code }) => {
          dispatch(failure(deviceTypes.ADD_CONTROLLER_FAILURE, error?.toString()));
          getMessage(`controller-post-${code}`).then(delay(500)).then((msg) => {
            dispatch(appActions.apiErrorMessage(msg));
          },)
        }
      );
  };
};



const deleteDevice = (deviceId, cb = () => { }) => {
  // console.log("deleteDevice in action ---- ");
  return (dispatch) => {
    // console.log("deleteDevice 2 in action ---- ");
    dispatch(request(deviceTypes.DELETE_DEVICE_REQUEST));
    deviceService.deleteDevice(deviceId).then(
      ({ data: device, status }) => {
        dispatch(success(deviceTypes.DELETE_DEVICE_SUCCESS, { payload: deviceId }));
        getMessage(`device-delete-${status}`).then(delay(500)).then((msg) => {
          dispatch(appActions.apiSuccessMessage(msg));
        },)
        cb();
      },
      ({ error, code }) => {
        dispatch(failure(deviceTypes.DELETE_DEVICE_FAILURE, error?.toString()));
        getMessage(`device-delete-${code}`).then(delay(500)).then((msg) => {
          dispatch(appActions.apiErrorMessage(msg));
        },)
      }
    );
  };
};

//Action that calls API to update device name
const updateDevice = (deviceId, payload) => {
  // console.log("updateDevice in action ---- ",payload);
  return (dispatch, getState) => {
    // console.log("updateDevice 2 in action ---- ");
    dispatch(request(deviceTypes.UPDATE_DEVICE_REQUEST));
    deviceService.updateDevice(deviceId, payload).then(
      ({ data: device, status }) => {
        device["lightId"] = device?.configuration?.lightId;
        device["syncStatus"] = device?.sync ? "Synced" : "Needs Sync"
        device["batteryLevel"] = device?.deviceStatus?.status?.batteryStatus || "";
        device["buttonCount"] = device?.configuration?.buttonCount || "";
        device["connectionStatus"] = device?.deviceStatus?.connectionStatus?.onlineStatus ? "online" : "offline"
        // console.log("UPDATE RESPONSE",device)
        const deviceModels = getState().devices.deviceModels;
        const model = deviceModels?.find(dm => dm.modelId === device.modelId);
        device["modelNumber"] = device?.configuration?.daliDriver || model?.modelNumber;
        device["fwUpdateStatus"] = device?.hasOwnProperty("lastFwStatus") ? `Version ${device?.lastFwStatus?.fwVersion} : ${device?.lastFwStatus?.status}` : "-"
        device["connectionType"] = model?.wired === 1 ? "wired" : "wireless";
        const lastUpdatedDate = formatedDate({ date: device?.lastUpdatedOn });
        device["lastUpdatedOn"] = lastUpdatedDate;
        const spaces = getState().spaces.list;
        const space = findNode(spaces, "Spaces", { key: "spaceId", value: device.spaceId });
        device["spaceName"] = space?.name || "";
        dispatch(success(deviceTypes.UPDATE_DEVICE_SUCCESS, { payload: device }));
        getMessage(`updateDevice-put-${status}`).then(delay(500)).then((msg) => {
          dispatch(appActions.apiSuccessMessage(msg));
        },)
      },
      ({ error, code }) => {
        dispatch(failure(deviceTypes.UPDATE_DEVICE_FAILURE, error?.toString()));
        getMessage(`updateDevice-put-${code}`).then(delay(500)).then((msg) => {
          dispatch(appActions.apiErrorMessage(msg));
        },)
      }
    );
  };
};
const syncDevice = (deviceId) => {//used for syncing one device in table
  // console.log("sync light in action ---- ");
  return (dispatch, getState) => {
    // console.log("sync light 2 in action ---- ");
    dispatch(request(deviceTypes.UPDATE_DEVICE_REQUEST));
    deviceService.getDevice(deviceId).then(
      ({ data: device, status }) => {
        device.lightId = device?.configuration?.lightId;
        device["batteryLevel"] = device?.deviceStatus?.status?.batteryStatus || "";
        device["buttonCount"] = device?.configuration?.buttonCount || "";
        device["connectionStatus"] = device?.deviceStatus?.connectionStatus?.onlineStatus ? "online" : "offline"
        device.syncStatus = device?.sync ? "Synced" : "Needs Sync"
        const deviceModels = getState().devices.deviceModels;
        const model = deviceModels?.find(dm => dm.modelId === device.modelId);
        device["modelNumber"] = device?.configuration?.daliDriver || model?.modelNumber;
        device["fwUpdateStatus"] = device?.hasOwnProperty("lastFwStatus") ? `Version ${device?.lastFwStatus?.fwVersion} : ${device?.lastFwStatus?.status}` : "-"
        device["connectionType"] = model?.wired === 1 ? "wired" : "wireless";
        const lastUpdatedDate = formatedDate({ date: device?.lastUpdatedOn });
        device["lastUpdatedOn"] = lastUpdatedDate;
        const spaces = getState().spaces.list;
        const space = findNode(spaces, "Spaces", { key: "spaceId", value: device.spaceId });
        device["spaceName"] = space?.name || "";
        dispatch(success(deviceTypes.UPDATE_DEVICE_SUCCESS, { payload: device }));
        getMessage(`syncDevice-get-${status}`).then(delay(500)).then((msg) => {
          dispatch(appActions.apiSuccessMessage(msg));
        },)
      },
      ({ error, code }) => {
        dispatch(failure(deviceTypes.UPDATE_DEVICE_FAILURE, error?.toString()));
        getMessage(`syncDevice-get-${code}`).then(delay(500)).then((msg) => {
          dispatch(appActions.apiErrorMessage(msg));
        },)
      }
    );
  };
};
const moveDevice = (spaceId, deviceId) => {
  return (dispatch, getState) => {
    dispatch(request(deviceTypes.MOVE_REQUEST));
    deviceService
      .moveDevice(spaceId, deviceId)
      .then(({ data, status }) => {
        const spaces = getState().spaces.list;
        const space = findNode(spaces, "Spaces", { key: "spaceId", value: spaceId });
        // console.log("Move device 2 --- ", device, spaceId, deviceId);
        return { spaceName: space?.name || "", status };
      })
      .then(
        ({ spaceName, status }) => {
          dispatch(
            success(deviceTypes.MOVE_SUCCESS, {
              payload: {
                spaceId,
                deviceId,
                spaceName,
              },
            })
          );
          getMessage(`moveDevice-put-${status}`).then(delay(500)).then((msg) => {
            dispatch(appActions.apiSuccessMessage(msg));
          },)
          // console.log("Move device 3 --- ", device, spaceId, deviceId);
        },
        ({ error, code, message }) => {
          dispatch(failure(deviceTypes.MOVE_FAILURE, error?.toString()));
          getMessage(`moveDevice-put-${code}`, message).then(delay(500)).then((msg) => {
            dispatch(appActions.apiErrorMessage(msg));
          },)
        }
      );
  };
};

const getDevicesBySpaceId = (spaceId, category = "", deviceType = null, cb = () => { }) => {
  // console.log("getDevicesbyspaceId in action ---- ", spaceId)
  return (dispatch, getState) => {
    dispatch(request(deviceTypes.GET_DEVICES_BY_SPACEID_REQUEST));
    deviceService.getDevicesBySpaceId(spaceId, category)
      .then((devices) => {
        devices = devices?.map(d => {
          d["lightId"] = d?.configuration?.lightId;
          d["syncStatus"] = d?.sync ? "Synced" : "Needs Sync";
          d["batteryLevel"] = d?.deviceStatus?.status?.batteryStatus || "";
          d["buttonCount"] = d?.configuration?.buttonCount || "";
          d["connectionStatus"] = d?.deviceStatus?.connectionStatus?.onlineStatus ? "online" : "offline"
          return d;
        })
        const activeDevices = tableSort(devices?.filter(d => d?.status === "active"), { order: "asc", orderBy: 'lightId' });
        const inactiveDevices = tableSort(devices?.filter(d => d?.status === "inactive"), { order: "asc", orderBy: 'lightId' });
        devices = [...activeDevices, ...inactiveDevices];
        const spaces = getState().spaces.list;
        const deviceModels = getState().devices.deviceModels;
        return devices.map((d) => {
          const space = findNode(spaces, "Spaces", { key: "spaceId", value: d.spaceId });
          d["spaceName"] = space?.name || "";
          const model = deviceModels?.find(dm => dm.modelId === d.modelId);
          d["modelNumber"] = d?.configuration?.daliDriver || model?.modelNumber;
          d["fwUpdateStatus"] = d?.hasOwnProperty("lastFwStatus") ? `Version ${d?.lastFwStatus?.fwVersion} : ${d?.lastFwStatus?.status}` : "-"
          d["connectionType"] = model?.wired === 1 ? "wired" : "wireless";
          const lastUpdatedDate = formatedDate({ date: d?.lastUpdatedOn });
          d["lastUpdatedOn"] = lastUpdatedDate;
          return d;
        });
      })
      .then(
        device => {
          dispatch(success(deviceTypes.GET_DEVICES_BY_SPACEID_SUCCESS, { payload: device, category: category, deviceType }));
          cb();
        },
        ({ error, code }) => {
          dispatch(failure(deviceTypes.GET_DEVICES_BY_SPACEID_FAILURE, error?.toString()));
          getMessage(`devicesBySpace-get-${code}`).then(delay(500)).then((msg) => {
            dispatch(appActions.apiErrorMessage(msg));
          },)
        }
      );
  };
}

const getDevicesBySpaceId_for_future = (spaceId, category = "", { getChildSpaceDevices = false, allDevices = [], errors = [] } = { getChildSpaceDevices: false }) => {
  // console.log("getDevicesbyspaceId in action ---- ")
  return (dispatch, getState) => {
    dispatch(request(deviceTypes.GET_DEVICES_BY_SPACEID_REQUEST));
    const space = getState()?.spaces?.list?.find(s => s.spaceId === spaceId);

    const p = new Promise((resolve, reject) => {
      deviceService.getDevicesBySpaceId(spaceId, category)
        .then(
          devices => {
            if (!allDevices) { allDevices = []; }
            allDevices = [...allDevices, ...devices];
            resolve({ error: null, allDevices: allDevices });
            // dispatch(success(deviceTypes.GET_DEVICES_BY_SPACEID_SUCCESS, { payload: devices }));
          },
          ({ error, code }) => {
            if (!errors) { errors = []; }
            errors.push(error);
            resolve({ error: true, errors: errors });
            // dispatch(failure(deviceTypes.GET_DEVICES_BY_SPACEID_FAILURE, error?.toString()));
            getMessage(`devicesBySpace-get-${code}`).then(delay(500)).then((msg) => {
              dispatch(appActions.apiErrorMessage(msg));
            },)
          }
        );
    })

    p.then((data) => {
      if (data?.error) {
        if (data?.errors) {
          data?.errors.forEach(err => {
            dispatch(failure(deviceTypes.GET_DEVICES_BY_SPACEID_FAILURE, err.toString()));
            getMessage(`devicesBySpace-get-${err?.code}`).then(delay(500)).then((msg) => {
              dispatch(appActions.apiErrorMessage(msg));
            },)
          })
        }
      }

      if (getChildSpaceDevices && space && space["Spaces"] && space["Spaces"]?.length > 0) {
        space["Spaces"].forEach(ch => {
          getDevicesBySpaceId(ch?.spaceId, category, { allDevices: data?.allDevices, errors });
        })
      } else {
        if (data?.allDevices) {
          dispatch(success(deviceTypes.GET_DEVICES_BY_SPACEID_SUCCESS, { payload: data?.allDevices }));
        }
      }
    })

  };
}

const getDeviceModels = (tenantId = null) => {
  // console.log("getDeviceModels in action ---- ")
  return dispatch => {
    dispatch(request(deviceTypes.GET_DEVICE_MODELS_REQUEST));
    deviceService.getDeviceModels(tenantId)
      .then(
        device => {
          dispatch(success(deviceTypes.GET_DEVICE_MODELS_SUCCESS, { payload: device }));
        },
        ({ error, code }) => {
          dispatch(failure(deviceTypes.GET_DEVICE_MODELS_FAILURE, error?.toString()));
          getMessage(`model-get-${code}`).then(delay(500)).then((msg) => {
            dispatch(appActions.apiErrorMessage(msg));
          },)
        }
      );
  };
}

const getDeviceCategories = (tenantId = null, cb = () => { }, fcb = () => { }) => {
  return dispatch => {
    dispatch(request(deviceTypes.GET_DEVICE_CATEGORIES_REQUEST));
    deviceService.getDeviceCategories(tenantId)
      .then(
        categories => {
          cb(categories)
          dispatch(success(deviceTypes.GET_DEVICE_CATEGORIES_SUCCESS, { payload: categories }));
        },
        ({ error, code }) => {
          fcb();
          dispatch(failure(deviceTypes.GET_DEVICE_CATEGORIES_FAILURE, error?.toString()));
          getMessage(`categories-get-${code}`).then(delay(500)).then((msg) => {
            dispatch(appActions.apiErrorMessage(msg));
          },)
        }
      );
  };
}

const startPairing = (deviceId) => {
  return dispatch => {
    dispatch(success(deviceTypes.START_PAIRING, { payload: { deviceId: deviceId } }));
  };
}

const stopPairing = (deviceId) => {
  return dispatch => {
    dispatch(success(deviceTypes.STOP_PAIRING, { payload: { deviceId: deviceId } }));
  };
}
const startLoading = (deviceId, shortId = null) => {
  return dispatch => {
    dispatch(success(deviceTypes.START_LOADING, { payload: { deviceId, shortId } }));
  };
}
const stopLoading = (deviceId, shortId = null) => {
  return dispatch => {
    dispatch(success(deviceTypes.STOP_LOADING, { payload: { deviceId, shortId } }));
  };
}

const deviceBreadCrumbs = ({ data = null, page = "gateway" }) => {
  return async (dispatch, getState) => {
    let result = [];
    let deviceState = getState()?.devices;
    let itemId = data?.deviceId;
    // setTimeout(async () => {
    if (data) {
      let url = '';
      await (async () => {
        if (data && data?.length !== 0 && page === 'device' && data?.category !== 'Receiver' && !data?.category?.includes('Light') && data?.parentDeviceId && (!deviceState?.parentDevice || deviceState?.parentDevice?.deviceId !== data?.parentDeviceId)) {
          await dispatch(getParentDevice(data?.parentDeviceId));
        }
      })();
      await (async () => {
        //DO NOT ADD BREAKS TO CASE STATEMENTS
        //DO NOT CHANGE ORDER OF CASE STATEMENTS
        switch (page) {
          case 'device':
            // console.log("deviceeeeeeeeeeeeee  ===== 1 ", itemId, list?.deviceList, data)
            url = `/spaces/devices?pt=device&id=${itemId}&category=${data?.category}`;
            result.unshift(helperdeviceBreadcrumb(data, url));
            if (data && deviceState?.parentDevice && data?.parentDeviceId !== data?.controllerId && deviceState?.parentDevice?.deviceId === data?.parentDeviceId) {
              url = `/spaces/devices?pt=device&id=${data?.parentDeviceId}&category=${deviceState?.parentDevice?.category}`;
              result.unshift(helperdeviceBreadcrumb(deviceState?.parentDevice, url));
            }
            itemId = await data?.controllerId;
          case 'controller':
          case 'aggregator':
            const device = await (deviceState?.controllerList)?.find(d => d?.deviceId === itemId);
            url = `/spaces/devices?pt=controller&id=${itemId}&category=${device?.category}`;
            result.unshift(helperdeviceBreadcrumb(device, url));
            itemId = await device?.gwDeviceId;
          case "gateway":
            const device2 = await (deviceState?.gatewayList)?.find(d => d?.deviceId === itemId);
            url = `/spaces/devices?pt=gateway&id=${itemId}&category=${device2?.category}`;
            result.unshift(helperdeviceBreadcrumb(device2, url));
          default:

        }
      })();
      dispatch(success(deviceTypes.DEVICES_BREADCRUMBS, { payload: result }));
    }
    // }, 1000);
  }
}

const updateDeviceConfiguration = (deviceId, payload, type) => {
  // console.log("update device configuration in action ---- ");
  return (dispatch, getState) => {
    // console.log("update device configuration 2 in action ---- ");
    dispatch(request(deviceTypes.UPDATE_DEVICE_CONFIGURATION_REQUEST));
    deviceService.updateConfig(deviceId, payload, type).then(
      ({ data, status }) => {
        dispatch(success(deviceTypes.UPDATE_DEVICE_CONFIGURATION_SUCCESS, { payload: { deviceId } }));
        getMessage(`updateConfig-put-${status}`).then(delay(500)).then((msg) => {
          dispatch(appActions.apiSuccessMessage(msg));
        },)
      },
      ({ error, code }) => {
        dispatch(failure(deviceTypes.UPDATE_DEVICE_CONFIGURATION_FAILURE, error?.toString()));
        getMessage(`updateConfig-put-${code}`).then(delay(500)).then((msg) => {
          dispatch(appActions.apiErrorMessage(msg));
        },)
        // syncing device in case of failure of update config API
        setTimeout(() => dispatch(syncDevice(deviceId)), 5000);
      }
    );
  };
};
const getParentDevice = (deviceId) => {
  return (dispatch, getState) => {
    dispatch(request(deviceTypes.GET_PARENT_DEVICE_REQUEST));
    deviceService.getDevice(deviceId)
      .then(({ data: device, status }) => {
        const spaces = getState().spaces.list;
        const space = findNode(spaces, "Spaces", { key: "spaceId", value: device.spaceId });
        device["spaceName"] = space?.name || "";
        return device;
      })
      .then(device => {
        dispatch(success(deviceTypes.GET_PARENT_DEVICE_SUCCESS, { payload: device }))
        return device;
      },
        ({ error, code }) => {
          dispatch(failure(deviceTypes.GET_PARENT_DEVICE_FAILURE, error?.toString()));
          getMessage(`device-get-${code}`).then(delay(500)).then((msg) => {
            dispatch(appActions.apiErrorMessage(msg));
          },)
        }
      );
  };
}
// call api if want devices based on category or device id in detailedDevicelist
const getDetailedDeviceList = (payload={}, addModelNumber = false, tenantId = null) => {
  return (dispatch, getState) => {
    dispatch(request(deviceTypes.GET_DETAILED_DEVICE_LIST_REQUEST));
    deviceService.getDetailedDeviceList(payload, tenantId)
      .then((devices) => {
        if (addModelNumber) {
          const deviceModels = getState()?.devices?.deviceModels;
          devices = devices?.map(d => {
            d.modelNumber = deviceModels?.find(dm => dm.modelId === d.modelId)?.modelNumber;
            return d;
          })
        }
        dispatch(success(deviceTypes.GET_DETAILED_DEVICE_LIST_SUCCESS, { payload: devices }))
      },
        ({ error, code }) => {
          dispatch(failure(deviceTypes.GET_DETAILED_DEVICE_LIST_FAILURE, error?.toString()));
          getMessage(`allDevices-get-${code}`).then(delay(500)).then((msg) => {
            dispatch(appActions.apiErrorMessage(msg));
          },)
        }
      );
  };
}

//To get deviceLogs
const getDeviceActivityLogs = (deviceId, queryParams = {}, cb = () => { }) => { //requestSpecs is an object which must have deviceId. It can also have start time, end time, type, uid
  return dispatch => {
    dispatch(request(deviceTypes.GET_DEVICE_LOGS_REQUEST));
    queryParams = { pgSize: activityLogPageSize, ...queryParams, };
    deviceService.getDeviceActivityLogs(deviceId, queryParams)
      .then(
        deviceLogs => {
          // setTimeout(() => {
          if (deviceLogs?.activities === null) {
            deviceLogs.activities = [];
          }
          dispatch(success(deviceTypes.GET_DEVICE_LOGS_SUCCESS, { payload: deviceLogs, }))
          cb(deviceLogs);
          // }, 5000)
        },
        ({ error, code }) => {
          dispatch(failure(deviceTypes.GET_DEVICE_LOGS_FAILURE, error?.toString()));
          getMessage(`activityLog-get-${code}`).then(delay(500)).then((msg) => {
            dispatch(appActions.apiErrorMessage(msg));
          },)
          cb([], true);
        }
      );
  }
};

const factoryReset = (deviceId, payload, cb = () => { }) => {
  return dispatch => {
    dispatch(request(deviceTypes.FACTORY_RESET_REQUEST));
    deviceService.factoryReset(deviceId, payload)
      .then(
        ({ data, status }) => {
          dispatch(success(deviceTypes.FACTORY_RESET_SUCCESS, {}));
          getMessage(`factoryReset-post-${status}`).then(delay(500)).then((msg) => {
            dispatch(appActions.apiSuccessMessage(msg));
          },)
          cb();
        },
        ({ error, code }) => {
          dispatch(failure(deviceTypes.FACTORY_RESET_FAILURE, error?.toString()));
          getMessage(`factoryReset-post-${code}`).then(delay(500)).then((msg) => {
            dispatch(appActions.apiErrorMessage(msg));
          },)

        }
      );
  };
}

const getGatewayLog = (deviceId, date) => {
  return dispatch => {
    dispatch(request(deviceTypes.GET_GATEWAYLOG_REQUEST));
    deviceService.getGatewayLog(deviceId, date)
      .then(
        ({ data, status }) => {
          dispatch(success(deviceTypes.GET_GATEWAYLOG_SUCCESS, { payload: { ...data, date: formatedDate({ date: `${date?.substring(0, 4)}-${date?.substring(4, 6)}-${date?.substring(6, 8)}` }) } }));
          getMessage(`gatewayLog-get-${status}`).then(delay(500)).then((msg) => {
            dispatch(appActions.apiSuccessMessage(msg));
          },)
        },
        ({ error, code }) => {
          dispatch(failure(deviceTypes.GET_GATEWAYLOG_FAILURE, error?.toString()));
          getMessage(`gatewayLog-get-${code}`).then(delay(500)).then((msg) => {
            dispatch(appActions.apiErrorMessage(msg));
          },)
        }
      );
  };
}

const getGatewayLogDates = (deviceId) => {
  return dispatch => {
    dispatch(request(deviceTypes.GET_GATEWAYLOG_DATES_REQUEST));
    deviceService.getGatewayLogDates(deviceId)
      .then(
        ({ data, status }) => {
          dispatch(success(deviceTypes.GET_GATEWAYLOG_DATES_SUCCESS, { payload: { ...data } }));
        },
        ({ error, code }) => {
          dispatch(failure(deviceTypes.GET_GATEWAYLOG_DATES_FAILURE, error?.toString()));
          getMessage(`gatewayLogDates-get-${code}`).then(delay(500)).then((msg) => {
            dispatch(appActions.apiErrorMessage(msg));
          },)
        }
      );
  };
}

const findSensors = (deviceId, duration, forced = false, cb = () => { }) => {
  return dispatch => {
    dispatch(request(deviceTypes.FIND_SENSORS_REQUEST));
    deviceService.findSensors(deviceId, { duration, forced })
      .then(
        ({ data, status }) => {
          cb(data?.requestId, duration);
          dispatch(success(deviceTypes.FIND_SENSORS_SUCCESS, { payload: { ...data } }));
          getMessage(`findSensors-post-${status}`).then(delay(500)).then((msg) => {
            dispatch(appActions.apiSuccessMessage(msg));
          },)
        },
        ({ error, code }) => {
          dispatch(failure(deviceTypes.FIND_SENSORS_FAILURE, error?.toString()));
          getMessage(`findSensors-post-${code}`).then(delay(500)).then((msg) => {
            dispatch(appActions.apiErrorMessage(msg));
          },)
        }
      );
  };
}

const stopFindSensors = (deviceId) => {
  return (dispatch, getState) => {
    const requestId = getState()?.devices?.requestId;
    dispatch(request(deviceTypes.STOP_FIND_SENSORS_REQUEST));
    deviceService.findSensors(deviceId, { duration: 0, requestId })
      .then(
        ({ data, status }) => {
          dispatch(success(deviceTypes.STOP_FIND_SENSORS_SUCCESS, { payload: { ...data } }));
          getMessage(`stopFindSensors-post-${status}`).then(delay(500)).then((msg) => {
            dispatch(appActions.apiSuccessMessage(msg));
          },)
        },
        ({ error, code }) => {
          dispatch(failure(deviceTypes.STOP_FIND_SENSORS_FAILURE, error?.toString()));
          getMessage(`stopFindSensors-post-${code}`).then(delay(500)).then((msg) => {
            dispatch(appActions.apiErrorMessage(msg));
          },)
        }
      );
  };
}

const fetchSensors = (requestId) => {
  return dispatch => {
    dispatch(request(deviceTypes.FETCH_SENSORS_REQUEST));
    deviceService.fetchSensors(requestId)
      .then(
        ({ data, status }) => {
          dispatch(success(deviceTypes.FETCH_SENSORS_SUCCESS, { payload: { ...data } }));
        },
        ({ error, code }) => {
          dispatch(failure(deviceTypes.FETCH_SENSORS_FAILURE, error?.toString()));
          getMessage(`fetchSensors-get-${code}`).then(delay(500)).then((msg) => {
            dispatch(appActions.apiErrorMessage(msg));
          },)
        }
      );
  };
}
const bindSensors = (deviceId, requestId, sensorList, cb = () => { }) => {
  return dispatch => {
    dispatch(request(deviceTypes.BIND_SENSORS_REQUEST));
    deviceService.bindSensors(deviceId, { requestId, sensors: sensorList })
      .then(
        ({ data, status }) => {
          dispatch(success(deviceTypes.BIND_SENSORS_SUCCESS, { payload: { ...data } }));
          getMessage(`bindSensors-post-${status}`).then(delay(500)).then((msg) => {
            dispatch(appActions.apiSuccessMessage(msg));
          },)
          cb();
        },
        ({ error, code }) => {
          dispatch(failure(deviceTypes.BIND_SENSORS_FAILURE, error?.toString()));
          getMessage(`bindSensors-post-${code}`).then(delay(500)).then((msg) => {
            dispatch(appActions.apiErrorMessage(msg));
          },)
        }
      );
  };
}

const clearSensorList = () => {
  return dispatch => {
    dispatch({ type: deviceTypes.CLEAR_SENSOR_LIST, payload: {} });
  };
}

const clearAllDevices = () => {
  return dispatch => {
    dispatch({ type: deviceTypes.CLEAR_ALL_DEVICES, payload: {} });
  }
}

const assignGateway = (payload, cb = () => { }) => {
  return dispatch => {
    dispatch(request(deviceTypes.ASSIGN_GATEWAY_REQUEST));
    deviceService.assignGateway(payload)
      .then(
        ({ data, status }) => {
          getMessage(`assignGateway-post-${status}`).then(delay(500)).then((msg) => {
            dispatch(appActions.apiSuccessMessage(msg));
          })
          dispatch(success(deviceTypes.ASSIGN_GATEWAY_SUCCESS, { payload: { ...data } }));
          cb();
        },
        ({ error, code }) => {
          dispatch(failure(deviceTypes.ASSIGN_GATEWAY_FAILURE, error?.toString()));
          getMessage(`assignGateway-post-${code}`).then(delay(500)).then((msg) => {
            dispatch(appActions.apiErrorMessage(msg));
          })
        }
      );
  };
}

const getGatewayDetails = (macAddress) => {
  return (dispatch) => {
    dispatch(request(deviceTypes.GET_REQUEST));
    deviceService.getGatewayDetails(macAddress)
      .then(
        ({ data, status }) => {
          // const deviceModels = getState().devices.deviceModels;
          // const model = deviceModels?.find(dm => dm.modelId === data.modelId);
          // data["modelNumber"] = model?.modelNumber;
          const assignedOn = ((!data?.hasOwnProperty("tenantAssignedOn")) || data?.tenantAssignedOn === 0) ? "-" : formatedDate({ date: data?.tenantAssignedOn });
          data["assignedOn"] = assignedOn;
          const onboardedOn = data?.onboardedOn === 0 ? "-" : formatedDate({ date: data?.onboardedOn });
          data["gatewayOnboardedOn"] = onboardedOn;
          getMessage(`gatewayDetails-get-${status}`).then(delay(500)).then((msg) => {
            dispatch(appActions.apiSuccessMessage(msg));
          })
          dispatch(success(deviceTypes.GET_SUCCESS, { payload: { ...data } }));
        },
        ({ error, code }) => {
          dispatch(failure(deviceTypes.GET_FAILURE, error?.toString()));
          getMessage(`gatewayDetails-get-${code}`).then(delay(500)).then((msg) => {
            dispatch(appActions.apiErrorMessage(msg));
          })
        }
      );
  };
}

const getGatewayStatistics = () => {
  return (dispatch, getState) => {
    dispatch(request(deviceTypes.GET_GATEWAY_STATISTICS_REQUEST));
    deviceService.getGatewayStatistics()
      .then(
        ({ data, status }) => {
          // getMessage(`gatewayStatistics-get-${status}`).then(delay(500)).then((msg) => {
          //   dispatch(appActions.apiSuccessMessage(msg));
          // })
          const tenants = getState()?.tenants?.list;
          data.tenant = data?.tenant?.map(t => {
            t.logoBitmap = tenants?.find(tenant => tenant?.tenantName === t?.tenantName)?.webApp?.logoBitmap;
            return t;
          })
          dispatch(success(deviceTypes.GET_GATEWAY_STATISTICS_SUCCESS, { payload: { ...data } }));
        },
        ({ error, code }) => {
          dispatch(failure(deviceTypes.GET_GATEWAY_STATISTICS_FAILURE, error?.toString()));
          getMessage(`gatewayStatistics-get-${code}`).then(delay(500)).then((msg) => {
            dispatch(appActions.apiErrorMessage(msg));
          })
        }
      );
  };
}
const getAssignedGateways = (params = {}, tenantId = null, getDeviceDetails = true) => {
  return (dispatch, getState) => {
    dispatch(request(deviceTypes.GET_ASSIGNED_GATEWAYS_REQUEST));
    const portal = getState()?.app?.portal;
    deviceService.getAssignedGateways(params, tenantId, portal)
      .then(
        ({ data, status }) => {
          const deviceIdList = data?.filter(g => g?.onboarded === true)?.map(g => g?.deviceId);
          if (!_.isEmpty(deviceIdList) && getDeviceDetails) {
            deviceService.getDetailedDeviceList({
              "deviceIds": deviceIdList?.join(','),
            }).then((multipleDeviceList) => {
              const deviceModels = getState().devices.deviceModels;
              const spaces = getState().spaces.list;
              const categories = getState()?.devices?.deviceCategories
              const gateways = data?.map(d => {
                const device = multipleDeviceList?.find(dev => dev?.deviceId === d?.deviceId)
                d["name"] = device ? device?.name : categories?.find(c => c?.category === d?.category)?.label;
                const space = findNode(spaces, "Spaces", { key: "spaceId", value: device?.spaceId });
                d["spaceName"] = device ? space?.name : "-";
                d["spaceId"] = device ? space?.spaceId : "";
                d["deviceStatus"] = device ? device?.deviceStatus : {};
                d["status"] = device ? device?.status : "";
                const model = deviceModels?.find(dm => dm.modelId === d.modelId);
                d["modelNumber"] = model?.modelNumber;
                d["fwUpdateStatus"] = d?.hasOwnProperty("lastFwStatus") ? `Version ${d?.lastFwStatus?.fwVersion} : ${d?.lastFwStatus?.status}` : "-"
                d["gatewayOnboarded"] = d?.onboarded ? "Onboarded" : "Not Onboarded";
                // d["3rdPartyFwVersion"] = d?.hasOwnProperty("fwVersion3rdParty") ? JSON?.parse(d?.fwVersion3rdParty) : [];
                return d;
              })
              dispatch(success(deviceTypes.GET_ASSIGNED_GATEWAYS_SUCCESS, { payload: gateways }));
            },
              ({ error, code }) => {
                dispatch(failure(deviceTypes.GET_DETAILED_DEVICE_LIST_FAILURE, error?.toString()));
                getMessage(`allDevices-get-${code}`).then(delay(500)).then((msg) => {
                  dispatch(appActions.apiErrorMessage(msg));
                })
              }
            )
          }
          else {
            const deviceModels = getState().devices.deviceModels;
            const gateways = data?.map(d => {
              d["name"] = formatString(d?.category, "wordBreak");
              const model = deviceModels?.find(dm => dm.modelId === d.modelId);
              d["modelNumber"] = model?.modelNumber;
              d["gatewayOnboarded"] = d?.onboarded ? "Onboarded" : "Not Onboarded";
              return d;
            })
            dispatch(success(deviceTypes.GET_ASSIGNED_GATEWAYS_SUCCESS, { payload: gateways }));
          }
        },
        ({ error, code }) => {
          dispatch(failure(deviceTypes.GET_ASSIGNED_GATEWAYS_FAILURE, error?.toString()));
          getMessage(`assignedGateways-get-${code}`).then(delay(500)).then((msg) => {
            dispatch(appActions.apiErrorMessage(msg));
          })
        }
      );
  };
}


const getCameraStreamUrl = () => {
  return dispatch => {
    dispatch(request(deviceTypes.GET_CAMERA_STREAM_REQUEST));
    deviceService.getCameraStreamUrl()
      .then(
        ({ data, status }) => {
          dispatch(success(deviceTypes.GET_CAMERA_STREAM_SUCCESS, { payload: { ...data } }));
        },
        ({ error, code }) => {
          dispatch(failure(deviceTypes.GET_CAMERA_STREAM_FAILURE, error?.toString()));
          getMessage(`cameraStreamUrl-get-${code}`).then(delay(500)).then((msg) => {
            dispatch(appActions.apiErrorMessage(msg));
          },)
        }
      );
  };
}

const stopCameraStream = () => {
  return dispatch => {
    dispatch(request(deviceTypes.STOP_CAMERA_STREAM_REQUEST));
    deviceService.stopCameraStream()
      .then(
        ({ data, status }) => {
          dispatch(success(deviceTypes.STOP_CAMERA_STREAM_SUCCESS, { payload: { ...data } }));
        },
        ({ error, code }) => {
          dispatch(failure(deviceTypes.STOP_CAMERA_STREAM_FAILURE, error?.toString()));
          getMessage(`stopCameraStream-get-${code}`).then(delay(500)).then((msg) => {
            dispatch(appActions.apiErrorMessage(msg));
          },)
        }
      );
  };
}


export const deviceActions = {
  getAllDevices,
  addDevice,
  deleteDevice,
  getDevice,
  getAllChildren,
  moveDevice,
  updateDevice,
  getDevicesBySpaceId,
  getDeviceModels,
  getDeviceCategories,
  startPairing,
  stopPairing,
  startLoading,
  stopLoading,
  deviceBreadCrumbs,
  updateDeviceConfiguration,
  syncDevice,
  syncDeviceDetails,
  getParentDevice,
  getDetailedDeviceList,
  getDeviceActivityLogs,
  factoryReset,
  getGatewayLog,
  getGatewayLogDates,
  findSensors,
  stopFindSensors,
  fetchSensors,
  bindSensors,
  clearSensorList,
  getDevicesBySpaceId_for_future,
  clearAllDevices,
  assignGateway,
  getAssignedGateways,
  getGatewayDetails,
  getGatewayStatistics,
  getCameraStreamUrl,
  stopCameraStream

};
