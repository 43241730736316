import { failure, request, success, getMessage } from "../../helpers";
import { reportService } from "../../services";
import { reportType } from "../types";
import { appActions } from "./app.actions";
import { history } from "../../helpers";
import { delay } from "../../services";


const getReportById = (reportId) => {
  return (dispatch) => {
    dispatch(request(reportType.GET_REPORT_REQUEST));
    reportService.getReportById(reportId).then(
      ({ data, status }) => {
        dispatch(success(reportType.GET_REPORT_SUCCESS, { payload: data }))
        // dispatch(appActions.apiSuccessMessage(getMessage(`reportById-get-${status}`)))
        //   getMessage(`reportById-get-${status}`).then(delay(500)).then((msg)=>{
        //     dispatch(appActions.apiSuccessMessage(msg));
        // })
      },
      ({ error, code }) => {
        dispatch(failure(reportType.GET_REPORT_FAILURE, error.toString()));
        getMessage(`reportById-get-${code}`).then(delay(500)).then((err) => {
          dispatch(appActions.apiErrorMessage(err));
        })
      }
    );
  };
};

const getAllReports = (payload) => {
  return (dispatch) => {
    dispatch(request(reportType.GET_ALL_REPORTS_REQUEST));
    reportService.getAllReports(payload).then(
      ({ data, status }) => {
        dispatch(success(reportType.GET_ALL_REPORTS_SUCCESS, { payload: data }))
      },
      ({ error, code }) => {
        dispatch(failure(reportType.GET_ALL_REPORTS_FAILURE, error.toString()));
        getMessage(`report-get-${code}`).then(delay(500)).then((err) => {
          dispatch(appActions.apiErrorMessage(err));
        })
      }
    );
  };
};

const getAllReportDocument = (reportId) => {
  return (dispatch) => {
    dispatch(request(reportType.GET_ALL_REPORT_DOCUMENTS_REQUEST));
    reportService.getDocumentReportById(reportId).then(
      ({ data, status }) => {
        dispatch(success(reportType.GET_ALL_REPORT_DOCUMENTS_SUCCESS, { payload: data }))
      },
      ({ error, code }) => {
        dispatch(failure(reportType.GET_ALL_REPORT_DOCUMENTS_FAILURE, error.toString()));
        getMessage(`documentReport-get-${code}`).then(delay(500)).then((err) => {
          dispatch(appActions.apiErrorMessage(err));
        })
      }
    );
  };
};

const updateReport = (reportId, payload, cb = () => { }, fcb = () => { }) => {
  return (dispatch) => {
    dispatch(request(reportType.UPDATE_REPORT_REQUEST));
    reportService.updateReport(reportId, payload).then(
      ({ data, status }) => {
        // dispatch(
        //   success(reportType.UPDATE_REPORT_SUCCESS, { payload: payload,reportId:reportId })
        // );
        getMessage(`updateReport-put-${status}`).then(delay(500)).then((msg) => {
          dispatch(appActions.apiSuccessMessage(msg));
        })
        cb();
      },
      ({ error, code }) => {
        dispatch(failure(reportType.UPDATE_REPORT_FAILURE, error.toString()));
        getMessage(`updateReport-put-${code}`).then(delay(500)).then((err) => {
          dispatch(appActions.apiErrorMessage(err));
        })
        fcb();
      }
    );
  };
};

const createReport = (payload, cb = () => { }) => {
  return (dispatch) => {
    dispatch(request(reportType.CREATE_REPORTS_REQUEST));
    reportService.createReport(payload).then(
      ({ data, status }) => {
        // const responsePayload = {
        //   ...payload,
        //   reportId:data?.reportId
        // }
        // dispatch(
        //   success(reportType.CREATE_REPORTS_SUCCESS, { payload: data })
        // );
        cb(data)
      },
      ({ error, code }) => {
        dispatch(failure(reportType.CREATE_REPORTS_FAILURE, error.toString()));
        getMessage(`createReport-post-${code}`).then(delay(500)).then((err) => {
          dispatch(appActions.apiErrorMessage(err));
        })
      }
    );
  };
};

const shareReportDocument = (reportId, documentId, payload) => {
  return (dispatch) => {
    dispatch(request(reportType.SHARE_REPORT_DOCUMENTS_REQUEST));
    reportService.shareReportDocument(reportId, documentId, payload).then(
      ({ data, status }) => {
        dispatch(
          success(reportType.SHARE_REPORT_DOCUMENTS_SUCCESS, { payload: data })
        );
        getMessage(`shareReportDocumentById-post-${status}`).then(delay(500)).then((msg) => {
          dispatch(appActions.apiSuccessMessage(msg));
        })
      },
      ({ error, code }) => {
        dispatch(failure(reportType.SHARE_REPORT_DOCUMENTS_FAILURE, error.toString()));
        getMessage(`shareReportDocumentById-post-${code}`).then(delay(500)).then((err) => {
          dispatch(appActions.apiErrorMessage(err));
        })
      }
    );
  };
};


const deleteReport = (id) => {
  return (dispatch) => {
    dispatch(request(reportType.DELETE_REPORTS_REQUEST));
    reportService.deleteReport(id).then(
      ({ data, status }) => {
        dispatch(success(reportType.DELETE_REPORTS_SUCCESS, { payload: id }));
        getMessage(`deleteReport-delete-${status}`).then(delay(500)).then((msg) => {
          dispatch(appActions.apiSuccessMessage(msg));
        })
      },
      ({ error, code }) => {
        dispatch(failure(reportType.DELETE_REPORTS_FAILURE, error.toString()));
        getMessage(`deleteReport-delete-${code}`).then(delay(500)).then((err) => {
          dispatch(appActions.apiErrorMessage(err));
        })
      }
    );
  };
};

const deleteDocumentById = (reportId, documentId) => {
  return (dispatch) => {
    dispatch(request(reportType.DELETE_DOCUMENT_REPORTS_REQUEST));
    reportService.deleteDocumentById(reportId, documentId).then(
      ({ data, status }) => {
        dispatch(success(reportType.DELETE_DOCUMENT_REPORTS_SUCCESS, { payload: documentId }));
        getMessage(`deleteDocumentById-delete-${status}`).then(delay(500)).then((msg) => {
          dispatch(appActions.apiSuccessMessage(msg));
        })
      },
      ({ error, code }) => {
        dispatch(failure(reportType.DELETE_DOCUMENT_REPORTS_FAILURE, error.toString()));
        getMessage(`deleteDocumentById-delete-${code}`).then(delay(500)).then((err) => {
          dispatch(appActions.apiErrorMessage(err));
        })
      }
    );
  };
};

const getReportDocumentUrl = (reportId, documentId) => {
  return (dispatch) => {
    dispatch(request(reportType.DOWNLOAD_REPORT_REQUEST));
    reportService.getReportDocumentUrl(reportId, documentId).then(
      ({ data, status }) => {
        dispatch(
          success(reportType.DOWNLOAD_REPORT_SUCCESS, { payload: data })
        );
        getMessage(`downloadReportDocumentById-get-${status}`).then(delay(500)).then((msg) => {
          dispatch(appActions.apiSuccessMessage(msg));
        })
      },
      ({ error, code }) => {
        dispatch(failure(reportType.DOWNLOAD_REPORT_FAILURE, error.toString()));
        getMessage(`downloadReportDocumentById-get-${code}`).then(delay(500)).then((err) => {
          dispatch(appActions.apiErrorMessage(err));
        })
      }
    );
  };
};

const downloadReportDocument = (reportId, documentId) => {
  return (dispatch) => {
    dispatch(request(reportType.DOWNLOAD_REPORT_REQUEST));
    reportService.downloadReportDocument(reportId, documentId).then(
      ({ data, status }) => {
        dispatch(
          success(reportType.DOWNLOAD_REPORT_SUCCESS, { payload: data })
        );
        getMessage(`downloadReportDocumentById-get-${status}`).then(delay(500)).then((msg) => {
          dispatch(appActions.apiSuccessMessage(msg));
        })
      },
      ({ error, code }) => {
        dispatch(failure(reportType.DOWNLOAD_REPORT_FAILURE, error.toString()));
        getMessage(`downloadReportDocumentById-get-${code}`).then(delay(500)).then((err) => {
          dispatch(appActions.apiErrorMessage(err));
        })
      }
    );
  };
};

const generateReportDocument = (reportId, cb = () => { }) => {
  return (dispatch) => {
    dispatch(request(reportType.GENERATE_REPORT_REQUEST));
    reportService.generateReportDocument(reportId).then(
      ({ data, status }) => {
        dispatch(
          success(reportType.GENERATE_REPORT_SUCCESS, { payload: data })
        );
        getMessage(`generateReport-put-${status}`).then(delay(500)).then((msg) => {
          dispatch(appActions.apiSuccessMessage(msg));
        })
        cb();
      },
      ({ error, code }) => {
        dispatch(failure(reportType.GENERATE_REPORT_FAILURE, error.toString()));
        getMessage(`generateReport-put-${code}`).then(delay(200)).then((err) => {
          dispatch(appActions.apiErrorMessage(err));
        })
      }
    );
  };
};


export const reportActions = {
  getReportById,
  getAllReports,
  getAllReportDocument,
  updateReport,
  createReport,
  deleteReport,
  deleteDocumentById,
  shareReportDocument,
  generateReportDocument,
  downloadReportDocument,
  getReportDocumentUrl
};
