import { dragAndDropTypes } from "../types";
import { success } from "../../helpers";

const setDragList = (dragList=[]) => {
    return dispatch => {
        dispatch(success(dragAndDropTypes.SET_DRAG_LIST,{payload: dragList}));
    }
}

const setDropList = (dropList=[]) => {
    return dispatch => {
        dispatch(success(dragAndDropTypes.SET_DROP_LIST,{payload: dropList}));
    }
}

const addToDropList = (newAdditions=[]) => {
    return dispatch => {
        dispatch(success(dragAndDropTypes.ADD_TO_DROP_LIST ,{payload: newAdditions}));
    }
}

const deleteFromDropList = (id) => {
    return dispatch => {
        dispatch(success(dragAndDropTypes.DELETE_FROM_DROP_LIST ,{id: id}));
    }
}

const emptyLists = () => {
    return dispatch => {
        dispatch(success(dragAndDropTypes.EMPTY_LISTS));
    }
}

const setErrorMessage = (message) => {
    return dispatch => {
        dispatch(success(dragAndDropTypes.SET_ERROR_MESSAGE, {message}));
    }
}

export const dragAndDropActions = {
    setDragList,
    setDropList,
    addToDropList,
    deleteFromDropList,
    emptyLists,
    setErrorMessage
}