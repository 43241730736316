export const tenantMessages = (item) => {
    return {
        "tenant-addTenantFormValidation-name": "Tenant name is required",
        "tenant-addTenantFormValidation-corporationName": "Corporation name is required",
        "tenant-addTenantFormValidation-brandName": "Brand name is required",
        "tenant-addTenantFormValidation-adminName": "Admin name is required",
        "tenant-addTenantFormValidation-adminEmail": "Admin email is required",
        "tenant-addTenantFormValidation-adminPhone": "Admin phone is required",
        "tenant-addTenantFormValidation-billingEmail": "Billing email is required",
        "tenant-addTenantFormValidation-supportEmail": "Support email is required",
        "tenant-addTenantFormValidation-logo": "Logo is required",
        "tenant-addTenantFormValidation-logoSize": "Logo size should not exceed more than 5 MB",
        "tenant-addTenantFormValidation-primaryColor": "Primary color is required",
        "tenant-addTenantFormValidation-secondaryColor": "Secondary Color is required",
        "tenant-addTenantFormValidation-primaryTextColor": "Primary text color is required",
        "tenant-addTenantFormValidation-secondaryTextColor": "Secondary text color is required ",
        "tenant-addTenantFormValidation-nameMinLength": "Tenant name should have minimum 3 characters",
        "tenant-addTenantFormValidation-nameMaxLength": "Tenant name should have maximum 8 characters",
        "tenant-addTenantFormValidation-nameAplhaValidation": "Tenant name should only contain 'a-z' characters",
        "tenant-addTenantFormValidation-corporationNameMinLength": "Corporation name should have minimum 3 characters",
        "tenant-addTenantFormValidation-corporationNameMaxLength": "Corporation name should have maximum 30 characters",
        "tenant-addTenantFormValidation-corporationNameAplhaValidation": "Corporation name should only contain 'a-z', 'A-Z', '  ' characters",
        "tenant-addTenantFormValidation-brandNameMinLength": "Brand name should have minimum 3 characters",
        "tenant-addTenantFormValidation-brandNameMaxLength": "Brand name should have maximum 30 characters",
        "tenant-addTenantFormValidation-brandNameAplhaValidation": "Brand name should only contain 'a-z', 'A-Z', '  ' characters",
        "tenant-addTenantFormValidation-adminNameMinLength": "Admin name should have minimum 3 characters",
        "tenant-addTenantFormValidation-adminNameMaxLength": "Admin name should have maximum 30 characters",
        "tenant-addTenantFormValidation-adminNameAplhaValidation": "Admin name should only contain 'a-z', 'A-Z', '-', '  ' characters",
        "tenant-addTenantFormValidation-adminEmailInvalid": "Admin email invalid",
        "tenant-addTenantFormValidation-adminPhoneInvalid": "Admin phone invalid",
        "tenant-addTenantFormValidation-billingEmailInvalid": "Billing email invalid",
        "tenant-addTenantFormValidation-supportEmailInvalid": "Support email invalid",
        "tenant-addTenantFormValidation-primaryColorInvalid": "Primary color should only contain hex code",
        "tenant-addTenantFormValidation-primaryTextColorInvalid": "Primary text color should only contain hex code ",
        "tenant-addTenantFormValidation-secondaryColorInvalid": "Secondary color should only contain hex code",
        "tenant-addTenantFormValidation-secondaryTextColorInvalid": "Secondary text color should only contain hex code",
        'tenant-get-noTenantFound': 'There is no tenants available, Please create a new tenant.',
        "tenant-tooltip-enabled": "The tenant is enabled and acitve.",
        "tenant-tooltip-disabled": "The tenant is disabled and and cannot be used.",
        "tenant-gateways-emptyList": "No gateways found.",
        "tenant-gatewayStatistics-empty": "There are no Tenants, please create a Tenant from Tenant Management.",
        "tenant-unassignedGateways-empty": "No unassigned gateways found.",
        "tenant-debugGateway-gatewayConnecting": "Connecting to gateway",
        "tenant-debugGateway-gatewayError": "Could not connect to gateway.",
        "tenant-debugGateway-mqttConnecting": "Establishing MQTT connection",
        "tenant-debugGateway-mqttError": "Could not make MQTT connection",
        'disconnect-send-confirm': `Are you sure you want to disconnect the gateway with mac address: ${item} ?`,
        'debugEnd-send-confirm': <p>Please copy the commands to the clipboard.<br /><br /><b>The executed commands will be lost, are you sure you want to end the debugging session?</b></p>,
        "deleteTenant-send-confirm": `Are you sure you want to delete ${item} tenant?`,
        "disableTenant-send-confirm": `Are you sure you want to disable ${item} tenant?`

    }
}



export const tenantApiMessages = {
    "tenant-get-200": "Tenant List successfully retrieved.",
    "tenant-get-401": "Unable to retrieve the Tenant, please verify your access and try again.",
    "tenant-get-403": "Unable to retrieve the Tenant, please verify your access and try again.",
    "tenant-get-500": "Unable to retrieve the Tenant due to internal error, please try again later.",

    "createTenant-post-200": "Tenant successfully created.",
    "createTenant-post-400": "Unable to create the Tenant, please verify inputs and try again.",
    "createTenant-post-401": "Unable to create the Tenant, please verify your access and try again.",
    "createTenant-post-403": "Unable to create the Tenant, please verify your access and try again.",
    "createTenant-post-404": "Unable to create the Tenant, please try again later.",
    "createTenant-post-500": "Unable to create the Tenant due to internal error, please try again later.",
    "createTenant-post-502": "Unable to create the Tenant due to server is unavailable, please try again later.",

    "tenantById-get-200": "Tenant successfully retrieved.",
    "tenantById-get-400": "Unable to retrieve the Tenant, please verify inputs and try again.",
    "tenantById-get-401": "Unable to retrieve the Tenant, please verify your access and try again.",
    "tenantById-get-403": "Unable to retrieve the Tenant, please verify your access and try again.",
    "tenantById-get-404": "Unable to retrieve the Tenant, please refresh the page and try again.",
    "tenantById-get-500": "Unable to retrieve the Tenant due to internal error, please try again later.",

    "tenantByName-get-200": "Tenant successfully retrieved.",
    "tenantByName-get-400": "Unable to retrieve the Tenant, please verify inputs and try again.",
    "tenantByName-get-401": "Unable to retrieve the Tenant, please verify your access and try again.",
    "tenantByName-get-403": "Unable to retrieve the Tenant, please verify your access and try again.",
    "tenantByName-get-404": "Unable to retrieve the Tenant, please refresh the page and try again.",
    "tenantByName-get-500": "Unable to retrieve the Tenant due to internal error, please try again later.",

    "getTenantWebconfig-get-200": "Tenant Webconfig retrieved successfully.",
    "getTenantWeconfig-get-400": "Unable to fetch the Tenant webconfig, please verify inputs and try again.",
    "getTenantWeconfig-get-401": "Unable to fetch the Tenant webconfig, please verify your access and try again.",
    "getTenantWeconfig-get-403": "Unable to fetch the Tenant webconfig, please verify your access and try again.",
    "getTenantWeconfig-get-404": "Unable to fetch the Tenant webconfig, please refresh the page and try again.",
    "getTenantWeconfig-get-500": "Unable to fetch the Tenant webconfig  due to internal error, please try again later.",


    "updateTenant-put-200": "Tenant successfully updated.",
    "updateTenant-put-400": "Unable to edit the Tenant, please verify inputs and try again.",
    "updateTenant-put-401": "Unable to edit the Tenant, please verify your access and try again.",
    "updateTenant-put-403": "Unable to edit the Tenant, please verify your access and try again.",
    "updateTenant-put-404": "Unable to update the Tenant, please refresh the page and try again.",
    "updateTenant-put-500": "Unable to update the Tenant due to internal error, please try again later.",

    "updateTenantWebconfig-put-200": "Tenant Webconfig successfully updated.",
    "updateTenantWeconfig-put-400": "Unable to update the Tenant webconfig, please verify inputs and try again.",
    "updateTenantWeconfig-put-401": "Unable to edit the Tenant webconfig, please verify your access and try again.",
    "updateTenantWeconfig-put-403": "Unable to edit the Tenant webconfig, please verify your access and try again.",
    "updateTenantWeconfig-put-404": "Unable to update the Tenant webconfig, please refresh the page and try again.",
    "updateTenantWeconfig-put-500": "Unable to update the Tenant webconfig due to internal error, please try again later.",

    "deleteTenant-delete-200": "Tenant successfully deleted.",
    "deleteTenant-delete-400": "Unable to delete the Tenant, please verify inputs and try again.",
    "deleteTenant-delete-401": "Unable to delete the Tenant list, please verify your access and try again.",
    "deleteTenant-delete-403": "Unable to delete the Tenant list, please verify your access and try again.",
    "deleteTenant-delete-404": "Unable to delete the Tenant, please refresh the page and try again.",
    "deleteTenant-delete-500": "Unable to delete the Tenant  due to internal error, please try again later.",

    "enableTenant-put-200": "Tenant successfully enabled",
    "enableTenant-put-400": "Unable to enable the Tenant, please verify inputs and try again.",
    "enableTenant-put-401": "Unable to enable the Tenant, please verify your access and try again.",
    "enableTenant-put-403": "Unable to enable the Tenant, please verify your access and try again.",
    "enableTenant-put-404": "Unable to enable the Tenant, please refresh the page and try again.",
    "enableTenant-put-500": "Unable to enable the Tenant  due to internal error, please try again later.",

    "disableTenant-put-200": "Tenant successfully disabled",
    "disableTenant-put-400": "Unable to disable the Tenant, please verify inputs and try again.",
    "disableTenant-put-401": "Unable to disable the Tenant, please verify your access and try again.",
    "disableTenant-put-403": "Unable to disable the Tenant, please verify your access and try again.",
    "disableTenant-put-404": "Unable to disable the Tenant, please refresh the page and try again.",
    "disableTenant-put-500": "Unable to disable the Tenant  due to internal error, please try again later.",
}