/***
 *
 * Controller class for user.
 * @file DebugGateway.js
 * @description DebugGateway component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './DebugGateway.style.js';
import './DebugGateway.scss';
import { Box, Grid } from '@mui/material';
import CustomTypography from '../CustomTypography/index.js';
import StyledButton from '../StyledButton/index.js';
import CustomLabel from '../CustomLabel/index.js';
import StyledTextfield from '../StyledTextfield/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { closeMqttConnection, mqttRequest, openMqttConnection, unsubscribe, unsubscribeAll } from '../../services/mqtt.service.js';
import { gatewayDebugTimeout } from '../../config/mqttConfig.js';
import StyledSpinner from '../StyledSpinner/index.js';
import { confirmationDialog } from '../../helpers/device.helper.js';
import { getMessageStr } from '../../helpers/message.helper.js';
import PageHeader from '../PageHeader/index.js';
import debugIcon from '../../assets/icons/fix.svg'
import { history } from '../../helpers/history.helper.js';
import { useQuery } from '../../hooks/useQuery.js';
import { deviceActions } from '../../redux/actions/device.actions.js';
import { tenantActions } from '../../redux/actions/tenant.actions.js';
import _ from 'lodash';
import { appConnections, defaultTenantName } from '../../config/appConfig.js';

const DebugGateway = ({ ...props }) => {
  const classes = useStyles();
  const query = useQuery();
  const serialNumber = query?.get("serialNumber") || "";
  const tenantName = query?.get("tenantName") || null;

  const gw = useSelector(state => state?.devices?.deviceList?.find(d => serialNumber === d?.serialNumber));
  const tenant = useSelector(state => state?.tenants?.tenantDetails);
  const [command, setCommand] = useState({ commandIndex: null, currentCommand: "", executedCommands: [], copyCommands: false, executingCommand: false });
  const dispatch = useDispatch();
  const [directoryLocation, setDirectoryLocation] = useState("");
  const [loading, setLoading] = useState({ show: false, message: "" });
  const [error, setError] = useState({ show: false, message: "" });
  const [gateway, setGateway] = useState(null);
  const [tenantDetails, setTenantDetails] = useState(null);
  const [mqttClient, setMqttClient] = useState({});


  const endDebugSession = () => {
    // setDialogWrapper(false);
    unsubscribeAll(mqttClient, dispatch, () => {
      appConnections?.mqtt && closeMqttConnection(() => {
        setMqttClient({});
      });
    })
    // unsubscribe(`${tenantDetails?.tenantName || defaultTenantName}/${gateway?.macAddress[0]}/debugCommand/output`, mqttClient, dispatch, () => {
    // });
    setCommand({ currentCommand: "", executedCommands: [], copyCommands: false, executingCommand: false });
  }
  const sendCommand = (cmd, tenantDetails) => {
    const sid = mqttRequest({
      subTopic: `${tenantDetails?.tenantName || defaultTenantName}/${gateway?.macAddress[0]}/debugCommand/output`,
      pubTopic: `${tenantDetails?.tenantName || defaultTenantName}/${gateway?.macAddress[0]}/debugCommand`,
      deviceConfig: { command: cmd, cmd: "debugCommand" },
      onRecieve: (msg) => {
        if (msg?.cmd === "debugCommand" && msg?.hasOwnProperty("response")) {
          clearTimeout(timeout);
          if (msg?.command?.split(" ")[0] === "cd" && !msg?.debugErr) {
            setDirectoryLocation(msg?.response)
          }
          setCommand((c) => {
            const executedCommands = c?.executedCommands?.map(t => {
              if (t?.sid === msg?.sid && t?.cmd === msg?.command) {
                t.resp = t.resp + msg?.response;
              }
              return t
            })
            return { ...command, commandIndex: null, currentCommand: "", copyCommands: false, executedCommands, executingCommand: false }
          });
        }
      },
      onFail: () => {

      }
    }, mqttClient, dispatch, "debugGateway");

    const timeout = setTimeout(() => {
      setCommand((c) => {
        const executedCommands = c?.executedCommands?.map(t => {
          if (t?.sid === sid) {
            t.resp = "No Response";
          }
          return t
        })
        return { ...command, currentCommand: "", copyCommands: false, executedCommands, executingCommand: false }
      });

    }, gatewayDebugTimeout);
    setCommand({ ...command, copyCommands: false, currentCommand: "", executingCommand: true, executedCommands: [...command?.executedCommands, { cmd, resp: "", sid, directoryLocation }] });
  }

  const downloadCommands = () => {
    setCommand({ ...command, copyCommands: true })
    let result = "";
    command?.executedCommands?.forEach(t => {
      result = result + `${t?.directoryLocation} ` + t?.cmd + "\n" + t?.resp + (t?.resp === "" ? "" : "\n");
    })
    const link = document?.createElement("a");
    const file = new Blob([result], { type: 'text/plain' })
    link.href = URL.createObjectURL(file);
    link.download = "debugGateway.txt";
    link.click();
    URL?.revokeObjectURL(link?.href);
    link?.remove();
  }

  const getDirectoryLocation = () => {
    let timeout = null;
    timeout = setTimeout(() => {
      setLoading({ show: false, message: "" });
      setError({ show: true, message: getMessageStr("tenant-debugGateway-gatewayError") });
    }, gatewayDebugTimeout);
    setLoading({ show: true, message: getMessageStr("tenant-debugGateway-gatewayConnecting") });
    mqttRequest({
      subTopic: `${tenantDetails?.tenantName || defaultTenantName}/${gateway?.macAddress[0]}/debugCommand/output`,
      pubTopic: `${tenantDetails?.tenantName || defaultTenantName}/${gateway?.macAddress[0]}/debugCommand`,
      deviceConfig: { command: "cd .", cmd: "debugCommand" },
      onRecieve: (msg) => {
        if (msg?.cmd === "debugCommand" && msg?.hasOwnProperty("response")) {
          clearTimeout(timeout);
          setLoading({ show: false, message: "" });
          setDirectoryLocation(msg?.response);
        }
      },
      onFail: () => {
        setLoading({ show: false, message: "" });
        setError({ show: true, message: getMessageStr("tenant-debugGateway-gatewayError") });
      }
    }, mqttClient, dispatch, "debugGateway");
  };

  const handleKeyPress = (e, ind = null) => {
    if (e?.key === "Enter" && !command?.executingCommand && command?.currentCommand !== "") {
      if (command?.currentCommand !== "") {
        const cmd = command?.currentCommand;
        sendCommand(cmd, tenantDetails);
      }
      return;
    }
    else if (e?.key === "Enter" && !command?.executingCommand && command?.currentCommand === "") {
      setCommand({ ...command, copyCommands: false, currentCommand: "", executingCommand: false, executedCommands: [...command?.executedCommands, { cmd: command?.currentCommand, resp: "", sid: "", directoryLocation }] });
      return;
    }
    else if (e?.key === "ArrowUp" && command?.executedCommands?.length > 0) {
      let index = (command?.commandIndex === null ? command?.executedCommands?.length - 1 : command?.commandIndex - 1 >= 0 ? command?.commandIndex - 1 : 0);

      while (command?.executedCommands[index]?.cmd === "" && (index - 1) >= 0) {
        index = index - 1;
      }
      setCommand({ ...command, currentCommand: command?.executedCommands[index]?.cmd === "" ? command?.currentCommand : command?.executedCommands[index]?.cmd, commandIndex: command?.executedCommands[index]?.cmd === "" ? command?.commandIndex : index })
    }
    else if (e?.key === "ArrowDown" && command?.executedCommands?.length > 0 && command?.commandIndex !== null && command?.commandIndex + 1 < command?.executedCommands?.length) {
      let index = command?.commandIndex + 1;
      while (command?.executedCommands[index]?.cmd === "" && (index + 1) < command?.executedCommands?.length) {
        index = index + 1;
      }
      setCommand({ ...command, commandIndex: command?.executedCommands[index]?.cmd === "" ? command?.commandIndex : index, currentCommand: command?.executedCommands[index]?.cmd === "" ? command?.currentCommand : command?.executedCommands[index]?.cmd })
    }
  }

  useEffect(() => {
    if (!_.isEmpty(gw)) {
      setGateway(gw)
    }
    else {
      dispatch(deviceActions?.getAssignedGateways({}, tenantName, false))
    }
    return () => {
      endDebugSession();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gw])

  useEffect(() => {
    if (_.isEmpty(tenant) && tenantName !== null) {
      dispatch(tenantActions.getTenantByName(tenantName));
    }
    else {
      setTenantDetails(tenant)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [tenant])

  useEffect(() => {
    if (!_.isEmpty(mqttClient) && mqttClient?.connected && gateway?.hasOwnProperty("macAddress")) {
      getDirectoryLocation();
    }
  }, [mqttClient?.connected, gateway])

  useEffect(() => {
    if (appConnections?.mqtt && !mqttClient?.connected && tenantDetails?.tenantName) {
      setLoading({ show: true, message: getMessageStr("tenant-debugGateway-mqttConnecting") });
      setMqttClient(openMqttConnection(dispatch, () => {
        setLoading({ show: false, message: "" });
      }, () => {
        setLoading({ show: false, message: "" });
        setError({ show: true, message: getMessageStr("tenant-debugGateway-mqttError") });
      }, tenantDetails?.tenantName));
    }
  }, [tenantDetails?.tenantName])

  return (
    <Box className={classes?.DebugGateway || ""} data-testid="DebugGateway">
      <PageHeader
        title={`Debug Gateway - ${serialNumber}`}
        bckbtn={true}
        icon={debugIcon}
        backfunc={() => {
          history.goBack();
        }}
      >
        <StyledButton
          sx={{ ml: 1 }}
          disabled={command?.executedCommands?.length === 0}
          onClick={(e) => { downloadCommands() }}
        >
          Download
        </StyledButton>
      </PageHeader>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
        {/* <CustomTypography
          weight={700}
          size={14}
          lineHeight={14.63}
          color="#000">
          Command Console
        </CustomTypography> */}

      </Box>
      <Box className={classes?.scrollBox}>
        {
          loading?.show ?
            <Box className="loader" sx={{ display: "flex", alignItems: "center" }}>
              <CustomTypography
                weight={700}
                size={12}
                lineHeight={14.63}
                styles={{ fontFamily: "Consolas" }}
                color="#FFFFFF">
                {loading?.message}
              </CustomTypography>
              <StyledSpinner type="line" size={"8px"} />
            </Box>
            :
            error?.show ?
              <CustomTypography
                weight={700}
                size={12}
                lineHeight={14.63}
                styles={{ fontFamily: "Consolas" }}
                color="#FFFFFF">
                {error?.message}
              </CustomTypography>
              :
              <Box>
                {command?.executedCommands?.map((t, ind) => {
                  return <Box sx={{ ml: 1 }} key={ind} className={classes?.executedCommands}>
                    <Box sx={{ display: "flex" }}>
                      <CustomLabel
                      >{`${t?.directoryLocation}`}</CustomLabel>
                      <CustomTypography
                      >
                        {t?.cmd}
                      </CustomTypography>
                    </Box>
                    <Box sx={{ display: "flex" }}>
                      <pre>
                        <CustomTypography
                        >
                          {t?.resp}
                        </CustomTypography>
                      </pre>

                    </Box>
                  </Box>
                })}
                {
                  !command?.executingCommand &&
                  <Grid comtainer className={classes?.currentLine}>
                    <Grid item xs={"auto"}>
                      <CustomLabel
                      >{`${directoryLocation}`}</CustomLabel>
                    </Grid>
                    <Grid item sx={{ width: "100%" }}>
                      <StyledTextfield
                        autoComplete={"off"}
                        sx={{ width: "100%", "& fieldset": { border: "none" } }}
                        autoFocus={true}
                        onKeyDown={(e) => { handleKeyPress(e) }
                        }
                        value={command?.currentCommand}
                        onChange={(e) => {
                          setCommand({ ...command, commandIndex: e?.target?.value === "" ? null : command?.commandIndex, currentCommand: e?.target?.value })
                        }}
                      />
                    </Grid>
                  </Grid>
                }
              </Box>
        }
      </Box>

      {/* <Grid container spacing={1}>
        <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
          <CustomLabel
            style={{ fontSize: 14, fontWeight: 500, color: "#000000CC", marginBottom: "0px" }}
          >
            Command
          </CustomLabel>
        </Grid>
        <Grid item xs={12} >
          <StyledTextfield
            sx={{ width: "100%", }}
            value={command?.currentCommand}
            onChange={(e) => { setCommand({ ...command, currentCommand: e?.target?.value }) }}
          />
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "right" }} >
          <StyledButton
            disabled={command?.executingCommand ? true : command?.currentCommand === ""}
            sx={{ minHeight: "40px", }}
            onClick={(e) => {
              
            }}
          >
            Execute
          </StyledButton>
        </Grid>
      </Grid> */}
    </Box >
  )
};

DebugGateway.propTypes = {};

DebugGateway.defaultProps = {};

export default DebugGateway;
