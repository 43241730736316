export const fotaTypes = {

    UPGRADE_FIRMWARE_REQUEST: 'UPGRADE_FIRMWARE_REQUEST',
    UPGRADE_FIRMWARE_SUCCESS: 'UPGRADE_FIRMWARE_SUCCESS',
    UPGRADE_FIRMWARE_FAILURE: 'UPGRADE_FIRMWARE_FAILURE',

    GET_NEXT_APPLICABLE_FIRMWARES_REQUEST: 'GET_NEXT_APPLICABLE_FIRMWARE_REQUEST',
    GET_NEXT_APPLICABLE_FIRMWARES_SUCCESS: 'GET_NEXT_APPLICABLE_FIRMWARES_SUCCESS',
    GET_NEXT_APPLICABLE_FIRMWARES_FAILURE: 'GET_NEXT_APPLICABLE_FIRMWARES_FAILURE',

    GET_FIRMWARE_DISTRIBUTION_REQUEST: 'GET_FIRMWARE_DISTRIBUTION_REQUEST',
    GET_FIRMWARE_DISTRIBUTION_SUCCESS: 'GET_FIRMWARE_DISTRIBUTION_SUCCESS',
    GET_FIRMWARE_DISTRIBUTION_FAILURE: 'GET_FIRMWARE_DISTRIBUTION_FAILURE',

    GET_FIRMWARE_LIST_REQUEST: 'GET_FIRMWARE_LIST_REQUEST',
    GET_FIRMWARE_LIST_SUCCESS: 'GET_FIRMWARE_LIST_SUCCESS',
    GET_FIRMWARE_LIST_FAILURE: 'GET_FIRMWARE_LIST_FAILURE',

    DELETE_FIRMWARE_REQUEST: 'DELETE_FIRMWARE_REQUEST',
    DELETE_FIRMWARE_SUCCESS: 'DELETE_FIRMWARE_SUCCESS',
    DELETE_FIRMWARE_FAILURE: 'DELETE_FIRMWARE_FAILURE',

    UPDATE_FW_STATUS: 'UPDATE_FW_STATUS',
    UPDATE_CONNECTIVITY_STATUS: 'UPDATE_DEVICE_CONNECTIVITY_STATUS'

}