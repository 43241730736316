/***
 *
 * Controller class for user.
 * @file SearchWrapper.js
 * @description SearchWrapper component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './SearchWrapper.style.js';
import './SearchWrapper.scss';
import { Box } from '@mui/material';
import SearchBox from '../SearchBox/index.js';
import { useEffect } from 'react';
import { useState } from 'react';
import CustomTypography from '../CustomTypography/index.js';

const SearchWrapper = ({ placeholder = "Search", data = null, searchValue = "", searchIn = null, filteredData = () => { }, autoComplete = false, autoFill = false, sx = {}, style = {}, onChange = () => { }, label = null, customsx = { container: {} }, ...props }) => {
  const classes = useStyles();
  const [value, setValue] = useState(searchValue);

  useEffect(() => {
    setValue(searchValue);
  }, [searchValue])
  return (
    <Box className={classes?.SearchWrapper + " SearchWrapper" || ""} data-testid="SearchWrapper" sx={{ ...customsx?.container }}>
      {label && (
        typeof label === "string" ?
          <CustomTypography content={label} styles={{ marginBottom: "8px" }} weight={600} size={12} lineHeight={14.63} color="rgba(0, 0, 0, 0.8)" />
          :
          label
      )
      }
      <SearchBox
        className="space-search"
        placeholder={placeholder}
        data={data || []}
        autoComplete={autoComplete}
        onChange={onChange}
        searchValue={value}
        filteredData={filteredData}
        searchIn={searchIn}
        autoFill={autoFill}
        sx={{ ...sx }}
        style={{ ...style }}
        {...props}
      />
    </Box>
  )
};

SearchWrapper.propTypes = {};

SearchWrapper.defaultProps = {};

export default SearchWrapper;
