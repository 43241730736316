/***
 *
 * Controller class for user.
 * @file InputSpinner.js
 * @description InputSpinner component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import './InputSpinner.scss';
import StyledButton from '../StyledButton';

const InputSpinner = ({min=0, max=100, value=0, step=1, readOnly=false, changedValue}) => {
  const [inputValue, setInputValue] = useState(0);

  const handleClick = (param) => {
    let val = inputValue;
    if(param === "dec" && min < inputValue) {
      val -= step;
    } else if(param === "inc" && max > inputValue) {
      val += step;
    }

    // let v = inputValue;
    // v = v + val;
    setInputValue(val);
  }

  const handleChange = (e) => {
    const input = e?.target;
    const val = input.value.replace(/\+|-/ig, '');
    let regex = new RegExp('[0-9]*', 'g');
    if(regex.test(val)) {
      if(val > max) {
        setInputValue(max);
      } else if(val < min) {
        setInputValue(min);
      } else {
        setInputValue(Number(val));
      }
    }   
  }

  useEffect(() => {
    setInputValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    changedValue(inputValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue])

  return (
    <Grid sx={{height:'30px'}} className="InputSpinner btn-group" data-testid="InputSpinner" role="group" aria-label="Basic example">
      <StyledButton startIcon={<RemoveOutlinedIcon/>} size='small' color='primary' type="button" disabled={min >= inputValue} onClick={()=>handleClick("dec")} ></StyledButton>
      <input type="number" value={inputValue} min={min} max={max} step={step} readOnly={readOnly} onChange={handleChange} style={{width: "min-content", maxWidth: 200, textAlign:'center', borderRadius: 0, borderWidth: 1, borderLeft: "none", borderRight: "none"}} />
      <StyledButton startIcon={<AddOutlinedIcon/>} size={'small'} type="button" disabled={max <= inputValue} onClick={()=>handleClick("inc")} > <i className="fa fa-plus" aria-hidden="true"></i> </StyledButton>
    </Grid>
  )
};

InputSpinner.propTypes = {};

InputSpinner.defaultProps = {};

export default InputSpinner;
