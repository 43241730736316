import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    ReportList: {

    },
    dotStatus: {
        height: '6px',
        width: '6px',
        borderRadius: '50%',
        display: 'inline-block'
    }
});
