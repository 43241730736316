import { API_URL } from '../config/appConfig'
import { API } from '.'
import { handleResponse } from '../helpers';

const getAllSpaceTypes = () => {
    return API.get(`${API_URL.space}/spaceTypes`, "getAllSpaceTypes", {}).then(handleResponse);
}

const addSpaceType = (payload) => {
    return API.post(`${API_URL.space}/spaceTypes/`, "addSpaceType", payload).then((res) => handleResponse(res, true));
}
const editSpaceType = (spaceType, payload) => {
    return API.put(`${API_URL.space}/spaceTypes/${spaceType}`, "editSpaceType", payload).then((res) => handleResponse(res, true));
}

const deleteSpaceType = (spaceType) => {
    return API.delete(`${API_URL.space}/spaceTypes/${spaceType}`, `deleteSpaceType/${spaceType}`, {}).then((res) => handleResponse(res, true));
}


export const spaceTypeService = {
    getAllSpaceTypes,
    addSpaceType,
    editSpaceType,
    deleteSpaceType,
};