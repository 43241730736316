import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    KeyRotation: {
        "& .borderBox": {
            width: '100%',
            height: '100%',
            border: "1px solid #D9D9D9",
        },
        "& .header": {
            background: '#0F8D480D',
            padding: "16px",
            marginBottom: "8px"
        },
        "& .deviceTree": {
            "& a": {
                fontSize: "0.75rem"
            },
            "& .icon-3": {
                width: "12px",
                height: "12px",
            },
            height: "100%"

        },
        "& .warning": {
            filter: "invert(9%) sepia(100%) saturate(6974%) hue-rotate(2deg) brightness(97%) contrast(96%)"
        }
    }
});
