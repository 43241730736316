import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme=>({
    StyledTabs: {
        backgroundColor: 'rgba(15, 141, 72, 0.05)',
        "& .MuiTabs-scroller": {
            "overflow-x": "auto !important"
        },
        "& .MuiTabs-scroller::-webkit-scrollbar": {
            height: "0px !important"
        },
        '& .MuiTabs-root': {
            minHeight: "50px",
            '& .MuiButtonBase-root': {  
                marginTop: "2px",
                // '& img': {
                //     filter: "invert(51%) sepia(3%) saturate(5%) hue-rotate(321deg) brightness(89%) contrast(86%)"
                // },
                '& .MuiTypography-root': {
                    color: 'rgba(0, 0, 0, 1)',
                    fontSize: '0.75rem',
                    fontWeight: 400,
                },
                '&.Mui-selected': {
                    '& .MuiTypography-root': {
                        color: 'rgba(0, 0, 0, 1)',
                        fontWeight: 900,
                    },
                    '& img': {
                        filter: "invert(37%) sepia(14%) saturate(5108%) hue-rotate(112deg) brightness(99%) contrast(88%)"
                    },
                }
            }
        }
    }
}));
