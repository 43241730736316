/***
 *
 * Controller class for user.
 * @file SearchBox.js
 * @description SearchBox component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import { Autocomplete, Box } from '@mui/material';
import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import StyledButton from "../StyledButton/index.js";
// import PropTypes from 'prop-types';
import { useStyles } from './SearchBox.style.js';
import ClearIcon from '@mui/icons-material/Clear';
import './SearchBox.scss';
import _ from 'lodash';
import StyledTextfield from '../StyledTextfield/index.js';
import ImageWrapper from '../ImageWrapper/index.js';

const sleep = (delay = 0) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const SearchBox = ({ data = [], filteredData = () => { }, columns = { id: 1, label: "title" }, searchValue = "", width, rounded = null, styles = {}, sx = {}, inline, autoComplete, autoFill = true, searchIn = [], placeholder = "Search...", freeSolo = true, onChange = () => { }, ...props }) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState(data);
  const [value, setValue] = useState(null);
  const [timer, setTimer] = useState(null);
  const [inputValue, setInputValue] = useState("");

  const loading = open && options?.length === 0;

  const classes = useStyles();

  useEffect(() => {
    // if(searchValue){
    //both inputValue and value have to be strings
    setInputValue(searchValue?.hasOwnProperty('name') ? searchValue?.name : "");
    // }
  }, [searchValue?.name])

  useEffect(() => {
    //both inputValue and value have to be strings
    setValue(searchValue?.name || searchValue || "");
  }, [searchValue])

  const excludeKeys = (obj, keys) => {
    let includedKeys = JSON.parse(JSON.stringify(obj));
    if (keys) {
      keys?.forEach(k => {
        delete includedKeys[k];
      })
    }
    return includedKeys;
  }

  const includeKeys = (obj, keys) => {
    let includedKeys = [];
    // console.log("SearchBox step - 1 ", keys);
    if (keys && keys.length > 0) {
      // console.log("SearchBox step - 2 ", keys);
      keys?.forEach(k => {
        // console.log("SearchBox step - 3 ", obj, obj[k]);
        includedKeys[k] = obj[k].toLowerCase();
      })
    } else {
      // console.log("SearchBox step - 4 ", obj);
      includedKeys = obj;
    }

    return includedKeys;
  }

  const filterData = (fieldquery) => {
    const query = fieldquery?.toLowerCase() || null;
    // console.log("Search filter step 1 ------ ", fieldquery, data);
    onChange(null, query);
    setValue(query);
    if (!query) {
      filteredData(data);
      // console.log("Search filter step 2 ------ ", fieldquery, data);
      // return data;
    } else {
      let fd = [];
      if (searchIn && searchIn.length > 0) {
        fd = data.filter(item => Object.values(includeKeys(item, searchIn)).find(q => q?.toLowerCase()?.includes(query)));
      } else {
        fd = data.filter(item => Object.values(item).find(q => typeof (q) === 'string' && q?.toLowerCase()?.includes(query)));
      }
      // console.log("Search filter step 3 ------ ", fieldquery, fd);
      filteredData(fd);
      // console.log('search==>',fd);
    }
  };

  useEffect(() => {
    // console.log("Search filter step 99 ---- ", value, searchValue)
    if (!value) { filterData(value); }
  }, [value])


  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(1e3); // For demo purposes.
      if (active) {
        setOptions([...data]);
      }
    })();

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const handleChange = (e, value) => {
    setValue(value?.name);
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      onChange(e, value);
    }, 500);

    setTimer(newTimer);
  }

  return (
    <Box className="SearchBox" data-testid="SearchBox" width={width} >
      <Box className="SearchInput" style={{ ...{ borderRadius: rounded ? "30px" : "4px", display: inline ? "inline-block" : "block" }, ...styles }}>
        {autoComplete ? (
          <>
            <Autocomplete
              {...props}
              freeSolo
              inputValue={inputValue}
              onInputChange={(e, value) => {
                onChange(e, { name: value, id: value?.id })
                setInputValue(value)
              }}
              id="asynchronous-demo"
              value={value?.name || ""}
              open={open}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              onChange={handleChange}
              getOptionLabel={(option) => {
                // if(option.hasOwnProperty(columns?.label)) {
                //   return option[columns?.label]
                // } else {
                //   return option;
                // }   
                return option?.name || '';

              }}
              options={options}
              renderOption={(props, option) => {
                // console.log('options are==>', option);
                return (
                  <Box component="li" {...props} key={option?.id} gap={1}>
                    {option?.hasOwnProperty("icon") &&
                      <ImageWrapper src={option?.icon} height={16} width={16} />
                    }
                    {option?.name}
                  </Box>
                );
              }}
              loading={loading}
              renderInput={(params) => (
                <StyledTextfield
                  placeholder={placeholder}
                  className={classes?.AutoComplete}
                  sx={{ '& .MuiOutlinedInput-root': { borderRadius: rounded ? "30px" : "4px", background: "#fff", ...sx } }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {/* {loading ? <CircularProgress sx={{ marginRight: .8 }} color="inherit" size={20} /> : <SearchIcon sx={{ marginRight: .8 }} edge="end" />} */}
                        {loading ? <CircularProgress sx={{ marginRight: .8 }} color="inherit" size={20} /> : <StyledButton iconButton size='small' sx={{ display: !_.isEmpty(value) ? "" : "none" }} onClick={() => {
                          setValue(null);
                          onChange({}, "")
                          // filterData(null);
                        }} ><ClearIcon /></StyledButton>}
                        {/* {params.InputProps.endAdornment} */}
                      </>
                    )
                  }}
                  {...params}
                />
              )}
            />
          </>
        ) : (
          <>
            <StyledTextfield
              className={classes?.AutoComplete || ""}
              type="text"
              placeholder={placeholder}
              onChange={(e) => {
                setValue(value?.name);
                filterData(e.target.value)
              }}
              value={value}
              fullWidth
              sx={{ '& .MuiOutlinedInput-root': { borderRadius: rounded ? "30px" : "4px", height: '33px', pr: '0' }, background: "#fff", ...sx }}
              InputProps={{
                endAdornment: (
                  <>
                    {/* {loading ? <CircularProgress sx={{ marginRight: .8 }} color="inherit" size={20} /> : <SearchIcon sx={{ marginRight: .8 }} edge="end" />} */}
                    {loading ? <CircularProgress sx={{ marginRight: .8 }} color="inherit" size={20} /> : <StyledButton iconButton size='small' sx={{ display: !_.isEmpty(value) ? "" : "none" }} onClick={() => {
                      setValue(null);
                      // filterData(null);
                    }} ><ClearIcon /></StyledButton>}

                  </>
                )
              }}
            />
          </>
        )}
      </Box>

    </Box>
  )
};

SearchBox.propTypes = {};

SearchBox.defaultProps = {};

export default SearchBox;
