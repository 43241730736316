import { Box, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { defaultSelectedMenu } from '../../config/appConfig';
import { appActions } from '../../redux/actions';
// import routes from '../../routes/routes'; // Route list
import ImageWrapper from '../ImageWrapper';
// import PropTypes from 'prop-types';
import './TopNavigation.scss';

const NoTopBarRoutes = ['/profile'];

const TopNavigation = ({ open, routes }) => {
  const dispatch = useDispatch();
  const selectedLeftMenu = useSelector(state => state?.app?.selectedLeftMenu || defaultSelectedMenu.left);
  const selectedTopMenu = useSelector(state => state?.app?.selectedTopMenu || defaultSelectedMenu.top);
  // const _portal = useSelector(state => state?.authentication?.portal);
  const history = useHistory();
  const [value, setValue] = useState({ index: 0, name: "dashboard" });
  const [menuBar, setMenuBar] = useState(() => {
    return routes?.filter(r => r.topMenu && !r.isPublicRoute && r.parent === selectedLeftMenu );
  });

  const handleChange = (event, newValue) => {
    console.log("menu --- ", menuBar[newValue]?.id)
    setValue({ index: newValue });
    // dispatch(appActions?.selectLeftMenu(menuBar[newValue]?.id));
    dispatch(appActions?.selectTopMenu(menuBar[newValue]?.id));
  };

  useEffect(() => {
    setMenuBar(routes?.filter(r => r.topMenu && !r.isPublicRoute && r.parent === selectedLeftMenu ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLeftMenu])

  useEffect(() => {
    const currentRoute = window.location.pathname;
    menuBar.map((m, i) => {
      if (currentRoute.includes(m?.name)) {
        setValue({ index: i });
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuBar, window.location.pathname])

  useEffect(() => {
    menuBar.map((m, i) => {
      if (m?.id === selectedTopMenu) {
        setValue({ index: i });
      } else if (null === selectedTopMenu) {
        setValue({ index: 0 });
      }
    })
  }, [menuBar, selectedTopMenu])

  return (
    <>
    {/* {JSON.stringify(menuBar)} - {selectedLeftMenu} pppp */}
      {!NoTopBarRoutes.includes(history?.location?.pathname) ?
          <Tabs
            className="TopNavigation"
            data-testid="TopNavigation"
            value={value?.index}
            onChange={handleChange}
            initialselectedindex={selectedTopMenu?menuBar.indexOf(selectedTopMenu):0}
          // indicatorColor="primary"
          // centered
          >            
            {menuBar?.map((menu, index) => {
              return (
                <Tab
                  sx={{ mx: 2 }}
                  key={"menu-" + index}
                  id={"menu-" + index}
                  to={`/${menu?.path}`}
                  component={Link}
                  label={
                    <Box sx={{ px: 0 }} style={{ textTransform: "capitalize" }}>
                      <ImageWrapper
                        alt={`${menu?.label}`}
                        style={{
                          float: "left",
                          height: "19px",
                          width: "19px",
                          marginRight: "5px"
                        }}
                        // src={dashboardImage}
                        src={`${menu?.icon}`}
                      />
                      <Typography variant="caption" sx={{ lineHeight: "20px", display: "inline-block" }}>{`${menu?.label}`}</Typography>
                    </Box>
                  }
                />
              );
            })}
          </Tabs>
          : <Box height={'50px'}></Box>
       }
    </>
  )
};

TopNavigation.propTypes = {};

TopNavigation.defaultProps = {};

export default TopNavigation;
