// import { defaultSelectedMenu } from '../../config/appConfig';
import { mqttTypes } from '../types';

let initialState = {
    mqttRequests: [],
    mqttTopics: [],
}

export function mqttReducer(state = initialState, action) {
    switch (action.type) {
        case mqttTypes.ADD_MQTT_REQUEST:
            return {
                ...state,
                mqttRequests: [...state.mqttRequests, action.payload]
            };
        case mqttTypes.DELETE_MQTT_REQUEST:
            return {
                ...state,
                mqttRequests: state.mqttRequests.filter(item => {
                    if (typeof action?.payload === "object") {
                        let objMatch = true;
                        for (const t in action?.payload) {
                            objMatch = objMatch && (action?.payload[t] === item[t])
                        }
                        return !objMatch;
                    }
                    else {
                        return item.sid !== action.payload
                    }
                }),
            };
        case mqttTypes.ADD_MQTT_TOPIC:
            return {
                ...state,
                mqttTopics: Array.isArray(action?.payload) ? [...state?.mqttTopics, ...action?.payload] : [...state.mqttTopics, action?.payload],
            };
        case mqttTypes.DELETE_MQTT_TOPIC:
            return {
                ...state,
                mqttTopics: Array.isArray(action?.payload) ? state?.mqttTopics?.filter(t => !action?.payload?.includes(t?.topic)) : state?.mqttTopics?.filter(t => t?.topic !== action?.payload),
            };
        default:
            return state
    }
}