/**
 * 
 * @name = Public Routes
 * @description = Public routes are Log in, SignUp, Forgot Password, 
 * Reset Password. In simple words, These routes can be accessed 
 * before login into the App.
 * 
 * @author = Naveen Kumar
 * @since = 07 Oct, 2021
 * 
 */

import { Route, Redirect } from 'react-router-dom';
// import NotFoundPage from '../pages/NotFoundPage';
  
const PublicRoute = ({ children, isAuthenticated, ...rest }) => {
    return (
     <>
       <Route
        {...rest}
        render={
            ({ location }) => (!isAuthenticated ? (children) : (<Redirect to={{pathname: '/', state: { from: location }}}/>))
        }
      />
          {/* <Route path="/404" component={NotFoundPage} />
          <Redirect to="/404" /> */}
     </>
    );
  }
  
  export default PublicRoute;