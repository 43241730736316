export const canvasMessages = {
    "canvasAddAsset-post-200": "Layout successfully added to the space.",
    "canvasAddAsset-post-401": "Failed to add the layout to space, please try again later.",
    "canvasAddAsset-post-405": "Failed to add the layout to space, please try again later.",
    "canvasUpdateAsset-put-200": "Layout successfully updated to the space.",
    "canvasUpdateAsset-put-405": "Layout udpation is failed to the space, please try again later.",
    "canvasUpdateConfig-put-200": "Configuration successfully updated to the space.",
    "canvasUpdateConfig-put-405": "Configuration udpation is failed to the space, please try again later.",
    "canvasUpdateEntities-put-200": "Entities successfully updated to the space.",
    "canvasUpdateEntities-put-405": "Entities udpation is failed to the space, please try again later.",
    "canvas-get-200": "Layout successfully retrieved.",
    "canvasGetAllAsset-get-503": 'Unable to retrieve the Layout, please try again later.',
}