import { styled } from '@mui/material/styles';
import { Paper } from "@mui/material";

export const style = {
    bgcolor: 'background.paper',
    border: '2px solid #ededef',
    position: 'absolute',
    transform: "translate(-50%, -50%)",
    left: "50%",
    top: '50%',
    margin: '0 auto',
    height: '100%',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

export const defaultProperities = {
    "id": 1060,
    "uniq_id": "",
    "type": "image",
    "src": "/devices/Dark-Bulb.svg",
    "img_off": "/devices/Dark-Bulb.svg",
    "img_on": "/devices/Light-Bulb.svg",
    "state": 0,
    "stateToggle": 0,
    "group": "mnp",
    "width": 70,
    "height": 70,
    "event": "click"
}

export const comjson = {
    componentName: "Space Details",
    isMore: true,
    justify: "space-between",
    widget: [
        {
            label: "Temperature",
            counter: 0,
            bgColors: ["#FBF6DF", "rgba(251, 246, 223, 0.25)"]
        },
        {
            label: "Humidity",
            counter: 0,
            bgColors: ["#E5F2FA", "rgba(229, 242, 250, 0.25)"]

        },
        {
            label: "Ambient Light",
            counter: 0,
            bgColors: ["#EFE8F8", "rgba(239, 232, 248, 0.25)"]
        },
        {
            label: "CO2",
            counter: 0,
            bgColors: ["#FBF6DF", "rgba(251, 246, 223, 0.25)"]
        },
        {
            label: "PM 2.5",
            counter: 0,
            bgColors: ["#C5F2C5", "rgb(255, 255, 255) 90%"]
        },
    ],

}

export const airQualityParameters = [
    { id: "Temperature", label: "Temperature", unit: "°C", threshold: 25, visible: true, prop: "setmp" },
    { id: "Humidity", label: 'Humidity', unit: "%", threshold: 50, visible: true, prop: "sehu" },
    { id: "Carbon Dioxide", label: "Carbon Dioxide", unit: "mg/m3", threshold: 1800, visible: true, prop: 'seco2' },
    { id: "PM 2.5", label: "PM 2.5", unit: "ug/m3", threshold: 5, visible: true, prop: 'sepm25' },
    { id: "ALS", label: "ALS", unit: "lum", threshold: 25, visible: false, prop: 'als' }
]


export const defaultTicketJson = {
    componentName: "Tickets",
    isMore: true,
    widget: [
        {
            label: "Total",
            counter: 0,
            bgColors: ["#FF8B8B", "rgba(255, 139, 139, 0.25)"],
            counterPosition: "top",
            counterSx: { color: "#333333 !important" }
        },
        {
            label: "My Tickets",
            counter: 0,
            bgColors: ["#EFE8F8", "rgba(239, 232, 248, 0.25)"],
            counterPosition: "top",
            counterSx: { color: "#333333 !important" }
        },
        {
            label: "Open",
            counter: 0,
            bgColors: ["#FBF6DF", "rgba(251, 246, 223, 0.25)"],
            counterPosition: "top",
            counterSx: { color: "#333333 !important" }
        },
        {
            label: "Closed",
            counter: 0,
            bgColors: ["#E5F2FA", "rgba(229, 242, 250, 0.25)"],
            counterPosition: "top",
            counterSx: { color: "#333333 !important" }
        },
    ],
    tableData: {
        headerList: {
            TicketId: {
                label: 'Ticket Id',
                type: "link",
                url: "/ticketing?op=details",
                urlParams: { ticketId: "TicketId" },
            },
            synopsis: { label: 'Title' },
            state: { label: "State" },
            assignedTo: { label: 'Assigned To', type: "avatar" },
            severity: { label: 'Severity' },
            createdAt: { label: 'Created On' },
        },
        data: null,
    }
}

export const defaultSpaceJson = {
    componentName: "Space",
    isMore: true,
    justify: "space-between",
    widget: [
        {
            label: "Total",
            counter: 0,
            bgColors: ["#FBF6DF", "rgba(251, 246, 223, 0.25)"]
        }
    ],
}

export const defaultDeviceJson = {
    componentName: "Device Details",
    isMore: true,
    widget: [
        {
            label: "Total",
            counter: 0,
            bgColors: ["#FBF6DF", " rgba(251, 246, 223, 0.25)"]
        },
        {
            label: "Offline",
            counter: 0,
            bgColors: ["#EFE8F8", "rgba(239, 232, 248, 0.25)"]
        },
        {
            label: "Faulted",
            counter: 0,
            bgColors: ["#FF8B8B", "rgba(255, 139, 139, 0.25)"]
        },
        {
            label: "Active",
            counter: 0,
            bgColors: ["#E5F2FA", "rgba(229, 242, 250, 0.25)"]
        },
    ],
}

export const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    // border: "4px solid black"
    borderRadius: "20px"
}));

export const defaultChartData = {
    componentName: "Energy Consumption", isMore: true,
    today: 0,
    thisWeek: 0,
    thisMonth: 0,
    dataPoints: {}
};


