import { failure, formatedDate, getMessage, request, success } from "../../helpers";
import { delay, rotateKeyService } from "../../services";
import { rotateKeyTypes } from "../types";
import { appActions } from "./app.actions";

const generateBleKey = () => {
    return (dispatch) => {
        dispatch(request(rotateKeyTypes?.GENERATE_KEY_REQUEST));
        rotateKeyService?.generateBleKey()
            .then(
                ({ data, status }) => {
                    dispatch(success(rotateKeyTypes?.GENERATE_KEY_SUCCESS, { payload: data }));
                },
                ({ error, code }) => {
                    dispatch(failure(rotateKeyTypes?.GENERATE_KEY_FAILURE, error?.toString()));
                    getMessage(`generateBleKey-post-${code}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    })
                }
            );
    };
}

const rotateBleKey = (params) => {
    return (dispatch) => {
        dispatch(request(rotateKeyTypes?.ROTATE_KEY_REQUEST));
        rotateKeyService?.rotateBleKey(params)
            .then(
                ({ data, status }) => {
                    dispatch(success(rotateKeyTypes?.ROTATE_KEY_SUCCESS, { payload: data }));
                },
                ({ error, code }) => {
                    dispatch(failure(rotateKeyTypes?.ROTATE_KEY_FAILURE, error?.toString()));
                    getMessage(`rotateBleKey-post-${code}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    })
                }
            );
    };
}

const getBleKeys = () => {
    return (dispatch) => {
        dispatch(request(rotateKeyTypes?.GET_BLE_KEYS_REQUEST));
        rotateKeyService?.getBleKeys()
            .then(
                ({ data, status }) => {
                    const keys = data?.bleKeyStatus?.map(d => {
                        d["createdOnDate"] = d?.hasOwnProperty("createdOn") ? formatedDate({ date: d?.createdOn, format: "MMM dd, yyyy 'AT' hh:mm a" }) : "-"
                        return d;
                    });
                    data.bleKeyStatus = [
                        {
                            "bleKeyVersion": -1,
                            "latest": false,
                            "count": data?.devicesUsingMasterKey,
                            "createdOnDate": "-",
                            "type": "Master Key"
                        },
                        ...keys
                    ];
                    dispatch(success(rotateKeyTypes?.GET_BLE_KEYS_SUCCESS, { payload: data }));
                },
                ({ error, code }) => {
                    dispatch(failure(rotateKeyTypes?.GET_BLE_KEYS_FAILURE, error?.toString()));
                    getMessage(`bleKeys-get-${code}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    })
                }
            );
    };
}

export const rotateKeyActions = {
    generateBleKey,
    rotateBleKey,
    getBleKeys
}