import { makeStyles } from '@mui/styles';


export const PropertyCss = {
 root:{
    '& .MuiOutlinedInput-root':{width:'150px', paddingLeft:'6px'}
 },
 input:{
    '& .MuiOutlinedInput-input':{paddingRight:0}
 }
};

export const useStyles = makeStyles({
  
});

