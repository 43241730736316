export const ticketMessages = {
    "ticket-createTicket-titleMandatory": "Ticket Title is Required.",
    "ticket-createTicket-titleMinLen": " Ticket Title should have minimum 2 characters.",
    "ticket-createTicket-titleMaxLen": "Ticket Title should not exceed more than 30 characters.",
    "ticket-createTicket-descMandatory": "Ticket Description is Required.",
    "ticket-createTicket-descMinLen": "Ticket Description should have minimum 2 characters.",
    "ticket-createTicket-descMaxLen": "Ticket Description should not exceed more than 280 characters.",
    "ticket-createTicket-typeMandatory": 'Ticket Type is Required.',
    "ticket-createTicket-entityMandatory": "Entity Type is Required.",
    "ticket-createTicket-severityMandatory": "Severity is Required.",
    "ticket-createTicket-stateMandatory": "State is Required.",
    "ticket-createTicket-attachmentCheck": 'Please Upload File with Size Less Than 50KB.',
    "ticket-createTicket-titleAllowedCharacters": "Ticket Title should only contain ['a-z', 'A-Z'] characters and ['-', ' '] symbol",
    "ticket-comment-maxLength": "Ticket Description should not exceed more than 280 characters.",
    "ticket-comment-minLength": "Ticket Comment should have minimum 2 characters.",
    "ticket-share-noAssignee": "There are no assignees for the ticket."
}


export const ticketApiMessages = {
    "ticket-post-200": "Ticket successfully created.",
    "ticket-post-400": "Unable to create the ticket, please verify the inputs and try again.",
    "ticket-post-401": "Unable to create the ticket, please verify your access and try again.",
    "ticket-post-403": "Unable to create the ticket, please verify your access and try again.",
    "ticket-post-404": "Unable to create the ticket, please try again.",
    "ticket-post-500": "Unable to create the ticket due to internal error, please try again.",

    "ticket-delete-200": "Ticket successfully deleted.",
    "ticket-delete-400": "Unable to delete the ticket, please refresh the page and try again",
    "ticket-delete-401": "Unable to delete the ticket, please verify your access and try again",
    "ticket-delete-403": "Unable to delete the ticket, please verify your access and try again",
    "ticket-delete-404": "Unable to delete the ticket, please try again.",
    "ticket-delete-500": "Unable to delete the ticket due to internal error, please try again later.",

    "ticketSearch-get-200": "Ticket(s) successfully retrieved.",
    "ticketSearch-get-401": "Unable to retrieve the ticket(s), please verify your access and try again.",
    "ticketSearch-get-403": "Unable to retrieve the ticket(s), please verify your access and try again.",
    "ticketSearch-get-404": "Unable to retrieve the ticket(s), please try again later.",
    "ticketSearch-get-500": "Unable to retrieve the ticket(s) due to internal error, please try again later.",
    "ticketSearch-get-502": "Unable to retrieve the ticket(s) due to internal error, please try again later.",
    "ticketSearch-get-503": "Unable to retrieve the ticket(s) due to internal error, please try again later.",

    "ticketDetail-get-200": "Ticket details retrieveed Successfully",
    "ticketDetail-get-401": "Unable to retrieve the Ticket Details, please verify your access and try again.",
    "ticketDetail-get-403": "Unable to retrieve the Ticket Details, please verify your access and try again.",
    "ticketDetail-get-404": "Unable to retrieve the ticket Details, please try again later.",
    "ticketDetail-get-500": "Unable to retrieve the ticket Details due to internal error, please try again later.",

    "ticketUpdate-post-200": "Ticket successfully updated.",
    "ticketUpdate-post-400": "Unable to update the ticket, please verify inputs and try again.",
    "ticketUpdate-post-401": "Unable to update the ticket, please verify your access and try again.",
    "ticketUpdate-post-403": "Unable to update the ticket, please verify your access and try again.",
    "ticketUpdate-post-404": "Unable to update the ticket, please try again later.",
    "ticketUpdate-post-500": "Unable to update the ticket due to internal error, please try again later.",

    "ticketComment-post-200": "Comment successfully posted.",
    "ticketComment-post-400": "Unable to post the comment, please verify inputs and try again.",
    "ticketComment-post-401": "Unable to post the comment, please verify your access and try again.",
    "ticketComment-post-403": "Unable to post the comment, please verify your access and try again.",
    "ticketComment-post-404": "Unable to post the comment, please try again later.",
    "ticketComment-post-500": "Unable to post the comment due to internal error, please try again later.",

    "ticketCommentUpdate-put-200": "Comment successfully updated.",
    "ticketCommentUpdate-put-400": "Unable to update the comment, please verify inputs and try again.",
    "ticketCommentUpdate-put-401": "Unable to update the comment, please verify your access and try again.",
    "ticketCommentUpdate-put-403": "Unable to update the comment, please verify your access and try again.",
    "ticketCommentUpdate-put-404": "Unable to update the comment, please try again later.",
    "ticketCommentUpdate-put-500": "Unable to update the comment due to internal error, please try again later.",

    "ticketComment-delete-200": "Comment successfully deleted.",
    "ticketComment-delete-400": "Unable to delete the comment, please verify inputs and try again.",
    "ticketComment-delete-401": "Unable to delete the comment, please verify your access and try again.",
    "ticketComment-delete-403": "Unable to delete the comment, please verify your access and try again.",
    "ticketComment-delete-404": "Unable to delete the comment, please try again later",
    "ticketComment-delete-500": "Unable to delete the comment due to internal error, please try again later.",

    "ticketSubscribe-post-200": "Ticket successful subscribed.",
    "ticketSubscribe-post-400": "Unable to subscribe the ticket, please verify inputs and try again.",
    "ticketSubscribe-post-401": "Unable to subscribe the ticket, please verify your access and try again.",
    "ticketSubscribe-post-403": "Unable to subscribe the ticket, please verify your access and try again.",
    "ticketSubscribe-post-404": "Unable to subscribe the ticket, please try again later.",
    "ticketSubscribe-post-500": "Unable to subscribe the ticket due to internal error, please try again later.",

    "Unsubscribe-patch-200": "Ticket successfully unsubscribed.",
    "Unsubscribe-patch-400": "Unable to unsubscribe the ticket, please verify inputs and try again.",
    "Unsubscribe-patch-401": "Unable to unsubscribe the ticket, please verify your access and try again.",
    "Unsubscribe-patch-403": "Unable to unsubscribe the ticket, please verify your access and try again.",
    "Unsubscribe-patch-404": "Unable to unsubscribe the ticket, please verify inputs and try again.",
    "Unsubscribe-patch-500": "Unable to unsubscribe the ticket due to internal error, please try again later.",

    "ticketStatistics-get-200": "Ticket statistics successfully retrieved.",
    "ticketStatistics-get-401": "Unable to retrieve the Ticket statistics, please verify your access and try again",
    "ticketStatistics-get-403": "Unable to retrieve the Ticket statistics, please verify your access and try again",
    "ticketStatistics-get-500": "Unable to retrieve the Ticket statistics due to internal error, please verify your access and try again",

    "ticketsEntity-get-200": "Ticket entities successfully retrieved.",
    "ticketsEntity-get-401": "Unable to retrieve the ticket entities, please verify your access and try again.",
    "ticketsEntity-get-403": "Unable to retrieve the ticket entities, please verify your access and try again",
    "ticketsEntity-get-500": "Unable to retrieve the ticket entities due to internal error, please try again later.",

    "ticketStates-get-200": "Ticket states successfully retrieved.",
    "ticketsStates-get-401": "Unable to retrieve the ticket states, please verify your access and try again.",
    "ticketsStates-get-403": "Unable to retrieve the ticket states, please verify your access and try again.",
    "ticketsStates-get-500": "Unable to retrieve the ticket states due to internal error, please try again later.",

    "ticketSeverities-get-200": "Ticket severities successfully retrieved.",
    "ticketsSeverities-get-401": "Unable to retrieve the ticket severities, please verify your access and try again.",
    "ticketsSeverities-get-403": "Unable to retrieve the ticket severities, please verify your access and try again.",
    "ticketsSeverities-get-500": "Unable to retrieve the ticket severities due to internal error, please try again later.",

    "ticketAttachment-put-200": "Ticket attachment successfully uploaded.",
    "ticketAttachment-put-401": "Unable to upload attachment, please verify your access and try again.",
    "ticketAttachment-put-403": "Unable to upload attachment, please verify your access and try again.",
    "ticketAttachment-put-404": "Unable to upload attachment, please refresh the page and try again.",

    "ticketAttachment-delete-200": "Attachment successfully deleted",
    "ticketAttachment-delete-401": "Unable to delete attachment, please verify your access and try again.",
    "ticketAttachment-delete-403": "Unable to delete attachment, please verify your access and try again.",
    "ticketAttachment-delete-404": "Unable to delete attachment, please refresh the page and try again.",
    "ticketAttachment-delete-500": "Unable to delete attachment due to internal error, please try again later.",

    "ticketAttachment-get-200": "Attachment successfully retrieved.",
    "ticketAttachment-get-401": "Unable to retrieved attachment, please verify your access and try again",
    "ticketAttachment-get-403": "Unable to retrieved attachment, please verify your access and try again",
    "ticketAttachment-get-404": "Unable to retrieved attachment, please refresh the page and try again.",
    "ticketAttachment-get-500": "Unable to retrieved attachment due to internal error, please try again later.",

    "ticketsAssignee-get-200": "Assignees successfully retrieved.",
    "ticketsAssignee-get-401": "Unable to retrieve assignees, please verify your access and try again.",
    "ticketsAssignee-get-403": "Unable to retrieve assignees, please verify your access and try again.",
    "ticketsAssignee-get-500": "Unable to retrieve assignees due to internal error, please try again later.",

    "commentAttachment-put-200": "Attachment successfully uploaded.",
    "commentAttachment-put-401": "Unable to upload the attachment, please verify your access and try again.",
    "commentAttachment-put-403": "Unable to upload the attachment, please verify your access and try again.",
    "commentAttachment-put-404": "Unable to upload the attachment, please refresh the page and try again.",
    "commentAttachment-put-500": "Unable to upload the attachment due to internal error, please try again later.",

    "commentAttachment-delete-200": "Attachment successfully deleted.",
    "commentAttachment-delete-401": "Unable to delete the attachment, please verify your access and try again.",
    "commentAttachment-delete-403": "Unable to delete the attachment, please verify your access and try again.",
    "commentAttachment-delete-404": "Unable to delete the attachment, please refresh the page and try again.",

    "commentAttachment-get-200": "Attachment successfully retrieved.",
    "commentAttachment-get-401": "Unable to retrieve the attachment, please verify your access and try again.",
    "commentAttachment-get-403": "Unable to retrieve the attachment, please verify your access and try again.",
    "commentAttachment-get-404": "Unable to retrieve the attachment, please refresh the page and try again.",
    "commentAttachment-get-500": "Unable to retrieve the attachment due to internal error, please try again later.",
    "powerConsumption-get-400": "Unable to fetch power consumption",
    "powerConsumption-get-401": "Unable to fetch power consumption",
    "powerConsumption-get-403": "Unable to fetch power consumption, please verify your access and try again.",
    "powerConsumption-get-500": "Unable to fetch power consumption due to internal error, please try again later.",
}