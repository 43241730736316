import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
    ControllerSlider: {
        display: "flex",
        "& .slider-value": {
            margin: "0 16px 0 0"
        },
        "& .controls": {
            display: "flex",
            width: "100%",
            height: "100%",
            alignItems: "center",

        },
        "& .labels": {
            display: "flex",
            width: "100%",
            height: "100%",
            maxWidth: "max-content",
            minWidth: "max-content",
            alignItems: "center",

        },
    },
    Slider: {
        marginRight: "10px",
        "& .MuiSlider-rail": {
            backgroundColor: 'grey',
        },
        "&.MuiSlider-sizeMedium": {
            height: '3px',
            padding: '8px 0px'
        },
        "& .css-nz481w-MuiInputBase-input-MuiInput-input": {
            padding: '7px 4px 4px'
        },
        "& .MuiInput-root": {
            position: 'relative',
            top: '4px'
        },
        "& .MuiSlider-thumb": {
            height: '12px',
            width: '12px'
        },
        "& .MuiSlider-track": {
            height: '1px'
        },
    },
    wrapMargin: {
        [theme?.breakpoints?.down('lg')]: {
            marginTop: "8px"
        }
    },
    "wrapSlider": {
        [theme?.breakpoints?.down('lg')]: {
            flexWrap: "wrap"
        }
    },
}));
