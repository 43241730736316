/***
 *
 * Controller class for user.
 * @file RuleActions.js
 * @description RuleActions component
 * @author Ayesha Kumar
 * @since 20 Sep 2023
 * 
 */

import React from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './RuleActions.style.js';
import './RuleActions.scss';
import { Box, Menu, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import StyledButton from '../StyledButton/index.js';
import CustomTypography from '../CustomTypography/index.js';
import { useState } from 'react';
import _ from 'lodash';
import CustomAccordion from '../CustomAccordion/index.js';
import ImageWrapper from '../ImageWrapper/index.js';
import { TableHelper } from '../TableHelper/index.js';
import StyledPopupWrapper from "../StyledPopupWrapper";
import RuleActionDragDropWrapper from './RuleActionDragDropWrapper/index.js';
import { useEffect } from 'react';
import { deviceActions } from '../../redux/actions/device.actions.js';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Light from '../../assets/icons/bulb.svg';
import Group from '../../assets/icons/group.svg';
import Scene from '../../assets/icons/scene.svg';
import Delete from '../../assets/icons/delete.svg';
import Edit from '../../assets/icons/edit-2.svg';
import StyledTextfield from '../StyledTextfield/index.js';
import { formatString } from '../../services/util.service.js';
import DeviceTypes from '../DeviceTypes/index.js';
import StyledPopup from '../StyledPopup/index.js';
import { confirmationDialog } from '../../helpers/device.helper.js';

import { getMessageStr } from '../../helpers/message.helper.js';
import { useQuery } from '../../hooks/useQuery.js';


const actionMenu = {
  "device": { label: "Device Action", show: true },
  "group": { label: "Group Action", show: true },
  "scene": { label: "Scene Action", show: true }
}

const iconObj = { device: Light, group: Group, scene: Scene }

const RuleActions = ({ trigger = { type: "DEVICE_STATUS", property: "pir" }, actionsData, setActionsData, ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const query = useQuery();
  const queryParam = query.get("op") || null;
  const dropList = useSelector(state => state?.dragAndDrops?.dropList?.map(d => {
    d.name = d?.name?.split(" ")[0];
    return d
  }));
  const deviceModels = useSelector((state) => state.devices.deviceModels);
  const controller = useSelector((state) => state.devices.deviceDetails);
  const deviceCategories = useSelector(state => state?.devices?.deviceCategories);

  const [selectedActions, setSelectedActions] = useState({ device: { title: "Device Actions", entities: [] }, group: { title: "Group Actions", entities: [] }, scene: { title: "Scene Actions", entities: [] } });
  const [actionAnchor, setActionAnchor] = useState(null);
  const [dialogBox, setDialogBox] = useState(false);
  const [dialogBoxContents, setDialogBoxContents] = useState(null);
  const [menuOptions, setMenuOptions] = useState({ ...actionMenu });
  const [warningDialog, setWarningDialog] = useState(false);
  const [warningDialogContent, setWarningDialogContent] = useState({});


  const handlePopoverClose = () => {
    // setPopoverTitle("");
    setActionAnchor(null);
    // setOpenPopover(false);
    // dispatch(dragAndDropActions.emptyLists());
  }

  const handleTriggerSelection = (event, type) => {
    // const option = event?.target?.innerHTML;
    // setPopoverTitle(option);
    setDialogBox(true);
    switch (type) {
      case "device":
        setDialogBoxContents({ title: "Add Devices", type })
        break;
      case "group":
        setDialogBoxContents({ title: "Add Groups", type })
        break;
      case "scene":
        setDialogBoxContents({ title: "Add Scenes", type })
        break;
    }
    setActionAnchor(null);
    // setOpenPopover(true);
  }

  const handleSaveAction = (e) => {
    const getActionValue = (d, payload = false) => {
      if (trigger?.type !== "DEVICE_STATUS") {
        const obj = {};
        if (payload) {
          obj.payload = {
            lst: d?.hasOwnProperty("lst") ? d?.lst : d?.hasOwnProperty("deviceId") ? d?.deviceStatus?.status?.intensity || 0 : d?.groupStatus?.status?.intensity || 0
          }
        }
        else {
          obj.lst = d?.hasOwnProperty("lst") ? d?.lst : d?.hasOwnProperty("deviceId") ? d?.deviceStatus?.status?.intensity || 0 : d?.groupStatus?.status?.intensity || 0
        }
        return dialogBoxContents?.type !== "scene" ? obj : {};
      }
      else if (trigger?.property === "pir") {
        return {}
      }
      else {
        const obj = {};
        if (payload) {
          obj.payload = {
            gain: d?.hasOwnProperty("gain") ? d?.gain : 50,
            lux: d?.hasOwnProperty("lux") ? d?.lux : 250
          }
        }
        else {
          obj.gain = d?.hasOwnProperty("gain") ? d?.gain : 50;
          obj.lux = d?.hasOwnProperty("lux") ? d?.lux : 250
        }
        return obj
      }
    };
    const options = JSON.parse(JSON.stringify(menuOptions));
    options[dialogBoxContents?.type].show = false;
    setMenuOptions(options);
    setDialogBox(false);
    setDialogBoxContents(null);
    const action = JSON?.parse(JSON?.stringify(selectedActions[dialogBoxContents?.type]));
    // const actionIds = action?.entities?.map(a => a[dialogBoxContents?.type + "Id"]);
    // const newEntities = dropList?.filter(d => !actionIds?.includes(d?.id))
    // const entArr = [...action?.entities, ...newEntities]
    const arr = dropList?.map(d => {
      return ({
        ...d,
        ...getActionValue(d)
      })
    })
    setSelectedActions({ ...selectedActions, [dialogBoxContents?.type]: { ...action, entities: [...arr] } });
    setActionsData([...actionsData?.filter(a => a?.type !== (dialogBoxContents?.type?.toUpperCase() + "_ACTION")),
    ...dropList?.map(
      d => {
        return (
          {
            ...d,
            type: dialogBoxContents?.type.toUpperCase() + "_ACTION",
            // [dialogBoxContents?.type + "Id"]: d?.id,
            ...getActionValue(d, true)
          }
        )
      }
    )
    ])
  }
  const handleTempChange = (event, val, entity, entityData) => {
    const entityArr = selectedActions[entity]?.entities?.map(ent => {
      if (ent?.[entity + "Id"] === entityData[entity + "Id"]) {
        ent.klv = val
      }
      return ent;
    })
    setSelectedActions({ ...selectedActions, [entity]: { ...selectedActions[entity], entities: entityArr } })
    const actArr = actionsData?.map(a => {
      if (a[entity + "Id"] === entityData[entity + "Id"]) {
        a.payload.klv = val
        a.klv = val;
      }
      return a;
    })
    setActionsData(actArr);
  }

  const getTempSliderParams = (entity) => {
    const modelKlv = deviceModels.find((d) => (d.modelNumber?.substring(d.modelNumber?.length - 3, d.modelNumber?.length) === entity?.configuration?.daliDriver))?.controlCapabilities?.find(
      (c) => c?.capability?.trim()?.toLowerCase() === "kelvin"
    );
    if (modelKlv) {
      const klvMax = modelKlv?.attributes[0]?.range?.max[0]?.value;
      const klvMin = modelKlv?.attributes[0]?.range?.min[0]?.value;
      return ({ min: klvMin, max: klvMax, suffix: "K" });
    }
    else {
      return false;
    }
  }

  const handleIntensityChange = (event, val, entity, entityData) => {
    const entityArr = selectedActions[entity]?.entities?.map(ent => {
      if (ent?.[entity + "Id"] === entityData[entity + "Id"]) {
        ent.lst = val
      }
      return ent;
    })
    setSelectedActions({ ...selectedActions, [entity]: { ...selectedActions[entity], entities: entityArr } })
    const actArr = actionsData?.map(a => {
      if (a[entity + "Id"] === entityData[entity + "Id"]) {
        a.payload.lst = val
        a.lst = val;
      }
      return a;
    })
    setActionsData(actArr);
  }
  const getSliderParams = (entity) => {
    if (entity?.hasOwnProperty("deviceId")) {
      const modelLst = deviceModels.find((d) => d.modelNumber?.substring(d.modelNumber?.length - 3, d.modelNumber?.length) === entity?.configuration?.daliDriver || d?.modelId === entity?.modelId)?.controlCapabilities?.find(
        (c) => c?.capability?.trim()?.toLowerCase() === "intensity" || c?.capability?.trim()?.toLowerCase() === "luminosity"
      );
      if (modelLst) {
        const lstMax = modelLst?.attributes[0]?.range?.max[0]?.value;
        const lstMin = modelLst?.attributes[0]?.range?.min[0]?.value;
        if (lstMax === 1) {
          return ({ min: lstMin, max: lstMax, params: { switchButton: true, value: false, compo: false, label: true } });
        }
        else {
          return ({ min: lstMin, max: lstMax, params: { value: true, compo: true, label: true } });
        }
      }
    }
    else {
      const sliderRequired = (_.isEmpty(entity?.groupType) || entity?.groupType?.includes("LW_ANALOG") || entity?.groupType?.find(g => g?.includes("LW_DT")))
      return ({ params: { switchButton: true, value: sliderRequired, compo: sliderRequired, label: true } })
    }
  }

  const handleLuxChange = (event, entity, entityData) => {
    let val = event?.target?.value;
    let error = false;
    let errorMessage = "";
    const entityArr = selectedActions[entity]?.entities?.map(ent => {
      if (ent[entity + "Id"] === entityData[entity + "Id"]) {
        if (event?.target?.value < 0 || event?.target?.value > 12000) {
          error = true;
          errorMessage = getMessageStr("rule-action-luxRange")
        }
        // else if (event?.target?.value > 12000) {
        //   error=true;
        //   errorMessage="lux more than 12000"
        // }
        else if (event?.target?.value === "") {
          error = true;
          errorMessage = getMessageStr("rule-action-luxRequired")
        }
        else {
          val = val;
          error = false;
          errorMessage = ""
        }
        ent.lux = val;
        ent.luxError = error;
        ent.luxErrorMessage = errorMessage;
      }
      return ent;
    })
    const actArr = actionsData?.map(a => {
      if (a[entity + "Id"] === entityData[entity + "Id"]) {
        a.payload.lux = val
        a.lux = val;
        a.luxError = error;
        a.luxErrorMessage = errorMessage;
      }
      return a;
    })
    setActionsData(actArr);
    setSelectedActions({ ...selectedActions, [entity]: { ...selectedActions[entity], entities: entityArr } })
  }

  const handleGainChange = (event, entity, entityData) => {
    let val = event?.target?.value;
    let errorMessage = "";
    let error = false;
    const entityArr = selectedActions[entity]?.entities?.map(ent => {
      if (ent[entity + "Id"] === entityData[entity + "Id"]) {
        if (event?.target?.value < 0 || event?.target?.value > 100) {
          error = true;
          errorMessage = getMessageStr("rule-action-gainRange");
        }
        // else if (event?.target?.value > 100) {
        // error=true;
        // errorMessage="gain more than 12000";
        // }
        else if (event?.target?.value === "") {
          error = true;
          errorMessage = getMessageStr("rule-action-gainRequired")
        }
        else {
          error = false;
          errorMessage = "";
        }
        ent.gain = val;
        ent.gainError = error;
        ent.gainErrorMessage = errorMessage;
      }
      return ent;
    })
    const actArr = actionsData?.map(a => {
      if (a[entity + "Id"] === entityData[entity + "Id"]) {
        a.payload.gain = val;
        a.gainError = error;
        a.gainErrorMessage = errorMessage;
        a.gain = val;
      }
      return a;
    })
    setActionsData(actArr);
    setSelectedActions({ ...selectedActions, [entity]: { ...selectedActions[entity], entities: entityArr } })
  }
  const deleteAction = (entity) => {
    const menu = JSON.parse(JSON.stringify(menuOptions));
    menu[entity].show = true;
    setMenuOptions(menu);
    const actArr = actionsData?.filter(a => a?.type !== (entity?.toUpperCase() + "_ACTION"))
    setActionsData(actArr);
    setSelectedActions({ ...selectedActions, [entity]: { ...selectedActions[entity], entities: [] } })
  }

  const deleteEntity = (entity, entityData) => {
    const entityArr = selectedActions[entity]?.entities?.filter(ent => ent[entity + "Id"] !== entityData[entity + "Id"])
    if (_.isEmpty(entityArr)) {
      const menuToBeAdded = actionMenu[entity]
      setMenuOptions({ ...menuOptions, [entity]: menuToBeAdded })
    }
    const actArr = actionsData?.filter(a => a[entity + "Id"] !== entityData[entity + "Id"])
    setActionsData(actArr);
    setSelectedActions({ ...selectedActions, [entity]: { ...selectedActions[entity], entities: entityArr } })
  }

  useEffect(() => {
    const actData = actionsData?.map(a => {
      if (a?.hasOwnProperty("payload")) {
        return { ...a, ...a?.payload }
      }
      return a;
    })
    if (!_.isEmpty(actionsData)) {
      const actionObj = JSON.parse(JSON.stringify(selectedActions))
      actData?.map(a => {
        switch (a?.type) {
          case "DEVICE_ACTION":
            actionObj?.device?.entities?.push(a);
            break;
          case "GROUP_ACTION":
            actionObj?.group?.entities?.push(a);
            break;
          case "SCENE_ACTION":
            actionObj?.scene?.entities?.push(a);
            break;
        }
      })
      setSelectedActions(actionObj);
      const options = JSON.parse(JSON.stringify(menuOptions));
      Object.keys(actionObj)?.map(k => {
        if (!_.isEmpty(actionObj[k]?.entities)) {
          options[k].show = false;
        }
      })
      setMenuOptions(options)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    if (trigger?.type === "DEVICE_STATUS" && queryParam !== "view") {
      dispatch(deviceActions?.getDevice(trigger?.controllerId, "controller"))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger?.controllerId])

  return (
    <Box className={classes?.RuleActions + " RuleActions"} data-testid="RuleActions">
      {queryParam !== "view" && <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <CustomTypography content={"Actions"}
            fontWeight={600} />
        </Box>
        <Box>
          <StyledButton
            id="demo-customized-button"
            className={"menuButton"}
            aria-controls={actionAnchor ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={actionAnchor ? 'true' : undefined}
            variant="contained"
            disabled={(Object.keys(menuOptions)?.filter(k => menuOptions[k].show === true)).length === 0}
            disableElevation
            onClick={(e) => { setActionAnchor(e?.currentTarget) }}
            endIcon={<KeyboardArrowDownIcon />}
          >
            Add Action Type
          </StyledButton>
        </Box>
      </Box>}

      {(_.isEmpty(selectedActions?.device?.entities) && _.isEmpty(selectedActions?.scene?.entities) && _.isEmpty(selectedActions?.group?.entities)) && queryParam !== "view" ?
        <CustomTypography content={"Add an action to continue."} fontWeight={600} size={14} styles={{ opacity: "50%" }} />
        :
        Object?.keys(selectedActions)?.map(k => {
          return !_.isEmpty(selectedActions[k]?.entities) && <CustomAccordion title={selectedActions[k]?.title}
            component={queryParam !== "view" && <Box sx={{ display: "flex" }}>
              <StyledButton iconButton={true}
                tooltip='Edit Actions'
                onClick={(e) => { handleTriggerSelection(e, k) }}>
                <ImageWrapper src={Edit} height={16} width={16} />
              </StyledButton>
              <StyledButton

                iconButton={true}
                tooltip='Delete All Actions'
                onClick={(e) => {
                  confirmationDialog("Delete All Actions", () => { deleteAction(k); setWarningDialog(false); }, "deleteAllActions", setWarningDialog, setWarningDialogContent, k)
                }}>
                <ImageWrapper className="deleteButton" src={Delete} height={16} width={16} />
              </StyledButton>
            </Box>}
          >
            {selectedActions[k]?.entities?.map((e, i) => {
              return <Box key={i} className={i !== selectedActions[k]?.entities?.length - 1 && "borderBelow"} sx={{ width: "100%", display: "flex", justifyContent: "space-between", p: 1 }}>
                <Box sx={{ display: "flex", alignItems: "center" }} gap={0.5}>
                  <ImageWrapper className="entityIcon" src={e?.hasOwnProperty("icon") ? e?.icon : iconObj[k]} height={16} width={16} />
                  <CustomTypography
                    weight={400}
                    size={16}
                    color="#222"
                    content={(e?.name === "" || !e?.hasOwnProperty("name")) ? `[${e[k + "Id"]}]` : e?.name}
                  />
                  <CustomTypography content={`(${k === "device" ? deviceCategories?.find(c => c?.category === e?.category)?.label : k === "group" ? !_.isEmpty(e?.groupType) ? e?.groupType?.join(",") : "-" : !_.isEmpty(e?.sceneType) ? e?.sceneType?.join(",") : "-"})`} weight={400} size={14} lineHeight={20} sx={{ fontStyle: "italic" }} />
                  {k !== "device" &&
                    <Box sx={{ display: "flex" }} gap={0.5}>
                      {"|"}<DeviceTypes data={e} />
                    </Box>}
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {
                    trigger?.type === "DEVICE_STATUS" ?
                      (
                        trigger?.property === "als" &&
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <CustomTypography
                            size={14}
                            weight={500}
                            lineHeight={14}
                            content={"Gain"}
                          />
                          <StyledTextfield
                            error={e?.gainError}
                            errorPopup={true}
                            helperText={e?.gainErrorMessage}
                            placeholder="0-100"
                            disabled={queryParam === "view"}
                            value={e?.hasOwnProperty("gain") ? e?.gain : 50}
                            type="number"
                            sx={{
                              marginLeft: "8px",
                              errorPopup: {
                                zIndex: "1250"
                              },
                              // height: 25, 
                              '& .MuiOutlinedInput-root': {
                                minWidth: "6.5rem",
                              },
                            }}

                            inputProps={{ min: 0, max: 100 }}
                            onChange={(event, objkey = null, data = "") => { handleGainChange(event, k, e) }}
                          />
                          <CustomTypography
                            styles={{ marginLeft: "16px" }}
                            size={14}
                            weight={500}
                            lineHeight={14}
                            content={"Lux"}
                          />
                          <StyledTextfield
                            error={e?.luxError}
                            errorPopup={true}
                            helperText={e?.luxErrorMessage}
                            disabled={queryParam === "view"}
                            placeholder="0-12000"
                            value={e?.hasOwnProperty("lux") ? e?.lux : 250}
                            type="number"
                            sx={{
                              errorPopup: {
                                zIndex: "1250"
                              },
                              marginLeft: "8px",
                              // height: 25, 
                              '& .MuiOutlinedInput-root': {
                                minWidth: "6.5rem",
                              },
                            }}
                            inputProps={{ min: 0, max: 12000 }}
                            onChange={(event, objkey = null, data = "") => { handleLuxChange(event, k, e) }}
                          />
                        </Box>
                      )
                      :
                      <Box sx={{ display: "flex" }} gap={2}>
                        {/* {k !== "scene" && getTempSliderParams(e) && <TableHelper.TableSlider data={e} onChange={(event, val, data) => { handleTempChange(event, val, k, e) }} params={{ compo: true, value: true, label: true }} label={"Temperature"} customsx={SliderCss} customData={{ ...getTempSliderParams(e), value: e?.klv || 0 }}></TableHelper.TableSlider>} */}
                        {k !== "scene" && <TableHelper.TableSlider data={e} onChange={(event, val, data) => { handleIntensityChange(event, val, k, e) }} params={{ ...getSliderParams(e)?.params }} label={"Intensity"} customData={{ ...getSliderParams(e), value: e?.lst || 0, disabled: queryParam === "view" }}></TableHelper.TableSlider>}
                      </Box>
                  }
                  {queryParam !== "view" && <StyledButton iconButton={true}

                    tooltip={"Delete Action"}
                    onClick={(event) => {
                      confirmationDialog("Delete Action", () => { deleteEntity(k, e); setWarningDialog(false); }, "deleteAction", setWarningDialog, setWarningDialogContent, k + " action " + (e?.name === "" ? `(${e?.deviceId})` : e?.name))
                    }}>
                    <ImageWrapper className="deleteButton" src={Delete} height={16} width={16} />
                  </StyledButton>}
                </Box>
              </Box>
            })}
          </CustomAccordion>
        })
      }
      <Menu
        id="demo-customized-menu"
        anchorEl={actionAnchor}
        open={actionAnchor}
        onClose={handlePopoverClose}
      >
        {Object?.keys(menuOptions)?.map(a => {
          return menuOptions[a]?.show && <MenuItem sx={{ minWidth: "170px" }} onClick={(event) => handleTriggerSelection(event, a)} >
            <CustomTypography
              content={menuOptions[a]?.label}
              size={14}
            />
          </MenuItem>
        })}
      </Menu>
      <StyledPopupWrapper
        title={dialogBoxContents?.title}
        open={dialogBox}
        closeButton={false}
        onClose={() => { setDialogBox(false); setDialogBoxContents(null); }}
        fullWidth={true}
        maxWidth="md"
      >
        <Box>
          <RuleActionDragDropWrapper entityList={selectedActions[dialogBoxContents?.type]?.entities?.map(e => { return { ...e } })} controller={controller} trigger={trigger} entity={dialogBoxContents?.type} />
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
            <StyledButton variant="outlined" style={{ marginLeft: "8px" }} onClick={() => { setDialogBox(false); setDialogBoxContents(null) }}>Cancel</StyledButton>
            <StyledButton disabled={_.isEmpty(dropList)} style={{ marginLeft: "8px" }} onClick={handleSaveAction}>Save</StyledButton>
          </Box>
        </Box>
      </StyledPopupWrapper>
      <StyledPopup
        open={warningDialog}
        onClose={() => { setWarningDialog(false) }}
        state={warningDialogContent?.state}
        data={warningDialogContent?.content}
        // component={warningDialogContent?.component}
        customsx={{
          label: {
            color: "red",
            fontWeight: 700,
            fontSize: "0.9375rem",
            paddingBottom: "16px",
          },
          btns: { display: "flex", justifyContent: "center" },
        }}
      />
    </Box>
  )
};

RuleActions.propTypes = {};

RuleActions.defaultProps = {};

export default RuleActions;
