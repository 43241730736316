import { failure, request, success, getMessage } from "../../helpers";
import { delay, ticketingService } from "../../services"
import { ticketingType } from "../types";
import { appActions } from ".";

const getFilteredTickets = (payload) => {
    return dispatch => {
        dispatch(request(ticketingType.GET_REQUEST));
        ticketingService.getTickets(payload)
            .then(({ data }) => {
                data?.records?.reverse()
                dispatch(success(ticketingType.GET_TICKETS_SUCCESS, { payload: data }));
            },
                ({ error, code }) => {
                    dispatch(failure(ticketingType.GET_TICKETS_FAILURE, error.toString()));
                    // dispatch(appActions.apiErrorMessage(getMessage("ticketSearch-get-404")));
                    getMessage(`ticketSearch-get-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    };
}

const getTicketDetails = (ticketId) => {
    return dispatch => {
        dispatch(request(ticketingType.GET_TICKET_DETAILS));
        ticketingService.getTicketDetails(ticketId)
            .then(({ data }) => {
                dispatch(success(ticketingType.GET_TICKETS_DETAILS_SUCCESS, { payload: data }));
            },
                ({ error, code }) => {
                    dispatch(failure(ticketingType.GET_TICKETS_DETAILS_FAILURE, error.toString()));
                    // dispatch(appActions.apiErrorMessage(getMessage("ticketUpdate-post-400")));
                    getMessage(`ticketUpdate-post-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    };
}

const createTicket = (payload) => {
    return dispatch => {
        dispatch(request(ticketingType.CREATE_TICKET));
        ticketingService.createTicket(payload)
            .then(({ data, status }) => {
                dispatch(success(ticketingType.CREATE_TICKETS_SUCCESS, { payload: data }));
                // dispatch(appActions.apiSuccessMessage(getMessage("ticket-post-200")));
                getMessage(`ticket-post-${status}`).then(delay(500)).then((msg) => {
                    dispatch(appActions.apiSuccessMessage(msg));
                })

            },
                ({ error, code }) => {
                    dispatch(failure(ticketingType.CREATE_TICKETS_FAILURE, error.toString()));
                    // dispatch(appActions.apiErrorMessage(getMessage("ticket-post-400")));
                    getMessage(`ticket-post-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    };
}

const updateTicket = (ticketId, payload, cb = () => { }) => {
    return dispatch => {
        dispatch(request(ticketingType.UPDATE_TICKET));
        ticketingService.updateTicket(ticketId, payload)
            .then(({ data, status }) => {
                dispatch(success(ticketingType.UPDATE_TICKETS_SUCCESS, { payload: data }));
                // dispatch(appActions.apiSuccessMessage(getMessage("ticketUpdate-post-200")));
                getMessage(`ticketUpdate-post-${status}`).then(delay(500)).then((msg) => {
                    dispatch(appActions.apiSuccessMessage(msg));
                })
                cb();
            },
                ({ error, code }) => {
                    dispatch(failure(ticketingType.UPDATE_TICKETS_FAILURE, error.toString()));
                    // dispatch(appActions.apiErrorMessage(getMessage("ticketUpdate-post-400")));
                    getMessage(`ticketUpdate-post-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    };
}

const getAssignees = () => {
    return dispatch => {
        dispatch(request(ticketingType.GET_ASSIGNEES));
        ticketingService.getAssignees()
            .then(({ data }) => {
                dispatch(success(ticketingType.GET_TICKETS_ASSIGNEES_SUCCESS, { payload: data }));
            },
                ({ error, code }) => {
                    dispatch(failure(ticketingType.GET_TICKETS_ASSIGNEES_FAILURE, error.toString()));
                    // dispatch(appActions.apiErrorMessage(getMessage("ticketsAssignee-get-401")));
                    getMessage(`ticketsAssignee-get-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    };
}

const postComment = (ticketId, payload) => {
    return dispatch => {
        dispatch(request(ticketingType.POST_COMMENT));
        ticketingService.postComment(ticketId, payload)
            .then(({ data, status }) => {
                dispatch(success(ticketingType.POST_COMMENT_SUCCESS, { payload: data }));
                // dispatch(appActions.apiSuccessMessage(getMessage("ticketComment-post-200")));
                getMessage(`ticketComment-post-${status}`).then(delay(500)).then((msg) => {
                    dispatch(appActions.apiSuccessMessage(msg));
                })
            },
                ({ error, code }) => {
                    dispatch(failure(ticketingType.POST_COMMENT_FAILURE, error.toString()));
                    // dispatch(appActions.apiErrorMessage(getMessage("ticketComment-post-400")));
                    getMessage(`ticketComment-post-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    };
}

const subscribeTicket = (ticketId, payload) => {
    return dispatch => {
        ticketingService.subscribeTicket(ticketId, payload)
            .then(({ data, status }) => {
                dispatch(success(ticketingType.SUBSCRIBE_TICKET_SUCCESS, { payload: data }));
                // dispatch(appActions.apiSuccessMessage(getMessage("ticketSubscribe-post-200")));
                getMessage(`ticketSubscribe-post-${status}`).then(delay(500)).then((msg) => {
                    dispatch(appActions.apiSuccessMessage(msg));
                })
            },
                ({ error, code }) => {
                    dispatch(failure(ticketingType.SUBSCRIBE_TICKET_FAILURE, error.toString()));
                    // dispatch(appActions.apiErrorMessage(getMessage("ticketSubscribe-post-400")));
                    getMessage(`ticketSubscribe-post-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    };
}

const unsubscribeTicket = (ticketId, payload) => {
    return dispatch => {
        dispatch(request(ticketingType.UN_SUBSCRIBE_TICKET));
        ticketingService.unsubscribeTicket(ticketId, payload)
            .then(({ data, status }) => {
                dispatch(success(ticketingType.UN_SUBSCRIBE_TICKET_SUCCESS, { payload: data }));
                // dispatch(appActions.apiSuccessMessage(getMessage("Unsubscribe-patch-200")));
                getMessage(`Unsubscribe-patch-${status}`).then(delay(500)).then((msg) => {
                    dispatch(appActions.apiSuccessMessage(msg));
                })
            },
                ({ error, code }) => {
                    dispatch(failure(ticketingType.UN_SUBSCRIBE_TICKET_FAILURE, error.toString()));
                    // dispatch(appActions.apiErrorMessage(getMessage("Unsubscribe-patch-400")));
                    getMessage(`Unsubscribe-patch-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    };
}

const addAttachments = (ticketId, payload) => {
    return dispatch => {
        dispatch(request(ticketingType.ADD_ATTACHMENTS));
        ticketingService.addAttachments(ticketId, payload)
            .then(({ data, status }) => {
                dispatch(success(ticketingType.ADD_ATTACHMENTS_SUCCESS, { payload: data }));
                // dispatch(appActions.apiSuccessMessage(getMessage("ticketAttachment-put-200")));
                getMessage(`ticketAttachment-put-${status}`).then(delay(500)).then((msg) => {
                    dispatch(appActions.apiSuccessMessage(msg));
                })
            },
                ({ error, code }) => {
                    dispatch(failure(ticketingType.ADD_ATTACHMENTS_FAILURE, error.toString()));
                    // dispatch(appActions.apiErrorMessage(getMessage("ticketAttachment-put-404")));
                    getMessage(`ticketAttachment-put-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    };
}

const deleteAttachment = (ticketId, attachmentId) => {
    return dispatch => {
        dispatch(request(ticketingType.DELETE_ATTACHMENTS));
        ticketingService.deleteAttachment(ticketId, attachmentId)
            .then(({ attachment, status }) => {
                dispatch(success(ticketingType.DELETE_ATTACHMENTS_SUCCESS, { payload: attachment }));
                // dispatch(appActions.apiSuccessMessage(getMessage("ticketAttachment-delete-200")));
                getMessage(`ticketAttachment-delete-${status}`).then(delay(500)).then((msg) => {
                    dispatch(appActions.apiSuccessMessage(msg));
                })
            },
                ({ error, code }) => {
                    dispatch(failure(ticketingType.DELETE_ATTACHMENTS_FAILURE, error.toString()));
                    // dispatch(appActions.apiErrorMessage(getMessage("ticketAttachment-delete-404")));
                    getMessage(`ticketAttachment-delete-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    };
}

const deleteComment = (ticketId, commentId) => {
    return dispatch => {
        dispatch(request(ticketingType.DELETE_COMMENT));
        ticketingService.deleteComment(ticketId, commentId)
            .then(({ data, status }) => {
                dispatch(success(ticketingType.DELETE_COMMENT_SUCCESS, { payload: data }));
                // dispatch(appActions.apiSuccessMessage(getMessage("ticketComment-delete-200")));
                getMessage(`ticketComment-delete-${status}`).then(delay(500)).then((msg) => {
                    dispatch(appActions.apiSuccessMessage(msg));
                })
            },
                ({ error, code }) => {
                    dispatch(failure(ticketingType.DELETE_COMMENT_FAILURE, error.toString()));
                    // dispatch(appActions.apiErrorMessage(getMessage("ticketComment-delete-400")));
                    getMessage(`ticketComment-delete-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    };
}

const editComment = (ticketId, commentId, payload) => {
    return dispatch => {
        dispatch(request(ticketingType.EDIT_COMMENT));
        ticketingService.editComment(ticketId, commentId, payload)
            .then(({ data, status }) => {
                dispatch(success(ticketingType.EDIT_COMMENT_SUCCESS, { payload: data }));
                // dispatch(appActions.apiSuccessMessage(getMessage("ticketCommentUpdate-put-200")));
                getMessage(`ticketCommentUpdate-put-${status}`).then(delay(500)).then((msg) => {
                    dispatch(appActions.apiSuccessMessage(msg));
                })
            },
                ({ error, code }) => {
                    dispatch(failure(ticketingType.EDIT_COMMENT_FAILURE, error.toString()));
                    // dispatch(appActions.apiErrorMessage(getMessage("ticketCommentUpdate-put-400")));
                    getMessage(`ticketCommentUpdate-put-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    };
}

const getStatistics = () => {
    return dispatch => {
        dispatch(request(ticketingType.GET_STATISTICS));
        ticketingService.getStatistics()
            .then(({ data }) => {
                dispatch(success(ticketingType.GET_STATISTICS_SUCCESS, { payload: data }));
            },
                ({ error, code }) => {
                    dispatch(failure(ticketingType.GET_STATISTICS_FAILURE, error.toString()));
                    // dispatch(appActions.apiErrorMessage(getMessage("ticketStatistics-get-401")));
                    getMessage(`ticketStatistics-get-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    };
}

const addAttachmentsToComment = (ticketId, commentId, payload) => {
    return dispatch => {
        dispatch(request(ticketingType.ADD_ATTACHMENTS_TO_COMMENT));
        ticketingService.addAttachmentsToComment(ticketId, commentId, payload)
            .then(({ data }) => {
                dispatch(success(ticketingType.ADD_ATTACHMENTS_TO_COMMENT_SUCCESS, { payload: data }));
            },
                ({ error, code }) => {
                    dispatch(failure(ticketingType.ADD_ATTACHMENTS_TO_COMMENT_FAILURE, error.toString()));
                    // dispatch(appActions.apiErrorMessage(getMessage("commentAttachment-put-404")));
                    getMessage(`commentAttachment-put-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    };
}


const deleteCommentAttachment = (ticketId, commentId, payload) => {
    return dispatch => {
        dispatch(request(ticketingType.DELETE_ATTACHMENTS_TO_COMMENT));
        ticketingService.deleteCommentAttachment(ticketId, commentId, payload)
            .then(({ data, status }) => {
                dispatch(success(ticketingType.DELETE_ATTACHMENTS_TO_COMMENT_SUCCESS, { payload: data }));
                // dispatch(appActions.apiSuccessMessage(getMessage("commentAttachment-delete-200")));
                getMessage(`commentAttachment-delete-${status}`).then(delay(500)).then((msg) => {
                    dispatch(appActions.apiSuccessMessage(msg));
                })
            },
                ({ error, code }) => {
                    dispatch(failure(ticketingType.DELETE_ATTACHMENTS_TO_COMMENT_FAILURE, error.toString()));
                    // dispatch(appActions.apiErrorMessage(getMessage("commentAttachment-delete-404")));
                    getMessage(`commentAttachment-delete-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    };
}

const getCommentAttachment = (ticketId, commentId, payload) => {
    // return dispatch => {
    //     dispatch(request(ticketingType.GET_ATTACHMENTS_TO_COMMENT));
    //     ticketingService.getCommentAttachment(ticketId, commentId, payload)
    //         .then(({ data }) => {
    //             dispatch(success(ticketingType.GET_ATTACHMENTS_TO_COMMENT_SUCCESS, { payload: data }));
    //         },
    //             ({ error, code }) => {
    //                 dispatch(failure(ticketingType.GET_ATTACHMENTS_TO_COMMENT_FAILURE, error.toString()));
    //                 // dispatch(appActions.apiErrorMessage(getMessage("commentAttachment-get-404")));
    //                 getMessage(`commentAttachment-get-${code}`).then(delay(500)).then((err) => {
    //                     dispatch(appActions.apiErrorMessage(err));
    //                 })
    //             }
    //         );
    // };

    return ticketingService.getCommentAttachment(ticketId, commentId, payload);
}

const getAttachment = (ticketId, attachmentId) => {
    // return dispatch => {
    //     dispatch(request(ticketingType.GET_ATTACHMENTS));
    //     ticketingService.getAttachment(ticketId, attachmentId)
    //         .then(({ data }) => {
    //             dispatch(success(ticketingType.GET_ATTACHMENTS_SUCCESS, { payload: data }));
    //         },
    //             ({ error, code }) => {
    //                 dispatch(failure(ticketingType.GET_ATTACHMENTS_FAILURE, error.toString()));
    //                 // dispatch(appActions.apiErrorMessage(getMessage("ticketAttachment-get-404")));
    //                 getMessage(`ticketAttachment-get-${code}`).then(delay(500)).then((err) => {
    //                     dispatch(appActions.apiErrorMessage(err));
    //                 })
    //             }
    //         );
    // };

    return ticketingService.getAttachment(ticketId, attachmentId);
}

const getTicketTypes = () => {
    return dispatch => {
        dispatch(request(ticketingType.GET_TICKET_TYPES));
        ticketingService.getTicketTypes()
            .then(({ data }) => {
                dispatch(success(ticketingType.GET_TICKET_TYPES_SUCCESS, { payload: data }));
            },
                ({ error, code }) => {
                    dispatch(failure(ticketingType.GET_TICKET_TYPES_FAILURE, error.toString()));
                    // dispatch(appActions.apiErrorMessage(getMessage("ticketsEntity-get-401")));
                    getMessage(`ticketsEntity-get-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    };
}

const getTicketStates = () => {
    return dispatch => {
        dispatch(request(ticketingType.GET_TICKET_STATES));
        ticketingService.getTicketStates()
            .then(({ data }) => {
                dispatch(success(ticketingType.GET_TICKET_STATES_SUCCESS, { payload: data }));
            },
                ({ error, code }) => {
                    dispatch(failure(ticketingType.GET_TICKET_STATES_FAILURE, error.toString()));
                    // dispatch(appActions.apiErrorMessage(getMessage("ticketsStates-get-401")));
                    getMessage(`ticketsStates-get-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    };
}

const getTicketSeverities = () => {
    return dispatch => {
        dispatch(request(ticketingType.GET_TICKET_SEVERITIES));
        ticketingService.getTicketSeverities()
            .then(({ data }) => {
                dispatch(success(ticketingType.GET_TICKET_SEVERITIES_SUCCESS, { payload: data }));
            },
                ({ error, code }) => {
                    dispatch(failure(ticketingType.GET_TICKET_SEVERITIES_FAILURE, error.toString()));
                    // dispatch(appActions.apiErrorMessage(getMessage("ticketsSeverities-get-401")));
                    getMessage(`ticketsSeverities-get-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    };
}

export const emptyTicketingState = (type, payload = null) => {
    if (!type)
        return null;

    return dispatch => {
        dispatch(success(ticketingType[type], { payload: payload }))
    }
}


export const ticketingActions = {
    createTicket,
    updateTicket,
    getAssignees,
    getFilteredTickets,
    getTicketDetails,
    postComment,
    subscribeTicket,
    unsubscribeTicket,
    addAttachments,
    deleteAttachment,
    deleteComment,
    editComment,
    getStatistics,
    getAttachment,
    getCommentAttachment,
    addAttachmentsToComment,
    deleteCommentAttachment,
    getTicketTypes,
    emptyTicketingState,
    getTicketSeverities,
    getTicketStates
}
