/***
 *
 * Controller class for user.
 * @file DiscoverSensors.js
 * @description DiscoverSensors component
 * @author Ayesha Kumar
 * @since 14 Apr 2023
 * 
 */

import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './DiscoverSensors.style.js';
import './DiscoverSensors.scss';
import { Box, Checkbox } from '@mui/material';
import { useState } from 'react';
import CustomTypography from '../CustomTypography/index.js';
import CustomLabel from '../CustomLabel/index.js';
import StyledTextfield from '../StyledTextfield/index.js';
import CustomTable from '../CustomTable/index.js';
import SensorIdentifyIcon from '../../assets/icons/identify-2.svg'
import FactoryResetIcon from '../../assets/icons/factoryreset.svg'
import { factoryReset, identifySensor } from '../../helpers/device.helper.js';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { deviceActions } from '../../redux/actions/device.actions.js';
import { fetchSensorInterval } from '../../config/appConfig.js';
import { getMessageStr } from '../../helpers/message.helper.js';
import { TableHelper } from '../TableHelper/index.js';
import StyledButton from '../StyledButton/index.js';


const DiscoverSensors = ({ mqttClient,
  mqttTopics,
  deviceDetails = {},
  setDialog = () => { },//popupwrapper variable
  setDialogBox = () => { },//popup variable
  setDialogBoxContents = () => { },//popup variable
  ...props }) => {
  const selectedStatus = (shortId) => {
    return selectedSensors[shortId];
  }
  const tableHeaders = {
    checked: {
      label: "",
      type: "component",
      component: (row) => {
        return (
          <TableHelper.TableCheckbox
            disabled={row?.data?.discovering ? true : row?.data?.deviceId && row?.data?.controllerId !== deviceDetails?.deviceId}
            value={false}
            data={row?.data}
            onClick={(e) => {
              const shortId = row?.data?.shortId;
              // const sensors={...selectedSensors};
              // sensors[shortId] = !sensors[shortId];
              // setSelectedSensors(sensors);
              // console.log(selectedSensors,sensors,shortId);
              setSelectedSensors((selectedSensors) => {
                // console.log({...selectedSensors,[shortId]:!selectedSensors[shortId]},"SELECTED SENSORS");
                return { ...selectedSensors, [shortId]: !selectedSensors[shortId] }
              });
            }}
          />
        )
      }
    },
    name: {
      label: "sensor name",
      style: { minWidth: 200 }
    },
    bound: {
      label: "binding status",
      type: "component",
      component: (row) => {
        return <CustomTypography
          content={row?.data?.deviceId ? "Bound" : "Not Bound"}
          size={14}
          color={"#000000CC"}
          lineHeight={19}
          weight={400}
        />
      },
      style: { minWidth: 150 }
    },
    controllerName: {
      label: "aggregator",
      type: "component",
      component: (row) => {
        return <CustomTypography
          content={row?.data?.deviceId ? row?.data?.controllerName : "-"}
          size={14}
          color={"#000000CC"}
          lineHeight={19}
          weight={400}
        />
      }
    },
    modelNumber: {
      label: "model number",
      style: { minWidth: 200 }
    },
    userActions: {
      label: "Actions",
      actions: [
        {
          label: "Identify Sensor",
          type: "identify",
          icon: SensorIdentifyIcon,
          onClick: (event, data) => {
            identifySensor({ device: data ? data : deviceDetails, shortId: data?.deviceId ? null : data?.shortId, dispatch, mqttClient }, mqttTopics)
          },
          disabled: (row) => {
            return row?.discovering
          }
        },
        {
          label: "FactoryReset",
          icon: FactoryResetIcon,
          onClick: (event, data) => {
            factoryReset(data, setDialogBox, setDialogBoxContents, dispatch, mqttClient, true, () => {
              setTable((table) => {
                return {
                  ...table, data: table?.data?.map(s => {
                    if (s?.deviceId === data?.deviceId) {
                      delete s?.deviceId;
                    }
                    return s;
                  })
                }
              });
            })
          },
          hidden: (data) => {
            return data?.hasOwnProperty("deviceId") ? (data?.controllerId === deviceDetails?.deviceId) : true;
          },
          disabled: (row) => {
            return row?.discovering
          }
        }
      ],
      style: { textAlign: "right" }
    }
  }

  const classes = useStyles();
  const [force, setForce] = useState(false);
  const [duration, setDuration] = useState(120);
  const [error, setError] = useState(false);
  const [table, setTable] = useState({ headers: tableHeaders, data: [] });
  const [discovering, setDiscovering] = useState(false);
  const [discoveryTimer, setDiscoveryTimer] = useState(null);
  const [discoveryTimeout, setDiscoveryTimeout] = useState(null);
  const [selectedSensors, setSelectedSensors] = useState({});
  const [countdown, setCountdown] = useState({});


  const dispatch = useDispatch();
  const sensorList = useSelector((state) => state?.devices?.sensorList) || [];
  const requestId = useSelector((state) => state?.devices?.requestId) || null;
  const lastAction = useSelector((state) => state?.devices?.lastAction) || null;



  const changeDuration = (event) => {
    const value = event?.currentTarget?.value;
    setDuration(Number(value));
  }
  const startDiscovery = () => {
    if (duration < 10 || duration > 360) {
      setError(true);
    }
    else {
      let timerDuration = duration;
      setCountdown(setInterval(() => {
        if (timerDuration - 1 >= 1) {
          timerDuration = timerDuration - 1;
          setDuration(timerDuration - 1);
        }
        else {
          clearInterval(countdown);
        }
      }, 1000))
      setError(false);
      dispatch(deviceActions?.clearSensorList());
      setDiscovering(true);

      dispatch(deviceActions?.findSensors(deviceDetails?.deviceId, duration, force, (requestId, duration) => {
        let timer = null;
        setDiscoveryTimer(() => {
          timer = setInterval(() => {
            dispatch(deviceActions?.fetchSensors(requestId));
          }, fetchSensorInterval)
          return timer
        })
        setDiscoveryTimeout(() => {
          return (setTimeout(() => {
            clearInterval(timer);
            setDiscovering(false);
            setTimeout(() => {
              setTable((table) => {
                return {
                  ...table, data: table?.data?.map(s => {
                    s.discovering = false;
                    return s;
                  })
                }
              })
            }, 2000)
          }, (Number(duration)) * 1000))
        });
      }))

    }
  }

  const stopDiscovery = () => {
    setTable({
      headers: tableHeaders, data: (Array?.isArray(table?.data) && table?.data?.map(s => {
        s.discovering = false;
        return s;
      })) || []
    })
    clearTimeout(discoveryTimeout);
    clearInterval(countdown)
    setDiscovering(false);
    clearInterval(discoveryTimer);
    dispatch(deviceActions?.stopFindSensors(deviceDetails?.deviceId))
  }


  useEffect(() => {
    if (lastAction?.includes("set_sensor_list")) {
      const selected = {}
      Array?.isArray(sensorList) && sensorList?.forEach(s => {
        selected[s?.shortId] = false;
      })
      setSelectedSensors(selected);
      setTable({
        ...table, data: Array?.isArray(sensorList) && sensorList?.map(s => {
          s.discovering = true
          return s
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastAction]);



  return (
    <Box className={classes?.DiscoverSensors || ""} data-testid="DiscoverSensors" sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Checkbox
          id="forceDiscover"
          checked={force}
          sx={{ p: 0, mr: 1 }}
          onClick={(e) => { setForce(!force) }}
          inputProps={{ "aria-label": "check-box" }}
        />
        <CustomTypography
          content={"Force Discover"}
          size={12}
          color={"#000"}
          lineHeight={14}
          weight={600}
        />
      </Box>
      <Box>
        <CustomLabel
          required={true}
          style={{ fontSize: 12, fontWeight: 400, color: "#00000099", lineHeight: "14px" }}>
          {"Set a pairing duration between 10-360 sec"}
        </CustomLabel>
        <Box>
          <StyledTextfield
            error={error}
            helperText={error ? "Pairing duration can be between 10 and 360 seconds" : null}
            errorPopup={true}
            disabled={discovering}
            value={duration}
            type="number"
            sx={{
              // height: 25, 
              '& .MuiOutlinedInput-root': {
                width: "max-content",
              },
              alignItems: "center",
              '& .MuiFormHelperText-root': {
                textAlign: "center",
                fontSize: "small"
              }
            }}
            inputProps={{ min: 10, max: 360 }}
            onChange={(event, objkey = null, data = "") => { changeDuration(event) }}
          >
          </StyledTextfield>
          <StyledButton
            onClick={() => { discovering ? stopDiscovery() : startDiscovery() }}
            sx={{ ml: 1, height: "41px" }}
          >
            {discovering ? "Stop Discovery" : "Start Discovery"}
          </StyledButton>
        </Box>
        {/* <Box className="tableBox"> */}
        <CustomTable
          headerList={table?.headers}
          sorting={{ column: 'name' }}
          removeSortFrom={["checked", "userActions"]}
          cellMatrix={table?.data}
          // pagination={true}
          searching={true}>
        </CustomTable>
        {/* </Box> */}
        <Box sx={{ width: "100%", display: "flex", mt: 2, justifyContent: "right" }}>
          <StyledButton
            sx={{ ml: 1, minWidth: "117px" }}
            onClick={() => {
              if (isEmpty(table?.data)) {
                dispatch(deviceActions?.clearSensorList());
                if (discovering) {
                  stopDiscovery();
                }
                setDialog(false);
              }
              else {
                setDialogBoxContents((dialogContents) => ({
                  ...dialogContents,
                  state: "timeout",
                  content: {
                    title: "Bind Sensors",
                    content: getMessageStr(`device-discoverSensor-confirmation`),
                    actions: [
                      {
                        id: "1001",
                        type: "button",
                        label: "Cancel",
                        onClick: (event, data) => {
                          setDialogBox(false);
                        },
                      },
                      {
                        id: "1002",
                        type: "button",
                        label: "Confirm",
                        onClick: (event, data) => {
                          dispatch(deviceActions?.clearSensorList());
                          if (discovering) {
                            stopDiscovery();
                          }
                          setDialog(false);
                          setDialogBox(false);
                        },
                      },
                    ],
                  },
                }));
                setDialogBox(true);
              }
            }}>
            Cancel
          </StyledButton>
          <StyledButton
            sx={{ ml: 1, minWidth: "117px" }}
            disabled={isEmpty(Object?.keys(selectedSensors)?.filter(id => selectedSensors[id] === true))}
            onClick={() => {
              // console.log("SEL",selectedSensors,Object?.keys(selectedSensors)?.filter(id => selectedSensors[id] === true))
              let updatedTableData = table?.data;
              const bindSensorsList = Object?.keys(selectedSensors)?.filter(id => selectedSensors[id] === true)?.map(id => {
                updatedTableData = updatedTableData?.filter(d => d?.shortId !== id)
                const sensor = table?.data?.find(s => s?.shortId === id);
                delete sensor?.controllerName;
                delete sensor?.controllerId;
                delete sensor?.ts;
                delete sensor?.discovering;
                delete sensor?.deviceId;
                return sensor
              })
              dispatch(deviceActions?.bindSensors(deviceDetails?.deviceId, requestId, bindSensorsList, () => {
                setSelectedSensors({});
                setTimeout(() => {
                  setTable({
                    ...table, data: updatedTableData
                  });
                }, 500)
              }))
            }}>
            Bind Sensors
          </StyledButton>
        </Box>
      </Box>
    </Box >
  )
};

DiscoverSensors.propTypes = {};

DiscoverSensors.defaultProps = {};

export default DiscoverSensors;
