import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    StyledColorPicker: {
    },   
    ColorPicker:{
        '& .MuiOutlinedInput-input':{padding:'8px 15px',  flexDirection: 'row-reverse', paddingLeft:0},
        '& .MuiOutlinedInput-root':{flexDirection:'row-reverse'},
        '& .MuiButton-root':{position:'relative', right:'-70px', height:'37px'},      
        // '.MuiColorInput-Button':{backgroundSize:'cover'} 
    }

});
