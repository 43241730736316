import { rotateKeyTypes } from "../types";

let initialState = {
    loading: false,
    latestBleKey: {},
    keyList: [],
    error: null
}

export function rotateKey(state = initialState, action) {
    switch (action?.type) {
        case rotateKeyTypes?.GENERATE_KEY_REQUEST:
        case rotateKeyTypes?.ROTATE_KEY_REQUEST:
        case rotateKeyTypes?.GET_BLE_KEYS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case rotateKeyTypes?.GENERATE_KEY_SUCCESS:
            return {
                ...state,
                latestBleKey: { ...action?.payload },
                loading: false,
            }
        case rotateKeyTypes?.ROTATE_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
            }
        case rotateKeyTypes?.GET_BLE_KEYS_SUCCESS:
            return {
                ...state,
                keyList: action?.payload,
                loading: false,
            }
        case rotateKeyTypes?.GET_BLE_KEYS_FAILURE:
            return {
                ...state,
                error: action.payload,
                keyList: [],
                loading: false
            }
        case rotateKeyTypes?.GENERATE_KEY_FAILURE:
        case rotateKeyTypes?.ROTATE_KEY_FAILURE:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        default:
            return state;
    }
}
