import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    SpaceDeviceTree: {

    },
    TreeItem: {
        '& .css-1snv7l3-MuiTreeItem-content': {
          border: '1.4px solid #D9D9D9',
          borderRadius: '4px',
          mb: '5px'
        },
        '.MuiTreeItem-label': {
          height: '40px'
        }
    }
});
