/***
 *
 * Controller class for user.
 * @file ControllerSlider.js
 * @description ControllerSlider component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 *
 */

import React, { useEffect } from "react";
// import PropTypes from 'prop-types';
import { useStyles } from "./ControllerSlider.style.js";
import "./ControllerSlider.scss";
import { Box, Slider } from "@mui/material";
import TempGreen from "../../../assets/icons/temperature-2.svg";
import CustomTypography from "../../CustomTypography/index.js";
import ImageWrapper from "../../ImageWrapper/index.js";
import SwitchButton from "../../SwitchButton";

const ControllerSlider = ({
  prefix = "",
  suffix = "",
  customsx = { icon: {}, label: {}, value: {}, compo: {}, outerdiv: {}, toggleLabel: {}, toggle: {} },
  onOffLabel = true,
  wrap=true,
  params = {
    icon: true,
    label: true,
    value: true,
    compo: true,
    switchButton: false,
  },
  value = 0,
  name = "slider",
  disabled = false,
  icon = null,
  label,
  setterFunction = () => { },
  data,
}) => {
  const classes = useStyles();
  const [valueSlider, setValueSlider] = React.useState(value || 0);
  const [toggleswitch, setToggleSwitch] = React.useState(true);
  let timeout = null;

  useEffect(() => {
    setValueSlider(value);
    setToggleSwitch(value > 0 ? true : false);
  }, [value]);

  const handleToggle = (e, value) => {
    if (!value) {
      setValueSlider(0);
      setterFunction(e, 0);
    } else {
      setValueSlider(params.switchButton ? 70 : value);
      setterFunction(e, params.switchButton ? 70 : value);
    }
    setToggleSwitch(value);
  };
  const handleSliderChange = (event, newValue) => {
    const { name } = event.target;
    if (name) {
      if (newValue > 0) {
        setToggleSwitch(true);
      } else {
        setToggleSwitch(false);
      }
      setValueSlider(newValue);
      // setterFunction(event, newValue);
      // timeout && clearTimeout(timeout);
      // timeout = setTimeout(() => {
      //   setValueSlider(newValue);
      //   setterFunction(event, newValue);
      // }, 5000);
    }
  };

  return (
    <>
      {/* <pre style={{width: "100%"}}>{JSON.stringify(customsx, null, 2)}</pre> */}
      <Box sx={{ maxWidth: "100%", ...customsx.outerdiv }} className={" ControllerSlider " + classes?.ControllerSlider}>
        <Box className={"labels"} >
          {params.icon && (
            <ImageWrapper
              className="slider-icon"
              style={{ paddingRight: "10px", ...customsx.icon }}
              src={icon || TempGreen}
            />
          )}
          {params.label && (
            <CustomTypography
              className="slider-label"
              styles={{ marginRight: "8px", ...customsx.label }}
              content={label || "slider"}
              color="#000"
              weight={600}
              size={12}
              lineHeight={22}
            />
          )}
          {params.switchButton && (
            <Box
              sx={{ py: .3 }}
              style={{ display: "flex", alignItems: "center" }}
            >
              {/* {onOffLabel &&
                <CustomTypography
                  content={toggleswitch ? "ON" : "OFF"}
                  size={14}
                  weight={500}
                  sx={{ ...customsx?.toggleLabel }}
                />
              } */}

              <SwitchButton
                setToggle={toggleswitch}
                value="true"
                checked={toggleswitch}
                type="iphone"
                disabled={disabled}
                onChange={(e, checked) => {
                  handleToggle(e, checked);
                }}
                sx={{mr:1, ...customsx?.toggle }}
              />
            </Box>
          )}
        </Box>
          <Box className={"controls"}>
            {params.value && (
              <CustomTypography
                className="slider-value"
                styles={{ ...customsx.value }}
                content={`${prefix}${valueSlider}${suffix}`}
                weight={600}
                size={12}
                lineHeight={19}
                color={"#0F8D48"}
              />
            )}
            {params?.compo &&
              <Slider
                name={name}
                value={valueSlider}
                onChange={handleSliderChange}
                onChangeCommitted={(event, newValue) => { 
                  setterFunction(event, newValue) }}
                aria-labelledby="intensity-slider"
                max={data?.max}
                min={data?.min}
                disabled={disabled}
                className={classes?.Slider || ""}
                sx={{ width: "100%", minWidth: "70px", maxWidth: "300px", padding: "7px 0px", ...customsx.compo }}
              />
            }
          </Box>
      </Box>
    </>
  );
};

ControllerSlider.propTypes = {};

ControllerSlider.defaultProps = {};

export default ControllerSlider;
