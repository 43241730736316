/***
 *
 * Controller class for user.
 * @file TicketManagement.js
 * @description TicketManagement component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import { Box } from '@mui/material';
import React from 'react';
import Tickets from '../../components/Tickets/index.js';
// import PropTypes from 'prop-types';
import { useStyles } from './TicketManagement.style.js';

const TicketManagement = () => {
  const classes = useStyles()

    return (
        <Box className={classes?.TicketManagement || ""} data-testid="TicketManagement">
          <Tickets />
        </Box>
    )
};

TicketManagement.propTypes = {};

TicketManagement.defaultProps = {};

export default TicketManagement;
