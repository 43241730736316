// import { reportMessages } from "./i";
import { reportMessages } from "./messages/report.messages";

// export const scopeList = [
//   "Tickets",
//   "Alarms",
//   "Power Consumption",
//   "Rules",
//   "Users",
//   "Services",
//   "Upgrades",
// ];

// export const scopeListMap = {
//   Tickets: "tickets",
//   Alarms: "alarms",
//   "Power Consumption": "powerConsumption",
//   Rules: "rules",
//   Users: "users",
//   Services: "service",
//   Upgrades: "upgrades",
// };

// export const reverseScopeListMap = {
//   tickets: "Tickets",
//   alarms: "Alarms",
//   powerConsumption: "Power Consumption",
//   rules: "Rules",
//   users: "Users",
//   service: "Services",
//   upgrades: "Upgrades",
// };
export const scopeList = [
  { label: "Tickets", value: "tickets" },
  { label: "Alarms", value: "alarms" },
  { label: "Power Consumption", value: "powerConsumption" },
  { label: "Rules", value: "rules" },
  { label: "Users", value: "users" },
  { label: "Services", value: "service" },
  { label: "Upgrades", value: "upgrades" }];

// export const categoriesList = ["Lights", "Hvac"];

// export const categoriesListMap = {
//   Lights: "lighting",
//   Hvac: "hvac",
// };

// export const reverseCategoriesListMap = {
//   lighting: "Lights",
//   hvac: "Hvac",
// };

export const categories = [{ label: "Lights", value: "lighting" }, { label: "HVAC", value: "hvac" }];

export const reportTypeList = [
  { value: "oneTime", label: "Period" },
  { value: "scheduleReport", label: "Duration" },
];

export const monthList = [
  { value: "1", label: "JAN" },
  { value: "2", label: "FEB" },
  { value: "3", label: "MAR" },
  { value: "4", label: "APR" },
  { value: "5", label: "MAY" },
  { value: "6", label: "JUN" },
  { value: "7", label: "JUL" },
  { value: "8", label: "AUG" },
  { value: "9", label: "SEP" },
  { value: "10", label: "OCT" },
  { value: "11", label: "NOV" },
  { value: "12", label: "DEC" },
];

export const monthMap = {
  1: "JAN",
  2: "FEB",
  3: "MAR",
  4: "APR",
  5: "MAY",
  6: "JUN",
  7: "JUL",
  8: "AUG",
  9: "SEP",
  10: "OCT",
  11: "NOV",
  12: "DEC",
};

export const weekdayList = [
  { value: "1", label: "MON" },
  { value: "2", label: "TUE" },
  { value: "3", label: "WED" },
  { value: "4", label: "THU" },
  { value: "5", label: "FRI" },
  { value: "6", label: "SAT" },
  { value: "7", label: "SUN" },
];

export const weekDayMap = {
  1: "MON",
  2: "TUE",
  3: "WED",
  4: "THU",
  5: "FRI",
  6: "SAT",
  7: "SUN",
};

export const addScheduleCheckbox = [
  { value: "attachSchedule", label: "Add Scheduling Details" },
];

export const attachScheduleCheckbox = [
  { value: "attachSchedule", label: "Schedule this Report" },
];

export const accessList = [
  { value: "public", label: "Public" },
  { value: "private", label: "Private" },
];

export const startDateValidation = {
  req: [true, `${reportMessages["report-addReportFormValidation-startDate"]}`],
};

export const endDateValidation = {
  req: [true, `${reportMessages["report-addReportFormValidation-endDate"]}`],
};

export const durationValidation = {
  req: [true, `${reportMessages["report-addReportFormValidation-duration"]}`],
  minVal: [
    3,
    `${reportMessages["report-addReportFormValidation-durationMinValue"]}`,
  ],
};

export const scheduleLabelsReportDetails = {
  weekDays: { label: "WEEK DAYS" },
  days: { label: "DAY OF MONTH" },
  months: { label: "MONTHS" },
  time: { label: "SET TIME" },
  expiresAtSecs: { label: "Expires At" },
};

export const reportSummaryLabelsReportDetails = {
  createdBy: { label: "CREATED BY" },
  createdOn: { label: "CREATED ON" },
  lastUpdatedBy: { label: "LAST UPDATED BY" },
  lastUpdatedOn: { label: "LAST UPDATED ON" },
  expiresAtSecs: { label: "EXPIRES AT" },
};

export const scheduleLabels = {
  weekDays: { label: "WEEK DAYS" },
  days: { label: "DAY OF MONTH" },
  months: { label: "MONTHS" },
  time: { label: "SET TIME" },
  expiresAtSecs: { label: "Expires At" },
};

export const reportSummaryLabels = {
  createdBy: { label: "CREATED BY" },
  createdOn: { label: "CREATED ON" },
  lastUpdatedBy: { label: "LAST UPDATED BY" },
  lastUpdatedOn: { label: "LAST UPDATED ON" },
  expiresAtSecs: { label: "EXPIRES AT" },
};

export const frmConfigObj = [
  // create report m frmConfigObj
  {
    sid: 1,
    section: {
      heading: {
        text: "Add Report Details",
        style: { fontWeight: "700", marginBottom: "16px" },
      },
      style: { border: "1px solid #EAEAEA", padding: "20px 24px" },
    },
    columns: [
      {
        id: "1",
        type: "text",
        label: "Name",
        name: "name",
        required: true,
        placeholder: "Enter Report Name",
        validation: {
          req: [
            true,
            `${reportMessages["report-addReportFormValidation-name"]}`,
          ],
          minLen: [
            2,
            `${reportMessages["report-addReportFormValidation-nameMinLength"]}`,
          ],
          maxLen: [
            30,
            `${reportMessages["report-addReportFormValidation-nameMaxLength"]}`,
          ],
          alphaNumChars: [
            "- ",
            `${reportMessages["report-addReportFormValidation-nameAplhaNum"]}`,
          ],
        },
        columnSize: 4,
      },
      {
        id: "2",
        type: "text",
        label: "Description",
        name: "description",
        required: true,
        placeholder: "Enter Report Description",
        validation: {
          req: [
            true,
            `${reportMessages["report-addReportFormValidation-description"]}`,
          ],
          minLen: [
            2,
            `${reportMessages["report-addReportFormValidation-descriptionMinLength"]}`,
          ],
          maxLen: [
            280,
            `${reportMessages["report-addReportFormValidation-descriptionMaxLength"]}`,
          ],
        },
        columnSize: 8,
      },
      {
        id: "3",
        type: "treeDropDown",
        label: "Select Space",
        required: true,
        name: "space",
        placeholder: "Select Spaces",
        validation: {
          req: [
            true,
            `${reportMessages["report-addReportFormValidation-space"]}`,
          ],
        },
        options: [],
        columnSize: 4,
      },
      {
        id: "4",
        type: "multiselect",
        label: "Scope",
        name: "scope",
        placeholder: "Select Scopes",
        required: true,
        validation: {
          req: [
            true,
            `${reportMessages["report-addReportFormValidation-scope"]}`,
          ],
        },
        options: [],
        columnSize: 4,
      },
      {
        id: "5",
        type: "multiselect",
        label: "Category",
        required: true,
        name: "categories",
        placeholder: "Select Categories",
        validation: {
          req: [
            true,
            `${reportMessages["report-addReportFormValidation-categories"]}`,
          ],
        },
        options: [],
        columnSize: 4,
      },
      {
        id: "6",
        type: "radiobutton",
        label: "Access",
        name: "access",
        isLeft: true,
        required: true,
        options: [...accessList],
        customsx: { label: { fontSize: "0.875rem", fontWeight: "500" } },
        columnSize: 12,
      },
    ],
  },
  {
    sid: 2,
    section: {
      heading: {
        text: "Get Report For",
        style: { fontWeight: "700", marginBottom: "16px" },
      },
    },
    columns: [
      {
        columnSize: 7,
        style: { border: "1px solid #EAEAEA", padding: "20px 24px" },
        rows: [
          {
            rowSize: 12,
            style: { marginBottom: "16px" },
            columns: [
              {
                id: "7",
                type: "radiobutton",
                label: "",
                name: "reportType",
                isLeft: true,
                options: [{ value: "oneTime", label: "Period" }],
                customsx: {
                  label: { fontSize: "0.875rem", fontWeight: "500" },
                },
                columnSize: 12,
              },
            ],
          },
          {
            rowSize: 12,
            columns: [
              {
                id: "8",
                type: "datetimepicker",
                name: "startDate",
                label: "From Date",
                placeholder: "Select Date",
                disableFuture: true,
                disabled: false,
                required: true,
                columnSize: 6,
              },
              {
                id: "9",
                type: "datetimepicker",
                name: "endDate",
                label: "To Date",
                placeholder: "Select Date",
                disableFuture: true,
                disabled: false,
                required: true,
                columnSize: 6,
              },
            ],
          },
        ],
      },
      {
        columnSize: 5,
        style: { border: "1px solid #EAEAEA", padding: "20px 24px" },
        rows: [
          {
            rowSize: 12,
            style: { marginBottom: "16px" },
            columns: [
              {
                id: "71",
                type: "radiobutton",
                label: "",
                name: "reportType",
                isLeft: true,
                options: [
                  { value: "scheduleReport", label: "Duration" },
                ],
                customsx: {
                  label: { fontSize: "0.875rem", fontWeight: "500" },
                },
                columnSize: 12,
              },
            ],
          },
          {
            rowSize: 12,
            columns: [
              {
                id: "10",
                type: "number",
                label: "Enter duration (in days)",
                name: "duration",
                required: true,
                placeholder: "Enter Duration",
                columnSize: 12,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    sid: 3,
    columns: [
      {
        id: "11",
        type: "checkbox",
        label: "",
        name: "attachSchedule",
        selectedWeight: 600,
        disabled: true,
        customsx: { Options: { "fontSize": "0.875rem" } },
        columnSize: 12,
      },
    ],
  },
  {
    sid: 5,
    section: {
      heading: {
        text: "Add Subscribers",
        style: { fontWeight: "700", marginBottom: "16px" },
      },
      style: { border: "1px solid #EAEAEA", padding: "20px 24px" },
    },
    columns: [
      {
        id: "12",
        type: "chipinput",
        componentType: "select",
        label: "Add Users",
        name: "subscribers",
        addBtn: true,
        width: "300px",
        placeholder: "Select users",
        visibleIn: ["edit", "view", "create"],
        chipsPosition: "right",
        columnSize: 12,
      },
    ],
  },
];

export const scheduleConfig = {
  sid: 4,
  section: {
    heading: "",
    style: { border: "1px solid #EAEAEA", padding: "20px 24px" },
  },
  columns: [
    {
      id: "14",
      type: "checkbox",
      label: "Select Month (s)",
      name: "months",
      selectedColor: "#0f8d48",
      customsx: { options: { fontSize: "0.625rem" } },
      isLeft: true,
      coulumnSize: 12,
    },
    {
      id: "15",
      type: "chipInput",
      componentType: "select",
      addBtn: true,
      label: "Select Days Of Month",
      name: "days",
      disabledOnEdit: false,
      visibleIn: ["edit", "view", "create"],
      columnSize: 12,
    },
    {
      id: "16",
      type: "checkbox",
      label: "Select Week Day (s)",
      name: "weekDays",
      selectedColor: "#0f8d48",
      customsx: { options: { fontSize: "0.625rem" } },
      isLeft: true,
      coulumnSize: 12,
    },
    {
      id: "17",
      type: "chipInput",
      componentType: "datetime",
      componentProps: {
        datetime: { picker: "TimePicker", format: "hh:mm a" },
      },
      addBtn: true,
      label: "Set Time",
      name: "time",
      required: true,
      disabledOnEdit: false,
      visibleIn: ["edit", "view", "create"],
      validation: {
        req: [true, `${reportMessages["report-addReportFormValidation-time"]}`],
      },
      columnSize: 12,
    },
    {
      id: "18",
      type: "dateTimePicker",
      picker: "DatePicker",
      label: "Set Expiry Date",
      name: "expiresAtSecs",
      disablePast: true,
      columnSize: 4,
    },
  ],
};
