export const automationType = {
    GET_AUTOMATION: 'AUTOMATION_GET_REQUEST',
    GET_AUTOMATION_SUCCESS: 'AUTOMATION_GET_SUCCESS',
    GET_AUTOMATION_FAILURE: 'AUTOMATION_GET_FAILURE',

    CREATE_AUTOMATION: "CREATE_AUTOMATION",
    CREATE_AUTOMATION_SUCCESS: 'AUTOMATION_POST_CREATE_SUCCESS',
    CREATE_AUTOMATION_FAILURE: 'AUTOMATION_POST_CREATE_FAILURE',

    GET_RULE_BY_ID: "GET_RULE_BY_ID_REQUEST",
    GET_RULE_BY_ID_SUCCESS: 'AUTOMATION_GET_RULE_BY_ID_SUCCESS',
    GET_RULE_BY_ID_FAILURE: 'AUTOMATION_GET_RULE_BY_ID_FAILURE',

    DELETE_REQUEST: 'AUTOMATION_DELETE_REQUEST',
    DELETE_SUCCESS:'AUTOMATION_DELETE_SUCCESS',
    DELETE_FAILURE: 'AUTOMATION_DELETE_FAILURE',

    UPDATE_AUTOMATION_REQUEST: 'UPDATE_AUTOMATION_REQUEST',
    UPDATE_AUTOMATION_SUCCESS: 'UPDATE_AUTOMATION_SUCCESS',
    UPDATE_AUTOMATION_FAILURE : 'UPDATE_AUTOMATION_FAILURE'
}