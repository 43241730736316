/***
 *
 * Controller class for user.
 * @file StyledRadioButton.js
 * @description StyledRadioButton component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './StyledRadioButton.style.js';
import './StyledRadioButton.scss';
import { Box, FormControlLabel, Radio } from '@mui/material';
import ErrorMessage from '../ErrorMessage';

const StyledRadioButton = ({ id, label="", options = [], error = true, disabled = false, message="", labelPlacement="right",  selectedColor = "#000", selectedWeight = "500",helperText = "", value = null, name, vertical = false, onChange = () => { }, customsx = { label: {}, radio: {} } }) => {
  const classes = useStyles();
  const [checked, setChecked] = useState(value);

  const handleChange = (event) => {
    console.log(event.target.value)
    onChange(event, name);
  };

  useEffect(()=>{
    setChecked(value);
  }, [value])

  return (
    <>
    <Box data-testid="StyledRadioButton">
      {Array.isArray(options) && options.map((option, index) => (
          <FormControlLabel 
            key={"radiooooo--" + index}
            id={id}
            name={name}
            value={option?.value} 
            control={<Radio id={id} sx={{'&.MuiRadio-root':{p: '5px', ml:'5px', ...customsx.checkbox}, '& .MuiSvgIcon-root':{height:'15px', width:'15px', fill: !checked && (error ? '#ff4336' : '#999999')} }} />} 
            label={option?.label || label} 
            sx={{ mr: '26px', mb: 0, '& .MuiTypography-root': { color: checked===option.value && selectedColor, fontWeight: checked === option?.value && selectedWeight, ...customsx.label  } }}
            disabled={disabled}
            labelPlacement={labelPlacement === "right" ? "end" : (labelPlacement === "left" ? "start" : labelPlacement)}
            checked={checked === option?.value}
            onChange={handleChange} 
            inputProps={{ 'aria-label': option?.value }}
          />
        ))
      }
    </Box>
     {error && <ErrorMessage sx={{mt:0}} message={message || ''} />}
    
     </>
  )
};

StyledRadioButton.propTypes = {};

StyledRadioButton.defaultProps = {};

export default StyledRadioButton;
