export const customSortRules = (myArray = []) => {
    const today = Date.now() / 1000;

    const sortedArray = myArray?.sort((a, b) => {
        if (a?.status === 'enable' && a?.startsAtSecs <= today && a?.expiresAtSecs > today) {
            return -1;
        } else if (b?.status === 'enable' && b?.startsAtSecs <= today && b?.expiresAtSecs > today) {
            return 1;
        } else if (a?.status === 'enable' && a?.startsAtSecs > today && a?.expiresAtSecs > today) {
            return -1;
        } else if (b?.status === 'enable' && b?.startsAtSecs > today && b?.expiresAtSecs > today) {
            return 1;
        } else if (a?.status === 'disable' && a?.expiresAtSecs > today) {
            return -1;
        } else if (b?.status === 'disable' && b?.expiresAtSecs > today) {
            return 1;
        } else if (a?.expiresAtSecs < today) {
            return 1;
        } else if (b?.expiresAtSecs < today) {
            return -1;
        } else {
            return 0;
        }
    }) || [];

    return sortedArray;
};

export const findArrayByType = (arrayOfArrays, targetType) => {
    return arrayOfArrays?.find(array => array.some(obj => obj.type.includes(targetType))) || null;
  }