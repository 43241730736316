/***
 *
 * Controller class for user.
 * @file SceneDetails.js
 * @description SceneDetails component
 * @author Shubham Singh
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect, useState } from "react";
// import PropTypes from 'prop-types';
import './SceneDetails.scss';
import _ from 'lodash';
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../components/PageHeader";
import CustomTypography from "../../components/CustomTypography";
import CustomAccordion from "../../components/CustomAccordion";
import CustomTable from "../../components/CustomTable";
import StyledTabs from "../../components/StyledTabs";
import { useQuery } from "../../hooks";
import { sceneActions } from "../../redux/actions";
import { Box, Grid } from "@mui/material";
import { history } from '../../helpers/history.helper.js';
import { deviceActions } from "../../redux/actions";
import { TableHelper } from "../../components/TableHelper";
import { temperatureSliderCss } from './SceneDetails.style.js';
import StyledPopup from '../../components/StyledPopup/index.js';
import ImageWrapper from "../../components/ImageWrapper";
import { applyScene, formatedDate, getMessageStr, isAuthorised, setSceneDeleteTimeout } from "../../helpers";
import CheckCircle from "../../assets/icons/check-circle.svg";
import AlertIcon from "../../assets/icons/alert-icon.svg";
import Device from "../../assets/icons/bulb.svg"
import Refresh from "../../assets/icons/sync.svg";
import applyIcon from "../../assets/icons/apply.svg";
import groupIcon from "../../assets/icons/group.svg";
import sceneIcon from "../../assets/icons/scene.svg";
import editIcon from "../../assets/icons/edit-2.svg";
import deleteIcon from '../../assets/icons/delete.svg';
import lampIcon from '../../assets/icons/lamp.svg';
import { Link } from "react-router-dom";
import { features, permissions } from "../../constants";
import { subscribe, unsubscribeAll } from "../../services";
import StyledButton from "../../components/StyledButton";
import ItemView from "../../components/ItemView";
import { sceneDeleteTimeout } from "../../config/mqttConfig";

const sceneDetailsArray = {
  deviceTypes: { label: "applied on type of devices" },
  currentSpace: { label: "applicable to space" },
  managedBy: { label: "Managed By" },
  dateOfCreation: { label: "created on" },
  createdBy: { label: "created by" },
  dateOfModification: { label: "last modified" },
  synced: { label: "sync status" },
  appliedOnRules: { label: "applied on rules" },
  // apply: { label: "" },
};

const SceneDetails = ({ mqttClient }) => {
  const query = useQuery();
  const dispatch = useDispatch();
  const scene = useSelector((state) => state?.scenes?.scene);
  const [selectedSpace, setSelectedSpace] = useState('');
  const activeSpace = useSelector(state => state?.spaces?.selectedSpace);
  const [sceneDetails, setSceneDetails] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [tabsArray] = useState([{ icon: Device, label: 'Devices' }, { icon: groupIcon, label: 'Groups' }]);
  const [headers, setHeaders] = useState({});
  const sceneId = query.get("id") || null;
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState({ show: false });
  const [gateway, setGateway] = useState({});
  const deviceData = useSelector((state) => state?.devices?.deviceDetails);
  const gatewayList = useSelector((state) => state?.devices?.gatewayList) || [];
  const deviceModels = useSelector(state => { return (state?.devices?.deviceModels || []) })
  const deviceCategories = useSelector(state => state?.devices?.deviceCategories);

  useEffect(() => {
    return () => {
      unsubscribeAll(mqttClient, dispatch)
    }
  }, [mqttClient])

  useEffect(() => {
    if (!_.isEmpty(sceneId)) {
      dispatch(sceneActions.getSceneDetails(sceneId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sceneId]);

  useEffect(() => {
    setSelectedSpace(activeSpace?.name);
    if (!_.isEmpty(scene)) {
      setSceneDetails({
        ...scene,
        deviceTypes: "",
        currentSpace: selectedSpace,
        dateOfCreation: formatedDate({ date: scene.createdOn, format: "dd MMMM yyyy" }),
        dateOfModification: formatedDate({ date: scene.lastUpdatedOn, format: "dd MMMM yyyy" }),
        appliedOnRules: "",
        synced: scene.synced ? <ImageWrapper height={15} width={15} src={CheckCircle} /> : <ImageWrapper height={15} width={15} src={AlertIcon} />,
        syncStatus: scene.synced
        // apply: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scene, activeSpace])

  useEffect(() => {
    if (sceneDetails?.sceneId === sceneId) {
      const gwData = gatewayList?.find(g => g?.deviceId === sceneDetails?.gatewayIds[0])
      setGateway(gwData);
      if (sceneDetails?.managedBy === 'controller' && sceneDetails?.controllerIds && sceneDetails?.controllerIds[0]) {
        //calling device details API with controller ID to get controller topic for scene apply
        dispatch(deviceActions?.getDevice(sceneDetails?.controllerIds[0], sceneDetails?.managedBy, false, getMessageStr("scene-getParent-error", sceneDetails?.managedBy)));
      }
      if (sceneDetails?.managedBy !== 'cloud') {
        //to monitor sync status of scene
        subscribe(gwData?.mqttTopics?.comStatus, dispatch, mqttClient, "sceneSyncMonitor")//kept inside if condition as no subscription is required for cloud scene
      }
      handleToggleDevice(null, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sceneDetails?.sceneId])//sceneId is used so that when scene is synced the useEffect is not triggered


  const goTo = (route) => {
    history.push(route);
  };

  const settingUpHeaderList = (index) => {
    if (index === 0) {
      setHeaders({
        name: {
          label: "Devices",
          type: "component",
          component: ({ data }) => {
            return (
              <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                {data?.status !== 'deleted' ? <Link className="link" to={`/spaces/devices?pt=device&id=${data?.deviceId}`}>{`${data?.name && data?.name !== '' ? data?.name : data?.deviceId}`}</Link> : <CustomTypography content={`${data?.name && data?.name !== "" ? data?.name : data?.deviceId}`} />}
              </Box>
            );
          }
          // url: "/spaces/devices?pt=device",
          // urlParams: { id: "deviceId" }
        },
        intensity: {
          label: "Intensity",
          type: "component",
          component: (row) => {
            const model = deviceModels.find(deviceModel => { return (deviceModel?.modelNumber !== undefined && deviceModel?.modelNumber?.substring(deviceModel.modelNumber?.length - 3, deviceModel.modelNumber?.length) === row?.data?.configuration?.daliDriver) || (row?.data?.modelId === deviceModel?.modelId) }) || {}
            const lstModel = model?.controlCapabilities?.find(m => m?.capability?.trim()?.toLowerCase() === "intensity");
            const maxIntensity = lstModel?.attributes[0]?.range?.max[0]?.value
            return (row?.data?.intensity !== undefined ? <TableHelper.TableSlider params={row?.data?.category === "RelayLightDriver" ? { switchButton: true } : { compo: true, value: true }} customData={{ value: row?.data?.intensity, disabled: true }} /> : <CustomTypography content={'  Not Supported'} />)
          },
          style: { maxWidth: 180 }
        },
        temperature: {
          label: "Color Temperature",
          type: "component",
          component: TableHelper.TableSlider,
          component: (row) => {

            return (row?.data?.kelvin !== undefined ? <TableHelper.TableSlider customData={{ name: 'Temperature', suffix: "K", min: 2500, max: 6500, value: row?.data?.kelvin, disabled: true, customsx: { value: { color: "grey" }, compo: temperatureSliderCss } }} /> : <CustomTypography content={'  Not Supported'} />)
          },
          style: { maxWidth: 180 },
        },
        category: {
          label: "Category",
          type: "component",
          component: (row) => {
            const categoryLabel = deviceCategories?.find(c => c?.category === row?.data?.category)?.label;
            return <>{categoryLabel}</>
          }
        },
        status: {
          label: "Status",
          type: "component",
          component: (row) => {
            return (
              <TableHelper.TableStatus type={row?.data?.status} label={row?.data?.status === "active" ? "Active" : row?.data?.status === "inactive" ? "Inactive" : "Deleted"} />
            )
          },
        }
      })
    } else {
      setHeaders({
        name: {
          label: 'Groups',
          type: "component",
          component: ({ data }) => {
            return (
              <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                {data?.status !== 'deleted' ? <Link className="link" to={`/spaces/groups?id=${data?.groupId}`}>{`${data?.name && data?.name !== '' ? data?.name : data?.groupId}`}</Link> : <CustomTypography content={`${data?.name && data?.name !== "" ? data?.name : data?.groupId}`} />}
                {/* <Link className="link" to={`/spaces/groups?id=${data?.groupId}`}>{`${data?.name && data?.name!==''?data?.name : data?.groupId}`}</Link> */}
              </Box>
            );
          }
          // type: "link",
          // url: "/spaces/groups",
          // urlParams: { id: "groupId" }
        },
        intensity: {
          label: "Intensity",
          type: "component",
          component: (row) => {
            // its not that we show toggle when relay is in group type we show slider when analog or dali is present as toggle is applicable to dali and analog as well
            const groupType = row?.data?.groupType?.toString();
            const showSlider = groupType?.includes("LW_ANALOG") || groupType?.includes("LW_DT") || _.isEmpty(groupType);
            return <TableHelper.TableSlider params={{ switchButton: true, compo: showSlider, value: showSlider }} customData={{ value: row?.data?.intensity, disabled: "true" }} />
          },
          style: { maxWidth: 180 },
        },
        temperature: {
          label: "Color Temperature",
          type: "component",
          component: (row) => {
            return row?.data?.groupType?.includes("LW_DT8") ? <TableHelper.TableSlider customData={{ name: 'Temperature', suffix: "K", min: "2500", max: "6500", value: row?.data?.kelvin, disabled: "true", customsx: { value: { color: "grey" }, compo: temperatureSliderCss } }} /> : <CustomTypography content={'  Not Supported'} />
          },
          style: { maxWidth: 180 },
        },
        status: {
          label: "Status",
          type: "component",
          component: (row) => {
            return (
              <TableHelper.TableStatus type={row?.data?.status} label={row?.data?.status === "active" ? "Active" : row?.data?.status === "inactive" ? "Inactive" : "Deleted"} />
            )
          },
        },
        sync: {
          label: "Sync Status",
          type: "component",
          component: (row) => <TableHelper.TableStatus type={row?.data?.sync === "synced" ? "synced" : "needsSync"} variant={"filled"} label={row?.data?.sync === "synced" ? "Synced" : "Needs Sync"} />,
          style: {},
        },
      })
    }
  }

  const handleSync = (event) => {
    event.preventDefault();
    dispatch(
      sceneActions.syncById(sceneId)
    );
  }

  const filterDataFunc = () => {

  }
  const handleToggleDevice = (e, index) => {
    setCurrentIndex(index);
    let type = index === 1 ? 'groupId' : 'deviceId';
    let data = sceneDetails?.actions?.filter((e) => e.hasOwnProperty(type));
    settingUpHeaderList(index);
    setTableData(data);
  }
  const handleDelete = (event) => {
    dispatch(sceneActions.deleteScene(sceneId, () => {
      goTo("/spaces/scenes");
      setSceneDeleteTimeout(sceneDeleteTimeout, dispatch);
    }));

  }
  const handleConfirmation = (confirmed = false) => {
    if (confirmed) {
      openConfirmationPopup?.fun?.name(openConfirmationPopup?.fun?.params?.event, openConfirmationPopup?.fun?.params?.data);
      setOpenConfirmationPopup({ show: false, fun: null });
    } else {
      setOpenConfirmationPopup({ show: false, fun: null });
    }
  }
  return (
    <div className="SceneDetails" data-testid="SceneDetails">
      <PageHeader
        bckbtn={true}
        title={sceneDetails?.name}
        textTransform={'normal'}
        icon={sceneIcon}>
        <Grid container justifyContent="flex-end">
          {sceneDetails !== null && sceneDetails?.managedBy !== 'cloud' && !sceneDetails?.syncStatus && isAuthorised(features?.device, permissions?.syncScene) && (
            <StyledButton
              iconButton
              sx={{ p: 0, ml: 2 }}
              onClick={(event) => { handleSync(event) }}
              tooltip="Sync Scene"
            >
              <ImageWrapper width={18} height={18} src={Refresh} />
            </StyledButton>
          )}
          {isAuthorised(features?.device, permissions?.edit) &&
            <StyledButton
              tooltip="Edit scene"
              onClick={() => { goTo("/spaces/scenes?op=edit") }}
              iconButton
              sx={{ p: 0, ml: 2 }}
            >
              <ImageWrapper width={18} height={18} src={editIcon} />
            </StyledButton>

          }
          {isAuthorised(features?.device, permissions?.delete) &&
            <StyledButton
              onClick={() => setOpenConfirmationPopup({ show: true, fun: null })}
              iconButton
              tooltip="Delete Scene"
              sx={{ p: 0, ml: 2 }}
            >
              <ImageWrapper width={18} height={18} src={deleteIcon} />
            </StyledButton>
          }
          {isAuthorised(features?.device, permissions?.applyScene) &&
            // <IconButton type='icon' label='Apply' sx={{ padding: "5px", ml: 1, backgroundColor: "#FFFFFF" }} onMouseEnter={(e) => handleMouseEnter(e, 'Apply Scene')} className="pointer" onMouseLeave={handleMouseLeave} color='primary' mr={2}
            //   >
            // </IconButton>
            <StyledButton
              tooltip="Apply Scene"
              onClick={(event) => {
                event.preventDefault();
                applyScene(sceneDetails, dispatch, mqttClient, sceneDetails?.managedBy === "gateway" ? gateway : deviceData)
              }}
              iconButton
              sx={{ p: 0, ml: 2 }}
            >
              <ImageWrapper width={18} height={18} src={applyIcon} />
            </StyledButton>
          }
          {isAuthorised(features?.device, permissions?.add) &&
            <StyledButton
              onClick={() => goTo(`/spaces/rules?action=create&sceneId=${sceneId}`)}
              sx={{ ml: 2 }}
            >
              Create Rule
            </StyledButton>
          }
        </Grid>
      </PageHeader>
      <CustomAccordion title={"Scene Details"}>
        <ItemView data={sceneDetails} headers={sceneDetailsArray} />
      </CustomAccordion>
      <Box sx={{ mb: 2 }}>
        <StyledTabs
          tabs={tabsArray}
          onClick={(e, value) => {
            handleToggleDevice(e, value);
          }}
        />
      </Box>
      {currentIndex === 0 && <Box sx={{ py: 1, mb: 2 }}>
        <StyledTabs tabs={[{ icon: lampIcon, label: 'Lighting' }]} onClick={(event, data) => { filterDataFunc(event, data) }} />
      </Box>
      }
      <CustomTable
        headerList={headers}
        cellMatrix={tableData || []}
        pagination={true}
        sorting={true}
        enableScrollToTop={false}
      >
      </CustomTable>
      <StyledPopup open={openConfirmationPopup.show} onClose={() => setOpenConfirmationPopup({ show: false, fun: null })} state="timeout"
        data={{
          content: getMessageStr("scene-delete-confirmation", sceneDetails?.name),
          actions: [
            {
              id: "1002",
              type: "button",
              label: "Cancel",
              onClick: () => {
                handleConfirmation(false);
              }
            },
            {
              id: "1001",
              type: "button",
              label: "Confirm",
              onClick: (event) => {
                handleConfirmation(true);
                handleDelete(event);
              }
            }
          ]
        }} />

    </div>
  )
};

SceneDetails.propTypes = {};

SceneDetails.defaultProps = {};

export default SceneDetails;
