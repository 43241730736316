import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    FirebaseNotification: {

    },
    greenNotification: {
        filter: "invert(38%) sepia(21%) saturate(2239%) hue-rotate(101deg) brightness(101%) contrast(88%)"

    }


});
