/***
 *
 * Controller class for user.
 * @file SceneConfirmation.js
 * @description SceneConfirmation component
 * @author Shubham Singh
 * @since 12 Oct 2021
 * 
 */
/* WGJ */
import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import './SceneConfirmation.scss';
import StyledTabs from '../../components/StyledTabs';
import { Box, Grid, Stack } from '@mui/material';
import Group from '../../assets/icons/group.svg';
import ImageWrapper from '../../components/ImageWrapper';
import { useSelector } from "react-redux";
import CustomTable from '../../components/CustomTable';
import { TableHelper } from "../../components/TableHelper";
import { temperatureSliderCss } from '../SceneDetails/SceneDetails.style';
import CustomTypography from '../../components/CustomTypography';
import Light from '../../assets/icons/light.svg';
import { disableDevFeatures } from '../../config/appConfig';
import groupIcon from '../../assets/icons/group.svg';
import lampIcon from '../../assets/icons/lamp.svg';
import Device from '../../assets/icons/bulb.svg';
import _ from 'lodash';



//Set if the temperature or intensity slider should be disabled or not in a key and their default values as well
const addDisabilityAndDefault = (selectedDevices = [], deviceModels = []) => {
  if (selectedDevices && deviceModels) {
    let updatedSelectedDevices = selectedDevices.map(item => {

      item.settings = {}
      let objWithModelId = deviceModels.find(deviceModel => { return (deviceModel?.modelNumber !== undefined && deviceModel?.modelNumber?.substring(deviceModel.modelNumber?.length - 3, deviceModel.modelNumber?.length) === item?.configuration?.daliDriver) || (item?.modelId === deviceModel?.modelId) }) || {}
      for (let i in objWithModelId?.controlCapabilities) {
        if (objWithModelId?.controlCapabilities[i]?.capability === 'kelvin') {
          item.ifDisabledKelvin = false
        }
        if (objWithModelId?.controlCapabilities[i]?.capability === 'intensity') {
          item.ifDisabledIntensity = false;
          item.maxIntensity = objWithModelId?.controlCapabilities[i]?.attributes[0]?.range?.max[0]?.value
        }
      }
      if (!item.hasOwnProperty('ifDisabledKelvin')) {
        item.ifDisabledKelvin = (item?.hasOwnProperty('deviceId') ? true : !item?.groupType?.includes('LW_DT8'))
      }
      if (!item.hasOwnProperty('ifDisabledIntensity')) {
        item.ifDisabledIntensity = (item?.hasOwnProperty('deviceId') ? true : false)
        // if (item?.hasOwnProperty("groupId")) {
        //   item.maxIntensity = item?.groupType?.includes("LW_RELAY") ? 1 : 100;
        // }
      }
      if (!item.ifDisabledKelvin) {
        item.settings.kelvin = item?.hasOwnProperty('deviceId') ? (item?.deviceStatus?.status?.hasOwnProperty("kelvin") ? item?.deviceStatus?.status?.kelvin : 4000) : item?.groupStatus?.status?.hasOwnProperty("kelvin") ? item?.groupStatus?.status?.kelvin : 0
      }
      if (!item.ifDisabledIntensity) {
        item.settings.intensity = item?.hasOwnProperty('deviceId') ? (item?.deviceStatus?.status?.hasOwnProperty('intensity') ? item?.deviceStatus?.status?.intensity : 60) : item?.groupStatus?.status?.hasOwnProperty('intensity') ? item?.groupStatus?.status?.intensity : 0
      }

      return item
    })
    return updatedSelectedDevices
  }
  return selectedDevices

}

const CategoryColumn = ({ data }) => {
  const category = useSelector(state => state?.devices?.deviceCategories)?.find(c => c?.category === data?.category)?.label || 'No Category Specified'

  return (
    <CustomTypography content={`${category}`} />
  )
}

const ModelNumColumn = ({ data }) => {
  const [modelNum, setmodelNum] = useState('')
  const [modelId] = useState(data?.modelId || '')
  const deviceModels = useSelector(state => { return (state?.devices?.deviceModels || []) })
  const [objWithModelId, setobjWithModelId] = useState({})

  useEffect(() => {
    for (let i in deviceModels) {
      if (deviceModels[i]?.modelId === modelId) {
        setobjWithModelId(deviceModels[i])
        break;
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceModels])

  useEffect(() => {
    setmodelNum(objWithModelId?.modelNumber === undefined ? '-' : objWithModelId?.modelNumber)

  }, [objWithModelId])

  return (
    <CustomTypography content={`${modelNum}`} />
  )
}

const IconWithDevice = ({ data }) => {
  const [deviceHeading, setDeviceHeading] = useState(!disableDevFeatures?.itemId ? data?.deviceId : data?.name);
  const [groupHeading, setGroupHeading] = useState(!disableDevFeatures?.itemId ? data?.groupId : data?.name);

  useEffect(() => {
    if (!disableDevFeatures?.itemId) {
      setDeviceHeading(data?.deviceId);
      setGroupHeading(data?.groupId);
    }
    else {
      setDeviceHeading(data?.name);
      setGroupHeading(data?.name);
    }
  }, [data])


  return (
    <Stack spacing={0} direction="row">
      {/* <FluorescentOutlinedIcon fontSize='small' sx={{color: 'gray', marginRight: '2px', marginTop:'2px'}}/> */}
      <ImageWrapper src={data?.deviceId === undefined ? Group : Light} style={{ width: '14px', marginRight: '10px' }} />
      <CustomTypography content={`${data?.deviceId === undefined ? groupHeading : deviceHeading}`} />
    </Stack>
  )
}



const SceneConfirmation = ({ selectedDevices = [], setScene }) => {
  let currentSpaceId = useSelector((state) => { return state?.spaces?.selectedSpace?.spaceId });
  const [currentIndex, setCurrentIndex] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [groupsDevicesData, setGroupsDevicesData] = useState([]);
  const [tabsArray] = useState([{ icon: Device, label: 'Devices' }, { icon: groupIcon, label: 'Groups' }]);
  const [, setTabSelection] = useState('');
  const deviceModels = useSelector(state => { return (state?.devices?.deviceModels || []) })
  const [devicesHeader] = useState({
    deviceId: {
      label: "Devices",
      type: "component",
      component: ({ data }) => {
        return <IconWithDevice data={data} />
      }
    },
    category: {
      label: "Category",
      type: "component",
      component: ({ data }) => {
        return <CategoryColumn data={data} />
      },
      style: { minWidth: 220 }
    },
    modelNumber: {
      label: "Model Number",
      type: "component",
      component: ({ data }) => {
        return <ModelNumColumn data={data} />
      },
      style: { minWidth: 160 }
    },
    intensity: {
      label: "Intensity",
      type: "component",
      component: ({ data, onChange }) => {
        return (data?.ifDisabledIntensity ? <CustomTypography content={'  Not Supported'} /> : <TableHelper.TableSlider data={data} onChange={onChange} params={data?.maxIntensity === 1 ? { switchButton: true } : { compo: true, value: true }} customData={{ value: data?.settings?.intensity, name: 'Intensity', suffix: "%", min: 0, max: 100, customsx: { value: { color: "grey", minWidth: "44px" }, compo: { width: '140px' }, } }} />)
      },
      onChange: (e, val, data) => {
        setGroupsDevicesData(prev => {
          let objToBeChanged = prev.find(item => item?.deviceId === data?.deviceId)
          if (objToBeChanged !== undefined) {
            objToBeChanged.settings.intensity = val
            let newList = [...prev]
            return newList
          }
        })
      }
    },
    temperature: {
      label: "Color Temperature",
      type: "component",
      component: ({ data, onChange }) => {
        return (data?.ifDisabledKelvin ?
          <CustomTypography content={'  Not Supported'} />
          : <TableHelper.TableSlider data={data} onChange={onChange} customData={{ value: data?.settings?.kelvin, name: 'Temperature', suffix: "K", min: 2500, max: 6500, customsx: { value: { color: "grey", minWidth: "51px" }, compo: temperatureSliderCss, } }} />)
      },
      style: { minWidth: 200 },
      onChange: (e, val, data) => {
        setGroupsDevicesData(prev => {
          let objToBeChanged = prev.find(item => item?.deviceId === data?.deviceId)
          if (objToBeChanged !== undefined) {
            objToBeChanged.settings.kelvin = val
            let newList = [...prev]
            return newList
          }
        })
      }
    },
  })
  const [groupsHeader] = useState({
    groupId: {
      label: "Groups",
      type: "component",
      component: ({ data }) => {
        return <IconWithDevice data={data} />
      }
    },
    intensity: {
      label: "Intensity",
      type: "component",
      component: ({ data, onChange }) => {
        // its not that we show toggle when relay is in group type we show slider when analog or dali is present as toggle is applicable to dali and analog as well
        const groupType = data?.groupType?.toString();
        const showSlider = groupType?.includes("LW_ANALOG") || groupType?.includes("LW_DT") || _.isEmpty(data?.groupType);
        return (data?.ifDisabledIntensity ? <CustomTypography content={'Not Supported'} /> : <TableHelper.TableSlider params={{ switchButton: true, compo: showSlider, value: showSlider }} data={data} onChange={onChange} customData={{ value: data?.settings?.intensity, name: 'Intensity', suffix: "%", min: 0, max: 100, customsx: { value: { color: "grey", minWidth: '44px' }, compo: { width: '140px' }, } }} />)
      },
      onChange: (e, val, data) => {
        setGroupsDevicesData(prev => {
          let objToBeChanged = prev.find(item => item?.groupId === data?.groupId)
          if (objToBeChanged !== undefined) {
            objToBeChanged.settings.intensity = val
            let newList = [...prev]
            return newList
          }
        })
      }
    },
    temperature: {
      label: "Color Temperature",
      type: "component",
      component: ({ data, onChange }) => {
        return (data?.ifDisabledKelvin ? <CustomTypography content={'  Not Supported'} /> : <TableHelper.TableSlider data={data} onChange={onChange} customData={{ value: data?.settings?.kelvin, name: 'Temperature', suffix: "K", min: 2500, max: 6500, customsx: { value: { color: "grey", minWidth: "51px" }, compo: temperatureSliderCss, } }} />)
      },
      onChange: (e, val, data) => {
        setGroupsDevicesData(prev => {
          let objToBeChanged = prev.find(item => item?.groupId === data?.groupId)
          if (objToBeChanged !== undefined) {
            objToBeChanged.settings.kelvin = val
            let newList = [...prev]
            return newList
          }
        })
      }
    },
  })
  const [selectedData] = useState(() => {
    return {
      "spaceId": currentSpaceId,
      "name": selectedDevices?.name,
      "deviceGroupIds": selectedDevices?.map((e) => { return (e && e.deviceId === undefined ? e.groupId : e.deviceId) }),
      "deviceGroupDetails": addDisabilityAndDefault(selectedDevices, deviceModels)
    }
  });
  const [headers, setheaders] = useState(devicesHeader)

  useEffect(() => {
    handleGroupsDevicesData(null, 0, 'effect');
    filterDataFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let actionsArray = selectedData?.deviceGroupDetails?.map((e) => {
      let obj = { type: e.type, ifDisabledIntensity: e.ifDisabledIntensity, ifDisabledKelvin: e.ifDisabledKelvin, name: e.name }
      if (e?.settings?.intensity !== undefined) {
        obj.intensity = e?.settings?.intensity
      }
      if (e?.settings?.kelvin !== undefined) {
        obj.kelvin = e?.settings?.kelvin
      }
      if (e.deviceId) {
        obj.deviceId = e.deviceId
      } else {
        obj.groupId = e.groupId
      }
      return obj;
    });
    let payload =
    {
      spaceId: selectedData?.spaceId,
      name: selectedData?.name,
      actions: actionsArray
    }
    console.log('payload for sceme', groupsDevicesData, payload);
    setScene(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupsDevicesData])

  // useEffect(() => {
  //   console.log('groupsDevice',groupsDevicesData);
  // }, [groupsDevicesData])




  const settingUpHeaderList = (index) => {
    if (index === 0 && groupsDevicesData[0]?.deviceId !== undefined) {
      setheaders(devicesHeader)
    }
    else if (index === 1 && groupsDevicesData[0]?.deviceId === undefined) {
      setheaders(groupsHeader)
    }
  }

  useEffect(() => {
    settingUpHeaderList(currentIndex)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex])


  const filterDataFunc = (e, index) => {
    let type = (index === 0 ? 'Light' : (index === 1 ? 'Sensor' : (index === 2 ? 'Switch' : 'Reciever')));
    let filteredData = selectedData?.deviceGroupDetails?.filter((item) => item && item.category === type) || [];
    filteredData.forEach((e) => e.deviceType = type);
    // setTableData(filteredData);
  }
  const handleGroupsDevicesData = (e, index, comingFrom = 'tabsOnChange') => {
    let type = index === 1 ? 'groups' : 'devices';
    let typeCheck = index === 1 ? 'groupId' : 'deviceId';
    setTabSelection(type);
    selectedData?.deviceGroupDetails?.forEach((e) => {
      if (e.hasOwnProperty('groupId')) {
        e.type = 'GROUP_ACTION'
      } else {
        e.type = 'DEVICE_ACTION'
      }
      if (comingFrom !== 'tabsOnChange') {
        if (e.intensity !== undefined) {
          e.settings.intensity = e.intensity
        }
        if (e.kelvin !== undefined) {
          e.settings.kelvin = e.kelvin
        }
      }
    })
    let filteredData = selectedData?.deviceGroupDetails?.filter((item) => item && item.hasOwnProperty(typeCheck)) || [];
    filteredData?.forEach((e) => { e.deviceType = type; })
    let newFiltered = [...filteredData]
    console.log(newFiltered, 'tableData');
    setGroupsDevicesData(newFiltered);
    setTableData(newFiltered);
    // if (comingFrom === 'tabsOnChange') {
    //   setTableData(newFiltered);
    // }
  }


  useEffect(() => {
    setCurrentIndex(groupsDevicesData[0]?.deviceId === undefined ? 1 : 0);
  }, [groupsDevicesData])

  return (
    <div className="SceneConfirmation" data-testid="SceneConfirmation">
      <Box sx={{ py: 1, mb: 2 }}>
        <StyledTabs tabs={tabsArray} onClick={(event, data) => { handleGroupsDevicesData(event, data) }} />
      </Box>

      {groupsDevicesData?.length > 0 && currentIndex === 0 &&
        <Box sx={{ py: 1, mb: 2 }}>
          <StyledTabs tabs={[{ icon: lampIcon, label: 'Lighting' }]} onClick={(event, data) => { filterDataFunc(event, data) }} />
        </Box>
      }

      <Grid xs={12} container>
        <Grid item xs={12} sx={{ pr: '8', padding: "15px" }}>
          <CustomTable
            headerList={headers}
            cellMatrix={tableData || []}
            pagination={false}
            sorting={true}
            enableScrollToTop={false}
          />
        </Grid>
      </Grid>

    </div>
  )
};

SceneConfirmation.propTypes = {};

SceneConfirmation.defaultProps = {};

export default SceneConfirmation;
