/***
 *
 * Controller class for user.
 * @file TicketDetails.js
 * @description TicketDetails component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import { React, useState, useEffect, useRef } from 'react';
import { useStyles } from './TicketDetails.style.js';
import './TicketDetails.scss';
import _ from 'lodash';
import { Box, Grid, IconButton, Typography, Stack, Chip, Tooltip } from '@mui/material';
import PageHeader from '../PageHeader/index.js';
import FormWrapper from '../FormWrapper/index.js';
import DontNotifyMe from '../../assets/icons/dont-notify.svg';
import Resolve from '../../assets/icons/resolve.svg';
import ShareIcon from '../../assets/icons/share.svg'
import Notify from '../../assets/icons/notify.svg'
import { useDispatch, useSelector } from "react-redux";
import { deviceActions, ticketingActions, userActions } from '../../redux/actions';
import { ReactComponent as AttachmentIcon } from '../../assets/icons/attachment.svg';
import { ReactComponent as Delete } from '../../assets/icons/delete.svg';
import Timeline from '@mui/lab/Timeline';
import { useQuery } from '../../hooks';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import StyledPopup from '../StyledPopup/index.js';
import CustomTypography from '../CustomTypography/index.js';
import TicketIcon from '../../assets/icons/ticketing.svg';
import { getMessageStr } from '../../helpers/message.helper.js';
import Comments from '../Comments/index.js';
import { formatedDate } from '../../helpers/date.helper.js';
import StyledPopupWrapper from '../StyledPopupWrapper/index.js';
import { isAuthorised } from '../../helpers/users.helper.js';
import { features, permissions } from '../../constants/permission.constants.js';
import StyledButton from '../StyledButton/index.js';
import { ticketEntityOptions, ticketFormConfig } from '../../constants/ticket.constants.js';
import { deviceIcons } from '../../constants/device.constants.js';

const TicketDetails = () => {
  const classes = useStyles()
  const query = useQuery();
  const actionButtonsRef = useRef();
  const uploadRef = useRef(null);
  const dispatch = useDispatch();
  const assigneeData = useSelector(state => state?.ticketing?.assigneeData);
  const attachmentData = useSelector(state => state?.ticketing?.attachmentData);
  let spaces = useSelector(state => state?.spaces?.list);
  const devices = useSelector(state => state?.devices?.deviceList);
  const users = useSelector(state => state?.users?.list);
  const commentAttachmentData = useSelector(state => state?.ticketing?.commentAttachmentData);
  const ticketId = query.get("ticketId") || null;
  const [formData, setFormData] = useState({});
  const [shareTicketormData, setShareTicketFormData] = useState({});
  const [frmOptions, setFrmOptions] = useState(null);
  const [shareTicketFrmOptions, setShareTicketFrmOptions] = useState(null);
  const [frm, setFrm] = useState(null);
  const [shareTicketFrm, setShareTicketFrm] = useState(null);
  const [notifyme, setNotifyme] = useState(true)
  const ticketDetail = useSelector((state) => state?.ticketing?.ticketDetails)
  const commentData = useSelector((state) => state?.ticketing?.postComment)
  const userProfile = useSelector(state => state?.users?.userSelfProfile);
  const entityValues = useSelector(state => state?.ticketing?.entityTypes);
  const severities = useSelector(state => state?.ticketing?.severities);
  const states = useSelector(state => state?.ticketing?.states);
  const [activities, setActivities] = useState([])
  const activityList = [{ id: "newState", val: "New State" }, { id: "newAssignee", val: "New Assignee" }]
  const [comments, setComments] = useState([])
  const [commentIdx, setCommentIdx] = useState(null)
  const [attachments, setAttachments] = useState([])
  const [editedComment, setEditedComment] = useState("")
  const [openDeletePopup, setOpenDeletePopup] = useState({ open: false, tag: null, data: null });
  const [operation, setOperation] = useState("view")
  const [fileObj, setFileObj] = useState(null)
  const [openPopup, setOpenPopup] = useState(false);
  const [currentFrmState, setCurrentFrmState] = useState(null)
  const [isDuplicate, setIsDuplicate] = useState(false)

  const [buttons, setButtons] = useState([
    { img: notifyme ? DontNotifyMe : Notify, btnText: notifyme ? "Dont Notify Me" : "Notify Me", btn: "notify", disabled: false },
    { img: Resolve, btnText: "Resolve", btn: "resolved", disabled: false },
    { img: ShareIcon, btnText: "Share Ticket", btn: "share", disabled: assigneeData?.length === 0, tooltipText: getMessageStr("ticket-share-noAssignee") }
  ])
  const [ticketsData, setTicketsData] = useState({
    createdBy: {
      id: "createdBy",
      ticketHeader: "Created By",
      value: ""
    },
    createdOn: {
      id: "createdOn",
      ticketHeader: "Created On",
      value: ""
    },
    lastUpdatedOn: {
      id: "lastUpdatedOn",
      ticketHeader: "Last Updated On",
      value: ""
    },
  })

  const [shareTicketFrmConfig, setShareTicketFrmConfig] = useState([
    {
      section: { heading: null },
      columns: [
        {
          id: "1",
          type: "chipinput",
          componentType: "select",
          label: "Add Subscribers",
          name: "subscribers",
          addBtn: true,
          addBtnStyle: { marginRight: "0px" },
          width: "100%",
          rounded: true,
          chipsPosition: "bottom",
          columnSize: 12,
          visibleIn: ["edit", "view", "create"],
          onDelete: (subs) => {
            setOpenDeletePopup({ open: true, tag: "Subscriber", data: subs })
          }
        },

      ]
    }])

  const [actionButtons] = useState([
    {
      heading: null,
      columns: [
        {
          heading: null,
          buttons: [
            {
              id: "bckbtn",
              type: "button",
              display: true,
              variant: 'outlined',
              label: "Cancel",
              onClick: (event, data) => {
                setOpenPopup(false);
              }
            },
            {
              id: "savebtn",
              type: "button",
              display: true,
              checkValidation: true,
              label: 'Save',
              onClick: (event, data) => {
                handleSubscribers(data)
                setOpenPopup(false);
              },
            },
          ],
          columnSize: 12,
        },
      ],
    },
  ]);
  const [frmConfig, setFrmConfig] = useState(ticketFormConfig("ticketDetails"));
  const formatActivities = (actArray = []) => {
    let logs = []
    actArray.map((activity) => {
      activityList.map((ac) => {
        if (Object.keys(activity).includes(ac.id)) {
          logs.push({
            ...activity,
            task: `Tickets ${ac.val} ${ac.id === "newAssignee" ? assigneeData?.find(assignee => assignee?.id === activity[ac?.id]) ? assigneeData?.find(assignee => assignee?.id === activity[ac?.id])?.name : "-" : activity[ac?.id]}`,
            userName: activity ? activity.userName : "UserName"
          })
        }
      })
    })
    logs.reverse()
    setActivities(logs)
  }

  const handleFileChange = (event, type) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    event.target.value = null;

    if (type === "ticket") {
      dispatch(ticketingActions.addAttachments(ticketId, fileObj))
      setTimeout(() => {
        dispatch(ticketingActions.getTicketDetails(ticketId))
      }, 500);
    }
    if (type === "comment") {
      setFileObj(fileObj)
    }
  };

  const handleDeleteAttachment = (attachment) => {
    Promise.resolve().then(() => {
      dispatch(ticketingActions.deleteAttachment(ticketId, attachment.id))
    }).then(() => {
      setOpenDeletePopup({ ...openDeletePopup, open: false, data: null })
      setTimeout(() => {
        dispatch(ticketingActions.getTicketDetails(ticketId))
      }, 500);
    })
  }

  const handleDeleteCommentAttachment = (comment, attachment) => {
    Promise.resolve().then(() => {
      dispatch(ticketingActions.deleteCommentAttachment(ticketId, comment?.commentId, attachment?.id))
    }).then(() => {
      setOpenDeletePopup({ ...openDeletePopup, open: false, data: null })
      setTimeout(() => {
        dispatch(ticketingActions.getTicketDetails(ticketId))
      }, 500);
    })
  }

  const handleUpload = () => {
    uploadRef.current.click();
  };

  const doComment = (comment) => {
    Promise.resolve().then(() => {
      dispatch(ticketingActions.postComment(ticketId, { description: comment }))
    })
    setTimeout(() => {
      dispatch(ticketingActions.getTicketDetails(ticketId))
    }, 1000);
  }

  const removeSubscibers = (data) => {
    let payload = {}, subs = [], assignees = assigneeData
    if (data) {
      assignees?.forEach((user) => {
        // console.log("Hereee112", user?.name, data?.id)
        if (user?.name === data?.id) {
          subs.push(user?.id)
        }
      });
    }
    payload["userIds"] = subs
    dispatch(ticketingActions.unsubscribeTicket(ticketId, payload))
    setOpenDeletePopup({ ...openDeletePopup, open: false, data: null })
    setTimeout(() => {
      dispatch(ticketingActions.getTicketDetails(ticketId))
    }, 500);
  }

  const ticketAction = (event, action, actionType = "") => {
    if (action === "notify") {
      setNotifyme(!notifyme)
      let payload = {}
      let emails = [userProfile?.userId]
      payload["userIds"] = emails
      actionType === "Notify Me" ? dispatch(ticketingActions.subscribeTicket(ticketId, payload)) : dispatch(ticketingActions.unsubscribeTicket(ticketId, payload))
      setTimeout(() => {
        dispatch(ticketingActions.getTicketDetails(ticketId))
      }, 500);
    }
    if (action === "resolved" || action === "open" || action === "closed" || action === "assigned") {
      let payload = {}
      payload["state"] = action
      dispatch(ticketingActions.updateTicket(ticketId, payload))
    }

    if (action === "share") {
      setOpenPopup(true)
    }
  }

  const updateTicket = () => {
    let payload = ["state", "severity", "description", "synopsis", "assignedTo", "entityType", "ticketType"].reduce((acc, field) => {
      if (frm?.hasOwnProperty(field) && ticketDetail[field] !== frm[field]) {
        acc[field] = frm[field];
      }
      return acc;
    }, {});

    payload = ['rejectReason', 'duplicateTicketId'].reduce((acc, field) => {
      if (frm?.hasOwnProperty(field) && ticketDetail[field] !== frm[field] && frm[field]?.length > 0) {
        acc[field] = frm[field];
      }
      return acc;
    }, payload);

    payload = ["space", "device", "user"].reduce((acc, field) => {
      if (frm?.hasOwnProperty(field) && ticketDetail[field + "Id"] !== (field === "space" ? frm[field][0]?.id : field === 'device' ? frm[field]?.id : frm[field])) {
        acc[field + "Id"] = field === "space" ? frm?.space[0]?.id : field === 'device' ? frm[field]?.id : frm[field];
      }
      return acc;
    }, payload);
    console.log("mervin", payload, frm)
    dispatch(ticketingActions.updateTicket(ticketId, payload, () => {
      setOperation("view")
    }))
  }

  const handleEditComment = (comment, idx) => {
    setCommentIdx(idx)
    setEditedComment(comment.description)
  }

  const handleDeleteComment = (comment, idx) => {
    Promise.resolve().then(() => {
      dispatch(ticketingActions.deleteComment(ticketId, comment.commentId))
      setCommentIdx(null)
      setOpenDeletePopup({ ...openDeletePopup, open: false, data: null })
    }).then(() => {
      setTimeout(() => {
        dispatch(ticketingActions.getTicketDetails(ticketId))
      }, 500);
    })
  }

  const postEditedComment = (comment, idx) => {
    Promise.resolve().then(() => {
      dispatch(ticketingActions.editComment(ticketId, comment.commentId, { description: editedComment }))
    }).then(() => {
      setTimeout(() => {
        dispatch(ticketingActions.getTicketDetails(ticketId))
      }, 500);
      setCommentIdx(null)
    })
  }

  const mapSpacesToNames = (val) => {
    const options = (val) ? val?.map(v => {
      const sp = spaces.find(s => s?.spaceId === v);
      return { id: sp?.spaceId, label: sp?.name };
    }) : [];
    return options;
  }

  const cancelEdit = () => {
    if (ticketDetail) {
      let initialData = ["spaceId", "deviceId", "userId"].reduce((acc, field) => {
        if (acc?.hasOwnProperty(field)) {
          acc[field?.substring(0, field?.length - 2)] = field === 'spaceId' ? [{ id: acc[field] }] : field === "deviceId" ? { id: acc[field], name: devices?.find(d => d?.deviceId === acc[field])?.name || "" } : acc[field];
        }
        return acc;
      }, JSON.parse(JSON.stringify(ticketDetail)));
      setFormData(initialData);
      if (initialData?.ticketType === "devices") {
        const config = JSON.parse(JSON.stringify(frmConfig));
        if (config[1].columns[3]) {
          config[1].columns[3].disabled = false
        }
        setFrmConfig(config);
      }
      setOperation("view");
    }
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handleSubscribers = (data) => {
    let subs = [];
    if (data && data.subscribers) {
      data?.subscribers?.forEach((sub) => {
        assigneeData?.forEach((user) => {
          if (user?.name === sub) subs.push(user?.id);
        });
      });
    }
    dispatch(ticketingActions.subscribeTicket(ticketId, { userIds: subs }))
    setTimeout(() => {
      dispatch(ticketingActions.getTicketDetails(ticketId))
    }, 500)
  }

  const removeImage = () => { setFileObj(null) }

  const getCommentAttachment = (comment, attachment) => {
    dispatch(ticketingActions.getCommentAttachment(ticketId, comment?.commentId, attachment?.id))
  }

  const getButton = (button) => {
    return <Box sx={{ display: "flex" }}>
      <Box sx={{ pr: 1 }}>
        <img className='sideButton' src={button.img} alt="button" />
      </Box>
      <CustomTypography className='btnText' content={button.btnText} />
    </Box>
  }

  useEffect(() => {
    let opt = {};
    const typeList = [];
    Object.keys(entityValues).forEach((entity) => {
      if (entity === "devices" || entity === "users" || entity === "spaces") {
        if (entity === "devices") {
          isAuthorised("device", permissions?.view) && isAuthorised("space", permissions?.view) && typeList?.push({ id: entity, label: entity.charAt(0).toUpperCase() + entity.slice(1) })
        }
        else {
          isAuthorised(features[entity === "devices" ? "device" : entity === "users" ? "user" : "space"], permissions?.view) && typeList?.push({ id: entity, label: entity.charAt(0).toUpperCase() + entity.slice(1) })
        }
      }
      else {
        typeList?.push({ id: entity, label: entity.charAt(0).toUpperCase() + entity.slice(1) })
      }
    })
    opt["dropdowns"] = {
      severity: { list: severities && severities.length > 0 ? severities.map((severity) => { return { id: severity, label: capitalizeFirstLetter(severity) } }) : [] },
      rejectReason: { list: [{ id: "duplicate", label: "Duplicate" }, { id: "nonReproducible", label: "Non Reproducible" }, { id: "others", label: "Others" },] },
      state: { list: states && states.length > 0 ? states.filter((state) => { return state !== "in-progress" }).map((state) => { return { id: state, label: capitalizeFirstLetter(state) } }) : [] },
      assignedTo: { list: assigneeData.map((assignee) => { return { id: assignee?.id, label: assignee?.name } }) },
      ticketType: { list: typeList },
      space: {
        list: spaces && spaces.length > 0 && spaces[0] && spaces[0].Spaces ? spaces[0].Spaces.map(sp => { return { id: sp.spaceId, label: sp.name } }) : [],
      },
      user: {
        list: users?.map(u => { return { id: u?.userId, label: u?.name } })
      },
      device: {
        list: !_.isEmpty(frm?.space) ? _.isEmpty(devices) ? [{ name: 'No Item Available' }] : devices?.map(d => { return { id: d?.deviceId, name: d?.name, icon: deviceIcons[d?.category] || null } }) : [{ name: 'No Item Available' }]
      },
      entityType: { list: frm?.ticketType ? entityValues[frm?.ticketType]?.map((val) => { return { id: val, label: capitalizeFirstLetter(val) } }) : [] }

    };
    setFrmOptions(opt);
  }, [entityValues, devices, users, spaces])

  useEffect(() => {
    if (!_.isEmpty(assigneeData)) {
      let opt = {};
      opt["dropdowns"] = {
        subscribers: {
          list: assigneeData?.map((assignee) => { return { id: assignee?.name, label: assignee?.name } }),
        }
      }
      setShareTicketFrmOptions(opt);
    }
  }, [assigneeData])

  useEffect(() => {
    if (!_.isEmpty(frm)) {
      let config = JSON.parse(JSON.stringify(frmConfig)) || [];
      const frmData = JSON.parse(JSON.stringify(frm));
      if (frm && frm.state && frm.state === "rejected") {
        if (currentFrmState !== "rejected") {
          //add rejected dropdown
          setCurrentFrmState(frm.state)
          config[3]?.columns?.push({
            id: "8",
            type: "select",
            label: "Reject Reason",
            name: "rejectReason",
            placeholder: "Enter",
            list: [],
            columnSize: 6,
          });
          frmData.rejectReason = '';
        }

        else if (frm && frm.rejectReason && frm.rejectReason === "duplicate") {
          //add duplicate dropdown
          if (!isDuplicate) {
            setIsDuplicate(true)
            config[3]?.columns?.push({
              id: "32",
              type: "text",
              label: "TicketId",
              name: "duplicateTicketId",
              placeholder: "Enter",
              list: [],
              columnSize: 6,
            },)
            frmData.duplicateTicketId = '';
          }
        } else {
          //remove duplicate ticket id dropdown
          config[3].columns = config[3]?.columns?.filter(c => c?.name !== "duplicateTicketId");
          frmData.duplicateTicketId = '';
          setIsDuplicate(false)
        }
      } else {
        // let filteredFrmConfig = []
        // for (let i = 0; i < frmConfig[0]?.columns.length; i++) {
        //   if (frmConfig[0]?.columns[i].name === "rejectReason" || frmConfig[0]?.columns[i].name === "duplicateTicketId") {
        //   } else {
        //     filteredFrmConfig.push(frmConfig[0]?.columns[i])
        //   }
        // }
        //remove reject reason and duplicate
        config[3].columns = config[3]?.columns?.filter(c => c?.name !== "rejectReason" && c?.name !== "duplicateTicketId")
        frmData.duplicateTicketId = '';
        frmData.rejectReason = '';
        setIsDuplicate(false)
        setCurrentFrmState(frm.state)
      }

      setFrmConfig(config);
      setFormData(frmData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frm?.rejectReason, frm?.state])

  useEffect(() => {
    if (frm && frm?.ticketType) {
      let config = JSON.parse(JSON.stringify(frmConfig)) || [];
      const frmData = JSON.parse(JSON.stringify(frm));
      if (operation === 'edit') {
        frmData.entityType = '';
        frmData.space = [{ id: "" }];
        frmData.device = { id: "", name: "" };
        frmData.user = "";
      }
      setFrmOptions({ ...frmOptions, dropdowns: { ...frmOptions?.dropdowns, entityType: { list: entityValues[frm?.ticketType].map((val) => { return { id: val, label: capitalizeFirstLetter(val) } }) } } });
      if (frm?.ticketType === "devices" || frm?.ticketType === "spaces" || frm?.ticketType === "users") {
        if (frm?.ticketType === 'devices') {
          const entityField = JSON.parse(JSON.stringify(ticketEntityOptions["spaces"]));
          entityField.columnSize = 6;
          entityField.id = "3";
          entityField.label = "Parent Space of Device"
          config[1].columns[2] = (entityField);
        } else {
          delete config[1].columns[3]
        }
        const entityField = JSON.parse(JSON.stringify(ticketEntityOptions[frm?.ticketType]));
        entityField.columnSize = 6
        entityField.id = "4"
        if (frm?.ticketType === 'devices') {
          entityField.disabled = operation === 'edit'
        }
        config[1].columns[frm?.ticketType === 'devices' ? 3 : 2] = (entityField);
      }
      else {
        //remove entity dropdown
        config[1].columns = config[1]?.columns?.filter(c => c?.name !== "device" && c?.name !== "user" && c?.name !== "space")
      }
      setFrmConfig(config);
      setFormData(frmData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frm?.ticketType])

  useEffect(() => {
    if (frm?.ticketType === 'devices' && frmConfig) {
      frm?.space && !_.isEmpty(frm?.space[0]?.id) && dispatch(deviceActions?.getDevicesBySpaceId(frm?.space[0]?.id));
      if (operation === 'edit') {
        setFormData({ ...frm, device: { id: "", name: "" } });
        setFrmConfig(JSON.parse(JSON.stringify(frmConfig))?.map((c, i) => {
          if (i === 1) {
            c.columns[3].disabled = frm?.space && !_.isEmpty(frm?.space[0]?.id) ? false : true
          }
          return c;
        }));
      }
    }
  }, [frm?.space && frm?.space[0]?.id])

  useEffect(() => {
    dispatch(ticketingActions.getTicketTypes())
    dispatch(ticketingActions.getAssignees())
    dispatch(ticketingActions.getTicketSeverities())
    dispatch(ticketingActions.getTicketStates())
    dispatch(ticketingActions.getTicketDetails(ticketId))
    isAuthorised(features?.user, permissions?.view) && dispatch(userActions?.getAll(0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(() => {
    // let initialData = {
    //   severity: ticketDetail?.severity,
    //   state: ticketDetail?.state,
    //   description: ticketDetail?.description,
    //   synopsis: ticketDetail?.synopsis,
    //   assignedTo: ticketDetail?.assignedTo,
    //   entityType: ticketDetail?.entityType,
    //   ticketType: ticketDetail?.ticketType,
    // }
    formatActivities(ticketDetail?.activities)

    let initialData = ["spaceId", "deviceId", "userId"].reduce((acc, field) => {
      if (acc?.hasOwnProperty(field)) {
        acc[field?.substring(0, field?.length - 2)] = field === 'spaceId' ? [{ id: acc[field] }] : field === 'deviceId' ? {
          id: acc[field]
          , name: devices?.find(d => d?.deviceId === acc[field])?.name || ''
        } : acc[field];
      }
      return acc;
    }, JSON.parse(JSON.stringify(ticketDetail)));
    setFormData(initialData);
    setShareTicketFormData({ subscribers: ticketDetail?.subscribers ? ticketDetail?.subscriberNames : null })
    setButtons([
      { img: (ticketDetail?.subscribers?.includes(userProfile?.userId)) ? DontNotifyMe : Notify, btnText: (ticketDetail?.subscribers?.includes(userProfile?.userId)) ? "Dont Notify Me" : "Notify Me", btn: "notify", disabled: false },
      { img: Resolve, btnText: ticketDetail?.state === "resolved" ? "Close" : ticketDetail?.state === "closed" ? ticketDetail?.assignedTo ? "Re-Assign" : "Re-Open" : "Resolve", btn: ticketDetail?.state === "resolved" ? "closed" : ticketDetail?.state === "closed" ? ticketDetail?.assignedTo ? "assigned" : "open" : "resolved", disabled: false },
      { img: ShareIcon, btnText: "Share Ticket", btn: "share", disabled: assigneeData?.length === 0, tooltipText: getMessageStr("ticket-share-noAssignee") }
    ])
    if (ticketDetail?.subscribers && userProfile?.email) {
      setNotifyme(ticketDetail?.subscribers?.includes(userProfile?.email))
    }
    setAttachments(ticketDetail?.attachments || [])
    setTicketsData({
      ...ticketsData,
      createdBy: { ...ticketsData.createdBy, value: ticketDetail?.createdByUserName },
      createdOn: { ...ticketsData.createdOn, value: ticketDetail && ticketDetail?.createdAt ? formatedDate({ date: ticketDetail.createdAt, format: "MMM d, yyyy" }) : "" },
      lastUpdatedOn: { ...ticketsData.lastUpdatedOn, value: ticketDetail && ticketDetail?.lastModifiedAt ? formatedDate({ date: ticketDetail.lastModifiedAt, format: "MMM d, yyyy" }) : "" },
    })
    setComments(ticketDetail && ticketDetail.comments && ticketDetail.comments.length > 0 ? ticketDetail?.comments.reverse() : [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketDetail])

  useEffect(() => {
    if (operation === 'view') {
      const device = devices?.find(d => d?.deviceId === ticketDetail?.deviceId)
      setFormData({ ...frm, device: { id: device?.deviceId, name: device?.name } })
    }
  }, [devices])

  useEffect(() => {
    setTimeout(() => {
      if (fileObj) {
        let lastComment = commentData ? commentData.comments ? commentData.comments[commentData.comments.length - 1] : [] : []
        dispatch(ticketingActions.addAttachmentsToComment(ticketId, lastComment?.commentId, fileObj))
        setTimeout(() => {
          dispatch(ticketingActions.getTicketDetails(ticketId))
        }, 500)
        setFileObj(null)
      }
    }, 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentData])

  const customSx = {
    dialog: {
      "& .MuiDialog-paper": { width: "500px" },
      "& .css-1t5rjz-MuiPaper-root-MuiDialog-paper": { maxWidth: "800px" },
    },
  };


  return (
    <Box className={classes?.TicketDetails || ""} data-testid="TicketDetails">

      <PageHeader icon={TicketIcon} bckbtn={true} title={!_.isEmpty(formData) && formData?.synopsis ? `${ticketId} - ${formData?.synopsis}` : ""} >
        {operation === "edit" ?
          <>
            <StyledButton sx={{ mr: 1, minWidth: "90px" }} onClick={(e) => actionButtonsRef?.current?.handleButton(e, {
              checkValidation: true,
              onClick: (event, data) => {
                updateTicket()
              }
            })}>Save</StyledButton>
            <StyledButton variant="outlined" onClick={cancelEdit}>Cancel</StyledButton>
          </>
          :
          isAuthorised(features?.ticket, permissions?.edit) && <StyledButton color='primary' sx={{ mr: 1, minWidth: "90px" }} onClick={() => { setOperation("edit") }} >Edit</StyledButton>}
      </PageHeader>

      <Grid container item xs={12} spacing={4}>
        <Grid item lg={7} xs={12}>
          <FormWrapper
            formFields={frmConfig}
            formOptions={frmOptions}
            setPayload={setFrm}
            formData={formData}
            operation={operation}
            ref={actionButtonsRef}
          />
          <Grid>
            {attachments?.length > 0 && (
              <>
                <CustomTypography className='attachment' content={' Attached Files'} />
                {attachments.map((attachment) => (
                  <Chip
                    key={attachment.id}
                    variant="outlined"
                    color="primary"
                    label={attachment.fileName}
                    sx={{ marginRight: "10px", marginBottom: "10px" }}
                    onClick={(e) => {
                      ticketingActions.getAttachment(ticketId, attachment.id).then(({ data }) => {
                        if (data && !_.isEmpty(data)) {
                          const link = document.createElement("a");
                          link.href = data?.signedUrl;
                          link.setAttribute("download", data?.fileName);
                          document.body.appendChild(link);
                          link.click();
                        }
                      })

                    }}
                    deleteIcon={<Delete />}
                    readOnly={operation === "edit"}
                    onDelete={operation === "edit" ? () => setOpenDeletePopup({ open: true, tag: "Attachment", data: { attachment }, }) : undefined}
                  />
                ))}</>)}
            {
              operation === "edit" &&
              <IconButton
                edge="end"
                aria-label="Attach file"
                onClick={(e) => { handleUpload() }}
              ><input
                  style={{ display: 'none' }}
                  ref={uploadRef}
                  type="file"
                  onChange={(e) => { handleFileChange(e, "ticket") }}
                  accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                />
                <AttachmentIcon />
                <CustomTypography className='ticketAttachment' content={"Attach Files"} />
              </IconButton>
            }
          </Grid>
        </Grid>
        <Grid item lg={3} xs={6}>
          <Box className='imgDiv'>
            <Typography variant='h6' fontWeight={600} p={1}>
              Logs
            </Typography>
            <Box sx={{ height: "210px", overflow: "auto" }}>
              {activities?.map((activity, idx) => {
                return (
                  <Timeline className='timeline'>
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot style={{ backgroundColor: idx === 0 ? "green" : "white", margin: "0" }} />
                        <TimelineConnector style={{ backgroundColor: idx === 0 ? "green" : "gray" }} />
                      </TimelineSeparator>
                      <TimelineContent sx={{ display: "flex" }}>
                        <Stack mr={2} maxWidth={"50px"}>
                          <CustomTypography fontSize={"12px"} content={formatedDate({ date: activity.ts, format: "dd MMM yyyy" })} />
                          <CustomTypography fontSize={"12px"} content={formatedDate({ date: activity.ts, format: "hh:mm aaaaa'm'" })} />
                        </Stack>
                        <Stack>
                          <CustomTypography fontSize={"12px"} content={activity.task} />
                          <CustomTypography fontSize={"12px"} content={activity.userName ? "By " + activity.userName : "User"} />
                        </Stack>
                      </TimelineContent>
                      <TimelineOppositeContent display={"none "}>
                      </TimelineOppositeContent>
                    </TimelineItem>
                  </Timeline>
                )
              })}
            </Box>
          </Box>
        </Grid>
        {isAuthorised(features?.ticket, permissions?.notify) &&
          <Grid item lg={2} xs={6} sx={{ marginTop: "44px" }}>
            {buttons?.map((button, index) => {
              return (
                <Grid className={'btnGrid' + (button?.disabled ? "Disabled" : "")} key={'action-btn' + index} onClick={(e) => (!button?.disabled) && ticketAction(e, button.btn, button.btnText)} >
                  {
                    button?.disabled ?
                      <Tooltip title={button?.tooltipText} arrow slotProps={{
                        popper: {
                          modifiers: [
                            {
                              name: 'offset',
                              options: {
                                offset: [0, -7],
                              },
                            },
                          ],
                        },
                      }}>
                        {getButton(button)}
                      </Tooltip> :
                      getButton(button)
                  }
                </Grid>
              )
            })}
          </Grid>
        }
      </Grid>
      <Box>
        <Box className='ticketSubHeading'>
          <Typography className='ticketSubHeadingTypo' variant="h5" > Details </Typography>
        </Box>
        <Box className='ticketsDetails'>
          {Object.keys(ticketsData).map((id, index) => {
            return (
              <Box key={'box-' + index}>
                <Typography className='btnText'>{ticketsData[id].ticketHeader}</Typography>
                <Typography className='detailsText'>
                  {ticketsData[id].value}
                </Typography>
              </Box>
            )
          })}
        </Box>
      </Box>
      <Box>
        <Comments
          ticketId={ticketId}
          comments={comments}
          handleFileChange={handleFileChange}
          fileObj={fileObj}
          commentIdx={commentIdx}
          doComment={doComment}
          removeImage={removeImage}
          handleEditComment={handleEditComment}
          postEditedComment={postEditedComment}
          openDeletePopup={setOpenDeletePopup}
          setCommentIdx={setCommentIdx}
          getCommentAttachment={getCommentAttachment}
          editedComment={editedComment}
          setEditedComment={setEditedComment}
        />

        <StyledPopupWrapper title={"Share Ticket"} open={openPopup} closeButton={false} customsx={customSx}>

          <FormWrapper
            formFields={shareTicketFrmConfig}
            formOptions={shareTicketFrmOptions}
            setPayload={setShareTicketFrm}
            formData={shareTicketormData}
            formButtons={actionButtons}
          />

        </StyledPopupWrapper>

        <StyledPopup open={openDeletePopup?.open} onClose={() => setOpenDeletePopup({ open: false, data: null, tag: null })} state="timeout"
          data={{
            title: `Delete ${openDeletePopup?.tag}?`,
            content: `Are you sure you want to delete this ${openDeletePopup?.tag} ?`,
            label: 'Warning',
            actions: [
              {
                id: "1001",
                type: "button",
                label: "Cancel",
                styles: { minWidth: 100 },
                onClick: (event, data) => {
                  setOpenDeletePopup({ open: false, tag: null, data: null });
                  setCommentIdx(null)
                }
              }, {
                id: "1001",
                type: "button",
                label: "OK",
                styles: { minWidth: 100 },
                onClick: (event, data) => {
                  if (openDeletePopup?.tag && openDeletePopup.tag === "comment")
                    handleDeleteComment(commentIdx)

                  if (openDeletePopup?.tag && openDeletePopup.tag === "Comment Attachment")
                    handleDeleteCommentAttachment(openDeletePopup?.data?.comment, openDeletePopup?.data?.attachment)

                  if (openDeletePopup?.tag && openDeletePopup.tag === "Attachment")
                    handleDeleteAttachment(openDeletePopup?.data?.attachment)

                  if (openDeletePopup?.tag && openDeletePopup.tag === "Subscriber")
                    removeSubscibers(openDeletePopup?.data)
                }
              }]
          }}
          customsx={{ label: { color: 'red', fontWeight: 700, fontSize: '0.9375rem', paddingBottom: '5px' }, btns: { display: 'flex', justifyContent: 'center' } }}
        />
      </Box>
    </Box >
  )
};
TicketDetails.propTypes = {};
TicketDetails.defaultProps = {};
export default TicketDetails;
