/***
 *
 * Controller class for user.
 * @file CustomTypography.js
 * @description CustomTypography component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React from 'react';
// import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import './CustomTypography.scss';

const CustomTypography = ({children, ...props}) => {

    return (
        // <div className="CustomTypography" data-testid="CustomTypography">
        <Typography
            data-testid="CustomTypography"
            className={props?.className || "CustomTypography"}
            {...props}
            component={props?.component || "div"}
            style={{
                textDecoration: `${props.textDecoration}`,
                fontSize: `${props.size/16}rem`,
                color: `${props.color}`,
                fontWeight: `${props.weight}`,
                lineHeight: `${props.lineHeight}px`,
                fontFamily: 'Montserrat',
                textAlign: `${props.textAlign}`,
                cursor: props.cursor,
                ...props.styles
            }}
            sx={{...props?.sx}}>

            {props?.content ? props?.content : children}
        </Typography>
        // </div>
    )
};

CustomTypography.propTypes = {};

CustomTypography.defaultProps = {};

export default CustomTypography;
