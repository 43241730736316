/***
 *
 * Controller class for user.
 * @file AddEditSpace.js
 * @description AddEditSpace component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect, useState } from "react";
// import PropTypes from 'prop-types';
import { useStyles } from "./AddEditSpace.style.js";
// import './AddEditSpace.scss';
import { Box } from "@mui/material";
import PageHeader from "../PageHeader/index.js";
import { useDispatch, useSelector } from "react-redux";
import { spaceActions } from "../../redux/actions/space.actions.js";
import FormWrapper from "../FormWrapper/index.js";
import { findNode } from "../../services/util.service.js";
import { history } from "../../helpers/history.helper.js";
import deviceIcon from '../../assets/icons/device-2.svg'
import { getMessageStr } from "../../helpers/message.helper.js";

const frmConfig = [
  {
    section: { heading: null },
    columns: [
      {
        id: "1",
        type: "text",
        label: "Name",
        name: "name",
        required: true,
        placeholder: "Enter",
        validation: {
          req: [true, getMessageStr('space-add-nameRequired')],
          minLen: [2, getMessageStr('space-add-nameMinCharacter')],
          maxLen: [30, getMessageStr('space-add-nameMaxCharacter')],
          alphaNumChars: ['- ', getMessageStr('space-add-nameAllowedCharacters')]
        },
        columnSize: 4,
      },
      {
        id: "2",
        type: "select",
        label: "Space Type",
        name: "type",
        placeholder: "Enter",
        required: true,
        options: [],
        columnSize: 4,
        validation: {
          req: [true, getMessageStr('spaceType-add-nameEmpty')]
        }
      },
    ],
  },
  {
    section: { heading: "Geolocation" },
    columns: [
      {
        id: "3",
        type: "text",
        label: "Latitudes",
        name: "latitudes",
        required: true,
        placeholder: "Enter",
        columnSize: 4,
        hideInputArrows: true,
        textType: 'number',
        validation: {
          req: [true, getMessageStr('space-add-latitudeRequired')],
          range: [[-90, 90], getMessageStr('space-add-latitudeRange')]
        },
      },
      {
        id: "4",
        type: "text",
        label: "Longitudes",
        name: "longitudes",
        required: true,
        hideInputArrows: true,
        placeholder: "Enter",
        textType: 'number',
        columnSize: 4,
        validation: {
          req: [true, getMessageStr('space-add-longitudeRequired')],
          range: [[-180, 180], getMessageStr('space-add-longitudeRange')]
        },
      },
    ],
  },
  {
    section: { heading: "Address" },
    columns: [
      {
        id: "5",
        type: "text",
        label: "Line 1",
        name: "line1",
        required: true,
        placeholder: "Enter",
        columnSize: 12,
        validation: {
          req: [true, getMessageStr('space-add-addressRequired', 'Address Line 1')],
          minLen: [3, getMessageStr('space-add-addressMinCharacter', 'Address Line 1')],
          maxLen: [100, getMessageStr('space-add-addressMaxCharacter', 'Address Line 1')],
        },
      },
      {
        id: "6",
        type: "text",
        label: "Line 2",
        name: "line2",
        placeholder: "Enter",
        columnSize: 12,
        validation: {
          minLen: [3, getMessageStr('space-add-addressMinCharacter', 'Address Line 2')],
          maxLen: [100, getMessageStr('space-add-addressMaxCharacter', 'Address Line 2')],
        },
      },
      {
        id: "7",
        type: "text",
        label: "City Name",
        name: "city",
        required: true,
        placeholder: "Enter",
        columnSize: 4,
        validation: {
          req: [true, getMessageStr('space-add-cityNameRequired')],
          minLen: [2, getMessageStr('space-add-cityNameMinCharacter')],
          maxLen: [30, getMessageStr('space-add-cityNameMaxCharacter')],
          alphaNumChars: [['- '], getMessageStr('space-add-invalidCityName')]
        },
      },
      {
        id: "8",
        type: "number",
        label: "Zip Code",
        required: true,
        name: "pincode",
        placeholder: "Enter",
        hideInputArrows: true,
        truncate: true,
        columnSize: 4,
        validation: {
          req: [true, getMessageStr('space-add-zipCodeRequired')],
          minLen: [3, getMessageStr('space-add-zipCodeMinCharacter')],
          maxLen: [16, getMessageStr('space-add-zipCodeMaxCharacter')],
          pattern: [/^[0-9]*$/, getMessageStr('space-add-zipCodeFormat')],
        },
      },
      {
        id: "9",
        type: "text",
        label: "Country",
        name: "country",
        required: true,
        placeholder: "Enter",
        columnSize: 4,
        validation: {
          req: [true, getMessageStr('space-add-countryNameRequired')],
          minLen: [3, getMessageStr('space-add-countryNameMinCharacter')],
          maxLen: [100, getMessageStr('space-add-countryNameMaxCharacter')],
          customRegex: [/^[a-zA-Z- ]*$/, getMessageStr('space-add-invalidCountryName')]
        },
      },
    ],
  },
];

const addressObj = {
  line1: { value: "", required: true },
  line2: { value: "", required: false },
  city: { value: "", required: true },
  pincode: { value: "", required: true },
  country: { value: "", required: true },
};

const AddEditSpace = ({ parentId, id, operation = "view" }) => {
  const classes = useStyles();
  const [frmOptions, setFrmOptions] = useState(null);
  const [spaceData, setSpaceData] = useState(null);
  const [frm, setFrm] = useState({})

  const actionButtons = [
    {
      heading: null,
      columns: [
        {
          heading: null,
          buttons: [
            {
              id: "1000",
              type: "button",
              display: true,
              variant: 'outlined',
              label: "Cancel",
              onClick: (event, data) => {
                history.goBack();
              }
            },
            {
              id: "1000",
              type: "button",
              display: true,
              checkValidation: true,
              label: id ? 'Save' : 'Create',
              onClick: (event, data) => {
                handleSubmit(event);
              },
            },
          ],
          columnSize: 12,
        },
      ],
    },
  ];

  let spaceTypesData = useSelector(state => state?.spaceDTypes?.list);
  let spaces = useSelector(state => state?.spaces?.list);
  const dispatch = useDispatch();
  const [selectedSpace, setSelectedSpace] = useState(null);
  // const [spaceTypes, setSpaceTypes] = useState([]);
  // const [loader, setLoader] = useState(false);

  useEffect(() => {
    // console.log("FindNode AddEditSpace --- ", id)
    if (operation === "create" || operation === "add") {
      const parentSpace = spaces?.find(s => s?.spaceId === parentId);
      setSelectedSpace({
        name: "",
        type: "",
        address: parentSpace?.address,
        geoCoordinates: parentSpace?.geoCoordinates
      });
    } else {
      findNode(spaces, "Spaces", { key: "spaceId", value: id }, (res) => {
        setSelectedSpace(res);
      });
    }
  }, [id, spaces, operation, parentId]);

  // useEffect(() => {
  //   dispatch(spaceTypeActions.getSpaceTypes());
  // }, [dispatch]);

  // useEffect(() => {
  //   for (let sType of spaceTypesData) {
  //     sType.label = sType.spaceType;
  //     delete Object.assign(sType, { id: sType.spaceType })["spaceType"];
  //   }
  //   setSpaceTypes(spaceTypesData);
  // }, [spaceTypesData]);

  useEffect(() => {
    if (selectedSpace) {
      let payload = {
        spaceId: id,
        parentSId: parentId,
        name: selectedSpace?.name || "",
        type: selectedSpace?.type || "",
        latitudes: selectedSpace?.geoCoordinates ? selectedSpace?.geoCoordinates[0] : "",
        longitudes: selectedSpace?.geoCoordinates ? selectedSpace?.geoCoordinates[1] : ""
      };

      if (selectedSpace?.address) {
        for (let key in addressObj) {
          payload[key] = selectedSpace?.address[key] || "";
        }
      }

      // console.log("aaaaa --- ", payload)
      setSpaceData(payload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSpace, operation])

  const handleSubmit = (event) => {
    event.preventDefault();
    const payload = {
      name: frm?.name || "",
      type: frm?.type || "",
      address: {},
      geoCoordinates: []
    };

    for (let key in addressObj) {
      if (addressObj[key]?.required) {
        payload.address[key] = frm[key] || "";
      }
      else if (frm[key] !== "") {
        payload.address[key] = frm[key] || "";
      }
    }
    const regex = new RegExp("^0+(?!$)", 'g'); //// remove leading zeroes from string
    let latitudes = frm?.latitudes.replaceAll(regex, ""), longitudes = frm?.longitudes.replaceAll(regex, "");
    if (latitudes === '-0') {
      latitudes = "0";
    }
    if (longitudes === '-0') {
      longitudes = "0";
    }
    payload.geoCoordinates = [latitudes, longitudes];

    // console.log("space frm --- ", frm, payload);
    // console.log("updateSpaceTreeNode AddEditSpace ", parentId, id);

    if (id) {
      // console.log("updateSpaceTreeNode AddEditSpace updateSpace", parentId, id);
      dispatch(spaceActions.updateSpace(id, payload));
    } else {
      // console.log("updateSpaceTreeNode AddEditSpace addSpace", parentId, id);
      dispatch(spaceActions.addSpace(parentId, payload, () => {
        {
          dispatch(spaceActions.getAllSpaces());
        }
      }));
    }
    history.goBack();
  };


  useEffect(() => {
    let opt = {};
    opt["dropdowns"] = {
      type: {
        list: spaceTypesData && spaceTypesData?.length > 0 ? spaceTypesData?.filter(sp => sp.spaceType)?.map(sp => { return { id: sp.spaceType, label: sp.spaceType } }) : [],
        createNewButton: {
          onClick: (event) => history.push("/settings/space-types")
        }
      }
    };
    setFrmOptions(opt);
  }, [spaceTypesData, spaceData]);

  return (
    <Box className={classes?.AddEditSpace || ""} data-testid="AddEditSpace">
      <PageHeader
        title={`${id ? 'Update ' : 'Create '}Sub Space`}
        icon={deviceIcon}
      ></PageHeader>
      {/* {id} */}
      {/* <pre style={{width: 700}}>{JSON.stringify(frm, null, 2)}</pre> */}
      <FormWrapper
        formFields={frmConfig}
        formButtons={actionButtons}
        formData={spaceData || null}
        formOptions={frmOptions}
        setPayload={setFrm}
      />
    </Box>
  );
};

AddEditSpace.propTypes = {};

AddEditSpace.defaultProps = {};

export default AddEditSpace; 