export const canvasTypes = {
    // LOAD_SHAPE: 'loadShape',
    // ADD_PROFILE_SHAPE: 'addShape',
    // UPDATE_PROFILE_SHAPE: 'updateShape',
    // REMOVE_PROFILE_SHAPE: 'removeShape',
    // UPDATE_PROFILE_SHAPES: 'updateShapes',
    // DRAWING_PROFILE_SHAPE: 'drawingShape',
    // SELECTED_PROFILE_SHAPE: 'selectedShape',
    // SELECT_PROFILE_SHAPE: 'selected',
    // RESET: 'reset'
    ADD_SHAPE: 'addShape',
    DRAG_ONE_SHAPE: 'drag_one_shape',
    SELECTED_SHAPE: 'selected_shape',
    UPDATE_SHAPE: 'updateShape',
    REMOVE_SHAPE: 'removeShape',
    REMOVE_SHAPES: 'removeShapeS',
    UPDATE_SHAPES: 'updateShapes',
    DRAWING_SHAPE: 'drawingShape',
    GROUP_NAME: 'group_name',
    CANVAS_PROFILE: 'canvas_profile',
    RESET_LEFT_PANEL: 'resetRightPanel',
    POLYGON_BUTTON: 'POLYGON_BUTTON',
    CONSTRUCT_POLYGON: 'CONSTRUCT_POLYGON',
    ZOOM_IN: 'zoomin',
    ZOOM_OUT: 'zoomout',
    TOGGLE_GROUP: 'toggle_group',
    TOGGLE_DEVICES: 'toggle_device',
    HIGHLIGHT_GROUPID: 'highlight_groupid',
    GET_DEVICES: 'get_devices',    
    MODAL_STATE: 'modal_state',
    EDITOR_SPACE: 'editor_space',
    FLICKER_DEVICE: 'flicker_device',
    CANVAS_WINDOW: 'canvas_window',
    MODAL_SPACE: 'modal_space',
    SET_LAYOUT: 'set_layout',
    ADD_LAYOUT: 'add_layout',
    REPLACE_LAYOUT: 'replace_layout',
    OBSERVE_CHANGE: 'observe_change',
    SPACE_LAYOUTS: 'space_layouts',   
    MOVE_POLYGON: 'move_polygon',
    SHAPE_OVERLAP: 'shape_overlap',
    GETALL_GROUPLIST: 'getall_grouplist',
    OPEN_MODAL: 'open_modal'    
}