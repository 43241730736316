export const spaceTypes = {
    GET_REQUEST: 'SPACE_GET_REQUEST',
    GET_SUCCESS: 'SPACE_GET_SUCCESS',
    GET_FAILURE: 'SPACE_GET_FAILURE',

    GET_ALL_REQUEST: 'SPACE_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'SPACE_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'SPACE_GET_ALL_FAILURE',

    ADD_REQUEST: 'SPACE_ADD_REQUEST',
    ADD_SUCCESS: 'SPACE_ADD_SUCCESS',
    ADD_FAILURE: 'SPACE_ADD_FAILURE',

    UPDATE_REQUEST: 'SPACE_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'SPACE_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'SPACE_UPDATE_FAILURE',

    DELETE_REQUEST: 'SPACE_DELETE_REQUEST',
    DELETE_SUCCESS: 'SPACE_DELETE_SUCCESS',
    DELETE_FAILURE: 'SPACE_DELETE_FAILURE',
    
    BREADCRUMBS_SUCCESS: "BREADCRUMBS_SUCCESS",
    ADD_ASSET_REQUEST: 'SPACE_ADD_ASSET_REQUEST',
    ADD_ASSET_SUCCESS: 'SPACE_ADD_ASSET_SUCCESS',
    ADD_ASSET_FAILURE: 'SPACE_ADD_ASSET_FAILURE',
    
    UPDATE_ASSET_REQUEST: 'SPACE_UPDATE_ASSET_REQUEST',
    UPDATE_ASSET_SUCCESS: 'SPACE_UPDATE_ASSET_SUCCESS',
    UPDATE_ASSET_FAILURE: 'SPACE_UPDATE_ASSET_FAILURE',     
    
    UPDATE_ASSET_CONFIGURATION_REQUEST: 'SPACE_UPDATE_ASSET_CONFIGURATION_REQUEST',
    UPDATE_ASSET_CONFIGURATION_SUCCESS: 'SPACE_UPDATE_ASSET_CONFIGURATION_SUCCESS',
    UPDATE_ASSET_CONFIGURATION_FAILURE: 'SPACE_UPDATE_ASSET_CONFIGURATION_FAILURE',
    
    UPDATE_ENTITY_CONFIGURATION_REQUEST: 'SPACE_UPDATE_ENTITY_CONFIGURATION_REQUEST',
    UPDATE_ENTITY_CONFIGURATION_SUCCESS: 'SPACE_UPDATE_ENTITY_CONFIGURATION_SUCCESS',
    UPDATE_ENTITY_CONFIGURATION_FAILURE: 'SPACE_UPDATE_ENTITY_CONFIGURATION_FAILURE',
    
    DELETE_ASSET_REQUEST: 'SPACE_DELETE_ASSET_REQUEST',
    DELETE_ASSET_SUCCESS: 'SPACE_DELETE_ASSET_SUCCESS',
    DELETE_ASSET_FAILURE: 'SPACE_DELETE_ASSET_FAILURE',
    
    GET_GIVEN_ASSET_REQUEST: 'SPACE_GET_ASSET_REQUEST',
    GET_GIVEN_ASSET_SUCCESS: 'SPACE_GET_ASSET_SUCCESS',
    GET_PNG_ASSET_SUCCESS: 'SPACE_GET_PNG_ASSET_SUCCESS',
    GET_DWG_ASSET_SUCCESS: 'SPACE_GET_DWG_ASSET_SUCCESS',
    GET_GIVEN_ASSET_FAILURE: 'SPACE_GET_ASSET_FAILURE',

    GET_ALL_ASSET_REQUEST: 'SPACE_GET_ALL_ASSET_REQUEST',
    GET_ALL_ASSET_SUCCESS: 'SPACE_GET_ALL_ASSET_SUCCESS',
    GET_ALL_ASSET_FAILURE: 'SPACE_GET_ALL_ASSET_FAILURE',


    //For getting space logs
    GET_SPACE_LOGS_REQUEST: 'GET_SPACE_LOGS_REQUEST',
    GET_SPACE_LOGS_SUCCESS: 'GET_SPACE_LOGS_SUCCESS',
    GET_SPACE_LOGS_FAILURE: 'GET_SPACE_LOGS_FAILURE',

    GET_FILTERED_REQUEST: 'SPACE_GET_FILTERED_REQUEST',
    GET_FILTERED_SUCCESS: 'SPACE_GET_FILTERED_SUCCESS',
    GET_FILTERED_FAILURE: 'SPACE_GET_FILTERED_FAILURE',
    
    
    GET_CURRENT_SPACE_REQUEST: 'SPACE_GET_CURRENT_SPACE_REQUEST',
    GET_CURRENT_SPACE_SUCCESS: 'SPACE_GET_CURRENT_SPACE_SUCCESS',
    GET_CURRENT_SPACE_FAILURE: 'SPACE_GET_CURRENT_SPACE_FAILURE',

    UPDATE_SPACE_LIST:'UPDATE_SPACE_LIST',

    CREATE_SUB_SPACE_TREE:'CREATE_SUB_SPACE_TREE',
    
    SELECT_SPACE_SUCCESS: 'SELECT_SPACE_SUCCESS',

    CREATE_SPACE_TREE_SUCCESS: 'CREATE_SPACE_TREE_SUCCESS',

    SPACE_ASSETS: 'SPACE_ASSETS',

    SPACE_WITH_ASSET: 'SPACE_WITH_ASSETS'
}

