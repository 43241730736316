/***
 *
 * Controller class for user.
 * @file RuleActionDragDropWrapper.js
 * @description RuleActionDragDropWrapper component
 * @author Ayesha Kumar
 * @since 20 Sep 2023
 * 
 */

import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './RuleActionDragDropWrapper.style.js';
import './RuleActionDragDropWrapper.scss';
import { Box, Grid, Paper } from '@mui/material';
import DragAndDropColumnsWrapper from '../../DragAndDropColumnsWrapper/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { deviceActions } from '../../../redux/actions/device.actions.js';
import CustomTypography from '../../CustomTypography/index.js';
import StyledTextfield from '../../StyledTextfield/index.js';
import { dragAndDropActions } from '../../../redux/actions/dragAndDrop.actions.js';
import Light from '../../../assets/icons/bulb.svg';
import Group from '../../../assets/icons/group.svg';
import Scene from '../../../assets/icons/scene.svg';
import { disableDevFeatures } from '../../../config/appConfig.js';
import { groupActions } from '../../../redux/actions/group.actions.js';
import { sceneActions } from '../../../redux/actions/scene.actions.js';
import { formatString } from '../../../services/util.service.js';
import _ from 'lodash';
import StyledSpaceTree from '../../StyledSpaceTree/index.js';


const iconObj = { device: Light, group: Group, scene: Scene }

const RuleActionDragDropWrapper = ({ entity, entityList = [], trigger = {}, controller = {}, ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const deviceList = useSelector(state => state?.devices?.deviceList);
  const groupList = useSelector(state => state?.groups?.list);
  const sceneList = useSelector(state => state?.scenes?.sceneList);
  const activeSpace = useSelector(state => state?.spaces?.selectedSpace);
  const dropList = useSelector(state => state.dragAndDrops?.dropList)
  const dropListIds = dropList?.map(d => d?.id) || []
  const dragList = useSelector(state => state.dragAndDrops?.dragList);
  const spaces = useSelector((state) => state?.spaces?.list);

  const [selectedSpace, setSelectedSpace] = useState(JSON?.parse(JSON.stringify(activeSpace)));
  const [dragListToBeSet, setDragListToBeSet] = useState([]);
  const [reloadDragList, setReloadDragList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');



  const getEntitesUnderSpace = (space) => {
    setLoading(true);
    if (entity === "device") {
      dispatch(deviceActions?.getDevicesBySpaceId(space?.spaceId, "", null, () => {
        setReloadDragList(Date.now());
        setTimeout(() => {
          setLoading(false);
        }, 500)
      }))
    }
    else if (entity === "scene") {
      dispatch(sceneActions?.getAllScenesBySpace(space?.spaceId, () => {
        setReloadDragList(Date.now());
        setTimeout(() => {
          setLoading(false);
        }, 500)
      }))
    }
    else {
      dispatch(groupActions?.getAllGroupsBySpace(space?.spaceId, () => {
        setReloadDragList(Date.now());
        setTimeout(() => {
          setLoading(false);
        }, 500)
      }))
    }
  }

  const getEntitesUnderController = (controller) => {
    setLoading(true);
    if (entity === "device") {
      dispatch(deviceActions?.getAllChildren(controller?.deviceId, "device", { depth: 1 }, () => {
        setReloadDragList(Date.now());
        setTimeout(() => {
          setLoading(false);
        }, 500)
      }))
    }
    else if (entity === "scene") {
      dispatch(sceneActions?.getScenesByDeviceId("controller", controller?.deviceId, () => {
        setReloadDragList(Date.now());
        setTimeout(() => {
          setLoading(false);
        }, 500)
      }))
    }
    else {
      dispatch(groupActions?.getGroupsByDeviceId("controller", controller?.deviceId, () => {
        setReloadDragList(Date.now());
        setTimeout(() => {
          setLoading(false);
        }, 500)
      }))
    }
  }

  useEffect(() => {
    setSelectedSpace(JSON?.parse(JSON.stringify(activeSpace)))
    dispatch(dragAndDropActions.setDropList(entityList?.map(e => {

      e.id = e[entity + "Id"];
      e.name = e?.name || '';
      if (!disableDevFeatures?.itemId && e.name.slice(-1) !== ')') {
        e.name = e.name + ' (' + e?.id + ')';
      }
      e.icon = iconObj[entity];
      e.type = entity === "device" ? 'LIGHT' : entity?.toUpperCase();
      return e;
    })));
    dispatch(dragAndDropActions.setDragList([]));
    setReloadDragList(null)
    trigger?.type === "DEVICE_STATUS" ? getEntitesUnderController(controller) : getEntitesUnderSpace(activeSpace);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let dList = []
    if (reloadDragList !== null) {
      switch (entity) {
        case "device":
          dList = deviceList?.filter(device => {
            if (device.category.includes('LightDriver') && (device?.hasOwnProperty("status") ? device?.status === "active" : true)) {
              device.id = device?.deviceId;
              device.name = device?.name || '';
              if (!disableDevFeatures?.itemId && device.name.slice(-1) !== ')') {
                device.name = device.name + ' (' + device?.id + ')';
              }
              device.icon = Light;
              device.type = 'LIGHT';
              return device;
            }
          })
          dispatch(dragAndDropActions.setDragList(dList?.filter(d => !dropListIds?.includes(d?.id))));
          break;
        case "group":
          dList = groupList?.filter(group => {
            if (group?.hasOwnProperty("status") ? group?.status === "active" : true) {
              group.id = group?.groupId;
              group.name = group?.name || '';
              if (!disableDevFeatures?.itemId && group.name.slice(-1) !== ')') {
                group.name = group.name + ' (' + group?.id + ')';
              }
              group.icon = Group;
              group.type = 'GROUP';
              return group;
            }
          })

          dispatch(dragAndDropActions.setDragList(dList?.filter(d => !dropListIds?.includes(d?.id))));
          break;
        case "scene":
          dList = sceneList?.filter(scene => {
            if (scene?.hasOwnProperty("status") ? scene?.status === "active" : true) {
              scene.id = scene?.sceneId;
              scene.name = scene?.name || '';
              if (!disableDevFeatures?.itemId && scene.name.slice(-1) !== ')') {
                scene.name = scene.name + ' (' + scene?.id + ')';
              }
              scene.icon = Scene;
              scene.type = 'SCENE';
              return scene;
            }
          })
          dispatch(dragAndDropActions.setDragList(dList?.filter(d => !dropListIds?.includes(d?.id))));
          break;
      }
      setDragListToBeSet(dList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadDragList])


  useEffect(() => {
    dispatch(dragAndDropActions.setDragList(dragListToBeSet?.filter(item => !dropListIds.includes(item?.id))));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropList])


  return (
    <Box className={classes?.RuleActionDragDropWrapper || ""} data-testid="RuleActionDragDropWrapper">
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>

          <Paper sx={{ width: '100%', maxHeight: '500px', height: '100%', border: "1px solid #D9D9D9" }}>
            {
              trigger?.type === "DEVICE_STATUS" ?
                <>
                  <Box sx={{ background: '#0F8D480D', padding: '18px 24px 8px 24px' }}>
                    <CustomTypography content={`Select Devices`} styles={{ marginBottom: "16px" }} weight={600} size={12} lineHeight={14.63} color="rgba(0, 0, 0, 0.8)" />
                    <StyledTextfield
                      placeholder={`Search ${trigger?.type === "DEVICE_STATUS" ? "Devices" : "Spaces"}`}
                      disabled={true}
                    >
                    </StyledTextfield>
                  </Box>

                  <Box sx={{ position: "relative" }}>

                    <a style={{ cursor: "pointer", margin: "16px 32px", fontWeight: 700, fontSize: "1rem", color: "#0F8D48" }} onClick={(e) => {
                      getEntitesUnderController(controller);
                    }}>
                      {/* <CustomTypography
                      size={16}
                      weight={700}
                      color={"#0F8D48"}
                      content={controller?.name}
                    /> */}
                      {controller?.name}

                    </a>
                    {/* // <CustomizedTree
                  //   listId={'spaceTree'}
                  //   data={[activeSpace]}
                  //   activeSpace={selectedSpace}
                  //   onClick={(e, val) => {
                  //     setSelectedSpace(val);
                  //     getEntitesUnderSpace(val)
                  //   }}
                  // /> */}

                  </Box>
                </>
                :
                <Box className="spaceTree">
                  <StyledSpaceTree
                    searchPlaceholder="Search Spaces"
                    searchLabel={<CustomTypography content={`Select Space`} styles={{ marginBottom: "16px" }} weight={600} size={12} lineHeight={14.63} color="rgba(0, 0, 0, 0.8)" />}
                    spaces={spaces}
                    subTreeRoot={activeSpace}
                    handleExpand={(e, val) => {
                      setSelectedSpace(val);
                      getEntitesUnderSpace(val)
                    }}
                    activeSpace={selectedSpace}
                    style={{ maxHeight: '330px' }}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    handleChange={(val) => { setSearchQuery(val) }}
                  />
                </Box>

            }
          </Paper>





          {/* <SearchWrapper
            placeholder={`Search ${forList}`}
            data={leftList.length !== 0 ? leftList : [{ name: 'No Item Available' }]}
            autoComplete={true}
            onChange={(e, value) => { handleSearchListSelection(e, value) }}
            autoFill={false}
          /> */}
        </Grid>
        <Grid item xs={12} md={8}>
          <DragAndDropColumnsWrapper dropColumnLabel={`Added ${formatString(entity, "capitalize")}s`} forList={formatString(entity + "s", "capitalize")} typeCreated={'Action'} loading={loading} />
        </Grid>
      </Grid>
    </Box>
  )
};

RuleActionDragDropWrapper.propTypes = {};

RuleActionDragDropWrapper.defaultProps = {};

export default RuleActionDragDropWrapper;
