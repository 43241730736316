/***
 *
 * Controller class for user.
 * @file AreaList.js
 * @description AreaList component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React from 'react';
import { Box } from '@mui/material';
// import PropTypes from 'prop-types';
import './AreaList.scss';
import PageHeader from '../PageHeader';
import CustomTable from '../CustomTable';
import { useSelector } from 'react-redux';

const AreaList = () => {
  const areaList = useSelector(state=>state?.scenes?.sceneList) || [];

    return (
        <div className="AreaList" data-testid="AreaList">
          <Box>
            <PageHeader title={'Areas'} icon="" textStyles={{ position: "absolute" }}>
              <Box sx={{ textAlign: "right" }}>
                {/* Button for Create Area */}
              </Box>
            </PageHeader>
            <CustomTable 
              headerList={{}}
              sorting={true}
              cellMatrix={areaList}
              pagination={true}
              searching={true}
            />
          </Box>     
        </div>
    )
};

AreaList.propTypes = {};

AreaList.defaultProps = {};

export default AreaList;
