/***
 *
 * Controller class for user.
 * @file RightPanel.js
 * @description RightPanel component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import { Box } from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DRAG_DATA_KEY, SHAPES } from '../../../constants/canvas.constants';
import { canvasActions } from '../../../redux/actions';
import CustomAccordion from '../../CustomAccordion';
import GreenLight from '../../../assets/icons/konva/greenBulb.svg'
import GreenSwitch from '../../../assets/icons/konva/greenSwitch.svg'
import GreenSensor from '../../../assets/icons/konva/greenSensor.svg'
import identify from '../../../assets/icons/identify.svg'
// import PropTypes from 'prop-types';
import './RightPanel.scss';
import ImageWrapper from '../../ImageWrapper';
import { identifyDevice } from '../../../helpers';
import StyledPopup from '../../StyledPopup';
import SearchBox from '../../SearchBox';
import CustomTypography from '../../CustomTypography';
import StyledSpinner from '../../StyledSpinner';
import Property from '../LeftPanel/Property';
import StyledButton from '../../StyledButton';

const imgUrl = {
  "light": GreenLight,
  "sensor": GreenSensor,
  "switch": GreenSwitch
}

const DeviceTypes = [
  {
    label: 'Lights',
    id: 'light'
  }, {
    label: 'Sensors',
    id: 'sensor'
  }, {
    label: 'Switches',
    id: 'switch'
  },
]


const RightPanel = ({ space, canvasProfile, mqttClient, image = null, openpanel='panel1'}) => {
  const _shapes = useSelector(state => state?.canvas?.shapes);
  const _DeviceList = useSelector(state => state?.devices?.deviceList);
  const _spaceLayouts = useSelector(state => state?.canvas?.spaceLayouts);
  const _spaceConnectedAsset = useSelector(state => state?.spaces?.spaceConnectedAsset);
  const _selectedShape = useSelector(state => state?.canvas?.selectedShape);



  const [selectedShape, setSelectedShape] = useState(null);
  // const _selectedSpace = useSelector(state => state?.canvas?.modalSpace);
  const dispatch = useDispatch();
  const [enableShapes, setEnableShapes] = useState(false);
  const [deviceList, setDeviceList] = useState([]);
  const [originalDeviceList, setOriginalDeviceList] = useState([]);
  const [dialogContents, setDialogContents] = useState(null);
  const [dialog, setDialog] = useState(false);
  const [selectedDevices, setSelectedDevices] = useState(['light', 'sensor', 'switch']);
  const [loader, showLoader] = useState(true);
  const [imageLayout, setImageLayout] = useState(image);
  const [panel, setPanel] = useState(openpanel);
  
  const shaperef= useRef(); 

  useEffect(()=>{
    setPanel(openpanel);
  },[openpanel])

  useEffect(() => {
    // console.log('get select shape------->', _selectedShape);
    setSelectedShape( _selectedShape?.shapeType === 'shape' ? _selectedShape : {});
    if (_.isEmpty(_shapes)) {
      setPanel('panel1');
    }

  }, [_selectedShape, _shapes])

  useEffect(() => {
    const fetchAssetId = _spaceConnectedAsset.find(obj => obj?.spaceId === canvasProfile?.spaceId)?.assetId;
    let layout = _spaceLayouts?.find((shapeObj) => shapeObj?.assetId === fetchAssetId);
    // console.log('space layouts:::', layout);
    if (!_.isEmpty(layout?.url)) {
      setImageLayout(layout?.url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_spaceConnectedAsset, _spaceLayouts])

  useEffect(() => {
    if (!_.isEmpty(image)) {
      setImageLayout(image);
      setEnableShapes(_.isEmpty(_shapes?.filter((shapeObj) => shapeObj?.shapeType === 'layoutImage' && shapeObj?.spaceId === canvasProfile?.spaceId)));
      console.log('set enable shapes1', _shapes, image, canvasProfile?.spaceId, !_.isEmpty(_shapes?.filter((shapeObj) => shapeObj?.shapeType === 'layoutImage' && shapeObj?.spaceId === canvasProfile?.spaceId)));
    }
    else{
      setImageLayout(null);
      setEnableShapes(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image])

  function getDeviceType(device) {
    if (device?.category?.toLowerCase().search('lightdriver') !== -1) return 'light';
    else if (device?.category?.toLowerCase().search('sensor') !== -1) return 'sensor';
    else if (device?.category?.toLowerCase().search('switch') !== -1) return 'switch';
    else
      return "";
  }

  useEffect(() => {
    if (Array.isArray(_DeviceList)) {
      // console.log('______device', _DeviceList);
      let newDeviceList = [];
      if (!_.isEmpty(_DeviceList)) {
        newDeviceList = _DeviceList.filter(d=>d?.status === 'active')?.map(d => {
          if (d?.category?.toLowerCase().search('lightdriver') !== -1) return { ...d, typeDevice: 'light' };
          else if (d?.category?.toLowerCase().search('sensor') !== -1) return { ...d, typeDevice: 'sensor' };
          else if (d?.category?.toLowerCase().search('switch') !== -1) return { ...d, typeDevice: 'switch' };
          else
            return d
        });
      }
      setDeviceList([...newDeviceList]);
      setOriginalDeviceList([...newDeviceList]);
      showLoader(false);
    }


  }, [_DeviceList])

  useEffect(() => {
    if (loader) {
      setTimeout(() => {
        showLoader(false);
      }, 3000)
    }
  }, [loader])

  useEffect(() => {
    if (!_.isEmpty(_DeviceList) && !_.isEmpty(_shapes)) {
      const plottedDevices = _shapes.map((shapeObj) => { if (shapeObj?.shapeType === 'device') { return shapeObj?.deviceId } }).filter(item => item);
      setDeviceList([..._DeviceList.filter(dObj => !plottedDevices.includes(dObj?.deviceId))]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //// UE for handling redux shapes
  useEffect(() => {
    // console.log('shapes:::::',_shapes);
    if (!_.isEmpty(_shapes)) {
      const isShapePresent = _shapes?.filter((shapeObj) => shapeObj?.shapeType === 'shape' && shapeObj?.spaceId === canvasProfile?.spaceId);
      const fetchAssetId = _spaceConnectedAsset.find(obj => obj?.spaceId === space?.spaceId)?.assetId;
      const isLayoutPresent = fetchAssetId ? _spaceLayouts?.filter((shapeObj) => shapeObj?.assetId === fetchAssetId) : false;
      setEnableShapes(_.isEmpty(isShapePresent) && !_.isEmpty(isLayoutPresent));
      console.log('set enable shapes2', _shapes, isShapePresent, isLayoutPresent, _spaceConnectedAsset, fetchAssetId);
    }
    else {
      // console.log('imggggg layout', imageLayout, _shapes, _spaceConnectedAsset);     
      if (imageLayout && _.isEmpty(canvasProfile?.asset)) { /// no shapes, no asset
        setEnableShapes(true);
        console.log('set enable shapes3');
      }
      else if (imageLayout && !_.isEmpty(canvasProfile?.asset))  /// no shapes, asset present
      {
        console.log('set enable shapes4');
        setEnableShapes(true);
      }
      else if(_.isEmpty(image)){
        // setEnableShapes(true);
        console.log('set enable shapes5');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_shapes, _spaceLayouts, _spaceConnectedAsset, imageLayout])


  useEffect(() => {
    const plottedDevices = _shapes.map((shapeObj) => { if (shapeObj?.shapeType === 'device' && shapeObj?.spaceId === space?.spaceId) { return shapeObj?.deviceId } }).filter(item => item);
    let filterDevices = !_.isEmpty(selectedDevices) ? originalDeviceList.filter(dObj => !plottedDevices.includes(dObj?.deviceId)).filter(d => selectedDevices.includes(d?.typeDevice)) : originalDeviceList;
    if (filterDevices) {
      showLoader(false);
    }
    setDeviceList([...filterDevices]);
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDevices, _shapes, originalDeviceList])


  const handleDragStart = (event) => {
    const type = event.target.dataset.shape;
    const id = event.target.dataset.id;
    const deviceId = event.target.dataset.deviceid || null;
    if (type === 'image' || (id && enableShapes)) {
      // x,y coordinates of the mouse pointer relative to the position of the padding edge of the target node
      const offsetX = event.nativeEvent.offsetX;
      const offsetY = event.nativeEvent.offsetY;

      // dimensions of the node on the browser
      const clientWidth = event.target.clientWidth;
      const clientHeight = event.target.clientHeight;

      const dragPayload = JSON.stringify({
        id,
        type,
        offsetX,
        offsetY,
        clientWidth,
        clientHeight,
        deviceId
      });
      event.nativeEvent.dataTransfer.setData(DRAG_DATA_KEY, dragPayload);
    }
  };

  const handleOnChange = (e, value) => {
    const search = value;
    if (search) {
      let filter = deviceList.filter(d => ((d?.name && d?.name.toLowerCase().search(search) !== -1) || (d?.deviceId.toLowerCase().search(search) !== -1)));
      setDeviceList([...filter]);
    }
    else {
      setDeviceList(originalDeviceList);
    }
  }

  const handleClick = (e, device) => {
    identifyDevice({ device, dispatch, mqttClient }, setDialog, setDialogContents);
  }

  const handleSelectType = (key) => {
    let arr = selectedDevices;
    if (arr.indexOf(key) >= 0) {
      arr.splice(arr.indexOf(key), 1);
    }
    else {
      arr.push(key);
    }
    setSelectedDevices([...arr]);
  }


  const updateAttr = (event) => {
    const attr = event?.target?.name;
    let value = event?.target?.value;

    if (attr && value) {
      dispatch(canvasActions.updateAttribute(attr, value, selectedShape));
    }
  }

  return (
    <aside className="palette RightPanel" data-testid="RightPanel">
      {/* shapes accordion */}
      <CustomAccordion
        title={'Shapes'}
        aid={'panel1'}
        sx={{ width: '100%' }}
        style={{ marginTop: "0px", marginBottom: "2px" }}
        panel={panel}
        onChange={(state) => {
          setPanel(openpanel?'panel1':'');
        }}
        id={'shapeBox'}
      >
        <Box className='flexBox'  justifyContent={'center'}>
          {SHAPES?.map((shape, indx) => {
            if (shape?.type === "polygon") {
              return (
                <img
                  key={indx}
                  ref={shaperef}
                  className={"shape polygon"}
                  src={shape.src}
                  data-shape={shape.type}
                  data-id={shape.id}
                  style={{ opacity: enableShapes ? 1 : 0.5, cursor: ''}}
                  alt=""
                  draggable={enableShapes ? enableShapes : false}
                  onDragEnd={()=>{shaperef.current.style.cursor = ''}}
                  onDragStart={handleDragStart}
                />)
            }
            else if (['image', 'layoutImage'].includes(shape?.type)) return <></>
            else {
              return <div
                key={indx}
                className={"shape " + shape?.type}
                data-shape={shape?.type}
                data-id={shape.id}
                style={{ opacity: enableShapes ? 1 : 0.5 , cursor:''}}
                draggable={enableShapes}
                onDragStart={handleDragStart}
              >
              </div>
            }
          })}
        </Box>
      </CustomAccordion>

      {/* devices accordion*/}
      <CustomAccordion
        title={'Devices'}
        aid={'panel2'}
        sx={{ width: '100%' }}
        style={{ marginTop: "0px", marginBottom: "2px" }}
        customsx={{ details: { paddingRight: 0, paddingLeft: 0 } }}
        panel={panel}
        onChange={(state) => {
          setPanel('panel2');
        }}
        id={'deviceBox'}
      >

        <Box height={'calc(100vh - 240px)'} style={{ overflow: _.isEmpty(deviceList) ? 'hidden' : 'scroll', padding: '0 10px' }} >

          <SearchBox autocomplete={false} searchIn={['name', 'deviceId']} data={deviceList} placeholder="Search device..." onChange={handleOnChange} />
          <Box sx={{ mt: 2, mb: 2 }}>
            {
              !_.isEmpty(originalDeviceList) && DeviceTypes.map((item, key) => (
                <StyledButton size='small' disableElevation sx={{ mr: 1, fontSize: '0.625rem' }} variant={selectedDevices.includes(item?.id) ? 'contained' : 'outlined'} onClick={(e) => handleSelectType(item?.id)} key={key} className='__chips' >{item.label}</StyledButton>
              ))
            }
          </Box>
          <Box style={{ minHeigth: '100px', maxHeight: '400px', height: 'auto' }}>
            {
              !_.isEmpty(deviceList) && Array.isArray(deviceList) && selectedDevices.length > 0 ? deviceList.map((device, key) => (
                <>
                  {getDeviceType(device) ?
                    <Box key={key} className="devicesBox">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img style={{ cursor: '', marginRight: '5px', opacity: imageLayout && !enableShapes ? 1 : 0.5 }} 
                        onDragStart={handleDragStart} draggable={imageLayout && !enableShapes} 
                        data-id={'1060'} data-deviceId={device?.deviceId} 
                        data-shape={'image'} className={`device`}   /////${device?.deviceStatus?.status?.intensity > 0 ? 'device_on':'device_off'} 
                        src={imgUrl[getDeviceType(device)]} alt="" />
                        <CustomTypography size={12} content={`${device?.name ? device?.name : getDeviceType(device)}-(${device?.deviceId})`} />
                      </div>
                      {device?.loading ? <StyledSpinner sx={{ mr: '20px' }} size={15} />
                        :
                        getDeviceType(device) === 'light' ?
                          <StyledButton iconButton tooltip='Identify Device' onClick={e => handleClick(e, device)}
                            style={{ cursor: 'pointer', background: '#ededef', padding: '5px' }}>
                            <ImageWrapper width={15} height={15} src={identify} />
                          </StyledButton>
                          : <></>
                      }
                    </Box>
                    : <></>
                  }
                </>
              ))
                :
                <>
                  {loader ?
                    <StyledSpinner />
                    :
                    <Box sx={{ mt: 2 }}>
                      <p>List is empty</p>
                    </Box>
                  }
                </>
            }
          </Box>
        </Box>       
      </CustomAccordion>

      {/* properties accordion */}
      <CustomAccordion
        title="Properties"
        sx={{ width: '100%' }}
        style={{ marginTop: "0px", marginBottom: "2px" }}
        panel={panel}
        aid={'panel3'}
        customsx={{ details: { pr: 0, pb: 0 } }}
        onChange={(state) => {
          setPanel('panel3')
        }}
        id={'propertyBox'}
      >
        <Box  height={`calc(100vh - 218px)`} overflow={'scroll'}>
          {!_.isEmpty(selectedShape) ? (
            <>
              <form className="form-horizontal">
                {Object.keys(selectedShape)?.filter(ss => ss !== "fillColor")?.map(key => {
                  return (
                    <Property key={key} name={key} shapetype={selectedShape?.type} value={selectedShape[key]} onChange={(event) => updateAttr(event)} />
                  )
                })}
              </form>
            </>
          ) : (
            <Box className="no-data">{_.isEmpty(_shapes) ? 'Add shapes to change properties' : "No shape is selected. Select a shape to change properties."}</Box>
          )}
        </Box>
      </CustomAccordion>

      <StyledPopup
        open={dialog}
        onClose={() => { setDialog(false) }}
        state={dialogContents?.state}
        data={dialogContents?.content}
        component={dialogContents?.component}
      />     
    </aside>
  );
};

RightPanel.propTypes = {};

RightPanel.defaultProps = {};

export default RightPanel;
