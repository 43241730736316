import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    ReportDetails: {
        "& .MuiAccordion-root::before" :{
            display:"none"
        }
    },
    DocumentHeader: {
        display: 'flex',
        alignItems: 'center',
        background: 'rgba(15, 141, 72, 0.05)',
        padding: '17px 20px 18px 20px',
        marginTop: '16px',
        marginBottom: '16px',
        height: '50px',
        justifyContent: 'space-between'
    },
});
