import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme)=>({
    SideBar: {
        display: "inherit",
        // overflow: "hidden",   ///  to remove unusual overflow when sidebar is expanded
        '& .MuiDrawer-root': {
          display: "inherit",
        },
        '& .MuiDrawer-paper': {
          overflow: "hidden"
        },
        '& .MuiPaper-root': {
          'marginTop': "84px",
          'borderRight': "0px"
        },    
        '& .MuiAppBar-colorPrimary': {
          minHeight: "40px",
          backgroundColor: "transparent"
        },
        '& .MuiToolbar-regular': {
          "@media (min-width:600px)": {
                minHeight: "40px",
                paddingLeft: 0,
                paddingRight: 0
          },
          minHeight: "40px",          
          paddingRight: 0
        },
        '& .MuiTab-root': {
          minHeight: "50px"
        }
      }
}));
