export const permissionEntities = [
  {
    name: 'idp',
    allow: [],
  },
  {
    name: 'space',
    allow: [],
  },
  {
    name: 'device',
    allow: [],
  },
  {
    name: 'user',
    allow: [],
  },
  {
    name: 'report',
    allow: [],
  },
  {
    name: 'ticket',
    allow: [],
  },
  {
    name: 'config',
    allow: [],
  }
];

export const permissionEntities1 = {
  idp: {
    user: {
      allow: []
    },
    config: {
      allow: []
    },
    token: {
      allow: []
    },
    client: {
      allow: []
    },
    role: {
      allow: []
    }
  },
  app: {
    space: {
      allow: []
    },
    device: {
      allow: []
    },
    ticket: {
      allow: []
    },
    report: {
      allow: []
    },
    config: {
      allow: []
    }
  }
}

export const permissionEntitiesTMS = {
  idp: {
    sys: {
      user: {
        allow: []
      },
      config: {
        allow: []
      },
      token: {
        allow: []
      },
      client: {
        allow: []
      },
      role: {
        allow: []
      },
      tenant: {
        allow: []
      }
    },
    tenant: {


    }
  },
  app: {

  }
}

export const permissions = {
  add: 'add',
  edit: 'edit',
  delete: 'delete',
  view: 'view',
  operate: 'operate',
  deviceNameEdit: 'deviceNameEdit',
  moveDevice: 'moveDevice',
  deviceDetails: 'deviceDetails',
  searchSystemLogs: 'searchSystemLogs',
  upgradeFirmware: 'upgradeFirmware',
  factoryReset: 'factoryReset',
  systemReset: 'systemReset',
  broadcast: 'broadcast',
  readdress: 'readdress',
  commission: 'commission',
  discover: 'discover',
  resetChannel: 'resetChannel',
  configureIntensity: 'configureIntensity',
  configureTemperature: 'configureTemperature',
  identifyDevice: 'identifyDevice',
  applyScene: 'applyScene',
  syncGroup: 'syncGroup',
  syncArea: 'syncArea',
  identifyLight: 'identifyLight',
  identifySensor: 'identifySensor',
  pairing: 'pairing',
  addConfig: 'addConfig',
  scheduleConfig: 'scheduleConfig',
  configureButtons: 'configureButtons',
  configureLight: "configureLight",
  configureSensor: "configureSensor",
  configureSwitch: "configureSwitch",
  controlGroup: "controlGroup",
  control: 'control',
  syncScene: 'syncScene',
  enableDisable: 'enableDisable',
  notify: 'notify',
  resolve: 'resolve',
  addComments: 'addComments',
  addType: 'addType',
  generate: 'generate',
  download: 'download',
  share: 'share',
  downloadList: 'downloadList',
}

export const features = {
  space: 'space',
  device: 'device',
  user: 'user',
  report: 'report',
  ticket: 'ticket',
  alert: 'alert',
  config: 'config',
  setting: 'setting',
  tms: 'tms',
  role: 'role',
  profile: 'profile',
  fota: 'fota',
}