import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    StyledCard: {
        '& .card-heading': {
            maxWidth: "180px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
        },
        '& .image-array': {
            display: "flex",
            alignItems: 'center',
            marginBottom: "10px",
            
            '& img': {
                width: '44px',
                height: '44px',
                padding: '12px',
                backgroundColor: 'white',
                marginLeft: '-6px',
                borderRadius: '50%',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08), 0px 4px 24px rgba(0, 0, 0, 0.08)',
                '&:last-child': {
                    marginLeft: '0px'
                }
            }
        }
    }
});
