import { Alert } from "@mui/material";
import { useEffect } from "react";
import { connect } from "react-redux";
import Close from "../../assets/icons/close.svg"
import Notify from "../../assets/icons/notify.svg"
import { notificationTimeout } from "../../config/appConfig";
import CustomTypography from "../CustomTypography";
import ImageWrapper from "../ImageWrapper";
import { useStyles } from './FirebaseNotification.style.js';

// import { remove_snackbar } from "../../actions/auth";

const FirebaseNotification = ({ message, onClose }) => {

  const classes = useStyles()
  useEffect(() => {
    setTimeout(function () {
      onClose(message.id);
    }, notificationTimeout);
  }, [message, onClose]);

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    onClose(message.id);
  };

  const Display = () => {
    return (
      <div style={{ display: "flex" }} >
        <div style={{ marginRight: "10px" }}>
          <ImageWrapper src={Notify} className={classes?.greenNotification} />
        </div>
        <div>
          <CustomTypography sx={{ margin: "-5px 0px 10px 0px" }} content={message?.title} weight={600} size={16} lineHeight={17} color="#000" />
          <CustomTypography content={message?.body} weight={400} size={14} lineHeight={17} color="#000" />
        </div>
        <div style={{ position: "absolute", right: "10px" }}>
          <ImageWrapper style={{ cursor: "pointer" }} onClick={handleAlertClose} src={Close} />
        </div>
      </div>
    );
  }

  return (
    <Alert
      // onClose={handleAlertClose}
      icon={false}
      elevation={6}
      variant="filled"
      style={{ background: "white", width: "20rem" }}
    >
      <Display />
    </Alert>
  );
};

export default connect(null, {})(FirebaseNotification);
