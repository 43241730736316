import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    StyledSnackBar: {
        width: "auto",
        '& .MuiAlert-root': {
            marginTop: "5px !important",
            padding: "3px 16px !important"
        },
        '& .MuiAlert-standardError': {
            width: "100%"
        }
    },
    StyledStackedSnackBar: {
        '&.MuiAlert-root': {
            marginTop: "5px !important",
            padding: "3px 16px !important"
        },
        '&.MuiAlert-standardError': {
            width: "100%"
        }
    }
}));
