import { findMqttMetaTopic, success } from "../../helpers";
import { mqttTypes } from "../types";


const sendMqttRequest = (request) => {
    return dispatch => {
        dispatch(success(mqttTypes.ADD_MQTT_REQUEST, { payload: request }));
    };
}
const findMqttRequest = (sid) => {
    return (dispatch, getState) => {
        const mqttRequests = getState().mqtt.mqttRequests
        const request = mqttRequests.find((item) => item.sid === sid)
        if (request) {
            return true
        }
        return false
    };
}
const deleteMqttRequest = (sid) => {
    return dispatch => {
        dispatch(success(mqttTypes.DELETE_MQTT_REQUEST, { payload: sid }));
    }
}

const addMqttTopic = (topic, retain = false) => {
    return dispatch => {
        const payload = Array.isArray(topic) ? topic?.map(t => ({ topic: t, retain })) : { topic, retain }
        dispatch(success(mqttTypes.ADD_MQTT_TOPIC, { payload }));
    }
}

const deleteMqttTopic = (topic) => {
    return dispatch => {
        dispatch(success(mqttTypes.DELETE_MQTT_TOPIC, { payload: topic }));
    }
}

const findMqttTopic = (topic) => {
    return (dispatch, getState) => {
        const mqttTopics = getState()?.mqtt?.mqttTopics
        const topicFound = mqttTopics?.find((item) => item?.topic === topic);
        // const topicFound = findMqttMetaTopic(mqttTopics?.map(t => t?.topic), topic)
        if (topicFound) {
            return true;
        }
        else {
            return false;
        }
    };
}

const getMqttTopics = () => {
    return (dispatch, getState) => {
        const mqttTopics = getState()?.mqtt?.mqttTopics?.filter(t => t?.retain === false)
        return mqttTopics
    };
}
export const mqttActions = {
    sendMqttRequest,
    findMqttRequest,
    deleteMqttRequest,
    addMqttTopic,
    deleteMqttTopic,
    findMqttTopic,
    getMqttTopics
};
