import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    RuleSensorSelection: {
        '& .MuiOutlinedInput-input': {                  
            fontSize: '12px !important',
            padding: "5px 15px !important",
        },
        '& .CustomizedTree': {
            // maxHeight: '320px !important',

            // '@media (max-width: 1200px)': {
            //     maxHeight: 'calc(100vh - 250px) !important'
            // },

            // '@media (min-width: 1100px)': {
            //     maxHeight: 'calc(100vh - 360px) !important'
            // }
        }
    }
});
