/***
 *
 * Controller class for user.
 * @file UserDetail.js
 * @description UserDetail component
 * @author Aditya Rana
 * @since Sep 2022
 * 
 */

import React from 'react';
// import PropTypes from 'prop-types';
import './UserDetail.scss';
import UserList from '../../components/UserList';
import AddEditUser from '../../components/AddEditUser';
import { useQuery } from '../../hooks';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';

const UserDetail = () => {
  const query = useQuery();
  const uId = query.get("id") || null;
  const operation = query.get('op') || null;
  const portal = useSelector(state=>state?.app?.portal)

 
  return (
    <>
      <Box className="UserDetail" data-testid="UserDetail">
        {
          (() => {
            switch (operation) {
              case 'create':
                return (
                  <AddEditUser op={operation} portal={portal} />
                )
              case 'edit':
              case 'view':
              case "resetpassword":
                return (
                  <AddEditUser uId={uId} op={operation} portal={portal} />
                )
              default:
                return (
                  <UserList portal={portal} />
                )
            }
          })()
        }
      </Box>
    </>
  )
};

UserDetail.propTypes = {};

UserDetail.defaultProps = {};

export default UserDetail;
