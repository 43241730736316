import { groupTypes } from '../types';
import { request, success, failure, getMessage } from '../../helpers';

import { delay, groupService } from '../../services';
import { appActions } from './app.actions';

const getAllGroupsBySpace = (spaceId, cb = () => { }) => {
    return dispatch => {
        if (spaceId) {
            dispatch(request(groupTypes.GET_GROUPS_REQUEST));
            groupService.getAllGroupsBySpace(spaceId)
                .then((res) => {
                    // console.log("Group fix ---- ", res)
                    return res;
                })
                .then(
                    ({ data, status }) => {
                        // console.log("Groups BY SPACE", data);
                        dispatch(success(groupTypes.GET_GROUPS_SUCCESS, { payload: data, spaceId: spaceId }))
                        cb()
                    },
                    ({ error, code }) => {
                        dispatch(failure(groupTypes.GET_GROUPS_FAILURE, error?.toString()));
                        getMessage(`groupBySpace-get-${code}`).then(delay(500)).then((msg) => {
                            dispatch(appActions.apiErrorMessage(msg));
                        })
                    }
                );
        }
    };
}
const getGroupDetails = (groupId) => {
    // console.log("getGroupDetails in action ---- ")
    return dispatch => {
        // console.log("getGroupDetails 2 in action ---- ")
        dispatch(request(groupTypes.GET_GROUP_REQUEST));
        groupService.getGroupDetails(groupId)
            .then(
                groups => {
                    dispatch(success(groupTypes.GET_GROUP_SUCCESS, { payload: groups }))
                },
                ({ error, code }) => {
                    dispatch(failure(groupTypes.GET_GROUP_FAILURE, error?.toString()));
                    getMessage(`groupById-get-${code}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    })
                }
            );
    };
}

const syncByDeviceId = (deviceId) => {
    return dispatch => {
        dispatch(request(groupTypes.SYNC_BY_DEVICE_REQUEST));
        groupService.syncByDeviceId(deviceId)
            .then(
                ({ data, status }) => {
                    getMessage(`syncGroupsByDevice-put-${status}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiSuccessMessage(msg));
                    },)
                    dispatch(success(groupTypes.SYNC_BY_DEVICE_SUCCESS, { payload: data }));
                },
                ({ error, code }) => {
                    dispatch(failure(groupTypes.SYNC_BY_DEVICE_FAILURE, error?.toString()));
                    getMessage(`syncGroupsByDevice-put-${code}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    },)
                }
            );
    };
}
const deleteByDeviceId = (deviceId, deviceType = "gateway") => {
    return dispatch => {
        dispatch(request(groupTypes.DELETE_GROUPS_BY_DEVICE_REQUEST));
        groupService.deleteByDeviceId(deviceId, deviceType)
            .then(
                ({ data, status }) => {
                    getMessage(`deleteGroupsByDevice-delete-${status}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiSuccessMessage(msg));
                    },)
                    dispatch(success(groupTypes.DELETE_GROUPS_BY_DEVICE_SUCCESS, { payload: data }));
                },
                ({ error, code }) => {
                    dispatch(failure(groupTypes.DELETE_GROUPS_BY_DEVICE_FAILURE, error?.toString()));
                    getMessage(`deleteGroupsByDevice-delete-${code}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    })
                }
            );
    };
}

const deleteGroup = (groupId, cb = () => { }) => {
    return dispatch => {
        dispatch(request(groupTypes.DELETE_REQUEST));
        groupService.deleteGroup(groupId)
            .then(
                ({ data, status }) => {
                    dispatch(success(groupTypes.DELETE_SUCCESS, { payload: groupId }));
                    getMessage(`group-delete-${status}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiSuccessMessage(msg));
                    })
                    cb();
                },
                ({ error, code }) => {
                    dispatch(failure(groupTypes.DELETE_FAILURE, error?.toString()));
                    getMessage(`group-delete-${code}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    })
                }
            );
    };
}

const editGroup = (groupId, payload, cb = () => { }) => {
    return dispatch => {
        dispatch(request(groupTypes.UPDATE_REQUEST));
        groupService.editGroup(groupId, payload)
            .then(
                ({ data: group, status }) => {
                    dispatch(success(groupTypes.UPDATE_SUCCESS, { payload: group }));
                    getMessage(`group-put-${status}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiSuccessMessage(msg));
                    });
                    cb();
                },
                ({ error, code }) => {
                    dispatch(failure(groupTypes.UPDATE_FAILURE, error?.toString()));
                    getMessage(`group-put-${code}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    })
                }
            );
    };
}
const createGroup = (payload, cb = () => { }) => {
    return dispatch => {
        dispatch(request(groupTypes.ADD_REQUEST));
        groupService.createGroup(payload)
            .then(
                ({ data: group, status }) => {
                    dispatch(success(groupTypes.ADD_SUCCESS, { payload: group }));
                    getMessage(`group-post-${status}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiSuccessMessage(msg));
                    });
                    cb();
                },
                ({ error, code }) => {
                    dispatch(failure(groupTypes.ADD_FAILURE, error?.toString()));
                    getMessage(`group-post-${code}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    })
                }
            );
    };
}
const getGroupsByDeviceId = (deviceType, deviceId, cb = () => { }) => {
    return dispatch => {
        dispatch(request(groupTypes.GET_GROUPS_REQUEST));
        groupService.getGroupsByDeviceId(deviceType, deviceId, { managedBy: deviceType })
            .then(
                groups => {
                    dispatch(success(groupTypes.GET_GROUPS_SUCCESS, { payload: groups }));
                    cb();
                },
                ({ error, code }) => {
                    dispatch(failure(groupTypes.GET_GROUPS_FAILURE, error?.toString()));
                    getMessage(`groupByDevice-get-${code}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    })
                }
            );
    };
}

const getGroupCount = (deviceType, deviceId, cb = () => { }) => {
    return dispatch => {
        dispatch(request(groupTypes.GET_GROUP_COUNT_REQUEST));
        groupService.getGroupsByDeviceId(deviceType, deviceId, { groupCount: true })
            .then(
                groupCount => {
                    dispatch(success(groupTypes.GET_GROUP_COUNT_SUCCESS, { payload: { ...groupCount, ...groupCount?.channelGroupCount, deviceId } }));
                    cb();
                },
                ({ error, code }) => {
                    dispatch(failure(groupTypes.GET_GROUP_COUNT_FAILURE, error?.toString()));
                    getMessage(`groupByDevice-get-${code}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    })
                }
            );
    };
}


const controlGroup = (groupId, payload, onFail = () => { }) => {
    return dispatch => {
        dispatch(request(groupTypes.UPDATE_GROUP_CONTROL_REQUEST));
        groupService.controlGroup(groupId, payload)
            .then(
                ({ data, status }) => {
                    dispatch(success(groupTypes.UPDATE_GROUP_CONTROL_SUCCESS, { payload: { groupId, configuration: payload } }))
                    getMessage(`controlGroup-put-${status}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiSuccessMessage(msg));
                    });
                },
                ({ error, code }) => {
                    dispatch(failure(groupTypes.UPDATE_GROUP_CONTROL_FAILURE, error?.toString()));
                    getMessage(`controlGroup-put-${code}`).then(delay(500)).then((msg) => {
                        dispatch(appActions?.apiErrorMessage(msg));
                    });
                    onFail();
                }
            );
    };
}

const syncById = (id) => {
    return dispatch => {
        dispatch(request(groupTypes.GROUP_SYNC_REQUEST));
        groupService.syncById(id)
            .then(
                ({ data, status }) => {
                    dispatch(success(groupTypes.GROUP_SYNC_SUCCESS));
                    dispatch(groupActions.getGroupDetails(id)); // Added this to update group details after syncing is done
                    getMessage(`groupSync-put-${status}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiSuccessMessage(msg));
                    })
                },
                ({ error, code }) => {
                    dispatch(failure(groupTypes.GROUP_SYNC_FAILURE, error?.toString()));
                    getMessage(`groupSync-put-${code}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    })
                }
            );
    };
}

const getMultipleGroups = (payload) => {
    return dispatch => {
        dispatch(request(groupTypes.GET_MULTIPLE_GROUPS_REQUEST));
        groupService.getMultipleGroups(payload)
            .then(
                groups => dispatch(success(groupTypes.GET_MULTIPLE_GROUPS_SUCCESS, { payload: groups })),
                ({ error, code }) => {
                    dispatch(failure(groupTypes.GET_MULTIPLE_GROUPS_FAILURE, error?.toString()));
                    getMessage(`multipleGroups-get-${code}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    },)
                }
            );
    };
}

const reloadGroupData = (groupId) => {
    return (dispatch, getState) => {
        const group = getState().groups.group;
        if (group?.groupId === groupId) {
            dispatch(groupActions?.getGroupDetails(groupId))
        }
    };
}

export const groupActions = {
    getGroupDetails,
    getAllGroupsBySpace,
    syncByDeviceId,
    deleteByDeviceId,
    createGroup,
    editGroup,
    deleteGroup,
    getGroupsByDeviceId,
    getGroupCount,
    controlGroup,
    syncById,
    getMultipleGroups,
    reloadGroupData
};