export const spaceMessages = (item) => {
    return {
        "space-add-nameRequired": "Space name is required",
        "space-add-nameMinCharacter": "Space name should have minimum 2 characters",
        "space-add-nameMaxCharacter": "Space name should have maximum 30 characters",
        "space-add-nameAllowedCharacters": "Space name should have only alphanumerics and [-]",
        "space-add-latitudeRequired": "Latitudes is required",
        "space-add-latitudeRange": "Latitudes should be between -90 to 90",
        "space-add-longitudeRequired": "Longitudes is required",
        "space-add-longitudeRange": "Longitudes should be between -180 to 180",
        "space-add-addressRequired": `${item} is required`,
        "space-add-addressMinCharacter": `${item} should have minimum 3 characters`,
        "space-add-addressMaxCharacter": `${item} should have maximum 100 characters`,
        "space-add-addressCharacterAllowed": `${item} should have only these characters [- , _ . # @ "space"]`,
        "space-add-cityNameRequired": "City name is required",
        "space-add-cityNameMinCharacter": "City name should have minimum 2 characters",
        "space-add-cityNameMaxCharacter": "City name should have maximum 30 characters",
        "space-add-invalidCityName": "City should only contain alphanumerics, [-] and [space]",
        "space-add-zipCodeRequired": "Zip code is required",
        "space-add-zipCodeMinCharacter": "Zip code should have minimum 3 digits",
        "space-add-zipCodeMaxCharacter": "Zip code should have maximum 16 digits",
        "space-add-zipCodeFormat":'Zip code should have only digits. Other characters are not allowed',
        "space-add-countryNameRequired": "Country is required",
        "space-add-countryNameMinCharacter": "Country should have minimum 3 characters",
        "space-add-countryNameMaxCharacter": "Country should have maximum 100 characters",
        "space-add-invalidCountryName": "Country should only contain alphabets, [space] and [-]",
        "space-delete-confirmation": `Are you sure you want to delete ${item} space?`
    }
}
export const spaceAPIMessages =(item)=>{
    return {
    "spaceAll-get-200": "Space(s) successfully retrieved.",
    "spaceAll-get-401": "Unable to retrieve the space(s), please verify your access and try again.",
    "spaceAll-get-403": "Unable to retrieve the space(s), please verify your access and try again.",
    "spaceAll-get-404": "Unable to retrieve the space(s), please try again later.",
    "spaceAll-get-500": "Unable to retrieve the space(s) due to internal error, please try again later.",
    "spaceById-get-200": "Space details successfully retrieved.",
    "spaceById-get-401": "Unable to retrieve the space, please verify your access and try again.",
    "spaceById-get-403": "Unable to retrieve the space(s), please verify your access and try again.",
    "spaceById-get-404": "Unable to retrieve the space, please try again later.",
    "spaceById-get-500": "Unable to retrieve the space due to internal error, please try again later.",
    "space-post-200": "Space successfully created.",
    "space-post-400": "Unable to create the space, please verify inputs and try again.",
    "space-post-401": "Unable to create the space, please verify your access and try again.",
    "space-post-403": "Unable to create the space, please verify your access and try again.",
    "space-post-404": "Unable to create the space, please try again later.",
    "space-post-500": "Unable to create the space due to internal error, please try again later.",
    "space-put-200": "Space successfully updated.",
    "space-put-400": "Unable to edit the space, please verify inputs and try again.",
    "space-put-401": "Unable to edit the space, please verify your access and try again.",
    "space-put-403": "Unable to edit the space, please verify your access and try again.",
    "space-put-404": "Unable to edit the space, please try again later.",
    "space-put-500": "Unable to edit the space due to internal error, please try again later.",
    "space-delete-200": "Space successfully deleted.",
    "space-delete-400": "Unable to delete the space, please verify inputs and try again.",
    "space-delete-401": "Unable to delete the space, please verify your access and try again.",
    "space-delete-403": "Unable to delete the space, please verify your access and try again.",
    "space-delete-404": "Unable to delete the space, please try again later.",
    "space-delete-405": `Unable to delete the space, please delete the child space(s)/device(s) before deleting the selected space.`,
    "space-delete-500": "Unable to delete the space due to internal error, please try again later.",
    "spaceLog-get-200": "Space Activity Logs sccessfully retrieved.",
    "spaceLog-get-400": "Unable to retireve Space Activity Logs, please try again later.",
    "spaceLog-get-401": "Unable to retireve Space Activity Logs, please verify your access and try again.",
    "spaceLog-get-403": "Unable to retireve Space Activity Logs, please verify your access and try again.",
    "spaceLog-get-404": "Unable to retireve Space Activity Logs, please try again later."
}
}

export const spaceTypeMessages = (item) => {
    return {
        "spaceType-add-nameEmpty": "Space type is required",
        "spaceType-add-nameNoAlphabet": "Space type name should have alphabet.",
        "spaceType-add-minCharacter": "Space type name should have minimum 2 characters.",
        "spaceType-add-maxCharacter": "Space type name should not exceed more than 30 characters.",
        "spaceType-add-alphanumchars":'Space type should contain only alphanumerics and [-]',
        "spaceType-add-invalidName": "Space type is invalid",
        "spaceType-add-iconRequired": "Icon is required",
        "spaceType-add-iconFileSize": "Icon file size should be less than 50KB",
        "spaceType-add-imageFileSize": "Image file size should be less than 1MB",
        "spaceType-delete-confirmation": `Are you sure you want to delete space type ${item}`
    }
};

export const spaceTypeAPIMessages = {
    "spaceType-get-200": "Space Type(s) successfully retireved.",
    "spaceType-get-401": "Unable to retrieve Space Type(s), please verify your access and try again.",
    "spaceType-get-403": "Unable to retrieve Space Type(s), please verify your access and try again.",
    "spaceType-get-500": "Unable to retrieve Space Type(s) due to internal error, please try again later.",
    "spaceType-post-200": "Space Type successfully created",
    "spaceType-post-400": "Unable to create the Space Type, please verify inputs and try again.",
    "spaceType-post-401": "Unable to create the Space Type, please verify your access and try again.",
    "spaceType-post-403": "Unable to create the Space Type, please verify your access and try again.",
    "spaceType-post-404": "Unable to create the Space Type, please try again later.",
    "spaceType-post-409": "Space Type exists with same name, please try again with different space type.",
    "spaceType-post-500": "Unable to create the Space Type due to internal error, please try again later.",
    "spaceType-put-200": "Space Type successfully updated.",
    "spaceType-put-400": "Unable to edit the Space Type, please verify inputs and try again.",
    "spaceType-put-401": "Unable to edit the Space Type, please verify your access and try again.",
    "spaceType-put-403": "Unable to edit the Space Type, please verify your access and try again.",
    "spaceType-put-404": "Unable to edit the Space Type, please try again later.",
    "spaceType-put-500": "Unable to edit the Space Type due to internal error, please try again later.",
    "spaceType-delete-200": "Space Type successfully deleted.",
    "spaceType-delete-400": "Unable to delete the Space Type, please refresh your page and try again.",
    "spaceType-delete-401": "Unable to delete the Space Type, please verify your access and try again.",
    "spaceType-delete-403": "Unable to delete the Space Type, please verify your access and try again.",
    "spaceType-delete-404": "Unable to delete the Space Type, please try again later.",
    "spaceType-delete-405": "Unable to delete the Space Type as spaces exist with this type. Linked space(s) to be deleted before deleting the Space Type",
    "spaceType-delete-500": "Unable to delete the Space Type due to internal error, please try again later."
}