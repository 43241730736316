/***
 *
 * Controller class for user.
 * @file CustomStepper.js
 * @description CustomStepper component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import { Box, Grid } from '@mui/material';
import React from 'react';
// import PropTypes from 'prop-types';
import './CustomStepper.scss';
import CheckIcon from '@mui/icons-material/Check';

const CustomStepper = ({ labels, activeStep }) => {

  return (
    <Box className="CustomStepper" data-testid="CustomStepper">
      {labels?.map((label, index) => {
        index = index + 1;
        return (
          <Grid key={index} container item xs={12}>
            <span className={"progressBar" + (activeStep >= index ? " active" : "")}></span>
            <Box className="step">
              <Box className={"stepCount" + (activeStep >= index ? " active" : "")}>
                {(activeStep > index ? (
                  <CheckIcon sx={{
                    height: '14px',
                    fill: '#ffffff'
                  }} />
                ) : (index))}
              </Box>
              <Box className="stepLabel">
                {label}
              </Box>
            </Box>
          </Grid>
        )
      })
      }
    </Box >
  )
};

CustomStepper.propTypes = {};

CustomStepper.defaultProps = {};

export default CustomStepper;
