import { defaultAuthenticatedUrlArr, setDefaultAuthenticatedUrl } from "../config/appConfig";
import { isAuthorised } from "./users.helper";

const getDefaultUrl = (routes) => {
    let dr = defaultAuthenticatedUrlArr[0];
    for (const rr of defaultAuthenticatedUrlArr) {
      if (routes?.some(r => ("/" + r.path === rr))) {
        dr = rr;
        break;
      }
    }
    return dr;
}

export const getAuthenticatedRoutes = (routes, portal) => {
    let fRoutes = routes?.filter(r => (!r?.isPublicRoute && isAuthorised(r?.feature, 'routes', portal) && r?.portal.includes(portal) && isAuthorised(r?.parentRoute, 'routes', portal) ));

    if (fRoutes.some(r => r?.feature !== "space")) {
      setDefaultAuthenticatedUrl(getDefaultUrl(fRoutes));
    }
    return fRoutes?.sort((a, b) => a.sortOrder < b.sortOrder ? -1 : 1);
}