import { API_URL } from "../config/appConfig";
import { handleResponse } from "../helpers";
import { API } from "./api.service";

const generateBleKey = () => {
    return API.post(
        `${API_URL.device}/bleKeys/create`,
        `bleKey/create`,
        {}
    ).then((res) => {
        return handleResponse(res, {});
    });
};

const rotateBleKey = (params) => {
    return API.post(
        `${API_URL.device}/bleKeys/rotate?receiverIds=${params?.receiverList}`,
        `bleKeys/rotate`,
        {}
    ).then((res) => {
        return handleResponse(res, {});
    });
};

const getBleKeys = () => {
    return API.get(
        `${API_URL.device}/bleKeys/status`,
        `bleKeys/status`,
        {}
    ).then((res) => {
        return handleResponse(res, {});
    });
};

export const rotateKeyService = {
    generateBleKey,
    rotateBleKey,
    getBleKeys
}