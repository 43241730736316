/***
 *
 * Controller class for user.
 * @file AddEditUser.js
 * @description AddEditUser component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 *
 */

import { Box } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../redux/actions/user.actions";
import "./AddEditUser.scss";
import { useEffect } from "react";
import PageHeader from "../PageHeader";
import { history, isAuthorised } from "../../helpers";
import Permissions from "./Permissions";
import PersonalDetails from "./PersonalDetails";
import _ from "lodash";
import StyledPopup from "../StyledPopup";
import { appActions } from "../../redux/actions";
import CustomTypography from "../CustomTypography";
import StyledTabs from "../StyledTabs";
import ImageWrapper from "../ImageWrapper";
import ActivityLog from "../ActivityLog";
import Validator from "../../helpers/validator.helper";
import Delete from "../../assets/icons/delete.svg";
import userDeactivate from "../../assets/icons/user-deactivate.svg";
import userActivate from "../../assets/icons/user-activate.svg";
import userUnlock from "../../assets/icons/user-unlock.svg";
import userDetail from "../../assets/icons/user-detail.svg";
import UserScope from "../../assets/icons/user-scope.svg";
import UserActivityLogsIcon from "../../assets/icons/activitylog.svg";
import { useStyles } from "./AddEditUser.style";
import { features, permissions } from "../../constants";
import { ScrollToTop, containsSpace } from "../../services";
import StyledButton from "../StyledButton";
import ResetCred from "../ResetCred";

const pValueArray = [
  {
    id: "pass_new",
    type: "password",
    label: "Enter new password",
    name: "newPValue",
    error: false,
    errorMsg: "",
    minMaxLen: false,
    reqDigit: false,
    reqSpecial: false,
    reqUpper: false,
  },
  {
    id: "pass_confirm",
    type: "password",
    label: "Re-enter new password",
    name: "reEnterPValue",
    error: false,
    errorMsg: "",
  },
];
const enterPValue = {
  newPValue: "",
  reEnterPValue: "",
};


const AddEditUser = ({ op, uId, portal = "mss" }) => {
  const classes = useStyles();

  const [frm, setFrm] = useState();
  const [userDetails, setUserDetails] = useState(null);
  const [stepCount, setStepCount] = useState(1);
  const [userId, setUserId] = useState(uId);
  const [loader, showLoader] = useState(true);
  const _userDetails = useSelector((state) => state?.users?.userDetails); /// _<item> indicates that we're accessing reducer state; just naming convention; shudn't be confused with lodash
  const _deletedUser = useSelector((state) => state?.users?.deletedUser);
  const _addedUser = useSelector((state) => state?.users?.userAdded);
  const userStatus = useSelector((state) => state?.users?.userStatus);
  const _userError = useSelector((state) => state?.users?.error);
  const userLogs = useSelector((state) => state?.users?.userLogs);
  const _changeUserIDPValue = useSelector(
    (state) => state?.users?.changeUserIDPassword
  );
  const [originalDetails, setOriginalDetails] = useState(null);

  const [btn1Label, setBtn1Label] = useState("Edit");
  const [userScope, setUserScope] = useState({});
  const [operation, setOperation] = useState(op);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [openDeActivatePopup, setDeActivatePopup] = useState(false);
  const [toggleActivation, setToggleActivation] = useState(null);
  const [resetPValueArray, setResetPValueArray] = useState(pValueArray?.map(p => ({ ...p })));
  const [resetPValue, setResetPValue] = useState(enterPValue);
  const [tabs,] = useState([
    { label: "USER DETAILS", icon: userDetail },
    ...(() => {
      if (op !== "create" && portal === "mss") {
        return [
          { label: "USER SCOPE", icon: UserScope },
          { label: "ACTIVITY LOGS", icon: UserActivityLogsIcon },
        ];
      }
      return [];
    })(),
  ]);
  const [locked, setLocked] = useState(false);
  const _sideBarOpen = useSelector((state) => state?.app?.sideBarOpen);

  // const steps = uId ?
  //   [{ label: `${(operation === 'edit' && stepCount === 1) ? "EDIT" : "VIEW"} USER DETAILS` },
  //   { label: `${(operation === 'edit' ) ? "EDIT" : "VIEW"} USER SCOPE` }
  //   ] :
  //   [{ label: `${(operation === 'create' && stepCount === 1) ? 'ADD' : 'ADD'} USER DETAILS` },
  //   { label: `${(operation === 'create' && stepCount === 2) ? 'ADD' : 'ADD'} USER SCOPE` }
  //   ];
  const dispatch = useDispatch();
  const goTo = (route) => {
    history.push(route);
  };

  const findField = (fields, field, def) => {
    return !_.isEmpty(fields) &&
      fields.find(function (o) {
        return o.key === field;
      })
      ? fields.find(function (o) {
        return o.key === field;
      }).value
      : def;
  };

  const headerBtns = [
    {
      id: "delete",
      name: "delete",
      label: "Delete",
      color: "primary",
      display:
        portal === "tms"
          ? false
          : isAuthorised(features?.user, permissions?.add),
      icon: Delete,
      variant: "outlined",
      operations: ["view"],
      onClick: (e) => {
        setOpenDeletePopup(true);
      },
    },
    {
      id: "deactivate",
      name: "deactivate",
      label: !toggleActivation ? "Enable" : "Disable",
      color: "primary",
      variant: "outlined",
      display: isAuthorised(features?.user, permissions?.add),
      icon: !toggleActivation ? userActivate : userDeactivate,
      operations: ["view"],
      onClick: () => {
        if (!toggleActivation) {
          if (portal === "tms") {
            let status = { enabled: true };
            dispatch(userActions.update(status, userId, portal));
          } else {
            let status = { status: "enable" };
            dispatch(userActions.changeUserStatus(userId, status));
          }
        } else {
          setDeActivatePopup(true);
        }
      },
    },
    {
      id: "unlock",
      name: "unlock",
      label: "Unlock",
      color: "primary",
      variant: "outlined",
      display:
        portal === "tms"
          ? false
          : isAuthorised(features?.user, permissions?.add) && locked
            ? true
            : false,
      icon: userUnlock,
      // sx: { position: 'absolute', p: '8px 10px', left: '540px', height: '30px' },
      operations: ["view"],
      onClick: () => {
        let status = { status: "unlock" };
        dispatch(userActions.changeUserStatus(userId, status));
        setLocked(false);
      },
    },
  ];

  useEffect(() => {
    // console.log('user status==>', userStatus, toggleActivation);
    if (userStatus === "enable" || userStatus === "disable") {
      setToggleActivation(userStatus === "enable" ? true : false);
      setDeActivatePopup(false);
      dispatch(
        appActions.apiSuccessMessage(
          `User ${userStatus === "enable" ? "enabled" : "disabled"
          } successfully`
        )
      );
    } else if (userStatus === "unlock") {
      dispatch(appActions.apiSuccessMessage("User unlocked successfully"));
    }
    dispatch(userActions.emptyUserState("CHANGE_STATUS_SUCCESS", null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStatus, toggleActivation]);

  useEffect(() => {
    setOperation(op);
  }, [op]);

  useEffect(() => {
    if (operation === "resetpassword") {
      setResetPValue(enterPValue);
      setResetPValueArray(pValueArray?.map(p => ({ ...p })));
    }
    else if (operation === 'edit') {
      setBtn1Label('Cancel');
    }
    else if (operation === 'view') {
      setBtn1Label('Edit');
    }
  }, [operation]);

  useEffect(() => {
    if (uId && operation !== "create") {
      dispatch(userActions.getById(uId, portal));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (_userError) {
      ScrollToTop();
      setUserDetails({ ...originalDetails });
      dispatch(userActions.emptyUserErrors());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_userError]);

  useEffect(() => {
    if (_addedUser) {
      history.push(`/user-management/users?op=view&id=${_addedUser?.userId}`);
      // setStepCount(2);
      setBtn1Label("Edit");
      setUserId(_addedUser?.userId);
      dispatch(userActions.emptyUserState("ADD_USER_SUCCESS", null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_addedUser]);

  useEffect(() => {
    if (_userDetails) {
      let obj = _userDetails;
      if (portal === "mss") {
        obj["employeeId"] = findField(_userDetails?.fields, "employeeId", "");
        obj["tempUser"] = findField(_userDetails?.fields, "tempUser", "No");
        const timestamp = findField(_userDetails?.fields, "userExpiry", "");

        obj["userExpiry"] = timestamp ? timestamp : null;
        // console.log("user2===>", _userDetails);

        setLocked(_userDetails.locked);
      }
      setUserDetails(obj);
      setOriginalDetails(_userDetails);
      setToggleActivation(_userDetails?.enabled);
      showLoader(false);
      dispatch(userActions.emptyUserState("GET_SUCCESS", null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_userDetails]);

  useEffect(() => {
    // console.log('change it==>',_changeUserIDPValue);

    if (_changeUserIDPValue) {
      dispatch(appActions.apiSuccessMessage("Password changed successfully"));
      history.push(`/user-management/users?op=view&id=${userId}`);
      dispatch(
        userActions.emptyUserState("CHANGE_USERID_PASSWORD_SUCCESS", null)
      ); /// emptying the reducer state;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_changeUserIDPValue]);

  useEffect(() => {
    if (portal === "mss") {
      if (_deletedUser) {
        setOpenDeletePopup(false);
        history.push("/user-management/users");
        setUserDetails(null);
        setOriginalDetails(null);
        dispatch(appActions.apiSuccessMessage("User deleted successfully"));
      }
      dispatch(userActions.emptyUserState("DELETE_SUCCESS", null)); /// emptying the reducer state;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_deletedUser]);

  useEffect(() => {
    if (loader) {
      setTimeout(() => {
        showLoader(false);
      }, 2000);
    }
  }, [loader]);

  useEffect(() => {
    if (uId) {
      setUserId(uId);
      // if (portal === "mss") {
      //   setTabs([
      //     { label: "USER DETAILS", icon: userDetail },

      //   ]);
      // }
    }
    // let queryParams = [];
    // let queryString = queryParams.join('&');
    // queryString!=='' && (queryString = ''.concat('?',queryString)); //onFilter from outside
    // dispatch(userActions.getUserActivityLogs({userId:uId})); /// not required
  }, [uId]);

  const handleButton1 = (operation, stepCount) => {
    // console.log('operation::',operation);
    if (operation === "view") {
      setBtn1Label("Cancel");
      history.push(`/user-management/users?op=edit&id=${userId}`);
    } else {
      setBtn1Label("Edit");
      setUserDetails({ ...originalDetails });
      history.push(`/user-management/users?op=view&id=${userId}`);
    }

    // setStepCount(1);
  };

  const handlePValueChange = (e) => {
    const target = e.target;
    setResetPValue({
      ...resetPValue,
      [target.name]: target.value,
    });

    const arr = resetPValueArray.map((item) => {
      if (["pass_new", "newPValue"].includes(item?.id)) {
        item["minMaxLen"] =
          _.isEmpty(Validator(target.value, "text", { minLen: [8, ""] })) &&
          _.isEmpty(Validator(target.value, "text", { maxLen: [20, ""] }));
        item["reqSpecial"] = /.*[#@_-]/.test(target.value);
        item["reqDigit"] = /.*[0-9]/.test(target.value);
        item["reqUpper"] = /.*[A-Z]/.test(target.value);
      }
      item["error"] = false;
      item["errorMsg"] = ``;

      return item;
    });
    setResetPValueArray(arr);
  };

  const handleSubmit = (formData) => {
    if (portal === "mss") {
      if (operation === "create") {
        let { roles, employeeId, tempUser, userExpiry, ...rest } = frm;
        dispatch(
          userActions.addUser({
            roles: roles ? roles : [],
            fields: [
              {
                key: "employeeId",
                value: employeeId || "",
              },
              {
                key: "tempUser",
                value: tempUser || "",
              },
              {
                key: "userExpiry",
                value:
                  !_.isEmpty(userExpiry) && userExpiry !== "NaN"
                    ? Date.parse(userExpiry).toString()
                    : "",
              },
            ],
            ...(() => {
              if (formData?.newPValue) {
                return { password: formData?.newPValue }
              }
            })(),
            ...rest,
          })
        );
      } else if (userId && operation === "edit") {
        // console.log('dddddddddddd', roles,frm);
        if (stepCount === 1) {
          let { roles, employeeId, tempUser, userExpiry, ...rest } = frm;
          let payload = {},
            fieldkeys = ["email", "mobile", "name"];
          fieldkeys.forEach((item) => {
            let key = item;
            if (rest[item] !== userDetails[item]) {
              payload[item] = rest[item];
            }
          });
          payload["fields"] = [
            {
              key: "employeeId",
              value: employeeId || "",
            },
            {
              key: "tempUser",
              value: tempUser || "",
            },
            {
              key: "userExpiry",
              value:
                !_.isEmpty(userExpiry) && !isNaN(Date.parse(userExpiry))
                  ? Date.parse(userExpiry).toString()
                  : "",
            },
          ];
          dispatch(
            userActions.update(payload, userId, () => {
              // dispatch(userActions.getById(userId));
              dispatch(
                userActions.updateUserRoles(userId, { roles: roles }, () => {
                  // dispatch(userActions.getById(userId));
                })
              );
              setBtn1Label("Edit");
              history.push(`/user-management/users?op=view&id=${userId}`);
            }, portal, false)
          )
          // setStepCount(2);
        } else {
          if (userScope) {
            dispatch(userActions.updateUserScopes(userId, userScope), () => {
              dispatch(userActions.getById(userId));
            });
          }
          history.push(`/user-management/users?op=view&id=${userId}`);
          setBtn1Label("Edit");
          dispatch(userActions.emptyUserState("GET_USER_SCOPE_SUCCESS", null));
        }
      }
    } else {
      const payload = {
        firstname: frm?.name || "",
        lastname: frm?.lastname || "",
        username: frm?.username || "",
        email: frm?.email || "",
        mobile: frm?.mobile || "",
      };
      if (operation === "create") {
        payload["roles"] = frm?.roles;

        dispatch(
          userActions.addUser(payload, portal, (user) => {
            setBtn1Label("Edit");
            history.push(`/user-management/users?op=view&id=${user?.userId}`);
          })
        );
      } else if (operation === "edit") {
        dispatch(
          userActions.updateUserRoles(
            userId,
            { roles: frm?.roles },
            (user) => {
              dispatch(
                userActions.update(
                  payload,
                  userId,
                  (user) => {
                    setBtn1Label("Edit");
                    history.push(`/user-management/users?op=view&id=${userId}`);
                  },
                  portal,
                )
              );
            },
            portal, false
          )
        );

      }
    }

    if (operation === "resetpassword") {
      const regex = /^(?=.*?[A-Z])(?=.*?[#@\-_])[a-zA-Z0-9#@\-_]+$/;

      if (
        _.isEmpty(resetPValue["newPValue"]) ||
        _.isEmpty(resetPValue["reEnterPValue"])
      ) {
        const arr = resetPValueArray.map((item) => {
          if (_.isEmpty(resetPValue[item?.name])) {
            item["error"] = true;
            item["errorMsg"] = `Given field cannot be empty`;
          }
          return item;
        });
        setResetPValueArray(arr);
        return;
      }
      if (
        resetPValue["newPValue"].length < 8 ||
        resetPValue["newPValue"].length > 20 ||
        resetPValue["reEnterPValue"].length < 8 ||
        resetPValue["reEnterPValue"].length > 20
      ) {
        const arr = resetPValueArray.map((item) => {
          if (resetPValue[item?.name]) {
            item["error"] = true;
            item["errorMsg"] = `Passwords should be 8 to 20 characters long`;
          }
          return item;
        });
        setResetPValueArray(arr);
        return;
      }

      if (
        resetPValue["newPValue"] &&
        resetPValue["reEnterPValue"] &&
        resetPValue["newPValue"] !== resetPValue["reEnterPValue"]
      ) {
        const arr = resetPValueArray.map((item) => {
          if (item["name"] !== "oldPValue") {
            item["error"] = true;
            item["errorMsg"] = `Passwords do not match`;
          }
          return item;
        });
        setResetPValueArray(arr);
        return;
      }
      if (
        containsSpace(resetPValue["newPValue"]) ||
        containsSpace(resetPValue["reEnterPValue"])
      ) {
        const arr = resetPValueArray.map((item) => {
          if (containsSpace(resetPValue[item?.name])) {
            item["error"] = true;
            item["errorMsg"] = `Password cannot contain whitespace`;
          }
          return item;
        });
        setResetPValueArray(arr);
        return;
      }
      const newPValue = resetPValueArray?.find(item => item?.name === "newPValue");
      if (!(newPValue["minMaxLen"] && newPValue["reqSpecial"] && newPValue["reqDigit"] && newPValue["reqUpper"])) {
        const arr = resetPValueArray.map((item) => {
          if (item?.name === "newPValue") {
            item["error"] = true;
            item["errorMsg"] = `Passwords must satisfy the provided conditions`;
          }
          return item;
        });
        setResetPValueArray(arr);
        return;
      }
      if (
        !_.isEmpty(resetPValue["newPValue"]) &&
        !_.isEmpty(resetPValue["reEnterPValue"]) &&
        resetPValue["newPValue"] === resetPValue["reEnterPValue"]
      ) {
        const arr = resetPValueArray.map((item) => {
          if (["pass_new", "newPValue"].includes(item?.id)) {
            item["minMaxLen"] = false;
            item["reqSpecial"] = false;
            item["reqDigit"] = false;
            item["reqUpper"] = false;
          }
          item["error"] = false;
          item["errorMsg"] = ``;

          return item;
        });
        setResetPValueArray(arr);
        dispatch(
          userActions.changeCredByUserId(
            userId,
            { password: resetPValue["newPValue"] },
            portal
          )
        );
      }
    }
  };

  const handleDeleteBtn = (e) => {
    dispatch(userActions.delete(userId));
  };

  const handleDeActivateBtn = (e) => {
    if (portal === "tms") {
      let status = { enabled: !toggleActivation };
      dispatch(userActions.update(status, userId, portal));
      setDeActivatePopup(false);
    } else {
      let status = { status: !toggleActivation ? "enable" : "disable" };
      dispatch(userActions.changeUserStatus(userId, status));
    }
  };

  const handleBackBtn = (e, pos) => {
    if (pos === "top") {
      if (operation === "resetpassword") {
        goTo(`/user-management/users?op=view&id=${userId}`);
      } else if (pos === "bottom") {
        // setStepCount(2);
      } else if (stepCount === 1 || stepCount === 2) {
        goTo("/user-management/users");
      } else {
        setStepCount(1);
        goTo(`/user-management/users?op=view&id=${userId}`);
      }
    }
    dispatch(userActions.emptyUserState("GET_USER_SCOPE_SUCCESS", null));
  };

  return (
    <Box sx={{ maxWidth: "100%" }} data-testid="AddEditUser">
      <PageHeader
        bckbtn={true}
        flright={false}
        pageHeaderStyle={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: _sideBarOpen && "wrap",
        }}
        backfunc={(e) => {
          handleBackBtn(e, "top");
        }}
        title={
          stepCount === 3
            ? "Activity Logs"
            : operation === "edit"
              ? `Edit ${stepCount === 1 ? "User" : "Scope"}`
              : operation === "create"
                ? `Add ${stepCount === 1 ? "User" : "Scope"}`
                : `User ${stepCount === 1 ? "Details" : "Scope"}`
        }
        icon={userDetail}
      >
        <Box display={"flex"}>
          {operation === "view" &&
            headerBtns.map((item, key) => (
              <>
                {item?.operations?.includes(operation) &&
                  item?.display &&
                  stepCount === 1 && (
                    <StyledButton
                      type="icon"
                      iconButton
                      tooltip={item?.label}
                      id={item?.id}
                      sx={{
                        padding: "5px",
                        height: 35,
                        width: 35,
                        ...item?.sx,
                      }}
                      onClick={(event) => item?.onClick(event)}
                    >
                      <ImageWrapper
                        className="pointer"
                        src={item?.icon}
                        alt={item?.label}
                      />
                    </StyledButton>
                  )}
              </>
            ))}
          {["view"].includes(operation) &&
            isAuthorised(features?.user, permissions?.edit) &&
            stepCount === 1 && (
              <StyledButton
                className={classes.ChangePassword}
                color="primary"
                onClick={() => {
                  goTo(`/user-management/users?op=resetpassword&id=${userId}`);
                }}
              >
                Change Password
              </StyledButton>
            )}
        </Box>
      </PageHeader>
      {(() => {
        switch (operation) {
          case "resetpassword":
            return (
              <>
                <CustomTypography
                  sx={{ mb: 3 }}
                  content="Change Password"
                  size={20}
                  color="#000"
                  weight={700}
                />
                {isAuthorised(features?.user, permissions?.edit) && (
                  <Box sx={{ maxWidth: "350px" }} >
                    <ResetCred
                      handlePValueChange={handlePValueChange}
                      isdisabled={false}
                      onSubmit={(e) => {
                        handleSubmit();
                      }}
                      pArray={resetPValueArray}
                      resetPValue={resetPValue}
                      buttonLabel="Change Password"
                    />
                  </Box>
                )}
              </>
            );
          default:
            return (
              <>
                <Box pt={"30px"} width={"auto"} marginBottom={"23px"}>
                  <StyledTabs
                    tabs={tabs}
                    onClick={(event, index) => {
                      if (operation !== "create") {
                        //// edit and view operation
                        setBtn1Label("Edit");
                        setUserDetails({ ...originalDetails });
                        history.push(
                          `/user-management/users?op=view&id=${userId}`
                        );
                      }
                      setStepCount(index + 1);
                    }}
                  />
                </Box>
                <Box sx={{ p: 1 }}>
                  {(() => {
                    switch (stepCount) {
                      case 2:
                        return (
                          <Permissions
                            page={operation}
                            stepCount={stepCount}
                            userId={userId}
                            setUserScope={setUserScope}
                          />
                        );
                      case 3:
                        return (
                          <ActivityLog
                            name={userDetails?.username}
                            forEntity={"User"}
                            activityLogs={userLogs}
                          />
                        );
                      default:
                        return (
                          <PersonalDetails
                            page={operation}
                            btn1Label={btn1Label}
                            stepCount={stepCount}
                            handleSubmit={handleSubmit}
                            handleButton1={(op, sc) => {
                              handleButton1(op, sc);
                            }}
                            onChange={setFrm}
                            userDetails={userDetails}
                            portal={portal}
                          />
                        );
                    }
                  })()}
                </Box>
                {isAuthorised(features?.user, permissions?.edit) &&
                  <Box sx={{ width: "100%", textAlign: "right" }}>
                    {stepCount === 2 && operation !== "create" && (
                      <StyledButton
                        sx={{ ml: 2 }}
                        variant={
                          btn1Label === "Cancel" ? "outlined" : "contained"
                        }
                        fullWidth={false}
                        onClick={(e) => handleButton1(operation, stepCount)}
                      >
                        {btn1Label}
                      </StyledButton>
                    )}

                    {stepCount === 2 &&
                      (operation === "create" || operation === "edit") && (
                        <StyledButton
                          sx={{ ml: 2 }}
                          fullWidth={false}
                          onClick={(data) => {
                            handleSubmit();
                          }}
                        >
                          {operation === "create" || (operation === "edit" && uId)
                            ? "Save"
                            : "OK"}
                        </StyledButton>
                      )}
                  </Box>}
              </>
            );
        }
      })()}
      {/* Delete Popup */}
      <StyledPopup
        open={openDeletePopup}
        onClose={() => setOpenDeletePopup(false)}
        state="timeout"
        data={{
          title: "Delete User?",
          content: `Are you sure you want to delete user ${userDetails?.username} ?`,
          label: "Warning",
          actions: [
            {
              id: "1001",
              type: "button",
              label: "Cancel",
              styles: { minWidth: 100 },
              onClick: (event, data) => {
                setOpenDeletePopup(false);
              },
            },
            {
              id: "1001",
              type: "button",
              label: "OK",
              styles: { minWidth: 100 },
              onClick: (event, data) => {
                handleDeleteBtn(event);
              },
            },
          ],
        }}
        customsx={{
          label: {
            color: "red",
            fontWeight: 700,
            fontSize: "0.9375rem",
            paddingBottom: "5px",
          },
          btns: { display: "flex", justifyContent: "center" },
        }}
      />
      {/* Deactivate Popup */}
      <StyledPopup
        open={openDeActivatePopup}
        onClose={() => setDeActivatePopup(false)}
        state="timeout"
        data={{
          title: "Disable User?",
          content: `Are you sure you want to disable the user ${userDetails?.username} ?`,
          label: "Warning",
          actions: [
            {
              id: "1001",
              type: "button",
              label: "Cancel",
              styles: { minWidth: 100 },
              onClick: (event, data) => {
                setDeActivatePopup(false);
              },
            },
            {
              id: "1001",
              type: "button",
              label: "OK",
              styles: { minWidth: 100 },
              onClick: (event, data) => {
                handleDeActivateBtn(event);
              },
            },
          ],
        }}
        customsx={{
          label: {
            color: "red",
            fontWeight: 700,
            fontSize: "0.9375rem",
            paddingBottom: "5px",
          },
          btns: { display: "flex", justifyContent: "center" },
        }}
      />
    </Box>
  );
};

AddEditUser.propTypes = {};

AddEditUser.defaultProps = {};

export default AddEditUser;
