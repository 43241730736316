/***
 *
 * Controller class for user.
 * @file Footer.js
 * @description Footer component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './Footer.style.js';
import './Footer.scss';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import ImageWrapper from '../ImageWrapper/index.js';
import LOGO from "../../assets/icons/miraie-logo.svg";
import classNames from 'classnames';

const Footer = () => {
  const classes = useStyles()

  return (
    <Box className={classNames((classes?.Footer || ""), "footer-fixed-1")} sx={{ display: { xs: 'none', sm: 'block' } }} data-testid="Footer">
      <Grid container item sx={{alignItems: 'center'}} xs={12}>
        {/* <Grid item md={7} xs={12} >
          <span className={classes.copyRight}>© 2024 - Panasonic Life Solutions India Pvt. Ltd. All rights reserved</span>
        </Grid> */}
        <Grid sx={{ textAlign: "right" }} item md={12} xs={12}>
          <Box className={classes?.poweredBy} style={{ display: "inline-flex", width: "max-content", alignItems: "flex-end"}}>
            <Typography component="label" sx={{ fontSize: '0.75rem', minWidth: "max-content", marginRight: "8px", marginBottom: "0px", lineHeight: "12px"}}>Powered by:</Typography>
            <IconButton
              href="/"
              className="logo"
              color="inherit"
              aria-label="open drawer"
              sx={{ padding: "0px" }}>
              <ImageWrapper className="imageLogo" src={LOGO} alt="logo" />
            </IconButton>
            <span className={classes?.vBar || ""} />
            <Typography variant="h6" noWrap component="div" className="title" style={{ width: '100%' }}>
              Spatial Solutions
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
};

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
