/***
 *
 * Controller class for user.
 * @file StyledFileUpload.js
 * @description StyledFileUpload component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useRef, useState } from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './StyledFileUpload.style.js';
import './StyledFileUpload.scss';
import { Box, List, ListItem } from '@mui/material';
import UploadIcon from '../../assets/icons/upload.svg';
import Attachment from '../../assets/icons/attachment.svg';
import PdfIcon from '../../assets/icons/pdf-icon.svg';
import WordIcon from '../../assets/icons/word-icon.svg';
import ImgIcon from '../../assets/icons/png-icon.png';
import PptIcon from '../../assets/icons/ppt-Icon.svg';
import DwgIcon from '../../assets/icons/dwg-icon.svg';
import TextFileIcon from '../../assets/icons/file-icon.svg';
import DeleteIcon from '../../assets/icons/delete-2.svg';
import GreenInfo from '../../assets/icons/error.svg';
import ExcelIcon from '../../assets/icons/excel-icon.svg';
import ErrorMessage from '../ErrorMessage/index.js';
import StyledPopup from '../StyledPopup/index.js';
import ImageWrapper from '../ImageWrapper/index.js';
import CustomTypography from '../CustomTypography/index.js';
import _ from 'lodash';
import PopoverContainer from '../PopoverContainer/index.js';
import StyledButton from '../StyledButton/index.js';
import { useSelector } from 'react-redux';
import useWindowDimensions from '../../hooks/useWindowDimensions.js';

const ImgObj = {
  'pdf': PdfIcon,
  'txt': TextFileIcon,
  'docx': WordIcon,
  'pptx': PptIcon,
  'xslv': ExcelIcon,
  'xls': ExcelIcon,
  'xlsx': ExcelIcon,
  'dwg': DwgIcon,
  'png': ImgIcon,
  'jpg': ImgIcon,
  'jpeg': ImgIcon,
  'jif': ImgIcon
}

const StyledFileUpload = ({
  id = "file",
  name = "file-upload",
  error,
  helper = null,
  placeholder = "Select or Drag and Drop File(s).",
  allowedFileType,
  multiple = false,
  attachment = false,
  position = "top",
  disabled = false,
  message = "File Uploaded Successfully!",
  customsx = {},
  deleteConfirmation = false,
  fileObj,
  preview={},
  onClick = () => { },
  handleChange = () => { },
  onDelete = () => { }
}) => {
  const classes = useStyles();
  const [, setAnchorEl] = React.useState(null);
  const [, setOption] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});
  const [tooltip, setTooltip] = useState({ show: false });
  const [showIconToolTip, setShowIconToolTip] = useState(true);
  const _sideMenu= useSelector(state=>state?.app?.sideBarOpen)
  const windowDimensions=useWindowDimensions();
  const fileBox = useRef();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = (item, key) => {
    console.log("delete image---", item, key);
    if (deleteConfirmation) {
      setOpenDialog(true);
      setDeleteItem({ item: item, key: key });
    } else {
      onDelete(item, key);
    }
  }

  const handleMouseEnter = (event, content) => {
    setTooltip({
      show: true,
      anchorElement: event.currentTarget,
      content: content
    })
  }
  const handleMouseLeave = () => {
    setTooltip(null);
  }

  // useEffect(() => {
  //   // console.log('file obj====>', fileObj);
  //   if (fileObj) {
  //     setOption(null);
  //   }
  // }, [fileObj, error])

  // useEffect(() => {
  //   // console.log('uploaddddd---',files);
  // }, [files])

  return (
    <Box className={classes?.StyledFileUpload || ""} data-testid="StyledFileUpload">
      {/* {JSON.stringify(multiple)} --  */}
      {/* {JSON.stringify(attachment)} --  */}
      {/* -- {JSON.stringify(position)}  */}
      {/* -- {JSON.stringify(fileObj[name])} */}
      {/* ------------- Uploaded file list ------------ */}
      {/* { JSON.stringify(disabled) } - ggggg */}
      {(multiple && position === "top") &&
        <Box className='fileBoxUpload hideTrack'  
        onScroll={()=>{ setTooltip(null); 
          setShowIconToolTip(false);
        }}
        ref={fileBox} name={name} sx={{width:'max-content', maxHeight:'150px', mb:'10px', overflowY: fileBox?.current?.clientHeight >=150 ? 'scroll': 'hidden' }}>
          <List className="uploaded-list">
            {!_.isEmpty(fileObj[name]) && fileObj[name].map((item, key) => (
              <ListItem key={'file' + key} className="uploaded-list-item">
                <Box className="item-kkk">
                  <Box className="icon-kkk">
                    {attachment ? (
                      <ImageWrapper key={"img--" + key} src={ImgObj[item?.extn]} height={35} width={35} />
                    ) : (
                      <ImageWrapper key={"img--" + key} src={item?.isImg ? item?.data : (ImgObj[item?.extn] || "")} height={20} width={30} />
                    )}
                    <CustomTypography className="text-kkk" styles={{maxWidth: _sideMenu && windowDimensions?.width<515 ? '100px':'180px'}} content={item?.name || ''} onMouseEnter={(e) => handleMouseEnter(e, item?.name)} onMouseLeave={handleMouseLeave} />
                  </Box>
                  <StyledButton iconButton tooltip={showIconToolTip && 'Delete'} onMouseEnter={()=>{setShowIconToolTip(true) }} disabled={disabled} className="delete-icon-kkk" onClick={() => { handleDelete(item, key) }}> <img src={DeleteIcon} alt="Delete"/></StyledButton>
                </Box>
              </ListItem>
            ))}
          </List>
        </Box>
      }
      {/* ------------- File dropable or selectable area ------------- */}
      <Box
        id={`outerdiv__${id}`}
        className={classes.DropableArea}
        sx={{ borderColor: `${error ? "#FF4336" : "#D9D9D9"}`, ...customsx.outerdiv }}
        name={name}
      >
        {/* ------------- Uploaded file(s) ------------ */}
        {(!multiple && fileObj && fileObj[name]) && fileObj[name].map((item, key) => (
          <>
            <ImageWrapper name={name} key={"img--" + key} src={item?.isImg ? item?.data : (ImgObj[item?.extn] || "")} width={preview?.width ||  "100px"} style={{ margin: "2px", maxHeight: preview?.maxHeight || "100px" }} />
            <StyledButton iconButton tooltip={showIconToolTip && 'Delete'} onMouseEnter={()=>{setShowIconToolTip(true) }} disabled={disabled} className="delete-icon" onClick={() => { handleDelete(item, key) }}> <img src={DeleteIcon} alt="Delete" /></StyledButton>
          </>
        ))}

        {/* ------------- Upload icon and placeholder it will be shown if file not selected ------------ */}
        {
          ((fileObj && fileObj[name]?.length > 1) || (_.isEmpty(fileObj[name])) || multiple) &&

          <Box name={name} className={(!multiple && fileObj && fileObj[name] && fileObj[name]?.length > 0) ? "uDefault uploadDefault-hover" : "uDefault uploadDefault"} onClick={(e) => { 
            setOption('upload'); 
            onClick(); 
            handleClose()
           }}>
            <ImageWrapper name={name} className="icon" src={attachment ? Attachment : UploadIcon} alt="upload-icon" style={{ borderRadius: '5px', opacity: disabled ? 0.5 : 1 }} />
            <label className="placeholder">{placeholder}</label>
          </Box>
        }

        {/* ------------- file type form input to open file dialogbox for selecting file or files ---------- */}
        <input        
          type={"file"}
          accept={allowedFileType}
          name={name}
          multiple={multiple}
          id={`input__${id}`}
          hidden
          onChange={(e) => {
            if (!multiple && fileObj && fileObj[name] && fileObj[name]?.length > 0) {
              // onDelete(fileObj[name][0], 0);
            }
            setTimeout(() => {
              handleChange(e);
              e.target.value='' ; /// to allow same file upload again one after another
            }, 0);
          }}
          disabled={(!multiple && fileObj && fileObj[name] && fileObj[name]?.length === 1) || (disabled===true)?true:false}
        />

      </Box>

      {/* -------------- Error and helper text area to show message ------------- */}
      {error && <ErrorMessage message={message} />}
      {helper?.text && <ErrorMessage src={GreenInfo} color={helper?.color} sx={{ ...helper?.sx }} message={helper?.text} />}

      {/* ------------- Uploaded file list ------------ */}
      {(multiple && position === "bottom") &&
        <Box className='fileBoxUpload hideTrack'  
        ref={fileBox} name={name} sx={{width:'max-content', maxHeight:'150px', mb:'10px', overflowY: fileBox?.current?.clientHeight >=150 ? 'scroll': 'hidden' }}>
          <List className="uploaded-list">
            {!_.isEmpty(fileObj[name]) && fileObj[name].map((item, key) => (
              <ListItem key={'file' + key} className="uploaded-list-item">
                <Box className="item-kkk">
                  <Box className="icon-kkk">
                    <ImageWrapper key={"img--" + key} src={item?.isImg ? item?.data : (ImgObj[item?.extn] || "")} height={20} width={30} />
                    <CustomTypography className="text-kkk" content={item?.name || ''} onMouseEnter={(e) => handleMouseEnter(e, item?.name)} onMouseLeave={handleMouseLeave} />
                  </Box>
                  <StyledButton iconButton tooltip={showIconToolTip && 'Delete'} onMouseEnter={()=>{setShowIconToolTip(true) }} disabled={disabled} className="delete-icon-kkk" onClick={() => { handleDelete(item, key) }}> <img src={DeleteIcon} alt="Delete" /></StyledButton>
                </Box>
              </ListItem>
            ))}
          </List>
        </Box>
      }

      {/* --------------------- Confirmation on deletion Dialog box ------------------- */}
      {deleteConfirmation && openDialog &&
        <StyledPopup
          open={openDialog} onClose={() => { setOpenDialog(false); setDeleteItem({}) }} state="timeout"
          data={{
            title: "Delete file?",
            content: `Are you sure you want to delete the file ${deleteItem?.item?.name} ?`,
            label: 'Warning',
            actions: [
              {
                id: "1001",
                type: "button",
                label: "Cancel",
                styles: { minWidth: 100 },
                onClick: (event, data) => {
                  setOpenDialog(false);
                  setDeleteItem({});
                }
              }, {
                id: "1001",
                type: "button",
                label: "OK",
                styles: { minWidth: 100 },
                onClick: (event, data) => {
                  onDelete(deleteItem.item, deleteItem.key);
                  setDeleteItem({});
                  setOpenDialog(false);
                }
              }
            ]
          }}
          customsx={{ label: { color: 'red', fontWeight: 700, fontSize: '0.9375rem', paddingBottom: '5px' }, btns: { display: 'flex', justifyContent: 'center' } }}
        />
      }

      {/* -------------- Tooltip ------------ */}
      <PopoverContainer anchorEl={tooltip?.anchorElement || null} onClose={() => setTooltip(null)}>
        <Box display={"flex"} alignItems={"center"}>
          {tooltip?.content}
        </Box>
      </PopoverContainer>
    </Box>
  )
};

StyledFileUpload.propTypes = {};

StyledFileUpload.defaultProps = {};

export default StyledFileUpload;
