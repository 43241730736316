import _ from "lodash";

export const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export const ScrollToTop = (top = 0) => {
    window.scrollTo({
        top: top,
        left: 0,
        behavior: "smooth"
    });
}

export const deepCopy = (data) => {
    if (data) {
        return JSON.parse(JSON.stringify(data));
    } else {
        return data;
    }
}
export const isInList = (items = [], item) => {
    return items.includes(item);
}

export const delay = ms => {
    return new Promise(res => setTimeout(res, delay));
}

export const containsSpace = (string) => {
    return /.*\s.*/.test(string);
}

export const updateSpaceTreeNode = (list = [], spaceId, { key = null, data = null, method = null, action = null, removeId = null }) => {
    return list?.map((ch, index) => {
        if (ch?.spaceId === spaceId) {
            if (key) {
                // console.log("updateSpaceTreeNode: step 1", spaceId, removeId, key, method, data, action);
                if (method === "push") {
                    if (!ch[key]) { ch[key] = []; }
                    ch[key] = [...ch[key], ...[data]];
                    // console.log("updateSpaceTreeNode: step 2", ch[key]);
                } else if (method === "remove") {
                    // console.log("updateSpaceTreeNode: step 3.0", ch[key], removeId);
                    if (Array.isArray(ch[key]) && ch[key].length > 0) {
                        // console.log("updateSpaceTreeNode: step 3.1");
                        ch[key].forEach((c, i) => {
                            // console.log("updateSpaceTreeNode: step 3.2", c, i, removeId);
                            if (c.spaceId === removeId) {
                                // console.log("updateSpaceTreeNode: step 3.4", c.spaceId, removeId, i);
                                ch[key].splice(i, 1);
                            }
                        })
                    }

                    // console.log("updateSpaceTreeNode: step 3.5", ch[key]);
                } else {
                    ch = data;
                    // console.log("updateSpaceTreeNode: step 4", ch[key]);
                }
            } else {
                ch = data;
            }
        } else {
            ch["Spaces"] = updateSpaceTreeNode(ch?.Spaces, spaceId, { key, data, method, action, removeId });
        }
        return ch;
    })
}
export const findNode = (list = [], children, { key, value }, cb = (res) => { }) => {
    let node = {};
    if (!Array.isArray(list)) { list = [list]; }
    for (let ch of list) {
        if (ch[key] === value) {
            // console.log("FindNode --- ", key, value, ch);
            cb(ch);
            // return ch;
            node = ch;
            break;
        } else {
            // console.log("FindNode child --- ", key, value, ch);
            findNode(ch[children], children, { key, value }, cb);
        }
    }

    // console.log("FindNode --- ", key, value, node);
    return node;
}

export const findHierarchyRoute = async ({ data, childrenKey, find, output }, outPutArr = [], cb = (res) => { }) => {
    // const node = await findNode(data, childrenKey, { ...find });

    findNode(data, childrenKey, { ...find }, (res) => {

    });

    // for(let ch of data){
    //     if(ch[find?.key] === find?.value) {
    //         let obj ={}; 
    //         await (()=>{
    //             output?.forEach(o=>{
    //                 obj[o] = ch[o];
    //             })
    //         })()
    //         outPutArr.push(obj);
    //     } else {
    //         await findNode({ data: ch[childrenKey], childrenKey, find, output }, outPutArr, cb);
    //     }
    // }
    cb(outPutArr);
}

export const hexToRgbA = (hex, transparency = 1) => {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + transparency + ')';
    }
    return 'rgba(0,0,0,0.5)';
}

export const newHeight = (originalHeight, originalWidth, newWidth) => {
    // console.log("newHeight --- ", originalHeight, originalWidth, newWidth, (originalHeight / originalWidth) * newWidth);
    return (originalHeight / originalWidth) * newWidth;
}

export const newWidth = (originalHeight, originalWidth, newHeight) => {
    return (originalWidth / originalHeight) * newHeight;
}

export const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
        reader.readAsDataURL(file);
    });
}
export const toDataURL = url => fetch(url)
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(blob)
    }))

export function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}

export const getImageMeta = (url, cb) => {
    const img = new Image();
    img.onload = () => cb(null, img);
    img.onerror = (err) => cb(err);
    img.src = url;
}

export const text2num = (string) => {
    return parseInt(string.split('').map(function (char) {
        return char.charCodeAt(0).toString(2);
    }).join(''));
}

export const getAvaragePoint = (points) => {
    let totalX = 0;
    let totalY = 0;
    for (let i = 0; i < points.length; i += 2) {
        totalX += points[i];
        totalY += points[i + 1];
    }
    return {
        x: totalX / (points.length / 2),
        y: totalY / (points.length / 2),
    };
};

export const getDistance = (node1, node2) => {
    let diffX = Math.abs(node1[0] - node2[0]);
    let diffY = Math.abs(node1[1] - node2[1]);
    const distaneInPixel = Math.sqrt(diffX * diffX + diffY * diffY);
    return Number.parseFloat(distaneInPixel).toFixed(2);
};


export function validateIP(ipAddress) {
    if (_.isEmpty(ipAddress)) return true;

    if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipAddress)) {
        return (true)
    }
    return (false)
}

export function validateMacAddress(macId) {

    if (_.isEmpty(macId)) return true;

    return (/^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})|([0-9a-fA-F]{4}\\.[0-9a-fA-F]{4}\\.[0-9a-fA-F]{4})$/.test(macId))
}

export const getUrl = (url, dataObj = {}, params) => {
    let paramsStr = "";
    if (params) {
        paramsStr = "&";
        if (!url.includes("?")) {
            paramsStr = "?";
        }
        let delim = "";
        for (let key in params) {
            paramsStr = paramsStr + delim + key + "=" + dataObj[params[key]];
            delim = "&";
        }
    }

    return url + paramsStr;
}

const descendingComparator = (a, b, orderBy) => {
    if (a && b && orderBy) {
        // console.log("sorttt",orderBy,a[orderBy],b);
        //   if (b[orderBy] < a[orderBy]) {
        //     return -1;
        //   }
        //   if (b[orderBy] > a[orderBy]) {
        //     return 1;
        //   }
        if (!isNaN(Number(a[orderBy])) && !isNaN(Number(b[orderBy]))) {
            let numA = Number(a[orderBy]);
            let numB = Number(b[orderBy]);
            if (numB < numA) {
                return 1;
            } else if (numB > numA) {
                return -1;
            } else {
                return 0;
            }
        }
        else if (!isNaN(Date.parse(a[orderBy])) && !isNaN(Date.parse(b[orderBy]))) {
            let dateA = new Date(a[orderBy]);
            let dateB = new Date(b[orderBy]);
            if (dateB < dateA) {
                return 1
            }
            else if (dateB > dateA) {
                return -1;
            }
            else return 0;
        }
        else {
            let strA = Array.isArray(a[orderBy]) ? a[orderBy].join('') : a[orderBy] || '',  /// if data is coming in array, first make them a string then compare. 
                strB = Array.isArray(b[orderBy]) ? b[orderBy].join('') : b[orderBy] || '';    ///  if data is coming undefined, assign empty string to it
            return strA?.localeCompare(strB, 'en', { sensitivity: 'base' });
        }

    }
    return 0;
}

const getComparator = ({ order, orderBy }) => {
    return order === 'desc'
        ? (a, b) => -descendingComparator(a, b, orderBy)
        : (a, b) => descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
export const tableSort = (array, obj) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = getComparator(obj)(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export const getExtn = (str) => {
    let lastIndex = str.lastIndexOf(".");
    return str.substring(lastIndex + 1);
}

export const uuStr = (length = 16) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
export const getValueFromArray = (arr, key) => {
    //to find value from array of objects
    if (!arr || _.isEmpty(arr)) return "";
    const obj = arr.find((item) => item?.key === key);
    return obj?.value ? obj?.value : "";
}

export const parseBoolean = (str) => {
    return str === "true" ? true : false
}

export const formatString = (str = "", type = "wordBreak") => {
    switch (type) {
        case "wordBreak":
            return str?.split(/(?=[A-Z])/)?.join(" ");
        case "capitalize":
            return str?.substring(0, 1)?.toUpperCase() + str?.substring(1, str?.length)
        default:
            return str;
    }
}
