/***
 *
 * Controller class for user.
 * @file ChipInput.js
 * @description ChipInput component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import { Autocomplete, Box, FormControl, Grid, Input, TextField } from '@mui/material';
// import SearchIcon from '@mui/icons-material/Search';
import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './ChipInput.style.js';
import StyledChip from '../StyledChip/index.js';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import ClearIcon from '@mui/icons-material/Clear';
import _ from 'lodash';
import './ChipInput.scss';
import CustomDropDown from '../CustomDropDown/index.js';
import StyledDateTimePicker from '../StyledDateTimePicker/index.js';
import { convertTSIntoDate, getTimeFormat } from '../../helpers/users.helper.js';
import StyledButton from '../StyledButton/index.js';


// type = autocomplete | text
const ChipInput = ({ id = "chip-input", placeholder = "Search", error, helperText, name, page, disabled, filterSelectedOptions = true, value, data,
  options = [], columns = { id: 1, name: "name" }, style, width = "200px", sx = {}, rounded = false, chipPosition = "right", message,
  componentType = "autocomplete",
  addBtn = false,
  addBtnStyle = {},
  componentProps = {
    select: { defaultValue: '', isNoneAsItem: false, createNewButton: false },
    datetime: { format: "DD/MM/YYYY", placeholder: '', defaultValue: {}, picker: 'datePicker', sx: {}, style: {} }
  },
  onChange = () => { }, onDelete = () => { } }) => {
  const classes = useStyles()
  const [chips, setChips] = useState([]);
  const [inputValue, setInputValue] = useState(null);
  const [textInput, setTextInput] = useState('');
  const [presedKey, setPresedKey] = useState(null);
  const [autoCompleteEvent, setAutoCompleteEvent] = useState(null);
  const [selectValue, setSelectValue] = useState(null);
  const [dateValue, setDateValue] = useState(new Date());
  const [timeValue, setTimeValue] = useState(null);
  const [dropDownOptions, setDropDownOptions] = useState(options);

  useEffect(() => {
    // console.log('input value::', inputValue, autoCompleteEvent?.type);
  }, [autoCompleteEvent])

  useEffect(() => {
    if (page === 'view') {
      setTextInput('');
      setInputValue(null);
      setDateValue(null);
      setTimeValue(null);
      setSelectValue(null);
    }
  }, [page, componentType])

  const handleChange = (event, value, reason) => {

    if (componentType === 'autocomplete' || componentType === 'autoComplete') {
      if (!_.isEmpty(value)) {

        if (_.isEmpty(chips)) {
          setChips([value]);
          // onChange(event, name, [value?.label]);
          onChange({
            target: {
              name: name,
              value: [value?.label]
            }
          })

        }
        else if (value && chips && _.isEmpty(chips.filter(ch => ch.id === value.id))) {
          setChips([...chips, value]);
          // onChange(event, name, [...chips.map(ch => ch?.label), value?.label]);
          onChange({
            target: {
              name: name,
              value: [...chips.map(ch => ch?.label), value?.label]
            }
          })
        }
        // console.log("chips ---- ", event, value, event?.type, reason);
        if (reason === 'selectOption') {
          setInputValue(value);
        }
        setPresedKey(event?.key);
        setAutoCompleteEvent(event);
      }
    }
    else if (componentType === 'select') {
      setSelectValue({ id: event.target?.value, label: event.target.value });
    }
    else if (componentType === 'datetime') {
      if (componentProps[componentType]?.picker === 'datePicker') {
        setDateValue(event.target.value);
      }
      else if (componentProps[componentType]?.picker === 'TimePicker') {
        setTimeValue(event.target.value);
      }
    }

  }

  const deleteChip = (event, item) => {
    let filteredChips = chips?.filter(chip => chip?.id !== item?.id)
    // onChange(autoCompleteEvent, name, [...filteredChips.map(ch => ch?.label)]);
    onChange({
      target: {
        name: name,
        value: [...filteredChips.map(ch => ch?.label)]
      }
    })
    setChips([...filteredChips]);
    onDelete(item);
  }


  useEffect(() => {
    if (Array.isArray(value) && !_.isEmpty(value)) {
      let chiparr = value?.map(item => { return { id: item, label: item } })
      setChips([...chiparr]);
    }
    else if (Array.isArray(value) && _.isEmpty(value)) {
      setChips([]);
      setInputValue(null);
      setTextInput('');
      setDateValue(null);
      setTimeValue(null);
      setSelectValue(null);
    }
  }, [value]);


  const handleAddBtn = (event) => {
    let currValue = null;
    if (componentType === 'select') {
      currValue = selectValue;
      setSelectValue(null);
    }
    else if (componentType === 'datetime' && componentProps[componentType]?.picker === 'datePicker') {
      currValue = { id: convertTSIntoDate(dateValue).split('-').reverse().join('-'), label: convertTSIntoDate(dateValue).split('-').reverse().join('-') };
    }
    else if (componentType === 'datetime' && componentProps[componentType]?.picker === 'TimePicker') {
      currValue = timeValue ? getTimeFormat(timeValue) : null;
      setTimeValue(null);
    }

    if (!_.isEmpty(currValue) && _.isEmpty(chips)) {
      setChips([currValue]);
      // onChange(event, name, [currValue?.label]);
      onChange({
        target: {
          name: name,
          value: [currValue?.label]
        }
      })

    }
    else if (!_.isEmpty(currValue) && chips && _.isEmpty(chips.filter(ch => ch.id === currValue?.id))) {
      setChips([...chips, currValue]);
      // onChange(event, name, [...chips.map(ch => ch?.label), currValue?.label]);
      onChange({
        target: {
          name: name,
          value: [...chips.map(ch => ch?.label), currValue?.label]
        }
      })
    }
  }

  useEffect(() => {
    setDropDownOptions(options?.filter(x => !chips.some(y => x.id === y.id)))
  }, [options, chips])

  return (
    <Box className={classes?.ChipInput || ""} data-testid="ChipInput" sx={{
      display: chipPosition === "right" ? "flex" : "block"
    }}>
      <Grid item xs={12} container display={'flex'} flexWrap={'wrap'}>
        <FormControl
          variant="filled"
          className={classes?.Input || ""}
          sx={{
            width: width || 'auto',
            mr: 2,
            mb: 1,
            ...sx
          }}
          error={error}
        >
          {(() => {
            switch (componentType) {
              case "autocomplete":
              case "autoComplete":
                return (
                  <Autocomplete
                    id="chip-input"
                    getOptionLabel={(option) => {
                      if (presedKey === "Select") {
                        return "";
                      } else {
                        if (option?.hasOwnProperty('label')) {
                          return option?.label
                        } else {
                          return "";
                        }
                      }
                    }}
                    fullWidth
                    width={width}
                    name={name}
                    disabled={disabled}
                    clearOnEscape={true}
                    // filterSelectedOptions={filterSelectedOptions}
                    // getOptionSelected={(option, value) => option.id === value.id}
                    // isOptionEqualToValue={(option) => { return option?.id === inputValue?.id }}
                    options={dropDownOptions ? dropDownOptions : []}
                    renderOption={(props, option) => {
                      return (
                        <Box component="li" name={name} {...props} key={option?.id}>
                          {option?.label}
                        </Box>
                      );
                    }}
                    value={inputValue?.label}
                    inputValue={textInput}
                    // inputValue={autoCompleteEvent && autoCompleteEvent?.type ==='keydown' ? inputValue?.label:""}
                    // inputValue={''}
                    onChange={handleChange}
                    onInputChange={(e, val, reason) => {
                      console.log('input reason', reason);
                      if (reason === 'input')
                        setTextInput(val);
                      else
                        setTextInput('');
                    }}
                    onClose={(event, reason) => {
                      if (reason === 'selectOption') {
                        setTextInput('');
                        return;
                      }
                    }}

                    // onKeyDown={handleKeyDown} 
                    renderInput={(params) => (
                      <TextField
                        helperText={helperText}
                        error={error}
                        className={classes?.AutoComplete || ""}
                        sx={{ '& .MuiOutlinedInput-root': { borderRadius: rounded ? "30px" : "4px" } }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              <StyledButton iconButton size='small' sx={{ display: !_.isEmpty(inputValue) ? "" : "none" }} onClick={() => { setInputValue(null); setTextInput(''); }}><ClearIcon /></StyledButton>
                              {/* <SearchIcon sx={{ marginRight: 0.9 }} edge="end" /> */}
                            </>
                          )
                        }}
                        {...params}
                      />
                    )}

                  />
                );

              case 'select':
                return (
                  <>
                    <CustomDropDown
                      key={"select-" + id}
                      error={error}
                      message={message}
                      value={selectValue?.label || ''}
                      id={id}
                      name={name}
                      fullWidth
                      width={width}
                      onChange={handleChange}
                      onOpen={null}
                      defaultValue={componentProps[componentType]?.defaultValue}
                      isNoneAsItem={componentProps[componentType]?.isNoneAsItem || false}
                      disabled={disabled}
                      list={dropDownOptions || []}
                      createNewButton={componentProps[componentType]?.createNewButton}
                    />
                  </>
                )

              case 'datetime':
                return (
                  <>
                    <StyledDateTimePicker
                      key={"datePicker-" + id}
                      error={error}
                      message={message}
                      style={componentProps[componentType]?.style}
                      name={name}
                      width={width}
                      fullWidth
                      value={(componentProps[componentType]?.format === 'datePicker' ? (dateValue || null) : (timeValue || '')) || ""}
                      picker={componentProps[componentType]?.picker}
                      sx={componentProps[componentType]?.sx}
                      format={componentProps[componentType]?.format}
                      placeholder={componentProps[componentType]?.placeholder}
                      defaultValue={componentProps[componentType]?.defaultValue}
                      disabled={disabled}
                      onChange={handleChange}
                      {...componentProps[componentType]}

                    />
                  </>
                )

              default:
                return (
                  <Input
                    id={id || "chip-input"}
                    type={componentType}
                    value={inputValue}
                    onChange={handleChange}
                    disabled={disabled}
                    // onKeyPress={}
                    endAdornment={
                      <StyledButton iconButton size='small' sx={{ display: !_.isEmpty(inputValue) ? "" : "none" }} onClick={() => { setInputValue(null) }}><ClearIcon /></StyledButton>
                    }
                  />
                );
            }
          })()}

        </FormControl>
        {addBtn && <StyledButton color={disabled ? 'secondary' : 'primary'} size='medium' sx={{ height: "38px", mr: 2 }} style={{ ...addBtnStyle }} onClick={handleAddBtn} disabled={disabled} >Add</StyledButton>
        }

        {chips && chips?.map((chip, index) => {
          return (
            <>
              {
                disabled ?
                  <StyledChip
                    key={index}
                    label={chip?.label}
                  />
                  :
                  <StyledChip
                    key={index}
                    label={chip?.label}
                    onDelete={(e) => deleteChip(e, chip)}
                    deleteIcon={<DeleteIcon style={{ height: '15px' }} />}
                  />
              }
            </>
          );
        })}
      </Grid>
    </Box>
  )
};

ChipInput.propTypes = {};

ChipInput.defaultProps = {};

export default ChipInput;
