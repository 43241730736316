/***
 *
 * Controller class for user.
 * @file PageSetion.js
 * @description PageSetion component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './PageSetion.style.js';
import './PageSetion.scss';
import { Box, Typography } from '@mui/material';

const PageSetion = ({ title, children, bordered, component, contentBg="transparent", LeftIcon, RightIcon,...props}) => {
  const classes = useStyles()

    return (
        <Box className={classes.PageSetion} data-testid="PageSetion">
          <Box className='header' sx={{display: 'flex'}}>
              {LeftIcon && <LeftIcon style={{marginRight: "7px"}} />}
              <Typography sx={{fontWeight: 'bold', fontSize: '0.875rem'}}>{title}</Typography>
              {RightIcon && <RightIcon style={{marginLeft: "7px"}} />}
              {component}
          </Box>
          {children && 
            <Box 
              className={classes?.ContentBox || ""}
              sx={{ 
                marginTop: !bordered?"4px":"0px", 
                border: bordered?"1px rgba(0, 0, 0, 0.1) solid":"none", 
                backgroundColor: contentBg 
              }}
            >
              {children}
            </Box>
          }
        </Box>
    )
};

PageSetion.propTypes = {};

PageSetion.defaultProps = {};

export default PageSetion;
