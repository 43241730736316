/***
 *
 * Controller class for user.
 * @file SwitchButton.js
 * @description SwitchButton component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useStyles, IOSSwitch, DarkMode } from './SwitchButton.style.js';
import './SwitchButton.scss';
import { Box, FormControlLabel, FormGroup, Switch } from '@mui/material';
import { useSelector } from 'react-redux';
import StyledSpinner from '../StyledSpinner/index.js';


const SwitchButton = ({id, name,  type="normal", setToggle=false, value=false, onChange=()=>{},onFrmWrapperChange=()=>{}, deviceId="",dKey="intensity", sx={}, ...props}) => {
  const classes = useStyles()
  const [checked, setChecked] = useState(setToggle);
  const [loading, ] = useState(false)
  const deviceData=useSelector((state)=>state.devices.deviceList.find((device)=>device?.deviceId===deviceId))
  // const loadingStatus=deviceData?.loading
  const toggleChecked = (e, value) => {
    setChecked((prev) =>  !prev);
    onChange(e, value); 
    onFrmWrapperChange({
      target: {
        name:name,
        value: value
      }
    })
  };
  // useEffect(()=>{
  //   console.log("LOADING",loadingStatus)
  //   setLoading(loadingStatus)
  // },[loadingStatus])

  useEffect(()=>{
    setChecked(setToggle);
  },[setToggle])

  useEffect(() => {
    // console.log("DEVICE TOGGLE STATUS",deviceData?.deviceStatus?.status)
    if(deviceData){
    setChecked(deviceData?.deviceStatus?.status? deviceData?.deviceStatus?.status[dKey]>0? true:false : value);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, deviceData])

  return (
    <Box
      className={classes?.SwitchButton || ""}
      sx={sx}
      data-testid="SwitchButton"     
    >    
      <FormGroup>   
        <FormControlLabel
          sx={{margin: 0, width: "max-content"}}
           control={ 
            (()=>{
              switch(type){
                case "darkmode":
                  return(
                   <DarkMode className="darkmode-Switch-Button" sx={{ m: "2px 6px", fontSize: '0.75rem' }} {...props} checked={checked} onChange={(e,value)=>{toggleChecked(e,value)}} />
                    )                
                case "iphone":
                  return(
                    <>
                   { loading?
                      <StyledSpinner/>:
                    <IOSSwitch className="IOS-Switch-Button" sx={{ m: "2px 6px", fontSize: '0.75rem' }} {...props} checked={checked} onChange={(e,value)=>{toggleChecked(e,value)}} />                
                  }
                  </>
                    
                  )  
               default:
                return (
                  <Switch
                    className="white-Switch-Button"
                    style={{ color: "white" }}
                    sx={{"& .IOS-Switch-Button": { margin: "6px", fontSize: '0.75rem'}}}
                    {...props}
                    checked={checked}
                    disableRipple={false}
                    onChange={toggleChecked}
                    name="checkedB"
                  />
                )   
              }
            })()
            
           }    
         />              
      </FormGroup>
    </Box>
  )
};

SwitchButton.propTypes = {};

SwitchButton.defaultProps = {};

export default SwitchButton;
