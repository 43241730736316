import { format } from 'date-fns';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { countryCodes, features } from '../constants';

export const convertDateIntoTS = (string) => {
  var myDate = string;

  var myDate = string

  myDate = myDate.split("-");

  var newDate = new Date(myDate[0], myDate[1] - 1, myDate[2]);

  return newDate

}

export const convertTSIntoDate = (timestamp) => {
  var dat = new Date(timestamp);
  var req_date = dat.getFullYear().toString() + "-";
  var mon = (dat.getMonth() + 1).toString();
  if (mon.length === 1) {
    req_date += "0" + mon + "-";
  } else {
    req_date += mon + "-";
  }

  var dat = new Date(timestamp);
  var req_date = dat.getFullYear().toString() + '-';
  var mon = (dat.getMonth() + 1).toString();
  if (mon.length === 1) {
    req_date += '0' + mon + '-';
  }
  else {
    req_date += mon + '-'
  }

  var da = (dat.getDate()).toString();
  if (da.length === 1) {
    req_date += '0' + da;
  }
  else {
    req_date += da
  }
  return req_date

}

export const getDate = (date) => {
  if (_.isEmpty(date)) return '-';
  // return convertTSIntoDate(Number(date)).split('-').reverse().join('-');
  return format(Number(date), "MMM dd, yyyy");
}

export function stringToColor(string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

export var getInitials = function (string) {
  var names = string.split(' '),
    initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

export function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      width: 30,
      height: 30
    },
    children: getInitials(name),
  };
}

export const getTimeFormat = (time) => {
  let hour = time?.$H
  let minute = time?.$m
  if (minute < 10) {
    minute = "0" + minute;
  }
  if (hour < 10) {
    hour = "0" + hour;
  }
  let timeFormat = {
    id: `${hour}:${minute}`,
    label: hour + ":" + minute
  }
  return timeFormat
}


const useCountdown = (targetDate) => {
  const countDownDate = new Date(targetDate).getTime();

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [days, hours, minutes, seconds];
};

export function getMobileNumber(mobile) {
  if (mobile) {
    let code = '', number = '';
    countryCodes.forEach(item => {
      if (mobile.includes(item?.id)) {
        code = item?.id || '';
        number = mobile.substring(item?.id.length) || '';
        return [code, number];
      }
    })
    return [code, number];
  } else {
    return '';
  }
}

const permissionResponseObjectTemp = {
  name: "operator",
  idp: {
    user: {
      allow: ["view", "manage", "operate"],
    },
    config: {
      allow: ["view", "manage", "operate"]
    },
    token: {
      allow: ["view", "manage", "operate"]
    },
    client: {
      allow: ["view", "manage", "operate"]
    },
    role: {
      allow: ["view", "manage", "operate"]
    }
  },
  app: {
    space: {
      allow: ["view", "manage", "operate"],
    },
    device: {
      allow: ["view", "manage", "operate"],
    },
    ticket: {
      allow: ["view", "manage", "operate"],
    },
    report: {
      allow: ["view", "manage", "operate"],
    },
    alert: {
      allow: ["view", "manage", "operate"],
    },
    config: {
      allow: ["view", "manage", "operate"],
    },
    setting: {
      allow: ["view", "manage", "operate"]
    },
    fota: {
      allow: ["view", "manage", "operate"]
    }
  }
};

export const isAuthorised = (entityName = '', operation = 'routes', portal = "mss") => {
  const allow = {
    add: 'manage',
    edit: 'manage',
    delete: 'manage',
    view: 'view',
    operate: 'operate',
    systemReset: 'manage',
    deviceNameEdit: 'manage',
    moveDevice: 'operate',
    deviceDetails: 'view',
    searchSystemLogs: 'view',
    upgradeFirmware: 'manage',
    factoryReset: 'manage',
    broadcast: 'operate',
    readdress: 'operate',
    commission: 'operate',
    discover: 'operate',
    resetChannel: 'operate',
    configureIntensity: 'operate',
    configureTemperature: 'operate',
    configureSensor: 'manage',
    configureSwitch: 'manage',
    controlGroup: 'manage',
    configureLight: 'manage',
    identifyDevice: 'operate',
    applyScene: 'operate',
    syncGroup: 'operate',
    syncArea: 'operate',
    identifyLight: 'operate',
    identifySensor: 'operate',
    pairing: 'operate',
    addConfig: 'operate',
    scheduleConfig: 'operate',
    configureButtons: 'operate',
    control: 'operate',
    syncScene: 'operate',
    enableDisable: 'operate',
    notify: 'operate',
    resolve: 'operate',
    addComments: 'operate',
    addType: 'manage',
    generate: 'manage',
    download: 'view',
    share: 'manage',
    downloadList: 'manage',
  }

  // const permissionResponseObject = permissionResponseObjectTemp;
  const permissionResponseObject = JSON.parse(localStorage.getItem('user'))?.permissions || {};

  const userPermissions = (portal === "mss") ? {
    ...(permissionResponseObject?.idp ? permissionResponseObject?.idp : permissionResponseObject),
    ...(permissionResponseObject?.app ? permissionResponseObject?.app : permissionResponseObject),
    setting: { allow: ["view", "manage"] },
    alert: { allow: ["view", "manage"] },
    profile: { allow: ["view", "manage"] },
  } : {
    tms: { allow: ["view", "manage"] },
    profile: { allow: ["view", "manage"] },
    user: { allow: ["view", "manage"] },
    role: { allow: ["view", "manage"] },
  }

  if (operation === "routes" && permissionResponseObject) {
    if (!entityName) {
      return true;//will be modified in future
    }

    const isConditional = entityName?.includes('&'); // & -> feature requires all & seperated permissions

    if (isConditional) {
      const separatedFeatures = entityName?.split("&");
      return separatedFeatures.every((item) => userPermissions[features[item]]?.allow?.length > 0)//if every permission is present true is returned. Checks only view permisssion.
    }
    //setting#types|gateways setting is parents, types and gateways are siblings
    //permission can also be space|device
    const isChild = entityName?.includes('#');
    const hs = entityName?.split("#"); // # -> parent has(#) children 
    const ents = hs[hs?.length - 1]?.split("|");
    let ent = entityName;
    let allowedFeatures = [];
    if (ents?.length > 1) {
      ents?.forEach(en => {
        if (en && features[en]) {// child is present in features
          if (isChild) {
            allowedFeatures.push(hs[0]);//push parent
          }
          else {
            allowedFeatures.push(en);//if no parent child relationship
          }
        }
      })

      for (const feature of allowedFeatures) {
        if (userPermissions[features[feature]]?.allow?.length > 0) {//if any of device|space permission is found return true
          return true;
        }
      }
    }

    return userPermissions[features[ent]]?.allow?.length > 0 ? true : false;
  }
  else {
    if (userPermissions[features[entityName]]?.allow?.includes('manage')) {
      return ["view", "operate", "manage"]?.includes(allow[operation]);//cannot return true, will not work if "allow" object has wrong string 
    }
    else if (userPermissions[features[entityName]]?.allow?.includes('operate')) {
      return ["view", "operate"]?.includes(allow[operation]);//the array represents the permissions the user has
    }
    else {
      return userPermissions[features[entityName]]?.allow?.includes(allow[operation]);
    }
  }
}

export { useCountdown };
