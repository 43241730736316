import { failure, getLocalItem, getMessage, request, setLocalItem, success } from "../../helpers";
import { delay, notificationService } from "../../services";
import { notificationTypes } from "../types";
import { appActions } from "./app.actions";

const subscribeNotification = (token) => {
    return dispatch => {
        dispatch(request(notificationTypes.SUBSCRIBE_NOTIFICATION_REQUEST));
        notificationService.subscribeNotification(token)
            .then(
                ({ data, status }) => {
                    const miraieObj = JSON.parse(getLocalItem("miraie"));
                    setLocalItem('miraie', JSON?.stringify({ ...miraieObj, notificationToken: token }));
                    dispatch(success(notificationTypes.SUBSCRIBE_NOTIFICATION_SUCCESS, { payload: token }));
                },
                ({ error, code }) => {
                    dispatch(failure(notificationTypes.SUBSCRIBE_NOTIFICATION_FAILURE, error?.toString()));
                    getMessage(`subscribeNotification-post-${code}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    })
                }
            );
    };
};
const unsubscribeNotification = (token) => {
    return dispatch => {
        dispatch(request(notificationTypes.UNSUBSCRIBE_NOTIFICATION_REQUEST));
        notificationService.unsubscribeNotification({ token })
            .then(
                ({ data, status }) => {
                    const miraieObj = JSON.parse(getLocalItem("miraie"));
                    delete miraieObj?.notificationToken;
                    setLocalItem('miraie', JSON.stringify({ ...miraieObj }));
                    dispatch(success(notificationTypes.UNSUBSCRIBE_NOTIFICATION_SUCCESS, { payload: token }));
                },
                ({ error, code }) => {
                    dispatch(failure(notificationTypes.UNSUBSCRIBE_NOTIFICATION_FAILURE, error?.toString()));
                    getMessage(`unsubscribeNotification-post-${code}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    })
                }
            );
    };
}

export const notificationActions = {
    subscribeNotification,
    unsubscribeNotification
}