export const groupMessages =(item) =>{
    return{
    'group-add-noDevicesSelected': 'Please select the device(s) to proceed further.',
    'group-add-removeInactiveDevices': 'Please remove inactive device(s) marked in red color.',
    'group-add-groupNameEmpty': 'Group name is required',
    'group-add-groupNameMinLen': 'Group name can have minimum 2 characters.',
    'group-add-groupNameMaxLen': 'Group name can not exceed more than 30 characters.',
    'group-add-groupNameInvalid': 'Group name is invalid',
    'group-getAll-noGroup': "There is no Group(s) available",
    "group-getAll-createGroup":", please create a new group.",
    'group-delete-confirmation':`Are you sure you want to delete ${item} group?`,
    "group-getParent-error": `Unable to fetch details of the  ${item} that manages this group.`
}}

export const groupAPIMessages = {
    "groupById-get-200": "Group details successfully retrieved.",
    "groupById-get-401": "Unable to retrieve the Group, please check your access and try again.",
    "groupById-get-403": "Unable to retrieve the Group, please check your access and try again.",
    "groupById-get-404": "Unable to retrieve the Group, please try again later.",
    "groupById-get-500": "Unable to retrieve the Group due to internal error, please try again later.",
    "groupBySpace-get-200": "Group(s) successfully retrieved.",
    "groupBySpace-get-401": "Unable to retrieve Group(s), please check your access and try again.",
    "groupBySpace-get-403": "Unable to retrieve Group(s), please check your access and try again.",
    "groupBySpace-get-404": "Unable to retrieve the space, please try again later.",
    "groupBySpace-get-500": "Unable to retrieve the Group(s) due to internal error, please try again later.",
    "group-post-200": "Group successfully created.",
    "group-post-201": "Group successfully created.",
    "group-post-401": "Unable to add the Group, please check your access and try again.",
    "group-post-404": "Unable to get the Device(s), please try again later.",
    "group-post-403": "Unable to create the Group, please check your access and try again.",
    "group-post-500": "Unable to create the Group due to internal error, please try again later.",
    "group-post-400": "Unable to create the Group, please reverify your inputs and try again.",
    "group-put-200": "Group successfully updated.",
    "group-put-400": "Unable to edit the Group, please reverify your inputs and try again.",
    "group-put-401": "Unable to edit the Group, please check your access and try again.",
    "group-put-403": "Unable to edit the Group, please check your access and try again.",
    "group-put-404": "Unable to edit the Group, please try again later.",
    "group-put-500": "Unable to edit the Group due to internal error, please try again later.",
    "group-delete-200": "Group successfully deleted.",
    "group-delete-204": "Group successfully deleted.",
    "group-delete-401": "Unable to delete the Group, please check your access and try again.",
    "group-delete-403": "Unable to delete the Group, please check your access and try again.",
    "group-delete-404": "Unable to delete the Group, please refresh the page and try again.",
    "group-delete-500": "Unable to delete the Group due to internal error, please try again later.",
    "controlGroup-put-200": "Group Control request is accepted, it may take a while to get completed.",
    "controlGroup-put-404": "Unable to retrieve the Group, please try again later.",
    "controlGroup-put-405": "Unable to send group control request, please try again later.",
    "groupByDevice-get-200": "Group(s) successfully retrieved.",
    "groupByDevice-get-401": "Unable to retrieve the Group(s), please check your access and try again.",
    "groupByDevice-get-400": "Unable to retrieve the Group(s), please try again later.",
    "groupByDevice-get-403": "Unable to retrieve the Group(s), please check your access and try again.",
    "groupByDevice-get-404": "Unable to retrieve the Device, please try again later.",
    "groupByDevice-get-500": "Unable to retrieve the Group(s) due to internal error, please try again later.",
   
    "groupSync-put-200": "Group sync operation is accepted, it may take a while to get completed.",
    "groupSync-put-401": "Group Sync operation is failed, please check your access and try again.",
    "groupSync-put-403": "Group Sync operation is failed, please check your access and try again.",
    "groupSync-put-404": "Group Sync operation is failed, please refresh the page and try again.",
    "groupSync-put-412": "Group Sync operation is failed, please refresh the page and try again.",
    "groupSync-put-500": "Group Sync operation is failed due to internal error, please try again later.",
    

    "syncGroupsByDevice-put-200": "Group(s) Sync request initiated successfully, it may take while to get completed.",
    "syncGroupsByDevice-put-401": "Group Sync request is failed to initiate, please reverify your access and try again.",
    "syncGroupsByDevice-put-403": "Group Sync request is failed to initiate, please reverify your access and try again.",
    "syncGroupsByDevice-put-404": "Group Sync request is failed to initiate, please try again later.",
    "syncGroupsByDevice-put-412": "Group Sync operation is failed, please try again later.",
    "syncGroupsByDevice-put-500": "Group Sync request is failed to initiate due to internal issue, please try again later.",
    
    "deleteGroupsByDevice-delete-200": "Groups successfully deleted.",
    "deleteGroupsByDevice-delete-204": "Groups successfully deleted.",
    "deleteGroupsByDevice-delete-401": "Unable to delete Groups, please check your access and try again.",
    "deleteGroupsByDevice-delete-403": "Unable to delete Groups, please check your access and try again.",
    "deleteGroupsByDevice-delete-404": "Unable to delete Groups, please refresh the page and try again.",
    "deleteGroupsByDevice-delete-500": "Unable to delete Groups due to internal error, please try again later.",
    
    "multipleGroups-get-200": "Group(s) successfully retrieved.",
    "multipleGroups-get-401": "Unable to retrieve Group(s), please check your access and try again.",
    "multipleGroups-get-403": "Unable to retrieve Group(s), please check your access and try again.",
    "multipleGroups-get-404": "Unable to retrieve the Group(s), please try again later.",
    "multipleGroups-get-500": "Unable to retrieve the Group(s) due to internal error, please try again later.",
}