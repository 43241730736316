/***
 *
 * Controller class for user.
 * @file TableHelper.js
 * @description TableHelper component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 *
 */

import React, { useEffect, useState } from "react";
// import PropTypes from 'prop-types';
import { useStyles } from "./TableHelper.style.js";
import "./TableHelper.scss";
import { Box, Checkbox, Stack, Tooltip } from "@mui/material";
import ControllerSlider from "../DeviceController/ControllerSlider/index.js";
import StyledButton from "../StyledButton/index.js";
import ImageWrapper from "../ImageWrapper/index.js";
import CustomTypography from "../CustomTypography/index.js";
import Sync from "../../assets/icons/sync.svg"
import WiredIcon from "../../assets/icons/wire.svg"
import CheckCircle from "../../assets/icons/check-circle.svg"
import Exclamation from "../../assets/icons/exclamation.svg"
import WirelessIcon from "../../assets/icons/wifi-on.svg"
import NotSynced from "../../assets/icons/unsynced.svg"
import StyledTextfield from "../StyledTextfield/index.js";
import { formatedDate } from "../../helpers/date.helper.js";
import StyledChip from "../StyledChip/index.js";
import BatteryLow from "../../assets/icons/batterylow.svg"
import BatteryFull from "../../assets/icons/batteryfull.svg"
import BatteryMedium from "../../assets/icons/batterymedium.svg"
import { getMessageStr } from "../../helpers/message.helper.js";
import CustomDropDown from "../CustomDropDown/index.js";



const TableLightId = ({ data }) => {
  return (
    <CustomTypography
      content={data?.configuration?.lightId}
      weight={500}
      size={12}
      color="#000"
    />
  );
};

const TableSlider = ({ data, onChange = () => { }, customData = {}, params, ...props }) => {
  const [value, setValue] = useState(customData?.value || data?.deviceStatus?.status?.intensity || 0)
  const handler = (e, val) => {
    setValue(val);
    onChange(e, val, data, setValue);
  };

  useEffect(() => {
    setValue(customData?.value || data?.configuration?.intensity);
    // console.log("DeviceId ==== ", value, customData?.value, data?.configuration?.intensity)
  }, [customData?.value, data?.configuration])

  return (
    <ControllerSlider
      params={params || { value: true, compo: true }}
      customsx={{ ...customData?.customsx } || { ...props?.customsx }}
      name={customData?.name || "intensity"}
      value={value}
      data={customData}
      disabled={customData?.disabled}
      suffix={customData?.suffix ? customData.suffix : "%"}
      setterFunction={(e, value) => handler(e, value)}
      {...props}
    />

  );
};

const TableBatteryLevel = ({ data, deviceModel }) => {
  const batteryStatus = deviceModel?.statusCapabilities?.find(c => c?.capability === "batteryStatus")?.attributes[0];
  const maxBattery = batteryStatus?.range?.max[0]?.value;
  const minBattery = batteryStatus?.range?.min[0]?.value;
  // const [color]=useState(data?.battery>25 ? ('#0F8D48' ? data?.battery<25 && data?.battery>10 : "#E07B04" ):'#BC2200')
  const battery = data?.deviceStatus?.status?.batteryStatus;
  const color =
    battery >= 2.6
      ? "#0F8D48"
      : battery < 2.6 && battery >= 2.2
        ? "#E07B04"
        : "#EF0000";
  const batteryIcon = battery >= 2.6
    ? BatteryFull
    : battery < 2.6 && battery >= 2.2
      ? BatteryMedium
      : BatteryLow;
  return (
    battery &&
    <Box gap={1} sx={{ display: "flex" }}>
      <ImageWrapper src={batteryIcon} height={16} width={16} />
      <CustomTypography
        content={(Math.round((battery / (maxBattery - minBattery)) * 100)) + "%"}
        weight={700}
        size={14}
        lineHeight={19}
        color={color}
      />
    </Box>
  );
};


const TableStatus = ({ type = "sync", componentData = null, variant = "filled", label = "", sx = { margin: "0px" } }) => {
  // const [openToolTip, setOpentoolTip] = useState(null);
  const classes = useStyles();
  const chipIcons = {
    synced: <ImageWrapper className="icon" src={Sync} />,
    needsSync: <ImageWrapper className="icon" src={NotSynced} />,
    wired: <ImageWrapper className="icon" src={WiredIcon} />,
    wireless: <ImageWrapper className="icon" src={WirelessIcon} />,
    onboarded: <ImageWrapper className="icon" src={CheckCircle} />,
    notOnboarded: <ImageWrapper className="icon" src={Exclamation} />,
  }

  // useEffect(()=>{

  // },[type,label])


  // console.log("data status===>", data);
  return (
    <Box className={classes?.TableHelper || ""} sx={{ lineHeight: 1 }}>
      {
        (() => {
          switch (type) {
            case "online":
            case "offline":
              return <Tooltip arrow placement='bottom' title={getMessageStr(`device-${type}-tooltip`)} enterDelay={1500}>
                <Box className={type} sx={{width:"fit-content",...sx}}>
                  <span className={"dotStatus " + "type"}></span>
                  {label}
                </Box>
              </Tooltip>
            case "synced":
            case "needsSync":
              return <Tooltip arrow placement='bottom' title={getMessageStr(`device-${type}-tooltip`)} enterDelay={1500}>
                <Box >
                  <StyledChip
                    label={label}
                    variant={variant}
                    icon={chipIcons[type]}
                    className={"chipStatus " + type}
                    sx={sx}
                  />
                </Box>
              </Tooltip>
            case "wired":
            case "wireless":
              return <StyledChip
                label={label}
                variant={variant}
                icon={chipIcons[type]}
                className={"chipStatus " + type}
                sx={sx}
              />
            case "onboarded":
            case "notOnboarded":
              return <Tooltip arrow placement='bottom' title={getMessageStr(`device-${type}-tooltip`)} enterDelay={1500}>
                <Box sx={{width:"fit-content"}}>
                  <StyledChip
                    label={label}
                    variant={variant}
                    icon={chipIcons[type]}
                    className={"chipStatus " + type}
                    sx={sx}
                  />
                </Box>
              </Tooltip>
            case "active":
            case "inactive":
            case "deleted":
              return <Tooltip arrow placement='bottom' title={getMessageStr(`device-${type}-tooltip`)} enterDelay={1500}>
                <Box>
                  <CustomTypography
                    lineHeight={19}
                    fontWeight={700}
                    color={type === "active" ? "#0F8D48" : "#BC2200"}
                    size={14}
                    content={label} />
                </Box>
              </Tooltip>
            default:
              return <Box>{"-"}</Box>
          }
        })()
      }

    </Box>
  );
};
const TablePairingDuration = ({ setDuration = () => { }, cancel = () => { }, save = () => { }, ...props }) => {
  const [pairingDuration, setPairingDuration] = useState(30)
  const [error, setError] = useState(false)
  const changeDuration = (event) => {
    const value = event?.currentTarget?.value;
    setPairingDuration(value);
    setDuration(value);
  }
  const savePairingDuration = () => {
    if (pairingDuration < 10 || pairingDuration > 360) {
      setError(true);
    }
    else {
      save(pairingDuration);
      setError(false);
    }
  }

  return (
    <Box >
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <StyledTextfield
          error={error}
          helperText={error ? getMessageStr("receiverPairing-duration-range") : null}
          value={pairingDuration}
          type="number"
          sx={{
            // height: 25, 
            '& .MuiOutlinedInput-root': {
              width: "max-content",
            },
            alignItems: "center",
            '& .MuiFormHelperText-root': {
              textAlign: "center",
              fontSize: "small"
            }
          }}

          inputProps={{ min: 10, max: 360 }}
          onChange={(event, objkey = null, data = "") => { changeDuration(event) }}
        >
        </StyledTextfield>
      </Box>

      {/* {error &&
        <p className="validation">Pairing duration can only be between 10 and 360 seconds.</p>
      } */}
      <CustomTypography component="div" content={getMessageStr("receiverPairing-duration-helperText")} weight={400} styles={{ margin: "8px" }} size={14} color={'rgba(0, 0, 0, 0.8)'} lineHeight={14.63} />
      <Box sx={{ mt: 2 }}>
        <StyledButton variant={"outlined"} onClick={cancel} sx={{ mx: .5, minWidth: "97px" }} >Cancel</StyledButton>
        <StyledButton onClick={savePairingDuration} sx={{ mx: .5, minWidth: "97px" }} >Confirm</StyledButton>
      </Box>
    </Box>
  );
};
const TableCheckbox = ({ data, disabled = false, tooltip = { show: false, placement: "bottom", text: "" }, value = false, onClick = () => { }, sx = {}, ...props }) => {
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    setChecked(value);
  }, [data, value])

  return (
    tooltip?.show ?
      <Tooltip title={tooltip?.text} arrow placement={tooltip?.placement}>
        <Box>
          {/* <p>{JSON.stringify(checked)}</p>
      <p>{JSON.stringify(value)}</p> */}
          <Checkbox
            sx={{ ...sx }}
            disabled={disabled}
            checked={checked}
            value={checked}
            onClick={(e) => {
              setChecked(e?.target?.checked);
              onClick(e);
            }}
            inputProps={{ "aria-label": "check-box" }}
          />
        </Box>
      </Tooltip>
      :
      <Box>
        {/* <p>{JSON.stringify(checked)}</p>
      <p>{JSON.stringify(value)}</p> */}
        <Checkbox
          sx={{ ...sx }}
          disabled={disabled}
          checked={checked}
          value={checked}
          onClick={(e) => {
            setChecked(e?.target?.checked);
            onClick(e);
          }}
          inputProps={{ "aria-label": "check-box" }}
        />
      </Box>
  );
};

const TableDropDown = ({ data, name = null, tooltip = { show: false, placement: "bottom", text: "" }, disabled = false, value = null, isNoneAsItem = false, onChange = () => { }, optionList = [], ...props }) => {
  const [dropdownVal, setDropdownVal] = useState(null);

  useEffect(() => {
    setDropdownVal(value);
  }, [value])// can add data dependency as well

  return (
    tooltip?.show ?
      <Tooltip title={tooltip?.text} arrow placement={tooltip?.placement}>
        <Box>
          <CustomDropDown
            name={name || "dropdown"}
            value={dropdownVal}
            isNoneAsItem={isNoneAsItem}
            onChange={(e) => {
              setDropdownVal(e?.target?.value);
              onChange(e);
            }}
            disabled={disabled}
            list={optionList}
            {...props}
          />
        </Box>
      </Tooltip>
      :
      <CustomDropDown
        name={name || "dropdown"}
        value={dropdownVal}
        isNoneAsItem={isNoneAsItem}
        onChange={(e) => {
          setDropdownVal(e?.target?.value);
          onChange(e);
        }}
        disabled={disabled}
        list={optionList}
        {...props}
      />

  );
};

const TableLinkTooltipContent = ({ data, ...props }) => {

  return (
    <Box>
      <CustomTypography content={data?.groupId !== undefined ? 'Group' : 'Scene'} variant="subtitle1" fontWeight={'bold'} mb={'15px'} />
      <Stack direction={'row'} mb={'6px'}>
        <CustomTypography content={`Created on - `} variant="subtitle2" fontWeight={'bold'} />
        <CustomTypography content={` ${data?.createdOn ? formatedDate({ date: data?.createdOn, format: "dd/MM/yy" }) : ''}`} color={'primary'} variant="subtitle2" />
      </Stack>
      <Stack direction={'row'} mb={'6px'}>
        <CustomTypography content={`Last Updated - `} variant="subtitle2" fontWeight={'bold'} />
        <CustomTypography content={` ${data?.lastUpdatedOn ? formatedDate({ date: data?.lastUpdatedOn, format: "dd/MM/yy" }) : ''}`} color={'primary'} variant="subtitle2" />
      </Stack>
      <Stack direction={'row'} mb={'6px'}>
        <CustomTypography content={`Created by - `} variant="subtitle2" fontWeight={'bold'} />
        <CustomTypography content={` ${data?.createdBy ? data?.createdBy : ''}`} color={'primary'} variant="subtitle2" />
      </Stack>
    </Box>
  );

};

const PermissionsTooltipContent = ({ data, ...props }) => {

  return (
    <Box>
      <CustomTypography content={data?.name ? data?.name : ""} size={14} weight={500} />
    </Box>
  );

};

const UserTooltipContent = ({ data, ...props }) => {
  return (
    <Box>
      <CustomTypography content={!data?.enabled ? "Disabled" : "Enabled"} size={14} weight={500} />
    </Box>
  );

};

export const TableHelper = {
  TableBatteryLevel,
  TableSlider,
  TableLightId,
  TableStatus,
  TablePairingDuration,
  TableLinkTooltipContent,
  PermissionsTooltipContent,
  UserTooltipContent,
  TableCheckbox,
  TableDropDown
};
