import { sceneTypes } from '../types/scene.type';
import { request, success, failure, getMessage } from '../../helpers';
import { appActions } from './app.actions';
import { delay, sceneService } from '../../services/';

const getAllScenesBySpace = (spaceId, cb = () => { }) => {
    return dispatch => {
        if (spaceId) {
            dispatch(request(sceneTypes.GET_SCENES_REQUEST));
            sceneService.getAllScenesBySpace(spaceId)
                .then((scenes) => {
                    for (let i = 0; i < scenes?.length; i++) {
                        for (let j = 0; j < scenes[i].actions?.length; j++) {
                            scenes[i].actions[j]['category'] = scenes[i].actions[j]['type']
                            delete scenes[i].actions[j]['type'];
                        }
                    }
                    return scenes;
                })
                .then(
                    scenes => {
                        dispatch(success(sceneTypes.GET_SCENES_SUCCESS, { payload: scenes, spaceId: spaceId }));
                        cb();
                    },
                    ({ error, code }) => {
                        dispatch(failure(sceneTypes.GET_SCENES_FAILURE, error.toString()));
                        getMessage(`sceneBySpace-get-${code}`).then(delay(500)).then((msg) => {
                            dispatch(appActions.apiErrorMessage(msg));
                        })
                    }
                );
        }
    };
}
const getSceneDetails = (sceneId) => {
    return dispatch => {
        dispatch(request(sceneTypes.GET_SCENE_DETAILS_REQUEST));
        sceneService.getSceneDetails(sceneId)
            .then(
                scenes => dispatch(success(sceneTypes.GET_SCENE_DETAILS_SUCCESS, { payload: scenes })),
                ({ error, code }) => {
                    dispatch(failure(sceneTypes.GET_SCENE_DETAILS_FAILURE, error.toString()));
                    getMessage(`sceneById-get-${code}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    })
                }
            );
    };
}
const createScene = (payload, cb = () => { }) => {
    return dispatch => {
        dispatch(request(sceneTypes.ADD_REQUEST));
        sceneService.createScene(payload)
            .then(
                ({ data: scene, status }) => {
                    dispatch(success(sceneTypes.ADD_SUCCESS, { payload: scene }))
                    getMessage(`scene-post-${status}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiSuccessMessage(msg));
                    });
                    cb();
                },
                ({ error, code }) => {
                    dispatch(failure(sceneTypes.ADD_FAILURE, error.toString()))
                    getMessage(`scene-post-${code}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    })
                }
            );
    };
}
const editScene = (sceneId, payload, cb = () => { }) => {
    return dispatch => {
        dispatch(request(sceneTypes.UPDATE_REQUEST));
        sceneService.editScene(sceneId, payload)
            .then(
                ({ data: scene, status }) => {
                    dispatch(success(sceneTypes.UPDATE_SUCCESS, { payload: scene }));
                    getMessage(`scene-put-${status}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiSuccessMessage(msg));
                    });
                    cb();
                },
                ({ error, code }) => {
                    dispatch(failure(sceneTypes.UPDATE_FAILURE, error.toString()));
                    getMessage(`scene-put-${code}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    })
                }
            );
    };
}
const getScenesByDeviceId = (deviceType, deviceId,cb=()=>{}) => {
    return dispatch => {
        dispatch(request(sceneTypes.GET_SCENES_REQUEST));
        sceneService.getScenesByDeviceId(deviceType, deviceId)
            .then(
                scenes => {
                    dispatch(success(sceneTypes.GET_SCENES_SUCCESS, { payload: scenes }));
                    cb();
                },
                ({ error, code }) => {
                    dispatch(failure(sceneTypes.GET_SCENES_FAILURE, error.toString()));
                    getMessage(`sceneByDevice-get-${code}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    })
                }
            );
    };
}

const deleteScene = (sceneId, cb = () => { }) => {
    return dispatch => {
        dispatch(request(sceneTypes.DELETE_REQUEST));
        sceneService.deleteScene(sceneId)
            .then(
                ({ data, status }) => {
                    cb();
                    dispatch(success(sceneTypes.DELETE_SUCCESS, { payload: sceneId }));
                    getMessage(`scene-delete-${status}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiSuccessMessage(msg));
                    })
                },
                ({ error, code }) => {
                    dispatch(failure(sceneTypes.DELETE_FAILURE, error.toString()));
                    getMessage(`scene-delete-${code}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    })
                }
            );
    };
}

const syncById = (id) => {
    return dispatch => {
        dispatch(request(sceneTypes.SCENE_SYNC_REQUEST));
        sceneService.syncById(id)
            .then(
                ({ data, status }) => {
                    dispatch(success(sceneTypes.SCENE_SYNC_SUCCESS));
                    dispatch(sceneActions.getSceneDetails(id)); // Added this to update group details after syncing is done
                    getMessage(`sceneSync-put-${status}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiSuccessMessage(msg));
                    })
                },
                ({ error, code }) => {
                    dispatch(failure(sceneTypes.SCENE_SYNC_FAILURE, error.toString()));
                    getMessage(`sceneSync-put-${code}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    })
                }
            );
    };
};

const syncByDeviceId = (deviceId) => {
    return dispatch => {
        dispatch(request(sceneTypes.SYNC_SCENES_BY_DEVICE_REQUEST));
        sceneService.syncByDeviceId(deviceId)
            .then(
                ({ data, status }) => {
                    getMessage(`syncScenesByDevice-put-${status}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiSuccessMessage(msg));
                    },)
                    dispatch(success(sceneTypes.SYNC_SCENES_BY_DEVICE_SUCCESS, { payload: data }));
                },
                ({ error, code }) => {
                    dispatch(failure(sceneTypes.SYNC_SCENES_BY_DEVICE_FAILURE, error?.toString()));
                    getMessage(`syncScenesByDevice-put-${code}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    },)
                }
            );
    };
}
const deleteByDeviceId = (deviceId, deviceType = "gateway") => {
    return dispatch => {
        dispatch(request(sceneTypes.DELETE_SCENES_BY_DEVICE_REQUEST));
        sceneService.deleteByDeviceId(deviceId, deviceType)
            .then(
                ({ data, status }) => {
                    getMessage(`deleteScenesByDevice-delete-${status}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiSuccessMessage(msg));
                    },)
                    dispatch(success(sceneTypes.DELETE_SCENES_BY_DEVICE_SUCCESS, { payload: data }));
                },
                ({ error, code }) => {
                    dispatch(failure(sceneTypes.DELETE_SCENES_BY_DEVICE_FAILURE, error?.toString()));
                    getMessage(`deleteScenesByDevice-delete-${code}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    },)
                }
            );
    };
}
const applyScene = (id) => {
    return dispatch => {
        dispatch(request(sceneTypes.SCENE_APPLY_REQUEST));
        sceneService.applyScene(id)
            .then(
                ({ data, status }) => {
                    dispatch(success(sceneTypes.SCENE_APPLY_SUCCESS));
                    getMessage(`sceneApply-post-${status}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiSuccessMessage(msg));
                    })
                },
                ({ error, code }) => {
                    dispatch(failure(sceneTypes.SCENE_APPLY_FAILURE, error.toString()));
                    getMessage(`sceneApply-post-${code}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    })
                }
            );
    };
}

const getMultipleScenes = (payload) => {
    return dispatch => {
      dispatch(request(sceneTypes.GET_MULTIPLE_SCENES_REQUEST));
      sceneService.getMultipleScenes(payload)
        .then(
          scenes => dispatch(success(sceneTypes.GET_MULTIPLE_SCENES_SUCCESS, { payload: scenes })),
          ({ error, code }) => {
            dispatch(failure(sceneTypes.GET_MULTIPLE_SCENES_FAILURE, error?.toString()));
            getMessage(`multipleScenes-get-${code}`).then(delay(500)).then((msg) => {
              dispatch(appActions.apiErrorMessage(msg));
            },)
          }
        );
    };
  }

export const sceneActions = {
    createScene,
    getAllScenesBySpace,
    getSceneDetails,
    getScenesByDeviceId,
    deleteScene,
    editScene,
    syncById,
    syncByDeviceId,
    deleteByDeviceId,
    applyScene,
    getMultipleScenes
};