import {
  // lazy, 
  // Suspense, 
  useEffect, useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  // BrowserRouter as ConnectedRouter,
  Switch,
  Route
} from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { getAuthenticatedRoutes, history } from './helpers';

import { alertActions, appActions } from './redux/actions';
import PrivateRoute from './routes/PrivateRoute';
import ProtectedRoutes from './routes/ProtectedRoute'; //Authenticated routes
import PublicRoute from './routes/PublicRoute';
import './App.scss';
import NotFoundPage from './pages/NotFoundPage';
import routes from './routes/routes';
import { Helmet, HelmetProvider } from "react-helmet-async";
import { CssBaseline, ThemeProvider } from '@mui/material';
import { darkTheme, lightTheme } from './themes';
import StyledSnackBar from './components/StyledSnackBar';
import { ScrollToTop } from './hooks';
import { useLocation } from 'react-use';
import { onMessageListener } from '../src/helpers/notification.helper';
import FirebaseNotification from './components/FirebaseNotification';
import { Stack } from '@mui/system';
import FirebaseNotif from './hooks/FirebaseNotif';
import { webHost } from './config/appConfig';
import _ from 'lodash';
import { Box } from '@material-ui/core';
import StyledSpinner from './components/StyledSpinner';

const miraieStr = localStorage.getItem("miraie") || JSON.stringify({});

const App = () => {

  const dispatch = useDispatch();
  const app = useSelector((state) => state?.app);
  const messages = useSelector((state) => state?.app?.messages);
  const location = useLocation();
  const _theme = useSelector(state => state?.app?.theme);
  const _portal = useSelector(state => state?.app?.portal) || "mss";
  const [portal, setPortal] = useState(_portal);
  const [filteredRoutes, setFilteredRoutes] = useState(null);

  // const [tenant] = useState(tenantName === "localhost" ? "psn" : tenantName);

  const [miraie,] = useState(() => {
    if (!miraieStr) {
      const miraieApp = { app: { theme: "light" } };
      localStorage.setItem("miraie", JSON.stringify(miraieApp))
      return miraieApp;
    } else {
      return JSON.parse(miraieStr);
    }
  });
  const [, setError] = useState({});
  const [, setOpenSnackBar] = useState(false);
  // const [messages, setMessages] = useState(null);
  const [alerts, setAlerts] = useState([]);

  // localStorage.setItem("tenant", tenant);

  const getToken = () => {
    // console.log('User -------- ', miraie);
    if (process.env.REACT_APP_DISABLE_LOGIN === "true") {
      return "disabled";
    } else {
      return miraie ? miraie["accessToken"] : null;
    }
  }
  const [isAuthenticated, setIsAuthenticated] = useState(() => getToken());
  const [loading, setLoading] = useState(true);


  const handleClose = (event, reason) => {
    setOpenSnackBar(false);
    if (reason === 'clickaway') {
      return;
    }
    sessionStorage.removeItem("miraieError");
    setError({});

  };

  const handleCloseNotification = (id) => {
    let msgs = alerts.filter((message) => message.id !== id)
    setAlerts(msgs)
  }

  const preLoginConfig = () => {
    if (!isAuthenticated || _.isEmpty(JSON.parse(localStorage.getItem('appConfig')))) {
      dispatch(appActions.appConfig(webHost, (data) => {
        //removed as login api will not be called
        // localStorage.removeItem("miraie")
        // localStorage.removeItem("user")
        setLoading(false)
      }, () => {
        setLoading(false);
      }))
    }
    else {
      setLoading(false)
    }
  }

  useEffect(() => {
    preLoginConfig();
    const fRoutes = getAuthenticatedRoutes(routes, portal);
    // console.log("route prepared", fRoutes);
    setFilteredRoutes(fRoutes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setPortal(_portal);
  }, [_portal])

  useEffect(() => {
    onMessageListener().then((payload) => {
      setAlerts([...alerts, {
        id: payload.notification.body,
        title: payload.notification.title,
        body: payload.notification.body,
      }]);
      console.log("Notification recieved", payload);
    })
      .catch((err) => console.log("Notification failed: ", err));

    history.listen((location, action) => {
      dispatch(alertActions.clear());
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    setOpenSnackBar(false);
    if (app?.error || app?.message) {
      // setTimeout(() => {
      setOpenSnackBar(true);
      // }, 500);
    }
    // if(app?.messages) {
    //   setMessages(app.messages);
    // }
  }, [app?.messageId, app?.error, app?.message]);

  useEffect(() => {
    setIsAuthenticated(getToken());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage]);

  useEffect(() => {
    const errorStr = sessionStorage.getItem("miraieError");
    setError(JSON.parse(errorStr || '{}'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionStorage])

  useEffect(() => {
    dispatch(appActions.setTheme(miraie?.app?.theme || "light"));
    // console.log("isAuthenticated -- ", isAuthenticated)
    if (!isAuthenticated && !["/login", "/register"].includes(location?.pathname)) {
      // console.log("isAuthenticated 2 -- ", isAuthenticated, location)
      sessionStorage.setItem("miraie-shared-url", JSON.stringify(location));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    console.log('is auth::::', isAuthenticated);
  }, [isAuthenticated])


  return (
    <HelmetProvider>
      <Helmet><title>Miraie</title></Helmet>
      <ThemeProvider theme={_theme === 'dark' ? darkTheme : lightTheme}>   {/*done to avoid getting null in theme state of appReducer*/}
        <CssBaseline />
        {/* {defaultAuthenticatedUrl} */}
        <Box sx={{ position: "relative", height: "100%" }}>
          {loading && <Box sx={{ position: 'absolute', top: 0, bottom: 0, width: "100%", backgroundColor: "rgba(255, 255, 255, 0.8)", zIndex: 1001 }}>
            <StyledSpinner sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
          </Box>}
          {
            <div className="h-100">
              {/* <pre>{JSON.stringify(filteredRoutes, null, 2)}</pre> */}
              {/* We are using connectRouter here because of redux, in react-router component reload proble is there after changing the route */}
              <ConnectedRouter history={history}>
                <ScrollToTop />
                <Switch>
                  {isAuthenticated !== "disabled" && routes?.filter(r => r.isPublicRoute && r.portal.includes(portal))?.map(({ component: Component, path, exact }) => (
                    <PublicRoute path={`/${path}`} key={path} exact={exact} isAuthenticated={isAuthenticated}>
                      <Component />
                    </PublicRoute>
                  ))}
                  {/* <PublicRoute path="/login" isAuthenticated={isAuthenticated}><LoginPage /></PublicRoute> */}
                  {filteredRoutes &&
                    <PrivateRoute path="/" isAuthenticated={isAuthenticated} routes={filteredRoutes}>
                      <ProtectedRoutes routes={filteredRoutes} />
                    </PrivateRoute>
                  }
                  <Route path='*' exact={true} element={<NotFoundPage />} />
                  {/* <Redirect to="/404" /> */}
                </Switch>
              </ConnectedRouter>
              {/* <span style={{ position: 'absolute', zIndex: "99999", display: "block", backgroundColor: "#ff0000", top: "30px", left: "50%", padding: "30px" }}>{JSON.stringify(openSnackbar)}</span> */}

              {/* <Stack sx={{ position: "absolute", bottom: 24, right: 24 }} spacing={2}> */}
              {/* <StyledSnackBar
                open={openSnackbar}
                autoHideDuration={4000}
                onClose={handleClose}
                data={{ "type": app?.messageType, "message": app?.message }}
                key={app?.messageId}
              /> */}
              {/* </Stack> */}

              {/* <pre style={{position: "fixed", bottom: "30px", zIndex: "99999"}}>hhhhhhhh ---- {JSON.stringify(messages, null, 2)}</pre> */}
              {messages && messages?.length > 0 &&
                <Stack sx={{ position: "fixed", zIndex: "9999", bottom: 10, left: 10 }} spacing={1}>
                  {(messages || [])?.map((message) => (
                    <StyledSnackBar
                      stacked={true}
                      autoHideDuration={3000}
                      onClose={handleClose}
                      data={{ "messageId": message?.messageId, "type": message?.messageType, "message": message?.message }}
                      key={message?.messageId}
                    />
                  ))}
                </Stack>
              }
            </div>}
        </Box>
        {alerts && alerts.length > 0 &&
          <Stack sx={{ position: "absolute", zIndex: "9999", top: 20, right: 20 }} spacing={1}>
            {alerts?.map((message) => (
              <FirebaseNotification
                message={message}
                key={"noti" + message?.id}
                onClose={handleCloseNotification}
              />
            ))}
          </Stack>
        }
      </ThemeProvider>
      <FirebaseNotif />
    </HelmetProvider>
  );
};

export default App;