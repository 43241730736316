import _ from "lodash";
import { deviceTypes } from "../types";
import deviceModels from "../../data/deviceModels.json";
import { formatedDate } from "../../helpers";
import { activityLogPageSize } from "../../config/deviceConfig";

let initialState = {
  loading: false,
  allDevices: [],
  gatewayList: [],
  controllerList: [],
  deviceList: [],
  deviceModels: deviceModels,
  deviceCategories: [
    ...new Map(
      deviceModels.map((item) => [item["category"], { name: item?.category }])
    ).values(),
  ],
  deviceDetails: null,
  parentDevice: null,
  item: null,
  error: null,
  deviceBreadCrumbs: [],
  lastAction: null,
  detailedDeviceList: [],
  deviceLogs: { activities: [] },
  spinner: false,
  requestId: "",
  sensorList: [],
  gatewayStatistics: {}
};
export function devices(state = initialState, action) {
  // console.log("device action ------ ", action);
  switch (action.type) {
    case deviceTypes.GET_ALL_GATEWAYS_REQUEST:
    case deviceTypes.GET_ALL_CONTROLLERS_REQUEST:
    case deviceTypes.GET_ALL_DEVICES_REQUEST:
    case deviceTypes.GET_GATEWAY_REQUEST:
    case deviceTypes.GET_CONTROLLER_REQUEST:
    case deviceTypes.GET_DEVICE_REQUEST:
    case deviceTypes.GET_PARENT_DEVICE_REQUEST:
    case deviceTypes.UPDATE_GATEWAY_REQUEST:
    case deviceTypes.UPDATE_CONTROLLER_REQUEST:
    case deviceTypes.UPDATE_DEVICE_REQUEST:
    case deviceTypes.ADD_CONTROLLER_REQUEST:
    case deviceTypes.DELETE_GATEWAY_REQUEST:
    case deviceTypes.DELETE_CONTROLLER_REQUEST:
    case deviceTypes.DELETE_DEVICE_REQUEST:
    case deviceTypes.GET_DEVICE_LOGS_REQUEST:
    case deviceTypes.GET_DEVICES_BY_SPACEID_REQUEST:
    case deviceTypes.GET_DEVICE_MODELS_REQUEST:
    case deviceTypes.GET_DETAILED_DEVICE_LIST_REQUEST:
    case deviceTypes.UPDATE_DEVICE_CONFIGURATION_REQUEST:
    case deviceTypes.FACTORY_RESET_REQUEST:
    case deviceTypes.FIND_SENSORS_REQUEST:
    case deviceTypes.BIND_SENSORS_REQUEST:
    case deviceTypes.STOP_FIND_SENSORS_REQUEST:
    case deviceTypes.FETCH_SENSORS_REQUEST:
    case deviceTypes.GET_GATEWAYLOG_REQUEST:
    case deviceTypes.GET_GATEWAYLOG_DATES_REQUEST:
    case deviceTypes.ASSIGN_GATEWAY_REQUEST:
    case deviceTypes.GET_DEVICE_CATEGORIES_REQUEST:
    case deviceTypes.GET_GATEWAY_STATISTICS_REQUEST:
    case deviceTypes.GET_REQUEST:
    case deviceTypes.GET_ASSIGNED_GATEWAYS_REQUEST:
    case deviceTypes.GET_CAMERA_STREAM_REQUEST:
    case deviceTypes.STOP_CAMERA_STREAM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case deviceTypes.GET_ALL_GATEWAYS_SUCCESS:
      return {
        ...state,
        gatewayList: action.payload,
        loading: false,
        lastAction: "get_all_gateways"
      };
    case deviceTypes.GET_GATEWAY_SUCCESS:
      state.gatewayList = state?.gatewayList || [];
      let existingGateway = state.gatewayList?.find(item => item?.deviceId === action.payload.device?.deviceId);
      if (existingGateway === undefined) {
        Array.isArray(state?.gatewayList) && state.gatewayList?.push(action.payload.device);
      } //Adds the gateway if it is not in the list
      return {
        ...state,
        gatewayList: _.isEmpty(state.gatewayList)
          ? [action.payload.device]
          : state.gatewayList.map((item) =>
            item.deviceId === action.payload.device.deviceId ? action.payload.device : item
          ),
        deviceDetails: action.payload.dontUpdateDeviceDetails ? state.deviceDetails : action.payload.device,
        loading: false,
        lastAction: "get_gateway_details"
      };
    case deviceTypes.GET_SUCCESS:
      return {
        ...state,
        item: action.payload,
        deviceDetails: action.payload,
        loading: false,
        lastAction: "get_device_details"
      };
    case deviceTypes.GET_ALL_CONTROLLERS_SUCCESS:
      return {
        ...state,
        controllerList: action.payload,
        loading: false,
        lastAction: "get_all_controllers"
      };
    case deviceTypes.GET_MQTT_TOPIC_SUCCESS: // should be removed
      return {
        ...state,
        topic: action.payload,
        loading: false,
        lastAction: "get_mqtt_topic"
      };
    case deviceTypes.GET_CONTROLLER_SUCCESS:
      state.controllerList = state?.controllerList || [];
      let existingController = state.controllerList?.find(item => item?.deviceId === action.payload.device?.deviceId);
      if (existingController === undefined) {
        Array.isArray(state?.controllerList) && state.controllerList?.push(action.payload.device);
      }//Adds the controller if it is not in the list
      return {
        ...state,
        controllerList: _.isEmpty(state.controllerList)
          ? [action.payload.device]
          : state.controllerList.map((item) =>
            item.deviceId !== action.payload.device.deviceId ? item : action.payload.device
          ),
        deviceDetails: action.payload.dontUpdateDeviceDetails ? state.deviceDetails : action.payload.device,
        loading: false,
        lastAction: "get_controller_details"
      };
    case deviceTypes.GET_DEVICE_LOGS_SUCCESS:
      return {
        ...state,
        deviceLogs: {
          ...action?.payload,
          activities:
            // ...action?.payload?.activities
            [...state?.deviceLogs?.activities, ...action?.payload?.activities]
          // action?.nextPage ?
          //   state?.deviceLogs?.activities?.length > activityLogPageSize ? [...state?.deviceLogs?.activities?.slice(state?.deviceLogs?.activities?.length - activityLogPageSize, state?.deviceLogs?.activities?.length), ...action?.payload?.activities] : [...state?.deviceLogs?.activities, ...action?.payload?.activities]
          //   :
          //   state?.deviceLogs?.activities?.length > activityLogPageSize ? [...action?.payload?.activities, ...state?.deviceLogs?.activities?.slice(0, activityLogPageSize)] : [...action?.payload?.activities, ...state?.deviceLogs?.activities]
        },
        loading: false
      };
    case deviceTypes.CLEAR_DEVICE_LOGS:
      return {
        ...state,
        deviceLogs: {
          activities: []
        }
      };
    case deviceTypes.GET_DEVICES_BY_SPACEID_SUCCESS:
      return {
        ...state,
        deviceList: action.payload,
        loading: false,
        deviceDetails: {},
        lastAction: "get_devices_by_space_id " + Date.now()
      };
    case deviceTypes.GET_ASSIGNED_GATEWAYS_SUCCESS:
      return {
        ...state,
        deviceList: action.payload,
        loading: false,
        deviceDetails: {},
        lastAction: "get_assigned_gateways " + Date.now()
      };
    case deviceTypes.GET_ALL_DEVICES_SUCCESS:
      return {
        ...state,
        allDevices: action?.payload,
        deviceList: action?.payload?.filter(device => !["DaliAnalogLightController", "DaliLightController", "RelayLightController", "AnalogLightController", "VortexGateway", "Aggregator"]?.includes(device?.category)),
        // deviceList: action?.payload?.filter(device => (["Controller","Gateway","Aggregator"].find(d => !device?.category.includes(d)))),
        loading: false,
        lastAction: "get_devices_by_space_id " + Date.now()
      };
    case deviceTypes.GET_DEVICE_SUCCESS:
      return {
        ...state,
        deviceList: _.isEmpty(state.deviceList)
          ? [action.payload.device]
          : state?.deviceList?.map((item) =>
            item.deviceId === action.payload.device.deviceId ? action.payload.device : item
          ),
        deviceDetails: action.payload.dontUpdateDeviceDetails ? state.deviceDetails : action.payload.device,
        loading: false,
        lastAction: "get_device"
      };
    case deviceTypes.GET_PARENT_DEVICE_SUCCESS:
      return {
        ...state,
        parentDevice: action.payload,
        loading: false,
        lastAction: "get_parent_device"
      };
    case deviceTypes.UPDATE_DEVICE_SUCCESS:
      return {
        ...state,
        deviceList: state.deviceList.map((item) =>
          item?.deviceId === action?.payload?.deviceId ? action.payload : item
        ),
        controllerList: state.controllerList.map((item) =>
          item?.deviceId === action?.payload?.deviceId ? action.payload : item
        ),
        deviceDetails: action?.payload?.deviceId === state?.deviceDetails?.deviceId ? action?.payload : state?.deviceDetails,
        // deviceDetails: action.payload,
        loading: false,
        lastAction: "update_device" + Date.now()
      };
    // case deviceTypes.UPDATE_DEVICE_FW_STATUS:
    //   return {
    //     ...state,
    //     deviceList: state?.deviceList?.map(d => {
    //       if (action?.payload?.deviceId === d?.deviceId) {
    //         if (action?.payload?.status?.toLowerCase()?.includes("ended")) {
    //           d[d?.category?.toLowerCase()?.includes("gateway") ? "fwVersion" : "fwVersion3rdParty"] = action?.payload?.fwVersion
    //         }
    //         d["fwUpdateStatus"]= action?.payload?.status
    //       }
    //         return d;
    //       }),
    //     deviceDetails: action?.payload?.deviceId === state?.deviceDetails?.deviceId ?
    //       {
    //         ...state?.deviceDetails,
    //         ...(() => {
    //           if (action?.payload?.status?.toLowerCase()?.includes("ended")) {
    //             return {
    //               [state?.deviceDetails?.category?.toLowerCase()?.includes("gateway") ? "fwVersion" : "fwVersion3rdParty"]: action?.payload?.fwVersion
    //             }
    //           }
    //           else {
    //             return {};
    //           }
    //         })(),
    //         fwUpdateStatus: action?.payload?.status
    //       }
    //       : { ...state?.deviceDetails }
    //   }

    case deviceTypes.UPDATE_DEVICE_STATUS:
      // console.log("UPDATE DEVICE CONFIG", action.payload);
      return {
        ...state,
        deviceList: state.deviceList.map((item) => {
          if (item?.deviceId === action?.payload?.deviceId) {
            for (const key in action?.payload?.configuration) {
              if (item?.deviceStatus) {
                item.deviceStatus.status[key] = action.payload.configuration[key];
              }
              else {
                item.deviceStatus = { status: {} }
                item.deviceStatus.status[key] = action.payload.configuration[key];
              }
            }
          }
          return item;
        }),
        deviceDetails: action?.payload?.deviceId === state?.deviceDetails?.deviceId ? {
          ...state?.deviceDetails,
          deviceStatus: {
            ...state?.deviceDetails?.deviceStatus,
            status: {
              ...state?.deviceDetails?.deviceStatus?.status,
              ...action?.payload?.configuration
            }
          }
        } :
          state?.deviceDetails,
        // deviceList: [...state.deviceList, {...device}],
        loading: false,
        // lastAction: "update_device_config"
      };
    case deviceTypes.UPDATE_CONTROLLER_STATUS:
      return {
        ...state,
        deviceDetails: state?.deviceDetails?.deviceId === action?.payload?.deviceId ? { ...state?.deviceDetails, mqttStatus: action?.payload?.status } : state?.deviceDetails,
        loading: false,
        // lastAction: "update_device_config"
      };
    case deviceTypes.UPDATE_CONNECTIVITY_STATUS:
      const versionProp = action?.payload?.hasOwnProperty("fwVersion") ? "fwVersion" : "fwVersion3rdParty";
      return {
        ...state,
        deviceDetails: state?.deviceDetails?.deviceId === action?.payload?.deviceId ?
          {
            ...state?.deviceDetails,
            ...(() => {
              if (action?.payload?.hasOwnProperty("ipAddress") && state?.deviceDetails?.hasOwnProperty("ipAddress")) {
                return {
                  ipAddress: action?.payload?.ipAddress
                }
              }
              else {
                return {}
              }
            })(),
            [versionProp]: action?.payload?.hasOwnProperty(versionProp) ? action?.payload[versionProp] : state?.deviceDetails[versionProp],
            connectionStatus: action?.payload?.onlineStatus ? "Online" : "Offline",
            lastConnectedAt: formatedDate({ date: action?.payload?.ts, format: "MMM dd, yyyy 'AT' hh:mm a" }),
            ...(() => {
              return state.deviceDetails?.deviceStatus && state.deviceDetails?.deviceStatus?.connectionStatus ? {
                deviceStatus: {
                  ...state.deviceDetails?.deviceStatus,
                  connectionStatus: {
                    ...state.deviceDetails?.deviceStatus?.connectionStatus,
                    ts: action?.payload?.ts,
                    onlineStatus: action?.payload?.onlineStatus,
                    nextUpdate: action?.payload?.nextUpdate
                  }
                }
              } : {}
            })()
          } : state?.deviceDetails,
        deviceList: state?.deviceList?.find(d => d?.deviceId === action?.payload?.deviceId) ? state?.deviceList?.map(item => {
          if (action?.payload?.deviceId === item?.deviceId) {
            if (action?.payload?.hasOwnProperty(versionProp)) {
              item[versionProp] = action?.payload[versionProp];
            }
            if (action?.payload?.hasOwnProperty("ipAddress") && item?.hasOwnProperty("ipAddress")) {
              item["ipAddress"] = action?.payload?.ipAddress
            }
            if (item?.deviceStatus && item?.deviceStatus?.connectionStatus) {
              item.connectionStatus = action?.payload?.onlineStatus ? "online" : "offline";
              item.lastConnectedAt = formatedDate({ date: action?.payload?.ts, format: "MMM dd, yyyy 'AT' hh:mm a" });
              item.deviceStatus.connectionStatus.ts = action?.payload?.ts
              item.deviceStatus.connectionStatus.onlineStatus = action?.payload?.onlineStatus
              item.deviceStatus.connectionStatus.nextUpdate = action?.payload?.nextUpdate
            }
          }
          return item;
        }) : state?.deviceList,
        gatewayList: state?.gatewayList?.find(g => g?.deviceId === action?.payload?.deviceId) ? state?.gatewayList?.map(item => {
          if (action?.payload?.deviceId === item?.deviceId) {
            if (action?.payload?.hasOwnProperty(versionProp)) {
              item[versionProp] = action?.payload[versionProp];
            }
            if (action?.payload?.hasOwnProperty("ipAddress") && item?.hasOwnProperty("ipAddress")) {
              item["ipAddress"] = action?.payload?.ipAddress
            }
            if (item?.deviceStatus && item?.deviceStatus?.connectionStatus) {
              item.connectionStatus = action?.payload?.onlineStatus ? "online" : "offline";
              item.lastConnectedAt = formatedDate({ date: action?.payload?.ts, format: "MMM dd, yyyy 'AT' hh:mm a" });
              item.deviceStatus.connectionStatus.ts = action?.payload?.ts
              item.deviceStatus.connectionStatus.onlineStatus = action?.payload?.onlineStatus
              item.deviceStatus.connectionStatus.nextUpdate = action?.payload?.nextUpdate
            }
          }
          return item;
        }) : state?.gatewayList,
        controllerList: state?.controllerList?.find(c => c?.deviceId === action?.payload?.deviceId) ? state?.controllerList?.map(item => {
          if (action?.payload?.deviceId === item?.deviceId) {
            if (action?.payload?.hasOwnProperty(versionProp)) {
              item[versionProp] = action?.payload[versionProp];
            }
            if (action?.payload?.hasOwnProperty("ipAddress") && item?.hasOwnProperty("ipAddress")) {
              item["ipAddress"] = action?.payload?.ipAddress
            }
            if (item?.deviceStatus && item?.deviceStatus?.connectionStatus) {
              item.connectionStatus = action?.payload?.onlineStatus ? "online" : "offline";
              item.lastConnectedAt = formatedDate({ date: action?.payload?.ts, format: "MMM dd, yyyy 'AT' hh:mm a" });
              item.deviceStatus.connectionStatus.ts = action?.payload?.ts
              item.deviceStatus.connectionStatus.onlineStatus = action?.payload?.onlineStatus
              item.deviceStatus.connectionStatus.nextUpdate = action?.payload?.nextUpdate
            }
          }
          return item;
        }) : state?.controllerList,
        loading: false,
        // lastAction: "update_device_config"
      };
    //currently this case is not being used
    case deviceTypes.UPDATE_CONNECTIVITY_LOST_STATUS:
      return {
        ...state,
        deviceDetails: state?.deviceDetails?.deviceId === action?.payload?.deviceId ?
          {
            ...state?.deviceDetails,
            deviceStatus: {
              ...state.deviceDetails?.deviceStatus,
              connectionStatus: {
                ...state.deviceDetails?.deviceStatus?.connectionStatus,
                onlineStatus: false,
              }
            }
          } : state?.deviceDetails,
        deviceList: state?.deviceList?.map(
          (item) => {
            if (item?.deviceId === action.payload?.deviceId) {
              item.deviceStatus.connectionStatus.onlineStatus = false;
            }
            return item;
          }
        ),
        controllerList: state?.controllerList?.map(
          (item) => {
            if (item?.deviceId === action.payload?.deviceId) {
              item.deviceStatus.connectionStatus.onlineStatus = false;
            }
            return item;
          }
        ),
        loading: false,
        lastAction: `delete_device+ ${action?.payload}`
      };
    case deviceTypes.DELETE_DEVICE_SUCCESS:
      return {
        ...state,
        deviceList: state.deviceList.filter(
          (item) => item.deviceId !== action.payload
        ),
        controllerList: state.controllerList.filter(
          (item) => item.deviceId !== action.payload
        ),
        loading: false,
        lastAction: `delete_device+ ${action?.payload}`
      };

    case deviceTypes.DEVICES_BREADCRUMBS:
      console.log("device bread crumbs==>", action?.payload);
      return {
        ...state,
        deviceBreadCrumbs: [...action?.payload],
        loading: false,
        lastAction: "set_device_breadcrumbs"
      };
    case deviceTypes.MOVE_SUCCESS:
      return {
        ...state,
        gatewayList: state.gatewayList.map((item) => {
          if (item.deviceId === action.payload.deviceId) {
            item.spaceId = action.payload.spaceId;
            item.spaceName = action.payload.spaceName;
          }
          return item;
        }),
        controllerList: state.controllerList.map((item) => {
          if (item.deviceId === action.payload.deviceId) {
            item.spaceId = action.payload.spaceId;
            item.spaceName = action.payload.spaceName;
          }
          return item;
        }),
        // deviceList: state.deviceList.map(item => {
        //     if(item.deviceId === action.payload.deviceId) {
        //         item.spaceId = action.payload.spaceId;
        //         item.spaceName = action.payload.spaceName;
        //     }
        //     return item;
        // }),
        deviceList: state.deviceList.map((item) => {//not removing device from list as list will not be correct in case it is list of child devices
          if (item.deviceId === action.payload.deviceId) {//instead updating space name and id of device in list
            item.spaceId = action.payload.spaceId;
            item.spaceName = action.payload.spaceName;
          }
          return item;
        }),
        // allDevices: state.allDevices.map((item) => {//not removing device from list as list will not be correct in case it is list of child devices
        //   if (item.deviceId === action.payload.deviceId) {//instead updating space name and id of device in list
        //     item.spaceId = action.payload.spaceId;
        //     item.spaceName = action.payload.spaceName;
        //   }
        //   return item;
        // }),
        // deviceList: state.deviceList.filter(
        //   (item) => item?.deviceId !== action?.payload?.deviceId
        // ),
        loading: false,
        lastAction: "move_device" + Date.now()
      };
    case deviceTypes.GET_DEVICE_MODELS_SUCCESS:
      return {
        ...state,
        deviceModels: action.payload,
        loading: false,
        lastAction: "get_device_model"
      };
    case deviceTypes.GET_DEVICE_CATEGORIES_SUCCESS:
      return {
        ...state,
        deviceCategories: action?.payload,
        loading: false,
        lastAction: "get_device_categories" + Date.now()
      };
    case deviceTypes.GET_DETAILED_DEVICE_LIST_SUCCESS:
      let a = action.payload;
      return {
        ...state,
        detailedDeviceList: [...a],
        loading: false
      };
    case deviceTypes.START_PAIRING:
      return {
        ...state,
        deviceList: state.deviceList.map((d) => {
          if (d.deviceId === action.payload.deviceId) {
            d["pairing"] = true;
          }
          return d;
        }),
        lastAction: "start_pairing"
      };
    case deviceTypes.STOP_PAIRING:
      return {
        ...state,
        deviceList: state.deviceList.map((d) => {
          if (d.deviceId === action.payload.deviceId) {
            d["pairing"] = false;
          }
          return d;
        }),
        lastAction: "stop_pairing"
      };
    case deviceTypes.STOP_LOADING:
      return {
        ...state,
        deviceList: state?.deviceList?.map((d) => {
          if (d.deviceId === action.payload.deviceId) {
            d["loading"] = false;
          }
          return d;
        }),
        deviceDetails: state?.deviceDetails?.deviceId === action?.payload?.deviceId ? { ...state?.deviceDetails, loading: false } : state?.deviceDetails,
        sensorList: state?.sensorList?.map((d) => {
          if (d?.deviceId === action?.payload?.deviceId || d?.shortId === action?.payload?.shortId) {
            d["loading"] = false;
          }
          return d;
        }),
        spinner: false
      };
    case deviceTypes.START_LOADING:
      return {
        ...state,
        deviceList: state?.deviceList?.map((d) => {
          if (d.deviceId === action.payload.deviceId) {
            d["loading"] = true;
          }
          return d;
        }),
        deviceDetails: state?.deviceDetails?.deviceId === action?.payload?.deviceId ? { ...state?.deviceDetails, loading: true } : state?.deviceDetails,
        sensorList: state?.sensorList?.map((d) => {
          if (d.deviceId === action.payload.deviceId || d?.shortId === action?.payload?.shortId) {
            d["loading"] = true;
          }
          return d;
        }),
        spinner: true
      };
    case deviceTypes.UPDATE_DEVICE_CONFIGURATION_SUCCESS:
      return {
        ...state,
        loading: false,
        lastAction: "update_device_config" + Date.now()
      };
    case deviceTypes.ADD_CONTROLLER_SUCCESS:
      return {
        ...state,
        deviceList: state?.deviceList?.find((item) => item?.deviceId === action?.payload?.deviceId) ?//if controller is present replace 
          state?.deviceList?.map((item) =>//else add to list
            item?.deviceId === action?.payload?.deviceId ? action?.payload : item
          )
          : [...state?.deviceList, action?.payload],
        controllerList: state?.controllerList?.find((item) => item?.deviceId === action?.payload?.deviceId) ?//if controller is present replace 
          state?.controllerList?.map((item) =>//else add to list
            item?.deviceId === action?.payload?.deviceId ? action?.payload : item
          )
          : [...state?.controllerList, action?.payload],
        loading: false,
        lastAction: "add_controller" + Date.now()
      };
    case deviceTypes.FACTORY_RESET_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case deviceTypes.GET_GATEWAYLOG_SUCCESS:
      return {
        ...state,
        deviceDetails: state?.deviceDetails?.deviceId === action?.payload?.gatewayId ? {
          ...state.deviceDetails,
          log: { ...action?.payload }
        } : state?.deviceDetails,
        loading: false,
      };
    case deviceTypes.FIND_SENSORS_SUCCESS:
      return {
        ...state,
        requestId: action?.payload?.requestId,
        loading: false,
      };
    case deviceTypes.STOP_FIND_SENSORS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case deviceTypes.BIND_SENSORS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case deviceTypes.FETCH_SENSORS_SUCCESS:
      return {
        ...state,
        sensorList: action?.payload?.sensors,
        lastAction: "set_sensor_list" + Date.now(),
        loading: false,
      };
    case deviceTypes.CLEAR_SENSOR_LIST:
      return {
        ...state,
        sensorList: {},
        lastAction: "set_sensor_list" + Date.now(),
        loading: false,
      };
    case deviceTypes.GET_GATEWAYLOG_DATES_SUCCESS:
      return {
        ...state,
        deviceDetails: state?.deviceDetails?.deviceId === action?.payload?.gatewayId ? {
          ...state.deviceDetails,
          logDates: [...action?.payload?.availableLogs]
        } : state?.deviceDetails,
        loading: false,
      };
    case deviceTypes.ASSIGN_GATEWAY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case deviceTypes.GET_GATEWAY_STATISTICS_SUCCESS:
      return {
        ...state,
        gatewayStatistics: { ...action?.payload },
        loading: false,
      };
    case deviceTypes.CLEAR_ALL_DEVICES:
      return {
        ...state,
        deviceList: [],
        loading: false,
        lastAction: "clear_all_devices" + Date.now()
      };
    case deviceTypes.CLEAR_DETAILED_DEVICE_LIST:
      return {
        ...state,
        detailedDeviceList: [],
        loading: false,
        lastAction: "clear_detailed_deviceList" + Date.now()
      };
    case deviceTypes.GET_CAMERA_STREAM_SUCCESS:
      return {
        ...state,
        deviceDetails: { ...action?.payload },
        loading: false,
        lastAction: "get_camera_stream" + Date.now()
      }
    case deviceTypes.STOP_CAMERA_STREAM_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case deviceTypes.GET_CAMERA_STREAM_FAILURE:
      return {
        ...state,
        deviceDetails: {},
        loading: false,
      }
    case deviceTypes.GET_ASSIGNED_GATEWAYS_FAILURE:
    case deviceTypes.GET_DEVICES_BY_SPACEID_FAILURE:
      return {
        ...state,
        deviceList: [],
        loading: false,
        lastAction: "get_devices_by_space_id " + Date.now()
      };
    case deviceTypes.GET_ALL_CONTROLLERS_FAILURE://failure case of getAllChildren API
      return {
        ...state,
        deviceList: action?.payload?.type !== "controller" && action?.payload?.type !== "gateway" ? [] : state?.deviceList,
        controllerList: action?.payload?.type === "controller" ? [] : state?.controllerList,
        // gatewayList: action?.payload?.type === "gateway" ? [] : state?.gatewayList,
        loading: false,
        lastAction: "get_all_devices" + Date.now()
      };
    case deviceTypes.GET_FAILURE:
    case deviceTypes.GET_DEVICE_FAILURE:
      return {
        ...state,
        deviceDetails: action?.payload?.error?.dontUpdateDeviceDetails ? state?.deviceDetails : {},
        loading: false,
        lastAction: "get_device" + Date.now()
      };
    case deviceTypes.STOP_CAMERA_STREAM_FAILURE:
    case deviceTypes.GET_ALL_GATEWAYS_FAILURE:
    case deviceTypes.GET_GATEWAYLOG_FAILURE:
    case deviceTypes.GET_ALL_DEVICES_FAILURE:
    case deviceTypes.GET_CONTROLLER_FAILURE:
    case deviceTypes.GET_PARENT_DEVICE_FAILURE:
    case deviceTypes.UPDATE_GATEWAY_FAILURE:
    case deviceTypes.UPDATE_CONTROLLER_FAILURE:
    case deviceTypes.UPDATE_DEVICE_FAILURE:
    case deviceTypes.DELETE_GATEWAY_FAILURE:
    case deviceTypes.DELETE_CONTROLLER_FAILURE:
    case deviceTypes.DELETE_DEVICE_FAILURE:
    case deviceTypes.GET_DEVICE_LOGS_FAILURE:
    case deviceTypes.MOVE_FAILURE:
    case deviceTypes.GET_DEVICE_MODELS_FAILURE:
    case deviceTypes.GET_DETAILED_DEVICE_LIST_FAILURE:
    case deviceTypes.ADD_CONTROLLER_FAILURE:
    case deviceTypes.FIND_SENSORS_FAILURE:
    case deviceTypes.BIND_SENSORS_FAILURE:
    case deviceTypes.FETCH_SENSORS_FAILURE:
    case deviceTypes.STOP_FIND_SENSORS_FAILURE:
    case deviceTypes.UPDATE_DEVICE_CONFIGURATION_FAILURE:
    case deviceTypes.FACTORY_RESET_FAILURE:
    case deviceTypes.GET_GATEWAYLOG_DATES_FAILURE:
    case deviceTypes.ASSIGN_GATEWAY_FAILURE:
    case deviceTypes.GET_DEVICE_CATEGORIES_FAILURE:
    case deviceTypes.GET_GATEWAY_STATISTICS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
