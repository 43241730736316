
/***
 *
 * Controller class for user.
 * @file Alerts.js
 * @description Alerts component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import { React, useState, useEffect } from 'react';
import { useStyles } from './Alerts.style.js';
import './Alerts.scss';
import { confirmationDialog, getMessageStr, history, isAuthorised } from '../../helpers';
import { Box, Tooltip } from '@mui/material';
import CustomTable from '../CustomTable/index.js';
import PageHeader from '../PageHeader/index.js';
import NotificationIcon from '../../assets/icons/notificationIcon.svg';
import TicketIcon from '../../assets/icons/ticketing.svg';
import VisibilityOn from '../../assets/icons/visibility-on.svg';
import CheckCircle from "../../assets/icons/check-circle.svg";
import { alertActions } from '../../redux/actions';
import { useDispatch, useSelector } from "react-redux";
import _ from 'lodash';
import DataNotFound from '../../pages/DataNotFound/index.js';
import CustomTypography from '../CustomTypography/index.js';
import { features, permissions } from '../../constants/permission.constants.js';
import ImageWrapper from '../ImageWrapper/index.js';
import StyledPopup from '../StyledPopup/index.js';
import { alertSeverity, alertStatus } from '../../constants/alert.constants.js';



const Alerts = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const alertsData = useSelector(state => state?.alert?.alertsList);
  const [alertsList, setAlertsList] = useState(null)
  const [dialog, setDialog] = useState(false);
  const [dialogContents, setDialogContents] = useState(null);


  const tableHeaders = {
    alertTitle: {
      label: "Alert",
    },
    createdOn: { label: "Created At", type: "date", format: "MMM dd, yyyy 'at' hh:mm a" },
    alertStatus: {
      label: "status",
      type: "component",
      component: (row) => {
        return <CustomTypography
          content={row?.data?.alertStatus}
          color={alertStatus[row?.data?.alertStatus]}
          weight={700}
        />
      }
    },
    severity: {
      label: 'Severity',
      type: "component",
      component: (row) => {
        return (
          <Tooltip arrow enterDelay={1500} title={alertSeverity[row?.data?.severity]?.label || ""}>
            <Box>
              <ImageWrapper width={32} height={32} src={alertSeverity[row?.data?.severity]?.icon} />
            </Box>
          </Tooltip>
        )
      },
    },
  }
  let userActions = {
    label: "Actions",
    actions: [
      {
        label: "Create a Ticket",
        type: "ticket",
        icon: TicketIcon,
        onClick: (event, data) => {
          history.push('/tickets?op=create', { data })
        },
      },
      {
        label: "Acknowledge",
        type: "ack",
        icon: VisibilityOn,
        hidden: (row) => {
          return row?.ackStatus !== "no"
        },
        onClick: (event, data) => {
          dispatch(alertActions.acknowledgeAlert(data?.id, () => {
            dispatch(alertActions.getAlerts())
          }))
        },
      },
      {
        label: "Resolve",
        type: "resolve",
        hidden: (row) => {
          return row?.status === "closed"
        },
        icon: CheckCircle,
        onClick: (event, data) => {
          confirmationDialog(getMessageStr("alert-resolve-title"), () => {
            dispatch(alertActions.resolveAlert(data?.id, () => {
              dispatch(alertActions.getAlerts())
            }));
            setDialog(false);
          }, "alertResolve", setDialog, setDialogContents)

        },
      },
    ],
    style: { textAlign: "right" },
  }

  const headerList = isAuthorised(features?.alert, permissions?.edit) ? { ...tableHeaders, userActions } : tableHeaders

  useEffect(() => {
    // dispatch(alertActions.getAlertTypes(), () => {
    // })
    dispatch(alertActions.getAlerts())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!_.isEmpty(alertsData?.alerts)) {
      setAlertsList([...alertsData?.alerts])
    }
  }, [alertsData])

  return (
    <Box className={classes?.Alerts || ""} data-testid="Alerts">
      <PageHeader icon={NotificationIcon} title={`Alerts`} />
      <DataNotFound
        show={alertsList && alertsList.length > 0 ? false : true}
        customsx={{ image: { height: '202px', width: '100%' }, label: {}, content: {}, btns: {} }}
        data={{
          label: getMessageStr('alert-list-empty'),
        }}
      >
        <CustomTable
          headerList={headerList}
          cellMatrix={alertsList}
          pagination={true}
          searching={{ reset: true, columns: ["alertTitle", "alertStatus", "severity"] }}
          sorting={{ column: "createdOn", order: 'desc' }}>
        </CustomTable>
      </DataNotFound>
      <StyledPopup
        open={dialog}
        onClose={() => { setDialog(false) }}
        state={dialogContents?.state}
        data={dialogContents?.content}
        component={dialogContents?.component}
        customsx={{
          label: {
            color: "red",
            fontWeight: 700,
            fontSize: "0.9375rem",
            paddingBottom: "16px",
          },
          btns: { display: "flex", justifyContent: "center" },
        }}
      />
    </Box>
  )
};

Alerts.propTypes = {};

Alerts.defaultProps = {};

export default Alerts;