/**
 * 
 * @name = Protected Routes
 * @description = The protected Route component is used 
 * to map all the authenticated routes
 * 
 * @author = Naveen Kumar
 * @since = 07 Oct, 2021
 * 
 */

 import { Suspense } from 'react';
 import { Route, Switch, Redirect } from 'react-router-dom';

 const ProtectedRoutes = ({...props}) => (
   <Switch>
     <Suspense fallback={<div>Loading...</div>}>
       <>
        {props?.routes.map(({ component: Component, path, exact, style, redirectTo }) => (
          redirectTo ? (
            <Route path={`/${path}`} key={path} exact={exact} render={() => (<Redirect to={`/${redirectTo}`} />)} />
          ) : (
            <Route path={`/${path}`} key={path} exact={exact}>
              {/* {JSON.stringify(style)} */}
              <Component {...props} style={style}/>
            </Route>
          )
        ))}
       </>
     </Suspense>
   </Switch>
 );
 
 export default ProtectedRoutes;
 
