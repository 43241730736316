// import { useHistory } from 'react-router-dom';
import { closeMqttConnection, delay, getValueFromArray, subscribe, userService } from '../../services';
import { history, request, success, failure, getMessage, setLocalItem, formatedDate } from '../../helpers';
import { userTypes, authenticationTypes, appTypes } from '../types';
import { appActions } from './app.actions';
import { appConnections, NODE_ENV } from '../../config/appConfig';
import { roleActions } from './roles.actions';
import { notificationActions } from './notification.actions';
import _ from 'lodash';

const login = ({ username, password, mfa = null, goto, portal = "mss" }, cb = () => { }) => {
    return (dispatch, getState) => {
        dispatch(request(authenticationTypes.LOGIN_REQUEST));
        userService.login(username, password, portal, getState()?.app?.clientId)
            .then(
                ({ auth, code }) => {
                    dispatch(success(authenticationTypes.LOGIN_SUCCESS, { payload: { user: auth?.user, mfa, loggedIn: mfa ? false : true } }));
                    if (!mfa) {
                        localStorage.setItem('miraie', JSON.stringify(auth));
                        localStorage.setItem('user', JSON.stringify(auth?.user));
                    } else {
                        sessionStorage.setItem('tmp-miraie', JSON.stringify(auth));
                        sessionStorage.setItem('tmp-miraie-user', JSON.stringify(auth?.user));
                    }
                    cb(auth?.user);
                },
                ({ error, code, remainingAttempts = null, message }) => {
                    dispatch(failure(authenticationTypes.LOGIN_FAILURE, error?.toString()));
                    getMessage(`login-post-${code}`, { remainingAttempts, message }).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    };
}

/// action call for forgot password
export const generateOTP = (type = 'auth', payload, cb = () => { }) => {
    return (dispatch, getState) => {
        dispatch(request(appTypes.GENERATE_OTP_REQUEST));
        userService.generateOTP(type, payload, getState()?.app?.clientId)
            .then(
                ({ data: otp, status }) => {
                    // console.log('ottttp', otp);
                    dispatch(success(appTypes.GENERATE_OTP_SUCCESS, { payload: otp }));
                    getMessage(`generateOTP-post-${status}`).then(delay(500)).then((success) => {
                        dispatch(appActions.apiSuccessMessage(success));
                    })
                    cb(otp);
                },
                ({ error, code }) => {
                    dispatch(failure(appTypes.GENERATE_OTP_FAILURE, { error: error?.toString() }, 'generateOTP'));
                    getMessage(`generateOTP-post-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            )
    }
}


/// action call for forgot password
export const validateOTP = (type, payload, cb = () => { }) => {
    return dispatch => {
        dispatch(request(appTypes.VALIDATE_OTP_REQUEST));
        userService.validateOTP(type, payload)
            .then(
                ({ data: otp, status }) => {
                    if (type === 'auth') {
                        localStorage.setItem('miraie', sessionStorage.getItem('tmp-miraie'));
                        localStorage.setItem('user', sessionStorage.getItem('tmp-user'));
                        setTimeout(() => {
                            sessionStorage.removeItem('tmp-miraie');
                            sessionStorage.removeItem('tmp-user');
                        }, 500);
                    }
                    dispatch(success(appTypes.VALIDATE_OTP_SUCCESS, { payload: otp }));
                    getMessage(`validateOTP-post-${status}`).then(delay(500)).then((success) => {
                        dispatch(appActions.apiSuccessMessage(success));
                    })
                    cb(otp);
                },
                ({ error, code }) => {
                    if ((NODE_ENV === "development" || NODE_ENV === "qa") && payload?.otp === "0000") {
                        dispatch(success(appTypes.VALIDATE_OTP_SUCCESS, { payload: payload?.otp }));
                    } else {
                        dispatch(failure(appTypes.VALIDATE_OTP_FAILURE, error?.toString()));
                    }
                    getMessage(`validateOTP-post-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            )
    }
}


/// action call for forgot password
export const resetCred = (payload, cb = () => { }) => {
    return dispatch => {
        dispatch(request(authenticationTypes.RESET_PASSWORD_REQUEST));
        userService.resetCred(payload)
            .then(
                ({ data: user, status }) => {
                    dispatch(success(authenticationTypes.RESET_PASSWORD_SUCCESS, { payload: user }));
                    cb(user);
                    getMessage(`resetPassword-post-${status}`).then(delay(500)).then((success) => {
                        dispatch(appActions.apiSuccessMessage(success));
                    })

                },
                ({ error, code }) => {
                    dispatch(failure(authenticationTypes.RESET_PASSWORD_FAILURE, error?.toString()));
                    getMessage(`resetPassword-post-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            )
    }
}
/////// action call for user change password inside profile ///////
export const changeSelfCred = (payload, portal = "mss", cb = () => { }) => {
    return dispatch => {
        dispatch(request(userTypes.CHANGE_SELF_PASSWORD_REQUEST));
        userService.changeSelfCred(payload, portal)
            .then(
                ({ data: user, status }) => {
                    dispatch(success(userTypes.CHANGE_SELF_PASSWORD_SUCCESS, { payload: user }));
                    getMessage(`changeSelfPassword-put-${status}`).then(delay(500)).then((success) => {
                        dispatch(appActions.apiSuccessMessage(success));
                    });
                    cb();
                },
                ({ error, code }) => {
                    dispatch(failure(userTypes.CHANGE_SELF_PASSWORD_FAILURE, error?.toString(), 'changeSelfPassword'));
                    getMessage(`changeSelfPassword-put-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            )
    }
}
/// change password by tenant admin
export const changeCredByUserId = (userId, payload, portal = "mss") => {
    return dispatch => {
        dispatch(request(userTypes.CHANGE_USERID_PASSWORD_REQUEST));
        userService.changeCredByUserId(userId, payload, portal)
            .then(
                ({ data: user, status }) => {
                    dispatch(success(userTypes.CHANGE_USERID_PASSWORD_SUCCESS, { payload: user }));
                    // getMessage(`changePassword-post-${status}`).then(delay(500)).then((success) => {
                    //     dispatch(appActions.apiSuccessMessage(success));
                    // })
                },
                ({ error, code }) => {
                    dispatch(failure(userTypes.CHANGE_USERID_PASSWORD_FAILURE, error?.toString()));
                    getMessage(`changePassword-post-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            )
    }
}

// Structure for MSS portal permissions 
// const a = {
//     "name": "NewSet",
//     "tags" : ["tag1", "tag2"],
//     "systemPermission" : false,
//     "idp": {
//         "client": {
//             "allow" :["view","manage"],
//             "deny" : []
//         }
//     },
//     "app": {
//         "space": {
//             "allow": ["view"],
//             "deny" : []
//         }
//     }
// }

// Structure for TMS portal permissions 
// const b = {
//     "name": "NPP7",
//     "tags": ["tag1"],
//     "idp": {
//         "sys": {
//             "user": {
//                 "allow": ["view","operate"],
//                 "deny" : []
//             }
//         },
//         "tenant": {
//             "psn": {
//                 "config": {
//                     "allow": ["view"]
//                 }
//             }
//         }
//     },
//     "app": {
//         "psn": {
//             "space": {
//                 "allow": ["view","manage"],
//                 "deny" : []
//             },
//             "device": {
//                 "deny": ["view","manage"],
//                 "allow" : []
//             }
//         },
//         "hbk": {
//             "report": {
//                 "deny": ["view","manage"],
//                 "allow" : []
//             }
//         }
//     }
// }

// multiple permission
// const objects = [
//     {
//       idp: {
//         client: {
//           allow: [
//             'manage'
//           ]
//         }
//       },
//       app: {
//         space: {
//           allow: [
//             'operate',
//             'view',
//             'manage'
//           ]
//         }
//       }
//     },
//     {
//       idp: {
//         config: {
//           allow: [
//             'manage'
//           ]
//         },
//         user: {
//           allow: [
//             'manage'
//           ]
//         }
//       }
//     }
//   ];

// export const getSelfProfile = ({token = null, portal="mss"}={}, cb = () => { }) => {
//     return dispatch => {
//         dispatch(request(userTypes.GET_SELF_PROFILE_REQUEST));
//         userService.getSelfProfile(token, portal)      
//             .then(
//                 ({ data:user, status }) => {
//                    Promise.resolve().then(()=>{
//                         // we are assuming that we don't have any permissions initially
//                         if(portal === 'mss'){
//                         user["permissions"] = { idp: {}, app: {} };
//                         user?.roles?.forEach(role => {
//                             dispatch(roleActions?.getRole(role, 'profile', (userRole) => {
//                                 user["permissions"] = { idp: {...user.permissions.idp, ...(userRole?.idp || {})}, app: {...user.permissions.app, ...(userRole?.app || {})} }
//                                 setLocalItem("user", JSON.stringify(user));
//                             },portal));
//                         })}
//                         return user;
//                     }).then(()=>{
//                         dispatch(success(userTypes.GET_SELF_PROFILE_SUCCESS, { payload: user }));
//                     }).then(()=>{
//                         cb(user);
//                     })
//                 },
//                 ({ error, code }) => {
//                     dispatch(failure(userTypes.GET_SELF_PROFILE_FAILURE, error?.toString()));
//                     getMessage(`getSelfProfile-get-${code}`).then(delay(500)).then((err) => {
//                         dispatch(appActions.apiErrorMessage(err));
//                     })
//                 }
//             )
//     }
// }

export const getSelfProfile = ({ token = null, portal = "mss" } = {}, cb = () => { }) => {
    return dispatch => {
        dispatch(request(userTypes.GET_SELF_PROFILE_REQUEST));
        userService.getSelfProfile(token, portal)
            .then(({ data: user, status }) => {
                Promise.resolve().then(() => {
                    // we are assuming that we don't have any permissions initially
                    if (portal === "tms") {
                        const newUser = {
                            ...user,
                            "userId": user?.id,
                            "name": user?.firstname
                        }
                        return { data: newUser, status }
                    }
                    else if (portal === 'mss') {
                        user["permissions"] = { idp: {}, app: {} };
                        user?.roles?.forEach(role => {
                            dispatch(roleActions?.getRole(role, 'profile', portal, (userRole) => {
                                user["permissions"] = { idp: { ...user.permissions.idp, ...(userRole?.idp || {}) }, app: { ...user.permissions.app, ...(userRole?.app || {}) } }
                                setLocalItem("user", JSON.stringify(user));
                            }));
                        })
                        return { data: user, status };
                    }
                }).then(({ data: newUser, status: status }) => {
                    dispatch(success(userTypes.GET_SELF_PROFILE_SUCCESS, { payload: newUser }));
                    return { data: newUser, status }
                }).then(({ data: newUser, status }) => {
                    dispatch(success(authenticationTypes.UPDATE_USER, { payload: newUser }));
                    cb(newUser);
                })
            },
                ({ error, code }) => {
                    dispatch(failure(userTypes.GET_SELF_PROFILE_FAILURE, error?.toString()));
                    getMessage(`getSelfProfile-get-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            )
    }
}

export const updateSelfProfile = (payload, cb = () => { }, portal = "mss") => {
    return dispatch => {
        dispatch(request(userTypes.UPDATE_SELF_PROFILE_REQUEST));
        userService.updateSelfProfile(payload, portal)
            .then(
                ({ data: user, status }) => {
                    dispatch(success(userTypes.UPDATE_SELF_PROFILE_SUCCESS, { payload: user }));
                    cb();
                    getMessage(`updateSelfProfile-put-${status}`).then(delay(500)).then((success) => {
                        dispatch(appActions.apiSuccessMessage(success));
                    })
                },
                ({ error, code }) => {
                    dispatch(failure(userTypes.UPDATE_SELF_PROFILE_FAILURE, error?.toString()));
                    getMessage(`updateSelfProfile-put-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            )
    }
}

export const addUser = (user, portal = "mss", cb = () => { }) => {
    // console.log(`inside add user`)
    return (dispatch, getState) => {
        dispatch(request(userTypes.ADD_USER_REQUEST));

        userService.addUser(user, portal, getState()?.app?.clientId)
            .then(({ data: user, status }) => {
                if (portal === "tms") {
                    const newUser = {
                        ...user,
                        "userId": user?.id,
                        "name": user?.firstname
                    }
                    return { data: newUser, status }
                }
                else { return { data: user, status }; }
            })
            .then(
                ({ data: user, status }) => {
                    dispatch(success(userTypes.ADD_USER_SUCCESS, { payload: user }));
                    cb(user);
                    getMessage(`addUser-post-${status}`).then(delay(500)).then((success) => {
                        dispatch(appActions.apiSuccessMessage(success));
                    })
                },
                ({ error, code }) => {
                    dispatch(failure(userTypes.ADD_USER_FAILURE, error?.toString()));
                    getMessage(`addUser-post-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    };
};

export const update = (user, id, cb = () => { }, portal = "mss", updateUser = true) => {
    // console.log(`inside update user`)
    return dispatch => {
        dispatch(request(userTypes.UPDATE_USER_REQUEST));

        userService.update(user, id, portal)
            .then(({ data: user, status }) => {
                if (portal === "tms") {
                    const newUser = {
                        ...user,
                        "userId": user?.id,
                        "name": user?.firstname
                    }
                    return { data: newUser, status }
                }
                else { return { data: user, status }; }
            })
            .then(
                ({ data: user, status }) => {
                    if (updateUser) {
                        dispatch(success(userTypes.UPDATE_USER_SUCCESS, { payload: user }));
                    }
                    cb(user);
                    getMessage(`update-put-${status}`).then(delay(500)).then((success) => {
                        dispatch(appActions.apiSuccessMessage(success));
                    })
                },
                ({ error, code }) => {
                    dispatch(failure(userTypes.UPDATE_USER_FAILURE, error?.toString()));
                    getMessage(`update-put-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    };
};


const register = (user, goto, portal = "mss") => {
    // console.log(`inside register`)
    return (dispatch, getState) => {
        dispatch(request(authenticationTypes.REGISTER_REQUEST));

        userService.register(user, portal, getState()?.app?.clientId)
            .then(({ data: users, status }) => {
                if (portal === "tms") {
                    users = users.map(user => {
                        user["userId"] = user.id;
                        user["name"] = user.firstname;
                        return user;
                    })
                }
                return { users, status };
            })
            .then(
                ({ data: user, status }) => {
                    dispatch(success(authenticationTypes.REGISTER_SUCCESS));
                    history.push(goto);
                    getMessage(`register-post-${status}`).then(delay(500)).then((success) => {
                        dispatch(appActions.apiSuccessMessage(success));
                    })
                },
                ({ error, code }) => {
                    dispatch(failure(authenticationTypes.REGISTER_FAILURE, error?.toString()));
                    getMessage(`register-post-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    };
}

const changeCred = (user, goto = null) => {
    // console.log(`inside change password`)
    return (dispatch, getState) => {
        dispatch(request(authenticationTypes.CHANGE_PASSWORD_REQUEST));

        userService.changeCred(user, getState()?.app?.clientId)
            .then(
                ({ data: user, status }) => {
                    dispatch(success(authenticationTypes.CHANGE_PASSWORD_SUCCESS));
                    history.push(goto);
                    // getMessage(`changePassword-post-${status}`).then(delay(500)).then((success) => {
                    //     dispatch(appActions.apiSuccessMessage(success));
                    // })
                },
                ({ error, code }) => {
                    dispatch(failure(authenticationTypes.CHANGE_PASSWORD_FAILURE, error?.toString()));
                    getMessage(`changePassword-post-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    };
}

export const updateUserRoles = (id, roles, cb = () => { }, portal = 'mss', updateUserRole = true) => {
    return dispatch => {
        dispatch(request(userTypes.UPDATE_USER_ROLES_REQUEST));
        userService.updateUserRoles(id, roles, portal)
            .then(
                ({ data: user, status }) => {
                    if (updateUserRole) {
                        dispatch(success(userTypes.UPDATE_USER_SUCCESS, { payload: user }));
                    }
                    cb(user);
                    getMessage(`updateUserRoles-put-${status}`).then(delay(500)).then((success) => {
                        dispatch(appActions.apiSuccessMessage(success));
                    })
                },
                ({ error, code }) => {
                    dispatch(failure(userTypes.UPDATE_USER_ROLES_FAILURE, error?.toString()));
                    getMessage(`updateUserRoles-put-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    };
};

export const updateUserScopes = (id, scopes, cb = () => { }) => {
    // console.log(`inside update user scopes`)
    return dispatch => {
        dispatch(request(userTypes.UPDATE_USER_SCOPES_REQUEST));

        userService.updateUserScopes(id, scopes)
            .then(
                ({ data: scopes, status }) => {
                    dispatch(success(userTypes.UPDATE_USER_SCOPES_SUCCESS, { payload: { userId: id, scopes } }));
                    cb(scopes);
                    getMessage(`updateUserScopes-put-${status}`).then(delay(500)).then((success) => {
                        dispatch(appActions.apiSuccessMessage(success));
                    })
                },
                ({ error, code }) => {
                    dispatch(failure(userTypes.UPDATE_USER_SCOPES_FAILURE, error?.toString()));
                    getMessage(`updateUserScopes-put-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    };
};

const getAll = (size = 20, portal = "mss") => {

    return dispatch => {
        dispatch(request(userTypes.GET_ALL_REQUEST));
        userService.getAll(size, portal)
            .then(({ data: users, status }) => {
                if (portal === "tms") {
                    users = users.map(user => {
                        user["userId"] = user.id;
                        user["name"] = user.firstname;
                        return user;
                    })
                }
                else {
                    users = users?.map(user => {
                        user.tempUser = getValueFromArray(user?.fields, "tempUser") || "No"
                        user.userExpiry = getValueFromArray(user?.fields, "userExpiry") ? formatedDate({ date: parseInt(getValueFromArray(user?.fields, "userExpiry")) }) : "-"
                        return user;
                    })
                }
                return { users, status };
            })
            .then(
                ({ users, status }) => dispatch(success(userTypes.GET_ALL_SUCCESS, { payload: users })),
                ({ error, code }) => {
                    dispatch(failure(userTypes.GET_ALL_FAILURE, error?.toString()));
                    getMessage(`getAll-get-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    };
}


const getById = (userId, portal = "mss") => {

    return dispatch => {
        dispatch(request(userTypes.GET_REQUEST));
        userService.getById(userId, portal)
            .then(({ data: user, status }) => {
                if (portal === "tms") {
                    const newUser = {
                        ...user,
                        "userId": user.id,
                        "name": user.firstname
                    }
                    return { data: newUser, status }
                }
                else { return { data: user, status }; }
            })
            .then(
                ({ data: user, status }) => dispatch(success(userTypes.GET_SUCCESS, { payload: user })),
                ({ error, code }) => {
                    dispatch(failure(userTypes.GET_FAILURE, error?.toString()));
                    getMessage(`getById-get-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    };
}

const getAllTenants = () => {

    return dispatch => {

        dispatch(request(userTypes.GET_ALL_REQUEST));
        userService.getAllTenants()
            .then(
                users => dispatch(success(userTypes.GET_ALL_SUCCESS, { payload: users })),
                error => dispatch(failure(userTypes.GET_ALL_FAILURE, error?.toString()))
            );
    };
}

const getUserScope = (userId) => {

    return dispatch => {
        dispatch(request(userTypes.GET_USER_SCOPE_REQUEST));
        userService.getUserScope(userId)
            .then(
                ({ data: scopes, status }) => dispatch(success(userTypes.GET_USER_SCOPE_SUCCESS, { payload: scopes })),
                ({ error, code }) => dispatch(failure(userTypes.GET_USER_SCOPE_FAILURE, error?.toString()))
            );
    };
}

const changeUserStatus = (userId, payload) => {

    return dispatch => {
        dispatch(request(userTypes.CHANGE_STATUS_REQUEST));
        userService.changeUserStatus(userId, payload)
            .then(
                ({ data: user, status }) => {
                    dispatch(success(userTypes.CHANGE_STATUS_SUCCESS, { payload: payload?.status }));
                    getMessage(`changeUserStatus-put-${status}`).then(delay(500)).then((success) => {
                        dispatch(appActions.apiSuccessMessage(success));
                    })
                }
                ,
                ({ error, code }) => {
                    dispatch(failure(userTypes.CHANGE_STATUS_FAILURE, error?.toString()));
                    getMessage(`changeUserStatus-put-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    };
}

// prefixed function name with underscore because delete is a reserved word in javascript
const _delete = (id) => {
    return dispatch => {
        dispatch(request(userTypes.DELETE_REQUEST));

        userService.delete(id)
            .then(
                ({ data: user, status }) => {
                    dispatch(success(userTypes.DELETE_SUCCESS, { payload: id }));
                    getMessage(`deleteUser-delete-${status}`).then(delay(500)).then((success) => {
                        dispatch(appActions.apiSuccessMessage(success));
                    })
                },
                ({ error, code }) => {
                    dispatch(failure(userTypes.DELETE_FAILURE, error?.toString()));
                    getMessage(`deleteUser-delete-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })

                }
            );
    };
}

const logout = () => {
    return (dispatch, getState) => {
        const portal = getState()?.app?.portal;
        const notificationToken = getState()?.app?.notificationToken;
        Promise?.resolve()?.then(() => {
            appConnections?.mqtt && portal === "mss" && closeMqttConnection();
        })?.then(() => {
            appConnections?.notifications && portal === "mss" && notificationToken && dispatch(notificationActions?.unsubscribeNotification(notificationToken?.token))
        })?.then(() => {
            setTimeout(() => {
                userService.logout();
                dispatch(success(authenticationTypes.LOGOUT, {}));
            }, 0)
            // subscribe("psn/status/fd",dispatch,mqttClient)
        })

        // if (portal === "mss" && appConnections?.notifications && notificationToken) {
        //     dispatch(notificationActions?.unsubscribeNotification(notificationToken?.token, () => {
        //         userService.logout();
        //         history.push(goto);
        //         dispatch(success(authenticationTypes.LOGOUT, {}));
        //     }));
        //     console.log("sending mqtt message,connection status:", mqttClient?.connected)
        // }
        // else {
        //     userService.logout();
        //     history.push(goto);
        //     dispatch(success(authenticationTypes.LOGOUT, {}));
        // }
    }
}

//// emptying the reducer state after an operation is done; imperative to pass "type"
export const emptyUserState = (type, payload = null) => {
    if (!type)
        return null;
    return dispatch => {
        dispatch(success(userTypes[type], { payload: payload }))
    }
}

export const emptyUserErrors = () => {
    return dispatch => {
        dispatch(success(userTypes.USER_EMPTY_ERRORS))
    }
}

const getUserActivityLogs = (requestSpecs) => { //requestSpecs is an object which must have Id. It can also have start time, end time, type, uid
    return dispatch => {
        dispatch(request(userTypes.GET_USER_LOGS_REQUEST));
        userService.getUserActivityLogs(requestSpecs)
            .then(
                ({ data: userLogs, status }) => dispatch(success(userTypes.GET_USER_LOGS_SUCCESS, { payload: userLogs })),
                ({ error, code }) => {
                    dispatch(failure(userTypes.GET_USER_LOGS_FAILURE, error?.toString()));
                    getMessage(`getUserActivityLogs-get-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    }
}

export const emptyAuthState = (type, payload = null) => {
    if (!type)
        return null;
    return dispatch => {
        dispatch(success(authenticationTypes[type], { payload: payload }))
    }
}

export const userActions = {
    login,
    logout,
    addUser,
    getById,
    update,
    register,
    changeCred,
    getAll,
    getAllTenants,
    getUserScope,
    updateUserRoles,
    updateUserScopes,
    delete: _delete,
    generateOTP,
    validateOTP,
    changeSelfCred,
    resetCred,
    getSelfProfile,
    updateSelfProfile,
    changeCredByUserId,
    changeUserStatus,
    emptyUserState,
    emptyUserErrors,
    getUserActivityLogs,
    emptyAuthState
};
