import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    AddEditUser: {
       
    },
    ChangePassword:{
        padding: '8px 10px !important', marginLeft: '0px !important', height: '30px', width: 'max-content' 
    } 
});

export const deleteBtnStyle = makeStyles({
    '& .css-193q8ns-MuiButtonBase-root-MuiButton-root':{
        background:'red',
        position:'absolute',
        left:'250px'
    },
    '.css-u15wi9-MuiButtonBase-root-MuiButton-root:active':{
            background:'red'
    }
});
