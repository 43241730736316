/***
 *
 * Controller class for user.
 * @file UserList.js
 * @description UserList component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 *
 */

import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import "./UserList.scss";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../redux/actions";
// import SearchIcon from "@mui/icons-material/Search";
import CustomTable from '../CustomTable';
import PageHeader from '../PageHeader';
import UserIcon from '../../assets/icons/user.svg';
import { getDate, history, isAuthorised, stringAvatar } from '../../helpers';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Avatar from '@mui/material/Avatar';
import { TableHelper } from '../../components/TableHelper';
import DataNotFound from '../../pages/DataNotFound';
import usersIcon from '../../assets/icons/users.svg';
import { features, permissions } from '../../constants';
import StyledButton from '../StyledButton';
import { getValueFromArray } from "../../services";

const headers = {
  multiKeys: {
    label: "Name",
    image: {
      key: "name",
      type: "avatar",
      url: "/user-management/users?op=view",
      urlParams: { id: "userId" },
      style: { width: "2rem", height: "2rem", fontSize: "0.9rem", padding: "7px", marginRight: "7px" }
    },
    name: {
      type: "link",
      url: "/user-management/users?op=view",
      urlParams: { id: "userId" },
    },
    sortKey: "name",
  },
  roles: { label: "User Role", type: "list", separator: " | " }
};

const UserList = ({ portal = "mss" }) => {
  const _userProfile = useSelector(state=>state?.users?.userSelfProfile)
  const dispatch = useDispatch();
  const users = useSelector((state) => state?.users.list);

  const [userData, setUserData] = useState(null);
  const [loader, showLoader] = useState(true);
  const [userHeaders, setUserHeaders] = useState(headers);




  useEffect(() => {
    dispatch(userActions.getAll(0, portal));
    setUserHeaders({
      ...userHeaders, ...(() => {
        return portal === "mss" ? (
          {
            tempUser: {
              label: "Temporary User",
            },
            userExpiry: {
              label: "User Expiry Date",
            },
            type:{
              label:"user type"
            },
            enabled: {
              label: "User Status",
              type: "component",
              component: (row) => (
                row?.data?.enabled ? (
                  <CheckCircleOutlineIcon
                    sx={{ pointer: "cursor" }}
                    fontSize="small"
                    color="primary"
                  />
                ) : (
                  <WarningAmberIcon size="small" color="danger" />
                )
              ),
              toolTip: {
                type: "component",
                content: TableHelper.UserTooltipContent,
              },
            },
          }
        ) : ({});
      })()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goTo = (route) => {
    history.push(route);
  };

  useEffect(() => {
    let list = [];
    if (users && users.length > 0) {
      let userArray = users;
      if(_userProfile){ userArray = userArray?.filter(user => user?.userId !== _userProfile?.userId) }
      
      userArray?.forEach(currentUser => {
        if (currentUser?.type === 'user') {
          let tempObj = {
            "avatar": <Avatar style={{ display: 'inline-flex', fontSize: '1.125rem' }} {...stringAvatar(`${currentUser?.name}`)} />,
            "id": currentUser?.userId,
            "userId": {
              text: currentUser?.userId,
              img: UserIcon
            },
            "type": currentUser?.type,
            "tempUser": getValueFromArray(currentUser?.fields, 'tempUser') === 'Yes' ? 'Yes' : 'No',
            "userExpiry": isNaN(getValueFromArray(currentUser?.fields, 'userExpiry')) ? '-' : getDate(getValueFromArray(currentUser?.fields, 'userExpiry')),
            "name": currentUser?.name,
            "username": currentUser?.username,
            "userRole": currentUser?.roles,
            "status": currentUser?.enabled,
          };
          list.push(tempObj);
        }
      });
      showLoader(false);
      setUserData(userArray);
    }

  }, [users, _userProfile])

  useEffect(() => {
    if (loader) {
      setTimeout(() => {
        showLoader(false);
      }, 1000);
    }
  }, [loader]);

  return (
    <>
      <PageHeader pageHeaderStyle={{ padding: '8px 0px 8px 8px' }} title={'Users'} icon={usersIcon} >
         {(portal!=="mss" ||  isAuthorised(features?.user, permissions?.add)) && <StyledButton type='button' color='primary' size="medium" sx={{ mr: 0 }} onClick={event => goTo("/user-management/users?op=create")}>Add User</StyledButton>}
          {/* <StyledButton color='secondary' size="medium" onClick={event => goTo("/user-management/bulkregister")}>Bulk Registation</StyledButton> */}
      </PageHeader>
      <Box>
        <DataNotFound
          show={userData && userData?.length > 0 ? false : true}
          customsx={{
            image: { height: "202px", width: "100%" },
            label: { fontWeight: "600" },
            content: {},
            btns: {},
          }}
          data={{
            label: "Users",
            content:
              "You have not added any Users yet. Create a New User to see the details",
            action: [
              {
                id: "1001",
                type: "button",
                label: "Create User",
                onClick: (event, data) => {
                  goTo("/user-management/users?op=create");
                },
              },
            ],
          }}
        >
          <CustomTable
            headerList={userHeaders}
            cellMatrix={userData}
            pagination={true}
            searching={true}
            removeSortFrom={["enabled"]}
            sorting={{ column: "name", order: "asc" }}
          ></CustomTable>
        </DataNotFound>
      </Box>
    </>
  );
};

UserList.propTypes = {};

UserList.defaultProps = {};

export default UserList;
