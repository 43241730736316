import { appActions, groupActions } from "../redux/actions";
import { groupTypes } from "../redux/types";
import { groupDeleteMessageLatency } from "../config/mqttConfig";
import { getMessageStr } from "./message.helper";

let groupDeleteTimeout = null;
export const groupControl = async (group, dispatch, data, mqttClient, topicData = null, onFail = () => { }) => {
  // const groupType = group?.managedBy?.toLowerCase();
  let deviceConfig = {}
  // console.log("GROUP CONTROL", data,deviceConfig)
  // if (groupType === 'cloud') {
  if (data.hasOwnProperty('klv')) {
    deviceConfig.kelvin = data?.klv;
  }
  else {
    deviceConfig.intensity = data?.lst;
  }
  //calling APi for group managed by cloud
  dispatch(groupActions.controlGroup(group?.groupId, deviceConfig, onFail));
  return;
  // }
  // let payload = {};
  // const id = groupType === "controller" ? group?.controllerIds[0] : group.gatewayIds[0];
  // console.log("here", groupType, id)
  // Promise.resolve().then(() => {
  //   if (topicData === null) {
  //     return deviceService.getDevice(id);
  //   }
  //   return { data: topicData };
  // }
  // ).then(({ data: deviceData, status }) => {
  //   if (data.hasOwnProperty('klv')) {
  //     deviceConfig.klv = data?.klv
  //   }
  //   else {
  //     deviceConfig.lst = data?.lst
  //   }
  //   deviceConfig.grpId = group?.groupId;
  //   payload = {
  //     subTopic: deviceData?.mqttTopics?.status,
  //     pubTopic: deviceData?.mqttTopics?.control,
  //     deviceId: groupType === "controller" ? deviceData?.deviceId : null,
  //     deviceConfig,
  //     onRecieve: (message) => {
  //       if (message?.grpId === group?.groupId) {
  //         if (message?.status === "success") {
  //           dispatch(appActions?.apiSuccessMessage(getMessageStr("groupControl-receive-value", (group?.name ? group?.name : "") + ` (${group?.groupId})`)))
  //         }
  //         else {
  //           dispatch(appActions?.apiErrorMessage(getMessageStr("groupControl-receive-error", (group?.name ? group?.name : "") + ` (${group?.groupId})`)))
  //         }
  //         clearTimeout(timeout);
  //         // unsubscribe(deviceData?.mqttTopics?.status, mqttClient);
  //         dispatch(mqttActions.deleteMqttRequest(message?.sid));
  //       }
  //     }
  //   }
  //   const messageSid = mqttRequest(payload, mqttClient, dispatch);
  //   const timeout = setTimeout(() => {
  //     // unsubscribe(data?.mqttTopics?.status, mqttClient);
  //     dispatch(mqttActions.deleteMqttRequest(messageSid));
  //     dispatch(appActions?.apiErrorMessage(getMessageStr("groupControl-receive-timeout", (group?.name ? group?.name : "") + ` (${group?.groupId})`)))
  //   }, setMqttTimeout(60000))
  // },
  //   ({ error, code }) => {
  //     getMessage(`device-get-${code}`).then(delay(500)).then((msg) => {
  //       dispatch(appActions.apiErrorMessage((group?.name ? group?.name : "") + ` (${group?.groupId}) ` + "group control failed : " + msg));
  //     },)
  //   }
  // )
};

export const updateGroupStatus = (msg, dispatch) => {
  if (msg?.status?.includes("group_sync_failure") || msg?.status === "group_sync_success" || msg?.status === "dbus_failure") {
    dispatch({
      type: groupTypes.UPDATE_GROUP_STATUS,
      payload: { syncStatus: msg?.status === "group_sync_success", ...msg },
    });
  }
}

export const updateGroupDeleteStatus = (msg, dispatch) => {
  if (msg?.status === "group_delete_success" || msg?.status === "group_sync_success" || msg?.status === "incorrect_groupid") {
    clearTimeout(groupDeleteTimeout);
    setTimeout(() => {
      dispatch({
        type: groupTypes.UPDATE_DELETE_GROUP_STATUS,
        payload: { deleteGroupStatus: "delete_group" + Date.now() },
      });
    }, groupDeleteMessageLatency)
  }
}

export const setGroupDeleteTimeout = (ms, dispatch) => {
  groupDeleteTimeout = setTimeout(() => {
    dispatch({
      type: groupTypes.UPDATE_DELETE_GROUP_STATUS,
      payload: { deleteGroupStatus: "delete_group" + Date.now() },
    });
  }, ms)
}

export const updateGroupControl = (msg, dispatch) => {
  if (msg?.status === "success") {
    const configuration = {};
    if (msg?.hasOwnProperty('lst')) {
      configuration.intensity = msg?.lst;
    }
    if (msg?.hasOwnProperty('klv')) {
      configuration.kelvin = msg?.klv;
    }
    dispatch({
      type: groupTypes?.UPDATE_GROUP_CONTROL_SUCCESS,
      payload: { groupId: msg?.grpId, configuration },
    });
  }
  else {
    dispatch(appActions?.apiErrorMessage(getMessageStr("groupControl-receive-error", ("") + ` (${msg?.grpId})`)))
    dispatch(groupActions?.reloadGroupData(msg?.grpId));
  }
}