
export const months = [
  { key: "1", label: "January", id: "January", },
  { key: "2", label: "February", id: "February" },
  { key: "3", label: "March", id: "March" },
  { key: "4", label: "April", id: "April" },
  { key: "5", label: "May", id: "May" },
  { key: "6", label: "June", id: "June" },
  { key: "7", label: "July", id: "July" },
  { key: "8", label: "August", id: "August" },
  { key: "9", label: "September", id: "September" },
  { key: "10", label: "October", id: "October" },
  { key: "11", label: "November", id: "November" },
  { key: "12", label: "December", id: "December" }]

export const daysOfMonth = [
  { id: "1", label: "1" },
  { id: "2", label: "2" },
  { id: "3", label: "3"},
  { id: "4", label: "4" },
  { id: "5", label: "5" },
  { id: "6", label: "6" },
  { id: "7", label: "7" },
  { id: "8", label: "8" },
  { id: "9", label: "9" },
  { id: "10", label: "10" },
  { id: "11", label: "11" },
  { id: "12", label: "12" },
  { id: "13", label: "13" },
  { id: "14", label: "14" },
  { id: "15", label: "15" },
  { id: "16", label: "16" },
  { id: "17", label: "17" },
  { id: "18", label: "18" },
  { id: "19", label: "19" },
  { id: "20", label: "20" },
  { id: '21', label: "21" },
  { id: "22", label: "22" },
  { id: "23", label: "23" },
  { id: "24", label: "24" },
  { id: "25", label: "25" },
  { id: "26", label: "26" },
  { id: "27", label: "27" },
  { id: "28", label: "28" },
  { id: "29", label: "29" },
  { id: "30", label: "30" },
  { id: "31", label: "31" },

]
export const dayOfWeek = [
  { label: "MON", value: false, id: "1" },
  { label: "TUE", value: false, id: "2" },
  { label: "WED", value: false, id: "3" },
  { label: "THU", value: false, id: "4" },
  { label: "FRI", value: false, id: "5" },
  { label: "SAT", value: false, id: "6" },
  { label: "SUN", value: false, id: "0" },
  { label: "WORK WEEK", value: false, id: "7" }
]

export const hours = [
  { id: 0, label: "00" },
  { id: 1, label: "01" },
  { id: 2, label: "02" },
  { id: 3, label: "03" },
  { id: 4, label: "04" },
  { id: 5, label: "05" },
  { id: 6, label: "06" },
  { id: 7, label: "07" },
  { id: 8, label: "08" },
  { id: 9, label: "09" },
  { id: 10, label: "10" },
  { id: 11, label: "11" },
  { id: 12, label: "12" },
  { id: 13, label: "13" },
  { id: 14, label: "14" },
  { id: 15, label: "15" },
  { id: 16, label: "16" },
  { id: 17, label: "17" },
  { id: 18, label: "18" },
  { id: 19, label: "19" },
  { id: 20, label: "20" },
  { id: 21, label: "21" },
  { id: 22, label: "22" },
  { id: 23, label: "23" }
]

export const minutes = [
  { id: 0, label: "00" },
  { id: 1, label: "01" },
  { id: 2, label: "02" },
  { id: 3, label: "03" },
  { id: 4, label: "04" },
  { id: 5, label: "05" },
  { id: 6, label: "06" },
  { id: 7, label: "07" },
  { id: 8, label: "08" },
  { id: 9, label: "09" },
  { id: 10, label: "10" },
  { id: 11, label: "11" },
  { id: 12, label: "12" },
  { id: 13, label: "13" },
  { id: 14, label: "14" },
  { id: 15, label: "15" },
  { id: 16, label: "16" },
  { id: 17, label: "17" },
  { id: 18, label: "18" },
  { id: 19, label: "19" },
  { id: 20, label: "20" },
  { id: 21, label: "21" },
  { id: 22, label: "22" },
  { id: 23, label: "23" },
  { id: 24, label: "24" },
  { id: 25, label: "25" },
  { id: 26, label: "26" },
  { id: 27, label: "27" },
  { id: 28, label: "28" },
  { id: 29, label: "29" },
  { id: 30, label: "30" },
  { id: 31, label: "31" },
  { id: 32, label: "32" },
  { id: 33, label: "33" },
  { id: 34, label: "34" },
  { id: 35, label: "35" },
  { id: 36, label: "36" },
  { id: 37, label: "37" },
  { id: 38, label: "38" },
  { id: 39, label: "39" },
  { id: 40, label: "40" },
  { id: 41, label: "41" },
  { id: 42, label: "42" },
  { id: 43, label: "43" },
  { id: 44, label: "44" },
  { id: 45, label: "45" },
  { id: 46, label: "46" },
  { id: 48, label: "47" },
  { id: 48, label: "48" },
  { id: 49, label: "49" },
  { id: 50, label: "50" },
  { id: 51, label: "51" },
  { id: 52, label: "52" },
  { id: 53, label: "53" },
  { id: 54, label: "54" },
  { id: 55, label: "55" },
  { id: 56, label: "56" },
  { id: 57, label: "57" },
  { id: 58, label: "58" },
  { id: 59, label: "59" },


]