export const spaceDataTypes = {
    GET_REQUEST: 'SPACETYPE_GET_REQUEST',
    GET_SUCCESS: 'SPACETYPE_GET_SUCCESS',
    GET_FAILURE: 'SPACETYPE_GET_FAILURE',

    GET_ALL_REQUEST: 'SPACETYPE_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'SPACETYPE_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'SPACETYPE_GET_ALL_FAILURE',

    ADD_REQUEST: 'SPACETYPE_ADD_REQUEST',
    ADD_SUCCESS: 'SPACETYPE_ADD_SUCCESS',
    ADD_FAILURE: 'SPACETYPE_ADD_FAILURE',

    UPDATE_REQUEST: 'SPACETYPE_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'SPACETYPE_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'SPACETYPE_UPDATE_FAILURE',

    DELETE_REQUEST: 'SPACETYPE_DELETE_REQUEST',
    DELETE_SUCCESS: 'SPACETYPE_DELETE_SUCCESS',
    DELETE_FAILURE: 'SPACETYPE_DELETE_FAILURE'
}