/***
 *
 * Controller class for user.
 * @file LeftNav.js
 * @description LeftNav component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import { Box, List, ListItem, ListItemButton, ListItemIcon } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defaultSelectedMenu } from '../../config/appConfig';
import { getSessionItem, history, setSessionItem } from '../../helpers';
import { appActions } from '../../redux/actions';
// import routes from '../../routes/routes';
import { delay } from '../../services';
import CustomizedListItem from '../CustomizedListItem';
import ImageWrapper from '../ImageWrapper';
import PopoverContainer from '../PopoverContainer';
// import PropTypes from 'prop-types';
import './LeftNav.scss';

const LeftNav = ({ open, treeNode, routes = [], menuComp }) => {
  const dispatch = useDispatch();
  const selectedLeftMenu = useSelector(state => state?.app?.selectedLeftMenu || defaultSelectedMenu.left);
  // const forceExit = useSelector(state => state?.app?.forceExit);
  const router = useSelector(state => state?.router);

  const [activeMenu, setActiveMenu] = useState(null);
  const [expand, setExpand] = useState(false);
  const [toolTip, setToolTip] = useState({ show: false, message: "" });

  const [sideMenus] = useState(() => {
    return routes?.filter(r => r.leftMenu && !r.isPublicRoute).map((sMenu, index) => {
      if (sMenu.id === defaultSelectedMenu.left) {
        sMenu.leftComponent = menuComp;
      } else {
        sMenu.leftComponent = sMenu.component;
      }
      return sMenu;
    });
  });


  const selectLeftMenu = (obj) => {
    Promise.resolve().then(() => {
      return JSON.parse(getSessionItem("miraie-selected-menu") || JSON.stringify(defaultSelectedMenu));
    }).then((selectedMenu) => {
      const tMenus = routes?.filter(r => r.topMenu && !r.isPublicRoute && r.parent === obj.id);
      // console.log("selectedMenu ---- ", obj, selectedMenu, tMenus, routes);
      setSessionItem("miraie-top-menus", JSON.stringify(tMenus));
      return {
        left: obj?.id || selectedMenu?.left,
        top: obj?.id === selectedMenu?.left ? selectedMenu?.top : ((tMenus && tMenus[0]?.id) || null)//if id of the option the user has clicked on is same as that in session storage(refresh case) take from session storage otherwise take from routes
      }
    }).then(delay(500)).then((menu) => {
      // console.log("selectedMenu 2 ---- ", menu, obj);
      dispatch(appActions.selectLeftMenu(menu?.left));
      dispatch(appActions.selectTopMenu(menu?.top));
    });
    // dispatch(appActions.forceExit(true));
  }

  const handleClick = (event, obj, expanded = false) => {
    // console.log("tttt leftNav locationooo", forceExit, obj, expanded)
    // if(!forceExit) { return true; }
    // const selectedMenu = JSON.parse(getSessionItem("miraie-selected-menu") || JSON.stringify(defaultSelectedMenu));

    // console.log("tttt leftNav locationooo 1", obj, history)

    if (obj?.id !== defaultSelectedMenu.left) {
      // removeSessionItem("miraie-selectedSpace");
      if (history.location.pathname.search(obj?.path) === -1) {
        history.replace("/" + obj?.path);  //// no need to replace if already at that url
      }

    } else {
      setSessionItem("miraie-selected-menu", JSON.stringify(defaultSelectedMenu));
      if (history.location.pathname.search('/spaces/dashboard') === -1) {
        history.replace("/");
      }
    }

    if (obj?.subMenu) {
      setExpand(expanded);
    }
    selectLeftMenu(obj);
  }

  const openToolTip = (event, message) => {
    // console.log(event.currentTarget, toolTip, data);
    if (message) {
      setToolTip({ show: event.currentTarget, message: message });
    }
  }

  const closeToolTip = (event) => {
    setToolTip({ show: false, message: "" });
  }

  useEffect(() => {
    setActiveMenu(JSON.parse(getSessionItem("miraie-selected-menu") || "null"))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSessionItem("miraie-selected-menu")])

  useEffect(() => {
    const selectedMenu = JSON.parse(getSessionItem("miraie-selected-menu"));
    if (selectedMenu) {
      selectLeftMenu({ id: selectedMenu?.left });
    } else {
      selectLeftMenu({ id: defaultSelectedMenu?.left });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const route = routes.find(r => "/" + r.path === router?.location?.pathname);
    // console.log("SelectedMenu 2 ---- ", route, router, router?.location?.pathname, routes);
    if (route) {
      selectLeftMenu({ id: route?.parent || route?.id });
    } else {
      selectLeftMenu({ id: defaultSelectedMenu?.left });
    }
    setActiveMenu({ top: route?.parent ? route?.id : null, left: route?.parent || route?.id })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router?.location])

  return (
    <Box key={"ahap-2121"}>
      {/* {selectedLeftMenu} */}
      {/* <pre>{JSON.stringify(sideMenus)}</pre> */}
      {/* <pre>
      open: 
        {JSON.stringify(open, null, 2)}
        <br/>
        expand: 
        {JSON.stringify(expand, null, 2)}
      </pre> */}
      <List className="LeftNav" data-testid="LeftNav" sx={{ height: "100%" }}>
        {sideMenus.map((menu, index) => (
          <Box key={"sMenu--" + index}>
            {menu?.id === defaultSelectedMenu.left ? (
              <CustomizedListItem
                open={open}
                expand={expand}
                setExpand={setExpand}
                onClick={handleClick}
                item={menu}
                component={menu.leftComponent}
                onMouseEnter={(event) => (!open ? openToolTip(event, menu?.label) : null)}
                onMouseLeave={closeToolTip}
                activeMenu={activeMenu}
              />
            ) : (!open ? (
              <CustomizedListItem
                open={open}
                expand={expand}
                setExpand={setExpand}
                onClick={handleClick}
                item={menu}
                component={menu.leftComponent}
                onMouseEnter={(event) => (openToolTip(event, menu?.label))}
                onMouseLeave={closeToolTip}
                activeMenu={activeMenu}
              />
            ) : (
              !expand &&
              <CustomizedListItem
                key={"sMenu--" + index}
                open={open}
                onClick={handleClick}
                item={menu}
                component={menu.leftComponent}
                activeMenu={activeMenu}
              />
            )
            )}
          </Box>
        ))}
      </List>
      {open && expand &&
        <List className="BottomList" sx={{ bottom: treeNode?.id ? "84px" : "60px" }}>
          {sideMenus.filter(m => m?.leftBottom).map((menu, index) => {
            return (
              <ListItem key={"bottom-menu-" + index} className="BottomListItem" disablePadding>
                {/* <small style={{fontSize: "8px"}}>{menu?.id + " - " + menu?.parent + " - " + activeMenu?.left + " - "}</small> */}
                <ListItemButton
                  onClick={(event) => handleClick(event, menu)}
                  className={(activeMenu?.left === (menu?.id || menu?.parent) || selectedLeftMenu === (menu?.id && menu?.parent)) ? "active-menu" : ""}
                  sx={{
                    minHeight: "60px",
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    minWidth: "64px",
                    padding: "5px"
                  }}
                  onMouseEnter={(event) => (openToolTip(event, menu?.tooltip))}
                  onMouseLeave={closeToolTip}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: '30px',
                      justifyContent: 'center'
                    }}
                  >
                    <ImageWrapper className="Side" src={menu?.icon} style={{ maxWidth: "22px", margin: "auto" }} />
                  </ListItemIcon>
                  {/* <CustomTypography component="div" size={10} sx={{ display: 'block' }} content={menu?.tooltip} /> */}
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      }

      {/* Tooltip popup */}
      <PopoverContainer anchorEl={toolTip?.show} onClose={closeToolTip}>
        <Box sx={{ textAlign: "center" }}>{toolTip?.message || ""}</Box>
      </PopoverContainer>
    </Box>
  )
};

LeftNav.propTypes = {};

LeftNav.defaultProps = {};

export default LeftNav;
