/***
 *
 * Controller class for user.
 * @file CustomCheckBox.js
 * @description CustomCheckBox component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './CustomCheckBox.style.js';
import './CustomCheckBox.scss';
import { Box, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { useState } from 'react';
import { useEffect } from 'react';


const CustomCheckBox = ({ data, label,label2, selectedItems = [], setterFunction = () => { }, idKey, customsx = {outerdiv:{}, container: {}, checkbox: {}, label: {} } }) => {
  const classes = useStyles()
  const [selected, setSelected] = useState([]);
  const [selectedItemsList, setSelectedItems] = useState([]);
  const getOnChange = (checked, idKey, item) => {
    let sArr = selected;
    let sItem= selectedItemsList;
    if (!checked && selected.indexOf(idKey) >= 0) {      
      sArr.splice(selected.indexOf(idKey), 1);
      sItem.splice(selectedItemsList.indexOf(item),1);
    }
    else {
      sArr.push(idKey);
      sItem.push(item);
    }
    setSelected([...sArr]);
    setSelectedItems(sItem)
    setterFunction([...sArr]);

  }

  useEffect(() => {
    if (selectedItems) {
      setSelected(selectedItems);
    }
  }, [selectedItems])


  


  return (
    <Box className={classes?.CustomCheckBox || ""} sx={{minHeight:'100px', maxHeight:'300px', overflowY:'scroll', ...customsx.outerdiv }} data-testid="CustomCheckBox">
      {
        data && data.map((item, index) => (
          <Box key={`checkbox-${index}`} display={'flex'} alignItems="center" sx={{ pr: '10px', ...customsx.container }}>
            <Checkbox
              sx={{ ...customsx.checkbox }}
              checked={selected.includes(item[idKey])}
              onChange={(event) => {
                getOnChange(event.currentTarget.checked, item[idKey], item);
              }
              }
            />
            <Typography sx={{ ...customsx.label }}>
              {
                item[label]
              }
              {
              label2 && item[label2]
              }
            </Typography>
          </Box>
        ))
      }
    </Box>
  )
};

CustomCheckBox.propTypes = {};

CustomCheckBox.defaultProps = {};

export default CustomCheckBox;
