import { createTheme } from "@mui/material";
import { buttonConstants,paletteConstants, typographyConstants } from "../constants";

export const darkTheme = createTheme({
    breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1200,
          xl: 1536,
          xxl: 1700,
        },
    },
  height: {
    input: "25px",
    select: "25px"
},
padding: {
    input: "8px 15px",
    select: "8px 15px"
},
fontSize: {
    label: "0.875rem",
    input: "0.875rem",
    select: "0.875rem"
},
lineHeight: {
    input: "18px",
    select: "24px"
},
zIndex: {
    "mobile stepper": 1000,
    "fab": 1050,
    "speed dial": 1050,
    "app bar": 10,
    "drawer": 1200,
    "modal": 1250,
    "snackbar": 1400,
    "tooltip": 1500
},
arrow: {
    borderColor: "#282626"
},
palette: {
    mode: 'dark',
    primary: {
        main: paletteConstants.PRIMARY,
    },
    // secondary: {
    //     main: '#eae3da',
    // },
    error: {
        main: paletteConstants.ERROR,
    },
    warning: {
        main: paletteConstants.WARNING,
    },
    // info: {
    //     main: '#0058a3',
    // },
    success: {
        main: paletteConstants.SUCCESS,
    },
    text: {
        primary: paletteConstants.dark.TEXT_PRIMARY,
        // disabled: '#767571',
        // secondary: '#767571',
    },
    label: {
        color: paletteConstants.dark.LABEL
    },
    background: {
        papper: "#ffffff"
    }
},
typography: {
    fontFamily: typographyConstants.FONT_FAMILY,
    color: typographyConstants.light.COLOR,
    // h1: {
    //     fontWeight: 700,
    //     fontSize: 40,
    //     lineHeight: 1.1,
    //     fontFamily: 'Lively Headline',
    // },
    // h2: {
    //     fontSize: 36,
    //     fontWeight: 700,
    //     lineHeight: 1,
    //     fontFamily: 'Lively Headline',
    // },
    // h3: {
    //     fontSize: 28,
    //     fontWeight: 700,
    //     lineHeight: 1.14,
    //     fontFamily: 'Lively Headline',
    // },
    // h4: {
    //     fontSize: 24,
    //     fontWeight: 700,
    //     lineHeight: 1.17,
    //     fontFamily: 'Lively Headline',
    // },
    // h5: {
    //     fontSize: 16,
    //     fontWeight: 700,
    //     lineHeight: 1.25,
    //     fontFamily: 'Lively Headline',
    // },
    // h6: {
    //     fontSize: 14,
    //     fontWeight: 700,
    //     lineHeight: 1.29,
    //     fontFamily: 'Lively Headline',
    // },
    // subtitle1: {
    //     fontSize: 16,
    //     fontWeight: 400,
    //     lineHeight: 1.62,
    //     fontFamily: 'Noto IKEA Latin'
    // },
    // subtitle2: {
    //     fontSize: 14,
    //     fontWeight: 400,
    //     fontFamily: 'Noto IKEA Latin'
    // },
    // body1: {
    //     fontSize: 12,
    //     fontFamily: 'Noto IKEA Latin'
    // },
    // body2: {
    //     lineHeight: 1.6,
    //     fontSize: 10,
    //     fontFamily: 'Noto IKEA Latin'
    // },
    button: {
        fontSize: 12,
        fontWeight: 600,
        lineHeight: '14.63px',
        fontFamily: typographyConstants.FONT_FAMILY,
    },
},
input:{
    background:'#303030'
},
boxBgColor:{
    background:''
},
components: {
    MuiButton: {
        styleOverrides: {
            root: {
                textTransform: 'none'
            }
        },
        variants: [
            {
                props: { color: 'primary' },
                style: {
                    minWidth: 'fit-content',
                    borderRadius: buttonConstants.BORDER_RADIUS,
                    padding: buttonConstants.PADDING_SMALL,
                    backgroundColor: paletteConstants.PRIMARY,
                    color: paletteConstants.dark.TEXT_SECONDARY,
                    fontSize: '0.75rem',
                    lineHeight: '0.9375rem',
                    '&:hover': {
                        backgroundColor: '#0b6332'
                    },
                    '&:active': {
                        backgroundColor: '#0b6332',
                    },
                    '&focus': {
                        outline: 'none'
                    }
                },
            },
            {
                props: { color: 'secondary' },
                style: {
                    minWidth: 'fit-content',
                    borderRadius: buttonConstants.BORDER_RADIUS,
                    padding: buttonConstants.PADDING_SMALL,
                    backgroundColor: 'rgba(15, 141, 72, 0.1)',
                    color: paletteConstants.PRIMARY,
                    fontSize: '0.75rem',
                    lineHeight: '0.9375rem',
                    '&:hover': {
                        backgroundColor: 'rgba(15, 141, 72, 0.2)'
                    },
                    '&:active': {
                        backgroundColor: 'rgba(15, 141, 72, 0.2)',
                    },
                    '&focus': {
                        outline: 'none'
                    }
                },
            },
            {
                props: { variant: 'outlined' },
                style: {
                    minWidth: 'fit-content',
                    borderRadius: buttonConstants.borderRadius,
                    padding: buttonConstants.PADDING_SMALL,
                    color: paletteConstants.PRIMARY,
                    borderWidth: buttonConstants.BORDER_WIDTH,
                    borderStyle: buttonConstants.BORDER_STYLE,
                    borderColor: paletteConstants.PRIMARY,
                    backgroundColor: '#ffffff',
                    fontSize: '0.75rem',
                    lineHeight: '0.9375rem',
                    '&:hover': {
                        color: paletteConstants.dark.TEXT_SECONDARY,
                        backgroundColor: paletteConstants.PRIMARY
                    },
                    '&:active': {
                        backgroundColor: paletteConstants.PRIMARY
                    },
                },
            },
            {
                props: { size: "small" },
                style: {
                    padding: buttonConstants.PADDING_SMALL,
                    fontSize: '0.75rem',
                    lineHeight: '0.9375rem'
                }
            },
            {
                props: { size: "medium" },
                style: {
                    padding: buttonConstants.PADDING_MEDIUM,
                    fontSize: '0.75rem',
                    lineHeight: '0.9375rem'
                }
            },
            {
                props: { size: "large" },
                style: {
                    padding: buttonConstants.PADDING_LARGE,
                    fontSize: '0.75rem',
                    lineHeight: '0.9375rem'
                }
            },
            {
                props: { size: "extra-large" },
                style: {
                    padding: buttonConstants.PADDING_EXTRA_LARGE,
                    fontSize: '0.875rem',
                    lineHeight: '17px'
                }
            }
        ]
    },
    // MuiTextField: {
    //     variants: [
    //         {
    //             props: { hiddenLabel: true },
    //             style: {
    //                 [`& .${outlinedInputClasses.notchedOutline}`]: {
    //                     border: 'none',
    //                 },
    //                 [`& .${outlinedInputClasses.input}`]: {
    //                     background: '#FFFFFF',
    //                     border: '1px solid #D9D9D9',
    //                     borderRadius: '4px',
    //                     color: 'rgba(0, 0, 0, 0.8)',
    //                     padding: '8px 10px',
    //                     fontWeight: 400,
    //                     fontSize: '14px',
    //                     lineHeight: '17px',
    //                 },
    //             }
    //         }
    //     ]
    // }
}
    })