/***
 *
 * Controller class for user.
 * @file CanvasArea.js
 * @description CanvasArea component
 * @author Naveen Kumar
 * @since 20 June 2022
 *
 */

import React from "react";
// import PropTypes from 'prop-types';
import "./CanvasArea.scss";
import Canvas from "../Canvas";
// import backgroundImage from "../../assets/img/Floor-plan2.jpg";
import RightPanel from "../Canvas/RightPanel";
import { customsx, useStyles } from "./CanvasArea.style";
import LeftPanel from "../Canvas/LeftPanel";
import { Box, Drawer, Grid, IconButton } from "@mui/material";
// import ClearIcon from '@mui/icons-material/Clear';
import { useEffect } from 'react';
import { ReactComponent as Discard } from '../../assets/icons/reset.svg'
import CustomTypography from '../CustomTypography';
import { useState } from 'react';
import { canvasActions, spaceActions } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import UploadIcon from '@mui/icons-material/Upload';
import SaveIcon from '@mui/icons-material/Save';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import DownloadIcon from '@mui/icons-material/Download';
import ExploreIcon from '@mui/icons-material/Explore';
// import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
// import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import _ from "lodash";
import StyledPopup from "../StyledPopup";
import FormWrapper from "../FormWrapper";
import Draggable from "react-draggable";
import Tour from "reactour";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
// import closeIcon from '../../assets/icons/cancel-2.svg'
import ArrowIcon from "../../assets/icons/back.svg";
import ImageWrapper from "../ImageWrapper";
import StyledButton from "../StyledButton";
const openDialogObj = {
  reset: false,
  uploadDwg: false,
  download: false,
};

const dwgConfig = [
  {
    section: { heading: null },
    columns: [
      {
        id: "4",
        type: "file",
        allowedFileType: ".dwg",
        label: "Upload DWG file",
        name: "uploadDwg",
        format: ["dwg"],
        validation: {
          req: [true, "Icon required"],
          fileSize: [5000, "File size should be less than 5MB"], /// in kB , for MB use size>=1000
        },
        placeholder: "Enter",
        columnSize: 12,
        preview: {
          width: "auto",
          maxHeight: "200px",
        },
      },
    ],
  },
];

const pngConfig = [
  {
    section: { heading: null },
    columns: [
      {
        id: "4",
        type: "file",
        allowedFileType: "image/png, image/PNG",
        name: "uploadPng",
        format: ["png", "PNG"],
        validation: {
          req: [true, "Icon required"],
          fileSize: [5000, "File size should be less than 5MB"], /// in kB , for MB use size>=1000
        },
        placeholder: "Enter",
        columnSize: 12,
        helperText: "Upload png between size of 1280px and 1920px",
        helperColor: "#0f8d48",
        imageResolution: { minWidth: 1280, maxWidth: 1920 },
        preview: {
          width: "auto",
          maxHeight: "200px",
        },
      },
    ],
  },
];

const CanvasArea = ({
  selectSpace,
  modalOpen,
  value,
  setValue,
  setSelectedSpace,
  setOpenModal,
  mqttClient,
}) => {
  const givenshapes = useSelector((state) => state?.canvas?.shapes);
  const [shapes, setShapes] = useState(givenshapes ? givenshapes : []);
  const _spaceList = useSelector((state) => state?.spaces?.list);
  const _deviceList = useSelector((state) => state?.devices?.deviceList);
  const _subSpaceTree = useSelector((state) => state?.spaces?.subSpaceTree);
  const _modalselectedSpace = useSelector((state) => state?.canvas?.modalSpace);
  const _addAssetObj = useSelector((state) => state?.spaces?.assetObj);
  const _updatedSpaceAsset = useSelector((state) => state?.spaces?.updatedAsset );
  const _observeChange = useSelector((state) => state?.canvas?.observeChange);
  const _spaceLayouts = useSelector((state) => state?.canvas?.spaceLayouts);
  const _spaceConnectedAsset = useSelector((state) => state?.spaces?.spaceConnectedAsset);
  const _rootSpace = useSelector((state) => state?.spaces?.rootSpace);
  const [disabletooltip, setDisableToolTip] = useState(false);
  const [image, setImage] = useState(null);
  const [fileAsset, setFileAsset] = useState(null);
  const [dialogKey, setDialogKey] = useState(null);
  const [openDialog, setOpenDialog] = useState(openDialogObj);
  const [payloadData, setPayloadData] = useState({});
  const [imgUpload, setImgUpload] = useState({});
  const [pngFormData] = useState({});
  const [dwgFormData] = useState({});
  const [filesObj, setFilesObj] = useState({});
  const [openDrawer, setOpenDrawer] = useState({
    spaceMenu: false,
    shapeMenu: false,
  });
  const [floaterDragging, setFloaterDragging] = useState({});
  const [tourOpen, setTourOpen] = useState(false);
  const [shapeopen, setShapeOpen] = useState(false);
  const [spaceopen, setSpaceOpen] = useState(false);
  const [showNavigation, setShowNavigation] = useState(true);
  const [currentSpace, setCurrentSpace] = useState(selectSpace);
  const [changeModalSpace, setChangeModalSpace] = useState("");
  const [loaderOnClick, setLoaderonClick] = useState(false);
  const [shapeAbsent, setShapeAbsent] = useState(null);
  const [tourPanel, setTourPanel] = useState("panel1");
  const [zoomScale,setZoomScale] = useState(1);
  const [zoomType,setZoomType] = useState('');

  const dispatch = useDispatch();

  const classes = useStyles();

  const component = {
    saveChanges: {
      compo: (
        <p>
          <h4>Save Changes?</h4>
          <br /> Do you want to save Changes made to canvas?
        </p>
      ),
      btns: [
        {
          id: "Discard",
          type: "button",
          label: "Discard Changes",
          onClick: (event, data) => {
            setValue({
              ..._spaceList?.find((s) => s?.spaceId === selectSpace?.spaceId),
            });
            handleClose();
            setOpenDialog({ ...openDialog, [dialogKey]: false });
            setChangeModalSpace("close");
          },
        },
        {
          id: "Save",
          type: "button",
          label: "Save",
          onClick: (event, data) => {
            if(!_.isEmpty(shapes?.filter(s=> s?.spaceId === selectSpace?.spaceId && s?.shapeType === 'device' && s?.deviceData?.status === 'inactive'))){
              setDialogKey('inactiveDevices');
              setOpenDialog({...openDialog, 'inactiveDevices':true});
              return;
            }  
            const deviceIds = _deviceList?.map(d=>d?.deviceId);
            // console.log('not present::',shapes?.filter(s=>s?.spaceId === selectSpace?.spaceId && s?.shapeType === 'device' && !deviceIds.includes(s?.deviceId)), _deviceList);
          if(shapes?.filter(s=>s?.spaceId === selectSpace?.spaceId && s?.shapeType === 'device' && !deviceIds.includes(s?.deviceId)).length>0  ){
          setDialogKey('noDevicesPresent');
          setOpenDialog({...openDialog, 'noDevicesPresent':true});
          return;
          } 
            handleSave();
          },
        },
      ],
      title: "",
      closeButton:true
    },
    reset: {
      compo: (
        <p>
          <CustomTypography content={'Reset Layout?'} size={24} weight={700} />
          <br /><CustomTypography content={'It will remove all the shapes and devices'} size={14} />
          <br /><CustomTypography content={'*You can only reset the current layout if it does not contain child shapes in it.'} color="#0f8d48" size={12} />
        </p>
      ),
      btns: [
        {
          id: "resetYes",
          type: "button",
          label: "Yes",
          disabled: !_.isEmpty(selectSpace?.Spaces?.find(sp => shapes?.find(shp => shp?.spaceId === sp?.spaceId))),
          onClick: (event, data) => {
            handleReset(event);
            setOpenDialog({ ...openDialog, [dialogKey]: false });
            dispatch(canvasActions.observeChange('SHAPE_REMOVED'));
          }
        },
        {
          id: "resetNo",
          type: "button",
          label: "No",
          onClick: (event, data) => {
            setOpenDialog({ ...openDialog, [dialogKey]: false });
          },
        },
      ],
      title: "",
    },
    uploadDwg: {
      title: "Upload DWG File",
      compo: (
        <Box>
          <FormWrapper
            formData={dwgFormData}
            FilesObj={(data) => {
              setFilesObj({ ...filesObj, ...data });
            }}
            formFields={dwgConfig}
            setPayload={(data) => {
              setPayloadData(data);
            }}
          />
        </Box>
      ),
      btns: [
        {
          id: "uploadDwg",
          type: "button",
          label: "Upload",
          variant: "contained",
          disabled: _.isEmpty(payloadData) || _.isEmpty(filesObj[dialogKey]),
          onClick: (event, data) => {
            handleClickDWG();
            setOpenDialog({ ...openDialog, [dialogKey]: false });
          },
        },
        {
          id: "cancelDwg",
          type: "button",
          label: "Cancel",
          onClick: (event, data) => {
            setPayloadData({});
            setFilesObj({ ...filesObj, [dialogKey]: [] });
            setOpenDialog({ ...openDialog, [dialogKey]: false });
          },
        },
      ],
    },
    uploadPng: {
      title: "Upload PNG file",
      compo: (
        <Box>
          <FormWrapper
            formData={pngFormData}
            FilesObj={(data) => {
              setFilesObj({ ...filesObj, ...data });
            }}
            formFields={pngConfig}
            setPayload={(data) => {
              setImgUpload(data);
            }}
          />
        </Box>
      ),
      btns: [
        {
          id: "uploadPng",
          type: "button",
          label: "Upload",
          variant: "contained",
          checkValidation: true,
          disabled: _.isEmpty(imgUpload) || _.isEmpty(filesObj[dialogKey]),
          onClick: (event, data) => {
            imageUpload(imgUpload);
            setOpenDialog({ ...openDialog, [dialogKey]: false });
          },
        },
        {
          id: "cancelPng",
          type: "button",
          label: "Cancel",
          onClick: (event, data) => {
            setImgUpload({});
            setFilesObj({ ...filesObj, [dialogKey]: [] });
            setOpenDialog({ ...openDialog, [dialogKey]: false });
          },
        },
      ],
    },
    download: {
      title: "",
      compo: <CustomTypography content="Download dwg file" />,
      btns: [
        {
          id: "dwnDwg",
          type: "button",
          label: "Download DWG",
          variant: "contained",
          disabled: _.isEmpty(value?.dwgFile),
          onClick: (event, data) => {
            handleDownload("dwg");
            setOpenDialog({ ...openDialog, [dialogKey]: false });
          },
        },
        {
          id: "cancel",
          type: "button",
          label: "Cancel",
          variant: "outlined",
          onClick: (event, data) => {
            setOpenDialog({ ...openDialog, [dialogKey]: false });
          },
        },
      ],
    },
    shapesPresent: {
      compo: (
        <CustomTypography
          content={`Cannot save until all shapes are added. Please add shape to ${_spaceList?.find((spc) => shapeAbsent?.spaceId === spc?.spaceId)
              ?.name || ""
            } space`}
        />
      ),
      btns: [
        {
          id: "CloseBtn",
          type: "button",
          label: "Close",
          onClick: (event, data) => {
            setOpenDialog({ ...openDialog, [dialogKey]: false });
            setShapeAbsent(null);
          },
        },
      ],
      title: "",
    },
    inactiveDevices: {
      compo: (
        <CustomTypography
          content={`Cannot save or discard changes until all inactive devices are removed. Please remove all inactive devices`}
        />
      ),
      btns: [
        {
          id: "CloseBtn",
          type: "button",
          label: "Close",
          onClick: (event, data) => {
            setOpenDialog({ ...openDialog, [dialogKey]: false });
            setDialogKey('');
          },
        },
      ],
      title: "Remove Inactive Devices",
    }, 
    noDevicesPresent: {
      compo: (
        <CustomTypography
          content={`Please remove all devices which are not present in current space.`}
        />
      ),
      btns: [
        {
          id: "CloseBtn",
          type: "button",
          label: "Close",
          onClick: (event, data) => {
            setOpenDialog({ ...openDialog, [dialogKey]: false });
            setDialogKey('');
          },
        },
      ],
      title: "",
    },

  };

  const openSideDrawer = (type) => {
    if (type === "spaceMenu") {
      if (openDrawer["spaceMenu"])
        setOpenDrawer({ ...openDrawer, spaceMenu: false, shapeMenu: false });
      else setOpenDrawer({ ...openDrawer, spaceMenu: true, shapeMenu: false });
    } else {
      if (openDrawer["shapeMenu"])
        setOpenDrawer({ ...openDrawer, shapeMenu: false, spaceMenu: false });
      else setOpenDrawer({ ...openDrawer, shapeMenu: true, spaceMenu: false });
    }
  };

  //// for update/add payload
  useEffect(() => {
    setShapes(givenshapes);
  }, [givenshapes]);

  /// redundant
  // useEffect(() => {
  //   if (_subSpaceTree) {
  //     dispatch(canvasActions.modalSpace(_subSpaceTree[0]));
  //   }
  // }, [_subSpaceTree]);

  useEffect(() => {
    // console.log("added space asset", shapes,  _addAssetObj,   _spaceConnectedAsset, value  );
    const isParent = _rootSpace?.spaceId === value?.parentSid;
    let devices = shapes.filter((s) => s?.spaceId === selectSpace?.spaceId && s?.shapeType === "device");

    if (!_.isEmpty(_addAssetObj) && !isParent) {

      let configArr = [];
      // let configpayLoadForGivenSpace = {
      //   "spaceId": selectSpace?.spaceId,
      //   "config": JSON.stringify({shapes:  shapes.filter(s => s?.spaceId === selectSpace?.spaceId && s?.shapeType === 'shape'), layout: _spaceLayouts.find(s=>s?.spaceId === selectSpace?.spaceId)})
      // };
      let entitypayload = {
        spaceId: selectSpace?.spaceId,
        default: true,
      };
      _spaceConnectedAsset?.forEach((sa) => {
        let obj = {},
          emptyentity = { spaceId: sa?.spaceId, default: true };
        if (_.isEmpty(shapes) &&!_spaceLayouts.find((s) => s?.assetId === _addAssetObj?.assetId) ) {
          obj["spaceId"] = sa?.spaceId;
          obj["config"] = JSON.stringify({ shapes: [], layout: {} });
          dispatch(  spaceActions.addUpdateSpaceEntities( _addAssetObj?.assetId,  emptyentity )); /// adding empty entities
        } 
        else { 
          obj["spaceId"] = sa?.spaceId;  
          obj["config"] = JSON.stringify(
            { shapes: shapes.filter((s) => s?.shapeType === "shape"), 
            layout: _spaceLayouts.find((s) => s?.assetId === _addAssetObj?.assetId ),
          }); }
        configArr.push(obj);
      });
      // console.log("config arr2--->", configArr, _spaceConnectedAsset);

      dispatch( spaceActions.addUpdateSpaceConfigs(_addAssetObj?.assetId, [...configArr,])); ///// shapes and layout
      if (_.isEmpty(devices)) {
        dispatch(spaceActions.addUpdateSpaceEntities(_addAssetObj?.assetId,entitypayload)); /// devices
      } else {
        entitypayload["entities"] = devices.map((d) => {
          return { deviceId: d?.deviceId, config: JSON.stringify(d) };
        });
        dispatch(spaceActions.addUpdateSpaceEntities(_addAssetObj?.assetId, entitypayload));}

      dispatch(spaceActions.getAllAsset(selectSpace?.spaceId));
      dispatch(spaceActions.emptySpaceState("ADD_ASSET_SUCCESS"));
      dispatch(canvasActions.observeChange(null));
    }
    if (!_.isEmpty(_addAssetObj) && isParent) {
      let entitypayload = {
        spaceId: value?.spaceId,
        default: true,  /// to get url
      };
      // console.log("___spacelayout", _spaceLayouts, _spaceLayouts.find((s) => s?.spaceId === value?.spaceId));
      dispatch(spaceActions.associateAssetwithSpace({ spaceId: value?.spaceId, assetId: _addAssetObj?.assetId, }));
      if (!_.isEmpty(devices)) {
        entitypayload["entities"] = devices.map((d) => {
          return { deviceId: d?.deviceId, config: JSON.stringify(d) };
        });
      }
      dispatch(spaceActions.addUpdateSpaceEntities(_addAssetObj?.assetId, entitypayload));
      dispatch(canvasActions.observeChange(null));
      dispatch(spaceActions.emptySpaceState("ADD_ASSET_SUCCESS"));
      dispatch(
        spaceActions.addUpdateSpaceConfigs(_addAssetObj?.assetId, [
          {
            spaceId: value?.spaceId,
            config: JSON.stringify({
              shapes: shapes,
              layout: {
                ..._spaceLayouts.find((s) => s?.spaceId === value?.spaceId),
                assetId: _addAssetObj?.assetId,
              },
            }),
          },
        ])
      );
      if (_spaceLayouts) {
        let findLayoutofParentSpace = _spaceLayouts?.find( (s) => s?.spaceId === value?.spaceId );
        dispatch(canvasActions.replaceImage({...findLayoutofParentSpace, assetId: _addAssetObj?.assetId, }));
        // dispatch(canvasActions.spaceLayouts({
        //   ..._spaceLayouts?., url: item?.url || layout?.url || canvasProfile?.spaceLayout || '', spaceId: spaceId, assetId: assetId, uniq_id: `${spaceId}_layout`
        // }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_addAssetObj]);

  useEffect(() => {
    if (_updatedSpaceAsset === 200) {
      dispatch(spaceActions.getAllAsset(selectSpace?.spaceId));
      if (openDialog[dialogKey]) {
        // console.log('close dialog', openDialog[dialogKey], dialogKey, changeModalSpace);
        handleClose();
        setOpenDialog({ ...openDialog, [dialogKey]: false });
      }
      dispatch(canvasActions.observeChange(null));
      dispatch(
        spaceActions.emptySpaceState("UPDATE_ASSET_CONFIGURATION_SUCCESS")
      );
      dispatch(canvasActions.removeShapes(null)); //// commented for given shapessss
      setDialogKey("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_updatedSpaceAsset]);

  /// redundant
  // useEffect(() => {
  //   if (_spaceList) {
  //     dispatch(spaceActions.createSubTree(_modalselectedSpace, _spaceList));
  //   }
  // }, [_spaceList]);

  const imageUpload = (data) => {
    const [files] = data?.uploadPng?.file["uploadPng"];
    const file = files;
    var url = URL.createObjectURL(file);
    var img = new Image();
    img.src = url;

    img.onload = function () {
      let widthRatio = window.innerWidth / img.naturalWidth,
        heightRatio = window.innerHeight / img.naturalHeight;
      let bestRatio = Math.min(widthRatio, heightRatio);
      // setImage({ url: url, height: img?.naturalHeight*bestRatio , width:  img?.naturalWidth*bestRatio  });
      setImage({
        url: url,
        height:
          window.innerHeight > img?.naturalHeight
            ? window.innerHeight
            : img.naturalHeight,
        width:
          img?.naturalWidth > window.innerWidth
            ? window.innerWidth < 1920
              ? window.innerWidth
              : 1920
            : img.naturalWidth > 1280
              ? img.naturalWidth
              : 1280,
      });
      setFileAsset(file);
    };
    setImgUpload({});
  };

  const handleClickDWG = () => {
    let asset = value?.asset;
    if (!asset || _.isEmpty(asset) || !asset.find((a) => a?.mime === "dwg")) {
      fetch(payloadData?.uploadDwg?.base64)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "layout.dwg");
          dispatch(
            spaceActions.addSpaceAsset(value?.spaceId, {
              image: file,
              mime: "dwg",
            })
          );
        });
    } else if (
      Array.isArray(asset) &&
      asset.length > 0 &&
      asset.find((a) => a?.mime === "dwg")
    ) {
      const assetId = asset.find((a) => a?.mime === "dwg").assetId;
      fetch(payloadData?.uploadDwg?.base64)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "layout.dwg");
          dispatch(
            spaceActions.UpdateSpaceAsset(value?.spaceId, assetId, {
              image: file,
            })
          );
        });
    }
    setPayloadData({});
  };

  const handleSave = () => {
    const asset = selectSpace?.asset;

    if (_.isEmpty(asset) || !asset.find((a) => a?.mime === "png")) {
      //// if asset does not exist
      if (fileAsset) {
        /// and file is uploaded
        dispatch(
          spaceActions.addSpaceAsset(selectSpace?.spaceId, {
            image: fileAsset,
            type: "layout",
            mime: "png",
            spaceIds: selectSpace?.spaceIds,
          })
        );
      }
    } else {
      const assetId = asset.find((a) => a?.mime === "png")?.assetId;
      const filterConnectedspaces = _spaceConnectedAsset?.filter(
        (sa) => sa?.assetId === assetId
      );
      if (fileAsset) {
        //// if reupload it
        dispatch(
          spaceActions.UpdateSpaceAsset(selectSpace?.spaceId, assetId, {
            image: fileAsset,
          })
        );
      }
      let devices = shapes.filter(
        (s) => s?.spaceId === selectSpace?.spaceId && s?.shapeType === "device"
      );
      let configArr = [];

      //// add those devices
      let entitypayload = {
        spaceId: selectSpace?.spaceId,
        default: true,
      };

      filterConnectedspaces?.forEach((sa) => {
        if (!_.isEmpty(_spaceList?.find((s) => s?.spaceId === sa?.spaceId))) {
          let obj = {},
            emptyentity = { spaceId: sa?.spaceId, default: true };
          if (
            !shapes?.find((s) => s?.spaceId === sa?.spaceId) &&
            !_spaceLayouts.find((s) => s?.assetId === assetId)
          ) {
            obj["spaceId"] = sa?.spaceId;
            obj["config"] = JSON.stringify({ shapes: [], layout: {} });
            dispatch(spaceActions.addUpdateSpaceEntities(assetId, emptyentity)); /// adding devices
            // console.log('hit here 1==>', shapes, _spaceLayouts);
          } else {
            obj["spaceId"] = sa?.spaceId;
            obj["config"] = JSON.stringify({
              shapes: shapes.filter((s) => s?.shapeType === "shape"),
              layout: _spaceLayouts.find((s) => s?.assetId === assetId),
            });
            // console.log('hit here 2==>', shapes, _spaceLayouts);
          }
          configArr.push(obj);
        }
      });

      // dispatch(spaceActions.UpdateSpaceConfigurationAsset(selectSpace?.spaceId, assetId, filteredSpace));
      dispatch(spaceActions.addUpdateSpaceConfigs(assetId, [...configArr])); /// adding shape
      if (_.isEmpty(devices)) {
        dispatch(spaceActions.addUpdateSpaceEntities(assetId, entitypayload)); /// adding devices
      } else {
        // entitypayload["entities"]= JSON.stringify(shapes.filter(s => s?.spaceId === selectSpace?.spaceId && s?.shapeType === 'device'));
        entitypayload["entities"] = devices.map((d) => {
          return { deviceId: d?.deviceId, config: JSON.stringify(d) };
        });
        dispatch(spaceActions.addUpdateSpaceEntities(assetId, entitypayload));
      }
      setSelectedSpace(currentSpace);
    }
    setFileAsset(null);
    dispatch(canvasActions.clearSelection());
    dispatch(canvasActions.observeChange(null));
  };

  const handleDownload = (type) => {
    var link = document.createElement("a");
    link.href = type === "dwg" ? value?.dwgFile : value?.spaceLayout;
    link.setAttribute("target", "_blank");
    link.setAttribute("download", "layout.dwg");

    document.body.appendChild(link);
    // //console.log('file asset====>', selectSpace?.spaceLayout, link);
    link.click();
    document.body.removeChild(link);
  };

  const handleReset = () => {
    dispatch(canvasActions.removeShapes(selectSpace));
    dispatch(canvasActions.resetRightPanel());
  };

  const floatingBtns = [
    {
      id: "upload_png",
      name: "upload_png",
      type: "upload",
      label: "Upload PNG",
      display: _rootSpace?.spaceId === value?.parentSid && _.isEmpty(shapes),
      icon: <UploadIcon />,
      background: "#1976D2",
      onClick: () => {
        setDialogKey("uploadPng");
        setOpenDialog({ ...openDialog, uploadPng: true });
      },
    },
    {
      id: "upload_dwg",
      name: "upload_dwg",
      type: "upload",
      label: "Upload DWG",
      disabled: false,
      display: true,
      icon: <UploadIcon />,
      background: "#1976D2",
      onClick: () => {
        setDialogKey("uploadDwg");
        setOpenDialog({ ...openDialog, uploadDwg: true });
      },
    },
    {
      id: "save",
      name: "save",
      label: "Save",
      type: "save",
      display: true,
      icon: <SaveIcon />,
      background: "green",
      onClick: () => {
        if(!_.isEmpty(shapes?.filter(s=>s?.spaceId === selectSpace?.spaceId && s?.shapeType === 'device' && s?.deviceData?.status === 'inactive'))){
          setDialogKey('inactiveDevices');
          setOpenDialog({...openDialog, 'inactiveDevices':true});
          return;
        }        
        const deviceIds = _deviceList?.map(d=>d?.deviceId);
        console.log('not present::',shapes?.filter(s=>s?.spaceId === selectSpace?.spaceId && s?.shapeType === 'device'), _deviceList);
        if(shapes?.filter(s=>s?.spaceId === selectSpace?.spaceId && s?.shapeType === 'device' && !deviceIds.includes(s?.deviceId)).length>0  ){
          setDialogKey('noDevicesPresent');
          setOpenDialog({...openDialog, 'noDevicesPresent':true});
          return;
        }  
        handleSave();
      },
    },
    {
      id: "reset",
      name: "reset",
      type: "reset",
      display: true,
      label: "Reset",
      icon: <Discard />,
      background: "#FF4336",
      onClick: () => {
        setDialogKey("reset");
        setOpenDialog({ ...openDialog, reset: true });
      },
    },
    {
      id: "download",
      name: "download",
      type: "download",
      display: true,
      disabled: _.isEmpty(value?.dwgFile),
      label: "Download DWG",
      background: "green",
      icon: <DownloadIcon />,
      onClick: () => {
        // handleDownload();
        setDialogKey("download");
        setOpenDialog({ ...openDialog, download: true });
      },
    },
    {
      id: "zoomin",
      name: "zoomin",
      type: "zoom",
      display: true,
      disabled: zoomScale === 2,
      label: "Zoom-In",
      icon: <ZoomInIcon />,
      background: "green",
      onClick: () => {
        dispatch(canvasActions.zoomin());        
        let scale =  zoomScale + 0.2 >= 2 ? 2 : zoomScale + 0.2
        setZoomScale(Math.round(scale*10)/10);
        setZoomType('zoomIn');
      },
    },
    {
      id: "zoomout",
      name: "zoomout",
      type: "zoom",
      display: true,
      disabled: zoomScale === 1,
      label: "Zoom-Out",
      icon: <ZoomOutIcon />,
      background: "green",
      onClick: () => {
        dispatch(canvasActions.zoomout());
        let scale =  zoomScale - 0.2 <= 1 ? 1 : zoomScale - 0.2
        setZoomScale(Math.round(scale*10)/10);
        setZoomType('zoomOut');
      },
    },
    {
      id: "tourguide",
      name: "tourguide",
      type: "guide",
      display: true,
      disabled: false,
      label: "Tourguide",
      icon: <ExploreIcon />,
      background: "green",
      onClick: () => {
        setTourPanel('');
        setTourOpen(true);
        setOpenDrawer({ ...openDrawer, shapeMenu: false, spaceMenu: false });
        setSpaceOpen(false);
        setShapeOpen(false);
      },
    },
  ];


  const handleClickSpace = (data) => {
    setLoaderonClick(true);
    dispatch(canvasActions.highlightGroup(null));
    if (!_.isEmpty(_observeChange)) {
      setDialogKey("saveChanges");
      setOpenDialog({ ...openDialog, saveChanges: true });
      setChangeModalSpace("spacetree");
      setCurrentSpace(data);
    } else {
      dispatch(spaceActions.emptySpaceState("GET_ALL_ASSET_SUCCESS", null));
      dispatch(spaceActions.emptySpaceState("GET_GIVEN_ASSET_SUCCESS", null));
      // console.log('got data', data);
      setSelectedSpace(data);
      setCurrentSpace(data);
      setChangeModalSpace("");
      dispatch(spaceActions.getAllAsset(data?.spaceId));
      dispatch(spaceActions.selectSpace(data));
    }
  };

  const handleClose = () => {
    if (changeModalSpace === "close" || changeModalSpace === "") {
      setOpenModal(false);
      dispatch(
        spaceActions.selectSpace(
          _spaceList?.find((s) => s?.spaceId === selectSpace?.spaceId)
        )
      );
    } else {
      setSelectedSpace(currentSpace);
      dispatch(spaceActions.selectSpace(currentSpace));
      dispatch(spaceActions.getAllAsset(currentSpace?.spaceId));
    }
    dispatch(canvasActions.clearSelection());
    dispatch(canvasActions.observeChange(null));
    dispatch(canvasActions.modalState(false));
  };
  const tourSteps = [
    {
      selector: "#canvasGrid", //0
      content: `2D Editor tour guide help with step by step journey to configure the space layout.`,
    },
    {
      selector: "#iconTray", //1
      content: ` Icon tray provide various operation to start the 2D Editor journey starting with uploading the PNG file, Reset operation, Save the editor etc.`,
    },
    {
      selector: "#spaceMenu", //2
      content: `Click here to open space menu.`,
    },
    {
      selector: '[data-tour="first-step"]', //3
      content: `Select the space or sub space from the space tree for which space layout to be configured.`,
    },
    {
      selector: "#shapeMenu", //4
      content: `Click here to open shapes, devices and properties menu.`,
    },
    {
      selector: "#shapeDrawer", //5
      content: `The right panel allows to drag and drop custom shape and devices and change properties of a shape linked to selected space.`,
    },
    {
      selector: "#shapeBox", //6
      content: `Select the appropriate shape and drag & drop to the space layout linking with selected space from space tree.`,
    },
    {
      selector: "#deviceBox", //7
      content: `Select the appropriate device and drag & drop on the plotted shape on space layout. Light device can be identified for the appropriate position using "Identify Device" operation before plotting on the space.`,
    },
    {
      selector: "#propertyBox", //8
      content: `Change the properties of the shape once you drag it on the canvas`,
    },
    {
      selector: "#canavsGrid",
      content: "Thanks for watching the 2D Editor tour guide.",
    },
  ];

  return (
    <>
      <Grid
        item
        xs={12}
        style={{ marginTop: "5px", overflow: "hidden" }}
        className="CanvasArea"
        data-testid="CanvasArea"
      >
        <Box>
          <Drawer
            anchor={"left"}
            transitionDuration={5}
            variant="persistent"
            className={
              openDrawer["spaceMenu"] || spaceopen
                ? "left-nav-bar-open"
                : "left-nav-bar-close"
            }
            open={openDrawer["spaceMenu"] || spaceopen || false}
            onClose={() => {
              setOpenDrawer({ ...openDrawer, spaceMenu: false });
            }}
          >
            <Box className="drawer" id="spaceDrawer" data-tour="first-step">
              <LeftPanel
                space={selectSpace}
                canvasProfile={value}
                onChange={(data) => { if (data?.spaceId !== selectSpace?.spaceId) { handleClickSpace(data) } }}
              />
            </Box>
          </Drawer>
          <Grid item xs={12} id={'canvasGrid'} className="canvas" style={{ paddingBottom: '24px', paddingLeft: openDrawer['spaceMenu'] ? '250px' : '0', maxWidth: (openDrawer['shapeMenu']) ? 'calc(100% - 250px)' : '100%', width: (openDrawer['shapeMenu']) ? "calc(100% - 15vw)" : '100%' }}>
          
            <Box style={{ position: 'relative' }}>
              {/* ************ Canvas Editor ************** */}
              <Canvas
                loaderOnClick={loaderOnClick}
                space={selectSpace}
                canvasProfile={value}
                mqttClient={mqttClient}
                openDrawer={openDrawer}
                onClose={() => {
                  console.log('_observe', _observeChange);
                  if(!_.isEmpty(shapes?.filter(s=>s?.spaceId === selectSpace?.spaceId && s?.shapeType === 'device' && s?.deviceData?.status === 'inactive'))){
                    setDialogKey('inactiveDevices');
                    setOpenDialog({...openDialog, 'inactiveDevices':true});
                    return;
                  }  
                  if (!_.isEmpty(_observeChange)) {
                    setDialogKey("saveChanges");
                    setOpenDialog({ ...openDialog, ['saveChanges']: true });
                  } else {
                    setValue(
                      _spaceList?.find(
                        (s) => s?.spaceId === selectSpace?.spaceId
                      )
                    );
                    handleClose();
                  }
                }}
                modalOpen={modalOpen}
                loadImage={image}
                setLoadImage={setImage}
                zoomscale={zoomScale}
                zoomType={zoomType}
              />

              {/* ************* Icon Tray ****************** */}
              <Draggable
                onStart={() => {
                  setDisableToolTip(true);
                }}
                onStop={()=>{ setDisableToolTip(false);}}
                disabled={floaterDragging?.iconTray}
              >
                <Box sx={customsx.DragIconTray}>
                  <StyledButton
                  iconButton
                  tooltip={!disabletooltip && "Drag icon tray"}
                  placement="top"
                  >
                    <ControlCameraIcon />
                  </StyledButton>
                  <Box id="iconTray" display={"flex"}>
                    {floatingBtns?.map((item, key) => (
                      <Box key={key}>
                        {item?.display && (
                          <StyledButton
                            key={key}
                            onClick={(e) => {
                              !item?.disabled && item?.onClick(e);
                            }}
                            iconButton
                            tooltip={item?.label}
                            // onMouseEnter={(e) =>
                            //   handleFloatingMouseEnter(e, item?.label, key)
                            // }
                            // onMouseLeave={handleFloatingMouseLeave}
                            color="primary"
                            placement="top"
                            size={"small"}
                            sx={{
                              mr: key !== floatingBtns.length - 1 && 2,
                              flexDirection: "column",
                              "& .MuiSvgIcon-root": {
                                fill: item?.disabled ? "#005f00" : "#fff",
                              },
                            }}
                            style={{ background: item?.background, padding:'7px' }}
                            aria-label="edit"
                          >
                            {item?.icon}
                          </StyledButton>
                        )}
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Draggable>

              {/* ************ Left Panel ****************** */}
              <StyledButton
                id="spaceMenu"
                className={classes.ArrowIcon + " left"}
                iconButton
                disableRipple
                tooltip="Space Menu"
                size="small"
                color="primary"
                sx={{
                  transform: !openDrawer["spaceMenu"] && "rotate(180deg)",
                  left: openDrawer["spaceMenu"] ? "-4px" : 0,
                }}
                onClick={() => openSideDrawer("spaceMenu")}
              >
                <ImageWrapper
                  className={"arrowRight"}
                  src={ArrowIcon}
                  width={18}
                  height={18}
                />
              </StyledButton>

              {/* ************ Right panel ****************** */}
              <StyledButton
                id="shapeMenu"
                className={classes.ArrowIcon + " right"}
                disableRipple
                size={"small"}
                iconButton
                tooltip="Shape Menu"
                color="primary"
                sx={{
                  transform: openDrawer["shapeMenu"] && "rotate(180deg)",
                  right: openDrawer["shapeMenu"] ? "-4px" : 0,
                }}
                onClick={() => openSideDrawer("shapeMenu")}
                
              >
                <ImageWrapper
                  className={"arrowRight"}
                  src={ArrowIcon}
                  width={18}
                  height={18}
                />
              </StyledButton>
            </Box>
          </Grid>
          <Drawer
            anchor={"right"}
            variant="persistent"
            transitionDuration={5}
            className={
              openDrawer["shapeMenu"] || shapeopen
                ? "left-nav-bar-open"
                : "left-nav-bar-close"
            }
            open={openDrawer["shapeMenu"] || shapeopen || false}
            onClose={() => {
              setOpenDrawer({ ...openDrawer, shapeMenu: false });
            }}
          >
            <div className='drawer' id='shapeDrawer'>
              <RightPanel openpanel={tourPanel} mqttClient={mqttClient} space={selectSpace} image={image} canvasProfile={value} onChange={(data) => handleClickSpace(data)} />
            </div>
          </Drawer>
        </Box>

        {openDialog[dialogKey] && (
          <StyledPopup
            customsx={{
              btns: { display: "flex", justifyContent: "center" },
              dialog: { "& .MuiDialog-paper": { maxWidth: "390px" } },
            }}
            open={openDialog[dialogKey]}
            onClose={() => {
              setOpenDialog({ ...openDialog, [dialogKey]: false });
              setDialogKey("");
            }}
            closeButton={component[dialogKey]?.closeButton ? true : false}
            closeTimeOut={0}
            data={{
              title: component[dialogKey].title,
              content: component[dialogKey].compo,
              actions: component[dialogKey].btns,
            }}
          />
        )}

        <Tour
          steps={tourSteps}
          isOpen={tourOpen}
          accentColor={'#0f8d48'}
          // showNumber={false}
          scrollDuration={10}
          rounded={10}
          startAt={0}
          nextButton={<IconButton iconButton ><NavigateNextIcon /> </IconButton>}
          prevButton={<IconButton iconButton id='prevBtnTour'><NavigateBeforeIcon /> </IconButton>}
          maskSpace={5}
          badgeContent={(curr, tot) => `Step ${curr} of ${tot}`}
          lastStepNextButton={<StyledButton sx={{ height: '35px' }}>Close Guide</StyledButton>}
          showNavigation={showNavigation}
          getCurrentStep={(step) => {
            if( document.getElementById('prevBtnTour')){
            document.getElementById('prevBtnTour').style.display = 'inline-block';
          }
            setTourPanel('');
            if (step === 0 || step !== tourSteps.length - 1) {
              setShowNavigation(true);
            }
            if (step === tourSteps.length - 1) {
              setShowNavigation(false);
              document.getElementById('prevBtnTour').style.display = 'none';
              document.querySelector('[data-tour-elem="right-arrow"]').style.margin = '0 20px 0 0';
              document.querySelector('.reactour__close').style.display = 'none';
              setTourPanel('panel1');
            }
            if (step >= 3 && step < 4) {
              setShapeOpen(false);
              setSpaceOpen(true);
            }
            else if (step >= 5 && step < 9) {
              setShapeOpen(true);
              setSpaceOpen(false);

            }
            else {
              setShapeOpen(false);
              setSpaceOpen(false);
            }

          }}
          onRequestClose={() => {
            setTourOpen(false);
            setShapeOpen(false);
            setSpaceOpen(false);
            setTourPanel('panel1');
          }}
          updateDelay={2000}
        />
      </Grid>
    </>
  );
};

CanvasArea.propTypes = {};

CanvasArea.defaultProps = {};

export default CanvasArea;
