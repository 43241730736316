import { API_URL } from '../config/appConfig'
import { API } from '../services'
import { handleResponse } from '../helpers';

// const getGroupDevicesDetails = async (payload) => {
//     return API.get(`${API_URL.device}/devices`, "getGroupDevicesDetails", payload, {}, {headers:{'Content-Type':'application/json'}}).then(handleResponse);
// }

const getAllGroupsBySpace = (spaceId) => {
    return API.get(`${API_URL.device}/groups/space/${spaceId}`, `getAllGroupsBySpace/${spaceId}`, {}).then((res) => handleResponse(res, true));
}

const getGroupDetails = (groupId) => {
    return API.get(`${API_URL.device}/groups/${groupId}`, "getGroup", {}).then(handleResponse);
}

const syncByDeviceId = (deviceId) => {
    return API.put(`${API_URL.device}/groups/device/${deviceId}/groupsync`, `syncByDeviceId/${deviceId}/groupsync`, {}).then((res) => {
        return handleResponse(res, true);
    });
}
const deleteByDeviceId = (deviceId, deviceType) => {
    return API.delete(`${API_URL.device}/groups/${deviceType}/${deviceId}`, `deleteByDeviceId/${deviceId}`, {}).then((res) => {
        return handleResponse(res, true);
    });
}

const createGroup = (payload) => {
    return API.post(`${API_URL.device}/groups`, "createGroup", payload).then((res) => {
        return handleResponse(res, {});
    });
}

const editGroup = (groupId, payload) => {
    return API.put(`${API_URL.device}/groups/${groupId}`, "editGroup", payload).then((res) => {
        return handleResponse(res, {});
    });
}

const deleteGroup = (groupId) => {
    return API.delete(`${API_URL.device}/groups/${groupId}`, "deleteGroup", {}).then((res) => {
        return handleResponse(res, {});
    });
}

const syncById = (id) => {
    return API.put(`${API_URL.device}/groups/${id}/groupsync`, `groupSyncById/${id}/groupsync`, {}).then((res) => {
        return handleResponse(res, {});
    });
}

const getGroupsByDeviceId = (deviceType, deviceId, params = {}) => {
    return API.get(`${API_URL.device}/groups/${deviceType}/${deviceId}`, `getGroupsByDeviceId/${deviceId}`, params).then(handleResponse);
}

const controlGroup = (groupId, payload) => {
    return API.put(`${API_URL.device}/groups/${groupId}/activate`, "controlGroup", payload).then((res) => {
        return handleResponse(res, true);
    });
}

const getMultipleGroups = (payload) => {
    return API.get(`${API_URL.device}/groups`, "getMultipleGroups", payload, {}, { headers: { 'Content-Type': 'application/json' } }).then(handleResponse);
}

export const groupService = {
    getGroupDetails,
    getAllGroupsBySpace,
    createGroup,
    editGroup,
    syncByDeviceId,
    deleteByDeviceId,
    deleteGroup,
    controlGroup,
    getGroupsByDeviceId,
    syncById,
    getMultipleGroups
};