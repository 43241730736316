import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    RuleActions: {
        minHeight:"210px",
        "& .menuButton": {
            minWidth: "170px"
        },
        "& .MuiIconButton-root": {
            padding: "0px",
            paddingLeft: "16px"
        },
        "& .entityIcon":{
           filter: "invert(37%) sepia(14%) saturate(5108%) hue-rotate(112deg) brightness(99%) contrast(88%)"
        },
        "& .borderBelow":{
            borderBottom:"1px solid #E5E5E5"
        },
        "& .MuiPopper-root":{
            zIndex:"1250 !important"
        }        
    }
});
export const SliderCss = {
    compo: {
        "& .MuiSlider-rail": {
          background: 'linear-gradient(90deg, #FF0000 0.83%, #E9FA00 15.6%, #22FF01 32.13%, #00E5FF 46.59%, #1803FF 65.19%, #FF00E9 80.68%, #FF0030 100%)',
          height: '3px'
        }, 
        "& .MuiSlider-track": {
          height: '1px',
          border: 'none',
          color: 'transparent'
        }
      },
      label:{width:"fit-content"},
      value:{minWidth:"51px"}

};
