/***
 *
 * Controller class for user.
 * @file CustomChipInput.js
 * @description CustomChipInput component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect, useState } from 'react';
import { delay, isInList } from '../../services';
// import PropTypes from 'prop-types';
import './CustomChipInput.scss';

import StyledChip from '../StyledChip';
import { Box } from '@mui/material';
import ErrorMessage from '../ErrorMessage';
import _ from 'lodash';

const CustomChipInput = ({ id, name="textChipInput", chipsPosition="top", value, placeholder="Type or paste here and press `Enter` or `tab`", width="200px", rounded, style, disabled=false, error=null, helperText=null, onChange = () => {}, onKeyDown = () => {}, validateEmail = false}) => {
  const [chip, setChip] = useState(null);
  const [chips, setChips] = useState(value);
  const [customError, setCustomError] = useState(null);

  const handleChange = (event) => {
    // console.log("chip ---- ", event.target.value);
    setChip(event.target.value);
    // setTimeout(() => {
    //   handleKeyDown(event);
    // }, 300);
  };

  const isEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleKeyDown = (evt) => {
    // evt.preventDefault();
    // console.log("chip 1 ---- ", evt, evt.target, chip);

    Promise.resolve().then(() => {
      if(!chip?.trim()) { setChip(evt?.target?.value); }
    }).then(delay(500)).then(() => {
      // console.log("chip 2 ----- ", evt?.key);
      if (['Enter', 'Tab', ','].includes(evt.key)) {
        // console.log("chip 3 ----- ", chip?.trim(), chips);
        if (!isInList(chips || [], chip) && chip?.trim()) {
          if(validateEmail){
          if(isEmail(chip))
             setChips(arr => [...(arr || []), chip]);
          else{
            setCustomError("Enter Valid Email");
            setTimeout(() => {
              setCustomError(null);
            }, 2000);
          }
        }else{
          setChips(arr => [...(arr || []), chip]);
        }
      }
      else if (!chip){
        setCustomError("Item is required");
        setTimeout(() => {
          setCustomError(null);
        }, 2000);
      } 
      else {
          setCustomError("Item already exist.");
          setTimeout(() => {
            setCustomError(null);
          }, 2000);
        }
      }
    })
  };

  const handlePaste = (evt) => {
    evt.preventDefault();
    
    var paste = evt.clipboardData.getData('text');
    
    if (paste) {
      var toBeAdded = paste.filter(ch => !this.isInList(ch));      
      setChips(arr => [...arr, ...toBeAdded]);
    }
  };

  const handleDelete = (e, toBeRemoved) => {
    // console.log('chip ---- ', e, toBeRemoved)
    setChips(chips.filter(ch => ch !== toBeRemoved));
  };

  useEffect(() => {
    // console.log('chip ---- ', value)
    setChips(value); 
  }, [value])

  useEffect(() => {
    setChip(null);
    onKeyDown({
      target: { value: chips }
    }, name );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chips])
  
  useEffect(() => {
    setCustomError(error);
  }, [error]);

  return (
      <Box className="CustomChipInput" data-testid="CustomChipInput">
        {/* {JSON.stringify(chips)}--{JSON.stringify(value)} */}
        {chipsPosition === "top" && 
          <Box className="" sx={{marginBottom: "5px"}}>
            { chips && chips?.map((ch, index) => <StyledChip
                    key={"chipppp---" + index}
                    label={ch}
                    onDelete={(e) => handleDelete(e, ch)}
                    disabled={disabled}
                  />
            )}  
          </Box>
        }

        <Box className={chipsPosition}>
          {chipsPosition === "left" && 
               chips && chips?.map((ch, index) => <StyledChip
                      key={"chipppp---" + index}
                      label={ch}
                      onDelete={(e) => handleDelete(e, ch)}
                      disabled={disabled}
                    />
              )
          }
          <div className="input-container">
            <input 
              className={'input' + (error || !_.isEmpty(customError)?' has-error':'')}
              placeholder={placeholder}
              value={chip || ""}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              onPaste={handlePaste}
              style={{ ...style, width: width }}
              disabled={disabled}              
            />          
            {helperText && <ErrorMessage color={ '#EF0000'} message={helperText}/>}  
            {customError && <ErrorMessage message={customError}/>}  
          </div>

          {chipsPosition === "right" && 
            <Box className="" sx={{marginLeft: "10px"}}>
              { chips && chips?.map((ch, index) => <StyledChip
                      key={"chipppp---" + index}
                      label={ch}
                      onDelete={(e) => handleDelete(e, ch)}
                      disabled={disabled}
                    />
              )}  
            </Box>
          }
        </Box>

        {chipsPosition === "bottom" && 
          <Box className="" sx={{marginTop: "5px"}}>
            { chips && chips?.map((ch, index) => <StyledChip
                    key={"chipppp---" + index}
                    label={ch}
                    onDelete={(e) => handleDelete(e, ch)}
                    disabled={disabled}
                  />
            )}  
          </Box>
        }
      </Box>
  )
};

CustomChipInput.propTypes = {};

CustomChipInput.defaultProps = {};

export default CustomChipInput;
