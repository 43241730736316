export const paletteConstants = {
    PRIMARY: '#0F8D48',
    ERROR: '#EF0000',
    WARNING: '#FDD600',
    SUCCESS: '#0F8D48',
    BUTTON_BORDER_PRIMARY: '#0F8D48',
    light: {
        BUTTON_BG_PRIMARY: '#0F8D48',
        TEXT_PRIMARY: '#222222',
        TEXT_SECONDARY: '#FFFFFF',
        LABEL: '#000000'
    },
    dark: {
        TEXT_PRIMARY: '#f7f7f7',
        TEXT_SECONDARY: '#FFFFFF',
        BUTTON_BG_PRIMARY: '#2C2E33',
        LABEL: '#FFFFFF',
    }
};

export const buttonConstants = {
    BORDER_RADIUS: '4px',
    PADDING_SMALL: '8px',
    PADDING_MEDIUM: '8px 24px',
    PADDING_LARGE: '12px 32px',
    PADDING_EXTRA_LARGE: '16px 88px',
    BORDER_WIDTH: '1px',
    BORDER_STYLE: 'solid',
}

export const typographyConstants = {
    light: {
        COLOR: '#000000'
    },
    dark: {
        COLOR: '#FFFFFF'
    },
    // FONT_FAMILY: "'Lato', sans-serif"
    FONT_FAMILY: 'Montserrat'
}

export const pieChartTheme={
    borderColor:"#FFF",
    pieSegmentColors:[
        "#D90707",
        "#0F8D48",
        "#F8AB31"
    ]
}

export const colorfulGradient = 'conic-gradient(from 30deg, #FF0000 0%, #FFFF00 33.3%, #00FF00 33.4%, #00FFFF 66.6%, #0000FF 66.7%, #FF00FF 100%, #FF0000 100%)';