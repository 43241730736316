/***
 *
 * Controller class for user.
 * @file Groups.js
 * @description Groups component
 * @author ayeshakumar
 * @since 23 AUG 2022
 *
 */

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import GroupDetails from "../../components/GroupDetails";
import GroupList from "../../components/GroupList";
import { useQuery } from "../../hooks";
import { deviceActions, dragAndDropActions } from "../../redux/actions";
import CreateGroups from "../CreateGroups";
// import PropTypes from "prop-types";
import { useStyles } from "./Groups.style.js";

const Groups = ({ mqttClient }) => {
  const styles = useStyles();
  const query = useQuery();
  const groupId = query.get("id") || null;

  // const [groups, setGroups] = useState();
  const dispatch = useDispatch();
  const [groupOperation, setGroupOperation] = useState();

  // useEffect(() => {
  //   dispatch(deviceActions?.getAllDevices({ category: "VortexGateway" }, "gateway"))
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  useEffect(() => {
    setGroupOperation(query.get("op"));
  }, [query]);

  useEffect(() => {
    if (groupOperation === null || groupOperation === undefined) {
      dispatch(dragAndDropActions.emptyLists());
      dispatch(dragAndDropActions.setErrorMessage(''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupOperation])

  return (
    <div className={styles?.Groups || ""} data-testid="Groups">
      {groupOperation === "create" || groupOperation === "edit" ? (
        <CreateGroups groupOperation={groupOperation} mqttClient={mqttClient} />
      ) : (
        groupId ? <GroupDetails groupId={groupId} mqttClient={mqttClient} /> : <GroupList mqttClient={mqttClient} />
      )}
    </div>
  );
};

Groups.propTypes = {};

Groups.defaultProps = {};

export default Groups;
