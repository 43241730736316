    export const reportMessages = {
        "report-addReportFormValidation-name":"Report name is required",
        "report-addReportFormValidation-description":"Report description is required",
        "report-addReportFormValidation-scope":"Report scope is required",
        "report-addReportFormValidation-categories":"Report categories is required",
        "report-addReportFormValidation-space":"Report space(s) is required",
        "report-addReportFormValidation-access":"Access is required",
        "report-addReportFormValidation-duration":"Duration is required",
        "report-addReportFormValidation-durationMinValue":"Duration should have minimum 3 days",
        "report-addReportFormValidation-durationMaxValue":"Duration should not exceed more than 180 days",
        "report-addReportFormValidation-startDate":"Start date is required",
        "report-addReportFormValidation-endDate":"End date is required",
        "report-addReportFormValidation-time":"Schedule time is required",
        "report-addReportFormValidation-nameMinLength":"Report name should have minimum 2 characters",
        "report-addReportFormValidation-descriptionMinLength":"Report description should have minimum 2 characters",
        "report-addReportFormValidation-emailMinLength":"Subscriber email should have minimum 3 characters",
        "report-addReportFormValidation-nameMaxLength": "Report name should not exceed more than 30 characters",
        "report-addReportFormValidation-descriptionMaxLength": "Report description not exceed more than 280 characters",
        "report-addReportFormValidation-emailMaxLength": "Subscriber email should not exceed more than 30 characters",
        "report-addReportFormValidation-nameAplhaNum": "Report name should only contain 'a-z', 'A-Z', '0-9', '-'characters.",
        "report-addReportFormValidation-email": "Subscriber email is invalid",
        "report-addReportFormValidation-expiry": "Report schedule expiry date is required",
        "report-addReportFormValidation-reportType": "Report type is required",
        "report-shareReportValidation-email": "Subscriber email is required",
        "report-shareReportValidation-emailInvalid": "Subscriber email is invalid" 
    }
    
    export const reportApiMessages = {
        "report-get-200": "Report List successfully retrieved.",
        "report-get-401": "Unable to retrieve the Report list, please verify your access and try again.",
		"report-get-403": "Unable to retrieve the Report list, please verify your access and try again.",
		"report-get-500": "Unable to retrieve the Report list due to internal error, please try again later.",
        "report-get-502": "Unable to retrieve the Report list due to internal error, please try again later.",
        
        "createReport-post-200": "Report successfully created.",
        "createReport-post-400": "Unable to create the Report, please verify inputs and try again.",
        "createReport-post-401": "Unable to create the Report, please verify your access and try again.", 
        "createReport-post-403": "Unable to create the Report, please verify your access and try again.",
        "createReport-post-404": "Unable to create the Report, please try again later.",
        "createReport-post-500": "Unable to create the Report due to internal error, please try again later.",
        "createReport-post-502": "Unable to create the Report due to server is unavailable, please try again later.",
        
        "reportById-get-200": "Report successfully retrieved.",
        "reportById-get-400": "Unable to retrieve the Report, please verify inputs and try again.",
        "reportById-get-401": "Unable to retrieve the Report list, please verify your access and try again.", 
        "reportById-get-403": "Unable to retrieve the Report list, please verify your access and try again.",
        "reportById-get-404": "Unable to retrieve the Report, please refresh the page and try again.",
        "reportById-get-500": "Unable to retrieve the Report list due to internal error, please try again later.",
        "reportById-get-502": "Unable to retrieve the Report list due to internal error, please try again later.",        
        
        "updateReport-put-200": "Report successfully updated.",
        "updateReport-put-400": "Unable to edit the Report, please verify inputs and try again.",
        "updateReport-put-401": "Unable to edit the Report, please verify your access and try again.", 
        "updateReport-put-403": "Unable to edit the Report, please verify your access and try again.",
        "updateReport-put-404": "Unable to retrieve the Report, please refresh the page and try again.",
        "updateReport-put-500": "Unable to edit the Report due to internal error, please try again later.",
        "updateReport-put-502": "Unable to edit the Report due to internal error, please try again later.",
        
        "deleteReport-delete-200": "Report successfully deleted.",
        "deleteReport-delete-400": "Unable to delete the Report, please verify inputs and try again.",
        "deleteReport-delete-401": "Unable to delete the Report list, please verify your access and try again.", 
        "deleteReport-delete-403": "Unable to delete the Report list, please verify your access and try again.",
        "deleteReport-delete-404": "Unable to delete the Report, please refresh the page and try again.",
        "deleteReport-delete-500": "Unable to delete the Report list due to internal error, please try again later.",
        
        "documentReport-get-200": "Report document(s) list successfully retrieved.",
        "documentReport-get-400": "Unable to retrieve Report document(s) list, please verify inputs and try again.",
        "documentReport-get-401": "Unable to retrieve Report document(s) list, please verify your access and try again.", 
        "documentReport-get-403": "Unable to retrieve Report document(s) list, please verify your access and try again.",
        "documentReport-get-404": "Unable to retrieve Report document(s) list, please try again later.",
        "documentReport-get-500": "Unable to retrieve Report document(s) list due to internal error, please try again later.",
        "documentReport-get-502": "Unable to retrieve Report document(s) list due to server unavailable, please try again later.",
        
        "documentReportById-get-200": "Report document successfully retrieved.",
        "documentReportById-get-400": "Unable to retrieve Report document(s) list, please verify inputs and try again.",
        "documentReportById-get-401": "Unable to retrieve Report document(s) list, please verify your access and try again.", 
        "documentReportById-get-403": "Unable to retrieve Report document(s) list, please verify your access and try again.",
        "documentReportById-get-404": "Unable to retrieve Report document(s) list, please try again later.",
        "documentReportById-get-500": "Unable to retrieve Report document(s) list due to internal error, please try again later.",
        "documentReportById-get-502": "Unable to retrieve Report document(s) list due to server unavailable, please try again later.",
        
        "shareReportDocumentById-post-200":"Report Document successfully shared.",
		"shareReportDocumentById-post-401":"Unable to share Report document, please verify your access and try again.",
		"shareReportDocumentById-post-403":"Unable to share Report document, please verify your access and try again.",
        "shareReportDocumentById-post-404":"Unable to share Report document, please refresh the page and try again.",
		"shareReportDocumentById-post-500":"Unable to share Report document due to internal error, please try again later.",
        "shareReportDocumentById-post-502":"Unable to share Report document due to internal error, please try again later.",

        "deleteDocumentById-delete-200": "Document successfully deleted.",
        "deleteDocumentById-delete-400": "Unable to delete Report document, please verify inputs and try again.",
        "deleteDocumentById-delete-401": "Unable to delete Report document, please verify your access and try again.", 
        "deleteDocumentById-delete-403": "Unable to delete Report document, please verify your access and try again.",
        "deleteDocumentById-delete-404": "Unable to delete Report document, please refresh the page and try again.",
        "deleteDocumentById-delete-500": "Unable to delete Report document due to internal error, please try again later.",
        "deleteDocumentById-delete-502": "Unable to delete Report document due to internal error, please try again later.",

        "generateReport-put-200": "Report Document successfully generated.",
        "generateReport-put-400": "Unable to generate the Report, please verify inputs and try again.",
        "generateReport-put-401": "Unable to generate the Report, please verify your access and try again.", 
        "generateReport-put-403": "Unable to generate the Report, please verify your access and try again.",
        "generateReport-put-404": "Unable to generate the Report, please try again later.",
        "generateReport-put-500": "Unable to generate the Report due to internal error, please try again later.",
        "generateReport-put-502": "Unable to generate the Report due to server is unavailable, please try again later.",
        
        "downloadReport-get-200": "Report Document successfully downloaded.",
        "downloadReport-get-400": "Unable to download the Report, please verify inputs and try again.",
        "downloadReport-get-401": "Unable to download the Report, please verify your access and try again.", 
        "downloadReport-get-403": "Unable to download the Report, please verify your access and try again.",
        "downloadReport-get-404": "Unable to download the Report, please try again later.",
        "downloadReport-get-500": "Unable to download the Report due to internal error, please try again later.",
		"downloadReport-get-502": "Unable to download the Report due to server is unavailable, please try again later."
    } 