/***
 *
 * Controller class for user.
 * @file StyledTabs.js
 * @description StyledTabs component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 *
 */

import React, { useEffect, useState } from "react";
// import PropTypes from 'prop-types';
import { useStyles } from "./StyledTabs.style.js";
import "./StyledTabs.scss";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import ImageWrapper from "../ImageWrapper/index.js";
import _ from "lodash";
import { setSessionItem } from "../../helpers/storage.helper.js";

// const a11yProps = (index) => {
//   return {
//     id: `full-width-tab-${index}`,
//     "aria-controls": `full-width-tabpanel-${index}`,
//   };
// };
const StyledTabs = ({ tabs = [], selectedIndex = 0, onClick = () => { }, ...props }) => {
  const classes = useStyles();
  const [value, setValue] = useState(selectedIndex)
  useEffect(() => {
    // console.log("STYLED TABS")()
    if (!_.isEmpty(tabs)) {
      setValue(selectedIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs]);

  useEffect(() => {
    setValue(selectedIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex])

  const handleChange = (event, newValue) => {
    setSessionItem("hideDataNotFound", true)
    setValue(newValue);
    onClick(event, newValue);
  };

  // const handleChangeIndex = (index) => {
  //   setValue(index);
  // };

  return (
    <Box className={classes?.StyledTabs || ""} data-testid="StyledTabs">
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="inherit"
        aria-label="tabs example"
        {...props}
      >
        {tabs?.map((t, i) => {
          return (
            <Tab
              sx={{ mx: 2 }}
              key={"tab-" + i}
              id={"tab-" + i}
              label={
                <Box sx={{ px: 0 }} style={{ textTransform: "capitalize" }}>
                  <ImageWrapper
                    alt={`${t?.label}`}
                    style={{
                      float: "left",
                      height: "19px",
                      width: "19px",
                      marginRight: "5px",
                    }}
                    src={`${t?.icon}`}
                  />
                  <Typography
                    variant="caption"
                    sx={{ lineHeight: "20px", display: "inline-block", fontWeight: 600, color: "#444" }}
                  >{`${t?.label}`}</Typography>
                </Box>
              }
            />
          );
        })}
      </Tabs>
    </Box>
  );
};

StyledTabs.propTypes = {};

StyledTabs.defaultProps = {};

export default StyledTabs;
