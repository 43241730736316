import HomePage from '../pages/HomePage';
import Devices from "../pages/Devices";
import UserDetail from '../pages/UserDetail';
import Spaces from '../pages/Spaces';
import SpaceTypes from '../pages/SpaceTypes';
import AssignedGateways from '../pages/AssignedGateways';
import Groups from '../pages/Groups';
import Reports from '../pages/Reports';
import Scenes from '../pages/Scenes';
import PermissionManagement from '.././pages/PermissionManagement';
import Settings from '../pages/Settings';
import DesignSystem from '../components/DesignSystem';
import Canvas from '../pages/Canvas';
import UserManagement from '../pages/UserManagement';
import LoginPage from '../pages/LoginPage';
import UserProfile from '../pages/UserProfile';
import Areas from '../pages/Areas';
import TicketManagement from '../pages/TicketManagement';
import BulkRegistration from '../components/BulkRegistration';
import TwoDLayout from '../layouts/TwoDLayout';
import TestLayout from '../layouts/TestLayout';
import Tenants from '../pages/Tenants';
import Fota from "../pages/Fota";

import { NODE_ENV } from '../config/appConfig';
import Alerts from '../components/Alerts';
import FirmwareDistribution from '../components/FirmwareDistribution';
import FirmwareList from '../components/FirmwareList';
import FirmwareUpgrade from '../components/FirmwareUpgrade';
import userPermission from '../assets/icons/user-permission.svg'
import UserIcon from '../assets/icons/user.svg'
import UsersIcon from '../assets/icons/users.svg'
import TicketIcon from '../assets/icons/ticketing.svg';
import sceneIcon from '../assets/icons/scene.svg';
import RulesIcon from '../assets/icons/rules.svg';
import designSystemImg from '../assets/img/design-system.png';
import themeImg from '../assets/img/themes.png';
import spaceTypesImg from '../assets/img/space-types.png';
import iotEditorImg from '../assets/img/iot-editor.png';
import grouptabIcon from '../assets/icons/group.svg';
import devicetabIcon from '../assets/icons/bulb.svg';
import dashboardtabIcon from '../assets/icons/dashboard.svg';
import spaceMenuIcon from '../assets/icons/space.svg';
import reportMenuIcon from '../assets/icons/report.svg';
import areaMenuIcon from '../assets/icons/area.svg';
import NotificationIcon from '../assets/icons/notificationIcon.svg';
import tenantIcon from '../assets/icons/tenantIcon.svg';
import UnauthorisedAccess from '../pages/UnauthorisedAccess';
import GatewayStatisitcs from '../pages/GatewayStatistics';
import Rules from '../pages/Rules';
import gatewayImg from "../assets/icons/singleantenna.svg";
import FotaIcon from "../assets/icons/download-2.svg";
import Distribution from "../assets/icons/pie.svg";
import FirmwareListIcon from "../assets/icons/list.svg";
import ResetCredPage from '../pages/ResetCredPage';
import ForgotCred from '../pages/ForgotCred';
import KeyRotation from '../pages/keyRotation';
import RotateIcon from '../assets/icons/systemreset.svg'
import DebugGateway from '../components/DebugGateway';
import debugIcon from '../assets/icons/fix.svg'


const routes = [
  {
    id: 1001,
    parent: null,
    icon: '',
    isPublicRoute: true,
    path: 'login',
    name: 'login',
    label: "Login",
    tooltip: "Login",
    component: LoginPage,
    exact: true,
    topMenu: false,
    subMenu: false,
    portal: "mss|tms",
    feature: "auth",
    sortOrder: 20
  },
  {
    id: 1999,
    parent: null,
    icon: '',
    isPublicRoute: false,
    path: 'unauthorised',
    name: 'unauthorised',
    label: "Unauthorised",
    tooltip: "Unauthorised",
    component: UnauthorisedAccess,
    exact: true,
    topMenu: false,
    subMenu: false,
    portal: "mss",
    feature: "unauthorised",
    sortOrder: 145
  },
  {
    id: 1021,
    parent: 1061,
    icon: dashboardtabIcon,
    isPublicRoute: false,
    path: '',
    name: 'dashboard',
    label: "Dashboard",
    tooltip: "Dashboard",
    component: HomePage,
    exact: true,
    redirectTo: "spaces/dashboard",
    topMenu: true,
    leftMenu: false,
    subMenu: false,
    portal: "mss",
    feature: "space",
    sortOrder: 60
  },
  {
    id: 1022,
    parent: 1061,
    icon: dashboardtabIcon,
    isPublicRoute: false,
    path: 'spaces/dashboard',
    name: 'dashboard',
    label: "Dashboard",
    tooltip: "Dashboard",
    component: HomePage,
    exact: true,
    topMenu: false,
    leftMenu: false,
    subMenu: false,
    portal: "mss",
    feature: "space",
    sortOrder: 80
  },
  {
    id: 1061,
    parent: null,
    icon: spaceMenuIcon,
    isPublicRoute: false,
    path: 'spaces',
    name: 'spaces',
    label: "Space Management",
    tooltip: "Spaces",
    component: Spaces,
    exact: true,
    topMenu: false,
    leftMenu: true,
    subMenu: true,
    portal: "mss",
    feature: "space",
    sortOrder: 100
  },
  {
    id: 1071,
    parent: 1061,
    icon: devicetabIcon,
    isPublicRoute: false,
    path: 'spaces/devices',
    name: 'devices',
    label: "Devices",
    tooltip: "Devices",
    component: Devices,
    exact: true,
    topMenu: true,
    leftMenu: false,
    leftBottom: true,
    subMenu: false,
    sortOrder: 80,
    feature: "space&device",
    portal: "mss"
  },
  {
    id: 1231,
    parent: null,
    icon: tenantIcon,
    isPublicRoute: false,
    path: 'tenants',
    name: 'tenants',
    label: "Tenant Management",
    tooltip: "tenants",
    component: Tenants,
    exact: true,
    topMenu: false,
    leftMenu: true,
    leftBottom: true,
    subMenu: false,
    portal: "tms",
    feature: "tms",
    sortOrder: 120
  },
  {
    id: 1233,
    parent: 1231,
    icon: debugIcon,
    isPublicRoute: false,
    path: 'debugGateway',
    name: 'debugGateway',
    label: "Debug Gateway",
    tooltip: "debugGateway",
    component: DebugGateway,
    exact: true,
    topMenu: false,
    leftMenu: false,
    leftBottom: true,
    subMenu: false,
    portal: "tms",
    feature: "tms",
    sortOrder: 122
  },
  {
    id: 1023,
    parent: 1231,
    icon: tenantIcon,
    isPublicRoute: false,
    path: '',
    name: 'tenants',
    label: "Tenant Management",
    tooltip: "tenants",
    component: Tenants,
    exact: true,
    redirectTo: "tenants",
    topMenu: false,
    leftMenu: false,
    subMenu: false,
    portal: "tms",
    feature: "tms",
    sortOrder: 121
  },
  {
    id: 1101,
    parent: null,
    icon: UserIcon,
    isPublicRoute: false,
    path: 'user-management',
    name: 'user-management',
    label: "User Management",
    tooltip: "Users",
    component: UserManagement,
    exact: true,
    topMenu: false,
    leftMenu: true,
    leftBottom: true,
    subMenu: false,
    portal: "mss|tms",
    feature: "role|user",
    sortOrder: 140
  },
  {
    id: 1102,
    parent: 1101,
    icon: UsersIcon,
    isPublicRoute: false,
    path: 'user-management/users',
    name: 'users',
    label: "Users",
    tooltip: "Users",
    component: UserDetail,
    exact: true,
    topMenu: true,
    leftMenu: false,
    leftBottom: false,
    subMenu: false,
    portal: "mss|tms",
    feature: "user",
    sortOrder: 160
  },
  // {
  //   id: 1331,
  //   parent: null,
  //   icon: UserIcon,
  //   isPublicRoute: false,
  //   path: 'tmsUsers',
  //   name: 'tmsUsers',
  //   label: "TMS User Management",
  //   tooltip: "Tms User",
  //   component:TmsUsers,
  //   exact: true,
  //   topMenu: false,
  //   leftMenu: true,
  //   leftBottom: true,
  //   subMenu: false,
  //   portal:"tms"  
  // },
  // {
  //   id: 1102,
  //   parent: 1101,
  //   icon: UserIcon,
  //   isPublicRoute: false,
  //   path: 'user-management/users',
  //   name: 'users',
  //   label: "Users",
  //   tooltip: "Users",
  //   component: UserDetail,
  //   exact: true,
  //   topMenu: true,
  //   leftMenu: false,
  //   leftBottom: false,
  //   subMenu: false,
  //   portal:"mss|tms"
  // },
  {
    id: 1115,
    parent: 1101,
    icon: userPermission,
    isPublicRoute: false,
    path: 'user-management/permissions',
    name: 'roles',
    label: "Permissions",
    tooltip: "Permissions",
    component: PermissionManagement,
    exact: true,
    topMenu: true,
    leftMenu: false,
    leftBottom: false,
    subMenu: false,
    portal: "mss|tms",
    feature: "role",
    sortOrder: 180
  },
  {
    id: 1104,
    parent: 1101,
    icon: UserIcon,
    isPublicRoute: false,
    path: 'user-management/bulkregister',
    name: 'bulkregister',
    label: "Bulk User Registration",
    tooltip: "Bulk User Registration",
    component: BulkRegistration,
    exact: true,
    topMenu: false,
    leftMenu: false,
    leftBottom: false,
    subMenu: false,
    portal: "mss",
    feature: "user",
    sortOrder: 200
  },
  {
    id: 1232,
    parent: null,
    icon: gatewayImg,
    isPublicRoute: false,
    path: 'gateways',
    name: 'gatewayList',
    label: "Gateway Management",
    tooltip: "Gateway Management",
    component: GatewayStatisitcs,
    exact: true,
    topMenu: false,
    leftMenu: true,
    leftBottom: true,
    subMenu: false,
    portal: "tms",
    feature: "tms",
    sortOrder: 142
  },
  {
    id: 1111,
    parent: 1061,
    icon: grouptabIcon,
    isPublicRoute: false,
    path: 'spaces/groups',
    name: 'groups',
    label: "Groups",
    tooltip: "Groups",
    component: Groups,
    exact: true,
    topMenu: true,
    leftMenu: false,
    leftBottom: false,
    subMenu: false,
    portal: "mss",
    feature: "space&device",
    sortOrder: 220
  },
  {
    id: 1121,
    parent: 1061,
    icon: sceneIcon,
    isPublicRoute: false,
    path: 'spaces/scenes',
    name: 'scene',
    label: "Scenes",
    tooltip: "Scenes",
    component: Scenes,
    exact: true,
    topMenu: true,
    leftMenu: false,
    leftBottom: false,
    subMenu: false,
    portal: "mss",
    feature: "space&device",
    sortOrder: 240
  },
  {
    id: 1131,
    parent: 1061,
    icon: RulesIcon,
    isPublicRoute: false,
    path: 'spaces/rules',
    name: 'rules',
    label: "Rules",
    tooltip: "Rules",
    component: Rules,
    exact: true,
    topMenu: true,
    leftMenu: false,
    leftBottom: false,
    subMenu: false,
    portal: "mss",
    feature: "space&device",
    sortOrder: 260
  },
  {
    id: 1141,
    parent: null,
    icon: reportMenuIcon,
    isPublicRoute: false,
    path: 'reports',
    name: 'report',
    label: "Reports",
    tooltip: "Reports",
    component: Reports,
    exact: true,
    topMenu: false,
    leftMenu: true,
    leftBottom: true,
    subMenu: false,
    portal: "mss",
    feature: "report",
    sortOrder: 300
  },
  {
    id: 1151,
    parent: null,
    icon: TicketIcon,
    isPublicRoute: false,
    path: 'tickets',
    name: 'tickets',
    label: "Tickets",
    tooltip: "Tickets",
    component: TicketManagement,
    exact: false,
    topMenu: false,
    leftMenu: true,
    leftBottom: true,
    subMenu: false,
    portal: "mss",
    feature: "ticket",
    sortOrder: 280
  },
  {
    id: 1154,
    parent: null,
    icon: FotaIcon,
    isPublicRoute: false,
    path: 'fota',
    name: 'fota',
    label: "FOTA",
    tooltip: "FOTA",
    component: Fota,
    exact: true,
    topMenu: true,
    leftMenu: true,
    leftBottom: true,
    subMenu: false,
    portal: "mss|tms",
    feature: "device|tms",
    sortOrder: 500
  },
  {
    id: 1155,
    parent: 1154,
    icon: Distribution,
    isPublicRoute: false,
    path: 'fota/distribution',
    name: 'firmware-distribution',
    label: "Distribution",
    tooltip: "Firmware Distribution",
    component: FirmwareDistribution,
    exact: true,
    topMenu: true,
    leftMenu: false,
    leftBottom: false,
    subMenu: false,
    portal: "mss|tms",
    feature: "device|tms",
    sortOrder: 510
  },
  {
    id: 1156,
    parent: 1154,
    icon: FirmwareListIcon,
    isPublicRoute: false,
    path: 'fota/upgrade',
    name: 'firmware-upgrade',
    label: "Firmwares",
    tooltip: "Firmware Upgrade",
    component: FirmwareUpgrade,
    exact: true,
    topMenu: true,
    leftMenu: false,
    leftBottom: false,
    subMenu: false,
    portal: "mss",
    feature: "device",
    sortOrder: 511
  },
  {
    id: 1157,
    parent: 1154,
    icon: FirmwareListIcon,
    isPublicRoute: false,
    path: 'fota/firmwares',
    name: 'firmwares',
    label: "Firmwares",
    tooltip: "Firmwares",
    component: FirmwareList,
    exact: true,
    topMenu: true,
    leftMenu: false,
    leftBottom: false,
    subMenu: false,
    portal: "tms",
    feature: "tms",
    sortOrder: 512
  },
  {
    id: 1161,
    parent: null,
    icon: themeImg,
    isPublicRoute: false,
    path: 'settings',
    name: 'settings',
    label: "Settings",
    tooltip: "Settings",
    component: Settings,
    exact: true,
    topMenu: true,
    leftMenu: true,
    leftBottom: true,
    subMenu: false,
    portal: "mss",
    feature: NODE_ENV === "development" ? "space|setting" : "space&setting",
    sortOrder: 10000
  },
  {
    id: 1171,
    parent: 1161,
    icon: spaceTypesImg,
    isPublicRoute: false,
    path: 'settings/space-types',
    name: 'space-types',
    label: "Space Types",
    tooltip: "Space Types",
    component: SpaceTypes,
    exact: true,
    topMenu: true,
    leftMenu: false,
    leftBottom: false,
    subMenu: false,
    portal: "mss",
    parentRoute: 'space',
    feature: "setting#design_system|space",
    sortOrder: 340
  },
  {
    id: 1175,
    parent: 1161,
    icon: gatewayImg,
    isPublicRoute: false,
    path: 'settings/assignedGateways',
    name: 'assigned-gateways',
    label: "Assigned Gateways",
    tooltip: "Assigned Gateways",
    component: AssignedGateways,
    exact: true,
    topMenu: true,
    leftMenu: false,
    leftBottom: false,
    subMenu: false,
    portal: "mss",
    parentRoute: 'space',
    feature: "device",
    sortOrder: 350
  },
  {
    id: 1181,
    parent: 1161,
    icon: designSystemImg,
    isPublicRoute: false,
    path: 'settings/design-system',
    name: 'design-system',
    label: "Design System",
    tooltip: "Design System",
    component: DesignSystem,
    exact: true,
    topMenu: NODE_ENV === "development" ? true : false,
    leftMenu: false,
    leftBottom: false,
    subMenu: false,
    portal: "mss",
    feature: "setting",
    sortOrder: 361
  },
  {
    id: 1186,
    parent: 1161,
    icon: RotateIcon,
    isPublicRoute: false,
    path: 'settings/keyRotation',
    name: 'BLE Key Management',
    label: "BLE Key Management",
    tooltip: "BLE Key Management",
    component: KeyRotation,
    exact: true,
    topMenu: true,
    leftMenu: false,
    leftBottom: false,
    subMenu: false,
    portal: "mss",
    feature: "setting",
    sortOrder: 360
  },
  {
    id: 1191,
    parent: null,
    icon: iotEditorImg,
    isPublicRoute: false,
    path: 'canvas',
    name: 'canvas',
    label: "Canvas",
    tooltip: "Canvas",
    component: Canvas,
    exact: true,
    topMenu: false,
    leftMenu: false,
    leftBottom: false,
    subMenu: false,
    portal: "mss",
    feature: "space",
    sortOrder: 380
  },
  {
    id: 1201,
    parent: null,
    isPublicRoute: true,
    path: 'forgot',
    name: 'Password',
    label: "Password",
    tooltip: "Password",
    component: ForgotCred,
    exact: true,
    topMenu: false,
    leftMenu: false,
    leftBottom: false,
    subMenu: false,
    portal: "mss|tms",
    feature: "user",
    sortOrder: 400
  },
  {
    id: 1202,
    parent: null,
    isPublicRoute: true,
    icon: "",
    path: 'resetPassword',
    name: 'Reset Password',
    label: "Reset Password",
    tooltip: "Reset Password",
    component: ResetCredPage,
    exact: true,
    topMenu: false,
    subMenu: false,
    portal: "mss|tms",
    feature: "user",
    sortOrder: 410
  },
  // {
  //   id: 1211,
  //   parent: 1101,
  //   icon: UserIcon,
  //   isPublicRoute: false,
  //   path: 'user-management/profile',
  //   name: 'My Profile',
  //   label: "My Profile",
  //   tooltip: "profile",
  //   component: UserProfile,
  //   exact: true,
  //   topMenu: true,
  //   leftMenu: false,
  //   leftBottom: false,
  //   subMenu: true,
  //   portal:"mss|tms",
  //   feature: "user|role",
  //   sortOrder: 420
  // },
  {
    id: 1212,
    parent: null,
    icon: UserIcon,
    isPublicRoute: false,
    path: 'profile',
    name: 'My Profile',
    label: "My Profile",
    tooltip: "profile",
    component: UserProfile,
    exact: true,
    topMenu: true,
    leftMenu: false,
    leftBottom: false,
    subMenu: true,
    portal: "mss|tms",
    feature: "profile",
    sortOrder: 420
  },
  {
    id: 1221,
    parent: 1061,
    icon: areaMenuIcon,
    isPublicRoute: false,
    path: 'spaces/areas',
    name: 'area',
    label: "Areas",
    tooltip: "Areas",
    component: Areas,
    exact: true,
    topMenu: false,
    leftMenu: false,
    leftBottom: false,
    subMenu: false,
    portal: "mss",
    feature: "space",
    sortOrder: 440
  },
  {
    id: 1501,
    parent: 1061,
    icon: '',
    isPublicRoute: false,
    path: '2d',
    name: '2dlayout',
    label: "2D Layout",
    tooltip: "2D Layout",
    component: TwoDLayout,
    webView: true,
    exact: true,
    topMenu: false,
    leftMenu: false,
    leftBottom: false,
    subMenu: false,
    portal: "mss",
    feature: "space",
    sortOrder: 460
  },
  {
    id: 1502,
    parent: 1061,
    icon: '',
    isPublicRoute: false,
    path: 'test-layout',
    name: 'testLayout',
    label: "Test Layout",
    tooltip: "Test Layout",
    component: TestLayout,
    webView: true,
    exact: true,
    topMenu: false,
    leftMenu: false,
    leftBottom: false,
    subMenu: false,
    portal: "mss",
    feature: "testing",
    sortOrder: 480
  },
  {
    id: 1503,
    parent: null,
    icon: NotificationIcon,
    isPublicRoute: false,
    path: 'alerts',
    name: 'alerts',
    label: "Alerts",
    tooltip: "Alerts",
    component: Alerts,
    webView: true,
    exact: true,
    topMenu: false,
    leftMenu: true,
    leftBottom: true,
    subMenu: false,
    portal: "mss",
    sortOrder: 1000,
    feature: "alert"
  },
];


export default routes;