/***
 *
 * Controller class for user.
 * @file AreaDetails.js
 * @description AreaDetails component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import PropTypes from 'prop-types';
import './AreaDetails.scss';
import { useQuery } from '../../hooks';
import { areaActions, deviceActions } from '../../redux/actions';
import PageHeader from '../PageHeader';
import { Stack, Box, Grid } from '@mui/material';
import ImageWrapper from '../ImageWrapper';
import CustomTypography from '../CustomTypography';
import CustomAccordion from '../CustomAccordion';
import CheckCircle from "../../assets/icons/check-circle.svg";
import AlertIcon from "../../assets/icons/alert-icon.svg";
import StyledTabs from '../StyledTabs';
import _ from 'lodash';
import { format } from "date-fns";
import CustomTable from '../CustomTable';
import StyledPopup from '../StyledPopup';
import { getMessageStr, history, isAuthorised, setAreaDeleteTimeout } from '../../helpers';
import Refresh from "../../assets/icons/sync.svg";
import { TableHelper } from '../TableHelper';
import areaImg from '../../assets/icons/area.svg';
import groupIcon from '../../assets/icons/group.svg';
import sensorIcon from '../../assets/icons/sensor.svg';
import editIcon from '../../assets/icons/edit-2.svg';
import deviceIcon from '../../assets/icons/device-2.svg';
import deleteIcon from '../../assets/icons/delete.svg';
import { Link } from 'react-router-dom';
import { features, permissions } from '../../constants';
import StyledButton from '../StyledButton';
import { areaDeleteTimeout } from '../../config/mqttConfig';

const LeftIcon = ({ ...props }) => {
  return <ImageWrapper src={areaImg} {...props} style={{ filter: "invert(37%) sepia(14%) saturate(5108%) hue-rotate(112deg) brightness(99%) contrast(88%)", marginRight: "7px" }} />
}

const areaDetailsArray = {
  areaId: { label: "Area Id" },
  name: { label: "Name" },
  spaceId: { label: "Space Id" },
  sync: { label: "Sync Status" },
  dateOfCreation: { label: "Date Created" },
  dateLastUpdated: { label: "Date Updated" },
};

const AreaDetails = ({ areaId }) => {

  const dispatch = useDispatch();
  const query = useQuery(); // Not seem correct
  if (query.get("id")) {
    areaId = query.get("id");
  }

  const area = useSelector(state => state?.areas?.item)
  const controller = useSelector(state => state?.devices?.deviceDetails) // to get name of controller under which this area comes
  const groupList = useSelector(state => state?.groups?.list) //To get group details 

  const [areaDetails, setAreaDetails] = useState({})
  const [controllerName, setControllerName] = useState(controller?.name)
  const [controllerCategory, setControllerCategory] = useState(controller?.category)
  const [tabsArray] = useState([{ icon: sensorIcon, label: 'Sensors' }, { icon: groupIcon, label: 'Groups' }]);
  const sensorList = useSelector(state => state?.areas?.deviceList)
  const [groupsUnderArea, setGroupsUnderArea] = useState([])
  const [sensorsUnderArea, setSensorsUnderArea] = useState([])
  const [sensorHeaders] = useState({
    name: {
      label: "Sensor Name",
      // type: "link",
      // url: "/spaces/devices?pt=device",
      // urlParams: { id: "sensorId" }
      type: "component",
      component: ({ data }) => {
        return (
          <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            {data?.status !== 'deleted' ? <Link className="link" to={`/spaces/devices?pt=device&id=${data?.sensorId}`}>{`${data?.name && data?.name !== '' ? data?.name : data?.sensorId}`}</Link> : <CustomTypography content={`${data?.name && data?.name !== "" ? data?.name : data?.sensorId}`} />}
          </Box>
        );
      }
    },
    status: {
      label: "Status",
      type: "component",
      component: (row) => {
        return (
          <TableHelper.TableStatus type={row?.data?.status} label={row?.data?.status === "active" ? "Active" : row?.data?.status === "inactive" ? "Inactive" : "Deleted"} />
        )
      },
    },
    spaceName: {
      label: "Location"
    }
  })
  const [groupHeaders] = useState({
    name: {
      label: "Group Name",
      // type: "link",
      // url: "/spaces/groups",
      // urlParams: { id: "groupId" },
      type: "component",
      component: ({ data }) => {
        return (
          <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            {data?.status !== 'deleted' ? <Link className="link" to={`/spaces/groups?id=${data?.groupId}`}>{`${data?.name && data?.name !== '' ? data?.name : data?.groupId}`}</Link> : <CustomTypography content={`${data?.name && data?.name !== "" ? data?.name : data?.groupId}`} />}
            {/* <Link className="link" to={`/spaces/groups?id=${data?.groupId}`}>{`${data?.name && data?.name!==''?data?.name : data?.groupId}`}</Link> */}
          </Box>
        );
      }
    },
    synced: {
      label: "Sync Status",
      type: "component",
      component: (row) => {
        return row?.data.hasOwnProperty('synced') ? <TableHelper.TableStatus type={row?.data?.synced ? "synced" : "needsSync"} variant={"filled"} label={row?.data?.synced ? "Synced" : "Needs Sync"} /> : <CustomTypography content={'-'} />
      }
    }
  })
  const [tableHeaders, setTableHeaders] = useState({})
  const [tableData, setTableData] = useState([])
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState({ show: false })

  useEffect(() => {
    dispatch(areaActions.getAreaDetails(areaId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaId])

  useEffect(() => {
    if (area?.controllerId !== undefined && area?.controllerId !== controller?.deviceId) {
      dispatch(deviceActions.getDevice(area?.controllerId, "controller"));
    }
    setControllerName(controller?.name)
    setControllerCategory(controller?.category)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [area, controller])

  useEffect(() => {
    if (!_.isEmpty(area)) {
      setAreaDetails({
        ...area,
        areaId: area?.areaId,
        name: area?.name,
        spaceId: area?.spaceId,
        sync: area?.synced ? <ImageWrapper height={15} width={15} src={CheckCircle} /> : <ImageWrapper height={15} width={15} src={AlertIcon} />,
        syncStatus: area?.synced,
        dateOfCreation: format(new Date(area.createdOn), "dd MMMM yyyy"),
        dateLastUpdated: format(new Date(area.lastUpdatedOn), "dd MMMM yyyy"),
      });
    }
    //This useEffect calls api to get info of all the devices (here sensors) present in the area.
    if (!_.isEmpty(area?.sensorIds)) {
      dispatch(areaActions.getAreaDevicesDetails({
        "deviceIds": area?.sensorIds.join(',')
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [area])

  // useEffect(() => {
  //   if(!_.isEmpty(area) && !_.isEmpty(groupList)){
  //     let filteredGroupList = groupList?.filter( item => area?.groupIds?.includes(item?.groupId) ) || []
  //     setGroupsUnderArea(filteredGroupList)
  //   }
  //   if(!_.isEmpty(area) && !_.isEmpty(sensorList)){ //Need this if condition as we don't want to use previous area's sensor list if this area does not have any sensor list
  //     let filteredSensorList = sensorList?.filter( item => area?.sensorIds?.includes(item?.deviceId) ) || []
  //     setSensorsUnderArea(filteredSensorList)
  //   }
  // }, [area,groupList,sensorList])

  useEffect(() => {
    if (!_.isEmpty(area)) {
      let filteredGroupList = area?.groupIds || [];
      let filteredSensorList = area?.sensorIds || [];
      setSensorsUnderArea(filteredSensorList);
      setGroupsUnderArea(filteredGroupList);
    }
  }, [area, groupList, sensorList])

  useEffect(() => {
    setTableHeaders(sensorHeaders)
    setTableData(sensorsUnderArea)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sensorsUnderArea])

  const goTo = (route) => {
    history.push(route);
  };

  const handleSync = (event) => {
    event.preventDefault();
    dispatch(
      areaActions.syncById(areaId)
    );
  }


  const handleToggle = (e, index) => {
    if (index === 0) {
      setTableHeaders(sensorHeaders)
      setTableData(sensorsUnderArea)
    }
    else {
      setTableHeaders(groupHeaders)
      setTableData(groupsUnderArea)
    }
  }

  const handleConfirmation = (confirmed = false) => {
    if (confirmed) {
      setOpenConfirmationPopup({ show: false });
    } else {
      setOpenConfirmationPopup({ show: false });
    }
  }

  const handleDelete = (event) => {
    event.preventDefault();
    dispatch(areaActions.deleteArea(areaId, () => {
      history.goBack();
      setAreaDeleteTimeout(areaDeleteTimeout, dispatch)
    }));

  }

  return (
    <div className="AreaDetails" data-testid="AreaDetails">
      {/* AreaDetails Component of {areaId} */}
      <PageHeader
        bckbtn={true}
        backfunc={() => { goTo(`/spaces/devices?pt=controller&id=${area?.controllerId}&category=${controllerCategory}`) }} //Assuming we are coming from controller details page of controller always
        title={controllerName}
        icon={deviceIcon}
        breadcrumbs={{ device: true }}
      >
        <Stack direction={'row'}>
          <Box sx={{ textAlign: "right" }}>
            {areaDetails !== null && !areaDetails?.syncStatus && isAuthorised(features?.device, permissions?.syncArea) && (
              <StyledButton
                iconButton
                tooltip="Sync Area"
                onClick={(event) => { handleSync(event) }}
                sx={{ p: 0, ml: 2 }}
              >
                <ImageWrapper className="area-action-icons" src={Refresh} />
              </StyledButton>

            )}
            {isAuthorised(features?.device, permissions?.edit) &&
              <StyledButton
                sx={{ ml: 2, p: 0 }}
                tooltip="Edit Area"
                iconButton
                onClick={(event) => { goTo(`/spaces/areas?op=edit&deviceId=${area?.controllerId}&name=${controllerName}`) }}
              >
                <ImageWrapper className="area-action-icons" src={editIcon} />
              </StyledButton>
            }
            {isAuthorised(features?.device, permissions?.delete) &&
              <StyledButton
                onClick={() => setOpenConfirmationPopup({ show: true })}
                tooltip="Delete Area"
                sx={{ ml: 2, p: 0 }}
                iconButton
              >
                <ImageWrapper className="area-action-icons" src={deleteIcon} />
              </StyledButton>
            }
          </Box>
        </Stack>
      </PageHeader>
      <CustomAccordion title={"Area Summary"} LeftIcon={LeftIcon}>
        <Grid className="" container item xs={12}>
          {areaDetails &&
            Object.keys(areaDetailsArray)?.map((item, key, array) => (
              <Grid item md={2} xs={12} sx={{ mr: 10, mt: 2 }} style={{ margin: "16px 30px 0px 0px" }} key={key}>
                <Box className="">
                  <CustomTypography
                    weight={600}
                    color={"#000"}
                    size={13}
                    lineHeight={19}
                    styles={{ textTransform: "uppercase" }}
                    content={areaDetailsArray[item]["label"]}
                  />
                  <CustomTypography
                    weight={400}
                    color={"#000"}
                    size={13}
                    lineHeight={19}
                    content={areaDetails[item]}
                  />
                </Box>
              </Grid>
            ))}
        </Grid>
      </CustomAccordion>
      <Box sx={{ mb: 2 }}>
        <StyledTabs
          tabs={tabsArray}
          onClick={(e, value) => {
            handleToggle(e, value);
          }}
        />
      </Box>
      <CustomTable
        headerList={tableHeaders}
        cellMatrix={tableData || []}
        pagination={true}
        sorting={true}
      />
      <StyledPopup open={openConfirmationPopup.show} onClose={() => setOpenConfirmationPopup({ show: false })} state="timeout"
        data={{
          title: "Delete Area",
          content: getMessageStr("area-delete-confirmation", area?.name),
          actions: [

            {
              id: "1002",
              type: "button",
              label: "Cancel",
              onClick: (event, data) => {
                handleConfirmation(false);
              }
            },
            {
              id: "1001",
              type: "button",
              label: "Confirm",
              onClick: (event, data) => {
                handleConfirmation(true);
                handleDelete(event);
              }
            },
          ]
        }} />

    </div>
  )
};

AreaDetails.propTypes = {};

AreaDetails.defaultProps = {};

export default AreaDetails;
