/***
 *
 * Controller class for user.
 * @file StyledSpaceTreeDropDown.js
 * @description StyledSpaceTreeDropDown component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './StyledSpaceTreeDropDown.style.js';
import './StyledSpaceTreeDropDown.scss';
import { Box } from '@mui/material';
import { ReactComponent as DeleteIcon } from '../../assets/icons/cancel.svg';
import StyledChip from '../StyledChip/index.js';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import StyledTree from '../StyledTree/index.js';
import ErrorMessage from '../ErrorMessage/index.js';
import GreenInfo from '../../assets/icons/info.svg';
import { useOuterClick } from '../../hooks';
import classNames from 'classnames';
import StyledSpinner from '../StyledSpinner/index.js';

const StyledSpaceTreeDropDown = ({ id = "spdropdown", name = "spdropdown", multiselect = false, value = null, disabled = false, placeholder = "Select", error = null, message, helper = null, style = {}, sx = {}, onChange = () => { } }) => {
  const classes = useStyles();
  const spaces = useSelector(state => state?.spaces?.list);
  const [selectedItems, setSelectedItems] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [loading, setLoading] = useState(true);

  const innerRef = useOuterClick(e => {
    setOpenPopup(false);
  });

  const handleDelete = (item) => {
    selectOption(selectedItems?.filter(i => i.id !== item.id), true)
    // setSelectedItems(selectedItems?.filter(i => i.id !== item.id));
  }

  const selectOption = (val, callOnChange = true) => {
    const options = (val) ? val?.map(v => {
      const sp = spaces.find(s => s?.spaceId === (v?.id || v));
      return { id: sp?.spaceId, label: sp?.name };
    }) : [];

    if (callOnChange) {
      onChange({ target: { name: name, value: options } });
    }
    setSelectedItems(options);
  }

  const handleClick = (prev) => {
    if (!prev) {
      setLoading(true);
    }
    setOpenPopup(!prev);
  }

  useEffect(() => {
    // const options = (value) ? value?.map(v => {
    //   const sp = spaces.find(s => s?.spaceId === v);
    //   console.log('opppppsp',sp,v);
    //   return { id: sp?.spaceId, label: sp?.name };
    // }) : [];
    if (value && value?.length > 0) {
      selectOption(value, false);
    }
    // setSelectedItems(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useEffect(() => {
    if (openPopup) {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [openPopup])

  return (
    <Box className={classes?.StyledSpaceTreeDropDown || ""} data-testid="StyledSpaceTreeDropDown">

      <Box ref={innerRef} style={{ position: "relative", }}>
        {/* ------------- Dropdown box ------------ */}
        {/* <Box style={{border:error && `1px solid #ff4336`, opacity: (!disabled ? 1 : 0.4), cursor: (!disabled ? "pointer" : "default")}} className={openPopup ? "ChipArea selectedBorder" : "ChipArea" + disabled ? " disabled" : ""} onClick={!disabled ? () => setOpenPopup(prev => { return !prev; }) : null}> */}
        <Box tabIndex={0} style={{ border: error && `1px solid #ff4336` }} className={classNames(
          openPopup ? "ChipArea selectedBorder" : "ChipArea", disabled && "disabled"
        )} onClick={!disabled ? () => handleClick(openPopup) : null} sx={openPopup ? { "&::after": { borderBottom: " 5px solid rgba(0, 0, 0, 0.54);", borderTop: "0" } } : {}}>
          {(selectedItems && selectedItems[0] && selectedItems[0]?.id) ? (
            selectedItems?.map((item, index) => {
              return (
                <StyledChip
                  key={"space-tree----" + index}
                  variant="outlined"
                  sx={{ marginTop: "3px", marginBottom: "3px", ...sx }}
                  style={{ ...style }}
                  label={item?.label}
                  deleteIcon={!disabled ? <DeleteIcon /> : null}
                  onDelete={!disabled ? () => handleDelete(item) : null}
                />
              )
            })
          ) : (<span className={"placeholder"}>{placeholder}</span>)
          }
        </Box>

        {/* -------------- Dropdown list ----------- */}
        {openPopup &&
          <Box className="StyledSpaceTreeDropDownList">
            {loading &&
              <Box sx={{ position: 'absolute', top: 0, bottom: 0, width: "100%", height: "100%", backgroundColor: "#f6faf8", zIndex: 1090, minHeight: "40px" }}>
                <StyledSpinner size="20px" sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
              </Box>
            }
            <StyledTree
              data={spaces[0]}
              selectedItems={((selectedItems && selectedItems?.length > 0 && selectedItems[0]?.id) && selectedItems?.map(ssp => ssp?.id)) || []}
              search={false}
              type={multiselect ? 'checkbox' : 'radio'}
              idKey='spaceId' childrenKey={'Spaces'}
              setterFunction={(value) => {
                if (!Array.isArray(value)) {
                  let arrValue = []
                  arrValue.push(value?.spaceId);
                  value = arrValue;
                }
                selectOption(value)
              }} />
          </Box>
        }
      </Box>

      {/* -------------- Error and helper text area to show message ------------- */}
      {message && <ErrorMessage message={message} />}
      {(helper && helper?.text) && <ErrorMessage src={GreenInfo} color={helper?.color} sx={{ ...helper?.sx }} message={helper?.text} />}

    </Box>
  )
};

StyledSpaceTreeDropDown.propTypes = {};

StyledSpaceTreeDropDown.defaultProps = {};

export default StyledSpaceTreeDropDown;
