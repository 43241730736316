/***
 *
 * Controller class for user.
 * @file UnauthorisedAccess.js
 * @description UnauthorisedAccess component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import { Box } from '@mui/material';
import React from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './UnauthorisedAccess.style.js';
import DataNotFound from '../DataNotFound/index.js';
import UnauthorisedIcon from '../../assets/icons/troubleshoot-error.svg';

const UnauthorisedAccess = () => {
  const classes = useStyles();

  return (
    <Box className={classes?.UnauthorisedAccess || ""} data-testid="UnauthorisedAccess">
      <DataNotFound 
        data={{
          image: UnauthorisedIcon,
          label: 'Unauthorised User',
          content: "Uh oh, looks like you are not authorised to access this page. Please contact system administrator",
          // action: [
          //   {
          //     id: "1001",
          //     type: "button",
          //     label: "Go to home page",
          //     onClick: (event, data) => { history.replace("/"); },
          //   }
          // ]
        }}
        style={{height: "100vh"}}
      />
    </Box>
  )
};

UnauthorisedAccess.propTypes = {};

UnauthorisedAccess.defaultProps = {};

export default UnauthorisedAccess;
