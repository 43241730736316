import {
  Box,
  Checkbox,
  Divider,
  Grid,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { deviceActions } from "../../../redux/actions/device.actions";
import CustomTypography from "../../CustomTypography";
import {
  changeDialogContent,
  confirmationDialog,
  getMessageStr,
  isAuthorised,
} from "../../../helpers";
import { mqttRequest } from "../../../services";
import mqttTimeout from "../../../helpers/mqtt.helper";
import { mqttActions } from "../../../redux/actions";
import DiscoverSensors from "../../DiscoverSensors";
import { features, permissions } from "../../../constants";
import StyledButton from "../../StyledButton";

function ControllerToolbar({
  mqttTopics,
  lightSelector,
  deviceData,
  setDialog,//styledPopup variable
  setDialogContents,//styledPopup variable
  mqttClient,
  setDialogBox,//styledPopupWrapper variable
  setDialogBoxContents,//styledPopupWrapper variable
  deviceList = [],
  params = { broadcast: true, commission: true, discover: true }
}) {
  const [toggleReaddress, setToggleReaddress] = useState(false);



  const dispatch = useDispatch();

  const handleReaddress = () => {
    if (toggleReaddress) setToggleReaddress(false);
    else setToggleReaddress(true);
  };


  const findSensorDialog = (e) => {
    setDialogBoxContents((dialogBoxContents) => ({
      ...dialogBoxContents,
      state: "component",
      title: "Discover Sensors",
      component: <DiscoverSensors
        mqttClient={mqttClient}
        mqttTopics={mqttTopics}
        deviceDetails={deviceData}
        setDialog={setDialogBox}//popupwrapper variable
        setDialogBox={setDialog}//popup variable
        setDialogBoxContents={setDialogContents}//popup variable
      >
      </DiscoverSensors>,
      content: {
        title: "Discover Sensors",
      },
    }));
    setDialogBox(true);
  }
  //ayeshakumar 6 sep

  const setPendingDialog = (title, content) => {

    setDialogContents((dialogContents) => ({
      ...dialogContents,
      state: "pending",
      content: {
        title: title || dialogContents?.content?.title,
        content: content || "Operation in Progress",
        actions: [],
      },
    }));
    setDialog(true);
  }

  //ayesha a kumar 29 aug 2022
  const broadcast = (intensity) => {
    //   const mqttPayload = {
    //     "cmd": "daliBroadcast",
    //     // "deviceId": "L4Ck",
    //     "deviceId": deviceData?.deviceId,
    //     "intensity": intensity,
    //     "sid": "7687",
    //     "cnt": "wa"
    //  }
    const broadcastOperation = () => {
      let timeout = null;
      setDialog(false);
      const mqttMessageRecieved = (message) => {
        console.log("dali broadcast")
        //console.log("message received",message);
        if (message?.cmd === "daliBroadcast") {
          if (message?.status === "success") {
            clearTimeout(timeout)
            changeDialogContent("success", "Light Broadcast", getMessageStr(`${message?.cmd}-receive-${message?.status}`), true, setDialog, setDialogContents);
            setDialog(true);
            dispatch(mqttActions.deleteMqttRequest(message?.sid));
            // unsubscribe(mqttTopics?.comStatus, mqttClient);
          }
          else if (message?.status === "command_accepted") {
            setPendingDialog(null, getMessageStr(`${message?.cmd}-receive-${message?.status}`))
            setDialog(true);
          }
          else {
            // setPendingDialog("Light Broadcast");
            clearTimeout(timeout);
            changeDialogContent("timeout", "Light Broadcast", getMessageStr(`${message?.cmd}-receive-${message?.status}`), true, setDialog, setDialogContents);
            setDialog(true);
            dispatch(mqttActions.deleteMqttRequest(message?.sid));
            // unsubscribe(mqttTopics?.comStatus, mqttClient);
          }
        }
      };
      setPendingDialog("Light Broadcast", getMessageStr("daliBroadcast-send-send_mqtt_command"));

      const messageSid = mqttRequest(
        {
          subTopic: mqttTopics?.comStatus,
          pubTopic: mqttTopics?.comControl,
          deviceId: deviceData?.deviceId,
          deviceConfig: {
            cmd: "daliBroadcast",
            lst: intensity,
          },
          onRecieve: mqttMessageRecieved,
        },
        mqttClient,
        dispatch
      );
      timeout = setTimeout(() => {
        dispatch(mqttActions.deleteMqttRequest(messageSid));
        mqttTimedOut("daliBroadcast", mqttTopics?.comStatus)
      }, mqttTimeout);
    }
    confirmationDialog("Light Broadcast", broadcastOperation, "daliBroadcast", setDialog, setDialogContents, intensity === 70 ? "on" : "off")
  };

  // const closeDialog = () => {
  //   setDialog(false);
  //   mqttDisconnect();
  // };
  const mqttMessageRecieved = (message, timeout) => {
    if (
      message?.cmd === "daliDiscover" ||
      message?.cmd === "sensorDiscover" ||
      message?.cmd === "daliCommission" ||
      message?.cmd === "sensorCommission" ||
      message?.cmd === "lightConfigSync"
    ) {
      if (
        message?.status === "commissioning_ended" ||
        // message?.status === "success"
        (message.status === "success" && message?.cmd === "sensorDiscover") ||
        (message.status === "light_config_success" && message?.cmd === "lightConfigSync")
      ) {
        if ((message?.cmd === "lightConfigSync" || message?.cmd === "sensorDiscover")) {
          dispatch(deviceActions.getAllChildren(deviceData?.deviceId, "device"));
        }
        clearTimeout(timeout);
        changeDialogContent("success", null, getMessageStr(`${message?.cmd}-receive-${message?.status}`), true, setDialog, setDialogContents);
        dispatch(mqttActions.deleteMqttRequest(message?.sid));
        // unsubscribe(mqttTopics?.comStatus, mqttClient);
      } else if (
        message.status === "command_accepted" ||
        message.status === "success" ||
        message.status === "commissioning_started"
      ) {
        setPendingDialog(null, getMessageStr(`${message?.cmd}-receive-${message?.status}`));
      } else {
        clearTimeout(timeout);
        dispatch(mqttActions.deleteMqttRequest(message?.sid));
        // unsubscribe(mqttTopics?.comStatus, mqttClient);
        changeDialogContent("timeout", null, getMessageStr(`${message?.cmd}-receive-${message?.status}`), true, setDialog, setDialogContents)
      }
    }


    //console.log(typeof(message),JSON.parse(message))
  };
  // const mqttConnectionFail = () => {
  //   console.log("mqtt connection was not successful");
  //   clearTimeout(timeout);
  //   changeDialogContent("timeout", null, "Connection with mqtt broker has failed!", true);
  // };
  const mqttTimedOut = (cmd, topic) => {
    // unsubscribe(topic, mqttClient);
    changeDialogContent("timeout", null, getMessageStr(`${cmd}-receive-response_not_received`), true, setDialog, setDialogContents);
  };

  const daliDiscovery = () => {
    let timeout = null;
    const cmd = lightSelector === "Receiver" ? "sensorDiscover" : "daliDiscover";
    const discoverOperation = () => {

      console.log(mqttTopics?.comControl);
      console.log(mqttTopics?.comStatus);
      const statusTopic = mqttTopics?.comStatus;
      const controlTopic = mqttTopics?.comControl;


      setPendingDialog(lightSelector === "Receiver" ? "Sensor Discover" : "Device Discover", getMessageStr(`${cmd}-send-send_mqtt_command`))

      const messageSid = mqttRequest(
        {
          subTopic: statusTopic,
          pubTopic: controlTopic,
          deviceId: deviceData?.deviceId,
          deviceConfig: { cmd },
          onRecieve: (message) => { mqttMessageRecieved(message, timeout) },
          // onFail: mqttConnectionFail,
        },
        mqttClient,
        dispatch
      );
      timeout = setTimeout(() => {
        dispatch(mqttActions.deleteMqttRequest(messageSid));
        mqttTimedOut(cmd, statusTopic)
      }, mqttTimeout);

    }
    confirmationDialog(lightSelector === "Receiver" ? "Sensor Discover" : "Device Discover", discoverOperation, cmd, setDialog, setDialogContents)
  };
  const commissionStart = () => {
    let timeout = null;
    const cmd = lightSelector === "Receiver" ? "sensorCommission" : "daliCommission";
    const commissionOperation = () => {
      // console.log(mqttTopics?.comStatus);
      const statusTopic = mqttTopics?.comStatus;
      const controlTopic = mqttTopics?.comControl;
      // console.log(deviceData.deviceId);
      const mqttPayload = {
        cmd,
        // "deviceId":"L4Ck",
        //"deviceId": deviceData?.deviceId,
        reAddr: false,
        // "sid": "299",
        // "cnt": "wa",
      };
      if (toggleReaddress && lightSelector !== "Receiver") {
        mqttPayload.reAddr = true;
      } else {
        mqttPayload.reAddr = false;
      }
      setPendingDialog(lightSelector === "Receiver" ? "Sensor Commission" : "Device Commission", getMessageStr(`${mqttPayload?.cmd}-send-send_mqtt_command`))
      const messageSid = mqttRequest(
        {
          subTopic: statusTopic,
          pubTopic: controlTopic,
          deviceId: deviceData?.deviceId,
          deviceConfig: mqttPayload,
          onRecieve: (message) => { mqttMessageRecieved(message, timeout) },
          // onFail: mqttConnectionFail,
        },
        mqttClient,
        dispatch
      );
      timeout = setTimeout(() => {
        dispatch(mqttActions.deleteMqttRequest(messageSid));
        mqttTimedOut(mqttPayload?.cmd, mqttTopics?.comStatus)
      }, mqttTimeout);

    };
    confirmationDialog(lightSelector === "Receiver" ? "Sensor Commission" : "Device Commission", commissionOperation, cmd, setDialog, setDialogContents)
  };

  return (
    <Box className="toolbarContainer">
      <Grid container gap={1} sx={{ mt: 2 }} >
        <>
          {params?.broadcast && isAuthorised(features?.device, permissions?.broadcast) &&
            <>
              <Grid item
                style={{
                  display: "flex",
                  gap: "1em",
                  alignItems: "center",
                  // marginRight: "0.5rem",
                }}
              >
                <CustomTypography
                  content={"Broadcast"}
                  size={12}
                  color={"#000"}
                  lineHeight={14.63}
                  weight={600}
                />
                <Box style={{ display: "flex", gap: "1em" }}>
                  <StyledButton
                    sx={{ px: { xs: 1, sm: 2, }, minWidth: "58px" }}
                    id="on_icon"
                    variant="outlined"
                    disabled={params?.broadcast === "disabled" ? true : false}
                    onClick={() => {
                      broadcast(70)
                    }
                    }
                  >
                    ON
                  </StyledButton>
                  <StyledButton
                    sx={{ px: { xs: 1, sm: 2, }, minWidth: "58px" }}
                    id="off_icon"
                    variant="outlined"
                    disabled={params?.broadcast === "disabled" ? true : false}
                    onClick={() => broadcast(0)}
                  >
                    OFF
                  </StyledButton>
                </Box>

              </Grid>
              <Grid item className="divider">
                <Divider
                  sx={{ height: "40px" }}
                  orientation="vertical"
                  flexItem
                />
              </Grid>
            </>
          }

          {params?.commission && isAuthorised(features?.device, permissions?.commission) && (
            <>
              <Grid item style={{ display: "flex", alignItems: "center" }}>
                {lightSelector !== "Receiver" &&
                  < Box className="readdress_box">
                    <Checkbox
                      sx={{ pl: 0 }}
                      checked={toggleReaddress}
                      id="checkbox"
                      onClick={handleReaddress}
                      inputProps={{ "aria-label": "check-box" }}
                    />
                    <CustomTypography
                      content={"Readdress"}
                      size={12}
                      color={"#000"}
                      lineHeight={14.63}
                      weight={600}
                    />
                  </Box>}
                <StyledButton
                  sx={{ ml: 1, px: 2 }}
                  id="commission"
                  disabled={params?.commission === "disabled" || (lightSelector === "Receiver" && deviceList?.find(d => d?.pairing === true))}
                  onClick={commissionStart}
                  variant="outlined"
                >
                  Commission
                </StyledButton>
              </Grid>
              <Grid item className="divider">
                <Divider
                  sx={{ height: "40px" }}
                  orientation="vertical"
                  flexItem
                />

              </Grid>
            </>
          )}
          {
            params?.discover && isAuthorised(features?.device, permissions?.discover) &&
            <Grid item style={{
              display: "flex",
              alignItems: "center",
            }}>
              <StyledButton
                sx={{ px: 2 }}
                id="discover"
                disabled={(lightSelector === "Receiver" && deviceList?.find(d => d?.pairing === true) || params?.discover === "disabled")}
                onClick={deviceData?.category === "Aggregator" ? findSensorDialog : daliDiscovery}
                variant="outlined"
              >
                Discover
              </StyledButton>
            </Grid>
          }
        </>
      </Grid>

    </Box >
  );
}
export default ControllerToolbar;
