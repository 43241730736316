export const alertsMessages = {}
export const alertsApiMessages = {
    "alertSearch-get-200": "Alert(s) successfully retrieved",
    "alertSearch-get-401": "Unable to retrieve the alert(s), please verify your access and try again.",
    "alertSearch-get-403": "Unable to retrieve the alert(s), please verify your access and try again.",
    "alertSearch-get-404": "Unable to retrieve the alert(s), please try again later.",
    "alertSearch-get-500": "Unable to retrieve the alert(s) due to internal error, please try again later.",

    "alertResolve-put-200": "Alert successfully resolved.",
    "alertResolve-put-400": "Unable to resolve alert, please verify inputs and try again.",
    "alertResolve-put-401": "Unable to resolve alert, please verify your access and try again.",
    "alertResolve-put-403": "Unable to resolve alert, please verify your access and try again.",
    "alertResolve-put-404": "Unable to resolve alert, please try again later.",
    "alertResolve-put-500": "Unable to resolve alert due to internal error, please try again later.",

    "alertAcknowledge-put-200": "Alert successfully acknowledged.",
    "alertAcknowledge-put-400": "Unable to acknowledge alert, please verify inputs and try again.",
    "alertAcknowledge-put-401": "Unable to acknowledge alert, please verify your access and try again.",
    "alertAcknowledge-put-403": "Unable to acknowledge alert, please verify your access and try again.",
    "alertAcknowledge-put-404": "Unable to acknowledge alert, please try again later.",
    "alertAcknowledge-put-500": "Unable to acknowledge alert due to internal error, please try again later.",

    "alertType-get-200": "Alert types successfully retrieved.",
    "alertType-get-401": "Unable to retrieve the alert types, please verify your access and try again.",
    "alertType-get-403": "Unable to retrieve the alert types, please verify your access and try again",
    "alertType-get-500": "Unable to retrieve the alert types due to internal error, please try again later.",
}

export const alertInlineMessages={
    'alert-list-empty':"There are no alerts available.",
    'alert-resolve-title':"Resolve Alert",
    'alertResolve-send-confirm':"Are you sure you want to resolve the alert?"
}