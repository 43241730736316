import { API_URL } from '../config/appConfig'
import { API } from '../services'
import { handleResponse } from '../helpers';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

const getAllDevices = (params) => {
  return API.get(
    `${API_URL.device}/devices`,
    "getAllDevices", params
  ).then(handleResponse)
};

const getDevice = (parentId) => {
  return API.get(
    `${API_URL.device}/device/${parentId}`,
    `getDevice/${parentId}`,
    {}
  ).then((res) => {
    return handleResponse(res, true);
  });
};

// const getDataByControllerId = (type,controlledId) => {
//     return API.get(`${API_URL.device}/${type}/controller/${controlledId}`, `getDevice/${controlledId}`, {}).then(handleResponse);
// } 

const getAllChildren = (parentId, params = {}) => {
  return API.get(`${API_URL.device}/devices/${parentId}/children`, `getAllChildren/${parentId}/children`, params).then(handleResponse);
}
//API to update device name
const updateDevice = (deviceId, payload) => {
  return API.put(
    `${API_URL.device}/device/${deviceId}`,
    `updateDevice/${deviceId}`,
    payload
  ).then((res) => {
    return handleResponse(res, true);
  });
};

const deleteDevice = (deviceId) => {
  return API.delete(
    `${API_URL.device}/devices/${deviceId}`,
    `deleteDevice/${deviceId}`,
    {}
  ).then((res) => {
    return handleResponse(res, true);
  });
};

const factoryReset = (deviceId, payload) => {
  return API.post(
    `${API_URL.device}/devices/${deviceId}/factoryReset`,
    `factoryReset/${deviceId}`,
    payload
  ).then((res) => {
    return handleResponse(res, true);
  });
};

const findSensors = (deviceId, payload) => {
  return API.post(
    `${API_URL.device}/devices/${deviceId}/findSensors`,
    `findSensors/${deviceId}`,
    payload
  ).then((res) => {
    return handleResponse(res, true);
  });
};
const fetchSensors = (requestId) => {
  return API.get(
    `${API_URL.device}/devices/${requestId}/fetchSensors`,
    `fetchSensors/${requestId}`,
    {}
  ).then((res) => {
    return handleResponse(res, true);
  });
};
const bindSensors = (deviceId, payload) => {
  return API.post(
    `${API_URL.device}/devices/${deviceId}/bindSensors`,
    `bindSensors/${deviceId}`,
    payload
  ).then((res) => {
    return handleResponse(res, true);
  });
};
// const updateController = (deviceId, payload) => {
//   return API.patch(
//     `${API_URL.device}/devices/${deviceId}`,
//     `updateController/${deviceId}`,
//     payload
//   ).then(handleResponse);
// };
const addDevice = (parentId, payload) => {//used to add controller and aggregator
  return API.post(
    `${API_URL.device}/devices/${parentId}`,
    `addDevice/${parentId}`,
    payload
  ).then((res) => {
    return handleResponse(res, true);
  });
};

// const deleteController = (deviceId) => {
//   return API.delete(
//     `${API_URL.device}/devices/${deviceId}`,
//     `deleteController/${deviceId}`,
//     {}
//   ).then(handleResponse);
// };

const moveDevice = (spaceId, deviceId) => {
  // console.log("moveDevice in service ---- ");
  return API.put(
    `${API_URL.device}/spaces/${spaceId}/${deviceId}`,
    `update/moveDevice/${spaceId}/${deviceId}`,
    {}
  ).then((res) => {
    return handleResponse(res, true);
  });
};

const updateConfig = (deviceId, payload, type) => {
  // console.log("updateLightConfig in service");
  return API.put(
    `${API_URL.device}/devices/${deviceId}/config?type=${type}`,
    `updateConfig/${deviceId}`,
    payload
  ).then((res) => {
    return handleResponse(res, true);
  });
};

const getDevicesBySpaceId = (spaceId, category) => {
  // console.log("getdevicesbypsceid in service")
  let url = !_.isEmpty(category) ? `${API_URL.device}/spaces/${spaceId}/devices?category=${category}` : `${API_URL.device}/spaces/${spaceId}/devices`
  return API.get(url, `getDevices/${spaceId}`)
    .then((res) => {
      // console.log("resss -- ", res)
      res.data = res?.data?.map(data => {
        data["devId"] = uuidv4();
        return data;
      })
      return res;
    }).then(handleResponse);
}


const getDeviceModels = (tenantId = null) => {
  // console.log("in getDeviceModels service")
  return API.get(`${API_URL.device}/deviceModels`, `deviceModels`, {}, {}, tenantId === null ? {} : { headers: { tenantId } }).then(handleResponse);
}
const getDeviceCategories = (tenantId = null) => {
  return API.get(`${API_URL.device}/deviceCategory`, `deviceCategory`, {}, {}, tenantId === null ? {} : { headers: { tenantId } }).then(handleResponse);
}
const getDetailedDeviceList = async (payload={}, tenantId = null) => {
  const headers = { 'Content-Type': 'application/json' };
  if (tenantId !== null) {
    headers.tenantId = tenantId
  }
  return API.get(`${API_URL.device}/devices`, "getDetailedDeviceList", payload, {}, { headers }).then(handleResponse);
}

const getDeviceActivityLogs = (deviceId, queryParams) => {
  return API.get(`${API_URL.support}/activities/device/${deviceId}`, `/activities/device/${deviceId}`, queryParams).then(handleResponse);
}

const getGatewayLog = (deviceId, date) => {
  return API.get(
    `${API_URL.device}/devices/${deviceId}/log?date=${date}`,
    `getGatewayLog/${deviceId}`,
    {}
  ).then((res) => {
    return handleResponse(res, {});
  });
};

const getGatewayLogDates = (deviceId) => {
  return API.get(
    `${API_URL.device}/devices/${deviceId}/logs`,
    `getGatewayLogs/${deviceId}`,
    {}
  ).then((res) => {
    return handleResponse(res, {});
  });
};

const assignGateway = (payload) => {
  return API.post(
    `${API_URL.gateway}/gateways/linking`,
    `assignGateway/linking`,
    payload
  ).then((res) => {
    return handleResponse(res, true);
  });
};

const getAssignedGateways = (params = {}, tenantId = null, portal = "mss") => {
  return API.get(
    `${portal === "mss" ? API_URL.device : API_URL.gateway}/gateways${portal === "mss" ? "/" : ""}`,
    `getAssignedGateways/`,
    params, {}, tenantId === null ? {} : { headers: { tenantId } }
  ).then((res) => {
    return handleResponse(res, {});
  });
};

const getGatewayDetails = (macAddress) => {
  return API.get(
    `${API_URL.gateway}/gateways/${macAddress}`,
    `getGatewayDetails/`,
    {}
  ).then((res) => {
    return handleResponse(res, {});
  });
};

const getGatewayStatistics = () => {
  return API.get(
    `${API_URL.gateway}/gateways/summary`,
    `getGatewayStatistics/`,
    {}
  ).then((res) => {
    return handleResponse(res, {});
  });
};

const getCameraStreamUrl = () => {
  return API?.get(`${API_URL.camera}/start?deviceId=23kiqlkwslkjhd3maksoufwbej&resolution=360p`,
    '/start?deviceId=23kiqlkwslkjhd3maksoufwbej',
    {}
  ).then((res) => {
    return handleResponse(res, {})
  })
}

const stopCameraStream = () => {
  return API?.get(`${API_URL.camera}/stop?deviceId=23kiqlkwslkjhd3maksoufwbej`,
    '/stop?deviceId=23kiqlkwslkjhd3maksoufwbej',
    {}
  ).then((res) => {
    return handleResponse(res, {})
  })
}


export const deviceService = {
  getAllDevices,
  updateDevice,
  addDevice,
  deleteDevice,
  // updateController,
  getDevice,
  getAllChildren,
  moveDevice,
  // getDataByControllerId,
  updateConfig,
  getDevicesBySpaceId,
  getDeviceModels,
  getDeviceCategories,
  // getMQTTTopicByControllerId,
  getDetailedDeviceList,
  getDeviceActivityLogs,
  factoryReset,
  getGatewayLog,
  findSensors,
  fetchSensors,
  bindSensors,
  getGatewayLogDates,
  assignGateway,
  getAssignedGateways,
  getGatewayDetails,
  getGatewayStatistics,
  getCameraStreamUrl,
  stopCameraStream
};
