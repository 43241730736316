import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(( theme ) => ({
    StyledChip: {
        '&.MuiChip-root': {
            fontSize: theme?.fontSize?.chip || "0.75rem",
            lineHeight: theme?.lineHeight?.chip || "32px",
            height: theme?.height?.chip || "32px",
            color: theme?.color?.chip || "rgba(0, 0, 0, 0.8)"
        },
        '& .MuiChip-label': {
            padding: "0px 15px 0px 15px"
        }
    }
}));
