
import { canvasTypes } from "../types/canvas.type";
import clamp from "clamp";
import { LIMITS, SHAPES } from "../../constants/canvas.constants";
import { success } from "../../helpers";

const deviceType = {
  'VortexGateway': 1080,
  'DaliLightController': 1060,
  'DaliAnalogLightController': 1060,
  'RelayLightController': 1060,
  'AnalogLightDriver': 1060,
  'AnalogLightController': 1060,
  'DaliLightDriver': 1060,
  'RelayLightDriver': 1060,
  'Sensor': 1090,
  'Receiver': 1090
}


const createShape = (props) => {
  let shape = props?.category ? SHAPES?.find(s => s?.id === deviceType[props?.category]) : SHAPES?.find(s => s?.id === Number(props?.id));
  let shapeobj = { ...shape, ...props };

  // if(props?.type !== 'polygon'){
  shapeobj['x'] = props?.x;
  shapeobj['y'] = props?.y;
  shapeobj['uniq_id'] = props?.type === 'image' ? props?.deviceId + '_' + props?.spaceId + '_' + 'device' : props?.spaceId + '_' + props?.type
  // }

  return (dispatch) => {
    dispatch({ type: canvasTypes.ADD_SHAPE, payload: shapeobj });
  }

};

const constructPolygon = (shapeObj, payload) => {
  let uid = shapeObj?.spaceId + '_polygon';
  let pointSet = new Set(payload?.points);
  shapeObj['points'] = Array.from(pointSet); // [x1,y1,x2,y2,x3,y3];
  shapeObj['position'] = payload?.position;
  shapeObj['isPolyComplete'] = payload?.isPolyComplete;
  shapeObj['flattenedPoints'] = payload?.flattenedPoints || [0, 0]; // [ [x1,y1],[x2,y2],[x3,y3] ]
  shapeObj['uniq_id'] = uid;
  // console.log('polygon constructed===>',shapeObj,payload);
  return (dispatch) => {
    dispatch({ type: canvasTypes.CONSTRUCT_POLYGON, payload: shapeObj });
  }
}

const addImage = (props) => { //// layout 
  return (dispatch) => {

    let image = SHAPES.find(s => s?.id === Number(props?.id));
    let shapeObj = { ...image, ...props };

    shapeObj['uniq_id'] = props?.spaceId + '_layout';
    shapeObj['url'] = props?.url;
    shapeObj['spaceId'] = props?.spaceId;
    shapeObj['width'] = props?.width;
    shapeObj['height'] = props?.height;
    dispatch({ type: canvasTypes.ADD_LAYOUT, payload: shapeObj });
  }
}

const replaceImage = (props) => {
  return (dispatch) => {
    dispatch({ type: canvasTypes.REPLACE_LAYOUT, payload: props });
  }
}

const selectShape = (id) => {
  return (dispatch) => {
    dispatch({ type: canvasTypes.SELECTED_SHAPE, payload: id });
  }
};

const removeShapes = (space = null) => {
  return (dispatch) => {
    dispatch({ type: canvasTypes.REMOVE_SHAPES, payload: space });
  }
};

const zoomin = () => {
  return (dispatch) => {
    dispatch({ type: canvasTypes.ZOOM_IN })
  }
}
const zoomout = () => {
  return (dispatch) => {
    dispatch({ type: canvasTypes.ZOOM_OUT })
  }
}

const resetRightPanel = () => {
  return (dispatch) => {
    dispatch({ type: canvasTypes.RESET_LEFT_PANEL });
  }
}

const clearSelection = () => {
  return (dispatch) => {
    dispatch({ type: canvasTypes.SELECTED_SHAPE, payload: null })
  }
};

const moveShape = (selectedShape, event) => {
  selectedShape['x'] = event.target.x();
  selectedShape['y'] = event.target.y();
  return (dispatch) => {
    dispatch({ type: canvasTypes.UPDATE_SHAPE, payload: selectedShape });
  }
};


const updateAttribute = (attr, value, selectedShape) => {
  selectedShape[attr] = value;
  return (dispatch) => {
    dispatch({ type: canvasTypes.UPDATE_SHAPE, payload: selectedShape })
  }

};


const deleteShape = (shape) => {
  return (dispatch, getState) => {
    dispatch({ type: canvasTypes.REMOVE_SHAPE, payload: shape })
  }
}

/// redundant
// const shapesBySpaceId = (spaceId, type) => {
//   return (dispatch, getState) => {
//     dispatch({ type: canvasTypes.DRAG_ONE_SHAPE, payload: { spaceId: spaceId, type: type } })
//   }
// }

const highlightGroup = (gid) => {
  return (dispatch) => {
    dispatch({ type: canvasTypes.HIGHLIGHT_GROUPID, payload: gid });
  }
}

const modalState = (state) => {
  return (dispatch) => {
    dispatch({ type: canvasTypes.MODAL_STATE, payload: state });
  }
}



const flickerDevice = (device) => {
  return dispatch => {
    dispatch({ type: canvasTypes.FLICKER_DEVICE, payload: device })
  }
}



const modalSpace = (space) => {
  return dispatch => {
    dispatch({ type: canvasTypes.MODAL_SPACE, payload: space })
  }
}


export const emptyCanvasState = (type, payload = null) => {
  if (!type)
    return null;

  // console.log('typessssss===>',userTypes[type]);

  return dispatch => {
    dispatch(success(canvasTypes[type], { payload: payload }))
  }
}

export const observeChange = (payload = null) => {
  return dispatch => {
    dispatch(success(canvasTypes.OBSERVE_CHANGE, { payload: payload }))
  }
}

export const spaceLayouts = (payload) => {
  return (dispatch, getState) => {
    const spaceList = getState().spaces?.list;
    dispatch(success(canvasTypes.SPACE_LAYOUTS, { payload: payload, spacelist: spaceList }))
  }
}

export const getAllGroupList = (payload) => {
  return (dispatch, getState) => {
    dispatch(success(canvasTypes.GETALL_GROUPLIST, { payload }))
  }
}


const transformRectangleShape = (node, selectedShape, event) => {

  const scaleX = node.scaleX();
  const scaleY = node.scaleY();

  node.scaleX(1);
  node.scaleY(1);


  selectedShape['x'] = node.x();
  selectedShape['y'] = node.y();

  selectedShape.rotation = node.rotation();

  selectedShape['width'] = clamp(
    // increase the width in order of the scale
    node.width() * scaleX,
    // should not be less than the minimum width
    LIMITS.RECT.MIN,
    // should not be more than the maximum width
    LIMITS.RECT.MAX
  );
  selectedShape['height'] = clamp(
    node.height() * scaleY,
    LIMITS.RECT.MIN,
    LIMITS.RECT.MAX
  );

  return (dispatch) => {
    dispatch({ type: canvasTypes.UPDATE_SHAPE, payload: selectedShape });
  }

};

const transformCircleShape = (node, selectedShape, event) => {

  const scaleX = node.scaleX();
  const scaleY = node.scaleY();

  node.scaleX(1);
  node.scaleY(1);


  selectedShape['x'] = node.x();
  selectedShape['y'] = node.y();

  selectedShape.rotation = node.rotation();

  selectedShape['width'] = clamp(
    // increase the width in order of the scale
    node.width() * scaleX,
    // should not be less than the minimum width
    LIMITS.RECT.MIN,
    // should not be more than the maximum width
    LIMITS.RECT.MAX
  );
  selectedShape['height'] = clamp(
    node.height() * scaleY,
    LIMITS.RECT.MIN,
    LIMITS.RECT.MAX
  );


  selectedShape.x = node.x();
  selectedShape.y = node.y();

  selectedShape.radius = clamp(
    (node.width() * scaleX) / 2,
    LIMITS.CIRCLE.MIN,
    LIMITS.CIRCLE.MAX
  );



  return (dispatch) => {
    dispatch({ type: canvasTypes.UPDATE_SHAPE, payload: selectedShape });
  }
};

const transformPolygonShape = (node, selectedShape, event) => {

  node.scaleX(1);
  node.scaleY(1);


  selectedShape['x'] = node.x();
  selectedShape['y'] = node.y();

  return (dispatch) => {
    dispatch({ type: canvasTypes.UPDATE_SHAPE, payload: selectedShape });
  }
};

const transformSticker = (node, selectedShape, event) => {


  // we will reset the scale back
  node.scaleX(1);
  node.scaleY(1);


  selectedShape['x'] = node.x();
  selectedShape['y'] = node.y();

  return (dispatch) => {
    dispatch({ type: canvasTypes.UPDATE_SHAPE, payload: selectedShape });
  }
};



export const canvasActions = {
  createShape,
  constructPolygon,
  selectShape,
  updateAttribute,
  deleteShape,
  removeShapes,
  moveShape,
  clearSelection,
  resetRightPanel,
  zoomin,
  zoomout,
  highlightGroup,
  modalState,
  flickerDevice,
  modalSpace,
  emptyCanvasState,
  addImage,
  replaceImage,
  observeChange,
  spaceLayouts,
  getAllGroupList,
  transformRectangleShape,
  transformCircleShape,
  transformSticker,
  transformPolygonShape,
}



