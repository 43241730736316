/***
 *
 * Controller class for user.
 * @file Tickets.js
 * @description Tickets component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './Tickets.style.js';
import './Tickets.scss';
import { Box } from '@mui/material';
import { useQuery } from '../../hooks';
import CreateTicket from '../CreateTicket/index.js';
import TicketDetails from '../TicketDetails/index.js';
import TicketsList from '../TicketsList/index.js';

const Tickets = () => {
  const classes = useStyles()
  const query = useQuery();
  const operation = query.get('op') || null;

  return (
    <Box className={classes?.Tickets || ""} data-testid="Tickets">
      {(() => {
        switch (operation) {
          case "create":
            return <CreateTicket />;
          case "details":
            return <TicketDetails />;
          default:
            return <TicketsList />;
        }
      })()}
    </Box>
  )
};

Tickets.propTypes = {};

Tickets.defaultProps = {};

export default Tickets;
