/***
 *
 * Controller class for user.
 * @file StyledButton.js
 * @description StyledButton component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './StyledButton.style.js';
import './StyledButton.scss';
import { Button, IconButton, Tooltip } from '@mui/material';

const StyledButton = ({id = '',color = "primary", tooltip = '', placement = 'bottom', iconButton = false, variant = "contained", size = "medium", children, ...props}) => {
  const classes = useStyles();
  // const [openPopOver, setOpenPopover] = useState(null);

  return (

    <Tooltip title={tooltip} arrow placement={placement}
      enterDelay={50}
      // componentsProps={{
      //   tooltip: {
      //     sx: {
      //       bgcolor: 'white',
      //       color: 'black',
      //       '& .MuiTooltip-arrow': {
      //         color: 'white',
      //       },
      //     }
      //   }
      // }}
    >
      {
        iconButton
          ? 
            <IconButton
              id={ id? id: 'styled-icon-btn_'}
              data-testid="StyledButton"
              size={size}
              className={classes?.StyledButton}
              variant={variant}
              // onMouseEnter={(e)=>{ setOpenPopover(e.currentTarget)}}
              // onMouseLeave={(e)=>{ setOpenPopover(null)}}
              {...props}
            >
              {children}
            </IconButton>
          :
            <Button
              id={id?id:'styled-btn_'} 
              data-testid='StyledButton'
              className={classes?.StyledButton}
              color={color} 
              size={size}
              variant={variant}
              {...props}
            >
              {children}
            </Button>
      }
    </Tooltip>
  )
};

StyledButton.propTypes = {};

StyledButton.defaultProps = {};

export default StyledButton;
