/***
 *
 * Controller class for user.
 * @file StyledSpinner.js
 * @description StyledSpinner component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './StyledSpinner.style.js';
import './StyledSpinner.scss';
import { Box } from '@mui/material';

const StyledSpinner = ({type, size, ...props}) => {
  const classes = useStyles()

  return ((() => {
    switch (type) {
      case "pageLoader":
        return (<Box className="no-freeze-spinner" data-testid="StyledSpinner" {...props}>
                  <Box id="no-freeze-spinner">
                    <Box>
                      <i className="material-icons">
                        lightbulb
                      </i>
                      <i className="material-icons">
                        router
                      </i>
                      <i className="material-icons">
                        camera_outdoor
                      </i>
                      <Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>)
      case "line":
        return <Box className="spinner-3" {...props}>
          <Box className="bounce1" style={{width: size, height: size}}></Box>
          <Box className="bounce2" style={{width: size, height: size}}></Box>
          <Box className="bounce3" style={{width: size, height: size}}></Box>
      </Box>;
      default:
        return <Box className="sk-chase" style={{width: size, height: size}} {...props}>
            <Box className="sk-chase-dot"></Box>
            <Box className="sk-chase-dot"></Box>
            <Box className="sk-chase-dot"></Box>
            <Box className="sk-chase-dot"></Box>
            <Box className="sk-chase-dot"></Box>
            <Box className="sk-chase-dot"></Box>
        </Box>;
    }
  })())
};

StyledSpinner.propTypes = {};

StyledSpinner.defaultProps = {};

export default StyledSpinner;
