export const rotateKeyTypes={
    GENERATE_KEY_REQUEST: 'GENERATE_KEY_REQUEST',
    GENERATE_KEY_SUCCESS: 'GENERATE_KEY_SUCCESS',
    GENERATE_KEY_FAILURE: 'GENERATE_KEY_FAILURE',

    ROTATE_KEY_REQUEST: 'ROTATE_KEY_REQUEST',
    ROTATE_KEY_SUCCESS: 'ROTATE_KEY_SUCCESS',
    ROTATE_KEY_FAILURE: 'ROTATE_KEY_FAILURE',

    GET_BLE_KEYS_REQUEST: 'GET_BLE_KEYS_REQUEST',
    GET_BLE_KEYS_SUCCESS: 'GET_BLE_KEYS_SUCCESS',
    GET_BLE_KEYS_FAILURE: 'GET_BLE_KEYS_FAILURE',
    

}