/***
 *
 * Controller class for user.
 * @file DeviceList.js
 * @description DeviceList component
 * @author Ayesha Kumar
 * @since 18 July 2022
 *
 */

import React, { useEffect, useState } from "react";
// import PropTypes from 'prop-types';
import "./DeviceList.scss";

import { Box } from "@mui/material";
import PageHeader from "../PageHeader";
import StyledTabs from "../StyledTabs";
import Lighting from "../../assets/icons/bulb.svg";
import Sensor from "../../assets/icons/sensor.svg";
import Switch from "../../assets/icons/switch.svg";
import Receiver from "../../assets/icons/router.svg";
import Gateway from "../../assets/icons/singleantenna.svg";
import Aggregator from "../../assets/icons/doubleantenna.svg";
import Controller from "../../assets/icons/controller.svg";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { deviceActions } from "../../redux/actions";
import { formatString, subscribe, unsubscribeAll } from "../../services";
import useTable from "../../hooks/useTable";
import DataNotFound from "../../pages/DataNotFound";
import ListView from "../../assets/icons/list-view.svg";
import CardView from "../../assets/icons/card-view.svg";
import ImageWrapper from "../ImageWrapper";
import GridView from "../GridView/index"
import StyledPopup from "../StyledPopup";
import CustomTable from "../CustomTable";
import { tenantName } from "../../config/appConfig";
import StyledButton from "../StyledButton";
import StyledPopupWrapper from "../StyledPopupWrapper";
import TemperatureSlider from "../TemperatureSlider";
import DeviceSettings from "../DeviceSettings";
import CustomCard from "../CustomCard";
import { getMessageStr, setSessionItem } from "../../helpers";
import CameraIcon from '../../assets/icons/camera.png'


const tabs = [
  { label: "Gateways", type: "VortexGateway", category: "VortexGateway", icon: Gateway, id: 0 },
  { label: "Controllers", type: "controllerList", category: "controller", icon: Controller, id: 1 },
  { label: "Aggregators", type: "aggregatorList", category: "aggregator", icon: Aggregator, id: 2 },
  { label: "Lighting", type: "lightdriver", category: "lightdriver", icon: Lighting, id: 3 },
  { label: "Receivers", type: "receiverList", category: "Receiver", icon: Receiver, id: 4 },
  { label: "Sensors", type: "sensorList", category: "Sensor", icon: Sensor, id: 5 },
  // { label: "AQ Sensors", type: "AQSensor", category: "ODMSENSORHUB", icon: Sensor, id: 6 },
  { label: "Switches", type: "switchList", category: "Switch", icon: Switch, id: 6 },
];


const DeviceList = ({ mqttClient }) => {
  const selectedSpace = useSelector((state) => state?.spaces?.selectedSpace);
  const deviceList = useSelector((state) => state?.devices?.deviceList);
  const gatewayList = useSelector((state) => state?.devices?.gatewayList);
  const _getBreadCrumbs = useSelector(state => state?.spaces?.breadcrumbsData);

  const [dialogContents, setDialogContents] = useState(null);
  const [dialog, setDialog] = useState(false);
  const [dialogWrapperContents, setDialogWrapperContents] = useState(null);
  const [dialogWrapper, setDialogWrapper] = useState(false);
  const [tooltip, setTooltip] = useState(null);
  const [view, setView] = useState("card");
  const [table, setTable] = useState({ header: {}, data: [] });
  const [loader, setLoader] = useState(false);
  const [tempLoader, setTempLoader] = useState(false)

  const cameraList = [
    {
      "deviceId": "OOgG",
      "gwDeviceId": "NfnN",
      "spaceId": "mbCg",
      "name": "Camera-01",
      "modelNumber": "DS-2CV2Q21FD-IW",
      "serialNumber": "",
      "macAddress": "",
      "ipAddress": "10.2.3.4",
      "modelId": "810120",
      "category": "CAMERA",
      "brand": "PSN",
      "connectionType": "wired",
      "mqttTopics": {
        "control": "psn/OOgG/control/AnalogLightDriver/kDH8/jhqv/tmQ7/mbCg",
        "status": "psn/OOgG/status/AnalogLightDriver/kDH8/jhqv/tmQ7/mbCg",
        "connectionStatus": "psn/OOgG/connectionstatus/AnalogLightDriver/kDH8/jhqv/tmQ7/mbCg"
      },
      "configuration": {
        "lightId": "L07"
      },
      "sync": true,
      "deviceStatus": {
        "status": {
          "intensity": 0,
          "ts": 1717152355585
        },
        "lastUpdatedOn": "2024-05-31T10:45:57.219Z"
      },
      "status": "active",
      "createdOn": 1714733738897,
      "createdBy": "",
      "lastUpdatedOn": 1714733738896,
      "lastUpdatedBy": ""
    }
  ]

  const dispatch = useDispatch();

  const [searching] = useState(false);
  const [devicesData, setDevicesData] = useState(null);
  const [selectDevice, setSelectDevice] = useState({ label: "Gateways", type: "VortexGateway", category: "VortexGateway", icon: Gateway, id: 0 });
  // const [loader, setLoader] = useState(false);
  // const [snackBar, setSnackBar] = useState({ show: false });

  let { columns } = useTable({
    gatewayDetails: gatewayList,//
    controller: devicesData,//
    setDialog,
    setDialogContents,
    setDialogWrapper,
    setDialogWrapperContents,
    mqttClient
  });


  const lastActionOnDevice = useSelector((state) => state?.devices?.lastAction);

  useEffect(() => {
    return () => {
      unsubscribeAll(mqttClient, dispatch)
    }
  }, [mqttClient]);


  useEffect(() => {
    if (!_.isEmpty(mqttClient) && !_.isEmpty(_getBreadCrumbs)) {
      setTimeout(() => {
        subscribe([`${tenantName}/+/status/DaliLightDriver/${_getBreadCrumbs.map(bd => bd?.id).join('/')}`,
        `${tenantName}/+/status/AnalogLightDriver/${_getBreadCrumbs.map(bd => bd?.id).join('/')}`,
        `${tenantName}/+/status/RelayLightDriver/${_getBreadCrumbs.map(bd => bd?.id).join('/')}`],
          dispatch,
          mqttClient,
          "lightControl");
        subscribe(`${tenantName}/+/connectionstatus/+/${_getBreadCrumbs.map(bd => bd?.id).join('/')}`,
          dispatch,
          mqttClient,
          "connectionStatus");
      }, 100)
    }
  }, [_getBreadCrumbs]);
  useEffect(() => {
    if (selectedSpace) {
      // setLoader(true);
      if (selectDevice?.category) {
        if (selectDevice?.category === 'controller' || selectDevice?.category === 'lightdriver' || selectDevice?.category === 'aggregator') {
          dispatch(deviceActions.getDevicesBySpaceId(selectedSpace?.spaceId, "", selectDevice?.category))
        }
        else if (selectDevice?.category === "camera") {
          setDevicesData(cameraList);
          setTable({
            header: columns[selectDevice?.type]["headerList"],
            data: cameraList
          });
          setTimeout(() => {
            setSessionItem("hideDataNotFound", false)
          }, 1000)
        }
        else
          dispatch(deviceActions.getDevicesBySpaceId(selectedSpace?.spaceId, selectDevice?.category, selectDevice?.category))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSpace, selectDevice?.category, dispatch]);


  useEffect(() => {
    if (Array.isArray(deviceList)) {
      let filteredDeviceList = deviceList?.filter(d => d?.spaceId === selectedSpace?.spaceId);//filtering as redux can have device of different space after move device action
      if (
        selectDevice?.category === "controller" ||
        selectDevice?.category === "lightdriver" ||
        selectDevice?.category === "aggregator"
      ) {
        let dList = filteredDeviceList.filter(
          (d) => d?.category?.toLowerCase().search(selectDevice?.category) >= 0
        );
        setDevicesData([...dList]);
        setTable({
          header: columns[selectDevice?.type]["headerList"],
          data: [...dList]
        });
      } else {
        setDevicesData(filteredDeviceList);
        setTable({
          header: columns[selectDevice?.type]["headerList"],
          data: filteredDeviceList
        });
      }
    }
    // setTimeout(()=>{setLoader(false)},2000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastActionOnDevice]);

  const handleSelectDevices = (device) => {
    // setLoader(true);
    const selectedTab = tabs?.find(t => t?.id === device)
    setSelectDevice(selectedTab);
  };

  return (
    <Box className="DeviceList" data-testid="DeviceList">
      <PageHeader
        backbtn={false}
        title={"Devices"}
        icon={Lighting}
      >
        <Box sx={{ textAlign: "right" }}>
          <StyledButton
            iconButton
            tooltip={view === "card" ? "List View" : "Card View"}
            onClick={(e) => {
              setView(view === "card" ? "list" : "card");
            }}
          >
            <ImageWrapper src={view === "list" ? CardView : ListView}></ImageWrapper>
          </StyledButton>
        </Box>
      </PageHeader>
      <Box style={!searching ? { paddingBottom: "15px" } : {}} sx={{ py: 1 }}>
        <StyledTabs
          tabs={tabs}
          onClick={(e, value) => {
            handleSelectDevices(value);
          }}
          sx={{ mb: 1 }}
        />
      </Box>
      <DataNotFound
        show={table?.data?.length > 0 ? false : true}
        customsx={{ image: { height: '202px', width: '100%' }, label: {}, content: {}, btns: {} }}
        data={{
          content: "There are no devices in the selected space, please select another space.",
          action: []
        }}
      >
        <Box>
          {/* Spinner while waiting for API response*/}
          {/* {loader &&
            <Box sx={{ position: 'absolute', top: 0, bottom: 0, width: "100%", backgroundColor: "rgba(255, 255, 255, 0.95)", zIndex: 1011 }}>
              <StyledSpinner sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
            </Box>
          } */}
          {
            view === "list" ?
              //list view for devices
              <Box className="Table">
                <CustomTable
                  {...columns[selectDevice?.type]}
                  cellMatrix={table?.data}
                  headerList={table?.header}
                  mqttClient={mqttClient}
                  searching={{ reset: true, columns: ["name", "modelNumber"] }}
                  pagination={true}
                  enableLoading={true}
                  rowIdentifier={table?.data[0]?.deviceId && "deviceId"}
                // loader={loader}
                />
              </Box>
              :
              //card view for device
              <GridView data={table?.data}
                card={({ data, ...props }) => <CustomCard
                  data={data}
                  icon={<ImageWrapper height={24} width={24} src={selectDevice?.icon} />}
                  header={table?.header?.name?.component({ data, cardView: true }, {
                    "fontSize": "1rem",
                    "& .link": {
                      "fontSize": "0.938rem"
                    }
                  })}
                  cardStatus={{ status: data?.status, label: formatString(data?.status, "capitalize"), tooltip: getMessageStr(`device-${data?.status}-tooltip`) }}
                  status={(() => {
                    const status = []
                    table?.header?.sync && status?.push({ content: table?.header?.sync?.component({ data }) });
                    table?.header?.connectionType && status?.push({ content: table?.header?.connectionType?.component({ data }) })
                    table?.header?.connectionStatus && status?.push({ content: table?.header?.connectionStatus?.component({ data }) })
                    return status;
                  })()}
                  actions={[table?.header?.userActions?.actions?.find(a => a?.type === "viewDetails" && (typeof a?.hidden === "function" ? !a?.hidden(data) : !a?.hidden))]}
                  menuActions={table?.header?.userActions?.actions?.filter(a => (typeof a?.hidden === "function" ? !a?.hidden(data, true) : !a?.hidden) && a?.type !== "viewDetails")}
                  contentHeaders={(() => {
                    const headers = { ...table?.header };
                    delete headers?.userActions;
                    delete headers?.name;
                    delete headers?.sync;
                    delete headers?.connectionType;
                    delete headers?.status;
                    delete headers?.connectionStatus;
                    return headers;
                  })()}></CustomCard>


                  // <StyledCardWrapper sx={{ borderLeft: data?.status === "active" ? "3px green solid" : "3px red solid" }}>
                  //   <DeviceCard deviceData={data}
                  //     headerProp={"name"}
                  //     contentHeaders={table?.header}
                  //     mqttClient={mqttClient}
                  //     deviceIcon={selectDevice?.icon}
                  //     setDialog={setDialogWrapper}
                  //     setDialogContents={setDialogWrapperContents}
                  //     {...props} />
                  // </StyledCardWrapper>
                }>
              </GridView>
          }
        </Box>
      </DataNotFound >
      <StyledPopup
        open={dialog}
        onClose={() => { setDialog(false) }}
        state={dialogContents?.state}
        data={dialogContents?.content}
        component={dialogContents?.component}
        customsx={{
          label: {
            color: "red",
            fontWeight: 700,
            fontSize: "0.9375rem",
            paddingBottom: "16px",
          },
          btns: { display: "flex", justifyContent: "center" },
        }}
      />
      <StyledPopupWrapper
        maxWidth="lg"
        title={dialogWrapperContents?.title}
        open={dialogWrapper}
        onClose={() => { setDialogWrapper(false); setDialogWrapperContents(null); }}
        closeButton={true}
      >
        {
          dialogWrapperContents?.content === "config" ?
            <DeviceSettings data={dialogWrapperContents?.deviceData} saveAction={() => { setDialogWrapper(false) }} />
            :
            <TemperatureSlider
              data={dialogWrapperContents?.deviceData}
              params={{ icon: true, label: true, value: true, compo: true }}
              label="Color Temperature (K)"
              mqttClient={mqttClient}
              showLoader={true}
              customsx={{ loader: { zIndex: 1200 }, compo: { minWidth: "200px" } }}
              subscribe={false}
            />
        }
      </StyledPopupWrapper>
    </Box >
  );
};

DeviceList.propTypes = {};

DeviceList.defaultProps = {};

export default DeviceList;
