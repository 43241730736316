import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    StyledDateTimePicker: {
        '& .MuiFormLabel-root': {
            display: 'none'
        },
        '& .MuiInputBase-formControl': {
            '& .MuiOutlinedInput-input': {
                // padding: theme?.padding?.input || "8px 0px 8px 15px !important",
                padding: "8px 0px 8px 15px !important",
                fontSize: theme?.fontSize?.input || "0.875rem",
                lineHeight: theme?.lineHeight?.input || "18px",
                height: theme?.height?.input || "24px",
            },
            '& .MuiInputAdornment-root': {
                marginLeft: 0
            },
            '& fieldset': {
                top: '0px',
                '& legend': {
                    display: 'none'
                }
            }
        },
        '& .MuiTextField-root': {
            width: '100%'
        }        
    }
}));
