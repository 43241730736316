/***
 *
 * Controller class for user.
 * @file ItemView.js
 * @description ItemView component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './ItemView.style.js';
import './ItemView.scss';
import { Box, Grid } from '@mui/material';
import CustomTypography from '../CustomTypography/index.js';

const ItemView = ({ data = {}, headers = {}, gridSize={md:3, xxl:2, sm:6, xs:12}, customsx={}  }) => {
  const classes = useStyles()
  return (
    <Grid container item xs={12} spacing={1} className={classes?.ItemView || ""} sx={customsx} data-testid="ItemView">
      {data &&
        Object.keys(headers)?.map((key, index) => {
          return (<Grid item xs={12} sm={6} md={3} xxl={2} {...gridSize} sx={{ mr: 0, mt: 2 }} key={index}>
            <Box className="">
              <CustomTypography
                weight={600}
                color={"#000"}
                size={13}
                lineHeight={19}
                styles={{ textTransform: "uppercase" }}
                content={headers[key]["label"]}
              />
              {
                headers[key]?.type === "component" ?
                   headers[key]?.component
                  :
                  <CustomTypography
                    weight={400}
                    color={"#000"}
                    size={13}
                    lineHeight={19}
                  >
                    {headers[key].hasOwnProperty("content") ? (headers[key]?.content === true || headers[key]?.content === false) ? JSON?.stringify(headers[key]?.content) : headers[key]?.content : data[key]}
                  </CustomTypography>
              }
            </Box>
          </Grid>
          )
        })}
    </Grid>
  )
};

ItemView.propTypes = {};

ItemView.defaultProps = {};

export default ItemView;
