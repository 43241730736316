import { API_URL } from "../config/appConfig";
import { handleResponse } from "../helpers";
import { API } from "./api.service";

const upgradeFirmware = (deviceIds = [], gwDeviceId = "", firmwareId = "") => {
    const params = {
        deviceIds,
        "fwId": firmwareId
    }
    if (gwDeviceId !== "") {
        params["gwDeviceId"] = gwDeviceId
    }
    return API.put(
        `${API_URL.device}/firmware/upgrade`,
        `/firmware/upgrade`,
        params
    ).then((res) => {
        return handleResponse(res, {});
    });
};
const getNextApplicableFirmwares = (params) => {
    return API.get(
        `${API_URL.device}/firmware`,
        `nextApplicableFirmwares`,
        params
    ).then((res) => {
        return handleResponse(res, true);
    });
};

const getFirmwareDistribution = (params, tenantId = null) => {
    return API.get(
        `${API_URL.device}/firmware/distribution`,
        `/firmware/distribution`,
        params,
        {},
        tenantId === null ? {} : { headers: { tenantId } }

    ).then((res) => {
        return handleResponse(res, true);
    });
};

const getFirmwareList = (params,) => {
    return API.get(
        `${API_URL.support}/firmware`,
        `/firmware`,
        params, {}, { headers: { tenantId: "" } }).then((res) => {
            return handleResponse(res, true);
        });
};

const deleteFirmware = (fwId) => {
    return API.delete(
        `${API_URL.support}/firmware/${fwId}`,
        `deleteFirmware/${fwId}`,
        {}
    ).then((res) => {
        return handleResponse(res, true);
    });
};

export const fotaService = {
    upgradeFirmware,
    getNextApplicableFirmwares,
    getFirmwareDistribution,
    getFirmwareList,
    deleteFirmware
}