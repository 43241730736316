import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    StyledSelectTextField: {
        "& .MuiFormControl-root": {
            display:'flex',
            flexDirection:'row'
        }      
    },
     CustomDropDown: {
        width: "auto",
        minWidth: "max-content",
        maxWidth: "max-content",
        // '&.MuiOutlinedInput-root': {
            '& .MuiOutlinedInput-input': {
                padding: theme?.padding?.select || "8px 10px",
                fontSize: theme?.fontSize?.select || "0.875rem",
                lineHeight: theme?.lineHeight?.select || "18px",
                height: theme?.height?.select || "24px",
                display:'flex',
                alignItems:'center',
            },
            '& .MuiOutlinedInput-input.MuiSelect-select': {
                height: theme?.height?.select || "24px",
            },
            '& fieldset': {
                top: 0,
                '& legend': {
                    display: "none"
                }
            },
            '& svg': {
                width: "1.25em",
                height: "1.25em",
                top: "initial"
            }
        // }
        ,
        '& .MuiFormHelperText-root': {
            marginLeft: 0
        }
    }
}));

export const sx = {
    select: {
    },
    menu: {
        padding: 0
    },
    paper: {
        boxSizing: 'content-box',
        background: '#FFFFFF',
        border: '1px solid #D9D9D9',
        boxShadow: '0px 2px 25px rgba(0, 0, 0, 0.07)',
        borderRadius: '0px 0px 0px 0px',

    },
    input: {
        background: 'none',
        border: 'none',
        borderRadius: '0',
        color: 'rgba(0, 0, 0, 0.8)',
        fontWeight: 400,
        fontSize: '0.875rem',
        lineHeight: '17px',
    },
    menuItem: {
        fontWeight: 400,
        fontSize: '0.875rem',
        lineHeight: '19px',
        color: 'rgba(0, 0, 0, 0.8)',
        padding: '6px 16px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.05)'
    }
}
