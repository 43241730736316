/***
 *
 * Controller class for user.
 * @file TenantList.js
 * @description TenantList component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 *
 */

import React from "react";
// import PropTypes from 'prop-types';
import { useStyles } from "./TenantList.style.js";
import "./TenantList.scss";
import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import CustomTable from "../CustomTable";
import PageHeader from "../PageHeader";
import { history } from "../../helpers";
import { tenantActions } from "../../redux/actions";
import _ from "lodash";
import usersIcon from "../../assets/icons/users.svg";
import CardView from "../../assets/icons/card-view.svg";
import ListView from "../../assets/icons/list-view.svg";
import { getMessageStr } from "../../helpers";
import DataNotFound from "../../pages/DataNotFound/index.js";
import ImageWrapper from "../ImageWrapper/index.js";
import TenantCard from "../TenantCard/index.js";
import GridView from '../../components/GridView/index.js';
import logo from '../../assets/logo/panasonic.png';
import shareWeb from "../../assets/icons/web.svg";
import view from "../../assets/icons/view.svg";
import StyledButton from "../StyledButton/index.js";
import CustomCard from "../CustomCard/index.js";
import { Link } from 'react-router-dom';
import { formatString } from "../../services/util.service.js";

const TenantList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const _tenants = useSelector((state) => state?.tenants.list) || null;
  const accessToken = JSON.parse(localStorage.getItem('miraie'))?.accessToken;
  const [tenantData, setTenantData] = useState(_tenants);
  const [currentView, setCurrentView] = useState("card");

  const handleWeb = (url) => {
    window.open(url, '_blank')
  }

  const headers = {
    multiKeys: {
      label: "Tenant Name",
      image: {
        key: "tenantName",
        type: "avatar",
        url: `/tenants?op=view`,
        urlParams: { name: "tenantName" },
        style: { width: "2rem", height: "2rem", fontSize: "0.9rem", padding: "7px", marginRight: "7px" }
      },
      tenantName: {
        type: "link",
        url: `/tenants?op=view`,
        urlParams: { name: "tenantName" },
      },
      sortKey: "tenantName",
    },
    corporationName: {
      label: "Corporation Name",
    },
    adminEmail: { label: "Admin email" },
    mobile: { label: "Admin contact" },
    // license:{ label:"License type","" },
    userActions: {
      label: "Actions",
      actions: [
        {
          label: "View Details",
          type: "view",
          icon: view,
          onClick: (event, data) => {
            history.push(`/tenants?op=view&name=${data?.tenantName}`)
          },
        },
        {
          label: "Portal Link",
          type: "share",
          icon: shareWeb,
          onClick: (event, data) => {
            handleWeb(`${data?.dns}?cid=${accessToken}` || "")
          },
        },
      ],
      style: { textAlign: "right" },
    },
  }

  useEffect(() => {
    dispatch(tenantActions.getAllTenants());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!_.isEmpty(_tenants)) {
      const tenantPayload = _tenants?.map((tenant) => {
        let payload = {
          ...tenant,
          adminEmail: tenant?.contacts?.adminEmail || "",
          dns: tenant?.tenantFQDNExternal?.web || "",
          mobile: tenant?.contacts?.adminPhone || "",
          logo: tenant?.webApp?.logoBitmap || logo
        };
        return payload;
      });
      setTenantData(tenantPayload);
    }
  }, [_tenants]);

  return (
    <Box className={classes?.TenantList || ""} data-testid="TenantList">
      <DataNotFound
        show={_.isEmpty(tenantData) ? true : false}
        customsx={{
          image: { height: "202px", width: "100%" },
          label: {},
          content: {},
          btns: {},
        }}
        data={{
          label: "Tenants",
          content: getMessageStr("tenant-get-noTenantFound") || "",
          action: [
            {
              id: "1001",
              type: "button",
              label: "Create Tenant",
              onClick: (event, data) => {
                history.push("/tenants?op=create");
              },
            },
          ],
        }}
      >
        <>
          <PageHeader title={`Tenants`} pageHeaderStyle={{ padding: '8px 0px 8px 8px' }} icon={usersIcon}>
            <Box>
              {
                <StyledButton iconButton onClick={(e) => setCurrentView(currentView === 'card' ? "list" : "card")} tooltip={currentView === 'card' ? "List View" : "Card View"}>
                  <ImageWrapper
                    src={currentView === 'card' ? ListView : CardView}
                  />
                </StyledButton>}
              <StyledButton color='primary' size="medium" sx={{ mr: 0 }} onClick={(event) => history.push("/tenants?op=create")}>Add Tenant</StyledButton>
            </Box>
          </PageHeader>
          <Box>
            {currentView === "card" ? (

              <Box sx={{ width: "100%", p: 1 }}>
                {/* <GridView
                  data={tenantData}
                  card={({ data, ...props }) => (
                    <TenantCard data={data}/>
                    )}
                  ></GridView> */}
                <GridView data={tenantData}
                  cardsPerRow={{ lg: 6 }}
                  card={({ data, ...props }) => <CustomCard
                    data={data}
                    icon={<ImageWrapper height={16} width={"auto"} src={data?.logo} />}
                    // icon={data?.logo}
                    header={<Link className="link" to={`/tenants?op=${data?.op || "view"}&name=${data?.tenantName}`}
                      style={{
                        fontSize: "1rem",
                        // fontWeight: "600",
                        // color: "rgba(15, 141, 72, 1)",
                        lineHeight: 1
                      }}
                    >{data?.tenantName || ""}</Link>}
                    cardStatus={{ status: data?.enabled ? "active" : "inactive", label: data?.enabled ? "Enabled" : "Disabled", tooltip: getMessageStr(`tenant-tooltip-${data?.enabled ? "enabled" : "disabled"}`) }}
                    actions={headers?.userActions?.actions}
                    contentHeaders={(() => {
                      const header = { ...headers };
                      delete header?.userActions;
                      delete header?.multiKeys;
                      return header;
                    })()}></CustomCard>
                  }>
                </GridView>
              </Box>
            ) : (
              <CustomTable
                headerList={headers}
                cellMatrix={tenantData}
                pagination={true}
                searching={true}
              ></CustomTable>
            )}
          </Box>
        </>
      </DataNotFound>
    </Box>
  );
};

TenantList.propTypes = {};

TenantList.defaultProps = {};

export default TenantList;
