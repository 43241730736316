import { API_URL } from "../config/appConfig";
import { handleResponse } from "../helpers";
import { API } from "./api.service";

const getAllSpaces = () => {
    return API.get(`${API_URL.space}/spaces`, "get/spaces", {}).then(handleResponse).then(data => {
        // console.log("dataaaaaaaaaaaaaa -- ", data)
        if (Array.isArray(data)) {
            return data && data?.map(d => {
                return { ...d, "isRootSpace": true };
            })
        } else {
            return data;
        }
    });
}
const getSpaceById = (spaceId, depth = 1) => {
    // console.log("getSpaceById in service ---- ", spaceId)
    return API.get(`${API_URL.space}/spaces/${spaceId}/child?depth=${depth}`, `getById/spaces/${spaceId}`, {}).then(handleResponse);
}

const addSpace = (parentId, payload) => {
    // console.log("addSpace in service ---- ")
    return API.post(`${API_URL.space}/spaces/${parentId}`, `add/spaces/${parentId}`, payload).then((res) => handleResponse(res, true));
}

const updateSpace = (spaceId, payload) => {
    // console.log("updateSpace in service ---- ")
    return API.put(`${API_URL.space}/spaces/${spaceId}`, `update/spaces/${spaceId}`, payload).then(res => handleResponse(res, true));
}

const deleteSpace = (spaceId) => {
    // console.log("deleteSpace in service ---- ")
    return API.delete(`${API_URL.space}/spaces/${spaceId}`, `delete/spaces/${spaceId}`, {}).then((res) => handleResponse(res, true));
}

const addSpaceAsset = (spaceId, payload) => {
    let formData = new FormData();
    formData.append("File", payload.image);
    // formData.append('type', payload?.type);
    // formData.append('Mime', payload?.mime);
    // console.log("space assets ---- ", formData, payload)

    // return API.asset(`${API_URL.space}/spaces/${spaceId}/asset?type=layout&mime=${payload?.mime? payload?.mime:"test"}`,`addSpaceAsset`, formData , {headers:{ 'Content-Type': 'multipart/form-data' }}).then(handleResponse);
    return API.asset(`${API_URL.space}/spaces/assets?type=layout&mime=${payload?.mime}&spaceIds=${spaceId}`, `addSpaceAsset`, formData, { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${JSON.parse(localStorage.getItem('miraie'))?.accessToken}` } }).then((res) => handleResponse(res, true));

}



const getAllAsset = (spaceId) => {
    return API.get(`${API_URL.space}/spaces/assets?spaceId=${spaceId}`, `getAllSpaceAsset`, {}).then(res=>handleResponse(res,true));
}

const getGivenAsset = (assetId) => {
    return API.get(`${API_URL.space}/spaces/assets/${assetId}`, `getGivenAsset`, {}).then(res=>handleResponse(res,true));
}

const updateSpaceAsset = (spaceId, assetId, payload) => {
    const formData = new FormData();
    formData.append("File", payload?.image);
    return API.asset(`${API_URL.space}/spaces/assets/${assetId}?type=layout&mime=png`, `updateSpaceAsset`, formData, { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${JSON.parse(localStorage.getItem('miraie'))?.accessToken}` } }, 'PUT').then(res=>handleResponse(res,true));
}




const deleteSpaceAsset = (spaceId) => {
    // console.log("space assets ---- ", formData, payload)

    return API.delete(`${API_URL.space}/spaces/${spaceId}`, `deleteSpaceAsset`, {}).then((res) => handleResponse(res, true));
}

// const updateSpaceConfigurationAsset = (spaceId,assetId, payload) => {     
//     // console.log("update config assets ---- ",  payload)

//     return API.updateAssetConfig(`${API_URL.space}/spaces/${spaceId}/asset/${assetId}/config`,`updateSpaceConfigAsset`, payload).then((res)=>handleResponse(res,true));
// }

const addUpdateSpaceConfigs = (assetId, payload) => {
    // console.log("update config assets ---- ", payload)

    return API.put(`${API_URL.space}/spaces/assets/${assetId}/config`, `updateSpaceConfigAsset`, payload).then(res=>handleResponse(res,true));
}

const addUpdateSpaceEntities = (assetId, payload) => {
    // console.log("update config assets ---- ",  payload)

    return API.put(`${API_URL.space}/spaces/assets/${assetId}/entity`, `updateSpaceEntity`, payload).then((res) => handleResponse(res, true));
}

const getSpaceActivityLogs = (payload) => {
    return API.get(`${API_URL.support}/activities/space/${payload?.spaceId}${payload?.queryString !== undefined ? payload?.queryString : ''}`).then(handleResponse);
}

const getCurrentSpaceById = (spaceId) => {
    // console.log("getSpaceById in service ---- ", spaceId)
    return API.get(`${API_URL.space}/spaces/${spaceId}`, `getById/spaces/${spaceId}`, {}).then(handleResponse);
}


export const spaceService = {
    getAllSpaces,
    getSpaceById,
    addSpace,
    updateSpace,
    deleteSpace,
    addSpaceAsset,
    getAllAsset,
    getGivenAsset,
    updateSpaceAsset,
    deleteSpaceAsset,
    // updateSpaceConfigurationAsset,
    addUpdateSpaceConfigs,
    addUpdateSpaceEntities,
    getSpaceActivityLogs,
    getCurrentSpaceById
}
