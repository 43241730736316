/***
 *
 * Controller class for user.
 * @file StyledMultiSelect.js
 * @description StyledMultiSelect component
 * @author Naveen Kumar
 * @since 05 Dec 2022
 * 
 */

import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './StyledMultiSelect.style.js';
import './StyledMultiSelect.scss';
import { Box, Checkbox, FormControl, ListItemIcon, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material';
import ErrorMessage from '../ErrorMessage/index.js';
import StyledChip from '../StyledChip/index.js';

const StyledMultiSelect = ({value, id, name, MenuProps, options=[], width="100%", style, disabled=false, error=false, message="", delim=",", placeholder="Select", onChange = () => { }, operation}) => {
  const classes = useStyles()

  const [selected, setSelected] = useState(null);

  const isAllSelected = options?.length > 0 && selected?.length === options?.length;

    // const handleCheckBox = (event) => {
    //   const {
    //     target: { value },
    //   } = event;
      
    // };

    const handleChange = (event) => {
      const val = event?.target?.value;
      console.log(val);
      
      // if (val[val?.length - 1] === "all") {
      //   setSelected((selected?.length === options?.length ? [] : options));        
      //   onChange(event, (selected?.length === options?.length ? [] : options));
      //   return;
      // }
      setSelected(val);
      onChange(event, val);
      return;
    };

    useEffect(() => {
      setSelected(value);
    }, [value]);

    useEffect(() => {
      onChange({target: { name: name, value: selected }});
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name, selected]);
    
    return (
        <FormControl className={classes?.StyledMultiSelect || ""} data-testid="StyledMultiSelect" error={error}>
          {/* {JSON.stringify(selected)} */}
          <Select
            id={id || "multiple-checkbox"}
            name={name || "multiselect"}
            multiple
            disabled={disabled}
            value={selected ? selected : []}
            onChange={handleChange}
            input={<OutlinedInput label="State" />}
            // renderValue={(selected) => selected?selected?.join(delim):""}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.2 }}>
                {selected.map((value) => (
                    <StyledChip
                      key={"multiSelect----" + value}
                      variant="outlined"
                      sx={{ marginTop: "-3px", marginBottom: "3px" }}
                      style={{ ...style }}
                      label={value}
                    
                    />
                  
                  // <Chip key={value} label={value} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
            // placeholder={placeholder}
            style={{ ...style, width: width }}
          >
            {/* <MenuItem disabled value="">
              <ListItemText classes={{ primary: classes?.selectAllText }} primary={placeholder}/>
            </MenuItem> */}
            {/* <MenuItem value="all" classes={{ root: isAllSelected ? classes?.selectedAll : "" }}>
              <ListItemIcon>
                <Checkbox
                  classes={{ indeterminate: classes?.indeterminateColor }}
                  checked={isAllSelected}
                  indeterminate={
                    selected?.length > 0 && selected?.length < options?.length
                  }
                />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes?.selectAllText }}
                primary="Select All"
              />
            </MenuItem> */}
            {options && options?.map((option, index) => (
              <MenuItem key={"optttt-" + index} value={option}>
                <ListItemIcon>
                  <Checkbox checked={selected?.indexOf(option) > -1} />
                </ListItemIcon>
                <ListItemText primary={option} />
              </MenuItem>
            ))}
          </Select>
          {message && <ErrorMessage message={message} />}
        </FormControl>

    )
};

StyledMultiSelect.propTypes = {};

StyledMultiSelect.defaultProps = {};

export default StyledMultiSelect;
