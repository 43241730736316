import { makeStyles } from '@mui/styles';

export const customsx = {
    StyledChip: {
        margin: "10px", marginBottom: "10px", height: "24px !important", paddingRight: "3px !important",
        width:'min-content'
    }
}

export const useStyles = makeStyles({

});
