/***
 *
 * Controller class for user.
 * @file StyledTextArea.js
 * @description StyledTextArea component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './StyledTextArea.style.js';
import './StyledTextArea.scss';
import { TextareaAutosize } from '@mui/material';
import StyledTextfield from '../StyledTextfield/index.js';

const StyledTextArea = ({...props}) => {
  const classes = useStyles()

    return (
      <TextareaAutosize
        className={classes?.StyledTextArea || ""} 
        data-testid="StyledTextArea"
        renderInput={(params) => <StyledTextfield {...params} />}
        {...props}
      />
    )
};

StyledTextArea.propTypes = {};

StyledTextArea.defaultProps = {};

export default StyledTextArea;
