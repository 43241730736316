import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    SceneList: {

    },
    CustomImageWrapper: {
        width: '44px',
        padding: '12px',
        backgroundColor: 'white',
        marginLeft: '-6px',
        borderRadius: '50%',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08), 0px 4px 24px rgba(0, 0, 0, 0.08)'
    }
});
