import React, {
  useEffect,
  useRef,
  useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { appActions, userActions } from '../../redux/actions';
// import PropTypes from 'prop-types';
import './LoginPage.scss';
import frame from '../../assets/img/login-bg.png';
import Miraie from '../../assets/logo/logo-miraie.png';
import { Box, Grid, InputAdornment } from "@mui/material";
import StyledTextfield from '../../components/StyledTextfield';
import { ReactComponent as VisibilityOff } from '../../assets/icons/visibility-off.svg';
import { ReactComponent as VisibilityOn } from '../../assets/icons/visibility-on.svg';
import ImageWrapper from '../../components/ImageWrapper';
import TenantLogo from '../../assets/logo/panasonic.png';
import StyledSpinner from '../../components/StyledSpinner';
import Countdown from 'react-countdown';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CustomTypography from '../../components/CustomTypography';
import { getMessageStr, getSessionItem, setSessionItem, success, getAuthenticatedRoutes } from '../../helpers';
import routes from '../../routes/routes';
import { MFA_ENABLED, defaultAuthenticatedUrl } from '../../config/appConfig';
import { authenticationTypes } from '../../redux/types';
import StyledButton from '../../components/StyledButton';
import { history } from '../../helpers';


const LoginPage = () => {
  const dispatch = useDispatch();
  // const location = useLocation();
  const usernameRef = useRef();
  const pValueRef = useRef();

  const loggingIn = useSelector(state => state.authentication.loading);
  const _validateOTP = useSelector(state => state?.app?.validateOtp);
  const _authenticated = useSelector(state => state?.authentication?.loggedIn);
  const loggedInUser = useSelector(state => state?.authentication?.user);
  const _portal = useSelector((state) => state?.app?.portal);
  const _webConfig = useSelector(state => state?.app?.webConfig);
  const _clientId = useSelector(state => state?.app?.clientId);

  const [frm, setFrm] = useState({ username: '', pValue: '' });
  const [submitted, setSubmitted] = useState(false);
  const [pValueVisibility, setPValueVisibility] = useState(false);
  const [mfa,] = useState((MFA_ENABLED && MFA_ENABLED === "true") ? "email|mobile" : null);
  const [OTP, setOTP] = useState('');
  const [otpTimer, setOtpTimer] = useState(null);
  const [correctOTP, setCorrectOTP] = useState(false);
  const [timeOut, setTimeOutContent] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [page, setPage] = useState('login');
  const [preLoginError, setPreLoginError] = useState(_portal ? (((_portal === "mss" && _clientId) || (_portal === "tms" && _webConfig)) ? false : true) : true)

  const redirect = () => {
    getAuthenticatedRoutes(routes, _portal);
    const sharedUrl = JSON.parse(getSessionItem("miraie-shared-url") || "null");
    const sRoute = routes?.find(r => ("/" + r.path === sharedUrl?.pathname));
    dispatch(appActions.selectLeftMenu(sRoute?.parent ? sRoute?.parent : sRoute?.id));
    dispatch(appActions.selectTopMenu(sRoute?.parent ? sRoute?.id : sRoute?.parent));
    // const authenticatedUrl = sharedUrl && sharedUrl?.pathname !== '/' ? `${sharedUrl?.pathname}${sharedUrl?.search}` : (_portal=== 'tms'? "/tenants" : "/");//need to comment
    setSessionItem("miraie-logged-in-user", JSON.stringify(userDetails));
    window.location.href = defaultAuthenticatedUrl; // authenticatedUrl;
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setFrm(inputs => ({ ...inputs, [name]: value }));
    // if (name === "tenant") {
    //   setTenant(value);
    // }
    if (name === 'otp') {
      setOTP(e.target.value);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    setOTP('');
    if (frm?.username && frm?.pValue) {
      console.log("handleSubmit");
      // get return url from location state or default to home page
      // localStorage.clear();
      // sessionStorage.clear();
      dispatch(userActions.login({ username: frm?.username, password: frm?.pValue, mfa, portal: _portal }, (res) => {
        // console.log("authh ---- ", res, _portal)
        if (!res?.passwordResetRequired) {
          dispatch(userActions.getSelfProfile({ token: res?.accesstoken, portal: _portal }, (user) => {
            setSessionItem("miraie-selected-menu", JSON.stringify({ top: null, left: 1231 }))
            setUserDetails(user);
            if (mfa && user) {
              setPage("mfaOTP");
              // Validating OTP
              mfa.split("|")?.forEach(m => {
                if (user[`${m}Verified`]) {
                  dispatch(userActions.generateOTP('auth', { apiId: m, [m]: user[m], transactionType: 'OTPLogin' }, (otptimer) => {
                    setOtpTimer(otptimer?.validityInSecs);
                  }));
                }
              })
            }
            // else if (user){
            //   redirect();
            // }
          }))
        }
        else {
          history?.push('/resetPassword')
        }
      }));
    }
    // localStorage.setItem("tenant", tenantName);
  }

  const confirmOTP = () => {
    if (mfa) {
      setPage("mfaOTP");
      mfa.split("|")?.forEach(m => {
        dispatch(userActions.validateOTP('auth', { apiId: m, [m]: userDetails[m], transactionType: 'OTPLogin', otp: OTP }));
      })
    }
  }

  const TimeCounter = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      setOtpTimer(null);
      setTimeOutContent(true);
      return;
    } else {
      // Render a countdown
      return <CustomTypography content={`Resend OTP in ${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`} color="#33333380" size={16} weight={400} lineHeight={19.5} />
    }
  };

  useEffect(() => {
    setPreLoginError(_portal ? (((_portal === "mss" && _clientId) || (_portal === "tms" && _webConfig)) ? false : true) : true)
    // console.log("ayes", _portal, _clientId, _webConfig)
  }, [_portal, _webConfig, _clientId])

  useEffect(() => {
    if (_validateOTP) {
      localStorage.setItem('miraie', sessionStorage.getItem('tmp-miraie'));
      localStorage.setItem('user', sessionStorage.getItem('tmp-miraie-user'));
      setTimeout(() => {
        sessionStorage.removeItem('tmp-miraie');
        sessionStorage.removeItem('tmp-miraie-user');
      }, 500);
      dispatch(success(authenticationTypes.LOGIN_SUCCESS, { payload: { loggedIn: true } }));
    }
  }, [dispatch, _validateOTP])

  useEffect(() => {
    if (_authenticated) {
      setOTP(null);
      setCorrectOTP(true);
      setTimeOutContent(false);
      setOtpTimer(null);
      // dispatch(appActions.emptyAppState('VALIDATE_OTP_SUCCESS'));

      if (_portal === "tms") {
        setTimeout(() => {
          redirect();
        }, 1000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_authenticated])

  useEffect(() => {
    if (_authenticated && loggedInUser?.permissions) {
      setTimeout(() => {
        redirect();
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUser?.permissions])

  return (
    <Grid container sx={{ height: "100%" }}>
      <Grid item sm={6} style={{ display: "flex", justifyContent: "center", alignItems: "center", position: 'relative', backgroundImage: `url(${frame})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat", width: "100%", padding: "40px" }}>
        <ImageWrapper component="Image" src={Miraie} sx={{ maxWidth: { md: "300px", sm: "250px", xs: "200px" }, width: "-webkit-fill-available" }} alt='mirai-logo' />
      </Grid>
      <Grid container item sm={6} style={{ display: "flex", justifyContent: "center", alignItems: "center", background: '#ffffff', minHeight: "550px", overflow: "auto" }} justifyContent="center" alignItems={'center'}>
        <Grid container item xs={12} sx={{ minHeight: "calc(100% - 70px)", padding: "30px 15px" }}>
          {
            (() => {
              switch (page) {
                case 'login':
                  return (
                    <form name="form" onSubmit={handleSubmit} style={{ margin: "auto" }} >
                      <Grid container item flexDirection={'column'} justifyContent="center" alignItems={'flex-start'} width={{ md: '350px', sm: '300px', xs: '280px' }}>

                        <Box className="auth-heading">
                          <Box sx={{ mb: 3, width: "100%", textAlign: 'left' }}><ImageWrapper src={_portal === "mss" ? _webConfig?.webApp?.logoBitmap : TenantLogo} width={160} /></Box>
                          <Box className='login-subtitle'>Welcome</Box>
                          <Box className='login-title'>Login to your account</Box>
                        </Box>
                        <Box className="custom-form-group">
                          <label htmlFor="username-input" className="custom-form-control-label">User ID</label>
                          <StyledTextfield
                            fullWidth
                            hiddenLabel
                            ref={usernameRef}
                            defaultReadOnly={false}
                            autoFocus={true}
                            id="username-input"
                            name="username"
                            type="text"
                            value={frm?.username}
                            onChange={handleChange}
                          />
                          {submitted && !frm?.username &&
                            (<div className='login-error'>Username is required</div>)
                          }
                        </Box>
                        <Box className='custom-form-group' sx={{ mb: 1 }}>
                          <label className='custom-form-control-label'>Password</label>
                          <StyledTextfield
                            fullWidth
                            hiddenLabel
                            ref={pValueRef}
                            value={frm?.pValue}
                            defaultReadOnly={false}
                            onChange={handleChange}
                            type={!pValueVisibility ? "password" : 'text'}
                            name="pValue"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <StyledButton
                                    iconButton
                                    id="eye-icon"
                                    sx={{
                                      background: '#FFFFFF',
                                      borderRadius: '4px',
                                      marginRight: '-9px',
                                      padding: '5px'
                                    }}
                                    edge="end"
                                    aria-label="Toggle password visibility"
                                    onClick={() => setPValueVisibility(!pValueVisibility)}
                                  >
                                    {!pValueVisibility ? <VisibilityOff /> : <VisibilityOn />}
                                  </StyledButton>
                                </InputAdornment>
                              )
                            }}
                          />
                          {submitted && !frm?.pValue &&
                            (<div className="login-error">Password is required</div>)
                          }
                        </Box>
                        {/* <Grid item width={'100%'} mb={'11px'}>
                          <label htmlFor="tenant">Tenant</label>
                          <StyledTextfield id="tenant" fullWidth value={tenant} onChange={handleChange} name="tenant" type="text" />
                        </Grid> */}
                        <Grid item mb={'40px'} width={'100%'}>
                          {!preLoginError && _portal === "mss" && <Link to="forgot" underline="hover" className='forgot-password' >
                            Forgot Password?
                          </Link>
                          }
                          {/* <Link to="/tenantLogin" underline="hover" className='tenant-login'  > */}
                          {/* <span style={{float: 'right', cursor: 'pointer', color: "#0F8D48", fontSize: "0.8rem", textDecoration: "underline", marginTop:'3px'}} onClick={() => setPortal(portal==="mss"?'tms':'mss')}>Go to {portal==="mss"?'Tenant':'MSS'} login page?</span> */}
                          {/* </Link> */}
                        </Grid>
                        <Grid item width={'100%'}>
                          <StyledButton disabled={frm?.username && frm.pValue && !preLoginError ? false : true} color='primary' size="extra-large" sx={{ p: 1, height: "45px" }} fullWidth type='submit'>
                            {loggingIn &&
                              <StyledSpinner size={22} sx={{ '& .sk-chase-dot::before': { backgroundColor: '#fff' }, marginRight: "16px" }} />
                            }
                            Login
                          </StyledButton>
                          {preLoginError && <Box sx={{ mt: 1, color: "#EF0000" }}>{getMessageStr("preLogin-fail-401")}</Box>}
                        </Grid>
                      </Grid>
                    </form>
                  )
                case 'mfaOTP':
                  return (
                    <>
                      <Grid justifyContent="center" alignItems={'flex-start'} width={'350px'}>
                        <Grid item xs={12}>
                          <Box className="auth-heading">
                            <Box sx={{ mb: 3, width: "100%", textAlign: 'left' }}><ImageWrapper src={_portal === "mss" ? _webConfig?.webApp?.logoBitmap : TenantLogo} width={160} /></Box>
                            <Box className='login-subtitle'>OTP sent to registered Email and Mobile</Box>
                            <Box className='login-title'>Login to your account</Box>
                          </Box>
                          <Box className='custom-form-group'>
                            <Box display={'flex'} justifyContent='space-between' alignItems={'center'}>
                              <label className='custom-form-control-label'>OTP</label>
                              {
                                otpTimer !== null ? <Countdown
                                  date={Date.now() + otpTimer * 1000}
                                  renderer={TimeCounter}
                                />
                                  : <label onClick={() => { }} className="custom-form-control-label pointer" style={{ color: timeOut ? "#0F8D48" : '#33333380' }}>{timeOut ? 'Resend OTP' : ''}</label>
                              }
                            </Box>
                            <Box display={'flex'} flexDirection='column' position={'relative'}>
                              <StyledTextfield value={OTP} disabled={correctOTP} name="otp" sx={{ width: 'auto', minWidth: '350px' }} onChange={handleChange} placeholder="Enter..." />
                              {/* <StyledSpinner position={'absolute'} right="-50px" top="5px" /> */}
                              {correctOTP && <CheckOutlinedIcon sx={{ color: 'green', position: 'absolute', right: "-30px", top: "5px" }} />}
                            </Box>
                          </Box>
                        </Grid>

                        <Box >
                          <StyledButton disabled={OTP ? false : true} onClick={confirmOTP} color={OTP ? 'primary' : 'secondary'} sx={{ width: '67%', mr: 1, height: '40px' }}> Confirm </StyledButton>
                          <StyledButton sx={{ width: '28%', ml: 1, height: '40px' }} variant={'outlined'} onClick={() => { setPage('login'); setOTP('') }} fullWidth color='primary'>Back</StyledButton>
                        </Box>

                      </Grid>


                    </>
                  );
                default:
                  return (<></>);
              }
            })()
          }
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "60px" }}>
          <small>© 2024 - Panasonic Life Solutions India Pvt. Ltd. All rights reserved</small>
        </Grid>
      </Grid>
    </Grid>
  );
};

LoginPage.propTypes = {};

LoginPage.defaultProps = {};

export default LoginPage;
