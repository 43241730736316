/***
 *
 * Controller class for user.
 * @file PhysicalParamters.js
 * @description PhysicalParamters component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 *
 */

import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import {
  DropdownCss,
  useStyles,
  SliderCss,
} from "./PhysicalParamters.style.js";
import ControllerSlider from "../DeviceController/ControllerSlider";
import TempGreen from "../../assets/icons/temperature-2.svg";
import HourGlass from "../../assets/icons/hourglass.svg";
import Warning from "../../assets/icons/warning.svg";
import Repeat from "../../assets/icons/sync.svg";
import ControllerRangeSlider from "../DeviceController/ControllerRangeSlider";
import { DEVICE_PHYSICAL_CONSTANTS as data } from "../../constants/device.constants";
import Save from "../../assets/icons/save.svg";
import ControllerDropDown from "../DeviceController/ControllerDropDown";
import { useDispatch, useSelector } from "react-redux";
import ImageWrapper from "../ImageWrapper/index.js";
import "./PhysicalParamters.scss";
import CustomTypography from "../CustomTypography/index.js";
import { useEffect } from "react";
import { deviceActions } from "../../redux/actions/index.js";
import { isAuthorised } from "../../helpers/users.helper.js";
import { features, permissions } from "../../constants/permission.constants.js";
import StyledButton from "../StyledButton/index.js";
import { disableDeviceOperation } from "../../helpers/index.js";

const PhysicalParamters = ({ deviceData, saveAction = () => { }, ...props }) => {

  const deviceModel = useSelector((state) =>
    state.devices.deviceModels.find((d) => d.modelNumber?.substring(d.modelNumber?.length - 3, d.modelNumber?.length) === deviceData?.configuration?.daliDriver)
  );
  const dispatch = useDispatch();
  const classes = useStyles();
  const [valueData, setValueData] = useState({});


  //ayeshakumar 21 sep 2022
  const deviceConfiguration = (model) => {
    //ayesha-do not make props from device model case insensitive, otherwise cannot access data from deviceData
    const modelConfig = model?.configCapabilities;
    const config = {};
    Array.isArray(modelConfig) &&
      modelConfig?.map((c) => {
        let capability = c?.capability;
        if (capability === "fadeTime" || capability === "fadeRate") {
          const value = deviceData?.configuration
            ? deviceData?.configuration[capability]
            : c?.attributes[0]?.defaultValue;
          const option = data[capability].list.find((d) => d?.id === value);
          config[capability] = {
            value: { id: value, label: option?.label },
          };
        } else {
          config[capability] = {
            value: deviceData?.configuration ?
              deviceData?.configuration[capability] ? deviceData?.configuration[capability] : c?.attributes ? c?.attributes[0]?.defaultValue : null
              : null
          };
        }
      });
    const modelKelvin = model?.controlCapabilities?.find(
      (c) => c?.capability === "kelvin"
    );
    if (modelKelvin) {
      const kelvinValue =
        deviceData?.deviceStatus?.status?.kelvin ||
        modelKelvin?.attributes[0]?.defaultValue;
      const kelvinMax =
        deviceData?.configuration?.maxWarm ||
        config?.maxWarm?.value ||
        modelKelvin?.attributes[0]?.range?.max[0]?.value;
      const kelvinMin =
        deviceData?.configuration?.maxCool ||
        config?.maxCool?.value ||
        modelKelvin?.attributes[0]?.range?.min[0]?.value;
      config["kelvin"] = { value: kelvinValue, min: kelvinMin, max: kelvinMax };
    }
    if (config.maxWarm && config.maxCool) {
      config.minMaxRange = {
        value: [
          deviceData?.configuration?.maxCool || config?.maxCool?.value,
          deviceData?.configuration?.maxWarm || config?.maxWarm?.value,
        ],
      };
    }
    if (deviceData?.configuration?.hasOwnProperty("daliDriver")) {
      const driver = data["daliDriver"].list.find((d) => d?.id === deviceData?.configuration?.daliDriver);
      config.daliDriver = { value: driver };
    }
    if (config?.fadeRate || config?.fadeTime || config?.minPreset || config?.maxPreset || config?.powerFailLevel || config?.systemFailLevel) {
      config.configuration = true;
    }
    return config;
  };


  const syncLight = () => {
    dispatch(deviceActions.syncDevice(deviceData?.deviceId))
  }
  const updateConfig = () => {
    const payload = {
      minPreset: valueData?.minPreset?.value,
      maxPreset: valueData?.maxPreset?.value,
      systemFailLevel: valueData?.systemFailLevel?.value,
      powerFailLevel: valueData?.powerFailLevel?.value,
      maxCool: valueData?.minMaxRange?.value[0],
      maxWarm: valueData?.minMaxRange?.value[1],
    };
    if (valueData?.daliDriver) {
      payload.daliDriver = valueData?.daliDriver?.value?.id;
    }
    if (valueData?.fadeRate?.value?.id) {
      payload.fadeRate = valueData?.fadeRate?.value?.id
    }
    else if (valueData?.fadeTime?.value?.id) {
      payload.fadeTime = valueData?.fadeTime?.value?.id
    }
    dispatch(deviceActions.updateDeviceConfiguration(deviceData.deviceId, payload, "light"))
  };

  const handleChange = (event, value, key = "") => {
    // console.log("keys are==>", key, value);
    // if (key === "fadeRate") {
    //   setValueData({ ...valueData, [key]: { ...valueData?.key, value: value }, fadeTime: { ...valueData?.fadeTime, value: null } });
    // }
    // else if (key === "fadeTime") {
    //   setValueData({ ...valueData, [key]: { ...valueData?.key, value: value }, fadeRate: { ...valueData?.fadeRate, value: null } });
    // }
    // else {
    setValueData({ ...valueData, [key]: { ...valueData?.key, value: value } });
    // }
  };





  const icons = [
    { src: Save, tooltip: "Save configuration", onClick: () => { updateConfig(); saveAction(); }, width: 14, height: 14 },
    { src: Repeat, tooltip: "Resync", onClick: syncLight, width: 18, height: 18 },
  ]


  useEffect(() => {
    if (deviceModel) {
      setValueData(deviceConfiguration(deviceModel));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceModel, deviceData?.configuration, deviceData?.deviceStatus?.status?.kelvin]);



  return (
    <Box
      className={classes?.PhysicalParamters}
      sx={{
        p: 0,
        display: "inline-block",
        width: "100%",
        background: "rgba(0, 0, 0, 0.08)",
      }}
    >
      {
        deviceData?.category === "DaliLightDriver" ?
          <>
            {/* <Box
            sx={{ width: "100%", borderBottom: valueData?.configuration && "1px #d5d5d5 solid", py: 2, px: 3 }}
          >
            <Grid container spacing={2} item xs={12} >
              {valueData?.kelvin &&
                <Grid item sm={"auto"} md={6} xs>
                  <Box className="control-box" sx={{ position: "relative" }} >
                    {tempLoader &&
                      <Box sx={{ position: 'absolute', top: 0, bottom: 0, width: "100%", backgroundColor: "rgba(255, 255, 255, 0.8)", zIndex: 1000,...customsx?.tempLoader }}>
                        <StyledSpinner  size={24} sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
                      </Box>
                    }
                    {valueData?.kelvin &&
                      <TemperatureSlider
                        data={deviceData}
                        params={{ icon: true, label: true, value: true, compo: true }}
                        label="Color Temperature (K)"
                        mqttClient={mqttClient}
                      />}
                  </Box>
                </Grid>
              }

              <Grid item sm={"auto"} xs={12}>
                <Box className="control-box">
                  <ControllerEditor
                    icon={Wattage}
                    label="Wattage (W)"
                    disabled={deviceData?.status === "inactive" ? true : false}
                    inputProps={{ min: 0, max: 200, pattern: "\d*" }}
                    data={deviceData}
                    validation={{
                      req: [true, getMessageStr("device-wattage-required")],
                      range: [[0, 200], getMessageStr("device-wattage-range")],
                      customRegex: [/^-?\d*$/, getMessageStr("device-wattage-whole")]
                    }}
                    customsx={{ value: { width: "24px" } }}
                    value={deviceData?.wattage || 0}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box> */}

            {valueData?.configuration &&
              <Box className="" sx={{ pb: 2, px: 3, width: "100%" }}>
                <Grid container item xs={12}>
                  <Grid container item sm={10} sx={{ mt: 0.2 }} rowSpacing={2}>
                    {(valueData.minPreset || valueData?.maxPreset) &&
                      <Grid container item xs={12} spacing={2}>
                        {valueData.minPreset &&
                          <Grid item xl={4} md={6} sm={12} xs>
                            <Box className="control-box">
                              <ControllerSlider
                                icon={TempGreen}
                                label="Preset Min. (%)"
                                value={valueData["minPreset"]?.value}
                                disabled={valueData.minPreset ? !disableDeviceOperation(deviceData, "lightConfig") ? !isAuthorised(features?.device, permissions?.configureLight) : true : true}
                                data={valueData?.minPreset}
                                name="minPreset"
                                suffix=""
                                setterFunction={(e, value) => handleChange(e, value, "minPreset")}
                                params={{ icon: true, label: true, value: true, compo: true }}
                              />
                            </Box>
                          </Grid>
                        }
                        {valueData.maxPreset &&
                          <Grid item xl={4} md={6} sm={12} xs>
                            <Box className="control-box">
                              <ControllerSlider
                                icon={TempGreen}
                                label="Preset Max. (%)"
                                value={valueData?.maxPreset?.value}
                                disabled={valueData?.maxPreset ? !disableDeviceOperation(deviceData, "lightConfig") ? !isAuthorised(features?.device, permissions?.configureLight) : true : true}
                                data={valueData?.maxPreset}
                                name="maxPreset"
                                suffix=""
                                setterFunction={(e, value) => handleChange(e, value, "maxPreset")}
                                params={{ icon: true, label: true, value: true, compo: true }}
                              />
                            </Box>
                          </Grid>
                        }
                      </Grid>
                    }
                    {
                      valueData?.minMaxRange &&
                      <Grid container item xs={12} spacing={2}>
                        <Grid item xl={6} lg={8} md={10} sm={12} xs={12} >
                          <Box className="control-box rangeSlider">
                            <ControllerRangeSlider
                              icon={TempGreen}
                              label="Min/Max Color Temp. (K)"
                              customsx={SliderCss}
                              disabled={valueData?.minMaxRange ? !disableDeviceOperation(deviceData, "lightConfig") ? !isAuthorised(features?.device, permissions?.configureLight) : true : true}
                              data={{
                                min: valueData?.maxCool?.value,
                                max: valueData?.maxWarm?.value,
                              }}
                              value={valueData?.minMaxRange?.value}
                              name="minMaxRange"
                              sx={{ width: "100px" }}
                              suffix=""
                              minDistance={1}
                              setterFunction={(e, value) => handleChange(e, value, "minMaxRange")}
                              params={{ icon: true, label: true, value: true }}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    }


                    {(valueData.daliDriver || valueData?.fadeRate || valueData?.fadeTime) &&
                      <Grid container item xs={12} spacing={2}>
                        {valueData.fadeTime &&
                          <Grid item md={5} xs={12}>
                            <Box className="control-box">
                              <ControllerDropDown
                                icon={HourGlass}
                                label="Fade Time"
                                value={valueData["fadeTime"]?.value}
                                disabled={valueData.fadeTime ? !disableDeviceOperation(deviceData, "lightConfig") ? !isAuthorised(features?.device, permissions?.configureLight) : true : true}
                                name="fadeTime"
                                onChange={(e, value) => handleChange(e, value[0], "fadeTime")}
                                list={data["fadeTime"]?.list}
                                data={data}
                                customsx={DropdownCss}
                                params={{ icon: true, label: true }}
                              />
                            </Box>
                          </Grid>
                        }
                        {valueData.fadeRate &&
                          <Grid item md={5} xs={12}>
                            <Box className="control-box">
                              <ControllerDropDown
                                icon={HourGlass}
                                label="Fade Rate"
                                value={valueData["fadeRate"]?.value}
                                disabled={valueData.fadeRate ? !disableDeviceOperation(deviceData, "lightConfig") ? !isAuthorised(features?.device, permissions?.configureLight) : true : true}
                                name="fadeRate"
                                onChange={(e, value) => handleChange(e, value[0], "fadeRate")}
                                list={data["fadeRate"]?.list}
                                data={data}
                                customsx={DropdownCss}
                                params={{ icon: true, label: true }}
                              />
                            </Box>
                          </Grid>
                        }
                        {valueData.daliDriver &&
                          <Grid item md={5} xs={12}>
                            <Box className="control-box">
                              <ControllerDropDown
                                icon={HourGlass}
                                label="Driver Type"
                                value={valueData["daliDriver"]?.value}
                                disabled={valueData.daliDriver ? !disableDeviceOperation(deviceData, "lightConfig") ? !isAuthorised(features?.device, permissions?.configureLight) : true : true}
                                name="daliDriver"
                                onChange={(e, value) => handleChange(e, value[0], "daliDriver")}
                                // list={data["daliDriver"]?.list?.filter(d => d?.key <= valueData?.originalDaliDriver?.value?.key)}
                                list={data["daliDriver"]?.list}
                                data={data}
                                customsx={DropdownCss}
                                params={{ icon: true, label: true }}
                                isNoneAsItem={false}
                              />
                            </Box>
                          </Grid>
                        }
                      </Grid>
                    }
                    {(valueData.powerFailLevel || valueData?.systemFailLevel) &&
                      <Grid container item xs={12} spacing={2}>
                        {valueData.systemFailLevel &&
                          <Grid item md={6} sm={12} xs={12}>
                            <Box className="control-box">
                              <ControllerSlider
                                icon={Warning}
                                label="System Fail Level (%)"
                                value={valueData["systemFailLevel"]?.value}
                                disabled={valueData.systemFailLevel ? !disableDeviceOperation(deviceData, "lightConfig") ? !isAuthorised(features?.device, permissions?.configureLight) : true : true}
                                data={data["systemFailLevel"]}
                                name="systemFailLevel"
                                suffix=""
                                setterFunction={(e, value) => handleChange(e, value, "systemFailLevel")}
                                params={{ icon: true, label: true, value: true, compo: true }}
                              />
                            </Box>
                          </Grid>
                        }
                        {valueData.powerFailLevel &&
                          <Grid item md={6} sm={12} xs={12}>
                            <Box className="control-box">
                              <ControllerSlider
                                icon={Warning}
                                label="Power Fail Level (%)"
                                value={valueData?.powerFailLevel?.value}
                                disabled={valueData.powerFailLevel ? !disableDeviceOperation(deviceData, "lightConfig") ? !isAuthorised(features?.device, permissions?.configureLight) : true : true}
                                data={data["powerFailLevel"]}
                                name="powerFailLevel"
                                suffix=""
                                setterFunction={(e, value) => handleChange(e, value, "powerFailLevel")}
                                params={{ icon: true, label: true, value: true, compo: true }}
                              />
                            </Box>
                          </Grid>
                        }
                      </Grid>
                    }
                  </Grid>
                  {isAuthorised(features?.device, permissions?.configureLight) &&
                    <Grid item sm={2} xs={12} sx={{ mt: 2 }}>

                      <Box className="" sx={{ display: "flex", justifyContent: "flex-end" }}>
                        {icons && icons?.map((icn, index) => (
                          <StyledButton
                            tooltip={icn?.tooltip}
                            iconButton
                            onClick={icn?.onClick}
                            disabled={!disableDeviceOperation(deviceData, "lightConfig") ? !isAuthorised(features?.device, permissions?.configureLight) : true}
                            key={index}
                          >
                            <ImageWrapper height={icn?.height || 16} width={icn?.width || 16} src={icn?.src} />
                          </StyledButton>

                        ))}
                      </Box>
                    </Grid>
                  }
                </Grid>
              </Box>
            }


            {/* <StyledPopup open={dialog} onClose={closeDialog} state={dialogContents?.state} 
         data={dialogContents?.content} /> */}
          </>
          :
          <CustomTypography
            weight={600}
            color={"#0F8D48"}
            size={14}
            lineHeight={17}
            styles={{ margin: "8px" }}
            content="This device does not have configurations."
          />
      }
    </Box>

  );
};

PhysicalParamters.propTypes = {};

PhysicalParamters.defaultProps = {};

export default PhysicalParamters;
