/***
 *
 * Controller class for user.
 * @file BackButton.js
 * @description BackButton component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React from 'react';
import ImageWrapper from '../ImageWrapper';
// import PropTypes from 'prop-types';
import './BackButton.scss';
import backIcon from '../../assets/icons/back.svg'
const BackButton = (prope) => {

    return (
      <ImageWrapper className="BackButton" data-testid="BackButton" src={backIcon} alt="Back" style={{height: "18px"}} />
    )
};

BackButton.propTypes = {};

BackButton.defaultProps = {};

export default BackButton;
