import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme)=>({
    StyledDashboardCard: {
        height: '100%',
        padding: "20px 25px",
        borderRadius: "0px !important",
        boxShadow: "0px 0px 5px 0px rgb(0 0 0 / 18%), 0px 0px 0px 0px rgb(0 0 0 / 1%), 0px 0px 0px 0px rgb(0 0 0 / 3%) !important",
        // transition: "all 0.1s",
        [theme.breakpoints.down('md')]: {
            padding: "12px 15px",
        }
    },
    Heading: {
        color: "#5E5E5E", 
        fontSize: '1.125rem',
        fontWeight: "600",
        textTransform: "capitalize",
        lineHeight:"21.5px",
        width:" max-content"
    },
    Link: {
        color: "#0F8D48", 
        textDecoration: "underline",
        fontSize: '0.875rem',
        fontWeight: "500",
        textTransform: "capitalize"
    }
}));
