/***
 *
 * Controller class for user.
 * @file SceneList.js
 * @description SceneList component
 * @author Shubham Singh
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './SceneList.style.js';
import './SceneList.scss';
import { Box, Stack } from '@mui/material';
import PageHeader from '../PageHeader/index.js';
import ImageWrapper from '../ImageWrapper/index.js';
import { sceneActions } from '../../redux/actions/scene.actions.js';
import { useDispatch, useSelector } from 'react-redux';
import { history, applyScene, formatedDate, getMessageStr, isAuthorised } from "../../helpers";
import DeviceTypes from "../DeviceTypes";
import ListView from "../../assets/icons/list-view.svg";
import CardView from "../../assets/icons/card-view.svg";
import CustomTable from '../CustomTable/index.js';
import CustomTypography from '../CustomTypography/index.js';
import { TableHelper } from '../TableHelper/index.js';
import _ from 'lodash';
import Refresh from "../../assets/icons/sync.svg";
import StyledCard from '../StyledCard/index.js';
import DataNotFound from '../../pages/DataNotFound/index.js';
import applyIcon from '../../assets/icons/apply.svg';
import syncIcon from '../../assets/icons/sync.svg';
import LightIcon from '../../assets/icons/bulb.svg';
import GroupIcon from '../../assets/icons/group.svg';
import sceneIcon from '../../assets/icons/scene.svg';
import GridView from '../GridView/index.js';
import { subscribe, unsubscribeAll } from '../../services/mqtt.service.js';
import { features, permissions } from '../../constants/permission.constants.js';
import StyledButton from '../StyledButton/index.js';
import { Link } from 'react-router-dom';


const cardIconsList = [
  {
    "src": LightIcon,
    "key": "light",
    "borderInfo": "1px solid #fdd600"
  },
  {
    "src": GroupIcon,
    "key": "group",
    "borderInfo": "1px solid #d7aaff"
  }
]
const SceneCard = ({ sceneDetails, mqttClient, ...props }) => {

  const dispatch = useDispatch();
  const [scene, setScene] = useState({});

  const filterCardIconsList = (scene) => {
    let data = scene?.actions || [];

    const filteredKeys = data.map((item) => {
      if (item?.deviceId) {
        return "light";
      } else if (item?.groupId) {
        return "group";
      }
      return null;
    }).filter((key, index, array) => key && array.indexOf(key) === index);

    const filteredIcons = cardIconsList.filter((item) => filteredKeys.includes(item.key));

    return filteredIcons || [];
  }

  useEffect(() => {
    return () => {
      unsubscribeAll(mqttClient, dispatch)
    }
  }, [mqttClient])

  useEffect(() => {
    setScene(sceneDetails)
  }, [sceneDetails])

  return <StyledCard
    disabled={scene?.status === "inactive"}
    config={{
      heading: {
        style: { textTransform: 'normal' }
      }
    }}
    data={{
      // ...(() => {
      //   return scene?.managedBy !== "cloud" ? {
      status: {
        text: scene?.synced ? 'Synced' : 'Requires Sync',
        active: scene?.synced
      },
      //   } : {}
      // })(),
      heading: {
        label: scene.name,
        to: scene?.status !== "inactive" && `?id=${scene.sceneId}`,
        actions: (
          <Stack direction={'row'} alignItems={'center'}>
            {scene.managedBy !== 'cloud' && scene?.status !== "inactive" && isAuthorised(features?.device, permissions?.syncScene) && !scene?.synced &&
              <StyledButton
                iconButton
                tooltip="Sync Scene"
                sx={{ p: 0, ml: 1 }}
                onClick={(event) => {
                  event.preventDefault();
                  dispatch(
                    sceneActions.syncById(scene.sceneId)
                  );
                }}
              >
                <ImageWrapper width={16} height={16} src={Refresh} />
              </StyledButton>
            }
            {isAuthorised(features?.device, permissions?.applyScene) && scene?.status !== "inactive" &&
              <StyledButton
                iconButton
                sx={{ p: 0, ml: 1 }}
                onClick={(event) => {
                  event.preventDefault();
                  applyScene(scene, dispatch, mqttClient);
                }}
                tooltip="Apply Scene"
              >
                <ImageWrapper width={16} height={16} src={applyIcon} />
              </StyledButton>
            }
          </Stack>
        )
      },
      mediaList: {
        max: 6,
        images: filterCardIconsList(scene)
      },
      info: {
        // text: `Managed By: ${_.capitalize(scene?.managedBy)}`,
        // count: <DeviceTypes data={scene} />
        text: `${_.capitalize(scene?.managedBy)} managed`,
        secondText: `Last Updated On: ${scene?.lastUpdatedOn && formatedDate({ date: scene?.lastUpdatedOn, format: "MMM dd, yyyy" })}`,
        count: <DeviceTypes data={scene} />
      },
      // footer: {
      //   content: <CustomTypography content={`${scene.createdOn === scene.lastUpdatedOn ? 'Created' : 'Last Updated'} On: ${scene?.lastUpdatedOn && formatedDate({ date: scene.lastUpdatedOn, format: "MMM dd, yyyy" })}`} variant='subtitle2' />
      // }
    }}
  />
}
const SceneList = ({ mqttClient }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const scenes = useSelector((state) => state?.scenes?.sceneList) || [];
  let currentSpaceId = useSelector((state) => state?.spaces?.selectedSpace?.spaceId);
  const lastAction = useSelector((state) => state?.scenes?.lastAction) || null;
  const gatewayList = useSelector((state) => state?.devices?.gatewayList) || [];
  const sceneDeleted = useSelector((state) => state?.scenes?.sceneDeleted)

  const [listActive, setListActive] = useState(false);
  const [sceneList, setSceneList] = useState([]);
  const [gatewayIdList, setGatewayIdList] = useState([]);

  const goTo = (route) => {
    history.push(route);
  };
  // const handleViewSelection = () => {
  //   setListActive(!listActive)
  // }

  useEffect(() => {
    dispatch(sceneActions?.getAllScenesBySpace(currentSpaceId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSpaceId, sceneDeleted]);

  useEffect(() => {
    setSceneList(scenes);
    const gwIdList = new Set();
    scenes?.forEach(s => {
      s?.managedBy !== "cloud" && gwIdList?.add(s?.gatewayIds[0]);//fetching ids of gateways we need to subscribe to 
    })
    setGatewayIdList(gwIdList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastAction])

  useEffect(() => {
    if (!_.isEmpty(mqttClient) && !_.isEmpty(gatewayList) && !_.isEmpty(gatewayIdList)) {
      gatewayList?.forEach(g => {
        if (gatewayIdList?.has(g?.deviceId)) {
          subscribe(g?.mqttTopics?.comStatus, dispatch, mqttClient, "sceneSyncMonitor")
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gatewayIdList, gatewayList])

  return (
    <Box className={classes?.SceneList || ""} data-testid="SceneList">
      <DataNotFound
        show={sceneList.length === 0 ? true : false}
        customsx={{ image: { height: '202px', width: '100%' }, label: {}, content: {}, btns: {} }}
        data={{
          label: "Scenes",
          content: (getMessageStr('scene-getAll-noScene') + (isAuthorised(features?.device, permissions?.add) ? getMessageStr('scene-getAll-createScene') : ".")), //WGJGBM scene-getAll-noScene
          action:
            isAuthorised(features?.device, permissions?.add) &&
            [
              {
                id: "1001",
                type: "button",
                label: "Create Scene",
                onClick: (event, data) => { goTo("/spaces/scenes?op=create") },
              }]
        }
        }
      >

        <Box>
          <PageHeader title={"Scenes"} icon={sceneIcon}>
            <Box sx={{ textAlign: "right" }}>
              <StyledButton
                onClick={(e) => {
                  setListActive(!listActive);
                }}
                iconButton
                tooltip={listActive ? 'Card View' : "List View"}
              >
                <ImageWrapper src={listActive ? CardView : ListView}></ImageWrapper>
              </StyledButton>
              {isAuthorised(features?.device, permissions?.add) &&
                <StyledButton
                  sx={{ ml: 1 }}
                  onClick={(event) => goTo("/spaces/scenes?op=create")}>
                  Create Scene
                </StyledButton>
              }
            </Box>
          </PageHeader>
          {listActive ? (
            <CustomTable
              headerList={{
                name: {
                  label: "SCENE NAME",
                  type: "component",
                  component: (row) => {
                    return row?.data?.status === "inactive" ?
                      <CustomTypography fontWeight={400} size={14} lineHeight={19} content={row?.data?.name} />
                      :
                      <Link className="link" to={`/spaces/scenes?id=${row?.data?.sceneId}`}>{row?.data?.name}</Link>
                  },
                  toolTip: {
                    type: "component",
                    content: TableHelper.TableLinkTooltipContent
                  }
                },
                devices: {
                  label: "Device Count",
                  type: "component",
                  component: DeviceTypes,
                },
                managedBy: {
                  label: "Managed By",
                },
                synced: {
                  label: "Sync Status",
                  type: "component",
                  component: (row) => {
                    return (
                      // row?.data?.managedBy === "cloud" ?
                      //   <Box >-</Box>
                      //   :
                      <TableHelper.TableStatus type={row?.data?.synced ? "synced" : "needsSync"} variant={"filled"} label={row?.data?.synced ? "Synced" : "Needs Sync"} />
                    )
                  },
                  style: { maxWidth: 180 },
                },
                // userActions: {
                //   label: "Sync Action",
                //   actions: [
                //     {
                //       label: "Sync Group",
                //       type: "refresh",
                //       onClick: (event, data) => {
                //         event.preventDefault();
                //         dispatch(
                //           deviceActions.syncById(data.sceneId)
                //         );
                //       },
                //     },
                //   ],
                //   style: { textAlign: "right" },
                // },
                userActions: {
                  label: "ACTIONS",
                  actions: [
                    {
                      label: "Sync Scene",
                      type: "refresh",
                      onClick: (event, data) => {
                        event.preventDefault();
                        dispatch(
                          sceneActions.syncById(data.sceneId)
                        );
                      },
                      hidden: (row) => {
                        return !isAuthorised(features?.device, permissions?.syncScene) || row.managedBy === 'cloud' || row?.synced || row?.status === "inactive"
                      },
                      icon: syncIcon
                    },
                    {
                      label: "Apply Scene",
                      type: "apply",
                      onClick: (event, data) => {
                        event.preventDefault();
                        applyScene(data, dispatch, mqttClient);
                      },
                      hidden: (row) => {
                        return !isAuthorised(features?.device, permissions?.applyScene) || row?.status === "inactive"
                      },
                      icon: applyIcon
                    },
                  ],
                  style: { textAlign: "right" },
                },
              }}
              sorting={{ column: ['name', 'managedBy'] }}
              removeSortFrom={["devices", "userActions"]}
              cellMatrix={sceneList}
              pagination={true}
              searching={true}
              rowConfig={{ highlight: { bgKey: "status", bgValue: "inactive" }, disabled: { key: "status", value: "inactive" } }}

            />
          ) : (
            <GridView
              data={sceneList}
              card={({ data: scene }) => {
                return <SceneCard sceneDetails={scene} mqttClient={mqttClient} ></SceneCard>
              }}
            >
            </GridView>

          )}
        </Box>
      </DataNotFound>
    </Box >
  )
};

SceneList.propTypes = {};

SceneList.defaultProps = {};

export default SceneList;
