import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    RuleActionDragDropWrapper: {

        "& .droppableArea": {
            height: '260px'
        },
        "& .draggableArea": {
            height: '230px'
        },
        '& .CustomizedTree': {
            maxHeight: '310px !important'
        },
        "& .DragAndDropColumnsWrapper": {
            minHeight: "415px"
        },
        "& .SearchWrapper": {
            padding: '18px 24px 8px 24px'
        },
        "& .draggableItem": {
            textOverflow: "ellipsis",
            overflow: "hidden",
            maxWidth: "130px",
            whiteSpace: "nowrap"
        },
        "& .spaceTree": {
            '& .MuiOutlinedInput-root': {
                '& .MuiOutlinedInput-input': {
                    fontSize: "0.875rem !important",
                    padding: "7px 15px",
                }
            }
        }
    }
});
