/***
 *
 * Controller class for user.
 * @file TwoDLayout.js
 * @description TwoDLayout component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import { Box, Dialog, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ImageWrapper from '../../components/ImageWrapper/index.js';
import StyledDashboardCard from '../../components/StyledDashboardCard/index.js';
import DeviceStatus from '../../pages/Dashboard/DeviceStatus/index.js';
import DataNotFound from '../../pages/DataNotFound/index.js';
import { canvasActions } from '../../redux/actions/canvas.actions.js';
// import PropTypes from 'prop-types';
import { useStyles } from './TwoDLayout.style.js';
import EditIcon from '../../assets/icons/edit.svg';
import Canvas from '../../components/Canvas/index.js';
import DefaultOfficeImage from "../../assets/defaults/office.png";
import DefaultOfficeImage2 from "../../assets/defaults/layout4.png";
import MaximizeIcon from "../../assets/icons/maximize.svg";
import MinimizeIcon from "../../assets/icons/minimize.svg";
import { spaceActions } from '../../redux/actions/space.actions.js';
import _ from 'lodash';
import { deviceActions } from '../../redux/actions/device.actions.js';
import { subscribe, unsubscribeAll } from '../../services/mqtt.service.js';
import { defaultDeviceJson, defaultSpaceJson } from '../../constants/dashboard.constants.js';
import { groupActions } from '../../redux/actions/group.actions.js';
import TopBar from '../../components/TopBar/index.js';
import CanvasArea from '../../components/CanvasArea/index.js';
import { spaceService } from '../../services/space.service.js';
import { success } from '../../helpers/action.helper.js';
import { spaceTypes } from '../../redux/types/space.type.js';
import { isAuthorised } from '../../helpers/users.helper.js';
import { features, permissions } from '../../constants/permission.constants.js';
import { tenantName } from '../../config/appConfig.js';
import StyledButton from '../../components/StyledButton/index.js';


const TwoDLayout = ({ mqttClient, mobileView, ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const _selectedSpace = useSelector(state => state?.spaces?.selectedSpace);
  const _spaceList = useSelector(state => state?.spaces?.list);
  const _breadCrumbs = useSelector(state => state?.spaces?.breadcrumbsData);

  const _devices = useSelector(state => state?.devices?.deviceList);
  const _alldevices = useSelector(state => state?.devices?.allDevices);
  const _spaceLayouts = useSelector(state => state?.canvas?.spaceLayouts);

  //// new
  const _allAsset = useSelector(state => state?.spaces?.allAsset);

  const _addAssetObj = useSelector(state => state?.spaces?.assetObj);
  const _rootSpace = useSelector(state => state?.spaces?.rootSpace);

  const [modalOpen, setOpenModal] = useState(false);
  const [loader, showLoader] = useState(true);
  const [selectSpace, setSelectedSpace] = useState(null);
  const [value, setValue] = useState({});
  const [maximize, setMaximize] = useState(false);
  const [spaceJson, setSpaceJson] = useState(defaultSpaceJson);
  const [deviceJson, setDeviceJson] = useState(defaultDeviceJson);

  function getDimnsn(canvasProfile) {
    const shape = _spaceLayouts?.find((shapeObj) => shapeObj?.spaceId === canvasProfile?.spaceId);
    return { width: (shape?.width) || '100%', height: shape?.height || 'auto' }
  }


  const handleMaximize = (e) => {
    if (maximize) setMaximize(false);
    else setMaximize(true);
  }

  const getChildSpaces = (space, collected, zIndex = 0) => {
    collected.push({ spaceId: space?.spaceId, zIndex: zIndex, depth: zIndex });

    if (Array.isArray(space['Spaces'])) {
      for (const s of space['Spaces']) {
        getChildSpaces(s, collected, zIndex + 1)
      }
    }
    return collected;
  }

  const getParentNodes = (selectedSpace, collected, zIndex = -1) => {
    if (selectedSpace === _rootSpace?.spaceId) { return collected; }
    const findSpace = _spaceList?.find(space => space?.spaceId === selectedSpace);
    collected.push({ spaceId: findSpace?.spaceId, zIndex: zIndex });
    if (findSpace?.parentSid) {
      getParentNodes(findSpace?.parentSid, collected, zIndex - 1);
    }

    return collected;

  }

  const assetArray = (space, collected, assetId = null) => {
    let currAssetId = assetId;
    if (!_.isEmpty(space?.asset)) {
      let pngAsset = space?.asset?.find(s => s?.default === true);
      console.log('pngg arr', pngAsset, space?.asset);
      if (!collected?.find(s => s?.spaceId === space?.spaceId)) {
        collected.push({ assetId: pngAsset?.assetId, spaceId: space?.spaceId });
      }
      currAssetId = pngAsset?.assetId;
    }

    if (!space?.asset) {
      if (currAssetId) {
        collected.push({ assetId: currAssetId, spaceId: space?.spaceId });
      }
    }

    if (Array.isArray(space['Spaces'])) {
      for (const s of space['Spaces']) {
        assetArray(s, collected, currAssetId);
      }
    }

    return collected;


  }


  const getAssetFromSpace = async (spaceArr) => {
    let i = 0;
    while (i < spaceArr.length) {
      await spaceService.getAllAsset(spaceArr[i]).then(({ data: res }) => {
        if (_.isEmpty(res)) { i++; }
        else {
          dispatch(success(spaceTypes.GET_ALL_ASSET_SUCCESS, { payload: res }))
          i = 1e7;
          return;
        }
      })
    }
    return;
  }

  ////// dashoboard place for 2d //////

  useEffect(() => {
    return () => {
      unsubscribeAll(mqttClient, dispatch)
    }
  }, [mqttClient])

  useEffect(() => {
    // console.log('selected space=====>', _selectedSpace);

    if (!_.isEmpty(_selectedSpace) && !_.isEmpty(_spaceList)) {
      dispatch(spaceActions.emptySpaceState('ADD_ASSET_SUCCESS', null));
      dispatch(spaceActions.emptySpaceState('GET_ALL_ASSET_SUCCESS', null));
      dispatch(spaceActions.emptySpaceState('GET_GIVEN_ASSET_SUCCESS', null));
      dispatch(canvasActions.highlightGroup(null));
      dispatch(groupActions.getAllGroupsBySpace(_selectedSpace?.spaceId));
      let spacefromlist = null
      spacefromlist = _spaceList?.find(space => space?.spaceId === _selectedSpace?.spaceId)
      if (spacefromlist?.asset) {
        delete spacefromlist['asset']; /// remove any cache asset
      }
      setSelectedSpace(spacefromlist);
      getAssetFromSpace(spacefromlist?.parentSid ? [spacefromlist?.spaceId, spacefromlist?.parentSid] : [spacefromlist?.spaceId]);

      setMaximize(false);
      if(isAuthorised(features?.device)){
        dispatch(deviceActions.getDevicesBySpaceId(_selectedSpace?.spaceId)) 
      }  


      // dispatch(spaceActions.getAllAsset(_selectedSpace?.spaceId)); //// for current space

      if (!_.isEmpty(mqttClient) && !_.isEmpty(_breadCrumbs)) {
        setTimeout(()=>{
          subscribe(
            [`${tenantName}/+/status/AnalogLightDriver/${_breadCrumbs.map(bd => bd?.id).join('/')}`,
            `${tenantName}/+/status/RelayLightDriver/${_breadCrumbs.map(bd => bd?.id).join('/')}`,
            `${tenantName}/+/status/DaliLightDriver/${_breadCrumbs.map(bd => bd?.id).join('/')}`
            ], dispatch, mqttClient, 'lightControl')
        },100)    
      }

      setSpaceJson({
        ...spaceJson, widget: [
          {
            widgetHeading: "Total",
            WidgetText: _selectedSpace?.Spaces?.length,
            widgetColor: "linear-gradient(110.94deg, #FBF6DF 5.34%, rgba(251, 246, 223, 0.25) 94.04%)",
            label: "Total",
            counter: _selectedSpace?.Spaces?.length,
            bgColors: ["#FBF6DF", "rgba(251, 246, 223, 0.25)"]
          }
        ],
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_selectedSpace])


  //// navigate spaces
  useEffect(() => {
    console.log('got select space::::', selectSpace);
    if (selectSpace) {
      let obj = selectSpace;
      showLoader(false);
      // not needed
      // setImageReset(false);
      // const spacePaths = selectSpace?.Path ? selectSpace?.Path?.split('.')?.reverse():[];
      // const childToRootPath = [selectSpace?.spaceId ,...spacePaths];
      // const getAssetOfParents = fetchAssetOfparents(childToRootPath,[]);
      // console.log('get all asset===>',getAssetOfParents);

      const asset = getChildSpaces(_spaceList.find(s => s?.spaceId === selectSpace?.spaceId), [], 0);
      const parentNodes = getParentNodes(_selectedSpace?.parentSid, []);
      obj['spaceIds'] = asset;
      obj['asset'] = {};
      if (!_.isEmpty(_allAsset)) {
        obj['asset'] = _allAsset;
        const getPNGMime = _allAsset.find(a => a?.mime === 'png');
        const getDWGMime = _allAsset.find(a => a?.mime === 'dwg');
        const spaceIds = asset.map(a => { return { spaceId: a?.spaceId, assetId: getPNGMime?.assetId } });
        spaceIds.forEach(element => {
          dispatch(spaceActions.associateAssetwithSpace(element));
        });
        obj['nodezIndex'] = [...parentNodes, ...asset];
        // console.log('asset came:::',_allAsset, _spaceLayouts );
        if (!_.isEmpty(getPNGMime)) {
          if (getPNGMime?.default)
            obj['spaceLayout'] = getPNGMime?.url;
          else
            obj['spaceLayout'] = _spaceLayouts?.find(s => getPNGMime?.assetId === s?.assetId)?.url
          showLoader(false);
        }
        obj['dwgFile'] = getDWGMime?.url ? getDWGMime.url : null;
      }
      setValue({ ...obj })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectSpace, _allAsset, _addAssetObj])




  /// fetch modal state
  useEffect(() => {
    dispatch(canvasActions.modalState(modalOpen));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen])

  useEffect(() => {
    if (loader) {
      setTimeout(() => {
        showLoader(false);
      }, 5000)
    }
  }, [loader])

  useEffect(() => {
    setDeviceJson({
      ...deviceJson,
      widget: [
        {
          ...deviceJson?.widget[0],
          WidgetText: _devices?.length,
          counter: _devices?.length
        },
        {
          ...deviceJson?.widget[1],
          WidgetText: "-",
          counter: "-"
        },
        {
          ...deviceJson?.widget[2],
          WidgetText: _devices?.length - _devices?.filter((device) => {
            return device.status === "active"
          })?.length,
          counter: _devices?.length - _devices?.filter((device) => {
            return device.status === "active"
          })?.length
        },
        {
          ...deviceJson?.widget[3],
          WidgetText: _devices?.filter((device) => {
            return device.status === "active"
          })?.length,
          counter: _devices?.filter((device) => {
            return device.status === "active"
          })?.length
        }
      ],
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_devices, selectSpace])

  return (
    <Grid item xs={12} className={classes?.TwoDLayout || ""} data-testid="TwoDLayout">
      <StyledDashboardCard
        heading="Devices"
        headingChildren={
          <>
            {selectSpace && selectSpace?.asset &&
              <>
                {!mobileView && !_.isEmpty(value?.asset) && isAuthorised(features?.space, permissions?.edit) && isAuthorised(features?.device, permissions?.edit) &&
                  <StyledButton iconButton tooltip='2D Editor' onClick={() => { setOpenModal(true); dispatch(canvasActions.modalState(true)); dispatch(canvasActions.modalSpace(selectSpace)) }}>
                    <ImageWrapper className="action-icons pointer" width={18} src={EditIcon} alt={"img"} />
                  </StyledButton>
                }
              </>
            }
            {(isAuthorised(features?.space) && isAuthorised(features?.device)) && (!_.isEmpty(selectSpace?.asset) || selectSpace?.spaceId === _rootSpace?.spaceId) &&
              <StyledButton iconButton tooltip={maximize ? 'Minimize' : 'Maximize'} onClick={handleMaximize} sx={{ ml: .5 }}>
                <ImageWrapper className="action-icons pointer" width={18} src={maximize ? MinimizeIcon : MaximizeIcon} alt={"img"} />
              </StyledButton>
            }
          </>
        } sx={{ mb: 0 }}
        headingStyle={{ minHeight: "34px", display: "flex", alignItems: "center" }}>
        <Grid item xs={12} sx={{ overflow: 'hidden' }}>

          {
            selectSpace?.spaceId === _rootSpace?.spaceId ?
              <Box className="canvasContainer" sx={{ height: !maximize ? '350px' : 'auto' }} >
                <img src={DefaultOfficeImage2} alt="" style={{ width: '100%' }} />
              </Box>
              :
              <Box className="canvasContainer" sx={{ maxHeight: '100vh', height: !maximize ? (selectSpace && !_.isEmpty(selectSpace?.asset) ? '375px' : 'auto') : getDimnsn(selectSpace)?.height }} >
                {(selectSpace && !_.isEmpty(selectSpace?.asset) && isAuthorised(features?.space) && isAuthorised(features?.device)) ? (
                  <>
                    {!modalOpen && <Canvas mqttClient={mqttClient} showBtns={false} space={selectSpace} canvasProfile={value} modalOpen={modalOpen} maximize={maximize} image={value?.spaceLayout} />}
                  </>
                ) : (
                  <DataNotFound style={{ minHeight: "350px" }} data={{
                    image: DefaultOfficeImage, label: "", content: (isAuthorised(features?.space, permissions.add) && isAuthorised(features?.device, permissions.add)) ? "To add a floor plan and devices please click on the below button." : "No layout found for this space.",
                    action: (isAuthorised(features?.space, permissions.add) && isAuthorised(features?.device, permissions.add)) ? [{ label: "Add layout plan", onClick: () => { setOpenModal(true); dispatch(canvasActions.modalState(true)); dispatch(canvasActions.modalSpace(selectSpace)) } }] : []
                  }} />
                )}
              </Box>
          }
        </Grid>
        {!mobileView &&
          <Grid className="containerFull" item xs={12} >
            <DeviceStatus />
          </Grid>
        }
      </StyledDashboardCard>
      <Dialog fullScreen className={`canvasdialog ${classes.EditorDialog}`} open={modalOpen} onClose={() => { setOpenModal(false); dispatch(canvasActions.modalState(false)); dispatch(canvasActions.modalSpace(null)) }}>
        <TopBar />
        <CanvasArea
          mqttClient={mqttClient} setValue={setValue} modalOpen={modalOpen} selectSpace={selectSpace} setSelectedSpace={setSelectedSpace} value={value}
          setOpenModal={setOpenModal} />
      </Dialog>
    </Grid>
  )
};

TwoDLayout.propTypes = {};

TwoDLayout.defaultProps = {};

export default TwoDLayout;
