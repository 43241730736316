import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme=>({
    StyledDashboardCounter: {
        minWidth: "100px",
        // maxWidth: "200px",
        width: "100%",
        // background: "linear-gradient(110.94deg, #FBF6DF 5.34%, rgba(251, 246, 223, 0.25) 92%)",
        border: "1px solid #FFFFFF",
        borderRadius: "20px",
        display: "flex", 
        justifyContent: "space-between", 
        padding: "20px 20px",
        [theme.breakpoints.down('md')]: {
            padding: "12px",
        },
        '& .label': {
            // padding: "10px",
            width: "100%",
            fontWeight: 600,
            fontSize: '1rem', 
            lineHeight: "1rem", 
            color: "#5E5E5E",
            fontFamily: "Lato",
            textTransform: "capitalize"
        },
        '& .counter': {
            width: "100%",
            // padding: "10px",
            fontWeight: 600, 
            fontSize: '1.875rem', 
            lineHeight: "1.875rem", 
            color: "#5E5E5E",
        }
    }
}));
