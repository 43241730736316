/***
 *
 * Controller class for user.
 * @file ImageWrapper.js
 * @description ImageWrapper component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import './ImageWrapper.scss';
import defaultImage from '../../assets/img/img.png';
import PopoverContainer from '../PopoverContainer';
import CustomTypography from '../CustomTypography';
import Image from 'mui-image';

const ImageWrapper = ({component="img", enablePopOver, hoverLabel, onMouseEnter=()=>{}, onMouseLeave=()=>{}, ...props }) => {
  props.src = props?.src || "";

  const [hideImage, setHideImage] = useState(false);
  const [openPopOver, setOpenPopover]= useState(null)

  // console.log(`ImageWrapper ---- `, props?.alt)

  const addDefaultSrc = (en) => {
    // console.log("ImageWrapper ImageError --- ", en.target)
    en.target.src = defaultImage;
  }

  useEffect(() => {
    setHideImage((props?.hide || false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.show])

  return (
    <>
      {!hideImage && <>
        {component === "img" ? (
          <img
            style={{ display: "inline", ...props?.style }}
            data-testid="ImageWrapper"
            src={props?.src || defaultImage}
            className={props?.className || ""}
            alt={props?.alt || "img"}
            onError={addDefaultSrc}
            onClick={props?.onClick}
            onMouseEnter={(e)=>{ onMouseEnter(e); setOpenPopover(e.currentTarget)}}
            onMouseLeave={(e)=>{ onMouseLeave(e); setOpenPopover(null)}}
            effect="blur"
            width={props?.width || "auto"} 
            height={props?.height || "auto"}
            {...props}
          />
        ) : (
          <Image
            style={{ display: "inline", ...props?.style }}
            data-testid="ImageWrapper"
            src={props?.src || defaultImage}
            className={props?.className || ""}
            alt={props?.alt || "img"}
            onError={addDefaultSrc}
            onClick={props?.onClick}
            onMouseEnter={(e)=>{ onMouseEnter(e); setOpenPopover(e.currentTarget)}}
            onMouseLeave={(e)=>{ onMouseLeave(e); setOpenPopover(null)}}
            effect="blur"
            width={props?.width || "auto"} 
            height={props?.height || "auto"}
            {...props}
          />
        )}
        
        {enablePopOver &&
        <PopoverContainer sx={{ zIndex: '9999' }} anchorEl={openPopOver} placement={props?.placement}>
          <CustomTypography content={hoverLabel} size={12} weight={400} color={"#202020"} styles={props?.hoverstyle} />
        </PopoverContainer>
       }
       </>
      }
    </>
  )
};

ImageWrapper.propTypes = {};

ImageWrapper.defaultProps = {};

export default ImageWrapper;
