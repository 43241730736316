import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    PageHeader: {
        overflow: 'hidden',
        padding: '0px 0px 20px 0px',
        marginBottom: "5px"
    },
    Icon: {
        backgroundColor: "rgba(15, 141, 72, 0.05)", 
        padding: "5.5px 5.5px",
        marginRight: "10px",
        borderRadius: "2px",
        // marginBottom: "3px",
        display: "inline-block",
        '& img': {
            width: "25px",
            height: "25px",
            filter: "invert(37%) sepia(14%) saturate(5108%) hue-rotate(112deg) brightness(99%) contrast(88%)"
        }
    },
    MenuItem:{
        '&.MuiMenuItem-root':{
            minHeight:'35px'
        },
        '& img':{
            width:'15px',
            height:'15px'
        }
    }
});
