import { API_URL } from '../config/appConfig'
import { API } from '.'
import { handleResponse } from '../helpers';

const getReportById = (reportId) => {
    let payload={};
    return API.get(`${API_URL.support}/reports/${reportId}`, "getReportById", payload).then((res) => {
        return handleResponse(res,{});
    });
}

const getDocumentReportById = (reportId) => {
    let payload={};
    //Todo change the url
    return API.get(`${API_URL.support}/reports/${reportId}/documents`, "getDocumentReportById", payload).then((res) => {
        return handleResponse(res,{});
    });
}

// const getDocumentById = (reportId,documentId) => {
//     // return API.get(`${API_URL.support}/reports/${reportId}/documents`, "getDocumentReportById", payload).then(handleResponse);
// } 

const updateReport = (reportId,payload) => {
    return API.put(`${API_URL.support}/reports/${reportId}`, "UpdateReport", payload).then((res) => {
        return handleResponse(res,{});
    });
}

const getAllReports = (payload) => {
    return API.get(`${API_URL.support}/reports`, "getAllReports", payload).then((res) => {
        return handleResponse(res,{});
    });
}

const createReport = (payload) => {
    // console.log("Report--->", payload)
    return API.post(`${API_URL.support}/reports`, "createReport", payload).then((res) => {
        return handleResponse(res,{});
    });
}

const deleteReport = async(id) => {
    // const requestOptions = {
    //     method: 'DELETE',
    //     headers:authHeader()
    // };
    return API.delete(`${API_URL.support}/reports/${id}`,"deleteReport",{}).then((res) => {
        return handleResponse(res,{});
    });
}

const deleteDocumentById = async(reportId,documentId) => {
    return API.delete(`${API_URL.support}/reports/${reportId}/documents/${documentId}`,"deleteReportDocument",{}).then((res) => {
        return handleResponse(res,{});
    });
}

const shareReportDocument = async(reportId,documentId,payload) =>{
    return API.put(`${API_URL.support}/reports/${reportId}/share/${documentId}`,"shareReportDocument",payload).then((res) => {
        return handleResponse(res,{});
    });
}

const generateReportDocument = async(reportId) =>{
    return API.put(`${API_URL.support}/reports/${reportId}/generate`,"generateReportDocument",{}).then((res) => {
        return handleResponse(res,{});
    });
}

const downloadReportDocument = async(reportId,documentId) =>{  // get report document url by document id
    return API.get(`${API_URL.support}/reports/${reportId}/${documentId}/url`, "downloadReportDocument", {}).then((res) => {
        return handleResponse(res,{});
    });
}

const getReportDocumentUrl = async(reportId,documentId) =>{  // get report document url by document id
    return API.get(`${API_URL.support}/reports/${reportId}/${documentId}/url`, "GetReportDocumentUrl", {}).then((res) => {
        return handleResponse(res,{});
    });
}

export const reportService = {
    getReportById,
    getAllReports,
    createReport,
    updateReport,
    deleteReport,
    getDocumentReportById,
    deleteDocumentById,
    shareReportDocument,
    generateReportDocument,
    downloadReportDocument,
    getReportDocumentUrl
};