/***
 *
 * Controller class for user.
 * @file StyledSnackBar.js
 * @description StyledSnackBar component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './StyledSnackBar.style.js';
import './StyledSnackBar.scss';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { useDispatch } from 'react-redux';
import { appActions } from '../../redux/actions/app.actions.js';
import { getSessionItem, removeSessionItem } from '../../helpers/storage.helper.js';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StyledSnackBar = ({ open = false, onClose, autoHideDuration=5000, data, anchorOrigin={ vertical: "bottom", horizontal: "left" }, stacked=false, ...props }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [autoClose, setAutoClose] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(open);

  useEffect(() => {
    const canceledReq = getSessionItem("miraie_req_canceled");
    if (canceledReq === "canceled") {
      setShowSnackBar(false);
    } else {
      setShowSnackBar(open);
      if (!open) {
        setAutoClose(false);
      } else {
        setAutoClose(false);
        setTimeout(() => {
          setAutoClose(true);
        }, autoHideDuration)
      }
    }  
    removeSessionItem("miraie_req_canceled"); 

  }, [dispatch, open, autoHideDuration]);

  useEffect(() => {
      if(stacked) {
        setTimeout(() => {
          dispatch(appActions.messagePop(data?.messageId));
        }, autoHideDuration)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);


  return (data && data?.type && data?.message &&
    <>
      {stacked ? (
        <Alert 
          className={classes?.StyledStackedSnackBar || ""}
          onClose={onClose}
          severity={(data?.severity) || (data?.type === "success" ? "success" : (data?.type === "error" ? "error" : ""))}
          sx={{ minWidth: "200px" }}
        >
          {data.message || data.error || (data?.type === "success" ? "Success" : (data?.type === "error" ? "Error" : ""))}
        </Alert>
      ) : (
        <Snackbar
          data-testid="StyledSnackBar"
          open={showSnackBar && data && !autoClose ? showSnackBar : false}
          onClose={onClose}
          anchorOrigin={anchorOrigin}
          className={classes?.StyledSnackBar || ""}
          autoHideDuration={autoHideDuration}
          severity={(data?.severity) || (data?.type === "success" ? "success" : (data?.type === "error" ? "error" : ""))}
          {...props}
        >
          <Alert
            onClose={onClose}
            severity={(data?.severity) || (data?.type === "success" ? "success" : (data?.type === "error" ? "error" : ""))}
            sx={{ minWidth: "200px" }}
          >
            {data.message || data.error || (data?.type === "success" ? "Success" : (data?.type === "error" ? "Error" : ""))}
          </Alert>
        </Snackbar>
      )}
    </>
    
  )
};

StyledSnackBar.propTypes = {};

StyledSnackBar.defaultProps = {};

export default StyledSnackBar;
