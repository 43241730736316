import { Switch } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';

export const customsx={
  CanvasDropdown:{
    '& .MuiOutlinedInput-input': { padding: '4px 8px !important' },
    '& .MuiSelect-select':{
      display: 'inline-block !important',
      textOverflow:'ellipsis',
      whiteSpace:"nowrap",
      overflow:'hidden',
      marginRight:'17px'
    },
    '& .MuiSelect-icon':{
      // position:'relative',
      // right:'0',
    } 
  },
  CanvasPaper:{
    maxWidth: '100%',
    minWidth: '100%',
    margin: '0 auto',
    p: 1, 
    overflowY: 'scroll',
    overflowX: 'scroll',
    maxHeight: '100%'
  }
}

export const SliderCss = {
    compo: {       
        "& .MuiSlider-rail": {
          background: 'linear-gradient(90deg, #FF0000 0.83%, #E9FA00 15.6%, #22FF01 32.13%, #00E5FF 46.59%, #1803FF 65.19%, #FF00E9 80.68%, #FF0030 100%)',
          height: '3px'
        }, 
        "& .MuiSlider-track": {
          height: '1px',
          border: 'none',
          color: 'transparent'
        }
      },
};

export const useStyles = makeStyles({
    GroupDetails: {
        Slider: SliderCss
    },
    GroupSelect:{
      '& .MuiOutlinedInput-input':{
        padding:'4px 15px !important'
      }
    },
    CanvasTitleHeader:{ minHeight: "40px",padding:'5px' },
    CloseEditor:{position: "fixed !important", top: "12px", right: "200px", zIndex: "1500", borderRadius: "20px !important"}
});

export const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '200ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#OF8D48',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 200,
    }),
  },
}));
