/***
 *
 * Controller class for user.
 * @file Fota.js
 * @description Fota component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import { Box } from '@mui/material';
import React from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './Fota.style.js';
import { history } from '../../helpers';
import { useEffect } from 'react';

const Fota = () => {
  const classes = useStyles();

  useEffect(() => {
    history.replace("/fota/distribution");
  }, []);

  return (
    <Box className={classes?.Fota || ""} data-testid="Fota">
    </Box>
  )
};

Fota.propTypes = {};

Fota.defaultProps = {};

export default Fota;
