/***
 *
 * Controller class for user.
 * @file DraggableColumn.js
 * @description DraggableColumn component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './DraggableColumn.style.js';
import './DraggableColumn.scss';
import { Box, Grid, Paper, Checkbox, ListItem, ListItemButton, ListItemIcon, ListItemText, List } from '@mui/material';
import CustomTypography from '../CustomTypography/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { Draggable, Droppable } from "react-beautiful-dnd";
import _ from 'lodash';
import ImageWrapper from '../ImageWrapper/index.js';
import Move from '../../assets/icons/drag-move.svg';
import { dragAndDropActions } from '../../redux/actions/index.js';
import StyledSpinner from '../StyledSpinner/index.js';
import SearchWrapper from '../SearchWrapper/index.js';
import StyledButton from '../StyledButton/index.js';

// const onDragEnd = (result, checkedItems, setCheckedItems) => { //need checked items list and its setter
//   if (!result.destination) return;
//   const { source, destination } = result;
//   if (source.droppableId ==='leftList' && destination.droppableId==='rightList') {
//     let sourceItems = [...leftList];
//     let destItems = [...rightList];
//     const [removed] = sourceItems.splice(source.index, 1);
//     destItems.splice(destination.index, 0, removed);
//     if(checkedItems.includes(removed?.id)){
//       let temperoryCheckedItems = checkedItems.filter(item => item?.id!==removed?.id)
//       let splitSourceItems = sourceItems.reduce((final, current) => {
//         final[temperoryCheckedItems.includes(current?.id) ? 'toBeMovedToRight' : 'toBeKeptLeft'].push(current);
//         return final;
//       }, { toBeKeptLeft: [], toBeMovedToRight: [] });
//       sourceItems = splitSourceItems.toBeKeptLeft
//       destItems = [...destItems, ...splitSourceItems.toBeMovedToRight];
//       setCheckedItems([])
//     }
//     setLeftList(sourceItems)
//     setRightList(destItems)
//   }
// }

// const searchstyle = {
//   search: {
//       background: '#0F8D480D',
//       padding: '20px 20px 20px 20px',
//       '& .css-1jlqqfu-MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root': {
//           borderRadius: '14px',
//           background: '#fff'
//       }
//   },
//   tree: {
//       marginTop: '10px',
//       padding: '0 10px'
//   }
// }

const DraggableColumn = ({ forList = 'Devices', checkedItems, setCheckedItems, loading }) => {
  const classes = useStyles()
  const dispatch = useDispatch();

  const leftList = useSelector(state => state?.dragAndDrops?.dragList);

  const handleChecking = (e, item) => {
    let temperoryCheckedItems = checkedItems;
    if (!e.currentTarget.checked && checkedItems.indexOf(item?.id) >= 0) {
      temperoryCheckedItems.splice(checkedItems.indexOf(item?.id), 1)
    }
    else {
      temperoryCheckedItems.push(item?.id);
    }
    setCheckedItems([...temperoryCheckedItems])
  }

  const handleSearchListSelection = (e, item) => {
    if (item) {
      let temporaryCheckedItems = checkedItems;
      if (temporaryCheckedItems.indexOf(item?.id) >= 0) {
        temporaryCheckedItems.splice(checkedItems.indexOf(item?.id), 1)
      }
      else {
        temporaryCheckedItems.push(item?.id);
      }
      setCheckedItems([...temporaryCheckedItems]);
      //document.getElementById(`${item['id']}`).scrollBy({ top: 90, behavior: 'instant' });   
      const element = document.getElementById(`${item['id']}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  const handleAdd = () => {
    let splitLeftList = leftList.reduce((final, current) => {
      final[checkedItems.includes(current?.id) ? 'toBeMovedToRight' : 'toBeKeptLeft'].push(current);
      return final;
    }, { toBeKeptLeft: [], toBeMovedToRight: [] });

    //dispatch to dragList(splitLeftList.toBeKeptLeft) no need 
    // dispatch to dropList([...old drop list,...splitLeftList.toBeMovedToRight])
    dispatch(dragAndDropActions.addToDropList(splitLeftList.toBeMovedToRight))
    setCheckedItems([])
  }

  return (
    <Box className={classes?.DraggableColumn || ""} data-testid="DraggableColumn">
      <Grid item container sx={{ height: "100%" }} xs={12}>
        <Paper sx={{ width: '100%', maxHeight: '500px', height: '100%', position: 'relative', border: "1px solid #D9D9D9" }}  >
          <Box sx={{ background: '#0F8D480D', padding: '18px 24px 0px' }}>
            <CustomTypography content={`Select ${forList} in Space`} weight={600} size={12} lineHeight={14.63} color="rgba(0, 0, 0, 0.8)" />
          </Box>

          <SearchWrapper
            placeholder={`Search ${forList}`}
            data={leftList.length !== 0 ? leftList : [{ name: 'No Item Available' }]}
            autoComplete={true}
            onChange={(e, value) => { handleSearchListSelection(e, value) }}
            autoFill={false}
          />

          {loading ? <Grid container item justifyContent={'center'}><StyledSpinner item mt={'130px'} /></Grid> :
            <Droppable droppableId={'leftList'} key={'leftList'}>
              {(provided, snapshot) => {
                return (
                  <Box className="draggableArea" sx={{ height: '330px', overflowY: 'scroll' }}  {...provided.droppableProps} ref={provided.innerRef}>
                    <Grid item container sx={{ mt: '4px', pl: '4px' }}>
                      <List sx={{ width: '100%', padding: "5px 20px" }}>
                        {!_.isEmpty(leftList) && leftList.map((item, index) => {
                          // let id = item?.deviceId!==undefined ? item.deviceId : (item?.groupId!==undefined ? item.groupId : item?.sceneId)
                          let id = item?.id
                          return (
                            <Draggable
                              key={id}
                              draggableId={id}
                              index={index}
                            >
                              {(provided, snapshot) => {
                                return (
                                  <ListItem
                                    key={id}
                                    id={id}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      ...provided.draggableProps.style
                                    }}
                                    sx={{ border: '1.4px solid #D9D9D9', borderRadius: '4px', mb: '5px' }}
                                    disablePadding
                                  >
                                    <ListItemButton onClick={(e) => { handleChecking(e, item) }} disableRipple sx={{ padding: "2px 10px", height: '40px', lineHeight: '40px' }}>
                                      <ListItemIcon sx={{ minWidth: "auto" }}>
                                        <Checkbox
                                          edge={"start"}
                                          checked={checkedItems.includes(item?.id)}
                                          disableRipple
                                        />
                                        <ListItemText />
                                      </ListItemIcon>
                                      <ListItemText id={id} primary={
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                          <ImageWrapper className='icon-right' src={item.icon} />
                                          <span className='draggableItem' style={{}}>{item?.name}</span>
                                          <ImageWrapper className='move-icon' src={Move} />
                                        </Box>
                                      } />
                                    </ListItemButton>
                                  </ListItem>
                                )
                              }}
                            </Draggable>
                          )
                        })
                        }
                      </List>
                    </Grid>
                    {provided.placeholder}
                  </Box>
                )
              }}
            </Droppable>}
          <StyledButton sx={{ position: 'absolute', width: '80px', bottom: '8px', right: '20px' }} size='small' color='secondary' onClick={handleAdd}>Add</StyledButton>
        </Paper>
      </Grid>
    </Box>
  )
};

DraggableColumn.propTypes = {};

DraggableColumn.defaultProps = {};

export default DraggableColumn;
