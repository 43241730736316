import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    AutoComplete: {
        width: "100%",
        '& .MuiOutlinedInput-root': {
            padding: "1px 8px",
            '& .MuiOutlinedInput-input': {
                fontSize: theme?.fontSize?.search || "0.875rem",
                lineHeight: theme?.lineHeight?.search || "18px",
                height: theme?.height?.search || "24px",
            },
            '& fieldset': {
                // border: "10px #333 solid",
                top: 0,
                '& legend': {
                    border: "10px #333 solid",
                    display: "none"
                }
            },
            '& svg': {
                width: "18px",
                height: "18px"
            }
        }
    }

}));