import _ from "lodash";
import { spaceActions } from "../redux/actions";
// import { deepCopy, delay } from "../services";


export function getSpaces(data, mapObj = {}) {
    mapObj[data?.spaceId] = { parentsId: data?.parentSid, hasChildren: !_.isEmpty(data?.Spaces), nodeData: data }

    if (!data || !data?.Spaces) {
        return null;
    }

    for (const node of data['Spaces']) {
        getSpaces(node, mapObj);
    }
    return { mapObj: mapObj };
}

export const selectSpace = (space, dispatch) => {
    if (space && space?.spaceId) {
        // console.log("LeftSpaceList selectSpace ---------- ", space);

        dispatch(spaceActions.selectSpace(space));
        dispatch(spaceActions.createBreadcrumbsData({ childrenKey: "Spaces", obj: { key: "spaceId", value: space?.spaceId } }));
        // dispatch(deviceActions.getDevicesBySpaceId(space?.spaceId, "", {getChildSpaceDevices: true}));
    }
}

export const getParentFromPath = (pathString, child) => {
    if (isNaN(child) || child < 0 || !_.isString(pathString)) {
        return ""
    }

    const pathArray = pathString.split('.');

    if (child < pathArray.length) {
        return pathArray[child];
    }
    else if (pathArray.length === 1) {
        return pathArray[0];
    }

    return "";

}


export const spaceTreeWithAssets = (spaceObject, asset) => {
    let obj = {};
    function helperFunction(spaceObject, obj) {
        if (spaceObject?.spaceId === null) {
            return;
        }
        spaceObject['spaceLayout'] = asset?.url;
        if (Array.isArray(spaceObject['Spaces'])) {
            for (let space of spaceObject['Spaces']) {
                helperFunction(space, obj);
            }
        }
    }

    helperFunction(spaceObject, obj);

    return spaceObject;

}
export const getLightSpacePaths = (deviceList) => {
    const spaces = {};
    deviceList = deviceList?.filter(d => d?.category?.includes("LightDriver"));
    for (let d in deviceList) {
        const device = deviceList[d];
        const ind = device?.mqttTopics?.control?.indexOf("Driver/") + 7;
        spaces[device?.spaceId] = device?.mqttTopics?.control?.substring(ind);
    }
    return Object.values(spaces);
};

const treeNode = (data, spaceId) => {
    // console.log("SpaceTree step - 2", spaceId, data);
    let children = [];
    children = data.filter(space => space.parentSid === spaceId);
    // console.log("SpaceTree step - 3", children)
    if (children && children[0] && children[0]["spaceId"]) {
        children = children.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        return Promise.all(children?.map(async child => {
            // console.log("SpaceTree step - 4", children, child.spaceId)
            child["Spaces"] = children ? await treeNode(data, child.spaceId) : [];
            // console.log("SpaceTree step - 5", child);
            return child;
        }));
    } else {
        return [];
    }
}

export const tree = async (rootSpaces, spaces) => {
    return await Promise.all(rootSpaces.map(async space => {
        let children = [];
        children = spaces.filter(s => s.parentSid === space.spaceId);
        if (children && space) {
            space["Spaces"] = await treeNode(spaces, space?.spaceId);
        }
        return space;
    }))
}

export const searchTree = (arr, query) => {
    return query ? arr.reduce((acc, item) => {
        if( item?.Spaces?.length > 0 ){
            // console.log("handleChange --- ", acc, item);
           const filtered = searchTree(item.Spaces, query);
           if( filtered.length > 0 ) { return [...acc, {...item, Spaces: filtered}]; }
        }
    
        const {Spaces, ...itemWithoutChildren} = item;
        return item.name?.toLowerCase().includes(query.toLowerCase()) ? [...acc, itemWithoutChildren] : acc   
      }, []) : arr;
}
