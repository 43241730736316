/***
 *
 * Controller class for user.
 * @file ReportSchedules.js
 * @description ReportSchedules component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 *
 */

import React from "react";
import { useStyles } from "./ReportList.style.js";
import { useState, useEffect } from "react";

import "./ReportList.scss";
import { Box } from "@mui/material";
import { reportActions } from "../../redux/actions/report.actions.js";
import { useSelector, useDispatch } from "react-redux";
import CustomTable from "../CustomTable/index.js";
import PageHeader from "../PageHeader/index.js";
import DataNotFound from "../../pages/DataNotFound/index.js";
import ImageWrapper from "../ImageWrapper/index.js";
import { history, } from "../../helpers/history.helper.js";
import { formatedDate } from "../../helpers/date.helper.js";
import { isAuthorised } from "../../helpers/users.helper.js";
import { Link } from "react-router-dom";
import StyledChip from "../StyledChip/index.js";

import reports from "../../assets/icons/report.svg";
import scheduledIcon from "../../assets/icons/scheduled.svg";
import share from "../../assets/icons/share.svg";
import Avatar from "@mui/material/Avatar";
import { stringAvatar } from "../../helpers/users.helper.js";
import StyledSnackBar from "../StyledSnackBar/index.js";
import { features, permissions } from "../../constants/permission.constants.js";
import StyledPopupWrapper from "../StyledPopupWrapper/index.js";
import FormWrapper from "../FormWrapper/index.js";
import { reportMessages, categories, scopeList } from "../../constants/index.js";
import StyledButton from "../StyledButton/index.js";


const reverseAccessListMap = {
  public: "Public",
  private: "Private",
};

const weekDayMap = {
  1: "MON",
  2: "TUE",
  3: "WED",
  4: "THU",
  5: "FRI",
  6: "SAT",
  7: "SUN",
};

const statusComponent = (row) => {
  const currentDate = new Date();
  const expiryDate = row.data.expiresAtSecs
    ? new Date(row?.data?.expiresAtSecs)
    : new Date();
  return (
    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <StyledChip
        label={
          row.data.expiresAtSecs
            ? expiryDate.getDate() > currentDate.getDate()
              ? "Expired"
              : "Scheduled"
            : "Executed"
        }
        variant="outlined"
        icon={
          <span
            style={{
              backgroundColor: "rgba(15, 141, 72, 1)",
              height: "6px",
              width: "6px",
              borderRadius: "50%",
              display: "inline-block",
            }}
          ></span>
        }
        sx={{
          "& .MuiChip-label": {
            color: "rgba(15, 141, 72, 1)",
            p: 0.5,
            mr: "6px",
            ml: "8px",
            fontSize: '0.875rem',
            fontWeight: '400'
          },
          mt: 0,
          mr: 0,
          mb: 0,
          minWidth: "80px",
          maxHeight: "18px",
          border: "1.5px solid rgba(15, 141, 72, 1)",
          fontWeight: "500",
        }}
      />
    </Box>
  );
};

const imageIconSelector = (row) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginRight: "5px",
      }}
    >
      {row.data.duration && <ImageWrapper src={scheduledIcon} />}
    </Box>
  );
};

const shareFrmConfig = [
  {
    section: null,
    columns: [
      {
        id: "1",
        type: "freetextchipinput",
        label: "Add Subscribers",
        name: "subscribers",
        placeholder: "Press enter to add new emails",
        width: "300px",
        visibleIn: ["edit", "view", "create"],
        chipsPosition: "bottom",
        validation: {
          req: [
            true, `${reportMessages["report-shareReportValidation-email"]}`],
          email: [
            true, `${reportMessages["report-shareReportValidation-emailInvalid"]}`
          ]
        },
        columnSize: 12,
      },
    ]
  }
]

const ReportList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const _reportList = useSelector((state) => state?.reports?.reportList);
  const [reportList, setReportList] = useState();
  const [openSharePopup, setOpenSharePopup] = useState(false);

  const [shareFrm, setShareFrm] = useState({});

  const [shareRowData, setShareRowData] = useState(null);
  const [snackBar, setSnackBar] = useState({
    show: false,
    type: "error",
    message: "Document unavailable for sharing",
  });

  const closeSnackBar = () => {
    setSnackBar({
      show: false,
      type: "error",
      message: "",
    });
  };

  const handleShare = (data) => {
    if (shareRowData && shareRowData.lastDocumentId) {
      let payload = data?.subscribers || [];
      dispatch(
        reportActions.shareReportDocument(
          shareRowData?.reportId,
          shareRowData?.lastDocumentId,
          payload
        )
      );
    } else {
      setSnackBar({
        show: true,
        type: "error",
        message: "Document unavailable for sharing",
      });
    }
  };

  const shareCustomSx = {
    dialog: {
      "& .MuiDialog-paper": { width: "500px" },
      "& .css-1t5rjz-MuiPaper-root-MuiDialog-paper": { maxWidth: "800px" },
    },
  };

  const shareActionButtons = [
    {
      heading: null,
      columns: [
        {
          heading: null,
          buttons: [
            {
              id: "bckbtn",
              type: "button",
              display: true,
              variant: 'outlined',
              label: "Cancel",
              onClick: (event, data) => {
                setOpenSharePopup(false);
              }
            },
            {
              id: "savebtn",
              type: "button",
              display: true,
              checkValidation: true,
              label: 'Save',
              onClick: (event, data) => {
                handleShare(data)
                setOpenSharePopup(false);
              },
            },
          ],
          columnSize: 12,
        },
      ],
    },
  ];

  const headerList = {
    name: {
      label: "Report Name",
      type: "component",
      style: { minWidth: "106px" },
      component: (row) => {
        return (
          <Link
            key={"reportName" + row?.data?.reportId}
            underline="hover"
            color="primary"
            to={`/reports?op=view&reportId=${row?.data?.reportId}`}
            onClick={() => { }}
            style={{ color: "rgba(15, 141, 72, 1)", fontSize: '0.875rem' }}
          >
            {row && row.data && row.data.duration && row.data.schedule ? (
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                {imageIconSelector(row)}
                <span>{row?.data?.name || ""}</span>
              </Box>
            ) : (
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <span style={{ marginLeft: "29px" }}>
                  {row?.data?.name || ""}
                </span>
              </Box>
            )}
          </Link>
        );
      },
      toolTip: {
        content: (data) =>
          data?.duration && data?.schedule ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  fontWeight: "700",
                  fontSize: '0.875rem',
                  marginBottom: "10px",
                }}
              >
                Scheduled Report
              </div>
              {data?.schedule?.dayOfWeek && <div
                style={{
                  fontWeight: "500",
                  fontSize: '0.75rem',
                  marginBottom: "5px",
                }}
              >
                {`Day: ${data?.schedule?.dayOfWeek
                  .map((w) => {
                    return weekDayMap[w];
                  })
                  .join(" | ")
                  }`}
              </div>}
              <div
                style={{
                  fontWeight: "500",
                  fontSize: '0.75rem',
                  marginBottom: "5px",
                }}
              >{`Time: ${data?.schedule?.timeOfDay
                .map((t) => {
                  let chars = t.split("");
                  chars.splice(2, 0, ":");
                  return chars.join("");
                })
                .join(" | ") || ""
                }`}</div>
              <div
                style={{
                  fontWeight: "500",
                  fontSize: '0.75rem',
                  marginBottom: "5px",
                }}
              >{data?.expiresAtSecs ? `Expiry: ${formatedDate({ date: data.expiresAtSecs * 1000, format: "MMM dd, yyyy", })}` : ''}</div>
            </div>
          ) : (
            <div style={{ fontWeight: "700", fontSize: '0.875rem' }}>
              Unschedule Report
            </div>
          ),
      },
    },
    access: {
      label: "Access",
    },
    createdByUsername: {
      label: "Created By",
      type: "component",
      component: (row) => {
        return (
          <Box sx={{ display: "flex" }}>
            <Avatar
              style={{ display: "inline-flex", fontSize: "1.2rem" }}
              {...stringAvatar(`${row?.data?.createdByUsername}`)}
            />
            <span style={{ marginLeft: "5px", marginTop: "5px" }}>
              {row?.data?.createdByUsername}
            </span>
          </Box>
        );
      },
    },
    scope: {
      label: "Scope",
      type: "list",
      separator: " | ",
      // style: { fontSize: '0.75rem' },
    },
    categories: {
      label: "Category",
      type: "list",
      separator: " | ",
      // style: { fontSize: '0.75rem' },
    },
    status: {
      label: "Status",
      type: "component",
      component: statusComponent,
    },
    lastUpdatedOn: {
      label: "Last Updated At",
      type: 'date'
    },
    ...(() => {
      if (isAuthorised(features?.report, permissions?.share)) {
        return {
          userActions: {
            label: "Actions",
            actions: [
              {
                label: "Share",
                type: "share",
                icon: share,
                onClick: (event, data) => {
                  setShareRowData(data);
                  setOpenSharePopup(true);
                },
              },
            ],
            style: { textAlign: "right" },
          }
        }
      }
    })()
  };

  useEffect(() => {
    dispatch(reportActions.getAllReports({}));
  }, [dispatch]);

  useEffect(() => {
    if (_reportList) {
      const transformedReportList = _reportList.map((report) => {
        return {
          ...report,
          scope:
            report && report.scope
              ? report.scope.map((val) => {
                return scopeList?.find(s => s?.value === val)?.label;
              })
              : [],
          categories:
            report && report.categories
              ? report.categories.map((val) => {
                return categories?.find(c => c?.value === val)?.label;
              })
              : [],
          lastUpdatedOn:
            report && report.lastUpdatedOn
              ? report.lastUpdatedOn
              : report && report.createdOn
                ? report.createdOn
                : "",
          access:
            report && report.access ? reverseAccessListMap[report.access] : "-",
        };
      });
      setReportList(transformedReportList);
    }
  }, [_reportList]);

  return (
    <Box className={classes?.ReportList} data-testid="ReportList">
      <DataNotFound
        show={reportList && reportList.length > 0 ? false : true}
        customsx={{
          image: { height: "202px", width: "100%" },
          label: {},
          content: {},
          btns: {},
        }}
        data={{
          label: "Reports",
          content:
            "Keep a check on every Space, Create a New Report to see the details",
          action: [
            {
              id: "1001",
              type: "button",
              label: "Create Report",
              onClick: () => {
                history.push("/reports?op=create");
              },
            },
          ],
        }}
      >
        <>
          <PageHeader title={"Reports"} icon={reports}>
            {isAuthorised(features?.report, permissions?.add) &&
              <StyledButton onClick={() => { history.push("/reports?op=create"); }}>Create Report</StyledButton>}
          </PageHeader>
          <CustomTable
            headerList={headerList}
            cellMatrix={reportList}
            pagination={true}
            removeSortFrom={["userActions", "status"]}
            searching={{ reset: true, columns: ["name", "access", "createdByUsername", "scope", "categories"] }}
          ></CustomTable>
          <StyledPopupWrapper title={"Share Report"} open={openSharePopup} close={false} customsx={shareCustomSx}>
            <FormWrapper
              formFields={shareFrmConfig}
              setPayload={setShareFrm}
              formButtons={shareActionButtons}
            />
          </StyledPopupWrapper>
        </>
      </DataNotFound>
      <StyledSnackBar
        open={snackBar?.show}
        onClose={closeSnackBar}
        autoHideDuration={5000}
        data={{
          type: snackBar?.type,
          message: snackBar?.message,
        }}
      />
    </Box>
  );
};
ReportList.propTypes = {};

ReportList.defaultProps = {};

export default ReportList;
