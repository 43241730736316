import { automationType } from '../types/automation.type';

let initialState = {
    loading: false,
    automationRulesList: [],
    automationRuleById: {},
    error: null
}

export function automation(state = initialState, action) {
    switch (action?.type) {
        case automationType.GET_AUTOMATION:
        case automationType.GET_RULE_BY_ID:
        case automationType.DELETE_REQUEST:    
            return {
                ...state,
                loading: true
            };
        case automationType.GET_AUTOMATION_SUCCESS:
            return {
                ...state,
                loading: false,
                automationRulesList: action.payload,
                automationRuleById: {}
            };
        case automationType.CREATE_AUTOMATION:
            return {
                ...state,
                loading: false,
            };
        case automationType.CREATE_AUTOMATION_SUCCESS:
            return {
                ...state,
                loading: false,
                automationRulesList: state.automationRulesList === null ? [action.payload] : [...state.automationRulesList, action.payload]
            };
        case automationType.GET_RULE_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                automationRuleById: action.payload
            }

        case automationType.DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                automationRulesList: state?.automationRulesList?.filter(rule => rule.ruleId !== action.ruleId),
            }

        case automationType.UPDATE_AUTOMATION_SUCCESS:
            return{
                ...state,
                automationRulesList: state.automationRulesList.map((rule) => {
                    let updatedRule = {};
                    if(rule.ruleId === action.payload.ruleId) {
                        updatedRule = action.payload;
                    }
                    else {
                        updatedRule = rule;
                    }
                    return updatedRule;
                }),
                automationRuleById: action.payload,
                loading: false
        }
        case automationType.GET_RULE_BY_ID_FAILURE:
            return {
                ...state,
                loading: false,
                automationRuleById: {}
            }
        
        case automationType.DELETE_FAILURE:
        case automationType.UPDATE_AUTOMATION_FAILURE: 
            return {
                ...state,
                error: action.payload.error,
                loading: false
            }

        default:
            return state
    }
}

