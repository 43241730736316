/***
 *
 * Controller class for user.
 * @file Circle.js
 * @description Circle component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useRef, useEffect, useCallback } from "react";
import { Circle as KonvaCircle, Transformer } from "react-konva";
// import PropTypes from 'prop-types';
import './Circle.scss';

import { LIMITS } from "../../../../constants/canvas.constants";
import { canvasActions } from "../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { closestPointsInPolygon, dragBoundCircle, dragBoundCirlceParentToCircle, dragBoundFunc, dragBoundRectParent, fillColorInShapes, haveIntersection, outsidePolygon } from "../../../../helpers";


const Circle = ({ id, isSelected, type,onSelect, onChange, draggable, profileSpaceId, highlight, handleDrag=()=>{}, handleClickShape=()=>{},  spaceIds,nodezIndex,getZIndex,...shapeProps }) => {
  const shapeRef = useRef();
  const transformerRef = useRef();
  const getSelectedShape = useSelector(state=>state?.canvas?.selectedShape);
  const _getModalState = useSelector(state=>state?.canvas?.modalState);
  const _selectedSpace = useSelector(state=>state?.spaces?.selectedSpace);
  const _shapeList =  useSelector(state=>state?.canvas?.shapes);

  const [selectedShape, setSelectedShape]= useState(null);
  const dispatch= useDispatch();

  useEffect(() => {
    if (isSelected) {
      transformerRef.current.nodes([shapeRef.current]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  useEffect(()=>{
    if(getSelectedShape){
      setSelectedShape(getSelectedShape);
    }
  },[getSelectedShape])

  useEffect(()=>{
    if(selectedShape){     
        shapeRef.current.zIndex(shapeProps?.zIndex);      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedShape])


  const boundBoxCallbackForCircle = (oldBox, newBox) => {
    const parentShape = _shapeList?.find(shape=>shape?.spaceId === _selectedSpace?.parentSid);
    // limit resize
    if (
      newBox.width < LIMITS.CIRCLE.MIN ||
      newBox.height < LIMITS.CIRCLE.MIN ||
      newBox.width > LIMITS.CIRCLE.MAX ||
      newBox.height > LIMITS.CIRCLE.MAX
    ) {
      return oldBox;
    }

    if(shapeRef &&  
    ( newBox?.x < 0 ||
     newBox?.y < 0 ||
     newBox?.x + newBox?.width >  shapeRef?.current.getStage().width() ||
     newBox?.y + newBox?.height >  shapeRef?.current.getStage().height())     
     ){
      return oldBox
    }


    if(parentShape &&  parentShape?.type === 'rect' &&
   ( newBox?.x < parentShape?.x ||
    newBox?.y < parentShape?.y ||
    newBox?.x + newBox?.width > parentShape?.x + parentShape?.width ||
    newBox?.y + newBox?.height > parentShape?.y + parentShape?.height)
    ){
      return oldBox
    }

    if (parentShape &&  parentShape?.type === 'circle' &&
    ( 
    (newBox.x - parentShape.x)*(newBox.x - parentShape.x)+ (newBox.y - parentShape.y)*(newBox.y - parentShape.y) > parentShape?.radius * parentShape?.radius ||
    (newBox.x + newBox?.width - parentShape.x)*(newBox.x + newBox?.width - parentShape.x)+ (newBox.y + newBox?.height - parentShape.y)*(newBox.y + newBox?.height - parentShape.y) > parentShape?.radius * parentShape?.radius ||
    (newBox.x - parentShape.x)*(newBox.x - parentShape.x)+ (newBox.y + newBox?.height - parentShape.y)*(newBox.y + newBox?.height - parentShape.y) > parentShape?.radius * parentShape?.radius ||
    (newBox.x + newBox?.width - parentShape.x)*(newBox.x + newBox?.width - parentShape.x)+ (newBox.y - parentShape.y)*(newBox.y - parentShape.y) > parentShape?.radius * parentShape?.radius
    )
  ) {
    return oldBox
  }


    return newBox;
  };

  const handleSelect = useCallback(
    (event) => {
      if(_selectedSpace?.spaceId === shapeProps?.spaceId){
       event.cancelBubble = true;
      dispatch(canvasActions.selectShape(id));
      handleClickShape(event,shapeProps)
    }
      // onSelect(shapeRef);
    },
    [id]
  );


  const handleDragEvent = useCallback(
    (event) => {
      // if (selectedShape) {
        dispatch(canvasActions.moveShape(selectedShape, event));
      // } 
      handleDrag(event,selectedShape);
      dispatch(canvasActions.selectShape(id));      
    },
    [selectedShape]
  );

  const handleTransform = useCallback(
    (event) => {
      if (selectedShape) {
        dispatch(canvasActions.transformCircleShape(shapeRef.current, selectedShape, event));
      } 
      
      const node = shapeRef.current;
      // we will reset it back
      node.scaleX(1);
      node.scaleY(1);
    },
    [selectedShape]
  );

  const handleDragMove=(e)=>{
    const pos= shapeRef.current.absolutePosition();
    const radius =shapeRef.current.attrs.radius;
    
    let w = 1*radius, h = 1*radius; 
    const parentShape = _shapeList?.find(shape=>shape?.spaceId === _selectedSpace?.parentSid);
    let rectBox={x: shapeRef?.current?.attrs?.x -radius, y: shapeRef?.current?.attrs?.y - radius}  
    // console.log('parent shape:::',shapeRef.current.attrs, rectBox, pos);
    const PW =parentShape?.width;
    const PH =parentShape?.height;
    e.target.x(dragBoundCircle(shapeRef.current.getStage().width(), shapeRef.current.getStage().height(),radius,pos).x);
    e.target.y(dragBoundCircle(shapeRef.current.getStage().width(), shapeRef.current.getStage().height(),radius,pos).y);
    if(parentShape?.type === 'rect'){
    e.target.x(dragBoundRectParent(PW,PH,w,h,parentShape?.x, parentShape?.y, pos, radius , 3).x);
    e.target.y(dragBoundRectParent(PW,PH,w,h,parentShape?.x, parentShape?.y, pos, radius, 3).y);
    }
    else if(parentShape?.type === 'circle'){
      const PR = parentShape?.radius;
      e.target.x(dragBoundCirlceParentToCircle(PR, parentShape?.x, parentShape?.y, pos, radius ).x);
      e.target.y(dragBoundCirlceParentToCircle(PR, parentShape?.x, parentShape?.y, pos, radius ).y);
    }
    else if (parentShape?.type === 'polygon'){
      let scrollY = document.getElementById('paperDOM').scrollTop, scrollX = document.getElementById('paperDOM').scrollLeft;  /// to get how much screen has been scrolled
      let center = [pos?.x + w/2 - scrollX, pos?.y + h/2 - scrollY];
      const isOutside =  outsidePolygon(parentShape?.points, center, scrollX, scrollY);
      if (isOutside) {
        let closest = closestPointsInPolygon(parentShape?.points, center);
        let x_coord = center[0] , y_coord= center[1];
        if (x_coord < Math.min(closest.x1, closest.x2)) {
          x_coord = Math.min(closest.x1, closest.x2) ;
        }
        if (x_coord > Math.max(closest.x1, closest.x2)) {
          x_coord = Math.max(closest.x1, closest.x2) ;
        }   
       
        // y = m * (x-a) +b; here (a,b) is the point of intersection of image center with the polygon line
        y_coord = (closest.slope) * (x_coord - (closest.intersection)[0]) + (closest.intersection)[1];
  
        if (y_coord < Math.min(closest.y1, closest.y2)) {
          y_coord = Math.min(closest.y1, closest.y2)  ;
        }
        if (y_coord  > Math.max(closest.y1, closest.y2)) {
          y_coord = Math.max(closest.y1, closest.y2) ;
        }
        
        e.target.x(x_coord -12); // <---- 
        e.target.y(y_coord -12 ); //<---- 
      
  
      }
    }

  }

const dragTransformer =(e)=>{
    let target = e.target;
    let targetRect = e.target.getClientRect();
    let siblings = shapeRef?.current?.getStage()?.getChildren()?.filter(child => (child.getAttrs()['data-parentlayerid'] === _selectedSpace?.parentSid) && child.getAttrs()['data-layerid'] !== _selectedSpace?.spaceId);

    siblings.forEach(layer => {
      if (layer === target?.getLayer()) return;
      if (haveIntersection(layer.getClientRect(), targetRect, target?.getAttrs()['data-testid'])) {
        layer.findOne('.fillShape').fill('#fe0000');
      } else {
        layer.findOne('.fillShape').fill('rgba(0,0,0,0.5)');
      }
    })   

}

  return (
    <>
      <KonvaCircle
        data-testid="Circle" 
        data-type="shape"
        data-spaceId={shapeProps?.spaceId}
        onClick={handleSelect}
        onTap={handleSelect}
        onDragStart={handleSelect}
        onDragMove={handleDragMove}
        ref={shapeRef}
        dragBoundFunc={(pos)=>{dragBoundFunc(shapeRef.current.getStage().width(), shapeRef.current.getStage().height(), shapeRef.current.attrs?.radius, pos)}}
        opacity={ spaceIds?.find(s=>s?.spaceId === shapeProps?.spaceId) ? 1 : 0.5}
        x={shapeProps?.x || 500}
        y={shapeProps?.y || 500}
        width={shapeProps?.width || 30}
        height={shapeProps?.height || 30}
        radius={shapeProps?.radius}
        fill={ fillColorInShapes(shapeProps,_selectedSpace,spaceIds, _shapeList?.find(s=>s?.spaceId === _selectedSpace?.spaceId) ? true : false)}        
        stroke={shapeProps?.spaceId === _selectedSpace?.spaceId ? '#1616f7' : '#77e2f0'}
        strokeWidth={3}
        draggable={_getModalState && draggable}
        droppable
        name="fillShape"
        onDragEnd={handleDragEvent}
        onTransformEnd={handleTransform}        
      />
    
      {isSelected && shapeProps?.spaceId === _selectedSpace?.spaceId && (
        <Transformer
          anchorSize={5}
          borderDash={[6, 2]}
          ref={transformerRef}
          rotateEnabled={false}
          enabledAnchors={[
            "top-left",
            "top-right",
            "bottom-right",
            "bottom-left",
          ]}
          onTransform={dragTransformer}
          boundBoxFunc={boundBoxCallbackForCircle}
        />
      )}
    </>
  );
}

Circle.propTypes = {};

Circle.defaultProps = {};

export default Circle;
