import polygon from '../assets/img/polygon.png'

export const SHAPE_TYPES = {
    RECT: "rect",
    CIRCLE: "circle",
    TRIANGLE: "triangle",
    IMAGE: "image"
  };
  
  export const DEFAULTS = {
    RECT: {
      STROKE: "#000000",
      FILL: "#ffffff",
      WIDTH: 100,
      HEIGHT: 80,
      ROTATION: 0,
      OPACITY: 0.6,
      TEXT:"its a rectangle",
    },
    CIRCLE: {
      STROKE: "#000000",
      FILL: "#ffffff",
      RADIUS: 30,
      OPACITY: 0.6,
      TEXT:"its a circle"
    },
    TRIANGLE: {
      STROKE: "#000000",
      FILL: "#ffffff",
      RADIUS: 30,
      OPACITY: 0.6,
      TEXT:"its a triangle"
    },
    IMAGE: {
      STROKE: "#000000",
      FILL: "#ffffff",
      WIDTH: 44,
      HEIGHT: 44,
      OPACITY: 0.6,
      TEXT:"its an image"
    },
    POLYGON: {
      STROKE: "#000000",
      FILL: "#ffffff",
      WIDTH: 144,
      HEIGHT: 144,
      OPACITY: 0.6,
      TEXT:"its a polygon"
    }    
  };
  
  export const LIMITS = {
    RECT: {
      MAX: 10000,
      MIN: 30,
    },
    CIRCLE: {
      MAX: 1000,
      MIN: 30,
    },
    TRIANGLE: {
      MAX: 1000,
      MIN: 10,
    },
    IMAGE: {
      MAX: 5000,
      MIN: 5,
    },
    POLYGON: {
      MAX: 5000,
      MIN: 5,
    },
  };
  
export const SHAPES=[
    {
      "id":1010,
      "uniq_id":"",
      "type": "rect",
      "fill": "#FF13FF",
      "fillColor": "#FFFFFF",
      "transparency": 0.7,
      "width": 100.00,
      "height": 100.00,     
      "stroke": "#000000",
      "group": "abc",
      "stateToggle":0,
      "rotation": 0,
      "shapeType":"shape",
      "dash": [0],
      "x": 0,
      "y": 0
    },
    {
      "id": 1020,
      "uniq_id":"",
      "type": "circle",
      "fill": "#FF13FF",
      "transparency": 0.7,
      "radius": 50.00,      
      "group": "abcd",
      "stateToggle":0,
      "stroke": "#000000",
      "shapeType":"shape",
      "dash": [0],
      "x": 0,
      "y": 0
    }
    ,
    {
      "id": 1030,
      "uniq_id":"",
      "src": polygon,
      "type": "polygon",
      "fill": "#FF13FF",
      "fillColor": "#FFFFFF",
      "shapeType":"shape",
      "group":"pqr",
      "transparency": 0.7,
      "state":0,
      "stroke": "#000000",
      "rotation": 0,
      "points":[],
      "flattenedPoints":null,
      "position":[0,0],
      "isPolyComplete":false,
      "isMouseOverPoint":false

    }
    ,{
    "id": 1060,
    "uniq_id":"",
    "type": "image",
    "shapeType":"device",
    "borderRadius":"50%",
    "group":"mnp",
    "width": 28,
    "height": 28,
    "event": "click"
  },{
    "id": 1070,
    "uniq_id":"",
    "type": "layoutImage",
    "shapeType":"layoutImage",
    "width": 1278,
    "height": 714,
    "url":""
  }
  ]


  export const DRAG_DATA_KEY = "__drag_data_payload__";
  export const GROUP_NAME = 'group_name';
  