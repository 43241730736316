/***
 *
 * Controller class for user.
 * @file GroupDetails.js
 * @description GroupDetails component
 * @author ayeshakumar
 * @since 23 AUG 2022
 *
 */

import React, { useEffect, useState } from "react";
// import PropTypes from 'prop-types';
//import _ from "lodash";
import _ from 'lodash';
import { useDispatch, useSelector } from "react-redux";
import "./GroupDetails.scss";
import PageHeader from "../PageHeader";
import CustomTypography from "../CustomTypography";
import CustomAccordion from "../CustomAccordion";
import CustomTable from "../CustomTable";
import lampIcon from "../../assets/icons/lamp.svg";
import ReceiverGrey from "../../assets/icons/router.svg";
import Sensor from "../../assets/icons/sensor.svg";
import SwitchIcon from "../../assets/icons/switch.svg";
import { DEVICE_PHYSICAL_CONSTANTS as data, features, permissions } from "../../constants";
import StyledTabs from "../StyledTabs";
import { useQuery } from "../../hooks";
import { groupActions, deviceActions } from "../../redux/actions";
import { Box, Grid, Tooltip } from "@mui/material";
import { history } from '../../helpers/history.helper.js';
import StyledPopup from '../StyledPopup/index.js';
import { SliderCss } from "./GroupDetails.style.js";
import ImageWrapper from "../ImageWrapper";
import CheckCircle from "../../assets/icons/check-circle.svg";
import AlertIcon from "../../assets/icons/alert-icon.svg";
import Refresh from "../../assets/icons/sync.svg";
import { TableHelper } from "../TableHelper";
import { formatedDate, getMessageStr, groupControl, isAuthorised, setGroupDeleteTimeout } from "../../helpers";
import groupIcon from "../../assets/icons/group.svg";
import editIcon from "../../assets/icons/edit-2.svg";
import deleteIcon from "../../assets/icons/delete.svg";
import { Link } from "react-router-dom";
import ItemView from "../ItemView";
import { subscribe, unsubscribeAll } from "../../services";
import StyledButton from "../StyledButton";
import { groupDeleteTimeout } from "../../config/mqttConfig";


// function prepareSelectedDeviceDetails(selectedDeviceDetails) {
//   return selectedDeviceDetails.map((e) => {
//     let contents = { ...e, name: e.name ? e.name : e.deviceId }
//     return {
//       id: e.deviceId, content: contents
//     }
//   })
// }

const GroupDetails = ({ groupId, mqttClient }) => {
  const dispatch = useDispatch();
  const query = useQuery();
  if (query.get("id")) {
    groupId = query.get("id");
  }
  const [groupDetails, setGroupDetails] = useState(null);
  const [deviceTypes, setDeviceTypes] = useState(null);
  const [toggleDevice, setToggleDevice] = useState(null);
  const [groupDeviceList, setGroupDeviceList] = useState([]);
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState({ show: false });
  // const [configIdList, setConfigIdList] = useState([]);
  // const [configIdDetails, setConfigIdDetails] = useState([]);
  const [gateway, setGateway] = useState({});
  const [groupDevicesCompleteList, setGroupDevicesCompleteList] = useState([])

  const group = useSelector((state) => state?.groups?.group);
  const deviceDetails = useSelector((state) => state?.devices?.deviceDetails);
  const gatewayList = useSelector((state) => state?.devices?.gatewayList) || [];
  const groupDevices = useSelector((state) => state?.devices?.detailedDeviceList);
  const deviceCategories = useSelector(state => state?.devices?.deviceCategories);

  const [headers] = useState({
    name: {
      label: "device name",
      type: "component",
      component: ({ data }) => {
        return (
          <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            {data?.status !== 'deleted' ? <Link className="link" to={`/spaces/devices?pt=device&id=${data?.deviceId}`}>{`${data?.name && data?.name !== '' ? data?.name : data?.deviceId}`}</Link> : <CustomTypography content={`${data?.name && data?.name !== "" ? data?.name : data?.deviceId}`} />}
          </Box>
        );
      }
    },
    lightId: {
      label: "Light ID",
      type: "component",
      component: (row) => {
        return row?.data?.configuration?.lightId !== undefined ? <CustomTypography content={row?.data?.configuration?.lightId} /> : <CustomTypography content={'-'} />
      }
    },
    // syncStatus: {
    //   label: "Sync Status",
    //   type: "component",
    //   component: (row) => <TableHelper.TableStatus type={row?.data?.sync ? "synced" : "needsSync"} variant={"filled"} label={row?.data?.sync ? "Synced" : "Needs Sync"} />,
    // },
    category: {
      label: "category",
      type: "component",
      component: (row) => {
        const categoryLabel = deviceCategories?.find(c => c?.category === row?.data?.category)?.label;
        return <>{categoryLabel}</>
      }
    },
    status: {
      label: "Status",
      type: "component",
      component: (row) => {
        return (
          <TableHelper.TableStatus type={row?.data?.status} label={row?.data?.status === "active" ? "Active" : row?.data?.status === "inactive" ? "Inactive" : "Deleted"} />
        )
      },
    },
    // intensity: {
    //   label: "Intensity",
    //   type: "component",
    //   component: (row) => {
    //     return (
    //       <IntensitySlider data={row?.data} mqttClient={mqttClient} subscribe={false} disabled={true} />
    //     );
    //   },
    // }

  })
  const groupDetailsArray = {
    deviceCount: { label: "total devices" },
    groupType: { label: "group type" },
    deviceTypes: { label: "device types" },
    managedBy: { label: "Managed By", content: groupDetails?.managedBy?.substring(0, 1)?.toUpperCase() + groupDetails?.managedBy?.substring(1, groupDetails?.managedBy?.length) },
    synced: { label: "sync status" },
    dateOfCreation: { label: "created on" },
    dateLastUpdated: { label: "last updated on" },
    createdBy: { label: "created by" },
  };

  useEffect(() => {
    return () => {
      unsubscribeAll(mqttClient, dispatch)
    }
  }, [mqttClient])

  useEffect(() => {
    dispatch(groupActions.getGroupDetails(groupId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  useEffect(() => {

    if (!_.isEmpty(group)) {
      setGroupDetails({
        ...group,
        deviceCount: group.devices.length,
        groupType: group?.groupType?.join(' | ') || "",
        deviceTypes: "Light",
        synced: <Tooltip arrow placement="bottom" enterDelay={1500} title={group.synced ? "Synced":"Needs sync"}>
          <Box sx={{width:"fit-content"}}>
          <ImageWrapper height={15} width={15} src={group.synced ? CheckCircle : AlertIcon} />
        </Box></Tooltip>,
        syncStatus: group.synced,
        dateOfCreation: formatedDate({ date: group.createdOn, format: "dd MMMM yyyy" }),
        dateLastUpdated: formatedDate({ date: group.lastUpdatedOn, format: "dd MMMM yyyy" }),
      });
      // setConfigIdList(group?.devices.map((e) => e.deviceId));
      // setConfigIdDetails(prepareSelectedDeviceDetails(group?.devices));
    }
    let cats = [];
    // deviceCategories

    const deviceCategories = [
      {
        icon: lampIcon,
        label: "Lighting",
        category: [
          "AnalogLightDriver",
          "DaliLightDriver",
          "RelayLightDriver",
          "DaliLightController",
          "RelayLightController",
        ],
      },
      { icon: ReceiverGrey, label: "Receivers", category: ["Receiver"] },
      { icon: Sensor, label: "Sensors", category: ["Sensors"] },
      { icon: SwitchIcon, label: "Switches", category: ["Switch"] },
    ];
    deviceCategories.forEach((dc, i) => {
      let categoryExists = false;
      dc?.category?.forEach((c, ii) => {
        if (
          (group?.devices?.filter((d) => d.category === c) || []).length > 0
        ) {
          categoryExists = true;
        }
      });
      if (categoryExists) {
        cats.push(dc);
      }
    });
    setDeviceTypes(cats);
  }, [group]);

  useEffect(() => {
    if (groupDetails?.groupId === groupId) {
      const gwData = gatewayList?.find(g => g?.deviceId === groupDetails?.gatewayIds[0])
      setGateway(gwData);
      if (groupDetails?.managedBy === 'controller' && groupDetails?.controllerIds && groupDetails?.controllerIds[0]) {
        //calling device details API with controller ID to get controller topic
        dispatch(deviceActions?.getDevice(groupDetails?.controllerIds[0], groupDetails?.managedBy, false, getMessageStr("group-getParent-error", groupDetails?.managedBy)));
      }
      if (groupDetails?.managedBy !== 'cloud') {
        //to monitor sync status of group
        subscribe(gwData?.mqttTopics?.comStatus, dispatch, mqttClient, "groupSyncMonitor")//kept inside if condition as no subscription is required for cloud scene
      }
      if (!_.isEmpty(groupDetails?.devices)) {
        //This useEffect calls api to get info of all the devices present in the group.
        dispatch(deviceActions.getDetailedDeviceList({
          "deviceIds": groupDetails?.devices.map(deviceItem => deviceItem?.deviceId).join(',')
        }))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupDetails?.groupId])//groupId is used as change in group sync status should not trigger the use effect

  useEffect(() => {
    if (!_.isEmpty(deviceTypes) && !_.isEmpty(groupDevicesCompleteList)) {
      handleToggleDevice(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceTypes, groupDevicesCompleteList]);

  useEffect(() => {
    if (groupDetails?.managedBy === 'controller' && groupDetails?.controllerIds[0] === deviceDetails?.deviceId) {
      //subscribing to listen to update in lst or klv of group
      subscribe(deviceDetails?.mqttTopics?.status, dispatch, mqttClient, "groupMonitor");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceDetails])

  useEffect(() => {
    if (groupDetails?.managedBy === 'gateway' && groupDetails?.gatewayIds[0] === gateway?.deviceId) {
      //subscribing to listen to update in lst or klv of group
      subscribe(gateway?.mqttTopics?.status, dispatch, mqttClient, "groupMonitor");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gateway])

  //Some devices may not be present in redux store so this useEffect is checking which device details are not there and adding bare minimum information (i.e. id and category) of these devices in it. 
  useEffect(() => {
    let mergedDeviceList = group?.devices?.map((item, index) => {
      let detailedDevice = groupDevices.find(groupDevice => groupDevice?.deviceId === item?.deviceId) || {};
      return { ...detailedDevice, ...item };
    })
    setGroupDevicesCompleteList(mergedDeviceList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupDevices])

  const goTo = (route) => {
    history.push(route);
  };
  const handleDelete = (event) => {
    dispatch(groupActions.deleteGroup(groupId, () => {
      goTo("/spaces/groups");
      setGroupDeleteTimeout(groupDeleteTimeout, dispatch);
    }));

  }
  const handleToggleDevice = (index) => {
    let deviceCat = deviceTypes[index];
    setToggleDevice(deviceCat?.label);
    let deviceList = [];
    deviceCat?.category?.forEach((c, i) => {
      deviceList = [
        ...deviceList,
        ...(groupDevicesCompleteList?.filter((d) => d.category === c) || []),
      ];
    });
    setGroupDeviceList(deviceList);
  };

  // const handleNextStep = () => {
  //   setTimeout(() => {
  //     dispatch(groupActions.editGroup(groupId, dataToBeSend));
  //   }, 1500)
  //   goTo("/spaces/groups");
  // }
  // const setEditData = (data) => {
  //   let dataToBeSend = { name: groupDetails?.name, deviceIds: data };
  //   setDataToBeSend({ ...dataToBeSend });
  // }
  const handleChange = (e, value, type, setter) => {
    console.log("SETTING GROUP DETAILS", groupDetails?.managedBy, value, type)
    let deviceConfig = {};
    if (type === 'intensity') {
      //adding intenity in case function call was from intensity slider
      if (value === true) {
        value = 70;
      }
      else if (value === false) {
        value = 0
      }
      deviceConfig.lst = value;
    }
    else {
      //adding intensity in case function call was from temperature slider
      deviceConfig.klv = value;
    }
    groupControl(groupDetails, dispatch, deviceConfig, mqttClient, group?.managedBy === "gateway" ? gateway : deviceDetails, () => {
      setter(groupDetails?.groupStatus?.status?.hasOwnProperty(type) ? groupDetails?.groupStatus?.status[type] : 0);
    })
  }
  const handleConfirmation = (confirmed = false) => {
    if (confirmed) {
      openConfirmationPopup?.fun?.name(openConfirmationPopup?.fun?.params?.event, openConfirmationPopup?.fun?.params?.data);
      setOpenConfirmationPopup({ show: false, fun: null });
    } else {
      setOpenConfirmationPopup({ show: false, fun: null });
    }
  }

  const handleSync = (event) => {
    event.preventDefault();
    dispatch(
      groupActions.syncById(groupId)
    );
  }

  return (
    <div className="GroupDetails" data-testid="GroupDetails">
      <PageHeader
        bckbtn={true}
        title={groupDetails?.name}
        textTransform={'normal'}
        icon={groupIcon}
      >
        <Box sx={{ textAlign: "right" }}>
          {groupDetails !== null && groupDetails?.managedBy !== 'cloud' && !groupDetails?.syncStatus && isAuthorised(features?.device, permissions?.syncGroup) && (
            <StyledButton
              tooltip="Sync Group"
              onClick={(event) => { handleSync(event) }}
              iconButton
              label='Refresh'
              sx={{ p: 0, ml: 2 }}
            >
              <ImageWrapper width={18} height={18} src={Refresh} />
            </StyledButton>
          )}
          {isAuthorised(features?.device, permissions?.edit) &&
            <StyledButton
              iconButton
              label='Edit'
              onClick={(event) => { goTo("/spaces/groups?op=edit") }}
              tooltip="Edit Group"
              sx={{ ml: 2, p: 0 }}
            >
              <ImageWrapper width={18} height={18} src={editIcon} />
            </StyledButton>
          }
          {isAuthorised(features?.device, permissions?.delete) &&
            <StyledButton
              label='Delete'
              sx={{ ml: 2, p: 0 }}
              tooltip="Delete Group"
              onClick={() => setOpenConfirmationPopup({ show: true, fun: null })}
              iconButton
            >
              <ImageWrapper width={18} height={18} src={deleteIcon} />
            </StyledButton>
          }
        </Box>
      </PageHeader>

      <StyledPopup open={openConfirmationPopup.show} onClose={() => setOpenConfirmationPopup({ show: false, fun: null })} state="timeout"
        data={{
          content: getMessageStr("group-delete-confirmation", groupDetails?.name),
          actions: [
            {
              id: "1002",
              type: "button",
              label: "Cancel",
              onClick: (event, data) => {
                handleConfirmation(false);
              }
            },
            {
              id: "1001",
              type: "button",
              label: "Confirm",
              onClick: (event, data) => {
                handleConfirmation(true);
                handleDelete(event);
              }
            }
          ]
        }} />

      <CustomAccordion title={"Group Details"}>
        <ItemView data={groupDetails} headers={groupDetailsArray} gridSize={{ sm: 4 }}
        />
      </CustomAccordion>


      <Box sx={{ mb: 2 }}>
        <StyledTabs
          tabs={deviceTypes}
          onClick={(e, value) => {
            handleToggleDevice(value);
          }}
        />
      </Box>

      {toggleDevice === "Lighting" && (
        <Grid className="GroupControls mb-3" container item xs={12} justifyContent={'space-between'} alignItems={'center'}>
          <Grid
            item
            sx={{
              background: "#fff",
              padding: "5px 10px",
              borderRadius: "4px",
            }}
            xs={12}
            md={6}
            alignItems={"center"}
            flexDirection={"row"}
          >
            {/* <Grid item>  { This Grid item not included in Stack to align this in center, so that's why put inside this Grid item }
                <CustomTypography
                  content={"ON/OFF"}
                  weight={400}
                  size={14}
                  lineHeight={22}
                />
              </Grid> */}
            {/* <Stack direction={'row'}> */}
            {/* <Switch checked={light}  onChange={(e,value)=>handleChange(e,value,'light')} color="primary" disabled={group===null ||!group.synced}/> */}
            {/* <ControllerSlider
              icon={data?.intensity?.icon}
              label={data?.intensity?.label}
              data={data?.intensity}
              value={groupDetails?.groupStatus?.status?.intensity || 0}
              customsx={{ compo: {}, label: { width: "fit-content" }, value: { minWidth: "45px" }, labelContainer: { width: "fit-content" } }}
              name="intensity"
              suffix="%"
              setterFunction={(e, value) => { handleChange(e, value, 'intensity') }}
              params={{
                icon: true,
                label: true,
                value: (groupDetails?.groupType === "" || groupDetails?.groupType?.includes("LW_ANALOG") || groupDetails?.groupType?.includes("LW_DT")),
                switchButton: true,
                compo: (groupDetails?.groupType === "" || groupDetails?.groupType?.includes("LW_ANALOG") || groupDetails?.groupType?.includes("LW_DT"))
              }}
              disabled={group === null || !groupDetails.syncStatus || !isAuthorised(features?.device, permissions?.controlGroup)}
            /> */}
            <TableHelper.TableSlider
              icon={data?.intensity?.icon}
              label={data?.intensity?.label}
              customData={{ ...data?.intensity, value: groupDetails?.groupStatus?.status?.intensity || 0 }}
              name="intensity"
              customsx={{ compo: {}, label: { width: "fit-content" }, value: { minWidth: "45px" }, labelContainer: { width: "fit-content" } }}
              params={{
                icon: true,
                label: true,
                value: (groupDetails?.groupType === "" || groupDetails?.groupType?.includes("LW_ANALOG") || groupDetails?.groupType?.includes("LW_DT")),
                switchButton: true,
                compo: (groupDetails?.groupType === "" || groupDetails?.groupType?.includes("LW_ANALOG") || groupDetails?.groupType?.includes("LW_DT"))
              }}
              disabled={group === null || !groupDetails.syncStatus || !isAuthorised(features?.device, permissions?.controlGroup)}
              onChange={(e, value, obj, setter) => {
                handleChange(e, value, 'intensity', setter)
              }}
            />
            {/* </Stack> */}
          </Grid>
          <Grid
            item
            sx={{
              background: "#fff",
              padding: "5px 10px",
              borderRadius: "4px",
            }}
            xs={12}
            md={6}
            alignItems={"center"}
            flexDirection={"row"}
          >
            {
              (groupDetails?.groupType === "" || groupDetails?.groupType?.includes("LW_DT8")) &&
              // <ControllerSlider
              //   icon={data?.kelvin?.icon}
              //   customsx={{ ...SliderCss, value: { minWidth: "45px" } }}
              //   label={'Color Temperature'}
              //   data={data?.kelvin}
              //   value={groupDetails?.groupStatus?.status?.kelvin || 0}
              //   name="kelvin"
              //   suffix="K"
              //   setterFunction={(e, value) => handleChange(e, value, 'temperature')}
              //   params={{ icon: true, label: true, value: true, compo: true }}
              //   disabled={group === null || !groupDetails.syncStatus || !isAuthorised(features?.device, permissions?.controlGroup)}
              // />
              <TableHelper.TableSlider
                icon={data?.kelvin?.icon}
                label={'Color Temperature'}
                customData={{ ...data?.kelvin, value: groupDetails?.groupStatus?.status?.kelvin || 0, suffix: "K" }}
                name="kelvin"
                customsx={{ ...SliderCss, value: { minWidth: "45px" } }}
                params={{ icon: true, label: true, value: true, compo: true }}
                disabled={group === null || !groupDetails.syncStatus || !isAuthorised(features?.device, permissions?.controlGroup)}
                onChange={(e, value, obj, setter) => {
                  handleChange(e, value, 'kelvin', setter)
                }}
              />
            }
          </Grid>
        </Grid>
      )}
      <CustomTable
        headerList={headers}
        cellMatrix={groupDeviceList || []}
        pagination={true}
        searching={{ columns: ["deviceId", "category"] }}
        enableScrollToTop={false}
      // removeSortFrom={['intensity']}
      />
    </div>
  );
};

GroupDetails.propTypes = {};

GroupDetails.defaultProps = {};

export default GroupDetails;
