import { alertConstants } from '../../constants';
import { alertsType } from '../types';

const initialState = {
    type: "",
    message: "",
    action: "",
    alertsList: {},
    ackAlert: {},
    resolveAlert: {},
    alertTypes: {}

}

export function alert(state = initialState, action) {
    switch (action.type) {
        case alertConstants.SUCCESS:
            return {
                type: 'alert-success',
                message: action.message || "Success"
            };
        case alertConstants.ERROR:
            return {
                type: 'alert-danger',
                message: action.message || "Server error"
            };
        case alertConstants.CLEAR:
            return {};
        case alertsType.GET_ALERTS_SUCCESS:
            return {
                ...state,
                loading: false,
                alertsList: action.payload
            };
        case alertsType.ACK_ALERT_SUCCESS:
            return {
                ...state,
                loading: false,
                ackAlert: action.payload
            };
        case alertsType.RESOLVE_ALERT_SUCCESS:
            return {
                ...state,
                loading: false,
                resolveAlert: action.payload
            };
        case alertsType.GET_ALERT_TYPES_SUCCESS:
            return {
                ...state,
                loading: false,
                alertTypes: action.payload
            };
        default:
            return state
    }
}