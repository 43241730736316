/***
 *
 * Controller class for user.
 * @file StyledSelectTextField.js
 * @description StyledSelectTextField component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React from 'react';
// import PropTypes from 'prop-types';
import { useStyles, sx } from './StyledSelectTextField.style.js';
import './StyledSelectTextField.scss';
import { Box } from '@mui/material';
import StyledTextfield from '../StyledTextfield/index.js';
import {
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { useEffect } from 'react';
import { useState } from 'react';
import ErrorMessage from '../ErrorMessage/index.js';

const StyledSelectTextField = ({
  value,
  label,
  list = [],
  onChange,
  disabled = false,
  name,
  customsx = {},
  customboxsx = {},
  customdropsx = {},
  defaultSelect = "",
  defaultText = "",
  placeholder = '',
  helperText,
  onOpen,
  error,
  message = null,
  isNoneAsItem = true,
  width = "25%",
  requireOpen = false,
  shouldDefaultSelected = true,
  originalData,
  setterFunction = () => { },
  partition = () => { },
  textType,
  hideInputArrows,
  ...props }) => {

  const classes = useStyles();
  const [selectValue, setSelectValue] = useState(defaultSelect);
  const [textValue, setTextValue] = useState(defaultText);
  const [open, setOpen] = useState(false);
  const [delay, setDelay] = useState(false);

  const handleSelectChange = (e) => {
    setSelectValue(e?.target?.value);
    setOpen(false)
    setterFunction(e?.target?.value, textValue);
  }
  const handleTextChange = (e) => {
    setTextValue(e?.target?.value);
    setterFunction(selectValue, e?.target?.value);
  }

  // useEffect(() => {
  //   setterFunction(selectValue, textValue);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [textValue, selectValue])

  useEffect(() => {
    const arr = partition(value);
      setSelectValue(
        arr[0] !== null && arr[0] !== undefined ? arr[0] : defaultSelect
      );
      setTextValue(
        arr[1] !== null && arr[1] !== undefined ? arr[1] : defaultText
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const menuProps = {
    MenuListProps: {
      sx: sx.menu,
    },
    PaperProps: {
      sx: sx.paper,
    },
  };
  const onDropdownOpen = () => {
    if (onOpen) {
      onOpen();
    }
  };

  return (
    <Box
      className={classes?.StyledSelectTextField || ""}
      data-testid="StyledSelectTextField"
      sx={{ ...customboxsx }}
    >
      <FormControl
        fullWidth
        disabled={disabled}
        error={error}
        //  sx={customdropsx}
        sx={{ "& .MuiInputBase-root": { borderRadius: "4px 0 0 4px" } }}
      >
        <Select
          fullWidth
          className={classes?.CustomDropDown || ""}
          {...props}
          error={error}
          sx={{ "&.MuiSelect-root": { borderRadius: 0 }, ...customsx }}
          value={selectValue || defaultSelect || ""}
          name={name}
          renderValue={label}
          onChange={handleSelectChange}
          onOpen={(event) => {
            onDropdownOpen();
            setOpen(true);
          }}
          onClose={(event) => {
            setOpen(false);
          }}
          displayEmpty
          MenuProps={menuProps}
          inputProps={{ sx: sx.input }}
          variant="outlined"
        >
          {isNoneAsItem && (
            <MenuItem sx={sx.menuItem} value="">
              <em>None</em>
            </MenuItem>
          )}

          {list?.map((item, index) => {
            return (
              <MenuItem
                disabled={item?.isDisabled || false}
                key={"sel--" + index}
                sx={sx.menuItem}
                value={item?.id || ""}
              >
                {requireOpen ? (open ? item?.label : item?.id) : item?.label}
              </MenuItem>
            );
          })}
        </Select>
        <StyledTextfield
          name={name}
          placeholder={placeholder}
          fullWidth
          disabled={disabled}
          value={textValue || defaultText || ""}
          onChange={handleTextChange}
          error={error}
          sx={{
            "& .MuiInputBase-root": {
              borderRadius: "0px 4px 4px 0px",
              pt: "1px",
            },
          }}
          type={textType}
          hideInputArrows={hideInputArrows}
        />
      </FormControl>
      {message && <ErrorMessage message={message} />}
    </Box>
  )
};

StyledSelectTextField.propTypes = {};

StyledSelectTextField.defaultProps = {};

export default StyledSelectTextField;
