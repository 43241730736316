import { handleError } from "./error.helper";
import { setSessionItem } from "./storage.helper";

export const handleResponse = (response, obj = null) => {
    // return response.text().then(text => {
    //     const data = text && JSON.parse(text);
    //     console.log("get response", response, data);
    //     if (!response.ok) {
    //         if (response.status === 401) {
    //             // auto logout if 401 response returned from api
    //             logout();
    //             window.location.reload(true);
    //         }

    //         const error = (data && data.message) || response.statusText;
    //         return Promise.reject(error);
    //     }



    return new Promise((resolve, reject) => {
        console.log('response came====>',response);
        if (response) {
            if (response.status < 300 && response.status >= 200) {
                if (obj) {
                    setSessionItem("hideDataNotFound", false)
                    resolve(response || {})
                } else {
                    setSessionItem("hideDataNotFound", false)
                    resolve(response?.data || {})
                }
            } 
            else {
                console.log('Errorr response came====>',response);
                reject(handleError(response))
            }
        }
        // if( response.status > 299) {
        //     reject(handleError(response));
        // } else {
        //     resolve(response?.data);
        // }
    })
}