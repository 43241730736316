/***
 *
 * Controller class for user.
 * @file ControllerDropDown.js
 * @description ControllerDropDown component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './ControllerDropDown.style.js';
import './ControllerDropDown.scss';
import { Box } from '@mui/material';
import CustomTypography from '../../CustomTypography/index.js';
import CustomDropDown from '../../CustomDropDown/index.js';
import TempGreen from '../../../assets/icons/temperature-2.svg'
import ImageWrapper from '../../ImageWrapper/index.js';


const ControllerDropDown = ({list=[], data, disabled, value=null,name, onChange, icon, label,isNoneAsItem, customsx={icon:{}, label:{},value:{},compo:{}},params={icon:false, label:false, value:false, compo:false}}) => {
  const classes = useStyles()
  const [dropdownvalue, setDropDownValue]=useState(value);

  useEffect(()=>{
    console.log('values got==>',value);
    setDropDownValue(value);
  },[value])

  const handleChange=(e,value)=>{
    let currVal = list.filter(d=>d?.id === value);
    setDropDownValue(currVal[0]);
    onChange(e,currVal);
  }

    return (
      <Box display={'flex'} alignItems={'center'} className={`ControllerDropDown ${classes.ControllerDropDown}`} data-testid="ControllerDropDown">      
        {params.icon && <ImageWrapper className="dropdown-icon" style={{ paddingRight: '10px', ...customsx.icon }} src={icon || TempGreen } />}
        {params.label && <CustomTypography className="dropdown-label" styles={{...customsx.label}} content={label || 'dropdown'} color="#000" weight={600} size={12} lineHeight={22} />}
        {params.value && <CustomTypography className="dropdown-value" styles={{...customsx.value}} content={`${dropdownvalue?.label}`} weight={600} size={12} lineHeight={19} color={'#0F8D48'} />}
          <Box sx={{maxWidth: "max-content"}}>
            <CustomDropDown
                  name={name || 'controller_dropdown'}
                  value={dropdownvalue?.id}
                  onChange={(e)=>handleChange(e, e.target.value)}
                  disabled={disabled}            
                  list={list}
                  isNoneAsItem={isNoneAsItem}
                  customsx={{ width: "max-content", ...customsx.compo}}             
            /> 
          </Box>       
        </Box>
    )
};

ControllerDropDown.propTypes = {};

ControllerDropDown.defaultProps = {};

export default ControllerDropDown;
