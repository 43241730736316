/***
 *
 * Controller class for user.
 * @file StyledDashboardCounter.js
 * @description StyledDashboardCounter component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './StyledDashboardCounter.style.js';
import './StyledDashboardCounter.scss';
import { Box } from '@mui/material';
import CustomTypography from '../CustomTypography/index.js';

const StyledDashboardCounter = ({ data = { label: "", counter: 0 }, counterPosition = "bottom", sx = { background: ["#FBF6DF", "rgba(251, 246, 223, 0.25)"] }, labelSx = {}, counterSx = {},...props }) => {
  const classes = useStyles()
  const [counter, setCounter] = useState("-");

  useEffect(() => {
    setCounter(data?.counter || 0);
  }, [data?.counter])

  return (
    <Box className={classes?.StyledDashboardCounter + " StyledDashboardCounter" || ""} data-testid="StyledDashboardCounter" sx={{height:"100%", background: `linear-gradient(110.94deg, ${sx?.background[0]} 5.34%, ${sx?.background[1]} 90%)` }}>
      {props?.children ?
        props?.children :
        <Box>
          {counterPosition !== "bottom" && <CustomTypography component="div" className="counter" sx={{ mb: data?.label ? 1.5 : 0, ...counterSx }}>{counter || 0}</CustomTypography>}
          {data?.label && <CustomTypography component="div" className="label" content={data?.label} sx={{ ...labelSx }} />}
          {counterPosition === "bottom" && <CustomTypography component="div" className="counter" sx={{ mt: data?.label ? 1.5 : 0, ...counterSx }}>{counter || 0}</CustomTypography>}
        </Box>}
    </Box>
  )
};

StyledDashboardCounter.propTypes = {};

StyledDashboardCounter.defaultProps = {};

export default StyledDashboardCounter;
