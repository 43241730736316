import { defaultSelectedMenu, setTenantUrl } from "../../config/appConfig";
import { getSessionItem, request, setLocalItem, setSessionItem, success, failure } from "../../helpers";
import { appTypes } from "../types";
import { systemTokenUsername, systemTokenPassword, setDefaultSelectedMenu, setTenantName } from "../../config/appConfig";
import { userService } from "../../services";

// const portal = JSON.parse(localStorage.getItem('miraie'))?.portal;

const selectLeftMenu = (id) => {
    console.log("SelectedMenu actions ---- ", id);
    let sMenu = JSON.parse(getSessionItem("miraie-selected-menu") || JSON.stringify(defaultSelectedMenu));

    return dispatch => {
        if (id) {
            sMenu = { ...sMenu, left: id }
            dispatch(success(appTypes.SELECT_LEFT_MENU, { payload: id }));
            setSessionItem("miraie-selected-menu", JSON.stringify(sMenu));
        }
    };
}
const selectTopMenu = (id) => {
    // console.log("SelectedMenu top actions ---- ", id);
    let sMenu = JSON.parse(getSessionItem("miraie-selected-menu") || JSON.stringify(defaultSelectedMenu));
    return dispatch => {
        if (id) {
            sMenu = { ...sMenu, top: id };
            dispatch(success(appTypes.SELECT_TOP_MENU, { payload: id }));
            setSessionItem("miraie-selected-menu", JSON.stringify(sMenu));
        }
    };
}

const setTheme = (theme) => {
    // console.log("setTheme", theme);
    return dispatch => {
        dispatch({ type: appTypes.THEME, payload: theme });
    };
}

const apiSuccessMessage = (message = "Success") => {
    return { type: appTypes.API_SUCCESS, message };
}

const apiErrorMessage = (message = "Error") => {
    return { type: appTypes.API_ERROR, message };
}

const apiClearMessage = () => {
    return { type: appTypes.API_CLEAR };
}

const messagePop = (id) => {
    return { type: appTypes.MESSAGE_POP, id };
}

const emptyAppState = (type, payload = null) => {
    if (!type)
        return null;
    return dispatch => {
        dispatch(success(appTypes[type], { payload: payload }))
    }
}

const forceExit = (payload = true) => {
    return dispatch => {
        dispatch(success(appTypes["FORCE_EXIT"], { payload }));
    }
}

const SideBarOpen = (payload) => {
    return dispatch => {
        dispatch(success(appTypes['SIDE_BAR_EXPANDED'], { payload }));
    }
}

//OLD APP CONFIG CALL
// const appConfig = (url, scb, fcb)=>{
//     let appConfigObj = {}
//     return dispatch => {
//         dispatch(request(appTypes.WEB_CONFIG_REQUEST))
//         userService.login(systemTokenUsername, systemTokenPassword, 'tms', null, 'preLogin')
//             .then(
//                 ({ auth, code }) => {
//                     setLocalItem('miraie', JSON.stringify(auth));
//                     setLocalItem('user', JSON.stringify(auth?.user));
//                     userService.getWebConfig(url)
//                     .then(({data: webConf, status})=>{
//                         setLocalItem('tenant', webConf?.tenantName||null)
//                         setTenantName(webConf?.tenantName||null)
//                         // setLocalItem('appConfig',{"webConfig":webConf})
//                         appConfigObj['webConfig'] = webConf
//                         appConfigObj['portal'] = webConf?.tenantName ? 'mss':'tms'
//                         setTenantUrl(appConfigObj);
//                         if(webConf?.tenantName){
//                             userService.getClientId(webConf?.tenantName).then(({data,status})=>{
//                                 appConfigObj['clientId']=data?.filter((resObj)=>resObj?.name==='webApp')[0]?.clientId
//                                 setTimeout(setLocalItem('appConfig', JSON.stringify(appConfigObj)), 0)
//                                 scb(appConfigObj)
//                                 dispatch(success(appTypes.WEB_CONFIG_SUCCESS,{ payload: {...appConfigObj}}))
//                             },({error, code})=>{
//                                dispatch(failure(appTypes.WEB_CONFIG_FAILURE, error?.toString()));
//                                 fcb()
//                             })}
//                         else{
//                         setTimeout(setLocalItem('appConfig', JSON.stringify(appConfigObj)),0)
//                         setDefaultSelectedMenu({top:null,left:'1231'})
//                         scb(appConfigObj)
//                         dispatch(success(appTypes.WEB_CONFIG_SUCCESS,{ payload: {...appConfigObj}}))
//                         }
//                     },
//                     ({error, code})=>{
//                         dispatch(failure(appTypes.WEB_CONFIG_FAILURE, error?.toString()));
//                         fcb()
//                     });
//                 },
//                 ({ error, code }) => {
//                     dispatch(failure(appTypes.WEB_CONFIG_FAILURE, error?.toString()));
//                     fcb()
//                 }
//             );
//     };
// }

//new app config call
const appConfig = (url, scb, fcb) => {
    let appConfigObj = {}
    return dispatch => {
        dispatch(request(appTypes.WEB_CONFIG_REQUEST))
        userService.getWebConfig(url, "preLogin")
            .then(({ data: webConf, status }) => {
                setLocalItem('tenant', webConf?.tenantName || null)
                setTenantName(webConf?.tenantName || null)
                appConfigObj['webConfig'] = webConf
                appConfigObj['portal'] = webConf?.tenantName ? 'mss' : 'tms'
                setTenantUrl(appConfigObj);
                // if (webConf?.hasOwnProperty("clientId") || webConf?.hasOwnProperty("clienId")) {
                //change
                appConfigObj['clientId'] = webConf?.clienId || null;
                // }
                setTimeout(setLocalItem('appConfig', JSON.stringify(appConfigObj)), 0)
                if (!webConf?.hasOwnProperty("tenantName")) {
                    setDefaultSelectedMenu({ top: null, left: '1231' })
                }
                scb(appConfigObj)
                dispatch(success(appTypes.WEB_CONFIG_SUCCESS, { payload: { ...appConfigObj } }))
            },
                ({ error, code }) => {
                    dispatch(failure(appTypes.WEB_CONFIG_FAILURE, error?.toString()));
                    fcb()
                });
    };
}


export const appActions = {
    selectLeftMenu,
    selectTopMenu,
    setTheme,
    apiSuccessMessage,
    apiErrorMessage,
    apiClearMessage,
    messagePop,
    emptyAppState,
    forceExit,
    SideBarOpen,
    appConfig,
};
