import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    DiscoverSensors: {
        "& .tableBox":{
            minHeight:"390px",
            overflow:"auto",
            marginTop:"16px"
        }
    }
});
