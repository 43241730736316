
import _ from "lodash"
import { getMobileNumber } from "../helpers";
/*  
  This is validator function calls for forms. 
    To use validations, check the type of field
    and then use appropriate key in object to avail
    function calls. See used example for more
    clarification.    
*/
function isRequired(string, value, type = "text") {
    if (value) {
        if (type === 'texteditor') {
            return (string.replace(/<(.|\n)*?>/g, '').trim().length === 0);
        }
        return _.isEmpty(string);
    }
    return false
}

function isMinLength(string, value, type = "text") {
    if (type === 'texteditor') {
        return string.replace(/<(.|\n)*?>/g, '').trim().length < value;
    }
    return string.length < value;
}

function isMaxLength(string, value, type = 'text') {
    if (type === 'texteditor') {
        return string.replace(/<(.|\n)*?>/g, '').trim().length > value;
    }
    return string.length > value;
}

function isFixLength(string, value) {
    return string.length !== value;
}


function isNumber(string) {
    if (_.isEmpty(string)) return true
    return (/^-?\d*\.{0,1}\d+$/).test(string);
}

function isAlphaNumeric(string) {
    return (/^[0-9a-zA-Z]*$/).test(string);
}
function isAlphaNumericWithSpecialChar(string, special = '') {
    let regexstr = '[0-9a-zA-Z]*';
    if (special) {
        regexstr = `[0-9a-zA-Z${special}]*`
    }
    return new RegExp('^' + regexstr + '$').test(string);
}
function nospace(string) {
    return (/\s+/g).test(string);
}
function validateEmail(string) {
    if (_.isEmpty(string)) return true;
    return (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(string))
}

function validateIP(ipAddress) {
    return (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipAddress) || /^([0-9a-f]){1,4}(:([0-9a-f]){1,4}){7}$/.test(ipAddress))
}

function validateMacAddress(macId) {
    return (/^([0-9A-Fa-f]{2}[:]){5}[0-9A-Fa-f]{2}$/.test(macId))
}
function isAlphabets(string) {
    return (/^[a-zA-Z ]*$/).test(string);
}

function phoneReq(mobile, req) {
    let validMobile = Array.isArray(getMobileNumber(mobile)) ? getMobileNumber(mobile)[1] : '';
    return validMobile === '';
}

function validateMobile(mobile, req) {
    let validMobile = Array.isArray(getMobileNumber(mobile)) ? getMobileNumber(mobile)[1] : '';
    if (req === 'empty' && validMobile === '') return true;
    if (validMobile.length !== 10) return false;
    return (/^(\+\d{1,3}[- ]?)?\d{10}$/).test(mobile);
}

function validateCustomRegex(string, regex) {
    return (regex).test(string);
}

function isMinVal(string, value) {
    return Number(string) < value;
}

function isMaxVal(string, value) {
    return Number(string) > value;
}

function isRequired_Mask(string) {
    return (/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/).test(string);
}

function isRequiredArray(arr) {
    return _.isEmpty(arr);
}

function betweenRange(value, range = []) {
    if (range[0] === undefined || range[1] === undefined) return true;
    if (value > range[1] || value < range[0] || isNaN(value)) return true;

    return false;

}

function isCorrectDate(string, value) {
    // console.log('stttttttt',Date.parse(string).toString(), isNaN(string));
    if (value) {
        return typeof string === 'string' ? _.isEmpty(string) : string === 0;
    }
    return false
}


function noDuplicate(string, value) {
    if (value) {
        return !_.isEmpty(string) && !_.isEmpty(value) && string === value;
    }
    return false
}

function isHexCode(string) {
    return (/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/).test(string);
}



export default function Validator(string = "", type = null, options = {}, item = null) {
    // console.log("validation -------------------- ", string, type, options)
    if (type === 'text' || type === 'password') {
        const optArr = Object.keys(options);
        for (const key of optArr) {
            switch (key) {
                case 'req': if (isRequired(string, options[key][0])) return options[key][1] ? options[key][1] : "field cannot be empty";
                    break;
                case 'minLen': if (isMinLength(string, options[key][0])) return options[key][1] ? options[key][1] : `field value cannot be less than ${options[key][0]} characters`;
                    break;
                case 'maxLen': if (isMaxLength(string, options[key][0])) return options[key][1] ? options[key][1] : `field value cannot be more than ${options[key][0]} characters`;
                    break;
                case 'fixLen': if (isFixLength(string, options[key][0])) return options[key][1] ? options[key][1] : `field value should have ${options[key][0]} characters only`;
                    break;
                case 'alpha': if (!isAlphabets(string, options[key][0])) return options[key][1] ? options[key][1] : 'Only letters are allowed';
                    break;
                case 'number': if (!isNumber(string, options[key][0])) return options[key][1] ? options[key][1] : 'Only numbers are allowed';
                    break;
                case 'alphaNum': if (!isAlphaNumeric(string, options[key][0])) return options[key][1] ? options[key][1] : 'Only letters and numbers are allowed';
                    break;
                case 'email': if (!validateEmail(string, options[key][0])) return options[key][1] ? options[key][1] : 'Invalid Email';
                    break;
                case 'range': if (betweenRange(Number(string), options[key][0])) return options[key][1] ? options[key][1] : 'Invalid Range';
                    break;
                case 'ipAddress': if (!validateIP(string, options[key][0])) return options[key][1] ? options[key][1] : 'Invalid IP Address';
                    break;
                case 'macAddress': if (!validateMacAddress(string, options[key][0])) return options[key][1] ? options[key][1] : 'Invalid Mac Address';
                    break;
                case 'phonenumber': if (!validateMobile(string, options[key][0])) return options[key][1] ? options[key][1] : 'Invalid Mobile number';
                    break;
                case 'customRegex': if (!validateCustomRegex(string, options[key][0])) return options[key][1] ? options[key][1] : 'Invalid format';
                    break;
                case 'noDuplicate': if (noDuplicate(string, options[key][0])) return options[key][1] ? options[key][1] : 'Duplicate field not allowed';
                    break;
                case 'alphaNumChars': if (!isAlphaNumericWithSpecialChar(string, options[key][0])) return options[key][1] ? options[key][1] : 'Invalid format';
                    break;
                case 'nospace': if (nospace(string, options[key][0])) return options[key][1] ? options[key][1] : 'Spaces not allowed';
                    break;
                default: return "";
            }
        }
        return "";
    }
    else if (type === 'number') {
        if (!isNumber(string)) return "Only numeric values allowed";
        const optArr = Object.keys(options);
        for (const key of optArr) {
            switch (key) {
                case 'req': if (isRequired(String(string), options[key][0])) return options[key][1] ? options[key][1] : "field cannot be empty";
                    break;
                case 'minLen': if (isMinLength(string, options[key][0])) return options[key][1] ? options[key][1] : `field value cannot be less than ${options[key][0]} characters`;
                    break;
                case 'maxLen': if (isMaxLength(string, options[key][0])) return options[key][1] ? options[key][1] : `field value cannot be more than ${options[key][0]} characters`;
                    break;
                case 'fixLen': if (isFixLength(string, options[key][0])) return options[key][1] ? options[key][1] : `field value should have ${options[key][0]} characters only`;
                    break;
                case 'minVal': if (isMinVal(string, options[key][0])) return options[key][1] ? options[key][1] : `field value cannot be less than ${options[key][0]}`;
                    break;
                case 'maxVal': if (isMaxVal(string, options[key][0])) return options[key][1] ? options[key][1] : `field value cannot be more than ${options[key][0]}`;
                    break;
                case 'neg': if (Number(string) > 0) return options[key][1] ? options[key][1] : 'Entered value cannot be greater than 0';
                    break;
                case 'pos': if (Number(string) < 0) return options[key][1] ? options[key][1] : 'Entered value cannot be less than 0';
                    break;
                case 'range': if (betweenRange(Number(string), options[key][0])) return options[key][1] ? options[key][1] : 'Invalid Range';
                    break;
                case 'customRegex': if (!validateCustomRegex(string, options[key][0])) return options[key][1] ? options[key][1] : 'Invalid format';
                    break;
                default: return "";
            }
        }
        return "";
    }
    else if (type === 'mask') {
        // const maskFormat= options['maskFormat'];
        const optArr = Object.keys(options);
        for (const key of optArr) {
            switch (key) {
                case 'req': if (!isRequired_Mask(string, options[key][0])) return options[key][1] ? options[key][1] : "field cannot be empty";
                    break;
                default: return "";
            }
        }
        return "";
    }
    else if (type === 'select') {
        const optArr = Object.keys(options);
        for (const key of optArr) {
            switch (key) {
                case 'req': if (isRequired(string, options[key][0])) return options[key][1] ? options[key][1] : "field cannot be empty";
                    break;
                default:
                    return "";
            }
        }
        return ""
    }
    else if (type === 'chipinput' || type === 'chipInput') {
        const optArr = Object.keys(options);
        for (const key of optArr) {
            switch (key) {
                case 'req': if (isRequiredArray(string, options[key][0])) return options[key][1] ? options[key][1] : "field cannot be empty";
                    break;
                default:
                    return "";
            }
        }
        return ""
    }
    else if (type === "file") {
        const optArr = Object.keys(options);
        for (const key of optArr) {
            switch (key) {
                case 'req': if (isRequired(string, options[key][0])) return options[key][1] ? options[key][1] : "field cannot be empty";
                    break;
                default:
                    return "";
            }
        }
        return ""
    }
    else if (type === "multiselect") {
        const optArr = Object.keys(options);
        for (const key of optArr) {
            switch (key) {
                case 'req': if (isRequired(string, options[key][0])) return options[key][1] ? options[key][1] : "field cannot be empty";
                    break;
                default:
                    return "";
            }
        }
        return ""
    }
    else if (type.toLowerCase() === "treedropdown") {
        const optArr = Object.keys(options);
        for (const key of optArr) {
            switch (key) {
                case 'req': if (isRequired(string, options[key][0], type)) return options[key][1] ? options[key][1] : "field cannot be empty";
                    break;
                default:
                    return "";
            }
        }
        return ""
    }
    else if (type === "selectTextField") {
        const optArr = Object.keys(options);
        for (const key of optArr) {
            switch (key) {
                case 'req': if (isRequired(string, options[key][0])) return options[key][1] ? options[key][1] : "field cannot be empty";
                    break;
                case 'phonereq': if (phoneReq(string, options[key][0])) return options[key][1] ? options[key][1] : 'Mobile number required';
                    break;
                case 'phonenumber': if (!validateMobile(string, options[key][0])) return options[key][1] ? options[key][1] : 'Invalid Mobile number';
                    break;
                case 'noDuplicate': if (noDuplicate(string, options[key][0])) return options[key][1] ? options[key][1] : 'Duplicate field not allowed';
                    break;
                default:
                    return "";
            }
        }
        return ""
    }
    else if (type === "datetimepicker") {
        const optArr = Object.keys(options);
        for (const key of optArr) {

            switch (key) {
                // case 'value':
                //     if(options[key]) { return ""; }
                //     break;
                case 'req': if (isCorrectDate(string, options[key][0])) return options[key][1] ? options[key][1] : "field cannot be empty";
                    break;
                default:
                    return "";
            }
        }
        return ""
    }
    else if (type === "freetextchipinput") {
        const optArr = Object.keys(options);
        for (const key of optArr) {
            switch (key) {
                case 'req': if (isRequired(string, options[key][0])) return options[key][1] ? options[key][1] : "field cannot be empty";
                    break;
                case 'email': if (!validateEmail(string, options[key][0])) return options[key][1] ? options[key][1] : 'Invalid Email';
                    break;
                default:
                    return "";
            }
        }
        return ""
    }
    else if (type === "radiobutton") {
        const optArr = Object.keys(options);
        for (const key of optArr) {
            switch (key) {
                case 'req': if (isRequired(string, options[key][0])) return options[key][1] ? options[key][1] : "field cannot be empty";
                    break;
                default:
                    return "";
            }
        }
        return ""
    }
    else if (type === "checkbox") {
        const optArr = Object.keys(options);
        for (const key of optArr) {
            switch (key) {
                case 'req': if (isRequired(string, options[key][0])) return options[key][1] ? options[key][1] : "field cannot be empty";
                    break;
                default:
                    return "";
            }
        }
        return ""
    }

    else if (type === "texteditor") {
        const optArr = Object.keys(options);
        for (const key of optArr) {
            switch (key) {
                case 'req': if (isRequired(string, options[key][0], type)) return options[key][1] ? options[key][1] : "field cannot be empty";
                    break;
                case 'minLen': if (isMinLength(string, options[key][0], type)) return options[key][1] ? options[key][1] : `field value cannot be less than ${options[key]} characters`;
                    break;
                case 'maxLen': if (isMaxLength(string, options[key][0], type)) return options[key][1] ? options[key][1] : `field value cannot be more than ${options[key]} characters`;
                    break;
                default:
                    return "";
            }
        }
        return ""
    }

    else if (type === "colorPicker") {
        const optArr = Object.keys(options);
        for (const key of optArr) {
            switch (key) {
                case 'req': if (isRequired(string, options[key][0], type)) return options[key][1] ? options[key][1] : "field cannot be empty";
                    break;
                case 'hex': if (!isHexCode(string)) return options[key][1] ? options[key][1] : "Invalid hex string";
                    break;
                default:
                    return "";
            }
        }
        return ""
    }
}