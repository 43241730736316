/***
 *
 * Controller class for user.
 * @file UserProfile.js
 * @description UserProfile component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 *
 */

import { Box, Grid } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import PageHeader from "../../components/PageHeader/index.js";
import { useQuery } from "../../hooks/useQuery.js";
// import PropTypes from 'prop-types';
import { customsx, useStyles } from "./UserProfile.style.js";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../redux/actions/user.actions.js";
import "./UserProfile.scss";
import _ from "lodash";
import FormWrapper from "../../components/FormWrapper/index.js";
import Permissions from "../../components/AddEditUser/Permissions/index.js";
import StyledChip from "../../components/StyledChip/index.js";
import VerifyPopup from "./VerifyPopup/index.js";
import { countryCodes, timezones } from "../../constants/user.constants.js";
import { getMobileNumber, isAuthorised } from "../../helpers/users.helper.js";
import CustomLabel from "../../components/CustomLabel/index.js";
import Validator from "../../helpers/validator.helper.js";
import User from "../../assets/icons/user.svg";
import { features, permissions } from "../../constants/permission.constants.js";
import StyledPopupWrapper from "../../components/StyledPopupWrapper/index.js";
import useWindowDimensions from "../../hooks/useWindowDimensions.js";
import StyledButton from "../../components/StyledButton/index.js";
import { useRef } from "react";
import CustomTypography from "../../components/CustomTypography/index.js";
import { getMessageStr } from "../../helpers/message.helper.js";
import ResetCred from "../../components/ResetCred/index.js";

const pArray = [
  {
    id: "pass_old",
    type: "password",
    label: "Enter old password",
    name: "oldPValue",
    error: false,
    errorMsg: "",
    minMaxLen: false,
    reqDigit: false,
    reqSpecial: false,
    reqUpper: false,
  },
  {
    id: "pass_new",
    type: "password",
    label: "Enter new password",
    name: "newPValue",
    error: false,
    errorMsg: "",
  },
  {
    id: "pass_confirm",
    type: "password",
    label: "Re-enter new password",
    name: "reEnterPValue",
    error: false,
    errorMsg: "",
  },
];

const otpObj = {
  otp: "",
  mobile: "+91",
  email: "",
  oldPValue: "",
  newPValue: "",
  reEnterPValue: "",
};

const RadioArray = [
  {
    label: "Email",
    name: "email",
    id: "email",
    verified: false,
  },
  {
    label: "Mobile",
    name: "mobile",
    id: "mobile",
    verified: false,
  },
];

const popUpCustomSx = {
  label: {
    color: "red",
    fontWeight: 700,
    fontSize: "0.9375rem",
    paddingBottom: "5px",
  },
  btns: { display: "flex", justifyContent: "flex-end" },
  dialog: { "& .MuiDialog-paper": { width: "450px" } },
  compo: { marginTop: 0 },
  box: { paddingBottom: "15px" },
};

const UserProfile = () => {
  const classes = useStyles();
  const history = useHistory();
  const getUserProfile = useSelector((state) => state?.users?.userSelfProfile);
  const _changeSelfPValue = useSelector(
    (state) => state?.users?.changeSelfPassword
  );
  const _error = useSelector((state) => state?.users?.error);
  const _sideBarOpen = useSelector((state) => state?.app?.sideBarOpen);
  const windowDimensions = useWindowDimensions();
  const actionButtonsRef = useRef();

  const [userProfile, setUserProfile] = useState(null);
  const [originalUserProfile, setOriginalUserProfile] = useState({});
  const query = useQuery();
  // const page = query.get("pt") || null;
  const operation = query.get("op") || null;
  const [openVerificationPopup, setOpenVerificationPopup] = useState(false);

  const [formData, setFormData] = useState(null);
  const [resetPValue, setResetPValue] = useState(otpObj);
  const [payload, setPayload] = useState({});
  const [step, setStep] = useState("");
  const [currState, setCurrentState] = useState("view");
  const [verification, setVerification] = useState(null);
  const [frmOptions, setFrmOptions] = useState(null);

  const [resetArray, setResetArray] = useState(pArray?.map(p => ({ ...p })));

  const dispatch = useDispatch();
  const _portal = useSelector((state) => state?.app?.portal);
  const frmConfigObj = [
    {
      rowid: "row1",
      section: { heading: null },
      columns: [
        {
          id: "11",
          type: "text",
          label: "Username",
          name: "username",
          placeholder: "Enter",
          disabledOnEdit: true,
          columnSize: 4,
        },
        {
          id: "1",
          type: "text",
          label: "Name",
          name: "firstname",
          placeholder: "Enter...",
          disabledOnEdit: false,
          required: true,
          validation: {
            req: [true, "Name is required"],
            minLen: [3, "Minimum 3 characters"],
            maxLen: [20, "Maximum 20 characters"],
            alphaNumChars: [
              ["- "],
              "Invalid name. Only alphanumeric, - and space are allowed",
            ],
          },
          columnSize: 4,
        },
      ],
    },
    {
      rowid: "row2",
      section: { heading: null },
      columns: [
        {
          id: "13",
          type: "text",
          label: "Primary Email",
          name: "email",
          placeholder: "Enter",
          disabledOnEdit: false,
          validation: {
            req: [true, getMessageStr("user-addUserFormValidation-email")],
            email: [true, getMessageStr("user-addUserFormValidation-emailInvalid")],
          },
          columnSize: 4,
        },
        {
          id: "15",
          type: "selectTextField",
          name: "mobile",
          defaultSelect: "+91",
          defaultText: "",
          placeholder: "Enter Mobile",
          disabledOnEdit: false,
          requireOpen: true,
          isNoneAsItem: false,
          hideInputArrows: true,
          width: _sideBarOpen && windowDimensions?.width < 545 ? "35%" : "25%",
          label: "Primary Mobile",
          partition: (data) => {
            return getMobileNumber(data);
          },
          onClickLabel2: () => {
            setVerification("mobile");
            if (!getUserProfile?.mobileVerified) setOpenVerificationPopup(true);
          },
          validation: {
            phonereq: [true, getMessageStr("user-addUserFormValidation-contact")],
            phonenumber: ['empty', getMessageStr("user-addUserFormValidation-contactInvalid")],
          },
          columnSize: 4,
        },
      ],
    },
  ];

  const [frmConfig, setFrmConfig] = useState(frmConfigObj);

  ///// handle errors

  useEffect(() => {
    if (_error && _error?.key === "changeSelfPassword") {
      setResetPValue(otpObj);
      dispatch(userActions.emptyUserErrors());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_error]);

  const customstyle = {
    label2: {
      color: "#0F8D48",
      textDecoration:
        !getUserProfile?.emailVerified && !getUserProfile?.mobileVerified
          ? "underline"
          : "none",
      cursor: "pointer",
    },
  };

  const findField = (fieldsArr, field, def) => {
    return (
      Array.isArray(fieldsArr) &&
      fieldsArr.find(function (item) {
        return item.key === field;
      }) &&
      (fieldsArr.find(function (item) {
        return item.key === field;
      })?.value ||
        def)
    );
  };

  const actionButtons = [
    {
      heading: null,
      columns: [
        {
          heading: null,
          buttons: [
            {
              id: "cancel",
              type: "button",
              size: "medium",
              variant: "outlined",
              display: currState === "edit" ? true : false,
              checkValidation: false,
              label: "Cancel",
              onClick: (e, data) => {
                history.push("/profile");
                setFormData({
                  ...originalUserProfile,
                });
              },
            },
            {
              id: "save",
              type: "button",
              size: "medium",
              color: "primary",
              checkValidation: currState === "edit" ? true : false,
              display: true,
              label: currState === "edit" ? "Save" : "Edit",
              onClick: (e, data) => {
                // console.log('clicked');
                if (currState === "edit") {
                  handleSubmit(e, "saveProfile");
                } else goTo("/profile?op=edit");
              },
            },
          ],
          columnSize: 12,
        },
      ],
    },
  ];

  useEffect(() => {
    // dispatch(userActions.getSelfProfile());
    let arr = frmConfigObj;
    if (_portal === "mss") {
      let section2 = arr.find((rid) => rid?.rowid === "row2");
      section2.columns[0]["label"] = {
        component: (() => {
          return <>
            <CustomLabel required={true} htmlFor="13">Primary Email</CustomLabel>
            <CustomLabel style={{ color: '#0F8D48', textDecoration: !getUserProfile?.emailVerified ? 'underline' : 'none', cursor: !getUserProfile?.emailVerified ? 'pointer' : "" }} onClick={() => {
              setVerification('email');
              if (!getUserProfile?.emailVerified)
                setOpenVerificationPopup(true);
            }} htmlFor="13">{!getUserProfile?.emailVerified ? 'Verify' : 'Verified ✔'}</CustomLabel>
          </>
        })()
      }

      section2.columns[1]['label'] = {
        component: (() => {
          return <>
            <CustomLabel required={true} htmlFor="15">Primary Mobile</CustomLabel>
            <CustomLabel style={{ color: '#0F8D48', textDecoration: !getUserProfile?.mobileVerified ? 'underline' : 'none', cursor: !getUserProfile?.mobileVerified ? 'pointer' : "" }} onClick={() => {
              setVerification('mobile');
              if (!getUserProfile?.mobileVerified)
                setOpenVerificationPopup(true);
            }} htmlFor="15">{!getUserProfile?.mobileVerified ? 'Verify' : 'Verified ✔'}</CustomLabel>
          </>
        })()
      }
      section2.columns[1]['onClickLabel2'] = () => {
        setVerification('mobile');
        if (!getUserProfile?.mobileVerified)
          setOpenVerificationPopup(true);
      }
      arr.splice(2, 0, {
        section: { heading: null }, columns: [
          {
            id: "14",
            type: "text",
            label: "Alternate Email",
            name: "alternateEmail",
            placeholder: "Enter alternate mail",
            disabledOnEdit: false,
            validation: {
              email: [true, "Invalid email"],
              noDuplicate: [
                payload?.email,
                "Alternate email id cannot be equal to primary email",
              ],
            },
            columnSize: 4,
          },
          {
            id: "16",
            label: "Alternate Mobile",
            name: "alternateMobile",
            placeholder: "Enter alternate mobile",
            type: "selectTextField",
            defaultSelect: "+91",
            defaultText: "",
            disabledOnEdit: false,
            requireOpen: true,
            isNoneAsItem: false,
            width:
              _sideBarOpen && windowDimensions?.width < 545 ? "35%" : "25%",
            hideInputArrows: true,
            partition: (data) => {
              return getMobileNumber(data);
            },
            validation: {
              phonenumber: ["empty", "Invalid mobile number"],
              noDuplicate: [
                payload?.mobile,
                "Alternate mobile cannot be equal to primary mobile",
              ],
            },
            columnSize: 4,
          },
        ],
      },
        {
          section: { heading: null },
          columns: [
            {
              id: "17",
              type: "text",
              label: "Employee ID",
              name: "employeeId",
              placeholder: "Employee ID",
              disabledOnEdit: true,
              columnSize: 4,
            },
            {
              id: "18",
              type: "select",
              label: "Timezones",
              name: "timezones",
              placeholder: "Enter",
              options: [],
              columnSize: 4,
            },
          ],
        }
      );
      setFrmConfig([...arr]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserProfile?.mobileVerified, getUserProfile?.emailVerified]);

  //// to fetch userprofile from redux
  useEffect(() => {
    if (!_.isEmpty(getUserProfile)) {
      let opt = {};
      opt["dropdowns"] = {
        timezones: {
          list: timezones.map((item) => {
            return { id: item, label: item };
          }),
        },
        mobile: {
          list: countryCodes,
        },
        alternateMobile: {
          list: countryCodes,
        },
      };
      setFrmOptions(opt);

      setUserProfile({ ...getUserProfile });
      RadioArray[0]["verified"] = getUserProfile?.emailVerified || false;
      RadioArray[1]["verified"] = getUserProfile?.mobileVerified || false;

      setFormData({
        ...getUserProfile,
        firstname: getUserProfile?.name,
        alternateEmail: findField(getUserProfile?.fields, "alternateEmail", ""),
        alternateMobile: findField(
          getUserProfile?.fields,
          "alternateMobile",
          ""
        ),
        timezones: findField(getUserProfile?.fields, "timezones", ""),
        employeeId: findField(getUserProfile?.fields, "employeeId", ""),
      });
      setOriginalUserProfile({
        ...getUserProfile,
        firstname: getUserProfile?.name,
        alternateEmail: findField(getUserProfile?.fields, "alternateEmail", ""),
        alternateMobile: findField(
          getUserProfile?.fields,
          "alternateMobile",
          ""
        ),
        timezones: findField(getUserProfile?.fields, "timezones", ""),
        employeeId: findField(getUserProfile?.fields, "employeeId", ""),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserProfile]);

  ///// to handle operation state
  useEffect(() => {
    if (operation) {
      setCurrentState(operation);
      if (operation === "reset") {
        setStep("enterData");
        setResetArray(pArray?.map(p => ({ ...p })));
      }
    } else {
      setStep(null);
      setCurrentState("view");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operation]);

  useEffect(() => {
    if (_changeSelfPValue) {
      history.push("/profile");
      setStep("passwordChanged");
      setResetPValue(otpObj);
      dispatch(
        userActions.emptyUserState("CHANGE_SELF_PASSWORD_SUCCESS", null)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_changeSelfPValue])


  const goTo = (route) => {
    history.push(route);
  };

  const handlePValueChange = (e) => {
    const target = e.target;
    setResetPValue({
      ...resetPValue,
      [target.name]: target.value,
    });
    const arr = resetArray.map((item) => {
      if (["pass_new", "newPValue"].includes(item?.id)) {
        item["minMaxLen"] =
          _.isEmpty(Validator(target.value, "text", { minLen: [8, ""] })) &&
          _.isEmpty(Validator(target.value, "text", { maxLen: [20, ""] }));
        item["reqSpecial"] = /.*[#@_-]/.test(target.value);
        item["reqDigit"] = /.*[0-9]/.test(target.value);
        item["reqUpper"] = /.*[A-Z]/.test(target.value);
      }
      item["error"] = false;
      item["errorMsg"] = ``;

      return item;
    });
    setResetArray(arr);
  };

  function containsSpace(string) {
    return /.*\s.*/.test(string);
  }

  const handleSubmit = (e, step) => {
    if (step === "generateOTP") {
      setStep("generateOTP");
      setResetPValue({
        ...resetPValue,
        ...otpObj,
      });
    } else if (step === "confirmpassword") {
      if (
        _.isEmpty(resetPValue["newPValue"]) ||
        _.isEmpty(resetPValue["reEnterPValue"]) ||
        _.isEmpty(resetPValue["oldPValue"])
      ) {
        const arr = resetArray.map((item) => {
          if (_.isEmpty(resetPValue[item?.name])) {
            item["error"] = true;
            item["errorMsg"] = `Given field cannot be empty`;
          }
          return item;
        });
        setResetArray(arr);
        return;
      }



      if (
        resetPValue["newPValue"] &&
        resetPValue["reEnterPValue"] &&
        resetPValue["newPValue"] !== resetPValue["reEnterPValue"]
      ) {
        const arr = resetArray.map((item) => {
          if (item["name"] !== "oldPValue") {
            item["error"] = true;
            item["errorMsg"] = `Passwords do not match`;
          }
          return item;
        });
        setResetArray(arr);
        return;
      }

      if (
        containsSpace(resetPValue["oldPValue"]) ||
        containsSpace(resetPValue["newPValue"]) ||
        containsSpace(resetPValue["reEnterPValue"])
      ) {
        const arr = resetArray.map((item) => {
          if (containsSpace(resetPValue[item?.name])) {
            item["error"] = true;
            item["errorMsg"] = `Password cannot contain whitespace`;
          }
          return item;
        });
        setResetArray(arr);
        return;
      }
      const newPValue = resetArray?.find(item => ["newPValue", "new_pass"]?.includes(item?.name))
      if (resetPValue["newPValue"] && (!(newPValue["minMaxLen"] && newPValue["reqSpecial"] && newPValue["reqDigit"] && newPValue["reqUpper"]))) {
        const arr = resetArray.map((item) => {
          if (["new_pass", "newPValue"]?.includes(item?.name)) {
            item["error"] = true;
            item["errorMsg"] = `Password must satisfy the provided conditions.`;
          }
          return item;
        });
        setResetArray(arr);
        return;
      }

      if (resetPValue["newPValue"] === resetPValue["reEnterPValue"]) {
        dispatch(userActions.changeSelfCred(_portal === 'mss' ? { oldPassword: resetPValue["oldPValue"], password: resetPValue["newPValue"] } : { password: resetPValue["newPValue"] }, _portal));
      }
    } else if (step === "saveProfile") {
      let payloadObj = {
        email: payload?.email,
        mobile: payload?.mobile,
      };
      if (_portal === 'mss') {
        payloadObj["name"] = payload?.firstname
        payloadObj["fields"] = [
          {
            key: "alternateEmail",
            value: payload?.alternateEmail || "",
          },
          {
            key: "alternateMobile",
            value:
              Array.isArray(getMobileNumber(payload?.alternateMobile)) &&
                getMobileNumber(payload?.alternateMobile)[1]
                ? payload?.alternateMobile
                : "",
          },
          {
            key: "timezones",
            value: payload?.timezones || "",
          },
        ]
      }
      else {
        payloadObj['firstname'] = payload?.firstname
      }

      dispatch(
        userActions.updateSelfProfile(payloadObj, () => {
          dispatch(userActions.getSelfProfile());
          setCurrentState("view");
          setFormData({
            ...payload,
            ...payloadObj,
          });
          setOriginalUserProfile({
            ...payload,
            ...payloadObj,
          });
          history.push("/profile");
        }, _portal)
      );
    }
  };

  return (
    <Box className={classes?.UserProfile || ""} data-testid="UserProfile">
      <Grid item xs={12} container>
        <Grid item xs={12}>
          <PageHeader
            bckbtn={["reset"].includes(operation) ? true : false}
            backfunc={() => {
              goTo(currState === "reset" ? "/profile" : "/");
            }}
            textStyles={{ textTransform: "none" }}
            pageHeaderStyle={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
            title={"My Profile - " + getUserProfile?.username}
            icon={User}
          >
            {operation === null &&
              isAuthorised(features?.user, permissions?.edit) && (
                <StyledButton
                  type="button"
                  onClick={() => {
                    goTo("/profile?op=reset");
                    setStep("enterData");
                  }}
                  color="primary"
                  sx={{}}
                >
                  Reset Password
                </StyledButton>
              )}
          </PageHeader>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {(() => {
          switch (currState) {
            case "reset":
              return (
                <>
                  {(() => {
                    switch (step) {
                      case "enterData":
                        return (
                          <Box sx={{ maxWidth: "350px" }} >
                            <ResetCred
                              pArray={resetArray}
                              handlePValueChange={handlePValueChange}
                              onSubmit={(e) => {
                                handleSubmit(e, "confirmpassword");
                              }}
                              resetPValue={resetPValue}
                            />
                          </Box>
                        );
                      default:
                        return <></>;
                    }
                  })()}
                </>
              );
            default:
              return (
                <>
                  <Grid container item xs={12} md={12}>
                    <Box sx={{ width: "100%" }}>
                      <Box className={classes?.subHeading} style={{ marginBottom: 0 }}>
                        <CustomTypography sx={{ fontWeight: 600, fontSize: "1.2em" }}>
                          User Details
                        </CustomTypography>
                      </Box>
                      <FormWrapper
                        ref={actionButtonsRef}
                        style={customstyle}
                        operation={currState}
                        setPayload={(data) => {
                          setPayload(data);
                        }}
                        formData={formData}
                        formFields={frmConfig}
                        formButtons={actionButtons}
                        formOptions={frmOptions}
                      ></FormWrapper>

                      <Box className={classes?.subHeading} style={{ marginBottom: 0 }}>
                        <CustomTypography sx={{ fontWeight: 600, fontSize: "1.2em" }}>
                          Permissions
                        </CustomTypography>
                      </Box>
                      {Array.isArray(getUserProfile?.roles) &&
                        !_.isEmpty(getUserProfile?.roles) &&
                        getUserProfile?.roles.map((item, key) => (
                          <StyledChip
                            key={key}
                            variant="outlined"
                            color="primary"
                            label={item}
                            sx={customsx.StyledChip}
                          />
                        ))}
                      {_portal === "mss" && (
                        <Box sx={{ marginTop: "1rem" }} >
                          <Box className={classes?.subHeading}>
                            <CustomTypography
                              sx={{ fontWeight: 600, fontSize: "1.2em" }}
                            >
                              Scopes
                            </CustomTypography>
                          </Box>
                          <Box sx={{ margin: "1rem 3rem 1rem 1rem" }}>
                            <Permissions
                              page={currState}
                              addmore={false}
                              empty={"No"}
                              setUserScope={() => { }}
                              userId={userProfile?.userId}
                            />
                          </Box>
                        </Box>
                      )}
                    </Box>
                    <StyledPopupWrapper
                      title={`Verify ${verification}`}
                      open={openVerificationPopup}
                      onClose={() => setOpenVerificationPopup(false)}
                      customsx={popUpCustomSx}
                    >
                      <VerifyPopup
                        value={userProfile}
                        onCancel={() => {
                          setOpenVerificationPopup(false);
                          setTimeout(() => {
                            setVerification(null);
                          }, 100);
                        }}
                        validation={
                          (() => {
                            const obj = frmConfig?.find(f => f?.rowid === "row2")?.columns?.find(c => c?.name === verification);
                            return { valObj: obj?.validation, type: obj?.type }
                          })()
                        }
                        onVerifiedOTP={() => {
                          setOpenVerificationPopup(false);
                          dispatch(userActions.getSelfProfile());
                        }}
                        closePopUp={openVerificationPopup}
                        type={verification}
                      />
                    </StyledPopupWrapper>
                  </Grid>
                </>
              );
          }
        })()}
      </Grid>
    </Box>
  );
};

UserProfile.propTypes = {};

UserProfile.defaultProps = {};

export default UserProfile;
