/***
 *
 * Controller class for user.
 * @file DeviceTypes.js
 * @description DeviceTypes component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 *
 */

import React from "react";
// import PropTypes from 'prop-types';
import "./DeviceTypes.scss";
import Light from "../../assets/icons/bulb.svg";
import Receiver from "../../assets/icons/controller.svg";
import Sensor from "../../assets/icons/sensor.svg";
import Switch from "../../assets/icons/switch.svg";
import Group from "../../assets/icons/group.svg";
import Scene from "../../assets/icons/scene.svg"
import ImageWrapper from "../ImageWrapper";
import _ from "lodash";

const DeviceTypes = ({ data }) => {
  const deviceList = data.devices || data.actions || data.onTrigger || [];
  let automationSensorsList = [];

  if(data?.triggers !== undefined && data?.triggers?.length === 2) {
    if(!_.isEmpty(data.triggers[0]) && data.triggers[0][0].type === 'DEVICE_STATUS') {
      automationSensorsList = data?.triggers[0];
    }
    else {
      automationSensorsList = data?.triggers[1];
    }
  }
  else if(data?.triggers !== undefined && data?.triggers?.length !== 2) {
    automationSensorsList = data?.triggers[0][0]?.type === 'DEVICE_STATUS' ? data?.triggers[0] : [];
  }

  const deviceTypeIcons = {
    lighting: <ImageWrapper className="mr-1" height={16} width={16} src={Light} />,
    switches: <ImageWrapper className="mr-1" height={16} width={16} src={Switch} />,
    sensors: <ImageWrapper className="mr-1" height={16} width={16} src={Sensor} />,
    receivers: <ImageWrapper className="mr-1" height={16} width={16} src={Receiver} />,
    groups: <ImageWrapper className="mr-1" height={16} width={16} src={Group} />,
    scenes: <ImageWrapper className="mr-1" height={16} width={16} src={Scene} />,
  };
  const deviceTypesObject = {
    lighting: 0,
    switches: 0,
    sensors: automationSensorsList.length,
    receivers: 0,
    groups: 0,
    scenes: 0
  };
  let i = 0;
  for (i = 0; i < deviceList?.length; i++) {
    if (
      deviceList[i]?.category === "AnalogLightDriver" ||
      deviceList[i]?.category === "DaliLightDriver" ||
      deviceList[i]?.category === "RelayLightDriver" ||
      deviceList[i]?.type === 'DEVICE_ACTION'
       //||
      // deviceList[i].category === "DaliLightController" ||
      // deviceList[i].category === "RelayLightController"
    )
      deviceTypesObject["lighting"] += 1;
    else if (deviceList[i]?.category === "Receiver"){
      deviceTypesObject["receivers"] += 1;
    }
    else if (deviceList[i]?.category === "Sensors"){
      deviceTypesObject["sensors"] += 1;
    }
    else if (deviceList[i]?.category === "Switch"){
      deviceTypesObject["switches"] += 1;
    }
    else if (deviceList[i]?.category === "GROUP_ACTION" || deviceList[i]?.type === 'GROUP_ACTION'){
      deviceTypesObject["groups"] += 1;
    }
    else if(deviceList[i]?.type === 'SCENE_ACTION') {
      deviceTypesObject['scenes'] += 1;
    }
    //Added this else if in case where we don't get any category in such a case we will define if its a device(by default lighting chosen) or group
    else if(deviceList[i]?.category === undefined){
      if(deviceList[i]?.deviceId!==undefined){
        deviceTypesObject["lighting"] += 1;
      }
      else if(deviceList[i]?.groupId!==undefined){
        deviceTypesObject["groups"] += 1;
      }
    }
  }
  //This if condition handles "Area" case as data don't have any devices or actions array in it in case of Area so deviceList will be empty in case of Area
  if(data?.areaId!==undefined){
    deviceTypesObject["groups"] = data?.groupIds?.length || 0
    deviceTypesObject["sensors"] = data?.sensorIds?.length || 0
    
  }
  const deviceTypesArray = Object.keys(deviceTypesObject);
  // console.log(
  //   deviceTypesArray.map((item) => {
  //     return deviceTypeIcons[item];
  //   })
  // );

  let deviceTypesData = "";
  let flag = 0;
  for (i = 0; i < deviceTypesArray?.length; i++) {
    let deviceType = deviceTypesArray[i];
    if (deviceTypesObject[deviceType] !== 0) {
      if (flag === 0){
        flag=1;
        deviceTypesData = (
          <>
            {deviceTypeIcons[deviceType]}
            {deviceTypesObject[deviceType]}
          </>
        );
      }
      else
        deviceTypesData = (
          <>
            {deviceTypesData}
            {<span style={{color: "#999"}}>&nbsp;|&nbsp;</span>}
            {deviceTypeIcons[deviceType]}
            {deviceTypesObject[deviceType]}
          </>
        );
    }
  }

  return (
    <div className="DeviceTypes" data-testid="DeviceTypes">
      {deviceTypesData}
      {/* {deviceTypesArray.map((deviceType)=>{
            switch(deviceType){
              case "DaliLightController":
                return (<p>{deviceTypesObject[deviceType]}{deviceTypeIcons[deviceType]}</p>)
              case "RelayLightController":
                return (<p>{deviceTypesObject[deviceType]}<LightbulbRoundedIcon/></p>)
              default:
                return (<p></p>)

                

            }
          })} */}
    </div>
  );
};

DeviceTypes.propTypes = {};

DeviceTypes.defaultProps = {};

export default DeviceTypes;
