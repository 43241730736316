import { tableCellClasses } from '@mui/material/TableCell';

export const style = {
    tableCell: {
        minWidth: "140px",
        width: "max-content",
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#333333',
            fontWeight: 600,
            fontSize: '0.625rem',
            lineHeight: '19px',
            letterSpacing: '0.045em',
            textTransform: 'uppercase',
            color: '#FFFFFF',
            padding: '10px 20px',
            border: 0,
            '& .MuiTableSortLabel-icon': {
                display: 'none'
            },
            '& .MuiTableSortLabel-root:hover': {
                color: '#FFFFFF',
                    '& svg': {
                        color: '#FFFFFF'
                    }
            },
            '& .Mui-active': {
                color: '#FFFFFF',
                '& svg': {
                    color: '#FFFFFF'
                }
            }
        },
        [`&.${tableCellClasses.body}`]: {
            fontWeight: 400,
            fontSize: '0.875rem',
            lineHeight: '19px',
            // color: 'rgba(0, 0, 0, 0.8)',
            padding: '10px 20px',
        }
    },
    tableRow: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
        '&:nth-of-type(odd)': {
            backgroundColor: '#FFFFFF',
        },
        '&:nth-of-type(even)': {
            backgroundColor: 'rgba(15, 141, 72, 0.05)',
        },
    },
    ul: {
        "& .MuiPaginationItem-root": {
            color: "#888",
            fontWeight: 400,
            fontSize: '0.75rem'
        },
        "& .Mui-selected": {
            backgroundColor: '#fff !important',
            color: '#0F8D48',
            fontWeight: 600,
            fontSize: '0.75rem'
        },
        "& .MuiPaginationItem-previousNext": {
            color: '#0F8D48',
            "&.Mui-disabled": {
                color: '#888',
            }
        }
    },
    rowsPerPage:{
        '&.MuiOutlinedInput-root': {
            '& .MuiOutlinedInput-input':{
                padding:'5px 25px 5px 8px',
                lineHeight: '115%',
                height: "min-content",
                fontSize: '0.875rem',
                minWidth: "10px",
                width: "max-content"
            },
            '& .MuiSelect-icon': {
                right: '0px'
            }
        }
    }
}
