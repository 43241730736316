/***
 *
 * Controller class for user.
 * @file Rectangle.js
 * @description Rectangle component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useRef, useEffect, useCallback } from "react";
import { Rect as KonvaRectangle, Transformer } from "react-konva";
// import PropTypes from 'prop-types';
import './Rectangle.scss';

import { LIMITS } from "../../../../constants/canvas.constants";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { canvasActions } from "../../../../redux/actions";
import { useState } from "react";
import { dragBoundCirlceParentToRect, dragBoundRect, dragBoundRectParent, fillColorInShapes, haveIntersection } from "../../../../helpers";
import { closestPointsInPolygon, outsidePolygon } from "../../../../helpers";



export function Rectangle({ id, isSelected, type, draggable = true, profileSpaceId, handleDrag = () => { }, handleClickShape=()=>{}, spaceIds, nodezIndex, getZIndex, ...shapeProps }) {
  const shapeRef = useRef();
  const borderRect = useRef();
  const transformerRef = useRef();
  const getSelectedShape = useSelector(state => state?.canvas?.selectedShape);
  const _getModalState = useSelector(state => state?.canvas?.modalState);
  const _shapeList = useSelector(state => state?.canvas?.shapes);
  const _selectedSpace = useSelector(state => state?.spaces?.selectedSpace);
  const [selectedShape, setSelectedShape] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (isSelected) {
      transformerRef?.current?.nodes([shapeRef?.current]);
      transformerRef?.current?.getLayer().batchDraw();
    }
  }, [isSelected]);

  useEffect(() => {
    // if(getSelectedShape){
    setSelectedShape(getSelectedShape);
    // }
  }, [getSelectedShape])

  useEffect(() => {
    if (!_.isEmpty(selectedShape)) {
      // shapeRef.current.zIndex(shapeProps?.zIndex);      
    }
  }, [selectedShape])

  const boundBoxCallbackForRectangle = (oldBox, newBox) => {
    const parentShape = _shapeList?.find(shape => shape?.spaceId === _selectedSpace?.parentSid);

    // limit resize
    if (
      newBox.width < LIMITS.RECT.MIN ||
      newBox.height < LIMITS.RECT.MIN ||
      newBox.width > LIMITS.RECT.MAX ||
      newBox.height > LIMITS.RECT.MAX
    ) {
      return oldBox;
    }

    if(shapeRef &&  !parentShape &&
      (newBox?.x < 0 ||
      newBox?.y < 0 ||
      newBox?.x + newBox?.width >  shapeRef?.current.getStage().width() ||
      newBox?.y + newBox?.height >  shapeRef?.current.getStage().height())     
      ){
       return oldBox
     }

    if (parentShape &&  parentShape?.type === 'rect' &&
      (newBox?.x < parentShape?.x  ||
      newBox?.y < parentShape?.y  ||
      newBox?.x + newBox?.width > parentShape?.x + parentShape?.width  ||
      newBox?.y + newBox?.height > parentShape?.y + parentShape?.height )
    ) {
      return oldBox
    }
    
    if (parentShape &&  parentShape?.type === 'circle' &&
      ( 
      (newBox.x - parentShape.x)*(newBox.x - parentShape.x)+ (newBox.y - parentShape.y)*(newBox.y - parentShape.y) > parentShape?.radius * parentShape?.radius ||
      (newBox.x + newBox?.width - parentShape.x)*(newBox.x + newBox?.width - parentShape.x)+ (newBox.y + newBox?.height - parentShape.y)*(newBox.y + newBox?.height - parentShape.y) > parentShape?.radius * parentShape?.radius ||
      (newBox.x - parentShape.x)*(newBox.x - parentShape.x)+ (newBox.y + newBox?.height - parentShape.y)*(newBox.y + newBox?.height - parentShape.y) > parentShape?.radius * parentShape?.radius ||
      (newBox.x + newBox?.width - parentShape.x)*(newBox.x + newBox?.width - parentShape.x)+ (newBox.y - parentShape.y)*(newBox.y - parentShape.y) > parentShape?.radius * parentShape?.radius
      )
    ) {
      return oldBox
    }


    return newBox;
  };

  const handleSelect = useCallback(
    (event) => {
      if (_selectedSpace?.spaceId === shapeProps?.spaceId) {
        event.cancelBubble = true;
        dispatch(canvasActions.selectShape(id));
        handleClickShape(event,shapeProps);
      }
    },
    [id]
  );

  const handleDragEvent = useCallback(
    (event) => {
      if (selectedShape) {
        dispatch(canvasActions.moveShape(selectedShape, event));
      }
      handleDrag(event, selectedShape);
      dispatch(canvasActions.selectShape(id));

    },
    [selectedShape]
  );

  const handleTransform = useCallback(
    (event) => {
      if (!_.isEmpty(selectedShape)) {
        dispatch(canvasActions.transformRectangleShape(shapeRef.current, selectedShape, event));
      }

      const node = shapeRef.current;
      const scaleX = node.scaleX();
      const scaleY = node.scaleY();

      // we will reset it back
      node.scaleX(1);
      node.scaleY(1);
    },
    [selectedShape]
  );


  useEffect(() => {
    if(shapeProps?.spaceId === _selectedSpace?.spaceId){
    // shapeRef?.current?.moveUp();
        // console.log('shapeprops::',shapeProps, isSelected);

    // shapeRef?.current?.moveDown();
    // shapeRef?.current?.moveToTop();
    }
    else{
    // shapeRef?.current?.moveDown();
  }

  }, [shapeProps, _selectedSpace])



  const handleDragMove = (e) => {
    const pos = shapeRef?.current?.absolutePosition();
    const SW = shapeRef?.current?.getStage().width();
    const SH = shapeRef?.current?.getStage().height();
    const w = shapeRef?.current?.attrs?.width;
    const h = shapeRef?.current?.attrs?.height;
    const parentShape = _shapeList?.find(shape => shape?.spaceId === _selectedSpace?.parentSid);

    e.target.x(dragBoundRect(SW, SH, w, h, pos).x);
    e.target.y(dragBoundRect(SW, SH, w, h, pos).y);
    if (parentShape?.type === 'rect') {
      const PW = parentShape?.width;
      const PH = parentShape?.height;
      e.target.x(dragBoundRectParent(PW, PH, w, h, parentShape?.x, parentShape?.y, pos).x);
      e.target.y(dragBoundRectParent(PW, PH, w, h, parentShape?.x, parentShape?.y, pos).y);
    }
    else if (parentShape?.type === 'circle') {
      const PR = parentShape?.radius;
      e.target.x(dragBoundCirlceParentToRect(PR, w, h, parentShape?.x, parentShape?.y, pos ).x);
      e.target.y(dragBoundCirlceParentToRect(PR, w, h, parentShape?.x, parentShape?.y, pos ).y);
    }
    else if (parentShape?.type === 'polygon'){
      let scrollY = document.getElementById('paperDOM').scrollTop, scrollX = document.getElementById('paperDOM').scrollLeft;  /// to get how much screen has been scrolled
      let center = [pos?.x + w/2 - scrollX, pos?.y + h/2 - scrollY];
      const isOutside =  outsidePolygon(parentShape?.points, center, scrollX, scrollY);
      if (isOutside) {
        let closest = closestPointsInPolygon(parentShape?.points, center);
        let x_coord = center[0] , y_coord= center[1];
        if (x_coord < Math.min(closest.x1, closest.x2)) {
          x_coord = Math.min(closest.x1, closest.x2) ;
        }
        if (x_coord > Math.max(closest.x1, closest.x2)) {
          x_coord = Math.max(closest.x1, closest.x2) ;
        }   
       
        // y = m * (x-a) +b; here (a,b) is the point of intersection of image center with the polygon line
        y_coord = (closest.slope) * (x_coord - (closest.intersection)[0]) + (closest.intersection)[1];
  
        if (y_coord < Math.min(closest.y1, closest.y2)) {
          y_coord = Math.min(closest.y1, closest.y2)  ;
        }
        if (y_coord  > Math.max(closest.y1, closest.y2)) {
          y_coord = Math.max(closest.y1, closest.y2) ;
        }
        
        e.target.x(x_coord -12); // <---- 
        e.target.y(y_coord -12 ); //<---- 
      
  
      }
    }

  }

  const dragTransformer =(e)=>{
    let target = e.target;
    let targetRect = e.target.getClientRect();
    let siblings = shapeRef?.current?.getStage()?.getChildren()?.filter(child => (child.getAttrs()['data-parentlayerid'] === _selectedSpace?.parentSid) && child.getAttrs()['data-layerid'] !== _selectedSpace?.spaceId);

    siblings.forEach(layer => {
      if (layer === target?.getLayer()) return;
      if (haveIntersection(layer.getClientRect(), targetRect, target?.getAttrs()['data-testid'])) {
        layer.findOne('.fillShape').fill('#fe0000');
      } else {
        layer.findOne('.fillShape').fill('rgba(0,0,0,0.5)');
      }
    })   

}

  return (
    <>
      <KonvaRectangle
        data-testid="Rectangle"
        data-type="shape"
        data-spaceId={shapeProps?.spaceId}
        onClick={handleSelect}
        onTap={handleSelect}
        onDragStart={handleSelect}
        ref={shapeRef}
        // opacity={shapeProps?.spaceId === _selectedSpace?.spaceId? shapeProps?.transparency : 1}
        // opacity={shapeProps?.spaceId === _selectedSpace?.spaceId? 1 : 0.5}
        opacity={spaceIds?.find(s => s?.spaceId === shapeProps?.spaceId) ? 1 : 0.5}
        // opacity={0.5}
        x={shapeProps?.x}
        y={shapeProps?.y}
        height={shapeProps?.height}
        width={shapeProps?.width}
        fill={fillColorInShapes(shapeProps, _selectedSpace, spaceIds, _shapeList?.find(s => s?.spaceId === _selectedSpace?.spaceId) ? true : false)}
        dash={shapeProps?.dash}
        cornerRadius={4}
        stroke={shapeProps?.spaceId === _selectedSpace?.spaceId ? '#1616f7' : '#77e2f0'}
        strokeWidth={3}
        // {...shapeProps}     
        draggable={draggable && _getModalState}
        // name={"rectangle" + shapeProps?.spaceId}
        name={"fillShape"}
        onDragEnd={handleDragEvent}
        onTransformEnd={handleTransform}
        onDragMove={handleDragMove}
      />

      {isSelected && shapeProps?.spaceId === _selectedSpace?.spaceId && (
        <Transformer
          rotateEnabled={false}
          anchorSize={5}
          borderDash={[6, 2]}
          ref={transformerRef}
          onTransform={dragTransformer}
          boundBoxFunc={boundBoxCallbackForRectangle}
        />
      )}

    </>
  )
};

Rectangle.propTypes = {};

Rectangle.defaultProps = {};

export default Rectangle;
