import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'

import { appReducer } from './app.reducer';
import { authentication } from './authentication.reducer';
// import { tenantAuthentication } from './tenantAuthenatication.reducer';
import { registration } from './registration.reducer';
import { users } from './user.reducer';
import { spaces } from './space.reducer';
import { alert } from './alert.reducer';
import { history } from '../../helpers';
import { devices } from './device.reducer';
import { canvas } from './canvas.reducer';
import { roles } from './role.reducer';
import { spaceDTypes } from './spaceType.reducer';
import { groups } from './group.reducer';
import { scenes } from './scene.reducer';
import { ticketing } from './ticketing.reducer';
import { mqttReducer } from './mqtt.reducer';
import { reports } from './report.reducer';
import { automation } from './automation.reducer';
import { areas } from './area.reducer';
import { dragAndDrops } from './dragAndDrop.reducer';
import { dashboard } from './dashboard.reducer' 
import { tenants } from './tenant.reducer';
import { fota } from './fota.reducer';
import { rotateKey } from './rotateKey.reducer';


const rootReducer = combineReducers({
    app: appReducer,
    authentication,
    registration,
    users,
    canvas,
    spaces,
    spaceDTypes,
    devices,
    alert,
    groups,
    areas,
    roles,
    scenes,
    reports,
    ticketing,
    automation,
    dragAndDrops,
    mqtt: mqttReducer,
    dashboard,
    router: connectRouter(history),
    tenants:tenants,
    fota,
    rotateKey
});

export default rootReducer;