/***
 *
 * Controller class for user.
 * @file Shapes.js
 * @description Shapes component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, {  } from "react";
import { SHAPE_TYPES } from "../../../constants/canvas.constants";
import Circle from "./Circle";
import Sticker from "./Sticker";
import Rectangle from "./Rectangle";

// import PropTypes from 'prop-types';
import './Shapes.scss';

const Shapes = ({ shape, isSelectedShape, key, handleContextMenu , profileSpaceId, draggable=true, handleDrag=()=>{}, handleDragEnd=()=>{}, handleClickShape=()=>{}, ...props}) => {

    if (shape.type === SHAPE_TYPES.RECT) {
    return <Rectangle {...shape} 
            isSelected={isSelectedShape}
            profileSpaceId={profileSpaceId}
            draggable={draggable} 
            handleDrag={(e,data)=>handleDrag(e,data)}
            handleClickShape={(e,data)=>handleClickShape(e,data)}
            {...(()=>{
              delete props.key
              return props
            })}
            />;
  } else if (shape.type === SHAPE_TYPES.CIRCLE) {
    return <Circle {...shape}   
            isSelected={isSelectedShape}
            profileSpaceId={profileSpaceId}
            draggable={draggable} 
            handleDrag={(e,data)=>handleDrag(e,data)}
            handleClickShape={(e,data)=>handleClickShape(e,data)}
            {...(()=>{
              delete props.key
              return props
            })}
            />;
  } else if (shape.type === "image") {
    return <Sticker           
            handleContextMenu={handleContextMenu}
            handleDragEnd={(e,data)=>handleDragEnd(e,data)}
            isSelected={isSelectedShape} 
            profileSpaceId={ profileSpaceId}
            onDragEnd={(event) => {
              shape.x = event.target.x();
              shape.y = event.target.y();
            }}                       
            image={shape}
            draggable={draggable}
            {...shape} 
            {...(()=>{
              delete props.key
              return props
            })}
            handleClickShape={(e, data)=>handleClickShape(e, data)}
          />;
  }

  return null;
};

Shapes.propTypes = {};

Shapes.defaultProps = {};

export default Shapes;
