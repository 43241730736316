import { makeStyles } from '@mui/styles';

export const customsx={
    DragIconTray:{
        display: 'flex', position: 'absolute', bottom: '40px', width: '100%', justifyContent: 'center', alignItems: 'center' 
    }
}

export const useStyles = makeStyles({
    ArrowIcon: {
        '&.MuiIconButton-root': {
            borderRadius: "0px",            
            position: 'absolute',
            top: '81px',
            background: '#f4f4f4',
            zIndx: "99999",
            opacity: 1,
            boxShadow: '-2px 0px 5px 1px #d4d4d4',
            '&:hover': {
                background: '#f4f4f4',
            }           
        }
    }
});

