import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    CustomDropDown: {
        '&.MuiOutlinedInput-root': {
            '& .MuiOutlinedInput-input': {
                padding: theme?.padding?.select || "8px 10px",
                fontSize: theme?.fontSize?.select || "0.875rem",
                lineHeight: theme?.lineHeight?.select || "18px",
                height: theme?.height?.select || "25px",
                display:'flex',
                alignItems:'center'
            },
            '& fieldset': {
                top: 0,
                '& legend': {
                    display: "none"
                }
            },
            '& svg': {
                width: "1.25em",
                height: "1.25em",
                top: "initial"
            }
        },
        '& .MuiFormHelperText-root': {
            marginLeft: 0
        }
    }
}));


export const sx = {
    select: {
        // background: '#FFFFFF',
        // border: '1px solid #D9D9D9',
        // borderRadius: '4px',
        // fontWeight: 400,
        // fontSize: '14px',
        // lineHeight: '17px',
        // color: 'rgba(0, 0, 0, 0.6)',
        // '& .MuiOutlinedInput-notchedOutline': {
        //     border: 'none'
        // },
        // '& .MuiOutlinedInput-input': {
        //     padding: '8px 32px 8px 10px',
        // },
        // '& .MuiSelect-icon': {
        //     marginTop: '4px'
        // }
    },
    menu: {
        padding: 0
    },
    paper: {
        boxSizing: 'content-box',
        background: '#FFFFFF',
        border: '1px solid #D9D9D9',
        boxShadow: '0px 2px 25px rgba(0, 0, 0, 0.07)',
        borderRadius: '0px 0px 4px 4px',

    },
    input: {
        background: 'none',
        border: 'none',
        borderRadius: '0',
        color: 'rgba(0, 0, 0, 0.8)',
        fontWeight: 400,
        fontSize: '0.875rem',
        lineHeight: '17px',
    },
    menuItem: {
        fontWeight: 400,
        fontSize: '0.875rem',
        lineHeight: '19px',
        color: 'rgba(0, 0, 0, 0.8)',
        padding: '6px 16px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.05)'
    }
}
