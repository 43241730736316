import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme)=>({
    CustomLabel: {       
        '&.MuiFormLabel-root': {
            fontWeight: theme?.fontWeight?.label || "500",
            fontSize: theme?.fontSize?.label || "0.875rem",
            lineHeight: theme?.lineHeight?.label || "14px",
            height: theme?.height?.label || "14px",
            color: theme?.palette?.label?.color || "#222222",
        }
    }
}));
