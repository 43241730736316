import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    FirmwareDistribution: {
        "& .searchContainer": {
            border: "1px solid #EAEAEA",
            // padding: "20px 24px",
            display: "flex",
            // justifyContent: "space-between",
            // alignItems:"flex-end",
            margin:"0px 0px 16px -4px"
        },
        "& .StyledCardWrapper:hover":{
            cursor:"pointer"
        }
    }
});
