import { API_URL } from "../config/appConfig";
import { handleResponse } from "../helpers";
import { API } from "./api.service";

const subscribeNotification = (payload) => {
    return API.post(`${API_URL?.notification}/notification/subscribe`, "subscribeNotification", payload).then((res) => {
        return handleResponse(res, true);
    });
}
const unsubscribeNotification = (payload) => {
    return API.post(`${API_URL?.notification}/notification/unsubscribe`, "unsubscribeNotification", payload).then((res) => {
        return handleResponse(res, true);
    });
}
export const notificationService = {
    subscribeNotification,
    unsubscribeNotification
}