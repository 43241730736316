/***
 *
 * Controller class for user.
 * @file StyledTextfield.js
 * @description StyledTextfield component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

// import { Tooltip } from '@material-ui/core';
import { TextField } from '@mui/material';
// import { styled } from '@mui/styles';
import React, { useRef } from 'react';
// import { useEffect } from 'react';
import PopoverContainer from '../PopoverContainer/index.js';
// import PropTypes from 'prop-types';
import { useStyles } from './StyledTextfield.style.js';
import { useState } from 'react';
import CustomTypography from '../CustomTypography/index.js';
import _ from 'lodash';
import { forwardRef } from 'react';

// const StyledTextfield = styled(TextField)(({ theme }) => ({
//   '& .MuiOutlinedInput-root': {
//     '& .MuiOutlinedInput-input': {
//       padding: theme?.padding?.input,
//       fontSize: theme?.fontSize?.input,
//       lineHeight: theme?.lineHeight?.input,
//       height: theme?.height?.input || "24px",
//     },
//     '& fieldset': {
//       top: 0,
//       '& legend': {
//         display: "none"
//       }
//     }
//   },
//   '& .MuiFormHelperText-root': {
//     marginLeft: 0
//   }

// }));

// console.log("inputClasses -- ", outlinedInputClasses)
// export default StyledTextfield;
const StyledTextfield = forwardRef(({ onKeyDown, onKeyPress, errorPopup = false, defaultReadOnly = true, hideInputArrows = false, ...props }, ref) => {
  const classes = useStyles();
  const [tooltip, setToolTip] = useState(null);
  const inputRef = useRef();

  // document.addEventListener('click',(e)=>{
  //   if(inputRef?.current !== e.target && defaultReadOnly) {
  //     inputRef?.current?.setAttribute('readonly', true);
  //   }
  // })

  return (
    <>
      <TextField ref={ref}
        id={props?.id ? props?.id : _.uniqueId('input__')}
        inputRef={inputRef}
        inputProps={{
          ...props?.inputProps
        }}
        onFocus={(e) => { if (inputRef?.current?.hasAttribute('readonly')) { inputRef?.current?.removeAttribute('readonly'); } }}
        {...props}
        readOnly={defaultReadOnly || false}
        onKeyDown={onKeyDown}
        helperText={errorPopup ? null : props?.helperText}
        onMouseEnter={(e) => {
          errorPopup && setToolTip(e?.currentTarget)
        }}
        className={`${classes?.StyledTextfield} ${hideInputArrows && 'hideInputArrows'}`}
        data-testid="StyledTextfield" />
      {props?.helperText &&
        <PopoverContainer
          style={{ zIndex: props?.sx?.errorPopup?.zIndex || 1400 }}
          anchorEl={tooltip}
        >
          <CustomTypography
            content={props?.helperText}
            color={"#FF0000"}
            size={12}
          />
        </PopoverContainer>
      }
    </>
  )
});

StyledTextfield.propTypes = {};

StyledTextfield.defaultProps = {};

export default StyledTextfield;
