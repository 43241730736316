/***
 *
 * Controller class for user.
 * @file ReportDocumentDetail.js
 * @description ReportDocumentDetail component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './ReportDocumentDetail.style.js';
import './ReportDocumentDetail.scss';
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { useState, useEffect } from "react";

import CustomTypography from "../CustomTypography/index.js";
import CustomTable from "../CustomTable/index.js";
import { reportActions } from "../../redux/actions/report.actions.js";
import ImageWrapper from "../ImageWrapper";
import { reportService } from "../../services/index.js";
import { appActions } from "../../redux/actions/app.actions.js";

import { failure, getMessage } from "../../helpers";
import { reportType } from "../../redux/types/report.type.js";
import { success } from "../../helpers";

import _ from "lodash";
import StyledPopupWrapper from '../StyledPopupWrapper/index.js';
import generateReport from "../../assets/icons/generate.svg";
import refreshReport from "../../assets/icons/refresh.svg";
import shareDocument from "../../assets/icons/share.svg";
import DownloadDocument from "../../assets/icons/download.svg";
import ViewDocument from "../../assets/icons/view.svg";
import { features, permissions } from '../../constants/permission.constants.js';
import { isAuthorised } from '../../helpers';
import { delay } from '../../services/index.js';
import FormWrapper from '../FormWrapper/index.js';
import { reportMessages, categories, scopeList } from "../../constants/index.js";
import StyledButton from '../StyledButton/index.js';


const shareFrmConfig = [
  {
    section: null,
    columns: [
      {
        id: "1",
        type: "freetextchipinput",
        label: "Add Subscribers",
        name: "subscribers",
        placeholder: "Press enter to add new emails",
        width: "300px",
        visibleIn: ["edit", "view", "create"],
        chipsPosition: "bottom",
        validation: {
          req: [
            true, `${reportMessages["report-shareReportValidation-email"]}`],
          email: [
            true, `${reportMessages["report-shareReportValidation-emailInvalid"]}`
          ]
        },
        columnSize: 12,
      },
    ]
  }
]

const ReportDocumentDetail = ({ rId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const _documentList = useSelector((state) => state?.reports?.documentList);
  const _documenturl = useSelector((state) => state?.reports?.documentUrl);

  const [documentList, setDocumentList] = useState(_documentList);
  const [shareFrm, setShareFrm] = useState(null);
  const [shareRowData, setShareRowData] = useState(null);
  const [docUrl, setDocUrl] = useState("");
  const [openReportPopup, setOpenReportPopup] = useState(false);
  const [openSharePopup, setOpenSharePopup] = useState(false);
  const [userAction, setUserAction] = useState([]);

  function downloadPdf(url) {
    const link = document.createElement("a");
    link.href = url;

    link.download = "report.pdf";
    document.body.appendChild(link);
    link.click();

  }

  const shareCustomSx = {
    dialog: {
      "& .MuiDialog-paper": { width: "500px" },
      "& .css-1t5rjz-MuiPaper-root-MuiDialog-paper": { maxWidth: "800px" },
    },
  };

  const shareActionButtons = [
    {
      heading: null,
      columns: [
        {
          heading: null,
          buttons: [
            {
              id: "bckbtn",
              type: "button",
              display: true,
              variant: 'outlined',
              label: "Cancel",
              onClick: (event, data) => {
                setOpenSharePopup(false);
              }
            },
            {
              id: "savebtn",
              type: "button",
              display: true,
              checkValidation: true,
              label: 'Save',
              onClick: (event, data) => {
                handleShare(data)
                setOpenSharePopup(false);
              },
            },
          ],
          columnSize: 12,
        },
      ],
    },
  ];

  const handleShare = (data) => {
    let payload = data?.subscribers || [];
    dispatch(
      reportActions.shareReportDocument(
        shareRowData.reportId,
        shareRowData?.id,
        payload
      )
    );
  };

  const handleRefresh = () => {
    dispatch(reportActions.getAllReportDocument(rId));
  };

  const handleGenerate = (event) => {
    dispatch(reportActions.generateReportDocument(rId, () => {
      dispatch(reportActions.getAllReportDocument(rId));
    }));
  };

  const headerList = {
    createdOn: {
      label: "Created On",
      type: 'date'
    },
    scope: {
      label: "Scope",
      type: "list",
      separator: " | ",
    },
    spaceIdCount: {
      label: "Space Id Count",
    },
    categories: {
      label: "Category",
      type: "list",
      separator: " | ",
    },
    userActions: {
      label: "Actions",
      actions: userAction,
      style: { textAlign: "right" },
    },
  };

  const popUpCustomSx = {
    dialog: {
      "& .MuiDialog-paper": { width: "1400px", height: "750px" },
      "& .css-1t5rjz-MuiPaper-root-MuiDialog-paper": { maxWidth: "1400px" },
    },
  };

  useEffect(() => {
    const list = [];
    if (isAuthorised(features?.report, permissions?.download)) {
      list.push({
        label: "View",
        type: "view",
        icon: ViewDocument,
        onClick: (event, data) => {
          reportService.getReportDocumentUrl(data.reportId, data.id).then(
            ({ data: resp, code }) => {
              setDocUrl(resp?.viewableSignedUrl)

              dispatch(
                success(reportType.DOWNLOAD_REPORT_SUCCESS, { payload: resp })
              );
              setOpenReportPopup(true);
            },
            ({ error, code }) => {
              dispatch(
                failure(reportType.DOWNLOAD_REPORT_FAILURE, error.toString())
              );
              getMessage(`downloadReportDocumentById-get-${code}`).then(delay(500)).then((err) => {
                dispatch(appActions.apiErrorMessage(err));
              })
            }

          );
        },
      })
      list.push({
        label: "Download",
        type: "download",
        icon: DownloadDocument,
        onClick: (event, data) => {
          if (data && data.id && data.reportId) {
            reportService.downloadReportDocument(data.reportId, data.id).then(
              ({ data: resp, status }) => {
                downloadPdf(resp?.downloadableSignedUrl);
              },
              ({ error, code }) => {
                dispatch(
                  failure(
                    reportType.DOWNLOAD_REPORT_FAILURE,
                    error.toString()
                  )
                );
                getMessage(`downloadReportDocumentById-get-${code}`).then(delay(500)).then((err) => {
                  dispatch(appActions.apiErrorMessage(err));
                });
              }
            );
          }
        },
      })
    }
    if (isAuthorised(features?.report, permissions?.share)) {
      list.push({
        label: "Share",
        type: "share",
        icon: shareDocument,
        onClick: (event, data) => {
          setOpenSharePopup(true);
          setShareRowData(data);
        },
      })
    }
    setUserAction(list)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // useEffect(() => {
  //   if (rId) {
  //     dispatch(reportActions.getReportById(rId));
  //     dispatch(reportActions.getAllReportDocument(rId));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (_documenturl) {
      setDocUrl(_documenturl);
    }
  }, [_documenturl]);

  // useEffect(()=>{
  //   if(_.isEmpty(userIdData)){
  //     dispatch(ticketingActions.getAssignees())
  //   }
  // },[userIdData]);

  useEffect(() => {
    if (!_.isEmpty(_documentList)) {
      let docList = _documentList.map((doc) => {
        return {
          ...doc,
          createdOn:
            doc && doc.createdOn
              ? doc.createdOn
              : 0,
          spaceIdCount: doc && doc.spaceIds ? doc.spaceIds.length : 0,
          scope:
            doc && doc.scope
              ? doc.scope.map((val) => {
                return scopeList?.find(s => s?.value === val)?.label;
              })
              : [],
          categories: doc.categories
            ? doc.categories.map((c) => {
              return categories?.find(cat => cat?.value === c)?.label;
            })
            : [],
        };
      });
      setDocumentList(docList);
    }
  }, [_documentList]);

  return (
    <Box className={classes?.ReportDocumentDetail || ""} data-testid="ReportDocumentDetail">
      <Box className={classes?.DocumentHeader || ""}>
        <CustomTypography
          content="Document Details"
          weight={700}
          size={14}
          lineHeight={14.63}
          color="#000"
        />

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          {isAuthorised(features?.report, permissions?.generate) &&
            <StyledButton iconButton tooltip='Generate' sx={{ p: 0, ml: 2 }} onClick={handleGenerate}>
              <ImageWrapper
                src={generateReport}
              // enablePopOver={true}
              // hoverLabel=""
              />
            </StyledButton>}
          <StyledButton iconButton tooltip='Refresh' sx={{ p: 0, ml: 2 }} onClick={handleRefresh}>
            <ImageWrapper
              src={refreshReport}
            // enablePopOver={true}
            // hoverLabel="Refresh"
            />
          </StyledButton>
        </Box>
      </Box>
      <CustomTable
        headerList={headerList}
        cellMatrix={documentList}
        pagination={true}
        sorting={{ columns: ['createdOn', 'scope', 'spaceIdCount', 'categories'], order: 'asc' }}
        searching={false}
        enableScrollToTop={false}
      ></CustomTable>
      <StyledPopupWrapper title={"Share Report"} open={openSharePopup} close={false} customsx={shareCustomSx}>
        <FormWrapper
          formFields={shareFrmConfig}
          setPayload={setShareFrm}
          formButtons={shareActionButtons}
        />
      </StyledPopupWrapper>
      <StyledPopupWrapper
        fullWidth={true}
        maxWidth="1300px"
        title={"View Document"}
        open={openReportPopup}
        onClose={() => setOpenReportPopup(false)}
      // customsx={popUpCustomSx}
      >{<Box sx={{ display: "flex", flexDirection: 'column', alignItems: 'flex-end' }}>
        <StyledButton sx={{ margin: "2px 5px 5px 5px" }} size="small" variant="contained" fullWidth={false} onClick={() => setOpenReportPopup(false)}>Close</StyledButton>
        <iframe
          src={`${docUrl}#zoom=100`}
          title="testPdf"
          width="100%"
          height="550px"
        />
      </Box>}
      </StyledPopupWrapper>
    </Box>
  )
};

ReportDocumentDetail.propTypes = {};

ReportDocumentDetail.defaultProps = {};

export default ReportDocumentDetail;
