/***
 *
 * Controller class for user.
 * @file DeviceSettings.js
 * @description DeviceSettings component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './DeviceSettings.style.js';
import './DeviceSettings.scss';
import { Box } from '@mui/material';
import PhysicalParamters from '../PhysicalParameters/index.js';

const DeviceSettings = ({ data, ...props }) => {
  const classes = useStyles()
  useEffect(()=>{
    console.log("data changed 3")
  },[data?.loading])
  return (
    <Box className={classes?.DeviceSettings || ""} data-testid="DeviceSettings">
      <PhysicalParamters deviceData={data} {...props} />
    </Box>
  )
};

DeviceSettings.propTypes = {};

DeviceSettings.defaultProps = {};

export default DeviceSettings;
