import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    TicketDetails: {
        '& .ticketSubHeading': {
            padding: "1rem",
            background: "#0f8d480d"
        },
        '& .ticketsDetails': {
            display: "flex",
            justifyContent: "space-between",
            padding: "1rem 1rem 2rem 1rem",

        },
        '& .btnGrid': {
            display: "flex",
            alignItems: "center",
            paddingBottom: "10px",
            cursor: "pointer", '&:hover': "background: lightgray",
            // width: "140px",
        },
        '& .btnGridDisabled': {
            display: "flex",
            alignItems: "center",
            // background: "#EAEAEA",
            borderRadius: "4px",
            // width: "140px",
        },
        '& .imgDiv': {
            borderRadius: "5px",
            maxWidth: "355px",
            marginTop: "44px",
            marginBottom: "10px",
            height: "264px",
            background: "#FAFDFB"
        },
        '& .sideButton': {
            height: "25px", width: "20px", padding: "2px"
        },
        '& .avatar': {
            cursor: 'pointer',
            backgroundColor: "lightgray",
            color: '#fff',
            marginRight: '10px',
            height: '35px !important',
            width: '35px !important',
        },

        '& .underline': {
            cursor: "pointer",
            '&:hover': {
                'text-decoration': "underline",
                'text-decoration-color': 'gray'
            }

        },

        '& .textSpan': {
            fontSize: '0.75rem',
            color: "gray",
            fontWeight: "400"
        },

        '& .noComment': {
            fontWeight: 200,
            fontSize: "0.9em",
            background: "#F9F9FE",
            minWidth: "95%",
            minHeight: "10px",
            borderRadius: "10px",
            padding: "5px"
        },
        '& .d-flex': {
            display: "flex"
        },
        '& .justify-sb': {
            justifyContent: "space-between"
        },
        '& .attachment': { fontWeight: 500, fontSize: '0.875rem', marginBottom: "5px" },
        '& .ticketAttachment': { fontWeight: 200, fontSize: "0.5em", marginLeft: "10px" },
        '& .timeline': { marginBottom: "0px", padding: "0px 16px" },
        '& .btnText': { fontWeight: 600, fontSize: "0.9em", alignContent: "center" },
        '& .ticketSubHeadingTypo': { fontWeight: 600, fontSize: "1em" },
        '& .detailsText': { fontSize: "0.9em" }
    },


});
