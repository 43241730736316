import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    StyledMultiSelect: {
        padding: theme?.padding?.multiselect || "8px 10px",
        fontSize: theme?.fontSize?.multiselect || "0.875rem",
        lineHeight: theme?.lineHeight?.multiselect || "20px",
        minHeight: theme?.minHeight?.multiselect || "24px",
        borderColor: theme?.borderColor?.multiselect || "rgba(0, 0, 0, 0.23)",
        borderRadius: theme?.borderRadius?.multiselect || "4px",
        width: theme?.width?.multiselect || "100%",
        '& .MuiOutlinedInput-root': {
            '& .MuiOutlinedInput-input': {
                padding: theme?.padding?.multiselect || "8px 10px",
                fontSize: theme?.fontSize?.multiselect || "0.875rem",
                lineHeight: theme?.lineHeight?.multiselect || "24px",
                // height: theme?.height?.multiselect || "24px",
            },
            '& fieldset': {
                top: 0,
                '& legend': {
                    display: "none"
                }
            }
        },
        '& .MuiFormHelperText-root': {
            marginLeft: 0
        }
    }
}));
