export const tenantTypes = {
  GET_TENANT_REQUEST: "GET_TENANT_REQUEST",
  GET_TENANNT_SUCCESS: "GET_TENANT_SUCCESS",
  GET_TENANT_FAILURE: "GET_TENANT_FAILURE",

  GET_TENANT_BY_NAME_REQUEST: "GET_TENANT_BY_NAME_REQUEST",
  GET_TENANT_BY_NAME_SUCCESS: "GET_TENANT_BY_NAME_SUCCESS",
  GET_TENANT_BY_NAME_FAILURE: "GET_TENANT_BY_NAME_FAILURE",


  GET_ALL_TENANT_REQUEST: "GET_ALL_TENANT_REQUEST",
  GET_ALL_TENANT_SUCCESS: "GET_ALL_TENANT_SUCCESS",
  GET_ALL_TENANT_FAILURE: "GET_ALL_TENANT_FAILURE",

  GET_TENANT_WEBCONFIG_REQUEST: "GET_TENANT_WEBCONFIG_REQUEST",
  GET_TENANT_WEBCONFIG_SUCCESS: "GET_TENANT_WEBCONFIG_SUCCESS",
  GET_TENANT_WEBCONFIG_FAILURE: "GET_TENANT_WEBCONFIG_FAILURE",


  CREATE_TENANT_REQUEST: "CREATE_TENANT_REQUEST",
  CREATE_TENANT_SUCCESS: "CREATE_TENANT_SUCCESS",
  CREATE_TENANT_FAILURE: "CREATE_TENANT_FAILURE",

  UPDATE_TENANT_REQUEST: "UPDATE_TENANT_REQUEST",
  UPDATE_TENANT_SUCCESS: "UPDATE_TENANT_SUCCESS",
  UPDATE_TENANT_FAILURE: "UPDATE_TENANT_FAILURE",

  UPDATE_TENANT_WEBCONFIG_REQUEST: "UPDATE_TENANT_WEBCONFIG_REQUEST",
  UPDATE_TENANT_WEBCONFIG_SUCCESS: "UPDATE_TENANT_WEBCONFIG_SUCCESS",
  UPDATE_TENANT_WEBCONFIG_FAILURE: "UPDATE_TENANT_WEBCONFIG_FAILURE",

  ENABLE_TENANT_REQUEST: "ENABLE_TENANT_REQUEST",
  ENABLE_TENANT_SUCCESS: "ENABLE_TENANT_SUCCESS",
  ENABLE_TENANT_FAILURE: "ENABLE_TENANT_FAILURE",

  DISABLE_TENANT_REQUEST: "DISABLE_TENANT_REQUEST",
  DISABLE_TENANT_SUCCESS: "DISABLE_TENANT_SUCCESS",
  DISABLE_TENANT_FAILURE: "DISABLE_TENANT_FAILURE",

  DELETE_TENANT_REQUEST:"DELETE_TENANT_REQUEST",
  DELETE_TENANT_SUCCESS:"DELETE_TENANT_SUCCESS",
  DELETE_TENANT_FAILURE:"DELETE_TENANT_FAILURE",

  GET_SELF_PROFILE_REQUEST: 'USER_GET_SELF_PROFILE_REQUEST',
  GET_SELF_PROFILE_SUCCESS: 'USER_GET_SELF_PROFILE_SUCCESS',
  GET_SELF_PROFILE_FAILURE: 'USER_GET_SELF_PROFILE_FAILURE',

  CLEAR_TENANT_DETAILS_REQUEST:'CLEAR_TENANT_DETAILS_REQUEST',
  CLEAR_TENANT_DETAILS_SUCCESS:'CLEAR_TENANT_DETAILS_SUCCESS',
  CLEAR_TENANT_DETAILS_FAILURE:'CLEAR_TENANT_DETAILS_FAILURE',
};