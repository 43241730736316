/***
 *
 * Controller class for user.
 * @file ResetCredPage.js
 * @description ResetCredPage component
 * @author Ayesha Kumar
 * @since 29 Feb 2024
 * 
 */

// import PropTypes from 'prop-types';
import { useStyles } from './ResetCredPage.style.js';
import { Box, Grid } from '@mui/material';
import { React, useState } from 'react';
import Miraie from '../../assets/logo/logo-miraie.png';
import ImageWrapper from '../../components/ImageWrapper/index.js';
import frame from '../../assets/img/login-bg.png';
import _ from "lodash";
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../redux/actions/index.js';
import { containsSpace } from '../../services/util.service.js';
import TenantLogo from '../../assets/logo/panasonic.png';
import { history } from '../../helpers';
import Validator from '../../helpers/validator.helper.js';
import ResetCred from '../../components/ResetCred/index.js';

const pValueArray = [
  {
    id: "pass_old",
    type: "password",
    label: "Enter old password",
    name: "oldPValue",
    error: false,
    errorMsg: "",
    minMaxLen: false,
    reqDigit: false,
    reqSpecial: false,
    reqUpper: false,
  },
  {
    id: "pass_new",
    type: "password",
    label: "Enter new password",
    name: "newPValue",
    error: false,
    errorMsg: "",
    minMaxLen: false,
    reqDigit: false,
    reqSpecial: false,
    reqUpper: false,
  },
  {
    id: "pass_confirm",
    type: "password",
    label: "Re-enter new password",
    name: "reEnterPValue",
    error: false,
    errorMsg: "",
  },
];
const ResetCredPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const portal = useSelector(state => state?.app?.portal);
  const userId = useSelector(state => state?.authentication?.user?.userId);
  const webConfig = useSelector(state => state?.app?.webConfig);

  const [resetPValue, setResetPValue] = useState({
    newPValue: "",
    reEnterPValue: "",
    oldPValue: ""
  });
  const [resetPValueArray, setResetPValueArray] = useState(pValueArray?.map(p => ({ ...p })));

  const handlePValueChange = (e) => {
    const target = e.target;

    setResetPValue({
      ...resetPValue,
      [target.name]: target.value,
    });
    let arr = []
    arr = resetPValueArray?.map(p => {
      if (p?.name === target?.name && ["pass_new", "newPValue"].includes(target?.name)) {
        p["minMaxLen"] = _.isEmpty(Validator(target.value, "text", { minLen: [8, ""] })) && _.isEmpty(Validator(target.value, "text", { maxLen: [20, ""] }));
        p["reqSpecial"] = /.*[#@_-]/.test(target.value);
        p["reqDigit"] = /.*[0-9]/.test(target.value);
        p["reqUpper"] = /.*[A-Z]/.test(target.value);
        p["valerror"] = !p?.minMaxLen || !p?.reqSpecial || !p?.reqDigit || !p?.reqUpper;
      }
      p.error = false;
      p["errorMsg"] = ``;
      return p;
    }
    )
    setResetPValueArray(arr);
    // const arr = resetPValueArray.map((item) => {
    //   if (["pass_new", "newPValue"].includes(item?.id)) {
    //     item["minMaxLen"] =
    //       _.isEmpty(Validator(target.value, "text", { minLen: [8, ""] })) &&
    //       _.isEmpty(Validator(target.value, "text", { maxLen: [20, ""] }));
    //     item["reqSpecial"] = /.*[#@_-]/.test(target.value);
    //     item["reqDigit"] = /.*[0-9]/.test(target.value);
    //     item["reqUpper"] = /.*[A-Z]/.test(target.value);
    //   }
    //   item["error"] = !item?.minMaxLen || !item?.reqSpecial || !item?.reqDigit || !item?.reqUpper;
    //   item["errorMsg"] = ``;
    //   return item;
    // });
  };

  const handleSubmit = () => {
    if (
      _.isEmpty(resetPValue["newPValue"]) ||
      _.isEmpty(resetPValue["reEnterPValue"]) ||
      _.isEmpty(resetPValue["oldPValue"])
    ) {
      const arr = resetPValueArray.map((item) => {
        if (_.isEmpty(resetPValue[item?.name])) {
          item["error"] = true;
          item["errorMsg"] = `Given field cannot be empty`;
        }
        return item;
      });
      setResetPValueArray(arr);
      return;
    }
    else if (
      resetPValue["newPValue"] &&
      resetPValue["reEnterPValue"] &&
      resetPValue["newPValue"] !== resetPValue["reEnterPValue"]
    ) {
      const arr = resetPValueArray.map((item) => {
        if (item["name"] !== "oldPValue") {
          item["error"] = true;
          item["errorMsg"] = `Passwords do not match`;
        }
        return item;
      });
      setResetPValueArray(arr);
      return;
    }
    else if (
      resetPValue["oldPValue"] &&
      resetPValue["newPValue"] &&
      resetPValue["newPValue"] === resetPValue["oldPValue"]
    ) {
      const arr = resetPValueArray.map((item) => {
        if (item["name"] !== "reEnterPValue") {
          item["error"] = true;
          item["errorMsg"] = `Passwords match`;
        }
        return item;
      });
      setResetPValueArray(arr);
      return;
    }
    else if (
      containsSpace(resetPValue["oldPValue"]) ||
      containsSpace(resetPValue["newPValue"]) ||
      containsSpace(resetPValue["reEnterPValue"])
    ) {
      const arr = resetPValueArray.map((item) => {
        if (containsSpace(resetPValue[item?.name])) {
          item["error"] = true;
          item["errorMsg"] = `Password cannot contain whitespace`;
        }
        return item;
      });
      setResetPValueArray(arr);
      return;
    }
    else if (resetPValueArray?.filter(p => p?.error === true)?.length === 0 && !resetPValueArray?.find(p => p?.name === "newPValue")?.valerror) {
      dispatch(userActions.changeSelfCred(portal === 'mss' ? { oldPassword: resetPValue["oldPValue"], password: resetPValue["newPValue"] } : { password: resetPValue["newPValue"] }, portal, () => {
        history?.push("/login")
      }));
    }
    else {
      console.log("error")
      const arr = resetPValueArray?.map((p) => {
        if (p?.name === "newPValue") {
          p.error = true;
          p.errorMsg = "Password must satisfy the provided conditions"
        }
        return p;
      });
      setResetPValueArray(arr);
    }
  }

  return (
    <Box className={classes?.ResetCredPage || ""} data-testid="ResetCredPage">
      <Grid container sx={{ height: "100%" }}>
        <Grid item sm={6} style={{ display: "flex", justifyContent: "center", alignItems: "center", position: 'relative', backgroundImage: `url(${frame})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat", width: "100%", padding: "40px" }}>
          <ImageWrapper component="Image" src={Miraie} sx={{ maxWidth: { md: "300px", sm: "250px", xs: "200px" }, width: "-webkit-fill-available" }} alt='mirai-logo' />
        </Grid>
        <Grid container item sm={6} style={{ display: "flex", justifyContent: "center", alignItems: "center", background: '#ffffff', minHeight: "550px", overflow: "auto" }} justifyContent="center" alignItems={'center'}>
          <Grid container item xs={12} justifyContent="center" sx={{ minHeight: "calc(100% - 70px)", padding: "30px 15px" }}>
            <Grid container item flexDirection={'column'} disp alignItems={'flex-start'} width={{ md: '350px', sm: '300px', xs: '280px' }}>
              <Box sx={{ mb: 3, width: "100%", textAlign: 'left' }}><ImageWrapper src={portal === "mss" ? webConfig?.webApp?.logoBitmap : TenantLogo} width={160} /></Box>
              <Box className='login-title' sx={{ mb: 6 }}>Reset your password</Box>
              <ResetCred
                handlePValueChange={handlePValueChange}
                isdisabled={false}
                onSubmit={(e) => {
                  handleSubmit();
                }}
                pArray={resetPValueArray}
                resetPValue={resetPValue}
                buttonLabel="Change Password"
                extraButtons={[{ label: "Back to Login", variant: "outlined", onClick: () => { history?.push("/login") } }]}
              />
            </Grid>

          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "60px" }}>
            <small>© 2024 - Panasonic Life Solutions India Pvt. Ltd. All rights reserved</small>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
};

ResetCredPage.propTypes = {};

ResetCredPage.defaultProps = {};

export default ResetCredPage;
