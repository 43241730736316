import { mqttMessageSource } from "../constants/mqtt.constants";
import {
  getLocalItem,
  mqttConnect,
  mqttDisconnect,
  mqttPublish,
  mqttSubscribe,
  mqttUnsubscribe,
  updateAreaDeleteStatus,
  updateConnnectivityStatus,
  updateControllerStatus,
  updateFwStatus,
  updateGroupControl,
  updateGroupDeleteStatus,
  updateGroupStatus,
  updateLightControl,
  updateSceneDeleteStatus,
  updateSceneStatus,
} from "../helpers";
import { mqttActions } from "../redux/actions";
import _ from "lodash";
import { tenantName } from "../config/appConfig"

const callbacks = {};

export const mqttRequest = (
  {
    subTopic = null,
    pubTopic = `${tenantName}/P0gx/comcontrol/VortexGateway/kDH7`,
    deviceId = "",
    deviceConfig = {},
    onRecieve = () => { },
    onFail = () => { },
  },
  mqttClient,
  dispatch = null,
  key = "mqttRequest"
) => {
  // let timeout = null;
  console.log("sending mqtt message,connection status:", mqttClient?.connected)
  const mqttPayload = {
    cnt: mqttMessageSource?.webapp,
  };

  if (deviceId) {
    mqttPayload["deviceId"] = deviceId;
  }
  // if (deviceConfig?.cmd === "startReceiverPairing" ) {
  //   deviceConfig.durationInSecs = MQTT.PAIRING_DURATION;
  // }

  switch (deviceConfig?.cmd) {
    case "startReceiverPairing":
      callbacks["startPairing" + deviceConfig?.receiverId] = onRecieve;
      callbacks["startPairing" + deviceId] = onRecieve;
      break;
    case "stopReceiverPairing":
      callbacks["stopPairing" + deviceConfig?.receiverId] = onRecieve;
      break;
    case "deviceIdentify":
      callbacks["deviceIdentify" + deviceId] = onRecieve;
      break;
  }
  if (deviceConfig?.hasOwnProperty("lst") && !deviceConfig?.hasOwnProperty("grpId")) {
    callbacks["deviceLstControl" + deviceId] = onRecieve;
  }
  else if (deviceConfig?.hasOwnProperty("klv") && !deviceConfig?.hasOwnProperty("grpId")) {
    callbacks["deviceTempControl" + deviceId] = onRecieve;
  }

  for (const key in deviceConfig) {
    // console.log(key, deviceConfig[key]);
    mqttPayload[key] = deviceConfig[key];
  }
  console.log("CALLBACKS", callbacks)
  // if(temperature || temperature === 0) {
  //     mqttPayload["klv"] = temperature || MQTT.TEMPERATURE;
  // }
  // if(intensity || intensity === 0) {
  //     mqttPayload["lst"] = intensity || MQTT.INTENSITY;
  // }

  // const messageRecieved = (message, topic) => {
  //   // if (key === "lightControl" && message?.deviceId === deviceId) {
  //   //   clearTimeout(timeout);
  //   // }
  //   mqttMessageRecieved(message, topic, dispatch, key, onRecieve);
  // }
  const sendMqttCommand = () => {
    const sid = Date.now().toString();
    mqttPayload.sid = sid;
    mqttPayload.ts = Date.now();
    mqttPayload.uid = JSON?.parse(getLocalItem("user"))?.userId;
    // if(key!=="light"){
    const mqttSidPayload = { deviceId, key, sid, };
    if (deviceConfig?.receiverId) {
      mqttSidPayload.receiverId = deviceConfig?.receiverId
    }
    dispatch(mqttActions.sendMqttRequest(mqttSidPayload));
    // dispatch(groupActions.getAllGroupsBySpace("kDH7"))
    // }
    // dispatch(appActions.sendMqttRequest({ deviceId, key, sid }));
    callbacks.onRecieve = onRecieve
    console.log("topic found 2", dispatch(mqttActions?.findMqttTopic(subTopic)))
    if (subTopic !== null && !dispatch(mqttActions?.findMqttTopic(subTopic))) {
      mqttSubscribe(subTopic, (message, topic) => {
        mqttMessageRecieved(message, topic, dispatch, key);
      }, mqttClient);
      dispatch(mqttActions?.addMqttTopic(subTopic))
    }
    // if (key === "lightControl") {
    //   timeout = setTimeout(() => {
    //     dispatch(deviceActions.stopLoading(deviceId));
    //     // unsubscribe(subTopic,mqttClient);
    //     dispatch(mqttActions?.deleteMqttRequest(sid));
    //   }, setMqttTimeout());
    // }
    mqttPublish(pubTopic, mqttPayload, mqttClient);
    return sid;
  };
  return sendMqttCommand();
  // mqttConnect(sendMqttCommand, mqttConnectionFail);
  // console.log(mqttPayload);
};

export const openMqttConnection = (dispatch, onConnect = () => { }, onFail = () => { }, username) => {
  const mqttConnectionFail = (obj) => {
    // const message = "Mqtt Connection Failed";
    // console.log(message);
    onFail(obj);
    // dispatch(deviceActions.stopLoading(message?.deviceId))
  };
  return mqttConnect(onConnect, mqttConnectionFail, dispatch, username);
};

export const closeMqttConnection = (cb = () => { }) => {
  mqttDisconnect(cb);
};

const mqttMessageRecieved = (message, topic, dispatch, key,) => {
  //can accept callback only for operation that is currently going on and will end after a definite time(broadcast,discover)
  //if we try to accept callback (onReceive) for topics which we are listening on for continous monitoring 
  //the onReceive will be overwritten during other operations (like broadcast) where we send onReceive
  //the latest onReceive will be used for all monitoring actions moving forward
  const topicLevels = topic?.split("/");
  const time = new Date();
  const requestFound = dispatch(mqttActions.findMqttRequest(message?.sid));
  console.log("message received", message, topic, time, requestFound);

  if (requestFound && message?.uid === JSON?.parse(getLocalItem("user"))?.userId) {
    //case for all commissioning opts
    console.log("message processed sid found", message, topic, time);
    if (message?.cmd === "startReceiverPairing" || (message?.cmd === "sensorCommission" && message?.cnt !== mqttMessageSource?.webapp)) {
      const devId = message?.receiverId || message?.deviceId;
      callbacks["startPairing" + devId] && callbacks["startPairing" + devId](message);
    }
    else if (message?.cmd === "stopReceiverPairing") {
      const devId = message?.receiverId || message?.deviceId;
      callbacks["stopPairing" + devId] && callbacks["stopPairing" + devId](message);
    }
    else if (message?.cmd === "deviceIdentify") {
      callbacks["deviceIdentify" + message?.deviceId] && callbacks["deviceIdentify" + message?.deviceId](message);
      setTimeout(() => {
        delete callbacks["deviceIdentify" + message?.deviceId];
      }, 1000)
    }
    else if (message?.hasOwnProperty("lst") && !message?.hasOwnProperty("grpId") && message?.cmd !== "daliBroadcast" && topicLevels[2] === "status") {
      callbacks["deviceLstControl" + message?.deviceId] && callbacks["deviceLstControl" + message?.deviceId](message);
      setTimeout(() => {
        delete callbacks["deviceLstControl" + message?.deviceId];
      }, 1000)
    }
    else if (message?.hasOwnProperty("klv") && !message?.hasOwnProperty("grpId")) {
      callbacks["deviceTempControl" + message?.deviceId] && callbacks["deviceTempControl" + message?.deviceId](message);
      setTimeout(() => {
        delete callbacks["deviceTempControl" + message?.deviceId];
      }, 1000)
    }
    else {
      callbacks?.onRecieve(message);
      console.log("message processed with", message, topic, time, callbacks);
    }
  }
  else if ((message?.hasOwnProperty("lst") || message?.hasOwnProperty("klv")) && topicLevels[2] === "status" && !message?.hasOwnProperty("grpId")) {
    updateLightControl(message, dispatch);
    console.log("message processed sid not found", message, topic, time);
  }
  else if (topicLevels[2] === "status" && message?.hasOwnProperty("grpId")) {
    updateGroupControl(message, dispatch);
    console.log("message processed sid not found", message, topic, time);
  }
  else if ((message?.status === "busy" || message?.status === "available") && topicLevels[2] === "status") {
    const deviceId = topicLevels[1];
    message.deviceId = deviceId;
    console.log("message processed  sid not found", message, topic, time);
    updateControllerStatus(message, dispatch);
  }
  else if (topicLevels[2] === "connectionstatus") {
    console.log("message processed  sid not found", message, topic, time);
    updateConnnectivityStatus(message, dispatch);
  }
  else if (message?.cmd === "groupSync") {
    console.log("message processed  sid not found", message, topic, time);
    updateGroupStatus(message, dispatch);
  }
  else if (message?.cmd === "sceneSync") {
    console.log("message processed  sid not found", message, topic, time);
    updateSceneStatus(message, dispatch);
  }
  else if (message?.cmd === "deleteGroup") {
    console.log("message processed  sid not found", message, topic, time);
    updateGroupDeleteStatus(message, dispatch);
  }
  else if (message?.cmd === "sceneDelete") {
    console.log("message processed  sid not found", message, topic, time);
    updateSceneDeleteStatus(message, dispatch);
  }
  else if (message?.cmd === "deleteArea") {
    console.log("message processed  sid not found", message, topic, time);
    updateAreaDeleteStatus(message, dispatch);
  }
  else if (message?.cmd === "firmwareUpgrade") {
    console.log("message processed  sid not found", message, topic, time);
    updateFwStatus(message, dispatch);
  }
  // onRecieve(message)
};

export const subscribe = (topics = "", dispatch, mqttClient, key = "", retain = false) => {
  // const messageRecieved = (message, topic) => {
  //   mqttMessageRecieved(message, topic, dispatch, key);
  // };
  // console.log("trying to subcribe",topics)
  const subscribedTopics = [];
  if (_.isArray(topics)) {
    for (const t in topics) {
      if (!dispatch(mqttActions?.findMqttTopic(topics[t]))) {
        mqttSubscribe(topics[t], (message, topic) => {
          mqttMessageRecieved(message, topic, dispatch, key);
        }, mqttClient);
        subscribedTopics?.push(topics[t])
      }
    }
    !_.isEmpty(subscribedTopics) && dispatch(mqttActions?.addMqttTopic(subscribedTopics, retain))
  }
  else if (topics && !dispatch(mqttActions?.findMqttTopic(topics))) {
    mqttSubscribe(topics, (message, topic) => {
      mqttMessageRecieved(message, topic, dispatch, key);
    }, mqttClient);
    dispatch(mqttActions?.addMqttTopic(topics, retain))
  }
}

export const unsubscribe = (topics = "", mqttClient, dispatch, callback = () => { }) => {
  const unsubscribedTopics = [];
  if (_.isArray(topics)) {
    for (const t in topics) {
      mqttUnsubscribe(topics[t], mqttClient, () => {
        callback();
      });
      unsubscribedTopics?.push(topics[t]);
    }
    dispatch(mqttActions?.deleteMqttTopic(unsubscribedTopics))
  }
  else {
    mqttUnsubscribe(topics, mqttClient, () => {
      callback();
      dispatch(mqttActions?.deleteMqttTopic(topics))
    });
  }
}

export const unsubscribeAll = (mqttClient, dispatch, callback = () => { }) => {
  const subscribedTopics = dispatch(mqttActions?.getMqttTopics())?.map(t => t?.topic)
  unsubscribe(subscribedTopics, mqttClient, dispatch, callback)
}

export const deleteMqttCallback = (callbackId = "") => {
  if (callbacks?.hasOwnProperty(callbackId)) {
    delete callbacks[callbackId];
  }
}

