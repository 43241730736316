export const areaTypes={
    GET_AREAS_REQUEST: 'GET_AREAS_REQUEST',
    GET_AREAS_SUCCESS: 'GET_AREAS_SUCCESS',
    GET_AREAS_FAILURE: 'GET_AREAS_FAILURE',

    GET_AREA_REQUEST: 'GET_AREA_REQUEST',
    GET_AREA_SUCCESS: 'GET_AREA_SUCCESS',
    GET_AREA_FAILURE: 'GET_AREA_FAILURE',

    ADD_REQUEST: 'AREA_ADD_REQUEST',
    ADD_SUCCESS: 'AREA_ADD_SUCCESS',
    ADD_FAILURE: 'AREA_ADD_FAILURE',

    UPDATE_REQUEST: 'AREA_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'AREA_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'AREA_UPDATE_FAILURE',

    DELETE_REQUEST: 'AREA_DELETE_REQUEST',
    DELETE_SUCCESS: 'AREA_DELETE_SUCCESS',
    DELETE_FAILURE: 'AREA_DELETE_FAILURE',

    GET_AREA_DEVICES_DETAILS_REQUEST : 'GET_AREA_DEVICES_DETAILS_REQUEST',
    GET_AREA_DEVICES_DETAILS_SUCCESS : 'GET_AREA_DEVICES_DETAILS_SUCCESS',
    GET_AREA_DEVICES_DETAILS_FAILURE : 'GET_AREA_DEVICES_DETAILS_FAILURE',

    AREA_SYNC_REQUEST: 'AREA_SYNC_REQUEST',
    AREA_SYNC_SUCCESS: 'AREA_SYNC_SUCCESS',
    AREA_SYNC_FAILURE: 'AREA_SYNC_FAILURE',
    UPDATE_DELETE_AREA_STATUS:'UPDATE_DELETE_AREA_STATUS'
}