import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    DataNotFound: {
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"center",
        backgroundColor: "#0b522b0a" ,
        // sx={{ padding: "30px"}}
        padding: "15px",
        minHeight: "400px"
    },
    Label: {
        display:"flex",
        justifyContent:"center",
    },
    Content: {
        display:"flex",
        justifyContent:"center"
    },
    Buttons: {
        display:"flex",
        justifyContent:"center"
    }
});

