/***
 *
 * Controller class for user.
 * @file ActivityLog.js
 * @description ActivityLog component
 * @author Ayesha Kumar
 * @since 25 June 2024
 * 
 */

import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './ActivityLog.style.js';
import './ActivityLog.scss';
import { Avatar, Box, Grid } from '@mui/material';
import CustomAccordion from '../CustomAccordion/index.js';
import { ReactComponent as TicketFilters } from '../../assets/icons/ticket-filter.svg';
import CustomTypography from '../CustomTypography/index.js';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@mui/lab';
import { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import ImageWrapper from '../ImageWrapper/index.js';
import _ from 'lodash';
import FormWrapper from '../FormWrapper/index.js';
import DeviceIcon from "../../assets/icons/bulb.svg";
import GroupIcon from '../../assets/icons/group.svg';
import SceneIcon from '../../assets/icons/scene.svg';
import RotateIcon from '../../assets/icons/systemreset.svg'
import RuleIcon from '../../assets/icons/rules.svg'
import AreaIcon from '../../assets/icons/area.svg'
import FactoryResetIcon from '../../assets/icons/factoryreset.svg'
import FotaIcon from '../../assets/icons/download-2.svg'
import ConfigureIcon from '../../assets/icons/configure.svg'
import ControllerIcon from '../../assets/icons/controller.svg'
import { useDispatch, useSelector } from 'react-redux';
import { deviceActions, userActions } from '../../redux/actions/index.js';
import StyledSpinner from '../StyledSpinner/index.js';
import { TableHelper } from '../TableHelper/index.js';
import { formatedDate } from '../../helpers/date.helper.js';
import { stringAvatar } from '../../helpers/users.helper.js';
import { Link } from 'react-router-dom';
import MoveIcon from '../../assets/icons/move.svg'
import { ACTIVITY_LOG_TYPES, ACTIVITY_LOGS_FILTER_FORM_OPTIONS, DEVICE_LOGS_FILTER_FORM_CONFIG } from '../../constants/device.constants.js';
import DataNotFound from '../../pages/DataNotFound/index.js';
import { activityLogPageSize } from '../../config/deviceConfig.js';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getMessageStr } from '../../helpers/message.helper.js';
import InfoIcon from '../../assets/icons/info.svg'
import { useQuery } from '../../hooks/useQuery.js';
import { deviceTypes } from '../../redux/types/device.type.js';


//These titles list should include the key name in each object which has further keys(information) like intensity, maxcool, etc;
// const titles=['status', 'config', 'control', 'connectivity', 'space', 'device', 'configuration'];

//activityLogs will have the object having all the information including the activities object and onFilter function will have the appropriate dispatch function and will accept querystring


const ActivityLog = ({
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const query = useQuery();
  const deviceId = query.get("id") || "";


  const [logs, setLogs] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [pg, setPg] = useState(1);
  const [filters, setFilters] = useState(null);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [formConfig, setFormConfig] = useState(DEVICE_LOGS_FILTER_FORM_CONFIG({ startDate: getMessageStr("device-activityLog-startDateRequired") }));

  const [filterFrm, setFilterFrm] = useState({
    startDate: new Date(),
    endDate: '',
    userId: "",
    type: ""
  });

  const groups = useSelector(state => state?.groups?.list);
  const spaces = useSelector(state => state?.spaces?.list);
  const scenes = useSelector(state => state?.scenes?.sceneList);
  const areas = useSelector(state => state?.areas?.list)
  const users = useSelector((state) => state?.users?.list);
  const activityLogs = useSelector(state => state?.devices?.deviceLogs);
  const portal = useSelector(state => state?.app?.portal);



  // const deviceDetails=useSelector(state=>state?.devices?.deviceDetails)

  const getParams = () => {
    let queryParams = {};
    if (filters?.startDate !== null && filters.startDate !== "") {
      queryParams.startDate = Math?.floor((new Date(filters?.startDate)?.getTime()) / 1000);
    }
    if (filters.endDate !== "") {
      queryParams.endDate = Math?.floor((new Date(filters?.endDate)?.getTime()) / 1000);
    }
    if (!_.isEmpty(filters?.type)) {
      queryParams.types = filters?.type?.map(t => ACTIVITY_LOG_TYPES[t])?.join(",");
    }
    if (filters?.userId !== '') {
      queryParams.uid = filters?.userId;
    }
    return queryParams
  }

  const clearFilter = () => {
    setFiltersApplied(false);
    dispatch({
      type: deviceTypes?.CLEAR_DEVICE_LOGS,
      payload: {},
    });
    setFilterFrm({
      startDate: new Date(),
      endDate: '',
      userId: "",
      type: ""
    });
    const scrollableComponent = document?.getElementsByClassName('infinite-scroll-component')[0];
    if (scrollableComponent) {
      scrollableComponent.scrollTop = 0;
    }
    setTimeout(() => {
      dispatch(deviceActions.getDeviceActivityLogs(deviceId, { startDate: Math?.floor((new Date()?.getTime()) / 1000) }, (logs, error) => {
        if (!error) {
          checkEndOfLogs(logs);
          setPg(1);
        }
      })); // To get activity logs for the device
    }, 0)
  }


  const actionButtons = [{
    heading: null,
    columns: [
      {
        heading: null,
        buttons: [
          {
            id: "cancel",
            type: "button",
            size: 'large',
            variant: 'outlined',
            display: true,
            checkValidation: false,
            label: "Cancel",
            onClick: () => {
              clearFilter();
            }
          },
          {
            id: "apply",
            type: "button",
            size: 'large',
            color: 'primary',
            checkValidation: true,
            display: true,
            label: 'Apply',
            onClick: () => {
              dispatch({
                type: deviceTypes?.CLEAR_DEVICE_LOGS,
                payload: {},
              });
              let queryParams = {}
              const startDate = new Date(filters?.startDate);
              if (_.isEmpty(filters?.type) && filters?.userId === '' && filters?.endDate === "" && startDate?.toDateString() === (new Date())?.toDateString()) {
                setFiltersApplied(false);
                queryParams = { startDate: Math?.floor((new Date(filters?.startDate)?.getTime()) / 1000) }
              }
              else {
                queryParams = getParams();
                setFiltersApplied(true);
              }
              const scrollableComponent = document?.getElementsByClassName('infinite-scroll-component')[0]
              if (scrollableComponent) {
                scrollableComponent.scrollTop = 0;
              }
              setTimeout(() => {
                dispatch(deviceActions.getDeviceActivityLogs(deviceId, { ...queryParams }, (logs, error) => {
                  if (!error) {
                    checkEndOfLogs(logs);
                    setPg(1);
                  }
                })); // To get activity logs for the device
              }, 0)
            }
          }
        ],
        columnSize: 12,
      },
    ],
  },
  ]

  const checkEndOfLogs = (logs) => {
    if (logs?.resultCount < activityLogPageSize) {
      setHasMore(false);
    }
    else {
      setHasMore(true)
    }
  }

  const getEvent = (event) => {
    const operations = {
      firmwareUpgrade: 'Firmware Upgrade',
      pirSensorMapSync: 'PIR Sensor Map',
      alsSensorMapSync: 'ALS Sensor Map',
      lightConfigSync: 'Light config change',
      sensorConfigSync: 'Sensor config change',
      switchConfigSync: 'Switch config change',
      groupSync: "Group sync",
      sceneSync: "Scene sync",
      "controllerDiscover": 'Controller Discover',
      "areaSync": "Area sync",
      "daliCommission": "Device commission",
      "sensorCommission": 'Sensor commission',
      "sceneDelete": 'Delete scene',
      "deleteGroup": 'Delete group',
      "deleteArea": "Delete area",
      "daliBroadcast": 'Broadcast',
      "addController": "Add controller",
      "deviceMove": 'Move device',
      "logUpload": "Upload log",
      "deletePirSensorMapSync": "Delete PIR Sensor Map",
      "deleteAlsSensorMapSync": "Delete ALS Sensor Map",
      "factoryReset": "Factory Reset",
      "systemReset": "System reset"
    }
    const getCommonActivityInfo = (event) => {
      return {
        message: <>
          {event?.activity?.hasOwnProperty('cmd') && `Command : ${event?.activity?.cmd} | `}
          {Object?.keys(event?.activity)?.filter(k => k !== "ts" && k !== "cmd" && k !== "status" && event?.activity[k] !== null)?.map(k => `${k} : ${event?.activity[k]}`)?.join(" | ")}
        </>,
        icon: { src: DeviceIcon, backgroundColor: event?.activity?.status?.toLowerCase()?.includes("fail") ? '#D90707' : '#0F8D48' }
      }
    }

    const getStatus = (event) => {
      const status = {
        "command_accepted": "Command accepted",
        "download_started": "Download started",
        "download_ended": "Download ended",
        "upgrade_started": "Upgrade started",
        "upgrade_ended": "Upgrade ended",
        "upgrade_successfully": "Upgraded successfully"

      }
      return status[event?.activity?.status] || ""
    }

    const getActivityTemplate = (event, message = "", showStatus = true) => {
      const user = users?.find(u => u?.userId === event?.event?.uid);

      return <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <CustomTypography
          fontWeight={400} size={13} lineHeight={14} >
          {message}
          {
            event?.activity?.status && showStatus &&
            ` | Status : ${getStatus(event) ? getStatus(event) : event?.activity?.status?.includes("success") ? "Success" : event?.activity?.status?.includes("accepted") ? "Command accepted" : "Failure"}`
          }
        </CustomTypography>
        {
          event?.event?.hasOwnProperty('uid') && event?.type !== "connectionStatus" && <CustomTypography className="triggeredBy"
            fontWeight={400} color={'#00000099'} size={12} lineHeight={14} >
            Triggered by :
            <Link to={`/user-management/users?op=view&id=${user?.userId}`}>
              <Avatar
                style={{
                  display: "inline-flex",
                  margin: "0px 8px",
                  fontSize: "0.8rem",
                  width: 20,
                  height: 20,
                }}
                {...stringAvatar(`${user?.name}`)}
              />
              {user?.name || event?.event?.uid}
            </Link>
          </CustomTypography>}
      </Box>
    }

    const eventInfo = {
      space: () => {
        const activites = {
          deviceMove: {
            message: <>
              {event?.topic === "comstatus" ? `Device moved to space ` : `Initiated device move to space `}
              <b>{spaces?.find(s => s?.spaceId === event?.metadata?.spaceId)?.name}</b>.</>,
            icon: { src: MoveIcon }
          }
        }
        return activites[event?.activity?.cmd]
      },
      group: () => {
        const name = groups?.find(g => g?.groupId === event?.activity?.grpId)?.name || event?.activity?.grpId;
        const activites = {
          groupSync: <>
            {event?.topic === "comstatus" ? `Group ` : "Initiated group "}<b>sync</b> {` for `}
            <Link className="link" to={`/spaces/groups?id=${event?.activity?.grpId}`}>
              {name}
            </Link>
            {` group.`}
          </>,
          deleteGroup: <>
            {event?.topic === "comstatus" ? `${name} group deleted.` : `Initiated deletion for ${name} group.`}
          </>
        }
        return { message: activites[event?.activity?.cmd], icon: { src: GroupIcon } }
      },
      scene: () => {
        const name = scenes?.find(s => s?.sceneId === event?.activity?.sceneId)?.name || event?.activity?.sceneId;
        const activites = {
          sceneSync: <>
            {event?.topic === "comstatus" ? `Scene ` : "Initiated scene "}<b>sync</b> {` for `}
            <Link className="link" to={`/spaces/scenes?id=${event?.activity?.sceneId}`}>
              {name}
            </Link>
            {` scene.`}
          </>,
          sceneDelete: <>
            {event?.topic === "comstatus" ? `${name} scene deleted.` : `Initiated deletion for ${name} scene.`}
          </>
        }
        return { message: activites[event?.activity?.cmd], icon: { src: SceneIcon } }
      },
      control: () => {
        const activities = {
          groupControl: {
            message: <>

              <Link className="link" to={`/spaces/groups?id=${event?.activity?.grpId}`}>
                {groups?.find(g => g?.groupId === event?.activity?.grpId)?.name || event?.activity?.grpId}
              </Link>
              {" group"}
              {
                event?.activity?.hasOwnProperty("lst") ? <>{" intensity change to "}<b>{event?.activity?.lst}%</b></> : <>{` temperature change to `}<b>{event?.activity?.klv}K</b></>
              }
              {" initiated."}
            </>,
            icon: { src: GroupIcon }
          },
          sceneControl: {
            message: <>
              {"Initiated apply operation for "}
              <Link className="link" to={`/spaces/scenes?id=${event?.activity?.sceneId}`}>
                {scenes?.find(s => s?.sceneId === event?.activity?.sceneId)?.name || event?.activity?.sceneId}
              </Link>
              {" scene"}</>,
            icon: { src: SceneIcon }
          },
          lightControl: {
            message: <>
              {event?.activity?.lst !== null && event?.activity?.hasOwnProperty('lst') && <>
                {`Initiated intensity changed to `}
                <b>{event?.activity?.lst}%</b>
              </>}
              {event?.activity?.klv !== null && event?.activity?.hasOwnProperty('klv') && <>
                {event?.activity?.lst !== null && event?.activity?.hasOwnProperty('lst') && " | "}
                {`Initated temperature changed to `}
                <b>{event?.activity?.klv}K</b>
              </>}
            </>,
            icon: {
              src: DeviceIcon,
              backgroundColor: event?.activity?.lst !== null && event?.activity?.hasOwnProperty('lst') ? event?.activity?.lst > 0 ? "#0F8D48" : "rgba(0, 0, 0, 0.4)" : "#0F8D48",
              borderColor: event?.activity?.lst !== null && event?.activity?.hasOwnProperty('lst') ? event?.activity?.lst > 0 ? "#0F8D48" : "rgba(0, 0, 0, 0.01)" : "#0F8D48",
            }
          }
        }
        const action = event?.activity?.hasOwnProperty("grpId") ? "groupControl" : event?.activity?.hasOwnProperty("sceneId") ? "sceneControl" : event?.metadata?.category?.toLowerCase()?.includes("lightdriver") ? "lightControl" : ""
        return activities[action]
      },
      status: () => {
        const activities = {
          groupControl: {
            message: <>
              <Link className="link" to={`/spaces/groups?id=${event?.activity?.grpId}`}>
                {groups?.find(g => g?.groupId === event?.activity?.grpId)?.name || event?.activity?.grpId}
              </Link>
              {" group"}
              {
                event?.activity?.hasOwnProperty("lst") ? <>{" intensity changed to "}<b>{event?.activity?.lst}%</b></> : <>{` temperature changed to `}<b>{event?.activity?.klv}K</b></>
              }.
            </>,
            icon: { src: GroupIcon }
          },
          sceneControl: {
            message: <>
              <Link className="link" to={`/spaces/scenes?id=${event?.activity?.sceneId}`}>
                {scenes?.find(s => s?.sceneId === event?.activity?.sceneId)?.name || event?.activity?.sceneId}
              </Link>
              {" scene "}
              <b>applied</b>
            </>,
            icon: { src: SceneIcon }
          },
          daliBroadcast: {
            message: <>
              {event?.topic === "comcontrol" ? `Initiated broadcast ` : "Broadcast "}
              {`to turn `}<b>{event?.activity?.lst === 0 ? 'off' : 'on'}</b> {`all lights. `}
            </>,
            icon: { src: DeviceIcon }
          },
          lightControl: {
            message: <>
              {event?.activity?.intensity !== null && event?.activity?.hasOwnProperty('intensity') && <>
                {` Intensity changed to `}
                <b>{event?.activity?.intensity}%</b>
              </>}
              {event?.activity?.kelvin !== null && event?.activity?.hasOwnProperty('kelvin') && <>
                {event?.activity?.intensity !== null && event?.activity?.hasOwnProperty('intensity') && " | "}
                {`Temperature changed to `}
                <b>{event?.activity?.kelvin}K</b>
              </>}
            </>,
            icon: {
              src: DeviceIcon,
              backgroundColor: event?.activity?.intensity !== null && event?.activity?.hasOwnProperty('intensity') ? event?.activity?.intensity > 0 ? "#0F8D48" : "rgba(0, 0, 0, 0.4)" : "#0F8D48",
              borderColor: event?.activity?.intensity !== null && event?.activity?.hasOwnProperty('intensity') ? event?.activity?.intensity > 0 ? "#0F8D48" : "rgba(0, 0, 0, 0.01)" : "#0F8D48",
            }
          }
        }
        const action = event?.activity?.hasOwnProperty("grpId") ? "groupControl" : event?.activity?.hasOwnProperty("sceneId") ? "sceneControl" : (event?.metadata?.category?.toLowerCase()?.includes("lightdriver") ? "lightControl" : event?.activity?.cmd)
        return activities[action]
      },
      unknown: () => {
        const activities = {
          rotateBlekeys: {
            message: <>
              {event?.topic === "comcontrol" ? 'Initiated BLE key update to version ' : "BLE key updated to version"}
              <b>{event?.activity?.bleKeyVersion}</b>
              {event?.activity?.hasOwnProperty('deviceIds') && ` for ${event?.activity?.deviceIds?.join(", ")}`}
            </>,
            icon: { src: RotateIcon }
          },
          sensorDiscover: {
            message: <>{event?.topic === "comcontrol" ? `Initiated sensor discovery.` : 'Sensor discovery.'}
            </>,
            icon: { src: ControllerIcon }
          },
          daliDiscover: {
            message: <>{event?.topic === "comcontrol" ? `Initiated device discovery.` : 'Device discovery.'}
            </>,
            icon: { src: ControllerIcon }
          },
          systemReset: {
            message: <>{event?.topic === "comcontrol" ? "Initiated device system reset." : `Device system reset.`}</>,
            icon: { src: RotateIcon }
          },
          factoryReset: {
            message: <>{event?.topic === "comcontrol" ? "Initiated device factory reset." : `Device factory reset.`}</>,
            icon: { src: FactoryResetIcon }
          },
        }
        return activities[event?.activity?.cmd]
      },
      bleKeyUpgrade: () => {
        const activities = {
          rotateBlekeys: {
            message: <>
              {event?.topic === "comcontrol" ? 'Initiated BLE key update to version ' : "BLE key updated to version"}
              <b>{event?.activity?.bleKeyVersion}</b>
              {event?.activity?.hasOwnProperty('deviceIds') && ` for ${event?.activity?.deviceIds?.join(", ")}`}
            </>,
            icon: { src: RotateIcon }
          }
        }
        return activities[event?.activity?.cmd]
      },
      connectionStatus: () => {
        const onlineStatus = event?.activity?.onlineStatus ? "online" : "offline";

        return {
          message: <Box sx={{ display: "flex", alignItems: "center" }} gap={1}>
            {`Connection status :`}
            <TableHelper.TableStatus type={onlineStatus} label={_.capitalize(onlineStatus)} />
            {/* {`| Next update : ${formatedDate({ date: event?.activity?.nextUpdate, format: "MMM dd, yyyy 'AT' hh:mm a" })} `} */}
          </Box>, icon: {
            src: DeviceIcon,
            backgroundColor: onlineStatus === "online" ? "#0F8D48" : "rgba(0, 0, 0, 0.4)",
            borderColor: onlineStatus === "online" ? "#0F8D484A" : "rgba(0, 0, 0, 0.01)"
          }
        }
      },
      commission: () => {
        const activities = {
          switchConfigSync: {
            message: <>{event?.topic === "comcontrol" ? `Initiated change in configuration for ` : 'Configuration changed for '}
              <Link className='link' to={`/spaces/devices?pt=device&id=${event?.activity?.switch?.deviceId}&category=Switch`}>
                {event?.activity?.switch?.deviceId}
              </Link>
              {" switch"}
            </>,
            icon: { src: ConfigureIcon }
          },
          sensorConfigSync: {
            message: <>{event?.topic === "comcontrol" ? `Initiated change in configuration for ` : 'Configuration changed for '}
              <Link className='link' to={`/spaces/devices?pt=device&id=${event?.activity?.sensor?.deviceId}&category=Sensor`}>
                {event?.activity?.sensor?.deviceId}
              </Link>
              {" sensor"}
            </>,
            icon: { src: ConfigureIcon }
          },
          lightConfigSync: {
            message: <>{event?.topic === "comcontrol" ? `Initiated change in configuration for ` : 'Configuration changed for '}
              {event?.activity?.hasOwnProperty('lightIds') && event?.activity?.lightIds?.map((l, i) => {
                return <>
                  {i !== 0 && ", "}
                  <Link className='link' to={`/spaces/devices?pt=device&id=${l}&category=`}>
                    {l}
                  </Link>
                  {" light"}
                </>
              })}


            </>,
            icon: { src: ConfigureIcon }
          },
          addController: {
            message: <>{event?.topic === "comcontrol" ? `Initiated add operation for ` : 'Added '}
              <Link className='link' to={`/spaces/devices?pt=controller&id=${event?.metadata?.deviceId}&category=${event?.activity?.category}`}>
                {event?.metadata?.deviceId}
              </Link>
              {" controller"}
            </>,
            icon: { src: ControllerIcon }
          },
          controllerDiscover: {
            message: <>{event?.topic === "comcontrol" ? `Initiated controller discovery.` : 'Controller discovery.'}
            </>,
            icon: { src: ControllerIcon }
          },
          sensorDiscover: {
            message: <>{event?.topic === "comcontrol" ? `Initiated sensor discovery.` : 'Sensor discovery.'}
            </>,
            icon: { src: ControllerIcon }
          },
          daliDiscover: {
            message: <>{event?.topic === "comcontrol" ? `Initiated device discovery.` : 'Device discovery.'}
            </>,
            icon: { src: ControllerIcon }
          },
          daliCommission: {
            message: <>{event?.topic === "comcontrol" ? `Initiated device commissioning.` : 'Device Commissioning.'}
            </>,
            icon: { src: ControllerIcon }
          },
          sensorCommission: {
            message: <>{event?.topic === "comcontrol" ? `Initiated sensor commissioning.` : 'Sensor commissioning.'}
            </>,
            icon: { src: ControllerIcon }
          },
        }
        return activities[event?.activity?.cmd]
      },

      firmwareUpgrade: () => {
        const activities = {
          firmwareUpgrade: {
            message: <>
              {`${event?.topic === "comcontrol" ? "Initiated firmware" : "Firmware"} update to version `}
              <b>{event?.activity?.fwVersion}</b>
              {` for ${event?.activity?.deviceIds?.join(", ")}.`}
            </>,
            icon: { src: FotaIcon }
          }
        }
        return activities["firmwareUpgrade"]
      },
      reset: () => {
        const activities = {
          systemReset: {
            message: <>{event?.topic === "comcontrol" ? "Initiated device system reset." : `Device system reset.`}</>,
            icon: { src: RotateIcon }
          },
          factoryReset: {
            message: <>{event?.topic === "comcontrol" ? "Initiated device factory reset." : `Device factory reset.`}</>,
            icon: { src: FactoryResetIcon }
          },
        }
        return activities[event?.activity?.cmd]
      },
      rule: () => {
        const activities = {
          pirSensorMapSync: {
            message: <>
              {event?.activity?.sensors?.map(s => {
                return <Box>
                  {event?.topic === "comcontrol" ? 'Initiated map of PIR sensor ' : `PIR sensor mapping of`}
                  <Link className="link" to={`/spaces/devices?pt=device&id=${s?.id}&category=Sensor`}>{s?.id}</Link>
                  {` to `}
                  {s?.deviceIds?.map((d, i) => { return (<>{i !== 0 && `, `}<Link className="link" to={`/spaces/devices?pt=device&id=${d?.id}`}>{d?.id}</Link></>) })}
                </Box>
              })}

            </>,
            icon: { src: RuleIcon, backgroundColor: event?.activity?.hasOwnProperty('status') ? ((event?.activity?.status?.toLowerCase()?.includes("accepted") || event?.activity?.status?.toLowerCase()?.includes("success")) ? '#0F8D48' : '#D90707') : '#0F8D48' }
          },
          deletePirSensorMapSync: {
            message: <>
              {event?.activity?.sensors?.map(s => {
                return <Box>
                  {event?.topic === "comcontrol" ? 'Initiated deletion of map for PIR sensor ' : `Deleted PIR sensor mapping of`}
                  <Link className="link" to={`/spaces/devices?pt=device&id=${s?.id}&category=Sensor`}>{s?.id}</Link>
                </Box>
              })}
            </>,
            icon: { src: RuleIcon, backgroundColor: event?.activity?.hasOwnProperty('status') ? ((event?.activity?.status?.toLowerCase()?.includes("accepted") || event?.activity?.status?.toLowerCase()?.includes("success")) ? '#0F8D48' : '#D90707') : '#0F8D48' }
          },
          alsSensorMapSync: {
            message: <>
              {event?.activity?.sensors?.map(s => {
                return <Box>
                  {event?.topic === "comcontrol" ? 'Initiated map of ALS sensor ' : `ALS sensor mapping of`}
                  <Link className="link" to={`/spaces/devices?pt=device&id=${s?.id}&category=Sensor`}>{s?.id}</Link>
                  {` to `}
                  {s?.deviceIds?.map((d, i) => { return (<>{i !== 0 && `, `}<Link className="link" to={`/spaces/devices?pt=device&id=${d?.id}`}>{d?.id}</Link></>) })}
                </Box>
              })}

            </>,
            icon: { src: RuleIcon, backgroundColor: event?.activity?.hasOwnProperty('status') ? ((event?.activity?.status?.toLowerCase()?.includes("accepted") || event?.activity?.status?.toLowerCase()?.includes("success")) ? '#0F8D48' : '#D90707') : '#0F8D48' }
          },
          deleteAlsSensorMapSync: {
            message: <>
              {event?.activity?.sensors?.map(s => {
                return <Box>
                  {event?.topic === "comcontrol" ? 'Initiated deletion of map for ALS sensor ' : `Deleted ALS sensor mapping of`}
                  <Link className="link" to={`/spaces/devices?pt=device&id=${s?.id}&category=Sensor`}>{s?.id}</Link>
                </Box>
              })}
            </>,
            icon: { src: RuleIcon, backgroundColor: event?.activity?.hasOwnProperty('status') ? ((event?.activity?.status?.toLowerCase()?.includes("accepted") || event?.activity?.status?.toLowerCase()?.includes("success")) ? '#0F8D48' : '#D90707') : '#0F8D48' }
          },
        }
        return activities[event?.activity?.cmd]

      },
      area: () => {
        const name = areas?.find(a => a?.areaId === event?.activity?.areaId)?.name || event?.activity?.areaId;

        const activities = {
          areaSync: {
            message: <>{event?.topic === "comcontrol" ? "Initiated area " : `Area `}
              <b>sync</b>
              {' for '}
              <Link className="link" to={`/spaces/areas?areaId=${event?.activity?.areaId}`}>
                {name}
              </Link>
              {' area'}

            </>,
            icon: { src: AreaIcon }
          },
          deleteArea: {
            message: <>
              {event?.topic === "comstatus" ? `${name} area deleted.` : `Initiated deletion for ${name} area.`}
            </>,
            icon: { src: AreaIcon }
          },

        }
        return activities[event?.activity?.cmd]
      },
      busy: () => {
        return {
          message: <>
            {"Device is "}
            <b>{event?.activity?.status}</b>
            {
              event?.activity?.hasOwnProperty('cmd') &&
              ` | Ongoing operation : ${operations[event?.activity?.cmd] || event?.activity?.cmd}`
            }
          </>,
          icon: { src: DeviceIcon }
        }
      }


    }
    let type = event?.type
    let showStatus = true
    if ((event?.topic === "comstatus" || event?.topic === "status") && (event?.activity?.status === "available" || event?.activity?.status === "busy")) {
      type = "busy"
      showStatus = false
    }
    const { icon, message } = eventInfo?.hasOwnProperty(type) ? eventInfo[type]() ? eventInfo[type]() : getCommonActivityInfo(event) : getCommonActivityInfo(event)
    return { icon, activity: getActivityTemplate(event, message, showStatus) };
  }

  const getNextPage = () => {
    const newPageNumber = pg + 1
    const queryParams = filtersApplied ? getParams() : { startDate: Math?.floor((new Date(filters?.startDate)?.getTime()) / 1000) };
    dispatch(deviceActions.getDeviceActivityLogs(deviceId, { pgIndex: newPageNumber, ...queryParams }, (logs, error) => {
      if (!error) {
        checkEndOfLogs(logs);
        setPg(newPageNumber);
      }
    }))
  }

  useEffect(() => {
    dispatch(deviceActions.getDeviceActivityLogs(deviceId, { startDate: Math?.floor((new Date()?.getTime()) / 1000) }, (logs, error) => {
      if (!error) {
        checkEndOfLogs(logs);
        setPg(1);
      }
    })); // To get activity logs for the device
    dispatch(userActions.getAll(0, portal));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setLogs(activityLogs)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityLogs])


  useEffect(() => {

    if (!_.isEmpty(filters)) {
      const futureDate = new Date(filters?.startDate);
      const maxDate = new Date(futureDate?.setDate(futureDate?.getDate() + 31));
      let column = formConfig[0]?.columns
      const newColumn = column.filter((item) => item.name !== "endDate");
      if (filters && filters?.startDate && filters?.startDate !== "") {
        setFilterFrm({ ...filters, endDate: "" })
        newColumn.splice(1, 0, {
          id: "2",
          type: "DateTimePicker",
          label: "End Date",
          name: "endDate",
          columnSize: 4,
          required: false,
          disableFuture: true,
          minDate: filters?.startDate,
          maxDate: maxDate
        });
      }
      else {
        newColumn.splice(1, 0, {
          id: "2",
          type: "DateTimePicker",
          label: "End Date",
          disabled: true,
          name: "endDate",
          columnSize: 4,
          required: false,
          disableFuture: true
        });
      }
      setFormConfig([{ ...formConfig[0], columns: newColumn }])

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters?.startDate])


  return (
    <Box className={classes?.ActivityLog || ""} data-testid="ActivityLog">
      <CustomAccordion title="Filters" LeftIcon={TicketFilters} bordered contentBg={"#FFFFFF"}>
        <FormWrapper setPayload={setFilters} formFields={formConfig} formButtons={actionButtons} formData={filterFrm} formOptions={ACTIVITY_LOGS_FILTER_FORM_OPTIONS(users)} customsx={{ formbtn: { my: 1 } }} />
      </CustomAccordion>
      <Box gap={1} sx={{ display: "flex", mt: 1 }}>
        <ImageWrapper src={InfoIcon} />
        <CustomTypography
          fontWeight={400} size={10} lineHeight={14}
          content={getMessageStr("device-activityLog-infoMessage")}
        />
      </Box>
      <Grid container item xs={12} sx={{ marginTop: '10px' }} className='activityLogContainer'>

        <Grid item xs={12} >
          <DataNotFound
            show={_.isEmpty(logs?.activities)}
            customsx={{ image: { height: '202px', width: '100%' }, label: {}, content: {}, btns: {} }}
            data={{
              content: "No activities found.",
              action: []
            }}
          >
            <InfiniteScroll
              dataLength={logs?.activities?.length || 0}
              next={getNextPage}
              height={400}
              hasMore={hasMore}
              loader={<Box sx={{ display: "flex", justifyContent: "center" }}><StyledSpinner /></Box>}
              endMessage={<Box sx={{ display: "flex", justifyContent: "center" }}><CustomTypography content={getMessageStr("device-activityLogs-end")} /></Box>}
            >
              <Timeline
                sx={{
                  [`& .${timelineOppositeContentClasses.root}`]: {
                    flex: 0.12,
                  },
                  mb: '5px'
                }}
              >
                {logs?.hasOwnProperty("activities") && !_.isEmpty(logs?.activities) && logs?.activities?.map((item, index) => {
                  // let title = item?.status ? 'status' : (item?.control ? 'control' : (item?.config ? 'config': (item?.connectivity?'connectivity':(item?.space?'space':''))));
                  const activityContent = getEvent(item);
                  return (
                    <TimelineItem key={index}>
                      <TimelineOppositeContent sx={{ p: "8px 4px 0px 0px" }}>
                        <CustomTypography content={`${formatedDate({ date: item?.ts, format: "MMM dd, " })}${formatedDate({ date: item?.ts, format: "HH:mm" })}`} fontWeight={400} size={13} lineHeight={14} />
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot sx={{ backgroundColor: activityContent?.icon?.backgroundColor || '#0F8D48', width: '24px', height: '24px', display: 'inline-flex', justifyContent: 'center' }}>
                          {/* Image Wrapper for the Icon */}
                          <ImageWrapper src={activityContent?.icon?.src} className="activityIcon" />
                        </TimelineDot>
                        <TimelineConnector sx={{ backgroundColor: activityContent?.icon?.backgroundColor || '#0F8D484A', borderStyle: 'solid', borderColor: activityContent?.icon?.borderColor || '#0F8D484A', borderWidth: '1px' }} />
                      </TimelineSeparator>
                      <TimelineContent>
                        {activityContent?.activity}

                      </TimelineContent>
                    </TimelineItem>)
                })}
              </Timeline>
            </InfiniteScroll>
          </DataNotFound>
        </Grid>

        {/* <Grid item container sx={{ marginTop: '10px' }} xs={12} justifyContent={'right'}>
          <StyledButton
            onClick={loadMore}>
            Load More
          </StyledButton>
        </Grid> */}
      </Grid>

    </Box >
  )
};

ActivityLog.propTypes = {};

ActivityLog.defaultProps = {};

export default ActivityLog;
