/***
 *
 * Controller class for user.
 * @file StreamVideo.js
 * @description StreamVideo component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect, useRef, useState } from 'react';
// import PropTypes from 'prop-types';
import './StreamVideo.scss';
import 'video.js/dist/video-js.css';
import videojs from 'video.js';

const getVideoJsOptions = (src, type, fullscreen, controls) => {
  return {
    autoplay: 'muted',
    controls: true,
    liveui: true,
    controlBar: {
      pictureInPictureToggle: false,
      fullscreenToggle: fullscreen,
      // volumePanel: false,
      // playToggle: false,
    },
    userActions: {
      doubleClick: false,
    },
    sources: [{
      src: src,
      type: type
    }],
    ...controls
  };
};

const StreamVideo = ({ src = null, type = "application/x-mpegURL", fullscreen = false, controls = {}, className = "", ...props }) => {

  const videoRef = useRef(null);
  const [player, setPlayer] = useState(null);
  let playerRef = null

  // useEffect(() => {
  //   if (!player) {
  //     const videoElement = videoRef.current;
  //     if (!videoElement || !src) {
  //       return;
  //     }
  //     const newPlayer = videojs(videoElement, getVideoJsOptions(src, type, fullscreen, controls))
  //     setPlayer(newPlayer);
  //     console.log("here set player", player)
  //   }
  //   return () => {
  //     console.log("here unmount", player)
  //     player?.dispose()
  //     let newPlayer = null;
  //     setPlayer(p => {
  //       console.log("here checking player 1", p)
  //       newPlayer = p;
  //       return p
  //     });
  //     if (newPlayer) {
  //       console.log("here stop")
  //       newPlayer?.dispose();
  //       setPlayer(null);
  //     }
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [src])


  useEffect(() => {
    if (!player) {
      const videoElement = videoRef.current;
      if (!videoElement || !src) {
        return;
      }
      const newPlayer = playerRef = videojs(videoElement, getVideoJsOptions(src, type, fullscreen, controls))
      setPlayer(newPlayer);
    }
    return () => {
      if (playerRef) {
        playerRef?.dispose();
        setPlayer(null);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src])



  return (
    <div className="StreamVideo" data-testid="StreamVideo">
      <video
        ref={videoRef}
        controls
        className={`${className} video-js vjs-big-play-centered`}
        {...props}
      >
      </video>
    </div>
  )
};

StreamVideo.propTypes = {};

StreamVideo.defaultProps = {};

export default StreamVideo;
