import _ from "lodash";

console.log("mainurl", window.location);
export const host = window.location.host;
export const hostName = window.location.hostname;
export const webHost = window.location.host?.includes("localhost") ? `${hostName.split(".").length === 1 ? '' : hostName.split(".")[0] + '.'}web.mss-dev.lifestyleindia.net` : host

// export const tenantName = process.env.REACT_APP_WEB_PORTAL_URL.includes(host) ? null : hostName.split(".")[0];
export let tenantName = localStorage.getItem("tenant");
export const setTenantName = (tenant) => {
    tenantName = tenant
}
export const BASE_URL = (localStorage.getItem("appConfig") === null || localStorage.getItem("appConfig") === "null" || (!JSON.parse(localStorage?.getItem("appConfig"))?.webConfig?.tenantFQDNExternal?.hasOwnProperty("app"))) ? process.env.REACT_APP_GATEWAY_URL : JSON.parse(localStorage?.getItem("appConfig"))?.webConfig?.tenantFQDNExternal?.app;
export const IAM_URL = process.env.REACT_APP_IAM_URL || "https://iam.mss-dev.lifestyleindia.net";
// export const TENANT_URL = IAM_URL && localStorage.getItem("tenant") !== "null" ? IAM_URL.replace(/:\/\//i, `://${(localStorage.getItem("tenant") || tenantName)}.`) : "";
export let TENANT_URL = (localStorage.getItem("appConfig") === null || localStorage.getItem("appConfig") === "null") ? "" : JSON.parse(localStorage?.getItem("appConfig"))?.webConfig?.tenantFQDNExternal?.auth;
export const setTenantUrl = (appConfig = {}) => {
    TENANT_URL = _.isEmpty(appConfig) ? (localStorage.getItem("appConfig") === null || localStorage.getItem("appConfig") === "null") ? "" : localStorage?.getItem("appConfig")?.webConfig?.tenantFQDNExternal?.auth : appConfig?.webConfig?.tenantFQDNExternal?.auth;
}
// export const clientId = process.env.REACT_APP_CLIENT_ID;
// export const clientId = localStorage.getItem("clientId");

const MSS_WEB_URL = process.env.REACT_APP_WEB_PORTAL_URL || "https://web.mss-dev.lifestyleindia.net";
export const WEB_PORTAL_URL = (localStorage.getItem("appConfig") === null || localStorage.getItem("appConfig") === "null" || (!JSON.parse(localStorage?.getItem("appConfig"))?.webConfig?.tenantFQDNExternal?.hasOwnProperty("web"))) ? MSS_WEB_URL.replace(/:\/\//i, `://${(localStorage.getItem("tenant") || tenantName)}.`) : JSON.parse(localStorage?.getItem("appConfig"))?.webConfig?.tenantFQDNExternal?.web;
// export const WEB_PORTAL_URL = host; // use for deployments

const API_VERSION = "v1";
export const API_URL = {
    device: BASE_URL + "/api/deviceManagement/" + API_VERSION,
    gateway: BASE_URL + "/api/gatewayManagement/" + API_VERSION,
    space: BASE_URL + "/api/spaceManagement/" + API_VERSION,
    user: TENANT_URL + "/api/identity/" + API_VERSION,
    getIdentityUrl: () => {//to get updated value of tenant_url in api calls before login
        return TENANT_URL + "/api/identity/" + API_VERSION
    },
    permission: BASE_URL + "/api/permissionManagement/" + API_VERSION,
    support: BASE_URL + "/api/appsupport/" + API_VERSION,
    automation: BASE_URL + "/api/appsupport/" + API_VERSION,
    notification: BASE_URL + "/api/notification/" + API_VERSION,
    tenant: BASE_URL + "/api/tenantManagement/" + API_VERSION,
    tmsUser: IAM_URL + "/api/identity/" + API_VERSION,
    camera: "http://13.201.136.61:8080"
};

export let defaultSelectedMenu = {
    top: null,
    left: 1061
}

export const setDefaultSelectedMenu = ({ top = null, left = null }) => {
    defaultSelectedMenu = { top: top, left: left };
}

export let defaultLandingPage = "/"; // "/login";

export const setDefaultLandingPage = (route) => {
    defaultLandingPage = route;
}
export const defaultAuthenticatedUrlArr = ["/", "/tenants", "/tickets", "/reports", "/user-management"];

export let defaultAuthenticatedUrl = "/"; // "/spaces/dashboard";

export const setDefaultAuthenticatedUrl = (route) => {
    defaultAuthenticatedUrl = route;
}

export const disableDevFeatures = {
    itemId: process.env.REACT_APP_ENV === 'development' ? false : true,
    tooltip: process.env.REACT_APP_ENV === 'development' ? false : true,
}
export const NODE_ENV = process.env.REACT_APP_ENV;
export const MFA_ENABLED = process.env.REACT_APP_MFA === "true" ? true : false;

export const notificationTimeout = "10000"
// console.log("IAM URL ---- ", API_URL.user)

export const fetchSensorInterval = process.env.REACT_APP_FETCH_SENSOR_INTERVAL;

//removed as login call is removed
// export const systemTokenUsername = process.env.REACT_APP_OPEN_SYSTEM_TOKEN_USERNAME
// export const systemTokenPassword = process.env.REACT_APP_OPEN_SYSTEM_TOKEN_PASSWORD
export const appConnections = {
    "notifications": process.env.REACT_APP_ENV === 'development' ? true : true,
    "mqtt": process.env.REACT_APP_ENV === 'development' ? true : true,
}

export const defaultTenantName = "psn";
