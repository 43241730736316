/***
 *
 * Controller class for user.
 * @file SpaceTree.js
 * @description SpaceTree component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './SpaceTree.style.js';
import './SpaceTree.scss';
import { Box, Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CustomTypography from '../CustomTypography/index.js';
import StyledSpaceTree from '../StyledSpaceTree/index.js';

const SpaceTree = ({onClick, loading=false}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const spaces = useSelector((state) => state?.spaces?.list);
  const activeSpace = useSelector(state=>state?.spaces?.selectedSpace);  

  //const devices = useSelector(state => state?.devices?.deviceList);
  const [selectedSpace, setSelectedSpace] = useState(activeSpace);
  const [searchQuery, setSearchQuery] = useState('')

  const handleExpand = (event, data) => {
    console.log("tttt Event ---- ", data);
    setSelectedSpace(data);
    //const filteredDevices = devices.filter( d => d?.spaceId===data.spaceId )
    onClick(event,data);

  }

  // useEffect(() => {
  //   //dispatch to dragdrop store
  // }, [itemList])
  
  const handleChange = (value) => {
    // setSearchStr(value);
    setSearchQuery(value);
  }

    return (
        <Box className={classes?.SpaceTree || ""} data-testid="SpaceTree" sx={{width:'100%', maxHeight: '500px', height: '500px'}}>
          <Paper sx={{ width:'100%',maxHeight: '500px', height: '500px', position: 'relative', border: "1px solid #D9D9D9" }}  >
            <Box sx={{ background: '#0F8D480D', padding: '18px 24px 0px' }}>
                <CustomTypography content={`Select Space`} weight={600} size={12} lineHeight={14.63} color="rgba(0, 0, 0, 0.8)" />
            </Box>
            <Box>
              <StyledSpaceTree 
                spaces={spaces} 
                handleExpand={handleExpand} 
                activeSpace={selectedSpace} 
                style={{maxHeight: '330px'}} 
                searchQuery={searchQuery} 
                setSearchQuery={setSearchQuery} 
                handleChange={handleChange}
              />
            </Box>
          </Paper>
        </Box>
    )
};

SpaceTree.propTypes = {};

SpaceTree.defaultProps = {};

export default SpaceTree;
