/***
 *
 * Controller class for user.
 * @file KeyRotation.js
 * @description KeyRotation component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import { Box, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Tooltip, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './keyRotation.style.js';
import PageHeader from '../../components/PageHeader/index.js';
import RotateIcon from '../../assets/icons/systemreset.svg'
import StyledButton from '../../components/StyledButton/index.js';
import CustomLabel from '../../components/CustomLabel/index.js';
import CustomTable from '../../components/CustomTable/index.js';
import DataNotFound from '../DataNotFound/index.js';
import { getMessageStr } from '../../helpers/message.helper.js';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import ChipInput from '../../components/ChipInput/index.js';
import { TableHelper } from '../../components/TableHelper/index.js';
import CustomTypography from '../../components/CustomTypography/index.js';
import { deviceActions } from '../../redux/actions/index.js';
import CustomDropDown from '../../components/CustomDropDown/index.js';
import StyledPopup from '../../components/StyledPopup/index.js';
import { confirmationDialog, getDeviceDetailHeaders } from '../../helpers/device.helper.js';
import ImageWrapper from '../../components/ImageWrapper/index.js';
import CheckCircle from "../../assets/icons/check-circle.svg"
import ViewIcon from '../../assets/icons/view.svg'
import { ReactComponent as TicketFilters } from '../../assets/icons/ticket-filter.svg';
import CustomAccordion from '../../components/CustomAccordion/index.js';
import StyledDashboardCounter from '../../components/StyledDashboardCounter/index.js';
import StyledTabPanel from '../../components/StyledTabPanel/index.js';
import ItemView from '../../components/ItemView/index.js';
import CustomizedTree from '../../components/CustomizedTree/index.js';
import LogIcon from "../../assets/icons/activitylog.svg";
import GatewayIcon from "../../assets/icons/singleantenna.svg";
import GenerateIcon from "../../assets/icons/fix.svg";
import { rotateKeyActions } from '../../redux/actions/rotateKey.actions.js';
import { formatedDate } from '../../helpers/date.helper.js';
import SensorIcon from '../../assets/icons/sensor.svg';
import ReceiverIcon from '../../assets/icons/router.svg'
import WarningIcon from '../../assets/icons/warning.svg'
import { history } from '../../helpers/history.helper.js';
import ControllerSlider from '../../components/DeviceController/ControllerSlider/index.js';
import { useQuery } from '../../hooks/useQuery.js';
import { deviceTypes } from '../../redux/types/device.type.js';


const headerList = {
  bleKeyVersion: {
    label: "key version",
    type: "component",
    component: (row) => {
      if (row?.data?.bleKeyVersion === -1) {
        return <CustomTypography content={"Master key"} />
      }
      else {
        return <CustomTypography content={row?.data?.bleKeyVersion} />
      }
    }
  },
  createdOnDate: {
    label: "created at",
  },
  count: {
    label: "NO of Devices updated",
  }
};
const tabs = [{ id: "generate", label: "generate Ble key", icon: GenerateIcon }, { id: "rotate", label: "Sync Ble key", icon: RotateIcon }, { label: "BLE key Log", id: "log", icon: LogIcon }]
const icons = { VortexGateway: GatewayIcon, Sensor: SensorIcon, Receiver: ReceiverIcon }
const bleKeyStats = (bleKeys, latestBleKey) => {
  return [{ label: "Total Devices", data: bleKeys?.hasOwnProperty("totalDevices") ? bleKeys?.totalDevices : "-", background: ["#EFE8F8", "rgba(239, 232, 248, 0.25)"] },
  { label: "Key Updated", data: latestBleKey?.hasOwnProperty("count") ? latestBleKey?.count : "-", background: ["#E5F2FA", "rgba(229, 242, 250, 0.25)"] },
  { label: "Key Update Needed", data: bleKeys?.hasOwnProperty("totalDevices") && latestBleKey?.hasOwnProperty("count") ? bleKeys?.totalDevices - latestBleKey?.count : "-", background: ["#FBF6DF", "rgba(251, 246, 223, 0.25)"] },
  { label: "Key Update Failed", data: bleKeys?.hasOwnProperty("failedDevices") ? bleKeys?.failedDevices : "-", background: ["#FF8B8B", "rgba(255, 139, 139, 0.25)"] }]
}

const KeyRotation = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const query = useQuery();
  const tabIndex = parseInt(query.get("tab") || 0);
  const device = useSelector(state => state?.devices?.deviceDetails);

  const bleKeys = useSelector(state => state?.rotateKey?.keyList);
  const deviceCategories = useSelector(state => state?.devices?.deviceCategories)
  const deviceList = useSelector(state => state?.devices?.detailedDeviceList);


  const [table, setTable] = useState({ data: [], headers: headerList, searching: { reset: true, columns: ["count", "createdOnDate", "bleKeyVersion", "type"] } });
  const [latestBleKey, setLatestBleKey] = useState(null);
  const [dialog, setDialog] = useState(false);
  const [dialogContents, setDialogContents] = useState({});
  const [selectedTab, setSelectedTab] = useState(tabs[0]?.id);
  const [deviceDetails, setDeviceDetails] = useState({});
  const [deviceTree, setDeviceTree] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState({})


  const getIcon = (device, receiverList = []) => {
    let className = "warning";
    let icon = WarningIcon;
    if (latestBleKey?.hasOwnProperty("bleKeyVersion")) {
      if ((device?.category === "Receiver") && device?.hasOwnProperty("bleKeyVersion") && device?.bleKeyVersion === latestBleKey?.bleKeyVersion) {
        icon = CheckCircle;
        className = "";
      }
      else if (device?.category?.toLowerCase()?.includes("gateway") && receiverList?.find(r => r?.bleKeyVersion !== latestBleKey?.bleKeyVersion)?.length === 0) {
        icon = CheckCircle;
        className = "";
      }
    }
    return { className, icon }
  }

  const constructDeviceTree = (list) => {
    const tree = [];
    list?.forEach(item => {
      if (item?.category === "VortexGateway") {
        const receiverList = list?.filter(l => l?.category === "Receiver" && l?.gwDeviceId === item?.deviceId);
        receiverList?.forEach(r => {
          const sensorList = list?.filter(l => l?.category === "Sensor" && l?.parentDeviceId === r?.deviceId);
          sensorList?.forEach(s => {
            s.leftIcon = { src: SensorIcon }
            // const { className, icon } = getIcon(s);
            // s.rightIcon = {
            // src: icon,
            // class: className,
            //check scenario when there is no latestBleKey
            //   tooltip: { title: getMessageStr(`rotateKey-version-${s?.bleKeyVersion === latestBleKey?.bleKeyVersion ? "latest" : "needsUpdate"}`), enterDelay: 1500 }
            // };
          })
          r.childDevices = sensorList;
          r.leftIcon = { src: ReceiverIcon };
          const { className, icon } = getIcon(r);
          r.rightIcon = {
            class: className,
            src: icon,
            tooltip: { title: getMessageStr(`rotateKey-version-${r?.bleKeyVersion === latestBleKey?.bleKeyVersion ? "latest" : "needsUpdate"}`), enterDelay: 1500 }
          };
        })
        item.childDevices = receiverList;
        item.leftIcon = { src: GatewayIcon };
        const { className, icon } = getIcon(item, receiverList);
        item.rightIcon = {
          src: icon,
          class: className,
          tooltip: { title: getMessageStr(`rotateKey-version-${item?.bleKeyVersion === latestBleKey?.bleKeyVersion ? "latest" : "needsUpdate"}`), enterDelay: 1500 }
        };
        tree?.push(item);
      }
    });
    return tree
  }


  useEffect(() => {
    dispatch(rotateKeyActions?.getBleKeys());
    dispatch(deviceActions?.getDetailedDeviceList({ category: "VortexGateway,Receiver,Sensor" }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return (() => {
      dispatch(
        {
          type: deviceTypes?.CLEAR_DETAILED_DEVICE_LIST,
          payload: {},
        }
      )
    })
  }, [])

  useEffect(() => {
    setTable({ ...table, data: bleKeys?.bleKeyStatus })
    setLatestBleKey(bleKeys?.bleKeyStatus?.find(k => k?.latest === true) || {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bleKeys])

  useEffect(() => {
    setDeviceDetails(device);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device])

  useEffect(() => {
    if (!_.isEmpty(deviceList) && latestBleKey !== null) {
      const dTree = constructDeviceTree(deviceList)
      setDeviceTree(dTree);
      if (!_.isEmpty(dTree[0])) {
        setSelectedDevice(dTree[0]);
        dispatch(deviceActions?.getDevice(dTree[0]?.deviceId, dTree[0]?.category?.toLowerCase()?.includes("gateway") ? "gateway" : "device", false, '', false));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceList, latestBleKey])
  // useEffect(() => {

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [deviceTree[0]])

  useEffect(() => {
    setSelectedTab(tabs[tabIndex]?.id);
    //to ensure first device is always selected
    // if (tabs[tabIndex]?.id === "rotate" && !_.isEmpty(deviceTree)) {
    // setSelectedDevice(deviceTree[0]);
    // dispatch(deviceActions?.getDevice(deviceTree[0]?.deviceId, deviceTree[0]?.category?.toLowerCase()?.includes("gateway") ? "gateway" : "device"));
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabIndex])


  return (
    <Box className={classes?.KeyRotation || ""} data-testid="KeyRotation">
      <PageHeader
        backbtn={false}
        title={"BLE Key Management"}
        icon={RotateIcon}
      >
      </PageHeader>
      <StyledTabPanel
        selectedIndex={tabIndex}
        tabs={tabs}
        onClick={(e, value) => { history?.push(`/settings/keyRotation?tab=${value}`) }}
      >
        {
          selectedTab === "log" ?
            <Box>
              <DataNotFound
                show={table?.data?.length === 0}
                data={{
                  content: getMessageStr("keyLog-list-empty")
                }}
              >
                <CustomTable
                  headerList={table?.headers}
                  cellMatrix={table?.data}
                  searching={table?.searching}
                  pagination={true}
                  enableScrollToTop={false}
                  sorting={{ column: "bleKeyVersion", order: "desc" }}
                // rowConfig={{ highlight: { bgKey: "status", bgValue: "inactive" }, disabled: { key: "status", value: "inactive" } }}
                >
                </CustomTable>
              </DataNotFound>
            </Box>
            :
            (
              selectedTab === "generate" ?
                <Box>
                  <Grid container spacing={1}>
                    {bleKeyStats(bleKeys, latestBleKey)?.map(s => {
                      return <Grid item xs={6} md={3} >
                        <StyledDashboardCounter data={{ label: s?.label, counter: s?.data }} sx={{ background: s?.background, height: "100%" }} counterSx={{ fontSize: "1.5rem !important", marginTop: "8px" }} labelSx={{ fontSize: "0.781rem !important", minHeight: "2rem" }} />
                      </Grid>
                    })}
                  </Grid>
                  <Paper className='borderBox' sx={{ mt: 2 }}>
                    <Box className="header">
                      <CustomTypography content={`Generate BLE key`} weight={600} size={14} lineHeight={14.63} color="rgba(0, 0, 0, 0.8)" />
                    </Box>
                    <Box sx={{ p: 2 }}>
                      <CustomTypography content={`Current BLE key version : ${latestBleKey?.bleKeyVersion ? `Version ${latestBleKey?.bleKeyVersion} (${latestBleKey?.createdOnDate})` : getMessageStr("rotateKey-bleKey-notFound")}`} styles={{ marginBottom: "8px" }} weight={600} size={12} lineHeight={14.63} color="rgba(0, 0, 0, 0.8)" />

                      <CustomTypography content={getMessageStr("keyGenerate-info-message")} weight={600} size={12} lineHeight={14.63} color="rgba(0, 0, 0, 0.8)" />
                      <StyledButton sx={{ mt: 2 }}
                        onClick={(e) => {
                          confirmationDialog("Generate New BLE Key", () => {
                            dispatch(rotateKeyActions?.generateBleKey())
                            setDialog(false)
                          }, `keyGenerate`,
                            setDialog,
                            setDialogContents,
                          )
                        }}>
                        {"Generate BLE Key"}
                      </StyledButton>
                    </Box>
                  </Paper>
                </Box>
                :
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Paper className='borderBox' sx={{ minHeight: "500px" }}>
                      <Box className="header">
                        <CustomTypography content={`Select Device`} weight={600} size={12} lineHeight={14.63} color="rgba(0, 0, 0, 0.8)" />
                      </Box>
                      <Box className="deviceTree">
                        <CustomizedTree
                          listId={'spaceTree'}
                          data={deviceTree}
                          activeSpace={selectedDevice}
                          selectSpaceMenu={false}
                          keys={{ selection: "deviceId", children: "childDevices" }}
                          onClick={(e, val) => {
                            dispatch(deviceActions?.getDevice(val?.deviceId, val?.category?.toLowerCase()?.includes("gateway") ? "gateway" : "device", false, '', false));
                            setSelectedDevice(val);
                          }}
                          // style={style}
                          actButtons={false}
                        />
                      </Box>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Paper className='borderBox' sx={{ p: 2 }}>
                      <PageHeader
                        backbtn={false}
                        title={deviceDetails?.name}
                        icon={icons[deviceDetails?.category]}>

                        {
                          deviceDetails?.category?.toLowerCase()?.includes("receiver") &&
                          <StyledButton disabled={!latestBleKey?.hasOwnProperty("bleKeyVersion")} onClick={(e) => {
                            confirmationDialog("Sync BLE key", () => {
                              setDialog(false);
                              dispatch(rotateKeyActions?.rotateBleKey({ receiverList: [deviceDetails?.deviceId] }))
                            }, "rotateKey", setDialog, setDialogContents, { version: `Version ${latestBleKey?.bleKeyVersion}`, name: deviceDetails?.name })
                          }}>
                            {"Sync BLE key"}
                          </StyledButton>
                        }
                        {deviceDetails &&
                          <StyledButton tooltip='View Details' iconButton sx={{ ml: 1 }} onClick={(e) => {
                            history?.push(`/spaces/devices?pt=${deviceDetails?.category?.toLowerCase()?.includes("gateway") ? "gateway" : "device"}&id=${deviceDetails?.deviceId}&category=${deviceDetails?.category}`)
                          }}>
                            <ImageWrapper width={18} height={18} src={ViewIcon} />
                          </StyledButton>
                        }
                      </PageHeader>
                      <CustomAccordion title="Device Details">
                        <ItemView
                          gridSize={{ md: 6 }}
                          data={deviceDetails}
                          headers={getDeviceDetailHeaders(deviceDetails?.category, {
                            ...deviceDetails, bleStatusIcon: {
                              src: deviceDetails?.bleKeyVersion === latestBleKey?.bleKeyVersion ? CheckCircle : WarningIcon,
                              class: deviceDetails?.bleKeyVersion === latestBleKey?.bleKeyVersion ? "" : "warning",
                              tooltip: getMessageStr(`rotateKey-version-${deviceDetails?.bleKeyVersion === latestBleKey?.bleKeyVersion ? "latest" : "needsUpdate"}`)
                            }
                          }, dispatch, {}, deviceCategories?.find(c => c?.category === deviceDetails?.category)?.label)}
                        />
                      </CustomAccordion>
                    </Paper>
                  </Grid>

                </Grid>
            )

        }
      </StyledTabPanel>
      <StyledPopup
        open={dialog}
        onClose={() => { setDialog(false) }}
        state={dialogContents?.state}
        data={dialogContents?.content}
        component={dialogContents?.component}
        customsx={{
          btns: { display: "flex", justifyContent: "center" },
        }} />
    </Box >
  )
};

KeyRotation.propTypes = {};

KeyRotation.defaultProps = {};

export default KeyRotation;
