import { customSortRules, failure, getMessage, request, success } from "../../helpers";
import { automationService, delay, findNode } from "../../services"
import { automationType } from "../types";
import { appActions } from ".";


const createAutomationRule = (payload, cb = () => { }) => {
    return dispatch => {
        dispatch(request(automationType.CREATE_AUTOMATION));
        automationService.createAutomationRule(payload)
            .then(({ data, status }) => {
                dispatch(success(automationType.CREATE_AUTOMATION_SUCCESS, { payload: data }));
                getMessage(`rule-post-${status}`).then(delay(500)).then((msg) => {
                    dispatch(appActions.apiSuccessMessage(msg));
                    cb();
                });
            },
                ({ error, code }) => {
                    dispatch(failure(automationType.CREATE_AUTOMATION_FAILURE, error.toString()));
                    getMessage(`rule-post-${code}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    });
                }
            );
    };
}

const getAllAutomationRule = (spaceId) => {
    return dispatch => {
        dispatch(request(automationType.GET_AUTOMATION));
        automationService.getAllAutomationRule(spaceId)
            .then(({ data, }) => {
                const sortedData = customSortRules(data?.rules);
                dispatch(success(automationType.GET_AUTOMATION_SUCCESS, { payload: sortedData }));
            },
                ({ error, code }) => {
                    dispatch(failure(automationType.GET_AUTOMATION_FAILURE, error.toString()));
                    getMessage(`rule-get-${code}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    });
                }
            )
    }
}

const getAutomationRuleById = (payload) => {
    return (dispatch, getState) => {
        dispatch(request(automationType.GET_RULE_BY_ID));
        automationService.getAutomationRuleById(payload)
            .then(({ data }) => {
                const spaces = getState().spaces.list;
                const space = findNode(spaces, "Spaces", { key: "spaceId", value: data.spaceId });
                console.log("space found",space,data?.spaceId)
                data["spaceName"] = space?.name || "";
                dispatch(success(automationType.GET_RULE_BY_ID_SUCCESS, { payload: data }));
            },
                ({ error, code }) => {
                    dispatch(failure(automationType.GET_RULE_BY_ID_FAILURE, error.toString()));
                    getMessage(`ruleById-get-${code}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    });
                }
            )
    }
}

const deleteAutomationRuleById = (ruleId, cb = () => { }) => {
    return dispatch => {
        dispatch(request(automationType.DELETE_REQUEST));
        automationService.deleteAutomationRuleById(ruleId)
            .then(({ data, status }) => {
                dispatch(success(automationType.DELETE_SUCCESS, { payload: data, ruleId: ruleId }));
                getMessage(`rule-delete-${status}`).then(delay(500)).then((msg) => {
                    dispatch(appActions.apiSuccessMessage(msg));
                    cb();
                });
            },
                ({ error, code }) => {
                    dispatch(failure(automationType.DELETE_FAILURE, error.toString()));
                    getMessage(`rule-delete-${code}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    });
                }
            );
    };
}

const updateAutomationRule = (ruleId, payload, cb = () => { }) => {
    return dispatch => {
        dispatch(request(automationType.UPDATE_AUTOMATION_REQUEST));
        automationService.updateAutomationRule(ruleId, payload)
            .then(({ data, status }) => {
                dispatch(success(automationType.UPDATE_AUTOMATION_SUCCESS, { payload: data }));
                getMessage(`rule-put-${status}`).then(delay(500)).then((msg) => {
                    dispatch(appActions.apiSuccessMessage(msg));
                    cb();
                });
            },
                ({ error, code }) => {
                    dispatch(failure(automationType.UPDATE_AUTOMATION_FAILURE, error.toString()));
                    getMessage(`rule-put-${code}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    });
                }
            );
    };
}

export const automationActions = {
    createAutomationRule,
    getAllAutomationRule,
    getAutomationRuleById,
    deleteAutomationRuleById,
    updateAutomationRule
}
