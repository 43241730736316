/***
 *
 * Controller class for user.
 * @file CreateScenes.js
 * @description CreateScenes component
 * @author Shubham Singh
 * @since 12 Oct 2021
 * 
 */

import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
// import PropTypes from 'prop-types';
import './CreateScenes.scss';
import PageHeader from '../../components/PageHeader';
import CustomStepper from '../../components/CustomStepper';
import SceneConfirmation from '../SceneConfirmation';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../helpers/history.helper.js';
import { sceneActions } from '../../redux/actions/scene.actions';
import ImageWrapper from '../../components/ImageWrapper';
import StyledSnackBar from "../../components/StyledSnackBar/index.js";
import StyledTextfield from '../../components/StyledTextfield';
// import _ from 'lodash';
import { useQuery } from "../../hooks";
import StyledPopup from '../../components/StyledPopup';
import SceneDragDropWrapper from '../../components/SceneDragDropWrapper';
import { dragAndDropActions } from '../../redux/actions';
import editIcon from '../../assets/icons/edit-2.svg';
import sceneIcon from '../../assets/icons/scene.svg';
import { getMessageStr } from '../../helpers';
import Validator from '../../helpers/validator.helper';
import StyledButton from '../../components/StyledButton';

// const frmConfig = [
//   {
//     section: { heading: null },
//     columns: [
//       {
//         id: "1",
//         type: "text",
//         label: "Scene Name",
//         name: "sceneName",
//         placeholder: "",
//         columnSize: 4,
//         required: true,
//         validation: {
//           req: [true, 'Please Enter Scene Name'],
//           // customRegex: [/[a-zA-Z]/, 'Space Type Name have No Alphabet'], //This regex validates that the space type have a alphabet atleast
//           // minLen: [3, 'Minimum 3 Characters Required'],
//           // maxLen: [30, 'Maximum 30 Characters Allowed'],
//           // alphaNum: [true, 'Invalid Name']
//         },
//         disabledOnEdit: true
//       },
//     ],
//   }
// ];

// function prepareSelectedDeviceDetails(selectedDeviceDetails){
//   return selectedDeviceDetails.map((e)=>{
//     let contents = {...e,name : e.name? e.name : e.deviceId || e.groupId, }
//     return {
//       id : e.deviceId || e.groupId, content: contents
//     }
//   })
// }
const sceneNameValidation = {
  req: [true, getMessageStr('scene-formValidation-sceneNameEmpty')],
  alphaNumChars: ["-", getMessageStr('scene-formValidation-sceneNameInvalid')],
  minLen: [2, getMessageStr('scene-add-sceneNameMinLen')],
  maxLen: [30, getMessageStr('scene-add-sceneNameMaxLen')],
};
const CreateScenes = ({ sceneOperation }) => {
  const query = useQuery();
  const operation = query.get("op") || null;
  const dispatch = useDispatch();
  const steps = [{ label: "ADD DEVICES" }, { label: "CONFIGURE SCENE" }]
  const [data, setData] = useState();
  const [stepCount, setStepCount] = useState(1);
  const readOnly = true;
  const [enableEdit, setEnableEdit] = useState(false);
  const [snackBar, setSnackBar] = useState({ show: false, type: 'error', message: getMessageStr('scene-formValidation-sceneNameEmpty') }); //WGJGBM scene-formValidation-sceneNameEmpty
  const [errorMsg, setErrorMsg] = useState('');
  const scene = useSelector((state) => { return state?.scenes?.scene });
  const [sceneName, setsceneName] = useState(operation === 'edit' ? scene?.name : '');
  //const [configIdList, setConfigIdList] = useState(operation=='edit'? scene.actions.map((e)=>e.deviceId || e.groupId) : []);
  //const [configIdDetails, setConfigIdDetails] = useState(operation=='edit'? prepareSelectedDeviceDetails(scene.actions) : []);
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState({ show: false });
  const [deviceList, setdeviceList] = useState({
    settingsNotSetForIntensity: '',
    settingsNotSetForKelvin: ''
  })
  const [idAlreadyPresentinEdit, setIdAlreadyPresentinEdit] = useState([])
  const dropList = useSelector(state => state?.dragAndDrops?.dropList)
  const [ifMovedToNextStep, setIfMovedToNextStep] = useState(false)
  const [notFoundDevicesInDropList, setNotFoundDevicesInDropList] = useState([]);

  useEffect(() => {
    if (sceneOperation === 'edit') {
      setEnableEdit(true);
    }
  }, [sceneOperation])

  // useEffect(() => { //This useEffect just stores the array of ids of devices or groups which was already present in scene which we are editing. So new additions won't get added to this array-idAlreadyPresentinEdit
  //   let IdAlreadyPresent = [];
  //   configIdDetails.map(item=>{
  //     if(item?.content?.intensity!==undefined || item?.content?.kelvin!==undefined )
  //       IdAlreadyPresent.push(item?.id)
  //   })
  //   setidAlreadyPresentinEdit(IdAlreadyPresent)
  // }, [ configIdDetails ])

  //This useEffect just stores the array of ids of devices or groups which was already present in scene which we are editing. So new additions won't get added to this array-idAlreadyPresentinEdit
  useEffect(() => {
    if (sceneOperation === 'edit') {
      let idsAlreadyPresent = scene?.actions?.map(item => {
        return item?.deviceId || item?.groupId
      })
      setIdAlreadyPresentinEdit(idsAlreadyPresent)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scene])


  const handleChange = (e) => {
    const name = e?.target?.value;

    // if (name.length < 3) {
    //   setErrorMsg(getMessageStr('scene-add-sceneNameMinLen')); //WGJGBM scene-formValidation-sceneNameInvalid
    // }
    // else if (name.length > 30) {
    //   setErrorMsg(getMessageStr('scene-add-sceneNameMaxLen')); //WGJGBM scene-formValidation-sceneNameInvalid
    // }
    // else if (!/^\d*[a-zA-Z][a-zA-Z0-9, #,-]*$/.test(name)) {
    //   setErrorMsg(getMessageStr('scene-formValidation-sceneNameInvalid')); //WGJGBM scene-formValidation-sceneNameInvalid
    // }
    // else {
    //   setErrorMsg('');
    // }
    setErrorMsg(Validator(name, "text", sceneNameValidation))
    setsceneName(e.target.value);
  }
  const closeSnackBar = () => {
    setSnackBar({
      show: false,
      type: 'error',
      message: getMessageStr('scene-formValidation-sceneNameEmpty') //WGJGBM scene-formValidation-sceneNameEmpty
    });
  };
  const handleBackStep = () => {
    if (stepCount === 1) {
      history.goBack();
      //dispatch(dragAndDropActions.emptyLists());
    }
    setStepCount(1);
  };
  // const setConfrimdata = (data) =>{
  //   setData(data);
  // }
  const goTo = (route) => {
    history.push(route);
  };
  const createScene = () => {
    let createSceneData = { name: sceneName, spaceId: data.spaceId };
    createSceneData.actions = data.actions.map(item => {
      delete item.ifDisabledIntensity
      delete item.ifDisabledKelvin
      delete item.name
      return item
    })
    if (sceneOperation === 'edit') {
      delete createSceneData.spaceId;
      dispatch(sceneActions.editScene(scene.sceneId, createSceneData, history?.goBack));
    } else {
      dispatch(sceneActions.createScene(createSceneData, history?.goBack));
    }
  }
  const checkValidation = () => {
    let settingsNotSetForIntensity = '';
    let settingsNotSetForKelvin = '';
    if (data) {
      for (let i = 0; i < data.actions.length; i++) {
        if (!data.actions[i].ifDisabledIntensity && data.actions[i].intensity === 60) {
          if (data.actions[i].deviceId && !idAlreadyPresentinEdit.includes(data.actions[i].deviceId)) {
            settingsNotSetForIntensity = settingsNotSetForIntensity + data.actions[i]?.name + ', '
          } else if (data.actions[i].groupId && !idAlreadyPresentinEdit.includes(data.actions[i].groupId)) {
            settingsNotSetForIntensity = settingsNotSetForIntensity + data.actions[i]?.name + ', '
          }
        }
        if (!data.actions[i].ifDisabledKelvin && data.actions[i].kelvin === 4000) {
          if (data.actions[i].deviceId && !idAlreadyPresentinEdit.includes(data.actions[i].deviceId)) {
            settingsNotSetForKelvin = settingsNotSetForKelvin + data.actions[i]?.name + ', '
          } else if (data.actions[i].groupId && !idAlreadyPresentinEdit.includes(data.actions[i].groupId)) {
            settingsNotSetForKelvin = settingsNotSetForKelvin + data.actions[i]?.name + ', '
          }
        }
      }
    }
    settingsNotSetForIntensity = settingsNotSetForIntensity.replace(/,\s*$/, "");
    settingsNotSetForKelvin = settingsNotSetForKelvin.replace(/,\s*$/, "");
    if (Validator(sceneName, "text", sceneNameValidation)) {
      setErrorMsg(Validator(sceneName, "text", sceneNameValidation))
    }
    else if (settingsNotSetForIntensity !== '' || settingsNotSetForKelvin !== '') {
      setdeviceList({ settingsNotSetForIntensity, settingsNotSetForKelvin })
      setOpenConfirmationPopup({ show: true });
    }
    else {
      createScene();
    }
  }
  const handleNextStep = () => {
    if (stepCount === 1) {
      setErrorMsg(Validator(sceneName, "text", sceneNameValidation))
      dropList.length === 0 && dispatch(dragAndDropActions.setErrorMessage(getMessageStr('scene-add-noDevicesGroupSelected'))); //WGJGBM scene-add-noDevicesGroupSelected
      notFoundDevicesInDropList.length !== 0 && dispatch(dragAndDropActions.setErrorMessage(getMessageStr('scene-add-removeInactiveDevices'))); //WGJGBM scene-add-removeInactiveDevices
      if (!Validator(sceneName, "text", sceneNameValidation) && dropList.length !== 0 && notFoundDevicesInDropList.length === 0) {
        setEnableEdit(true);
        setIfMovedToNextStep(true);
        setData(dropList);
        dispatch(dragAndDropActions.setErrorMessage('')); //WGJGBM< scene-emptyMessage
        setStepCount(2);
      }
    } else {
      checkValidation();
    }

  };
  const currentPage = (steps) => {
    switch (steps) {
      case 2:
        return <SceneConfirmation selectedDevices={data} setScene={setData} />
      default:
        return <SceneDragDropWrapper ifEdit={sceneOperation === 'edit'} ifMovedToNextStep={ifMovedToNextStep} setNotFoundDevicesInDropList={setNotFoundDevicesInDropList} />
    }
  }
  return (
    <div className="CreateScenes" data-testid="CreateScenes">
      <PageHeader title={sceneOperation === 'edit' ? 'Edit Scene' : 'Create Scene'} bckbtn={true} icon={sceneIcon} />

      <div className="form-group">
        <label style={{marginRight:"8px"}}>Scene Name<label style={{ color: "#BC2200" }}>*</label> : </label>
        {enableEdit ? <label disabled={readOnly}>{sceneName}<ImageWrapper style={{ marginRight: '5px', marginLeft: '10px' }} onClick={() => { setEnableEdit(false) }} src={editIcon} /></label> :
          <StyledTextfield color={errorMsg !== '' ? 'error' : ""} helperText={errorMsg} sx={{ minWidth: '300px', pb: '20px' }} error={errorMsg !== '' ? true : false} value={sceneName} type="text" name="sceneName" className="" onChange={handleChange} />
        }
      </div>
      <Box width={'100%'} borderRadius="10px">
        <CustomStepper labels={steps.map(item => item.label)} activeStep={stepCount} />
      </Box>
      <Box sx={{ py: 1 }}>
        {currentPage(stepCount)}
      </Box>
      <Box sx={{ width: "100%", textAlign: "right" }}>
        <StyledButton
          variant="outlined"
          sx={{ ml: 1, minWidth: "90px" }}
          onClick={handleBackStep}
        >
          {stepCount === 2 ? "Back" : "Cancel"}
        </StyledButton>
        {stepCount === 2 ?
          <StyledButton
            sx={{ ml: 1, minWidth: "90px" }}
            variant="outlined"
            onClick={() => { goTo('/spaces/scenes') }}
          >
            Cancel
          </StyledButton>
          : ''}
        <StyledButton
          onClick={handleNextStep}
          sx={{ ml: 1, minWidth: "90px" }}
        >
          {stepCount === 2 ? "Save" : "Next"}
        </StyledButton>
      </Box>
      <StyledSnackBar
        open={snackBar?.show}
        onClose={closeSnackBar}
        autoHideDuration={5000}
        data={{
          type: snackBar?.type,
          message: snackBar?.message,
        }}
      />
      <StyledPopup open={openConfirmationPopup.show} onClose={() => setOpenConfirmationPopup({ show: false })} state="timeout" data={{
        content: deviceList.settingsNotSetForIntensity !== '' && deviceList.settingsNotSetForKelvin !== '' ? getMessageStr(`scene-create-confirmation`, deviceList.settingsNotSetForIntensity) : deviceList.settingsNotSetForIntensity !== '' ? getMessageStr(`scene-create-confirmationIntensity`, deviceList.settingsNotSetForIntensity) : getMessageStr(`scene-create-confirmationTemp`, deviceList.settingsNotSetForKelvin),
        actions: [
          {
            id: "1001",
            type: "button",
            label: "Cancel",
            onClick: (event, data) => {
              setOpenConfirmationPopup({ show: false })

            }
          },
          {
            id: "1002",
            type: "button",
            label: "Confirm",
            onClick: (event, data) => {
              createScene();
              setOpenConfirmationPopup({ show: false })
            }
          }
        ]
      }} />
    </div >
  )
};

CreateScenes.propTypes = {};

CreateScenes.defaultProps = {};

export default CreateScenes;
