import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    AddEditSpaceType: {
        overflow: 'hidden',
        maxWidth: 'fit-content',
        fontSize: '0.75rem',
        '& h6': {
            fontSize: '0.75rem',
            fontWeight: '600'
        },
        '& label': {
            fontSize: '0.75rem',
            fontWeight: '500'
        }
    }
});
