import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    StyledScheduler: {

        "& .scheduleBox": {
            background: "rgba(15, 141, 72, 0.05)",
            marginBottom: "8px",
            padding: "16px",
            maxWidth: "100%"
        },
        "& .MuiFormControlLabel-label":{
            color:"#0F8D48",
            lineHeight:"20px",
            fontWeight:500,
            fontSize:"10px"
        },
        "& .dayChoice .MuiFormControlLabel-label":{
            color:"#000",
            fontWeight:500,
            fontSize:"14px"
        },
        "& .MuiOutlinedInput-root":{
            backgroundColor:"white"
        }


        //     week_box: {
        //         display: flex,
        //         align-items: center,
        // //     #checkbox{
        // //         width: 30px,
        // //         height: 30px,
        // //         padding: 0,
        // //         margin-right: 5px
        // // }
        //         },
        // borderBox: {
        //     border: "1px solid rgba(0, 0, 0, 0.1)",
        //     borderRadius: "10px"
        // }

        
    },
    // xyz:{
    //     background: "rgba(15, 141, 72, 0.05)",
    //     marginBottom: "8px",
    //     padding: "16px",
    //     maxWidth: "100%"
    // }
});

