/***
 *
 * Controller class for user.
 * @file FirmwareDistribution.js
 * @description FirmwareDistribution component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './FirmwareDistribution.style.js';
import './FirmwareDistribution.scss';
import { Box, Grid } from '@mui/material';
import Distribution from '../../assets/icons/pie.svg'
import PageHeader from '../PageHeader/index.js';
import StyledGraph from '../StyledGraph/index.js';
import ChipInput from '../ChipInput/index.js';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useState } from 'react';
import StyledButton from '../StyledButton/index.js';
import { fotaActions } from '../../redux/actions/fota.actions.js';
import { deviceActions, tenantActions } from '../../redux/actions/index.js';
import CustomTable from '../CustomTable/index.js';
import { nameEditor } from '../../helpers/device.helper.js';
import { useEffect } from 'react';
import { pieChartTheme } from '../../constants/theme.constants.js';
import CustomLabel from '../CustomLabel/index.js';
import { getMessageStr } from '../../helpers/message.helper.js';
import CustomTypography from '../CustomTypography/index.js';
import StyledCardWrapper from '../StyledCardWrapper/index.js';
import DataNotFound from '../../pages/DataNotFound/index.js';
import GridView from '../GridView/index.js';
import CustomDropDown from '../CustomDropDown/index.js';

const tableHeaders = (category, deviceCategories, portal) => {
  return {
    name: {
      label: "name",
      type: "component",
      component: (row, sx = {}) => {
        return nameEditor(row, deviceCategories?.find(c => c?.category === row?.data?.category), sx, null, portal === "tms" ? "" : `/spaces/devices?pt=${row?.data?.category?.toLowerCase()?.includes("gateway") ? "gateway" : row?.data?.category?.toLowerCase()?.includes("controller") ? "controller" : "aggregator"}`, false)
      },
      style: { minWidth: 200 }
    },
    modelNumber: {
      label: 'Model Number',
      style: { minWidth: 160 }
    },
    ...(() => {
      if (category?.toLowerCase()?.includes("gateway")) {
        return { fwVersion: { label: 'Gateway Application Version', style: { minWidth: 250 } } }
      }
      else {
        return { fwVersion3rdParty: { label: 'Firmware Version', style: { minWidth: 210 } }, }
      }
    })(),
  }
}

const FirmwareDistribution = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const deviceCategories = useSelector(state => state?.devices?.deviceCategories);
  const deviceList = useSelector(state => state?.devices?.detailedDeviceList);
  const fwDistribution = useSelector(state => state?.fota?.firmwareDistribution || []);
  const portal = useSelector(state => state?.app?.portal);
  const tenants = useSelector(state => state?.tenants?.list || []);
  const lastAction = useSelector(state => state?.devices?.lastAction);

  const [error, setError] = useState({ tenant: null, category: null });
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [table, setTable] = useState({ header: {}, data: [], searching: [] });
  const [chartData, setChartData] = useState(null);
  // const [showDataNotFound, setShowDataNotFound] = useState(false);
  const [categories, setCategories] = useState(portal === "mss" ? _.isArray(deviceCategories) && (deviceCategories?.filter(c => c?.category?.toLowerCase()?.includes("gateway") || c?.category?.toLowerCase()?.includes("controller"))?.map((c, ind) => {
    return {
      "id": c?.label,
      "label": c?.label,
      "category": c?.category
    }
  }) || []) : []);

  const tenantList = _.isArray(tenants) && tenants?.map(t => {
    return {
      id: t?.tenantName,
      label: t?.tenantName
    }
  })

  // useEffect(() => {
  //   console?.log("here1", selectedTenant, lastAction, categories)
  // }, [categories])

  useEffect(() => {
    if (_.isEmpty(tenants) && portal === "tms") {
      dispatch(tenantActions.getAllTenants());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if ((portal === "mss" || (portal === "tms" && selectedTenant !== null)) && lastAction?.includes("get_device_categories")) {
      // console?.log("here2", selectedTenant, lastAction, categories)
      setCategories(_.isArray(deviceCategories) && deviceCategories?.filter(c => c?.category?.toLowerCase()?.includes("gateway") || c?.category?.toLowerCase()?.includes("controller"))?.map((c, ind) => {
        return {
          "id": c?.label,
          "label": c?.label,
          "category": c?.category
        }
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastAction])

  useEffect(() => {
    if (!_.isEmpty(selectedCategories)) {
      setTable({ header: tableHeaders(deviceList[0]?.category, deviceCategories, portal), data: deviceList, searching: ["name", "modelNumber", deviceList[0]?.category?.toLowerCase()?.includes("gateway") ? "fwVersion" : "fwVersion3rdParty"] })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [deviceList])

  useEffect(() => {
    if (!_.isEmpty(selectedCategories)) {
      setChartData(fwDistribution?.map(f => {
        return {
          category: f?.category,
          total: f?.totalDeviceCount,
          data: {
            labels: f?.firmwareDistribution?.map(fw => (f?.category?.toLowerCase()?.includes("gateway") ? (fw?.fwVersion === "empty" ? "Not specified" : ("Version " + fw?.fwVersion)) : (fw?.fwVersion3rdParty === "empty" ? "Not specified" : ("Version " + fw?.fwVersion3rdParty)))),
            datasets: [
              {
                title: "Firmware Version",
                data: f?.firmwareDistribution?.map(fw => fw?.count),
                backgroundColor: pieChartTheme?.pieSegmentColors,
                color: ["#FFF"],
                borderColor: [
                  pieChartTheme?.borderColor
                ],
                borderWidth: 2,
              }
            ]
          }

        }
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fwDistribution])

  return (
    <Box className={classes?.FirmwareDistribution || ""} data-testid="FirmwareDistribution">
      <PageHeader
        backbtn={false}
        title={"Firmware Distributions"}
        icon={Distribution}
      >
      </PageHeader>
      <Grid container className="searchContainer" p={{ xs: "8px 20px 16px 20px", sm: "12px 24px 20px 24px" }} spacing={1}>

        {portal === "tms" && <Grid item xs={12} md={3} lg={3} sx={{ minHeight: "100px" }}>
          <CustomLabel
            required={true}
            style={{ fontSize: "12px", margin: "0px 0px 8px 8px", fontWeight: 700 }}
          >
            {"Select Tenant"}
          </CustomLabel>
          <CustomDropDown
            name={"tenant"}
            error={error?.tenant ? true : false}
            message={error?.tenant}
            value={selectedTenant}
            customsx={{ maxWidth: { xs: "240px", sm: "260px" }, menuList: { maxHeight: "180px" } }}
            isNoneAsItem={_.isEmpty(tenantList)}
            list={tenantList}
            // disabled={d?.disabled()
            // }
            onChange={(e) => {
              setSelectedCategories([]);
              // setShowDataNotFound(false);
              setChartData(null)
              // console.log("here4", categories)
              if (!_.isEmpty(e?.target?.value)) {
                setError({ ...error, tenant: null })
              }
              dispatch(deviceActions.getDeviceModels(e?.target?.value));
              dispatch(deviceActions.getDeviceCategories(e?.target?.value, () => { }, () => {
                // console?.log("here3", selectedTenant, lastAction, categories)
                setCategories([]);
              }));
              setSelectedTenant(e?.target?.value);
            }}
          />
        </Grid>}
        <Grid item xs={12} md={portal === "tms" ? 7 : 10} lg={portal === "tms" ? 8 : 11} sx={{ minHeight: "100px" }}>
          <CustomLabel
            required={true}
            style={{ fontSize: "12px", margin: "0px 0px 8px 8px", fontWeight: 700 }}
          >
            {"Select Categories"}
          </CustomLabel>
          <ChipInput placeholder="Choose Categories"
            value={selectedCategories}
            sx={{ minWidth: { xs: "240px", sm: "260px" }, marginBottom: "0px" }}
            rounded
            fullWidth
            options={categories}
            key="categories"
            error={error?.category ? true : false}
            helperText={error?.category}
            disabled={portal === "tms" && _.isEmpty(categories)}
            onChange={(e) => {
              // setShowDataNotFound(false);
              setChartData(null)
              // setTimeout(() => {
              setSelectedCategories(e?.target?.value)
              // }, 1000)
              if (!_.isEmpty(e?.target?.value)) {
                setError({ ...error, category: null })
              }
            }} />
        </Grid>
        <Grid item xs={12} md={2} lg={1} sx={{ display: "flex", justifyContent: "flex-end", alignItems: 'flex-end' }}>
          <StyledButton
            sx={{ maxHeight: "32px", marginTop: "8px" }}
            onClick={(e) => {
              if (_?.isEmpty(selectedCategories) || (portal === "tms" && selectedTenant === null)) {
                setError({ tenant: selectedTenant === null ? getMessageStr("fota-distribution-tenantRequired") : null, category: _?.isEmpty(selectedCategories) ? getMessageStr("fota-distribution-categoryRequired") : null })
              }
              else {
                // setShowDataNotFound(true);
                setError({ category: null, tenant: null })
                const categoryData = selectedCategories?.map(c => categories?.find(cat => cat?.label === c)?.category)
                dispatch(fotaActions?.getFirmwareDistribution({ categories: categoryData?.join(",") }, selectedTenant, (data) => {
                  if ((!_.isEmpty(data)) && data[0]?.hasOwnProperty("category")) {
                    dispatch(deviceActions?.getDetailedDeviceList({ category: data[0]?.category, keys: `deviceId,category,name,modelId,${data[0]?.category?.toLowerCase()?.includes("gateway") ? "fwVersion" : "fwVersion3rdParty"}` }, true, selectedTenant))
                  }
                }));
              }
            }}
          >
            {"Search"}
          </StyledButton>
        </Grid>
      </Grid>


      {chartData !== null &&
        <DataNotFound
          hideLoader={true}
          show={_.isEmpty(chartData)}
          customsx={{ image: { height: '202px', width: '100%' }, label: {}, content: {}, btns: {} }}
          data={{
            content: getMessageStr("fota-distribution-listEmpty")
          }}
        >
          {
            Array.isArray(chartData) && <GridView
              data={chartData}
              card={({ data }) => <StyledCardWrapper onClick={(e) => {
                dispatch(deviceActions?.getDetailedDeviceList({ category: data?.category, keys: `deviceId,category,name,modelId,${data?.category?.toLowerCase()?.includes("gateway") ? "fwVersion" : "fwVersion3rdParty"}` }, true, selectedTenant))
              }}>
                <CustomTypography
                  fontWeight={700}
                  size={12}
                  content={deviceCategories?.find(cat => cat?.category === data?.category)?.label}
                />
                <CustomTypography
                  color={"#5E5873"}
                  fontWeight={700}
                  size={24}
                  content={data?.total}
                />
                <StyledGraph chartType={"pie"} legend={{ position: "right", title: { display: true, text: "Firmware Version" } }} chartData={data?.data} />
              </StyledCardWrapper>
              }>
            </GridView>
          }
          {
            table?.data?.length > 0 &&
            <CustomTable
              headerList={table?.header}
              cellMatrix={table?.data}
              searching={{ reset: true, columns: table?.searching }}
              pagination={true}
              enableScrollToTop={false}
              sorting={{ column: "name" }}
            ></CustomTable>
          }
        </DataNotFound>}

    </Box>
  )
};

FirmwareDistribution.propTypes = {};

FirmwareDistribution.defaultProps = {};

export default FirmwareDistribution;
