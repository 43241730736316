/***
 *
 * Controller class for user.
 * @file RuleTriggers.js
 * @description RuleTriggers component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useState, useEffect } from 'react';
import { useStyles } from './RuleTriggers.style.js';
import './RuleTriggers.scss';
import { Box, Divider } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import StyledPopupWrapper from '../StyledPopupWrapper/index.js';
import CustomTypography from '../CustomTypography/index.js';
import StyledButton from '../StyledButton/index.js';
import Delete from '../../assets/icons/delete.svg';
import Edit from '../../assets/icons/edit-2.svg';
import ImageWrapper from '../ImageWrapper/index.js';
import RuleSensorSelection from './RuleSensorSelection/index.js';
import StyledPopup from '../StyledPopup/index.js';
import CustomScheduler from './CustomScheduler/index.js';
import ErrorMessage from '../ErrorMessage/index.js';

const RuleTriggers = ({ triggersData = [], warningMessage="", handleTriggerData, setShowWarning = () => {} }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openPopover, setOpenPopover] = useState(false);
  const [popoverTitle, setPopoverTitle] = useState("");
  const [selectedTriggerValue, setSelectedTriggerValue] = useState(null);
  const [confirmedSensor, setConfirmedSensor] = useState(null);
  const [confirmedSchedule, setConfirmedSchedule] = useState(null);
  const [deleteTriggerConfirmation, setDeleteTriggerConfirmation] = useState(false);
  const [isTimeTriggerClicked, setIsTimeTriggerClicked] = useState(false);
  const [isEditClicked, setIsEditClicked] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleTriggerSelection = (event) => {
    const option = event?.target?.innerHTML;
    if(isTimeTriggerClicked) {
      setIsTimeTriggerClicked(false);
    }

    if(option === "Time") {
      setIsTimeTriggerClicked(true);
    }
    setPopoverTitle(option);
    setAnchorEl(null);
    setOpenPopover(true);
  }

  const handlePopoverClose = () => {
    setOpenPopover(false);
    setAnchorEl(null);
    setPopoverTitle("");
  }

  const handleTriggerConfirm = () => {
    if(isTimeTriggerClicked) {
      prepareTriggerData(confirmedSchedule);
      setSelectedTriggerValue({...confirmedSchedule});
    }
    else {
      setSelectedTriggerValue({...confirmedSensor});
      prepareTriggerData(confirmedSensor);
    }
    handlePopoverClose();
  }

  const handleTriggerEdit = (triggerType = "") => {
    // setShowWarning(true);
    if(triggerType === "Time") {
      setIsTimeTriggerClicked(true);
      setIsEditClicked(true);
    }
    else {
      setIsEditClicked(true);
    }
    setPopoverTitle("Edit " + (triggerType === "Time" ? triggerType : "Device Status"));
    setAnchorEl(null);
    setOpenPopover(true);
  }

  const handleTriggerDelete = () => {
    setDeleteTriggerConfirmation(false);
    setSelectedTriggerValue(null);
    setIsTimeTriggerClicked(false);
    handleTriggerData([]);
    setIsEditClicked(false);
  }

  const prepareTriggerData = (data = {}) => {
    var preparedData = {};
    if(data) {
      if(data?.type === "Time") {
        preparedData = {type: data.type, timeOfDay: data.timeOfDay};
      }
      else {
        preparedData = {...data};
      }
    }
    handleTriggerData([preparedData]);
  }

  const shareCustomSx = {
    dialog: {
      // "& .MuiDialog-paper": { width: "700px" },
      "& .MuiDialog-paper": { maxWidth: "800px !important" },
    },
  };

  const prepareTimeString = (timeList = []) => {
    var timeString = "";

    timeList.forEach((time, index) => {
      if(index !== 0) {
        timeString += " | "
      }
      if(time.hasOwnProperty('interval')) {
        timeString += time.label + " - Every " + time.interval + " Mins"
      }
      else {
        timeString += time.label
      }
    });
    return timeString;
  }

  useEffect(() => {
    if(triggersData.length > 0) {
      const data = triggersData[0];

      if(data.type === "Time") {
        setSelectedTriggerValue({type: data.type, timeOfDay: data?.timeOfDay || []});
      }
      else {
        setSelectedTriggerValue({...data});
      }
    }
  }, [triggersData])

  return (
    <Box className={classes?.RuleTriggers || ""} data-testid="RuleTriggers" sx={{ minHeight: "210px" }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between'}}>
        <Box>
          <CustomTypography content={"Triggers"} fontWeight={600} />
          {!selectedTriggerValue && <CustomTypography content={"Add a trigger to continue."} fontWeight={600} styles={{ opacity: "50%"}} />}
        </Box>

        <Box sx={{ mb: 2 }}>
          <StyledButton
            id="demo-customized-button"
            aria-controls={open ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
            disabled={selectedTriggerValue ? true : false}
          >
            Add Trigger Group
          </StyledButton>
          <Menu
            id="demo-customized-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handlePopoverClose}
            className='TriggerMenu'
          >
            <MenuItem onClick={(event) => handleTriggerSelection(event)} sx={{ p: '0px 8px', minWidth: '170px' }} disableRipple>
              Time
            </MenuItem>
            <Divider />
            <MenuItem onClick={(event) => handleTriggerSelection(event)} sx={{ p: '0px 8px' }} disableRipple>
              Device Status
            </MenuItem>
          </Menu>
        </Box>
      </Box>
      {(warningMessage && selectedTriggerValue) && <ErrorMessage message={warningMessage} />}
      {
        selectedTriggerValue &&
          <Box sx={{ border: "1px solid #bcbcbc", borderRadius: "4px", p: 2}}>
            {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end'}}>
              <StyledButton iconButton tooltip='Remove' sx={{ p: '0'}} onClick={() => {}} color='#BC2200'>
                <ImageWrapper className="deleteButton" height={16} src={Delete} /><CustomTypography content=" Remove Triggers" sx={{ marginLeft: "10px", color: "#BC2200" }} fontWeight={600} fontSize="18px"  />
              </StyledButton>
            </Box> */}
            <Box>
              <Box>
                <Box><CustomTypography content={selectedTriggerValue?.type === "Time" ? "Time Trigger" : "Device Status Trigger"} fontWeight={600} fontSize="14px" /></Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: "1px solid #bcbcbc", borderRadius: "4px", p: 1, maxWidth: '305px'}}>
                  <Box sx={{ display: 'flex', maxWidth: '230px', whiteSpace: 'nowrap' }}>
                    { selectedTriggerValue?.type === "Time"
                      ? <Box sx={{ textWrap: "wrap" }}>
                        <CustomTypography content={"Time"} fontWeight={500} fontSize="16px" /> 
                        <CustomTypography content={prepareTimeString(selectedTriggerValue?.timeOfDay)} fontWeight={400} fontSize="14px"/> 
                      </Box>
                      : <>
                        <CustomTypography content={selectedTriggerValue?.name} fontWeight={400} fontSize="14px" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}/> 
                        <CustomTypography content={selectedTriggerValue?.property ? " | " + (selectedTriggerValue?.property).toUpperCase() : ""} fontWeight={400} fontSize="14px"/> 
                      </>
                    }
                  </Box>
                  <Box sx={{ display: 'flex' }} >
                    <StyledButton iconButton tooltip='Edit' sx={{p: '0'}} onClick={() => {return handleTriggerEdit(selectedTriggerValue?.type)}} src={Edit}>
                        <ImageWrapper height={16} src={Edit} />
                    </StyledButton>
                    <StyledButton iconButton tooltip='Remove' sx={{ p: '0', ml: 2 }} onClick={() => setDeleteTriggerConfirmation(true)} color='#BC2200'>
                      <ImageWrapper className="deleteButton" height={16} src={Delete} />
                    </StyledButton>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
      }

      <StyledPopupWrapper 
        open={openPopover} 
        onClose={handlePopoverClose}
        closeButton={true}
        title= {popoverTitle + " Trigger"}
        customsx={shareCustomSx}
      >
        { isTimeTriggerClicked
        ? <CustomScheduler
          currentSchedule={selectedTriggerValue}
          editClicked={isEditClicked}
          scheduleHandler={setConfirmedSchedule}
        />
        : <RuleSensorSelection
          currentSensor={selectedTriggerValue}
          editClicked={isEditClicked}
          sensorHandler={setConfirmedSensor}
        />  
        }
        <Box sx={{ py: 1, float: 'right' }}>
          <StyledButton variant="outlined" onClick={handlePopoverClose}>Cancel</StyledButton>
          <StyledButton sx={{ ml: 1}} disabled={(confirmedSensor || confirmedSchedule) ? false : true} onClick={handleTriggerConfirm}>{isTimeTriggerClicked ? "Save" : "Confirm"}</StyledButton>
        </Box>
      </StyledPopupWrapper>

      <StyledPopup title={"Delete Trigger"} open={deleteTriggerConfirmation} onClose={() => setDeleteTriggerConfirmation(false)} state="timeout" 
        data={{
          content: "Do you want to remove this trigger?",
          actions: [
            {
              id: "1002",
              type: "button",
              label: "Cancel",
              onClick: (event, data) => {
                setDeleteTriggerConfirmation(false)
              }
            },
            {
              id: "1001",
              type: "button",
              label: "Confirm",
              onClick: (event, data) => {
                handleTriggerDelete(event);
              }
            }
          ]
        }}  
      />
    </Box>
  )
};

RuleTriggers.propTypes = {};

RuleTriggers.defaultProps = {};

export default RuleTriggers;
