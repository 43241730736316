/***
 *
 * Controller class for user.
 * @file RuleSensorSelection.js
 * @description RuleSensorSelection component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useState, useEffect } from 'react';
import { useStyles } from './RuleSensorSelection.style.js';
import './RuleSensorSelection.scss';
import { Box, Checkbox, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Tab, Tabs } from '@mui/material';
import { deviceActions } from '../../../redux/actions/index.js';
import { useDispatch, useSelector } from 'react-redux';
import CustomTypography from '../../CustomTypography/index.js';
import SearchWrapper from '../../SearchWrapper/index.js';
import SensorIcon from '../../../assets/icons/sensor.svg';
import StyledSpinner from '../../StyledSpinner/index.js';
import _ from 'lodash';
import ImageWrapper from '../../ImageWrapper/index.js';
import StyledSpaceTree from '../../StyledSpaceTree/index.js';

const RuleSensorSelection = ({ currentSensor = {}, editClicked = false, sensorHandler = () => { } }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const deviceList = useSelector(state => state?.devices?.deviceList);
  const deviceModels = useSelector(state => { return (state?.devices?.deviceModels || []) })
  const activeSpace = useSelector(state => state?.spaces?.selectedSpace);
  const spaces = useSelector((state) => state?.spaces?.list);

  const [loading, setLoading] = useState(false)
  const [isSpaceSelected, setIsSpaceSelected] = useState(false);
  const [sensorsList, setSensorsList] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedSpace, setSelectedSpace] = useState(JSON?.parse(JSON.stringify(activeSpace)));
  const [searchQuery, setSearchQuery] = useState('');

  const handleSpaceClick = (event, data) => {
    if (!isSpaceSelected) {
      setIsSpaceSelected(true);
    }

    setLoading(true);
    dispatch(deviceActions.getDevicesBySpaceId(data?.spaceId));
  }

  const handleCheck = (e, item) => {
    setSelectedDevice({
      ...item,
      property: tabIndex === 0 ? "pir" : "als"
    });
  }

  const handleSearchListSelection = (e, item) => {
    if (item) {
      setSelectedDevice({ ...item });

      const element = document.getElementById(`${item['id']}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  const handleTabChange = (event, newIndex, comingFrom = "function") => {
    setLoading(true);
    setTabIndex(newIndex);
    if(comingFrom !== "useeffect") {
      setSelectedDevice(null);
    }
    setTimeout(() => {
      setLoading(false);
    }, 100);
  };

  useEffect(() => {
    if (currentSensor && editClicked) {
      const item = { ...currentSensor }
      setSelectedDevice(item);
      const sensorSpace = activeSpace.spaceId === item?.spaceId ? activeSpace : activeSpace?.Spaces.find((space) => space.spaceId === item.spaceId);
      if (item.property === "als") {
        handleTabChange(null, 1, "useeffect");
      }
      handleSpaceClick(null, item);
      setSelectedSpace(sensorSpace);
      setTimeout(() => {
        const element = document.getElementById(`${item['id']}`);
        if (element) {
          element.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSensor])

  useEffect(() => {
    if (isSpaceSelected) {
      let lightDevices = deviceList.filter(item => item.hasOwnProperty('deviceId') && item.status === 'active');
      let devices = lightDevices.filter(device => {
        if (device.category.includes('Sensor')) {
          device.id = device?.deviceId
          if (device?.name === undefined || device.name.slice(-1) !== ')') {
            device.name = (device?.name || '') + ' (' + device?.id + ')'
          }
          device.icon = SensorIcon
          device.type = 'DEVICE_STATUS'
          return device;
        }
      })

      let updatedData = [];
      let updatedSensorData = []
      updatedData = devices.map((sensor) => {
        if (sensor?.type === 'DEVICE_STATUS') {
          sensor.capability = []
          let objWithModelId = deviceModels.find(deviceModel => { return deviceModel?.modelId === sensor?.modelId });
          let sensorCapabilities = objWithModelId?.statusCapabilities;

          sensorCapabilities.forEach((item) => {
            if (item?.capability === 'occupancy') {
              sensor.capability.push(item?.attributes[0]?.key)
            }
            else if (item.capability === 'ambientLight') {
              sensor.capability.push(item?.attributes[0]?.key)
            }
          })
          updatedSensorData.push(sensor);
          return sensor;
        }
      })
      setSensorsList([...updatedData])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceList, isSpaceSelected]);

  useEffect(() => {
    if (sensorsList) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sensorsList])

  useEffect(() => {
    sensorHandler(selectedDevice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDevice]);

  useEffect(() => {
    if(!editClicked && activeSpace && selectedDevice === null) {
      handleSpaceClick(null, {spaceId: activeSpace?.spaceId});
    }
    // setSelectedSpace(JSON?.parse(JSON.stringify(activeSpace)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box className={classes?.RuleSensorSelection || ""} data-testid="RuleSensorSelection">
      <Grid item container xs={12} spacing={1} sx={{ height: "100%" }}>
        <Grid item xs={12} sm={6} md={6}>
          {/* <SpaceTree onClick={handleSpaceClick}/> */}
          <Box>
            <Paper sx={{ width: '100%', minHeight: "420px", maxHeight: '500px', height: '100%', border: "1px solid #D9D9D9" }}  >
              <Box sx={{ background: '#0F8D480D', padding: '18px 24px 0px' }}>
                <CustomTypography content={`Select Space`} weight={600} size={12} lineHeight={14.63} color="rgba(0, 0, 0, 0.8)" />
              </Box>
              <Box className="spaceTree" sx={{ position: "relative" }}>
                <StyledSpaceTree
                  // searchLabel={<CustomTypography content={`Select Space`} styles={{ marginBottom: "16px" }} weight={600} size={12} lineHeight={14.63} color="rgba(0, 0, 0, 0.8)" />}
                  spaces={spaces}
                  subTreeRoot={activeSpace}
                  handleExpand={(e, val) => {
                    setSelectedSpace(val);
                    handleSpaceClick(e, val)
                  }}
                  activeSpace={selectedSpace}
                  style={{ maxHeight: '330px' }}
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  handleChange={(val) => { setSearchQuery(val) }}
                />
              </Box>
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Paper sx={{ width: '100%', minHeight: "420px", maxHeight: '500px', height: '100%', border: "1px solid #D9D9D9", position: 'relative' }}  >
            <Box sx={{ background: '#0F8D480D', padding: '18px 24px 0px' }}>
              <CustomTypography content={`Select a sensor`} weight={600} size={12} lineHeight={14.63} color="rgba(0, 0, 0, 0.8)" />
            </Box>

            <SearchWrapper
              placeholder={`Search Sensor`}
              data={sensorsList.length !== 0 ? sensorsList : [{ name: 'No Item Available' }]}
              autoComplete={true}
              onChange={(e, value) => { handleSearchListSelection(e, value) }}
              autoFill={false}
            />

            <Grid item container justifyContent="center" >
              <Box sx={{ display: "flex", alignItems: "center", width: '100%', borderBottom: 1, borderColor: 'divider' }}>
                <CustomTypography content="Sensor Type" weight={500} size={14} lineHeight={20} sx={{ p: 1 }} />
                <Tabs value={tabIndex} onChange={handleTabChange}>
                  <Tab label="PIR" id="sensor-tab-pir" sx={{ fontSize: "14px", fontWeight: 600, p: 2 }} />
                  <Tab label="ALS" id="sensor-tab-als" sx={{ fontSize: "14px", fontWeight: 600, p: 2 }} />
                </Tabs>
              </Box>
              <Box sx={{ width: "100%" }}>
                <CustomTypography content="*You can select only one sensor" weight={400} size={14} lineHeight={20} sx={{ p: "8px 16px", fontStyle: "italic" }} />
                {loading
                  ? <StyledSpinner sx={{ marginLeft: "auto", marginRight: "auto" }} item mt={'130px'} />
                  : <List sx={{ width: '100%', padding: "0 24px 24px", maxHeight: '240px', overflowY: 'auto' }}>
                    {
                      (tabIndex === 0 && !_.isEmpty(sensorsList))
                        ?
                        sensorsList.filter((sensor) => sensor?.capability.includes("pir")).map((item) => {
                          let id = item?.deviceId
                          return (
                            <Box key={id}>
                              <ListItem
                                key={id}
                                id={id}
                                sx={{ border: '1.4px solid #D9D9D9', borderRadius: '4px', mb: '5px' }}
                                disablePadding
                              >
                                <ListItemButton onClick={(e) => { handleCheck(e, item) }} sx={{ padding: "2px 10px", height: '40px', lineHeight: '40px' }}>
                                  <ListItemIcon sx={{ minWidth: "auto" }}>
                                    <Checkbox
                                      edge={"start"}
                                      checked={selectedDevice?.deviceId === id || false}

                                    />
                                    <ListItemText />
                                  </ListItemIcon>
                                  <ListItemText id={id} primary={
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                      <ImageWrapper className='icon-right' src={item?.icon} />
                                      <span style={{}}>{item?.name}</span>
                                    </Box>
                                  } />
                                </ListItemButton>
                              </ListItem>
                            </Box>
                          )
                        })
                        :
                        sensorsList.filter((sensor) => sensor?.capability.includes("als")).map((item) => {
                          let id = item?.deviceId
                          return (
                            <Box key={id}>
                              <ListItem
                                key={id}
                                id={id}
                                sx={{ border: '1.4px solid #D9D9D9', borderRadius: '4px', mb: '5px' }}
                                disablePadding
                              >
                                <ListItemButton onClick={(e) => { handleCheck(e, item) }} sx={{ padding: "2px 10px", height: '40px', lineHeight: '40px' }}>
                                  <ListItemIcon sx={{ minWidth: "auto" }}>
                                    <Checkbox
                                      edge={"start"}
                                      checked={selectedDevice?.deviceId === id || false}

                                    />
                                    <ListItemText />
                                  </ListItemIcon>
                                  <ListItemText id={id} primary={
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                      <ImageWrapper className='icon-right' src={item?.icon} />
                                      <span style={{}}>{item?.name}</span>
                                    </Box>
                                  } />
                                </ListItemButton>
                              </ListItem>
                            </Box>
                          )
                        })
                    }

                  </List>
                }
              </Box>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
};

RuleSensorSelection.propTypes = {};

RuleSensorSelection.defaultProps = {};

export default RuleSensorSelection;
