/***
 *
 * Controller class for user.
 * @file SystemLog.js
 * @description SystemLog component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './SystemLog.style.js';
import './SystemLog.scss';
import { Box, Grid, Link } from '@mui/material';
import { useState } from 'react';
import CustomLabel from '../CustomLabel/index.js';
import StyledDateTimePicker from '../StyledDateTimePicker/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { deviceActions } from '../../redux/actions/index.js';
import { useEffect } from 'react';
import CustomAccordion from '../CustomAccordion/index.js';
import ItemView from '../ItemView/index.js';
import DownloadIcon from '../../assets/icons/download.svg'
import ViewIcon from '../../assets/icons/view.svg'
import ImageWrapper from '../ImageWrapper/index.js';
import HideIcon from '../../assets/icons/hide.svg'
import CustomDropDown from '../CustomDropDown/index.js';
import { formatedDate } from '../../helpers/date.helper.js';
import StyledButton from '../StyledButton/index.js';
const SystemLog = ({ deviceId, ...props }) => {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState({ value: null, errorMessage: null });
  const [selectedAvailableDate, setSelectedAvailableDate] = useState({ value: null, errorMessage: null });
  const [log, setLog] = useState({});
  const [logDates, setLogDates] = useState([]);//dates on which gateway log is available
  const [viewLog, setViewLog] = useState(false);

  const dispatch = useDispatch();
  const gatewayLog = useSelector((state) => state?.devices?.deviceDetails?.log);
  const gatewayLogDates = useSelector((state) => state?.devices?.deviceDetails?.logDates);


  const getGatewayLog = () => {

    if (selectedAvailableDate?.value) {
      const year = selectedAvailableDate?.value?.substring(0, 4);
      const month = selectedAvailableDate?.value?.substring(4, 6);
      const day = selectedAvailableDate?.value?.substring(6, 8);
      dispatch(deviceActions?.getGatewayLog(deviceId, selectedAvailableDate?.value, `${year}-${month}-${day}`));
    }
    else {
      // if (selectedDate?.value !== null) {
      const day = selectedDate?.value?.$D < 10 ? `0${selectedDate?.value?.$D}` : selectedDate?.value?.$D;
      let month = selectedDate?.value?.$M + 1;
      month = month < 10 ? `0${month}` : month;
      const year = selectedDate?.value?.$y
      dispatch(deviceActions?.getGatewayLog(deviceId, `${year}${month}${day}`, `${year}-${month}-${day}`));
      // }
      // else {
      //   setSelectedDate({ ...selectedDate, errorMessage: getMessageStr("systemLog-date-required") })
      //   setSelectedAvailableDate({ ...selectedAvailableDate, errorMessage: getMessageStr("systemLog-date-required") })
      // }
    }
  }
  useEffect(() => {
    dispatch(deviceActions?.getGatewayLogDates(deviceId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLog(gatewayLog);
  }, [gatewayLog]);

  useEffect(() => {
    gatewayLogDates?.reverse();
    setLogDates(gatewayLogDates?.map(g => {
      const year = g?.substring(0, 4);
      const month = g?.substring(4, 6);
      const day = g?.substring(6, 8);
      return {
        id: g,
        label: formatedDate({ date: `${year}-${month}-${day}` })
      }
    }))
  }, [gatewayLogDates]);

  return (
    <Box className={classes?.SystemLog || ""} data-testid="SystemLog">
      <Grid container spacing={{ xs: 0, sm: 2 }}>
        <Grid item xs={12} sm={6} md={3} >
          <CustomLabel
            required={true}
            style={{ fontSize: 12, fontWeight: 500, color: "#000000", lineHeight: "15px" }}>
            {"Select Available Log Date"}
          </CustomLabel>
          <CustomDropDown
            // customsx={{ height: "49px" }}
            id={"selectDate"}
            value={selectedAvailableDate?.value}
            name="date"
            isNoneAsItem={true}
            error={selectedAvailableDate?.errorMessage ? true : false}
            message={selectedAvailableDate?.errorMessage}
            placeholder="Select Date"
            list={logDates}
            onChange={(e) => {
              setSelectedDate({ value: null })
              setSelectedAvailableDate({ value: e?.target?.value ? e?.target?.value : null })
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} mt={{ xs: 1, sm: 0 }}>
          <CustomLabel
            required={true}
            style={{ fontSize: 12, fontWeight: 500, color: "#000000", lineHeight: "15px" }}>
            {"Select New Log Date"}
          </CustomLabel>
          <StyledDateTimePicker
            sx={{ width: "100%" }}
            picker="datePicker"
            value={selectedDate?.value}
            error={selectedDate?.errorMessage ? true : false}
            message={selectedDate?.errorMessage}
            placeholder="Select date"
            disableFuture={true}
            onChange={(e) => {
              setSelectedAvailableDate({ value: null })
              setSelectedDate({ value: e?.target?.value });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} sx={{ display: "flex", alignItems: "flex-end" }} mt={{ xs: 2, sm: 0 }} justifyContent={{ xs: "right", md: "left" }}>
          {/* <Box sx={{display:"flex",alignItems:"flex-end"}}> */}
          <StyledButton
            onClick={getGatewayLog}
            sx={{ height: "41px"}}
            disabled={selectedAvailableDate?.value === null && selectedDate?.value === null}
          >Search</StyledButton>

          {/* </Box> */}
        </Grid>
      </Grid>
      {log &&
        <CustomAccordion title={"System Log Details"}
          component={<Box sx={{ display: "flex", textAlign: "center" }}>
            {
              <StyledButton
                iconButton
                tooltip={viewLog ? "Hide log" : "View log"}
                sx={{ p: 0 }} onClick={(e) => { setViewLog(!viewLog) }}>
                <ImageWrapper src={viewLog ? HideIcon : ViewIcon} />
              </StyledButton>
            }
            {log?.downloadableSignedUrl &&
              <StyledButton
                iconButton
                tooltip="Download log"
                sx={{ p: 0 }}>
                <Link sx={{ ml: 1 }} href={log?.downloadableSignedUrl}>
                  <ImageWrapper src={DownloadIcon} />
                </Link>
              </StyledButton>

            }
          </Box>
          }>
          <ItemView
            data={{ ...log, category: "SystemLog" }}
            headers={{
              date: { label: "date" },
              status: { label: "Status" },
            }}
          />
        </CustomAccordion>
      }
      {viewLog &&
        <Box mt={1}>
          <iframe
            src={`${log?.viewableSignedUrl}#zoom=100`}
            title="testPdf"
            width="100%"
            height="250px"
            style={{ borderRadius: "10px", border: "1px solid #EAEAEA" }}
          />
        </Box>
      }

    </Box >
  )
};

SystemLog.propTypes = {};

SystemLog.defaultProps = {};

export default SystemLog;
