/***
 *
 * Controller class for user.
 * @file PermissionManagement.js
 * @description PermissionManagement component
 * @author Shubham Singh
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect, useState } from 'react';
import CustomTable from '../../components/CustomTable';
import PageHeader from '../../components/PageHeader';
import { roleActions } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '../../hooks';
// import PropTypes from 'prop-types';
import './PermissionManagement.scss';
import RoleSettings from '../RoleSettings';
import { history, isAuthorised } from '../../helpers';
import { TableHelper } from '../../components/TableHelper';
import userPermission from '../../assets/icons/user-permission.svg'
import _ from 'lodash';
import { features, permissions } from '../../constants';
import DataNotFound from '../DataNotFound';
import StyledButton from '../../components/StyledButton';

const PermissionManagement = () => {
  const query = useQuery();
  const permissionName = query.get("name") || null;
  const [roles, setRoles] = useState([]);
  const dispatch = useDispatch();
  const rolesData = useSelector((state) => state?.roles?.list);
  const _portal = useSelector((state) => state?.app?.portal);

  useEffect(() => {
    if (permissionName === null) {
      dispatch(roleActions?.getAllRoles(_portal));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, permissionName]);

  const goTo = (route) => {
    history.push(route);
  };
  
  useEffect(() => {
    if(rolesData) {
      let data = [];
      
      rolesData.forEach((item) => {
        let entities = [];
        
        if(item.hasOwnProperty('idp')) {
          entities.push('IDP')
        }
        if(item.hasOwnProperty('app')) {
          entities.push('APP')
        }
        data.push({name: item.name, entities: entities});
      })

      setRoles(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolesData]);


  return (
    <>
      {!permissionName &&
        <PageHeader pageHeaderStyle={{ padding: '8px 0px 8px 8px' }}  backbtn={false} title={"Permissions"} icon={userPermission}>
          {(isAuthorised(features?.role, permissions?.add, _portal) && roles.length > 0) && <StyledButton onClick={event => goTo("/user-management/permissions?page=create&name=create")}>Create Permission</StyledButton>}
        </PageHeader> 
      }
      <DataNotFound
        show={ (roles.length > 0 || permissionName) ? false : true }
        hideLoader={permissionName ? true : false}
        data={{ 
          label: "No Data Found",
          content: "No permissions found.",
          action: isAuthorised(features?.role, permissions?.edit, _portal) ? [
            {
              id: 1,
              type: "button",
              label: "Create Permission",
              onClick: () => {goTo("/user-management/permissions?page=create&name=create")},
            }
          ] : []
        }}
        style={{minHeight: "350px"}}
      
      >
        { permissionName 
          ? <RoleSettings />
          : (
            <CustomTable 
              headerList={{
                name: {
                  label: 'USER Permission', 
                  type: "link", 
                  toolTip: {
                    type: "component",
                    content: TableHelper.PermissionsTooltipContent
                  }, 
                  url: "/user-management/permissions?page=view", 
                  urlParams: { name: "name" }
                },
                entities: {
                  label: 'Permission Entities',
                  type: 'component',
                  component: ((row) => {
                    if(_.isEmpty(row?.data?.entities)) {
                      return "-";
                    }
                    else {
                      return row?.data?.entities.join(" | ")
                    }
                  })
                }
              }} 
              cellMatrix={roles} 
              searching={{columns: ["name"]}} 
              pagination={true} 
              sorting={{ column: "name", order: "asc" }}
            />
          )
        }
      </DataNotFound>
    </>
  )
};

PermissionManagement.propTypes = {};

PermissionManagement.defaultProps = {};

export default PermissionManagement;
