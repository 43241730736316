import { tableSort } from '../../services';
import { spaceDataTypes } from '../types';

let initialState = { 
    loading: false,
    list: [],
    item: null,
    error: null,
    spaceType: null
}

export function spaceDTypes(state = initialState, action) {
    switch (action.type) {
        case spaceDataTypes.GET_REQUEST:
        case spaceDataTypes.ADD_REQUEST:
        case spaceDataTypes.UPDATE_REQUEST:
        case spaceDataTypes.GET_ALL_REQUEST:
        case spaceDataTypes.DELETE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case spaceDataTypes.GET_ALL_SUCCESS:
            // let l = action.payload;
            // l.sort((a,b)=>{
            //     let spaceTypeA = a.spaceType;
            //     let spaceTypeB = b.spaceType
            //     return spaceTypeA.localeCompare(spaceTypeB, 'en', { sensitivity: 'base' })
            // });
            let l= tableSort(action.payload, {order:"asc", orderBy:"spaceType"});
            return {
                ...state,
                list: [...l],
                loading: false
            };
        case spaceDataTypes.ADD_SUCCESS:
            state?.list.push(action.payload);
            // state?.list.sort((a,b)=>{
            //     let spaceTypeA = a.spaceType;
            //     let spaceTypeB = b.spaceType
            //     return spaceTypeA.localeCompare(spaceTypeB, 'en', { sensitivity: 'base' })
            // });
            let lis = tableSort(state?.list, {order:"asc", orderBy:"spaceType"});
            return {
                ...state,
                spaceType: action.payload,
                list: [...lis],
                loading: false
            };
        case spaceDataTypes.UPDATE_SUCCESS:
            let updatedList = state?.list?.map(s=>{if(s.spaceType === action.spaceType){return action.payload;} else { return s; }}) || [];
            // updatedList.sort((a,b)=>{
            //     let spaceTypeA = a.spaceType;
            //     let spaceTypeB = b.spaceType
            //     return spaceTypeA.localeCompare(spaceTypeB, 'en', { sensitivity: 'base' })
            // });
            let li = tableSort(updatedList, {order:"asc", orderBy:"spaceType"});
            return {
                ...state,
                spaceType: action.payload,
                list: [...li],
                loading: false
            };
        case spaceDataTypes.GET_SUCCESS: //Not used
            return {
                ...state,
                list: state?.list && Array.isArray(state?.list) ? state?.list?.map(item=>item.id === action.payload.id?action.payload:item) : [],
                item: action.payload,
                loading: false
            };
        case spaceDataTypes.DELETE_SUCCESS:
            return {
                ...state,
                list: state?.list?.filter(item => item.spaceType !== action.payload.spaceType) || [],
                loading: false
            };
        case spaceDataTypes.GET_FAILURE:
        case spaceDataTypes.ADD_FAILURE:
        case spaceDataTypes.UPDATE_FAILURE:
        case spaceDataTypes.GET_ALL_FAILURE:
        case spaceDataTypes.DELETE_FAILURE:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return state
    }
}