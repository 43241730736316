import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    RuleAddEdit: {
        '& .CreateRuleBtn': {
            minWidth: "90px",
            maxWidth: "90px",

            '@media (max-width: 500px)': {
                minWidth: "48px",
                maxWidth: "48px",
            }
        },
        "& .deleteButton": {
            filter: "invert(12%) sepia(80%) saturate(5694%) hue-rotate(24deg) brightness(98%) contrast(102%) !important"
        }
    },

    CreateRuleStepper: {
        marginTop: '2rem',
    },

    CreateRuleButtonWrapper: {
        maxWidth: '100%',
        textAlign: 'right',
        marginTop: '1rem'
    },
});
