// /***
//  *
//  * Controller class for user.
//  * @file SideBar.js
//  * @description SideBar component
//  * @author Naveen Kumar
//  * @since 12 Oct 2021
//  * 
//  */


import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import "./SideBar.scss";
import {useStyles} from "./SideBar.style.js";
import LeftNav from '../LeftNav';
import TopNavigation from '../TopNavigation';
import { useEffect } from 'react';
import { drawerWidth } from '../../layouts/MainLayout';
import { appActions } from '../../redux/actions';
import { useDispatch } from 'react-redux';


const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9.25)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  minHeight: "50px",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({    
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const SideBar = ({ children, treeNode=null, expanded=false, routes=[], menuComp=null, onExpand=()=>{} }) => {
  const [open, setOpen] = useState(expanded);
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleDrawerOpen = () => {
    setOpen(true);
    onExpand(true);
    dispatch(appActions.SideBarOpen(true));
  };

  const handleDrawerClose = () => {
    setOpen(false);
    onExpand(false);
    dispatch(appActions.SideBarOpen(false));
  };

  useEffect(()=> {
    setOpen(expanded);
  }, [expanded])

  return (
      <Box sx={{ display: 'flex', overflow: "auto" }}>
        <CssBaseline />
        <Box className={classes?.SideBar || ""} sx={{transition: ".28s", '& .MuiPaper-root': {marginTop: "84px"}}}>
        {/* <Box className={classes?.SideBar || ""} sx={{transition: ".28s", '& .MuiPaper-root': {marginTop: selectedSpace?.spaceId?"84px":"60px"}}}> */}
          <AppBar position="fixed" elevation={0} open={open} sx={{transition: "0.4s"}}>
            <Toolbar>
              <IconButton
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className="icon-button"
                sx={{
                  ...(open && { display: 'none' }),
                }}
              >
                <MenuIcon />
              </IconButton>
              <TopNavigation open={open} routes={routes} />
            </Toolbar>
          </AppBar>
          <Drawer variant="permanent" open={open}>
            <DrawerHeader style={{
              minHeight: "50px",
              display: "inline-flex",
              justifyContent: "start",
              lineHeight: "50px",
              marginLeft: "9px"
            }}>
              <IconButton onClick={handleDrawerClose}>
                {/* {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />} */}
                <MenuIcon />                
              </IconButton>
              <Typography variant="caption" sx={{ fontSize: '1rem', lineHeight: "20px", display: "inline-block", fontWeight: 600}}>Menu</Typography>
            </DrawerHeader>
            <Divider />
            <LeftNav open={open} treeNode={treeNode} menuComp={menuComp} routes={routes} />
          </Drawer>
        </Box>
        <Box component="main" sx={{ flexGrow: 1, p: 1.2, mt: 9.7 }}>
        {/* <Box component="main" sx={{ flexGrow: 1, p: 1.2, mt: selectedSpace?.spaceId? 9.7 : 6.3 }}> */}
          {/* <DrawerHeader /> */}
          { children }
        </Box>
      </Box>
  );
}

SideBar.propTypes = {};

SideBar.defaultProps = {};

export default SideBar;