const pagination = (data = [], itemsPerPage, currentPage, setCurrentPage) => {
  const cPage = (currentPage > 1) ? currentPage : 1;
  const maxPage = Math.ceil(data?.length / itemsPerPage);

  function currentData() {
    const begin = (cPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    console.log("SEARCH 3.5", data, itemsPerPage, cPage, begin, end)
    return data ? data?.slice(begin, end) : [];
  }

  function next() {
    setCurrentPage(cPage => Math.min(cPage + 1, maxPage));
  }

  function prev() {
    setCurrentPage(cPage => Math.max(cPage - 1, 1));
  }

  function jump(page) {
    const pageNumber = Math.max(1, page);
    setCurrentPage(cPage => Math.min(pageNumber, maxPage));
  }

  return {
    currentData, next, prev, jump
  }
}

export const tableHelper = {
  pagination
}