export const reportType = {
    GET_REPORT_REQUEST: 'GET_REPORT_REQUEST',
    GET_REPORT_SUCCESS: 'GET_REPORT_SUCCESS',
    GET_REPORT_FAILURE: 'GET_REPORT_FAILURE',
    UPDATE_REPORT_REQUEST: 'UPDATE_REPORT_REQUEST',
    UPDATE_REPORT_SUCCESS: 'UPDATE_REPORT_SUCCESS',
    UPDATE_REPORT_FAILURE: 'UPDATE_REPORT_FAILURE',
    GET_ALL_REPORTS_REQUEST: 'GET_ALL_REPORTS_REQUEST',
    GET_ALL_REPORTS_SUCCESS: 'GET_ALL_REPORTS_SUCCESS',
    GET_ALL_REPORTS_FAILURE: 'GET_ALL_REPORTS_FAILURE',
    GET_ALL_REPORT_DOCUMENTS_REQUEST: 'GET_ALL_REPORT_DOCUMENTS_REQUEST',
    GET_ALL_REPORT_DOCUMENTS_SUCCESS: 'GET_ALL_REPORT_DOCUMENTS_SUCCESS',
    GET_ALL_REPORT_DOCUMENTS_FAILURE: 'GET_ALL_REPORT_DOCUMENTS_FAILURE',
    CREATE_REPORTS_REQUEST: 'CREATE_REPORTS_REQUEST',
    CREATE_REPORTS_SUCCESS: 'CREATE_REPORTS_SUCCESS',
    CREATE_REPORTS_FAILURE: 'CREATE_REPORTS_FAILURE',
    DELETE_REPORTS_REQUEST: 'DELETE_REPORTS_REQUEST',
    DELETE_REPORTS_SUCCESS: 'DELETE_REPORTS_REQUEST',
    DELETE_REPORTS_FAILURE: 'DELETE_REPORTS_FAILURE',
    DELETE_DOCUMENT_REPORTS_REQUEST: 'DELETE_DOCUMENT_REPORTS_REQUEST',
    DELETE_DOCUMENT_REPORTS_SUCCESS: 'DELETE_DOCUMENT_REPORTS_REQUEST',
    DELETE_DOCUMENT_REPORTS_FAILURE: 'DELETE_DOCUMENT_REPORTS_FAILURE',
    SHARE_REPORT_DOCUMENTS_REQUEST: "SHARE_REPORT_DOCUMENTS_REQUEST",
    SHARE_REPORT_DOCUMENTS_SUCCESS:"SHARE_REPORT_DOCUMENTS_SUCCESS",
    SHARE_REPORT_DOCUMENTS_FAILURE:"SHARE_REPORT_DOCUMENTS_FAILURE",
    GENERATE_REPORT_REQUEST: "GENERATE_REPORT_REQUEST",
    GENERATE_REPORT_SUCCESS:"GENERATE_REPORT_SUCCESS",
    GENERATE_REPORT_FAILURE:"GENERATE_REPORT_FAILURE",
    DOWNLOAD_REPORT_REQUEST:"DOWNLOAD_REPORT_REQUEST",
    DOWNLOAD_REPORT_SUCCESS:"DOWNLOAD_REPORT_SUCCESS",
    DOWNLOAD_REPORT_FAILURE:"DOWNLOAD_REPORT_FAILURE",
    CLEAR_GENERATE_REPORT_REQUEST:"CLEAR_GENERATE_REPORT_REQUEST",
    CLEAR_GENERATE_REPORT_SUCCESS:"CLEAR_GENERATE_REPORT_SUCCESS",
    CLEAR_GENERATE_REPORT_FAILURE:"CLEAR_GENERATE_REPORT_FAILURE"
}