export const dragAndDropTypes = {
    SET_DRAG_LIST: 'SET_DRAG_LIST',
    SET_DROP_LIST: 'SET_DROP_LIST',

    DELETE_FROM_DROP_LIST: 'DELETE_FROM_DROP_LIST',
    ADD_TO_DROP_LIST: 'ADD_TO_DROP_LIST',

    SELECTED_ITEMS: 'SELECTED_ITEMS',
    CLEAR_DROP_LIST: 'CLEAR_DROP_LIST',

    // FROM_DRAG_TO_DROP: 'FROM_DRAG_TO_DROP',
    // FROM_DROP_TO_DRAG: 'FROM_DROP_TO_DRAG',

    EMPTY_LISTS: 'EMPTY_LISTS',

    SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE'
}