/***
 *
 * Controller class for user.
 * @file CreateAreas.js
 * @description CreateAreas component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import './CreateAreas.scss';
import PageHeader from '../PageHeader';
import { useQuery } from '../../hooks';
import CustomTypography from '../CustomTypography';
import { Stack, Grid, Box } from '@mui/material';
import ImageWrapper from '../ImageWrapper';
import StyledTextfield from '../StyledTextfield';
import CustomStepper from '../../components/CustomStepper';
import { useSelector, useDispatch } from 'react-redux';
import { areaActions, deviceActions, dragAndDropActions } from '../../redux/actions';
import StyledSnackBar from '../StyledSnackBar';
import AreaConfirmation from '../AreaConfirmation';
import { getMessageStr, history } from '../../helpers';
import AreaDragDropWrapper from '../AreaDragDropWrapper';
import areaImg from '../../assets/icons/area.svg';
import editIcon from '../../assets/icons/edit-2.svg';
import Device from '../../assets/icons/bulb.svg'
import StyledButton from '../StyledButton';

const CreateAreas = ({ areaOperation }) => {
  const readOnly = true;
  const steps = [{ label: "ADD DEVICES" }, { label: "CONFIRMATION" }]
  const query = useQuery();
  const [controllerId] = useState(query.get("deviceId") || null)
  const [controllerName] = useState(query.get("name") || null) //get through redux if deemed fit
  const area = useSelector(state => state?.areas?.item)
  const [enableEdit, setEnableEdit] = useState(areaOperation === 'edit')
  const [areaName, setAreaName] = useState(areaOperation === 'edit' ? area?.name : '')
  const [errorMsg, setErrorMsg] = useState('')
  const [stepCount, setStepCount] = useState(1);
  let currentSpaceId = useSelector((state) => { return state?.spaces?.selectedSpace?.spaceId });
  const areaId = useSelector(state => state?.areas?.item?.areaId)
  const gatewayId = useSelector((state) => state?.devices?.deviceDetails?.gwDeviceId); //Assuming we are coming through controller details we can directly get gatewayId from controller details
  const [snackBar, setSnackBar] = useState({ show: false, type: 'error', message: 'Area Name is Required' }); // WGJGBM< area-formValidation-areaNameEmpty
  const dropList = useSelector(state => state?.dragAndDrops?.dropList);
  const [ifMovedToNextStep, setIfMovedToNextStep] = useState(false)

  const dispatch = useDispatch();

  useEffect(() => {
    //API calls for groups and sensor
    if (controllerId !== '') {
      dispatch(deviceActions.getAllChildren(controllerId, "devices", { depth: 0, category: 'Sensor' }))//API for getting sensor
      //API for getting groups can be added here if needed - getting groups by controller id 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controllerId])

  useEffect(() => {
    if (areaOperation === 'edit') {
      setEnableEdit(true);
    }
  }, [areaOperation])

  // useEffect(() => {
  //   //Make left and right list
  //   let sensors = sensorList.map(item => {
  //     item.id = item.deviceId
  //     item.type = 'SENSOR'
  //     item.icon = Sensor
  //     return item
  //   })
  //   let groups = groupList.map( item => {
  //     item.id = item.groupId
  //     item.type = 'GROUP'
  //     item.icon = Group
  //     return item
  //   })
  //   if(areaOperation==='edit'){
  //     if(!_.isEmpty(area.sensorIds)){
  //       sensors = sensorList.reduce((final, current) => {
  //           final[area.sensorIds.includes(current?.deviceId) ? 'sensorListRight' : 'sensorListLeft'].push(current);
  //           return final;
  //         }, { sensorListLeft: [], sensorListRight: [] });
  //     }
  //     if(!_.isEmpty(area.groupIds)){
  //       groups = groupList.reduce((final, current) => {
  //         final[area.groupIds.includes(current?.groupId) ? 'groupListRight' : 'groupListLeft'].push(current);
  //         return final;
  //       }, { groupListLeft: [], groupListRight: [] });
  //     }
  //     let combinedListLeft = [...sensors.sensorListLeft, ...groups.groupListLeft]
  //     dispatch(dragAndDropActions.setDragList(combinedListLeft))
  //     setAvailableItems(combinedListLeft)
  //     let combinedListRight = [...sensors.sensorListRight, ...groups.groupListRight]
  //     dispatch(dragAndDropActions.setDropList(combinedListRight))
  //     setSelectedItems(combinedListRight)
  //   }else{
  //     let combinedList = [...sensors,...groups]
  //     dispatch(dragAndDropActions.setDragList(combinedList))
  //     setAvailableItems(combinedList)
  //   }
  // }, [area, groupList, sensorList])

  const closeSnackBar = () => {
    setSnackBar({
      show: false,
      type: 'error',
      message: 'Area Name is Required' // WGJGBM< area-formValidation-areaNameEmpty
    });
  };

  const checkValidName = (name) => {
    if (name.length > 2 && name.length < 30 && /^\d*[a-zA-Z][a-zA-Z0-9, #,-]*$/.test(name)) {
      return true;
    }
    return false;
  }

  const checkGroupSensorsPresent = () => {
    let groupPresent = false;
    let sensorPresent = false;
    dropList.forEach(item => {
      if (item?.groupId !== undefined) groupPresent = true;
      if (item?.deviceId !== undefined) sensorPresent = true;
    })
    let notPresent = '';
    let notPresentList = [];
    if (!groupPresent) {
      notPresentList.push('group');
    }
    if (!sensorPresent) {
      notPresentList.push('sensor')
    }
    notPresent = notPresentList.join(' & ');
    return notPresent;
  }

  const handleChange = (e) => {
    if (checkValidName(e.target.value)) {
      setErrorMsg(''); //WGJGBM area-emptyMessage
    } else {
      setErrorMsg(getMessageStr('area-formValidation-areaNameInvalid')); //WGJGBM area-formValidation-areaNameInvalid
    }
    setAreaName(e.target.value);
  }

  const settingAndSendingPayload = () => {
    let result = dropList.reduce((final, current) => {
      let id = current?.deviceId !== undefined ? current.deviceId : current.groupId
      final[current?.deviceId !== undefined ? 'sensorIds' : 'groupIds'].push(id);
      return final;
    }, { sensorIds: [], groupIds: [] }); //This is using selectedItems to have two parts one having sensorIds and other having groupIds
    let payload = {
      name: areaName,
      sensorIds: result.sensorIds,
      groupIds: result.groupIds
    }
    if (areaOperation === 'create') {
      payload.controllerId = controllerId
      payload.spaceId = currentSpaceId
      dispatch(areaActions.createArea(gatewayId, payload, () => {
        history.goBack();
      }));
    }
    else {
      dispatch(areaActions.editArea(areaId, payload, () => {
        history.goBack();
      }));
    }


  }

  const handleBackStep = () => {
    if (stepCount === 1) {
      history.goBack();
    }
    setStepCount(1);
  };

  const handleNextStep = () => {
    if (stepCount === 1) {
      let sensorOrGroupNotPresent = checkGroupSensorsPresent();
      if (areaName === '') {
        // console.log(getMessageStr('area-formValidation-areaNameEmpty'), 'areaname');
        setErrorMsg(getMessageStr('area-formValidation-areaNameEmpty')); //WGJGBM area-formValidation-areaNameEmpty
      } else if (dropList.length === 0) {
        dispatch(dragAndDropActions.setErrorMessage(getMessageStr('area-add-noSensorsGroupSelected'))); //WGJGBM
      } else if (sensorOrGroupNotPresent.length !== 0) {
        let messIndex = 'area-add-noSensorsGroupSelected';
        if (sensorOrGroupNotPresent === 'group') {
          messIndex = 'area-add-noGroupSelected';
        }
        else if (sensorOrGroupNotPresent === 'sensor') {
          messIndex = 'area-add-noSensorSelected';
        }
        dispatch(dragAndDropActions.setErrorMessage(getMessageStr(messIndex))); //WGJGBM area-add-noSensorSelected area-add-noGroupSelected
      } else {
        setEnableEdit(true);
        setIfMovedToNextStep(true);
        dispatch(dragAndDropActions.setErrorMessage(''));
        setStepCount(2);
      }
    } else {
      settingAndSendingPayload();
    }

  };

  const currentPage = (steps) => {
    switch (steps) {
      case 2:
        return <AreaConfirmation selectedItems={dropList} />
      default:
        return <AreaDragDropWrapper ifEdit={areaOperation === 'edit'} ifMovedToNextStep={ifMovedToNextStep} />
    }
  }

  return (
    <div className="CreateAreas" data-testid="CreateAreas">
      <PageHeader
        bckbtn={true}
        backfunc={() => { history.goBack() }}
        title={controllerName}
        icon={Device}
        breadcrumbs={{ device: true }}
      />
      <Stack direction={'row'}>
        <ImageWrapper src={areaImg} style={{ filter: "invert(37%) sepia(14%) saturate(5108%) hue-rotate(112deg) brightness(99%) contrast(88%)" }} />
        <CustomTypography content={areaOperation === 'edit' ? 'Edit Area' : 'Create Area'} variant={'h5'} sx={{ ml: 1, lineHeight: '22px' }} fontSize={'18px'} weight={'bold'} />
      </Stack>
      <Grid className="form-group" container alignItems={'baseline'} sx={{ mt: '10px' }}>
        <label item>Area Name<label style={{ color: "#BC2200" }}>*</label> : </label>
        {enableEdit ? <label disabled={readOnly} style={{ marginLeft: "10px" }}>{areaName}<ImageWrapper item style={{ marginRight: '5px', marginLeft: '10px' }} onClick={() => { setEnableEdit(false) }} src={editIcon} /></label> :
          <StyledTextfield item color={errorMsg !== '' ? 'error' : ""} helperText={errorMsg} sx={{ minWidth: '120px', pb: '20px' }} error={errorMsg !== '' ? true : false} value={areaName} style={{ marginLeft: "10px" }} type="text" name="areaName" className="" onChange={handleChange} />
        }
      </Grid>
      <Box width={'100%'} borderRadius="10px">
        <CustomStepper labels={steps.map(item => item.label)} activeStep={stepCount} />
      </Box>
      <Box width={'100%'} sx={{ py: 1 }}>
        {currentPage(stepCount)}
      </Box>
      <Box sx={{ width: "100%", textAlign: "right" }}>
        <StyledButton
          variant="outlined"
          sx={{ ml: 1, minWidth: "90px" }}
          onClick={handleBackStep}>
          {stepCount === 2 ? "Back" : "Cancel"}
        </StyledButton>
        <StyledButton
          sx={{ ml: 1, minWidth: "90px" }}
          onClick={handleNextStep}>
          {stepCount === 2 ? "Save" : "Next"}
        </StyledButton>
      </Box>
      <StyledSnackBar
        open={snackBar?.show}
        onClose={closeSnackBar}
        autoHideDuration={5000}
        data={{
          type: snackBar?.type,
          message: snackBar?.message,
        }}
      />
    </div>
  )
};

CreateAreas.propTypes = {};

CreateAreas.defaultProps = {};

export default CreateAreas;
