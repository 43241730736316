/***
 *
 * Controller class for user.
 * @file TicketsList.js
 * @description TicketsList component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import './TicketsList.scss';
import React, { useState, useEffect } from 'react';
import { Box, Avatar } from "@mui/material";
import { useStyles } from './TicketsList.style';
import _ from 'lodash';
import CustomTable from '../CustomTable';
import PageHeader from '../PageHeader';
import CustomAccordion from '../CustomAccordion';
import { ReactComponent as TicketFilters } from '../../assets/icons/ticket-filter.svg';
import { ReactComponent as Critical } from '../../assets/icons/critical-2.svg';
import { ReactComponent as Major } from '../../assets/icons/major-2.svg';
import { ReactComponent as Minor } from '../../assets/icons/minor-2.svg';
import OpenTicketsIcon from '../../assets/icons/all-tickets.svg';
import MyTickets from '../../assets/icons/my-tickets.svg';
import { formatedDate, history, isAuthorised } from '../../helpers';
import StyledTabs from '../StyledTabs';
import { useDispatch, useSelector } from "react-redux";
import { ticketingActions } from '../../redux/actions';
import * as XLSX from 'xlsx-js-style';
import FormWrapper from '../FormWrapper';
import { Link } from 'react-router-dom';
import DataNotFound from '../../pages/DataNotFound';
import TicketIcon from '../../assets/icons/ticketing.svg';
import { WEB_PORTAL_URL } from '../../config/appConfig';
import { features, permissions } from '../../constants';
import StyledButton from '../StyledButton';
import { stringAvatar } from "../../helpers/users.helper.js";
import AddIcon from "../../assets/icons/add.svg";
import DownloadDocument from "../../assets/icons/download.svg";
import ImageWrapper from '../ImageWrapper';
import CustomTypography from '../CustomTypography';

const TicketsList = () => {
  const [ticketsData, setTicketsData] = useState([]);
  const dispatch = useDispatch();
  const classes = useStyles();
  const tickets = useSelector((state) => state?.ticketing?.ticketList)
  const assigneeData = useSelector(state => state?.ticketing?.assigneeData);
  const statistics = useSelector(state => state?.ticketing?.statistics);
  const userProfile = useSelector(state => state?.users?.userSelfProfile);
  const severities = useSelector(state => state?.ticketing?.severities);
  const states = useSelector(state => state?.ticketing?.states);
  const [filterFrm, setFilterFrm] = useState({
    severities: [],
    states: [],
    createdDateStart: null,
    createdDateEnd: null,
    createdBy: null,
    assignedTo: null,
    severity: [],
    state: []
  });
  const [formData, setFormData] = useState({});
  const [filterChange, setFilterChange] = useState(false)
  const [ind, setInd] = useState({ val: 0, count: 0 })

  const [frmConfig, setFrmConfig] = useState([
    {
      section: { heading: null },
      columns: [
        {
          id: "1",
          type: "select",
          name: "createdBy",
          label: "Created By",
          columnSize: 3
        },
        {
          id: "6",
          type: "select",
          name: "assignedTo",
          label: "Assigned To",
          columnSize: 3
        },
        {
          id: "2",
          type: "datetimepicker",
          name: "createdDateStart",
          label: "From Date",
          placeholder: "Title",
          disableFuture: true,
          columnSize: 3,

        },
        {
          id: "3",
          type: "datetimepicker",
          name: "createdDateEnd",
          label: "To Date",
          placeholder: "Title",
          disableFuture: true,
          columnSize: 3,
          disabled: true,
        },
        {
          id: "4",
          type: "multiselect",
          label: "State",
          name: "state",
          options: [],
          columnSize: 3,
        },
        {
          id: "5",
          type: "multiselect",
          label: "Severity",
          name: "severity",
          options: [],
          columnSize: 3,

        },
      ],
    },
  ]);

  const [frmOptions, setFrmOptions] = useState(null);
  const [frm, setFrm] = useState(null);

  const [tabs, setTabs] = useState([
    { icon: MyTickets, label: `My Tickets (${0})` },
    { icon: OpenTicketsIcon, label: `Open Tickets (${0})` },
    { icon: OpenTicketsIcon, label: `All Tickets (${0})` },
  ])

  const defaultFilters = () => {
    let frmdata = {
      ...filterFrm,
      createdBy: "",
      createdDateStart: null,
      createdDateEnd: null,
      state: "",
      severity: "",
      assignedTo: ""
    }
    setFormData(frmdata)

    let column = frmConfig[0]?.columns
    const newColumn = column.filter((item) => item.name !== "createdDateEnd");
    newColumn.splice(3, 0, {
      id: "3",
      type: "datetimepicker",
      name: "createdDateEnd",
      label: "To Date",
      placeholder: "Title",
      disableFuture: true,
      columnSize: 3,
      disabled: true,
      minDate: frm?.createdDateStart
    });
    setFrmConfig([{ ...frmConfig, columns: newColumn }])
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const downloadExcel = (tableData) => {
    let data = JSON.parse(JSON.stringify(tableData))
    data.forEach(d => {
      var map = {
        synopsis: "Synopsis",
        state: "State",
        createdByUserName: "Created_By_Username",
        severity: "Severity",
        lastModifiedByUserName: "Last_Modified_By",
        assignedTo: "Assigned_To",
        Created_Att: "Created_At",
        Last_Modified_Att: "Last_Modified_At"
      }

      _.each(d, function (value, key) {
        key = map[key] || key;
        d[key] = value;
      });

      delete d['createdByUserId'];
      delete d['avatar'];
      delete d['lastModifiedByUserId'];
      delete d['createdByUserName']
      delete d['severity']
      delete d['createdAt']
      delete d['lastModifiedByUserName']
      delete d['state']
      delete d['assignedTo']
      delete d['lastModifiedAt']
      delete d['synopsis']
      delete d['assignedToUsername']
      delete d['rowId']
      delete d['Created_Att']
      delete d['Last_Modified_Att']

    });

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    for (var i = 0; i < data.length; i++) {
      worksheet[`A${i + 2}`].l = { Target: WEB_PORTAL_URL + `/tickets?op=details&ticketId=${data[i].TicketId}&synopsis=${data[i]?.Synopsis}`, Tooltip: "Go to Ticket" };
      worksheet[`A${i + 2}`].s = {
        font: { color: { rgb: '0000FF' } },
      };
    }
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `MSS-Tickets-${formatedDate({ date: new Date(), format: "ddMMMyyyy-hhmma", })}.xlsx`);

  };

  useEffect(() => {
    if (tickets) {
      if (tickets?.records && tickets.records.length > 0) {
        let manupulatedTickets = tickets.records.map((ticket) => {
          return {
            ...ticket,
            severity: ticket && ticket.severity?.charAt(0).toUpperCase() + ticket.severity?.slice(1),
            createdAt: ticket && ticket.createdAt ? ticket.createdAt : "-",
            state: ticket && ticket.state?.charAt(0).toUpperCase() + ticket.state?.slice(1),
            assignedTo: ticket && ticket?.assignedToUsername ? ticket?.assignedToUsername : "-",
            lastModifiedAt: ticket && ticket?.lastModifiedAt ? ticket?.lastModifiedAt : "-",
            Last_Modified_Att: ticket && ticket?.lastModifiedAt ? formatedDate({ date: ticket?.lastModifiedAt, format: "MMM d, yyyy" }) : "-",
            Created_Att: ticket && ticket.createdAt ? formatedDate({ date: ticket.createdAt, format: "MMM d, yyyy" }) : "-",
            "avatar": ticket?.assignedToUsername ? <Avatar className='ticketAvatar' {...stringAvatar(`${ticket?.assignedToUsername ? ticket.assignedToUsername : null}`)} /> : "",
          }
        })
        setTicketsData(manupulatedTickets)
      } else {
        setTicketsData([])
      }

      let opt = {}
      opt["dropdowns"] = {
        severity: {
          list: filterFrm?.severities
        },
        state: {
          list: filterFrm?.states
        },
        createdBy: {
          list: assigneeData?.map((assignee) => { return { id: assignee?.id, label: assignee?.name } })
        },
        assignedTo: {
          list: assigneeData?.map((assignee) => { return { id: assignee?.id, label: assignee?.name } })
        }
      };
      setFrmOptions(opt);
      setFilterChange(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tickets])

  useEffect(() => {
    if (statistics) {
      setTabs([
        { icon: MyTickets, label: `My Tickets (${statistics ? statistics.myTickets ? statistics.myTickets : 0 : 0})` },
        { icon: OpenTicketsIcon, label: `Open Tickets (${statistics ? statistics.open ? statistics.open : 0 : 0})` },
        { icon: OpenTicketsIcon, label: `All Tickets (${statistics ? statistics.all ? statistics.all : 0 : 0})` },
      ])
      setInd({ val: 0, count: statistics?.myTickets })
    }
  }, [statistics])

  const goTo = (route) => {
    history.push(route);
  };

  const handleStyleTabs = (e, ind) => {
    let data = {}

    if (ind === 2) {
      data = {
        ...filterFrm,
        state: [],
        states: states?.map((state) => { return capitalizeFirstLetter(state) }),
        assignedTo: null,
      }
      setInd({ val: ind, count: statistics?.all })
    }

    if (ind === 1) {
      data = {
        ...filterFrm, state: "open",
        states: ["Open"],
        assignedTo: null
      }
      setInd({ val: ind, count: statistics?.open })
    }

    if (ind === 0) {
      data = {
        ...filterFrm, state: [],
        states: states?.map((state) => { return capitalizeFirstLetter(state) }),
        assignedTo: userProfile ? userProfile?.userId : ""
      }
      setInd({ val: ind, count: statistics?.myTickets })
    }

    setFilterFrm(data)
    defaultFilters()
    getTickets(data)
  }

  const handleSubmit = (event, data) => {
    let selectedState, selectedSeverity;

    if (ind && ind?.val === 0) {
      data = {
        ...data,
        assignedTo: userProfile ? userProfile?.userId : ""
      }
    }

    if (ind && ind?.val === 1) {
      selectedState = ["open"]
    } else {
      if (data && data.state && data.state.length > 0) {
        selectedState = data?.state?.map((st) => {
          return st.toLowerCase()
        })
      }
    }

    if (data && data.severity && data.severity.length > 0)
      selectedSeverity = data?.severity?.map((sev) => sev.toLowerCase())

    if (data?.createdDateStart && _.isEmpty(data?.createdDateEnd)) {
      return
    }

    const frmdata = {
      ...filterFrm,
      severity: selectedSeverity,
      state: selectedState,
      createdDateEnd: data?.createdDateEnd,
      createdDateStart: data?.createdDateStart,
      createdBy: data?.createdBy,
      assignedTo: data?.assignedTo
    };
    getTickets(frmdata);
  }

  const getTickets = (data = {}) => {
    let payload = {}
    if (data) {
      for (let key in data) {
        if (Array.isArray(data[key]) && data[key].length > 0) {
          if (key === "state" || key === "severity") {
            payload[key] = data[key].join(",");
          }
        } else {
          if (data[key]) {
            if (key === "createdDateStart" || key === "createdDateEnd") {
              payload[key] = new Date(data[key]?.$d?.toISOString())?.getTime()?.toString()?.substring(0, 10);
            } else {
              payload[key] = data[key];
            }
          }
        }
      }
    }

    dispatch(ticketingActions.getFilteredTickets(payload))
  }

  useEffect(() => {
    dispatch(ticketingActions.getStatistics())
    dispatch(ticketingActions.getAssignees())
    dispatch(ticketingActions.getTicketSeverities())
    dispatch(ticketingActions.getTicketStates())
  }, [dispatch])

  useEffect(() => {
    if (userProfile && userProfile?.userId)
      dispatch(ticketingActions.getFilteredTickets({ assignedTo: userProfile?.userId }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile])


  useEffect(() => {
    if (!_.isEmpty(states) && !_.isEmpty(severities)) {
      setFilterFrm({
        ...filterFrm,
        severities: severities?.map((severity) => { return capitalizeFirstLetter(severity) }),
        states: states?.map((state) => { return capitalizeFirstLetter(state) }),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [states, severities])

  useEffect(() => {
    if (!_.isEmpty(frm)) {
      if (frm && frm?.createdDateStart) {
        setFilterChange(false)

        if (frm?.createdDateEnd) { setFilterChange(true) }
        let column = frmConfig[0]?.columns
        const newColumn = column.filter((item) => item.name !== "createdDateEnd");
        newColumn.splice(3, 0, {
          id: "3",
          type: "datetimepicker",
          name: "createdDateEnd",
          label: "To Date",
          placeholder: "Title",
          disableFuture: true,
          columnSize: 3,
          disabled: false,
          minDate: frm?.createdDateStart
        });
        setFrmConfig([{ ...frmConfig, columns: newColumn }])
      } else if (frm && (frm?.createdBy || frm?.assignedTo || frm?.state || frm?.severity)) {
        setFilterChange(true)
      }
      setFrm(frm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frm])

  return (
    <Box className={classes?.TicketsList || ""} data-testid="TicketsList">
      <PageHeader icon={TicketIcon} title={`Tickets`}>
        {isAuthorised(features?.ticket, permissions?.add) && <StyledButton sx={{ mr: 1 }} onClick={() => goTo("/tickets?op=create")}>  <ImageWrapper className={classes?.btnIcon} height={16} src={AddIcon} />    <CustomTypography className={classes?.short} size={'12'} content="Create Ticket" /></StyledButton>}
        <StyledButton disabled={ticketsData.length > 0 ? false : true} onClick={() => { downloadExcel(ticketsData) }}><ImageWrapper className={classes?.btnIcon} height={16} src={DownloadDocument} /><CustomTypography className={classes?.short} size={'12'} content="Download List" /></StyledButton>
      </PageHeader>

      <Box sx={{ py: 1 }}>
        <StyledTabs tabs={tabs} onClick={(e, value) => {
          handleStyleTabs(e, value);
        }} />
      </Box>

      <>
        {ind?.count > 0 &&
          <Box sx={{ py: 1 }}>
            <CustomAccordion defaultState={false} title="Filters" LeftIcon={TicketFilters} bordered contentBg={"#FFFFFF"}>
              <FormWrapper
                formFields={frmConfig}
                formOptions={frmOptions}
                formData={formData}
                setPayload={setFrm}
              />
              <Box className='btnSet'>
                <StyledButton variant='outlined' sx={{ mr: 1 }} onClick={(e) => {
                  defaultFilters();
                  getTickets({
                    ...filterFrm,
                    createdBy: "",
                    createdDateStart: null,
                    createdDateEnd: null,
                    state: ind?.val === 1 ? "open" : "",
                    severity: "",
                    assignedTo: ind?.val === 0 ? userProfile ? userProfile?.userId : "" : ""
                  })
                }}>Cancel</StyledButton>
                <StyledButton color={filterChange ? "primary" : "secondary"} disabled={!filterChange} onClick={(e) => { handleSubmit(e, frm) }}> Apply</StyledButton>

              </Box>
            </CustomAccordion>
          </Box>
        }
        <Box sx={{ width: "100%" }}>

          <DataNotFound
            show={ticketsData && ticketsData.length > 0 ? false : true}
            customsx={{ image: { height: '202px', width: '100%' }, label: {}, content: {}, btns: {} }}
            data={{
              label: ind?.val === 0 ? "No tickets are assigned to you" : "There are no tickets available",
            }}
          >
            <CustomTable
              headerList={{
                TicketId: {
                  label: "Ticket",
                  type: "component",
                  component: (row) => {
                    return (
                      <Link className='ticketLink' to={`/tickets?op=details&ticketId=${row?.data?.TicketId}&synopsis=${row?.data?.synopsis}`} >
                        {row?.data?.TicketId} - {row?.data?.synopsis}
                      </Link>
                    )
                  }
                },
                state: { label: "State" },
                multiKeys: {
                  label: 'Assigned To',
                  avatar: 'avatar',
                  assignedTo: {},
                  sortKey: "assignedTo"
                },
                severity: {
                  label: 'Severity',
                  type: "component",
                  component: (row) => {
                    return (
                      <div>
                        {row?.data?.severity === 'Major' ? <Major /> : row?.data?.severity === 'Minor' ? <Minor /> : <Critical />}
                        {row?.data?.severity}
                      </div>
                    )
                  }
                },
                lastModifiedAt: { label: 'Last Updated On', type: "date" },
              }} cellMatrix={ticketsData} pagination={true} sorting={{ column: "createdAt", order: 'desc' }}>
            </CustomTable>
          </DataNotFound>

        </Box>
      </>

    </Box>
  )
};

TicketsList.propTypes = {};

TicketsList.defaultProps = {};

export default TicketsList;
