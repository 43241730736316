/***
 *
 * Controller class for user.
 * @file FirmwareList.js
 * @description FirmwareList component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './FirmwareList.style.js';
import './FirmwareList.scss';
import { Box, Grid } from '@mui/material';
import PageHeader from '../PageHeader/index.js';
import Listing from '../../assets/icons/list.svg'
import { useDispatch, useSelector } from 'react-redux';
import CustomLabel from '../CustomLabel/index.js';
import CustomDropDown from '../CustomDropDown/index.js';
import _ from 'lodash';
import StyledButton from '../StyledButton/index.js';
import { fotaActions } from '../../redux/actions/fota.actions.js';
import { deviceActions } from '../../redux/actions/index.js';
import { getMessageStr } from '../../helpers/message.helper.js';
import DataNotFound from '../../pages/DataNotFound/index.js';
import CustomTable from '../CustomTable/index.js';
import StyledTextfield from '../StyledTextfield/index.js';
import CustomTypography from '../CustomTypography/index.js';
import DeleteIcon from "../../assets/icons/delete.svg"
import StyledPopup from '../StyledPopup/index.js';
import { confirmationDialog } from '../../helpers/device.helper.js';
import { defaultTenantName } from '../../config/appConfig.js';


const tableHeaders = (dispatch, setDialog, setDialogContents) => {
  return {
    firmwareVersion: {
      label: "Firmware Version",
      style: { minWidth: 200 }
    },
    modelIdList: {
      label: "Model Numbers"
    },
    tagList: {
      label: "Tags"
    },
    userActions: {
      label: "Actions",
      actions: [
        {
          label: "Delete firmware",
          icon: DeleteIcon,
          type: "delete",
          onClick: (event, data) => {
            confirmationDialog("Delete Firmware", () => {
              dispatch(fotaActions?.deleteFirmware(data?.fwId,()=>{
                setDialog(false);
              }));
            }, "deleteFirmware", setDialog, setDialogContents, data?.firmwareVersion);
          },
        },
      ],
      style: { textAlign: "right" }
    }
  }
}

const FirmwareList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const deviceCategories = useSelector(state => state?.devices?.deviceCategories);
  const deviceModels = useSelector(state => state?.devices?.deviceModels);
  //remove_tenant_dropdown
  // const tenants = useSelector(state => state?.tenants?.list || []);
  const firmwareList = useSelector(state => state?.fota?.list);

  const [selection, setSelection] = useState({ tenant: "", model: "", category: "", tag: "" });
  const [error, setError] = useState({ category: null, gateway: null, model: null });
  const [dialog, setDialog] = useState(false);
  const [dialogContents, setDialogContents] = useState(null);
  const [table, setTable] = useState({ headers: tableHeaders(dispatch, setDialog, setDialogContents), data: null });

  const dropdowns = [
    //remove_tenant_dropdown
    // {
    //   label: "Select Tenant",
    //   name: "tenant",
    //   list: tenants?.map(t => { return { id: t?.tenantName, label: t?.tenantName } }),
    //   disabled: () => { return false }
    // },
    {
      label: "Select Category",
      name: "category",
      list: deviceCategories?.filter(c => c?.category?.toLowerCase()?.includes("gateway") || c?.category?.toLowerCase()?.includes("controller"))?.map(c => { return { id: c?.category, label: c?.label } }),
      disabled: () => {
        return false;
        //remove_tenant_dropdown
        // return selection?.tenant === "" 
      }
    },
    {
      label: "Select Model",
      name: "model",
      list: deviceModels?.filter(m => m?.category === selection?.category)?.map(m => { return { id: m?.modelId, label: m?.modelNumber } }),
      disabled: () => { return selection?.category === "" }
    },

  ]

  //remove_tenant_dropdown

  useEffect(() => {
    dispatch(deviceActions.getDeviceCategories(defaultTenantName));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (selection?.category !== ""
      //remove_tenant_dropdown
      // selection?.tenant !== ""
    ) {
      setTable({ ...table, data: [...firmwareList] })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [firmwareList])

  return (
    <Box className={classes?.FirmwareList || ""} data-testid="FirmwareList">
      <PageHeader
        backbtn={false}
        title={"Firmware Management"}
        icon={Listing}
      >
      </PageHeader>
      <Box className="searchContainer">
        <Grid container spacing={2} >
          {
            dropdowns?.map((d, ind) => {
              return <Grid item xs={12} key={ind} md={4} sx={{ minHeight: { xs: "103px", sm: "106px", md: "109px" } }}>
                <CustomLabel
                  required={true}
                  style={{ fontSize: "12px", marginBottom: "8px", fontWeight: 700 }}
                >
                  {d?.label}
                </CustomLabel>
                <CustomDropDown
                  name={d?.name}
                  error={error[d?.name] ? true : false}
                  message={error[d?.name]}
                  value={selection[d?.name]}
                  customsx={{ menuList: { maxHeight: "180px" } }}
                  isNoneAsItem={_.isEmpty(d?.list)}
                  list={d?.list}
                  disabled={d?.disabled()
                  }
                  onChange={(e) => {
                    setTable({ ...table, data: null })
                    setError({ ...error, [d?.name]: null });
                    //remove_tenant_dropdown
                    // if (d?.name === "tenant") {
                    //   dispatch(deviceActions.getDeviceModels(e?.target?.value));
                    //   dispatch(deviceActions.getDeviceCategories(e?.target?.value));
                    // }
                    setSelection({
                      ...selection, [d?.name]: e?.target?.value,
                      //remove_tenant_dropdown
                      // category: d?.name === "tenant" ? "" : d?.name === "category" ? e?.target?.value : selection?.category,
                      model: d?.name === "category" ? "" : d?.name === "model" ? e?.target?.value : selection?.model,
                    })
                  }}
                />
              </Grid>
            })
          }

          <Grid item xs={12} md={4} sx={{ display: "block" }}>
            <CustomLabel
              style={{ fontSize: "12px", marginBottom: "8px", fontWeight: 700 }}
            >
              {"Enter Tag"}
            </CustomLabel>
            <Box>
              <StyledTextfield
                sx={{ width: "100%" }}
                value={selection?.tag}
                onChange={(e) => {
                  setTable({ ...table, data: null })
                  setSelection({ ...selection, tag: e?.target?.value })
                }}
              />
            </Box>
          </Grid>
          <Grid className="searchButtonContainer" item xs={12}>
            <StyledButton
              onClick={(e) => {
                const err = {
                  //remove_tenant_dropdown
                  //  tenant: selection?.tenant === "" ? getMessageStr("fota-list-tenantRequired") : null,
                  category: selection?.category === "" ? getMessageStr("fota-list-categoryRequired") : null,
                  model: selection?.model === "" ? getMessageStr("fota-list-modelRequired") : null
                }
                setError(err);
                if (err?.category === null && err?.model === null
                  //remove_tenant_dropdown
                  // && err?.tenant === null
                ) {
                  dispatch(fotaActions?.getFirmwareList(
                    {
                      category: selection?.category,
                      modelId: selection?.model,
                      tag: selection?.tag
                    },
                    //remove_tenant_dropdown
                    //  selection?.tenant
                  ));
                }
              }}
            >
              {"Search"}
            </StyledButton>
          </Grid>
        </Grid>

      </Box>
      {
        table?.data !== null &&
        <DataNotFound
          show={table?.data?.length === 0}
          customsx={{ image: { height: '202px', width: '100%' }, label: {}, content: {}, btns: {} }}
          data={{
            content: getMessageStr("fota-list-listEmpty")
          }}
        >
          <CustomTable
            headerList={table?.headers}
            cellMatrix={table?.data}
            searching={{ reset: true, columns: ["firmwareVersion", "modelIdList", "tagList"] }}
            pagination={true}
            enableScrollToTop={false}
            sorting={{ column: "firmwareVersion" }}
            removeSortFrom={["checked", "userActions", "modelIdList", "tagList"]}
          >
          </CustomTable>
        </DataNotFound>
      }
      <StyledPopup
        open={dialog}
        onClose={() => { setDialog(false) }}
        state={dialogContents?.state}
        data={dialogContents?.content}
        component={dialogContents?.component}
      />
    </Box>
  )
};

FirmwareList.propTypes = {};

FirmwareList.defaultProps = {};

export default FirmwareList;
