import { API_URL } from '../config/appConfig'
import { API } from '.'
import { handleResponse } from '../helpers';


const getPowerConsumption = async (space, interval, counter) => {
    return await API.get(`${API_URL.support}/power/spaces/${space}?interval=${interval}&count=${counter}`, "getPowerConsumption").then((res) => {
        return handleResponse(res, true);
    });;
}


export const dashboardService = {
    getPowerConsumption,
}