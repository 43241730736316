
export const roleTypes = {
    GET_REQUEST: 'ROLE_GET_REQUEST',
    GET_SUCCESS: 'ROLE_GET_SUCCESS',
    GET_FAILURE: 'ROLE_GET_FAILURE',

    GET_ALL_REQUEST: 'ROLE_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'ROLE_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'ROLE_GET_ALL_FAILURE', 
    
    GET_ALL_CONTROLLER_REQUEST: 'ROLE_GET_ALL_CONTROLLER_REQUEST',
    GET_ALL_CONTROLLER_SUCCESS: 'ROLE_GET_ALL_CONTROLLER_SUCCESS',
    GET_ALL_CONTROLLER_FAILURE: 'ROLE_GET_ALL_CONTROLLER_FAILURE',

    ADD_REQUEST: 'ROLE_ADD_REQUEST',
    ADD_SUCCESS: 'ROLE_ADD_SUCCESS',
    ADD_FAILURE: 'ROLE_ADD_FAILURE',

    UPDATE_REQUEST: 'ROLE_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'ROLE_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'ROLE_UPDATE_FAILURE',

    DELETE_REQUEST: 'ROLE_DELETE_REQUEST',
    DELETE_SUCCESS: 'ROLE_DELETE_SUCCESS',
    DELETE_FAILURE: 'ROLE_DELETE_FAILURE',    
    
    ADD_CONTROLLER_REQUEST: 'CONTROLLER_ADD_REQUEST',
    ADD_CONTROLLER_SUCCESS: 'CONTROLLER_ADD_SUCCESS',
    ADD_CONTROLLER_FAILURE: 'CONTROLLER_ADD_FAILURE',
    
    UPDATE_CONTROLLER_REQUEST: 'CONTROLLER_UPDATE_REQUEST',
    UPDATE_CONTROLLER_SUCCESS: 'CONTROLLER_UPDATE_SUCCESS',
    UPDATE_CONTROLLER_FAILURE: 'CONTROLLER_UPDATE_FAILURE',

    DELETE_CONTROLLER_REQUEST: 'CONTROLLER_DELETE_REQUEST',
    DELETE_CONTROLLER_SUCCESS: 'CONTROLLER_DELETE_SUCCESS',
    DELETE_CONTROLLER_FAILURE: 'CONTROLLER_DELETE_FAILURE',  

    DELETE_ROLE: 'DELETE_ROLE',
    DELETE_ROLE_SUCCESS: 'DELETE_ROLE_SUCCESS',
    DELETE_ROLE_FAILURE: 'DELETE_ROLE_FAILURE', 
}