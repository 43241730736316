import { Avatar, Box, Grid, Tooltip as TooltipComponent } from "@mui/material";
import React, { useEffect, useState } from "react";
import './Dashboard.scss';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useDispatch, useSelector } from "react-redux";
import _, { range } from "lodash";
import "./Dashboard.scss"
import { ticketingActions, dashboardActions, deviceActions } from "../../redux/actions";
import StyledGraph from "../../components/StyledGraph";
import { format } from "date-fns";
import { defaultSpaceJson, comjson, defaultTicketJson, defaultDeviceJson, defaultChartData, features, airQualityParameters } from '../../constants'
import { ReactComponent as Critical } from '../../assets/icons/critical-2.svg';
import { ReactComponent as Major } from '../../assets/icons/major-2.svg';
import { ReactComponent as Minor } from '../../assets/icons/minor-2.svg';
import { ReactComponent as GraphDataNotFound } from '../../assets/icons/graphDataNotFound.svg';
import { Link } from "react-router-dom";
import StyledDashboardCard from "../../components/StyledDashboardCard";
import StyledDashboardCounter from "../../components/StyledDashboardCounter";
import CustomTable from "../../components/CustomTable";
import CustomDropDown from "../../components/CustomDropDown";
import TwoDLayout from "../../layouts/TwoDLayout";
import CustomTypography from "../../components/CustomTypography";
import StyledSpinner from "../../components/StyledSpinner";
import { aggregateAirQualityData, formatedDate, isAuthorised } from "../../helpers";
import { ScrollToTop } from "../../services";
import ControllerSlider from "../../components/DeviceController/ControllerSlider";
import StyledCardWrapper from "../../components/StyledCardWrapper";
import { deviceTypes } from "../../redux/types";

ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.defaults.plugins.tooltip.enabled = false;

const Dashboard = ({ mqttClient }) => {
    const dispatch = useDispatch();
    const _selectedSpace = useSelector(state => state?.spaces?.selectedSpace);
    const spaces = useSelector(state => state?.spaces?.list);
    // const spaceTree = useSelector(state => state?.spaces?.spaceTree);
    const _devices = useSelector(state => state?.devices?.deviceList);
    const AQISensorData = useSelector(state => state?.devices?.deviceLogs?.activities);
    const powerConsumption = useSelector(state => state?.dashboard?.powerConsumption)
    const tickets = useSelector((state) => state?.ticketing?.ticketList)
    const statistics = useSelector(state => state?.ticketing?.statistics);

    const [interval, setInterval] = useState("hourly")
    const [count, setCount] = useState("5")
    // const [noDevice, setNoDevice] = useState(false);
    const [spaceJson, setSpaceJson] = useState(defaultSpaceJson);
    const [deviceJson, setDeviceJson] = useState(defaultDeviceJson);
    const [ticketsJson, setTicketsJson] = useState(defaultTicketJson);
    const [chartData, setChartData] = useState(defaultChartData);
    const [spaceDetailsJson, setSpaceDetailsJson] = useState(comjson)
    const [childSpaces, setChildSpaces] = useState(_selectedSpace?.Spaces || null);
    const [dataLoading, setDataLoading] = useState(true);
    const [airQualityGraphData, setAirQualityGraphData] = useState({});
    const [airQualityParam, setAirQualityParam] = useState({});


    const getAllChildren = (space) => {
        let children = spaces?.filter(c => c.parentSid === space?.spaceId) || [];
        // return [...children, ...(children ? await children.map(async ch=>{
        //     // return await getAllChildren(ch);
        //     return []
        // }) : [])];
        if (children && Array.isArray(children) && children.length > 0) {
            children.forEach((ch) => {
                children = [...children, ...getAllChildren(ch)];
            })
        };

        return children;
    }

    useEffect(() => {
        setTicketsJson({
            ...ticketsJson,
            widget: [
                {
                    ...ticketsJson?.widget[0],
                    counter: statistics ? statistics?.all : 0
                },
                {
                    ...ticketsJson?.widget[1],
                    counter: statistics ? statistics?.myTickets : 0
                },
                {
                    ...ticketsJson?.widget[2],
                    counter: statistics ? statistics?.open : 0
                },
                {
                    ...ticketsJson?.widget[3],
                    counter: statistics ? statistics?.closed : 0
                }
            ],
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statistics])

    useEffect(() => {
        if (isAuthorised(features?.space) && isAuthorised(features?.ticket)) {
            dispatch(ticketingActions.getStatistics())
            dispatch(ticketingActions.getAssignees())
            setTimeout(() => {
                dispatch(ticketingActions.getFilteredTickets({}))
            }, 500)
        }
        // console.log("selectedSpaces ----------- ", _selectedSpace)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (AQISensorData[0]?.metadata?.category === 'ODMSENSORHUB') {
            const para = airQualityParameters?.find(p => p?.id === "PM 2.5")
            setAirQualityParam(para)
            setAirQualityGraphData(aggregateAirQualityData(AQISensorData, para?.prop));
        }
        else {
            setAirQualityGraphData({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [AQISensorData])

    useEffect(() => {
        ScrollToTop();
        setDataLoading(true);
        setTimeout(() => {
            setDataLoading(false);
        }, 1000);

        if (spaces) {
            setChildSpaces([_selectedSpace, ...getAllChildren(_selectedSpace)])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [spaces, _selectedSpace])

    useEffect(() => {
        const aqiSensor = _devices?.find(d => d?.category === 'ODMSENSORHUB');
        if (aqiSensor) {
            dispatch(deviceActions?.getDeviceActivityLogs(aqiSensor?.deviceId, { startDate: Math?.floor((new Date()?.getTime() - (5 * 60 * 60 * 1000)) / 1000), endDate: Math.floor(new Date()?.getTime() / 1000), pgSize: 350 }))
        }
        else {
            dispatch({
                type: deviceTypes?.CLEAR_DEVICE_LOGS,
                payload: {},
            });
        }
        setDeviceJson({
            ...deviceJson,
            widget: [
                {
                    ...deviceJson?.widget[0],
                    counter: _devices?.length
                },
                {
                    ...deviceJson?.widget[1],
                    counter: _devices?.filter(
                        device =>
                            device &&
                            device?.deviceStatus &&
                            device?.deviceStatus?.connectionStatus &&
                            device.deviceStatus?.connectionStatus?.hasOwnProperty('onlineStatus') &&
                            !device?.deviceStatus?.connectionStatus?.onlineStatus
                    )?.length
                },
                {
                    ...deviceJson?.widget[2],
                    counter: 0
                },
                {
                    ...deviceJson?.widget[3],
                    counter: _devices?.filter((device) => {
                        return device.status === "active"
                    })?.length
                }
            ],
        })


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_devices])

    useEffect(() => {
        if (!_.isEmpty(AQISensorData) && AQISensorData[0]?.metadata?.category === 'ODMSENSORHUB') {
            setSpaceDetailsJson({
                ...spaceDetailsJson,
                widget: [
                    {
                        ...spaceDetailsJson?.widget[0],
                        columnSize: 2,
                        counter: AQISensorData[0]?.activity?.setmp ? AQISensorData[0]?.activity?.setmp + ` ${airQualityParameters?.find(p => p?.id === "Temperature")?.unit}` : "-"
                    },
                    {
                        ...spaceDetailsJson?.widget[1],
                        columnSize: 2,
                        counter: AQISensorData[1]?.activity?.sehu ? AQISensorData[1]?.activity?.sehu + ` ${airQualityParameters?.find(p => p?.id === "Humidity")?.unit}` : "-"
                    },
                    {
                        ...spaceDetailsJson?.widget[2],
                        columnSize: 2,
                        counter: "-"
                    },
                    {
                        ...comjson?.widget[3],
                        columnSize: 2,
                        counter: AQISensorData ? AQISensorData[0]?.activity?.seco2 + ` ${airQualityParameters?.find(p => p?.id === "Carbon Dioxide")?.unit}` : "-"
                    },
                    {
                        ...comjson?.widget[4],
                        columnSize: 2,
                        counter: AQISensorData ? AQISensorData[0]?.activity?.sepm25 + ` ${airQualityParameters?.find(p => p?.id === "PM 2.5")?.unit}` : "-"
                    }
                ],
            })
        }
        else {
            let humidityCounter = 0, tempCounter = 0, humidity = 0, temp = 0, als = 0, alsCounter = 0;
            for (let i = 0; i < _devices.length; i++) {
                if (_devices[i].category === "Sensor") {
                    if (_devices[i]?.deviceStatus && _devices[i]?.deviceStatus?.status && _devices[i]?.deviceStatus?.status?.humidity) {
                        humidity += _devices[i]?.deviceStatus?.status?.humidity
                        humidityCounter++;
                    }

                    if (_devices[i]?.deviceStatus && _devices[i]?.deviceStatus?.status && _devices[i]?.deviceStatus?.status?.temperature) {
                        temp += _devices[i]?.deviceStatus?.status?.temperature
                        tempCounter++;
                    }
                    if (_devices[i]?.deviceStatus && _devices[i]?.deviceStatus?.status && _devices[i]?.deviceStatus?.status?.als) {
                        als += _devices[i]?.deviceStatus?.status?.als;
                        alsCounter++;
                    }
                }
            }
            setSpaceDetailsJson({
                ...spaceDetailsJson,
                widget: [
                    {
                        ...spaceDetailsJson?.widget[0],
                        columnSize: 4,
                        counter: temp && temp > 0 ? parseFloat(temp / tempCounter).toFixed(2) + ` ${airQualityParameters?.find(p => p?.id === "Temperature")?.unit}` : "-",
                    },
                    {
                        ...spaceDetailsJson?.widget[1],
                        columnSize: 4,
                        counter: humidity && humidity > 0 ? parseFloat(humidity / humidityCounter).toFixed(2) + ` ${airQualityParameters?.find(p => p?.id === "Humidity")?.unit}` : "-",
                    },
                    {
                        ...spaceDetailsJson?.widget[2],
                        columnSize: 4,
                        counter: als && als > 0 ? parseFloat(als / alsCounter).toFixed(2) + ` ${airQualityParameters?.find(p => p?.id === "ALS")?.unit}` : "-"
                    },
                ],
            })
        }
    }, [AQISensorData])

    useEffect(() => {
        if (_selectedSpace) {
            setSpaceJson({
                ...spaceJson,
                widget: [
                    {
                        ...deviceJson?.widget[0],
                        counter: childSpaces?.length || 0
                    }
                ],
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [childSpaces])

    useEffect(() => {
        if (_selectedSpace?.spaceId && isAuthorised(features?.device))
            dispatch(dashboardActions.getPowerConsumption(_selectedSpace?.spaceId, interval, count))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_selectedSpace?.spaceId, count, interval])

    function stringAvatar(name) {
        return {
            sx: {
                bgcolor: stringToColor(name),
                width: 24,
                height: 24
            },
            children: getInitials(name),
        };
    }

    function stringToColor(string) {
        let hash = 0;
        let i;

        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        return color;
    }

    var getInitials = function (string) {
        var names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    };

    useEffect(() => {
        if (tickets) {
            if (tickets?.records && tickets.records.length > 0) {
                let manupulatedTickets = tickets.records.slice(0, 5).map((ticket, i) => {
                    return {
                        ...ticket,
                        createdAt: ticket && ticket.createdAt ? ticket.createdAt : "-",
                        severity: ticket && ticket.severity?.charAt(0).toUpperCase() + ticket.severity?.slice(1),
                        state: ticket && ticket.state?.charAt(0).toUpperCase() + ticket.state?.slice(1),
                        assignedTo: ticket && ticket?.assignedToUsername ? ticket?.assignedToUsername : "-",
                        lastModifiedAt: ticket && ticket?.lastModifiedAt ? ticket?.lastModifiedAt : "-",
                        "avatar": ticket?.assignedToUsername ? <Avatar className='ticketAvatar' {...stringAvatar(`${ticket?.assignedToUsername ? ticket.assignedToUsername : null}`)} /> : "",
                    }
                })
                setTicketsJson({
                    ...ticketsJson,
                    tableData: {
                        headerList: {
                            TicketId: {
                                label: "Ticket",
                                type: "component",
                                component: (row) => {
                                    return (
                                        <Link to={`/tickets?op=details&ticketId=${row?.data?.TicketId}&synopsis=${row?.data?.synopsis}`} style={{ color: "#0f8d48" }} >
                                            {row?.data?.TicketId} - {row?.data?.synopsis}
                                        </Link>
                                    )
                                }
                            },
                            state: { label: "State" },
                            multiKeys: {
                                label: 'Assigned To',
                                avatar: 'avatar',
                                assignedTo: {},
                                sortKey: "assignedTo"
                            },
                            severity: {
                                label: 'Severity',
                                type: "component",
                                component: (row) => {
                                    return (
                                        <div>
                                            {row?.data?.severity === 'Major' ? <Major /> : row?.data?.severity === 'Minor' ? <Minor /> : <Critical />}
                                            {row?.data?.severity}
                                        </div>
                                    )
                                }
                            },
                            lastModifiedAt: { label: 'Last Updated On', type: "date" }
                        },
                        data: manupulatedTickets,
                    }
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tickets])

    useEffect(() => {
        if (!_.isEmpty(powerConsumption)) {
            let barData = powerConsumption?.powerConsumption?.map((usage) => {
                return (Number(parseFloat((usage?.powerWH) / 1000).toFixed(2)))
                // return parseFloat((usage?.powerWH) / 1000)
            })

            let lineData = JSON.parse(JSON.stringify(barData))
            for (let i = 1; i < lineData.length; i++) {
                lineData[i] += lineData[i - 1];
            }
            const dateFormat = interval === "hourly" ? 'hh:mm a' : "dd MMM";
            let label = powerConsumption?.powerConsumption?.map(({ startTs, endTs }) => {
                return (
                    formatedDate({ date: startTs, format: dateFormat }) + " - " + formatedDate({ date: endTs, format: dateFormat })
                )
            })
            setChartData({
                ...chartData, dataPoints: {
                    labels: label,
                    datasets: [
                        {
                            data: lineData,
                            type: "line",
                            interval: interval,
                            label: _selectedSpace?.name,
                            backgroundColor: "#0F8D48"
                        },
                        {
                            data: barData,
                            barThickness: 20,
                            type: "bar",
                            interval: interval,
                            label: _selectedSpace?.name,
                            backgroundColor: "#0F8D48"
                        }
                    ]
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [powerConsumption])


    return (
        <Box sx={{ position: "relative" }}>
            {dataLoading &&
                <Box sx={{ position: 'absolute', top: "12px", bottom: 0, width: "100%", height: "100%", backgroundColor: "#f6faf8eb", zIndex: 1090, minHeight: "350px" }}>
                    <StyledSpinner sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
                </Box>
            }
            <Grid container item xs={12} spacing={1.5}>
                {isAuthorised(features.space) &&
                    <Grid item xs={12}>
                        <TwoDLayout mqttClient={mqttClient} />
                    </Grid>
                }
                {isAuthorised(features?.space) &&
                    <Grid item xs={_.isEmpty(AQISensorData) ? 8 : 12}>
                        <StyledDashboardCard heading={comjson?.componentName}>
                            <Grid container item xxl={8} md={12} xs={12} spacing={1.5}>
                                {
                                    !_.isEmpty(AQISensorData) &&
                                    <Grid item md={2} sm={6} xs={12} >
                                        <StyledDashboardCounter sx={{ background: ["#C5F2C5", "rgb(255, 255, 255) 90%"] }}>
                                            <TooltipComponent arrow enterDelay={1500} title="Air quality is satisfactory, and air pollution poses little or no risk.">
                                                <Box sx={{ display: "block" }}>
                                                    <CustomTypography
                                                        fontWeight={600}
                                                        size={16}
                                                        color="#5E5E5E"
                                                        content={"AQI (PM 2.5)"}
                                                        lineHeight={16}
                                                        styles={{ marginBottom: "12px" }}
                                                    />
                                                    <CustomTypography
                                                        color={"#5E5873"}
                                                        fontWeight={700}
                                                        size={24}
                                                        content={AQISensorData[0]?.activity?.sepm25}
                                                    // color="#64a13c"
                                                    />
                                                    <ControllerSlider
                                                        customsx={{
                                                            compo: {
                                                                marginTop: '8px',
                                                                "& .MuiSlider-rail": {
                                                                    background: 'linear-gradient(90deg,#58a006,#64a13c,#eac51c,#e0c805,#f00d01,#ea1a27)',
                                                                    height: '5px',
                                                                    width: '100%'
                                                                },
                                                                "& .MuiSlider-track": {
                                                                    height: '1px',
                                                                    border: 'none',
                                                                    color: 'transparent'
                                                                }
                                                            }, label: { width: "fit-content" },
                                                        }}
                                                        label={''}
                                                        data={{ min: 0, max: 500, }}
                                                        value={AQISensorData[0]?.activity?.sepm25}
                                                        name="aqi"
                                                        params={{ icon: false, label: false, value: false, compo: true }}
                                                        disabled={true}
                                                    />
                                                </Box>
                                            </TooltipComponent>
                                        </StyledDashboardCounter>
                                    </Grid>
                                }
                                {spaceDetailsJson?.widget?.map((item, indx) => {
                                    return (
                                        <Grid item key={indx} md={item?.columnSize} sm={6} xs={12}>
                                            <StyledDashboardCounter counterSx={{ fontSize: "1.5rem !important" }} key={"aaa-" + indx} data={item} counterPosition="bottom" sx={{ background: item?.bgColors || [] }} />
                                        </Grid>
                                    )
                                })}

                            </Grid>
                        </StyledDashboardCard>
                    </Grid>
                }
                {isAuthorised(features?.space) &&
                    <Grid item lg={4} xs={12}>
                        <StyledDashboardCard heading={spaceJson?.componentName} moreBtn={{ url: "/spaces?op=view&id=" + _selectedSpace?.spaceId }}>
                            <Grid container item spacing={1.5}>
                                {spaceJson?.widget?.map((item, indx) => {
                                    return (
                                        <Grid item key={indx} xl={4} sm={6} xs={12}>
                                            <StyledDashboardCounter key={"bbb-" + indx} data={item} counterPosition="bottom" sx={{ background: item?.bgColors }} />
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </StyledDashboardCard>
                    </Grid>
                }
                {
                    !_.isEmpty(AQISensorData) &&
                    <Grid item lg={8} xs={12}>
                        <StyledCardWrapper sx={{ height: "100%" }}>
                            <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "16px" }}>
                                <CustomTypography weight={600} content={"Air Quality"} color="#5E5E5E" />
                                <Box style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                    <CustomDropDown
                                        list={airQualityParameters?.filter(p => p?.visible)}
                                        isNoneAsItem={false}
                                        customdropsx={{ width: "150px" }}
                                        customsx={{ '& .MuiOutlinedInput-input': { lineHeight: "14px !important", height: "auto !important", padding: "3px 10px !important" }, '& .MuiSvgIcon-root': { width: "22px !important", right: "2px !important" } }}
                                        value={airQualityParam?.label}
                                        onChange={(e) => {
                                            const param = airQualityParameters?.find(p => p?.id === e?.target?.value);
                                            setAirQualityParam(param);
                                            setAirQualityGraphData(aggregateAirQualityData(AQISensorData, param?.prop));
                                        }}
                                        rounded={true}
                                    />
                                    <CustomTypography content="levels" weight={600} size={14} lineHeight={14.63} color="rgba(0, 0, 0, 0.8)" />
                                    {/* <CustomDropDown
                                    list={range(1, 8).map(o => { return { id: o, label: o } })}
                                    isNoneAsItem={false}
                                    customdropsx={{ width: "40px" }}
                                    customsx={{ width: "40px", '& .MuiOutlinedInput-input': { lineHeight: "14px !important", height: "auto !important", padding: "3px 10px !important" }, '& .MuiSvgIcon-root': { width: "22px !important", right: "2px !important" } }}
                                    value={count}
                                    onChange={(e) => { setCount(e.target.value) }}
                                    rounded={true}
                                />
                                <CustomDropDown
                                    list={[
                                        { id: "hourly", label: "Hour(s)" },
                                        { id: "daily", label: "Day(s)" },
                                        { id: "weekly", label: "Week(s)" },
                                        { id: "monthly", label: "Month(s)" },
                                    ]}
                                    isNoneAsItem={false}
                                    customdropsx={{ width: "95px" }}
                                    customsx={{ width: "95px !important", '& .MuiOutlinedInput-input': { lineHeight: "14px !important", height: "auto !important", padding: "3px 10px !important" }, '& .MuiSvgIcon-root': { width: "22px !important", right: "2px !important" } }}
                                    value={interval}
                                    onChange={(e) => { setInterval(e.target.value) }}
                                    rounded={true}
                                /> */}
                                </Box>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "right", mb: 1 }}>
                                <CustomTypography content={`Ideal level: ${airQualityParam?.threshold} ${airQualityParam?.unit}`} weight={600} size={14} lineHeight={14.63} color="rgba(0, 0, 0, 0.8)" />
                            </Box>
                            {
                                !_.isEmpty(airQualityGraphData) ?
                                    <StyledGraph chartType={"Line"}
                                        sideTitle={airQualityParam?.unit}
                                        chartData={
                                            {
                                                "labels": Object?.keys(airQualityGraphData)?.reverse(),
                                                "datasets": [
                                                    {
                                                        "data": Object?.keys(airQualityGraphData)?.reverse()?.map(k => {
                                                            return parseFloat(airQualityGraphData[k]?.data / airQualityGraphData[k]?.count).toFixed(2)
                                                        }),
                                                        "type": "line",
                                                        tension: 0.4,
                                                        fill: {
                                                            target: {
                                                                value: airQualityParam?.threshold
                                                            },
                                                            above: "rgba(255, 139, 139, 0.25)",
                                                            below: "#eefddd"
                                                        },
                                                        "interval": "hourly",
                                                        // "label": "IIC Office",
                                                        "backgroundColor": "#0F8D48"

                                                    },
                                                ]
                                            }
                                        }

                                        plugins={{
                                            tooltip: {
                                                enabled: true,
                                                displayColors: false,
                                                callbacks: {
                                                    title: function (tooltipItems) {
                                                        return `${airQualityParam?.id} : ` + (tooltipItems && tooltipItems[0] ? tooltipItems[0]?.formattedValue + ` ${airQualityParam?.unit}` : `0 ${airQualityParam?.unit}`)
                                                    },

                                                    label: function (tooltipItems) {
                                                        return "Hour - " + tooltipItems?.label
                                                    },
                                                },
                                            }
                                        }}
                                    /> :
                                    <GraphDataNotFound style={{ display: "block", margin: "auto", width: "50%", padding: "10px" }} />

                            }
                        </StyledCardWrapper>
                    </Grid>}
                {/* <Grid item lg={6} xs={12}>
                    <StyledCardWrapper sx={{height:"100%"}}>
                        <Box sx={{ display: "flex", justifyContent: "space-between",marginBottom:"16px" }}>
                            <CustomTypography weight={600} content={"PM 2.5"} color="#5E5E5E" />
                            <Box style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                <CustomTypography content="Data for last" weight={600} size={14} lineHeight={14.63} color="rgba(0, 0, 0, 0.8)" />
                                <CustomDropDown
                                    list={range(1, 8).map(o => { return { id: o, label: o } })}
                                    isNoneAsItem={false}
                                    customdropsx={{ width: "40px" }}
                                    customsx={{ width: "40px", '& .MuiOutlinedInput-input': { lineHeight: "14px !important", height: "auto !important", padding: "3px 10px !important" }, '& .MuiSvgIcon-root': { width: "22px !important", right: "2px !important" } }}
                                    value={count}
                                    onChange={(e) => { setCount(e.target.value) }}
                                    rounded={true}
                                />
                                <CustomDropDown
                                    list={[
                                        { id: "hourly", label: "Hour(s)" },
                                        { id: "daily", label: "Day(s)" },
                                        { id: "weekly", label: "Week(s)" },
                                        { id: "monthly", label: "Month(s)" },
                                    ]}
                                    isNoneAsItem={false}
                                    customdropsx={{ width: "95px" }}
                                    customsx={{ width: "95px !important", '& .MuiOutlinedInput-input': { lineHeight: "14px !important", height: "auto !important", padding: "3px 10px !important" }, '& .MuiSvgIcon-root': { width: "22px !important", right: "2px !important" } }}
                                    value={interval}
                                    onChange={(e) => { setInterval(e.target.value) }}
                                    rounded={true}
                                />
                            </Box>
                        </Box>
                        <StyledGraph chartType={"Line"}
                            sideTitle={"ug/m3"}   
                            chartData={
                              {
                                "labels": [
                                  "9 AM ",
                                  "10 PM",
                                  "11 PM",
                                  "12 PM ",
                                  "1 AM"
                                ],
                                "datasets": [
                                  {
                                    "data": [
                                      16,
                                      18,
                                      22,
                                      22,
                                      20
                                    ],
                                    "type": "line",
                                    "interval": "hourly",
                                    "label": "Sensor 1",
                                    "backgroundColor": "#0F8D48"
                                  },
                                  {
                                    "data": [
                                      11,
                                      12,
                                      24,
                                      2,
                                      24
                                    ],
                                    "type": "line",
                                    "interval": "hourly",
                                    "label": "Sensor 2",
                                    "backgroundColor": "#FBF6DF"
                                  },
                                  {
                                    "data": [
                                      1,
                                      8,
                                      2,
                                      2,
                                      21
                                    ],
                                    "type": "line",
                                    "interval": "hourly",
                                    "label": "Sensor 3",
                                    "backgroundColor": "rgb(164, 231, 192) 0%"
                                  },
                                  {
                                    "data": [
                                      20,
                                      10,
                                      12,
                                      15,
                                      20
                                    ],
                                    "type": "line",
                                    "interval": "hourly",
                                    "label": "Sensor 4",
                                    "backgroundColor": "#FF8B8B"
                                  },
                                ]
                              }
                            } />
                    </StyledCardWrapper>
                </Grid> */}
                {/* <Grid item xs={12}>

                    <StyledDashboardCard heading={AQjson?.componentName}>
                       
                        <Grid container item xxl={8} md={12} xs={12} spacing={1.5}>
                            {AQjson?.widget?.map((item, indx) => {
                                return (
                                    <Grid item key={indx} md={3} sm={6} xs={12}>
                                        <StyledDashboardCounter key={"aaa-" + indx} data={item} counterPosition="bottom" sx={{ background: item?.bgColors }} />
                                    </Grid>
                                )
                            })}
                            
                        </Grid>
                    </StyledDashboardCard>
                </Grid> */}
                {isAuthorised(features?.device) &&
                    <Grid item lg={5} xs={12}>
                        <StyledDashboardCard heading={deviceJson?.componentName} moreBtn={{ url: "/spaces/devices" }}>
                            <Grid container item spacing={1.5}>
                                {deviceJson?.widget?.map((item, indx) => {
                                    return (
                                        <Grid item key={indx} sm={6} xs={12}>
                                            <StyledDashboardCounter key={"ccc-" + indx} data={item} counterPosition="bottom" sx={{ background: item?.bgColors }} />
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </StyledDashboardCard>
                    </Grid>
                }

                {isAuthorised(features?.device) &&
                    <Grid item lg={7} xs={12}>
                        <StyledDashboardCard
                            heading={chartData?.componentName}
                            headingChildren={
                                <Box style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                    <CustomTypography content="Data for last" weight={600} size={14} lineHeight={14.63} color="rgba(0, 0, 0, 0.8)" />
                                    <CustomDropDown
                                        list={range(1, 8).map(o => { return { id: o, label: o } })}
                                        isNoneAsItem={false}
                                        customdropsx={{ width: "40px" }}
                                        customsx={{ width: "40px", '& .MuiOutlinedInput-input': { lineHeight: "14px !important", height: "auto !important", padding: "3px 10px !important" }, '& .MuiSvgIcon-root': { width: "22px !important", right: "2px !important" } }}
                                        value={count}
                                        onChange={(e) => { setCount(e.target.value) }}
                                        rounded={true}
                                    />
                                    <CustomDropDown
                                        list={[
                                            { id: "hourly", label: "Hour(s)" },
                                            { id: "daily", label: "Day(s)" },
                                            { id: "weekly", label: "Week(s)" },
                                            { id: "monthly", label: "Month(s)" },
                                        ]}
                                        isNoneAsItem={false}
                                        customdropsx={{ width: "95px" }}
                                        customsx={{ width: "95px !important", '& .MuiOutlinedInput-input': { lineHeight: "14px !important", height: "auto !important", padding: "3px 10px !important" }, '& .MuiSvgIcon-root': { width: "22px !important", right: "2px !important" } }}
                                        value={interval}
                                        onChange={(e) => { setInterval(e.target.value) }}
                                        rounded={true}
                                    />
                                </Box>
                            }>

                            {powerConsumption && powerConsumption?.powerConsumption?.length > 0 ?
                                <StyledGraph chartType={"Bar"} chartData={chartData?.dataPoints} /> :
                                <GraphDataNotFound style={{ display: "block", margin: "auto", width: "50%", padding: "10px" }} />
                            }

                        </StyledDashboardCard>
                    </Grid>
                }

                {isAuthorised(features?.ticket) &&
                    <Grid item lg={12} xs={12}>
                        <StyledDashboardCard heading={ticketsJson?.componentName} moreBtn={{ url: "/tickets" }}>
                            <Grid container item xxl={8} lg={10} md={12} spacing={1.5}>
                                {ticketsJson?.widget?.map((item, indx) => {
                                    return (
                                        <Grid item key={indx} md={3} xs={6}>
                                            <StyledDashboardCounter key={"ddd-" + indx} data={item} counterPosition={item?.counterPosition} sx={{ background: item?.bgColors }} labelSx={{ color: "#5E5E5E", fontSize: "12px !important" }} counterSx={{ fontSize: "22px !important", ...item?.counterSx }} />
                                        </Grid>
                                    )
                                })}
                            </Grid>
                            <Box sx={{ mt: 3 }}>
                                <CustomTable headerList={ticketsJson?.tableData?.headerList} sx={{ heading: { backgroundColor: "#EAEAEA" } }} cellMatrix={ticketsJson?.tableData?.data} sorting={{ column: "TicketId", order: "desc" }} />
                            </Box>
                        </StyledDashboardCard>
                    </Grid>
                }
            </Grid>
        </Box>
    )
}


export default Dashboard;