/***
 *
 * Controller class for user.
 * @file Areas.js
 * @description Areas component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React,{ useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// import PropTypes from 'prop-types';
import './Areas.scss';
import { useQuery } from '../../hooks';
import AreaList from '../../components/AreaList';
import AreaDetails from '../../components/AreaDetails';
import CreateAreas from '../../components/CreateAreas';
import { dragAndDropActions } from '../../redux/actions';

const Areas = () => {
  const query = useQuery();
  const areaId = query.get("areaId") || null;
  const [areaOperation, setAreaOperation] = useState()

  const dispatch = useDispatch()

  useEffect(() => {
    setAreaOperation(query.get("op"))
  }, [query])

  useEffect(() => {
    if(areaOperation===null || areaOperation===undefined){
      dispatch(dragAndDropActions.emptyLists());
      dispatch(dragAndDropActions.setErrorMessage(''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaOperation,query])

    return (
      <>
        {areaOperation === "create" || areaOperation === "edit" ? (
          <CreateAreas areaOperation={areaOperation}/>
        ) : (
          <div className="Areas" data-testid="Areas">
            {areaId ? < AreaDetails areaId={areaId} /> : <AreaList/>}
          </div>
        )}
        
      </>
      
    )
};

Areas.propTypes = {};

Areas.defaultProps = {};

export default Areas;
