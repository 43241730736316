/***
 *
 * Controller class for user.
 * @file ReportDetails.js
 * @description ReportDetails component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 *
 */

import { React, useEffect, useState } from "react";
import { useStyles } from "./ReportDetails.style.js";
import "./ReportDetails.scss";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../PageHeader/index.js";
import CustomAccordion from "../CustomAccordion/index.js";
import FormWrapper from "../FormWrapper/index.js";
import { history } from "../../helpers/history.helper.js";
import { reportActions } from "../../redux/actions/report.actions.js";
import ImageWrapper from "../ImageWrapper";
import { formatedDate } from "../../helpers/date.helper.js";
import { daysOfMonth } from "../../constants/scheduler.constants.js";
import StyledPopup from "../StyledPopup/index.js";
import { ticketingActions } from "../../redux/actions/ticketing.actions.js";
import { isAuthorised } from "../../helpers";
import Avatar from "@mui/material/Avatar";
import { stringAvatar } from "../../helpers";
import { useCallback } from "react";
import { features, permissions } from "../../constants/permission.constants.js";
import {
  scopeList,
  categories,
  monthList,
  monthMap,
  weekDayMap,
  weekdayList,
  addScheduleCheckbox,
  attachScheduleCheckbox,
  accessList,
  frmConfigObj,
  scheduleConfig,
  scheduleLabels,
  reportSummaryLabelsReportDetails as reportSummaryLabels,
  startDateValidation,
  endDateValidation,
  durationValidation,
} from "../../constants";

import ItemView from "../ItemView/index.js";
import _ from "lodash";
import reports from "../../assets/icons/report.svg";
import editIcon from "../../assets/icons/edit-2.svg";
import deleteIcon from "../../assets/icons/delete.svg";
import ReportDocumentDetail from "../ReportDocumentDetail/index.js";
import StyledButton from "../StyledButton/index.js";

const ReportDetails = ({ op = "create", rId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const _SPACELIST = useSelector((state) => state?.spaces?.list);
  const userIdData = useSelector((state) => state?.ticketing?.assigneeData);
  const _reportDetail = useSelector((state) => state?.reports?.reportDetails);

  const [operation, setOperation] = useState(op);

  const [reportDetail, setReportDetail] = useState({});
  const [summaryDetails, setSummaryDetails] = useState([]);
  const [scheduleDetails, setScheduleDetails] = useState([]);

  const [frmConfig, setFrmConfig] = useState(JSON.parse(JSON.stringify(frmConfigObj)));
  const [frmOptions, setFrmOptions] = useState(null);
  const [frm, setFrm] = useState({});
  const [formData, setFormData] = useState(() => {
    if (op === "create") {
      return {
        access: "public",
        reportType: "oneTime",
      };
    } else return {};
  });

  const [isScheduleAvailable, setIsScheduleAvailable] = useState(false);
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState({
    show: false,
  });

  const [reportPanel, setReportPanel] = useState('panel2');

  const mapSpacesToNames = useCallback(
    (val) => {
      const options = val
        ? val?.map((v) => {
          const sp = _SPACELIST.find((s) => s?.spaceId === v);
          return { id: sp?.spaceId, label: sp?.name };
        })
        : [];
      return options;
    },
    [_SPACELIST]
  );

  const handleConfirmation = (confirmed = false) => {
    if (confirmed) {
      openConfirmationPopup?.fun?.name(
        openConfirmationPopup?.fun?.params?.event,
        openConfirmationPopup?.fun?.params?.data
      );
      setOpenConfirmationPopup({ show: false, fun: null });
    } else {
      setOpenConfirmationPopup({ show: false, fun: null });
    }
  };

  const handleDelete = (event) => {
    event.preventDefault();
    dispatch(reportActions.deleteReport(rId));
    history.push("/reports");
  };

  const getTimeFormat = (time) => {
    let [hour, minute] = time.split(":");
    let timeFormat = `${hour}${minute}`;
    return timeFormat;
  };

  const handleReport = (event, data) => {
    const schedulePayload = (frm) => {
      let payload = {
        month:
          frm && frm?.months && frm?.months.length > 0 ? frm?.months : undefined,
        timeOfDay:
          frm && frm?.time && frm?.time.length > 0
            ? frm?.time.map((t) => {
              return getTimeFormat(t);
            })
            : undefined,
        dayOfWeek:
          frm && frm?.weekDays && frm?.weekDays.length > 0
            ? frm?.weekDays
            : undefined,
        dayOfMonth:
          frm && frm?.days && frm?.days.length > 0
            ? frm?.days.map((m) => {
              return m.toString();
            })
            : undefined,
      };
      if (
        payload.month === undefined &&
        payload.timeOfDay === undefined &&
        payload.dayOfWeek === undefined &&
        payload.dayOfMonth === undefined
      ) {
        payload = undefined;
        return payload;
      } else {
        for (let key in payload) {
          if (payload[key] === undefined) {
            delete payload[key];
          }
        }
        return payload;
      }
    };

    let subs = [];
    if (frm && frm?.subscribers) {
      frm?.subscribers.forEach((sub) => {
        if (!_.isEmpty(userIdData)) {
          return userIdData.forEach((user) => {
            if (user.name === sub) subs.push(user.id);
          });
        } else return [];
      });
    }

    const payload = {
      name: frm?.name,
      description: frm?.description,
      spaceIds:
        frm && frm?.space && frm?.space.length > 0
          ? frm?.space.map((sp) => {
            return sp?.id;
          })
          : [],
      scope:
        frm && frm?.scope && frm?.scope.length > 0
          ? frm?.scope.map((val) => {
            return scopeList?.find(s => s?.label === val)?.value;
          })
          : [],
      categories:
        frm && frm?.categories && frm?.categories.length > 0
          ? frm?.categories.map((val) => {
            return categories?.find(c => c?.label === val)?.value;
          })
          : [],
      access: frm && frm?.access ? frm?.access : undefined,
      subscribers: _.isEmpty(subs) ? undefined : subs,
      startDate:
        frm && frm?.startDate
          ? formatedDate({ date: frm?.startDate, format: "dd-MM-yyyy" })
          : undefined,
      endDate:
        frm && frm?.endDate
          ? formatedDate({ date: frm?.endDate, format: "dd-MM-yyyy" })
          : undefined,
      expiresAtSecs:
        frm && frm?.expiresAtSecs
          ? Math.round(new Date(frm?.expiresAtSecs).getTime() / 1000)
          : undefined,
      duration: frm && frm?.duration ? parseInt(frm?.duration) : undefined,
      schedule: schedulePayload(frm),
    };

    for (let key in payload) {
      if (payload[key] === undefined) {
        delete payload[key];
      }
    }

    if (operation === "create") {
      dispatch(
        reportActions.createReport(payload, (data) => {
          setOperation("view");
          history.replace(`/reports?op=view&reportId=${data?.reportId}`);
          setFrm({});
        })
      );
    }
    if (operation === "edit") {
      dispatch(
        reportActions.updateReport(
          rId,
          payload,
          () => { // success calback
            setOperation("view");
            // console.log("report update success - report detail before frm update", reportDetail, frm)
            setReportDetail(() => { return { ...frm } })
            // setFormData(()=>{return {...frm}})
            // console.log("report update success","reportDetails - ", reportDetail, frmConfig, frmConfigObj, frm, formData)
            if (payload?.schedule && !_.isEmpty(payload?.schedule)) {
              setIsScheduleAvailable(true);
            } else setIsScheduleAvailable(false);
            setFrmConfig(JSON.parse(JSON.stringify(frmConfigObj)));
            dispatch(reportActions.getReportById(rId))
          },
          () => { // failure callback
            // setOperation("view");
            // console.log(
            //   "report update failure",
            //   "payload",
            //   payload,
            //   "formData",
            //   formData,
            //   "reportDetail",
            //   reportDetail
            // );
            // setFormData(() => { return { ...reportDetail } })
            // setFrm(() => { return { ...reportDetail } })
            // setFrmConfig(JSON.parse(JSON.stringify(frmConfigObj)));

            // console.log("report updated data", frm, formData, reportDetail);
          }
        )
      );
    }
  };

  const actionButtons = [
    {
      heading: null,
      columns: [
        {
          heading: null,
          buttons: [
            {
              id: "cancel",
              type: "button",
              // size: "large",
              variant: "outlined",
              display: true,
              checkValidation: false,
              label: "Cancel",
              onClick: (event, data) => {
                if (operation === 'create') {
                  history.push('/reports')
                }
                else {
                  setOperation("view");
                  setFrmConfig(JSON.parse(JSON.stringify(frmConfigObj)));
                  setFormData({ ...reportDetail });
                  // dispatch(reportActions.getReportById(rId))
                  // history.push("/reports");
                }
              },
            },
            {
              id: "1000",
              type: "button",
              display: true,
              variant: "contained",
              color: "primary",
              checkValidation: true,
              label: "Save",
              onClick: (event, data) => {
                handleReport(event, data);
              },
            },
          ],
          columnSize: 12,
        },
      ],
    },
  ];

  useEffect(() => {
    if (rId && operation !== "create") {
      dispatch(reportActions.getReportById(rId));
      dispatch(reportActions.getAllReportDocument(rId));
    }
    // setFrm({})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operation, rId]);

  useEffect(() => {
    let opt = {};
    opt["dropdowns"] = {
      scope: {
        list: scopeList?.map(s => s?.label),
      },
      categories: {
        list: categories?.map(c => c?.label),
      },
      months: {
        list: monthList,
      },
      weekDays: {
        list: weekdayList,
      },
      days: {
        list: daysOfMonth,
      },
      scheduleAvailable: {
        list: addScheduleCheckbox,
      },
      access: {
        list: accessList,
      },
      attachSchedule: {
        list: attachScheduleCheckbox,
      },
    };
    setFrmOptions(opt);
    dispatch(ticketingActions.getAssignees());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (operation === 'edit') {
      setReportPanel('panel2');
    }
  }, [operation])

  useEffect(() => {
    if (!_.isEmpty(userIdData)) {
      let dd = frmOptions?.dropdowns;
      if (dd) {
        dd["subscribers"] = {
          list: userIdData?.map((user) => {
            return { id: user?.name, label: user?.name };
          }),
        };
        setFrmOptions({ ...frmOptions, dropdowns: dd });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIdData]);

  useEffect(() => {
    if (_reportDetail && !_.isEmpty(_reportDetail)) {
      let reportData = {
        name: _reportDetail?.name || "",
        description: _reportDetail?.description || "",
        space:
          _reportDetail && _reportDetail?.spaceIds
            ? mapSpacesToNames(_reportDetail?.spaceIds)
            : [],
        scope:
          _reportDetail?.scope && _reportDetail?.scope.length > 0
            ? _reportDetail?.scope.map((val) => {
              return scopeList?.find(s => s?.value === val)?.label;
            })
            : [],
        categories:
          _reportDetail?.categories && _reportDetail?.categories.length > 0
            ? _reportDetail?.categories.map((c) => {
              return categories?.find(cat => cat?.value === c)?.label;
            })
            : [],
        duration: _reportDetail?.duration || undefined,
        reportType:
          _reportDetail && _reportDetail?.duration
            ? "scheduleReport"
            : "oneTime",
        attachSchedule:
          _reportDetail && _reportDetail?.duration && _reportDetail?.schedule
            ? ["attachSchedule"]
            : [],
        access: _reportDetail.access ? _reportDetail?.access : "",
        subscribers:
          _reportDetail && _reportDetail?.subscribers
            ? _reportDetail?.subscribers
              ?.map((sub) => {
                for (
                  let i = 0;
                  !_.isEmpty(userIdData) && i < userIdData.length;
                  i++
                ) {
                  if (!_.isEmpty(userIdData) && userIdData[i]["id"] === sub) {
                    return userIdData[i]["name"];
                  }
                }
                return "";
              })
              .filter((name) => {
                return name === "" ? false : true;
              })
            : [],
        months:
          _reportDetail &&
            _reportDetail.schedule &&
            _reportDetail.schedule.month &&
            _reportDetail.schedule.month.length > 0
            ? _reportDetail?.schedule?.month
            : undefined,
        time:
          _reportDetail &&
            _reportDetail?.schedule &&
            _reportDetail?.schedule?.timeOfDay &&
            !_.isEmpty(_reportDetail?.schedule?.timeOfDay)
            ? _reportDetail?.schedule?.timeOfDay.map((t) => {
              let chars = t.split("");
              chars.splice(2, 0, ":");
              return chars.join("");
            })
            : undefined,
        days:
          _reportDetail &&
            _reportDetail?.schedule &&
            _reportDetail?.schedule?.dayOfMonth &&
            _reportDetail?.schedule?.dayOfMonth.length > 0
            ? _reportDetail?.schedule?.dayOfMonth
            : undefined,
        weekDays:
          _reportDetail &&
            _reportDetail?.schedule &&
            _reportDetail?.schedule?.dayOfWeek &&
            _reportDetail?.schedule?.dayOfWeek.length > 0
            ? _reportDetail?.schedule?.dayOfWeek
            : undefined,
        expiresAtSecs: _reportDetail?.expiresAtSecs
          ? _reportDetail?.expiresAtSecs * 1000
          : undefined,
        startDate: _reportDetail?.startDate
          ? Date.parse(_reportDetail?.startDate.split("-").reverse().join("-"))
          : undefined,
        endDate: _reportDetail?.endDate
          ? Date.parse(_reportDetail?.endDate.split("-").reverse().join("-"))
          : undefined,
      };
      for (let key in reportData) {
        if (reportData[key] === undefined) {
          delete reportData[key];
        }
      }
      setReportDetail(reportData);
      setFormData(reportData);

      let summaryData = {
        createdOn: _reportDetail?.createdOn
          ? formatedDate({
            date: _reportDetail?.createdOn * 1000,
            format: "dd MMM,yyyy",
          })
          : "",
        createdBy: _reportDetail?.createdByUsername ? (
          <div sx={{ display: "flex" }}>
            <Avatar
              style={{
                display: "inline-flex",
                fontSize: "0.8rem",
                width: 20,
                height: 20,
              }}
              {...stringAvatar(`${_reportDetail?.createdByUsername}`)}
            />
            <span style={{ marginLeft: "3px" }}>
              {_reportDetail?.createdByUsername}
            </span>
          </div>
        ) : (
          ""
        ),
        lastUpdatedOn: _reportDetail?.lastUpdatedOn
          ? formatedDate({
            date: _reportDetail?.lastUpdatedOn * 1000,
            format: "MMM dd, yyyy",
          })
          : "",
        lastUpdatedBy: _reportDetail?.lastUpdatedByUsername ? (
          <div sx={{ display: "flex" }}>
            <Avatar
              style={{
                display: "inline-flex",
                fontSize: "0.8rem",
                width: 20,
                height: 20,
              }}
              {...stringAvatar(`${_reportDetail?.lastUpdatedByUsername}`)}
            />
            <span style={{ marginLeft: "3px" }}>
              {_reportDetail?.lastUpdatedByUsername}
            </span>
          </div>
        ) : (
          ""
        ),
        expiresAtSecs: _reportDetail?.expiresAtSecs
          ? formatedDate({
            date: _reportDetail?.expiresAtSecs * 1000,
            format: "MMM dd, yyyy",
          })
          : "",
      };
      setSummaryDetails(summaryData);

      let scheduleData = {
        months:
          _reportDetail &&
            _reportDetail?.schedule &&
            _reportDetail?.schedule?.month &&
            _reportDetail?.schedule?.month.length > 0
            ? _reportDetail?.schedule?.month
              .map((m) => {
                return monthMap[m];
              })
              .join(" | ")
            : undefined,
        time:
          _reportDetail &&
            _reportDetail?.schedule &&
            _reportDetail?.schedule?.timeOfDay &&
            !_.isEmpty(_reportDetail?.schedule?.timeOfDay)
            ? _reportDetail?.schedule?.timeOfDay
              .map((t) => {
                let chars = t.split("");
                chars.splice(2, 0, ":");
                return chars.join("");
              })
              .join(" | ")
            : [],
        days:
          _reportDetail &&
            _reportDetail?.schedule &&
            _reportDetail?.schedule?.dayOfMonth &&
            _reportDetail?.schedule?.dayOfMonth.length > 0
            ? _reportDetail?.schedule?.dayOfMonth.join(" | ")
            : undefined,
        weekDays:
          _reportDetail &&
            _reportDetail?.schedule &&
            _reportDetail?.schedule?.dayOfWeek &&
            _reportDetail?.schedule?.dayOfWeek.length > 0
            ? _reportDetail?.schedule?.dayOfWeek
              .map((w) => {
                return weekDayMap[w];
              })
              .join(" | ")
            : undefined,
        expiresAtSecs: _reportDetail?.expiresAtSecs
          ? formatedDate({
            date: _reportDetail?.expiresAtSecs * 1000,
            format: "MMM dd, yyyy",
          })
          : "",
      };
      setScheduleDetails(scheduleData);

      if (
        _reportDetail &&
        _reportDetail?.schedule &&
        !_.isEmpty(_reportDetail?.schedule)
      ) {
        setIsScheduleAvailable(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_reportDetail]);

  useEffect(() => {
    console.log("neelesh 1234", frm, frmConfig, frmConfigObj)
    if (frm && frm?.startDate) {
      setFrmConfig(
        frmConfig.map((conf) => {
          if (conf.sid === 2) {
            conf.columns[0].rows[1].columns[1]["minDate"] = frm?.startDate;
          }
          return conf;
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frm?.startDate]);

  useEffect(() => {
    console.log("neelesh 1222", frm, frmConfig, frmConfigObj)
    if (frm && frm?.endDate) {
      setFrmConfig(
        frmConfig.map((conf) => {
          if (conf.sid === 2) {
            conf.columns[0].rows[1].columns[0]["maxDate"] = frm?.endDate;
          }
          return conf;
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frm?.endDate]);

  useEffect(() => {
    if (!_.isEmpty(frm) && frm?.reportType && !_.isEmpty(frm?.reportType)) {
      let obj = frm;
      if (frm.reportType === "scheduleReport") {
        setFrmConfig(
          frmConfig.map((conf) => {
            if (conf.sid === 2) {
              conf.columns[0].rows[1].columns[0]["disabled"] = true;
              conf.columns[0].rows[1].columns[1]["disabled"] = true;
              conf.columns[1].rows[1].columns[0]["disabled"] = false;
              delete conf.columns[0].rows[1].columns[0]["validation"];
              delete conf.columns[0].rows[1].columns[1]["validation"];
              // conf.columns[0].rows[1].columns[0]["validation"] = undefined;
              // conf.columns[0].rows[1].columns[1]["validation"] = undefined;
              conf.columns[1].rows[1].columns[0]["validation"] =
                durationValidation;
            } else if (conf.sid === 3) {
              conf.columns[0]["disabled"] = false;
            }
            return conf;
          })
        );

        if (obj?.startDate) {
          delete obj["startDate"];
        }
        if (obj?.endDate) {
          delete obj["endDate"];
        }
      } else if (frm?.reportType === "oneTime") {
        setFrmConfig(
          frmConfig.map((conf) => {
            if (conf.sid === 2) {
              conf.columns[1].rows[1].columns[0]["disabled"] = true;
              conf.columns[0].rows[1].columns[0]["disabled"] = false;
              conf.columns[0].rows[1].columns[1]["disabled"] = false;
              delete conf.columns[1].rows[1].columns[0]["validation"];
              // conf.columns[0].rows[1].columns[0]['validation'] = undefined;
              conf.columns[0].rows[1].columns[0]["validation"] =
                startDateValidation;
              conf.columns[0].rows[1].columns[1]["validation"] =
                endDateValidation;
            } else if (conf.sid === 3) {
              conf.columns[0]["disabled"] = true;
            }
            return conf;
          })
        );
        if (obj?.duration) {
          delete obj["duration"];
        }
        if (obj?.attachSchedule) {
          delete obj["attachSchedule"];
        }
        if (obj?.months) {
          delete obj["months"];
        }
        if (obj?.time) {
          delete obj["time"];
        }
        if (obj?.days) {
          delete obj["days"];
        }
        if (obj?.weekDays) {
          delete obj["weekDays"];
        }
        if (obj?.expiresAtSecs) {
          delete obj["expiresAtSecs"];
        }
      }
      setFrm({ ...obj });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frm?.reportType]);

  useEffect(() => {
    let opt = frmOptions || {};
    if (opt["dropdowns"]) {
      opt["dropdowns"] = {
        ...opt["dropdowns"],
        attachSchedule: {
          list: _.isEmpty(frm?.attachSchedule)
            ? attachScheduleCheckbox
            : addScheduleCheckbox,
        },
      };
      setFrmOptions(opt);
    }
    setFrmConfig((prev) => {
      if (operation === "view") return JSON.parse(JSON.stringify(frmConfigObj));
      else {
        return _.isEmpty(frm?.attachSchedule)
          ? JSON.parse(JSON.stringify(frmConfigObj))
          : [
            ...frmConfigObj.slice(0, 3),
            scheduleConfig,
            ...frmConfigObj.slice(3),
          ];
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frm?.attachSchedule, operation]);

  return (
    <Box className={classes?.ReportDetails || ""} data-testid="ReportDetails">
      <PageHeader
        title={
          operation === "create" ? "Create Report" : reportDetail?.name || ""
        }
        bckbtn={true}
        backfunc={() => {
          history.push("/reports");
        }}
        icon={reports}
        textTransform={'normal'}
      >
        {operation !== "create" && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            {isAuthorised(features?.report, permissions?.edit) &&
              operation !== "edit" && (
                <StyledButton iconButton tooltip='Edit' sx={{ p: 0, ml: 2 }} onClick={(event) => { setOperation("edit"); setReportPanel('panel2') }}>
                  <ImageWrapper
                    // enablePopOver={true}
                    // hoverLabel="Edit"
                    // hoverstyle={{ fontSize: "1rem", fontWeight: "400" }}
                    src={editIcon}
                    height="20px"
                    weight="20px"
                  />
                </StyledButton>
              )}
            {isAuthorised(features?.report, permissions?.delete) && (
              <StyledButton iconButton tooltip='Delete' sx={{ p: 0, ml: 2 }} onClick={() => { setOpenConfirmationPopup({ show: true, fun: null }); }}>
                <ImageWrapper
                  src={deleteIcon}
                  // enablePopOver={true}
                  // hoverLabel="Delete"
                  // hoverstyle={{ fontSize: "1rem", fontWeight: "400" }}
                  height="20px"
                  weight="20px"
                />
              </StyledButton>)}
          </Box>
        )}
      </PageHeader>

      {operation !== "create" && (
        <CustomAccordion title={"Summary"} contentBg={"#FFFFFF"} aid="panel1">
          <ItemView data={summaryDetails} headers={reportSummaryLabels} />
        </CustomAccordion>
      )}

      {operation === "create" ? (
        <FormWrapper
          formData={formData}
          formFields={frmConfig}
          formOptions={frmOptions}
          formButtons={actionButtons}
          setPayload={setFrm}
          operation={operation}
        />
      ) : (
        <CustomAccordion
          title={"Report Details"}
          aid="panel2"
          // expandState={operation === 'edit' }
          panel={reportPanel}
          defaultState={'panel2'}
          onChange={(state) => {
            if (!state) setReportPanel('');
          }}
        >
          <FormWrapper
            formData={formData}
            formFields={frmConfig}
            formOptions={frmOptions}
            formButtons={operation === "edit" ? actionButtons : []}
            setPayload={setFrm}
            operation={operation}
          />
        </CustomAccordion>
      )}
      {operation === "view" && isScheduleAvailable && (
        <CustomAccordion title={"Schedule Details"}
          aid="panel3"
          panel="panel3">
          <ItemView data={scheduleDetails} headers={scheduleLabels} />
        </CustomAccordion>
      )}

      {operation !== "create" && <ReportDocumentDetail rId={rId} />}

      <StyledPopup
        open={openConfirmationPopup.show}
        onClose={() => setOpenConfirmationPopup({ show: false, fun: null })}
        state="timeout"
        data={{
          title: "Delete Report Details",
          content: (
            <>
              <p>
                Are you sure?
                <br /> You want to perform this operation.
              </p>
            </>
          ),
          actions: [
            {
              id: "1002",
              type: "button",
              label: "No",
              onClick: (event, data) => {
                handleConfirmation(false);
              },
            },
            {
              id: "1001",
              type: "button",
              label: "Yes",
              onClick: (event, data) => {
                handleConfirmation(true);
                handleDelete(event);
              },
            },
          ],
        }}
      />
    </Box>
  );
};

ReportDetails.propTypes = {};

ReportDetails.defaultProps = {};

export default ReportDetails;
