/***
 *
 * Controller class for user.
 * @file Notifications.js
 * @description Notifications component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */
import { useState, useEffect } from "react";
// import PropTypes from 'prop-types';

import { getFirebaseToken } from "../helpers/notification.helper.js";
import { appConnections } from "../config/appConfig.js";
import { useDispatch } from "react-redux";

const FirebaseNotif = () => {
    const [, setTokenFound] = useState(false);
    const dispatch=useDispatch();

    // To load once
    useEffect(() => {
        let data;
        async function tokenFunc() {
            data = await getFirebaseToken(setTokenFound,dispatch);
            if (data) {
                console.log("Token is", data);
            }
            return data;
        }

        appConnections?.notifications && tokenFunc();
    }, [setTokenFound]);
};

FirebaseNotif.propTypes = {};

FirebaseNotif.defaultProps = {};

export default FirebaseNotif;
