/***
 *
 * Controller class for user.
 * @file IntensitySlider.js
 * @description IntensitySlider component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './IntensitySlider.style.js';
import './IntensitySlider.scss';
import { Box } from '@mui/material';
import { TableHelper } from '../TableHelper/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { disableDeviceOperation, isAuthorised, lstControl } from '../../helpers';
import { features, permissions } from '../../constants/permission.constants.js';

const IntensitySlider = ({ data, params = { value: true, switchButton: true, compo: true }, mqttClient,subscribe=true, ...props }) => {
    const classes = useStyles();

    const deviceModels = useSelector((state) => state.devices.deviceModels);
    //   const deviceData=useSelector((state)=>state.devices.deviceList.find((device)=>device?.deviceId===data?.deviceId))
    //   const loadingStatus=deviceData?.loading
    const dispatch = useDispatch();

    const [slider, setSlider] = useState(data);
    const [config, setConfig] = useState(null);

    useEffect(() => {
        setSlider(data);
    }, [data]);
    // useEffect(()=>{
    //     setLoading(loadingStatus)
    // },[loadingStatus])

    useEffect(() => {
        const modelLst = deviceModels.find((d) => d.modelNumber?.substring(d.modelNumber?.length - 3, d.modelNumber?.length) === slider?.configuration?.daliDriver || d?.modelId === slider?.modelId)?.controlCapabilities?.find(
            (c) => c?.capability?.trim()?.toLowerCase() === "intensity" || c?.capability?.trim()?.toLowerCase() === "luminosity"
        );
        if (modelLst) {
            const lstValue = slider?.deviceStatus?.status?.intensity;
            const lstMax = modelLst?.attributes[0]?.range?.max[0]?.value;
            const lstMin = modelLst?.attributes[0]?.range?.min[0]?.value;
            if (lstMax === 1) {
                setConfig({ value: lstValue, min: lstMin, max: lstMax, disabled: !disableDeviceOperation(slider,"deviceControl") ? !isAuthorised(features?.device,permissions?.configureIntensity) : true, params: { ...params, switchButton: true, value: false, compo: false } });
            }
            else {
                setConfig({ value: lstValue, min: lstMin, max: lstMax, disabled: !disableDeviceOperation(slider,"deviceControl") ? !isAuthorised(features?.device,permissions?.configureIntensity) : true, params });
            }
        } else {
            setConfig({ customsx: { value: { color: "grey" } }, disabled: true, params });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deviceModels, slider?.deviceStatus?.status?.intensity, slider?.modelId, slider?.status])

    return (
        <Box className={classes?.IntensitySlider+" IntensitySlider"} data-testid="IntensitySlider">
            {
                //  loading?
                //  <StyledSpinner/>
                //  :
                <TableHelper.TableSlider
                    data={slider}
                    customData={config}
                    params={{ ...config?.params }}
                    onOffLabel={false}
                    onChange={(e, value, obj, setter) => {
                        lstControl(e, value, obj, {
                            deviceId: obj.deviceId, dispatch, mqttClient, onFail: () => {
                                setter(slider?.deviceStatus?.status?.intensity)
                            },
                            subscribe
                        });
                    }}
                    {...props}
                    customsx={{ toggleLabel: { minWidth: "30px" }, minWidth: "70px", value: { minWidth: "44px" }, ...props?.customsx }}

                />

            }
        </Box>
    )
};

IntensitySlider.propTypes = {};

IntensitySlider.defaultProps = {};

export default IntensitySlider;
