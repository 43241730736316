/***
 *
 * Controller class for user.
 * @file SpaceDetails.js
 * @description SpaceDetails component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import './SpaceDetails.scss';
import { useDispatch, useSelector } from 'react-redux';
import PageHeader from '../PageHeader';
import { formatedDate, history, isAuthorised } from '../../helpers';
import { Box, Grid } from '@mui/material';
import PopoverContainer from '../PopoverContainer';
import ImageWrapper from '../ImageWrapper';
import CustomTypography from '../CustomTypography';
import CustomAccordion from '../CustomAccordion';
import _ from 'lodash';
import StyledTabs from '../StyledTabs';
import CustomTable from '../CustomTable';
import { selectSpace } from '../../helpers';
import { findNode } from '../../services';
import StyledPopup from '../StyledPopup';
import { spaceActions } from '../../redux/actions';
import { format } from 'date-fns';
import ActivityLog from '../ActivityLog';
import spaceIcon from '../../assets/icons/space.svg';
import activityLogsIcon from '../../assets/icons/activitylog.svg';
import deleteIcon from '../../assets/icons/delete.svg';
import editFileIcon from '../../assets/icons/edit-2.svg';
import { features, permissions } from '../../constants';
import StyledButton from '../StyledButton';

const frmConfig = [
  {
    section: { heading: null },
    columns: [
      {
        id: "1",
        type: "DateTimePicker",
        label: "Start Date",
        name: "startDate",
        columnSize: 3,
        required: false,
        disableFuture: true
      },
      {
        id: "2",
        type: "DateTimePicker",
        label: "End Date",
        name: "endDate",
        columnSize: 3,
        required: false,
        disableFuture: true
      },
      {
        id: "3",
        type: "select",
        label: "Type",
        name: "type",
        columnSize: 3,
        required: false,
      },
      {
        id: "4",
        type: "text",
        label: "User ID",
        name: "userId",
        placeholder: "Enter",
        columnSize: 3,
        required: false,
      }
    ],
  },
];

const frmOptions = {
  dropdowns: {
    type: {
      list: [{ id: 'control', label: 'Control' }, { id: 'config', label: 'Config' }, { id: 'connectivity', label: 'Connectivity' }]
    }
  }
}

const spaceDetailsArray = {
  type: { label: "Type" },
  name: { label: "Name" },
  dateOfCreation: { label: "Date Created" },
  dateLastUpdated: { label: "Last Updated" },
};

//if sub spaces are not present then Spaces key will not be present
const SpaceDetails = ({ id }) => {
  const dispatch = useDispatch();

  const space = useSelector(state => state?.spaces?.selectedSpace);
  const spaces = useSelector(state => state?.spaces?.list);
  const spaceLogs = useSelector(state => state?.spaces?.spaceLogs);

  const [showToolTip, setShowToolTip] = useState(false);
  const [popoverAnchorToolTip, setPopoverAnchorToolTip] = useState(null);
  const [popoverAnchorToolTipContent, setPopoverAnchorToolTipContent] = useState('');
  //Added edit and delete functionalities same as in customized tree
  const [confirmationPopup, setConfirmationPopup] = useState({ show: false });
  const [tabsArray] = useState([{ icon: spaceIcon, label: 'Sub-Spaces' }, { icon: activityLogsIcon, label: 'Activity Logs' }])
  const [tabIndex, setTabIndex] = useState(0);
  const [openState, setOpenState] = useState({ title: true });
  const [tableHeaders] = useState({
    name: {
      label: "Space Name",
      type: "link",
      url: "/spaces?op=view",
      urlParams: { id: "spaceId" }
    },
    type: {
      label: "Space Type"
    },
    geoCoordinates: {
      label: "Geo-Coordinates",
      type: "component",
      component: (row) => {
        return Array.isArray(row?.data?.geoCoordinates) && row?.data?.geoCoordinates.length === 2 && row?.data?.geoCoordinates[0] !== '' && row?.data?.geoCoordinates[1] !== '' ? <CustomTypography content={`${row?.data?.geoCoordinates[0]}, ${row?.data?.geoCoordinates[1]}`} /> : <CustomTypography content={`-`} /> //Added this to show geoCoordinates accordingly
      }
    },
    address: {
      label: "Address",
      type: "component",
      component: (row) => {
        let addressArray = [];
        row?.data?.address && Object.values(row?.data?.address)?.map(line => (line !== '' && addressArray.push(line)));
        let addressLine = addressArray.join(', ');
        return <CustomTypography content={`${addressLine}`} />
      }
    },

  })
  const [tableData, setTableData] = useState(null)
  const [spaceDetails, setSpaceDetails] = useState(null)
  const [filterFrm, setFilterFrm] = useState({
    startDate: null,
    endDate: null,
    userId: "",
    type: ""
  });
  const [formConfig, setFormConfig] = useState(frmConfig);


  // useEffect(()=>{
  //   console.log("BLANK USEEFFECT")
  //   const space = spaces?.find(s => s?.spaceId === id);
  //     selectSpace(space, dispatch);
  //   setTableData(spaces?.filter(s=>s?.parentSid===id))
  // },[])

  useEffect(() => {

    const space = spaces?.find(s => s?.spaceId === id);
    selectSpace(space, dispatch);
    setTableData(spaces?.filter(s => s?.parentSid === id))
    // findNode(spaces,"Spaces", { key:"spaceId", value: id }, (space) => {
    //   selectSpace(space, dispatch);
    //   setTableData(space?.Spaces || []);

    //   console.log("spaceId -- ", id, space);
    // })

  }, [dispatch, id, spaces])

  // useEffect(() => {
  //   console.log('spaceInfo',spaceInfo);
  //   selectSpace(spaceInfo, dispatch);
  // }, [spaceInfo])


  useEffect(() => {
    console.log("dt -- ", space?.creationDate, space?.updatedDate)
    if (!_.isEmpty(space)) {
      setSpaceDetails({
        ...space,
        dateOfCreation: space?.createdOn ? formatedDate({ date: space.createdOn, format: "dd MMMM yyyy" }) : "",
        dateLastUpdated: space?.lastUpdatedOn ? formatedDate({ date: space.lastUpdatedOn, format: "dd MMMM yyyy" }) : "",
      });
    }
  }, [space])

  useEffect(() => {
    if (tabIndex === 1) {
      dispatch(spaceActions.getSpaceActivityLogs({ spaceId: space?.spaceId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabIndex])

  useEffect(() => {
    if (!_.isEmpty(filterFrm)) {
      if (filterFrm && filterFrm?.startDate) {
        let column = formConfig[0]?.columns
        const newColumn = column.filter((item) => item.name !== "endDate");
        newColumn.splice(1, 0, {
          id: "2",
          type: "DateTimePicker",
          label: "End Date",
          name: "endDate",
          columnSize: 3,
          required: false,
          disableFuture: true,
          minDate: filterFrm?.startDate
        });
        setFormConfig([{ ...formConfig, columns: newColumn }])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterFrm])



  const handleMouseEnter = (event, content) => {
    setPopoverAnchorToolTip(event.currentTarget);
    setPopoverAnchorToolTipContent(content);
    setShowToolTip(true);
  }

  const handleMouseLeave = () => {
    setShowToolTip(false);
    setPopoverAnchorToolTip(null)
  }

  const handleEdit = () => {
    history.push(`/spaces?id=${id}&op=edit`);
  }

  const deleteSpace = (id, parentId, cb = () => { }) => {
    dispatch(spaceActions.deleteSpace(id, parentId, cb));
  };

  const openConfirmationPopup = (id) => {
    setConfirmationPopup({ show: true, id: id });
  }

  const handleDelete = () => {
    openConfirmationPopup(id);
  }

  const handleConfirmation = (confirmed = false) => {
    if (confirmed) {
      deleteSpace(id, space?.parentSid, () => {
        let selectedSp = spaces[0];
        findNode(spaces, "Spaces", { key: "spaceId", value: space?.parentSid }, (resp) => {
          selectedSp = resp || spaces[0];
        });
        dispatch(spaceActions.selectSpace(selectedSp));
        history.push(`/spaces?op=view&id=${selectedSp?.spaceId}`);
      });
    }
    setConfirmationPopup({ show: false });
  }

  //also pass frmconfig from outside
  const getFilteredSpaceLog = () => { //pass this to set Payload 
    let queryParams = [];
    if (!_.isEmpty(filterFrm.startDate)) {
      //send time in seconds
      queryParams.push(`startDate=${Math?.floor((new Date(filterFrm.startDate)?.getTime()) / 1000)}`);
    }
    if (!_.isEmpty(filterFrm.endDate)) {
      //send time in seconds
      queryParams.push(`endDate=${Math?.floor((new Date(filterFrm.endDate)?.getTime()) / 1000)}`);
    }
    if (filterFrm.type !== '') {
      queryParams.push(`type=${filterFrm.type}`);
    }
    if (filterFrm.userId !== '') {
      queryParams.push(`uid=${filterFrm.userId}`);
    }
    let queryString = queryParams.join('&');
    queryString !== '' && (queryString = ''.concat('?', queryString)); //onFilter from outside
    dispatch(spaceActions.getSpaceActivityLogs({ spaceId: space?.spaceId, queryString }));
  }

  const clearFilter = () => {
    setFilterFrm({
      startDate: null,
      endDate: null,
      userId: "",
      type: ""
    });
  }

  return (
    <div className="SpaceDetails" data-testid="SpaceDetails">
      <PageHeader
        bckbtn={true}
        backfunc={() => { history.goBack() }}
        title={space?.name}
        icon={spaceIcon}
        textTransform='none'
      >
        {/* <Stack direction={'row'}> */}
        {/* <Box sx={{ textAlign: "right" }}> */}
        {!space?.isRootSpace &&
          (<Box>
            {isAuthorised(features.space, permissions.edit) &&
              <StyledButton iconButton type='icon' label='Edit' sx={{ padding: "5px", ml: 1, backgroundColor: "#FFFFFF" }} color={'primary'} size="large" onMouseEnter={(e) => handleMouseEnter(e, 'Edit Space')} onMouseLeave={handleMouseLeave} onClick={handleEdit}>
                <ImageWrapper className="action-icons pointer" src={editFileIcon} />
              </StyledButton>
            }
            {isAuthorised(features.space, permissions.delete) &&
              <StyledButton iconButton type='icon' label='Delete' onClick={handleDelete} onMouseEnter={(e) => handleMouseEnter(e, 'Delete Space')} onMouseLeave={handleMouseLeave}>
                <ImageWrapper className="pointer" src={deleteIcon} />
              </StyledButton>
            }
          </Box>)
        }
        {/* <Button variant="outlined" color="primary">
                  More
                </Button> */}
        {/* </Box> */}
        {/* </Stack> */}
      </PageHeader>
      {showToolTip && <PopoverContainer anchorEl={popoverAnchorToolTip}>
        <CustomTypography content={popoverAnchorToolTipContent} weight={700} size={13} lineHeight={19} color="#202020" />
      </PopoverContainer>
      }
      <StyledPopup
        open={confirmationPopup.show}
        onClose={() => setConfirmationPopup({ show: false })}
        state="timeout"
        data={{
          content: <p>Are you sure?<br /> You want to perform this operation.</p>,
          actions: [
            {
              id: "1002",
              type: "button",
              label: "No",
              onClick: (event, data) => {
                handleConfirmation(false);
              }
            },
            {
              id: "1001",
              type: "button",
              label: "Yes",
              onClick: (event, data) => {
                handleConfirmation(true)
              }
            }
          ]
        }} />
      <CustomAccordion
        title={"Space Details"}
        sx={!openState?.title ? { mb: 2 } : {}}
        onChange={(state) => {
          setOpenState({ ...openState, title: state })
        }}
      >
        <Grid className="" container item xs={12} justifyContent={'space-evenly'}>
          {spaceDetails &&
            Object.keys(spaceDetailsArray)?.map((item, key) => (
              <Grid item md={2} xs={12} sx={{ mt: 2 }} key={key}>
                <Box className="">
                  <CustomTypography
                    weight={600}
                    color={"#000"}
                    size={13}
                    lineHeight={19}
                    styles={{ textTransform: "uppercase" }}
                    content={spaceDetailsArray[item]["label"]}
                  />
                  <CustomTypography
                    weight={400}
                    color={"#000"}
                    size={13}
                    lineHeight={19}
                    content={spaceDetails[item]}
                  />
                </Box>
              </Grid>
            ))}
        </Grid>
      </CustomAccordion>
      <Box sx={{ mb: 2 }}>
        <StyledTabs
          tabs={tabsArray}
          onClick={(e, data) => { setTabIndex(data); }}
        />
      </Box>
      {tabIndex === 0 ?
        <CustomTable
          headerList={tableHeaders}
          cellMatrix={tableData || []}
          pagination={true}
          sorting={{ column: ['spaceId', 'name', 'type'] }}
          removeSortFrom={['geoCoordinates', 'address']}
        />
        :
        <ActivityLog
          name={space?.name}
          forEntity={'Space'}
          activityLogs={spaceLogs}
          filterForm={{
            formConfig: formConfig,
            formOptions: frmOptions,
            filterFormData: filterFrm,
            setFilterFormData: setFilterFrm,
            actionButtons: [{
              heading: null,
              columns: [
                {
                  heading: null,
                  buttons: [
                    {
                      id: "cancel",
                      type: "button",
                      size: 'large',
                      variant: 'outlined',
                      display: true,
                      checkValidation: false,
                      label: "Cancel",
                      onClick: (e) => { clearFilter(); }
                    },
                    {
                      id: "apply",
                      type: "button",
                      size: 'large',
                      color: 'primary',
                      checkValidation: false,
                      display: true,
                      label: 'Apply',
                      onClick: (e) => { getFilteredSpaceLog(); }
                    }
                  ],
                  columnSize: 12,
                },
              ],
            },
            ]
          }}
        />
      }
    </div>
  )
};

SpaceDetails.propTypes = {};

SpaceDetails.defaultProps = {};

export default SpaceDetails;
