import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    TenantDetails: {
        "& .expandLink": {
            color: "rgba(15, 141, 72, 1)",
            fontSize: "12px",

        },
        "& .expandLink:hover": {
            cursor: "pointer"
        },
    },


    HeaderDetails: {
        display: 'flex',
        alignItems: 'center',
        background: 'rgba(15, 141, 72, 0.05)',
        position: 'relative',
        padding: '17px 20px 18px 20px',
        marginTop: '16px',
        marginBottom: '16px',
        height: '50px',
        justifyContent: 'space-between'
    },
    BodyDetails: {
        display: "flex",
        alignItems: 'center',
        background: 'rgba(15, 141, 72, 0.02)',
    },
    LabelDetails: {
        display: "flex",
        alignItems: 'center'
    }

});
