/***
 *
 * Controller class for user.
 * @file StyledSpaceTree.js
 * @description StyledSpaceTree component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './StyledSpaceTree.style.js';
import './StyledSpaceTree.scss';
import { Box } from '@mui/material';
import CustomizedTree from '../CustomizedTree/index.js';
import { useSelector } from 'react-redux';
import SearchWrapper from '../SearchWrapper/index.js';
import { searchTree, tree } from '../../helpers/space.helper.js';
// import { deepCopy } from '../../services/util.service.js';


// pass subTreeRoot if we need to render the subtree and not the entire space tree starting from the root space
//subTreeRoot is an object ex: subTreeRoot={name:"2d test", spaceId:"inCf"} 
// subTreeRoot is the root of the sub tree that will be rendered
// if subTreeRoot is not passed the entire space tree will be rendered
const StyledSpaceTree = ({ spaces = null, activeSpace = null, subTreeRoot = null, listId = "lt", style = {}, actButtons = false, searchQuery = null, setSearchQuery = () => { }, handleExpand = () => { }, ...props }) => {
  const classes = useStyles();
  const rootSpace = useSelector((state) => state?.spaces?.rootSpace);
  const reduxSpaceTree = useSelector((state) => state?.spaces?.spaceTree);
  const spaceTree = subTreeRoot ? [subTreeRoot] : reduxSpaceTree;
  const [searchStr, setSearchStr] = useState(null);
  const [filteredSpaceTree, setFilteredSpaceTree] = useState(null);

  const handleChange = (value) => {
    setSearchStr(value);
    setSearchQuery(value);
    setFilteredSpaceTree(searchTree(spaceTree, value))
  }

  useEffect(() => {
    // dispatch(spaceActions.getAllSpaces());
    setFilteredSpaceTree(searchTree(spaceTree, searchQuery))
    tree([JSON.parse(JSON.stringify(subTreeRoot || rootSpace))], spaces)
      .then(tree => {
        setFilteredSpaceTree(tree);
      })

    // setSearchStr(searchQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaces]);

  return (
    <Box className={classes?.StyledSpaceTree || ""} data-testid="StyledSpaceTree">
      <Box>
        <SearchWrapper
          label={props?.searchLabel}
          placeholder={props?.searchPlaceholder || `Search`}
          data={spaces.length > 0 ? spaces : [{ name: 'No Item Available' }]}
          onChange={(e, value) => { handleChange(value) }}
          searchValue={searchStr || searchQuery || ""}
          searchIn={["name"]}
          autoFill={false}
          sx={
            {
              '& .MuiOutlinedInput-root': {
                '& .MuiOutlinedInput-input': {
                  fontSize: "12px !important",
                  padding: "5px 15px",
                }
              }

            }
          }
        />


        {/* <pre style={{overflow: 'auto', maxHeight:50}}>{JSON.stringify(spaces.length > 0, null, 2)}</pre>
        <pre style={{overflow: 'auto', maxHeight:50}}>{JSON.stringify(filteredSpaceTree, null, 2)}</pre> */}

        {/* {JSON.stringify(filteredSpaces)} */}
        {/* <Box sx={{pb: 1.7}}>
          {[
            { label: 'company' },
            { label: 'floor' },
            { label: 'ODC' },
            { label: 'MR' }
          ].map((d, ind)=>{
            return (
              <Chip
                key={ind}
                label={d.label}
                variant="outlined"
                sx={{
                  fontSize: '0.75rem',
                  lineHeight: "14.6px",
                  height: "24px",
                  borderColor: "#0F8D48",
                  color: "#000000CC",
                  marginRight: "5px",
                  marginTop: "5px",
                  '& span': {
                    padding: "4px 8px"
                  }
                }}
                onClick={handleClick}
                // onDelete={handleDelete}
              />
            )
          })}
        </Box> */}
      </Box>
      {/* <pre>{JSON.stringify(filteredSpaceTree, null, 2)}</pre> */}
      <CustomizedTree
        listId={listId}
        data={filteredSpaceTree}
        activeSpace={activeSpace}
        onClick={handleExpand}
        actButtons={actButtons}
        style={style}
      />
    </Box>
  )
};

StyledSpaceTree.propTypes = {};

StyledSpaceTree.defaultProps = {};

export default StyledSpaceTree;
