/***
 *
 * Controller class for user.
 * @file StyledCheckBox.js
 * @description StyledCheckBox component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './StyledCheckBox.style.js';
import './StyledCheckBox.scss';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import ErrorMessage from '../ErrorMessage';

const StyledCheckBox = ({ id, options = [], error = true, message = "", disabled = false, helperText = "", selectedColor = "#000", selectedWeight = "500", label, labelPlacement = "right", value = null, name, vertical = false, onChange = () => { }, customsx = { label: {}, checkbox: {}, options: {} } }) => {
  const classes = useStyles();
  const [selectedItems, setSelectedItems] = useState((Array.isArray(value) ? value : []) || []);

  useEffect(() => {
    setSelectedItems((Array.isArray(value) ? value : []) || []);
  }, [value]);

  const handleChange = (event) => {
    const val = event.target.value;
    let arr = selectedItems;
    if (arr.indexOf(val) >= 0) {
      arr.splice(arr.indexOf(val), 1);
    } else {
      arr.push(val)
    }
    setSelectedItems([...arr]);
    onChange({
      target: {
        name: name,
        value: [...arr]
      }
    });
  }

  const checked = (value) => {
    return selectedItems.includes(value);
  }

  return (
    <>
      <Box key={id} className={classes.StyledCheckBox} data-testid="StyledCheckBox">
        {Array.isArray(options) && options.map((option, index) => (
          <FormControlLabel
            key={"checkbooxx--" + index}
            id={id}
            name={name}
            error={true}
            disabled={disabled}
            sx={{ mr: '26px', mb: 0, '& .MuiTypography-root': { color: checked(option?.value) && selectedColor, fontWeight: checked(option?.value) && selectedWeight, ...customsx.options } }}
            value={option?.value}
            control={<Checkbox id={id} sx={{ '&.MuiCheckbox-root': { p: '5px', ...customsx.checkbox }, '& .MuiSvgIcon-root': { fill: !checked(option?.value) && (error ? '#ff4336' : '#999999') } }} />}
            label={option?.label || label}
            labelPlacement={labelPlacement === "right" ? "end" : (labelPlacement === "left" ? "start" : labelPlacement)}
            checked={selectedItems.includes(option?.value)}
            onChange={handleChange}
            inputProps={{ 'aria-label': option?.value }}
          />
        ))}
      </Box>
      <Box>
        {error && <ErrorMessage message={message || ''} />}
      </Box>
    </>
  )
};

StyledCheckBox.propTypes = {};

StyledCheckBox.defaultProps = {};

export default StyledCheckBox;
