import { alertConstants } from "../../constants";
import { failure, request, success as succ, getMessage } from "../../helpers";
import { delay, alertsService } from "../../services"
import { alertsType } from "../types";
import { appActions } from ".";


function success(message) {
    return { type: alertConstants.SUCCESS, message };
}

function error(message) {
    return { type: alertConstants.ERROR, message };
}

function clear() {
    return { type: alertConstants.CLEAR };
}

const getAlerts = (space, ackStatus, status) => {
    return dispatch => {
        dispatch(request(alertsType.GET_REQUEST));
        alertsService.getAlerts(space, ackStatus, status)
            .then(({ data }) => {
                data.alerts=data?.alerts?.map(a=>{
                    a["alertStatus"]=a?.status==="closed"?"Resolved":a?.ackStatus!=="no"?"Acknowledged":"Open"
                    return a;
                })
                dispatch(succ(alertsType.GET_ALERTS_SUCCESS, { payload: data }));
            },
                ({ error, code }) => {
                    dispatch(failure(alertsType.GET_ALERTS_FAILURE, error.toString()));
                    getMessage(`alertSearch-get-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    };
}

const resolveAlert = (alertId, scb = () => { }) => {
    return dispatch => {
        dispatch(request(alertsType.RESOLVE_ALERT));
        alertsService.resolveAlert(alertId)
            .then(({ data, status }) => {
                dispatch(succ(alertsType.RESOLVE_ALERT_SUCCESS, { payload: data }));
                // console.log("Hereeeeeeeee", getMessage(`alertResolve-put-${status}`), `alertResolve-put-${status}`)
                getMessage(`alertResolve-put-${status}`).then(delay(500)).then((msg) => {
                    dispatch(appActions.apiSuccessMessage(msg));
                });
                scb();
            },
                ({ error, code }) => {
                    dispatch(failure(alertsType.RESOLVE_ALERT_FAILURE, error.toString()));
                    getMessage(`alertResolve-put-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    };
}

const acknowledgeAlert = (alertId, scb = () => { }) => {
    return dispatch => {
        dispatch(request(alertsType.ACK_ALERT));
        alertsService.acknowledgeAlert(alertId)
            .then(({ data, status }) => {
                dispatch(succ(alertsType.ACK_ALERT_SUCCESS, { payload: data }));
                getMessage(`alertAcknowledge-put-${status}`).then(delay(500)).then((msg) => {
                    dispatch(appActions.apiSuccessMessage(msg));
                });
                scb();
            },
                ({ error, code }) => {
                    dispatch(failure(alertsType.ACK_ALERT_FAILURE, error.toString()));
                    // console.log("Hereeeeeeeee", getMessage(`alertAcknowledge-put-${code}`), `alertAcknowledge-put-${code}`)
                    getMessage(`alertAcknowledge-put-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    };
}

const getAlertTypes = () => {
    return dispatch => {
        dispatch(request(alertsType.GET_ALERT_TYPES));
        alertsService.getAlertTypes()
            .then(({ data }) => {
                dispatch(succ(alertsType.GET_ALERT_TYPES_SUCCESS, { payload: data }));
            },
                ({ error, code }) => {
                    dispatch(failure(alertsType.GET_ALERT_TYPES_FAILURE, error.toString()));
                    getMessage(`alertType-get-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    };
}

export const alertActions = {
    success,
    error,
    clear,
    getAlerts,
    resolveAlert,
    getAlertTypes,
    acknowledgeAlert
};
