/***
 *
 * Controller class for user.
 * @file CustomizedListItem.js
 * @description CustomizedListItem component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { Collapse, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import React, { Component } from 'react';
import { useSelector } from 'react-redux';
import { defaultSelectedMenu } from '../../config/appConfig';
import ImageWrapper from '../ImageWrapper';
// import PropTypes from 'prop-types';
import './CustomizedListItem.scss';

const CustomizedListItem = ({ open, item, expand, setExpand, onClick, component: Component, activeMenu, ...props }) => {
  const selectedLeftMenu = useSelector(state => state?.app?.selectedLeftMenu || defaultSelectedMenu.left);
  const theme = useSelector(state => state?.app?.theme) || "light";

  const handleClick = (event, obj) => {
    console.log("tttt Handle Clicked....", obj, expand);    
    const exp = !expand;
    // setTimeout(() => {
      if(obj?.subMenu) {
        setExpand(exp);
      }
      onClick(event, obj, exp);
    // }, 10);
  }

  return (
    <>
      <ListItem className="CustomizedListItem" data-testid="CustomizedListItem" disablePadding sx={{ display: 'block' }} {...props}>
        <ListItemButton 
          onClick={(event)=>handleClick(event, item)}
          className={activeMenu?.left === item?.id || selectedLeftMenu === item?.id ? "active-menu" : ""}
          sx={{
            minHeight: 64,
            justifyContent: open ? 'initial' : 'center',
            px: 2.7,
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: '30px',
              mr: open ? 1 : 'auto',
              justifyContent: 'center',

            }}
          >
            <ImageWrapper className={`"Side"${theme==="dark"?" white-filter":""}`} src={item?.icon} style={{maxWidth: "22px"}} />
          </ListItemIcon>
          <ListItemText sx={{ opacity: (open ? 1 : 0), position: 'relative', transition: "all 0.1s" }}>
            <Typography sx={{ fontSize: "0.88rem", lineHeight: "40px", fontWeight: 600 }}>
              {item?.label}
              {item?.subMenu && 
                <span style={{ float: 'right', transition: "all 0.5s", opacity: (open ? 1 : 0), position: 'absolute', right: "0px" }}>
                  {expand ? <ExpandMore /> : <ChevronRight />}
                </span>
              }
            </Typography>
          </ListItemText>
        </ListItemButton>
      </ListItem>
      <Collapse
        className="MyCollapse"
        in={expand && open}
        timeout='auto'
        unmountOnExit
      >
        {item?.id === 1061 && <Component />}    
      </Collapse>
    </>
  )
}

CustomizedListItem.propTypes = {};

CustomizedListItem.defaultProps = {};

export default CustomizedListItem;
