export const userTypes = {
    GET_REQUEST: 'USER_GET_REQUEST',
    GET_SUCCESS: 'USER_GET_SUCCESS',
    GET_FAILURE: 'USER_GET_FAILURE',

    GET_ALL_REQUEST: 'USER_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'USER_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'USER_GET_ALL_FAILURE',

    ADD_USER_REQUEST: 'ADD_USER_REQUEST',
    ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
    ADD_USER_FAILURE: 'ADD_USER_FAILURE',
    
    UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
    UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
    UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',

    GET_USER_SCOPE_REQUEST: 'GET_USER_SCOPE_REQUEST',
    GET_USER_SCOPE_SUCCESS: 'GET_USER_SCOPE_SUCCESS',
    GET_USER_SCOPE_FAILURE: 'GET_USER_SCOPE_FAILURE',

    UPDATE_USER_SCOPES_REQUEST: 'UPDATE_USER_SCOPES_REQUEST',
    UPDATE_USER_SCOPES_SUCCESS: 'UPDATE_USER_SCOPES_SUCCESS',
    UPDATE_USER_SCOPES_FAILURE: 'UPDATE_USER_SCOPES_FAILURE',

    UPDATE_USER_ROLES_REQUEST: 'UPDATE_USER_ROLES_REQUEST',
    UPDATE_USER_ROLES_SUCCESS: 'UPDATE_USER_ROLES_SUCCESS',
    UPDATE_USER_ROLES_FAILURE: 'UPDATE_USER_ROLES_FAILURE',

    DELETE_REQUEST: 'USER_DELETE_REQUEST',
    DELETE_SUCCESS: 'USER_DELETE_SUCCESS',
    DELETE_FAILURE: 'USER_DELETE_FAILURE', 
    
    GET_SELF_PROFILE_REQUEST: 'USER_GET_SELF_PROFILE_REQUEST',
    GET_SELF_PROFILE_SUCCESS: 'USER_GET_SELF_PROFILE_SUCCESS',
    GET_SELF_PROFILE_FAILURE: 'USER_GET_SELF_PROFILE_FAILURE',
    
    UPDATE_SELF_PROFILE_REQUEST: 'USER_UPDATE_SELF_PROFILE_REQUEST',
    UPDATE_SELF_PROFILE_SUCCESS: 'USER_UPDATE_SELF_PROFILE_SUCCESS',
    UPDATE_SELF_PROFILE_FAILURE: 'USER_UPDATE_SELF_PROFILE_FAILURE',
    
    GENERATE_SELF_PROFILE_OTP_REQUEST: 'USER_GENERATE_SELF_PROFILE_OTP_REQUEST',
    GENERATE_SELF_PROFILE_OTP_SUCCESS: 'USER_GENERATE_SELF_PROFILE_OTP_SUCCESS',
    GENERATE_SELF_PROFILE_OTP_FAILURE: 'USER_GENERATE_SELF_PROFILE_OTP_FAILURE',  
    
    VALIDATE_SELF_PROFILE_OTP_REQUEST: 'USER_VALIDATE_SELF_PROFILE_OTP_REQUEST',
    VALIDATE_SELF_PROFILE_OTP_SUCCESS: 'USER_VALIDATE_SELF_PROFILE_OTP_SUCCESS',
    VALIDATE_SELF_PROFILE_OTP_FAILURE: 'USER_VALIDATE_SELF_PROFILE_OTP_FAILURE',
    
    CHANGE_SELF_PASSWORD_REQUEST: 'USER_CHANGE_SELF_PASSWORD_REQUEST',
    CHANGE_SELF_PASSWORD_SUCCESS: 'USER_CHANGE_SELF_PASSWORD_SUCCESS',
    CHANGE_SELF_PASSWORD_FAILURE: 'USER_CHANGE_SELF_PASSWORD_FAILURE',
    
    CHANGE_USERID_PASSWORD_REQUEST: 'USER_CHANGE_USERID_PASSWORD_REQUEST',
    CHANGE_USERID_PASSWORD_SUCCESS: 'USER_CHANGE_USERID_PASSWORD_SUCCESS',
    CHANGE_USERID_PASSWORD_FAILURE: 'USER_CHANGE_USERID_PASSWORD_FAILURE',    
    
    CHANGE_STATUS_REQUEST: 'USER_CHANGE_STATUS_REQUEST',
    CHANGE_STATUS_SUCCESS: 'USER_CHANGE_STATUS_SUCCESS',
    CHANGE_STATUS_FAILURE: 'USER_CHANGE_STATUS_FAILURE',   

    GET_USER_LOGS_REQUEST: 'GET_USER_LOGS_REQUEST',
    GET_USER_LOGS_SUCCESS: 'GET_USER_LOGS_SUCCESS',
    GET_USER_LOGS_FAILURE: 'GET_USER_LOGS_FAILURE',
    
    
    USER_EMPTY_ERRORS: 'USER_EMPTY_ERRORS',    
};