import { delay, formatString } from "../services/util.service";
import { TableHelper } from "../components/TableHelper";
import { appActions, deviceActions, mqttActions } from "../redux/actions";
import { deviceTypes, fotaTypes } from "../redux/types";
import { deleteMqttCallback, deviceService, mqttRequest, subscribe } from "../services";
import mqttTimeout from "./mqtt.helper";
import _ from "lodash";
import { getMessage, getMessageStr } from "./message.helper";
import { history } from "./history.helper";
import { setMqttTimeout } from "../config/mqttConfig";
import StyledEditor from "../components/StyledEditor";
import { formatedDate } from "./date.helper";
import { defaultTenantName, disableDevFeatures } from "../config/appConfig";
import { Box, Tooltip } from "@mui/material";
import CustomTypography from "../components/CustomTypography";
import ImageWrapper from "../components/ImageWrapper";
import Check from "../assets/icons/tickbox.svg";
import Cross from "../assets/icons/closebox.svg";
import { firmwareUpdateStatusExpiry } from "../config/fotaConfig";
import StyledSpinner from "../components/StyledSpinner";
import warningIcon from '../assets/icons/exclamation.svg'


const pairingtimeout = {};

export function getDevices(data, array = [], map = {}) {
  if (data?.devices) {
    data?.devices.forEach(d => {
      let obj = {};
      obj['id'] = d.deviceId;
      obj['content'] = d;
      map[d.deviceId] = array.length;
      array.push(obj);
    })
  }
  if (data?.groups) {
    data?.groups.forEach(d => {
      let obj = {};
      obj['id'] = d.groupId;
      obj['content'] = d;
      map[d.groupId] = array.length;
      array.push(obj);
    })
  }
  if (!data || !data?.Spaces) {
    return null;
  }
  for (const node of data['Spaces']) {
    getDevices(node, array, map);
  }
  return { array: array, map: map };
}

export const lstControl = (e, value, data, { deviceId, dispatch, mqttClient, onFail, subscribe = true }) => {
  //const { name } = e?.target;
  if (value === true) {
    //toggle
    value = 70;
  } else if (value === false) {
    //toggle
    value = 0;
  }
  dispatch(deviceActions.startLoading(deviceId));

  let sid = mqttRequest(
    {
      subTopic: subscribe ? data?.mqttTopics?.status : null,
      pubTopic: data?.mqttTopics?.control,
      deviceId: deviceId,
      deviceConfig: {
        lst: value,
        // klv: data?.configuration?.klv || MQTT.TEMPERATURE,
      },
      onFail: () => {
        onFail();
      },
      onRecieve: (message) => {
        // if (message?.deviceId === deviceId) {
        if (message?.status === "invalid_payload") {
          dispatch(appActions.apiErrorMessage(getMessageStr("lstControl-receive-invalid_payload", (data?.name ? data?.name : "-") + ` (${message?.deviceId})`)));
          dispatch(deviceActions.stopLoading(deviceId));
        }
        else if ((message?.hasOwnProperty("lst"))) {
          updateLightControl(message, dispatch);
          const msgText = (message?.lst === 0 || message?.lst === 1) ? getMessageStr("lstControl-receive-onOff", { header: (data?.name ? data?.name : "-") + ` (${message?.deviceId})`, value: message["lst"] }) : getMessageStr("lstControl-receive-value", { header: (data?.name ? data?.name : "-") + ` (${message?.deviceId})`, value: message["lst"] })
          dispatch(appActions.apiSuccessMessage(msgText));
        }
        else {
          dispatch(appActions.apiErrorMessage(getMessageStr("lstControl-receive-server_error", (data?.name ? data?.name : "-") + ` (${message?.deviceId})`)));
          dispatch(deviceActions.stopLoading(deviceId));
        }
        clearTimeout(timeout);
        // unsubscribe(data?.mqttTopics?.status, mqttClient);
        dispatch(mqttActions.deleteMqttRequest(message?.sid));
        // }
      },
    },
    mqttClient,
    dispatch, "lightControl"
  );
  const timeout = setTimeout(() => {
    // console.log("lst control timed out")
    onFail();
    dispatch(deviceActions.stopLoading(deviceId));
    // unsubscribe(data?.mqttTopics?.status, mqttClient);
    dispatch(mqttActions.deleteMqttRequest(sid));
    deleteMqttCallback("deviceLstControl" + deviceId)
    dispatch(appActions.apiErrorMessage(getMessageStr("lstControl-receive-server_error", (data?.name ? data?.name : "-") + ` (${data?.deviceId})`)));
  }, setMqttTimeout(60000))
};
export const identifyDevice = ({ device, dispatch, mqttClient }, setDialog, setDialogContents, gatewayDetails = null) => {
  const identifyOperation = () => {
    setDialog(false);
    Promise.resolve().then(() => {
      if (gatewayDetails === null) {
        return deviceService.getDevice(device?.gwDeviceId);
      }
      return { data: gatewayDetails };
    }
    ).then(({ data: gatewayDetails, status }) => {
      if (_.isArray(gatewayDetails)) {
        const gwDeviceId = device?.gwDeviceId;
        gatewayDetails = gatewayDetails.find((gw) => gw?.deviceId === gwDeviceId);
      }
      dispatch(deviceActions.startLoading(device?.deviceId));

      const messageSid = mqttRequest(
        {
          subTopic: gatewayDetails?.mqttTopics.comStatus,
          pubTopic: gatewayDetails?.mqttTopics.comControl,
          deviceId: device?.deviceId,
          deviceConfig: {
            cmd: "deviceIdentify",
            name: device?.name
          },
          onRecieve: (message) => {
            if (message?.cmd === "deviceIdentify" && message?.deviceId === device?.deviceId) {
              dispatch(deviceActions.stopLoading(message?.deviceId));
              if (message?.status === "command_accepted") {
                dispatch(appActions?.apiSuccessMessage(getMessageStr(`${message?.cmd}-receive-${message?.status}`, (device?.name ? device?.name : "-") + ` (${device?.deviceId})`)))
              } else {
                dispatch(appActions?.apiErrorMessage(getMessageStr(`${message?.cmd}-receive-${message?.status}`, (device?.name ? device?.name : "-") + ` (${device?.deviceId})`)))
              }
              clearTimeout(timeout);
              // unsubscribe(gatewayDetails?.mqttTopics?.comStatus, mqttClient);
              dispatch(mqttActions.deleteMqttRequest(message?.sid));
            }
          },
        },
        mqttClient,
        dispatch
      );
      const timeout = setTimeout(() => {
        // console.log("device Identify timed out")
        deleteMqttCallback("deviceIdentify" + device?.deviceId)
        dispatch(mqttActions.deleteMqttRequest(messageSid));
        dispatch(deviceActions.stopLoading(device?.deviceId));
        // unsubscribe(gatewayDetails?.mqttTopics?.comStatus, mqttClient);
        dispatch(appActions?.apiErrorMessage(getMessageStr("deviceIdentify-receive-timeout", (device?.name ? device?.name : "-") + ` (${device?.deviceId})`)))
      }, setMqttTimeout(60000));
    },
      ({ error, code }) => {
        getMessage(`device-get-${code}`).then(delay(500)).then((msg) => {
          dispatch(appActions.apiErrorMessage((device?.name ? device?.name : "-") + ` (${device?.deviceId}) : ` + msg));
        },)
      });
  }
  identifyOperation();
  //confirmationDialog("Device Identify", identifyOperation,"deviceIdentify", setDialog, setDialogContents)
};
export const identifySensor = ({ device, dispatch, mqttClient, ...params }, mqttTopics = null) => {
  dispatch(deviceActions.startLoading(device?.deviceId, params?.shortId));


  const config = {
    cmd: "deviceIdentify",
  };
  if (params?.shortId) {
    config.shortId = params?.shortId;
  }
  const messageSid = mqttRequest(
    {
      subTopic: mqttTopics.comStatus,
      pubTopic: mqttTopics.comControl,
      deviceId: device?.deviceId,
      deviceConfig: config,
      onRecieve: (message) => {
        if (message?.cmd === "deviceIdentify" && message?.deviceId === device?.deviceId) {
          dispatch(deviceActions.stopLoading(message?.deviceId, message?.shortId));
          if (message?.status === "command_accepted") {
            dispatch(appActions?.apiSuccessMessage(getMessageStr(`${message?.cmd}-receive-${message?.status}`, (device?.name ? device?.name : "-") + ` (${device?.deviceId})`)))
          } else {
            dispatch(appActions?.apiErrorMessage(getMessageStr(`${message?.cmd}-receive-${message?.status}`, (device?.name ? device?.name : "-") + ` (${device?.deviceId})`)))
          }
          clearTimeout(timeout);
          // unsubscribe(mqttTopics?.comStatus, mqttClient);
          dispatch(mqttActions.deleteMqttRequest(message?.sid));
        }
      },
    },
    mqttClient,
    dispatch
  );
  const timeout = setTimeout(() => {
    dispatch(deviceActions.stopLoading(device?.deviceId, params?.shortId));
    // unsubscribe(mqttTopics?.comStatus, mqttClient);
    dispatch(mqttActions.deleteMqttRequest(messageSid));
    dispatch(appActions?.apiErrorMessage(getMessageStr("deviceIdentify-receive-timeout", (device?.name ? device?.name : "-") + ` (${device?.deviceId})`)))
  }, setMqttTimeout(60000));

};
export const disableIdentifyDevice = (data, deviceModels) => {
  const model = deviceModels?.find((d) => d?.modelNumber?.substring(d.modelNumber?.length - 3, d.modelNumber?.length) === data?.configuration?.daliDriver || d.modelId === data?.modelId);
  if (model) {
    const modelIdentify = model?.controlCapabilities?.find(
      (c) => c?.capability.toLowerCase().trim() === "deviceidentify"
    );
    if (modelIdentify && !disableDeviceOperation(data, "deviceIdentify")) {
      return false;
    }
  }
  return true;
};
export const disableTemperatureControl = (data, deviceModels) => {
  const model = deviceModels?.find((d) => (d?.modelNumber?.substring(d.modelNumber?.length - 3, d.modelNumber?.length) === data?.configuration?.daliDriver));
  if (model) {
    const modelTemp = model?.controlCapabilities?.find(
      (c) => c?.capability.toLowerCase().trim() === "kelvin"
    );
    if (modelTemp && data?.status === "active") {
      return false;
    }
  }
  return true;
};
export const getSensorTypes = (data, deviceModels) => {
  const model = deviceModels?.find((d) => d.modelId === data?.modelId);
  let types = "";
  if (model) {
    model?.statusCapabilities?.forEach((s, i) => {
      const sensorType = s?.attributes[0]?.key?.toUpperCase()
      if (sensorType === "ALS" || sensorType === "PIR" || sensorType === "TMP") {
        types = i === 0 ? `${sensorType} ` : types + `| ${sensorType} `
      }
    })
  }
  return types;
};

export const startPairing = ({ gatewayDetails, device, dispatch, mqttClient, setDialog, setDialogContents }) => {
  let commissionTimeout = null;
  const startPairingMessageReceived = (message) => {
    if (message?.cmd === "startReceiverPairing") {
      if (message?.status === "command_accepted") {
        dispatch(deviceActions.startPairing(message?.receiverId));
        dispatch(appActions?.apiSuccessMessage(getMessageStr(`${message?.cmd}-receive-${message?.status}`, (device?.name ? device?.name : "-") + ` (${device?.deviceId})`)))
      }
      else if (message?.status === "pairing_ended") {
        dispatch(deviceActions.stopPairing(message?.deviceId));
        // dispatch(mqttActions.deleteMqttRequest(message?.sid));
        // unsubscribe(gatewayDetails?.mqttTopics?.comStatus, mqttClient);
        dispatch(appActions?.apiSuccessMessage(getMessageStr(`${message?.cmd}-receive-${message?.status}`, (device?.name ? device?.name : "-") + ` (${device?.deviceId})`)))
        pairingtimeout[device?.deviceId] && clearTimeout(pairingtimeout[device?.deviceId]);
        setTimeout(() => {
          delete pairingtimeout[device?.deviceId]
        }, 1000)
      }
      else if (message?.status === "pairing_started") {
        dispatch(appActions?.apiSuccessMessage(getMessageStr(`${message?.cmd}-receive-${message?.status}`, (device?.name ? device?.name : "-") + ` (${device?.deviceId})`)))
      }
      else {
        pairingtimeout[device?.deviceId] && clearTimeout(pairingtimeout[device?.deviceId]);
        setTimeout(() => {
          delete pairingtimeout[device?.deviceId]
        }, 1000)
        deleteMqttCallback("startPairing" + device?.deviceId);
        deleteMqttCallback("startPairing" + device?.parentDeviceId);
        // unsubscribe(gatewayDetails?.mqttTopics?.comStatus, mqttClient);
        dispatch(deviceActions.stopPairing(message?.deviceId));
        dispatch(mqttActions.deleteMqttRequest(message?.sid));
        dispatch(appActions?.apiErrorMessage(getMessageStr(`${message?.cmd}-receive-${message?.status}`, (device?.name ? device?.name : "-") + ` (${device?.deviceId})`)))
      }
    }
    else if (message?.cmd === "sensorCommission") {
      if (message?.status === "commissioning_ended") {
        deleteMqttCallback("startPairing" + device?.deviceId);
        deleteMqttCallback("startPairing" + device?.parentDeviceId);
        clearTimeout(commissionTimeout);
        changeDialogContent("success", null, getMessageStr(`${message?.cmd}-receive-${message?.status}`), true, setDialog, setDialogContents);
        dispatch(mqttActions.deleteMqttRequest(message?.sid));
        // unsubscribe(gatewayDetails?.mqttTopics?.comStatus, mqttClient);
      } else if (message.status === "commissioning_started") {
        commissionTimeout = setTimeout(() => {
          // const t = new Date()
          // console.log(t, "timeout end");
          // unsubscribe(gatewayDetails?.mqttTopics?.comStatus, mqttClient);
          // console.log("commission timed out")
          deleteMqttCallback("startPairing" + device?.deviceId);
          deleteMqttCallback("startPairing" + device?.parentDeviceId);
          changeDialogContent("timeout", null, getMessageStr(`${message?.cmd}-receive-response_not_received`), true, setDialog, setDialogContents);
        }, setMqttTimeout());
        // const tn = new Date()
        // console.log(tn, "timeout start");
        changeDialogContent("pending", "Sensor Commission", getMessageStr(`${message?.cmd}-receive-${message?.status}`), false, setDialog, setDialogContents)
        setDialog(true);
      }
      else {
        clearTimeout(commissionTimeout);
        deleteMqttCallback("startPairing" + device?.deviceId);
        deleteMqttCallback("startPairing" + device?.parentDeviceId);
        dispatch(mqttActions.deleteMqttRequest(message?.sid));
        // unsubscribe(gatewayDetails?.mqttTopics?.comStatus, mqttClient);
        changeDialogContent("timeout", null, getMessageStr(`${message?.cmd}-receive-${message?.status}`), true, setDialog, setDialogContents)
      }
    }
  }
  const pairingDurationDialog = () => {
    const cancelDialog = () => {
      setDialog(false);
    }
    const saveDuration = (pairingDuration) => {
      // console.log("PAIIRING DURATION", typeof pairingDuration, typeof parseInt(pairingDuration))
      startPairingOperation(parseInt(pairingDuration));
    }
    setDialogContents((dialogContents) => ({
      ...dialogContents,
      state: "customComponent",
      component: <TableHelper.TablePairingDuration save={saveDuration} cancel={cancelDialog} />,
      content: {
        title: "Set sensor pairing duration",
      },
    }));
    setDialog(true);
  }

  const startPairingOperation = (pairingDuration) => {
    setDialog(false);
    if (_.isArray(gatewayDetails)) {
      const gwDeviceId = device?.gwDeviceId;
      gatewayDetails = gatewayDetails.find((gw) => gw?.deviceId === gwDeviceId);
    }
    // console.log("pairing timeouts", pairingtimeout)

    const messageSid = mqttRequest(
      {
        subTopic: gatewayDetails?.mqttTopics.comStatus,
        pubTopic: gatewayDetails?.mqttTopics.comControl,
        deviceId: device?.parentDeviceId,
        deviceConfig: {
          cmd: "startReceiverPairing",
          receiverId: device?.deviceId,
          durationInSecs: pairingDuration
        },
        onRecieve: startPairingMessageReceived,
      },
      mqttClient,
      dispatch,
      "startPairing"
    );
    pairingtimeout[device?.deviceId] = setTimeout(() => {
      // console.log("pairing timed out")
      dispatch(mqttActions.deleteMqttRequest(messageSid));
      // unsubscribe(gatewayDetails?.mqttTopics?.comStatus, mqttClient);
      deleteMqttCallback("startPairing" + device?.deviceId);
      deleteMqttCallback("startPairing" + device?.parentDeviceId);
      dispatch(deviceActions.stopPairing(device?.deviceId));
      dispatch(appActions?.apiErrorMessage(getMessageStr("startReceiverPairing-receive-timeout", (device?.name ? device?.name : "-") + ` (${device?.deviceId})`)))
      setTimeout(() => {
        delete pairingtimeout[device?.deviceId]
      }, 1000)
    }, setMqttTimeout((pairingDuration * 1000) + 240000));
  }
  confirmationDialog("Start sensor pairing", pairingDurationDialog, "startReceiverPairing", setDialog, setDialogContents)

};


export const stopPairing = (gatewayDetails, { device, dispatch, mqttClient }, setDialog, setDialogContents) => {
  const stopPairingOperation = () => {
    setDialog(false);
    if (_.isArray(gatewayDetails)) {
      const gwDeviceId = device?.gwDeviceId;
      gatewayDetails = gatewayDetails.find((gw) => gw?.deviceId === gwDeviceId);
    }
    pairingtimeout[device?.deviceId] && clearTimeout(pairingtimeout[device?.deviceId])
    setTimeout(() => {
      delete pairingtimeout[device?.deviceId]
    }, 1000)
    // console.log("pairing timeouts", pairingtimeout)

    const messageSid = mqttRequest(
      {
        subTopic: gatewayDetails?.mqttTopics.comStatus,
        pubTopic: gatewayDetails?.mqttTopics.comControl,
        deviceId: device?.parentDeviceId,
        deviceConfig: {
          cmd: "stopReceiverPairing",
          receiverId: device?.deviceId,
        },
        onRecieve: (message) => {
          if (message?.cmd === "stopReceiverPairing") {
            if (message?.status === "pairing_ended") {
              dispatch(deviceActions.stopPairing(message?.deviceId));
              dispatch(mqttActions.deleteMqttRequest(message?.sid));
              deleteMqttCallback("stopPairing" + device?.deviceId);
              deleteMqttCallback("startPairing" + device?.deviceId);
              deleteMqttCallback("startPairing" + device?.parentDeviceId);
              dispatch(mqttActions.deleteMqttRequest({ key: "startPairing", receiverId: device?.deviceId }));
              clearTimeout(timeout)
              // unsubscribe(gatewayDetails?.mqttTopics?.comStatus, mqttClient);
              dispatch(appActions?.apiSuccessMessage(getMessageStr(`${message?.cmd}-receive-${message?.status}`, (device?.name ? device?.name : "-") + ` (${device?.deviceId})`)))
            }
            else if (message?.status === "command_accepted") {
              dispatch(appActions?.apiSuccessMessage(getMessageStr(`${message?.cmd}-receive-${message?.status}`, (device?.name ? device?.name : "-") + ` (${device?.deviceId})`)))
            }
            else {
              clearTimeout(timeout)
              deleteMqttCallback("stopPairing" + device?.deviceId);
              // unsubscribe(gatewayDetails?.mqttTopics?.comStatus, mqttClient);
              dispatch(mqttActions.deleteMqttRequest(message?.sid));
              dispatch(deviceActions.stopPairing(message?.deviceId));
              dispatch(appActions?.apiErrorMessage(getMessageStr(`${message?.cmd}-receive-${message?.status}`, (device?.name ? device?.name : "-") + ` (${device?.deviceId})`)))
            }
          }
        },
      },
      mqttClient,
      dispatch
    );
    const timeout = setTimeout(() => {
      // console.log("stop pairing timed out")
      dispatch(mqttActions.deleteMqttRequest(messageSid));
      deleteMqttCallback("stopPairing" + device?.deviceId);
      dispatch(deviceActions.stopPairing(device?.deviceId));
      dispatch(appActions?.apiErrorMessage(getMessageStr("stopReceiverPairing-receive-timeout", (device?.name ? device?.name : "-") + ` (${device?.deviceId})`)))
    }, setMqttTimeout())
  }
  confirmationDialog("Stop Pairing", stopPairingOperation, "stopReceiverPairing", setDialog, setDialogContents)
};

export const helperdeviceBreadcrumb = (device, url) => {
  return {
    label: device?.name || '',
    url: url ? url : '/devices',
    disabled: false,
    deviceId: device?.deviceId
  };
}
export const changeDialogContent = (state = "success", title = "", content = "", okButton = true, setDialog = () => { }, setDialogContents = () => { }) => {
  setDialogContents((dialogContents) => ({
    ...dialogContents,
    state,
    content: {
      title: title || dialogContents?.content?.title,
      content,

      actions: okButton ? [
        {
          id: "1001",
          type: "button",
          label: "Ok",
          onClick: (event, data) => {
            setDialog(false);
          },
        },
      ] : [],
    },
  }));
  // setDialog(true);
}

export const confirmationDialog = (title, operation, cmd, setDialog, setDialogContents, item = null, actions = null) => {
  setDialogContents((dialogContents) => ({
    ...dialogContents,
    state: "timeout",
    content: {
      title,
      label: "Warning",
      content: getMessageStr(`${cmd}-send-confirm`, item),
      actions: actions === null ? [
        {
          id: "1001",
          type: "button",
          label: "Cancel",
          onClick: (event, data) => {
            setDialog(false);
          },
        },
        {
          id: "1002",
          type: "button",
          label: "Confirm",
          onClick: (event, data) => {
            operation();
          },
        },
      ] : actions,
    },
  }));
  setDialog(true);

}
export const deleteDevice = (data, setDialog, setDialogContents, dispatch, cb = () => { }) => {
  confirmationDialog("Delete Device", () => { dispatch(deviceActions.deleteDevice(data?.deviceId, cb)); setDialog(false) }, "delete", setDialog, setDialogContents, data?.name)
}
export const systemReset = (deviceDetails, gatewayDetails, setDialog = () => { }, setDialogContents = () => { }, dispatch, mqttClient) => {
  let timeout = null;
  const systemResetOperation = () => {
    const mqttMessageRecieved = (message) => {
      //console.log("message received",message);
      if (message?.cmd === "systemReset") {
        if (message?.status === "command_accepted") {
          changeDialogContent("pending", "System Reset", getMessageStr(`${message?.cmd}-receive-${message?.status}`), false, setDialog, setDialogContents);
          // setDialog(true);
        } else if (
          message?.status === "unknown" ||
          message?.status === "low_power" ||
          message?.status === "window_watchdog" ||
          message?.status === "independent_watchdog" ||
          message?.status === "software" ||
          message?.status === "poweron_powerdn" ||
          message?.status === "external_pin" ||
          message?.status === "success"
        ) {
          clearTimeout(timeout);
          // unsubscribe(gatewayDetails?.mqttTopics?.comStatus, mqttClient);
          changeDialogContent("success", "System Reset", getMessageStr(`${message?.cmd}-receive-${message?.status}`), true, setDialog, setDialogContents);
          setDialog(true);
          dispatch(mqttActions.deleteMqttRequest(message?.sid));
        }
        else {
          // unsubscribe(gatewayDetails?.mqttTopics?.comStatus, mqttClient);
          clearTimeout(timeout);
          changeDialogContent("timeout", "System Reset", getMessageStr(`${message?.cmd}-receive-${message?.status}`), true, setDialog, setDialogContents);
          setDialog(true);
          dispatch(mqttActions.deleteMqttRequest(message?.sid));
        }
      }
    };
    changeDialogContent("pending", "System Reset", getMessageStr("systemReset-send-send_mqtt_command"), false, setDialog, setDialogContents);
    setDialog(true);

    const messageSid = mqttRequest(
      {
        subTopic: gatewayDetails?.mqttTopics.comStatus,
        pubTopic: gatewayDetails?.mqttTopics.comControl,
        deviceId: deviceDetails?.deviceId,
        deviceConfig: {
          cmd: "systemReset",
        },
        onRecieve: mqttMessageRecieved,
      },
      mqttClient,
      dispatch,
      "systemReset"
    );
    timeout = setTimeout(() => {
      dispatch(mqttActions.deleteMqttRequest(messageSid));
      changeDialogContent("timeout", null, getMessageStr("systemReset-receive-response_not_received"), true, setDialog, setDialogContents);
      // unsubscribe(gatewayDetails?.mqttTopics?.comStatus, mqttClient);
    }, mqttTimeout);
  }
  confirmationDialog("System Reset", systemResetOperation, "systemReset", setDialog, setDialogContents)
};

export const factoryReset = (deviceDetails, setDialog = () => { }, setDialogContents = () => { }, dispatch, mqttClient, loading = false, cb = null) => {
  // let timeout = null;
  const factoryResetOperation = () => {
    if (cb === null) {
      cb = () => {
        if (deviceDetails?.category === 'VortexGateway' || deviceDetails?.category.includes("Controller") || deviceDetails?.category === "Aggregator") {
          history.goBack();
        }
      }
    }
    dispatch(deviceActions?.factoryReset(deviceDetails?.deviceId, {}, cb));
    setDialog(false);
    //   // console.log("factory reset", gatewayDetails);
    //   const mqttMessageRecieved = (message) => {
    //     //console.log("message received",message);
    //     if (message?.cmd === "factoryReset") {
    //       if (message?.status === "command_accepted") {
    //         // unsubscribe(gatewayDetails?.mqttTopics?.comStatus,mqttClient);
    //         clearTimeout(timeout);
    //         changeDialogContent("success", "Factory Reset", getMessageStr(`${message?.cmd}-receive-${message?.status}`), true, setDialog, setDialogContents);
    //         setDialog(true);
    //         dispatch(mqttActions.deleteMqttRequest(message?.sid));
    //       }
    //       else {
    //         // unsubscribe(gatewayDetails?.mqttTopics?.comStatus,mqttClient);
    //         clearTimeout(timeout);
    //         changeDialogContent("timeout", "Factory Reset", getMessageStr(`${message?.cmd}-receive-${message?.status}`), true, setDialog, setDialogContents);
    //         setDialog(true);
    //         dispatch(mqttActions.deleteMqttRequest(message?.sid));
    //       }

    //     }
    //   };
    //   changeDialogContent("pending", "Factory Reset", getMessageStr("factoryReset-send-send_mqtt_command"), false, setDialog, setDialogContents);
    //   setDialog(true);

    // const messageSid=  mqttRequest(
    //     {
    //       subTopic: gatewayDetails?.mqttTopics.comStatus,
    //       pubTopic: gatewayDetails?.mqttTopics.comControl,
    //       deviceId: deviceDetails?.deviceId,
    //       deviceConfig: {
    //         cmd: "factoryReset",
    //       },
    //       onRecieve: mqttMessageRecieved,
    //     },
    //     mqttClient,
    //     dispatch
    //   );
    //   timeout = setTimeout(() => {
    //         dispatch(mqttActions.deleteMqttRequest(messageSid));

    //     changeDialogContent("timeout", null, getMessageStr("factoryReset-receive-response_not_received"), true, setDialog, setDialogContents)
    //   }, mqttTimeout);

  }
  confirmationDialog("Factory Reset", factoryResetOperation, ((deviceDetails?.status === "inactive" || deviceDetails?.connectionStatus === "Offline") ? "warnFactoryReset" : "factoryReset"), setDialog, setDialogContents, deviceDetails?.name + " " + formatString(deviceDetails?.category, "wordBreak"))
};

export const channelReset = (deviceDetails, gatewayDetails, channelId, setDialog = () => { }, setDialogContents = () => { }, dispatch, mqttClient) => {
  let timeout = null;
  const resetOperation = () => {
    setDialog(true);
    const mqttMessageRecieved = (message) => {
      if (message?.cmd === "daliLoopReset") {
        if (message?.status === "success") {
          clearTimeout(timeout);
          changeDialogContent("success", "Channel Reset", getMessageStr(`${message?.cmd}-receive-${message?.status}`), true, setDialog, setDialogContents);
          setDialog(true);
          dispatch(mqttActions.deleteMqttRequest(message?.sid));
          // unsubscribe(gatewayDetails?.mqttTopics?.comStatus, mqttClient);
        }
        else {
          clearTimeout(timeout);
          changeDialogContent("timeout", "Channel Reset", getMessageStr(`${message?.cmd}-receive-${message?.status}`), true, setDialog, setDialogContents);
          setDialog(true);
          dispatch(mqttActions.deleteMqttRequest(message?.sid));
          // unsubscribe(gatewayDetails?.mqttTopics?.comStatus, mqttClient);
        }
      }
    };
    changeDialogContent("pending", "Channel Reset", getMessageStr("daliLoopReset-send-send_mqtt_command"), false, setDialog, setDialogContents);
    const messageSid = mqttRequest(
      {
        subTopic: gatewayDetails?.mqttTopics?.comStatus,
        pubTopic: gatewayDetails?.mqttTopics?.comControl,
        deviceId: deviceDetails?.deviceId,
        deviceConfig: {
          cmd: "daliLoopReset",
          channelId,
        },
        onRecieve: mqttMessageRecieved,
      },
      mqttClient,
      dispatch
    );
    timeout = setTimeout(() => {
      dispatch(mqttActions.deleteMqttRequest(messageSid));
      changeDialogContent("timeout", null, getMessageStr("daliLoopReset-receive-response_not_received"), true, setDialog, setDialogContents);
      // unsubscribe(gatewayDetails?.mqttTopics?.comStatus, mqttClient);
    }, mqttTimeout);
  };
  confirmationDialog("Channel Reset", resetOperation, "daliLoopReset", setDialog, setDialogContents, channelId === "L01" ? "1" : "2");
};


export const tempControl = (deviceData, value, dispatch, mqttClient, onFail = () => { }, subscribe = true) => {
  dispatch(deviceActions.startLoading(deviceData?.deviceId));
  const messageSid = mqttRequest(
    {
      subTopic: subscribe ? deviceData?.mqttTopics?.status : null,
      pubTopic: deviceData?.mqttTopics?.control,
      // deviceId: deviceData?.parentDeviceId,
      deviceId: deviceData?.deviceId,
      deviceConfig: {
        // lightId: deviceData?.deviceId,
        klv: value,
      },
      onFail: () => {
      },
      onRecieve: (message) => {
        if (message?.deviceId === deviceData?.deviceId) {
          if (message?.status === "invalid_payload") {
            dispatch(appActions.apiErrorMessage(getMessageStr("tempControl-receive-invalid_payload", (deviceData?.name ? deviceData?.name : "-") + ` (${deviceData?.deviceId})`)));
            dispatch(deviceActions.stopLoading(deviceData?.deviceId));
          }
          else if (message?.hasOwnProperty("klv")) {
            updateLightControl(message, dispatch);
            dispatch(appActions.apiSuccessMessage(getMessageStr("tempControl-receive-value", { header: (deviceData?.name ? deviceData?.name : "-") + ` (${deviceData?.deviceId})`, value: message?.klv })));
          }
          else {
            dispatch(deviceActions.stopLoading(deviceData?.deviceId));
            dispatch(appActions.apiErrorMessage(getMessageStr("tempControl-receive-server_error", (deviceData?.name ? deviceData?.name : "-") + ` (${deviceData?.deviceId})`)));
          }
          clearTimeout(timeout);
          // unsubscribe(deviceData?.mqttTopics?.status, mqttClient)
          dispatch(mqttActions.deleteMqttRequest(message?.sid));
        }

      },
    },
    mqttClient,
    dispatch, "lightControl"
  );
  const timeout = setTimeout(() => {
    dispatch(mqttActions.deleteMqttRequest(messageSid));
    deleteMqttCallback("deviceTempControl" + deviceData?.deviceId)
    dispatch(appActions.apiErrorMessage(getMessageStr("tempControl-receive-server_error", (deviceData?.name ? deviceData?.name : "-") + ` (${deviceData?.deviceId})`)));
    dispatch(deviceActions.stopLoading(deviceData?.deviceId));
    // unsubscribe(deviceData?.mqttTopics?.status, mqttClient);
    onFail();
  }, setMqttTimeout(60000))
}
// let timeout=null;
export const updateLightControl = (message, dispatch, deviceList = null) => {
  // const updateControl = () => {
  // console.log("UPDATING REDUX");
  // timeout && clearTimeout(timeout);
  setTimeout(() => {
    Promise.resolve().then(() => {
      dispatch({
        type: deviceTypes.UPDATE_DEVICE_STATUS,
        payload: { deviceId: message?.deviceId, configuration: { intensity: message?.lst, kelvin: message?.klv } },
      });
    }).then(delay(500)).then(() => {
      dispatch(deviceActions.stopLoading(message?.deviceId))
    })
  }, 1000)
  // if (deviceList && deviceList?.find(d => d?.deviceId === message?.deviceId)) {
  //   console.log("DEVICE FOUNDDD", deviceList)
  //   updateControl();
  // }
  // if (deviceList === null) {
  //   updateControl();
  // }
}

export const updateControllerStatus = (message, dispatch) => {
  dispatch({
    type: deviceTypes.UPDATE_CONTROLLER_STATUS,
    payload: { deviceId: message?.deviceId, status: message?.status },
  });
  getMessage(`controller-receive-${message?.status}`).then(delay(500)).then((msg) => {
    if (message?.status === "busy") {
      dispatch(appActions.apiErrorMessage(msg));
    }
    else {
      dispatch(appActions.apiSuccessMessage(msg));
    }
  },)
};

export const updateConnnectivityStatus = (message, dispatch) => {
  dispatch({
    type: deviceTypes.UPDATE_CONNECTIVITY_STATUS,
    payload: message,
  });
  dispatch({
    type: fotaTypes.UPDATE_CONNECTIVITY_STATUS,
    payload: message,
  });

};


export const getCountOfCategory = (array, key) => {
  if (!array) return 0;
  if (key === 'light') {
    return array.filter(d => d['category'].toLowerCase().search('controller') === -1 && d['category'].toLowerCase().search(key) !== -1).length;
  }
  return array.filter(d => d['category'].toLowerCase().search(key) !== -1).length;
}

export const nameEditor = (row, category, sx = {}, dispatch, url = "/spaces/devices?pt=device", enableEdit = true, enableElipsis = true, onNavigate = () => { }) => {
  return <StyledEditor
    customsx={{ textField: { width: "fit-content" }, text: sx }}
    dialog={{ label: "Name" }}
    url={url}
    enableElipsis={enableElipsis && row?.cardView}
    urlParams={{ id: "deviceId", category: "category" }}
    showEdit={enableEdit && !disableDeviceOperation(row?.data, "editName")}
    validation={{
      req: [true, getMessageStr("device-name-required")],
      minLen: [2, getMessageStr("device-name-minlength")],
      maxLen: [30, getMessageStr("device-name-maxlength")],
      alphaNumChars: ["- ", getMessageStr("device-name-invalid")]
    }}
    onNavigate={onNavigate}
    tooltip={{
      content:
        <Box>
          <Box>
            <span>Name - </span><span style={{ color: "#0F8D48" }}>{row?.data?.name}</span>
          </Box>
          <Box>
            <span>Category - </span><span style={{ color: "#0F8D48" }}>{category?.label}</span>
          </Box>
          {
            !disableDevFeatures?.itemId && row?.data?.deviceId &&
            <Box>
              <span>Device Id - </span><span style={{ color: "#0F8D48" }}>{row?.data?.deviceId}</span>
            </Box>
          }
        </Box>
      , show: true
    }}
    data={row?.data} value={row?.data?.name || "-"} saveAction={(val) => {
      dispatch(deviceActions.updateDevice(row?.data?.deviceId, { name: val }))
    }} />
}
export const ipAddressEditor = (data, dispatch) => {
  return <StyledEditor
    customsx={{
      text: {
        width: "fit-content", fontWeight: "400", color: "#000",
        fontSize: "0.813rem",
        lineHeight: "1.188rem",
        fontFamily: 'Montserrat',
      }
    }}
    showEdit={!disableDeviceOperation(data, "editIPAddress")}
    dialog={{ label: "IP Address" }}
    validation={{
      req: [true, getMessageStr("device-ipAddress-required")],
      minLen: [7, getMessageStr("device-ipAddress-minlength")],
      maxLen: [45, getMessageStr("device-ipAddress-maxlength")],
      ipAddress: [true, getMessageStr("device-ipAddress-invalid")]
    }}
    data={data} value={data?.ipAddress || "-"} saveAction={(val) => {
      dispatch(deviceActions.updateDevice(data?.deviceId, { ipAddress: val }))
    }} />
}

export const serialNumberEditor = (data, dispatch) => {
  return <StyledEditor
    customsx={{
      text: {
        width: "fit-content", fontWeight: "400", color: "#000",
        fontSize: "0.813rem",
        lineHeight: "1.188rem",
        fontFamily: 'Montserrat',
      }
    }}
    showEdit={!disableDeviceOperation(data, "editSerialNumber")}
    dialog={{ label: "Serial Number" }}
    validation={{
      req: [true, getMessageStr("device-serialNumber-required")],
      minLen: [6, getMessageStr("device-serialNumber-minlength")],
      maxLen: [50, getMessageStr("device-serialNumber-maxlength")],
      alphaNum: [true, getMessageStr("device-serialNumber-invalid")]
    }}
    data={data} value={data?.serialNumber || "-"} saveAction={(val) => {
      dispatch(deviceActions.updateDevice(data?.deviceId, { serialNumber: val }))
    }} />
}

export const wattageEditor = (data, dispatch) => {
  return <StyledEditor
    data={data}
    customsx={{
      text: {
        width: "fit-content", fontWeight: "400", color: "#000",
        fontSize: "0.813rem",
        lineHeight: "1.188rem",
        fontFamily: 'Montserrat',
      }
    }}
    type="number"
    showEdit={!disableDeviceOperation(data, "editWattage")}
    onHoverEdit={false}
    validation={{
      req: [true, getMessageStr("device-wattage-required")],
      range: [[0, 200], getMessageStr("device-wattage-range")],
      customRegex: [/^-?\d*$/, getMessageStr("device-wattage-whole")]
    }}
    inputProps={{ min: 0, max: 200 }}
    // style={{ minWidth: "100px" }}
    value={data?.wattage || "-"}
    saveAction={(val) => {
      dispatch(deviceActions.updateDevice(data?.deviceId, { wattage: Number(val) }))
    }}
  />
}

export const fwStatusExpiredIcon = (style = {}) => {
  return <Tooltip arrow placement='bottom' title={getMessageStr(`fota-statusExpired-tooltip`)} enterDelay={1500}>
    <Box sx={{ width: "18px", height: "20px" }}>
      <ImageWrapper src={warningIcon} width={18} height={18} style={{ ...style }} />
    </Box>
  </Tooltip>
}
export const getDeviceDetailHeaders = (category = "", data = {}, dispatch = () => { }, model = {}, categoryLabel = "") => {
  const itemHeaders = {
    VortexGateway: {
      ipAddress: {
        label: "Ip Address", type: "component", component: ipAddressEditor(data, dispatch)
      },
      macAddress: { label: 'mac address' },
      fwVersion: { label: 'Gateway application version' },
      "3rdPartyFwVersion": {
        label: "Service Firmware Version", type: "component", component: <Box>
          {data?.hasOwnProperty("3rdPartyFwVersion") && Array.isArray(data["3rdPartyFwVersion"]) && data["3rdPartyFwVersion"]?.map(v => {
            const version = v?.split("-");
            return <Box sx={{ display: "flex" }}>
              <CustomTypography
                weight={400}
                color={"#000"}
                size={13}
                lineHeight={19}
              >{_.capitalize(version[0]) + "  Firmware :"}</CustomTypography>
              <CustomTypography
                styles={{ marginLeft: "4px" }}
                weight={400}
                color={"#000"}
                size={13}
                lineHeight={19}
              >{" Version " + version[1]}</CustomTypography>
            </Box>
          })}
        </Box>
      },
      serialNumber: { label: 'serial number', type: "component", component: serialNumberEditor(data, dispatch) },
      modelNumber: { label: "model number" },
      modelId: { label: "model id" },
      lastUpdatedOn: { label: 'Last updated at' },//convert
      connectionStatus: { label: "connection status", type: "component", component: <TableHelper.TableStatus sx={{ marginTop: "4px" }} type={data?.connectionStatus?.toLowerCase()} label={data?.connectionStatus} /> },
      lastConnectedAt: { label: "Last connectivity status" },
      fwUpdateStatus: {
        label: "Last Firmware update status", type: "component", component:
          <CustomTypography
            weight={400}
            color={"#000"}
            size={13}
            lineHeight={19}
          >
            <Box sx={{ display: "flex" }}>
              {data?.fwUpdateStatus}
              {data?.disableUpgrade &&
                <StyledSpinner type={"line"} size={8}></StyledSpinner>
              }
              {data?.statusExpired &&
                fwStatusExpiredIcon({ marginTop: "8px" })}
            </Box>
          </CustomTypography >

      }
    },
    gatewayDetails: {
      macAddress: {
        label: 'mac address', type: "component", component: <Box>{_.isArray(data?.macAddress) && data?.macAddress?.map(a => <CustomTypography
          weight={400}
          color={"#000"}
          content={a}
          size={13}
          lineHeight={19}
        />)}</Box>
      },
      serialNumber: { label: 'serial number' },
      fwVersion: { label: 'Gateway application version' },
      fwVersion3rdParty: { label: "Dali Firmware Version" },
      // modelNumber: { label: "model number" },
      modelId: { label: "model id" },
      // lastUpdatedOn: { label: 'Last updated at' },//convert
      tenantName: { label: "Tenant name" },
      tenantAssigned: { label: "assigned", type: "component", component: <ImageWrapper height={20} width={20} src={data?.tenantAssigned ? Check : Cross} /> },
      assignedOn: { label: "assigned on" },
      onboarded: { label: "onboarded", type: "component", component: <ImageWrapper height={20} width={20} src={data?.onboarded ? Check : Cross} /> },
      gatewayOnboardedOn: { label: "onboarded on" },
    },
    CAMERA: {
      ipAddress: {
        label: "Ip Address", type: "component",
        component: ipAddressEditor(data, dispatch)
      },
      macAddress: { label: "UUID" },
      fwVersion3rdParty: { label: "firmware Version" },
      serialNumber: { label: 'serial number', type: "component", component: serialNumberEditor(data, dispatch) },
      lastUpdatedOn: { label: 'last updated at' },//convert
      modelNumber: { label: "model number" },
      modelId: { label: "model id" },
      category: { label: "Category", content: categoryLabel },
      // connectionStatus: { label: "connection status", type: "component", component: <TableHelper.TableStatus sx={{ marginTop: "4px" }} type={data?.connectionStatus?.toLowerCase()} label={data?.connectionStatus} /> },
      // lastConnectedAt: { label: "Last connectivity status" },
      // fwUpdateStatus: {
      //   label: "Last Firmware update status", type: "component", component: <CustomTypography
      //     weight={400}
      //     color={"#000"}
      //     size={13}
      //     lineHeight={19}
      //   >
      //     <Box sx={{ display: "flex" }}>
      //       {data?.fwUpdateStatus}
      //       {data?.disableUpgrade &&
      //         <StyledSpinner type={"line"} size={8}></StyledSpinner>
      //       }
      //       {data?.statusExpired &&
      //         fwStatusExpiredIcon({ marginTop: "8px" })}
      //     </Box>
      //   </CustomTypography >
      // }
    },
    DaliLightController: {
      ipAddress: {
        label: "Ip Address", type: "component",
        component: ipAddressEditor(data, dispatch)
      },
      macAddress: { label: "UUID" },
      fwVersion3rdParty: { label: "firmware Version" },
      serialNumber: { label: 'serial number', type: "component", component: serialNumberEditor(data, dispatch) },
      lastUpdatedOn: { label: 'last updated at' },//convert
      modelNumber: { label: "model number" },
      modelId: { label: "model id" },
      category: { label: "Category", content: categoryLabel },
      daliCommissionTs: { label: "dali commissioned at", content: data?.daliCommissionTs ? formatedDate({ date: data?.daliCommissionTs }) : "" },
      sensorCommissionTs: { label: "sensor commissioned at", content: data?.sensorCommissionTs ? formatedDate({ date: data?.sensorCommissionTs }) : "" },
      daliDiscoveryTs: { label: "dali discovered at", content: data?.daliDiscoveryTs ? formatedDate({ date: data?.daliDiscoveryTs }) : "" },
      SensorDiscoveryTs: { label: "sensor discovered at", content: data?.SensorDiscoveryTs ? formatedDate({ date: data?.SensorDiscoveryTs }) : "" },
      connectionStatus: { label: "connection status", type: "component", component: <TableHelper.TableStatus sx={{ marginTop: "4px" }} type={data?.connectionStatus?.toLowerCase()} label={data?.connectionStatus} /> },
      lastConnectedAt: { label: "Last connectivity status" },
      fwUpdateStatus: {
        label: "Last Firmware update status", type: "component", component: <CustomTypography
          weight={400}
          color={"#000"}
          size={13}
          lineHeight={19}
        >
          <Box sx={{ display: "flex" }}>
            {data?.fwUpdateStatus}
            {data?.disableUpgrade &&
              <StyledSpinner type={"line"} size={8}></StyledSpinner>
            }
            {data?.statusExpired &&
              fwStatusExpiredIcon({ marginTop: "8px" })}
          </Box>
        </CustomTypography >
      }
    },
    RelayLightController: {
      ipAddress: {
        label: "Ip Address",
        type: "component",
        component: ipAddressEditor(data, dispatch)
      },
      macAddress: { label: "UUID" },
      fwVersion3rdParty: { label: "firmware Version" },
      serialNumber: { label: 'serial number', type: "component", component: serialNumberEditor(data, dispatch) },
      modelNumber: { label: "model number" },
      modelId: { label: "model id" },
      category: { label: "Category", content: categoryLabel },
      lastUpdatedOn: { label: 'last updated at' },//convert
      sensorCommissionedAt: { label: "sensor commissioned at" },
      sensorDiscoveryTs: { label: "sensor discovery at" },
      connectionStatus: { label: "connection status", type: "component", component: <TableHelper.TableStatus sx={{ marginTop: "4px" }} type={data?.connectionStatus?.toLowerCase()} label={data?.connectionStatus} /> },
      lastConnectedAt: { label: "Last connectivity status" },
      fwUpdateStatus: {
        label: "Last Firmware update status", type: "component", component: <CustomTypography
          weight={400}
          color={"#000"}
          size={13}
          lineHeight={19}
        >
          <Box sx={{ display: "flex" }}>
            {data?.fwUpdateStatus}
            {data?.disableUpgrade &&
              <StyledSpinner type={"line"} size={8}></StyledSpinner>
            }
            {data?.statusExpired &&
              fwStatusExpiredIcon({ marginTop: "8px" })}
          </Box>
        </CustomTypography >
      }
    },
    DaliLightDriver: {
      macAddress: { label: "UUID" },
      fwVersion3rdParty: { label: "firmware Version" },
      serialNumber: { label: 'serial number', type: "component", component: serialNumberEditor(data, dispatch) },
      modelNumber: { label: "model number" },
      modelId: { label: "model id" },
      wattage: { label: 'wattage', type: "component", component: wattageEditor(data, dispatch) },
      category: { label: "Category", content: categoryLabel },
      ...(() => {
        if (data?.category === "DaliLigthDriver") {
          return {
            lastSyncOn: { label: "last synced at", },
          }
        }
      })(),
      lastUpdatedOn: { label: 'Last updated at' },//convert
    },
    Receiver: {
      shortId: { label: "Short ID", content: data?.configuration?.shortId },
      macAddress: { label: "UUID" },
      fwVersion3rdParty: { label: "Firmware version" },
      serialNumber: { label: 'serial number', type: "component", component: serialNumberEditor(data, dispatch) },
      modelId: { label: "model id" },
      category: { label: "Category", content: categoryLabel },
      type: { label: "Receiver type", content: data?.configuration?.type },
      lastSyncAt: { label: "last synced at", },
      connectionStatus: { label: "connection status" },
      lastConnectedAt: { label: "Last connectivity status" },
      bleKeyVersion: {
        label: "ble key version", type: "component", component: <Box>
          <CustomTypography
            weight={400}
            color={"#000"}
            size={13}
            lineHeight={19}
          >
            <Box sx={{ display: "flex" }}>
              {
                data?.hasOwnProperty("bleKeyVersion") ?
                  `Version ${data?.bleKeyVersion}` : "-"
              }
              {
                data?.hasOwnProperty("bleKeyVersion") &&
                <Tooltip title={data?.bleStatusIcon?.tooltip} arrow>
                  <Box>
                    <ImageWrapper width={18} height={18} className={data?.bleStatusIcon?.class} src={data?.bleStatusIcon?.src} />
                  </Box>
                </Tooltip>
              }

            </Box>
          </CustomTypography>


        </Box>

      },
      bleRotationStatus: {
        label: "Ble key sync status", type: "component", component:
          data?.deviceStatus?.hasOwnProperty("bleKeyStatus") ?
            <Box sx={{ display: "flex" }} gap={1}>
              <CustomTypography
                weight={400}
                color={"#000"}
                size={13}
                lineHeight={19}
              >
                {`Version ${data?.deviceStatus?.bleKeyStatus?.bleKeyVersion} : ${data?.deviceStatus?.bleKeyStatus?.status} ${data?.deviceStatus?.bleKeyStatus?.hasOwnProperty("reason") ? "(" + data?.deviceStatus?.bleKeyStatus?.reason + ")" : ""} ${formatedDate({ date: data?.bleKeyStatus?.ts, format: "MMM dd, yyyy 'AT' hh:mm a" })}`}
              </CustomTypography>
            </Box>
            :
            <CustomTypography
              weight={400}
              color={"#000"}
              size={13}
              lineHeight={19}
            >
              {"-"}
            </CustomTypography>
      },
    },
    Sensor: {
      shortId: { label: "Short ID", content: data?.configuration?.shortId },
      macAddress: { label: "UUID" },
      fwVersion3rdParty: { label: "Firmware version" },
      serialNumber: { label: 'serial number', type: "component", component: serialNumberEditor(data, dispatch) },
      modelId: { label: "model id" },
      category: { label: "Category", content: categoryLabel },
      type: { label: "sensor type", content: data?.configuration?.type || "" },
      lastSync: { label: "last sync at" },
      connectionStatus: { label: "connection status", type: "component", component: <TableHelper.TableStatus sx={{ marginTop: "4px" }} type={data?.connectionStatus?.toLowerCase()} label={data?.connectionStatus} /> },
      lastConnectedAt: { label: "Last connectivity status" },
      bleKeyVersion: {
        label: "ble key version", type: "component", component: <CustomTypography
          weight={400}
          color={"#000"}
          size={13}
          lineHeight={19}
        >
          <Box sx={{ display: "flex" }}>
            {
              data?.hasOwnProperty("bleKeyVersion") ?
                `Version ${data?.bleKeyVersion}` : "-"
            }
            {
              data?.hasOwnProperty("bleKeyVersion") &&
              <Tooltip title={data?.bleStatusIcon?.tooltip} arrow>
                <Box>
                  <ImageWrapper width={18} height={18} className={data?.bleStatusIcon?.class} src={data?.bleStatusIcon?.src} />
                </Box>
              </Tooltip>
            }
          </Box>
        </CustomTypography>
      },
      bleRotationStatus: {
        label: "Ble key sync status", type: "component", component:
          data?.deviceStatus?.hasOwnProperty("bleKeyStatus") ?
            <Box sx={{ display: "flex" }} gap={1}>
              <CustomTypography
                weight={400}
                color={"#000"}
                size={13}
                lineHeight={19}
              >
                {`Version ${data?.deviceStatus?.bleKeyStatus?.bleKeyVersion} : ${data?.deviceStatus?.bleKeyStatus?.status} ${data?.deviceStatus?.bleKeyStatus?.hasOwnProperty("reason") ? "(" + data?.deviceStatus?.bleKeyStatus?.reason + ")" : ""} ${formatedDate({ date: data?.bleKeyStatus?.ts, format: "MMM dd, yyyy 'AT' hh:mm a" })}`}
              </CustomTypography>
            </Box>
            :
            <CustomTypography
              weight={400}
              color={"#000"}
              size={13}
              lineHeight={19}
            >
              {"-"}
            </CustomTypography>
      },
    },
    // ODMSENSORHUB: {
    //   shortId: { label: "Short ID", content: data?.configuration?.shortId },
    //   macAddress: { label: "UUID" },
    //   fwVersion3rdParty: { label: "Firmware version" },
    //   serialNumber: { label: 'serial number', type: "component", component: serialNumberEditor(data, dispatch) },
    //   modelId: { label: "model id" },
    //   category: { label: "Category", content: categoryLabel },
    //   lastSync: { label: "last sync at" },
    //   connectionStatus: { label: "connection status", type: "component", component: <TableHelper.TableStatus sx={{ marginTop: "4px" }} type={data?.connectionStatus?.toLowerCase()} label={data?.connectionStatus} /> },
    //   lastConnectedAt: { label: "Last connectivity status" },
    //   bleKeyVersiosn: {
    //     label: "ble key version", type: "component", component: <CustomTypography
    //       weight={400}
    //       color={"#000"}
    //       size={13}
    //       lineHeight={19}
    //     >
    //       <Box sx={{ display: "flex" }}>
    //         {
    //           data?.hasOwnProperty("bleKeyVersion") ?
    //             `Version ${data?.bleKeyVersion}` : "-"
    //         }
    //         {
    //           data?.hasOwnProperty("bleKeyVersion") &&
    //           <Tooltip title={data?.bleStatusIcon?.tooltip} arrow>
    //             <Box>
    //               <ImageWrapper width={18} height={18} className={data?.bleStatusIcon?.class} src={data?.bleStatusIcon?.src} />
    //             </Box>
    //           </Tooltip>
    //         }
    //       </Box>
    //     </CustomTypography>
    //   },
    //   bleRotationStatus: {
    //     label: "Ble key sync status", type: "component", component:
    //       data?.deviceStatus?.hasOwnProperty("bleKeyStatus") ?
    //         <Box sx={{ display: "flex" }} gap={1}>
    //           <CustomTypography
    //             weight={400}
    //             color={"#000"}
    //             size={13}
    //             lineHeight={19}
    //           >
    //             {`Version ${data?.deviceStatus?.bleKeyStatus?.bleKeyVersion} : ${data?.deviceStatus?.bleKeyStatus?.status} ${data?.deviceStatus?.bleKeyStatus?.hasOwnProperty("reason") ? "(" + data?.deviceStatus?.bleKeyStatus?.reason + ")" : ""} ${formatedDate({ date: data?.bleKeyStatus?.ts, format: "MMM dd, yyyy 'AT' hh:mm a" })}`}
    //           </CustomTypography>
    //         </Box>
    //         :
    //         <CustomTypography
    //           weight={400}
    //           color={"#000"}
    //           size={13}
    //           lineHeight={19}
    //         >
    //           {"-"}
    //         </CustomTypography>
    //   },
    // },
    // AQSensorProperties: {
    //   setmp: { label: "Temperature", content: data?.deviceStatus?.status?.setmp + "  °C" || "" },
    //   sehum: { label: "Humidity", content: data?.deviceStatus?.status?.sehum + " %" || "" },
    //   seco2: { label: "Carbon Dioxide", content: data?.deviceStatus?.status?.seco2 + " mg/m3" || "" },
    //   sepm25: { label: "PM 2.5", content: data?.deviceStatus?.status?.sepm25 + " ug/m3" || "" },

    //   ...(() => {
    //     const obj = {}
    //     model?.statusCapabilities?.map(s => {
    //       switch (s?.capability) {
    //         case "occupancy":
    //           obj.pir = { label: "pir", content: data?.deviceStatus?.status?.pir }
    //           break;
    //         case "ambientLight":
    //           obj.als = { label: "als", content: data?.deviceStatus?.status?.als }
    //           break;
    //         case "temperature":
    //           obj.temperature = { label: "temperature", content: data?.deviceStatus?.status?.temperature ? data?.deviceStatus?.status?.temperature + " °C" : "" }
    //           break;
    //         case "humidity":
    //           obj.humidity = { label: "humidity", content: data?.deviceStatus?.status?.humidity ? data?.deviceStatus?.status?.humidity + " %" : "" }
    //           break;
    //       }
    //     })
    //     return obj;
    //   })(),
    // },
    SensorProperties: {
      rssi: { label: "RSSI", content: data?.deviceStatus?.status?.rssi || "" },
      batteryType: {
        label: "battery level", type: "component",
        component: <TableHelper.TableBatteryLevel data={data} deviceModel={model} />
        // component:() => {
        //   return <TableHelper.TableBatteryLevel data={data} /> 
        // }
      },
      ...(() => {
        const obj = {}
        model?.statusCapabilities?.map(s => {
          switch (s?.capability) {
            case "occupancy":
              obj.pir = { label: "pir", content: data?.deviceStatus?.status?.pir }
              break;
            case "ambientLight":
              obj.als = { label: "als", content: data?.deviceStatus?.status?.als }
              break;
            case "temperature":
              obj.temperature = { label: "temperature", content: data?.deviceStatus?.status?.temperature ? data?.deviceStatus?.status?.temperature + " °C" : "" }
              break;
            case "humidity":
              obj.humidity = { label: "humidity", content: data?.deviceStatus?.status?.humidity ? data?.deviceStatus?.status?.humidity + " %" : "" }
              break;
          }
        })
        return obj;
      })(),
    },
    Switch: {
      shortId: { label: "Short ID", content: data?.configuration?.shortId },
      macAddress: { label: "UUID" },
      fwVersion3rdParty: { label: "Firmware version" },
      serialNumber: { label: 'serial number', type: "component", component: serialNumberEditor(data, dispatch) },
      modelId: { label: "model id" },
      category: { label: "Category", content: categoryLabel },
      buttonCount: { label: "type", content: data?.configuration?.buttonCount ? data?.configuration?.buttonCount + " button" : "-" },
      configVersion: { label: "config version", content: data?.configuration?.configVersion },
    },
    Aggregator: {
      ipAddress: {
        label: "Ip Address",
        type: "component",
        component: ipAddressEditor(data, dispatch)
      },
      macAddress: { label: "UUID" },
      fwVersion3rdParty: { label: "firmware Version" },
      serialNumber: { label: 'serial number', type: "component", component: serialNumberEditor(data, dispatch) },
      modelNumber: { label: "model number" },
      modelId: { label: "model id" },
      category: { label: "Category", content: categoryLabel },
      lastUpdatedOn: { label: 'last updated at' },//convert
      connectionStatus: { label: "connection status", type: "component", component: <TableHelper.TableStatus sx={{ marginTop: "4px" }} type={data?.connectionStatus?.toLowerCase()} label={data?.connectionStatus} /> },
      lastConnectedAt: { label: "Last connectivity status" }
    },
  };
  itemHeaders.AnalogLightController = itemHeaders.RelayLightController;
  itemHeaders.DaliAnalogLightController = itemHeaders.DaliLightController;
  itemHeaders.AnalogLightDriver = itemHeaders.DaliLightDriver;
  itemHeaders.RelayLightDriver = itemHeaders.DaliLightDriver;
  return itemHeaders[category];
}
export const disableDeviceOperation = (device, operation, gwList = []) => {
  const connectionStatus = device?.deviceStatus?.connectionStatus ? (device?.deviceStatus?.connectionStatus?.onlineStatus ? "Online" : "Offline") : ((device?.category?.includes("LightDriver") || device?.category?.includes("Switch")) ? "Online" : "Offline");
  const status = device?.status;
  const isGatewayOffline = () => {
    const gw = gwList?.find(g => g?.deviceId === device?.gwDeviceId)
    return gw?.deviceStatus?.connectionStatus?.onlineStatus === true ? false : true
  }
  const disableOperation = {
    "broadcast": { inactive: true, active: false, Offline: true, Online: false },
    "discover": { inactive: true, active: false, Offline: true, Online: false },
    "commission": { inactive: true, active: false, Offline: true, Online: false },
    "resetChannel": { inactive: true, active: false, Offline: true, Online: false },
    "addController": { inactive: true, active: false, Offline: true, Online: false },
    "addAggregator": { inactive: true, active: false, Offline: true, Online: false },
    "editName": { inactive: true, active: false, Offline: false, Online: false },
    "editWattage": { inactive: true, active: false, Offline: false, Online: false },
    "editSerialNumber": { inactive: true, active: false, Offline: false, Online: false },
    "editIPAddress": { inactive: true, active: false, Offline: true, Online: false },
    "addArea": { inactive: true, active: false, Offline: true, Online: false },
    "deviceControl": { inactive: true, active: false, Offline: false, Online: false },
    "deviceIdentify": { inactive: true, active: false, Offline: true, Online: false },
    "deviceConfig": { inactive: true, active: false, Offline: true, Online: false },
    "lightConfig": { inactive: true, active: false, Offline: false, Online: false },
    "receiverPairing": { inactive: true, active: false, Offline: false, Online: false },
    "moveDevice": { inactive: true, active: false, Offline: device?.category?.toLowerCase()?.includes("gateway") ? true : isGatewayOffline(), Online: false },
    "areaSync": { inactive: true, active: false, Offline: true, Online: false },
    "groupSync": { inactive: true, active: false, Offline: true, Online: false },
    "sceneSync": { inactive: true, active: false, Offline: true, Online: false },
    "sceneApply": { inactive: true, active: false, Offline: true, Online: false },
    "syncAll": { inactive: true, active: false, Offline: true, Online: false },
    "deleteAll": { inactive: false, active: false, Offline: true, Online: false },
    "systemReset": { inactive: true, active: false, Offline: true, Online: false },
    "factoryReset": { inactive: false, active: false, Offline: false, Online: false },
    "upgradeFirmware": { inactive: true, active: false, Offline: true, Online: false },

  }

  return disableOperation[operation] ? disableOperation[operation][connectionStatus] || disableOperation[operation][status] : false
}

export const fetchAndSubscribeToGateways = (client, dispatch, tenantName = defaultTenantName) => {
  // console.log("subcribing to gw1")
  dispatch(deviceActions?.getAllDevices({ category: "VortexGateway" }, "gateway", (res) => {
    const gatewayTopics = [];
    Array?.isArray(res) && res?.forEach((r, i) => {
      if (i <= 9) {
        gatewayTopics?.push(r?.mqttTopics?.connectionStatus)
      }
    })
    subscribe(`${tenantName}/+/connectionstatus/VortexGateway/#`, dispatch, client, "subscribing to all gateways connection status", true)
  }))
}

export const getFwUpdateStatus = (msg = {}) => {
  let disableUpgrade = false;
  let status = "Upgrade failed";
  const currentDate = new Date();
  const updateDate = new Date(msg?.ts);
  if (msg?.status?.toLowerCase()?.includes("initiated")) {
    status = "Upgrade initiated";
    disableUpgrade = (currentDate?.getTime() - updateDate?.getTime()) < firmwareUpdateStatusExpiry ? true : false;
  }
  else if (msg?.status === "command_accepted") {
    status = "Upgrade initiated";
    disableUpgrade = (currentDate?.getTime() - updateDate?.getTime()) < firmwareUpdateStatusExpiry ? true : false;
  }
  else if (msg?.status === "download_started") {
    status = "Download started"
    disableUpgrade = (currentDate?.getTime() - updateDate?.getTime()) < firmwareUpdateStatusExpiry ? true : false;
  }
  else if (msg?.status === "download_ended") {
    status = "Download completed"
    disableUpgrade = (currentDate?.getTime() - updateDate?.getTime()) < firmwareUpdateStatusExpiry ? true : false;
  }
  else if (msg?.status === "upgrade_started") {
    status = "Upgrade started";
    disableUpgrade = (currentDate?.getTime() - updateDate?.getTime()) < firmwareUpdateStatusExpiry ? true : false;
  }
  else if (msg?.status === "upgrade_ended") {
    status = "Upgrade completed";
    disableUpgrade = (currentDate?.getTime() - updateDate?.getTime()) < firmwareUpdateStatusExpiry ? true : false;
  }
  else if (msg?.status === "upgrade_successfully") {
    status = "Upgrade completed";
    disableUpgrade = false;
  }
  return ({
    status: `Version ${msg?.fwVersion} : ${status}`,
    disableUpgrade,
    statusExpired: (msg?.status === "upgrade_ended" || msg?.status === "upgrade_successfully") ? false : (currentDate?.getTime() - updateDate?.getTime()) < firmwareUpdateStatusExpiry ? false : true
  })
}
export const updateFwStatus = (msg, dispatch) => {
  const { status, disableUpgrade, statusExpired } = getFwUpdateStatus(msg);
  // dispatch({
  //   type: deviceTypes.UPDATE_DEVICE_FW_STATUS,
  //   payload: { deviceId: msg?.deviceId, status: `Version ${msg?.fwVersion} : ${status}`, fwVersion: msg?.fwVersion }
  // })
  dispatch({
    type: fotaTypes.UPDATE_FW_STATUS,
    payload: { deviceId: msg?.deviceId, status, fwVersion: msg?.fwVersion, disableUpgrade, statusExpired }
  })
  if (msg?.status === "upgrade_ended") {
    dispatch(appActions?.apiSuccessMessage(getMessageStr(`${msg?.cmd}-receive-${msg?.status}`)))
  }
}

export const aggregateAirQualityData = (data, param) => {
  const hourlyDataPoints = {};
  data?.forEach(d => {
    // console.log(formatedDate({ date: d?.ts, format: "hh:mm aa" }));
    const hour = formatedDate({ date: d?.ts, format: "dd MMM, hh aa" });
    if (hourlyDataPoints?.hasOwnProperty(hour)) {
      hourlyDataPoints[hour] = {
        data: hourlyDataPoints[hour]?.data + d?.activity[param],
        count: 1 + hourlyDataPoints[hour]?.count
      }
    }
    else {
      hourlyDataPoints[hour] = { data: d?.activity[param], count: 1 }
    }
  })
  return hourlyDataPoints;
}

