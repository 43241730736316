/***
 *
 * Controller class for user.
 * @file PersonalDetails.js
 * @description PersonalDetails component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import { Box, Grid } from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { countryCodes, features, permissions } from '../../../constants';
import { getMobileNumber, isAuthorised } from '../../../helpers';
import { roleActions } from '../../../redux/actions';
import FormWrapper from '../../FormWrapper';
import './PersonalDetails.scss';
import { useRef } from 'react';
import { getMessageStr } from '../../../helpers';
import StyledSpinner from '../../StyledSpinner';
import StyledButton from '../../StyledButton';
import { containsSpace } from '../../../services';
import Validator from '../../../helpers/validator.helper';
import ResetCred from '../../ResetCred';

const dropdownOptions = {
  dropdowns: {
    tempUser: {
      list: [{ id: "No", label: "No" }, { id: "Yes", label: "Yes" }]
    },
    mobile: {
      list: countryCodes
    }
  }
}
const pValueArray = [
  {
    id: "pass_new",
    type: "password",
    label: "Enter password",
    name: "newPValue",
    error: false,
    errorMsg: "",
    minMaxLen: false,
    reqDigit: false,
    reqSpecial: false,
    reqUpper: false,
    valError: false,
    required: false,
    columnSize: 6,
    sx: {
      fontWeight: 500,
      fontSize: "0.875rem",
    }
  },
  {
    id: "pass_confirm",
    type: "password",
    label: "Re-enter password",
    name: "reEnterPValue",
    error: false,
    required: false,
    errorMsg: "",
    columnSize: 6,
    sx: {
      fontWeight: 500,
      fontSize: "0.875rem",
    }
  },
];


const PersonalDetails = ({ stepCount, btn1Label, handleSubmit = () => { }, handleButton1 = () => { }, userDetails, page, onChange = () => { }, portal = 'mss' }) => {
  const actionButtonsRef = useRef();

  const dispatch = useDispatch();
  const _roles = useSelector(state => state?.roles?.list);
  const [frmOptions, setFrmOptions] = useState(dropdownOptions);
  const [formData, setFormData] = useState({});
  const [frm, setFrm] = useState(null);
  const [loader, showLoader] = useState(page !== 'create');
  const [resetPValue, setResetPValue] = useState({
    newPValue: "",
    reEnterPValue: "",
  });
  const [resetPValueArray, setResetPValueArray] = useState(pValueArray);

  // const [showPassword, setShowPassword] = useState({ password: false });

  // const handleShowPassword = (fieldName) => {
  //   console.log("sammy", showPassword, showPassword[fieldName]);
  //   setShowPassword((prevState) => ({
  //     ...prevState,
  //     [fieldName]: !prevState[fieldName]
  //   }));
  // };

  // useEffect(() => {
  //   console.log("shaun", showPassword["password"]);
  // }, [showPassword])

  const lastNameObj = {
    id: "2",
    type: "text",
    label: "Last Name",
    name: "lastname",
    placeholder: "Enter Last Name",
    required: true,
    validation: {
      req: [true, getMessageStr("user-addUserFormValidation-lastName")],
      minLen: [
        2,
        getMessageStr("user-addUserFormValidation-lastNameMinLength"),
      ],
      maxLen: [
        30,
        getMessageStr("user-addUserFormValidation-lastNameMaxLength"),
      ],
      alpha: [true, getMessageStr("user-addUserFormValidan-lastNameAplhaValidation")],
    },
    columnSize: 4,
  };

  const contactObj = {
    id: "15",
    type: "selectTextField",
    label: "Contact",
    name: "mobile",
    required: true,
    defaultSelect: '+91',
    defaultText: '',
    placeholder: "Enter Mobile",
    disabledOnEdit: false,
    requireOpen: true,
    isNoneAsItem: false,
    textType: 'number',
    hideInputArrows: true,
    label2: !userDetails?.mobileVerified ? 'Not verified!' : 'Verified ✔',
    style: {
      label2: { color: '#0F8D48', textDecoration: 'none', }
    },
    partition: (data) => { return getMobileNumber(data) },
    validation: {
      req: [true, getMessageStr("user-addUserFormValidation-contact")],
      phonereq: [true, getMessageStr("user-addUserFormValidation-contact")],
      phonenumber: ['empty', getMessageStr("user-addUserFormValidation-contactInvalid")],
    },
    columnSize: 4
  }
  const userNameObj = {
    id: "1",
    type: "text",
    label: "Username",
    name: "username",
    placeholder: "Enter username",
    required: true,
    disabledOnEdit: true,
    validation: {
      req: [true, getMessageStr("user-addUserFormValidation-userName")],
      minLen: [2, getMessageStr("user-addUserFormValidation-userNameMinLength")],
      maxLen: [30, getMessageStr("user-addUserFormValidation-userNameMaxLength")],
      nospace: [true, getMessageStr("user-addUserFormValidation-userNameSpaceValidation")],
      alphaNumChars: ['-', getMessageStr("user-addUserFormValidation-userNameAplhaValidation")]
    },
    columnSize: 4
  }
  const loginDetailsRow = {
    rowid: 'row5',
    section: { heading: { style: { padding: "16px", marginTop: "0px", backgroundColor: "rgba(15, 141, 72, 0.05)" }, text: "Login Details" }, style: { margin: "16px", } },
    columns: [
      { ...userNameObj },
      // {
      //   id: "21",
      //   type: showPassword["password"] === true ? "text" : "password",
      //   label: "Password",
      //   name: "password",
      //   required: true,
      //   placeholder: "Enter Password",
      //   InputProps: {
      //     endAdornment: (
      //       <InputAdornment position="end">
      //         {
      //           <StyledButton
      //             iconButton
      //             sx={{
      //               background: '#FFFFFF',
      //               borderRadius: '4px',
      //               marginRight: '-9px',
      //               padding: '5px'
      //             }}
      //             edge="end"
      //             aria-label="Toggle password visibility"
      //             onClick={() => handleShowPassword("password")}
      //           >
      //             {showPassword["password"] ? <VisibilityOn /> : <VisibilityOff />}
      //           </StyledButton>
      //         }
      //       </InputAdornment>
      //     )
      //   },
      //   // validation: {
      //   //   // req: [true, getMessageStr("user-addUserFormValidation-contact")],
      //   //   // phonereq: [true, getMessageStr("user-addUserFormValidation-contact")],
      //   //   phonenumber: ['empty',getMessageStr("user-addUserFormValidation-contactInvalid")],
      //   // },
      //   columnSize: 4,
      // },
      // {
      //   id: "22",
      // dont use showPassword, change name 
      //   type: showPassword["resetPassword"] === true ? "text" : "password",
      //   label: "Confirm Password",
      //   name: "confirmPassword",
      //   required: true,
      //   placeholder: "Re-Enter Password",
      //   InputProps: {
      //     endAdornment: (
      //       <InputAdornment position="end">
      //         {
      //           <StyledButton
      //             iconButton
      //             sx={{
      //               background: '#FFFFFF',
      //               borderRadius: '4px',
      //               marginRight: '-9px',
      //               padding: '5px'
      //             }}
      //             edge="end"
      //             aria-label="Toggle password visibility"
      //             onClick={() => handleShowPassword("resetPassword")}
      //           >
      //             {showPassword["resetPassword"] ? <VisibilityOn /> : <VisibilityOff />}
      //           </StyledButton>
      //         }
      //       </InputAdornment>
      //     )
      //   },
      //   // validation: {
      //   //   // req: [true, getMessageStr("user-addUserFormValidation-contact")],
      //   //   // phonereq: [true, getMessageStr("user-addUserFormValidation-contact")],
      //   //   phonenumber: ['empty',getMessageStr("user-addUserFormValidation-contactInvalid")],
      //   // },
      //   columnSize: 4
      // }
    ],
  }


  const empIdObj = {
    id: "16",
    type: "text",
    label: "Employee ID",
    name: "employeeId",
    placeholder: "Enter Employee ID",
    // required: true,
    disabledOnEdit: false,
    columnSize: 4,
    validation: {
      // req: [true, getMessageStr("user-addUserFormValidation-employeeId")],
      minLen: [2, getMessageStr("user-addUserFormValidation-employeeIdMinLength")],
      maxLen: [30, getMessageStr("user-addUserFormValidation-employeeIdMaxLength")],
      alphaNumChars: ['-', getMessageStr("user-addUserFormValidation-employeeIdInvalid")],
    },
  }

  const empExtraRowObj = [
    {
      id: "18",
      type: "select",
      label: "Temporary User",
      name: "tempUser",
      defaultValue: "No",
      isNoneAsItem: false,
      options: [],
      columnSize: 4
    },
    {
      id: "19",
      type: "datetimepicker",
      picker: "datePicker",
      required: frm?.tempUser && frm['tempUser'] === 'Yes',
      label: "Temporary User Expiry",
      disabledOnEdit: frm?.tempUser && frm['tempUser'] === 'Yes' ? false : true,
      disabledOnCreate: frm?.tempUser && frm['tempUser'] === 'Yes' ? false : true,
      disableHighlightToday: true,
      disablePast: true,
      defaultValue: frm?.tempUser === 'No' ? { required: true, value: null } : {},
      customsx: {
        datePicker: {
          '.css-9ltu22-MuiInputBase-root-MuiOutlinedInput-root.Mui-error.MuiOutlinedInput-notchedOutline': {
            borderColor: '#C4C4C4'
          }
        }
      },
      validation: {
        req: [frm?.tempUser && frm['tempUser'] === 'Yes', getMessageStr("user-addUserFormValidation-tempExpiryDate")]
      },
      name: "userExpiry",
      columnSize: 4
    },
  ]

  const formObjArray = [
    {
      rowid: 'row1',
      section: { heading: portal === "mss" ? { style: { padding: "16px", marginTop: "0px", backgroundColor: "rgba(15, 141, 72, 0.05)" }, text: "User Details" } : null, style: portal === "mss" ? { margin: "16px" } : {} },
      columns: [
        {
          id: "11",
          type: "text",
          label: portal === 'tms' ? "First Name" : "Name",
          name: "name",
          placeholder: portal === 'tms' ? "Enter First Name" : 'Enter Name',
          required: true,
          validation: {
            req: [true, getMessageStr(`user-addUserFormValidation-${portal === 'tms' ? "firstName" : "Name"}`)],
            minLen: [2, getMessageStr(`user-addUserFormValidation-${portal === 'tms' ? "firstNameMinLength" : "NameMinLength"}`)],
            maxLen: [30, getMessageStr(`user-addUserFormValidation-${portal === 'tms' ? "firstNameMaxLength" : "NameMaxLength"}`)],
            alpha: [true, getMessageStr(`user-addUserFormValidation-${portal === 'tms' ? "firstNameAplhaValidation" : "NameAplhaValidation"}`)],
          },
          columnSize: 4,
        },
        {
          id: "3",
          type: "text",
          label: "Email",
          name: "email",
          placeholder: "Type email and press enter to add more emails",
          visibleIn: ["create", "edit"],
          required: true,
          // placeholder: "Enter",
          disabledOnEdit: false,
          style: {
            label2: { color: '#0F8D48', }
          },
          validation: {
            req: [true, getMessageStr("user-addUserFormValidation-email")],
            email: [
              true,
              getMessageStr("user-addUserFormValidation-emailInvalid"),
            ],
          },
          columnSize: 4,
        },
        {
          id: "17",
          type: "chipInput",
          label: "Permissions",
          name: "roles",
          required: true,
          chipsPosition: "right",
          placeholder: "Select Roles",
          visibleIn: ["edit", "view", "create"],
          validation: {
            req: [true, getMessageStr("user-addUserFormValidation-addPermission")],
          },
          options: [],
          columnSize: 12,
        },
      ],
    },
    // {
    //   ...empExtraRowObj
    // }    ,

  ];


  const actionButtons = [{
    heading: null,
    columns: [
      {
        heading: null,
        buttons: [
          {
            id: "cancel",
            type: "button",
            size: 'medium',
            variant: 'outlined',
            display: page === 'edit' ? true : false,
            checkValidation: false,
            label: "Cancel",
            onClick: (e, data) => {
              handleButton1(page, stepCount);
            }
          },
          {
            id: "save",
            type: "button",
            size: 'medium',
            color: 'primary',
            checkValidation: page === 'edit' ? true : false,
            display: page !== 'create',
            label: page === 'edit' ? 'Save' : 'Edit',
            onClick: (e, data) => {
              if (page === 'edit') { handleSubmit(); }
              else
                handleButton1(page, stepCount);
            }
          }
        ],
        columnSize: 12,
      },
    ],
  },]

  const [frmConfig, setFrmConfig] = useState(JSON.parse(JSON.stringify(formObjArray)))

  const handlePValueChange = (e) => {
    const target = e.target;
    setResetPValue({
      ...resetPValue,
      [target.name]: target.value,
    });

    let arr = []
    arr = resetPValueArray?.map(p => {
      if (p?.name === target?.name && ["pass_new", "newPValue"].includes(target?.name) && !_.isEmpty(target?.value)) {
        p["minMaxLen"] = _.isEmpty(Validator(target.value, "text", { minLen: [8, ""] })) && _.isEmpty(Validator(target.value, "text", { maxLen: [20, ""] }));
        p["reqSpecial"] = /.*[#@_-]/.test(target.value);
        p["reqDigit"] = /.*[0-9]/.test(target.value);
        p["reqUpper"] = /.*[A-Z]/.test(target.value);
        p["valError"] = !p?.minMaxLen || !p?.reqSpecial || !p?.reqDigit || !p?.reqUpper;
      }
      else if (p?.name === target?.name && ["pass_new", "newPValue"].includes(target?.name) && _.isEmpty(target?.value)) {
        p["valError"] = false;
      }
      p.error = false;
      p["errorMsg"] = ``;
      return p;
    }
    )
    setResetPValueArray(arr);
  };

  const createUser = (e) => {
    if (
      (!_.isEmpty(resetPValue["newPValue"]) && _.isEmpty(resetPValue["reEnterPValue"]) || (_.isEmpty(resetPValue["newPValue"]) && !_.isEmpty(resetPValue["reEnterPValue"])))) {
      const arr = resetPValueArray.map((item) => {
        if (_.isEmpty(resetPValue[item?.name])) {
          item["error"] = true;
          item["errorMsg"] = `Given field cannot be empty`;
        }
        return item;
      });
      setResetPValueArray(arr);
    }
    else if (
      resetPValue["newPValue"] &&
      resetPValue["reEnterPValue"] &&
      resetPValue["newPValue"] !== resetPValue["reEnterPValue"]
    ) {
      const arr = resetPValueArray.map((item) => {
        item["error"] = true;
        item["errorMsg"] = `Passwords do not match`;
        return item;
      });
      setResetPValueArray(arr);
    }
    else if (
      containsSpace(resetPValue["newPValue"]) ||
      containsSpace(resetPValue["reEnterPValue"])
    ) {
      const arr = resetPValueArray.map((item) => {
        if (containsSpace(resetPValue[item?.name])) {
          item["error"] = true;
          item["errorMsg"] = `Password cannot contain whitespace`;
        }
        return item;
      });
      setResetPValueArray(arr);
    }
    else if (resetPValueArray?.find(p => p?.name === "newPValue")?.valError === true) {
      const arr = resetPValueArray?.map((p) => {
        if (p?.name === "newPValue") {
          p.error = true;
          p.errorMsg = "Password must satisfy the provided conditions"
        }
        return p;
      });
      setResetPValueArray(arr);
    }
    actionButtonsRef?.current?.handleButton(e, {
      checkValidation: true,
      onClick: (event, data) => {
        if (resetPValueArray?.filter(p => p?.error === true)?.length === 0 && !resetPValueArray?.find(p => p?.name === "newPValue")?.valError) {
          handleSubmit(resetPValue);
        }
      }
    })

  }

  const findField = (fields, field, def) => {
    return (Array.isArray(fields) && fields.find(function (o) { return o.key === field })) ? fields.find(function (o) { return o.key === field })?.value || '' : def;
  }

  useEffect(() => {
    isAuthorised(features?.role) && dispatch(roleActions.getAllRoles(portal));
  }, []);

  useEffect(() => {
    let arr = JSON.parse(JSON.stringify(formObjArray));
    if (portal === 'mss') {
      // let sectioncol1 = arr.find(rid => rid?.rowid === 'row1')?.columns;
      arr[0]?.columns?.splice(1, 0, empIdObj)
      arr[0]?.columns?.splice(2, 0, { columnSize: 4 })
      // sectioncol1.push(empIdObj); /// to push from beginning
      // let sectioncol12 = arr.find(rid => rid?.rowid === 'row2')?.columns;
      // sectioncol12.push(contactObj);
      arr[0]?.columns?.splice(3, 0, contactObj)
      arr[0]?.columns?.splice(5, 0, { columnSize: 4 })

      // arr?.splice(2, 0, { ...empExtraRowObj });
      empExtraRowObj?.forEach(e => {
        arr[0]?.columns?.splice(arr[0]?.columns?.length - 1, 0, e)
      })
      arr.push({ ...loginDetailsRow });

      // if (page === "create") {
      // arr?.splice(2, 0, { ...loginDetailsRow });
      // }
      // else {
      //   // arr?.filter(a => a?.rowid !== "row3");
      //   arr?.splice(2, 0, { ...empExtraRowObj });
      // }
    }
    else {
      let sectioncol1 = arr.find(rid => rid?.rowid === 'row1')?.columns;
      sectioncol1?.splice(1, 0, lastNameObj);
      sectioncol1?.splice(2, 0, { columnSize: 2 })
      // let sectioncol12 = arr.find(rid => rid?.rowid === 'row2')?.columns;
      sectioncol1?.splice(4, 0, userNameObj);
      sectioncol1?.splice(5, 0, { columnSize: 4 });
      sectioncol1?.splice(6, 0, { ...contactObj });

      // arr?.splice(2, 0, { section: null, columns: [{ ...contactObj }] })
    }
    setFrmConfig([...arr]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page,]);

  // useEffect(()=>{
  //   setFrmConfig(frmConfig)
  // },[])




  useEffect(() => {
    // console.log('user details===>', userDetails);
    if (!_.isEmpty(userDetails)) {
      let roles = _roles?.filter(role => userDetails?.roles?.includes(role?.name))?.map(item => item?.name);
      console.log("roles", _roles, roles, "user", userDetails)
      setFormData({
        ...userDetails,
        // roles,
        employeeId: findField(userDetails?.fields, 'employeeId', ''),
        tempUser: findField(userDetails?.fields, 'tempUser', 'No') || 'No',
        userExpiry: findField(userDetails?.fields, 'userExpiry', ''),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails])

  useEffect(() => {
    // console.log('payload frm:::', frm);
    if (!_.isEmpty(frm)) {
      onChange(frm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frm])

  useEffect(() => {
    if (frm?.tempUser && portal === 'mss') {
      let arr = frmConfig;
      let obj = {
        id: "19",
        type: "datetimepicker",
        picker: "datePicker",
        required: frm?.tempUser && frm['tempUser'] === 'Yes',
        label: "Temporary User Expiry",
        disabledOnEdit: frm?.tempUser && frm['tempUser'] === 'Yes' ? false : true,
        disabledOnCreate: frm?.tempUser && frm['tempUser'] === 'Yes' ? false : true,
        disableHighlightToday: true,
        disablePast: true,
        defaultValue: frm?.tempUser === 'No' ? { required: true, value: null } : {},
        customsx: {
          datePicker: {
            '.css-9ltu22-MuiInputBase-root-MuiOutlinedInput-root.Mui-error.MuiOutlinedInput-notchedOutline': {
              borderColor: '#C4C4C4'
            }
          }
        },
        validation: {
          req: [frm?.tempUser && frm['tempUser'] === 'Yes', 'Date is required']
        },
        name: "userExpiry",
        columnSize: 4
      }
      arr[0]?.columns?.splice(7, 1, obj)
      // arr.splice(3, 1, obj); cannot do splice as position of object will not be constant
      // arr = arr?.map(a => {
      //   if (a?.rowid === "row4") {
      //     return obj;
      //   }
      //   return a;
      // })
      // if (page !== "create") {
      //   arr = arr?.filter(a => a?.rowid !== "row3");
      // }

      setFrmConfig([...arr]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frm?.tempUser])

  useEffect(() => {
    setFrmOptions({
      ...frmOptions, dropdowns: {
        ...frmOptions?.dropdowns, roles: {
          list: _roles && _roles?.length > 0 ? _roles?.filter(item => item.name)?.map(item => { return { id: item.name, label: item.name, value: item?.name } }) : []
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_roles]);

  useEffect(() => {
    if (page === 'view' && _.isEmpty(formData)) {
      showLoader(true);
    }
  }, [page, formData])

  useEffect(() => {
    if (loader) {
      const timer = setTimeout(() => {
        showLoader(false);
      }, 3000)

      return () => {
        clearTimeout(timer);
      }
    }
  }, [loader])


  return (
    <Grid container className="PersonalDetails" data-testid="PersonalDetails">
      {loader ? <StyledSpinner size={30} sx={{ position: 'relative', left: '50%', top: '20vh' }} /> :
        <Grid item container xs={12}>
          <FormWrapper
            ref={actionButtonsRef}
            operation={page}
            formData={formData}
            setPayload={setFrm}
            formFields={frmConfig}
            formButtons={portal === "mss" ? isAuthorised(features?.user, permissions?.edit) ? actionButtons : [] : actionButtons}
            customsx={{ formbtn: { mt: 2 } }}
            formOptions={frmOptions}
          >
            {
              page === "create" && portal === "mss" &&
              <Box sx={{ width: "67%", ml: 2 }}>
                <ResetCred
                  spacing={3}
                  handlePValueChange={handlePValueChange}
                  isdisabled={false}
                  showResetPasswordButton={false}
                  pArray={resetPValueArray}
                  resetPValue={resetPValue}
                  buttonLabel="Change Password"
                />
              </Box>
            }
            {page !== 'view' &&
              <Box sx={{ width: "100%", mt: 1 }}>
                <label className='mandatoryLabel'>Fields marked <label style={{ color: "#BC2200" }}>*</label> are mandatory.</label>
              </Box>
            }
          </FormWrapper>

        </Grid>
      }
      {
        page === "create" &&
        <Grid container item xs={12} justifyContent={"flex-end"}>
          <Grid item>
            <StyledButton onClick={createUser}>
              {"Create"}
            </StyledButton>
          </Grid>
        </Grid>
      }
    </Grid>
  )
};

PersonalDetails.propTypes = {};

PersonalDetails.defaultProps = {};

export default PersonalDetails;
