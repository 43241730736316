import { Box } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "../../../hooks";
import { deviceActions } from "../../../redux/actions/device.actions";
import FormWrapper from "../../FormWrapper";
import { disableDeviceOperation, getMessageStr } from "../../../helpers";
import StyledButton from "../../StyledButton";



// const disableField = (name) => {
//   console.log("DISABLING")
//   const config=[...formConfig];
//   const frmConfig = [...config?.map((f) => {
//     if (f?.columns?.find(c => c?.name === name)) {
//       f.columns = [...f?.columns?.map(c => {
//         return {...c,disabled:c?.name===name?true:false};
//       })]
//     }
//     return {...f};
//   })]
//   return frmConfig;
// }


// const ModelControllerCategory = {
//   "DaliLightController": "101101",
//   "DaliAnalogLightController": "110103",
//   "AnalogLightController": "110102",
//   "RelayLightController": "110104"
// }

const initialState = {
  name: "",
  serialNumber: "",
  category: "",
  macAddress: "",
  ipAddress: "",
  brand: "",
};


function AddToolbar({ setDialogBox, setDialogBoxContents, classes,deviceDetails, ...props }) {

  // const [frm, setFrm] = useState(initialState);
  // const [ipError, setIpError] = React.useState(false);
  // const [macError, setMacError] = React.useState(false);
  // const [categoryError, setCategoryError] = React.useState(false);
  // const [nameError, setNameError] = React.useState(false);

  // const { parentId } = useParams();
  const query = useQuery();
  const parentId = query.get("id") || null;
  const [deviceType, setDeviceType] = useState('controller');
  // const openMenu = Boolean(anchorEl);
  // const deviceModels = useSelector((state) => state.devices?.deviceModels?.filter((d) => d?.category?.includes("Controller")));
  const deviceCategories = useSelector((state) => state.devices?.deviceCategories?.filter((d) => d?.category?.includes("Controller")));
  // const [frmOptions, setFrmOptions] = useState(null);
  const [actionButtons] = useState([
    {
      heading: null,
      columns: [
        {
          buttons: [
            {
              id: "1000",
              type: "button",
              display: true,
              variant: 'outlined',
              label: "Cancel",
              onClick: (event, data) => {
                handleClose();
              }
            },
            {
              id: "1000",
              type: "button",
              display: true,
              checkValidation: true,
              label: "Save",
              onClick: (event, data) => {
                submitHandler(event, data);
              },
            },
          ],
          columnSize: 12,
        },
      ],
    },
  ]);
  const dispatch = useDispatch();
  const formConfig = (deviceType) => {
    return [
      {
        section: { heading: null },
        columns: [
          {
            id: "1",
            type: "text",
            label: "Name",
            name: "name",
            placeholder: "",
            required: true,
            validation: {
              req: [true, getMessageStr("device-name-required")],
              minLen: [2, getMessageStr("device-name-minlength")],
              maxLen: [30, getMessageStr("device-name-maxlength")],
              alphaNumChars: ["- ", getMessageStr("device-name-invalid")]
            },
            columnSize: 6,
          },
          {
            id: "2",
            type: "text",
            label: "Serial Number",
            name: "serialNumber",
            placeholder: "",
            required: true,
            validation: {
              req: [true, getMessageStr("device-serialNumber-required")],
              minLen: [6, getMessageStr("device-serialNumber-minlength")],
              maxLen: [50, getMessageStr("device-serialNumber-maxlength")],
              alphaNum: [true, getMessageStr("device-serialNumber-invalid")]
            },
            columnSize: 6,
          },
          {
            id: "3",
            type: "select",
            label: "Category",
            disabled: deviceType === "controller" ? false : true,
            name: "category",
            placeholder: "",
            required: true,
            options: [],
            columnSize: 6,
            validation: {
              req: [true, 'Category cannot be empty']
            }
          },
          {
            id: "4",
            type: "text",
            label: "MAC Address",
            name: "macAddress",
            placeholder: "",
            required: true,
            validation: {
              req: [true, getMessageStr("device-mac-required")],
              macAddress: [true, getMessageStr("device-mac-invalid")],
              minLen: [17, getMessageStr("device-mac-minlength")],
              maxLen: [24, getMessageStr("device-mac-maxlength")],
            },
            columnSize: 6,
          },
          {
            id: "5",
            type: "text",
            label: "IP Address",
            name: "ipAddress",
            placeholder: "",
            required: true,
            validation: {
              req: [true, getMessageStr("device-ipAddress-required")],
              minLen: [7, getMessageStr("device-ipAddress-minlength")],
              maxLen: [45, getMessageStr("device-ipAddress-maxlength")],
              ipAddress: [true, getMessageStr("device-ipAddress-invalid")]
            },
            columnSize: 6,
          },
          {
            id: "6",
            type: "text",
            label: "Brand",
            name: "brand",
            placeholder: "",
            required: true,
            validation: {
              req: [true, getMessageStr("device-brand-required")],
              minLen: [3, getMessageStr("device-brand-minlength")],
              maxLen: [30, getMessageStr("device-brand-maxlength")],
              alpha: [true, getMessageStr("device-brand-invalid")]
            },
            columnSize: 6,
          },
        ],
      },
    ];
  }
  const handleClick = (event, device) => {

    let opt = {};
    let formData = {}
    if (device === 'controller') {
      formData = { ...initialState }
      // setFrm(formData);
      opt["dropdowns"] = {
        category: {
          list: deviceCategories && deviceCategories?.length > 0 ? deviceCategories?.map(item => { return { id: item?.category, label: item?.label } }) : []
        }
      };
    }
    else {
      formData = { ...initialState, category: "Aggregator" }
      // setFrm(formData);
      opt["dropdowns"] = {
        category: {
          list: [{ id: 'Aggregator', label: 'Aggregator' }]
        }
      };

    }
    // setFrmOptions(opt);
    setDeviceType(device);
    setDialogBoxContents((dialogBoxContents) => ({
      ...dialogBoxContents,
      title: device === "controller" ? "Controllers" : "Aggregators",
      state: "component",
      component: <Box className={classes?.deviceform}>
        <FormWrapper formFields={formConfig(device)} formButtons={actionButtons} formOptions={opt} formData={formData} customsx={{formbtn:{my:2}}}/>
      </Box>,
    }));
    setDialogBox(true);
  };


  const submitHandler = (e, data) => {
    e.preventDefault();
    let payload = { ...data };
    dispatch(deviceActions.addDevice(parentId, payload));
    setDialogBox(false);
    setDeviceType('controller');
    // setFrm(initialState);
    // }
    // await dispatch(addData(payload, parentId));
  };

  // const handleChange = (e) => {
  //   const target = e.target;
  //   setFrm({ ...frm, [target.name]: target.value });
  //   if (target.name === 'firstName') setNameError(false);
  //   if (target.name === "ipAddress") setIpError(false);
  //   if (target.name === "macAddress") setMacError(false);
  //   if (target.name === "category") setCategoryError(false);
  //   console.log('frm control====>', frm);

  // };

  const handleClose = () => {
    setDialogBox(false);
    setDeviceType('controller');
    // setFrm(initialState);
    // setIpError(false);
    // setMacError(false);
    // setNameError(false);
    // setCategoryError(false);
  };

  return (
    <Box className="toolbar">

      <StyledButton
        style={{ marginLeft: "8px" }}
        variant="outlined"
        onClick={(e) => handleClick(e, 'aggregator')}
        disabled={disableDeviceOperation(deviceDetails,"addAggregator")}
      >
        Add Aggregator
      </StyledButton>
      <StyledButton
        variant="outlined"
        style={{ marginLeft: "8px" }}
        onClick={(e) => handleClick(e, 'controller')}
        disabled={disableDeviceOperation(deviceDetails,"addController")}
      >
        Add Controller
      </StyledButton>
    </Box>
  );
}

export default AddToolbar;
