import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from './reducers';
import { NODE_ENV } from '../config/appConfig';

const loggerMiddleware = createLogger();

let middleware = [];
if (NODE_ENV === 'development' || NODE_ENV === 'dev') {
    middleware = [thunkMiddleware, loggerMiddleware];
} else {
    middleware = [thunkMiddleware];
}
  

export const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(
        ...middleware
    ))
);