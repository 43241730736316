/***
 *
 * Controller class for user.
 * @file StyledEditor.js
 * @description StyledEditor component
 * @author Ayesha A Kumar
 * @since 08 nov 2022
 * 
 */

import React from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './StyledEditor.style.js';
import './StyledEditor.scss';
import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';
import ImageWrapper from '../ImageWrapper/index.js';
import Save from "../../assets/icons/save.svg";
import Edit from "../../assets/icons/edit-2.svg"
import Cancel from "../../assets/icons/cancel-2.svg"
import StyledTextfield from '../StyledTextfield/index.js';
import { Link } from 'react-router-dom';
import { getUrl } from '../../services/util.service.js';
import Validator from '../../helpers/validator.helper.js';
import StyledPopupWrapper from '../StyledPopupWrapper/index.js';
import CustomLabel from '../CustomLabel/index.js';
import PopoverContainer from '../PopoverContainer/index.js';
import { isAuthorised } from '../../helpers/users.helper.js';
import { features, permissions } from '../../constants/permission.constants.js';
import StyledButton from '../StyledButton/index.js';


const StyledEditor = ({ tooltip = { show: false, content: null }, value = "", inputProps = {}, image, saveAction = () => { }, cancelAction = () => { }, onEdit = () => { }, data, url = null, urlParams = null, showEdit = true, defaultOpen = false, validation = {}, type = "text", onHoverEdit = true, errorPopup = false, inlineEditor = false, dialog = { label: null, title: "" }, enableElipsis = false, displayLength = 13, customsx = { editIcon: {}, text: {}, textField: {}, container: {} }, onNavigate = () => { }, ...props }) => {
  const classes = useStyles();
  const [edit, setEdit] = useState(defaultOpen || false);
  const [error, setError] = useState(false);
  const [txtValue, setTxtValue] = useState(value);
  const [popupTxtValue, setPopupTxtValue] = useState(value);

  const [openPopover, setOpenPopover] = useState({ target: null });
  const [dialogBox, setDialogBox] = useState(null);//styledpopupwrapper variable
  const [dialogBoxContents, setDialogBoxContents] = useState(null);
  // const textRef = useRef();

  const save = () => {
    const err = Validator(txtValue, type, validation)
    setError(err)
    if (!err) {
      saveAction(txtValue);
      setEdit(false);
      onEdit(false);
    }
  }

  const cancel = () => {
    cancelAction(txtValue);
    setDialogBox(false)
    setTxtValue(value);
    setError(false);
    setEdit(false);
    onEdit(false);
  }
  const handleChange = (e) => {
    const target = e.currentTarget;
    setTxtValue(target.value);
    setError(Validator(target?.value, type, validation));
  }


  useEffect(() => {
    // setEdit(false)
    setError(false)
  }, []);

  useEffect(() => {
    setPopupTxtValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogBox])

  useEffect(() => {
    setTxtValue(value);
    setPopupTxtValue(value);
  }, [value, edit]);


  return (
    <Box className={classes?.StyledEditor || ""} data-testid="StyledEditor" sx={{ ...customsx?.container }} {...props}>
      {/* {JSON.stringify(sx)} */}
      {edit ? (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <StyledTextfield
            // ref={textRef}
            error={error ? true : false}
            required
            type={type}
            value={txtValue}
            onChange={handleChange}
            inputProps={inputProps}
            errorPopup={errorPopup}
            helperText={error}
            sx={{
              '& .MuiOutlinedInput-input': {
                padding: "2px 5px !important",
                borderRadius: "3px",
                height: "auto !important",
                // backgroundColor: "#f7f7f7"
              },
              '& fieldset': {
                borderRadius: "0",
                // border: "none"
              },
              '& .MuiFormHelperText-root': {
                lineHeight: "16px"
              },
              ...customsx?.textField
            }}></StyledTextfield>
          <Box sx={{ pl: 0.5, display: "flex", alignItems: "baseline" }}>
            <StyledButton
              iconButton
              sx={{ p: "0px 0px 0px 8px" }}
              onClick={save}
              name="saveButton">
              <ImageWrapper width={12} height={12} src={Save} />
            </StyledButton>
            <StyledButton
              sx={{ p: "0px 0px 0px 8px" }}
              iconButton
              onClick={cancel}
              name="cancelButton">
              <ImageWrapper width={12} height={12} src={Cancel} />
            </StyledButton>
          </Box>
        </Box>
      ) : (
        <Box className="hoverEdit" id={data?.deviceId}>
          <Box className="text"
            onMouseLeave={(e) => { tooltip?.show && setOpenPopover({ target: null }); }}
            onMouseEnter={(e) => { tooltip?.show && setOpenPopover({ target: e?.currentTarget }) }}
            style={{ lineHeight: "1", ...customsx?.textField, ...customsx?.text }}
          >
            {image &&
              <ImageWrapper style={{ marginRight: "8px" }} src={image} />}
            {url && url.trim() !== "" ? (
              <Box onClick={(e)=>{
                onNavigate()}}>
                <Link className="link"  to={getUrl(url, data, urlParams)}
              //  style={{textOverflow:"ellipsis",overflow:"hidden",whiteSpace:"nowrap",width:"140px",}}
              >{
                  enableElipsis ?
                    txtValue?.length > displayLength ? txtValue?.substring(0, displayLength) + "..." : txtValue
                    :
                    txtValue
                }
              </Link>
              </Box>
            ) : (
              enableElipsis ?
                txtValue?.length > displayLength ? txtValue?.substring(0, displayLength) + "..." : txtValue
                :
                txtValue
            )}
          </Box>
          {isAuthorised(features?.device, permissions?.edit) && showEdit &&
            <Box className={onHoverEdit ? showEdit ? 'hoverEdit' : "btn-edit" : 'hoverEdit'}>
              <StyledButton
                iconButton
                onClick={() => {
                  setError(false);
                  tooltip?.show && setOpenPopover({ target: null })
                  if (inlineEditor) {
                    setEdit(true);
                  }
                  else {
                    setDialogBox(true);
                    setDialogBoxContents({ title: "Device name" });
                  }
                  onEdit(true);
                }}
                sx={{ ...customsx?.editIcon }}
                className="editButton">
                <ImageWrapper width={16} height={16} src={Edit} />
              </StyledButton>
            </Box>}
        </Box>
      )
      }
      {/* {!disableDevFeatures?.tooltip && */}
      <PopoverContainer anchorEl={openPopover?.target}>
        {tooltip?.content}
      </PopoverContainer>
      {/* } */}

      <StyledPopupWrapper
        open={dialogBox}
        title={dialog?.title || "Edit"}
        onClose={() => { setDialogBox(false); setDialogBoxContents(null); }}
      >
        <Box >
          <Box sx={{ minHeight: "93px", minWidth: "413px", mb: 0.5 }}>
            {dialog?.label && <CustomLabel
              required={true}
              style={{ fontSize: 14, fontWeight: 500, color: "#000000CC" }}>
              {dialog?.label}
            </CustomLabel>}
            <StyledTextfield
              // ref={textRef}
              error={error ? true : false}
              required
              type={type}
              value={popupTxtValue}
              onChange={(e) => {
                const target = e.currentTarget;
                setPopupTxtValue(target.value);
                setError(Validator(target?.value, type, validation));
              }}
              inputProps={inputProps}
              errorPopup={errorPopup}
              helperText={error}
              fullWidth={true}
              sx={{
                minWidth: "100%",
                '& .MuiOutlinedInput-root': {
                  minWidth: "100%"
                },
                ...customsx?.textField
              }}></StyledTextfield>
          </Box>
          <Box sx={{ display: "flex", alignItems: "baseline", justifyContent: "flex-end" }}>
            <StyledButton sx={{ ml: 1, minWidth: "90px" }}
              onClick={(e) => {
                cancelAction(txtValue);
                setDialogBox(false)
                // setPopupTxtValue(value);
                setError(false);
                setEdit(false);
                onEdit(false);
              }}
              name="cancelButton"
              variant="outlined">
              Cancel
            </StyledButton>
            <StyledButton sx={{ ml: 1, minWidth: "90px", height: "33px" }}
              onClick={(e) => {
                const err = Validator(popupTxtValue, type, validation)
                setError(err);
                if (!err) {
                  saveAction(popupTxtValue);
                  setDialogBox(false);
                  setEdit(false);
                  onEdit(false);
                }
              }}
              name="saveButton">
              Save
            </StyledButton>

          </Box>
        </Box>
      </StyledPopupWrapper >
    </Box >
  )
};

StyledEditor.propTypes = {};

StyledEditor.defaultProps = {};

export default StyledEditor;
