/***
 *
 * Controller class for user.
 * @file RuleAddEdit.js
 * @description RuleAddEdit component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useState, useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { useStyles } from './RuleAddEdit.style.js';
import PageHeader from '../../components/PageHeader/index.js';
import RulesIcon from '../../assets/icons/rules.svg';
import { useQuery } from '../../hooks/useQuery.js';
import { getMessageStr } from '../../helpers/message.helper.js';
import FormWrapper from '../../components/FormWrapper/index.js';
import CustomStepper from '../../components/CustomStepper/index.js';
import StyledButton from '../../components/StyledButton/index.js';
import { history } from "../../helpers/history.helper.js";
import RuleTriggers from '../../components/RuleTriggers/index.js';
import RuleActions from '../../components/RuleActions/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { findArrayByType } from '../../helpers/automation.helper.js';
import { automationActions, deviceActions, groupActions, sceneActions } from '../../redux/actions/index.js';
import StyledPopup from '../../components/StyledPopup/index.js';
import _ from 'lodash';

const RuleAddEdit = () => {
  const classes = useStyles();
  const query = useQuery();
  const actionButtonsRef = useRef();
  const dispatch = useDispatch();
  
  const queryParam = query.get("op") || null;

  const _ruleDetails = useSelector((state) => state?.automation?.automationRuleById);
  const _detailedSensor = useSelector((state) => state?.devices?.deviceDetails);
  const _detailedDeviceList = useSelector((state) => state?.devices?.detailedDeviceList);
  const _multipleGroupsList = useSelector((state) => state?.groups?.multipleGroupsList);
  const _multipleScenesList = useSelector((state) => state?.scenes?.multipleScenesList);
  const _currentSpaceId = useSelector((state) => state?.spaces?.selectedSpace?.spaceId);

  const [stepCount, setStepCount] = useState(1);
  const [formData, setFormData] = useState({ ruleName: "" })
  const [frm, setFrm] = useState(null);
  const [oldTriggerData, setOldTriggerData] = useState([]);
  const [triggerData, setTriggerData] = useState([]);
  const [actionsData, setActionsData] = useState([]);
  const [isTriggerChanged, setIsTriggerChanged] = useState(false);
  const [isBackClicked, setIsBackClicked] = useState(false);
  const [showActionsWarning, setShowActionsWarning] = useState(false);
  const [payloadData, setPayloadData] = useState({});
  const [deviceStatusData, setDeviceStatusData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [formFields, setFormFields] = useState([
    {
      section: { heading: null },
      columns: [
        {
          id: "10",
          type: "text",
          name: "ruleName",
          label: "Rule Name",
          required: true,
          placeholder: "Rule name",
          columnSize: 3,
          validation: {
            req: [true, getMessageStr('rule-formValidation-ruleName')],
            minLen: [2, getMessageStr('rule-formValidation-minLength')],
            maxLen: [20, getMessageStr('rule-formValidation-maxLength')],
            alphaNumChars: ['-', getMessageStr('rule-formValidation-alphaNumericStart')]
          },
        },
        // {
        //   id: "20",
        //   type: "datetimepicker",
        //   picker: 'DateTimePicker',
        //   label: "Start Date",
        //   name: "startDate",
        //   placeholder: 'Select Date',
        //   required: false,
        //   error: true,
        //   disablePast: true,
        //   disabled: false,
        //   columnSize: 3,
        // },
        // {
        //   id: "25",
        //   type: "datetimepicker",
        //   picker: 'DateTimePicker',
        //   label: "Expiry Date",
        //   name: "expiryDate",
        //   required: false,
        //   columnSize: 3,
        //   minDate: frm?.startDate,
        // },
        // {
        //   id: "30",
        //   type: "switchButton",
        //   label: "Rule Status",
        //   name: "ruleStatusSwitch",
        //   columnSize: 3,
        //   btntype: 'iphone',
        //   toggle: ruleStatusToggle
        // }
      ],
    }
  ]);

  const pageTitle = queryParam === "edit" ? "Edit Rule" : "Create Rule";
  const steps = [{ label: "Add Triggers" }, { label: "Add Actions" }];

  const handleCurrentPage = (currentStep = 1) => {
    if (currentStep === 2) {
      return (
        <RuleActions
          trigger={{ type: triggerData[0].type, property: triggerData[0]?.property, controllerId: triggerData[0]?.controllerId }}
          actionsData={isTriggerChanged ? [] : actionsData}
          setActionsData={setActionsData}
        />
      )
    }
    else {
      return (
        <RuleTriggers
          triggersData={triggerData}
          warningMessage={errorMessage}
          handleTriggerData={setTriggerData}
          setShowWarning={setShowActionsWarning}
        />
      )
    }
  }

  const handleNextStep = () => {
    if (stepCount === 1) {
      setStepCount(2);
      setTimeout(() => {
        setIsTriggerChanged(false);
      }, 500);
    }
    else if (stepCount === 2) {
      prepareRulePayload();
    }
  };

  const handleCancelStep = () => {
    history.goBack();
  };

  const handleBackStep = () => {
    setShowActionsWarning(false);
    setOldTriggerData([...triggerData]);
    setIsBackClicked(true);
    if (stepCount === 2) {
      setStepCount(1);
    }
  };

  const backHandler = () => {
    if (!_.isEmpty(actionsData)) {
      setShowActionsWarning(true);
    }
    else {
      handleBackStep();
    }
  }

  const prepareRulePayload = () => {
    // let startsAtUtcTime = frm?.startDate ? Math.round(new Date(frm?.startDate).getTime() / 1000) : null;
    // let expiresAtUtcTime = frm?.expiryDate ? Math.round(new Date(frm?.expiryDate).getTime() / 1000) : null;

    let onTriggerPayload = [];
    let triggersPayload = [];

    actionsData?.forEach((action) => {
      var dataObject = {
        type: action?.type,
        payloadVersion: "version",
      }

      if (action.type === 'DEVICE_ACTION') {
        dataObject.deviceId = action?.deviceId;
      }
      else if (action.type === 'GROUP_ACTION') {
        dataObject.groupId = action?.groupId
      }
      else if (action.type === 'SCENE_ACTION') {
        dataObject.sceneId = action?.sceneId
      }

      if (action?.payload) {
        dataObject = { ...dataObject, payload: JSON.stringify(action?.payload) };
      }

      onTriggerPayload.push(dataObject);
    });

    var triggersArray = []
    triggerData.forEach((trigger) => {
      var triggerObj = {
        type: trigger?.type,
      }
      if (trigger.type === "DEVICE_STATUS") {
        triggerObj = { ...triggerObj, property: trigger?.property, deviceId: trigger?.deviceId }
      }
      else {
        let timeList = trigger?.timeOfDay.map((time) => {
          if (time.hasOwnProperty("interval")) {
            return time.id + "/" + time.interval;
          }
          return time.id;
        }) || [];
        triggerObj = { type: "TIME", timeOfDay: timeList }
      }
      triggersArray.push(triggerObj);
    })
    triggersPayload = [triggersArray];

    const payloadObject = {
      schemaVersion: "v1",
      ruleName: frm?.ruleName,
      spaceId: _currentSpaceId,
      status: 'enable',
      triggers: triggersPayload,
      onTrigger: onTriggerPayload,
      tags: ['automation']
    }

    // if (startsAtUtcTime) {
    //   payloadObject["startsAtSecs"] = startsAtUtcTime;
    //   payloadObject["expiresAtSecs"] = expiresAtUtcTime;
    // }
    setPayloadData(payloadObject);
  }

  const handleDisableButton = () => {
    let disableButton = false;

    if (stepCount === 1) {
      if (_.isEmpty(triggerData)) {
        disableButton = true;
      }
    }
    else {
      if (_.isEmpty(actionsData)) {
        disableButton = true;
      }
      else if (actionsData?.find(a => a?.luxError || a?.gainError)) {
        disableButton = true;
      }
    }

    if(frm?.ruleName === "") {
      disableButton = true;
    }
    return disableButton;
  }

  useEffect(() => {
    if (isTriggerChanged) {
      setActionsData([]);
    }
  }, [isTriggerChanged]);

  useEffect(() => {
    if (!_.isEmpty(frm)) {
      if (frm && frm?.startDate) {
        let column = formFields[0]?.columns;
        const newColumn = column.filter((item) => item.name !== 'expiryDate');
        newColumn.splice(2, 0, {
          id: "25",
          type: "datetimepicker",
          picker: 'DateTimePicker',
          label: "Expiry Date",
          name: "expiryDate",
          columnSize: 3,
          minDate: frm?.startDate
        });
        setFormFields([{ ...formFields, columns: newColumn }])
      }
      setFrm(frm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frm?.startDate]);

  useEffect(() => {
    if (Object.keys(payloadData).length) {
      if (queryParam === "edit") {
        dispatch(automationActions.updateAutomationRule(_ruleDetails.ruleId, payloadData, () => {
          history.push("/spaces/rules")
        }));
      }
      else {
        dispatch(automationActions.createAutomationRule(payloadData, () => {
          history.push("/spaces/rules")
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payloadData]);

  useEffect(() => {
    if ((isBackClicked || queryParam === 'edit') && triggerData.length > 0) {
      const oldData = oldTriggerData[0];
      const currentData = triggerData[0];

      if (oldData.type !== currentData.type) {
        setIsTriggerChanged(true);
      }
      else if (oldData.type === "DEVICE_STATUS" && currentData.type === "DEVICE_STATUS") {
        if (oldData.controllerId !== currentData.controllerId) {
          setIsTriggerChanged(true);
        }
        else if (oldData.property !== currentData.property) {
          setIsTriggerChanged(true);
        }
      }
    }
  }, [triggerData])

  useEffect(() => {
    if (_ruleDetails && queryParam === "edit") {
      setFormData({
        ruleName: _ruleDetails?.ruleName,
        // startDate: _ruleDetails?.startsAtSecs * 1000,
        // expiryDate: _ruleDetails?.expiresAtSecs * 1000,
      });

      const timeTrigger = findArrayByType(_ruleDetails?.triggers, 'TIME') || [];
      const sensorTrigger = findArrayByType(_ruleDetails?.triggers, 'DEVICE_STATUS') || [];

      if (timeTrigger.length > 0) {
        const timeDetails = timeTrigger[0]?.timeOfDay || [];
        const timeData = timeDetails.map((time) => {
          return { id: time, label: `${time.slice(0, 2)}:${time.slice(2)}` }
        })
        setTriggerData([{ type: "Time", timeOfDay: timeData }]);
        setOldTriggerData([{ type: "Time", timeOfDay: timeData }]);
      }

      if (sensorTrigger.length > 0) {
        const sensorDetails = sensorTrigger[0] || [];
        if (sensorDetails) {
          dispatch(deviceActions?.getDevice(sensorDetails?.deviceId, "device"))
        }
        const sensorData = { type: sensorDetails?.type, name: sensorDetails?.name, deviceId: sensorDetails?.deviceId, property: sensorDetails?.property }
        setDeviceStatusData({ ...sensorData });
      }

      const ruleDeviceActions = _ruleDetails?.onTrigger?.filter(item => item.type === "DEVICE_ACTION").map(deviceItem => deviceItem?.deviceId) || [];
      const ruleGroupActions = _ruleDetails?.onTrigger?.filter(item => item.type === "GROUP_ACTION").map(groupItem => groupItem?.groupId) || [];
      const ruleSceneActions = _ruleDetails?.onTrigger?.filter(item => item.type === "SCENE_ACTION").map(sceneItem => sceneItem?.sceneId) || [];

      if (!_.isEmpty(ruleDeviceActions)) {
        dispatch(deviceActions.getDetailedDeviceList({
          "deviceIds": ruleDeviceActions.join(','),
        }));
      }
      if (!_.isEmpty(ruleGroupActions)) {
        dispatch(groupActions.getMultipleGroups({
          "groupIds": ruleGroupActions?.join(','),
          "keys": "devices,name,groupId,groupType"
        }));
      }

      if (!_.isEmpty(ruleSceneActions)) {
        dispatch(sceneActions.getMultipleScenes({
          "sceneIds": ruleSceneActions.join(','),
          "keys": "name,sceneType,actions,sceneId"
        }));
      }
    }
  }, [_ruleDetails])

  useEffect(() => {
    if (_detailedSensor && Object.keys(_detailedSensor).length > 0 && _detailedSensor?.deviceId === deviceStatusData?.deviceId && queryParam === 'edit') {
      setTriggerData([{ ..._detailedSensor, ...deviceStatusData }]);
      setOldTriggerData([{ ..._detailedSensor, ...deviceStatusData }]);
    }
  }, [_detailedSensor])

  useEffect(() => {
    const ruleActions = _ruleDetails?.onTrigger || [];
    if (ruleActions.length > 0) {
      let actions = ruleActions.map((action) => {
        if (action?.payload) {
          return {
            ...action,
            payload: (typeof action.payload !== 'object') ? JSON.parse(action?.payload) : action.payload
          }
        }
        return action;
      }) || []

      const ruleDeviceActions = actions?.filter(item => item.type === "DEVICE_ACTION") || [];
      const ruleGroupActions = actions?.filter(item => item.type === "GROUP_ACTION") || [];
      const ruleSceneActions = actions?.filter(item => item.type === "SCENE_ACTION") || [];

      let mergedDeviceList = ruleDeviceActions?.map((action) => {
        let deviceDetails = _detailedDeviceList?.find(detailedDevice => detailedDevice?.deviceId === action?.deviceId) || {};
        return { ...deviceDetails, ...action };
      }) || [];
      
      let mergedGroupList = ruleGroupActions?.map((action) => {
        let groupDetails = _multipleGroupsList?.find(group => group?.groupId === action?.groupId) || {};
        return { ...groupDetails, ...action };
      }) || [];

      let mergedSceneList = ruleSceneActions?.map((action) => {
        let sceneDetails = _multipleScenesList?.find(scene => scene?.sceneId === action?.sceneId) || {};
        return { ...sceneDetails, ...action };
      }) || [];
      setActionsData([...mergedDeviceList, ...mergedGroupList, ...mergedSceneList]);
    }
  }, [_detailedDeviceList, _multipleGroupsList, _multipleScenesList])

  useEffect(() => {
    if(_.isEmpty(actionsData)) {
      setErrorMessage("");
    }

    if(!_.isEmpty(actionsData)) {
      setErrorMessage(
        <span>Actions have been configured for this rule. The configured actions will be cleared, if<br/> 
        · the trigger type or sensor type changes.<br/> 
        · a sensor that belongs to a different controller is selected.</span>
      );
    }
  }, [actionsData])

  return (
    <Box className={classes?.RuleAddEdit || ""} data-testid="RuleAddEdit" sx={{ height: "100%" }}>
      <PageHeader pageHeaderStyle={{ marginBottom: '0px', padding: '0px' }} title={pageTitle} icon={RulesIcon}></PageHeader>

      <FormWrapper
        ref={actionButtonsRef}
        formFields={formFields}
        setPayload={setFrm}
        formData={formData}
      />

      <Box className={classes?.CreateRuleStepper}>
        <CustomStepper labels={steps.map((item) => item.label)} activeStep={stepCount} />
      </Box>

      <Box>
        {handleCurrentPage(stepCount)}
        <Box className={classes?.CreateRuleButtonWrapper}>
          <StyledButton className="CreateRuleBtn" variant="outlined" onClick={handleCancelStep} sx={{ mr: 2 }}>Cancel</StyledButton>
          {stepCount !== 1 && (
            <StyledButton className="CreateRuleBtn" variant="outlined" onClick={backHandler} sx={{ mr: 2 }} >Back</StyledButton>
          )}

          <StyledButton
            className="CreateRuleBtn"
            variant="contained"
            disabled={handleDisableButton()}
            onClick={(e) => actionButtonsRef?.current?.handleButton(e, {
              checkValidation: true,
              onClick: (event, data) => {
                handleNextStep();
              }
            })}
          >
            {`${stepCount === 1 ? "Next" : "Save"}`}
          </StyledButton>
        </Box>
      </Box>

      <StyledPopup open={showActionsWarning} onClose={() => setShowActionsWarning(false)} state="timeout"
        data={{
          content: <p>Actions have been configured for this rule. If the trigger type or sensor type is changed or a sensor that belongs to a different controller is chosen, the configured actions will be cleared.<br /><br />Would you like to proceed?</p>,
          actions: [
            {
              id: "1002",
              type: "button",
              label: "Cancel",
              onClick: () => {
                setShowActionsWarning(false);
              }
            },
            {
              id: "1001",
              type: "button",
              label: "Confirm",
              onClick: () => {
                handleBackStep();
              }
            }
          ]
        }}
      />
    </Box>
  )
};

RuleAddEdit.propTypes = {};

RuleAddEdit.defaultProps = {};

export default RuleAddEdit;
