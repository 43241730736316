import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    CameraDetails: {
        "& .StreamVideo": {
            height: "350px",
            "& .video-js": {
                width: "100%",
                height: "100%"
            }
        }
    }
});
