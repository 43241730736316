/***
 *
 * Controller class for user.
 * @file SpaceDeviceTree.js
 * @description SpaceDeviceTree component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './SpaceDeviceTree.style.js';
import './SpaceDeviceTree.scss';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { TreeView, TreeItem } from '@mui/x-tree-view'
import SearchBox from '../SearchBox/index.js';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import Light from '../../assets/icons/lamp.svg';
import Group from '../../assets/icons/group.svg';
import Move from '../../assets/icons/drag-move.svg';
import _ from 'lodash';
import { Draggable, } from "react-beautiful-dnd";
import ImageWrapper from '../ImageWrapper/index.js';
import { getDevices } from '../../helpers';


function SpaceDeviceTree({ deviceGroups = true, type = 'checkbox', destDevices = [], data, searchstyle, idKey, draggable = false, columns, childrenKey, selectedItems = null, search = false, setterFunction = () => { }, isDropped }) {
  const classes = useStyles()

  const [options, setOptions] = React.useState([]);
  const [deviceIndex, setDeviceIndex] = useState({});
  const [selectedDevice, setSelectedDevice] = useState([]);
  const [filterDeviceTree, setFilterDeviceTree] = useState([]);
  const [optionsOriginal, setOptionsOriginal] = useState([])

  useEffect(() => {
    const ch = columns?.sent?.items.map(itm => itm?.id) || [];
    setFilterDeviceTree(ch);
  }, [columns])

  useEffect(() => {
    if (isDropped) {
      setSelectedDevice([]);
    }
  }, [isDropped])

  useEffect(() => {
    if(filterDeviceTree && optionsOriginal){
      const array = optionsOriginal.filter(item => {
        if(item.deviceId !== undefined && !filterDeviceTree.includes(item.deviceId)){
          return true
        }
        else if(item.groupId !== undefined && !filterDeviceTree.includes(item.groupId)){
          return true
        }
        return false
      })
      setOptions(array)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDeviceTree])

  useEffect(() => {
    if (data) {
      let array = getDevices(data)?.array?.map(d => d?.content);
      let obj = getDevices(data)?.map;
      // console.log('arar::=>',array, data, obj);
      setDeviceIndex(obj);
      setOptions(array);
      setOptionsOriginal(array)
    }
  }, [data])

  
  

  // useEffect(()=>{
  //   if(destDevices){
  //    setSelectedDevice([]);
  //   }
  // },[destDevices])

  const handleOptionsChange = (e, value) => {
    if (value) {
      if (type === 'checkbox') {
        if (type === 'space') {
          let nodeId = value['spaceId']
          const { childNodesToToggle, path } = getChildById(data, nodeId);
          let array = [...selectedDevice, ...childNodesToToggle];

          setSelectedDevice([...array]);

        }
        else {
          let device = selectedDevice;
          let nodeId = value['deviceId'];
          if (selectedDevice.indexOf(nodeId) >= 0) {
            device.splice(selectedDevice.indexOf(nodeId), 1)
          }
          else {
            device.push(nodeId);
          }
          setSelectedDevice([...device]);

        }
        setterFunction(selectedDevice);
        document.getElementById(`${value['deviceId']}`).scrollBy({ top: 90, behavior: 'instant' });
        console.log('element got==>', document.getElementById(`${value['deviceId']}`));
        // const { childNodesToToggle, path } = getChildById(data, value[idKey]);
        // let array = [...selected, ...childNodesToToggle];
        // console.log('selected1==>', selected);
        // if (!selected.includes(undefined))
        //   setSelected(array);
        // setPath(path);     
      }
    }
  }


  function getAllChild(childNode, collectedNodes = []) {
    if (childNode === null) return collectedNodes.reverse();

    if (!childNode?.disabled)
      collectedNodes.push(childNode[idKey]);

    if (childNode?.devices) {
      childNode?.devices?.forEach(d => collectedNodes.push(d?.deviceId));
    }

    if (Array.isArray(childNode[childrenKey])) {
      for (const node of childNode[childrenKey]) {
        getAllChild(node, collectedNodes);
      }
    }

    return collectedNodes;
  }

  const getChildById = (nodes, id) => {
    let array = [];
    let path = [];
    let name = [];

    // recursive DFS
    function getNodeById(node, id, parentsPath = []) {
      let result = null;

      if (node[idKey] === id) {
        return node;
      } else if (Array.isArray(node[childrenKey])) {
        for (let childNode of node[childrenKey]) {
          result = getNodeById(childNode, id, parentsPath);

          if (!!result) {
            parentsPath.unshift(node[idKey]);
            return result;
          }
        }
        return result;
      }
      return result;
    }

    const nodeToToggle = getNodeById(nodes, id, path);

    let childDevices = [];
    for (const n of nodes?.devices) {
      childDevices.push(n?.deviceId);
    }

    return { childNodesToToggle: getAllChild(nodeToToggle, array), path };
  };

  function getOnChange(checked, nodeId, type) {

    if (type === 'space') {
      const { childNodesToToggle, path } = getChildById(data, nodeId);
      let array = checked ? [...selectedDevice, ...childNodesToToggle]
        : selectedDevice
          .filter((value) => !childNodesToToggle.includes(value))
      array = array.filter((v, i) => array.indexOf(v) === i);
      console.log('space==>', childNodesToToggle, path, checked, array);
      setSelectedDevice([...array]);
      setterFunction([...array]);
    }
    else {
      let device = selectedDevice;
      if (!checked && selectedDevice.indexOf(nodeId) >= 0) {
        device.splice(selectedDevice.indexOf(nodeId), 1)
      }
      else {
        device.push(nodeId);
      }
      console.log('device==>', device);
      setSelectedDevice([...device]);
      setterFunction([...device]);
    }
    console.log('on change==>', checked, nodeId, type, selectedDevice);
    // setterFunction(selectedDevice)

  }




  const renderTree = (node, count, outerIndex) => {
    if (node) {
      const checked = selectedDevice.includes(node[idKey])

      return (
        <>
          <TreeItem
            key={node[idKey]}
            nodeId={node[idKey]}
            className={classes.TreeItem}
            sx={{
              '.css-1snv7l3-MuiTreeItem-content': {
                border: selectedDevice.includes(node[idKey]) ? '2px solid #0F8D48' : '1.4px solid #D9D9D9',
              },'& .MuiTreeItem-iconContainer': {
                display: !_.isEmpty(node['Spaces']) ? 'block' : 'none'
              }}}
            label={
              <FormControlLabel
                sx={{ width: "100%" }}
                control={
                  <>
                    <Checkbox
                      checked={selectedDevice.includes(node[idKey])}
                      // indeterminate={!checked && indeterminate}
                      name={node[idKey]}
                      onClick={(e) => e.stopPropagation()}
                      disabled={node?.disabled}
                      icon={node?.disabled && <DisabledByDefaultIcon sx={{ color: '#EF0000' }} />}
                      onChange={(event) => {
                        event.stopPropagation();
                        getOnChange(event.currentTarget.checked, node[idKey], type = "space");
                      }}
                    />
                  </>

                }
                label={<span style={node?.disabled ? { color: 'rgba(0, 0, 0, 0.6)' } : { color: '#202020' }}>{node?.name}</span>}
                key={node[idKey]}
              />
            }
          >
            {/* { renderTree(node) } */}
            {node?.devices && node?.devices?.map((d, index) => {
              return (
                <>
                  {draggable ? !filterDeviceTree.includes(d?.deviceId) &&
                    <Draggable
                      key={deviceIndex[d?.deviceId]}
                      //  draggableId={selectedDevice.join('-') || 'drag'}
                      draggableId={d?.deviceId || 'drag'}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <TreeItem
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            cursor: snapshot.isDragging ? "move" : "all-scroll",
                            ...provided.draggableProps.style
                          }}
                          id={d?.deviceId}
                          key={d?.deviceId}
                          nodeId={d?.deviceId}
                          onClick={(e) => {
                            console.log('onclick==>', e);
                          }}
                          sx={{
                            '.css-1snv7l3-MuiTreeItem-content': {
                              border: selectedDevice.includes(d?.deviceId) ? '2px solid #0F8D48' : '1.4px solid #D9D9D9',
                              borderRadius: '4px',
                              mb: '5px'
                            },
                            '.MuiTreeItem-label': {
                              height: '40px'
                            },
                            '& .MuiTreeItem-iconContainer': {
                              display: 'none'
                            }
                          }}
                          label={
                            <FormControlLabel
                              sx={{ width: "100%" }}
                              control={
                                <>

                                  <Checkbox
                                    checked={selectedDevice.includes(d?.deviceId)}
                                    name={d?.deviceId}
                                    // indeterminate={!checked && indeterminate}
                                    onClick={(e) => e.stopPropagation()}
                                    disabled={d?.disabled}
                                    icon={d?.disabled && <DisabledByDefaultIcon sx={{ color: '#EF0000' }} />}
                                    onChange={(event) => {
                                      event.stopPropagation();
                                      getOnChange(event.currentTarget.checked, d?.deviceId, type = "device");
                                    }}
                                  />

                                </>
                              }
                              label={
                                <Box sx>
                                  <ImageWrapper className='icon-right' src={Light} />
                                  <span style={{}}>{d?.name}</span>
                                  <ImageWrapper className='move-icon' src={Move} />
                                </Box>}
                              key={d?.idKey}
                            />
                          }
                        />
                      )}
                    </Draggable>
                    :
                    <TreeItem
                      key={d?.deviceId}
                      nodeId={d?.deviceId}

                      sx={{
                        '.css-1snv7l3-MuiTreeItem-content': {
                          border: checked ? '2px solid #0F8D48' : '1.4px solid #D9D9D9',
                          borderRadius: '4px',
                          mb: '5px'
                        },
                        '.MuiTreeItem-label': {
                          height: '40px'
                        },
                        '.css-1snv7l3-MuiTreeItem-content .MuiTreeItem-iconContainer': {
                          display: 'none'
                        },
                      }}
                      label={
                        <FormControlLabel
                          sx={{ width: "100%" }}
                          control={
                            <Checkbox
                              checked={selectedDevice.includes(d?.deviceId)}
                              name={d?.deviceId}
                              // indeterminate={!checked && indeterminate}
                              onClick={(e) => e.stopPropagation()}
                              disabled={d?.disabled}
                              icon={d?.disabled && <DisabledByDefaultIcon sx={{ color: '#EF0000' }} />}
                              onChange={(event) => {
                                getOnChange(event.currentTarget.checked, d?.deviceId, type = "device");
                                // setterFunction(d);
                              }}
                            />
                          }
                          label={
                            <Box sx>
                              <ImageWrapper className='icon-right' src={Light} />
                              <span style={{}}>{d?.name}</span>
                              <ImageWrapper className='move-icon' src={Move} />
                            </Box>}
                          key={d?.idKey}
                        />
                      }
                    />


                  }
                </>
              )
            })}
            {node?.groups && !deviceGroups && node?.groups?.map((d, index) => {
              return (
                <>
                
                  {draggable ? !filterDeviceTree.includes(d?.groupId) &&
                    <Draggable
                      key={deviceIndex[d?.groupId] || 0}
                      //  draggableId={selectedDevice.join('-') || 'drag'}
                      draggableId={d?.groupId || 'drag'}
                      index={deviceIndex[d?.groupId] || 'index'}
                    >
                      {(provided, snapshot) => (
                        <TreeItem
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            cursor: snapshot.isDragging ? "move" : "all-scroll",
                            ...provided.draggableProps.style
                          }}
                          id={d?.groupId}
                          key={d?.groupId}
                          nodeId={d?.groupId}
                          onClick={(e) => {
                            console.log('onclick==>', e);
                          }}
                          sx={{
                            '.css-1snv7l3-MuiTreeItem-content': {
                              border: selectedDevice.includes(d?.groupId) ? '2px solid #0F8D48' : '1.4px solid #D9D9D9',
                              borderRadius: '4px',
                              mb: '5px'
                            },
                            '.MuiTreeItem-label': {
                              height: '40px'
                            },
                            '& .MuiTreeItem-iconContainer': {
                              display: 'none'
                            }
                          }}
                          label={
                            <FormControlLabel
                              sx={{ width: "100%" }}
                              control={
                                <>

                                  <Checkbox
                                    checked={selectedDevice.includes(d?.groupId)}
                                    name={d?.groupId}
                                    // indeterminate={!checked && indeterminate}
                                    onClick={(e) => e.stopPropagation()}
                                    disabled={d?.disabled}
                                    icon={d?.disabled && <DisabledByDefaultIcon sx={{ color: '#EF0000' }} />}
                                    onChange={(event) => {
                                      event.stopPropagation();
                                      getOnChange(event.currentTarget.checked, d?.groupId, type = "group");
                                    }}
                                  />

                                </>
                              }
                              label={
                                <Box sx>
                                  <ImageWrapper className='icon-right' src={Group} />
                                  <span style={{}}>{d?.name}</span>
                                  <ImageWrapper className='move-icon' src={Move} />
                                </Box>}
                              key={d?.idKey}
                            />
                          }
                        />
                      )}
                    </Draggable>
                    :
                    <TreeItem
                      key={d?.groupId}
                      nodeId={d?.groupId}

                      sx={{
                        '.css-1snv7l3-MuiTreeItem-content': {
                          border: checked ? '2px solid #0F8D48' : '1.4px solid #D9D9D9',
                          borderRadius: '4px',
                          mb: '5px'
                        },
                        '.MuiTreeItem-label': {
                          height: '40px'
                        },
                        '.css-1snv7l3-MuiTreeItem-content .MuiTreeItem-iconContainer': {
                          display: 'none'
                        },
                      }}
                      label={
                        <FormControlLabel
                          sx={{ width: "100%" }}
                          control={
                            <Checkbox
                              checked={selectedDevice.includes(d?.groupId)}
                              name={d?.groupId}
                              // indeterminate={!checked && indeterminate}
                              onClick={(e) => e.stopPropagation()}
                              disabled={d?.disabled}
                              icon={d?.disabled && <DisabledByDefaultIcon sx={{ color: '#EF0000' }} />}
                              onChange={(event) => {
                                getOnChange(event.currentTarget.checked, d?.groupId, type = "group");
                                // setterFunction(d);
                              }}
                            />
                          }
                          label={
                            <Box sx>
                              <ImageWrapper className='icon-right' src={Group} />
                              <span style={{}}>{d?.name}</span>
                              <ImageWrapper className='move-icon' src={Move} />
                            </Box>}
                          key={d?.idKey}
                        />
                      }
                    />
                  }
                </>
              )
            })}
            {node['Spaces'] && node['Spaces'].map((ch, index) => renderTree(ch, count, 10000 + index))}
          </TreeItem>
        </>
      );
    }
  };


  return (
    <>
      {search &&
        <Box sx={{ ...searchstyle?.search }}>
          <SearchBox placeholder="Search Devices" data={options} autoComplete={true} onChange={(e, value)=>{handleOptionsChange(e, value)}} />
        </Box>
      }
      {data && data[idKey] &&
        <TreeView
          className='SpaceDeviceTree'
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpanded={[data[idKey]]}
          defaultExpandIcon={<ChevronRightIcon />}
        >
          <Box sx={{ ...searchstyle?.tree }} >
            {renderTree(data, 0)}
          </Box>
        </TreeView>
      }
    </>

  )
};

SpaceDeviceTree.propTypes = {};

SpaceDeviceTree.defaultProps = {};

export default SpaceDeviceTree;
