/***
 *
 * Controller class for user.
 * @file Polygon.js
 * @description Polygon component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useCallback, useEffect, useRef } from 'react';
import { Circle, Group, Line } from 'react-konva';
// import PropTypes from 'prop-types';
import './Polygon.scss';
import { useState } from 'react';
import { dragBoundFunc, fillColorInShapes, minMax } from '../../../../helpers';
import { canvasActions } from '../../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
// import { Html } from 'react-konva-utils';

const Polygon = (props) => {
  const _selectedShape = useSelector(state => state?.canvas?.selectedShape);
  const _selectedSpace = useSelector(state=>state?.spaces?.selectedSpace);
  const _getModalState = useSelector(state => state?.canvas?.modalState);
  const _shapeList =  useSelector(state=>state?.canvas?.shapes);
  const [selectedShape, setSelectedShape] = useState(null);
  // const [showDelete, setShowDelete] = useState(null); /// used below
  const [pointsDragged,setPointsDragged] = useState(false);
const lineRef = useRef();
  const {
    points,
    flattenedPoints,
    isFinished,
    handlePointDragMove,
    handleGroupDragEnd,
    handleMouseOverStartPoint,
    handleMouseOutStartPoint,
    isSelectedShape,
    id,
    position,
    shape,
    draggable,
    selectShape,
    undo,
    spaceIds,
    handleDrag=() => {},
    handlePolygonClick=() => {}
  } = props
  const vertexRadius = 6

  const dispatch = useDispatch();

  useEffect(() => {
    if (_selectedShape) {
      setSelectedShape(_selectedShape);
    }
  }, [_selectedShape])

 
  const shapeRef = useRef();
  const [stage, setStage] = useState();


   useEffect(() => {

    if (isFinished) {
      // const fltpoints= points.concat(isFinished ? [] : position).reduce((a, b) => a.concat(b), []);     
      dispatch(canvasActions.constructPolygon(shape, { points, flattenedPoints, position: position, isPolyComplete: isFinished }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinished])

  const handleGroupMouseOver = (e) => {
    if (!isFinished || !draggable || !_getModalState ) return
    e.target.getStage().container().style.cursor = 'move'
    setStage(e.target.getStage())
  }
  const handleGroupMouseOut = (e) => {
    e.target.getStage().container().style.cursor = 'default'
  }

  const [minMaxX, setMinMaxX] = useState([0, 0]) //min and max in x axis
  const [minMaxY, setMinMaxY] = useState([0, 0]) //min and max in y axis
  
  const handleGroupDragStart = (e) => {
    let arrX = points.map((p) => p[0])
    let arrY = points.map((p) => p[1])
    setMinMaxX(minMax(arrX));
    setMinMaxY(minMax(arrY));
  }

  const handleSelect = useCallback(
    (event) => {
      if( selectShape){
      event.cancelBubble = true;
      dispatch(canvasActions.selectShape(id));
      handlePolygonClick(event,selectedShape);
      // onSelect(shapeRef);
      }
    },
    [id]
  );

  const handleDragGroup = 
  useCallback(
    (event, __Points, __FlattenedPoints, __Position) => {
      // console.log('eeeeeeeeeeeeeee===>',event.target.getAttrs()?.x, __Position, __Points);
      let x = event.target.x();
      let y = event.target.y();
      let newPosition = __Position; 
      let newFlattenedPoints = __FlattenedPoints;
      
      let newPoints = __Points;
     

      dispatch(canvasActions.selectShape(id));
      handleDrag(event, selectedShape);
      if(!pointsDragged)
      {
      newPosition = [position[0] + x, position[1] + y];
      newFlattenedPoints= flattenedPoints.map((p, index) => {
        if (index%2===0) p = p + x;
        else p = p + y;
        return p;
      });
      newPoints= points.map(([X, Y]) => {
        X = X + x;
        Y = Y + y;
        return [X, Y];
      })
    }
    dispatch(canvasActions.constructPolygon(shape, {  
      points: newPoints, 
      flattenedPoints: newFlattenedPoints, 
      position: newPosition, 
      isPolyComplete: isFinished   
    }));
    dispatch(canvasActions.observeChange('POLYGON_UPDATED'));

    const xcoords = newPoints.map((item)=> item[0]);
    const ycoords = newPoints.map((item)=> item[1]);
    const minX = Math.min(...xcoords);
    const maxX = Math.max(...xcoords);
    const minY = Math.min(...ycoords);
    const maxY = Math.max(...ycoords);
    const maxW=Math.abs(minX-maxX);
    const maxH=Math.abs(minY-maxY);

    const parentShape = _shapeList?.find(shape=>shape?.spaceId === _selectedSpace?.parentSid);
    const pos= {x:event.target.x(),y:event.target.y()};
    /// uncomment to bound polygon inside rect. needs finetuning
    // event.target.x(dragBoundPolygontoShape(parentShape,newPoints,pos,maxW,maxH).x);
    // event.target.y(dragBoundPolygontoShape(parentShape,newPoints,pos,maxW,maxH).y);  
    }   
    , [selectedShape]
  );

  const groupDragBound = (pos) => {
    let { x, y } = pos;
    const sw = stage.width();
    const sh = stage.height();
    if (minMaxY[0] + y < 0) y = -1 * minMaxY[0];
    if (minMaxX[0] + x < 0) x = -1 * minMaxX[0];
    if (minMaxY[1] + y > sh) y = sh - minMaxY[1]-200;
    if (minMaxX[1] + x > sw) x = sw - minMaxX[1];
    return { x, y };
  }
  return (
    <>
    {/* {
      isFinished && */}
      <Group
        ref={shapeRef}
        onClick={handleSelect}
        name="fillShape"
        data-testid="Polygon" 
        opacity={1}
        draggable={isFinished && draggable && _getModalState}
        onDragStart={handleGroupDragStart}
        onDragMove={(e)=>{handleDragGroup(e,points, flattenedPoints, position, pointsDragged)}}
        onDragEnd={(e)=>handleGroupDragEnd(e)}
        dragBoundFunc={groupDragBound}
        onMouseOver={handleGroupMouseOver}
        onMouseOut={handleGroupMouseOut}
      >     
        <Line
          points={flattenedPoints} /// [x1,y1,x2,y2...]
          stroke={shape?.spaceId === _selectedSpace?.spaceId ? '#1616f7' : '#77e2f0'}
          strokeWidth={3}
          data-type="shape"
          name="fillShape"
          data-spaceId={shape?.spaceId}
          closed={isFinished}
          fill={ fillColorInShapes(shape,_selectedShape,spaceIds, _shapeList?.find(s=>s?.spaceId === _selectedSpace?.spaceId, shape?.transparency) ? true : false)}        
        />
        { !isFinished && points?.length>0 &&
          <Line
            points={[...points[points?.length-1],...position]} 
            stroke={'#1616f7'}
            strokeWidth={3}
            data-type="shape"
            name="fillShape"
            data-spaceId={shape?.spaceId}
            closed={false}
          />
        }          
        {points.map((point, index) => {  /// [[x1,y1],[x2,y2],x3,y3.....]
          const x = point[0] - vertexRadius / 2
          const y = point[1] - vertexRadius / 2
          const startPointAttr =
            index === 0
              ? {
                hitStrokeWidth: 12,
                onMouseOver: handleMouseOverStartPoint,
                onMouseOut: handleMouseOutStartPoint,
              }
              : null
          return (
            <>
              {/* for deleting individual node */}
              {/* {draggable && _getModalState && index === points.length - 1 && showDelete === points.length - 1 &&
                <Group x={x + 15} y={y - 25} >
                  <Html>
                    <img style={{ cursor: 'pointer' }} onMouseEnter={(e) => setShowDelete(index)}
                      onMouseLeave={() => { setShowDelete(null) }}
                      onDragMove={handlePointDragMove} onClick={undo} src={Delete} />
                  </Html>
                </Group>
              } */}
             { (isSelectedShape || !isFinished) && 
             <Circle
                key={'polygon' + index}
                x={x}
                y={y}
                radius={vertexRadius}
                fill="white"
                stroke="black"
                strokeWidth={2}
                draggable={draggable && _getModalState}
                // onMouseEnter={(e) =>{    e.target.getStage().container().style.cursor = 'pointer';setShowDelete(points.length - 1)}}
                // onMouseLeave={() => { setTimeout(() => { if (!showDelete) setShowDelete(null) }, 600) }}
                onDragMove={(e)=>{handlePointDragMove(e); setPointsDragged(true);}}
                onDragEnd={() => {setPointsDragged(false)}}                
                // onMouseDown={handlePointDragMove}

                dragBoundFunc={(pos) => {
                  const parentShape = _shapeList.find(s=>s?.spaceId === _selectedSpace?.parentSid);
                  if(parentShape?.type === 'rect'){
                   return dragBoundFunc(parentShape?.width, parentShape?.height, vertexRadius, pos,parentShape?.x,parentShape?.y,0,'rect' )
                  }
                  else if(parentShape?.type === 'circle'){
                   return dragBoundFunc(parentShape?.width, parentShape?.height, vertexRadius, pos,parentShape?.x,parentShape?.y, parentShape?.radius,'circle' )
                  }
                  else
                 return dragBoundFunc(stage.width(), stage.height(), vertexRadius, pos,0,0)
                }}
                {...startPointAttr}
              />
              }
            </>
          )
        })}
      </Group>
      {/* } */}
    </>
  );
};

Polygon.propTypes = {};

Polygon.defaultProps = {};

export default Polygon;
