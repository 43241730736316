/***
 *
 * Controller class for user.
 * @file CreateTicket.js
 * @description CreateTicket component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import { React, useState, useEffect } from 'react';
import { useStyles } from './CreateTicket.style.js';
import _ from 'lodash';
import './CreateTicket.scss';
import { Box } from '@mui/material';
import PageHeader from '../PageHeader/index.js';
import { history, isAuthorised } from "../../helpers";
import FormWrapper from '../FormWrapper/index.js';
import { deviceActions, ticketingActions, userActions } from '../../redux/actions';
import { useDispatch, useSelector } from "react-redux";
import TicketsIcon from '../../assets/icons/all-tickets.svg';
import { getMessageStr } from '../../helpers';
import { features, permissions } from '../../constants/permission.constants.js';
import { ticketEntityOptions, ticketFormConfig } from '../../constants/ticket.constants.js';
import { deviceIcons } from '../../constants/device.constants.js';


const CreateTicket = ({ action = 'create' }) => {
  const classes = useStyles()
  const [frmOptions, setFrmOptions] = useState(null);
  const [frm, setFrm] = useState(null);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [frmConfig, setFrmConfig] = useState(ticketFormConfig("createTicket"));
  let spaces = useSelector(state => state?.spaces?.list);
  const entityType = useSelector(state => state?.ticketing?.entityTypes);
  const assigneeData = useSelector(state => state?.ticketing?.assigneeData);
  const severities = useSelector(state => state?.ticketing?.severities);
  const createTicketsData = useSelector(state => state?.ticketing?.createTicketsData);
  const devices = useSelector(state => state?.devices?.deviceList);
  const users = useSelector(state => state?.users?.list);
  const alertData = history?.location?.state?.data
  const [actionButtons] = useState([
    {
      heading: null,
      columns: [
        {
          heading: null,
          buttons: [
            {
              id: "bckbtn",
              type: "button",
              display: true,
              variant: 'outlined',
              label: "Cancel",
              onClick: (event, data) => {
                history.goBack();
              }
            },
            {
              id: "savebtn",
              type: "button",
              display: true,
              checkValidation: true,
              label: 'Create',
              onClick: (event, data) => {
                handleSubmit(event, data);
              },
            },
          ],
          columnSize: 12,
        },
      ],
    },
  ]);

  function capitalizeFirstLetter(string) { // why we are using this function?
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const mapSpacesToNames = (val) => {
    const options = (val) ? val?.map(v => {
      const sp = spaces.find(s => s?.spaceId === v);
      return { id: sp?.spaceId, label: sp?.name };
    }) : [];
    return options;
  }

  useEffect(() => {
    if (!_.isEmpty(alertData)) {
      const initialState = {
        ...frm,
        synopsis: alertData?.alertTitle,
        // description: alertData?.alertId,
        // ticketType: "devices",
        severity: alertData?.severity || "minor",
        // description: "Alert Id: " + alertData?.id,
      }
      // if (alertData && alertData.spaceId) {
      //   initialState.space = alertData?.spaceId
      // }
      setFormData(initialState)
      setTimeout(() => {
        setFormData({ ...frm, description: "Alert Id: " + alertData?.id })
      }, 100)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertData])

  useEffect(() => {
    if (frm?.hasOwnProperty("ticketType")) {
      let dd = frmOptions?.dropdowns
      if (dd) {
        dd["entityType"] = { list: frm?.ticketType ? entityType[frm?.ticketType]?.map((val) => { return { id: val, label: capitalizeFirstLetter(val) } }) : [] }
        setFrmOptions({ ...frmOptions, dropdowns: dd });
      }
      const newConfig = JSON?.parse(JSON?.stringify(ticketFormConfig("createTicket")))?.map((c, i) => {
        if (i === 1 && (frm?.ticketType === "devices" || frm?.ticketType === "spaces" || frm?.ticketType === "users")) {
          if (frm?.ticketType === 'devices') {
            const spaceField = JSON.parse(JSON.stringify(ticketEntityOptions?.spaces));
            spaceField.label = "Parent Space of Device";
            c?.columns?.push(spaceField);
            const deviceField = JSON.parse(JSON.stringify(ticketEntityOptions[frm?.ticketType]));
            deviceField.disabled = true;
            c?.columns?.push(deviceField);
          }
          else {
            c?.columns?.push(ticketEntityOptions[frm?.ticketType])
          }
        }
        return { ...c };
      });
      setFrmConfig(newConfig);
      setFormData({ ...frm, entityType: null, space: [{ id: "" }], device: null, user: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frm?.ticketType])

  useEffect(() => {
    if (frm?.ticketType === 'devices' && frmConfig) {
      setFormData({ ...frm, device: "" });
      !_.isEmpty(frm?.space[0]?.id) && dispatch(deviceActions?.getDevicesBySpaceId(frm?.space[0]?.id));
      setFrmConfig(JSON.parse(JSON.stringify(frmConfig))?.map((c, i) => {
        if (i === 1) {
          c.columns[3].disabled = frm?.space && !_.isEmpty(frm?.space[0]?.id) ? false : true
        }
        return c;
      }));
    }
  }, [frm?.space])

  useEffect(() => {
    let opt = {};
    const typeList = [];
    Object.keys(entityType).forEach((entity) => {
      if (entity === "devices" || entity === "users" || entity === "spaces") {
        if (entity === 'devices') {
          isAuthorised(features?.device, permissions?.view) && isAuthorised(features?.space, permissions?.view) && typeList?.push({ id: entity, label: entity.charAt(0).toUpperCase() + entity.slice(1) })
        }
        else {
          isAuthorised(features[entity === "devices" ? "device" : entity === "users" ? "user" : "space"], permissions?.view) && typeList?.push({ id: entity, label: entity.charAt(0).toUpperCase() + entity.slice(1) })
        }
      }
      else {
        typeList?.push({ id: entity, label: entity.charAt(0).toUpperCase() + entity.slice(1) })
      }
    })
    opt["dropdowns"] = {
      space: {
        list: spaces && spaces.length > 0 && spaces[0] && spaces[0].Spaces ? spaces[0].Spaces.map(sp => { return { id: sp.spaceId, label: sp.name } }) : [],
      },
      severity: {
        list: severities && severities.length > 0 ? severities.map((severitie) => { return { id: severitie, label: capitalizeFirstLetter(severitie) } }) : []
      },
      ticketType: {
        list: typeList
      },
      assignedTo: {
        list: assigneeData.map((assignee) => { return { id: assignee.id, label: assignee.name } })
      },
      user: {
        list: users?.map(u => { return { id: u?.userId, label: u?.name } })
      },
      device: {
        list: !_.isEmpty(frm?.space) ? _.isEmpty(devices) ? [{ name: 'No Item Available' }] : devices?.map(d => { return { id: d?.deviceId, name: d?.name, icon: deviceIcons[d?.category] || null } }) : [{ name: 'No Item Available' }]
      },
      entityType: { list: frm?.ticketType ? entityType[frm?.ticketType]?.map((val) => { return { id: val, label: capitalizeFirstLetter(val) } }) : [] }
    };
    setFrmOptions(opt);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaces, entityType, devices, users]);

  useEffect(() => {
    if (createTicketsData && createTicketsData.TicketId) {
      if (formData && formData.attachments) {
        formData?.attachments?.file?.attachments?.map((attachment, i) => {
          setTimeout(() => {
            dispatch(ticketingActions.addAttachments(createTicketsData.TicketId, attachment))
          }, i * 1000)
        })
        dispatch(ticketingActions.emptyTicketingState("CREATE_TICKETS_SUCCESS", {}));
        history.goBack()
      }
      else {
        dispatch(ticketingActions.emptyTicketingState("CREATE_TICKETS_SUCCESS", {}));
        history.goBack()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createTicketsData])

  useEffect(() => {
    dispatch(ticketingActions.getTicketTypes())
    dispatch(ticketingActions.getAssignees())
    dispatch(ticketingActions.getTicketSeverities())
    isAuthorised(features?.user, permissions?.view) && dispatch(userActions?.getAll(0));
    setFrmConfig(ticketFormConfig("createTicket"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = (event, data) => {
    let payload = {
      entityType: data.entityType,
      synopsis: data?.synopsis,
      ticketType: data?.ticketType,
      description: data?.description,
      severity: data?.severity,
    }
    if (data?.assignedTo?.length > 0) {
      payload.assignedTo = data?.assignedTo
    }
    payload = ["space", "device", "user"].reduce((acc, field) => {
      const val = data?.hasOwnProperty(field) ? field === "space" ? data?.space[0]?.id : field === "device" ? data[field]?.id : data[field] : ""
      if ((!_.isEmpty(val))) {
        acc[field + "Id"] = val;
      }
      return acc;
    }, payload);
    dispatch(ticketingActions.createTicket(payload))
  }

  return (
    <Box className={classes?.CreateTicket || ""} data-testid="CreateTicket">
      {action === 'create' ? <PageHeader icon={TicketsIcon} bckbtn={true} title={`Create Ticket`} /> : null}
      <FormWrapper
        formFields={frmConfig}
        formData={formData}
        formButtons={action === 'create' ? actionButtons : undefined}
        formOptions={frmOptions}
        setPayload={setFrm}
      />
    </Box>
  )
};

CreateTicket.propTypes = {};

CreateTicket.defaultProps = {};

export default CreateTicket;
