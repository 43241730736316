/***
 *
 * Controller class for user.
 * @file StyledCountdown.js
 * @description StyledCountdown component
 * @author Aditya Rana
 * @since 10/02/2023
 * 
 */

import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './StyledCountdown.style.js';
import './StyledCountdown.scss';
import { Box } from '@mui/material';
import CustomTypography from '../CustomTypography/index.js';

const StyledCountdown = ({hours=0, minutes=0, seconds=0,format='hh:mm:ss',message='',timerEnd=null, completed=()=>{}}) => {
  const classes = useStyles();
  const [paused, setPaused] = useState(false);
  const [over, setOver] = useState(false);
  const [time, setTime] = useState({
    hours: parseInt(hours),
    minutes: parseInt(minutes),
    seconds: parseInt(seconds)
  });

  const tick = () => {
    if (paused || over){ completed(); return};
    if (time.hours === 0 && time.minutes === 0 && time.seconds === 0){
      setOver(true);
      // setTime({
      //   hours:0,
      //   minutes: 0,
      //   seconds: 0
      // });
     return;
    }
    else if (time.minutes === 0 && time.seconds === 0)
      setTime({
        hours: time.hours - 1,
        minutes: 59,
        seconds: 59
      });
    else if (time.seconds === 0)
      setTime({
        hours: time.hours,
        minutes: time.minutes - 1,
        seconds: 59
      });
    else
      setTime({
        hours: time.hours,
        minutes: time.minutes,
        seconds: time.seconds - 1
      });

      setOver(false);
  };

  const reset = () => {
    setTime({
      hours: parseInt(hours),
      minutes: parseInt(minutes),
      seconds: parseInt(seconds)
    });
    setPaused(false);
    setOver(false);
  };

  useEffect(() => {
    let timerID = setInterval(() => tick(), 1000);
    return () => clearInterval(timerID);  
  });


  const getFormat=(format)=>{
    if(format === 'hh:mm:ss'){
      return `${time.hours >= 10 ? time?.hours : '0'+time?.hours}:${time.minutes >= 10 ? time?.minutes : '0'+time?.minutes}:${time.seconds >= 10 ? time?.seconds : '0'+time?.seconds}`;
    }
    else if(format === 'mm:ss'){
    return `${time.minutes >= 10 ? time?.minutes : '0'+time?.minutes}:${time.seconds >= 10 ? time?.seconds : '0'+time?.seconds}`;
    } 
    else {
    return `${time.seconds >= 10 ? time?.seconds : '0'+time?.seconds}`;
    }
  }

  const getSuffix= ()=>{
    if(time.hours === 0 && time.minutes === 0 && time.seconds !== 0){
      return 'sec';
    }
    else if (time.minutes !== 0 && time.hours === 0 && time?.seconds !==0)
      return 'min';
    
    else if (time.minutes !== 0 && time.hours !== 0 && time?.seconds !==0)
      return 'hr';   
    else
      return '';
  }


  return (
    <Box className={classes?.StyledCountdown || ""} data-testid="StyledCountdown">
      {
        !over
        ?
        message ? <CustomTypography content={`${message} ${getFormat(format)}${getSuffix()}`} color={"#999999"} weight={400} size={12}  />
         :  <CustomTypography content={`${getFormat(format)}${getSuffix()}`} color={"#999999"} weight={400} size={12}   /> 
      
      
       : 
       timerEnd &&
        <Box>
        {timerEnd}
        </Box>
      }
 
    </Box>
  )
};

StyledCountdown.propTypes = {};

StyledCountdown.defaultProps = {};

export default StyledCountdown;
