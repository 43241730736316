/***
 *
 * Controller class for user.
 * @file CustomCard.js
 * @description CustomCard component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './CustomCard.style.js';
import './CustomCard.scss';
import { Box, CardHeader, Menu, MenuItem, Stack, Tooltip } from '@mui/material';
import StyledCardWrapper from '../StyledCardWrapper/index.js';
import CustomTypography from '../CustomTypography/index.js';
import ImageWrapper from '../ImageWrapper/index.js';
import StyledButton from '../StyledButton/index.js';
import { useState } from 'react';
import moreIcon from "../../assets/icons/three-vertical-dots.svg"
import _ from 'lodash';

const CustomCard = ({ contentHeaders, data, header, icon, cardStatus, status = [], actions = [], menuActions = [], ...props }) => {
  const classes = useStyles();
  const [anchor, setAnchor] = useState(null);
  const closeMenu = () => {
    setAnchor(null);
  }
  actions = menuActions?.length === 1 ? [...actions, menuActions[0]] : actions;
  return (
    <Box className={classes?.CustomCard || ""} data-testid="CustomCard">
      <StyledCardWrapper >
        <Box className="cardContainer">
          <Box className={cardStatus?.status + "Card"}>
            <Box className="cardTop">
              <Tooltip arrow placement="right" title={cardStatus?.tooltip} enterDelay={1500}>
                <Box className="cardStatusContainer">
                  <CustomTypography
                    size={16}
                    className={"cardStatus " + cardStatus?.status}
                    weight={600}
                    content={cardStatus?.label} />
                </Box>
              </Tooltip>
              <Box className="actionsContainer action-buttons" >
                {
                  actions?.map((a, ind) => {
                    return (
                      a?.component ?
                        typeof a?.component === "function" ? a?.component({ data }) : a?.component
                        :
                        <StyledButton iconButton
                          tooltip={a?.label ? (typeof a?.label === "function" ? a?.label(data) : a?.label) : (typeof a?.toolTip === "function" ? a?.toolTip(data) : a?.toolTip)}
                          className="iconButton" key={ind} onClick={(event) => {
                            a?.onClick(event, data)
                          }}>
                          <ImageWrapper className={a?.type} height={16} width={16} src={typeof a?.icon === "function" ? a?.icon(data) : a?.icon} />
                        </StyledButton>
                    )
                  })

                }
                {
                  menuActions?.length > 1 &&
                  <>
                    <StyledButton iconButton style={{ width: "16px", height: "16px", marginLeft: "8px" }} onClick={(e) => {
                      setAnchor(e?.currentTarget)
                    }} >
                      <ImageWrapper className='moreActions' height={16} width={16} src={moreIcon} />
                    </StyledButton>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchor}
                      open={anchor ? true : false}
                      onClose={closeMenu}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      {
                        menuActions?.map((a, ind) => {
                          return (
                            <MenuItem
                              sx={{ p: a?.component && 0 }}
                              key={ind} onClick={(e) => { a?.onClick && a?.onClick(e, data); !a?.component && closeMenu(); }}>
                              {
                                a?.component ?
                                  <Box sx={{ width: "100%", minHeight: "30px" }}>
                                    {typeof a?.component === "function" ? a?.component({ data: data, cardView: true, onSave: () => { closeMenu() }, onCancel: () => { closeMenu() } }) : a?.component}
                                  </Box>
                                  :
                                  <Box sx={{ display: "flex", alignItems: "center", minHeight: "18px" }}>
                                    {/* sx={{ p:0, mr:1 }} */}
                                    <StyledButton iconButton style={{ padding: "0px", marginRight: "8px" }} >
                                      <ImageWrapper height={16} width={16} src={typeof a?.icon === "function" ? a?.icon(data) : a?.icon} />
                                    </StyledButton>
                                    <CustomTypography
                                      size={12}
                                      weight={500}
                                      lineHeight={15}>
                                      {a?.label ? (typeof a?.label === "function" ? a?.label(data) : a?.label) : (typeof a?.toolTip === "function" ? a?.toolTip(data) : a?.toolTip)}
                                    </CustomTypography>
                                  </Box>
                              }
                            </MenuItem>
                          )
                        }
                        )
                      }
                    </Menu></>
                }
              </Box>
            </Box>

            <CardHeader
              className="cardHeader"
              title={header}
              avatar={icon}
              titleTypographyProps={{ fontWeight: "600", fontSize: '0.9rem' }}
              subheader={!_.isEmpty(status) && <Box className="subStatus" gap={1}>
                {
                  status?.map((s, ind) => {
                    return <Box key={ind} >{s?.content}</Box>
                  })
                }
              </Box>}
              subheaderTypographyProps={{ fontSize: '0.75rem' }}
            />
            <Box className="cardContent">
              <Stack spacing={0.2} direction="column" alignItems="left">
                {Object.keys(contentHeaders)?.map((h, ind) => {
                  return (
                    <Stack direction="row" alignItems="left" key={ind}>
                      <Stack pr={1.5} sx={{ minWidth: "fit-content" }} direction="row" >
                        <CustomTypography
                          size={12}
                          weight={500}
                          lineHeight={19}
                          content={contentHeaders[h]?.label}
                        />
                      </Stack>
                      {
                        contentHeaders[h]?.type === "list" ?
                          <Stack sx={{ width: "100%", minHeight: "19px", display: "block", justifyContent: "flex-end" }} direction="row">
                            {Array?.isArray(data[h]) && data[h]?.map(d => {
                              return <CustomTypography
                                size={12}
                                weight={600}
                                lineHeight={19}
                                content={d}
                                styles={{ "textAlign": "right" }}
                              />

                            })}
                          </Stack>

                          :
                          <Stack sx={{ width: "100%", minHeight: "19px", display: "flex", justifyContent: "flex-end" }} direction="row">
                            {
                              contentHeaders[h]?.component ?
                                (typeof contentHeaders[h]?.component === "function" ?
                                  contentHeaders[h]?.component({ data, cardView: true, onChange: contentHeaders[h]?.onChange, onClick: contentHeaders[h]?.onClick, ...props })
                                  : contentHeaders[h]?.component)
                                :
                                <CustomTypography
                                  size={12}
                                  weight={600}
                                  lineHeight={19}
                                  content={data[h]}
                                />
                            }
                          </Stack>
                      }

                    </Stack>

                  )
                })}
              </Stack>
            </Box>
          </Box>

        </Box >
      </StyledCardWrapper>
    </Box>
  )
};

CustomCard.propTypes = {};

CustomCard.defaultProps = {};

export default CustomCard;
