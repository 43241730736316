import { areaTypes } from "../types";
import _ from 'lodash';

let intialState = {
    loading: false,
    list: [],
    item: null,
    deviceList: [],
    error: null,
    areaDeleted: null
}

export function areas(state = intialState, action) {
    switch (action.type) {
        case areaTypes.GET_AREAS_REQUEST:
        case areaTypes.GET_AREA_REQUEST:
        case areaTypes.ADD_REQUEST:
        case areaTypes.UPDATE_REQUEST:
        case areaTypes.GET_AREA_DEVICES_DETAILS_REQUEST:
        case areaTypes.AREA_SYNC_REQUEST:
        case areaTypes.DELETE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case areaTypes.GET_AREAS_SUCCESS:
            return {
                ...state,
                list: action.payload,
                loading: false
            }
        case areaTypes.GET_AREA_SUCCESS:
            return {
                ...state,
                item: action.payload,
                list: _.isEmpty(state.list)
                    ? [action.payload]
                    : state?.list?.map((item) =>
                        item.areaId === action.payload.areaId ? action.payload : item
                    ),
                loading: false
            }
        case areaTypes.ADD_SUCCESS:
            state?.list?.push(action.payload)
            return {
                ...state,
                list: _.isEmpty(state.list)
                    ? [action.payload]
                    : state?.list?.map((item) =>
                        item.areaId === action.payload.areaId ? action.payload : item
                    ),
                item: action.payload,
                loading: false
            }
        case areaTypes.UPDATE_SUCCESS:
            return {
                ...state,
                list: state?.list?.map(a => { if (a.areaId === action.payload.areaId) { return action.payload; } else { return a; } }),
                item: action.payload,
                loading: false
            }
        case areaTypes.GET_AREA_DEVICES_DETAILS_SUCCESS:
            return {
                ...state,
                deviceList: [...action.payload],
                loading: false
            }
        case areaTypes.DELETE_SUCCESS:
            return {
                ...state,
                list: state?.list?.filter(a => a.areaId !== action.areaId),
                item: "",
                loading: false
            }
        case areaTypes.AREA_SYNC_SUCCESS:
            return {
                ...state,
                loading: false,
            }
        case areaTypes.UPDATE_DELETE_AREA_STATUS:
            return {
                ...state,
                areaDeleted: action?.payload?.deleteAreaStatus
            }
        case areaTypes.GET_AREAS_FAILURE:
        case areaTypes.GET_AREA_FAILURE:
        case areaTypes.ADD_FAILURE:
        case areaTypes.UPDATE_FAILURE:
        case areaTypes.GET_AREA_DEVICES_DETAILS_FAILURE:
        case areaTypes.AREA_SYNC_FAILURE:
        case areaTypes.DELETE_FAILURE:
            return {
                ...state,
                error: action.payload.error,
                loading: false
            };
        default:
            return state
    }
}