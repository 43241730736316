/***
 *
 * Controller class for user.
 * @file CreateGroups.js
 * @description CreateGroups component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import { useState } from 'react';
import { Box } from '@mui/material';
// import PropTypes from 'prop-types';
import './CreateGroups.scss';
import PageHeader from '../../components/PageHeader';
import CustomStepper from '../../components/CustomStepper';
import GroupConfirmation from '../GroupConfirmation';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../helpers/history.helper.js';
import { groupActions } from '../../redux/actions/group.actions';
import ImageWrapper from '../../components/ImageWrapper';
import StyledTextfield from '../../components/StyledTextfield';
import StyledSnackBar from "../../components/StyledSnackBar/index.js";
import GroupDragDropWrapper from '../../components/GroupDragDropWrapper';
import { dragAndDropActions } from '../../redux/actions';
import editIcon from '../../assets/icons/edit-2.svg';
import groupIcon from '../../assets/icons/group.svg';
import { getMessageStr } from '../../helpers';
import Validator from '../../helpers/validator.helper';
import StyledButton from '../../components/StyledButton';

const groupNameValidation = {
  req: [true, getMessageStr('group-add-groupNameEmpty')],
  alphaNumChars: ["-", getMessageStr("group-add-groupNameInvalid")],
  minLen: [2, getMessageStr("group-add-groupNameMinLen")],
  maxLen: [30, getMessageStr("group-add-groupNameMaxLen")],
};
const CreateGroups = ({ groupOperation }) => {
  const dispatch = useDispatch();
  const steps = [{ label: "ADD DEVICES" }, { label: "CONFIRMATION" }]
  const [data, setData] = useState();
  const [stepCount, setStepCount] = useState(1);
  const readOnly = true;
  const [enableEdit, setEnableEdit] = useState(groupOperation === 'edit');
  const [snackBar, setSnackBar] = useState({ show: false, type: 'error', message: getMessageStr('group-add-groupNameEmpty') }); //WGJGBM group-add-groupNameEmpty
  const [errorMsg, setErrorMsg] = useState('');
  const group = useSelector((state) => state?.groups?.group);
  const [groupName, setGroupName] = useState(groupOperation === 'edit' ? group?.name : '');
  const dropList = useSelector(state => state?.dragAndDrops?.dropList)
  const [ifMovedToNextStep, setIfMovedToNextStep] = useState(false);
  const [notFoundDevicesInDropList, setNotFoundDevicesInDropList] = useState([]);

  const handleChange = (e) => {
    setErrorMsg(Validator(e.target.value, "text", groupNameValidation));
    setGroupName(e.target.value);
  }

  const handleBackStep = () => {
    if (stepCount === 1) {
      //dispatch(dragAndDropActions.emptyLists())
      history.goBack();
    }
    setStepCount(1);
  };
  const closeSnackBar = () => {
    setSnackBar({
      show: false,
      type: 'error',
      message: getMessageStr('group-add-groupNameEmpty') //WGJGBM group-add-groupNameEmpty
    });
  };

  const handleNextStep = () => {
    if (stepCount === 1) {
      setErrorMsg(Validator(groupName, "text", groupNameValidation));
      dropList.length === 0 && dispatch(dragAndDropActions.setErrorMessage(getMessageStr('group-add-noDevicesSelected'))); //WGJGBM group-add-noDevicesSelected
      notFoundDevicesInDropList.length !== 0 && dispatch(dragAndDropActions.setErrorMessage(getMessageStr('group-add-removeInactiveDevices'))); //WGJGBM group-add-removeInactiveDevices
      if (dropList.length !== 0 && notFoundDevicesInDropList.length === 0 && !Validator(groupName, "text", groupNameValidation)) {
        setIfMovedToNextStep(true);
        setEnableEdit(true);
        setData(dropList);
        dispatch(dragAndDropActions.setErrorMessage(''));//WGJGBM< group-emptyMessage
        setStepCount(2);
      }
    } else {
      let createGroupData = { ...data, name: groupName };
      setErrorMsg(Validator(groupName, "text", groupNameValidation));
      if (!Validator(groupName, "text", groupNameValidation)) {
        if (groupOperation === 'edit') {
          delete createGroupData.spaceId;
          dispatch(groupActions.editGroup(group.groupId, createGroupData, history.goBack));
        } else {
          dispatch(groupActions.createGroup(createGroupData, history.goBack));
        }
      }
    }
  };

  const currentPage = (steps) => {
    switch (steps) {
      case 2:
        return <GroupConfirmation comingData={data} settingUpData={setData} />
      default:
        return <GroupDragDropWrapper ifEdit={groupOperation === 'edit'} ifMovedToNextStep={ifMovedToNextStep} setNotFoundDevicesInDropList={setNotFoundDevicesInDropList} />
    }
  }
  return (
    <Box sx={{ maxWidth: "100%" }} className="CreateGroups" data-testid="CreateGroups">
      <PageHeader title={groupOperation === 'edit' ? 'Edit Group' : 'Create Group'} bckbtn={true} icon={groupIcon} />
      <div className="form-group">
        <form>
          <label style={{marginRight:"8px"}}>Group Name<label style={{ color: "#BC2200" }}>*</label> : </label>
          {enableEdit ? <label disabled={readOnly}>{groupName}<ImageWrapper style={{ marginRight: '5px', marginLeft: '10px' }} onClick={() => { setEnableEdit(false) }} src={editIcon} /></label> :
            <StyledTextfield color={errorMsg !== '' ? 'error' : ""} helperText={errorMsg} sx={{ minWidth: '290px', pb: '20px' }} error={errorMsg ? true : false} value={groupName} type="text" name="groupName" className="" onChange={handleChange} />
          }
        </form>
      </div>
      <Box width={'100%'} borderRadius="10px">
        <CustomStepper labels={steps.map(item => item.label)} activeStep={stepCount} />
      </Box>
      <Box sx={{ py: 1 }}>
        {currentPage(stepCount)}
      </Box>
      <Box sx={{ width: "100%", textAlign: "right" }}>
        <StyledButton
          sx={{ minWidth: "90px" }}
          variant="outlined"
          onClick={handleBackStep}
        >
          {stepCount === 2 ? "Back" : "Cancel"}
        </StyledButton>
        <StyledButton
          sx={{ ml: 1, minWidth: "90px" }}
          onClick={handleNextStep}
        >
          {stepCount === 2 ? "Save" : "Next"}

        </StyledButton>
      </Box>
      <StyledSnackBar
        open={snackBar?.show}
        onClose={closeSnackBar}
        autoHideDuration={2000}
        data={{
          type: snackBar?.type,
          message: snackBar?.message,
        }}
      />
    </Box>
  )
};

CreateGroups.propTypes = {};

CreateGroups.defaultProps = {};

export default CreateGroups;
