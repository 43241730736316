export const groupTypes = {

    GET_GROUPS_REQUEST: 'GET_GROUPS_REQUEST',
    GET_GROUPS_SUCCESS: 'GET_GROUPS_SUCCESS',
    GET_GROUPS_FAILURE: 'GET_GROUPS_FAILURE',

    GET_GROUP_REQUEST: 'GET_GROUP_REQUEST',
    GET_GROUP_SUCCESS: 'GET_GROUP_SUCCESS',
    GET_GROUP_FAILURE: 'GET_GROUP_FAILURE',

    ADD_REQUEST: 'GROUP_ADD_REQUEST',
    ADD_SUCCESS: 'GROUP_ADD_SUCCESS',
    ADD_FAILURE: 'GROUP_ADD_FAILURE',

    UPDATE_REQUEST: 'GROUP_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'GROUP_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'GROUP_UPDATE_FAILURE',

    DELETE_REQUEST: 'GROUP_DELETE_REQUEST',
    DELETE_SUCCESS: 'GROUP_DELETE_SUCCESS',
    DELETE_FAILURE: 'GROUP_DELETE_FAILURE',

    UPDATE_GROUP_CONTROL_REQUEST: 'UPDATE_GROUP_CONTROL_REQUEST',
    UPDATE_GROUP_CONTROL_SUCCESS: 'UPDATE_GROUP_CONTROL_SUCCESS',
    UPDATE_GROUP_CONTROL_FAILURE: 'UPDATE_GROUP_CONTROL_FAILURE',

    // GET_GROUP_DEVICES_DETAILS_REQUEST : 'GET_GROUP_DEVICES_DETAILS_REQUEST',
    // GET_GROUP_DEVICES_DETAILS_SUCCESS : 'GET_GROUP_DEVICES_DETAILS_SUCCESS',
    // GET_GROUP_DEVICES_DETAILS_FAILURE : 'GET_GROUP_DEVICES_DETAILS_FAILURE',

    GROUP_SYNC_REQUEST: 'GROUP_SYNC_REQUEST',
    GROUP_SYNC_SUCCESS: 'GROUP_SYNC_SUCCESS',
    GROUP_SYNC_FAILURE: 'GROUP_SYNC_FAILURE',

    //For Group Sync By Controller Id
    SYNC_BY_DEVICE_REQUEST: 'SYNC_BY_DEVICE_REQUEST',
    SYNC_BY_DEVICE_SUCCESS: 'SYNC_BY_DEVICE_SUCCESS',
    SYNC_BY_DEVICE_FAILURE: 'SYNC_BY_DEVICE_FAILURE',

    DELETE_GROUPS_BY_DEVICE_REQUEST: "DELETE_GROUPS_BY_DEVICE_REQUEST",
    DELETE_GROUPS_BY_DEVICE_SUCCESS: "DELETE_GROUPS_BY_DEVICE_SUCCESS",
    DELETE_GROUPS_BY_DEVICE_FAILURE: "DELETE_GROUPS_BY_DEVICE_FAILURE",


    UPDATE_GROUP_STATUS: 'UPDATE_GROUP_STATUS',
    UPDATE_DELETE_GROUP_STATUS: "UPDATE_DELETE_GROUP_STATUS",

    GET_MULTIPLE_GROUPS_REQUEST: 'GET_MULTIPLE_GROUPS_REQUEST',
    GET_MULTIPLE_GROUPS_SUCCESS: 'GET_MULTIPLE_GROUPS_SUCCESS',
    GET_MULTIPLE_GROUPS_FAILURE: 'GET_MULTIPLE_GROUPS_FAILURE',

    GET_GROUP_COUNT_REQUEST: 'GET_GROUP_COUNT_REQUEST',
    GET_GROUP_COUNT_SUCCESS: 'GET_GROUP_COUNT_SUCCESS',
    GET_GROUP_COUNT_FAILURE: 'gET_GROUP_COUNT_FAILURE',

}