import { format as setF, formatDistance, subDays } from 'date-fns'

const dateIsValid = (date) => {
    // console.log("date--------", date, new Date(date), new Date(date).getTime())
    return !Number.isNaN(new Date(date).getTime());
}

export const formatedDate = ({ date, format = 'MMM dd, yyyy', locale = "en" }) => {
    // console.log("formatedDate --------- ", date, date.toString().length,  new Date(date * 1000))
    return setF(dateIsValid(date) ? new Date(typeof(date) === 'number' && date.toString().length < 11 ? date * 1000 : date ) : new Date(), format);
}


export const dayDif = (sDate, eDate) => {
    return formatDistance(subDays(new Date(sDate), 3), new Date(eDate), { addSuffix: true });
}
