import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    StyledGraph: {},
    AvailableList: {
        paddingTop: "0px !important",
        paddingBottom: "10px !important",
        '& li': {
            listStyleType: 'none',
            display: 'inline-block',
            padding: "0px",
            color: "#818181",
            width: 'initial',
            textTransform: 'capitlize',
            fontSize: '0.75rem',
            '& span': {
                color: "#0F8D48",
                borderRight: "1px solid #CECECE",
                paddingRight: "13px",
                marginRight: "13px"
            },
            '&:last-child': {
                '& span': {
                    border: 'none'
                }
            }
        }

    }
});