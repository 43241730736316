/***
 *
 * Controller class for user.
 * @file MssLayout.js
 * @description MssLayout component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import { Box, Paper } from '@mui/material';
import React from 'react';
// import PropTypes from 'prop-types';
// import { useStyles } from './MssLayout.style.js';
import TopBar from '../../components/TopBar/index.js';
import StyledBreadcrumbs from '../../components/StyledBreadcrumbs/index.js';
import { findNode, getWindowDimensions } from '../../services/util.service.js';
import SideBar from '../../components/SideBar/index.js';
import Footer from '../../components/Footer/index.js';
import { useState } from 'react';
import { spaceActions } from '../../redux/actions/space.actions.js';
import { useDispatch, useSelector } from 'react-redux';
import { useProps } from '../../hooks/useProps.js';
import { spaceTypeActions } from '../../redux/actions/spaceType.actions.js';
import { deviceActions } from '../../redux/actions/device.actions.js';
import { useEffect } from 'react';
import { getLocalItem, getSessionItem } from '../../helpers/storage.helper.js';
import { openMqttConnection } from '../../services/mqtt.service.js';
import { useQuery } from '../../hooks/useQuery.js';
import LeftSpaceList from '../../components/LeftSpaceList';
import { fetchAndSubscribeToGateways, history, isAuthorised } from '../../helpers';
import { appConnections } from '../../config/appConfig.js';
import { features } from '../../constants/permission.constants.js';



const MssLayout = ({ children, expanded, setExpanded, mobileView, drawerWidth, ...props }) => {
  // const classes = useStyles();
  const query = useQuery();

  const addProps = useProps;
  const dispatch = useDispatch();
  const spaces = useSelector(state => state?.spaces?.list);
  const bData = useSelector((state) => state?.spaces?.breadcrumbsData);
  const selectedSpace = useSelector(state => state?.spaces?.selectedSpace);
  const tenantName = useSelector(state => state?.app?.webConfig?.tenantName);
  const [breadcrumbsData, setBreadcrumbsData] = useState([]);
  const [mqttClient, setMqttClient] = useState({});


  // const LeftSpace = () => {
  //   return <LeftSpaceList />;
  // }

  useEffect(() => {
    if (isAuthorised(features?.space)) {
      dispatch(spaceActions.getAllSpaces(10));
      dispatch(spaceTypeActions.getSpaceTypes());
    }

    if (isAuthorised(features?.device)) {
      dispatch(deviceActions.getDeviceModels());
      dispatch(deviceActions.getDeviceCategories());
    }

    const sessionSpace = JSON.parse(
      getSessionItem("miraie-selectedSpace") || "null"
    );
    if (spaces) {
      dispatch(spaceActions.selectSpace(sessionSpace || spaces[0])); //// original line
    }
    // check appConnections to see if mqtt connection needs to be made for the env
    // check device and space permissions before opening the mqtt connection
    // when mqtt connection is open subscribe to connection status topic and fetch all the gateways
    appConnections?.mqtt && isAuthorised(features?.device) && isAuthorised(features?.space) && setMqttClient(openMqttConnection(dispatch, (client) => {
      fetchAndSubscribeToGateways(client, dispatch, tenantName)
    }, () => { }, JSON.parse(getLocalItem('appConfig'))?.webConfig?.tenantName));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (!selectedSpace?.spaceId && spaces) {
      dispatch(spaceActions.selectSpace(spaces[0]));
      dispatch(
        spaceActions.createBreadcrumbsData({
          childrenKey: "Spaces",
          obj: { key: "spaceId", value: spaces[0]?.spaceId },
        })
      );
    } else {
      dispatch(
        spaceActions.createBreadcrumbsData({
          childrenKey: "Spaces",
          obj: { key: "spaceId", value: selectedSpace?.spaceId },
        })
      );
    }
  }, [dispatch, selectedSpace, spaces]);

  useEffect(() => {
    setBreadcrumbsData([...bData]);
  }, [bData]);

  useEffect(() => {
    // ScrollToTop();

    // To cleanup deviceList from reducer
    if (["/spaces/devices"].includes(history.location.pathname)) {
      console.log("clearing devices")
      dispatch(deviceActions?.clearAllDevices);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, history, query])

  useEffect(() => {
    const spaceId = query.get("spaceId");

    const cRoute = props?.routes?.find(r => (("/" + r.path) === history?.location?.pathname));

    const sSpace = spaceId ? spaces?.find(sp => sp.spaceId === spaceId) : JSON.parse(getSessionItem("miraie-selectedSpace") || "{}");

    if (cRoute?.id !== 1061 && cRoute?.parent !== 1061) {
      // console.log("tttt leftNav locationooo 4", history, cRoute?.id, cRoute?.parent)
      dispatch(spaceActions.selectSpace({}));
    } else {
      // console.log("tttt leftNav locationooo 5", cRoute?.id, cRoute?.parent, sSpace?.spaceId ? sSpace : spaces[0])
      dispatch(spaceActions.selectSpace(sSpace?.spaceId ? sSpace : spaces[0]));
    }
    // setSessionItem("hideDataNotFound", true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, query, spaces]);

  return (
    <>
      {["/2d", "/test-layout"].includes(history?.location?.pathname) ? (
        <>{addProps(children, { ...props, mqttClient, mobileView })}</>
      ) : (
        <Box data-testid="MssLayout" sx={{ maxWidth: "100%" }}>
          <TopBar />
          <StyledBreadcrumbs data={breadcrumbsData} sx={{ paddingLeft: "22px", paddingRight: "22px", backgroundColor: "#E5E5E5", position: "fixed", zIndex: "1099" }} fontSize={14} onClick={(event, index, item) => {
            if (!item || (item?.id === 1)) {
              dispatch(spaceActions.selectSpace(spaces[0]));
            } else {
              findNode(spaces, "Spaces", { key: "spaceId", value: item?.id }, (space) => {
                // alert(JSON.stringify(space))
                dispatch(spaceActions.selectSpace(space));
              });
            }
          }} />

          <SideBar expanded={expanded} onExpand={setExpanded} treeNode={{ id: selectedSpace?.spaceId }} routes={props?.routes} menuComp={LeftSpaceList}>
            <Box className="" sx={{ mb: 1, minWidth: "245px", maxWidth: expanded ? `calc(100vw - ${drawerWidth + 24}px)` : `calc(100vw - ${(getWindowDimensions()?.width > 1024 ? 80 : (getWindowDimensions()?.width > 600 ? 94 : 76))}px)`, transition: ".28s" }}>
              <Paper elevation={0} square sx={{ minHeight: "calc(100vh - 155px)", width: "100%", transition: ".28s", overflow: "hidden", ...(history?.location?.pathname === "/" || history?.location?.pathname === "/spaces/dashboard" ? {} : { pt: { md: 3, xs: 2 }, px: { md: 3, sm: 2, xs: 1.5 }, pb: 5 }) }} style={{ ...(props?.style || {}) }}>
                {/* ----------------------- Protected Routes ----------------------- */}

                {/* <pre>{JSON.stringify(props?.routes, null, 2)}</pre> */}
                {addProps(children, { ...props, mqttClient })}

                {/* ---------------------------------------------------------------- */}
              </Paper>
            </Box>
            <Footer />
          </SideBar>
        </Box>
      )}
    </>
  )
};

MssLayout.propTypes = {};

MssLayout.defaultProps = {};

export default MssLayout;
