import { failure, request, success, getMessage } from "../../helpers";
import { dashboardService, delay } from "../../services"
import { dashboardType } from "../types";
import { appActions } from ".";

const getPowerConsumption = (space, interval, counter) => {
    return dispatch => {
        dispatch(request(dashboardType.GET_POWER_CONSUMPTION));
        dashboardService.getPowerConsumption(space, interval, counter)
            .then(({ data }) => {
                dispatch(success(dashboardType.GET_POWER_CONSUMPTION_SUCCESS, { payload: data }));
            },
                ({ error, code }) => {
                    dispatch(failure(dashboardType.GET_POWER_CONSUMPTION_FAILURE, error.toString()));
                    // dispatch(appActions.apiErrorMessage("Unable to fetch power consumption "));
                    getMessage(`ticket-post-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    };
}




export const dashboardActions = {

    getPowerConsumption,

}