import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    StyledTextfield: {
        '& .MuiOutlinedInput-root': {
            background: theme?.input?.background,
            paddingTop: "0px !important",
            '& .MuiOutlinedInput-input': {
                padding: theme?.padding?.input || "8px 10px",
                fontSize: theme?.fontSize?.input || "0.875rem",
                lineHeight: theme?.lineHeight?.input || "18px",
                height: theme?.height?.input || "24px",
            },
            '& fieldset': {
                top: 0,
                '& legend': {
                    display: "none"
                }
            }
        },
        '& .MuiFormHelperText-root': {
            marginLeft: 0
        }
    }
}));
