/***
 *
 * Controller class for user.
 * @file Sticker.js
 * @description Sticker component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Circle, Group } from "react-konva";
import { useLongPress } from "react-use";
// import PropTypes from 'prop-types';
import './Sticker.scss';
import KonvaImage from '../../KonvaImage';
import { pointInPolygon, closestPointsInPolygon, pointCollinearity, outsidePolygon } from '../../../../helpers';
import { useDispatch, useSelector } from 'react-redux';
import { canvasActions } from '../../../../redux/actions';
import _ from 'lodash';

const hitTopLeft = (points, point) => {
  let x = point[0], y = point[1];
  if (!pointInPolygon(points, [x - 12, y - 12])) {
    return 'topleft';
  }
  // if (!pointInPolygon(points, [x - 16, y])) {
    // return 'left';
  // }
  return '';
}
const hitBottomLeft = (points, point) => {
  let x = point[0], y = point[1];
  // if (!pointInPolygon(points, [x, y + 16])) {
    // return 'bottom';
  // }
  if (!pointInPolygon(points, [x - 12, y + 12])) {
    return 'bottomleft';
  }
  return '';
}
const hitTopRight = (points, point) => {
  let x = point[0], y = point[1];
  // if (!pointInPolygon(points, [x, y - 16])) {
    // return 'top';
  // }
  if (!pointInPolygon(points, [x + 12, y - 12])) {
    return 'topright';
  }
  return '';
}
const hitBottomRight = (points, point) => {
  let x = point[0], y = point[1];
  if (!pointInPolygon(points, [x + 12, y + 12])) {
    return 'bottomright';
  }
  // if (!pointInPolygon(points, [x + 16, y])) {
    // return 'right';
  // }
  return '';
}


const getSize =(depth)=>{
  return  28 - depth*5 > 5 ? depth*5 : 5; 
}

const boundfun = (imgpos, shapeObj, depth) => {
  let pos = { x: imgpos?.x, y: imgpos?.y }; /// top-left
  let scrollY = document.getElementById('paperDOM').scrollTop, scrollX = document.getElementById('paperDOM').scrollLeft;  /// to get how much screen has been scrolled
  // console.log('shape objjjj',shapeObj);
  // console.log('scroll ji',scrollX,scrollY, shapeObj, imgpos);

  if (shapeObj?.type === 'rect') {
    if (imgpos.x -3< shapeObj.x) pos['x'] = shapeObj?.x +3;
    if (imgpos.y -3< shapeObj.y) pos['y'] = shapeObj?.y +3;
    if (imgpos.x + 25  > (shapeObj.x + shapeObj.width )) pos['x'] = shapeObj?.x + shapeObj?.width -25 ;
    if (imgpos.y + 25  > (shapeObj.y + shapeObj.height )) pos['y'] = shapeObj?.y + shapeObj?.height - 25 ;
  }
  else if (shapeObj?.type === 'circle') {
    let radius = shapeObj?.radius, x_coord, y_coord;
    var scale =
      (radius - 14 + getSize(depth)-4) / Math.sqrt(Math.pow(imgpos?.x + 14 + getSize(depth) - shapeObj?.x , 2) + Math.pow(imgpos?.y + 14 + getSize(depth) - shapeObj?.y , 2));
    if (scale < 1) { // 
     pos['x']  = (imgpos?.x + 14 - shapeObj?.x) * scale + shapeObj?.x - 14 + getSize(depth);
     pos['y']  = (imgpos?.y + 14 - shapeObj?.y) * scale + shapeObj?.y - 14 + getSize(depth);
    }
  }
  else if (shapeObj?.type === 'polygon') {
    let closest = null;
    const collinearity = pointCollinearity([closest?.x1, closest?.y1], [closest?.x2, closest?.y2], [imgpos?.x, imgpos?.y - 100]);


    let iTl = [imgpos?.x , imgpos?.y ],
      iTr = [imgpos?.x + 25, imgpos?.y],
      iBl = [imgpos?.x, imgpos?.y + 25],
      iBr = [imgpos?.x + 25, imgpos?.y + 25],
      center = [imgpos?.x + 12 - scrollX, imgpos?.y + 12 - scrollY];
    ;


    const isOutside =  outsidePolygon(shapeObj?.points, center, scrollX, scrollY);
    let xtol = 0, ytol =0;

    
    if (isOutside) {
      closest = closestPointsInPolygon(shapeObj?.points, center);     
      // console.log('closest::::',closest, shapeObj?.points, center, isOutside);

      // let x_coord = closest?.intersection[0] , y_coord= closest?.intersection[1];
      let x_coord = center[0] , y_coord= center[1];
      if (x_coord < Math.min(closest.x1, closest.x2)) {
        x_coord = Math.min(closest.x1, closest.x2) ;
      }
      if (x_coord > Math.max(closest.x1, closest.x2)) {
        x_coord = Math.max(closest.x1, closest.x2) ;
      }     


      // if(isOutside === 'right') x_coord -= 15;
      // if(isOutside === 'left') x_coord += 15;

      // if(x_coord + 24 > Math.max(closest.x1, closest.x2)) x_coord -= 24;
     
      // y = m * (x-a) +b; here (a,b) is the point of intersection of image center with the polygon line
      y_coord = (closest.slope) * (x_coord - (closest.intersection)[0]) + (closest.intersection)[1];

      if (y_coord < Math.min(closest.y1, closest.y2)) {
        y_coord = Math.min(closest.y1, closest.y2)  ;
      }
      if (y_coord  > Math.max(closest.y1, closest.y2)) {
        y_coord = Math.max(closest.y1, closest.y2) ;
      }

      // if(isOutside === 'topright') {xtol = -34}
      // if(isOutside === 'bottomright') {xtol = -34}
      // if(isOutside === 'bottomleft') {xtol = +34; ytol=-20}
      
      pos['x'] = x_coord  -12; // <---- 
      pos['y'] = y_coord -12 ; //<---- 
    

      // return pos;
    }
  }
  return pos;
}



const Sticker = ({ id, isSelected, image, onSelect, onChange, onDelete, handleContextMenu, draggable, onDragEnd, profileSpaceId,  handleClickShape=()=>{}, handleDragEnd=()=>{}, handleDrag=()=>{}, ...shapeProps }) => {
  // const imageRef = useRef(null);  
  const shapeRef = useRef();
  const transformerRef = useRef();
  const getSelectedShape = useSelector(state => state?.canvas?.selectedShape);
  const _getModalState = useSelector(state => state?.canvas?.modalState);
  const _getShapes = useSelector(state => state?.canvas?.shapes);
  const [selectedShape, setSelectedShape] = useState(null);
  const dispatch = useDispatch();
  const [shapeobj, setShapeobj] = useState({});
  const _highlightGroup = useSelector(state => state?.canvas?.highlightgroup);


  useEffect(() => {
    if (getSelectedShape) {
      setSelectedShape(getSelectedShape);
    }
  }, [getSelectedShape])

  useEffect(() => {
    if (_highlightGroup && shapeProps?.device) {
      let hgrp = _highlightGroup?.devices.filter(d => d?.deviceId === shapeProps?.device?.deviceId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_highlightGroup])

  useEffect(() => {
    if (isSelected) {
      transformerRef?.current?.nodes([shapeRef?.current]);
      transformerRef?.current?.getLayer()?.batchDraw();
    }
  }, [isSelected]);

  useEffect(() => {   
    shapeRef?.current?.moveToTop();
  }, [shapeRef, shapeProps])

  useEffect(() => {
    if (_getShapes) {
      let findshapeobj = _getShapes?.find((obj) => obj?.spaceId === profileSpaceId && obj?.shapeType === 'shape');
      // let findshapeobj = _getShapes?.find((obj) => obj?.shapeType === 'shape');
      setShapeobj(findshapeobj ? findshapeobj : {})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_getShapes])
 
  const handleSelect = useCallback(
    (event) => {
      event.cancelBubble = true;
      dispatch(canvasActions.selectShape(id));
      handleClickShape(event,shapeProps)

      // onSelect(shapeRef);
    },
    [id]
  );

  const handleDragEndEvent = useCallback(
    (event) => {
      if (!_.isEmpty(selectedShape)) {
        dispatch(canvasActions.moveShape(selectedShape, event));
      }
      dispatch(canvasActions.selectShape(id));
      handleDragEnd(event, selectedShape);
      shapeRef?.current?.moveToTop();
    },
    [selectedShape]
  )

  const onLongPress = () => {
  };



  const handleDragMove = (e) => {

    // let imgpos = e.currentTarget.getAbsolutePosition();
    let imgpos =  e.target.getClientRect();
    let draggable = boundfun(imgpos, shapeobj, shapeProps?.depthLevel );

    if (draggable && draggable?.x && draggable?.y) {
      e.target.x(draggable?.x);
      e.target.y(draggable?.y);
    }
    handleDrag(e,selectedShape)
    // e.target.x(imgpos?.x);
    // e.target.y(236);


  }

  // const longPressEvent = useLongPress(onLongPress, { delay: 200 });




  return (
    <>  
      <Group
        ref={shapeRef}
        draggable={_getModalState && draggable}
        className="Sticker"
        data-testid="Sticker"
        x={shapeProps?.x }
        y={shapeProps?.y }
        // onMouseEnter={showTooltip}
        onClick={handleSelect}
        // onContextMenu={handleSelect}
        onTap={handleSelect}
        onDragStart={handleSelect}
        onDragEnd={handleDragEndEvent}        
        onDragMove={handleDragMove}
      >
        {
          image && <>
            <KonvaImage
              width={image.width}
              height={image?.height}
              image={image}
              depth={shapeProps?.depthLevel}
              device={{ ...shapeProps }}
              name="image"
            />
          </>
        }
      </Group>
    </>
  )
};

Sticker.propTypes = {};

Sticker.defaultProps = {};

export default Sticker;
