import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    StyledEditor: {
        '& .editButton':{
            padding:"0px"
        },
        "& .text":{
            marginRight:"8px"
        }

    }
});
