import { mqttMessageSource } from '../constants/mqtt.constants';
import { getLocalItem } from '../helpers/storage.helper';
const password = JSON.parse(getLocalItem('miraie'))?.accessToken;
// const username = JSON.parse(getLocalItem('appConfig'))?.webConfig?.tenantName || "";
// const clientId = `${mqttMessageSource?.webapp}_:${username}:${Date.now().toString()}`;
export const mqttURL = process.env.REACT_APP_MQTT_URL;
export const mqttTimeout = process.env.REACT_APP_MQTT_TIMEOUT || (60 * 1000);
export const groupDeleteTimeout = process.env.REACT_APP_GROUP_DELETE_TIMEOUT || (70 * 1000);
export const sceneDeleteTimeout = process.env.REACT_APP_SCENE_DELETE_TIMEOUT || (70 * 1000);
export const areaDeleteTimeout = process.env.REACT_APP_AREA_DELETE_TIMEOUT || (70 * 1000);
export const gatewayDebugTimeout = process.env.REACT_APP_GATEWAY_DEBUG_TIMEOUT || (30 * 1000);


export const groupDeleteMessageLatency = process.env.REACT_APP_GROUP_DELETE_MESSAGE_LATENCY || (5 * 1000);
export const sceneDeleteMessageLatency = process.env.REACT_APP_SCENE_DELETE_MESSAGE_LATENCY || (5 * 1000);
export const areaDeleteMessageLatency = process.env.REACT_APP_AREA_DELETE_MESSAGE_LATENCY || (5 * 1000);



export const setMqttTimeout = (ms = mqttTimeout) => {
  return ms;
}

export const connectionOptions = {
  // Clean session
  //clean: true,
  //connectTimeout: 4000,
  // Auth
  // clientId: clientId,
  keepalive: 120,
  password,
  // username
}

