import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    "@media screen and (min-device-width:600px)": {
        status:{
            justifyContent:'flex-end'
        }
    },

    deviceCol: {
        display: 'flex', alignItems: 'center',        
    },
    box:{
        display: 'flex',flexWrap:'wrap'
    },
    mainDiv: {
        display: "flex", justifyContent: "space-between", width:'100%',
    },
    imageDevice: {
        margin: "5px",
        borderRadius: "50%",
        height:'20px',
        width:'20px'
    }
});