/***
 *
 * Controller class for user.
 * @file LeftPanel.js
 * @description LeftPanel component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
// import _ from 'lodash';
// import PropTypes from 'prop-types';
import './LeftPanel.scss';
import CustomAccordion from '../../CustomAccordion';

import { Box } from '@mui/material';
import { useState } from 'react';
import CustomizedTree from '../../CustomizedTree';


const LeftPanel = ({ onChange, space, canvasProfile }) => {
  // const selectedShape = useShapes(shapeSelector);
  const getSelectedShape = useSelector(state => state?.canvas?.selectedShape);
  const [selectedShape, setSelectedShape] = useState(null)
  const _selectedSpace = useSelector(state => state?.canvas?.modalSpace);
  const [defaultState, setDefaultState] = useState({ space: true, properties: true });
  // const dispatch = useDispatch();
  // const spaces = useSelector((state) => state?.spaces?.list);
  const activeSpace = useSelector(state => state?.spaces?.selectedSpace);
  useEffect(() => {
    console.log('get select shape------->', getSelectedShape);
    setSelectedShape(getSelectedShape)
  }, [getSelectedShape])


  return (
    <aside className="palette RightPanel" data-testid="LeftPanel">
      <CustomAccordion
        title={'Spaces'} sx={{ width: '100%' }} defaultState={'panel1'}
        onChange={(state) => {
          setDefaultState({ ...defaultState, ['space']: state })
        }}
        style={{ marginTop: "0px", marginBottom: "2px" }}
      >
        <Box height={'auto'} minHeight={400} maxHeight={600} sx={{ p: 0 }} >
          <CustomizedTree
            listId={'spaceTree'}
            data={[_selectedSpace]}
            activeSpace={activeSpace}
            onClick={(e, val) => onChange(val)}
          // style={style}
          />
        </Box>
      </CustomAccordion>
      {/* <CustomAccordion
        title="JSON"
        defaultState={false}
        // LeftIcon={LeftImage}
        bordered
        contentBg={"#FFFFFF"}
        customsx={{
          details:{
           p:2,
           pr:0         
         }
         }}
      >
        <Box sx={{overflow: "auto", height: "400px", p: 2}}>
          <pre>
            {JSON.stringify(spaces)}
          </pre>
        </Box>
      </CustomAccordion> */}
    </aside>

  )
};

LeftPanel.propTypes = {};

LeftPanel.defaultProps = {};

export default LeftPanel;
