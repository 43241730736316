/***
 *
 * Controller class for user.
 * @file AlertsPopover.js
 * @description AlertsPopover component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './AlertsPopover.style.js';
import './AlertsPopover.scss';
import { Box, Card, Tooltip } from '@mui/material';
import PopoverContainer from '../PopoverContainer/index.js';
import { formatedDate } from '../../helpers/date.helper.js';
import { useSelector, useDispatch } from 'react-redux';
import { alertActions } from '../../redux/actions/alert.actions.js';
import Badge from '@mui/material/Badge';
import { ReactComponent as Critical } from '../../assets/icons/critical-2.svg';
import { ReactComponent as Major } from '../../assets/icons/major-2.svg';
import { ReactComponent as Minor } from '../../assets/icons/minor-2.svg';
import CustomTypography from '../CustomTypography/index.js';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import StyledButton from '../StyledButton/index.js';
import { alertSeverity } from '../../constants/alert.constants.js';
import ImageWrapper from '../ImageWrapper/index.js';

const AlertsPopover = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const alertsList = useSelector(state => state?.alert?.alertsList);
  const _portal = useSelector(state => state?.app?.portal)

  const [alertsAnchorEl, setAlertsAnchorEl] = useState(null);
  const [alertsData, setAlertsData] = useState(null)
  const [alertsCount, setAlertsCount] = useState(null)


  const handleAlertsMenuOpen = (event) => {
    setAlertsAnchorEl(event.currentTarget);
  };

  const handleAlertsClose = () => {
    setAlertsAnchorEl(null)
  };


  useEffect(() => {
    if (_portal === 'mss') {
      dispatch(alertActions.getAlerts())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!_.isEmpty(alertsList)) {
      setAlertsData(alertsList?.alerts)
      setAlertsCount(alertsList?.resultCount)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertsList])


  return (
    <Box className={classes?.AlertsPopover || ""} data-testid="AlertsPopover">
      <StyledButton
        iconButton
        aria-label="show 11 new notifications"
        aria-haspopup="true"
        onClick={handleAlertsMenuOpen}
        color="inherit"
        style={{ color: 'black' }}
      >
        <Badge overlap="rectangular" badgeContent={alertsCount} color="secondary">
          <NotificationsIcon />
        </Badge>
      </StyledButton>

      <PopoverContainer
        className="iconStyles"
        anchorEl={alertsAnchorEl}
        onClose={handleAlertsClose}
      >
        <Box sx={{ minWidth: '300px' }} textAlign="center" display="table" justifyContent="center" alignItems="center">
          <div style={{ maxHeight: "400px", overflow: "auto", marginBottom: "10px",padding:"8px" }}>
            {alertsData && alertsData?.map((alert, i) => {
              return (
                // <Card key={"alert" + i} style={{ padding: "10px" }}>
                <Box sx={{ border: "1px solid rgba(0, 0, 0, 0.1)", marginBottom: "8px", padding: "8px", borderRadius: "8px", wordBreak: "break-all", borderLeft: `8px solid ${alertSeverity[alert?.severity]?.color}` }}>
                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                    <Tooltip arrow enterDelay={1500} title={alertSeverity[alert?.severity]?.label || ""}>
                      <Box>
                        <ImageWrapper width={32} height={32} src={alertSeverity[alert?.severity]?.icon} />
                      </Box>
                    </Tooltip>
                    <Box >
                      <CustomTypography textAlign="left" size={12} color="#999999" weight={500} content={formatedDate({ date: alert?.createdOn, format: "MMM dd, yyyy 'at' hh:mm a", })} />
                      <CustomTypography textAlign="left" size={14} weight={400} content={alert?.alertTitle} />
                    </Box>
                  </Box>
                </Box>
                // </Card>
              )
            })}
          </div>
          <Link onClick={handleAlertsClose} className="seeAll" to={"/alerts"}>{"See All"}</Link>
        </Box>
      </PopoverContainer>
    </Box>
  )
};

AlertsPopover.propTypes = {};

AlertsPopover.defaultProps = {};

export default AlertsPopover;
