import { Grid } from '@mui/material';
import React from 'react';
import Dashboard from '../Dashboard';
// import PropTypes from 'prop-types';
import './HomePage.scss';

const HomePage = ({mqttClient=null}) => {

  // const users = useSelector(state => state?.users);
  // const user = useSelector(state => state?.authentication?.user);
  // const dispatch = useDispatch();


  // useEffect(() => {
  //   dispatch(userActions.getAll());
  // }, [dispatch]);

  // const handleDeleteUser = (id) => {
  //   dispatch(userActions.delete(id));
  // }



  return (
    <Grid container className="HomePage" data-testid="HomePage">
      <Grid item xs={12}>
        {/* <Paper sx={{p: 3}}> */}
        <Dashboard mqttClient={mqttClient} />
        {/* </Paper> */}
      </Grid>
    </Grid>
  )
};

HomePage.propTypes = {};

HomePage.defaultProps = {};

export default HomePage;
