export const MQTT = {
    TEMPERATURE: 2500,
    INTENSITY: 30,
    CNT: "wa",
    SID: 1001,
    PAIRING_DURATION:30
}
export const mqttMessageSource={
    webapp:"wa",
    cloud:"dm"
  }

  export const mqttCodes={
    5:"Not Authorized"
  }