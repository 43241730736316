export const areaMessages = (item) => {
    return {
        'area-add-noSensorsGroupSelected': 'Please select the appropriate sensor(s) or group(s).',
        'area-add-noSensorSelected': 'Please select the sensor(s).',
        'area-add-noGroupSelected': 'Please select the group(s).',
        'area-add-removeInactiveDevices': 'Please remove inactive device(s) marked in red color.',
        'area-formValidation-areaNameEmpty': 'Please enter the Area Name.',
        'area-formValidation-areaNameInvalid': 'Please enter the valid Area Name.',
        "area-delete-confirmation": `Are you sure you want to delete ${item}?`
    }
}

export const areaAPIMessages = {
    "area-post-201": "Area successfully created.",
    "area-post-200": "Area successfully created.",
    "area-post-400": "Area creation request is failed, please reverify the input(s) and try again.",
    "area-post-401": "Area creation request is unauthorized, please verify your access and try again.",
    "area-post-403": "Area creation request is unauthorized, please verify your access and try again.",
    "area-post-404": "Area creation request is failed due to one or more sensor(s)/group(s) not found, please recheck the input request and try again.",
    "area-post-500": "Area creation request is failed due to internal error, please try again later.",
    "area-put-200": "Area successfully updated.",
    "area-put-400": "Area edit request is failed, please reverify the input(s) and try again.",
    "area-put-401": "Area edit request is unauthorized, please verify your access and try again.",
    "area-put-403": "Area edit request is unauthorized, please verify your access and try again.",
    "area-put-404": "Area edit requst is failed due to one or more sensor(s)/group(s) not found, please recheck the input request and try again.",
    "area-put-500": "Area edit request is failed due to internal error, please try again later.",
    "areaById-get-200": "Requested Area details successfully retrieved.",
    "areaById-get-400": "Unable to retrieve the Area(s),please reverify the input(s) and try again.",
    "areaById-get-401": "Unable to retrieve the Area(s), please verify your access and try again.",
    "areaById-get-403": "Unable to retrieve the Area(s), please verify your access and try again.",
    "areaById-get-404": "Unable to retrieve the Area(s), please try again later.",
    "areaById-get-500": "Unable to retrieve the Area(s) due to internal error, please try again later.",
    "areaByController-get-200": "Area(s) Details successfully retrieved.",
    "areaByController-get-401": "Unable to retrieve the Area(s), please verify your access and try again.",
    "areaByController-get-403": "Unable to retrieve the Area(s), please verify your access and try again.",
    "areaByController-get-404": "Unable to retrieve the Area(s), please check the controller status and try again.",
    "areaByController-get-500": "Unable to retrieve the Area(s) due to internal error, please try again later.",
    "areaBySpace-get-200": "Area(s) Details successfully retrieved.",
    "areaBySpace-get-401": "Unable to retrieve the Area(s),please reverify the input(s) and try again.",
    "areaBySpace-get-403": "Unable to retrieve the Area(s),please reverify the input(s) and try again.",
    "areaBySpace-get-404": "Unable to retrieve the Area(s), please try again later.",
    "areaBySpace-get-500": "Unable to retrieve the Area(s) due to internal error, please try again later.",
    "areaDeviceDetails-get-404": "Unable to retrieve the requested device details, please try again later.",
    "area-delete-204": "Area successfully deleted.",
    "area-delete-401": "Unable to delete the Area,please reverify the input(s) and try again.",
    "area-delete-403": "Unable to delete the Area,please reverify the input(s) and try again.",
    "area-delete-404": "Unable to delete the Area, please try again later.",
    "area-delete-500": "Unable to delete the Area due to intneral error, plee try again later.",
    "syncArea-put-200": "Area Sync request successfully initiated. It may take a while to get completed.",
    "syncArea-put-401": "Unable to perform the Area sync operation, please verify your access and try again.",
    "syncArea-put-403": "Unable to perform the Area sync operation, please verify your access and try again.",
    "syncArea-put-404": "Unable to retrieve the request Area sync status, please try again later.",
    "syncArea-put-500": "Area Sync operation is failed due to internal error, please try again later",
}