import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    AssignedGateways: {
       "& .action-buttons" :{
            "& .delete": {
              filter: "invert(12%) sepia(80%) saturate(5694%) hue-rotate(24deg) brightness(98%) contrast(102%) !important"
            }
          }
    }
});
