
import { roleTypes } from '../types/role.type'
import { request, success, failure, getMessage } from '../../helpers';

import { delay, roleService } from '../../services';
import { appActions } from './app.actions';

const getAllRoles = (portal='mss') => {
    return dispatch => {
        dispatch(request(roleTypes.GET_ALL_REQUEST));
        roleService.getAllRoles(portal)
            .then(({data:roles}) => {
                    dispatch(success(roleTypes.GET_ALL_SUCCESS, { payload: roles }));                    
                },
                ({error}) => {
                    dispatch(failure(roleTypes.GET_ALL_FAILURE, error.toString()));
                    dispatch(appActions.apiErrorMessage(error.toString()));
                }
            );
    };
}

const getRole = (name, comingFrom = 'permissions', portal="mss", cb=(params)=>{}) => {
	return dispatch => {
		dispatch(request(roleTypes.GET_REQUEST));
		roleService.getRole(name, portal)
            .then(({data: permissions}) => {
                dispatch(success(roleTypes.GET_SUCCESS, { payload: permissions, comingFrom: comingFrom }));
                cb(permissions);
            },
            ({error, code}) => {
                dispatch(failure(roleTypes.GET_FAILURE, error?.toString()));
                getMessage(`getUserPermissionById-get-${code}`).then(delay(500)).then((msg) => {
                    dispatch(appActions.apiErrorMessage(msg));
                });
            }
        );
	};
}

const addRole = (payload, portal = "mss", cb = () => {}) => {
    return dispatch => {
        dispatch(request(roleTypes.ADD_REQUEST));
        roleService.addRole(payload, portal)
            .then(({data: role, status}) => {
                    dispatch(success(roleTypes.ADD_SUCCESS, { payload: role }));
                    getMessage(`addPermission-post-${status}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiSuccessMessage(msg));
                        cb();
                    });
                },
                ({error,code}) => {
                    dispatch(failure(roleTypes.ADD_FAILURE, error.toString()));
                    getMessage(`addPermission-post-${code}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    });
                }
            );
    };
}
const updateRole = (name, payload, cb = () => {}) => {
    return dispatch => {
        dispatch(request(roleTypes.UPDATE_REQUEST));
        roleService.updateRole(name, payload)
            .then(({data: space, status}) => {
                    dispatch(success(roleTypes.UPDATE_SUCCESS, { payload: space }));
                    getMessage(`updateUserPermissions-put-${status}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiSuccessMessage(msg));
                        cb();
                    });
                },
                ({error,code}) => {
                    dispatch(failure(roleTypes.UPDATE_FAILURE, error.toString()));
                    getMessage(`updateUserPermissions-put-${code}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    });
                }
            );
    };
}

const deleteRole = (permission, portal="mss", cb = () => {}) => {
    return dispatch => {
        dispatch(request(roleTypes.DELETE_ROLE));
        roleService.deleteRole(permission, portal)
            .then(({data: space, status}) => {
                    dispatch(success(roleTypes.DELETE_ROLE_SUCCESS, { payload: permission }));
                    getMessage(`deletePermission-delete-${status}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiSuccessMessage(msg));
                        cb();
                    });
                },
                ({error,code}) => {
                    dispatch(failure(roleTypes.DELETE_ROLE_FAILURE, error.toString()));
                    getMessage(`deletePermission-delete-${code}`).then(delay(500)).then((msg) => {
                        dispatch(appActions.apiErrorMessage(msg));
                    });
                }
            );
    };
}
export const roleActions = {
    getAllRoles,
    getRole,
    addRole,
    updateRole,
    deleteRole
};