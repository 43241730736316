
const apiMessages = {
    "upgradeFirmware-get-200": "Firmware upgrade request has been accepted, it may take a while to get completed.",
    "upgradeFirmware-get-401": "Unable to upgrade firmware, please verify your access and try again.",
    "upgradeFirmware-get-400": "Unable to upgrade firmware, please check the firmware version and try again later.",
    "upgradeFirmware-get-403": "Unable to upgrade firmware, please verify your access and try again.",
    "upgradeFirmware-get-404": "Unable to find the device, please try again later.",
    "upgradeFirmware-get-500": "Unable to upgrade firmware due to internal issue, please try again later.",
    "upgradeFirmware-get-502": "Unable to upgrade firmware due to internal issue, please try again later.",
    "upgradeFirmware-get-503": "Unable to upgrade firmware due to internal issue, please try again later.",

    "nextApplicableFirmware-get-200": "Next Firmware Versions fetched successfully.",
    "nextApplicableFirmware-get-401": "Unable to fetch next applicable firmwares, please verify your access and try again.",
    "nextApplicableFirmware-get-400": "Unable to fetch next applicable firmwares, please check the firmware version and model Id.",
    "nextApplicableFirmware-get-403": "Unable to fetch next applicable firmwares, please verify your access and try again.",
    "nextApplicableFirmware-get-404": "No next applicable firmwares available, please try again later.",
    "nextApplicableFirmware-get-500": "Unable to fetch next applicable firmwares due to internal issue, please try again later.",
    "nextApplicableFirmware-get-502": "Unable to fetch next applicable firmwares due to internal issue, please try again later.",
    "nextApplicableFirmware-get-503": "Unable to fetch next applicable firmwares due to internal issue, please try again later.",

    "firmwareDistribution-get-200": "Firmware Distribution fetched successfully.",
    "firmwareDistribution-get-401": "Unable to fetch firmware distribution, please verify your access and try again.",
    "firmwareDistribution-get-400": "Unable to fetch firmware distribution, please check the category.",
    "firmwareDistribution-get-403": "Unable to fetch firmware distribution, please verify your access and try again.",
    "firmwareDistribution-get-404": "No firmware distribution available, please try again later.",
    "firmwareDistribution-get-500": "Unable to fetch firmware distribution due to internal issue, please try again later.",
    "firmwareDistribution-get-502": "Unable to fetch firmware distribution due to internal issue, please try again later.",
    "firmwareDistribution-get-503": "Unable to fetch firmware distribution due to internal issue, please try again later.",

    "firmwareList-get-200": "Firmware List fetched successfully.",
    "firmwareList-get-401": "Unable to fetch firmware list, please verify your access and try again.",
    "firmwareList-get-400": "Unable to fetch firmware list, please check the category.",
    "firmwareList-get-403": "Unable to fetch firmware list, please verify your access and try again.",
    "firmwareList-get-404": "No firmware list available, please try again later.",
    "firmwareList-get-500": "Unable to fetch firmware list due to internal issue, please try again later.",
    "firmwareList-get-502": "Unable to fetch firmware list due to internal issue, please try again later.",
    "firmwareList-get-503": "Unable to fetch firmware list due to internal issue, please try again later.",

    "firmware-delete-200": "Firmware deleted successfully.",
    "firmware-delete-401": "Unable to delete firmware, please verify your access and try again.",
    "firmware-delete-400": "Unable to delete firmware, please check the firmware details.",
    "firmware-delete-403": "Unable to delete firmware, please verify your access and try again.",
    "firmware-delete-404": "Firmware not found, please try again later.",
    "firmware-delete-500": "Unable to delete firmware due to internal issue, please try again later.",
    "firmware-delete-502": "Unable to delete firmware due to internal issue, please try again later.",
    "firmware-delete-503": "Unable to delete firmware due to internal issue, please try again later.",

}

const inlineMessages = (item) => {
    return {
        "fota-distribution-categoryRequired": "Please select categories.",
        "fota-distribution-tenantRequired": "Please select a tenant.",
        "fota-upgrade-categoryRequired": "Please select category.",
        "fota-upgrade-modelRequired": "Please select model.",
        "fota-upgrade-gatewayRequired": "Please select gateway.",
        "fota-upgrade-listEmpty": "There are no devices available for firmware upgrade.",
        "fota-upgrade-noAvailableFw": "Firmware up to date.",

        "fota-distribution-listEmpty": "Firmware distribution is not available, please add devices.",

        "fota-list-categoryRequired": "Please select category.",
        "fota-list-modelRequired": "Please select model.",
        "fota-list-tenantRequired": "Please select tenant.",
        "fota-list-listEmpty": "There are no firmware that match the search criteria.",
        "fota-statusExpired-tooltip": "Status was updated more than 10 minutes ago.",

        "deleteFirmware-send-confirm": `Are you sure you want to delete ${item}?`

    }
}

const mqttMessages = (item) => {
    return {
        "firmwareUpgrade-receive-upgrade_ended": "Firmware upgrade will take few minutes to complete, please revert to the FOTA page after 10 minutes.",
        // "firmwareUpgrade-receive-upgrade_successfully": "Firmware upgrade has completed.s"

    }
}

export const fotaApiMessages = (item) => {
    return {
        ...apiMessages,
        ...inlineMessages(item),
        ...mqttMessages(item)
    }
}

