import {
    ruleMessages,
    ruleAPIMessages,
    canvasMessages,
    deviceApiMessages,
    deviceMqttMessages,
    deviceInlineMessages,
    sceneMessages,
    groupMessages,
    groupAPIMessages,
    sceneAPIMessages,
    areaMessages,
    areaAPIMessages,
    reportApiMessages,
    reportMessages,
    ticketApiMessages,
    userMessages,
    spaceAPIMessages,
    spaceTypeAPIMessages,
    spaceMessages,
    spaceTypeMessages,
    ticketMessages,
    tenantApiMessages,
    alertsApiMessages,
    tenantMessages,
    inlineUserMessage,
    deviceTooltipMessages,
    alertInlineMessages,
    fotaApiMessages,

} from "../constants/";
import { mqttMessages } from "../constants/messages/mqtt.messages";
import { notificationAPIMessages } from "../constants/messages/notification.messages";
import { rotateKeyApiMessages, rotateKeyInlineMessages } from "../constants/messages/rotateKey.messages";

// Message dictionary
const messages = (key, item = null) => {
    const msg = {
        ...ruleMessages(item),
        ...ruleAPIMessages,
        ...userMessages(item),
        ...canvasMessages,
        ...sceneMessages(item),
        ...sceneAPIMessages,
        ...groupMessages(item),
        ...groupAPIMessages,
        ...areaMessages(item),
        ...areaAPIMessages,
        ...reportMessages,
        ...reportApiMessages,
        ...ticketApiMessages,
        ...spaceAPIMessages(item),
        ...spaceTypeAPIMessages,
        ...spaceMessages(item),
        ...spaceTypeMessages(item),
        ...deviceApiMessages(item),
        ...deviceMqttMessages(item),
        ...deviceInlineMessages(item),
        ...deviceTooltipMessages(item),
        ...tenantApiMessages,
        ...tenantMessages(item),
        ...ticketMessages,
        ...alertsApiMessages,
        ...inlineUserMessage,
        ...fotaApiMessages(item),
        ...rotateKeyApiMessages,
        ...rotateKeyInlineMessages(item),
        ...alertInlineMessages,
        ...notificationAPIMessages,
        ...mqttMessages
    };

    return msg[key] || 'Unknown error, please try again later.';
}

export const getMessage = (key, item = null) => {
    return Promise.resolve(messages(key, item));//.toSentenceCase();
}

export const getMessageStr = (key, item = null) => {
    return messages(key, item);//.toSentenceCase();
}