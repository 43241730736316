import 'firebase/compat/messaging';
import { messaging, publicKey } from "../config/firebaseConfig"
import { appConnections } from '../config/appConfig';
import { notificationActions } from '../redux/actions';

export const getFirebaseToken = async (setTokenFound,dispatch) => {
    // const _portal = JSON.parse(localStorage.getItem('miraie'))?.portal
    const _portal = JSON.parse(localStorage.getItem('appConfig'))?.portal
    const miraieStr = JSON.parse(localStorage.getItem("miraie"))
    let currentToken = '';
    if (miraieStr && miraieStr?.accessToken && appConnections && appConnections?.notifications) {
        try {
            currentToken = await messaging.getToken({ vapidKey: publicKey });
            console.log("Notification call for token::", currentToken);

            if (currentToken) {
                setTokenFound(true);
            } else {
                setTokenFound(false);
            }

            var data = { "platform": "web", "token": currentToken }

            if (_portal === 'mss' && appConnections?.notifications) {
               dispatch(notificationActions?.subscribeNotification(data));
            }

        } catch (error) {
            console.log('An error occurred while retrieving notification token.', error);
        }
    }
    return currentToken;
};

export const onMessageListener = () =>
    new Promise((resolve) => {
        messaging.onMessage((payload) => {
            resolve(payload);
        });
    });

onMessageListener()
