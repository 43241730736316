import React from 'react';
import './errorMessage.scss';
import ImageWrapper from '../ImageWrapper';
import ErrorIcon from '../../assets/icons/error.svg'
import { Box, Typography } from '@mui/material';

function ErrorMessage({message,src=null,color=null,width=16,height=16,img=false,...props}) {
    
    return(
        <Box className="error-message-root" {...props}>
            {img && <ImageWrapper width={width} height={height} className="error-message-image" src={src ? src :ErrorIcon}/>}
            <Typography sx={{color: color ? color : '#E34850'}} className="error-message-text">{message}</Typography>           
        </Box>
    )
}
export default ErrorMessage