export const rotateKeyApiMessages = {

    "generateBleKey-post-200": "Request to generate new BLE key sent successfully.",
    "generateBleKey-post-401": "Unable to generate new BLE key, please verify your access and try again.",
    "generateBleKey-post-400": "Unable to generate new BLE key, please try again later.",
    "generateBleKey-post-403": "Unable to generate new BLE key, please verfy your access and try again.",
    "generateBleKey-post-404": "Unable to generate new BLE key, please try again later.",
    "generateBleKey-post-500": "Unable to generate new BLE key, please try again later.",

    "rotateKey-post-200": "BLE key sync has been initiated, it may take a while to get completed.",
    "rotateKey-post-401": "Unable to initiate BLE key sync, please verify your access and try again.",
    "rotateKey-post-400": "Unable to initiate BLE key sync, please try again later.",
    "rotateKey-post-403": "Unable to initiate BLE key sync, please verfy your access and try again.",
    "rotateKey-post-404": "Unable to initiate BLE key sync, please try again later.",
    "rotateKey-post-500": "Unable to initiate BLE key sync, please try again later.",

    "bleKeys-get-200": "BLE keys fetched successfully.",
    "bleKeys-get-401": "Unable to fetch BLE keys, please verify your access and try again.",
    "bleKeys-get-400": "Unable to fetch BLE keys, please try again later.",
    "bleKeys-get-403": "Unable to fetch BLE keys, please verfy your access and try again.",
    "bleKeys-get-404": "Unable to fetch BLE keys, please try again later.",
    "bleKeys-get-500": "Unable to fetch BLE keys, please try again later.",

}

export const rotateKeyInlineMessages = (item) => {
    return {
        "keyRotation-oldKey-emptyList": "No devices are available for BLE key sync.",
        "keyGenerate-send-confirm": <p>A new BLE key will be generated for all devices. This key will be immediately distributed to all devices.<br /><br /><b>Are you sure you want to generate a new BLE key?</b></p>,
        "keyGenerate-info-message": `Generate a new BLE key for the devices. This BLE key will be automatically distributed to all online receivers and sensors once it is generated.`,
        "keyLog-list-empty": "No BLE keys found.",
        "rotateKey-send-confirm": `Are you sure you want to update ${item?.name} with the latest BLE key (${item?.version}).`,
        "rotateKey-version-latest": "BLE key version is up to date.",
        "rotateKey-version-needsUpdate": "BLE key version needs to be updated.",
        "rotateKey-bleKey-notFound": "BLE key not found."
    }
} 