import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    StyledPopupWrapper:
    {
        '& .MuiDialog-paper': {
            minWidth: "390px",
            borderRadius: "10px"
        },
        '& .MuiDialogTitle-root': {
            background:'rgba(15, 141, 72, 0.05)',    
            padding:'12px 20px',
            fontSize:'1rem',
            lineHeight:'22px',
            fontWeight:'600',
            display:'flex',
            justifyContent:'space-between',
            minHeight: "40px",
            '& button': {
                padding: 0,
            }
        
        },
    },
    body: {
        padding: '0 30px 35px 30px'
    },
    closeicon:{
        padding: '3px',
        margin:'3px'
    }
});
