import { Box, Grid } from "@mui/material";
import React from "react";
import CustomTypography from "../../../components/CustomTypography";
import { useStyles } from './DeviceStatus.Style.js';
import { getCountOfCategory, isAuthorised } from "../../../helpers";
import { useSelector } from "react-redux";
import { features } from "../../../constants";

const DeviceStatus = ({ heading }) => {
    const _getDeviceList = useSelector(state => state?.devices?.deviceList);
    const _groupList = useSelector(state => state?.groups?.list);
    const classes = useStyles();

    const devices = [
        {
            id: 'lights',
            label: 'Lights: ',
            value: getCountOfCategory(_getDeviceList, 'lightdriver') || '0'
        }, {
            id: 'senors',
            label: 'Sensors: ',
            value: getCountOfCategory(_getDeviceList, 'sensor') || '0'
        }, {
            id: 'groups',
            label: 'Groups: ',
            value: _groupList.length || '0'
        },
    ];

    const status = [
        {
            id: 'OK',
            label: 'On',            
            color:'#0f8d48'
        }, 
        {
            id: 'inactive',
            label: 'Off',            
            color:'#5a5a5a'
        },
        {
            id: 'error',
            label: 'Inactive',            
            color:'#d90707'
        }, 
        {
            id: 'maintenance',
            label: 'Maintenance',            
            color:'#fec703'
        },
    ]


    return (
        <Box className={classes.mainDiv}>
            <Grid container item xs={12}>
                {isAuthorised(features?.device) && <Grid container className={classes.box} item lg={5} md={5} sm={4} xs={12} spacing={1} py={2}>
                    {devices.map((item, key) => (
                        <Grid item key={item?.id} xs={4} >
                            <Box className={classes?.deviceCol || ""}>
                                <CustomTypography content={item?.label} sx={{ mr: '5px' }} weight={600} size={16} lineHeight={14.63} color="#5E5E5E" />
                                <CustomTypography content={item?.value} weight={600} size={16} lineHeight={14.63} color="#0F8D48" />
                            </Box>
                        </Grid>
                    ))}
                </Grid>}
                {isAuthorised(features?.space) && isAuthorised(features?.device) && <Grid className={`${classes.box} ${classes.status}`} item lg={7} md={7} sm={8} xs={12} >
                    <Box className={classes?.deviceCol || ""}>
                        <CustomTypography content={'Device Status :'} weight={600} size={16} lineHeight={14.63} color="#5E5E5E" />
                    </Box>
                    {status.map(item => (
                        <Box key={'status' + item?.id} className={classes?.deviceCol || ""}>
                            <div className={classes.imageDevice} style={{background:item?.color,}} ></div>
                            <CustomTypography content={item?.label} weight={500} size={12} lineHeight={14.63} color="#000" />
                        </Box>
                    ))}
                </Grid>}
            </Grid>
        </Box>
    )
}

export default DeviceStatus