/***
 *
 * Controller class for user.
 * @file ResetCred.js
 * @description ResetCred component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './ResetCred.style.js';
import './ResetCred.scss';
import { Box, Grid, InputAdornment } from '@mui/material';
import _ from 'lodash';
import CustomTypography from '../CustomTypography/index.js';
import StyledTextfield from '../StyledTextfield/index.js';
import { ReactComponent as VisibilityOff } from '../../assets/icons/visibility-off.svg';
import { ReactComponent as VisibilityOn } from '../../assets/icons/visibility-on.svg';
import ErrorMessage from '../ErrorMessage'
import { useState } from 'react';
import PopoverContainer from '../PopoverContainer/index.js';
import StyledButton from '../StyledButton/index.js';
import CustomLabel from '../CustomLabel/index.js';

const ResetCred = ({ pArray = [], isdisabled = false, spacing = 0, handlePValueChange, onSubmit = () => { }, onConfirmOtp = () => { }, resetPValue, buttonLabel = 'Reset Password', extraButtons = [], showResetPasswordButton = true }) => {
  const classes = useStyles();
  const [pValueVisibility, setPValueVisibility] = useState([]);
  const [newPass, setNewPass] = useState(null);
  const [currId, setCurrId] = useState('');

  const handlePValueVisibility = (e, item) => {
    let arr = pValueVisibility;
    if (arr.indexOf(item?.name) >= 0) {
      arr.splice(arr.indexOf(item?.name), 1);
    }
    else {
      arr.push(item?.name)
    }
    setPValueVisibility([...arr]);
  }
  return (
    <Box className={classes?.ResetCred || ""} data-testid="ResetCred">
      <Grid container spacing={spacing}>
        {Array.isArray(pArray) &&
          !_.isEmpty(pArray) && pArray.map((item, key) => (
            <Grid item xs={item?.columnSize || 12}>
              <div key={key}>
                <CustomLabel
                  required={item?.hasOwnProperty("required") ? item?.required : true}
                  style={{
                    lineHeight: "19.5px",
                    fontWeight: 700,
                    fontSize: 14,
                    paddingBottom: "10px",
                    color: item?.type === 'password' && isdisabled ? "#969ba4" : "#2D3748",
                    ...item?.sx
                  }}
                >
                  {item?.label}
                </CustomLabel>
                {/* <CustomTypography sx={{ pb: '10px' }} content={item?.label} color={item?.type === 'password' && isdisabled ? "#969ba4" : "#2D3748"} size={14} weight={700} lineHeight={19.5} /> */}
                <StyledTextfield
                  id={item?.id}
                  hiddenLabel
                  error={item?.error}
                  disabled={item?.type === 'password' && isdisabled}
                  value={resetPValue[item?.name]}
                  onChange={(e) => { handlePValueChange(e); }}
                  onClick={(e) => { setNewPass(e.currentTarget); setCurrId(e.target.id) }}
                  type={pValueVisibility.includes(item?.name) || (item?.type === 'text') ? "text" : 'password'}
                  name={item?.name}
                  fullWidth
                  autoComplete="new-password"
                  sx={{ minWidth: '200px', width: '100%', maxWidth: '350px', mb: !item?.error ? '20px' : '0' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {
                          item?.type === 'password' &&
                          <StyledButton
                            iconButton
                            sx={{
                              background: '#FFFFFF',
                              borderRadius: '4px', marginRight: '-9px', padding: '5px'
                            }}
                            edge="end"
                            aria-label="Toggle password visibility"
                            onClick={(e) => { handlePValueVisibility(e, item, key) }}
                          >
                            {pValueVisibility.includes(item?.name) ? <VisibilityOn /> : <VisibilityOff />}
                          </StyledButton>
                        }
                      </InputAdornment>
                    )
                  }}
                />
                {item?.error && <ErrorMessage sx={{ mb: '20px' }} message={item?.errorMsg} />}
                {item?.id === 'otp' && resetPValue?.otp && <StyledButton sx={{ mt: '9px', ml: '10px' }} onClick={onConfirmOtp} >Confirm OTP</StyledButton>}
              </div>
              {
                ['pass_new', 'newPValue'].includes(item?.id) && currId === item?.id &&
                (!item?.minMaxLen || !item?.reqDigit || !item?.reqSpecial || !item?.reqUpper) &&
                <PopoverContainer anchorEl={newPass} placement='right' onClose={() => { setNewPass(null); setCurrId('') }}>
                  <CustomTypography content={'Password should be 8 to 20 characters long'} size={12} color={item?.minMaxLen ? '#0f8d48' : '#ff4336'} />
                  <CustomTypography content={'Password should have at least one digit [0-9]'} size={12} color={item?.reqDigit ? '#0f8d48' : '#ff4336'} />
                  <CustomTypography content={'Password should contains at least one uppercase character [A-Z]'} size={12} color={item?.reqUpper ? '#0f8d48' : '#ff4336'} />
                  <CustomTypography content={'Password should have one special character [@#-_]'} size={12} color={item?.reqSpecial ? '#0f8d48' : '#ff4336'} />
                </PopoverContainer>
              }

            </Grid>

          ))
        }
        <Grid item container xs={12} spacing={2} >
          {
            showResetPasswordButton &&
            <Grid item xs={6}>
              <StyledButton color={'primary'} className="btn" onClick={onSubmit}>{buttonLabel}</StyledButton>
            </Grid>
          }

          {extraButtons?.map(b => {
            return <Grid item xs={6}>
              <StyledButton variant={b?.variant || "contained"} color={'primary'} className="btn" onClick={b?.onClick}>{b?.label}</StyledButton>
            </Grid>
          })}
        </Grid>

      </Grid>
    </Box>
  )
};

ResetCred.propTypes = {};

ResetCred.defaultProps = {};

export default ResetCred;
