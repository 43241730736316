export const deviceApiMessages = (item) => {
    return {
        "gateway-get-200": "Gateway(s) successfully retrieved.",
        "gateway-get-500": "Gateway(s) could not be retrieved at the moment, please try again later.",
        "allDevices-get-200": "Device(s) successfully retrieved.",
        "allDevices-get-500": "Device(s) could not be retrieved at the moment due to internal issue, please try again later.",
        "device-post-200": "Device successfully added.",
        "device-post-201": "Device successfully added.",
        "device-post-400": "Device failed to add, please reverify the input(s) and try again.",
        "device-post-403": "Device failed to add, please verify your access and try again.",
        "device-post-404": "Device failed to add, please try again later.",
        "device-post-409": "Device failed to add, please reverify the input combination and try again.",
        "device-delete-200": "Device successfully deleted.",
        "device-delete-404": "Device requested for deletion is not found, please refresh the page and try again.",
        "device-delete-500": "Device requested for deletion is failed due to internal issue, please try again later.",
        "device-get-200": "Device details successfully retrieved.",
        "device-get-404": "Requested device is not found, please refresh the page and try again.",
        "device-get-500": "Unable to retrieve device details due to internal issue, please try again later.",
        "syncDevice-get-200": "Device sucessfully synced.",
        "syncDevice-get-500": "Device failed to sync with controller device due to internal issue, please try again later.",
        "children-get-200": "Device(s) successfully fetched",
        "children-get-401": "Unable to retrieve the child device(s), please verify your access and try again.",
        "children-get-400": "Unable to retrieve the child device(s), please try again later.",
        "children-get-403": "Unable to retrieve the child device(s), please verfy your access and try again.",
        "children-get-404": "Unable to retrieve the parent device, please try again later.",
        "moveDevice-put-200": "Device successfully moved to a new space.",
        "moveDevice-put-404": "Unable to move device to a new space, please try again later.",
        "moveDevice-put-400": `${typeof item === 'string' && item?.toLowerCase() === "unable to move device to the target space" ? 'Cannot move device to parent space.' : 'Unable to move device to a new space, please try again later.'}`,
        "updateDevice-put-200": "Device successfully updated.",
        "updateDevice-put-400": "Unable to update Device the device property, please try again later.",
        "updateDevice-put-404": "Unable to retrieve the requested device, please try again later.",
        "updateConfig-put-200": "Device configuration successfully updated.",
        "updateConfig-put-400": "Device Configuration failed to update, please reverify the input request and try again.",
        "updateConfig-put-404": "Unable to retrieve the requested device, please try again later.",
        "devicesBySpace-get-200": "Device(s) successfully retrieved.",
        "devicesBySpace-get-404": "Unable to retrieve the device(s) for a selected space, please try again later.",
        "devicesBySpace-get-500": "Unable to retrieve the device(s) for a selected space due to internal issue, please try again later.",
        "model-get-200": "Device model(s) successfully retrieved.",
        "model-get-401": "Unable to retrieve the supported device model(s), please verify your access and try again.",
        "model-get-403": "Unable to retrieve the supported device model(s),  please verify your access and try again.",
        "model-get-404": "Unable to retrieve the supported device model(s), please try again later.",
        "categories-get-200": "Device categories successfully retrieved.",
        "categories-get-401": "Unable to retrieve the supported device categories, please verify your access and try again.",
        "categories-get-403": "Unable to retrieve the supported device categories, please verify your access and try again.",
        "categories-get-404": "Unable to retrieve the supported device categories, please try again later.",
        "activityLog-get-200": "Device activity logs successfully retrieved.",
        "activityLog-get-404": "Unable to retrive device activity logs, please try again later.",
        "activityLog-get-400": "Unable to retrieve device activity logs, please try again later.",
        "factoryReset-post-200": "Factory Reset request is initiated, it may take a while to get completed.",
        "factoryReset-post-500": "Unable to initiate Factory Reset request due to internal issue, please try again later.",
        "syncScene-put-200": "Scene(s) Sync request initiated successfully, it may take while to get completed.",
        "syncScene-put-401": "Scene Sync request is failed to initiate, please reverify your access and try again.",
        "syncScene-put-403": "Scene Sync request is failed to initiate, please reverify your access and try again.",
        "syncScene-put-404": "Scene Sync request is failed to initiate, please try again later.",
        "syncScene-put-500": "Scene Sync request is failed to initiate due to internal issue, please try again later.",
        "syncGroup-put-200": "Group(s) Sync request initiated successfully, it may take while to get completed.",
        "syncGroup-put-401": "Group Sync request is failed to initiate, please reverify your access and try again.",
        "syncGroup-put-403": "Group Sync request is failed to initiate, please reverify your access and try again.",
        "syncGroup-put-404": "Group Sync request is failed to initiate, please try again later.",
        "syncGroup-put-500": "Group Sync request is failed to initiate due to internal issue, please try again later.",
        "delete-send-confirm": `Are you sure you want to delete ${item}?`,
        "gatewayLog-get-201": "Gateway log upload initiated, you will be notified once done.",
        "gatewayLog-get-200": "Gateway log fetched successfully",
        "gatewayLog-get-405": "Unable to retrieve Gateway log",
        "gatewayLog-get-400": "Unable to retrieve Gateway log, please verify inputs and try again.",
        "gatewayLog-get-401": "Unable to retrieve Gateway log, please verify your access and try again.",
        "gatewayLog-get-403": "Unable to retrieve Gateway log, please verify your access and try again.",
        "gatewayLog-get-500": "Unable to retrieve Gateway log due to internal error, please try again later.",
        "gatewayLog-get-502": "Unable to retrieve Gateway log due to server unavailable, please try again later.",
        "gatewayLogDates-get-200": "Gateway log dates fetched successfully",
        "gatewayLogDates-get-405": "Unable to retrieve Gateway log dates",
        "gatewayLogDates-get-400": "Unable to retrieve Gateway log dates, please verify inputs and try again.",
        "gatewayLogDates-get-401": "Unable to retrieve Gateway log dates, please verify your access and try again.",
        "gatewayLogDates-get-403": "Unable to retrieve Gateway log dates, please verify your access and try again.",
        "gatewayLogDates-get-404": "Unable to retrieve Gateway log dates, please try again.",
        "gatewayLogDates-get-500": "Unable to retrieve Gateway log dates due to internal error, please try again later.",
        "gatewayLogDates-get-502": "Unable to retrieve Gateway log dates due to server unavailable, please try again later.",
        "findSensors-post-200": "Find sensor(s) request is triggered.",
        "findSensors-post-400": "Unable to find sensor(s), please verify the inputs and try again.",
        "findSensors-post-401": "Unable to find sensor(s), please check your access and try again.",
        "findSensors-post-403": "Unable to find sensor(s), please check your access and try again.",
        "findSensors-post-404": "Unable to find the requested device, please refresh the page and try again.",
        "findSensors-post-405": "Unable to find sensor(s), please verify the input request and try again.",
        "findSensors-post-500": "Unable to find sensor(s) due to internal error, please try again later.",
        "findSensors-post-502": "Unable to find sensor(s) due to internal error, please try again later.",

        "stopFindSensors-post-200": "Find sensors() operation is stopped.",
        "stopFindSensors-post-400": "Unable to stop find sensor(s) operation, please verify the input request and try again.",
        "stopFindSensors-post-401": "Unable to stop find sensor(s) operation, please check your access and try again.",
        "stopFindSensors-post-403": "Unable to stop find sensor(s) operation, please check your access and try again.",
        "stopFindSensors-post-404": "Unable to find the requested device, please refresh the page and try again.",
        "stopFindSensors-post-405": "Unable to stop find sensors operation, please verify the input request and try again.",
        "stopFindSensors-post-500": "Unable to stop find sensors operation due to internal error, please try again later.",
        "stopFindSensors-post-502": "Unable to stop find sensors operation due to internal error, please try again later.",

        "fetchSensors-get-200": "Sensor(s) successfully retrieved.",
        "fetchSensors-get-400": "Unable to retrieve sensor(s), please verify the input request and try again.",
        "fetchSensors-get-401": "Unable to retrieve sensor(s), please check your access and try again.",
        "fetchSensors-get-403": "Unable to retrieve sensor(s), please check your access and try again.",
        "fetchSensors-get-404": "Unable to retrieve the request Id, please refresh the page and try again.",
        "fetchSensors-get-405": "Unable to retrieve sensor(s), please reverify the input request and try again.",
        "fetchSensors-get-500": "Unable to retrieve sensor(s) due to internal error, please try again later.",
        "fetchSensors-get-502": "Unable to retrieve sensor(s) due to internal error, please try again later.",

        "bindSensors-post-200": "Sensor(s) successfully bind to the aggregator.",
        "bindSensors-post-400": "Unable to bind sensor(s), please verify the input request and try again.",
        "bindSensors-post-401": "Unable to bind sensor(s), please check your access and try again.",
        "bindSensors-post-403": "Unable to bind sensor(s), please check your access and try again.",
        "bindSensors-post-404": "Unable to find the requested device, please refresh the page and try again.",
        "bindSensors-post-405": "Unable to bind sensor(s), please verify the input request and try again.",
        "bindSensors-post-500": "Unable to bind sensor(s) due to internal error, please try again later.",
        "bindSensors-post-502": "Unable to bind sensor(s) due to internal error, please try again later.",


        "assignGateway-post-200": "Gateway assigned successfully.",
        "assignGateway-post-400": "Unable to assign gateway, please verify the input request and try again.",
        "assignGateway-post-401": "Unable to assign gateway, please check your access and try again.",
        "assignGateway-post-403": "Unable to assign gateway, please check your access and try again.",
        "assignGateway-post-404": "Unable to find the requested gateway, please check the gateway details and try again.",
        "assignGateway-post-405": "Unable to assign gateway, please verify the input request and try again.",
        "assignGateway-post-500": "Unable to assign gateway due to internal error, please try again later.",
        "assignGateway-post-502": "Unable assign gateway due to internal error, please try again later.",

        "assignedGateways-get-200": "Gateway(s) successfully retrieved.",
        "assignedGateways-get-400": "Unable to retrieve gateway(s), please verify the input request and try again.",
        "assignedGateways-get-401": "Unable to retrieve gateway(s), please check your access and try again.",
        "assignedGateways-get-403": "Unable to retrieve gateway(s), please check your access and try again.",
        "assignedGateways-get-404": "Unable to retrieve gateway(s) for the tenant, please refresh the page and try again.",
        "assignedGateways-get-405": "Unable to retrieve gateway(s), please reverify the input request and try again.",
        "assignedGateways-get-500": "Unable to retrieve gateway(s) due to internal error, please try again later.",
        "assignedGateways-get-502": "Unable to retrieve gateway(s) due to internal error, please try again later.",

        "gatewayDetails-get-200": "Gateway details successfully fetched",
        "gatewayDetails-get-401": "Unable to retrieve gateway details, please verify your access and try again.",
        "gatewayDetails-get-400": "Unable to retrieve gateway details, please try again later.",
        "gatewayDetails-get-403": "Unable to retrieve gateway details, please verfy your access and try again.",
        "gatewayDetails-get-404": "Unable to find gateway, please try again later.",
        "gatewayDetails-get-500": "Unable to retrieve gateway details, please try again later.",

        "gatewayStatistics-get-200": "Gateway Statistics successfully fetched",
        "gatewayStatistics-get-401": "Unable to retrieve gateway statistics, please verify your access and try again.",
        "gatewayStatistics-get-400": "Unable to retrieve gateway statistics, please try again later.",
        "gatewayStatistics-get-403": "Unable to retrieve gateway statistics, please verify your access and try again.",
        "gatewayStatistics-get-404": "Unable to retrieve gateway statistics, please try again later.",
        "gatewayStatistics-get-500": "Unable to retrieve gateway details, please try again later.",

        "cameraStreamUrl-get-200": "Camera stream URL fetched successfully.",
        "cameraStreamUrl-get-500": "Unable to fetch camera stream URL due to internal error, please try again later.",
        "cameraStreamUrl-get-503": "Unable to fetch camera stream URL due to internal error, please try again later.",

        "stopCameraStream-get-200": "Camera stream stopped successfully.",
        "stopCameraStream-get-500": "Unable to stop camera stream due to internal error, please try again later.",
        "stopCameraStream-get-503": "Unable to stop camera stream due to internal error, please try again later.",

    }
};

export const deviceMqttMessages = (item) => {
    return {
        "daliDiscover-receive-command_accepted": "Device Discovery operation request is accepted.",
        "daliDiscover-receive-unknown_device": "Device Discovery operation failed, please recheck the device and try again.",
        "daliDiscover-receive-dbus_failure": "Device Discovery operation failed, please try again later.",
        "daliDiscover-receive-internal_error": "Device Discovery operation failed, please try again later.",
        "daliDiscover-receive-unsupported_device_type": "Device Discovery operation failed, please try again later.",
        "daliDiscover-receive-success": "Device Discovery operation successfully completed.",
        "daliDiscover-receive-light_config_success": "Light Configuration successfully completed.",
        "daliCommission-receive-command_accepted": "Device Commissioning operation request is accepted.",
        "daliCommission-receive-unknown_device": "Device Commissioning operation failed, please recheck the device and try again.",
        "daliCommission-receive-incorrect_readdress_value": "Device Commissioning operation failed, please try again later.",
        "daliCommission-receive-unknown_commission_type": "Device Commissioning operation failed, please try again later.",
        "daliCommission-receive-unsupported_device_type": "Device Commissioning operation failed, please try again later.",
        "daliCommission-receive-internal_error": "Device Commissioning operation failed due to internal issue, please try again later.",
        "daliCommission-receive-dbus_failure": "Device Commissioning operation failed, please try again later.",
        "daliCommission-receive-commissioning_started": "Device Commissioning operation is started.",
        "daliCommission-receive-commissioning_ended": "Device Commissioning operation successfully ended.",
        "sensorDiscover-receive-command_accepted": "Sensor Discovery operation request is accepted.",
        "sensorDiscover-receive-unknown_device": "Sensor Discovery operation failed, please recheck the device and try again.",
        "sensorDiscover-receive-dbus_failure": "Sensor Discovery operation failed, please try again later.",
        "sensorDiscover-receive-internal_error": "Sensor Discovery operation failed due to internal error, please try again later.",
        "sensorDiscover-receive-unsupported_device_type": "Sensor Discovery operation failed, please try again later.",
        "sensorDiscover-receive-invalid_receiver_plot_payload": "Sensor Discovery operation failed, please try again later.",
        "sensorDiscover-receive-success": "Sensor Discovery operation successfully ended.",
        "sensorCommission-receive-command_accepted": "Sensor Commissioning operation request is accepted.",
        "sensorCommission-receive-unknown_device": "Sensor Commissioning operation failed, please recheck the device and try again.",
        "sensorCommission-receive-incorrect_readdress_value": "Sensor Commissioning operation failed, please try again later.",
        "sensorCommission-receive-unknown_commission_type": "Sensor Commissioning operation failed, please try again later.",
        "sensorCommission-receive-unsupported_device_type": "Sensor Commissioning operation failed, please try again later.",
        "sensorCommission-receive-internal_error": "Sensor Commissioning operation failed due to internal error, please try again later.",
        "sensorCommission-receive-dbus_failure": "Sensor Commissioning operation failed, please try again later.",
        "sensorCommission-receive-commissioning_started": "Sensor Commissioning operation is started",
        "sensorCommission-receive-commissioning_ended": "Sensor Commissioning operation successfully ended.",
        "daliBroadcast-receive-command_accepted": "Light Broadcast operation is accepted.",
        "daliBroadcast-receive-unknown_device": "Light Broadcast operation failed, please try again later.",
        "daliBroadcast-receive-missing_intensity_value": "Light Broadcast operation failed, please try again later.",
        "daliBroadcast-receive-unsupported_device_type": "Light Broadcast operation failed, please try again later.",
        "daliBroadcast-receive-internal_error": "Light Broadcast operation failed due to internal error, please try again later.",
        "daliBroadcast-receive-dbus_failure": "Light Broadcast operation failed, please try again later.",
        "daliBroadcast-receive-success": "Light Broadcast operation successfully completed.",
        "daliLoopReset-receive-command_accepted": "Channel Reset operation request is accepted.",
        "daliLoopReset-receive-unknown_device": "Channel Reset operation is failed, please try again later.",
        "daliLoopReset-receive-missing_channel_id": "Channel Reset operation is failed, please try again later.",
        "daliLoopReset-receive-unsupported_device_type": "Channel Reset operation is failed, please try again later.",
        "daliLoopReset-receive-internal_error": "Channel Reset operation is failed due to internal error, please try again later.",
        "daliLoopReset-receive-dbus_failure": "Channel Reset operation is failed, please try again later.",
        "daliLoopReset-receive-success": "Channel Reset operation successfully completed.",
        "systemReset-receive-command_accepted": "System Reset operation request is accepted.",
        "systemReset-receive-unknown_device": "System Reset operation is failed, please recheck the device status and try again.",
        "systemReset-receive-unsupported_device_type": "System Reset operation is failed, please try again later.",
        "systemReset-receive-internal_error": "System Reset operation failed due to internal error, please try again later.",
        "systemReset-receive-dbus_failure": "System Reset operation is failed, please try again later.",
        "systemReset-receive-success": "System Reset operation successfully completed.",
        "systemReset-receive-software": "System Reset operation successfully completed.",
        "systemReset-receive-external_pin": "System Reset operation successfully completed.",
        "systemReset-receive-low_power": "System Reset operation successfully completed.",
        "systemReset-receive-window_watchdog": "System Reset operation successfully completed.",
        "systemReset-receive-independent_watchdog": "System Reset operation successfully completed.",
        "systemReset-receive-poweron_powerdn": "System Reset operation successfully completed.",
        "systemReset-receive-unknown": "System Reset operation is failed, please try again later.",
        "factoryReset-receive-command_accepted": "Factory Reset operation request is accepted.",
        "factoryReset-receive-unknown_device": "Factory Reset operation is failed, please recheck the device status and try again.",
        "factoryReset-receive-unsupported_device_type": "Factory Reset operation is failed, please try again later.",
        "factoryReset-receive-internal_error": "Factory Reset operation is failed due to internal error, please try again later.",
        "factoryReset-receive-dbus_failure": "Factory Reset operation is failed, please try again later.",
        "deviceIdentify-receive-command_accepted": `${item} : Device Identify operation request is accepted.`,
        "deviceIdentify-receive-unknown_device": `${item} : Device Identify operation is failed, please recheck the device status and try again.`,
        "deviceIdentify-receive-unsupported_device_type": `${item} : Device Identify operation is failed, please try again later.`,
        "deviceIdentify-receive-internal_error": `${item} : Device Identify operation is failed due to interal error, please try again later.`,
        "deviceIdentify-receive-dbus_failure": `${item} : Device Identify operation is failed, please try again later.`,
        "deviceIdentify-receive-timeout": `${item} : Device Identify operation is failed, please try again later.`,
        "startReceiverPairing-receive-command_accepted": `${item} : Sensor pairing request is accepted.`,
        "startReceiverPairing-receive-unknown_device": `${item} : Sensor pairing operation failed, please try again later.`,
        "startReceiverPairing-receive-unsupported_device_type": `${item} : Sensor pairing operation is failed, please try again later.`,
        "startReceiverPairing-receive-internal_error": `${item} : Sensor pairing operation is failed due to internal error, please try again later.`,
        "startReceiverPairing-receive-dbus_failure": `${item} : Sensor pairing operation failed, please try again later.`,
        "startReceiverPairing-receive-pairing_started": `${item} : Sensor pairing started.`,
        "startReceiverPairing-receive-pairing_ended": `${item} : Sensor pairing successfully completed.`,
        "startReceiverPairing-receive-timeout": `${item} : Sensor pairing operation failed, please try again later.`,
        "stopReceiverPairing-receive-command_accepted": `${item} : Stop sensor pairing request is accepted.`,
        "stopReceiverPairing-receive-pairing_ended": `${item} : Stop sensor pairing successfully completed.`,
        "stopReceiverPairing-receive-pairing_started": `${item} :Stop sensor pairing operation failed, please try again later.`,
        "stopReceiverPairing-receive-unknown_device": `${item} :Stop sensor pairing operation failed, please try again later.`,
        "stopReceiverPairing-receive-unsupported_device_type": `${item} :Stop sensor pairing operation is failed, please try again later.`,
        "stopReceiverPairing-receive-internal_error": `${item} : Stop sensor pairing operation is failed due to internal error, please try again later.`,
        "stopReceiverPairing-receive-dbus_failure": `${item} : Stop sensor pairing operation failed, please try again later.`,
        "stopReceiverPairing-receive-timeout": `${item} : Stop sensor pairing operation failed, please try again later.`,
        "groupControl-receive-value": `${item} : Group Control operation request is accepted.`,
        "groupControl-receive-error": `${item} : Group Control operation has failed, group details will be reloaded.`,
        "groupControl-receive-timeout": `${item} : Group Control operation timed out, please try again later.`,
        "groupControl-receive-deviceError": "Group Control operation is failed, please check the device status and try again.",
        "sceneApply-receive-applied": `${item} : Scene apply request is accepted.`,
        "sceneApply-receive-error": `${item} : Scene apply operation failed, please try again later.`,
        "sceneApply-receive-timeout": `${item} : Scene apply operation timed out, please try again later.`,
        "sceneApply-receive-deviceError": "Scene Apply operation is failed, please check the device status and try again.",
        "daliDiscover-send-confirm": "Are you sure you would like to request for Device Discovery operation?",
        "daliDiscover-send-send_mqtt_command": "Device Discovery operation request is initiated.",
        "lightConfigSync-receive-light_config_success": "Light configuration successfully completed.",
        "daliDiscover-receive-response_not_received": "Device Discovery operation is timed out, please check the last discovery timestamp in device detail section and try again.",
        "daliDiscover-receive-device_busy": "Device Discovery operation is failed due to controller is busy in some commissioning operation.",
        "daliCommission-send-confirm": "Are you sure you would like to request for Device Commissioning operation?",
        "daliCommission-send-send_mqtt_command": "Device Commissioning request is initiated.",
        "daliCommission-receive-response_not_received": "Device Commissioning request is timed out, please check the last commissioning timestamp in device detail section and try again.",
        "daliCommission-receive-device_busy": "Dali Commissioning operation is failed due to controller is busy in some commissioning operation.",
        "sensorDiscover-send-confirm": "Are you sure you would like to request for Sensor Discover operation?",
        "sensorDiscover-send-send_mqtt_command": "Sensor Discovery operation request is initiated.",
        "sensorDiscover-receive-response_not_received": "Sensor Discovery operation is timed out, please check the last discovery timestamp in device detail section and try again.",
        "sensorDiscover-receive-device_busy": "Sensor Discovery operation is failed due to controller is busy in some commissioning operation.",
        "sensorCommission-send-confirm": "Are you sure you would like to request for Sensor Commissioning operation?",
        "sensorCommission-send-send_mqtt_command": "Sensor Commissioning request is initiated.",
        "sensorCommission-receive-response_not_received": "Sensor Commissioning operation is timed out, please check the last commissioning timestamp in device detail section and try again.",
        "sensorCommission-receive-device_busy": "Sensor Commissioning operation is failed due to controller is busy in some commissioning operation.",
        "daliBroadcast-send-confirm": `Are you sure you would like to switch ${item} all the lights?`,
        "daliBroadcast-receive-device_busy": "Light Broadcast operation failed due to controller is busy in some commissioning operation.",
        "daliBroadcast-send-send_mqtt_command": "Light Broadcast request is initiated.",
        "daliBroadcast-receive-response_not_received": "Light Broadcast request timed out, please try again later",
        "daliLoopReset-send-confirm": `Are you sure you would like to reset the configuration of all light(s) in channel ${item}?`,
        "daliLoopReset-send-send_mqtt_command": "Channel Reset request is initiated.",
        "daliLoopReset-receive-response_not_received": "Channel Reset operation request is timed out, please check the device status and try again.",
        "daliLoopReset-receive-device_busy": "Channel Reset operation is failed due to controller is busy in some commissioning operation.",
        "systemReset-send-confirm": "Are you sure you would like to perform System Reset operation?",
        "systemReset-send-send_mqtt_command": "System Reset request is initiated.",
        "systemReset-receive-response_not_received": "System Reset operation is timed out, please check the device status and try again.",
        "systemReset-receive-device_busy": "System Reset operation is failed due to controller is busy in some commissioning operation.",
        "warnFactoryReset-send-confirm": <p>The {item} is inactive or offline, hence the operation will not be synced with the cloud.<br /><br /><b>Are you sure you would like to perform Factory Reset operation?</b></p>,
        "factoryReset-send-confirm": "Are you sure you would like to perform Factory Reset operation?",
        "factoryReset-send-send_mqtt_command": "Factory Reset request is initiated.",
        "factoryReset-receive-response_not_received": "Factory Reset operation is timed out, please check the device status and try again.",
        "factoryReset-receive-device_busy": "Factory Reset operation is failed due to controller is busy in some commissioning operation.",
        "warnUpgradeFirmware-send-confirm": `${item?.name} has the latest firmware available.`,
        "upgradeFirmware-send-confirm": `Are you sure you would like to upgrade ${item?.name} to firmware version ${item?.fwVersion}?`,
        "deviceIdentify-send-confirm": "Are you sure you would like to identify the device on the physical space?",
        "deviceIdentify-receive-device_busy": `${item} : Device Identify operation is failed due to controller is busy in some commissioning operation.`,
        "startReceiverPairing-send-confirm": "Are you sure you would like to start sensor pairing operation?",
        "startReceiverPairing-receive-response_not_received": `${item} : Sensor pairing operation is timed out, please check the device status and try again.`,
        "startReceiverPairing-receive-device_busy": `${item} : Sensor pairing operation is failed due to controller is busy in some commissioning operation.`,
        "stopReceiverPairing-send-confirm": "Are you sure you would like to stop sensor pairing operation?",
        "lstControl-receive-value": `${item?.header} light Intensity : ${item?.value} %`,
        "lstControl-receive-onOff": `${item?.header} light turned ${item?.value === 0 ? "off" : "on"}`,
        "lstControl-receive-server_error": `${item} : Unable to change the Light Intensity due to internal error, please try again later.`,
        "lstControl-receive-invalid_payload": `${item}: Unable to change the Light Intensity, please try again later.`,
        "tempControl-receive-value": `${item?.header} Color Temperature : ${item?.value} K`,
        "tempControl-receive-server_error": `${item} : Unable to change Light Color Temperature, please try again later.`,
        "tempControl-receive-invalid_payload": `${item}: Unable to change Light Color Temperature, please try again later.`,
        "groupLstControl-receive-value": "Group Current Light Intensity: value",
        "groupTempControl-receive-value": "Group Current Color Temperature: value",
        "controller-receive-busy": "Controller is busy",
        "controller-receive-available": "Controller is available",
    }
};

export const deviceInlineMessages = (item) => {
    return {
        "assignedGateway-list-empty": "There are no assigned gateways, please assign a gateway.",
        "sensor-config-pirSleepRequired": 'PIR Sleep is required',
        "sensor-config-pirSleepRange": `PIR Sleep should be between ${item?.min} to ${item?.max}`,
        "sensor-config-pirSleepInteger": "PIR Sleep should be an integer",
        "sensor-config-pirUnoccupancyRequired": "PIR Unoccupancy Timeout is required",
        "sensor-config-pirUnoccupancyRange": `PIR Unoccupancy Timeout should be between ${item?.min} to ${item?.max}`,
        "sensor-config-pirUnoccupancyInteger": "PIR Unoccupancy Timeout should be an integer",
        "sensor-config-pirTransmissionRequired": "PIR Periodic Transmission is required",
        "sensor-config-pirTransmissionRange": `PIR Periodic Transmission should be between ${item?.min} to ${item?.max}`,
        "sensor-config-pirTransmissionInteger": "PIR Periodic Transmission should be an integer",
        "sensor-config-tempThresholdRequired": "Temperature Threshold is required",
        "sensor-config-tempThresholdRange": `Temperature Threshold should be between ${item?.min} to ${item?.max}`,
        "sensor-config-humThresholdRequired": "Humidity Threshold is required",
        "sensor-config-humThresholdRange": `Humidity Threshold should be between ${item?.min} to ${item?.max}`,
        "sensor-config-humThresholdInteger": "Humidity Threshold should be an integer",
        "sensor-config-temFreqRequired": "Temperature Humidity Frequency is required",
        "sensor-config-temFreqRange": `Temperature Humidity Frequency should be between ${item?.min} to ${item?.max}`,
        "sensor-config-temFreqInteger": "Temperature Humidity Frequency should be an integer",
        "sensor-config-alsThresholdRequired": "ALS Threshold is required",
        "sensor-config-alsThresholdRange": `ALS Threshold should be between ${item?.min} to ${item?.max}`,
        "sensor-config-alsThresholdInteger": "ALS Threshold should be an integer",
        "sensor-config-alsTimeRequired": "ALS Sensing Time is required",
        "sensor-config-alsTimeRange": `ALS Sensing Time should be between ${item?.min} to ${item?.max}`,
        "sensor-config-alsTimeInteger": "ALS Sensing Time should be an integer",
        "sensor-config-alsMulRequired": "Multiplication Factor is required",
        "sensor-config-alsMulRange": `Multiplication Factor should be between ${item?.min} to ${item?.max}`,
        "sensor-config-alsMulInteger": "Multiplication Factor should be an integer",
        "sensor-config-alsMRequired": "M is required",
        "sensor-config-alsMRange": `M should be between ${item?.min} to ${item?.max}`,
        "sensor-config-alsCRequired": "C is required",
        "sensor-config-alsCRange": `C should be between ${item?.min} to ${item?.max}`,
        "switch-config-sceneRequired": "Please select the scenes.",
        "switch-config-noDeviceOrGroup": "There are no devices or groups under this controller.",
        "switch-config-noScene": "There are no scenes under this controller.",
        "switch-config-entityRequired": "Please select the entity.",
        "switch-config-functionTypeRequired": "Please select the function type.",
        "device-wattage-required": "Wattage is required",
        "device-wattage-range": "Wattage should be between 0 and 200",
        "device-wattage-whole": "Wattage should be an integer.",

        "device-name-required": 'Device name is required.',
        "device-name-minlength": "Device name should have minimum 2 characters.",
        "device-name-maxlength": "Device name should not exceed more than 30 characters.",
        "device-name-invalid": "Device name should only contain 'a-z', 'A-Z', '-', '0-9' characters",

        "device-serialNumber-required": 'Serial number is required.',
        "device-serialNumber-minlength": "Serial number should have minimum 6 characters.",
        "device-serialNumber-maxlength": "Serial number should not exceed more than 50 characters.",
        "device-serialNumber-invalid": "Serial number should only contain 'a-z', 'A-Z', '0-9' characters",

        "device-mac-required": 'MAC address is required.',
        "device-mac-minlength": "MAC address should have minimum 17 characters.",
        "device-mac-maxlength": "MAC address should not exceed more than 24 characters.",
        "device-mac-invalid": "MAC address format is invalid",

        "device-brand-required": 'Brand is required.',
        "device-brand-minlength": "Brand should have minimum 3 characters.",
        "device-brand-maxlength": "Brand should not exceed more than 30 characters.",
        "device-brand-invalid": "Brand should only contain 'a-z', 'A-Z', ' ' characters.",

        "device-ipAddress-required": 'IP Address is required.',
        "device-ipAddress-invalid": 'IP Address format is invalid.',
        "device-ipAddress-minlength": "IP Address should have minimum 7 characters.",
        "device-ipAddress-maxlength": "IP Address should not exceed more than 45 characters.",

        "systemLog-date-required": "Date is required",
        "device-discoverSensor-confirmation": "Are you sure you want to close the window, there are sensor(s) available to bind. Once closed you need to perform the Discover operation to view the sensor to bind.",
        "sensorConfig-send-confirm": `Are you sure you want to delete Configuration ${item}?`,
        "receiverPairing-duration-range": "Sensor pairing duration can be between 10 and 360 seconds",
        "receiverPairing-duration-helperText": "Set sensor pairing duration between 10-360 seconds",
        'activeDeleteAllScenes-send-confirm': `Are you sure you want to delete all the scenes under this ${item}?`,
        'activeDeleteAllGroups-send-confirm': `Are you sure you want to delete all the groups under this ${item}?`,
        'inactiveDeleteAllScenes-send-confirm': <p>The {item} is inactive, hence the operation will not be synced with the cloud.<br /><br /><b>Are you sure you want to delete all the scenes under this {item}?</b></p>,
        'inactiveDeleteAllGroups-send-confirm': <p>The {item} is inactive, hence the operation will not be synced with the cloud.<br /><br /><b>Are you sure you want to delete all the groups under this {item}?</b></p>,

        'device-activityLogs-end': "You have reached the end of the logs.",
        "device-activityLog-startDateRequired": "Start date is required.",
        'device-activityLog-infoMessage': "Activity logs will be displayed for a maximum period of 31 days."
    }
}
export const deviceTooltipMessages = (item) => {
    return {
        "device-inactive-tooltip": "Device is currently inactive. Please delete the device.",
        "device-active-tooltip": "Device is active and ready to use.",
        "device-notOnboarded-tooltip": "Device is not onboarded. Please restart the device.",
        "device-onboarded-tooltip": "Device is onboarded and ready to use.",
        "device-deleted-tooltip": "Device has been deleted.",
        "device-online-tooltip": "Device is reachable and ready to use",
        "device-offline-tooltip": "Device is not reachable.",
        "device-synced-tooltip": "Device configurations are synced and up to date.",
        "device-needsSync-tooltip": "Device configurations are out of sync.",
    }
}