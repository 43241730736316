/***
 *
 * Controller class for user.
 * @file DroppableColumn.js
 * @description DroppableColumn component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './DroppableColumn.style.js';
import './DroppableColumn.scss';
import { Box, Paper, Stack } from '@mui/material';
import Alert from '../../assets/icons/info.svg';
import ImageWrapper from '../ImageWrapper/index.js';
import CustomTypography from '../CustomTypography/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { Droppable } from "react-beautiful-dnd";
import StyledChip from '../StyledChip/index.js';
import { dragAndDropActions } from '../../redux/actions/index.js';
import { ReactComponent as DeleteIcon } from '../../assets/icons/cancel.svg';
import SearchWrapper from '../SearchWrapper/index.js';
import ErrorMessage from '../ErrorMessage/index.js';

const DroppableColumn = ({ forList = 'Devices', typeCreated = 'Groups', ...props }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const rightList = useSelector(state => state?.dragAndDrops?.dropList);
  const errorMessage = useSelector(state => state?.dragAndDrops?.errorMessage);

  const handleDelete = (itemToBeDeleted) => {
    // dispatch setRightList(prev=>{
    //   return prev.filter(item => item?.id!==itemToBeDeleted?.id)
    // })
    // setLeftList(prev => {  no need
    //   prev.push(itemToBeDeleted)
    //   return prev
    // })
    dispatch(dragAndDropActions.deleteFromDropList(itemToBeDeleted?.id))
  }

  return (
    <Box className={classes?.DroppableColumn || ""} data-testid="DroppableColumn">
      <Paper sx={{ maxHeight: '500px', height: '100%', border: '1px solid #D9D9D9' }}>
        <Box sx={{ background: '#0F8D480D', padding: '18px 22px 0px', display: 'flex', alignItems: 'center' }}>
          {/* <CustomTypography content={`Selected devices`} weight={500} size={12} lineHeight={14.63} color="#fff" /> */}
          <ImageWrapper src={Alert} />
          <CustomTypography content={props?.label ? props?.label : `Select ${forList} From Left Column or Drag them Here to Add to the ${typeCreated}`} ml={'4px'} weight={600} size={12} lineHeight={14.63} color="rgba(0, 0, 0, 0.8)" styles={{ textOverflow: 'ellipsis' }} />
        </Box>


        {/* <Box className={classes?.infoTag}>
                  <ImageWrapper src={Alert}/>
                  <CustomTypography content={`Select ${forList} from left column or drag them here to add to the ${typeCreated}`} ml={'4px'} weight={600} size={12} lineHeight={14.63} color="rgba(0, 0, 0, 0.8)" />
              </Box> */}

        <SearchWrapper
          placeholder={`Search ${forList}`}
          data={rightList.length !== 0 ? rightList : [{ name: 'No Item Available' }]}
          autoComplete={true}
          autoFill={false}
        />

        <Droppable droppableId={'rightList'} key={'rightList'}>
          {(provided) => {
            return (
              <Box className="droppableArea" sx={{ p: '5px 20px ', height: '360px', overflow: "hidden", overflowY: "scroll" }} {...provided.droppableProps} ref={provided.innerRef}>
                <Stack direction={'column'}>
                  {rightList.map((item, index) => {
                    // console.log('itemwgj',item);
                    return (
                      <Box key={item?.id}>
                        <StyledChip
                          variant="outlined"
                          icon={<ImageWrapper src={item.icon} />}
                          label={item?.name || item?.id}
                          sx={{
                            borderRadius: '4px',
                            fontSize: '0.875rem',
                            fontWeight: '600',
                            minHeight: "40px",
                            padding: '8px 8px 8px 12px',
                            margin: '2px'
                          }}
                          style={item?.redChip === true ? { border: '1px solid #de2828' } : {}}
                          color={item?.redChip === true ? 'lightRed' : 'lightGreen'}
                          deleteIcon={<DeleteIcon />}
                          onDelete={() => handleDelete(item)} />
                      </Box>
                    )
                  })}
                </Stack>
                {provided.placeholder}
              </Box>
            )
          }}
        </Droppable>
        {errorMessage !== '' && <ErrorMessage message={errorMessage} sx={{ marginLeft: '8px' }} />}
      </Paper>

    </Box>
  )
};

DroppableColumn.propTypes = {};

DroppableColumn.defaultProps = {};

export default DroppableColumn;
