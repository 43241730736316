/***
 *
 * Controller class for user.
 * @file TestComponent.js
 * @description TestComponent component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import { Typography } from '@mui/material';
import React, { useEffect } from 'react';
import ImageWrapper from '../ImageWrapper';
import Device from "../../assets/icons/bulb.svg"
// import PropTypes from 'prop-types';
import './TestComponent.scss';
import spaceIcon from '../../assets/icons/space.svg';

const TestComponent = ({data,...props}) => {
  useEffect(() =>{console.log(data)}, [data])

  return (
      <div className="TestComponent" data-testid="TestComponent">
        <Typography className="">
          {data.category === "abc 12" ? (
            <><ImageWrapper src={Device} style={{marginRight: 10}} /> {data.category} | <ImageWrapper src={spaceIcon} style={{marginRight: 10}} /> {data.location}</>
          ) : (
            <><ImageWrapper src={spaceIcon} style={{marginRight: 10}} /> {data.location}</>
          )}          
        </Typography>
        {props?.label}
      </div>
  )
};

TestComponent.propTypes = {};

TestComponent.defaultProps = {};

export default TestComponent;
