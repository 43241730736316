/***
 *
 * Controller class for user.
 * @file StyledDateTimePicker.js
 * @description StyledDateTimePicker component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './StyledDateTimePicker.style.js';
import './StyledDateTimePicker.scss';
import { Box } from '@mui/material';
import { DatePicker, DateTimePicker, DesktopDatePicker, LocalizationProvider, MobileDatePicker, MobileTimePicker, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import StyledTextfield from '../StyledTextfield/index.js';
import dayjs from 'dayjs';

const StyledDateTimePicker = ({ picker = "datePicker", disabled = false, id = "date-picker", name = "datePicker", value = "", defaultValue = { required: false, value: null }, format = null, error = null, message = null, placeholder = null, sx = {}, onChange = () => { }, ...props }) => {
  const classes = useStyles()

  const [pickerValue, setPickerValue] = useState(value ? dayjs(value) : null);

  const handleChange = (newValue) => {
    setPickerValue(newValue);
    onChange({
      target: { name: name, value: newValue }
    })
  };

  useEffect(() => {
    // console.log('valllll', value, new Date(Number(value)).toString() === 'Invalid Date', Date.parse(value) );
    if (value) {
      setPickerValue(new Date(Number(value)));
    }
    else if (!value) setPickerValue(null);
  }, [value])

  useEffect(() => {
    if (defaultValue?.required) {
      setPickerValue(defaultValue?.value);
      onChange({
        target: { name: name, value: defaultValue?.value }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue?.required, defaultValue?.value])

  return (
    <Box className={classes?.StyledDateTimePicker || ""} data-testid="StyledDateTimePicker">
      {/* {JSON.stringify(props)} */}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {(() => {
          switch (picker) {
            case "TimePicker":
              return (
                <TimePicker
                  id={id || "time-picker"}
                  name={name || "time-picker"}
                  label="Time"
                  value={pickerValue ? dayjs(pickerValue) : null}
                  onChange={handleChange}
                  disabled={disabled}
                  inputFormat={format || "hh:mm a"}
                  renderInput={(params) => <StyledTextfield
                    inputProps={{
                      ...params.inputProps,
                      placeholder: (placeholder || "hh:mm a")
                    }}
                    sx={{ width: "100%", ...sx }}
                    {...params}
                    error={error}
                    helperText={message}
                    onKeyDown={(e) => { if (e.key !== 'Backspace') e.preventDefault(); }}
                  />}
                  {...props}
                />
              );
            case "MobileTimePicker":
              return (
                <MobileTimePicker
                  id={id || "time-picker"}
                  name={name || "time-picker"}
                  label="Time"
                  value={pickerValue ? dayjs(pickerValue) : null}
                  onChange={handleChange}
                  disabled={disabled}
                  inputFormat={format || "hh:mm a"}
                  renderInput={(params) => <StyledTextfield
                    inputProps={{
                      ...params.inputProps,
                      placeholder: (placeholder || "hh:mm a")

                    }}
                    sx={{ width: "100%", ...sx }}
                    {...params}
                    error={error}
                    helperText={message}
                    onKeyDown={(e) => { if (e.key !== 'Backspace') e.preventDefault(); }}
                  />}
                  {...props}
                />
              )
            case "MobileDatePicker":
              return (
                <MobileDatePicker
                  id={id || "mobile-date-picker"}
                  name={name || "mobile-date-picker"}
                  label="Date mobile"
                  inputFormat={format || "MM/DD/YYYY"}
                  value={pickerValue ? dayjs(pickerValue) : null}
                  onChange={handleChange}
                  disabled={disabled}
                  renderInput={(params) => <StyledTextfield
                    inputProps={{
                      ...params.inputProps,
                      placeholder: (placeholder || "MM/DD/YYYY")
                    }}
                    sx={{ width: "100%", ...sx }}
                    {...params}
                    error={error}
                    helperText={message}
                    onKeyDown={(e) => { if (e.key !== 'Backspace') e.preventDefault(); }}
                  />}
                  {...props}
                />
              );
            case "DateTimePicker":
              return (
                <DateTimePicker
                  id={id || "date-time-picker"}   /// redundant; no use 
                  name={name || "date-time-picker"} /// redundant; no use
                  label="Date desktop"
                  inputFormat={format || "MM/DD/YYYY hh:mm a"}
                  value={pickerValue ? dayjs(pickerValue) : null}
                  disabled={disabled}
                  onChange={handleChange}
                  renderInput={(params) => <StyledTextfield
                    inputProps={{
                      ...params.inputProps,
                      placeholder: (placeholder || "MM/DD/YYYY hh:mm a")
                    }}
                    sx={{ width: "100%", ...sx }}
                    {...params}
                    name={name || "date-time-picker"}  /// for error handling
                    id={id || "date-time-picker"}     /// for error handling
                    error={error}
                    helperText={message}
                    onKeyDown={(e) => { if (e.key !== 'Backspace') e.preventDefault(); }}
                  />}
                  {...props}
                />
              );
            case 'datePicker':
              return (
                <>
                  {/* <DatePicker
                  defaultValue={pickerValue}
                  minDate={props.minDate}
                  views={['year', 'month', 'day']}
                /> */}
                  <DatePicker
                    id={id || "date-picker"}
                    name={name || "date-picker"}
                    // error={!_.isEmpty(validation[cl?.name])}
                    // message={!_.isEmpty(validation[cl?.name]) ? validation[cl?.name] : ""}
                    fullWidth
                    InputProps={{
                      className: "date-input",
                      disableUnderline: true,
                    }}
                    className="date-picker"
                    style={{ marginTop: "0px", marginBottom: "0px" }}
                    onChange={(value) => { handleChange(value) }}
                    disabled={disabled}
                    variant={id || "inline"}
                    inputFormat={format || "MM/DD/YYYY"}
                    margin="normal"
                    // defaultValue={dayjs(pickerValue)}
                    value={pickerValue ? dayjs(pickerValue) : null}
                    renderInput={(params) => <StyledTextfield
                      inputProps={{
                        ...params.inputProps,
                        placeholder: (placeholder || "DD/MM/YYYY")
                      }}
                      sx={{ width: "100%", ...sx }}
                      {...params}
                      name={name || "date-time-picker"}  /// for error handling
                      id={id || "date-time-picker"}
                      error={error}
                      helperText={message}
                      onKeyDown={(e) => { if (e.key !== 'Backspace') e.preventDefault(); }}
                    />}
                    // views={['year', 'month', 'day']}
                    {...props}
                  />
                </>
              )
            default:
              return (
                <DesktopDatePicker
                  id={id || "desktop-date-picker"}
                  name={name || "desktop-date-picker"}
                  label="Date desktop"
                  inputFormat={format || "DD/MM/YYYY"}
                  value={pickerValue ? dayjs(pickerValue) : null}
                  onChange={(value) => { handleChange(value) }}
                  disableToolbar
                  style={{ border: '1px solid #ff4336' }}
                  fullWidth
                  col
                  InputProps={{
                    className: "date-input",
                    disableUnderline: true,
                  }}
                  disabled={disabled}
                  renderInput={(params) => <StyledTextfield
                    sx={{ width: "100%", ...sx }}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: (placeholder || "DD/MM/YYYY")
                    }}
                    {...params}
                    error={error}
                    helperText={message}
                    onKeyDown={(e) => { if (e.key !== 'Backspace') e.preventDefault(); }}
                  />}
                  {...props}
                />
              );
          }
        })()}
      </LocalizationProvider>
    </Box>
  )
};

StyledDateTimePicker.propTypes = {};

StyledDateTimePicker.defaultProps = {};

export default StyledDateTimePicker;
