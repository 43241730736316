/***
 *
 * Controller class for user.
 * @file SceneDragDropWrapper.js
 * @description SceneDragDropWrapper component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './SceneDragDropWrapper.style.js';
import './SceneDragDropWrapper.scss';
import { Box, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { groupActions, deviceActions } from '../../redux/actions/index.js';
import Group from '../../assets/icons/group.svg';
import Light from '../../assets/icons/light.svg';
import _ from 'lodash';
import SpaceTree from '../SpaceTree/index.js';
import { dragAndDropActions } from '../../redux/actions/index.js';
import DragAndDropColumnsWrapper from '../DragAndDropColumnsWrapper/index.js';
import { disableDevFeatures } from '../../config/appConfig.js';


//getGroupDeviceDetails also in scene or a common in device

const SceneDragDropWrapper = ({ ifEdit = false, ifMovedToNextStep, setNotFoundDevicesInDropList }) => {
  const classes = useStyles()
  const dispatch = useDispatch();
  const deviceList = useSelector(state => state?.devices?.deviceList);
  const groupList = useSelector(state => state?.groups?.list);
  const preSelectedScene = useSelector(state => state?.scenes?.scene);
  const sceneDevices = useSelector((state) => state?.devices?.detailedDeviceList);
  const [completeList, setCompleteList] = useState([])
  const dropList = useSelector(state => state?.dragAndDrops?.dropList);
  const [dropListIds, setDropListIds] = useState([])
  const [loading, setLoading] = useState(false)
  const [isSpaceSelected, setIsSpaceSelected] = useState(false)

  useEffect(() => {
    if (!_.isEmpty(preSelectedScene?.actions) && ifEdit) {
      let filteredDevices = preSelectedScene?.actions?.filter(item => item?.deviceId !== undefined);
      if (!_.isEmpty(filteredDevices)) {
        dispatch(deviceActions.getDetailedDeviceList({
          "deviceIds": filteredDevices.map(item => item?.deviceId).join(',') //more than 30 not handled
        }))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preSelectedScene])

  useEffect(() => {
    if (ifEdit && !ifMovedToNextStep) { //&& !_.isEmpty(sceneDevices)
      let initialDropList = preSelectedScene?.actions?.map(item => {
        if (item?.groupId !== undefined) {
          item.id = item?.groupId;
          item.icon = Group
          item.type = 'GROUP'
          if (!disableDevFeatures?.itemId && item?.name === undefined) {
            item.name = (item?.groupId)
          }
          return item
        }
        else {
          item.id = item?.deviceId;
          item.icon = Light
          item.type = 'LIGHT'
          let detailedDevice = sceneDevices.find(device => device?.deviceId === item?.deviceId) || {}
          if (!disableDevFeatures?.itemId && (detailedDevice?.name === undefined || detailedDevice.name.slice(-1) !== ')')) {
            detailedDevice.name = (detailedDevice?.name || '') + ' (' + item?.deviceId + ')'
          }
          let obj = { ...item, ...detailedDevice };
          obj.name = obj?.name || '';
          if (obj?.configuration?.daliDriver === undefined && obj?.modelId === undefined) {
            obj.redChip = true;
          }
          return obj;
        }
      });
      console.log('initialDropWgj', initialDropList);
      //dispatch action to set drop list complete
      dispatch(dragAndDropActions.setDropList(initialDropList));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sceneDevices])

  useEffect(() => {
    let droppedIds = dropList.map(item => item?.id)
    setDropListIds(droppedIds)
    let notFoundDevicesInDropList = dropList.filter(item => { return (item?.deviceId !== undefined && item?.configuration?.daliDriver === undefined && item?.modelId === undefined) }) || [];
    setNotFoundDevicesInDropList(notFoundDevicesInDropList);
    if (!_.isEmpty(dropList)) {
      dispatch(dragAndDropActions.setErrorMessage('')); //WGJGBM< scene-emptyMessage
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropList])


  const onClick = (event, data) => {
    if (!isSpaceSelected) { setIsSpaceSelected(true) }
    setLoading(true)
    //(()=>{
    dispatch(groupActions.getAllGroupsBySpace(data?.spaceId))
    dispatch(deviceActions.getDevicesBySpaceId(data?.spaceId))
    setTimeout(() => {
      setLoading(false)
    }, 2000)
    //})
  }

  useEffect(() => {
    if (isSpaceSelected || ifMovedToNextStep) {
      let devices = deviceList.filter(device => {
        if (device.category.includes('LightDriver') && device?.status === "active") {
          device.id = device?.deviceId
          device.name = device?.name || '';
          if (!disableDevFeatures?.itemId && device.name.slice(-1) !== ')') {
            device.name = device?.name + ' (' + device?.id + ')'
          }
          device.icon = Light
          device.type = 'LIGHT'
          return device;
        }
      })
      let groups = groupList.map(group => {
        group.id = group.groupId
        group.type = 'GROUP'
        group.icon = Group
        return group
      })
      setCompleteList([...devices, ...groups])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceList, groupList, isSpaceSelected])

  // change in space I want to update 
  useEffect(() => {
    let dragListToBeSet = [...completeList]
    if (!_.isEmpty(dropListIds)) {
      dragListToBeSet = completeList.filter(item => !dropListIds.includes(item?.id));
    }
    //dispatch to drag list complete
    dispatch(dragAndDropActions.setDragList(dragListToBeSet))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completeList, dropListIds])




  return (
    <Box className={classes?.SceneDragDropWrapper || ""} data-testid="SceneDragDropWrapper">
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <SpaceTree onClick={onClick} />
        </Grid>
        <Grid item xs={12} md={9}>
          <DragAndDropColumnsWrapper forList={'Devices/Groups'} typeCreated={'Scene'} loading={loading} />
        </Grid>
      </Grid>
    </Box>
  )
};

SceneDragDropWrapper.propTypes = {};

SceneDragDropWrapper.defaultProps = {};

export default SceneDragDropWrapper;
