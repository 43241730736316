/***
 *
 * Controller class for user.
 * @file CustomAccordion.js
 * @description CustomAccordion component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './CustomAccordion.style.js';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, FormControl } from '@mui/material';
import { ReactComponent as AccordionCaret } from '../../assets/icons/dropdown-caret.svg';
import _ from 'lodash';

/*  ****props and description****

defaultState: true or false: to open accordion on initial render only
aid: unique id of each accordion: shud be named as 'panel1', 'panel2' etc.., remember it is always constant
panel: variable whose value changes; must reflect value of aid, reqiured only when controlling accordions from outside; otherwise no need for it; check <RightPanel/> for example
onChage: callback function to know current state of accordion; gives boolean value

*/

const CustomAccordion = ({ defaultState=true, aid = "panel1", title, panel = 'panel1', children, bordered, component, contentBg = "rgba(15, 141, 72, 0.02)", LeftIcon, RightIcon, onChange = () => { }, customsx = { details: {}, leftIcon: {}, rightIcon: {} }, summaryCss = {},  ...props }) => {
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState(defaultState? aid:'');
  const handleChange = (accpanel) => (event, newExpanded) => {
    setExpanded(newExpanded ? accpanel : false);
    onChange(newExpanded ? true : false);
  };

  useEffect(()=>{
    setExpanded(!_.isEmpty(panel)?aid:false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[panel])

  useEffect(()=>{
    setExpanded(defaultState?aid:''); //// to open or close the panel in intialization
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <Accordion
      className={classes?.CustomAccordion || ""}
      data-testid="CustomAccordion"
      expanded={(expanded === panel)}
      onChange={handleChange(aid)}
      {...props}
    >
      <AccordionSummary
        style={{ '&.MuiAccordionSummaryContent': { ...summaryCss } }}
        expandIcon={<AccordionCaret />}
        aria-label="Expand"
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Box sx={{ display: 'flex', width: 'max-content' }}>
            {LeftIcon && <LeftIcon className="leftIcon_Acc" style={{ marginRight: "7px", ...customsx.leftIcon }} />}
            <Typography sx={{ fontWeight: 'bold', fontSize: '0.875rem', width: "max-content" }}>{title}</Typography>
            {RightIcon && <RightIcon className="rightIcon_Acc" style={{ marginLeft: "7px", ...customsx.rightIcon }} />}
          </Box>
          {component &&
            <FormControl
              sx={{ alignItems: 'end', width: '100%', pr: 2 }}
              aria-label="Acknowledge"
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}
            >{component}</FormControl>
          }
        </Box>
      </AccordionSummary>
      <AccordionDetails
        className={classes?.ContentBox || ""}
        sx={{ marginTop: !bordered ? "4px" : "0px", border: bordered ? "1px rgba(0, 0, 0, 0.1) solid" : "none", backgroundColor: contentBg, ...customsx.details }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  )
};

CustomAccordion.propTypes = {};

CustomAccordion.defaultProps = {};

export default CustomAccordion;
