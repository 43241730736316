import _ from "lodash";
import { failure, getMessage, request, setSessionItem, success, tree } from "../../helpers";
import { delay, findNode, spaceService } from "../../services";
import { spaceTypes } from "../types";
import { appActions } from "./app.actions";
import { canvasActions } from "./canvas.actions";

function getSpaceChildren(children, level, depth = 0, dispatch, comingFrom) {
    level = level + 1;
    // console.log("Child spaces A ", (level + 0), " ", level);
    if (Array.isArray(children)) {
        children?.forEach((sp, index) => {
            // console.log("Child spaces B ", (level + 1), " ", index, sp, sp?.Spaces);           
            if (!sp?.Spaces) {
                //   console.log("Child spaces C ", (level + 2));
                setTimeout(() => {
                    dispatch(getSpace({ spaceId: sp?.spaceId, level, depth, comingFrom }));
                }, 500);
            } else if (level <= depth && sp?.Spaces && sp?.Spaces?.length > 0) {
                setTimeout(() => {
                    getSpaceChildren(sp?.Spaces, level, depth, dispatch, comingFrom);
                }, 500);
            }
        });
    }
}

const getArray = async (space, depth, collected) => {
    await spaceService.getSpaceById(space?.spaceId, depth).then(res => {
        res.forEach(s => collected.push(s));
    })
    return collected;
}


const getAllSpaces = (depth = 0, comingFrom = 'login') => {
    return dispatch => {
        dispatch(request(spaceTypes.GET_ALL_REQUEST));
        spaceService.getAllSpaces()
            .then(async (spaces) => {
                const aa = await Promise.all(spaces.map(async space => await spaceService.getSpaceById(space?.spaceId, depth).then(res => res)));
                return { root: spaces, all: [...spaces, ...aa[0]] };
            })
            .then(resp => {
                dispatch(success(spaceTypes.GET_ALL_SUCCESS, { payload: resp.all.sort((a, b) => { if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; } return 0; }) }));  /// sorting so that list always comes alphabetically in redux, not randomly
                tree(resp.root, resp.all).then(tree => {
                    dispatch(success(spaceTypes.CREATE_SPACE_TREE_SUCCESS, { payload: tree }));
                });

                // spaces.forEach(s => {
                //     let promise = getArray(s, 0, []);
                //     if (typeof promise === 'object' && typeof promise.then === 'function' && promise.then !== undefined) {
                //         promise.then(res => {
                //             sArr = [s, ...res];
                //             return sArr;
                //         })
                //         .then(async (sps)=>{
                //             console.log("SpaceTree ------ ", treeNode(spaces, s.spaceId))
                //             s["Spaces"] = await treeNode(sps, s.spaceId);
                //             // return Promise.all(s).then(res => res);
                //             return 
                //         })
                //         .then(res => {
                //             console.log('ssssspace===>', res);
                //             dispatch(success(spaceTypes.GET_ALL_SUCCESS, { payload: res }));
                //             if (depth > 0) {
                //                 // getSpaceChildren(spaces, 0, depth, dispatch, comingFrom);
                //             }
                //         },
                //             ({error,code}) => {
                //                 dispatch(failure(spaceTypes.GET_ALL_FAILURE, error.toString()));
                //                 dispatch(appActions.apiErrorMessage(error.toString()));
                //             }
                //         );
                //     }
                // })
            })
    };
}

const getSpace = ({ spaceId, level = null, depth = null, comingFrom, asset = null }) => {
    return dispatch => {
        dispatch(request(spaceTypes.GET_REQUEST));
        spaceService.getSpaceById(spaceId)
            .then((space) => {
                if (_.isEmpty(space?.asset)) {
                    space['asset'] = null;
                }
                return space;
            })
            .then(space => {
                // console.log('space iddddd===>',space, spaceId);
                dispatch(success(spaceTypes.GET_SUCCESS, { payload: space }));
                if (depth > 0) {
                    getSpaceChildren(space?.Spaces, level, depth, dispatch, comingFrom);
                }
            },
                ({ error, code }) => {
                    dispatch(failure(spaceTypes.GET_FAILURE, error.toString()));
                    getMessage(`spaceById-get-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    };
}

const addSpace = (parentId, payload, cb = () => { }) => {
    return (dispatch, getState) => {
        dispatch(request(spaceTypes.ADD_REQUEST));
        spaceService.addSpace(parentId, payload)
            .then(({ data: space, status }) => {
                const spaceConnectedAsset = getState()?.spaces?.spaceConnectedAsset;
                const rootSpace = getState()?.spaces?.rootSpace;
                console.log('add space===>', spaceConnectedAsset, rootSpace, parentId);
                let entitypayload = {
                    "spaceId": space?.spaceId,
                    "default": true,
                };
                cb(space);
                if (rootSpace?.spaceId !== parentId && !_.isEmpty(spaceConnectedAsset)) { ///// grand child of root space 
                    let parentSpaceOfAsset = spaceConnectedAsset.find(s => s?.spaceId === parentId);
                    dispatch(spaceActions.associateAssetwithSpace({ spaceId: space?.spaceId, assetId: parentSpaceOfAsset?.assetId }));
                    // dispatch(spaceActions.addUpdateSpaceEntities(parentSpaceOfAsset?.assetId, entitypayload));
                    // dispatch(spaceActions.addUpdateSpaceConfigs(parentSpaceOfAsset?.assetId, [{ spaceId: space?.spaceId, config: JSON.stringify({ shapes: [], layout: {} }) }]))

                }
                dispatch(success(spaceTypes.ADD_SUCCESS, { payload: space }));
                getMessage(`space-post-${status}`).then(delay(500)).then((msg) => {
                    dispatch(appActions.apiSuccessMessage(msg));
                })
            },
                ({ error, code }) => {
                    dispatch(failure(spaceTypes.ADD_FAILURE, error.toString()));
                    getMessage(`space-post-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    };
}

const updateSpace = (spaceId, payload) => {
    return dispatch => {
        dispatch(request(spaceTypes.UPDATE_REQUEST));
        spaceService.updateSpace(spaceId, payload)
            .then(({ data: space, status }) => {
                dispatch(success(spaceTypes.UPDATE_SUCCESS, { payload: space }));
                getMessage(`space-put-${status}`).then(delay(500)).then((msg) => {
                    dispatch(appActions.apiSuccessMessage(msg));
                })
            },
                ({ error, code }) => {
                    dispatch(failure(spaceTypes.UPDATE_FAILURE, error.toString()));
                    getMessage(`space-put-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    };
}

const deleteSpace = (spaceId, parentId, cb = () => { }) => {
    return (dispatch) => {
        dispatch(request(spaceTypes.DELETE_REQUEST));
        spaceService.deleteSpace(spaceId)
            .then(({ data, status }) => {
                dispatch(canvasActions.removeShapes({ spaceId: spaceId }))
                dispatch(success(spaceTypes.DELETE_SUCCESS, { payload: { spaceId: spaceId, parentId: parentId } }));
                getMessage(`space-delete-${status}`).then(delay(500)).then((msg) => {
                    dispatch(appActions.apiSuccessMessage(msg));
                })
                cb(spaceId);
            },
                ({ error, code }) => {
                    dispatch(failure(spaceTypes.DELETE_FAILURE, error.toString()));
                    getMessage(`space-delete-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    };
}

const createBreadcrumbsData = ({ childrenKey, obj, output }, cb = (res) => { }) => {
    return async (dispatch, getState) => {
        const data = getState()?.spaces?.spaceTree;
        let outPutArr = [];
        await findNode(data, childrenKey, obj, (res) => {
            // console.log("tttt aaaaaa -- ", data, childrenKey, obj, res)
            if (res && res?.spaceId) {
                outPutArr.push({ id: res?.spaceId, "label": res?.name, "url": "/" });
                obj["value"] = res?.parentSid;

                findNode(data, childrenKey, obj, (res) => {
                    if (res && res?.spaceId) {
                        outPutArr.push({ id: res?.spaceId, "label": res?.name, "url": "/" });
                        obj["value"] = res?.parentSid;

                        findNode(data, childrenKey, obj, (res) => {
                            if (res && res?.spaceId) {
                                outPutArr.push({ id: res?.spaceId, "label": res?.name, "url": "/" });
                                obj["value"] = res?.parentSid;

                                findNode(data, childrenKey, obj, (res) => {
                                    if (res && res?.spaceId) {
                                        outPutArr.push({ id: res?.spaceId, "label": res?.name, "url": "/" });
                                        obj["value"] = res?.parentSid;

                                        findNode(data, childrenKey, obj, (res) => {
                                            if (res && res?.spaceId) {
                                                outPutArr.push({ id: res?.spaceId, "label": res?.name, "url": "/" });
                                                obj["value"] = res?.parentSid;

                                                findNode(data, childrenKey, obj, (res) => {
                                                    if (res && res?.spaceId) {
                                                        outPutArr.push({ id: res?.spaceId, "label": res?.name, "url": "/" });
                                                        obj["value"] = res?.parentSid;

                                                        findNode(data, childrenKey, obj, (res) => {
                                                            if (res && res?.spaceId) {
                                                                outPutArr.push({ id: res?.spaceId, "label": res?.name, "url": "/" });
                                                                obj["value"] = res?.parentSid;

                                                                findNode(data, childrenKey, obj, (res) => {
                                                                    if (res && res?.spaceId) {
                                                                        outPutArr.push({ id: res?.spaceId, "label": res?.name, "url": "/" });
                                                                        obj["value"] = res?.parentSid;
                                                                    }
                                                                });
                                                            }
                                                        });
                                                    }
                                                });
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    }
                });
            }
        });

        // await data.find(res=> {
        //     if(res.spaceId === obj["value"]) {
        //         outPutArr.push({ id: res?.spaceId, "label": res?.name, "url": "/" });
        //         obj["value"] = res?.parentSid;
        //     }
        // })

        // console.log("tttt bbbbb -- ", outPutArr)
        dispatch(success(spaceTypes.BREADCRUMBS_SUCCESS, { payload: outPutArr.reverse() }));
        // return outPutArr;
    }
}

const selectSpace = (space) => {
    return (dispatch, getState) => {
        if (space) {
            let breadcrumbsData = [];

            // let breadcrumbsData = getState().spaces.breadcrumbsData || [];

            // if(!breadcrumbsData || (breadcrumbsData && !Array.isArray(breadcrumbsData))) { breadcrumbsData = []; }
            // if(breadcrumbsData) {
            //     const ind = breadcrumbsData.findIndex(bd=>bd.spaceId === space.spaceId);
            //     if(ind > -1) {
            //         breadcrumbsData = breadcrumbsData.slice(0, ind + 1);
            //         dispatch(success(spaceTypes.BREADCRUMBS_SUCCESS, {payload: breadcrumbsData}));
            //     } else {                    
            //         breadcrumbsData.push({
            //             "id": space?.spaceId,
            //             "label": space?.name,
            //             "url": "/"
            //         });
            //         dispatch(success(spaceTypes.BREADCRUMBS_SUCCESS, {payload: breadcrumbsData}));
            //     }
            // }

            breadcrumbsData.push({
                "id": space?.spaceId,
                "label": space?.name,
                "url": "/"
            });

            // findNode(data, childrenKey, {...find}, (res)=>{

            // });


            sessionStorage.setItem("miraie-selectedSpace", JSON.stringify({ spaceId: space?.spaceId, parentSid: space?.parentSid, name: space?.name, type: space?.type, asset: space?.asset }));
            dispatch(success(spaceTypes.SELECT_SPACE_SUCCESS, { payload: space }));
            setSessionItem("miraie-breadcrumb-data", JSON.stringify(breadcrumbsData));
        }
    }
}

const addSpaceAsset = (spaceId, payload) => {
    return dispatch => {
        dispatch(request(spaceTypes.ADD_ASSET_REQUEST));
        spaceService.addSpaceAsset(spaceId, payload)
            .then(({ data: spaceAsset, status }) => {
                dispatch(success(spaceTypes.ADD_ASSET_SUCCESS, { payload: spaceAsset }));
                if (spaceAsset?.mime === 'png') {
                    let obj = spaceAsset;
                    obj[`${spaceAsset?.assetId}`] = payload?.spaceIds;
                    dispatch(success(spaceTypes.GET_PNG_ASSET_SUCCESS, { payload: obj }))
                }
                else if (spaceAsset?.mime === 'dwg') {
                    dispatch(success(spaceTypes.GET_DWG_ASSET_SUCCESS, { payload: spaceAsset }))
                }
                getMessage(`canvasAddAsset-post-${status}`).then(delay(500)).then((err) => {
                    dispatch(appActions.apiSuccessMessage(err));
                })
            },
                ({ error, code }) => {
                    dispatch(failure(spaceTypes.ADD_ASSET_FAILURE, error.toString()));
                    getMessage(`canvasAddAsset-post-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    };
}


const getGivenAsset = (assetId) => {
    return dispatch => {
        dispatch(request(spaceTypes.GET_GIVEN_ASSET_REQUEST));
        spaceService.getGivenAsset(assetId)
            .then((spaceAsset) => {
                dispatch(success(spaceTypes.GET_GIVEN_ASSET_SUCCESS, { payload: spaceAsset }))
            },
                ({ error, code }) => {
                    dispatch(failure(spaceTypes.GET_GIVEN_ASSET_FAILURE, error.toString()));
                    getMessage(`canvas-get-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    };
}

const getAllAsset = (spaceId) => {
    return dispatch => {
        dispatch(request(spaceTypes.GET_ALL_ASSET_REQUEST));
        spaceService.getAllAsset(spaceId)
            .then(({ data: spaceAsset, status }) => {
                dispatch(success(spaceTypes.GET_ALL_ASSET_SUCCESS, { payload: spaceAsset }))
                // getMessage(`canvas-get-${status}`).then(delay(500)).then((err) => {
                //     dispatch(appActions.apiSuccessMessage(err));
                // })

            },
                ({ error, code }) => {
                    dispatch(failure(spaceTypes.GET_ALL_ASSET_FAILURE, error.toString()));
                    getMessage(`canvasGetAllAsset-get-${code}`).then(delay(500)).then((err) => {
                        // dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    };
}

const UpdateSpaceAsset = (spaceId, assetId, payload) => {
    return dispatch => {
        dispatch(request(spaceTypes.UPDATE_ASSET_REQUEST));
        spaceService.updateSpaceAsset(spaceId, assetId, payload)
            .then(({ data: spaceAsset, status }) => {
                dispatch(success(spaceTypes.UPDATE_ASSET_SUCCESS, { payload: spaceAsset }));
                getMessage(`canvasUpdateAsset-put-${status}`).then(delay(500)).then((err) => {
                    dispatch(appActions.apiSuccessMessage(err));
                })
            },
                ({ error, code }) => {
                    dispatch(failure(spaceTypes.UPDATE_ASSET_FAILURE, error.toString()));
                    getMessage(`canvasUpdateAsset-put-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    };
}

const DeleteSpaceAsset = (spaceId) => {
    return dispatch => {
        dispatch(request(spaceTypes.DELETE_ASSET_REQUEST));
        spaceService.deleteSpaceAsset(spaceId)
            .then(({ data: spaceAsset, status: code }) => {
                dispatch(success(spaceTypes.DELETE_ASSET_SUCCESS, { payload: spaceAsset }));
                getMessage(`canvas-put-${code}`).then(delay(500)).then((err) => {
                    dispatch(appActions.apiSuccessMessage(err));
                })
            },
                ({ error, code }) => {
                    dispatch(failure(spaceTypes.DELETE_ASSET_FAILURE, error.toString()));
                    getMessage(`canvas-put-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    };
}

const addUpdateSpaceConfigs = (assetId, payload) => {
    return dispatch => {
        dispatch(request(spaceTypes.UPDATE_ASSET_CONFIGURATION_REQUEST));
        spaceService.addUpdateSpaceConfigs(assetId, payload)
            .then(({ data, status }) => {
                dispatch(success(spaceTypes.UPDATE_ASSET_CONFIGURATION_SUCCESS, { payload: 200 }));
                getMessage(`canvasUpdateConfig-put-${status}`).then(delay(500)).then((err) => {
                    dispatch(appActions.apiSuccessMessage(err));
                })
            },
                ({ error, code }) => {
                    dispatch(failure(spaceTypes.UPDATE_ASSET_CONFIGURATION_FAILURE, error.toString()));
                    getMessage(`canvasUpdateConfig-put-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    };
}

const addUpdateSpaceEntities = (assetId, payload) => {
    return dispatch => {
        dispatch(request(spaceTypes.UPDATE_ENTITY_CONFIGURATION_REQUEST));
        spaceService.addUpdateSpaceEntities(assetId, payload)
            .then(({ data, status }) => {
                dispatch(success(spaceTypes.UPDATE_ENTITY_CONFIGURATION_SUCCESS, { payload: 200 }));
                getMessage(`canvasUpdateEntities-put-${status}`).then(delay(500)).then((err) => {
                    dispatch(appActions.apiSuccessMessage(err));
                })
            },
                ({ error, code }) => {
                    dispatch(failure(spaceTypes.UPDATE_ENTITY_CONFIGURATION_FAILURE, error.toString()));
                    getMessage(`canvasEntities-put-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    };
}




//// emptying the reducer state after an operation is done; imperative to pass "type"
export const emptySpaceState = (type, payload = null) => {
    if (!type)
        return null;

    return dispatch => {
        dispatch(success(spaceTypes[type], { payload: payload }))
    }
}

//To get spaceLogs
const getSpaceActivityLogs = (requestSpecs) => { //requestSpecs is an object which must have spaceId. It can also have start time, end time, type, uid
    return dispatch => {
        dispatch(request(spaceTypes.GET_SPACE_LOGS_REQUEST));
        spaceService.getSpaceActivityLogs(requestSpecs)
            .then(
                spaceLogs => dispatch(success(spaceTypes.GET_SPACE_LOGS_SUCCESS, { payload: spaceLogs })),
                ({ error, code }) => {
                    dispatch(failure(spaceTypes.GET_SPACE_LOGS_FAILURE, error.toString()));
                    getMessage(`spaceLog-get-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            );
    }
}

export const updateSpaceList = (list) => {
    return dispatch => {
        dispatch(success(spaceTypes.UPDATE_SPACE_LIST, { payload: list }));
    }
}

export const createSubTree = (rootSpace, list) => {
    return dispatch => {
        tree([rootSpace], list).then(sTree => {
            dispatch(success(spaceTypes.CREATE_SUB_SPACE_TREE, { payload: sTree }));
        })
    }
}

export const getCurrentSpaceById = (id) => {
    return dispatch => {
        dispatch(request(spaceTypes.GET_CURRENT_SPACE_REQUEST));
        spaceService.getCurrentSpaceById(id)
            .then((space) => {
                dispatch(success(spaceTypes.GET_CURRENT_SPACE_SUCCESS, { payload: space }));
            },
                ({ error, code }) => {
                    dispatch(failure(spaceTypes.GET_CURRENT_SPACE_FAILURE, error.toString()));
                    getMessage(`spaceById-get-${code}`).then(delay(500)).then((err) => {
                        dispatch(appActions.apiErrorMessage(err));
                    })
                }
            )

    }
}

export const filterSpaceList = (data) => {
    return dispatch => {
        dispatch(success(spaceTypes.GET_FILTERED_SUCCESS, { payload: data }));
    }
}

export const associateAssetwithSpace = (asset = null) => {
    return (dispatch, getState) => {
        dispatch(success(spaceTypes.SPACE_WITH_ASSET, { payload: asset }));
    }
}

export const spaceActions = {
    getAllSpaces,
    getSpace,
    addSpace,
    updateSpace,
    deleteSpace,
    selectSpace,
    createBreadcrumbsData,
    addSpaceAsset,
    // getSpaceAsset,
    getGivenAsset,
    getAllAsset,
    UpdateSpaceAsset,
    DeleteSpaceAsset,
    addUpdateSpaceEntities,
    addUpdateSpaceConfigs,
    emptySpaceState,
    getSpaceActivityLogs,
    updateSpaceList,
    createSubTree,
    getCurrentSpaceById,
    filterSpaceList,
    associateAssetwithSpace
}