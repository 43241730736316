import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    ActivityLog: {
        "& .MuiTimelineDot-root": {
            margin: "0px",
            // marginLeft: "8px"
        },
        "& .MuiTimelineSeparator-root": {
            // maxHeight: "50px"
        },
        "& .MuiTimelineItem-root": {
            minHeight: "50px"
        },
        "& .triggeredBy": {
            "& a": {
                color: "#00000099",
            },
            "& a:hover": {
                textDecoration: "none"
            }
        },
        "& .activityIcon": {
            filter: "invert(106%) sepia(0%) saturate(0%) hue-rotate(258deg) brightness(205%) contrast(151%)"
        },
        "& .activityLogContainer": {
            borderStyle: 'solid',
            borderRadius: '4px',
            borderWidth: '1px',
            borderColor: '#b5b5ba'
        }
    }
});
