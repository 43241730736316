import { API_URL } from '../config/appConfig'
import { API } from '../services'
import { handleResponse } from '../helpers';

const getAreaDetails = (areaId) => {
    return API.get(`${API_URL.device}/areas/${areaId}`, "getArea", {}).then(handleResponse);
}

const getAreasByControllerId = (controllerId) => {
    return API.get(`${API_URL.device}/areas/controller/${controllerId}`, `getAreasByControllerId/${controllerId}`, {}).then(handleResponse);
}

const getAllAreasBySpace = (spaceId) => {
    return API.get(`${API_URL.device}/areas/space/${spaceId}`, `getAllAreasBySpace/${spaceId}`, {}).then(handleResponse);
}

const getAreaDevicesDetails = async (payload) => {
    return API.get(`${API_URL.device}/devices`, "getAreaDevicesDetails", payload, {}, {headers:{'Content-Type':'application/json'}}).then(handleResponse);
}

const createArea = (gatewayId,payload) => { //This is not correct we need to send gateway id here
    return API.post(`${API_URL.device}/areas/${gatewayId}`, "createArea", payload).then((res) => {
        return handleResponse(res, {});
    });
}

const editArea = (areaId,payload)=> {
    return API.put(`${API_URL.device}/areas/${areaId}`, "editArea", payload).then((res) => {
        return handleResponse(res, {});
    });
}

const deleteArea = (areaId)=> {
    return API.delete(`${API_URL.device}/areas/${areaId}`, "deleteArea", {}).then((res) => {
        return handleResponse(res, {});
    });
}

const syncById = (id) => {
    return API.put(`${API_URL.device}/areas/${id}/areasync`, `areaSyncById/${id}/areasync`, {}).then((res) => {
        return handleResponse(res, {});
    });
}

export const areaService = {
    getAreaDetails,
    getAreasByControllerId,
    getAllAreasBySpace,
    getAreaDevicesDetails,
    createArea,
    editArea,
    deleteArea,
    syncById
};