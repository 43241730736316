/***
 *
 * Controller class for user.
 * @file GroupConfirmation.js
 * @description GroupConfirmation component
 * @author Shubham Singh
 * @since 12 Oct 2021
 * 
 */

import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import './GroupConfirmation.scss';
import StyledTabs from '../../components/StyledTabs';
import { Box } from '@mui/material';
import CustomTable from '../../components/CustomTable';
import CustomAccordion from "../../components/CustomAccordion";
import { useSelector } from "react-redux";
import { formatedDate } from '../../helpers';
import ItemView from '../../components/ItemView';

const GroupConfirmation = (props) => {
  const todaysDate = formatedDate({ date: new Date(), format: "dd MMMM yyyy" });
  // const [deviceTypes] = useState('');
  const [tabTypes] = useState([]);
  const selectedSpace = useSelector((state) => state?.spaces?.selectedSpace.spaceId);
  const [data] = useState(() => {
    if (props.comingData) {
      return {
        "spaceId": selectedSpace,
        "name": "MyGroup12",
        "deviceIds": props.comingData.map((e) => e && e.deviceId),
        "deviceDetails": props.comingData,
        "deviceCount":props.comingData?.length,
        "dateOfCreation":todaysDate
      }
    } else {
      return {
        "spaceId": "11",
        "name": "MyGroup12",
        "deviceIds": ["P0gx", "PnG8"],
        "deviceDetails": [{ "id": "111", "name": "device1", "category": "Light", "space": "ODC2" }, { "id": "112", "name": "device2", "category": "Sensor", "space": "MR1" }]
      }
    }
  });

  useEffect(() => {
    let payload = {
      spaceId: data.spaceId,
      name: data.name,
      deviceIds: data.deviceIds
    }
    props.settingUpData(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.comingData])

  const [tableData, setTableData] = useState(() => {
    let filteredData = data.deviceDetails.filter((item) => item && item.category && item.category.includes('Light') && !item.category.includes('Controller') && item.category !== 'Aggregator');
    filteredData.forEach((e) => e.deviceType = 'Light');
    return filteredData;
  })


  const filterDataFunc = (e, index) => {
    let type = (index === 0 ? 'Light' : (index === 1 ? 'Sensor' : (index === 2 ? 'Switch' : 'Reciever')));
    let filteredData = data.deviceDetails.filter((item) => item && item.category === type);
    filteredData.forEach((e) => e.deviceType = type);
    setTableData(filteredData);
    let payload = {
      spaceId: data.spaceId,
      name: data.name,
      deviceIds: data.deviceIds
    }
    props.settingUpData(payload);
  }
  return (
    <div className="GroupConfirmation" data-testid="GroupConfirmation">
      <Box sx={{ width: '100%', py: 1 }}>
        <Box className='groupSummaryContent' sx={{ mb: 2 }}>
          <CustomAccordion title="Group Summary">
            <ItemView
            data={data}
            headers={{
              deviceTypes: { label: "device types" },
              deviceCount: { label: "total devices" },
              dateOfCreation:{label:"date of creation"}
            }}
            gridSize={{sm:4,xs:6}}
            ></ItemView>
          </CustomAccordion>
        </Box>
        {tabTypes.length > 0 && <Box sx={{ py: 1, mb: 2 }}>
          <StyledTabs tabs={tabTypes} onClick={(event, index) => { filterDataFunc(event, index) }} />
        </Box>}
        <CustomTable headerList={{ deviceType: { label: 'Device Type' }, name: { label: 'Device Name' } }} sorting={true} cellMatrix={tableData} enableScrollToTop={false} />
      </Box>
    </div>
  )
};

GroupConfirmation.propTypes = {};

GroupConfirmation.defaultProps = {};

export default GroupConfirmation;
