import { canvasTypes } from "../types";
const IMAGE_CANVAS = "image_canvas";

const layout = localStorage.getItem(IMAGE_CANVAS);
const initialState = {

    shapes: [],
    layout: layout ? layout : '/devices/layout3.png',
    selectedShape: null,
    selectOneShape: false,
    zoomfactor: 1,
    zoomType: '',
    highlightgroup: null,
    modalState: false,
    zoom: false,
    flickerDevice: null,
    modalSpace: {},
    observeChange: null,
    spaceLayouts: [],
    getAllGroups: [],
};

export function canvas(state = initialState, action) {
    switch (action.type) {
        case canvasTypes.ADD_SHAPE:
            // console.log('addShape===>', action.payload);
            return {
                ...state,
                shapes: state.shapes.find(sl => sl?.uniq_id === action?.payload?.uniq_id) ? [...state.shapes] : [...state.shapes, action?.payload],

            };
        case canvasTypes.CONSTRUCT_POLYGON:
            const filteredArr = state?.shapes?.filter((obj) => obj?.uniq_id !== action?.payload.uniq_id);
            return {
                ...state,
                shapes: [...filteredArr, action?.payload],
            }
        case canvasTypes.SELECTED_SHAPE:
            return {
                ...state,
                selectedShape: action?.payload ? state.shapes.find(shape => shape?.uniq_id === action?.payload) : {}
            }

        case canvasTypes.UPDATE_SHAPE:
            let findShape = state?.shapes.filter(s => s?.uniq_id !== action?.payload?.uniq_id);
            return {
                ...state,
                shapes: [...findShape, action?.payload],
                // selectedShape: action?.payload,
                observeChange: 'SHAPE_UPDATED'
            }
        case canvasTypes.REPLACE_LAYOUT:
            let findImage = state?.shapes.filter(s => s?.uniq_id !== action?.payload?.uniq_id);
            return {
                ...state,
                spaceLayouts: [...findImage, action?.payload],
                observeChange: 'IMAGE_REPLACED'
            }

        case canvasTypes.REMOVE_SHAPE:
            console.log('shape removed::::', action?.payload);
            return {
                ...state,
                shapes: state.shapes.filter(shape => shape?.uniq_id !== action.payload?.uniq_id),
                observeChange: 'SHAPE_REMOVED'
            };
        case canvasTypes.REMOVE_SHAPES:
            return {
                ...state,
                // shapes: state?.shapes.filter(s=>s.shapeType === 'layoutImage'),
                shapes: action?.payload !== null ? state.shapes.filter(shape => (shape?.shapeType === 'layoutImage' || shape?.shapeType === 'shape' && shape?.spaceId !== action.payload?.spaceId)) : [],
                selectOneShape: false,
                selectedShape: [],

            };
        case canvasTypes.CANVAS_PROFILE:
            return {
                ...state,
                layout: action.payload,
            }

        case canvasTypes.ZOOM_IN:
            let scalein = state?.zoomfactor + 0.2 >= 2 ? 2 : state?.zoomfactor + 0.2;
            return {
                ...state,
                zoomfactor: Math.round(scalein * 10) / 10,
                zoom: true,
                zoomType: 'zoomIn'
            }
        case canvasTypes.ZOOM_OUT:
            let scaleout = state?.zoomfactor - 0.2 <= 1 ? 1 : state?.zoomfactor - 0.2;
            return {
                ...state,
                zoomfactor: Math.round(scaleout * 10) / 10,
                zoom: false,
                zoomType: 'zoomOut'
            }

        case canvasTypes.HIGHLIGHT_GROUPID:
            return {
                ...state,
                highlightgroup: action?.payload
            }
        case canvasTypes.MODAL_STATE:
            return {
                ...state,
                modalState: action?.payload
            }

        case canvasTypes.FLICKER_DEVICE:
            return {
                ...state,
                flickerDevice: action?.payload
            }

        case canvasTypes.MODAL_SPACE:
            return {
                ...state,
                modalSpace: action?.payload
            }
        case canvasTypes.OBSERVE_CHANGE:
            return {
                ...state,
                observeChange: action?.payload
            }
        case canvasTypes.ADD_LAYOUT:
            return {
                ...state,
                spaceLayouts: [...state.spaceLayouts, action?.payload]
            }

        case canvasTypes.SPACE_LAYOUTS:
            const filterArray = state?.spaceLayouts.filter(sl => action?.spacelist?.find(s => s?.spaceId === sl?.spaceId)) || [...state?.spaceLayouts];
            return {
                ...state,
                spaceLayouts: filterArray.find(sl => sl?.spaceId === action?.payload?.spaceId) ? [...filterArray] : [...filterArray, action?.payload]
            }

        case canvasTypes.GETALL_GROUPLIST:
            return {
                ...state,
                getAllGroups: action?.payload
            }

        default:
            return state
    }
}