import { API } from ".";
import { handleResponse } from "../helpers";
import { API_URL } from "../config/appConfig";

const accessToken = localStorage.getItem('miraie')?.accessToken;

const LoginTenant = async (username, password) => {
  return API.post(`${API_URL.tmsUser}/system/auth/login`, "Tenant login", {
    username,
    password
  })
    .then((res)=>handleResponse(res, true))
    .then(({data, status}) => {
        let user = JSON.parse(JSON.stringify(data));
        delete user?.accessToken;
        delete user?.refreshToken;

        const miraieStr = localStorage.getItem("miraie");
        let auth = miraieStr?JSON.parse(miraieStr):{ app: { theme: "light" }};
        auth["accessToken"] = (user?.accessToken ? user?.accessToken : (user?.accesstoken ? user?.accesstoken: null));
        auth["refreshToken"] = (user?.refreshToken ? user?.refreshToken : (user?.refreshtoken ? user?.refreshtoken: null));
        auth["user"] = user;

        // localStorage.setItem('miraie', JSON.stringify(miraie));
        // console.log('looocal',localStorage.getItem("miraie"),res);
        return {auth, code: status};
    });
};

const LogoutTenant = () => {
  localStorage.clear();
  sessionStorage.clear();
  if(!window.location.href.toString().substring('/login')) /// if already at /login, no need to redirect again
  {
  window.location.href="/";
  }
};

// const registerTenant = async (tenant) => {
//   return API.post(`${API_URL.tenant_mock1}/tenant`, "register-tenant", {
//     ...tenant,
//     clientId: clientId,
//   }).then(handleResponse);
// };

// const changePassword = async (user) => {
//     return API.post(`/users/register`, "changePassword", {...user, clientId: clientId}).then(handleResponse);
// }


const getAllTenants = () => {
  return API.get(`${API_URL.tenant}/tenants`, "getAllTenants", {}).then((res)=>{
    return handleResponse(res,{});
  });
};

const getTenantById = async (id) => {
  return API.get(
    `${API_URL.tenant}/tenant/${id}`,
    "getTenantById",
    {}
  ).then((res)=>{
    return handleResponse(res,{});
  });
};

const getTenantByName = async (tenantName) => {
  return API.get(
    `${API_URL.tenant}/tenant/${tenantName}`,
    "getTenantByName",
    {}
  ).then((res)=>{
    return handleResponse(res,{});
  });
};

const getTenantWebconfig = async (tenantName) => {
  return API.get(
    `${API_URL.tenant}/tenant/${tenantName}/webconfig`,
    "getTenantWebconfig",
    {}
  ).then((res)=>{
    return handleResponse(res,{});
  });
};

const createTenant = async (tenant) => {
  return API.post(`${API_URL.tenant}/tenant`,'createTenant', {...tenant}).then((res)=>{
    return handleResponse(res,{});
  });
};

const updateTenant = async (id, tenant) => {
  return API.put(`${API_URL.tenant}/tenant/${id}`, "updateTenant", {
    ...tenant
  }).then((res)=>{
    return handleResponse(res,{});
  });
};

const updateTenantWebConfig = async (tenantName, payload) => {
  return API.put(`${API_URL.tenant}/tenant/${tenantName}/webconfig`, "updateTenantWebConfig", {
    ...payload
  }).then((res)=>{
    return handleResponse(res,{});
  });
};

const deleteTenant = (tenantName) => {
  return API.delete(`${API_URL.tenant}/tenant/${tenantName}`,{}).then((res)=>{
    return handleResponse(res,{});
  });
}
const getSelfProfile = (token=null) => {
  return API.get(`${API_URL.tmsUser}/system/user/profile`, "getUserProfile", null, null, {headers: {'Authorization': 'Bearer ' + token}}).then(res=>handleResponse(res,true));;
}

const enableTenant = (tenantName)=>{
  return API.put(`${API_URL.tenant}/tenant/${tenantName}/enable`, "enableTenant", {}).then((res)=>{
    return handleResponse(res,{});
  });
}

const disableTenant = (tenantName)=>{
  return API.put(`${API_URL.tenant}/tenant/${tenantName}/disable`, "disableTenant", {}).then((res)=>{
    return handleResponse(res,{});
  });
}

// prefixed function name with underscore because delete is a reserved word in javascript

export const tenantService = {
  LoginTenant,
  LogoutTenant,
   // registerTenant,
  getAllTenants,
  createTenant,
  getTenantById,
  updateTenant,
  deleteTenant,
  getSelfProfile,
  getTenantByName,
  updateTenantWebConfig,
  getTenantWebconfig,
  enableTenant,
  disableTenant
};
