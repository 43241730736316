/***
 *
 * Controller class for user.
 * @file Tenants.js
 * @description Tenants component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import { Box } from '@mui/material';
import React from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './Tenants.style.js';
import TenantList from '../../components/TenantList';
import AddEditTenant from '../../components/AddEditTenant';
import TenantDetails from '../../components/TenantDetails/index.js';
import { useQuery } from '../../hooks';
import { useDispatch, useSelector } from 'react-redux';


const Tenants = ({ ...props }) => {
  const classes = useStyles();
  const query = useQuery();
  const tname = query.get("name") || null;
  const operation = query.get('op') || null;

  return (
    <Box className={classes?.Tenants || ""} data-testid="Tenants">
      {
        (() => {
          switch (operation) {
            case 'edit':
              return (
                <AddEditTenant tId={tname} operation={operation} {...props} />
              )
            case 'view':
            case "viewGateways":
              return (<TenantDetails tId={tname} operation={operation} {...props} />);
            case 'create':
              return (
                <AddEditTenant operation={operation} {...props} />
              )
            default:
              return (<TenantList />)
          }
        })()
      }
    </Box>
  )
};

Tenants.propTypes = {};

Tenants.defaultProps = {};

export default Tenants;
