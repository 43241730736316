/***
 *
 * Controller class for user.
 * @file Permissions.js
 * @description Permissions component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../../redux/actions';
import StyledChip from '../../StyledChip';
import StyledScopeTree from '../../StyledScopeTree'
import './Permissions.scss';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import PageSetion from '../../PageSetion';
import _ from 'lodash';
import CustomCheckBox from '../../CustomCheckBox';
import { getSpaces } from '../../../helpers';
import CustomTypography from '../../CustomTypography';
import StyledPopupWrapper from '../../StyledPopupWrapper';
import { customsx } from './Permissions.style';
import StyledButton from '../../StyledButton';
import { formatString } from '../../../services';

const scopeTemplate = {
  spaces: {
    title: "Spaces",
    type: "spaces",
    allow: [],
    deny: []
  },
  deviceCategories: {
    title: "Device Categories",
    type: "deviceCategories",
    allow: []
  },
  // ["devices"]: {
  //   title: "Devices",
  //   type: 'devices',
  //   allow: []
  // }
}


const Permissions = ({ setUserScope, userId, page, empty = "Add", addmore = true }) => {
  const dispatch = useDispatch();
  const [popoverAnchor, setPopoverAnchor] = useState(false);
  const [deviceList,] = useState([]);
  const [popoverAnchorDevices, setPopoverAnchorDevices] = useState(false);
  const [popoverAnchorDeviceCategory, setPopoverAnchorDeviceCategory] = useState(false);
  const [popoverType, setPopOverType] = useState('');
  const [spacevalue, setSpaceValue] = useState({});
  const [devicesValue, setDevicesValue] = useState([]);
  const [deviceCategoryValue, setDeviceCategoryValue] = useState([]);
  const [setterValue, setSetterValue] = useState({})

  const spaces = useSelector(state => state?.spaces?.list);
  const deviceCategories = useSelector(state => state?.devices?.deviceCategories?.map(c => ({ name: c?.label })));
  const getUserScope = useSelector(state => state?.users?.userScope);
  const [uScope, setuScope] = useState(scopeTemplate);

  const handleClick = (e, type) => {
    setPopOverType(type)
    if (type === 'spaces') {
      setPopoverAnchor(true);
    }
    else if (type === 'deviceCategories') {
      setPopoverAnchorDeviceCategory(true);
    }
    else {
      setPopoverAnchorDevices(true);
    }
  }


  useEffect(() => {
    if (userId && page === 'view') {
      dispatch(userActions.getUserScope(userId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, page])


  // useEffect(() => {
  //   if (_selectedSpace?.spaceId) {
  //     dispatch(deviceActions.getDevicesBySpaceId(_selectedSpace?.spaceId));
  //   }
  // }, []);

  // useEffect(() => {
  //   if (!_.isEmpty(devices)) {
  //     setDeviceList([...devices]);
  //   }
  // }, [devices])


  useEffect(() => {

    setDevicesValue(!_.isEmpty(getUserScope?.devices) && Array.isArray(getUserScope?.devices) ? [...getUserScope?.devices] : []);

    setDeviceCategoryValue(!_.isEmpty(getUserScope?.deviceCategories?.allow) && Array.isArray(getUserScope?.deviceCategories?.allow) ? [...getUserScope?.deviceCategories?.allow] : []);

    setSpaceValue(!_.isEmpty(getUserScope?.spaces) ? { ...getUserScope?.spaces } : {});


  }, [getUserScope, page])


  //// space useEffect ///////
  // useEffect(() => {   
  //   let SPACE=[];
  //   if(!_.isEmpty(spacevalue)){
  //     const {mapObj}=  getSpaces(spaces[0]) ;
  //     spacevalue.forEach(sItem => {
  //       if(mapObj[sItem]) {
  //         const {parentsId, hasChildren} = mapObj[sItem];            
  //         if(!spaceMap.has(parentsId)) {
  //           let array = []; // spaceMap.get(parentsId || sItem)
  //           array.push(sItem)
  //           spaceMap.set(parentsId || sItem, array);
  //         }
  //         else {
  //           let filledArray= spaceMap.get(parentsId) || [];
  //           filledArray.push(sItem)
  //           spaceMap.set(parentsId, filledArray);
  //         }

  //       }
  //     })

  //     for (let [key,val] of spaceMap.entries()) {
  //       let obj={};     
  //       let parent= mapObj[key]['parentsId'];
  //       obj['parentSpace']= !_.isEmpty(parent) ? mapObj[parent]['nodeData'] : '';
  //       obj['currentSpace']= mapObj[key]['nodeData'];
  //       obj['childrenSpaces']= [...val.map(i=> mapObj[i]['nodeData'])];
  //       SPACE.push(obj);
  //       console.log('objjjjjj===>',parent, mapObj, obj);
  //     }    
  //   }
  //   let obj= uScope;
  //   obj['spaces']['allow']=SPACE
  //   setuScope({...obj});
  // }, [spacevalue]);



  useEffect(() => {
    if (spacevalue) {
      let obj = uScope;
      obj['spaces'] = {
        ...obj['spaces'],
        allow: !_.isEmpty(spacevalue?.allow) ? spacevalue?.allow : [],
        deny: !_.isEmpty(spacevalue?.deny) ? spacevalue?.deny : [],
      }
      setuScope({ ...obj });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spacevalue])

  // devices useEffect//////
  useEffect(() => {
    if (devicesValue) {
      // let DEVICES = [];
      // DEVICES = deviceList.filter(d => devicesValue.includes(d?.deviceId));
      let obj = uScope;
      // obj['devices']['allow'] = DEVICES
      setuScope({ ...obj });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devicesValue])

  ///// device Category value
  useEffect(() => {
    if (deviceCategoryValue) {
      let DEVICESCTG = [];
      DEVICESCTG = deviceCategories.filter(d => deviceCategoryValue.includes(d?.name));
      let obj = uScope;
      obj['deviceCategories']['allow'] = DEVICESCTG
      setuScope({ ...obj });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceCategoryValue])


  ///// to make user scope object payload
  useEffect(() => {
    if (uScope) {

      setUserScope({
        ...uScope,
        "spaces": {
          "allow": !_.isEmpty(spacevalue?.allow) ? spacevalue?.allow : [],
          "deny": !_.isEmpty(spacevalue?.deny) ? spacevalue?.deny : []
        },
        "deviceCategories": {
          "allow": !_.isEmpty(deviceCategoryValue) ? [...deviceCategoryValue] : [],
          "deny": []
        },
        "devices": !_.isEmpty(devicesValue) ? devicesValue : []
      })
    }
    console.log("uScope", uScope);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uScope, spacevalue, page])


  const handleDelete = (item, type, state = null) => {
    // if (type === 'spaces') { /// old
    //   setSetterValue({...setterValue, value: setterValue.value.filter(val => val !== item?.spaceId)});
    //   setSpaceValue(spacevalue.filter(s => s !== item?.spaceId));
    // }

    if (type === 'spaces') {
      // setSetterValue({...setterValue, value:{...setterValue['spaces'], [state]: setterValue['spaces'][state].value.filter(val => val !== item?.spaceId)}});
      setSpaceValue({ ...spacevalue, [state]: spacevalue[state].filter(s => s !== item?.spaceId) });
      setSetterValue({ value: { ...spacevalue, [state]: spacevalue[state].filter(s => s !== item?.spaceId) }, type: 'spaces' })
    }
    else if (type === 'spaceParent') {
      let childSpaces = item?.Spaces?.map(cs => cs?.spaceId);
      setSpaceValue([...spacevalue?.filter(s => !childSpaces?.includes(s)).filter(s => s !== item?.spaceId)])
    }
    else if (type === 'deviceCategories') {
      setSetterValue({ ...setterValue, value: setterValue?.value?.filter(val => val !== item?.name) });
      setDeviceCategoryValue([...deviceCategoryValue?.filter(d => d !== item?.name)]);
    }
    else {
      setSetterValue({ ...setterValue, value: setterValue.value.filter(val => val !== item?.deviceId) });
      setDevicesValue([...devicesValue.filter(d => d !== item?.deviceId)]);
    }
  }

  const handleClickSave = (e) => {
    if (setterValue?.type === 'spaces') {
      setSpaceValue(setterValue?.value);
    }
    else if (setterValue?.type === 'devices') {
      setDevicesValue(setterValue?.value);
    }
    else {
      setDeviceCategoryValue([...setterValue?.value]);
    }

    setPopoverAnchor(false);
    setPopoverAnchorDeviceCategory(false);
    setPopoverAnchorDevices(false);
  }

  const handleCancel = () => {
    setPopoverAnchor(false);
    setPopoverAnchorDeviceCategory(false);
    setPopoverAnchorDevices(false);

  }

  const getPathString = (space) => {
    const path = [...space?.Path?.split('.'), space?.spaceId];
    let str = [];
    const { mapObj } = getSpaces(spaces[0]);
    if (Array.isArray(path) && path?.length > 1) {
      path?.forEach(s => {
        if (s !== '')
          str.push(mapObj[s]?.nodeData?.name)
      })
      return str?.join(' / ');
    }

  }


  return (
    <Grid container className="Permissions" data-testid="Permissions" spacing={1}>
      <Grid item xs={12}>

        {Object.keys(uScope).map((key, index) =>
        (
          <PageSetion key={"scope-" + index} title={uScope[key]["title"]} component={
            <>
              {addmore && <StyledButton disabled={page === 'view'} className='AddMore' color={page === 'view' ? 'secondary' : 'primary'} onClick={(e) => { handleClick(e, uScope[key]['type']) }}>+</StyledButton>}
            </>
          }>
            {_.isEmpty(uScope[key]['allow']) && `${empty} ${(formatString(uScope[key]['type'], "wordBreak"))?.toLowerCase()}`}
            <Grid container item xs={12}>
              {
                (() => {
                  if (key === "spaces") {
                    return (
                      <>
                        {!_.isEmpty(spacevalue) &&
                          <Grid container key={'spaces tree'} item xs={12}>
                            {
                              !_.isEmpty(spacevalue?.allow) && <Grid xs={12}  >
                                <CustomTypography content="Allow" />
                                <Box display={'flex'} sx={{ flexFlow: 'wrap' }}>
                                  {
                                    spacevalue?.allow?.map((space) => {
                                      const getSpace = spaces?.find(s => s?.spaceId === space);
                                      return <> {getSpace &&
                                        <StyledChip
                                          variant="outlined"
                                          color="primary"
                                          label={getPathString(getSpace)}
                                          sx={customsx.StyledChip}
                                          deleteIcon={<DeleteIcon style={{ height: '15px' }} />}
                                          onDelete={page !== 'view' ? () => { handleDelete(getSpace, 'spaces', 'allow') } : null}
                                        />
                                      }
                                      </>
                                    }
                                    )
                                  }
                                </Box>
                              </Grid>
                            }
                            {
                              !_.isEmpty(spacevalue?.deny) && <Grid xs={12} sx={{ mt: 1 }}>
                                <CustomTypography content="Deny" />
                                <Box display={'flex'} sx={{ flexFlow: 'wrap' }} >
                                  {
                                    !_.isEmpty(spacevalue?.deny) && spacevalue?.deny?.map((space) => {
                                      const getSpace = spaces?.find(s => s?.spaceId === space);
                                      return <>{getSpace &&
                                        <StyledChip
                                          variant="outlined"
                                          color="error"
                                          label={getPathString(getSpace)}
                                          sx={customsx.StyledChip}
                                          deleteIcon={<DeleteIcon style={{ height: '15px' }} />}
                                          onDelete={page !== 'view' ? () => { handleDelete(getSpace, 'spaces', 'deny') } : null}
                                        />}
                                      </>
                                    }
                                    )
                                  }
                                </Box>
                              </Grid>
                            }
                          </Grid>
                        }
                      </>
                    )
                  }
                  else if (key === 'deviceCategories') {
                    return <>
                      {Array.isArray(uScope[key]["allow"]) && uScope[key]["allow"].map((pr, prIndex) => {
                        return (
                          <Box sx={{ display: 'flex', flexFlow: 'wrap' }} >
                            {
                              addmore && page === 'edit' ?
                                <StyledChip
                                  variant="outlined"
                                  color="primary"
                                  label={pr?.name || ""}
                                  sx={customsx.StyledChip}
                                  deleteIcon={<DeleteIcon style={{ height: '15px' }} />}
                                  onDelete={() => { handleDelete(pr, 'deviceCategories') }} />
                                :
                                <StyledChip
                                  variant="outlined"
                                  color="primary"
                                  label={pr?.name || ""}
                                  sx={customsx.StyledChip}

                                />
                            }
                          </Box>
                        )
                      })
                      }
                    </>
                  }
                  else {
                    return <>
                      {Array.isArray(uScope[key]["allow"]) && uScope[key]["allow"].map((pr, prIndex) => {
                        return (
                          <Grid container key={'dvcs' + prIndex} item xs={12}>
                            <Grid item xs={12}>
                              {
                                addmore && page === 'edit' ?
                                  <StyledChip
                                    variant="outlined"
                                    color="primary"
                                    label={pr?.name || pr?.deviceId}
                                    sx={customsx.StyledChip}
                                    deleteIcon={<DeleteIcon style={{ height: '15px' }} />}
                                    onDelete={() => { handleDelete(pr, 'devices') }} />
                                  :
                                  <StyledChip
                                    variant="outlined"
                                    color="primary"
                                    label={pr?.name || pr?.deviceId}
                                    sx={customsx.StyledChip}
                                  />
                              }
                            </Grid>
                          </Grid>
                        )
                      })
                      }
                    </>
                  }
                })()
              }
            </Grid>
          </PageSetion>
        )
        )}
      </Grid>

      <StyledPopupWrapper
        customsx={{ box: { maxWidth: '400px', minWidth: '300px', minHeight: '300px', maxHeight: '400px' } }}
        open={popoverType === 'spaces' ? popoverAnchor : popoverType === 'deviceCategories' ? popoverAnchorDeviceCategory : popoverType === 'devices' ? popoverAnchorDevices : false}>
        <Box >
          {
            (() => {
              switch (popoverType) {
                case 'spaces':
                  return (
                    <>
                      {/* {!_.isEmpty(spaces) && <StyledTree type='checkbox' selecticon={true} idKey={"spaceId"} selectedItems={spacevalue} getParentMap={(value)=>{setParentMap(value)}} childrenKey={"Spaces"} data={spaces[0]} setterFunction={(value)=>{setSetterValue({value:value, type:'spaces'})}}  />} */}
                      {!_.isEmpty(spaces) && <StyledScopeTree selecticon={true} scopeData={spacevalue} idKey={"spaceId"} spaceList={spaces} childrenKey={"Spaces"} data={spaces[0]} setPayload={(value) => { setSetterValue({ value: value, type: 'spaces' }) }} />}
                    </>
                  )
                case 'devices':
                  return (
                    <>
                      {Array.isArray(deviceList) && <CustomCheckBox data={deviceList} selectedItems={devicesValue} label={'name'} label2={'deviceId'} idKey={'deviceId'} setterFunction={(value) => { setSetterValue({ value: value, type: 'devices' }) }} />}
                    </>
                  )
                default:
                  return (
                    <>
                      {Array.isArray(deviceCategories) && <CustomCheckBox data={deviceCategories} customsx={{ outerdiv: { minHeight: '300px' } }} selectedItems={deviceCategoryValue} label={'name'} idKey={'name'} setterFunction={(value) => { setSetterValue({ value: value, type: 'deviceCategories' }) }} />}
                    </>
                  )
              }
            })()
          }
        </Box>
        <Grid item container xs={12} md={12} sx={{ mt: 1.5 }} display={'flex'} justifyContent="flex-end">
          <StyledButton variant='outlined' sx={{ mr: '10px' }} onClick={handleCancel}>Cancel</StyledButton>
          <StyledButton variant='primary' onClick={handleClickSave}>Save</StyledButton>
        </Grid>
      </StyledPopupWrapper>
    </Grid>
  )
};

Permissions.propTypes = {};

Permissions.defaultProps = {};

export default Permissions;
