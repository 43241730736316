import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider, ReactReduxContext } from 'react-redux';
import { store } from './redux/store';

// import i18n (needs to be bundled ;)) 
import './i18n';

const root = ReactDOM.createRoot(document.getElementById("root"));


root.render(
  // <React.StrictMode>
    <ReactReduxContext.Provider value={store} >
    
      <Provider  store={store} >
        <App />
      </Provider>
    
    </ReactReduxContext.Provider>
  // </React.StrictMode> 
  // document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
