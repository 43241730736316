/***
 *
 * Controller class for user.
 * @file StyledTree.js
 * @description StyledTree component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './StyledTree.style.js';
import './StyledTree.scss';
import { Box, Checkbox, FormControlLabel, Radio } from '@mui/material';
import { TreeView, TreeItem } from '@mui/x-tree-view'
import SearchBox from '../SearchBox/index.js';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';

const StyledTree = ({ type = 'checkbox', data, idKey, selecticon = true, childrenKey, selectedItems = null, search = true, setterFunction = () => { }, getParentMap = () => { }, customsx = { outerdiv: {} }, fontSize = 12, ...props }) => {
  const classes = useStyles()

  const [selected, setSelected] = React.useState([]);
  const [radioCheck, setRadioCheck] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const [path, setPath] = React.useState(['0']);
  const [selectedDevice, setSelectedDevice] = useState([]);
  const mapped = new Map();
  const selectedSet = React.useMemo(() => new Set(selected), [selected]);
  const [oneTimeClickedParent, setOneTimeClickedParent] = useState({});
  // const oneTimeClickedParent = new Map();


  // function mapIdtoName(node) {
  //   if (node === null) {
  //     return
  //   }
  //   mapped.set(node[idKey], node?.name);
  //   if (Array.isArray(node[childrenKey])) {
  //     for (const n of node[childrenKey]) {
  //       mapIdtoName(n)
  //     }
  //   }
  //   return;
  // }


  useEffect(() => {
    // if (selectedItems) {
    if (type === 'radio') {
      setRadioCheck(selectedItems[0]);
    }
    if (type === 'checkbox') {
      setSelected(selectedItems)
    }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems])

  const handleSearchBox = () => {
    // mapIdtoName(data);
    let array = [];
    for (const [key, val] of mapped.entries()) {
      let obj = {};
      obj[idKey] = key;
      obj['name'] = val;
      array.push(obj);
    }
    setOptions(array);
  }

  const handleOptionsChange = (e, value) => {
    if (value) {
      if (type === 'checkbox') {
        const { childNodesToToggle, path } = getChildById(data, value[idKey]);
        let array = [...selected, ...childNodesToToggle];
        console.log('selected1==>', selected);
        if (!selected.includes(undefined))
          setSelected(array);
        setPath(path);
      }
      else if (type === 'radio') {
        setRadioCheck(value[idKey]);
      }
      // console.log('options==>', value, childNodesToToggle, path);

    }
  }

  // Get all children from the current node.

  function goThroughAllNodes(node, map = {}, mapped) {
    if (!node || (node && !node[childrenKey])) {
      return null;
    }

    map[node[idKey]] = getAllChild(node, [], mapped).splice(1);

    for (let childNode of node[childrenKey]) {
      goThroughAllNodes(childNode, map);
    }

    return map;
  }

  const parentMap = React.useMemo(() => {
    return goThroughAllNodes(data, mapped);
  }, []);

  function getAllChild(childNode, collectedNodes = []) {
    if (childNode === null) return collectedNodes.reverse();

    if (!childNode?.disabled)
      collectedNodes.push(childNode[idKey]);


    if (Array.isArray(childNode[childrenKey])) {
      for (const node of childNode[childrenKey]) {
        getAllChild(node, collectedNodes);
      }
    }

    return collectedNodes;
  }

  const getChildById = (nodes, id) => {
    let array = [];
    let path = [];
    let name = [];

    // recursive DFS
    function getNodeById(node, id, parentsPath = []) {
      let result = null;

      if (node[idKey] === id) {
        return node;
      } else if (Array.isArray(node[childrenKey])) {
        for (let childNode of node[childrenKey]) {
          result = getNodeById(childNode, id, parentsPath);

          if (!!result) {
            parentsPath.unshift(node[idKey]);
            return result;
          }
        }
        return result;
      }
      return result;
    }

    const nodeToToggle = getNodeById(nodes, id, path);


    return { childNodesToToggle: getAllChild(nodeToToggle, array), path };
  };

  const getOnChange = (checked, nodeId, node) => {
    const { childNodesToToggle, path } = getChildById(data, nodeId);
    console.log("childNodesToChange", { childNodesToToggle, checked, oneTimeClickedParent });
    let array = [];
    // if (!selected.includes(undefined)) {
    // array = checked
    //   ? [...selected, ...childNodesToToggle]
    //   : selected
    //     .filter((value) => !childNodesToToggle.includes(value))
    //     .filter((value) => !path.includes(value));
    // array = array.filter((v, i) => array.indexOf(v) === i);
    // }
    let childs = childNodesToToggle.filter(value => value !== nodeId);
    if (childNodesToToggle.length === 1) {//no children
      array = checked ? [...selected, nodeId] : selected.filter(value => value !== nodeId)
    }
    else {
      //let ch=oneTimeClickedParent.has(nodeId);
      // console.log("tri-state 1--- ", typeof(oneTimeClickedParent.hasOwnProperty(nodeId)), oneTimeClickedParent.hasOwnProperty(nodeId), oneTimeClickedParent)
      if (oneTimeClickedParent.hasOwnProperty(nodeId) === true) {
        // console.log("tri-state 2--- ")
        // let nextOp = oneTimeClickedParent.get(nodeId);
        // if(nextOp===1){
        //   array = [...selected, ...childs];
        //   array  = array.filter((item, index) => array.indexOf(item) === index); //remove duplicates
        // }
        // else{
        //   array = selected.filter(value => value!==nodeId);
        // }
        // oneTimeClickedParent.delete(nodeId);
        array = [...selected, ...childs];
        array = array.filter((item, index) => array.indexOf(item) === index); //remove duplicates
        delete oneTimeClickedParent[nodeId];
      } else {
        if (!selected.includes(nodeId)) {
          array = [...selected, nodeId];
          setOneTimeClickedParent({ ...oneTimeClickedParent, [nodeId]: 1 });
          // oneTimeClickedParent.set(nodeId, 1);
        }
        else {
          array = selected.filter(value => !childNodesToToggle.includes(value));
          // oneTimeClickedParent.set(nodeId,0);
        }

        console.log("tri-state 3--- ", oneTimeClickedParent)
      }
    }

    console.log('selected2==>', selected, array, oneTimeClickedParent.hasOwnProperty(nodeId));
    setterFunction(array);
    getParentMap(parentMap);
    setSelected(array);
  }


  const renderTree = (node) => {
    // console.log('nodes are here==>', node,idKey,node[idKey]);
    if (node && node[idKey]) {
      // const allSelectedChildren = parentMap && parentMap[node[idKey]]?.every((childNodeId) => selectedSet.has(childNodeId));
      if (selectedSet.has(node[idKey]) === false && oneTimeClickedParent.hasOwnProperty(node[idKey])) {
        delete oneTimeClickedParent[node[idKey]];
      }
      const checked = selectedSet.has(node[idKey]) && !oneTimeClickedParent.hasOwnProperty(node[idKey]);
      const indeterminate = oneTimeClickedParent.hasOwnProperty(node[idKey]) || false;

      // if(allSelectedChildren){
      //   setSelected([...selected, node[idKey]])
      // }

      return (
        <>
          <TreeItem
            key={node[idKey]}
            nodeId={node[idKey]}
            sx={{
              '.MuiTreeItem-label': {
                height: selecticon ? '40px' : '30px'
              },
              '& .MuiFormControlLabel-root': {
                marginTop: selecticon ? '0rem' : '4px',
                marginLeft: selecticon ? '0rem' : '5px',
              },
              'label': {
                margin: selecticon ? '0.5rem' : '0'
              }
            }}
            label={
              <FormControlLabel
                sx={{
                  width: "100%",
                  '& .MuiRadio-root': {
                    display: selecticon ? 'inline-block' : 'none',
                    paddingRight: '10px'
                  }
                }}
                control={
                  type === 'checkbox' ? (
                    <>
                      <Checkbox
                        // checked={node?.disabled !== undefined ? !node?.disabled : checked}
                        checked={checked}
                        indeterminate={indeterminate}
                        onClick={(e) => e.stopPropagation()}
                        disabled={node?.disabled}
                        icon={node?.disabled && <DisabledByDefaultIcon sx={{ color: '#EF0000' }} />}
                        onChange={(event) => {
                          getOnChange(event.currentTarget.checked, node[idKey], node);
                          // setterFunction(node);
                        }}
                      />
                    </>
                  ) : (
                    type === 'radio' &&
                    <Radio
                      checked={node?.disabled !== undefined ? !node?.disabled : radioCheck === node[idKey]}
                      name={node?.name}
                      value={node[idKey]}
                      disabled={node?.disabled}
                      onChange={(e) => {
                        setRadioCheck(node[idKey]);
                        setterFunction(node);
                      }}
                    />
                  )
                }
                label={<span style={node?.disabled ? { color: 'rgba(0, 0, 0, 0.6)' } : { color: radioCheck === node[idKey] ? "#0f8d48" : '#202020', fontWeight: radioCheck === node[idKey] ? 700 : 400, fontSize: `${fontSize}px` }}>{node?.name}</span>}
                key={node[idKey]}
              />
            }
          >
            {node[childrenKey] && node[childrenKey].map(ch => renderTree(ch))}
          </TreeItem>

        </>
      );
    }
  };

  return (
    <>
      {search &&
        <div onClick={handleSearchBox}>
          <SearchBox data={options} autoComplete={true} onChange={handleOptionsChange} />
        </div>
      }
      {data && data[idKey] &&
        <Box id="treeBox" sx={{ minHeight: '70px', mt: 2, maxHeight: '300px', overflowY: 'scroll', ...customsx.outerdiv }} >
          <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpanded={[data[idKey]]}
            defaultExpandIcon={<ChevronRightIcon />}
          >
            {renderTree(data)}
          </TreeView>
        </Box>
      }

    </>
  )
};

StyledTree.propTypes = {};

StyledTree.defaultProps = {};

export default StyledTree;
