/***
 *
 * Controller class for user.
 * @file TenantCard.js
 * @description TenantCard component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 *
 */

import React from "react";
// import PropTypes from 'prop-types';
import { useStyles } from "./TenantCard.style.js";
import "./TenantCard.scss";
import { Box, Stack } from "@mui/material";
import Card from "@mui/material/Card";
import { Link } from 'react-router-dom';

import CustomTypography from "../CustomTypography/index.js";
import ImageWrapper from "../ImageWrapper/index.js";
import shareWeb from "../../assets/icons/web.svg";
import view from "../../assets/icons/view.svg";
import { history } from "../../helpers/history.helper.js";
import StyledButton from "../StyledButton/index.js";

const TenantCard = ({data=[],config={}}) => {
  const classes = useStyles();
  const accessToken = JSON.parse(localStorage.getItem('miraie'))?.accessToken;
  const handleView = () =>{
    history.push(`/tenants?op=${data?.op||"view"}&name=${data?.tenantName}`)
  }

  const handleWeb = () => {
    window.open(`${data?.dns}?cid=${accessToken}`||"", '_blank')
  }

  return (
    <Box className={classes?.TenantCard || ""} data-testid="TenantCard">
        <Box
          style={{
            backgroundColor: "rgba(255, 255, 255, 1)",
            minHeight: "100px",
          }}
        >
          <Card>
            <Box sx={{display:"flex", justifyContent:'space-between'}}>
              <Box sx={{ display: "flex" }}>
                  <Box sx={{ marginTop:"20px", marginLeft:"20px" }}>
                    <ImageWrapper src={data?.logo || ""} alt={"tenantLogo"} height="auto" width='100px'/>
                    <Box sx={{"marginTop":"10px"}}>
                      <CustomTypography size={12} sx={{fontSize:"0.75rem", fontWeight:"500",lineHeight:"15px", display:"inline", marginRight:"12px"}} >Tenant Name</CustomTypography>
                      <Link  to={`/tenants?op=${data?.op||"view"}&name=${data?.tenantName}`}
                        style={{
                          fontSize: "0.75rem",
                          fontWeight: "600",
                          color: "rgba(15, 141, 72, 1)",
                          // textDecoration: "underline",
                          // textDecorationColor: "rgba(15, 141, 72, 1)",
                          lineHeight:"19px"
                        }}
                      >{data?.tenantName||""}</Link>
                      <Box sx={{display:"flex"}}>
                          <CustomTypography size={12} sx={{fontSize:"0.75rem", fontWeight:"500",lineHeight:"15px", marginRight:"12px"}}>License Type</CustomTypography>
                          <CustomTypography size={12} sx={{fontSize:"0.75rem", fontWeight:"600", lineHeight:"19px"}}>{data?.license || "-"}</CustomTypography>
                      </Box>
                    </Box>
                  </Box>
              </Box>

                <Box sx={{display:"flex",marginTop:"10px", marginRight:"10px"}}>
                  <StyledButton iconButton tooltip="View Details" onClick={handleView}>
                      <ImageWrapper src={view} alt={"tenantLogo"} height="auto" width='24px'/>
                  </StyledButton>
                  <StyledButton iconButton tooltip="Portal Link" onClick={handleWeb}>
                      <ImageWrapper src={shareWeb} alt={"tenantLogo"} height="auto" width='24px'/>
                  </StyledButton>
                </Box>
            </Box>
              <Box sx={{marginLeft:'23px', marginTop:"10px", marginBottom:"20px"}}>
                <CustomTypography sx={{fontSize:"0.75rem", fontWeight:"600", lineHeight:"19px"}}>{data?.corporationName}</CustomTypography>
                <Box sx={{width:"297px", borderTop:"1px solid rgba(0, 0, 0, 0.1)", marginBottom:"10px"}}></Box>
                  <Stack spacing={0.2} direction="column" alignItems="right">
                    <Stack pr={1.5} sx={{ minWidth: "fit-content" }}>
                      <CustomTypography size={10} fontWeight={500}>
                        {`Admin Email - ${data?.adminEmail||""}`}
                      </CustomTypography>
                    </Stack>
                    <Stack pr={1.5} sx={{ minWidth: "fit-content" }}>
                      <CustomTypography size={10} fontWeight={500}>
                        {`Admin Contact - ${data?.mobile||""}`}
                      </CustomTypography>
                    </Stack>
                  </Stack>
                </Box>
          </Card>
        </Box>
      </Box>
  );
};

TenantCard.propTypes = {};

TenantCard.defaultProps = {};

export default TenantCard;
