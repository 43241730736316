/***
 *
 * Controller class for user.
 * @file StyledScopeTree.js
 * @description StyledScopeTree component
 * @author Aditya Rana
 * @since 31 Mar 2023
 * 
 */

import React from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './StyledScopeTree.style.js';
import './StyledScopeTree.scss';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import SearchBox from '../SearchBox/index.js';
import { TreeView, TreeItem } from '@mui/x-tree-view'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import { useState } from 'react';
import { useEffect } from 'react';
import _ from 'lodash';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CustomTypography from '../CustomTypography/index.js';
import StyledSpinner from '../StyledSpinner/index.js';

const parentNodeState=['','self','all','deny'];
const leafNodeState= ['','self','deny']

const StyledScopeTree = ({search=true,idKey="spaceId", scopeData=null,data=null, selecticon=true,childrenKey="Spaces", spaceList=[], setPayload=()=>{},  customsx = { outerdiv: {} }, fontSize = 12 }) => {
  const classes = useStyles();
  const [options, setOptions] = useState([]);
  const [nodeList, setNodeList] = useState([]);
  const [selectedNodes, setSelectedNodes] = useState({allow:[], deny:[]});

  useEffect(()=>{
    if(!_.isEmpty(spaceList)){
      let filteredArr=  spaceList.map(item=>{
        return{
          "name":item?.name,
          "spaceId":item?.spaceId,
          "Spaces":item?.Spaces,
          "id":item?.spaceId,
          "status":'',
          "clicks":0
        }
      });
      if(!_.isEmpty(scopeData)){
      scopeData['allow']?.forEach(al=>{
        const findNode = filteredArr?.find(n=>n?.spaceId === al);
        filteredArr = filteredArr?.filter(n=>n?.spaceId !== al);
        filteredArr.push({...findNode,"clicks":1, status:'self'});
      })
      scopeData['deny']?.forEach(al=>{
        const findNode = filteredArr?.find(n=>n?.spaceId === al);
        filteredArr = filteredArr?.filter(n=>n?.spaceId !== al);
        filteredArr.push({...findNode,"clicks":findNode?.Spaces?.length>0 ?3: 2, status:'deny'});
      })
    }

      setNodeList([...filteredArr])
    }
  },[scopeData, spaceList])

  
  useEffect(()=>{
    setSelectedNodes({
      ...selectedNodes,
      ['allow']:nodeList.filter(n=>n?.status ==='self' || n?.status === 'all')?.map(item=>item?.spaceId) || [],
      ['deny']:nodeList.filter(n=>n?.status ==='deny')?.map(item=>item?.spaceId) || [],
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[nodeList])


  useEffect(()=>{
    // console.log('selected nodes',selectedNodes);
    setPayload(selectedNodes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedNodes])

  const handleSearchBox=()=>{
    setOptions(nodeList)
  }

  const handleOptionsChange=(e, value)=>{
      if(value){
      const findNode = nodeList?.find(n=>n?.spaceId === value?.spaceId);
      let filterNode = nodeList.filter(n=>n?.spaceId !== value?.spaceId);
      setNodeList([...filterNode,{...findNode,"clicks":1,'status':'self'}])

    }
  }

  const traverseSpaces=(node, collected, nodeStatus)=>{
    if(Array.isArray(node['Spaces'])){
    for(const s of node['Spaces']){
      const findNode = nodeList?.find(n=>n.spaceId === s?.spaceId);
      let obj={
        ...findNode,
        "clicks":nodeStatus? s?.Spaces?.length>0 ? parentNodeState.indexOf(nodeStatus) : nodeStatus === 'deny' ? leafNodeState.indexOf('deny')  :  leafNodeState.indexOf('self') : 0,
        "status":nodeStatus? nodeStatus === 'deny' ?  "deny"  : s?.Spaces?.length>0 ?'all':'self' :''
      }
      collected.push(obj);    
      traverseSpaces(s, collected,nodeStatus);
    }
  }
    return collected;
  }

  const isAllSelected=(node)=>{
    if(node?.status === ''){
      return false;
    }

    if(Array.isArray(node['Spaces']) && node['Spaces'].length>0){
      console.log('in recursion');
      for(const s of node['Spaces']){    
       const findNode = nodeList?.find(n=>n.spaceId === s?.spaceId);
       let value = isAllSelected(findNode);
       if(!value) return false;
      }
    }
    return true;

  }

  const getOnChange=(nodeId, node)=>{
    const findNode = node;
    let click = findNode?.clicks + 1;
    let filterNode = nodeList.filter(n=>n?.spaceId !== nodeId);

    if(findNode?.Spaces?.length === 0 ){  /// leaf node
      const nodeStatus = leafNodeState[click%3];
      const newState={
        ...findNode,
        "clicks":click,
        "status": nodeStatus
      }
      setNodeList([...filterNode,newState]);
    }
    else{  /// parent node
      
      if(findNode?.status ==='self' && isAllSelected(findNode, false)){
        setNodeList([...filterNode,{...findNode,"clicks":3,status:'deny'}]);
      }
      else{
      const nodeStatus = parentNodeState[click%4];
      const newState={
        ...findNode,
        "clicks":click,
        "status": nodeStatus
      }
      if(nodeStatus === 'self' ){
        setNodeList([...filterNode,newState]);
      }
      else if(nodeStatus === 'all' || nodeStatus === 'deny' || nodeStatus ===''){
      let collected =  [...traverseSpaces(findNode,[], nodeStatus), {...newState}];
      filterNode = nodeList?.filter(n=> !collected?.find(nn=>nn?.spaceId === n?.spaceId ));
      filterNode= [...filterNode,...collected];
      setNodeList([...filterNode]);
      }
    }

    }
  }

  

  const renderTree = (node) => {
    if (node && node[idKey] && !_.isEmpty(nodeList)) { 
      const currentNode = nodeList?.find(n=>n?.spaceId === node?.spaceId);
      return (
        <>{ currentNode &&
          <TreeItem
            key={node[idKey]}
            nodeId={node[idKey]}
            sx={{
              '.MuiTreeItem-label': {
                height: selecticon ? '40px' : '30px'
              },
              '& .MuiFormControlLabel-root': {
                marginTop: selecticon ? '0rem' : '4px',
                marginLeft: selecticon ? '0rem' : '5px',
              },
              'label': {
                margin: selecticon ? '0.5rem' : '0'
              }
            }}
            label={
              <FormControlLabel
                sx={{
                  width: "100%",
                  '& .MuiRadio-root': {
                    display: selecticon ? 'inline-block' : 'none',
                    paddingRight: '10px'
                  }
                }}
                control={   
                    <>
                      <Checkbox
                        checked={!_.isEmpty(currentNode?.status)}
                        checkedIcon={ currentNode?.status === 'deny' ? <DisabledByDefaultIcon style={{color:'#ff4336'}} /> : <CheckBoxIcon/>}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(event) => {
                          getOnChange(currentNode[idKey], currentNode);
                        }}
                      />
                    </>
                }
                label={<CustomTypography content={currentNode?.name} color={ currentNode?.status ?  currentNode?.status === 'deny' ? '#ff4336':'#0f8d48':'#000'} />}
                key={currentNode[idKey]}
              />
            }
          >
            {node[childrenKey] && node[childrenKey].map(ch => renderTree(ch))}
          </TreeItem>
          }

        </>
      );
    }
  };

  return (
    <Box className={classes?.StyledScopeTree || ""} data-testid="StyledScopeTree">
     


     {search &&
        <div onClick={handleSearchBox}>
          <SearchBox data={options} autoComplete={true} onChange={handleOptionsChange} />
        </div>
      }
      {data && data[idKey] && !_.isEmpty(nodeList) ?
        <Box id="treeBox" sx={{ minHeight: '70px', mt: 2, maxHeight: '300px', overflowY: 'scroll', ...customsx.outerdiv }} >
          <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpanded={[data[idKey]]}
            multiSelect
            defaultExpandIcon={<ChevronRightIcon />}
          >
            {renderTree(data)}
          </TreeView>
        </Box>
        : <Box sx={{m:'100px 0',ml:'46%'}}> <StyledSpinner /> </Box>
      }
    </Box>
  )
};

StyledScopeTree.propTypes = {};

StyledScopeTree.defaultProps = {};

export default StyledScopeTree;
