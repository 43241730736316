import { makeStyles } from '@mui/styles';

export const temperatureSliderCss = { 
    width: "100px",
    "& .MuiSlider-rail": {
        background: 'linear-gradient(90deg, #FF0000 0.83%, #E9FA00 15.6%, #22FF01 32.13%, #00E5FF 46.59%, #1803FF 65.19%, #FF00E9 80.68%, #FF0030 100%)',
        height:'3px'
    },
    "& .MuiSlider-track":{
    height: '1px',
    border: 'none',
    color:'transparent'
  }
}

export const useStyles = makeStyles({
    AutomationRuleDetails: {

    },
    CustomSummaryHeader: {
        display: 'flex',
        alignItems: 'center',
        background: 'rgba(15, 141, 72, 0.05)',
        padding: '17px 20px 18px 20px',
        margin: '0',
        height: '50px',
        justifyContent: 'space-between'
    },
    CustomSummaryContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: 'rgba(15, 141, 72, 0.02)',
        padding: '30px 20px 30px 20px',
        flexWrap: 'wrap'
    },
    CustomeTableContentStyling: {
        display: 'flex',
        justifyContent: 'space-between'
    }
});
