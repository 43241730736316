export const appTypes = {
    SELECT_LEFT_MENU: "selectLeftMenu",
    SELECT_TOP_MENU: "selectTopMenu",
    THEME: "setTheme",
    API_SUCCESS: 'SUCCESS',
    API_ERROR: 'ERROR',
    API_CLEAR: 'CLEAR',
    MESSAGE_POP: 'MESSAGE_POP',
    GENERATE_OTP_REQUEST: 'GENERATE_OTP_REQUEST',
    GENERATE_OTP_SUCCESS: 'GENERATE_OTP_SUCCESS',
    GENERATE_OTP_FAILURE: 'GENERATE_OTP_FAILURE',

    VALIDATE_OTP_REQUEST: 'VALIDATE_OTP_REQUEST',
    VALIDATE_OTP_SUCCESS: 'VALIDATE_OTP_SUCCESS',
    VALIDATE_OTP_FAILURE: 'VALIDATE_OTP_FAILURE',
    FORCE_EXIT: 'FORCE_EXIT',
    // ADD_MQTT_REQUEST:"ADD_MQTT_REQUEST",
    // DELETE_MQTT_REQUEST:"DELETE_MQTT_REQUEST"
    SIDE_BAR_EXPANDED: 'SIDE_BAR_EXPANDED',
    WEB_CONFIG_REQUEST: 'WEB_CONFIG_REQUEST',
    WEB_CONFIG_SUCCESS: 'WEB_CONFIG_SUCCESS',
    WEB_CONFIG_FAILURE: 'WEB_CONFIG_FAILURE',

    CLEAR_VALIDATE_OTP: 'CLEAR_VALIDATE_OTP',
    CLEAR_OTP: 'CLEAR_OTP'

}