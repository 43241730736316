import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    Comments: {

    },
    avatar: {
        cursor: 'pointer',
        backgroundColor: "lightgray",
        color: '#fff',
        // marginRight: '10px',
        height: '35px !important',
        width: '35px !important',
    },
    commentor: { fontWeight: "600 !important", paddingRight: "15px" },
    commentDate: { fontSize: '0.75rem !important', color: "gray", fontWeight: "400" },
    commentDesc: { fontWeight: 200, fontSize: "0.9em", background: "#F9F9FE", minWidth: "95%", borderRadius: "10px", padding: "5px", minHeight: "50px", lineBreak: "anywhere" },
    cancelIcon: { cursor: "pointer", position: "absolute", top: "2px", right: "0px" },
    commentImage: { width: "25px", height: "25px" }
});
