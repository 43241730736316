import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    ChipInput: {
        alignItems:'center'
    },
    Input: {
        '& .MuiFormHelperText-root':{
            marginLeft:0
        },
        position: 'relative',
        // border: "1px solid #999999",
        overflow: "hidden",
        display: "inline-block",
        marginBottom: "10px",
        // height: "32px",
        // '& .inputField': {
        //     boxShadow: "none",
        //     border: 0, 
        //     padding: "7px 25px 7px 15px",
        //     fontSize: '12px',
        //     width: "100%"
        // },
        // '& img': {
        //     position: "absolute",
        //     top: "30%",
        //     right: "10px"
        // }
    },
    AutoComplete: {
        width: "100%",
        '& .MuiOutlinedInput-root': {
            padding: "0 !important",
            '& .MuiAutocomplete-input': {
                padding: (theme?.padding?.select || "8px 10px") + "!important",
                fontSize: theme?.fontSize?.select || "0.875rem",
                lineHeight: theme?.lineHeight?.select || "102%",
                height: theme?.height?.select || "25px",
            },            
            '& fieldset': {
                // border: "10px #333 solid",
                top: 0,
                '& legend': {
                    border: "10px #333 solid",
                    display: "none"
                }
            }
        }
    }
}));
