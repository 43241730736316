/***
 *
 * Controller class for user.
 * @file CustomDropDown.js
 * @description CustomDropDown component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 *
 */

import React from "react";
// import PropTypes from 'prop-types';
import { sx, useStyles } from "./CustomDropDown.style.js";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import _ from "lodash";
import ErrorMessage from "../ErrorMessage/index.js";
import StyledButton from "../StyledButton/index.js";

const CustomDropDown = ({
  value,
  label,
  list,
  onChange,
  disabled = false,
  name,
  customsx = {},
  customdropsx = {},
  defaultValue = "",
  onOpen,
  error,
  message = null,
  isNoneAsItem = true,
  createNewButton = null,
  rounded = false,
  ...props
}) => {
  const menuProps = {
    MenuListProps: {
      sx: { ...sx.menu, ...(customsx?.hasOwnProperty("menuList") && customsx?.menuList) },
    },
    PaperProps: {
      sx: sx.paper,
    },
  };
  const onDropdownOpen = () => {
    if (onOpen) {
      onOpen();
    }
  };

  const classes = useStyles();

  return (
    <>
      <FormControl
        data-testid="CustomDropDown"
        fullWidth
        disabled={disabled}
        error={error}
        sx={customdropsx}
      >
        <InputLabel>
          {label}
        </InputLabel>
        <Select
          fullWidth
          className={classes?.CustomDropDown || ""}
          {...props}
          error={error}
          sx={{ borderRadius: rounded ? "20px" : "4px", ...customsx }}
          value={value || defaultValue || ""}
          name={name}
          //  renderValue={label}
          onChange={onChange}
          onOpen={(event) => {
            onDropdownOpen();
          }}
          onClose={(event) => null}
          displayEmpty
          //  IconComponent={DropDownCaret}
          MenuProps={menuProps}
          inputProps={{ sx: sx.input }}
          variant="outlined"
        >
          {/* {console.log("vvvvvvv",createNewButton,value)} */}
          {createNewButton &&
            <MenuItem sx={sx.menuItem} style={{ justifyContent: "center" }}>
              <StyledButton color="secondary" size="large" sx={{ width: "100%" }} onClick={(event) => createNewButton?.onClick(event)}>
                Create New
              </StyledButton>
            </MenuItem>
          }
          {isNoneAsItem &&
            <MenuItem sx={sx.menuItem} value="">
              <em>None</em>
            </MenuItem>
          }

          {_.isArray(list) && list?.map((item, index) => {
            return (
              <MenuItem
                disabled={item?.isDisabled || false}
                key={"sel--" + index}
                sx={sx.menuItem}
                index={index}
                value={item?.id || ""}
              >
                {item?.label || ""}
              </MenuItem>
            );
          })}
        </Select>
        {error && <ErrorMessage message={message} />}
        {/* <TextField
           id="outlined-select-currency"
           select
           label="Select"
           value={value}
           onChange={onChange}
           helperText="Please select your currency"
         >
           {list.map((option) => (
             <MenuItem key={option.value} value={option.value}>
               {option.label}
             </MenuItem>
           ))}
         </TextField> */}
      </FormControl>
    </>
  );
};

CustomDropDown.propTypes = {};

CustomDropDown.defaultProps = {};

export default CustomDropDown;
