/***
 *
 * Controller class for user.
 * @file Canvas.js
 * @description Canvas component
 * @author Aditya Rana
 * @since 7 July 2022
 * 
 */

// import PropTypes from 'prop-types';
import React, { useRef, useState, useEffect } from "react";
import { Layer, Stage } from "react-konva";
import './Canvas.scss';
import { DRAG_DATA_KEY, } from "../../constants/canvas.constants";
import Shapes from "./Shapes";
import { haveIntersection } from "../../helpers";
import KonvaImage from "./KonvaImage";
import { Provider, useDispatch, useSelector, ReactReduxContext } from "react-redux";
import { Html } from 'react-konva-utils';
import _ from "lodash";
// import Property from "./LeftPanel/Property"; //// used below
import { canvasActions, spaceActions } from "../../redux/actions";
import Polygon from "./Shapes/Polygon";
import StyledSpinner from "../StyledSpinner";
import Konva from "konva";
import { Box, Grid, Paper, } from "@mui/material";
import { colorPallete, convertData, getDepthLevel, getShapeType, groupControl, isAuthorised, onLoadImage, pointInCircle, pointInPolygon } from "../../helpers";

import ControllerSlider from "../DeviceController/ControllerSlider";
import { SliderCss, customsx, useStyles } from './canvas.style';
import { DEVICE_PHYSICAL_CONSTANTS as data, features, permissions } from "../../constants";
import CustomTypography from "../CustomTypography";
import IntensitySlider from "../IntensitySlider";
import TemperatureSlider from "../TemperatureSlider";
import placeHolder from '../../assets/img/placeholderLayout.png'
import StyledPopup from "../StyledPopup";
import { groupService, spaceService } from "../../services";
import CustomDropDown from "../CustomDropDown";
import InfoIcon from '../../assets/icons/info.svg';
import ImageWrapper from "../ImageWrapper";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import StyledButton from "../StyledButton";
// import ImageWrapper from "../ImageWrapper";
// import closeIcon from '../../assets/icons/cancel-2.svg';


let defaultSetting = {
  state: false,
  intensity: 70,  //// klv
  temperature: 4000 ///// lst
}
let initcoord = { x: "0", y: "0" };

const hoverPopUpDetail = {
  device: [
    {
      heading: 'Name',
      key: 'name',
      type: 'string'
    },
    {
      heading: 'Category',
      key: 'categoryLabel',
      type: 'string'
    }, {
      heading: 'ModelId',
      key: 'modelId',
      type: 'string'
    }, {
      heading: 'DeviceId',
      key: 'deviceId',
      type: 'string'
    }
  ],
  shape: [
    {
      heading: 'Name',
      key: 'name',
      type: 'string'
    },
    {
      heading: 'Space Id',
      key: 'spaceId',
      type: 'string'
    },
  ]
}

function getHoverPosition(pos, coords, hitCanvas) {
  if (Number(coords.x / hitCanvas?.width) * 100 >= 80) {
    pos['x'] = pos['x'] - 120
  }
  if (Number(coords.y / hitCanvas?.height) * 100 >= 80) {
    pos['y'] = pos['y'] - 120
  }

  return pos;
}


const fetchAllEntities = async (spaceArr, collected, depth) => {
  // if(depth === childDepth) return;
  let i = 0;
  while (i < spaceArr.length && i < depth) {
    await spaceService.getAllAsset(spaceArr[i]).then(({ data: res }) => {
      if (res?.find(r => r?.mime === 'png')) {
        collected.push(...res);
      }
      i++;
    })
  }
  return collected;
}

const fetchAllGroups = async (spaceArr, collected, depth) => {
  // if(depth === childDepth) return;
  let i = 0;
  while (i < spaceArr.length && i < depth) {
    await groupService.getAllGroupsBySpace(spaceArr[i]).then(({ data: res }) => {
      collected.push(...res);
      i++;
    })
  }
  return collected;
}


const Canvas = ({ space = null, onClose = () => { }, canvasProfile, modalOpen = false, maximize = false, loadImage = null, setLoadImage, mqttClient, zoomscale = 1, zoomType = '' }) => {
  const dispatch = useDispatch();
  const classes = useStyles();


  const _shapes = useSelector(state => state?.canvas?.shapes);
  const [contextMenu, setContextMenu] = useState(false);
  const [contextType, setContextType] = useState(null);
  const layerRef = React.useRef();
  const getSelectedShape = useSelector(state => state?.canvas?.selectedShape);
  const [imageLayout, setImageLayout] = useState(null);
  const [loader, showLoader] = useState(true);
  const [selectShape, setSelectShape] = useState({});
  const [isDropped, setIsDropped] = React.useState(false);
  const [shapes, setShapes] = useState(_shapes ? _shapes : []);
  const [layerSpaces, setLayerSpaces] = useState([]);

  const [warningDialogs, setWarningDialogs] = useState({ deleteShape: false, dragShape: false, deleteParentShape: false });
  const [dialogLabels, setDialogLabels] = useState({ deleteShape: '', dragShape: '', deleteParentShape: '' })
  const [dialogKey, setDialogKey] = useState('');


  const _deviceList = useSelector((state) => state?.devices?.deviceList);
  const _spaceList = useSelector((state) => state?.spaces?.list);
  const _zoom = useSelector(state => state?.canvas?.zoom);
  const _spaceLayouts = useSelector(state => state?.canvas?.spaceLayouts);
  const _canvasgroups = useSelector(state => state?.canvas?.getAllGroups);
  const _selectedGroup = useSelector(state => state?.canvas?.highlightgroup);
  const _deviceCategories = useSelector(state => state?.devices?.deviceCategories);
  ////polygon usestates
  const [points, setPoints] = useState([])
  const [flattenedPoints, setFlattenedPoints] = useState(null)
  const [position, setPosition] = useState([0, 0])
  const [isMouseOverPoint, setMouseOverPoint] = useState(false)
  const [isPolyComplete, setPolyComplete] = useState(false);
  const [showHover, setShowHover] = useState(null);

  const [hoverProps, setHoverProps] = useState(null);
  const [anchorPoint, setAnchorPoint] = useState(initcoord);
  const [hoverPoint, setHoverPoint] = useState(initcoord);
  const [deviceProperties, setDeviceProperties] = useState(defaultSetting);
  const [device, setDevice] = useState({});
  const [shapeDrag, setShapeDrag] = useState(null);
  const [loadLayout, setLoadLayout] = useState(false);
  const [canvasDimns, setCanvasDimns] = useState({ width: window.innerWidth, height: window.innerHeight });
  const [canvasAssetId, setCanvasAssetId] = useState(null);
  const [selectGroup, setSelectGroup] = useState('None');
  const [actionLoader, setActionLoader] = useState(false);


  const stageRef = useRef();
  const canvasContainer = useRef();
  const popoverLayer = useRef();
  const contextMenuRef = useRef();
  const windowDimensions = useWindowDimensions();

  /// fetch current selected shape
  useEffect(() => {
    setSelectShape(getSelectedShape);
  }, [getSelectedShape])

  /// setloader false when space is loaded
  useEffect(() => {
    showLoader(true);
    setLoadLayout(false);
  }, [space])

  /// for fetching selected grp
  useEffect(() => {
    setSelectGroup(_selectedGroup?.groupId || 'None')
  }, [_selectedGroup])

  /// dragging shapes and devices over canvas
  const handleDragOver = (e) => {
    let shape = _shapes.find(d => d?.spaceId === canvasProfile?.spaceId && d?.shapeType === 'shape');
    let parentShape = _shapes.find(d => d?.spaceId === canvasProfile?.parentSid && d?.shapeType === 'shape');
    let scrollY = document.getElementById('paperDOM').scrollTop, scrollX = document.getElementById('paperDOM').scrollLeft;
    let posX = e.clientX - 15, posY = e.clientY, tol = 0, availableWidth = window.innerWidth, leftmargin = 0;
    let layoutWidth = _spaceLayouts?.find(s => s?.assetId === canvasAssetId)?.width || 0;
    if (availableWidth < window.innerWidth && availableWidth > layoutWidth) {
      leftmargin = (availableWidth - layoutWidth) / 2;
    }

    // console.log('rrrrrrrrr', e, scrollX, scrollY, leftmargin);
    // console.log('position:::::',posX,shape?.x);
    if (!parentShape && !shape) { e.preventDefault(); return; };
    // if (!shape) { e.preventDefault(); return; };

    if (shape) {
      if (shape?.type === 'rect') {
        let x = shape?.x - scrollX + leftmargin, y = shape?.y - scrollY + tol, width = shape?.width, height = shape?.height;;
        if (posX < x + 5 || posY - 100 < y || posX > x + width || posY - 100 > y + height) return false;
      }

      if (shape?.type === 'circle') {
        if (!pointInCircle(shape?.x - scrollX + leftmargin, shape?.y - scrollY + tol, shape?.radius, posX, posY)) return false;
      }

      if (shape?.type === 'polygon') {
        if (!pointInPolygon(shape?.points, [posX, posY - 100], scrollX, scrollY)) return false;
      }
    }

    if (parentShape) {
      if (parentShape?.type === 'rect') {
        let x = parentShape?.x - scrollX + leftmargin, y = parentShape?.y - scrollY + tol, width = parentShape?.width, height = parentShape?.height;;
        if (posX < x + 5 || posY - 100 < y || posX > x + width || posY - 100 > y + height) return false;
      }
      else if (parentShape?.type === 'circle') {
        if (!pointInCircle(parentShape?.x - scrollX + leftmargin, parentShape?.y - scrollY + tol, parentShape?.radius, posX, posY)) return false;
      }
      else if (parentShape?.type === 'polygon') {
        if (!pointInPolygon(parentShape?.points, [posX, posY - 100], scrollX, scrollY)) return false;
      }

    }

    e.preventDefault();
    return;
  };

  /// put all the shapes when fetched from space asset into setter
  useEffect(() => {
    console.log('my given shapes===', _shapes, _spaceLayouts);
    let device = []
    if (!_.isEmpty(_shapes)) {
      device = _shapes.filter(d => d?.spaceId === canvasProfile?.spaceId && d?.shapeType === 'device');
      setShapes([..._shapes]); //// imp condition     
      setLayerSpaces([..._shapes?.filter(shp => shp?.shapeType === 'shape')?.map(sp => sp?.spaceId)]); /// imp condition
    }
    else {
      setShapes([]); //// imp condition
      setLayerSpaces([]); /// imp condition

    }

    // setToggleDevices(!_.isEmpty(device));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_shapes])

  /// only for polygon::
  useEffect(() => {
    if (_.isEmpty(shapes) || (shapes && !shapes.find(shp => shp?.type === 'polygon' && shp?.spaceId === canvasProfile?.spaceId))) {
      setPoints([]);
      setFlattenedPoints(null);
      setPolyComplete(false);
      setMouseOverPoint(null);
      setPosition([0, 0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shapes])

  /// unselecting current shape
  const handleUnselect = (e) => {
    // checkDeselect(e);
    dispatch(canvasActions.clearSelection())
    setSelectShape({});
    setContextMenu(false);
    setContextType(null);
    setAnchorPoint(initcoord);
  }

  /// deleting the given shape
  const handleDelete = (e) => {
    // currentShape.destroy();
    document.getElementById('menu').style.display = 'none';
    const devices = _shapes.find(shape => shape?.shapeType === 'device' && shape?.spaceId === space?.spaceId)

    /// can only delete shape if no devices are present inside it
    if (!_.isEmpty(devices) && contextType && contextType['data-type'] === 'shape') {
      setWarningDialogs({ ...warningDialogs, 'deleteShape': true });
      setDialogKey('deleteShape');
      setDialogLabels({ ...dialogLabels, 'deleteShape': 'Cannot delete shape without removing devices. Please remove devices first' })

    }
    else {
      const childSpaces = canvasProfile?.Spaces;
      if (_.isEmpty(childSpaces) || _.isEmpty(childSpaces.filter(s => shapes.find(ss => ss?.spaceId === s?.spaceId))) || (contextType && contextType['data-type'] === 'device')) {
        dispatch(canvasActions.deleteShape(selectShape));
      }
      else {   ///// can only delete shape if no children are present inside it
        setWarningDialogs({ ...warningDialogs, 'deleteParentShape': true });
        setDialogKey('deleteParentShape');
        setDialogLabels({ ...dialogLabels, 'deleteParentShape': 'Cannot delete the shape. It may contain child shapes' })
      }
    }
    setContextMenu(false);
    setAnchorPoint(initcoord);
    setContextType(null);

  }

  /// canvas profile useeffect to process asset data
  useEffect(() => {
    console.log('canvas profile', canvasProfile);
    dispatch(canvasActions.removeShapes(null)); //// commented for given shapessss
    dispatch(canvasActions.selectShape(null));
    setCanvasAssetId(null);

    if (!_.isEmpty(canvasProfile?.asset)) {
      canvasProfile?.asset.forEach(item => {
        if (item?.mime === 'png' && (!_.isEmpty(item?.url) || !_.isEmpty(canvasProfile?.spaceLayout))) {
          const assetId = item?.assetId;
          setCanvasAssetId(assetId);
          // console.log('layyyyyout', canvasProfile?.spaceLayout);

          // const canvasConfig = config ? JSON.parse(config) : {};
          // console.log('layoutsssss', canvasConfig,item);

          if (item?.config && Array.isArray(item?.config)) {
            item?.config.forEach(cfg => {
              const { config, spaceId } = cfg; /// object

              if (_spaceList?.find(s => s?.spaceId === spaceId)) {
                dispatch(spaceActions.associateAssetwithSpace({ spaceId: spaceId, assetId: assetId }));
                const shapes = config ? JSON.parse(config)?.shapes?.filter(sh => sh?.shapeType === 'shape') : [];
                const layout = config ? JSON.parse(config)?.layout : {};
                // console.log('cgfffff', shapes, layout);

                if (!_.isEmpty(shapes)) {
                  shapes.forEach(shape => {
                    if (shape?.x !== undefined && shape?.y !== undefined && shape?.shapeType === 'shape' && _spaceList?.find(space => space?.spaceId === shape?.spaceId)) {
                      dispatch(canvasActions.createShape(shape));
                      if (shape?.type === 'polygon' && shape?.spaceId === space?.spaceId && !_.isEmpty(shape?.points)) {
                        // console.log('polygon came', shape);
                        setPoints(shape?.points);
                        setFlattenedPoints(shape?.flattenedPoints);
                        setPolyComplete(true);
                        setMouseOverPoint(true);
                        setPosition(shape?.position)

                      }
                    }
                  })
                }
                let findlayout = _spaceLayouts?.find(s => s?.assetId === assetId) || {};
                // console.log('spacelayouts===>', layout, findlayout, item?.url);
                if (layout) {

                  if (_.isEmpty(findlayout)) {
                    findlayout = { ...layout };
                    dispatch(canvasActions.spaceLayouts({
                      ...layout, width: layout?.width, height: layout?.height, url: item?.url || layout?.url || canvasProfile?.spaceLayout || '', spaceId: spaceId, assetId: assetId, uniq_id: `${spaceId}_layout`
                    }));
                  }
                  onLoadImage(item?.url || findlayout?.url || canvasProfile?.spaceLayout)
                    .then(
                      val => {
                        setImageLayout(item?.url || findlayout?.url || canvasProfile?.spaceLayout);
                        setCanvasDimns({ width: findlayout?.width, height: findlayout?.height });
                        showLoader(false);
                        setLoadLayout(true);
                      },
                      err => {
                        setLoadLayout(err); showLoader(true);
                        setImageLayout(null);
                        // setToggleDevices(false)
                      }
                    );
                }

                // if(layout){
                //   canvasProfile?.spaceIds.forEach(si=>{
                //     dispatch(canvasActions.spaceLayouts({
                //     ...si, ...layout, url: item?.url || layout?.url || canvasProfile?.spaceLayout || '', uniq_id: `${spaceId}_layout`
                //     }))  
                //   })
                // }               
              }
            })
          }

          fetchAllEntities(canvasProfile?.spaceIds.map(s => s?.spaceId), [], 5) //// take upto 5 level
            .then(res => {
              res.forEach((item) => {
                if (item?.entities && Array.isArray(item.entities)) {
                  const devices = item?.entities.filter(ent => ent?.deviceId);
                  devices.forEach(item => {
                    const deviceData = _deviceList?.find(d => d?.deviceId === item?.deviceId);
                    const shape = item?.config ? JSON.parse(item?.config) : {};
                    if (shape && shape?.x && shape?.y && shape?.shapeType === 'device') {
                      dispatch(canvasActions.createShape({ ...shape, ...deviceData }));
                    }
                  })
                }
              })
            })

          fetchAllGroups(canvasProfile?.spaceIds.map(s => s?.spaceId), [], 5)
            .then(res => {
              dispatch(canvasActions.getAllGroupList(res));
            })

          ///// original entities ////////
          // if (item?.entities && Array.isArray(item.entities)) {
          //   const devices = item?.entities.filter(ent => ent?.deviceId);
          //   console.log('devicessssss', devices, item?.entities);
          //   devices.forEach(item => {
          //     const shape = item?.config ? JSON.parse(item?.config) : {};
          //     if (shape && shape?.x && shape?.y && shape?.shapeType === 'device' && _deviceList && _deviceList.find(s => s?.deviceId === shape?.deviceId)) {
          //       console.log('dddddevice', shape);
          //       dispatch(canvasActions.createShape(shape));
          //     }
          //   })
          // }
        }
      })
    }

    setContextMenu(false);
    setAnchorPoint(initcoord);
    setContextType(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasProfile])

  /// loader timeout
  useEffect(() => {
    if (loader) {
      setTimeout(() => {
        showLoader(false);
      }, 4000)
    }
  }, [loader]);

  /// to preview uploaded layout
  useEffect(() => {
    if (loadImage) {
      setImageLayout(loadImage?.url);
      setLoadLayout(true);
      let findImage = _spaceLayouts?.find(s => s?.uniq_id === `${canvasProfile?.spaceId}_layout`) || {};
      // console.log('loading image', loadImage, findImage);

      if (_.isEmpty(findImage)) {
        // console.log('add image', loadImage);
        dispatch(canvasActions.addImage({
          spaceId: canvasProfile?.spaceId,
          id: 1070, ...loadImage
        }));
        setCanvasDimns({ width: loadImage?.width, height: loadImage?.height });
        dispatch(canvasActions.observeChange('IMAGE_UPLOADED'))
      } else {
        setCanvasDimns({ width: loadImage?.width, height: loadImage?.height });
        dispatch(canvasActions.replaceImage({ ...findImage, ...loadImage }));

        dispatch(canvasActions.observeChange('IMAGE_REPLACED'));
        // dispatch(canvasActions.removeShapes(null));

      }
      // setLoadImage(null);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadImage])


  /// isDropped useeffect
  useEffect(() => {
    // dispatch({type: "updateShapes", payload: shapes});  
    if (isDropped) {
      setIsDropped(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDropped])

  /// for dropping of shapes
  const handleDrop = (event) => {
    const draggedData = event.nativeEvent.dataTransfer.getData(DRAG_DATA_KEY);
    // console.log("draggedData ---- ", event.nativeEvent, draggedData, stageRef);
    if (draggedData) {
      const { offsetX, offsetY, type, id, deviceId } = JSON.parse(
        draggedData
      );

      stageRef.current.setPointersPositions(event);
      const coords = stageRef.current.getPointerPosition();
      //  if(type!=='polygon'){ 
      // console.log('coords===>', coords, deviceId, type);
      dispatch(canvasActions.createShape({
        x: type === 'image' ? coords.x - offsetX : coords.x,  //// exact postition for img only
        y: type === 'image' ? coords.y - offsetY : coords.y,   ///// exact position for img only
        type: type,
        id: id,
        spaceId: space?.spaceId,
        deviceId,
        deviceData: _deviceList?.find(d => d?.deviceId === deviceId)
      }
      ));
      dispatch(canvasActions.observeChange('SHAPE_DRAG_DROPPED'))
      // }
      setIsDropped(true);
      // dispatch({type: "updateShapes", payload: shapes});  
    }
  };

  /// give zoom effect
  useEffect(() => {
    let zoomfactor = Math.abs(1 - zoomscale);
    let midscreenx = (window.innerWidth) / 2, midscreeny = (window.innerHeight) / 2

    zoomfactor = Math.round(zoomfactor * 10) / 10;
    stageRef.current?.getStage().to({
      easing: Konva.Easings.EaseInOut,
      duration: 0.25,
      scaleX: zoomscale,
      scaleY: zoomscale,
      x: -1 * zoomfactor * midscreenx,
      y: -1 * zoomfactor * midscreeny,
      width: canvasDimns?.width
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomscale])


  const handleStageClick = (e) => {
    // console.log('stage click', e.target.attrs);
    if (e.target.attrs?.imageType === 'layoutImage' || e.target.attrs['data-stageId'] === 'canvasStage' || e.target.attrs['data-spaceId'] !== space?.spaceId || contextMenu) {
      handleUnselect();
    }
    /// not needed now
    // if (_zoom && _.isEmpty(zoomPos)) {
    // const stage = e.target;
    // const coords = stage.getStage()?.getPointerPosition();
    //   stageRef.current?.getStage().to({
    //     easing: Konva.Easings.EaseOut,
    //     duration: 0.25,
    //     scaleX: 1.9,
    //     scaleY: 1.9,
    //     x: -coords.x,
    //     y: -coords.y
    //   })
    // setZoomPos(coords);
    //   document.body.style.cursor = 'zoom-in'
    // }
  }

  const handleStageMouseEnter = (e) => {
    if (zoomscale !== 1) {
      document.body.style.cursor = 'move'
    }
    else {
      document.body.style.cursor = ''
    }
  }

  const handleStageMouseLeave = (e) => {
    if (zoomscale > 1) {
      document.body.style.cursor = ''
    }
  }

  const handleStageMouseDrag = (e) => {
    if (zoomscale && zoomscale !== 1) {
      document.body.style.cursor = 'move'
    }

  }

  const handleFloatingMouseEnter = (e) => {
    if (zoomscale > 1) { document.body.style.cursor = 'move' }
    else { document.body.style.cursor = '' }

    const stage = e.currentTarget;
    const coords = stage.getStage()?.getPointerPosition();
    // console.log('mouse enter===>', e.target.getAttrs() , contextType,  _.isEmpty(selectShape) || (selectShape && e.target.attrs['data-type'] !== 'shape'));
    if (e.target?.attrs?.deviceId && _deviceList) {
      const device = _deviceList?.find(d => d?.deviceId === e.target?.attrs?.deviceId);
      device["categoryLabel"] = _deviceCategories?.find(c => c?.category === device?.category)?.label;
      setHoverProps(device);
    }
    if (e.target.attrs['data-type'] === 'shape') {
      setHoverProps(_spaceList?.find(d => d?.spaceId === e.target?.attrs['data-spaceId']));
    }
    if (zoomscale === 1) {
      if (!contextMenu && !contextType)
        setShowHover(e.target);

      else if (contextMenu && e.target.getAttrs() !== contextType) {
        setShowHover(e.target);
      }
      else {
        setShowHover(null);
      }

    }

    // document.body.style.cursor = 'pointer';
    let pos = {
      x: coords.x + 10,
      y: coords.y + 10
    }
    const hitCanvas = popoverLayer?.current?.hitCanvas;
    const newPos = getHoverPosition(pos, coords, hitCanvas);
    setHoverPoint({ ...hoverPoint, x: newPos['x'] + 'px', y: newPos['y'] + 'px' });
  }

  const handleFloatingMouseMove = (e) => {
    handleFloatingMouseEnter(e);
  }
  const handleFloatingMouseLeave = () => {
    if (zoomscale > 1) {
      document.body.style.cursor = 'move'
    }
    setShowHover(null);
    setHoverPoint(initcoord);
  }


  /// handle context menu when right-click happens
  const handleContextMenu = (e) => {
    e.evt.preventDefault();
    const stage = e.currentTarget;

    // console.log("context e---->", stage, stage.getStage(), e.target.getAttrs(), e.target.attrs['data-spaceId'], contextMenu);
    const currentTarget = e?.target?.getAttrs();


    // console.log('selected shape', currentTarget);


    if (contextMenu || e.target?.attrs?.imageType === 'layoutImage' || e.target.attrs['data-stageId'] === 'canvasStage' ||
      (!modalOpen && e?.target?.attrs['data-type'] === 'shape') || (!modalOpen && (!isAuthorised(features.device, permissions.operate))) || /*(false && !isAu)  */
      (!modalOpen && currentTarget?.status === 'inactive') ||
      (!modalOpen && currentTarget?.category?.toLowerCase().search('lightdriver') === -1) ||
      (!modalOpen && _.isEmpty(_deviceList?.find(d => d?.deviceId === currentTarget?.deviceId))) ||
      (modalOpen && e?.target?.attrs['data-type'] === 'shape') && e?.target?.attrs['data-spaceId'] !== canvasProfile?.spaceId) {
      // if we are on empty place of the stage we will do nothing
      setContextMenu(false);
      setContextType(null);
      setAnchorPoint(initcoord);
      return;
    }

    setContextMenu(true);

    const coords = stage?.getStage()?.getPointerPosition();
    const paperDOM = document?.getElementById('paperDOM');
    const hitCanvas = modalOpen ? stage?.getStage()?.getAttrs() : { width: paperDOM?.offsetWidth + paperDOM?.scrollLeft, height: paperDOM?.offsetHeight + paperDOM?.scrollTop };
    let pos = {
      x: coords.x + 10,
      y: coords.y + 10
    }
    if (Number(coords.x / hitCanvas?.width) * 100 >= 80) {
      pos['x'] = pos['x'] - (modalOpen ? 180 : 260)
    }
    if (Number(coords.y / hitCanvas?.height) * 100 >= 80) {
      pos['y'] = pos['y'] - 100
    }
    // console.log('context===>', Number(coords.x / hitCanvas?.width), coords, stage?.getStage()?.getAttrs() , paperDOM?.offsetWidth, paperDOM?.scrollLeft );
    setAnchorPoint({ x: pos['x'], y: pos['y'] });
    setContextType(e.target.attrs);
    setActionLoader(true);
    setShowHover(null);
    setTimeout(() => { setActionLoader(false) }, 800);
  }


  useEffect(() => {
    if (stageRef) {
      stageRef?.current?.getLayers().forEach((item) => {
        const getLayerAttr = item.getAttrs();
        if (getLayerAttr['data-layertype'] === 'layer-shape' && getLayerAttr['data-layerid'] === space?.spaceId) {
          item?.moveUp();
        }
      })

      // let layers = [];
      // const orderedlayers = layers.sort((a, b) => a?.zIndex - b?.zIndex);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stageRef, loadLayout, layerSpaces])

  const getMousePos = (stage) => {
    return [stage.getPointerPosition().x, stage.getPointerPosition().y]
  }

  //drawing begins when mousedown event fires.
  const handleMouseDown = (e) => {
    if (isPolyComplete || _.isEmpty(getShapeType(shapes, 'polygon'))) return
    const stage = e.target.getStage();
    const mousePos = getMousePos(stage)

    if (!_.isEmpty(getShapeType(shapes, 'polygon'))) {
      const parentlayerid = layerRef?.current?.getAttrs()['data-parentlayerid'];
      const parentShape = shapes.find(s => s?.spaceId === parentlayerid);
      if (parentShape?.type === 'rect') {
        const xrect = parentShape?.x, yrect = parentShape?.y, PW = parentShape?.width, PH = parentShape?.height;
        if (mousePos[0] < xrect || mousePos[1] < yrect || mousePos[0] > xrect + PW || mousePos[1] > yrect + PH) return;
      }
      else if (parentShape?.type === 'circle') {
        if (Math.pow(mousePos[0] - parentShape?.x, 2) + Math.pow(mousePos[1] - parentShape?.y, 2) > Math.pow(parentShape?.radius, 2)) return;
      }
      else if (parentShape?.type === 'polygon') {
        if (!pointInPolygon(parentShape?.points, mousePos)) return;
      }
    }


    if (isMouseOverPoint && points.length >= 3) {
      setPolyComplete(true);
    } else {
      setPoints([...points, mousePos])
    }
  }

  const handleMouseMove = (e) => {
    const stage = e.target.getStage();
    const mousePos = getMousePos(stage);
    setPosition(mousePos)
  };

  const handleMouseOverStartPoint = (e) => {
    if (isPolyComplete || points.length < 3) return
    e.target.scale({ x: 3, y: 3 })
    setMouseOverPoint(true)
  }

  const handleMouseOutStartPoint = (e) => {
    e.target.scale({ x: 1, y: 1 })
    setMouseOverPoint(false) ////changed
  }

  const handlePointDragMove = (e) => {
    if (_.isEmpty(getShapeType(shapes, 'polygon'))) return;
    if (!e.target._lastPos) return;
    const stage = e.target.getStage()
    const index = e.target.index - 1
    let pos = [e.target._lastPos.x, e.target._lastPos.y];

    const parentShape = shapes.find(s => s?.spaceId === space?.parentSid);
    if (parentShape?.type === 'rect') {
      const xrect = parentShape?.x, yrect = parentShape?.y, PW = parentShape?.width, PH = parentShape?.height;
      if (pos[0] < xrect) pos[0] = xrect;
      if (pos[1] < yrect) pos[1] = yrect;
      if (pos[0] > xrect + PW) pos[0] = xrect + PW;
      if (pos[1] > yrect + PH) pos[1] = yrect + PH;
    }
    else if (parentShape?.type === 'circle') {
      if (Math.pow(pos[0] - parentShape?.x, 2) + Math.pow(pos[1] - parentShape?.y, 2) > Math.pow(parentShape?.radius, 2)) return;
    }

    else {
      if (pos[0] < 0) pos[0] = 0
      if (pos[1] < 0) pos[1] = 0
      if (pos[0] > stage.width()) pos[0] = stage.width()
      if (pos[1] > stage.height()) pos[1] = stage.height()
    }
    setPoints([...points.slice(0, index), pos, ...points.slice(index + 1)]);
  }

  useEffect(() => {
    //   [x1,y1,x2,y2]
    setFlattenedPoints(
      points.concat(isPolyComplete ? [] : position).reduce((a, b) => a.concat(b), [])
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [points])


  const undo = () => {
    setPoints(points.slice(0, -1))
    setPolyComplete(false)
  }
  const handleGroupDragEnd = (e) => {
    //drag end listens other children circles' drag end event
    //...that's, why 'name' attr is added, see in polygon annotation part
    if (e.target.name() === 'polygon') {
      let result = []
      let copyPoints = [...points]
      copyPoints.map((point) => result.push([point[0] + e.target.x(), point[1] + e.target.y()]))
      e.target.position({ x: 0, y: 0 }) //needs for mouse position otherwise when click undo you will see that mouse click position is not normal:)
      setPoints(result)
    }
  }


  const handleGroupClick = (value, color) => {
    if (value !== 'None') {
      const item = _canvasgroups?.find(grp => grp?.groupId === value);
      // console.log('item clicked', value, item);
      // setHighlightGroup(item);
      dispatch(canvasActions.highlightGroup({ ...item, color: color }))
    }
    else {
      // setHighlightGroup(null);
      dispatch(canvasActions.highlightGroup(null));
    }
  }


  useEffect(() => {

    if (selectShape) {
      if (selectShape?.deviceStatus) {
        setDeviceProperties({ ...deviceProperties, intensity: selectShape?.deviceStatus?.intensity, temperature: selectShape?.deviceStatus?.temperature });
      }
      if (selectShape?.status) {
        setDeviceProperties({ ...deviceProperties, state: selectShape?.status === 'active' ? true : false })
      }
      if (selectShape?.shapeType === 'device') {
        // console.log('my device===>',_deviceList?.find(d => d?.deviceId === selectShape?.deviceId));
        setDevice(_deviceList?.find(d => d?.deviceId === selectShape?.deviceId))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectShape])



  function getDimnsn() {
    const shape = _spaceLayouts?.find((shapeObj) => shapeObj?.assetId === canvasAssetId);
    return { width: (shape?.width) || canvasDimns?.width || '100%', height: shape?.height || canvasDimns?.height || 'auto' }
  }

  function isSynced(selectGroup) {
    if (selectGroup === '' || selectGroup === 'None') return false;
    return _canvasgroups?.find(grp => grp?.groupId === selectGroup)?.synced || false;
  }


  const dragShapesWithDevices = (e, data) => {
    const devices = shapes.find(shape => shape?.shapeType === 'device' && shape?.spaceId === space?.spaceId);
    const childSpaces = canvasProfile?.Spaces;
    setTimeout(() => {
      if (!_.isEmpty(devices)) {
        setWarningDialogs({ ...warningDialogs, 'dragShape': true });
        setDialogKey('dragShape');
        setDialogLabels({ ...dialogLabels, 'dragShape': 'This shape contain devices. Please move the devices to appropriate location.' });
        return;
      }
    }, 300);

    if (!_.isEmpty(childSpaces.filter(s => shapes.find(ss => ss?.spaceId === s?.spaceId)))) {
      setWarningDialogs({ ...warningDialogs, 'dragShape': true });
      setDialogKey('dragShape');
      setDialogLabels({ ...dialogLabels, 'dragShape': 'This shape may contain child shapes. Please move the shape to appropriate location.' })
      return;
    }
  }


  const layerDragMove = (e, spaceId) => { /// for collision detecttion

    setShapeDrag(e.currentTarget);
    const parentSid = canvasProfile?.parentSid;
    let target = e.target;
    let targetRect = e.target.getClientRect();
    let siblings = layerRef?.current?.getParent()?.getChildren()?.filter(child => child.getAttrs()['data-parentlayerid'] === parentSid);

    siblings.forEach(layer => {
      if (layer === target?.getLayer()) return;
      if (haveIntersection(layer.getClientRect(), targetRect, target?.getAttrs()['data-testid'])) {
        layer?.findOne('.fillShape')?.fill('#fe0000');
      } else {
        layer?.findOne('.fillShape')?.fill('rgba(0,0,0,0.5)');
      }
    })
  }


  const getGrpDetail = () => _canvasgroups?.find(gid => gid?.groupId === selectGroup) || {};

  const isDeviceOfGroupPresent = () => {
    if (_.isEmpty(_canvasgroups))
      return false;
    if (getGrpDetail()?.devices?.filter(dvc => shapes?.find(sh => sh?.deviceId === dvc?.deviceId))?.length > 0) {
      return true;
    }

    return false;
  }

  return (

    <Box id={"maindiv"} ref={canvasContainer}>
      {modalOpen && <StyledButton color="error" variant="contained" onClick={onClose} className={classes?.CloseEditor}>
        Close canvas editor
      </StyledButton>
      }
      {loader ? <Box className={"editor_spinner"} style={{ zIndex: 9999 }} > <StyledSpinner /> </Box> :
        <main id="stage-parent" className="canvas" data-testid="Canvas" onDrop={(e) => { handleDrop(e); }} onDragOver={(e) => !_.isEmpty(imageLayout) && handleDragOver(e)}>
          {modalOpen &&
            <Box display="flex" alignItems="center" className={classes.CanvasTitleHeader} position={'relative'}>
              {modalOpen && <CustomTypography content={'2D / ' + (canvasProfile?.name || '')} styles={{ marginRight: '16px' }} size={16} color={'#202020'} weight={600} />}
              {modalOpen && zoomscale !== 1 && <CustomTypography content={`zoomed to ${zoomscale}x`} styles={{ position: 'absolute', right: 0 }} size={12} color={'#202020'} weight={600} />}
            </Box>
          }
          {/* ************* grp selection ******************** */}
          {!modalOpen &&
            <Grid item xs={12} container spacing={1.5} my={1.2}>
              <Grid xs={12} md={3} item sx={{ minWidth: "218px" }}>
                <Box display={"flex"} width={"100%"} alignItems={'center'}>
                  <CustomTypography content={'Select group'} styles={{ marginRight: '5px', minWidth: "90px" }} size={14} />
                  <CustomDropDown
                    value={selectGroup || 'None'}
                    name="groupSelect"
                    isNoneAsItem={false}
                    disabled={_.isEmpty(imageLayout)}
                    customsx={customsx?.CanvasDropdown}
                    customdropsx={{ width: "100%" }}
                    onChange={(e, value) => { handleGroupClick(e?.target?.value, colorPallete[0]); setSelectGroup(e?.target?.value || 'None') }}
                    list={[{ id: 'None', label: 'None' }, ..._canvasgroups.map((item) => { return { id: item?.groupId, label: item?.name } })]} />
                </Box>
              </Grid>
              <Grid container item md={selectGroup === 'None' ? 9 : 9} xs={12}>
                {selectGroup === 'None' ? (
                  <Grid item xs={12} display="flex" justifyContent="flex-end" alignItems="center">
                    <CustomTypography size={12.5}>
                      <ImageWrapper src={InfoIcon} alt="" /> Group controls will be displayed here after group selection and device plotted.
                    </CustomTypography>
                  </Grid>
                ) : (
                  <>
                    {(isAuthorised(features.device, permissions.operate) && isAuthorised(features?.device, permissions?.controlGroup))
                      && canvasProfile?.asset && isDeviceOfGroupPresent() &&    /* (true || isAu) */
                      <>
                        <Grid xs={12} lg={2} sm={0} display={'flex'} alignItems={'center'} justifyContent={'flex-end'} sx={{ pr: 1 }}>
                          <CustomTypography size={12.5}>
                            {!isSynced(selectGroup) && <ImageWrapper src={InfoIcon} alt="" enablePopOver={true} hoverLabel={'Group operations will be enabled only after they are synced. Sync them in Groups.'} />}
                          </CustomTypography>
                        </Grid>
                        <Grid xs={12} lg={5} sm={6} display="flex" justifyContent="flex-end">
                          <ControllerSlider
                            suffix="%"
                            onOffLabel={false}
                            params={{
                              icon: true, label: true,
                              value: (_.isEmpty(getGrpDetail()?.groupType) || getGrpDetail()?.groupType?.includes("LW_ANALOG") || getGrpDetail()?.groupType?.includes("LW_DT2") || getGrpDetail()?.groupType?.includes("LW_DT6")), switchButton: true,
                              compo: (_.isEmpty(getGrpDetail()?.groupType) || getGrpDetail()?.groupType?.includes("LW_ANALOG") || getGrpDetail()?.groupType?.includes("LW_DT2") || getGrpDetail()?.groupType?.includes("LW_DT6"))
                            }}
                            name="intensity"
                            icon={data?.intensity?.icon} setterFunction={(e, value) => {
                              groupControl(getGrpDetail(), dispatch, { lst: value }, mqttClient)
                            }}
                            disabled={!isSynced(selectGroup)}
                            data={{ min: 0, max: 100 }}
                            label={data?.intensity?.label} customsx={{
                              // label: { width: '70px' }, compo: { width:'100%' },
                            }}
                          />
                        </Grid>

                        {(_.isEmpty(getGrpDetail()?.groupType) || getGrpDetail()?.groupType?.includes("LW_DT8")) &&
                          <Grid xs={12} lg={5} sm={6} display="flex" justifyContent="flex-end">
                            <ControllerSlider suffix="K" name="temperature" icon={data?.kelvin?.icon} disabled={!isSynced(selectGroup)}
                              setterFunction={(e, value) => {
                                groupControl(getGrpDetail(), dispatch, { klv: value }, mqttClient)
                              }}
                              customsx={SliderCss} label={data?.kelvin?.label} data={{ min: 4000, max: 7500 }} value={4000} />
                          </Grid>
                        }
                      </>
                    }
                  </>
                )
                }
              </Grid>
            </Grid>
          }
          {/* canvas */}
          <Paper variant="outlined"
            id="paperDOM"
            onScroll={() => { setShowHover(null) }}
            sx={{
              ...customsx.CanvasPaper,
              width: getDimnsn()?.width,
              height: modalOpen ? 'calc(100vh - 96px)' : maximize ? getDimnsn()?.height : '300px',
            }}>
            <Box className="canvasbox" sx={{ margin: "auto", width: "inherit" }}>
              <ReactReduxContext.Consumer>
                {({ store }) => (
                  <Stage
                    // listening={!_.isEmpty(shapes) &&  !_.isEmpty(shapes?.find(s => s?.spaceId === canvasProfile?.spaceId && s?.shapeType === 'shape'))}
                    ref={stageRef}
                    name={canvasProfile?.spaceId}
                    width={canvasDimns?.width || windowDimensions?.width}
                    height={canvasDimns?.height || window?.innerHeight || 300}
                    data-stageId={'canvasStage'}
                    // height={600}
                    // scale={{x:scale, y:scale}}
                    onMouseEnter={handleStageMouseEnter}
                    onMouseLeave={handleStageMouseLeave}
                    onClick={handleStageClick}
                    onMouseDown={handleMouseDown}
                    onMousemove={handleMouseMove}
                    onContextMenu={(e) => { handleContextMenu(e) }}
                    onDragMove={handleStageMouseDrag}
                    contextmenu="menu"
                    draggable={zoomscale !== 1}
                    dragBoundFunc={(pos) => {
                      let currpos = pos;
                      let zoomfactor = Math.abs(1 - zoomscale);
                      zoomfactor = Math.round(zoomfactor * 10) / 10;

                      if (pos?.x < -1 * zoomfactor * canvasDimns?.width) {
                        currpos['x'] = -1 * zoomfactor * canvasDimns?.width;
                      }
                      if (pos?.y < -1 * zoomfactor * canvasDimns?.height) {
                        currpos['y'] = -1 * zoomfactor * canvasDimns?.height;
                      }
                      if (pos?.x > 0) {
                        currpos['x'] = 0;
                      }
                      if (pos?.y > 0) {
                        currpos['y'] = 0;
                      }
                      return currpos;
                    }}
                  >
                    <Provider store={store}>
                      {/* <Layer>
                        <Html>
                          {JSON.stringify(layerSpaces,null,2)}
                          {JSON.stringify(shapes,null,2)}
                        </Html>                        
                      </Layer> */}

                      {/* layout layer */}
                      <Layer key={"layer-layout"} data-layertype={'layer-layout'} listening={false}>
                        {!_.isEmpty(imageLayout) ?
                          <KonvaImage listening={false} image={{ src: imageLayout }} width={canvasDimns?.width || windowDimensions?.width || 400} height={canvasDimns?.height || window?.innerHeight || 714} />
                          : <KonvaImage image={{ src: placeHolder }} width={windowDimensions?.width || 1242} height={windowDimensions?.height || 714} />
                        }
                      </Layer>

                      {/* context menu layer */}
                      {contextMenu &&
                        <Layer ref={contextMenuRef} key="layer-1020">
                          <Html>
                            <div name="menu_box" id="menu" className="canvasMenu" style={{ top: anchorPoint?.y, left: anchorPoint?.x }} >
                              {
                                (() => {
                                  switch (selectShape?.shapeType) {
                                    case 'shape':
                                      return (
                                        <>
                                          {modalOpen &&
                                            <Box width={'184px'} sx={{ ml: '6px' }}>
                                              {selectShape &&
                                                <Box className="deviceBtns">
                                                  <StyledButton onClick={handleUnselect} style={{ background: '#0F8D48' }} className="unselect" size="small" variant="outlined" >Unselect</StyledButton>
                                                  <StyledButton onClick={handleDelete} style={{ background: '#FF4336', }} className="remove" size="small" variant="outlined" >Delete</StyledButton>
                                                </Box>}
                                            </Box>
                                          }
                                        </>
                                      )
                                    case 'device':
                                      return (
                                        <>
                                          {!modalOpen ?
                                            <Box style={{ background: '#fff', overflow: 'hidden', padding: '10px', width: '350px', borderRadius: '10px' }}>
                                              {actionLoader
                                                ? <StyledSpinner size={20} sx={{ position: 'relative', left: '50%' }} />
                                                :
                                                <>
                                                  <Provider store={store}>
                                                    <IntensitySlider customsx={{ label: { width: 'fit-content', marginRight: '7px' }, compo: { width: '100%', marginRight: '10px' } }} mqttClient={mqttClient} data={device} icon={data?.intensity?.icon} label={'Intensity'} params={{ value: device?.category === "RelayLightDriver" ? false : true, switchButton: true, compo: device?.category === "RelayLightDriver" ? false : true, label: true, icon: true }} />

                                                    {device?.modelNumber === 'DT8' && <TemperatureSlider
                                                      data={device} customsx={{ label: { width: 'fit-content', marginRight: '7px' }, compo: { width: '100%', marginRight: '10px' } }} icon={data?.kelvin?.icon} suffix={'K'} label={'Temperature'} params={{ label: true, value: true, icon: true, compo: true }}
                                                      mqttClient={mqttClient}
                                                    />
                                                    }
                                                  </Provider>
                                                  <StyledButton onClick={handleUnselect} style={{ background: '#0F8D48', color: 'white', textTransform: 'none', float: 'right', fontFamily: 'Montserrat' }} size="small" color="primary" variant="outlined">Close</StyledButton>
                                                  {modalOpen && <StyledButton onClick={handleDelete} style={{ background: '#FF4336', marginRight: '10px' }} size="small" color="primary" variant="outlined">Remove</StyledButton>}
                                                </>
                                              }
                                            </Box>
                                            :
                                            <Box width={'184px'} sx={{ ml: '6px' }} className="deviceBtns" >
                                              <StyledButton onClick={handleUnselect} style={{ background: '#0F8D48' }} className="unselect" size="small" color="primary" variant="outlined">Unselect</StyledButton>
                                              {modalOpen && <StyledButton onClick={handleDelete} style={{ background: '#FF4336' }} className="remove" size="small" color="primary" variant="outlined">Remove</StyledButton>}
                                            </Box>

                                          }
                                        </>
                                      )
                                    default:
                                      return <></>
                                  }
                                })()
                              }
                            </div>
                          </Html>
                        </Layer>
                      }

                      {/* Shapes Layer */}

                      {loadLayout && Array.isArray(layerSpaces) && !_.isEmpty(layerSpaces) && layerSpaces.map((spaceId, i) => {
                        return (
                          <Layer
                            ref={layerRef}
                            data-layerid={`${spaceId}`}
                            data-parentlayerid={`${_spaceList?.find(s => s?.spaceId === spaceId)?.parentSid}`}
                            data-layertype={'layer-shape'}
                            data-layername={`${_spaceList?.find(s => s?.spaceId === spaceId)?.name}`}
                            key={`layer-${spaceId}`}
                            onMouseEnter={(e) => handleFloatingMouseEnter(e)}
                            onMouseLeave={handleFloatingMouseLeave}
                            onMouseMove={(e) => { handleFloatingMouseMove(e) }}
                            onDragMove={(e) => layerDragMove(e, spaceId)}
                            onDragEnd={(e) => { handleUnselect(e) }}
                          >

                            {loadLayout && Array.isArray(shapes) && !_.isEmpty(shapes) && shapes?.filter(s => s?.spaceId === spaceId).map((shape, i) =>
                              (() => {
                                if (shape?.type === 'polygon') {
                                  if (shape?.spaceId === canvasProfile?.spaceId && modalOpen) {
                                    return <Polygon
                                      key={"sps-" + shape?.uniq_id}
                                      shape={{ ...shape, id: shape?.uniq_id }}
                                      id={shape?.uniq_id}
                                      points={points}
                                      flattenedPoints={flattenedPoints}
                                      isFinished={isPolyComplete}
                                      position={position}
                                      handlePointDragMove={handlePointDragMove}
                                      handleGroupDragEnd={handleGroupDragEnd} // when drag ended
                                      handleMouseOverStartPoint={handleMouseOverStartPoint}
                                      handleMouseOutStartPoint={handleMouseOutStartPoint}
                                      handleDrag={(e, data) => { dragShapesWithDevices(e, data) }} // when dragging
                                      handlePolygonClick={(e, data) => { setContextMenu(false); setContextType(null); }}
                                      undo={undo}
                                      spaceIds={canvasProfile?.spaceIds}
                                      selectShape={selectShape}
                                      draggable={!_zoom && shape?.spaceId === canvasProfile?.spaceId}
                                      isSelectedShape={(selectShape?.uniq_id === shape?.uniq_id) && modalOpen}
                                    />
                                  }
                                  else {
                                    return (
                                      <>
                                        <Polygon
                                          key={"sps-" + shape?.uniq_id}
                                          shape={{ ...shape, id: shape?.uniq_id }}
                                          id={shape?.uniq_id}
                                          points={shape?.points}
                                          flattenedPoints={shape?.flattenedPoints}
                                          isFinished={shape?.isPolyComplete}
                                          position={shape?.position}
                                          profileSpaceId={canvasProfile?.spaceId}
                                          draggable={false}
                                          isSelectedShape={false}
                                          spaceIds={canvasProfile?.spaceIds}
                                        />
                                      </>
                                    )
                                  }

                                } else {
                                  return <>
                                    <Shapes
                                      key={"ssp-" + shape?.uniq_id}
                                      shape={{ ...shape, id: shape?.uniq_id }}
                                      isSelectedShape={(selectShape?.uniq_id === shape?.uniq_id) && modalOpen}
                                      profileSpaceId={space?.spaceId}
                                      highlight={shape?.spaceId === space?.spaceId}
                                      spaceIds={canvasProfile?.spaceIds}
                                      nodezIndex={canvasProfile?.nodezIndex}
                                      depthLevel={getDepthLevel(_spaceList?.find(item => item?.spaceId === spaceId)?.Path)}
                                      getZIndex={canvasProfile?.nodezIndex?.find(z => selectShape?.spaceId === z?.spaceId)?.zIndex || 0}
                                      draggable={!_zoom && shape?.spaceId === canvasProfile?.spaceId}
                                      handleDrag={(e, data) => { dragShapesWithDevices(e, data) }}
                                      handleDragEnd={(e, data) => {
                                        setShapeDrag(null);
                                        let pos = e.target.getClientRect();
                                        const hitCanvas = popoverLayer?.current?.hitCanvas;
                                        const newPos = getHoverPosition(pos, e.currentTarget?.getStage()?.getPointerPosition(), hitCanvas)
                                        setHoverPoint({ ...hoverPoint, x: newPos['x'] + 'px', y: newPos['y'] + 'px' })
                                      }}
                                      handleClickShape={(e, data) => { setContextMenu(false); setContextType(null); setAnchorPoint(initcoord); }}
                                    />
                                  </>
                                }
                              })()
                            )
                            }
                          </Layer>
                        )
                      })}

                      {/* Device props popover layer */}
                      {showHover && !shapeDrag && hoverPopUpDetail[showHover.attrs['data-type']] &&
                        <Layer ref={popoverLayer} key={'layer-1040'}   >
                          <Html>
                            <div className="hovertext" style={{ top: hoverPoint?.y, left: hoverPoint?.x }} >
                              {

                                showHover.attrs['data-type'] === 'device' && !_deviceList?.find(d => d?.deviceId === showHover.attrs['deviceId'])
                                  ?
                                  <Box>
                                    <CustomTypography size={12} content={'Given device not found in ' + space?.name} styles={{ marginRight: '8px', marginBottom: '4px' }} weight={700} />
                                  </Box>
                                  :
                                  hoverPopUpDetail[showHover.attrs['data-type']]?.map((item, key) => (
                                    <Box display={'flex'} key={"popup-" + key} >
                                      <CustomTypography size={12} content={item?.heading + ':'} styles={{ marginRight: '8px', marginBottom: '4px' }} weight={700} />
                                      <CustomTypography size={12} content={hoverProps && convertData(hoverProps[item?.key], item?.type)} weight={400} />
                                    </Box>
                                  ))
                              }
                            </div>
                          </Html>
                        </Layer>
                      }
                    </Provider>
                  </Stage>
                )}

              </ReactReduxContext.Consumer>
            </Box>
          </Paper>

          <StyledPopup
            customsx={{ btns: { display: 'flex', justifyContent: 'center' } }}
            open={warningDialogs[dialogKey]}
            onClose={() => {
              setWarningDialogs({ ...warningDialogs, [`${dialogKey}`]: false });
              setDialogLabels({ ...dialogLabels, [`${dialogKey}`]: '' })
              setDialogKey('');
            }}
            data={{
              content: <CustomTypography size={14} weight={600} content={dialogLabels[dialogKey]} />,
              actions: [
                {
                  id: "Discard",
                  type: "button",
                  label: "Close",
                  onClick: (event, data) => {
                    setWarningDialogs({ ...warningDialogs, [`${dialogKey}`]: false });
                    setDialogLabels({ ...dialogLabels, [`${dialogKey}`]: '' })
                    setDialogKey('');
                  }
                }
              ],
            }}
          />
        </main>
      }

    </Box>

  )
}

Canvas.propTypes = {};

Canvas.defaultProps = {};

export default Canvas;
