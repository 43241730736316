import { dragAndDropActions } from "../redux/actions";

export const onDragEnd = (result,dragList,dropList,checkedItems, setCheckedItems,dispatch) => {
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId ==='leftList' && destination.droppableId==='rightList') {
      let sourceItems = [...dragList];
      let destItems = [...dropList];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      if(checkedItems.includes(removed?.id)){
        let temperoryCheckedItems = checkedItems.filter(item => item?.id!==removed?.id)
        let splitSourceItems = sourceItems.reduce((final, current) => {
          final[temperoryCheckedItems.includes(current?.id) ? 'toBeMovedToRight' : 'toBeKeptLeft'].push(current);
          return final;
        }, { toBeKeptLeft: [], toBeMovedToRight: [] });
        sourceItems = splitSourceItems.toBeKeptLeft
        destItems = [...destItems, ...splitSourceItems.toBeMovedToRight];
        setCheckedItems([])
      }
      dispatch(dragAndDropActions.setDropList(destItems))
    }
  }