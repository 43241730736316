/***
 *
 * Controller class for user.
 * @file StyledCardWrapper.js
 * @description StyledCardWrapper component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './StyledCardWrapper.style.js';
import './StyledCardWrapper.scss';
import { Box, Card, CardActions, CardContent, Typography } from '@mui/material';
import ImageWrapper from '../ImageWrapper/index.js';

const StyledCardWrapper = ({ children, actions, heading, media, content, sx = {}, style = {}, ...props }) => {
  const classes = useStyles()

  return (
    <Card className={classes?.StyledCardWrapper + " StyledCardWrapper" || ""} sx={sx} style={style} data-testid="StyledCardWrapper" {...props}>

      {/* -------------- Chindren data ------------- */}
      {!children ? (
        <>
          {/* ------------- Card heading ------------- */}
          {heading?.text && heading?.position === "pageTop" &&
            <Typography gutterBottom variant="h5" component="div" sx={{ m: 0, p: 2, ...(heading?.sx || {}) }}>{heading?.text}</Typography>
          }

          {/* ------------- Card media ------------- */}
          {media?.src &&
            <Box sx={{ backgroundColor: "#f7f7f7", width: "100%", textAlign: "center", ...media?.wrapperSx }}>
              <ImageWrapper
                alt={media?.alt || "media"}
                height={media?.height}
                width={media?.width || "auto"}
                sx={{ margin: "auto", ...(media?.sx || {}) }}
                src={media?.src}
              />
            </Box>
          }

          <CardContent>

            {/* ------------- Card heading ------------- */}
            {heading?.text && heading?.position !== "pageTop" &&
              <Typography gutterBottom variant="h5" component="div" sx={{ ...(heading?.sx || {}) }}>{heading?.text}</Typography>
            }

            {/* -------------- content ------------ */}
            {content?.text &&
              <Box sx={{ ...content?.sx || {} }}>
                {content?.text}
              </Box>
            }
          </CardContent>

          {/* ------------- Card bottom action buttons ------------- */}
          {actions &&
            <CardActions>{ }</CardActions>
          }
        </>
      ) : (children)
      }
    </Card>
  )
};


StyledCardWrapper.propTypes = {};

StyledCardWrapper.defaultProps = {};

export default StyledCardWrapper;
