import {
    roleTypes
} from '../types/role.type';
let initialState = {
    loading: false,
    list: [],
    itemList: [],
    item: {},
    error: null
}

export function roles(state = initialState, action) {
    switch (action.type) {
        case roleTypes.GET_REQUEST:
        case roleTypes.GET_ALL_REQUEST:
        case roleTypes.UPDATE_REQUEST:
        case roleTypes.DELETE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case roleTypes.GET_ALL_CONTROLLER_SUCCESS:    
            return{
                ...state,
                itemList: action.payload,
                loading: false
            }

        case roleTypes.GET_ALL_SUCCESS:   
            return {
                ...state,
                list: action.payload,
                loading: false,
                item: []
            };
        case roleTypes.GET_SUCCESS:
            return {
                ...state,
                item: action.payload,
                // item: action?.comingFrom !== 'profile' ? action.payload : state?.item,
                loading: false
            };
        case roleTypes.ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                list: [...state?.list, action.payload],
            };
        case roleTypes.UPDATE_SUCCESS:
            return {
                ...state,
                list: state?.list && Array.isArray(state?.list) ? state?.list?.map(item=>item.id === action.payload.id?action.payload:item) : [],
                item: action.payload,
                loading: false
            };
        case roleTypes.DELETE_ROLE_SUCCESS:
            return {
                ...state,
                deleteRole:action.payload,
                list: state?.list && Array.isArray(state?.list) ? state?.list?.filter(item => item.name !== action.payload.name) : [],
                loading: false
            };
        case roleTypes.DELETE_SUCCESS:
            return {
                ...state,
                list: state?.list && Array.isArray(state?.list) ? state?.list?.filter(item => item.id !== action.payload.id) : [],
                loading: false
            };
        case roleTypes.GET_FAILURE:
        case roleTypes.GET_ALL_FAILURE:
        case roleTypes.UPDATE_FAILURE:
        case roleTypes.DELETE_FAILURE:
            return {
                ...state,
                error: action.payload.error,
                loading: false
            };
        case roleTypes.UPDATE_CONTROLLER_FAILURE:
            return{
                ...state,
                error: action.payload,
                loading:false
            }    
        default:
            return state
    }

}