/***
 *
 * Controller class for user.
 * @file TenantDetails.js
 * @description TenantDetails component
 * @author Neelesh Ghanghoriya
 * @since 12 may 2023
 *
 */

import React from "react";
// import PropTypes from 'prop-types';
import { useStyles } from "./TenantDetails.style.js";
import "./TenantDetails.scss";
import { Box, IconButton } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { tenantActions } from "../../redux/actions/tenant.actions.js";
import { useQuery } from "../../hooks/useQuery.js";
import _ from "lodash";
import PageHeader from "../PageHeader/index.js";
import { history } from "../../helpers/history.helper.js";
import CustomTypography from "../CustomTypography/index.js";
import ImageWrapper from "../ImageWrapper/index.js";
import StyledPopup from "../StyledPopup/index.js";
import ItemView from "../ItemView/index.js";

import editIcon from "../../assets/icons/edit.svg";
import deleteIcon from "../../assets/icons/delete.svg";
import { formatedDate } from "../../helpers/date.helper.js";
import userIcon from "../../assets/icons/user.svg";
import userDeactivate from "../../assets/icons/user-deactivate.svg";
import userActivate from "../../assets/icons/user-activate.svg";
import StyledPopupWrapper from "../StyledPopupWrapper/index.js";
import StyledButton from "../StyledButton/index.js";
import GatewayIcon from '../../assets/icons/singleantenna.svg'
import DetailIcon from '../../assets/icons/document.svg'
import StyledTabPanel from "../StyledTabPanel/index.js";
import DataNotFound from "../../pages/DataNotFound/index.js";
import CustomTable from "../CustomTable/index.js";
import { getMessageStr } from "../../helpers/message.helper.js";
import { confirmationDialog } from "../../helpers/device.helper.js";
import DebugGateway from "../DebugGateway/index.js";
import { deviceActions } from "../../redux/actions/device.actions.js";
import useTable from "../../hooks/useTable.js";

// const tenantDetailsLabel = {
//   tenantId:{ label:"TENANT ID"},
//   tenantName : {label:"TENANT NAME"},
//   corporationName: {label:"CORPORATION NAME"},
//   brandName: { label:"BRAND NAME"},
//   tenantStatus:{label:"TENANT STATUS"},
//   adminPhone:{label:"ADMIN CONTACT"},
//   adminEmail:{label:"ADMIN EMAIL"},
//   billingEmail: { label:"BILLING EMAIL"},
//   supportEmail: { label:"SUPPORT EMAIL" },
//   // logo: { label:"LOGO"},
//   primaryColor: { label:"PRIMARY COLOUR",
//                     component:<span style={{display: 'inline-block', borderRadius: '50%', padding: '5px', backgroundColor: color}}></span>},
//   secondaryColor: { label:"SECONDARY COLOUR"},
//   createdBy: { label: "CREATED BY" },
//   createdOn: { label: "CREATED ON" },
//   lastUpdatedBy: { label: "LAST UPDATED BY" },
//   lastUpdatedOn: { label: "LAST UPDATED ON" },
// };
const tabs = [{ id: "view", label: "Tenant Details", icon: DetailIcon, }, { label: "Gateways", id: "viewGateways", icon: GatewayIcon, }]
const summaryLabels = {
  createdBy: { label: "CREATED BY" },
  createdOn: { label: "CREATED ON" },
  lastUpdatedBy: { label: "LAST UPDATED BY" },
  lastUpdatedOn: { label: "LAST UPDATED ON" },
};

const TenantDetails = ({ ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const query = useQuery();
  const tenantName = query.get("name") || null;
  const operation = query?.get("op") || "view";
  const _tenant = useSelector((state) => state?.tenants?.tenantDetails);
  const _tenantWebconfig = useSelector(
    (state) => state?.tenants?.tenantWebConfig
  );
  const deviceList = useSelector(state => state?.devices?.deviceList)

  const [tenantDetails, setTenantDetails] = useState({});
  const [tenantLogo, setTenantLogo] = useState(null);
  const [toggleActivation, setToggleActivation] = useState(null);
  const [selectedTab, setSelectedTab] = useState(tabs[0]?.id);
  const [dialogWrapper, setDialogWrapper] = useState(false);
  const [dialogWrapperContent, setDialogWrapperContent] = useState({});
  const [dialog, setDialog] = useState(false);
  const [dialogContents, setDialogContents] = useState({});
  const { columns } = useTable({ setDialogWrapper, setDialogWrapperContents: setDialogWrapperContent, setDialog, setDialogContents, mqttClient: props?.mqttClient })

  const [table, setTable] = useState({ data: [] })

  const tenantDetailsLabel = {
    tenantId: { label: "TENANT ID" },
    tenantName: { label: "TENANT NAME" },
    corporationName: { label: "CORPORATION NAME" },
    brandName: { label: "BRAND NAME" },
    tenantStatus: { label: "TENANT STATUS" },
    contactPerson: { label: "ADMIN NAME" },
    adminPhone: { label: "ADMIN CONTACT" },
    adminEmail: { label: "ADMIN EMAIL" },
    billingEmail: {
      label: "BILLING EMAIL",
      type: "component",
      component: (
        <Box>
          <Box sx={{ display: "flex" }}>
            <CustomTypography
              sx={{
                fontSize: "0.8125rem",
                fontWeight: "400",
                color: "rgb(0, 0, 0)",
              }}
            >
              {tenantDetails?.billingEmail &&
                tenantDetails?.billingEmail.length > 0
                ? tenantDetails?.billingEmail
                  ?.slice(0, Math.min(tenantDetails?.billingEmail?.length, 3))
                  ?.map((email, index) => {
                    return <p key={index}>{email}</p>;
                  })
                : ""}
            </CustomTypography>
          </Box>
          <span
            className="expandLink"
            onClick={() => {
              setDialogWrapper(!dialogWrapper);
              setDialogWrapperContent({ title: "Billing Emails", data: tenantDetails?.billingEmail, operation: "showMore" })
            }}
          >
            View All
          </span>
        </Box>
      ),
    },
    supportEmail: {
      label: "SUPPORT EMAIL",
      type: "component",
      component: (
        <Box>
          <Box sx={{ display: "flex" }}>
            <CustomTypography
              sx={{
                fontSize: "0.8125rem",
                fontWeight: "400",
                color: "rgb(0, 0, 0)",
              }}
            >
              {tenantDetails?.supportEmail &&
                tenantDetails?.supportEmail?.length > 0
                ? tenantDetails?.supportEmail
                  ?.slice(0, Math.min(tenantDetails?.supportEmail.length, 3))
                  ?.map((email, index) => {
                    return <p key={index}>{email}</p>;
                  })
                : " "}
            </CustomTypography>
          </Box>
          <span
            className="expandLink"
            onClick={() => {
              setDialogWrapper(!dialogWrapper);
              setDialogWrapperContent({ title: "Support Emails", data: tenantDetails?.supportEmail, operation: "showMore" })
            }}
          >
            View All
          </span>
        </Box>
      ),
    },
  };

  const tenantWebLabel = {
    primaryColor: {
      label: "PRIMARY COLOUR",
      type: "component",
      component: (
        <div style={{ display: "flex" }}>
          <CustomTypography fontSize={"0.8125rem"} fontWeight={"400"}>
            {tenantDetails?.primaryColor}
          </CustomTypography>
          <div
            style={{
              borderRadius: "50%",
              height: "16px",
              width: "16px",
              marginLeft: "5px",
              backgroundColor: tenantDetails?.primaryColor,
            }}
          ></div>
        </div>
      ),
    },
    secondaryColor: {
      label: "SECONDARY COLOUR",
      type: "component",
      component: (
        <div style={{ display: "flex" }}>
          <CustomTypography fontSize={"0.8125rem"} fontWeight={"400"}>
            {tenantDetails?.secondaryColor}
          </CustomTypography>
          <div
            style={{
              borderRadius: "50%",
              height: "16px",
              width: "16px",
              marginLeft: "5px",
              backgroundColor: tenantDetails?.secondaryColor,
            }}
          ></div>
        </div>
      ),
    },
    primaryTextColor: {
      label: "PRIMARY Text COLOUR",
      type: "component",
      component: (
        <div style={{ display: "flex" }}>
          <CustomTypography fontSize={"0.8125rem"} fontWeight={"400"}>
            {tenantDetails?.primaryColor}
          </CustomTypography>
          <div
            style={{
              borderRadius: "50%",
              height: "16px",
              width: "16px",
              marginLeft: "5px",
              backgroundColor: tenantDetails?.primaryTextColor,
            }}
          ></div>
        </div>
      ),
    },
    secondaryTextColor: {
      label: "SECONDARY TEXT COLOUR",
      type: "component",
      component: (
        <div style={{ display: "flex" }}>
          <CustomTypography fontSize={"0.8125rem"} fontWeight={"400"}>
            {tenantDetails?.secondaryColor}
          </CustomTypography>
          <div
            style={{
              borderRadius: "50%",
              height: "16px",
              width: "16px",
              marginLeft: "5px",
              backgroundColor: tenantDetails?.secondaryTextColor,
            }}
          ></div>
        </div>
      ),
    },
  };

  const handleEdit = (event) => {
    const url = tenantDetails?.tenantName;
    history.push(`/tenants?op=edit&name=${url}`);
    dispatch(tenantActions.clearTenantDetails());
  };

  const handleDelete = () => {
    dispatch(tenantActions.deleteTenant(tenantDetails?.tenantName));
    history.push("/tenants");
  };

  useEffect(() => {
    if (tenantName) {
      dispatch(tenantActions.getTenantByName(tenantName));
      dispatch(tenantActions.getTenantWebconfig(tenantName));
      dispatch(deviceActions?.getAssignedGateways({}, tenantName, false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantName]);


  useEffect(() => {
    setTable({ ...table, data: deviceList })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceList])

  useEffect(() => {
    if (!_.isEmpty(_tenant)) {
      const tenantPayload = {
        tenantId: _tenant?.id || "",
        tenantName: _tenant?.tenantName || "",
        corporationName: _tenant?.corporationName || "",
        brandName: _tenant?.brandName || "",
        tenantStatus: _tenant?.enabled ? "enabled" : "disabled",
        adminPhone: _tenant?.contacts?.adminPhone || "",
        adminEmail: _tenant?.contacts?.adminEmail || "",
        contactPerson: _tenant?.contacts?.contactPerson || "",
        billingEmail: _tenant?.contacts?.billingEmail || [],
        supportEmail: _tenant?.contacts?.supportEmail || [],
        createdOn: _tenant?.createdOn
          ? formatedDate({
            date: _tenant.createdOn,
            format: "dd MMM,yyyy",
          })
          : "",
        createdBy: _tenant?.createdBy || "",
        lastUpdatedBy: _tenant?.lastUpdatedBy || "",
        lastUpdatedOn: _tenant?.lastUpdatedOn
          ? formatedDate({
            date: _tenant?.lastUpdatedOn,
            format: "dd MMM,yyyy",
          })
          : "",
        logo: _tenant?.webApp?.logoBitmap,
        primaryColor: _tenant?.webApp?.custom?.primaryColor?.backgroundColor,
        secondaryColor: _tenant?.webApp?.custom?.secondaryColor?.backgroundColor,
        primaryTextColor: _tenant?.webApp?.custom?.primaryColor?.foregroundColor,
        secondaryTextColor: _tenant?.webApp?.custom?.secondaryColor?.foregroundColor
      };
      setTenantDetails(tenantPayload);
      setToggleActivation(() => {
        return _tenant?.enabled ? true : false;
      });
      setTenantLogo(_tenant?.webApp?.logoBitmap);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_tenant]);

  useEffect(() => {
    setSelectedTab(operation)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operation])

  // useEffect(() => {
  //   setTenantDetails((prev) => {
  //     return {
  //       ...prev,
  //     };
  //   });
  //   setTenantLogo(_tenantWebconfig?.webApp?.logoBitmap);
  // }, [_tenantWebconfig]);

  return (
    <Box className={classes?.TenantDetails || ""} data-testid="TenantDetails">
      <PageHeader
        title={
          tenantDetails?.id ? tenantDetails.id : tenantDetails.tenantName || ""
        }
        bckbtn={true}
        icon={userIcon}
        backfunc={() => {
          history.goBack();
          dispatch(tenantActions.clearTenantDetails());
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <IconButton sx={{ p: 0, ml: 2 }} onClick={handleEdit}>
            <ImageWrapper
              className="action-icons pointer"
              src={editIcon}
              enablePopOver={true}
              hoverLabel="Edit Tenant"
              hoverstyle={{ fontSize: "14px", fontWeight: 600 }}
            />
          </IconButton>
          <IconButton
            sx={{ p: 0, ml: 2 }}
            onClick={() => {
              if (!toggleActivation) {
                dispatch(
                  tenantActions.enableTenant(tenantName, () => {
                    setTenantDetails(() => {
                      return {
                        ...tenantDetails,
                        tenantStatus: "enabled",
                      };
                    });
                    setToggleActivation(true);
                  })
                );
              } else {
                confirmationDialog("Disable Tenant", () => {
                  setDialog(false);
                  dispatch(
                    tenantActions.disableTenant(tenantName, () => {
                      setTenantDetails(() => {
                        return { ...tenantDetails, tenantStatus: "disabled" };
                      });
                      setToggleActivation(false);
                    })
                  );
                }, "disableTenant", setDialog, setDialogContents, tenantDetails?.tenantName);
              }
            }}
          >
            <ImageWrapper
              className="action-icons pointer"
              src={!toggleActivation ? userActivate : userDeactivate}
              enablePopOver={true}
              hoverLabel={!toggleActivation ? "Enable" : "Disable"}
              hoverstyle={{ fontSize: "14px", fontWeight: 600 }}
              height={"24px"}
              width={"24px"}
            />
          </IconButton>
          <IconButton
            sx={{ p: 0, ml: 2 }}
            onClick={() => {
              setDialog(true);
              confirmationDialog("Delete Tenant", () => {
                setDialog(false);
                handleDelete();
              }, "deleteTenant", setDialog, setDialogContents, tenantDetails?.tenantName)
            }}
          >
            <ImageWrapper
              className="action-icons pointer"
              src={deleteIcon}
              enablePopOver={true}
              hoverLabel="Delete Tenant"
              hoverstyle={{ fontSize: "14px", fontWeight: 600 }}
            />
          </IconButton>
        </Box>
      </PageHeader>
      <StyledTabPanel
        tabs={tabs}
        selectedIndex={tabs?.findIndex(t => t?.id === selectedTab)}
        onClick={(e, value) => {
          const url = `/tenants?op=${tabs[value]?.id}&name=${tenantDetails?.tenantName}`
          history?.push(url);
        }}
      >
        {
          selectedTab === "view" ?
            <Box>
              <Box className={classes?.HeaderDetails || ""}>
                <CustomTypography
                  content="Summary"
                  weight={700}
                  size={14}
                  lineHeight={14.63}
                  color="#000"
                />
              </Box>
              <Box sx={{ marginTop: "16px", padding: "16px" }}>
                <ItemView data={tenantDetails} headers={summaryLabels} />
              </Box>

              <Box className={classes?.HeaderDetails || ""}>
                <CustomTypography
                  content="Tenant Details"
                  weight={700}
                  size={14}
                  lineHeight={14.63}
                  color="#000"
                />
              </Box>
              <Box sx={{ marginTop: "16px", padding: "16px" }}>
                <ItemView data={tenantDetails} headers={tenantDetailsLabel} />
              </Box>

              <Box className={classes?.HeaderDetails || ""}>
                <CustomTypography
                  content="Tenant Web Configurations"
                  weight={700}
                  size={14}
                  lineHeight={14.63}
                  color="#000"
                />
              </Box>
              <Box sx={{ marginTop: "16px", padding: "16px" }}>
                {/* {JSON.stringify(tenantDetails)} */}
                <ItemView data={tenantDetails} headers={tenantWebLabel} />
              </Box>
            </Box>
            :
            <DataNotFound
              data={{
                content: getMessageStr("tenant-gateways-emptyList")
              }}
              show={table?.data.length === 0}
            >
              <CustomTable
                cellMatrix={table?.data}
                {...columns?.GatewayList}
                pagination={true}
              >
              </CustomTable>
            </DataNotFound>
        }
      </StyledTabPanel>

      {/* Deactivate user or delete tenant*/}
      <StyledPopup
        open={dialog}
        onClose={() => { setDialog(false) }}
        state={dialogContents?.state}
        data={dialogContents?.content}
        component={dialogContents?.component}
        customsx={{
          btns: { display: "flex", justifyContent: "center" },
        }}
      />
      <StyledPopupWrapper
        maxWidth={"lg"}
        fullWidth={dialogWrapperContent?.operation !== "showMore"}
        title={dialogWrapperContent?.title}
        open={dialogWrapper}
      >
        {
          dialogWrapperContent?.operation === "showMore" ?
            <Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {dialogWrapperContent?.data?.map((d, index) => {
                  return (
                    <p
                      key={index}
                      style={{
                        fontSize: "0.8125rem",
                        fontWeight: "400",
                        color: "rgb(0, 0, 0)",
                      }}
                    >
                      {d}
                    </p>
                  );
                })}
              </Box>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <StyledButton onClick={() => setDialogWrapper(false)}>
                  Close
                </StyledButton>
              </Box>
            </Box>
            :
            <DebugGateway mqttClient={props?.mqttClient} tenantDetails={tenantDetails} setDialog={setDialog} setDialogContents={setDialogContents} setDialogWrapper={setDialogWrapper} gateway={dialogWrapperContent?.data} />
        }

      </StyledPopupWrapper >
    </Box >
  );
};

TenantDetails.propTypes = {};

TenantDetails.defaultProps = {};

export default TenantDetails;
