import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    StyledCardWrapper: {
        position: "relative",
        padding: "20px",
        boxShadow: "0px 0px 7px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 1%), 0px 0px 0px 0px rgb(0 0 0 / 3%) !important",
        transition: "all 0.5s slide",
        '&:hover': {
            boxShadow: "0px 0px 7px 0px rgb(0 0 0 / 58%), 0px 0px 0px 0px rgb(0 0 0 / 70%), 0px 0px 0px 0px rgb(0 0 0 / 83%) !important"
        }
    }
});
