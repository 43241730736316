export const notificationAPIMessages={
    "subscribeNotification-post-200": "Successfully subscribed to notifications.",
    "subscribeNotification-post-400": "Unable to subscribe to notifications, please try again.",
    "subscribeNotification-post-403": "Unable to subscribe to notifications, please verify your access and try again.",
    "subscribeNotification-post-404": "Unable to subscribe to notifications, please try again later.",
    "subscribeNotification-post-502": "Unable to subscribe to notifications due to internal issue, please try again later.",
    "subscribeNotification-post-500": "Unable to subscribe to notifications due to internal issue, please try again later.",
    
    "unsubscribeNotification-post-200": "Successfully unsubscribed to notifications.",
    "unsubscribeNotification-post-400": "Unable to unsubscribe to notifications, please try again.",
    "unsubscribeNotification-post-403": "Unable to unsubscribe to notifications, please verify your access and try again.",
    "unsubscribeNotification-post-404": "Unable to unsubscribe to notifications, please try again later.",
    "unsubscribeNotification-post-502": "Unable to unsubscribe to notifications due to internal issue, please try again later.",
    "unsubscribeNotification-post-503": "Unable to unsubscribe to notifications due to internal issue, please try again later.",
    "unsubscribeNotification-post-500": "Unable to unsubscribe to notifications due to internal issue, please try again later.",
   
}