/***
 *
 * Controller class for user.
 * @file LeftSpaceList.js
 * @description LeftSpaceList component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { history, selectSpace} from '../../helpers';
import StyledSpaceTree from '../StyledSpaceTree';
// import PropTypes from 'prop-types';
import './LeftSpaceList.scss';

const LeftSpaceList = () => {
  const dispatch = useDispatch();
  const spaces = useSelector((state) => state?.spaces?.list);
  const activeSpace = useSelector(state=>state?.spaces?.selectedSpace);

  const [searchQuery, setSearchQuery] = useState('');
  

  const handleExpand = (event, data) => {
    setTimeout(() => {
      history.replace("/");
      selectSpace(data, dispatch);
    }, 10); 
  }
  //commenting as it causes dashboard menu to be selected everytime left space tree loads

  // useEffect(()=>{
  //   setTimeout(() => {
  //     const topMenus = JSON.parse(getSessionItem("miraie-top-menus") || "null");
  //     dispatch(appActions.selectTopMenu(topMenus?topMenus[0]?.id: defaultSelectedMenu?.top));
  //   }, 100);
  // }, [activeSpace])

  return (
    <Box className="LeftSpaceList" data-testid="LeftSpaceList">
      <StyledSpaceTree
        spaces={spaces}
        activeSpace={activeSpace}
        handleExpand={handleExpand}
        listId="spaceTree"
        actButtons={true}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />
    </Box>
  )
};

LeftSpaceList.propTypes = {};

LeftSpaceList.defaultProps = {};

export default LeftSpaceList;
