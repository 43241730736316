/***
 *
 * Controller class for user.
 * @file AssignedGateways.js
 * @description AssignedGateways component
 * @author Ayesha Kumar
 * @since 29 Sep 2023
 * 
 */

import { Box, Tooltip } from '@mui/material';
import React from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './AssignedGateways.style.js';
import PageHeader from '../../components/PageHeader/index.js';
import StyledButton from '../../components/StyledButton/index.js';
import ImageWrapper from '../../components/ImageWrapper/index.js';
import ListView from "../../assets/icons/list-view.svg";
import CardView from "../../assets/icons/card-view.svg";
import Gateway from "../../assets/icons/singleantenna.svg";
import DeleteIcon from "../../assets/icons/delete.svg"
import { useState } from 'react';
import StyledPopupWrapper from '../../components/StyledPopupWrapper/index.js';
import GridView from "../../components/GridView/index"
import FormWrapper from '../../components/FormWrapper/index.js';
import { getMessageStr } from '../../helpers/message.helper.js';
import CustomTable from '../../components/CustomTable/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { deviceActions } from '../../redux/actions/index.js';
import { getLocalItem } from '../../helpers/storage.helper.js';
import CustomCard from '../../components/CustomCard/index.js';
import { TableHelper } from '../../components/TableHelper/index.js';
import { deleteDevice, nameEditor } from '../../helpers/device.helper.js';
import DataNotFound from '../DataNotFound/index.js';
import { isAuthorised } from '../../helpers/users.helper.js';
import { features, permissions } from '../../constants/permission.constants.js';
import { selectSpace } from '../../helpers/space.helper.js';
import LocationSpaceTree from '../../components/LocationSpaceTree/index.js';
import { findNode } from '../../services/util.service.js';
import StyledPopup from '../../components/StyledPopup/index.js';
import { subscribe, unsubscribeAll } from '../../services/mqtt.service.js';
import _ from 'lodash';

const formConfig =
  [{
    section: { heading: null },
    columns: [
      {
        id: "2",
        type: "text",
        label: "Serial Number",
        name: "serialNumber",
        placeholder: "",
        required: true,
        validation: {
          req: [true, getMessageStr("device-serialNumber-required")],
          minLen: [6, getMessageStr("device-serialNumber-minlength")],
          maxLen: [50, getMessageStr("device-serialNumber-maxlength")],
          alphaNum: [true, getMessageStr("device-serialNumber-invalid")]
        },
        columnSize: 12,
      },
      {
        id: "4",
        type: "text",
        label: "MAC Address",
        name: "macAddress",
        placeholder: "",
        required: true,
        validation: {
          req: [true, getMessageStr("device-mac-required")],
          macAddress: [true, getMessageStr("device-mac-invalid")],
          minLen: [17, getMessageStr("device-mac-minlength")],
          maxLen: [24, getMessageStr("device-mac-maxlength")],
        },
        columnSize: 12,
      },
    ]
  }];

const columns = (dispatch, spaces, deviceCategories, setDialog, setDialogContents) => {
  return {
    name: {
      label: "name",
      type: "component",
      component: (row, sx = {}) => {
        return nameEditor(row, deviceCategories?.find(c => c?.category === row?.data?.category), sx, null, row?.data?.onboarded ? "/spaces/devices?pt=gateway" : null, false, false, () => {
          const space = findNode(spaces, "Spaces", { key: "spaceId", value: row?.data?.spaceId });
          selectSpace(space, dispatch);
        })
      },
      style: { minWidth: 200 }
    },
    modelNumber: {
      label: 'Model Number',
      style: { minWidth: 160 }
    },
    spaceName: {
      label: 'Space',
      // style: { minWidth: 160 }
    },
    fwVersion: { label: 'Gateway Application Version', style: { minWidth: 250 } },
    // "3rdPartyFwVersion": {
    //   label: 'Service Firmware Version', style: { minWidth: 210 },
    //   type: "component",
    //   component: (row) => {
    //     return <Box>
    //       {row?.data?.hasOwnProperty("3rdPartyFwVersion") && Array.isArray(row?.data["3rdPartyFwVersion"]) && row?.data["3rdPartyFwVersion"]?.map(v => {
    //         const version = v?.split("-");
    //         return <Box sx={{ display: "flex" }}>
    //           <CustomTypography
    //             weight={400}
    //             color={"#000"}
    //             size={13}
    //             lineHeight={19}
    //           >{_.capitalize(version[0]) + "  Firmware :"}</CustomTypography>
    //           <CustomTypography
    //             styles={{ marginLeft: "4px" }}
    //             weight={400}
    //             color={"#000"}
    //             size={13}
    //             lineHeight={19}
    //           >{" Version " + version[1]}</CustomTypography>
    //         </Box>
    //       })}
    //     </Box>
    //   }
    // },
    serialNumber: { label: "Serial Number", style: { minWidth: 160 } },
    macAddress: { label: "Mac address", type: "list", separator: " | ", style: { minWidth: 250 } },
    gatewayOnboarded: {
      label: "On boarded",
      type: "component",
      component: (row) => <TableHelper.TableStatus type={row?.data?.onboarded ? "onboarded" : "notOnboarded"} label={row?.data?.gatewayOnboarded} />,
      style: { minWidth: 135 }
    },
    userActions: {
      label: "Actions",
      actions: [
        {
          type: "editSpace",
          hidden: (row) => {
            return (row?.onboarded === false || !isAuthorised(features?.device, permissions?.moveDevice) || row?.status === "inactive")
          },
          component: (row) => {
            return row?.data?.deviceStatus?.connectionStatus?.onlineStatus === false ?
              (<Tooltip title="Unable to move offline device."
                arrow
                placement={"bottom"}
                enterDelay={50}
              >
                <span>
                  <LocationSpaceTree data={row?.data} disabled={row?.data?.deviceStatus?.connectionStatus?.onlineStatus === false} tooltip={row?.data?.deviceStatus?.connectionStatus?.onlineStatus === false ? "Unable to move offline device." : null} disableTooltip={row?.cardView} showLabel={row?.cardView} customsx={{ container: { px: row?.cardView && 2, py: row?.cardView && 1 }, icon: { ml: row?.cardView ? 0 : 1, mr: row?.cardView ? 1 : 0 } }} {...row} />
                </span>
              </Tooltip>)
              :
              <LocationSpaceTree data={row?.data} disabled={row?.data?.deviceStatus?.connectionStatus?.onlineStatus === false} tooltip={row?.data?.deviceStatus?.connectionStatus?.onlineStatus === false ? "Unable to move offline device." : null} disableTooltip={row?.cardView} showLabel={row?.cardView} customsx={{ container: { px: row?.cardView && 2, py: row?.cardView && 1 }, icon: { ml: row?.cardView ? 0 : 1, mr: row?.cardView ? 1 : 0 } }} {...row} />
          }
        },
        {
          label: "Delete device",
          icon: DeleteIcon,
          type: "delete",
          hidden: (row) => {
            if (row?.status === "inactive" && row?.onboarded === true) {
              return !isAuthorised(features?.device, permissions?.delete);
            }
            return true;
          },
          onClick: (event, data) => {
            deleteDevice(data, setDialog, setDialogContents, dispatch, () => {
              dispatch(deviceActions?.getAssignedGateways());
            });
          },
        },
      ]
    }
  }
}

const AssignedGateways = ({ mqttClient }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const deviceModels = useSelector(state => state.devices.deviceModels);
  const assignedGateways = useSelector(state => state?.devices?.deviceList);
  const spaces = useSelector((state) => state?.spaces?.list)
  const deviceCategories = useSelector((state) => state?.devices?.deviceCategories);
  const gwList = useSelector((state) => state?.devices?.gatewayList);

  const [view, setView] = useState("card");
  const [dialogWrapper, setDialogWrapper] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [dialogContents, setDialogContents] = useState(false);
  const [formData, setFormData] = useState({ serialNumber: "", macAddress: "" });
  const [table, setTable] = useState({ headers: columns(dispatch, spaces, deviceCategories, setDialog, setDialogContents), data: [] })
  const actionButtons = [
    {
      heading: null,
      columns: [
        {
          buttons: [
            {
              id: "1000",
              type: "button",
              display: true,
              variant: 'outlined',
              label: "Cancel",
              onClick: () => {
                setDialogWrapper(false);
              }
            },
            {
              id: "1000",
              type: "button",
              display: true,
              checkValidation: true,
              label: "Assign",
              onClick: (event, data) => {
                assignGateway(event, data);
              },
            },
          ],
          columnSize: 12,
        },
      ],
    },
  ];

  const assignGateway = (e, data) => {
    dispatch(deviceActions?.assignGateway({ ...data, tenantName: getLocalItem("tenant") }, () => {
      setDialogWrapper(false);
      dispatch(deviceActions?.getAssignedGateways());
    }))
  }


  useEffect(() => {
    dispatch(deviceActions?.getAssignedGateways())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceModels])

  useEffect(() => {
    setTable({ ...table, data: [...assignedGateways] })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedGateways])

  useEffect(() => {
    subscribe(gwList?.map(g => g?.mqttTopics?.comStatus), dispatch, mqttClient, "fwUpdateStatus");
    return () => {
      unsubscribeAll(mqttClient, dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gwList]
  )


  return (
    <Box className={classes?.AssignedGateways || ""} data-testid="AssignedGateways">

      <DataNotFound
        show={table?.data?.length > 0 ? false : true}
        customsx={{ image: { height: '202px', width: '100%' }, label: {}, content: {}, btns: {} }}
        data={{
          content: getMessageStr("assignedGateway-list-empty"),
          action: isAuthorised(features?.device, permissions?.add) &&
            [
              {
                id: "1001",
                type: "button",
                label: "Assign Gateway",
                onClick: () => { setDialogWrapper(true) },
              }]
        }}
      >
        <PageHeader
          backbtn={false}
          title={"Assigned Gateways"}
          icon={Gateway}
        >
          <Box sx={{ textAlign: "right" }}>
            <StyledButton
              iconButton
              tooltip={view === "card" ? "List View" : "Card View"}
              onClick={() => {
                setView(view === "card" ? "list" : "card");
              }}
            >
              <ImageWrapper src={view === "list" ? CardView : ListView}></ImageWrapper>
            </StyledButton>
            {isAuthorised(features?.device, permissions?.add) &&
              <StyledButton sx={{ ml: 1 }} onClick={() => setDialogWrapper(true)}>
                Assign Gateway
              </StyledButton>}
          </Box>
        </PageHeader>
        {
          view === "list" ?
            //list view for devices
            <Box className="Table">
              <CustomTable
                sorting={{ column: "name", order: "asc" }}
                // rowConfig={{ highlight: { bgKey: "gatewayOnboarded", bgValue: "Not Onboarded" }, disabled: { key: "gatewayOnboarded", value: "Not Onboarded" } }}
                cellMatrix={table?.data}
                headerList={table?.headers}
                searching={{ reset: true, columns: ["name", "modelNumber", "serialNumber", "gatewayOnboarded"] }}
                pagination={true}
                enableLoading={true}
                rowIdentifier={table?.data[0]?.deviceId && "deviceId"}
              // loader={loader}
              />
            </Box>
            :
            //card view for device
            <GridView data={table?.data}
              card={({ data }) => <CustomCard
                data={data}
                icon={<ImageWrapper height={24} width={24} src={Gateway} />}
                header={table?.headers?.name?.component({ data, cardView: true }, {
                  "fontSize": "1rem",
                  "& .link": {
                    "fontSize": "0.938rem"
                  }
                })}
                actions={table?.headers?.userActions?.actions?.filter(a => !a?.hidden(data)) || []}
                cardStatus={{ status: data?.onboarded ? "active" : "inactive", label: data?.gatewayOnboarded, tooltip: getMessageStr(`device-${data?.onboarded ? "onboarded" : "notOnboarded"}-tooltip`) }}
                contentHeaders=
                {(() => {
                  const headers = { ...table?.headers };
                  delete headers?.gatewayOnboarded;
                  delete headers?.name;
                  delete headers?.userActions;
                  return headers;
                })()}
              ></CustomCard>
              }>
            </GridView>
        }
      </DataNotFound>
      <StyledPopup
        open={dialog}
        onClose={() => { setDialog(false) }}
        state={dialogContents?.state}
        data={dialogContents?.content}
        component={dialogContents?.component}
        customsx={{
          label: {
            color: "red",
            fontWeight: 700,
            fontSize: "0.9375rem",
            paddingBottom: "16px",
          },
          btns: { display: "flex", justifyContent: "center" },
        }}
      />
      <StyledPopupWrapper title="Assign Gateway" open={dialogWrapper}>
        <FormWrapper formFields={formConfig} formButtons={actionButtons} formData={formData} customsx={{ formbtn: { my: 2 }, formdiv: { maxWidth: "390px !important" } }} />
      </StyledPopupWrapper>
    </Box>
  )
};

AssignedGateways.propTypes = {};

AssignedGateways.defaultProps = {};

export default AssignedGateways;
