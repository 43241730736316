import { Box, TextField, inputAdornmentClasses } from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { tenantActions } from "../../redux/actions";
import FormWrapper from "../FormWrapper";
import PageHeader from "../PageHeader";
import { getMobileNumber, history } from "../../helpers";
import _ from "lodash";
import "./AddEditTenant.scss";
import userIcon from "../../assets/icons/user.svg";
import { countryCodes } from "../../constants";
import { getMessageStr } from "../../helpers";

const frmConfig = [
  {
    section: {
      heading: {
        text: "Tenant Details",
        style: { fontWeight: "700", marginBottom: "16px" },
      },
      style: { border: "1px solid #EAEAEA", padding: "20px 24px" },
    },
    columns: [
      {
        id: "1",
        type: "text",
        label: "Tenant Name",
        name: "name",
        placeholder: "Enter Name",
        disabledOnEdit:true,
        required: true,
        validation: {
          req: [true, getMessageStr("tenant-addTenantFormValidation-name")],
          minLen: [
            3,
            getMessageStr("tenant-addTenantFormValidation-nameMinLength"),
          ],
          maxLen: [
            8,
            getMessageStr("tenant-addTenantFormValidation-nameMaxLength"),
          ],
          customRegex: [/^[a-z]+$/,
            getMessageStr("tenant-addTenantFormValidation-nameAplhaValidation"),
          ],
        },
        columnSize: 4,
      },
      {
        id: "2",
        type: "text",
        label: "Corporation Name",
        name: "corporationName",
        placeholder: "Enter your corporation name",
        required: true,
        validation: {
          req: [
            true,
            getMessageStr("tenant-addTenantFormValidation-corporationName"),
          ],
          minLen: [
            3,
            getMessageStr(
              "tenant-addTenantFormValidation-corporationNameMinLength"
            ),
          ],
          maxLen: [
            30,
            getMessageStr(
              "tenant-addTenantFormValidation-corporationNameMaxLength"
            ),
          ],
          customRegex:[/^[A-Za-z\s]*$/,
            getMessageStr(
              "tenant-addTenantFormValidation-corporationNameAplhaValidation"
            ),
          ],
        },
        columnSize: 4,
      },
      {
        id: "3",
        type: "text",
        label: "Brand Name",
        name: "brandName",
        placeholder: "Enter",
        required: true,
        validation: {
          req: [
            true,
            getMessageStr("tenant-addTenantFormValidation-brandName"),
          ],
          minLen: [3, getMessageStr("tenant-addTenantFormValidation-brandNameMinLength")],
          maxLen: [
            30,
            getMessageStr("tenant-addTenantFormValidation-brandNameMaxLength  "),
          ],
          customRegex:[/^[A-Za-z\s]*$/,,
            getMessageStr(
              "tenant-addTenantFormValidation-brandNameAplhaValidation"
            ),
          ],
        },
        columnSize: 4,
      },
      {
        id: "8",
        type: "text",
        label: "Admin Name",
        name: "contactPerson",
        placeholder: "Enter contact person name",
        required: true,
        validation: {
          req: [
            true,
            getMessageStr("tenant-addTenantFormValidation-adminName"),
          ],
          minLen: [3, getMessageStr("tenant-addTenantFormVaidation-adminName")],
          maxLen: [
            30,
            getMessageStr("tenant-addTenantFormValidation-adminName"),
          ],
          customRegex:[/^[A-Za-z\s]*$/,
            getMessageStr(
              "tenant-addTenantFormValidation-adminNameAplhaValidation"
            ),
          ],
        },
        columnSize: 4,
      },
      {
        id: "4",
        type: "text",
        label: "Admin Email",
        name: "adminEmail",
        placeholder: "Type email and press enter to add more emails",
        visibleIn: ["create", "edit"],
        required: true,
        validation: {
          req: [
            true,
            getMessageStr("tenant-addTenantFormValidation-adminEmail"),
          ],
          email: [
            true,
            getMessageStr("tenant-addTenantFormValidation-adminEmailInvalid"),
          ],
        },
        columnSize: 4,
      },
      {
        id: "5",
        type: "selectTextField",
        label: "Admin Contact",
        name: "adminPhone",
        required: true,
        defaultSelect: "+91",
        defaultText: "",
        placeholder: "Enter",
        required: true,
        requireOpen: true,
        isNoneAsItem: false,
        partition: (data) => {
          return getMobileNumber(data);
        },
        validation: {
          req: [
            true,
            getMessageStr("tenant-addTenantFormValidation-adminPhone"),
          ],
          phonereq: [
            true,
            getMessageStr("tenant-addTenantFormValidation-adminPhone"),
          ],
          phonenumber: [
            true,
            getMessageStr("tenant-addTenantFormVaildation-adminPhoneInvalid"),
          ],
        },
        columnSize: 4,
      },
      {
        id: "6",
        type: "text",
        label: "Billing Email",
        name: "billingEmail",
        chipsPosition: "right",
        placeholder: "Add Billing Email",
        disabledOnEdit: false,
        visibleIn: ["edit", "create"],
        required: true,
        validation: {
          req: [
            true,
            getMessageStr("tenant-addTenantFormValidation-billingEmail"),
          ],
          email: [
            true,
            getMessageStr("tenant-addTenantFormValidation-billingEmailInvalid"),
          ],
        },
        columnSize: 4,
      },
      {
        id: "7",
        type: "text",
        label: "Support Email",
        name: "supportEmail",
        chipsPosition: "right",
        placeholder: "Add Support Email",
        visibleIn: ["edit", "view", "create"],
        required: true,
        validation: {
          req: [
            true,
            getMessageStr("tenant-addTenantFormValidation-supportEmail"),
          ],
          email: [
            true,
            getMessageStr("tenant-addTenantFormValidation-supportEmailInvalid"),
          ],
        },
        columnSize: 4,
      },
    ],
  },
  {
    section: {
      heading: {
        text: "Tenant Web Configuration",
        style: { fontWeight: "700", marginBottom: "16px" },
      },
      style: { border: "1px solid #EAEAEA", padding: "20px 24px" },
    },
    columns: [
      {
        id: "8",
        type: "file",
        label: "Upload Logo",
        allowedFileType: "image/*, .png, .svg,.jpeg,.pdf",
        name: "logo",
        confirm: true,
        multiple: false,
        attachment: true,
        placeholder: "Select a file to upload.",
        format: ["jpg", "jpeg", "svg", "png", "pdf"],
        onTop: false,
        required: true,
        // preview:{width:"250px", "height":"100px"},
        customsx:{outerdiv:{minHeight:'150px', maxWidth:""}},
        validation: {
          req: [true, getMessageStr("tenant-addTenantFormValidation-logo")],
          //   fileSize: [5000, "file size should be less then 5 MB"],
        },
        columnSize: 8,
      },
      {
        id: "9",
        type: "colorPicker",
        label: "Add Primary Color",
        name: "primaryColor",
        format: "hex",
        placeholder: "Enter Hex code",
        required: true,
        validation: {
          req: [
            true,
            getMessageStr("tenant-addTenantFormValidation-primaryColor"),
          ]
        },
        columnSize: 5,
      },
      {
        id: "10",
        type: "colorPicker",
        label: "Add Secondary Color",
        name: "secondaryColor",
        placeholder: "Enter Hex code",
        required: true,
        format: "hex",
        validation: {
          req: [
            true,
            getMessageStr("tenant-addTenantFormValidation-secondaryColor"),
          ],
        },
        columnSize: 5,
      },
      {
        id: "9",
        type: "colorPicker",
        label: "Add Primary Text Color",
        name: "primaryTextColor",
        format: "hex",
        placeholder: "Enter Hex code",
        required: true,
        validation: {
          req: [
            true,
            getMessageStr("tenant-addTenantFormValidation-primaryTextColor"),
          ],
        },
        columnSize: 5,
      },
      {
        id: "10",
        type: "colorPicker",
        label: "Add Secondary Text Color",
        name: "secondaryTextColor",
        placeholder: "Enter Hex code",
        required: true,
        format: "hex",
        validation: {
          req: [
            true,
            getMessageStr("tenant-addTenantFormValidation-secondaryTextColor"),
          ],
        },
        columnSize: 5,
      },
    ]},
];

const opt = {
  dropdowns: {
    adminPhone: {
      list: countryCodes,
    },
  },
};

const AddEditTenant = ({ operation, tId }) => {
  const dispatch = useDispatch();
  const _tenantDetails = useSelector((state) => state?.tenants?.tenantDetails);
  const _tenantWebconfig = useSelector(
    (state) => state?.tenants?.tenantWebConfig
  );

  const [tenantDetails, setTenantDetails] = useState({});
  // const [tenantWebConfig, setTenantWebconfig] = useState({});
  const [frm, setFrm] = useState(null);
  const [frmOptions, setFrmOptions] = useState(opt);
  const [tenantData, setTenantData] = useState(null);
  const [tenantName, setTenantName] = useState(tId);
  
  const [preview, setPreview] = useState({
    primaryColor:'#f2f2f2',
    secondaryColor:'#f2f2f2',
    primaryTextColor:'#333333',
    secondaryTextColor:'#333333'
  })

  const handleSubmit = (data) => {
    const payload = {
      brandName: data?.brandName || "",
      corporationName: data?.corporationName || "",
      contacts: {
        adminEmail: data?.adminEmail || "",
        adminPhone: data?.adminPhone || "",
        billingEmail: [data?.billingEmail || ""],
        supportEmail: [data?.supportEmail || ""],
        contactPerson:data?.contactPerson || "",
      },
    };
    if (operation === "create") {
      payload["enabled"] = true;
      payload["tenantName"] = data?.name || "";
      dispatch(
        tenantActions.createTenant(payload, () => {
          const webconfigPayload = {
            webApp: {
              custom:{
                primaryColor:{
                  "foregroundColor":data?.primaryTextColor,
                  "backgroundColor":data?.primaryColor
                },
                secondaryColor:{
                  "foregroundColor":data?.secondaryTextColor,
                  "backgroundColor":data?.secondaryTextColor

                }
              },
              primaryColor: data?.primaryColor,
              secondaryColor: data?.secondaryColor,
              logoBitmap: data?.logo?.base64 || data?.logo?.file?.logo || "",
            },
          };

          dispatch(
            tenantActions.updateTenantWebConfig(data?.name, webconfigPayload)
          );
          history.push("/tenants");
        })
      );
    } else {
      dispatch(
        tenantActions.updateTenant(tId, payload, () => {
          const webconfigPayload = {
            webApp: {
              custom:{
                primaryColor:{
                  "foregroundColor":data?.primaryTextColor,
                  "backgroundColor":data?.primaryColor
                },
                secondaryColor:{
                  "foregroundColor":data?.secondaryTextColor,
                  "backgroundColor":data?.secondaryTextColor

                }
              },
              primaryColor: data?.primaryColor,
              secondaryColor: data?.secondaryColor,
              logoBitmap: data?.logo?.base64 || data?.logo?.file?.logo || "",
            },
          };

          dispatch(
            tenantActions.updateTenantWebConfig(data?.name, webconfigPayload)
          );
          history.push("/tenants");
        })
      );
    }
    setTenantData(data);
  };

  const handleBackStep = (event) => {
    history.push("/tenants");
  };

  const actionButtons = [
    {
      heading: null,
      columns: [
        {
          heading: null,
          buttons: [
            {
              id: "cancel",
              type: "button",
              display: true,
              variant: "outlined",
              label: "Cancel",
              onClick: (event, data) => {
                handleBackStep(event);
              },
            },
            {
              id: "1000",
              type: "button",
              display: true,
              variant: "contained",
              color: "primary",
              checkValidation: true,
              label: "Save",
              onClick: (event, data) => {
                handleSubmit(data);
              },
            },
          ],
          columnSize: 12,
        },
      ],
    },
  ];

  useEffect(() => {
    if (tId && operation !== "create") {
      dispatch(tenantActions.getTenantByName(tId));
      dispatch(tenantActions.getTenantWebconfig(tId));
    }
  }, []);

  useEffect(() => {
    if (_.isEmpty(tId) && _.isEmpty(tenantName) && !_.isEmpty(_tenantDetails)) {
      setTenantName(_tenantDetails?.tenantName);
    }

    if (!_.isEmpty(_tenantDetails)) {
      const tenantPayload = {
        brandName: _tenantDetails?.brandName,
        corporationName: _tenantDetails?.corporationName,
        contactPerson:_tenantDetails?.contacts?.contactPerson,
        adminEmail: _tenantDetails?.contacts?.adminEmail,
        adminPhone: _tenantDetails?.contacts?.adminPhone,
        billingEmail: _tenantDetails?.contacts?.billingEmail[0],
        supportEmail: _tenantDetails?.contacts?.supportEmail[0],
        name: _tenantDetails?.tenantName,
        id: _tenantDetails?.id,
      };
      setTenantDetails((prev) => {
        return {
          ...prev,
          ...tenantPayload,
        };
      });
    }
  }, [_tenantDetails]);

  useEffect(() => {
      setTenantDetails((prev) => {
        return {
          ...prev,
          logo: _tenantWebconfig?.webApp?.logoBitmap || "",
          primaryColor: _tenantWebconfig?.webApp?.custom?.primaryColor?.backgroundColor || "",
          secondaryColor: _tenantWebconfig?.webApp?.custom?.secondaryColor?.backgroundColor || "",
          primaryTextColor: _tenantWebconfig?.webApp?.custom?.primaryColor?.foregroundColor,
          secondaryTextColor: _tenantWebconfig?.webApp?.custom?.secondaryColor?.foregroundColor
        };
      });
  }, [_tenantWebconfig]);
  
  useEffect(()=>{
    if(frm && frm.primaryColor)
    {
      setPreview((prev)=>{
        return {
        ...prev,
        "primaryColor":frm.primaryColor
        }
      })
    }
    if(frm && frm.primaryTextColor)
    {
      setPreview((prev)=>{
        return {
        ...prev,
        "primaryTextColor":frm.primaryTextColor
        }
      })
    }
    if(frm && frm.secondaryColor)
    {
      setPreview((prev)=>{
        return {
        ...prev,
        "secondaryColor":frm.secondaryColor
        }
      })
    }
    if(frm && frm.secondaryTextColor)
    {
      setPreview((prev)=>{
        return {
        ...prev,
        "secondaryTextColor":frm.secondaryTextColor
        }
      })
    }

  }, [frm?.primaryColor, frm?.primaryTextColor, frm?.secondaryColor, frm?.secondaryTextColor])

  return (
    <Box sx={{ maxWidth: "100%" }} data-testid="AddEditTenant">
      <PageHeader
        title={
          operation === "edit"
            ? "Edit Tenant Details"
            : operation === "create"
            ? "Add New Tenant"
            : "View Tenant Details"
        }
        icon={userIcon}
        bckbtn={true}
      />
      <FormWrapper
        operation={operation}
        formData={tenantDetails}
        setPayload={setFrm}
        formFields={frmConfig}
        formOptions={frmOptions}
        formButtons={actionButtons}
      >
        <Box sx={{display:'flex', flexDirection:'column'}}>
          <Box sx={{display:'flex'}}>
            <label style={{background:preview?.primaryColor,color:preview?.primaryTextColor, width:"340px", height:"40px", marginRight:'68px'}}>Primary Selection Preview</label>
            <label style={{background:preview?.secondaryColor,color:preview?.secondaryTextColor, width:'340px', height:"40px"}}>Secondary Selection Preview</label>
          </Box>
        <Box sx={{display:'block'}}>
          <label className="mandatoryLabel">
            Fields marked <label style={{ color: "#BC2200" }}>*</label>are
            mandatory.
          </label>
       </Box> 
       </Box>
      </FormWrapper>
    </Box>
  );
};

AddEditTenant.propTypes = {};

AddEditTenant.defaultProps = {};

export default AddEditTenant;
