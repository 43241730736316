/***
 *
 * Controller class for user.
 * @file AreaDragDropWrapper.js
 * @description AreaDragDropWrapper component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useState, useEffect} from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './AreaDragDropWrapper.style.js';
import './AreaDragDropWrapper.scss';
import { Box, Grid } from '@mui/material';
import DragAndDropColumnsWrapper from '../DragAndDropColumnsWrapper/index.js';
import { useDispatch, useSelector } from 'react-redux';
import Group from '../../assets/icons/group.svg';
import Sensor from '../../assets/icons/sensor.svg';
import _ from 'lodash';
import { dragAndDropActions } from '../../redux/actions/dragAndDrop.actions.js';

const AreaDragDropWrapper = ({ifEdit=false, ifMovedToNextStep}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const preSelectedArea = useSelector(state => state?.areas?.item)
  const groupList = useSelector(state => state?.groups?.list)
  const sensorList = useSelector(state => state?.devices?.deviceList)
  const dropList = useSelector(state => state?.dragAndDrops?.dropList);

  const [dropListIds, setDropListIds] = useState([])
  const [completeList, setCompleteList] = useState([])
  
  useEffect(() => {
    let sensors = sensorList?.filter(s=>s?.status==="active");
    sensors=sensors.map(item => {
      item.id = item.deviceId
      item.type = 'SENSOR'
      item.icon = Sensor
      return item
    })
    let groups = groupList.map( item => {
      item.id = item.groupId
      item.type = 'GROUP'
      item.icon = Group
      return item
    })
    setCompleteList([...sensors,...groups])
  }, [sensorList, groupList])

  useEffect(() => {
    if(ifEdit && !ifMovedToNextStep && !_.isEmpty(completeList)){
      let initialSensorList = preSelectedArea.sensorIds?.map(item=>item?.sensorId) || []
      let initialGroupList = preSelectedArea.groupIds?.map(item=>item?.groupId) || []
      let initialDropListIds = [...initialSensorList,...initialGroupList]
      let dropListToBeSet = completeList.filter(item => initialDropListIds.includes(item?.id));
      dispatch(dragAndDropActions.setDropList(dropListToBeSet))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preSelectedArea,completeList])

  useEffect(() => {
    let droppedIds = dropList.map(item => item?.id)
    setDropListIds(droppedIds)
    if(!_.isEmpty(dropList)){
      dispatch(dragAndDropActions.setErrorMessage(''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropList])

  useEffect(() => {
    let dragListToBeSet = [...completeList]
    if(!_.isEmpty(dropListIds)){
      dragListToBeSet = dragListToBeSet.filter(item => !dropListIds.includes(item?.id));
    }
    dispatch(dragAndDropActions.setDragList(dragListToBeSet))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completeList,dropListIds])

    return (
        <Box className={classes?.AreaDragDropWrapper || ""} data-testid="AreaDragDropWrapper">
          <Grid container >
            <DragAndDropColumnsWrapper forList={'Sensors/Groups'} typeCreated={'Area'}/>
          </Grid>
            
        </Box>
    )
};

AreaDragDropWrapper.propTypes = {};

AreaDragDropWrapper.defaultProps = {};

export default AreaDragDropWrapper;
