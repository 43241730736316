/***
 *
 * Controller class for user.
 * @file ControllerDetails.js
 * @description ControllerDetails component
 * @author Ayesha Kumar
 * @since  23 Aug 2022
 *
 */
import React, { useEffect, useState } from "react";
import "./ControllerDetails.scss";
import { Box, Divider, Grid, Typography } from "@mui/material";
import PageHeader from "../PageHeader";
import CustomTable from "../CustomTable";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";

import StyledPopup from "../StyledPopup";
import CustomAccordion from "../CustomAccordion";
import {
  areaActions,
  deviceActions,
  fotaActions,
  groupActions,
  sceneActions,
} from "../../redux/actions";
import ControllerToolbar from "./ControllerToolbar";
import StyledTabs from "../StyledTabs";
import { useQuery } from "../../hooks";
import PopoverContainer from "../PopoverContainer";
import Lighting from "../../assets/icons/bulb.svg"
import DaliLight from "../../assets/icons/dalilight.svg"
import AnalogLight from "../../assets/icons/analoglight.svg"
import Receiver from "../../assets/icons/controller.svg"
import Switch from "../../assets/icons/switch.svg"
import Identify from "../../assets/icons/identify.svg"
import Sync from "../../assets/icons/sync.svg";
import ItemView from "../ItemView";
import useTable from "../../hooks/useTable";
import { channelReset, confirmationDialog, disableDeviceOperation, factoryReset, getDeviceDetailHeaders, getLightSpacePaths, identifyDevice, isAuthorised, setGroupDeleteTimeout, setSceneDeleteTimeout, systemReset } from "../../helpers";
import DeviceConfigurations from "../DeviceConfigurations";
import { history } from "../../helpers";
import AddToolbar from "./AddToolbar";
import { formatString, subscribe, unsubscribeAll } from "../../services";
import CustomTypography from "../CustomTypography";
import ActivityLog from "../ActivityLog";
import ImageWrapper from "../ImageWrapper";
import { features, permissions } from "../../constants";
import PhysicalParamters from "../PhysicalParameters";
import IntensitySlider from "../IntensitySlider";
import TemperatureSlider from "../TemperatureSlider";
import { useStyles } from "./controllerDetails.style";
import activityLog from "../../assets/icons/activitylog.svg"
import areaImg from "../../assets/icons/area.svg";
import groupIcon from "../../assets/icons/group.svg";
import sceneIcon from "../../assets/icons/scene.svg";
import sensorIcon from "../../assets/icons/sensor.svg";
import lampIcon from '../../assets/icons/lamp.svg';
import systemLogIcon from "../../assets/icons/systemlog.svg"
import SystemLog from "../SystemLog";
import AggregatorIcon from "../../assets/icons/doubleantenna.svg";
import ControllerIcon from "../../assets/icons/controller.svg";
import StyledPopupWrapper from "../StyledPopupWrapper";
import StyledSpinner from "../StyledSpinner";
// import { tenantName } from "../../config/appConfig";
import StyledButton from "../StyledButton";
import deleteIcon from '../../assets/icons/delete.svg';
import { groupDeleteTimeout, sceneDeleteTimeout } from "../../config/mqttConfig";
import StyledChip from "../StyledChip";
import { deviceTypes } from "../../redux/types";
import StyledCardWrapper from "../StyledCardWrapper";
import StyledGraph from "../StyledGraph";
import ControllerSlider from "../DeviceController/ControllerSlider";

const groupCountData = [
  { label: "Relay Groups", category: ["RelayLightController"], prop: "totalGroup" },
  { label: "Analog Groups", category: ["AnalogLightController"], prop: "totalGroup" },
  { label: "Analog Groups", category: ["DaliAnalogLightController"], prop: "AnalogLightDriver" },
  { label: "Dali Channel 1 Groups", category: ["DaliLightController", "DaliAnalogLightController"], prop: "L01" },
  { label: "Dali Channel 2 Groups", category: ["DaliLightController", "DaliAnalogLightController"], prop: "L02" }];
const tabs = [
  {
    id: "Device", icon: Lighting,
    label: "Devices",
    categories: [
      "DaliAnalogLightController",
      "DaliLightController",
      "AnalogLightController",
      "RelayLightController",
      "VortexGateway",
      "Receiver",
      "Aggregator"
    ],
    children: [
      {
        icon: DaliLight,
        label: "Dali Lights",
        category: "DaliLightDriver",
        type: "DaliLightDriver",
        page: "controller",
        parentCategories: [
          "DaliAnalogLightController",
          "DaliLightController"
        ],
        channels: [
          { category: "L01", label: "Channel 1" },
          { category: "L02", label: "Channel 2" },
        ],
        controllerToolbar: { broadcast: true, commission: true, discover: true }
      },
      {
        icon: AnalogLight,
        label: "Analog Lights",
        category: "AnalogLightDriver",
        type: "AnalogLightDriver",
        page: "controller",
        parentCategories: [
          "DaliAnalogLightController",
          "AnalogLightController"
        ]
      },
      {
        icon: lampIcon,
        label: "Relay Lights",
        category: "RelayLightDriver",
        type: "RelayLightDriver",
        page: "controller",
        parentCategories: [
          "RelayLightController"
        ]
      },
      {
        icon: Receiver,
        label: "Receivers",
        category: "Receiver",
        type: "Receiver",
        page: "controller",
        parentCategories: [
          "DaliAnalogLightController",
          "DaliLightController",
          "AnalogLightController",
          "RelayLightController"
        ],
        controllerToolbar: { commission: true, discover: true }
      },
      {
        icon: sensorIcon,
        label: "Sensors",
        category: "Sensor",
        type: "Sensor",
        page: "device",
        parentCategories: [
          "Receiver"
        ]
      },
      {
        icon: sensorIcon,
        label: "Sensors",
        category: "Sensor",
        type: "WirelessSensor",
        page: "device",
        parentCategories: [
          "Aggregator"
        ],
        controllerToolbar: { broadcast: true, discover: true }
      },
      {
        icon: Switch,
        label: "Switches",
        category: "Switch",
        type: "Switch",
        page: "device",
        parentCategories: [
          "Receiver", "Aggregator"
        ]
      },
      {
        icon: ControllerIcon,
        label: "Controllers",
        category: "Controller",
        type: "Controller",
        page: "device",
        parentCategories: [
          "VortexGateway"
        ]
      },
      {
        icon: AggregatorIcon,
        label: "Aggregators",
        category: "Aggregator",
        type: "Aggregator",
        page: "device",
        parentCategories: [
          "VortexGateway"
        ]
      },
      // {
      //   icon: CameraIcon,
      //   label: "Cameras",
      //   category: "CAMERA",
      //   type: "Camera",
      //   page: "device",
      //   parentCategories: [
      //     "VortexGateway"
      //   ]
      // }
    ]
  },
  { id: "Configuration", icon: groupIcon, categories: ["DaliLightDriver", "AnalogLightDriver", "RelayLightDriver", "Sensor", "Switch"], label: "Configuration" },
  { id: "Group", icon: groupIcon, categories: ["DaliAnalogLightController", "DaliLightController", "AnalogLightController", "RelayLightController", "VortexGateway", "Aggregator"], label: "Groups" },
  { id: "Scene", icon: sceneIcon, categories: ["DaliAnalogLightController", "DaliLightController", "AnalogLightController", "RelayLightController", "VortexGateway", "Aggregator"], label: "Scenes" },
  { id: "Area", icon: areaImg, categories: ["DaliAnalogLightController", "DaliLightController", "AnalogLightController", "RelayLightController"], label: "Areas" },
  { id: "deviceLog", icon: activityLog, categories: ["DaliAnalogLightController", "DaliLightController", "AnalogLightController", "RelayLightController", "VortexGateway", "Aggregator", "Sensor", "Switch", "Receiver", "DaliLightDriver", "AnalogLightDriver", "RelayLightDriver"], label: "Activity Logs" },
  { id: "systemLog", icon: systemLogIcon, categories: ["VortexGateway"], label: "System Logs" }

]

const cameraList = [
  {
    "deviceId": "OOgG",
    "gwDeviceId": "NfnN",
    "spaceId": "mbCg",
    "name": "Camera-01",
    "modelNumber": "DS-2CV2Q21FD-IW",
    "serialNumber": "",
    "macAddress": "",
    "ipAddress": "10.2.3.4",
    "modelId": "810120",
    "category": "CAMERA",
    "brand": "PSN",
    "connectionType": "wired",
    "mqttTopics": {
      "control": "psn/OOgG/control/AnalogLightDriver/kDH8/jhqv/tmQ7/mbCg",
      "status": "psn/OOgG/status/AnalogLightDriver/kDH8/jhqv/tmQ7/mbCg",
      "connectionStatus": "psn/OOgG/connectionstatus/AnalogLightDriver/kDH8/jhqv/tmQ7/mbCg"
    },
    "configuration": {
      "lightId": "L07"
    },
    "sync": true,
    "deviceStatus": {
      "status": {
        "intensity": 0,
        "ts": 1717152355585
      },
      "lastUpdatedOn": "2024-05-31T10:45:57.219Z"
    },
    "status": "active",
    "createdOn": 1714733738897,
    "createdBy": "",
    "lastUpdatedOn": 1714733738896,
    "lastUpdatedBy": ""
  }
];

const LightChannel = [
  { category: "L01", label: "Channel 1" },
  { category: "L02", label: "Channel 2" },
];

const ControllerDetails = ({ mqttClient }) => {
  const query = useQuery();
  const classes = useStyles();
  const deviceType = query.get("pt") || "";
  const deviceId = query.get("id") || "";
  const category = query.get("category") || "";
  const hTab = parseInt(query.get("hTab") || 0);
  const sTab = parseInt(query.get("sTab") || 0);

  const device = useSelector((state) => state?.devices?.deviceDetails);
  const areaList = useSelector(state => state?.areas?.list)
  const deviceList = useSelector((state) => state?.devices?.deviceList);
  const controllerList = useSelector((state) => state?.devices?.controllerList);
  const lastActionOnDevice = useSelector((state) => state?.devices?.lastAction);
  const firmwareDetails = useSelector((state) => state?.fota?.deviceList[0]);
  const fwUpdateStatus = useSelector((state) => state?.fota?.lastAction);
  // const groupList = useSelector((state) => state?.groups?.item);
  const groupList = useSelector((state) => state?.groups?.list);
  const scenes = useSelector((state) => state?.scenes?.sceneList);
  const rootSpace = useSelector((state) => state?.spaces?.rootSpace);
  const deviceModels = useSelector((state) => state.devices.deviceModels);
  const deviceCategory = useSelector((state) => state.devices.deviceCategories?.find(c => c?.category === device?.category));
  const gateway = useSelector((state) =>
    state?.devices?.gatewayList.find(
      (item) => item.deviceId === device?.gwDeviceId
    )
  );
  const groupDeleted = useSelector((state) => state?.groups?.groupDeleted);
  const sceneDeleted = useSelector((state) => state?.scenes?.sceneDeleted);
  const areaDeleted = useSelector((state) => state?.areas?.areaDeleted);
  const groupCount = useSelector((state) => state?.groups?.groupCount);



  const dispatch = useDispatch();


  const [deviceDetails, setDeviceDetails] = useState(null);
  const [toggleDevice, setToggleDevice] = useState("Devices");
  const [sceneList, setSceneList] = useState([]);
  const [lightSelector, setLightSelector] = useState(null);
  // const [popoverAnchor, setPopoverAnchor] = useState(null);
  // const [showToolTip, setShowToolTip] = useState(false);
  // const [popoverAnchorToolTip, setPopoverAnchorToolTip] = useState(null);
  const [popoverAnchorToolTipContent, setPopoverAnchorToolTipContent] = useState('');
  const [openPopover, setOpenPopover] = useState({ target: null });
  const [gatewayDetails, setGatewayDetails] = useState(null);
  const [dialogContents, setDialogContents] = useState(null);//styledpopup variable
  const [dialogBoxContents, setDialogBoxContents] = useState(null);//styledpopupwrapper variable
  const [dialog, setDialog] = useState(false);//styledpopup variable
  const [dialogBox, setDialogBox] = useState(false);//styledpopupwrapper variable
  const [headerTabs, setHeaderTabs] = useState([]);
  const [subTabs, setSubTabs] = useState([]);
  const [controllerBusy, setControllerBusy] = useState(false);
  const [controllerGroupCount, setControllerGroupCunt] = useState(groupCount);

  const [selectedTab, setSelectedTab] = useState({
    selectedView: tabs[0],
    selectedDeviceType: tabs[0].children.filter((c) => c?.parentCategories?.includes(device?.category))[0],
    selectedChannels: tabs[0]?.children[0]?.channels[0]
    // selectedChannels: sTabs[0]?.channels[0];
  });
  const [deviceModel, setDeviceModel] = useState(null);
  const [table, setTable] = useState({ header: {}, data: [] });

  let { columns, setGateway } = useTable({
    gatewayDetails,
    controller: device,
    setDialog,
    setDialogContents,
    setDialogWrapper: setDialogBox,
    setDialogWrapperContents: setDialogBoxContents,
    mqttClient
  });

  useEffect(() => {
    return () => {
      unsubscribeAll(mqttClient, dispatch)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mqttClient])

  useEffect(() => {
    setControllerGroupCunt(groupCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupCount])

  useEffect(() => {
    if (!_.isEmpty(deviceId) && category !== "Switch" && !category?.toLowerCase()?.includes("sensor") && !category?.includes("LightDriver")) {
      dispatch(deviceActions.getAllChildren(deviceId, deviceType === "gateway" ? "controller" : "device")); //devicelist replace
    }
    setOpenPopover({ target: null });
    if (!_.isEmpty(deviceId) && deviceType) {
      //AQI SENSOR
      // if (category !== "ODMSENSORHUB") {
      //   dispatch(deviceActions.getDevice(deviceId, deviceType)); //deviceList add
      // }
      // else {
      dispatch(deviceActions.getDevice(deviceId, deviceType, false, "", true)); //deviceList add
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceId])


  useEffect(() => {
    if ((deviceType === "controller" || deviceType === "gateway" || deviceType === "aggregator") && deviceId) {
      dispatch(groupActions.getGroupsByDeviceId(deviceType, deviceId));
      deviceType === "controller" && dispatch(groupActions.getGroupCount(deviceType, deviceId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupDeleted, deviceId])

  useEffect(() => {
    if (deviceType === "controller" && deviceId) {
      dispatch(areaActions.getAreasByControllerId(deviceId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaDeleted, deviceId])

  useEffect(() => {
    if ((deviceType === "controller" || deviceType === "gateway" || deviceType === "aggregator") && deviceId) {
      dispatch(sceneActions.getScenesByDeviceId(deviceType, deviceId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sceneDeleted, deviceId])

  useEffect(() => {
    if (device?.deviceId === firmwareDetails?.deviceId && (device?.category?.toLowerCase()?.includes("gateway") || device?.category?.toLowerCase()?.includes("controller"))) {
      setDeviceDetails({
        ...device,
        ...firmwareDetails
      });
    }
    else {
      setDeviceDetails(device || {})
    }

    if (device?.mqttStatus === "busy") {
      setControllerBusy(true);
    }
    else {
      setControllerBusy(false);
    }
  }, [device, firmwareDetails]);

  useEffect(() => {
    if (fwUpdateStatus?.includes("fw_updated")) {
      dispatch(fotaActions?.getNextApplicableFirmwares({ deviceId: deviceDetails?.deviceId, all: true }))
    }
  }, [fwUpdateStatus])

  useEffect(() => {
    const model = deviceModels.find((d) => {
      return ((d.modelNumber?.substring(d.modelNumber?.length - 3, d.modelNumber?.length) === deviceDetails?.modelNumber) || (d?.modelNumber === deviceDetails?.modelNumber))
    });
    const intensityModel = model?.controlCapabilities?.find(c => c?.capability === "intensity") || null;
    const kelvinModel = model?.controlCapabilities?.find(c => c?.capability === "kelvin") || null;
    setDeviceModel({ model, intensityModel, kelvinModel });
    if (deviceType === "gateway") {
      //subscribing to maintain delete status of groups,scenes and areas
      subscribe(deviceDetails?.mqttTopics?.comStatus, dispatch, mqttClient, "deleteAll")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceModels, deviceDetails?.modelNumber, deviceDetails?.deviceId])

  useEffect(() => {

    for (let i = 0; i < scenes.length; i++) {
      let devices = scenes[i].actions.filter((e) =>
        e.hasOwnProperty("deviceId")
      );
      let groups = scenes[i].actions.filter((e) =>
        e.hasOwnProperty("groupId")
      );
      let st = "Devices: " + devices.length + " | Groups:" + groups.length;
      scenes[i].deviceLength = st;
    }
    setSceneList([...scenes]);
  }, [scenes]);

  useEffect(() => {
    if (!_.isEmpty(mqttClient) && !_.isEmpty(deviceDetails)) {
      if (deviceType === "controller" || deviceDetails?.category?.includes("LightDriver")) {
        //subscribing to maintain controller busy or available status or to monitor light status
        subscribe(deviceDetails?.mqttTopics?.status, dispatch, mqttClient, deviceType === "controller" ? "controllerBusy" : "lightControl")
      }
      if (deviceDetails?.category !== "Switch" && !deviceDetails?.category?.includes("LightDriver") && deviceType !== "gateway") {
        //subscribing to maintain the connection status of the device
        subscribe(deviceDetails?.mqttTopics?.connectionStatus, dispatch, mqttClient, "connectionStatus")
      }
    }
    if (!_.isEmpty(deviceDetails) && (deviceType === "controller" || deviceType === "gateway")) {
      dispatch(fotaActions?.getNextApplicableFirmwares({ deviceId: deviceDetails?.deviceId, all: true }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceDetails?.deviceId]);

  useEffect(() => {
    const htabs = tabs?.filter(ht => ht?.categories?.includes(deviceDetails?.category))
    const sTabs = tabs[0]?.children?.filter(st => st?.parentCategories?.includes(deviceDetails?.category));
    setHeaderTabs(tabs?.filter(t => t.categories.includes(deviceDetails?.category)));
    setSubTabs(sTabs);
    setSelectedTab({
      selectedView: htabs[hTab] || null,
      selectedDeviceType: (sTabs[sTab]) || null,//selectedTab.selectedDeviceType ||
      selectedChannels: sTabs[0] && sTabs[0]?.channels && sTabs[0]?.channels[0] || null
    })
  }, [deviceDetails?.category]);


  useEffect(() => {

    if (!_.isEmpty(deviceList) && !_.isEmpty(mqttClient)) {
      if (deviceType === "controller") {
        //can subscribe based on type of controller
        // subscribe([
        //   ...spacePathList?.map(p => `${tenantName}/+/status/DaliLightDriver/${p}`),
        //   ...spacePathList?.map(p => `${tenantName}/+/status/AnalogLightDriver/${p}`),
        //   ...spacePathList?.map(p => `${tenantName}/+/status/RelayLightDriver/${p}`)],
        //   dispatch,
        //   mqttClient,
        //   "lightControl");
        //subscribing to maintain the live status of the lights of the controller
        subscribe(deviceList?.filter(d => d?.category?.includes("LightDriver") && d?.status === "active" && d?.parentDeviceId === deviceId)?.map(d => d?.mqttTopics?.status),
          dispatch,
          mqttClient,
          "lightControl");
        //conn status monitoring for receivers
        subscribe(deviceList?.filter(d => d?.category === "Receiver")?.map(d => d?.mqttTopics?.connectionStatus),
          dispatch,
          mqttClient,
          "connectionStatus");
      }
      if (category === "Receiver") {
        //subscribing to maintain the connection status of the sensors of the receiver
        subscribe(deviceList?.filter(d => d?.category === "Sensor")?.map(d => d?.mqttTopics?.connectionStatus), dispatch, mqttClient, "connectionStatus");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceList])

  useEffect(() => {
    if (deviceType === "gateway" && !_.isEmpty(controllerList) && !_.isEmpty(mqttClient)) {
      //subscribing to maintain the connection status of the children of the gateway
      subscribe(controllerList?.map(d => d?.mqttTopics?.connectionStatus), dispatch, mqttClient, "connectionStatus");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controllerList])

  useEffect(() => {
    if (!lastActionOnDevice?.includes("set_sensor_list")) {
      const id = selectedTab?.selectedView?.id === "Device" ? (selectedTab?.selectedDeviceType?.type) : selectedTab?.selectedView?.id;
      //|| (selectedTab?.selectedView?.categories?.children && selectedTab?.selectedView?.categories?.children[0]?.category)
      if (selectedTab?.selectedDeviceType?.category && selectedTab?.selectedView?.id === "Device" && selectedTab.selectedDeviceType.category === "DaliLightDriver" && deviceType !== "gateway") {
        const channelNode = document.querySelectorAll("#channel_btn");
        if (selectedTab?.selectedChannels?.category === "L01") {
          channelNode[1]?.classList.remove("activeBtn");
          channelNode[1]?.classList.add("inactiveBtn");
          channelNode[0]?.classList.remove("inactiveBtn");
          channelNode[0]?.classList.add("activeBtn");
        }
        else {
          channelNode[0]?.classList.add("inactiveBtn");
          channelNode[0]?.classList.remove("activeBtn");
          channelNode[1]?.classList.remove("inactiveBtn");
          channelNode[1]?.classList.add("activeBtn");
        }
        setTable({
          header: columns[id]["headerList"],
          data: (deviceType === "gateway" ? controllerList : deviceList)?.filter((item) => item?.configuration?.channelId === selectedTab?.selectedChannels?.category),

          // sorting: columns[id]?.sorting, 
          // removeSortFrom: columns[id]?.removeSortFrom,
          // rowConfig: columns[id]?.rowConfig
        })
      } else if (!selectedTab) {
        setTable({
          header: columns[id]["headerList"],
          data: [],
          // sorting: columns[id]?.sorting, 
          // removeSortFrom: columns[id]?.removeSortFrom,
          // rowConfig: columns[id]?.rowConfig
        });
      }
      else {
        setTable({
          header: columns[id]?.headerList,
          data: selectedTab?.selectedView?.id === "Device" ? (deviceType === "gateway" ? selectedTab?.selectedDeviceType?.label === "Cameras" ? cameraList : controllerList.filter(dl => dl?.category?.includes(selectedTab?.selectedDeviceType?.category)) : deviceList.filter(dl => dl?.category === selectedTab?.selectedDeviceType?.category)) : (selectedTab?.selectedView?.id === "Group" ? groupList : (selectedTab?.selectedView?.id === "Area") ? areaList : sceneList),
          // sorting: columns[id]?.sorting, removeSortFrom: columns[id]?.removeSortFrom,
          // rowConfig: columns[id]?.rowConfig
        })
      }
      if (id !== 'deviceLog') {
        dispatch({
          type: deviceTypes?.CLEAR_DEVICE_LOGS,
          payload: {},
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, sceneList, groupList, lastActionOnDevice, areaList])


  useEffect(() => {
    if (!_.isEmpty(deviceDetails?.parentDeviceId)) {
      setGatewayDetails(gateway);
      if (deviceType === "controller" || deviceType === "aggregator") {
        //subscribing to maintain delete status of groups,scenes and areas
        subscribe(gateway?.mqttTopics?.comStatus, dispatch, mqttClient, "deleteAll");
      }
      // setGateway(gateway);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gateway, deviceDetails?.deviceId]);


  useEffect(() => {
    setGateway(gateway);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table?.data]);



  useEffect(() => {
    if (!_.isEmpty(headerTabs)) {
      filterDataFunc({}, hTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hTab])

  useEffect(() => {
    if (!_.isEmpty(subTabs)) {
      handleLightDriverSelection(sTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sTab])

  const syncByDevice = () => {
    if (selectedTab?.selectedView?.id === 'Scene') {
      dispatch(
        sceneActions.syncByDeviceId(deviceId));
    } else if (selectedTab?.selectedView?.id === 'Group') {
      dispatch(
        groupActions.syncByDeviceId(deviceId));
    }
  }
  const deleteByDevice = () => {
    confirmationDialog(selectedTab?.selectedView?.id === 'Scene' ? "Delete Scenes" : "Delete Groups", () => {
      setDialog(false);
      if (selectedTab?.selectedView?.id === 'Scene') {
        dispatch(sceneActions.deleteByDeviceId(deviceId, deviceType));
        setSceneDeleteTimeout(sceneDeleteTimeout, dispatch);
      } else if (selectedTab?.selectedView?.id === 'Group') {
        dispatch(groupActions.deleteByDeviceId(deviceId, deviceType));
        setGroupDeleteTimeout(groupDeleteTimeout, dispatch);
      }
    }, selectedTab?.selectedView?.id === 'Scene' ? deviceDetails?.status + "DeleteAllScenes" : deviceDetails?.status + "DeleteAllGroups", setDialog, setDialogContents, deviceDetails?.name + " " + formatString(deviceDetails?.category, "wordBreak"))
  }

  const filterDataFunc = (e, index) => {

    setSelectedTab({
      selectedView: headerTabs[index],
      selectedDeviceType: (subTabs[sTab]) || null,
      selectedChannels: (headerTabs[index]?.children && headerTabs[index]?.children[0]?.channels && headerTabs[index]?.children[0]?.channels[0]) || null
    })
    setToggleDevice(headerTabs[index]?.label);
  };

  const closeDialog = () => {
    setDialog(false);
    // mqttDisconnect();
  };
  const handleLightDriverSelection = (index) => {
    setSelectedTab({
      ...selectedTab,
      selectedDeviceType: subTabs[index],
      selectedChannels: selectedTab?.selectedView?.children[index]?.channels ? selectedTab?.selectedView?.children[index]?.channels[0] : null
    });
  };


  const handleLightChannelSelection = (channel, index) => {
    // const btnNode = document.querySelectorAll("#channel_btn");
    // for (let n of btnNode) {
    //   n.classList.add("inactiveBtn");
    // }
    // btnNode[index]?.classList.remove("inactiveBtn");
    // btnNode[index]?.classList.add("activeBtn");
    setTable({ ...table, data: deviceList?.filter((item) => item.configuration?.channelId === channel) })
    setSelectedTab({
      ...selectedTab,
      selectedChannels: selectedTab?.selectedDeviceType?.channels[index]
    })
  };

  const goTo = (route) => {
    history.push(route);
  };


  return (
    <div className={classes?.ControllerDetails || ""} data-testid="ControllerDetails">
      <PageHeader
        bckbtn={true}
        title={deviceDetails?.name || "-"}
        icon={Lighting}
        backfunc={() => {
          setOpenPopover({ target: null });
          history.goBack();
        }}
        breadcrumbs={{ device: true }}
        // breadcrumbs={{ device: category !== "ODMSENSORHUB" }}
        page={deviceType}
        data={deviceDetails}
      >
        {((rootSpace?.spaceId !== deviceDetails?.spaceId && deviceType === "gateway") || deviceType !== "gateway") &&

          <Box gap={1} className="buttons">
            {/* {deviceType === "controller" && (
              <Button
                color="primary"
                style={{ letterSpacing: "1px" }}
                variant="outlined"
                disabled={true}
                onClick={handleEditMenu}

              >
                Edit
              </Button>
            )} */}
            {/* <Button
              onClick={syncDevice}
              style={{ letterSpacing: "1px" }}
              variant="outlined"
              // disabled={true}
              color="primary"
            >
              Sync
            </Button> */}
            {
              !deviceDetails?.category?.includes("LightDriver") && (isAuthorised(features?.device, permissions?.factoryReset) || isAuthorised(features?.device, permissions?.systemReset)) &&
              <StyledButton
                variant="outlined"
                onClick={(event) => {
                  setOpenPopover({ target: event.currentTarget, onHover: false });
                }}
              >
                More
              </StyledButton>
            }
          </Box>
        }
      </PageHeader>
      <Box>
        {
          isAuthorised(features?.device) &&
          <CustomAccordion title={"Device Details"}>
            <ItemView
              data={deviceDetails}
              headers={getDeviceDetailHeaders(deviceDetails?.category, deviceDetails, dispatch, deviceModel?.model, deviceCategory?.label)}
            />
            {
              deviceDetails?.category?.toLowerCase()?.includes("sensor") &&
              <ItemView data={deviceDetails}
                customsx={{ borderTop: "1px #d5d5d5 solid", mt: 2 }}
                headers={getDeviceDetailHeaders("SensorProperties", deviceDetails, dispatch, deviceModel?.model, deviceCategory?.label)} />
              // headers={getDeviceDetailHeaders(category === "ODMSENSORHUB" ? "AQSensorProperties" : "SensorProperties", deviceDetails, dispatch, deviceModel?.model, deviceCategory?.label)} />
            }
          </CustomAccordion>
        }

        {/* Add Aggregator, Add Controller */}
        {rootSpace?.spaceId !== deviceDetails?.spaceId && deviceType === "gateway" && isAuthorised(features?.device, permissions?.add) &&
          <AddToolbar setDialogBox={setDialogBox} setDialogBoxContents={setDialogBoxContents} classes={classes} deviceDetails={deviceDetails} />
        }
        <Box sx={{ py: 1, mb: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", float: "right", minHeight: "52px", mr: 1 }}>
            {/* Sync button for all groups/scenes */}
            {(selectedTab?.selectedView?.id === 'Group' || selectedTab?.selectedView?.id === 'Scene') && isAuthorised(features?.device, selectedTab?.selectedView?.id === 'Scene' ? permissions?.syncScene : permissions?.syncGroup) && ( //Changed here as I may not need sync in areas
              <StyledButton
                tooltip={`Sync ${selectedTab?.selectedView?.label}`}
                iconButton
                disabled={disableDeviceOperation(deviceDetails, "syncAll")}
                onClick={syncByDevice}
              >
                <ImageWrapper width={20} height={20} src={Sync} />
              </StyledButton>
            )}
            {/* Delete button for all groups/scenes */}
            {(selectedTab?.selectedView?.id === 'Group' || selectedTab?.selectedView?.id === 'Scene') && isAuthorised(features?.device, permissions?.delete) && (table?.data?.length > 0) && ( //Changed here as I may not need sync in areas
              <StyledButton
                tooltip={`Delete ${selectedTab?.selectedView?.label}`}
                disabled={disableDeviceOperation(deviceDetails, "deleteAll")}
                iconButton
                onClick={deleteByDevice}
              >
                <ImageWrapper width={18} height={18} src={deleteIcon} />
              </StyledButton>
            )}
          </Box>
          {/* Device control panel */}
          {
            deviceDetails?.category?.includes("LightDriver") &&
            <Box className="deviceControlBox" sx={{ mb: 2, minWidth: "100%" }}>
              <Box className="deviceControlHeader" sx={{ p: 2, minWidth: "100%" }}>
                <CustomTypography
                  weight={700}
                  size={14}
                  content="Device Controls"
                />
              </Box>
              <Grid className="deviceControls" container item xs={12} sx={{ mb: 1, p: 2, position: "relative" }} spacing={2}>
                {/* Spinner while waiting for MQTT response */}
                {deviceDetails?.loading &&
                  <Box sx={{ position: 'absolute', top: 15, bottom: 0, width: "100%", backgroundColor: "rgba(255, 255, 255, 0.88)", zIndex: 1011 }}>
                    <StyledSpinner sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
                  </Box>
                }
                {deviceModel?.intensityModel &&
                  <Grid className="intensityControl" item xs={deviceModel?.intensityModel?.attributes[0]?.range?.max[0]?.value === 1 ? "auto" : 12} md={6} lg={5}
                    alignItems={"center"}
                    display="flex"
                    flexDirection={"row"}>
                    <IntensitySlider
                      params={{ switchButton: true, label: true, value: deviceModel?.intensityModel?.attributes[0]?.range?.max[0]?.value === 1 ? false : true, icon: true, compo: deviceModel?.intensityModel?.attributes[0]?.range?.max[0]?.value === 1 ? false : true }}
                      label="Intensity"
                      customsx={{ label: { width: "fit-content" } }}
                      data={deviceDetails} mqttClient={mqttClient}
                    ></IntensitySlider>
                  </Grid>
                }
                {deviceModel?.kelvinModel &&
                  <Grid className="tempControl" item xs={12} md={6} lg={5} sx={{ display: "flex" }} alignItems={"center"}
                    flexDirection={"row"}>
                    <TemperatureSlider
                      params={{ value: true, label: true, icon: true, compo: true }}
                      label="Color Temperature"
                      customsx={{ label: { width: "fit-content" } }}
                      data={deviceDetails} mqttClient={mqttClient}
                    ></TemperatureSlider>
                  </Grid>
                }
                {deviceModel?.model?.controlCapabilities?.find(c => c?.capability === "deviceIdentify") && isAuthorised(features?.device, permissions?.identifyDevice) && !disableDeviceOperation(deviceDetails, "deviceIdentify") &&
                  <Grid item xs={"auto"} lg={2} md={3} sx={{ display: "flex", alignItems: "center" }}>
                    <StyledButton
                      className="identifyButton"
                      variant="outlined"
                      onClick={() => {
                        identifyDevice({
                          device: deviceDetails,
                          dispatch,
                          mqttClient
                        }, setDialog, setDialogContents, gatewayDetails);
                      }}
                    >
                      <ImageWrapper
                        className="identifyIcon"
                        style={{ marginRight: "8px" }}
                        src={Identify} alt={"IdentifyDevice"} />
                      Identify Device
                    </StyledButton>
                  </Grid>
                }
              </Grid>
            </Box>
          }
          {/* Category tabs - Device|Groups|Scenes */}
          {!_.isEmpty(headerTabs) && (
            <Box
              sx={{ pb: 1 }}>
              <StyledTabs
                tabs={headerTabs}
                selectedIndex={hTab}
                onClick={(event, data) => {
                  history?.push(`/spaces/devices?pt=${deviceType}&id=${deviceId}&category=${category}&hTab=${data}&sTab=${sTab}`)
                }}
                panelStyle={{ backgroundColor: "rgba(0, 0, 0, 0.01)" }}
              >
              </StyledTabs>
            </Box>

          )}
          {/* Lights tab panel - Dali lights|Analog lights|Receivers */}
          {!_.isEmpty(subTabs) && selectedTab?.selectedView?.id === 'Device' && (
            <Box
              sx={{ pb: 1 }}
            >
              <StyledTabs
                tabs={subTabs}
                selectedIndex={sTab}
                onClick={(e, value) => {
                  history?.push(`/spaces/devices?pt=${deviceType}&id=${deviceId}&category=${category}&hTab=${hTab}&sTab=${value}`)
                }}
              >

              </StyledTabs>
            </Box>
          )}
          {deviceType === "controller" && selectedTab?.selectedView?.id !== 'deviceLog' &&
            <Box sx={{ background: controllerBusy ? "rgb(247 57 57 / 13%)" : (deviceDetails?.status === "inactive" || deviceDetails?.connectionStatus === "Offline") ? "rgb(247 57 57 / 13%)" : "rgba(15, 141, 72, 0.05)", p: 1 }}>
              <CustomTypography
                weight={600}
                color={controllerBusy ? "red" : (deviceDetails?.status === "inactive" || deviceDetails?.connectionStatus === "Offline") ? "red" : "#0F8D48"}
                size={14}
                lineHeight={17}
                styles={{ margin: "8px" }}
                content={(deviceDetails?.status === "inactive" || deviceDetails?.connectionStatus === "Offline") ? "The controller is not available" : controllerBusy ? "The controller is currently busy" : "The controller is available"}
              />
            </Box>
          }

          {
            selectedTab?.selectedView?.id === "Device" && (selectedTab?.selectedDeviceType?.controllerToolbar) && (
              <ControllerToolbar
                deviceList={table?.data}
                mqttTopics={gatewayDetails?.mqttTopics}
                lightSelector={selectedTab?.selectedDeviceType?.category}
                deviceData={deviceDetails}
                params={{ broadcast: selectedTab?.selectedDeviceType?.controllerToolbar?.broadcast ? (table?.data?.length === 0 || disableDeviceOperation(deviceDetails, "broadcast")) ? "disabled" : true : false, commission: (selectedTab?.selectedDeviceType?.controllerToolbar?.commission ? disableDeviceOperation(deviceDetails, "commission") ? "disabled" : true : false), discover: selectedTab?.selectedDeviceType?.controllerToolbar?.discover ? disableDeviceOperation(deviceDetails, "discover") ? "disabled" : true : false }}
                setDialog={setDialog}
                setDialogContents={setDialogContents}
                setDialogBox={setDialogBox}
                setDialogBoxContents={setDialogBoxContents}
                mqttClient={mqttClient}
              />
            )}
          <Box sx={{ mt: 1 }}>
            {selectedTab?.selectedView?.id === "deviceLog" ?
              <ActivityLog
              />
              :
              selectedTab?.selectedView?.id === "systemLog" ?
                <SystemLog deviceId={deviceDetails?.deviceId}></SystemLog>
                :
                (deviceDetails?.category === "Sensor" || deviceDetails?.category === "Switch") ?
                  <DeviceConfigurations deviceData={deviceDetails} setDialog={setDialog} setDialogContents={setDialogContents} />
                  :
                  !deviceDetails?.category?.includes("LightDriver") ?
                    // category === "ODMSENSORHUB" ? <Grid container spacing={1}>
                    //   <Grid item xs={12}>
                    //     <StyledCardWrapper>
                    //       <CustomTypography
                    //         fontWeight={700}
                    //         size={12}
                    //         content={"Air quality (PM 2.5)"}
                    //         styles={{ marginBottom: "8px" }}
                    //       />
                    //       <CustomTypography
                    //         color={"#5E5873"}
                    //         fontWeight={700}
                    //         size={24}
                    //         content={50}
                    //       />
                    //       <CustomTypography
                    //         fontWeight={400}
                    //         size={10}
                    //         color="#000000"
                    //         content={"Air quality is satisfactory, and air pollution poses little or no risk."}
                    //         styles={{ marginBottom: "8px" }}
                    //       />
                    //       <ControllerSlider
                    //         customsx={{
                    //           compo: {
                    //             "& .MuiSlider-rail": {
                    //               background: 'linear-gradient(90deg,#64a13c,#eac51c,#d67900,#a90737,#50051e)',
                    //               height: '3px'
                    //             },
                    //             "& .MuiSlider-track": {
                    //               height: '1px',
                    //               border: 'none',
                    //               color: 'transparent'
                    //             }
                    //           }, label: { width: "fit-content" },
                    //         }}
                    //         label={''}
                    //         data={{ min: 0, max: 500, }}
                    //         value={50}
                    //         name="aqi"
                    //         params={{ icon: false, label: false, value: false, compo: true }}
                    //         disabled={true}
                    //       />
                    //     </StyledCardWrapper>
                    //   </Grid>
                    //   <Grid item xs={6}>
                    //     <StyledCardWrapper>
                    //       <CustomTypography
                    //         fontWeight={700}
                    //         size={12}
                    //         content={"Temperature"}
                    //         styles={{ marginBottom: "8px" }}
                    //       />
                    //       <StyledGraph chartType={"Line"}
                    //         sideTitle={"°C"}
                    //         chartData={
                    //           {
                    //             "labels": [
                    //               "9 AM ",
                    //               "10 PM",
                    //               "11 PM",
                    //               "12 PM ",
                    //               "1 AM"
                    //             ],
                    //             "datasets": [
                    //               {
                    //                 "data": [
                    //                   16,
                    //                   18,
                    //                   22,
                    //                   22,
                    //                   20
                    //                 ],
                    //                 "type": "line",
                    //                 "interval": "hourly",
                    //                 // "label": "IIC Office",
                    //                 "backgroundColor": "#0F8D48"
                    //               },
                    //             ]
                    //           }
                    //         } />
                    //     </StyledCardWrapper>
                    //   </Grid>
                    //   <Grid item xs={6}>
                    //     <StyledCardWrapper>
                    //       <CustomTypography
                    //         fontWeight={700}
                    //         size={12}
                    //         content={"Humidity"}
                    //         styles={{ marginBottom: "8px" }}
                    //       />
                    //       <StyledGraph chartType={"Line"}
                    //         sideTitle={"%"}
                    //         chartData={
                    //           {
                    //             "labels": [
                    //               "9 AM ",
                    //               "10 PM",
                    //               "11 PM",
                    //               "12 PM ",
                    //               "1 AM"
                    //             ],
                    //             "datasets": [
                    //               {
                    //                 "data": [
                    //                   20,
                    //                   40,
                    //                   60,
                    //                   70,
                    //                   80
                    //                 ],
                    //                 "type": "line",
                    //                 "interval": "hourly",
                    //                 "label": "IIC Office",
                    //                 "backgroundColor": "#0F8D48"
                    //               },
                    //             ]
                    //           }
                    //         } />
                    //     </StyledCardWrapper>
                    //   </Grid><Grid item xs={6}>
                    //     <StyledCardWrapper>
                    //       <CustomTypography
                    //         fontWeight={700}
                    //         size={12}
                    //         content={"Carbon Dioxide"}
                    //         styles={{ marginBottom: "8px" }}
                    //       />
                    //       <StyledGraph chartType={"Line"}
                    //         sideTitle={"mg/m3"}
                    //         chartData={
                    //           {
                    //             "labels": [
                    //               "9 AM ",
                    //               "10 PM",
                    //               "11 PM",
                    //               "12 PM ",
                    //               "1 AM"
                    //             ],
                    //             "datasets": [
                    //               {
                    //                 "data": [
                    //                   20,
                    //                   22,
                    //                   34,
                    //                   36,
                    //                   40
                    //                 ],
                    //                 "type": "line",
                    //                 "interval": "hourly",
                    //                 "label": "IIC Office",
                    //                 "backgroundColor": "#0F8D48"
                    //               },
                    //             ]
                    //           }
                    //         } />
                    //     </StyledCardWrapper>
                    //   </Grid><Grid item xs={6}>
                    //     <StyledCardWrapper>
                    //       <CustomTypography
                    //         fontWeight={700}
                    //         size={12}
                    //         content={"PM 2.5"}
                    //         styles={{ marginBottom: "8px" }}
                    //       />
                    //       <StyledGraph chartType={"Line"}
                    //         sideTitle={"ug/m3"}
                    //         chartData={
                    //           {
                    //             "labels": [
                    //               "9 AM ",
                    //               "10 PM",
                    //               "11 PM",
                    //               "12 PM ",
                    //               "1 AM"
                    //             ],
                    //             "datasets": [
                    //               {
                    //                 "data": [
                    //                   5,
                    //                   2,
                    //                   34,
                    //                   36,
                    //                   40
                    //                 ],
                    //                 "type": "line",
                    //                 "interval": "hourly",
                    //                 "label": "IIC Office",
                    //                 "backgroundColor": "#0F8D48"
                    //               },
                    //             ]
                    //           }
                    //         } />
                    //     </StyledCardWrapper>
                    //   </Grid>
                    // </Grid> :
                    <Box className="Table" sx={lightSelector === "DaliLightDriver" ? { mt: 3 } : { mt: 0 }}>
                      <CustomTable
                        {...columns[selectedTab?.selectedView?.id === "Device" ? (selectedTab?.selectedDeviceType?.category) : selectedTab?.selectedView?.id]}
                        mqttClient={mqttClient}
                        headerList={table?.header}
                        cellMatrix={table?.data}
                        rowIdentifier={table?.data[0]?.deviceId && "deviceId"}
                        // rowConfig={table?.rowConfig}
                        searching={selectedTab?.selectedView?.id === "Device" ? { reset: true, columns: ["name", "modelNumber", "category"] } : { reset: true, columns: ["name"] }}
                        // searching={true}
                        pagination={true}
                        enableScrollToTop={false}
                      //   expandable={
                      //     selectedTab?.selectedView?.id === "Device" && selectedTab?.selectedDeviceType?.category?.includes("LightDriver")
                      //       ?
                      //       {
                      //  component: columns[selectedTab?.selectedView?.id === "Device" ? (deviceType === "gateway" ? "controller" : selectedTab?.selectedDeviceType?.category) : selectedTab?.selectedView?.id]?.expandableF
                      //       }
                      //       : false
                      //   }
                      // sorting={table?.sorting || null}
                      // removeSortFrom={table?.removeSortFrom || ["userActions"]}
                      >
                        {deviceType === "controller" && selectedTab?.selectedView?.id === 'Group' &&
                          <Box>
                            {groupCountData?.map(g => {
                              if (Array.isArray(g?.category) && g?.category?.includes(deviceDetails?.category)) {
                                return <StyledChip sx={{ marginBottom: "0px" }} label={`${g?.label} - ${controllerGroupCount?.hasOwnProperty(g?.prop) ? controllerGroupCount[g?.prop] : 0}`}></StyledChip>
                              }
                            })}
                          </Box>
                        }
                        {selectedTab?.selectedView?.id === "Area" && isAuthorised(features?.device, permissions?.add) && (
                          <Box sx={{ width: '100%', textAlign: 'right' }}>
                            <StyledButton
                              disabled={disableDeviceOperation(deviceDetails, "addArea")}
                              sx={{ mr: 2, mt: 1 }}
                              onClick={() => goTo(`/spaces/areas?op=create&deviceId=${deviceId}&name=${deviceDetails?.name}`)}
                            >
                              Add Area
                            </StyledButton>
                          </Box>
                        )}
                        {/* Channels - Channel 1 | Channel 2 */}
                        {deviceType === "controller" && selectedTab?.selectedView?.id === "Device" && selectedTab?.selectedDeviceType?.category === "DaliLightDriver" && (
                          <Grid container gap={1} sx={{ mt: 3 }}>
                            <Grid item >
                              {!_.isEmpty(LightChannel) &&
                                LightChannel.map((ld, index) => {
                                  return (
                                    <StyledButton
                                      sx={{ px: { xs: 1, sm: 3, md: 6 }, boxShadow: "none" }}
                                      className="btn-tab inactiveBtn"
                                      id="channel_btn"
                                      key={index}
                                      name={ld?.label}
                                      onClick={() =>
                                        handleLightChannelSelection(ld?.category, index)
                                      }
                                    >
                                      {ld?.label}{` ( ${deviceList?.filter((item) => item?.configuration?.channelId === ld?.category)?.length || 0} )`}

                                    </StyledButton>
                                  );
                                })}
                            </Grid>
                            {isAuthorised(features?.device, permissions?.resetChannel) &&
                              <>
                                <Grid item className={"divider"}>
                                  <Divider
                                    className="channelDivider"
                                    sx={{ height: "40px", m: "0 10px" }}
                                    orientation="vertical"
                                  />
                                </Grid>
                                <Grid item sx={{ display: "flex", alignItems: "center" }}>
                                  <StyledButton
                                    sx={{ minWidth: "70px", px: { xs: 2, md: 3 } }}
                                    disabled={table?.data?.length === 0 || disableDeviceOperation(deviceDetails, "resetChannel") ? true : false}
                                    onClick={() => {
                                      channelReset(deviceDetails, gatewayDetails, selectedTab?.selectedChannels?.category, setDialog, setDialogContents, dispatch, mqttClient);
                                    }}
                                  >
                                    {selectedTab?.selectedChannels?.category === "L01"
                                      ? "Reset Channel 1"
                                      : "Reset Channel 2"}
                                  </StyledButton>
                                </Grid></>
                            }


                          </Grid>
                        )}
                      </CustomTable>
                    </Box>
                    :
                    <PhysicalParamters
                      deviceData={deviceDetails}
                      mqttClient={mqttClient}
                    ></PhysicalParamters>
            }
          </Box>
        </Box>
      </Box>

      <StyledPopup
        open={dialog}
        onClose={closeDialog}
        state={dialogContents?.state}
        data={dialogContents?.content}
        component={dialogContents?.component}
        customsx={{
          label: {
            color: "red",
            fontWeight: 700,
            fontSize: "0.9375rem",
            paddingBottom: "16px",
          },
          btns: { display: "flex", justifyContent: "center" },
        }}
      />
      {/* popup used for add aggregator/controller and scheduler */}
      <StyledPopupWrapper
        fullWidth={dialogBoxContents?.content?.title === "Discover Sensors" && true}
        maxWidth={dialogBoxContents?.content?.title === "Discover Sensors" && "xl"}
        title={dialogBoxContents?.title}
        open={dialogBox}
        closeButton={dialogBoxContents?.content === "control"}
        onClose={() => { setDialogBox(false); setDialogBoxContents(null); }}
      >{
          dialogBoxContents?.content === "control" ?
            <TemperatureSlider
              data={dialogBoxContents?.deviceData}
              params={{ icon: true, label: true, value: true, compo: true }}
              label="Color Temperature (K)"
              mqttClient={mqttClient}
              showLoader={true}
              customsx={{ loader: { zIndex: 1200 }, compo: { minWidth: "200px" } }}
            />
            :
            dialogBoxContents?.component}</StyledPopupWrapper>
      <PopoverContainer sx={{ maxWidth: "337px" }}
        onClose={() => { setOpenPopover({ target: null }) }}
        anchorEl={openPopover?.target}>
        {openPopover?.onHover ?
          <CustomTypography content={popoverAnchorToolTipContent} weight={700} size={13} lineHeight={19} color="#202020" /> //To show tooltip for sync action
          :
          <>
            <Typography
              style={{
                fontWeight: 600,
                fontSize: '0.875rem',
                marginBottom: "10px",
              }}
            >
              More
            </Typography>
            <Grid container item sx={{ p: 1 }} rowSpacing={1}>
              {/* <Grid item xs={12}>
              <Button
                color="primary"
                style={{ letterSpacing: "1px", width: "100%" }}
                variant="outlined"
              >
                View Logs
              </Button>
            </Grid> */}
              {isAuthorised(features?.device, permissions?.upgradeFirmware) && (deviceDetails?.category?.toLowerCase().includes("gateway") || deviceDetails?.category?.toLowerCase().includes("controller")) && <Grid item xs={12}>
                <StyledButton
                  style={{ width: "100%" }}
                  variant="outlined"
                  disabled={disableDeviceOperation(deviceDetails, "upgradeFirmware") || _.isEmpty(deviceDetails?.availableFirmware) || deviceDetails?.disableUpgrade}
                  onClick={() => {
                    setOpenPopover({ target: null });
                    confirmationDialog("Upgrade Firmware", () => {
                      dispatch(fotaActions?.upgradeFirmware([deviceId], deviceDetails?.gwDeviceId || deviceDetails?.deviceId, deviceDetails?.availableFirmware[0]?.fwId, () => { setDialog(false) }))
                    }, deviceDetails?.availableFirmware[0]?.hasOwnProperty("fwVersion") ? "upgradeFirmware" : "warnUpgradeFirmware", setDialog, setDialogContents, { name: deviceDetails?.name, fwVersion: deviceDetails?.availableFirmware[0]?.fwVersion },
                      deviceDetails?.availableFirmware[0]?.hasOwnProperty("fwVersion") ?
                        null :
                        [{
                          id: "1001",
                          type: "button",
                          label: "Close",
                          onClick: () => {
                            setDialog(false);
                          },
                        }])
                  }}
                >
                  <Box sx={{ display: "flex" }} gap={1}>
                    {/* <Box sx={{ position: "relative", top: 0, left: 10 }}> */}
                    Upgrade Firmware
                    {(deviceDetails?.disableUpgrade) &&
                      <StyledSpinner size={18} ></StyledSpinner>
                    }
                    {/* </Box> */}
                  </Box>
                </StyledButton>
              </Grid>}
              {isAuthorised(features?.device, permissions?.factoryReset) && <Grid item xs={12}>
                <StyledButton
                  style={{ width: "100%" }}
                  variant="outlined"
                  disabled={disableDeviceOperation(deviceDetails, "factoryReset")}
                  onClick={() => {
                    setOpenPopover({ target: null });
                    deviceType === "gateway" ? factoryReset(deviceDetails, setDialog, setDialogContents, dispatch, mqttClient) : factoryReset(deviceDetails, setDialog, setDialogContents, dispatch, mqttClient)
                  }}
                >
                  Factory Reset
                </StyledButton>
              </Grid>}
              {isAuthorised(features?.device, permissions?.systemReset) &&
                <Grid item xs={12}>
                  <StyledButton
                    style={{ width: "100%" }}
                    variant="outlined"
                    disabled={disableDeviceOperation(deviceDetails, "systemReset")}
                    onClick={() => {
                      setOpenPopover({ target: null });
                      deviceType === "gateway" ? systemReset(deviceDetails, deviceDetails, setDialog, setDialogContents, dispatch, mqttClient) : systemReset(deviceDetails, gatewayDetails, setDialog, setDialogContents, dispatch, mqttClient)
                    }}
                  >
                    System Reset
                  </StyledButton>
                </Grid>}
            </Grid>
            <Box
              style={{
                display: "flex",
                gap: "1em",
                marginTop: "21px",
                justifyContent: "flex-end",
              }}
            >
              <Typography
                style={{ fontWeight: 600, fontSize: '0.75rem', cursor: "pointer" }}
                onClick={() => {
                  setOpenPopover({ target: null });
                }}
                color="primary"
              >
                Cancel
              </Typography>
            </Box>
          </>}
      </PopoverContainer>
    </div >
  );
};

ControllerDetails.propTypes = {};

ControllerDetails.defaultProps = {};

export default ControllerDetails;
