import { reportType } from "../types";

let initialState = {
  loading: false,
  reportList: [],
  reportDetails: null,
  documentList: [],
  viewDocumentUrl:"",
  downloadDocumnetUrl:"",
  error: null,
};

export function reports(state = initialState, action) {
  switch (action?.type) {
    case reportType.GET_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case reportType.GET_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        reportDetails: action.payload,
      };
    case reportType.GET_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case reportType.GET_ALL_REPORTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case reportType.GET_ALL_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        reportList: action.payload,
        reportDetails:null,
        documentList:[]
      };
    case reportType.GET_ALL_REPORTS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    case reportType.GET_ALL_REPORT_DOCUMENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case reportType.GET_ALL_REPORT_DOCUMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        documentList:action.payload
      };
    case reportType.GET_ALL_REPORT_DOCUMENTS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    case reportType.CREATE_REPORTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case reportType.CREATE_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        reportDetails: action.payload,
        reportList: [...state.reportList, action.payload],
      };
    case reportType.CREATE_REPORTS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    case reportType.DELETE_REPORTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case reportType.DELETE_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        reportList:
          state && state.reportList && Array.isArray(state.reportList)
            ? state.reportList.filter((item) => item.id !== action.payload.id)
            : [],
        // reportList: [],
        reportDetails: null,
      };
    case reportType.DELETE_REPORTS_FAILURE:
      return {
        ...state,
        error: action?.payload,
        loading: false,
      };
    case reportType.UPDATE_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case reportType.UPDATE_REPORT_SUCCESS:
      const updatedReport = {
        ...action.payload,
        reportId: action.reportId,
      };
      return {
        ...state,
        loading: false,
        reportList: state?.reportList.map((report) =>
          report?.reportId === action?.reportId
            ? { ...report, ...action.payload }
            : report
        ),
        reportDetails: updatedReport,
      };
    case reportType.UPDATE_REPORT_FAILURE:
      return {
        ...state,
        loading:false,
        error: action?.payload.error,
      };
    case reportType.DELETE_DOCUMENT_REPORTS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case reportType.DELETE_DOCUMENT_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        documentList:
          state && state.documentList && Array.isArray(state.documentList)
            ? state.documentList.filter((item) => item.id !== action.payload.documentid)
            : [],
      }
    case reportType.DELETE_DOCUMENT_REPORTS_FAILURE:
      return {
        ...state,
        error: action?.payload,
        loading: false,
      };

      case reportType.DOWNLOAD_REPORT_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case reportType.DOWNLOAD_REPORT_SUCCESS:
        return {
          ...state,
          loading: false,
          viewDocumentUrl:action?.payload?.viewableSignedUrl,
          downloadDocumnetUrl:action?.payload.downloadableSignedUrl
        }
      case reportType.DOWNLOAD_REPORT_FAILURE:
        return {
          ...state,
          loading:false,
          error: action?.payload.error,
        };
    default:
      return state;
  }
}
