/***
 *
 * Controller class for user.
 * @file RulesList.js
 * @description RulesList component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import { Box } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useStyles } from './RulesList.style.js';
import PageHeader from '../../components/PageHeader/index.js';
import StyledButton from '../../components/StyledButton/index.js';
import { isAuthorised } from '../../helpers/users.helper.js';
import { features, permissions } from '../../constants/permission.constants.js';
import { history } from "../../helpers/history.helper.js";
import { automationActions, dragAndDropActions } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import {ReactComponent as CardView} from '../../assets/icons/card-view.svg';
import {ReactComponent as ListView} from '../../assets/icons/list-view.svg';
import LightIcon from '../../assets/icons/bulb.svg';
import GroupIcon from "../../assets/icons/group.svg";
import SceneIcon from '../../assets/icons/scene.svg';
import SensorIcon from '../../assets/icons/sensor.svg';
import RulesIcon from '../../assets/icons/rules.svg';
import { useQuery } from '../../hooks/useQuery.js';
import { findArrayByType } from '../../helpers/automation.helper.js';
import GridView from '../../components/GridView/index.js';
import StyledCard from '../../components/StyledCard/index.js';
import CustomTypography from '../../components/CustomTypography/index.js';
import RuleStatus from '../../components/RuleStatus/index.js';
import DeviceTypes from '../../components/DeviceTypes/index.js';
import CustomTable from '../../components/CustomTable/index.js';
import { formatedDate } from '../../helpers/date.helper.js';
import DataNotFound from '../DataNotFound/index.js';
import { getMessageStr } from '../../helpers/message.helper.js';
import _ from 'lodash';

const cardIconsList = [
  {
      "src": LightIcon,
      "key": "light",
      "borderInfo": "1px solid #fdd600"
  },
  {
      "src": GroupIcon,
      "key": "group",
      "borderInfo": "1px solid #d7aaff"
  },
  {
      "src": SceneIcon,
      "key": "scene",
      "borderInfo": "1px solid #ffa573"
  },
  {
      "src": SensorIcon,
      "key": "sensor",
      "borderInfo": "1px solid #b4e051"
  }
]

const RulesList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const query = useQuery();

  const queryParam = query.get("op") || null;  

  let currentSpaceId = useSelector((state) => state?.spaces?.selectedSpace?.spaceId);
  const dragList = useSelector(state => state?.dragAndDrops?.dragList);
  const dropList = useSelector(state => state?.dragAndDrops?.dropList);
  const _automationRuleApiData = useSelector((state) => state?.automation?.automationRulesList);

  const [tableData, setTableData] = useState([]);
  const [currentView, setCurrentView] = useState(true);
  const [automationData, setAutomationData] = useState([]);

  const goTo = (route) => {
      history.push(route);
  };

  const filterCardIconsList = (rule = {}) => {
      let sensorsData = findArrayByType(rule?.triggers, 'DEVICE_STATUS') || [];
      let ontriggerData = rule?.onTrigger ? rule?.onTrigger : [];
      let data = rule ? [...ontriggerData, ...sensorsData] : [];

      const filteredKeys = data.map((item) => {
          if (item.hasOwnProperty("deviceId") && item?.type === "DEVICE_STATUS") {
              return "sensor";
          }
          if (item.hasOwnProperty("deviceId")) {
              return "light";
          } 
          else if (item.hasOwnProperty("groupId")) {
              return "group";
          }
          else if (item.hasOwnProperty("sceneId")) {
              return "scene";
          }
          return null;
        }).filter((key, index, array) => key && array.indexOf(key) === index);
    
      const filteredIcons = cardIconsList.filter((item) => filteredKeys.includes(item.key));

      return filteredIcons || [];
  }
    
  useEffect(() => {
      if (currentSpaceId) {
          dispatch(automationActions?.getAllAutomationRule(currentSpaceId));        
      }
  }, [currentSpaceId, dispatch]);

  useEffect(() => {
      if (_automationRuleApiData ) {
          setTableData(_automationRuleApiData);
          setAutomationData(_automationRuleApiData);
      }
  }, [_automationRuleApiData]);

  useEffect(() => {
      if((queryParam === null || queryParam === undefined) && (dragList || dropList)){
        dispatch(dragAndDropActions.emptyLists());
        dispatch(dragAndDropActions.setErrorMessage());
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParam]); // Other dependencies such as 'dispatch', 'dragList', and 'dropList' is not added because the code will behave differently.

  return (
    <Box className={classes?.RulesList || ""} data-testid="RulesList">
      <DataNotFound
        show={_.isEmpty(automationData) ? true : false}
        customsx={{ image: { height: '202px', width: '100%' }, label: {}, content: {}, btns: {} }}
        data={{
          label: "Rules",
          content: getMessageStr('rule-get-noRuleFound') || '',
          action: [
          {
              id: "1001",
              type: "button",
              label: "Create Rule",
              onClick: () => {goTo("/spaces/rules?op=create") },
          }]
        }}
      >
        <>
          <PageHeader title={`Rules`} icon={RulesIcon}>
              <Box>
                  {
                    currentView
                      ? 
                        <StyledButton iconButton tooltip='List View' onClick={(e) => setCurrentView(false)}>
                            <ListView />
                        </StyledButton>
                      :
                        <StyledButton iconButton tooltip='Card View' onClick={(e) => setCurrentView(true)} >
                            <CardView />
                        </StyledButton>
                  }
                  {isAuthorised(features?.device, permissions?.add) && <StyledButton sx={{ ml: 3 }} onClick={() => goTo("/spaces/rules?op=create")}>Create Rule</StyledButton>}
              </Box>
          </PageHeader>

          <Box>
            {currentView ? (
              <GridView 
                data={automationData}
                card={({data: rule}) => {
                  return <StyledCard
                    config={{topBar: { style: {position: "absolute", top: "10px", right: "20px"}}}}
                    data={{   
                      topBar: (() => {
                        let today = new Date().getTime();
                        let content = '-';
                        if(rule?.expiresAtSecs) {
                          if(rule?.expiresAtSecs * 1000 < today) {
                            content = `Expired on: ${formatedDate({date: rule?.expiresAtSecs * 1000})}`
                          }
                          else {
                              content = `Expires at: ${formatedDate({date: rule?.expiresAtSecs * 1000})}`
                          }
                        }
                        return <CustomTypography content={content} variant="body2" styles={{textAlign: 'left', width: "100%", fontSize: '0.625rem', color: "#ed7979", display: "block", lineHeight: "12px"}} />
                      })(),
                      heading: {
                        label: rule.ruleName,
                        to: `?op=view&ruleId=${rule.ruleId}`,
                        // actions: (
                        //   <RuleStatus data={rule} />
                        // )
                      },
                      mediaList: {
                        max: 6,
                        images: filterCardIconsList(rule)
                      },
                      info: {
                        text: `${(rule?.ruleManagedBy === 'cloudManaged' ? 'Cloud Managed' : (rule?.ruleManagedBy === 'controllerManaged' ? 'Controller Managed' : '-'))} `,
                        secondText: `Last Updated: ${rule?.updatedAt && formatedDate({date: rule?.updatedAt * 1000})}`,
                        count: <DeviceTypes data={rule} />
                      }
                    }}
                  />
                }}
              />
            ) : (
              <CustomTable 
                headerList={{
                  ruleName: { label: 'Name', type: 'link', url: "?op=view", urlParams: {ruleId: 'ruleId'} },
                  onTrigger: {label: 'Total Devices', type: 'component', component: DeviceTypes },
                  updatedAt: { label: 'Last Updated On', type: 'date', },
                  expiresAtSecs: { label: 'Expiry', type: 'date'},
                  // status: { label: 'Status', type: 'component', component: RuleStatus }
                }} 
                cellMatrix={tableData || []}
                pagination={true}
                searching={{columns:['ruleName']}}
                removeSortFrom={['onTrigger', 'status']}
              >
              </CustomTable>
            )}
          </Box>
        </>
      </DataNotFound>
    </Box>
  )
};

RulesList.propTypes = {};

RulesList.defaultProps = {};

export default RulesList;
