import mqtt from "precompiled-mqtt";
import { connectionOptions, mqttURL, mqttTimeout } from "../config/mqttConfig";
import { getMessage } from "./message.helper";
import { delay } from "../services";
import { appActions, userActions } from "../redux/actions";
import { mqttCodes, mqttMessageSource } from "../constants/mqtt.constants";
import _ from "lodash";

//set the connected flag as false initially
let connected = false;
let client = null;
let messageCallback = () => { };
export default mqttTimeout;
const onMessageArrived = (topic, message) => {
  try {
    message = JSON?.parse(message);
  }
  catch (err) {
    console.log("message parsing error", message, err);
  }
  messageCallback(message, topic);
};

export const mqttConnect = (connectionSuccess, connectionFail, dispatch, username = null) => {
  //dest,msg,statusTopic,messageCallback
  //messageCallback is used to set the message that is recieved
  //connectionOptions.password="233"

  //const mqtt=require("mqtt")
  //create a client with emqx broker

  // console.log(mqttURL);


  client = mqtt.connect(mqttURL, {
    ...connectionOptions,
    username,
    clientId: `${mqttMessageSource?.webapp}_:${username}:${Date.now().toString()}`
  });
  const onConnect = (client) => {
    console.log("mqtt connection made")
    //set flag on connection
    connected = true;
    // console.log("onConnect");
    connectionSuccess(client);
    // console.log(statusTopic)
    // client.subscribe(statusTopic)
    // console.log(dest,msg)
    // client.publish(dest,JSON.stringify(msg))
  };
  const onConnectionLost = async (responseObject) => {
    //set flag to false on losing connection
    connected = false;
    console.log("lost connection", responseObject)
    if (mqttCodes[responseObject?.code] === "Not Authorized") {
      getMessage(`mqtt-connect-${responseObject?.code}`).then(delay(500)).then((msg) => {
        dispatch(appActions.apiErrorMessage(msg));
      })
      setTimeout(() => {
        dispatch(userActions?.logout());
      }, 3000)
    }
    connectionFail(responseObject);
    // if (responseObject.errorCode !== 0) {
    //   console.log("onConnectionLost:" + responseObject);
    // }
    //reconnect every 10 seconds until connected is true
    // while(connected==false){
    //   console.log("trying to connect")
    //   await sleep(10000)
    //   client = mqtt.connect(mqttURL,connectionOptions)
    // }
  };
  // const onMessageArrived=(topic,message)=> {

  //   console.log(topic)
  //   console.log(message.toString())
  //   if (messageCallback!= undefined)
  //     {
  //       messageCallback((JSON.parse(message)))
  //     }
  //   }

  //register onConnect as the callback for connection
  client.on("connect", () => { onConnect(client) });
  //register onMessageArrived as the callback for message arrival
  client?.on("message", onMessageArrived);

  //register onConnectionLost as the callback for losing the connection
  client?.on("error", onConnectionLost);

  client?.on("close", () => {
    console.log("connection closed")
  });

  client.on("reconnect", () => {
    console.log("reconnecting")
  });

  //client.on('message',onMessageArrived)
  return client;
};
export const mqttSubscribe = (topic, cb = () => { }, client) => {
  //register onMessageArrived as the callback for recieving a message
  messageCallback = cb;
  console.log("SUBCRIBED", topic);
  client?.subscribe(topic);

};
export const mqttPublish = (topic, message, client) => {
  message = JSON.stringify(message);
  client?.publish(topic, message);
  const time = new Date();
  console.log("PUBLISHED", message, topic, time);
};

export const mqttDisconnect = (cb = () => { }) => {
  client?.connected && client?.end(false, {}, (err) => {
    if (err) {
      console.log("error in closing mqtt connection", err);
    }
    else {
      console.log("mqtt connection closed");
      cb();
    }
  });
};

export const mqttUnsubscribe = (topic, cl, callback = () => { }) => {
  if (_.isEmpty(cl)) {
    cl = client;
  }
  const unsub = (error) => {
    if (error) {
      console.log("MQTT ERROR", error)
    } else {
      callback();
      const time = new Date();
      console.log('UNSUBCRIBED', topic, time)
    }
  }
  client?.connected && client?.unsubscribe(topic, unsub);
}


export const findMqttMetaTopic = (topicsArray, topic) => {

  const isMetaTopic = (topic) => {
    return topic?.includes("#") || topic?.includes("+")
  }

  const matchMetaTopic = (a, b) => {
    const firstArray = a?.split("/");
    const secondArray = b?.split("/");
    let match = true;

    for (let i = 0, j = 0; i < firstArray?.length && j < secondArray?.length; i++, j++) {
      if (firstArray[i] === "+" || secondArray[j] === "+") {
        continue;
      }
      else if (firstArray[i] === "#" || secondArray[j] === "#") {
        break;
      }
      else if (firstArray[i] !== secondArray[j]) {
        match = false;
        console?.log("trying to match", match, firstArray[i], secondArray[j])
        break;
      }

    }
    return match
  };

  let topicFound = false

  if (isMetaTopic(topic)) {

    for (const t in topicsArray) {
      console.log("topic compare 1", topicsArray[t], topic)
      if (matchMetaTopic(topicsArray[t], topic)) {
        topicFound = true;
        break;
      }
    }
  }
  else {

    for (const t in topicsArray) {
      console.log("topic compare 2", topicsArray[t], topic, isMetaTopic(topicsArray[t]), matchMetaTopic(topicsArray[t], topic))
      if (isMetaTopic(topicsArray[t]) && matchMetaTopic(topicsArray[t], topic)) {
        topicFound = true;
        break;
      }
      else if (topicsArray[t] === topic) {
        topicFound = true;
        break;
      }
    }
  }
  console.log("topic found", topicFound)

  return topicFound;
}
