import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    TableHelper: {
        // "& .chipStatus": {
        //     color: "rgba(51, 51, 51, 1)",
        //     backgroundColor: "rgba(15, 141, 72, 0.05)",
        //     display:"flex",
        //     alignItems:"center",
        //     borderRadius: "32px",
        //     padding: "4px 8px 4px 8px",
        //     '& .icon': { marginRight: "4px" }
        // },
        "& .chipStatus": {
            "& .MuiChip-label": {
                color: "rgba(51, 51, 51, 1)",
                fontSize: "0.75rem",
                padding: "0px"
            },
            '& .MuiChip-icon': { margin: "0px", marginRight: "4px" },
            backgroundColor: "rgba(15, 141, 72, 0.05)",
            // borderRadius: "32px",
            padding: "4px 8px 4px 8px",
            height: 'auto'
        },
        "& .needsSync": { backgroundColor: 'rgba(221, 6, 6, 0.05)' },
        "& .notOnboarded": { backgroundColor: 'rgba(221, 6, 6, 0.05)' },
        "& .online": {
            color: '#0F8D48',
            fontSize: "0.75rem",
            fontFamily: "Montserrat",
            "& .dotStatus": { backgroundColor: '#0F8D48', marginRight: "4px" }
        },
        "& .offline": {
            color: '#8B8B8B',
            fontSize: "0.75rem",
            fontFamily: "Montserrat",
            "& .dotStatus": { backgroundColor: '#8B8B8B', marginRight: "4px" }
        },
        "& .dotStatus": {
            height: '8px',
            width: '8px',
            borderRadius: '50%',
            display: 'inline-block',
            backgroundColor: "#0F8D48"
        },
        "& .chipIconStyle": { display: 'inline-flex', justifyContent: 'center' }
    },

});
