export const sceneMessages = (item) => {
    return {
        'scene-getAll-noScene': "There is no Scene(s) available",
        'scene-getAll-createScene': ", please create a new scene.",
        'scene-add-noDevicesGroupSelected': 'Please select the Device(s)/Group(s) to proceed further.',
        'scene-add-removeInactiveDevices': 'Please remove inactive device(s) marked in red color.',
        'scene-formValidation-sceneNameEmpty': 'Scene name is required',
        'scene-formValidation-sceneNameInvalid': 'Scene name is invalid',
        'scene-add-sceneNameMinLen': 'Scene name can have minimum 2 characters.',
        'scene-add-sceneNameMaxLen': 'Scene name can have maximum 30 characters.',
        'scene-delete-confirmation': `Are you sure you want to delete ${item} scene?`,
        'scene-create-confirmation': `Are you sure you want to use default values of intensity and temperature for ${item}?`,
        'scene-create-confirmationIntensity': `Are you sure you want to use default value of intensity for ${item}?`,
        'scene-create-confirmationTemp': `Are you sure you want to use default value of temperature for ${item}?`,
        "scene-getParent-error": `Unable to fetch details of the  ${item} that manages this scene.`,
    }
}
export const sceneAPIMessages = {
    "scene-post-200": "Scene successfully created.",
    "scene-post-201": "Scene successfully created.",
    "scene-post-400": "Unable to create the Scene, please verify the inputs and try again.",
    "scene-post-401": "Unable to create the Scene, please check your access and try again.",
    "scene-post-403": "Unable to create the Scene, please check your access and try again.",
    "scene-post-404": "Unable to retrieve the Device(s), please try again later.",
    "scene-post-500": "Unable to create the Scene due to internal error, please try again later.",
    "sceneBySpace-get-200": "Scene(s) successfully retrieved.",
    "sceneBySpace-get-401": "Unable to retrieve the Scene(s), please check your access and try again.",
    "sceneBySpace-get-403": "Unable to retrieve the Scene(s), please check your access and try again.",
    "sceneBySpace-get-404": "Unable to retieve the space, please try again later.",
    "sceneBySpace-get-500": "Unable to retieve the scene(s) due to internal error, please try again later.",
    "sceneById-get-200": "Scene Details successfully retrieved.",
    "sceneById-get-401": "Unable to retrieve the Scene, please check your access and try again.",
    "sceneById-get-403": "Unable to retrieve the Scene, please check your access and try again.",
    "sceneById-get-404": "Unable to retieve the Scene, please try again later.",
    "sceneById-get-500": "Unable to retieve the Scene, due to internal error, please try again later.",
    "sceneByDevice-get-200": "Scene(s) successfully retrieved.",
    "sceneByDevice-get-400": "Unable to retrieve the Scene(s), please try again later.",
    "sceneByDevice-get-401": "Unable to retrieve the Scene(s), please check your access and try again.",
    "sceneByDevice-get-403": "Unable to retrieve the Scene(s), please check your access and try again.",
    "sceneByDevice-get-404": "Unable to retrieve the Device, please try again later.",
    "sceneByDevice-get-500": "Unable to retieve the Scene(s) due to internal error, please try again later.",
    "scene-delete-200": "Scene successfully deleted.",
    "scene-delete-204": "Scene successfully deleted.",
    "scene-delete-401": "Unable to delete the Scene, please check your access and try again.",
    "scene-delete-403": "Unable to delete the Scene, please check your access and try again.",
    "scene-delete-404": "Unable to delete the Scene, please try again later.",
    "scene-delete-500": "Unable to delete the Scene due to internal error, please try again later.",
    "scene-put-200": "Scene successfully updated.",
    "scene-put-400": "Unable to edit the Scene, please verify the inputs and try again.",
    "scene-put-401": "Unable to edit the Scene, please check your access and try again.",
    "scene-put-403": "Unable to edit the Scene, please check your access and try again.",
    "scene-put-404": "Unable to retrieve the Scene/Device(s), please try again later.",
    "scene-put-500": "Unable to edit the Scene due to internal error, please try again later.",
    "sceneSync-put-200": "Scene sync operation is accepted, it may take a while to get completed.",
    "sceneSync-put-401": "Scene Sync operation is failed, please check your access and try again.",
    "sceneSync-put-403": "Scene Sync operation is failed, please check your access and try again.",
    "sceneSync-put-404": "Scene Sync operation is failed, please refresh the page and try again.",
    "sceneSync-put-412": "Scene Sync operation is failed, please try again later.",
    "sceneSync-put-500": "Scene Sync operation is failed due to internal error, please try again later.",

    "syncScenesByDevice-put-200": "Scene(s) Sync request initiated successfully, it may take while to get completed.",
    "syncScenesByDevice-put-401": "Scene Sync request is failed to initiate, please reverify your access and try again.",
    "syncScenesByDevice-put-403": "Scene Sync request is failed to initiate, please reverify your access and try again.",
    "syncScenesByDevice-put-404": "Scene Sync request is failed to initiate, please try again later.",
    "syncScenesByDevice-put-405": "Scene Sync request is failed to initiate, please try again later.",
    "syncScenesByDevice-put-500": "Scene Sync request is failed to initiate due to internal issue, please try again later.",

    "deleteScenesByDevice-delete-200": "Scenes successfully deleted.",
    "deleteScenesByDevice-delete-204": "Scenes successfully deleted.",
    "deleteScenesByDevice-delete-401": "Unable to delete Scenes, please check your access and try again.",
    "deleteScenesByDevice-delete-403": "Unable to delete Scenes, please check your access and try again.",
    "deleteScenesByDevice-delete-404": "Unable to delete Scenes, please try again later.",
    "deleteScenesByDevice-delete-500": "Unable to delete Scenes due to internal error, please try again later.",

    "sceneApply-post-200": "Scene Apply operation is accepted, it may take a while to get completed.",
    "sceneApply-post-201": "Scene Apply operation is accepted, it may take a while to get completed.",
    "sceneApply-post-204": "Scene Apply operation is accepted, it may take a while to get completed.",
    "sceneApply-post-401": "Unable to Apply the Scene, please check your access and try again.",
    "sceneApply-post-403": "Unable to Apply the Scene, please check your access and try again.",
    "sceneApply-post-404": "Unable to Apply the Scene, please refresh your page and try again.",
    "sceneApply-post-500": "Unable to Apply the Scene due to internal error, Please try again later.",

    
    "multipleScenes-get-200": "Scene(s) successfully retrieved.",
    "multipleScenes-get-400": "Unable to retrieve the Scene(s), please try again later.",
    "multipleScenes-get-401": "Unable to retrieve the Scene(s), please check your access and try again.",
    "multipleScenes-get-403": "Unable to retrieve the Scene(s), please check your access and try again.",
    "multipleScenes-get-404": "Unable to retrieve the Scene(s), please try again later.",
    "multipleScenes-get-500": "Unable to retieve the Scene(s) due to internal error, please try again later.",

}