import { makeStyles } from '@mui/styles';

export const useStyles = (sideMenuOpen) => {
   return makeStyles(theme => ({
        GridView: {
            "& .MuiPaginationItem-root": {
                color: "#888",
                fontWeight: 400,
                fontSize: '0.75rem'
            },
            "& .Mui-selected": {
                backgroundColor: '#fff !important',
                color: '#0F8D48',
                fontWeight: 600,
                fontSize: '0.75rem'
            },
            "& .MuiPaginationItem-previousNext": {
                color: '#0F8D48',
                "&.Mui-disabled": {
                    color: '#888',
                }
            },
            "& .cardItem": {
                minWidth: "255px",
                // [theme?.breakpoints?.up('xxl')]: {
                //         width:"20%"
                // },
                // [theme?.breakpoints?.down('xxl')]: {
                //         width:"25%"
                // },
                // [theme?.breakpoints?.down('xl')]: {
                //         minWidth:"358px",width:"33.33%"
                // },
                // [theme?.breakpoints?.down('lg')]: {
                //         minWidth:"336px",width:"50%"
                // },
                [theme?.breakpoints?.down('md')]: {
                    minWidth: sideMenuOpen ? "100%" : "300px"
                    // minWidth:"300px",
                    // maxWidth:"310px",
                    // width:"50%"
                },
                [theme?.breakpoints?.down('sm')]: {
                        minWidth:"255px",
                        // maxWidth:"300px",width:"100%"
                },
            }
        }
    })
    )};
