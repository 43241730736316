/***
 *
 * Controller class for user.
 * @file Settings.js
 * @description Settings component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { history, isAuthorised } from '../../helpers';
// import PropTypes from 'prop-types';
import { useStyles } from './Settings.style.js';
import { features } from '../../constants';

const Settings = () => {
  const classes = useStyles()

  useEffect(() => {
    if(isAuthorised(features?.space)) {
      history.replace("/settings/space-types");
    }
    else {
      history.replace("/settings/design-system");
    }
  }, []);
  return (
    <Box className={classes?.Settings || ""} data-testid="Settings">

    </Box>
  )
};

Settings.propTypes = {};

Settings.defaultProps = {};

export default Settings;
