/***
 *
 * Controller class for user.
 * @file DataNotFound.js
 * @description DataNotFound component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './DataNotFound.style.js';
import ImageWrapper from '../../components/ImageWrapper/index.js';
import noDataFound from "../../assets/icons/nodatafound.svg"
import CustomTypography from '../../components/CustomTypography/index.js';
import StyledSpinner from '../../components/StyledSpinner/index.js';
import { getSessionItem, setSessionItem } from '../../helpers/storage.helper.js';
import { parseBoolean } from '../../services/util.service.js';
import StyledButton from '../../components/StyledButton/index.js';

const DataNotFound = ({ 
  children, 
  show = true,
  hideLoader= false,
  data = { 
    image: null, 
    label: "No Data Found",
    content: null,
    action: null //[{id: "<some id>", type: "button", label: "Create Rule", onClick: (event, data) => {}}]
  }, 
  customsx = null, 
  style = {} 
}) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(!show ? false : true);
  const [noData, setNoData] = useState(show);
  const hideDataNotFound = getSessionItem("hideDataNotFound");

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [hideLoader])

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setNoData(show);
      setSessionItem("hideDataNotFound", false);
    }, 100);
  }, [show]);

  useEffect(() => {
    if (parseBoolean(hideDataNotFound)) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 10000);
    }
    else {
      setTimeout(()=>{setLoading(false)}, 1000);
    }
  }, [hideDataNotFound])

  return (
    <>
    {/* {JSON.stringify(hideDataNotFound) + "==" + typeof(hideDataNotFound)} --- {JSON.stringify(show) + "==" + typeof(show)} --- {JSON.stringify(loading) + "==" + typeof(loading)} */}
    <Box style={{ position: "relative", height: style?.height || "auto" }} data-testid="DataNotFound">    
      {(loading) &&
        <Box sx={{ position: 'absolute', top: 0, bottom: 0, width: "100%", height: "100%", backgroundColor: "#f6faf8", zIndex: 1090, minHeight: "350px" }}>
          <StyledSpinner sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />          
        </Box>
      }

      {noData && !parseBoolean(hideDataNotFound) ? (
        <Box className={classes?.DataNotFound || ""} style={style}>
          <ImageWrapper src={data?.image || noDataFound} style={{ width: "250px", ...customsx?.image }} alt={"Label"} />
          {data?.label &&
            <Box className={classes?.Label || ""}>
              <CustomTypography component="div" content={data?.label} sx={{ ...customsx?.label }} weight={500} size={18} color={'rgba(15, 141, 72, 1)'} lineHeight={22} />
            </Box>
          }
          {data?.content && typeof (data?.content) === "string" &&
            <Box className={classes?.Content || ""} sx={{ mt: 2 }}>
              <CustomTypography component="div" content={data?.content} sx={{ ...customsx?.content }} weight={400} size={16} color={'rgba(0, 0, 0, 0.6)'} lineHeight={22} />
            </Box>
          }
          {data?.action &&
            <Box className={classes.Buttons} sx={{ mt: 2 }}>
              {data?.action?.map((act, ind) => {
                return <StyledButton disabled={act?.disabled} variant={act?.variant || "contained"} key={ind} onClick={(event) => act?.onClick(event, data)} sx={{ ...customsx?.btns }}>{act?.label}</StyledButton>
              })}
            </Box>
          }
        </Box>
      ) : (children)}
    </Box>
    </>
  )
};

DataNotFound.propTypes = {};

DataNotFound.defaultProps = {};

export default DataNotFound;
