import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    TwoDLayout: {

    },
    
    EditorDialog:{
        '& .MuiDialog-paper': { overflowY: 'hidden', maxWidth: "100vw", padding: "0px 5px" } 
    }
});
