/***
 *
 * Controller class for user.
 * @file PageHeader.js
 * @description PageHeader component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { Children, useRef, useState } from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './PageHeader.style.js';
import './PageHeader.scss';
import { Box, Grid, Menu, MenuItem, Typography } from '@mui/material';
import BackButton from '../BackButton/index.js';
import { history } from '../../helpers/history.helper.js';
import ImageWrapper from '../ImageWrapper/index.js';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deviceActions } from '../../redux/actions/device.actions.js';
import StyledBreadcrumbs from '../StyledBreadcrumbs/index.js';
import { Helmet } from 'react-helmet-async';
// import useWindowDimensions from '../../hooks/useWindowDimensions.js';
// import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import StyledButton from '../StyledButton/index.js';


const PageHeader = ({ id, page, data, children, bckbtn, textTransform = "capitalize", backfunc = () => { history.goBack() }, breadcrumbs = { device: false }, boxImgStyles, imgStylesPageHeader, pageHeaderStyle, imgStyles, textStyles, icon, flright = true, title }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const childrenBox = useRef();
  // const windowDimensions = useWindowDimensions();
  const [anchorMenu, setAnchorMenu] = useState(null);
  const open = Boolean(anchorMenu);


  const deviceBreadCrumbs = useSelector(state => state?.devices?.deviceBreadCrumbs);
  const controllerList = useSelector((state) => state?.devices?.controllerList);
  const gatewayList = useSelector((state) => state?.devices?.gatewayList);

  const parentDevice = useSelector((state) => state?.devices?.parentDevice);

  const [breadcrumbsData, setBreadcrumbsData] = useState([]);

  useEffect(() => {
    if (deviceBreadCrumbs) {
      // setBreadcrumbsData([{ "id": 1, "label": "Devices", "url": "/spaces/devices", "disabled": true }, ...deviceBreadCrumbs]);
      setBreadcrumbsData([...deviceBreadCrumbs]);
    }
  }, [deviceBreadCrumbs]);

  useEffect(() => {
    if (data && ["device", "controller", "gateway", "aggregator"].includes(page)) {
      // console.log("ssss", data.deviceId, page)
      dispatch(deviceActions.deviceBreadCrumbs({ data, page }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, page, gatewayList, controllerList, parentDevice])

  const handleOpenMenu = (e) => {
    setAnchorMenu(e?.currentTarget);
  }

  const handleClose = () => {
    setAnchorMenu(null);
  };


  const getAllMenuItems = (menuItems, collected) => {
    menuItems?.forEach((item) => {
      // console.log('item loop::',item);

      if (Array.isArray(item?.props?.children)) {
        getAllMenuItems(item?.props?.children, collected);
      }
      else if (Array.isArray(item)) {
        getAllMenuItems(item, collected)
      }
      else {
        // console.log('type is here:::',typeof item?.props?.children);
        if (typeof item?.props?.children === 'object') {
          if (item?.props?.type !== 'icon' && item?.props?.children?.props) {
            collected?.push(item?.props?.children?.props)
          }
          else {
            collected?.push(item?.props)
          }
        }
        else {
          if (item && item?.props && item?.props?.children)
            collected?.push(item?.props);
        }
      }
    })

    return collected;

  }

  return (
    <Box className={classes?.PageHeader || ""} style={{ ...pageHeaderStyle }} data-testid="PageHeader">
      <Helmet>
        <title>MSS | {`${title || '...'}`}</title>
      </Helmet>
      <Grid container sx={{ display: 'flex', alignItems: 'center'}}>
        <Grid item xs={12} sm={6}>
          <Box style={{ ...imgStylesPageHeader }} sx={{ display: "inline-block", height: "100%" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {bckbtn &&
                <StyledButton 
                  iconButton 
                  sx={{ mr: 1 }} 
                  onClick={() => { backfunc() }} 
                  style={{
                    backgroundColor: "#0F8D4803",
                    padding: "4px 8px",
                    height: "28px",
                    width:'28px',
                    borderRadius: "3px",
                    '&:hover, &:focus': "border-radius: 3px",
                  }}
                >
                  <BackButton />
                </StyledButton>
              }
              {/* <PetsIcon /> */}
              <Box style={{ ...boxImgStyles }} className={classes?.Icon || ""} component="span"><ImageWrapper src={icon} alt="LOGO" style={{ ...imgStyles }} /></Box>
              <Box style={{ display: "inline-block" }}>
                <Typography style={{ fontWeight: 700, fontSize: '1.125rem', lineHeight: '22px', textTransform: textTransform, ...textStyles }} variant="h5">{title}</Typography>
                {breadcrumbs.device && breadcrumbsData?.length > 1 &&
                  <StyledBreadcrumbs data={breadcrumbsData} sx={{ p: 0 }} fontSize={12} />
                }
              </Box>
              {/* <LaunchIcon /> */}
            </Box >
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'flex-end'}}>
          <Box ref={childrenBox}>
            {
              // (() => {
              //   if (windowDimensions?.width > 549) {
              //     return <>{children}</>
              //   }
              //   else {
              //     return <>{!_.isEmpty(getAllMenuItems(Array.from(Children.toArray(children)), [])) && <MoreHorizIcon onClick={handleOpenMenu} id={"menuButtonHeader"} />}</>
              //   }
              // })()
            }
            {children}
          </Box>
        </Grid>
      </Grid>

      <Menu
        id="basic-menu"
        anchorEl={anchorMenu} open={open} sx={{ p: 0 }} onClose={handleClose} MenuListProps={{ 'aria-labelledby': 'basic-button' }}>
        {
          // console.log('children came::::',children,   Children.toArray(children) &&  getAllMenuItems(Array.from(Children.toArray(children)),[]))
        }
        {
          Children.toArray(children) && getAllMenuItems(Array.from(Children.toArray(children)), [])?.map((item, index) => (
            <Box key={"pHeader---" + index}>
              {
                item?.type === 'icon'
                  ?
                  <MenuItem {...item} onClick={(e) => { item?.onClick(e); handleClose(e) }} sx={{ m: 0 }} className={classes?.MenuItem}><Box maxWidth={'40%'}  minWidth={'25%'}> {item?.children}</Box><Box minWidth={'60%'} maxWidth={'75%'}> {item?.label}</Box></MenuItem>
                  :
                  <MenuItem {...item} onClick={(e) => { item?.onClick(e); handleClose(e) }} sx={{ m: 0 }} className={classes.MenuItem} >{item?.children}</MenuItem>
              }
            </Box>
          ))
        }
      </Menu>

    </Box >
  )
};

PageHeader.propTypes = {};

PageHeader.defaultProps = {};

export default PageHeader;
