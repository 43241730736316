import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    CustomCard: {
        "& .MuiPaper-root": {
            padding: "0px",
            borderRadius: "4px",
            border: "1px solid #E5E5E5",
            background: "#FFF"
        },
        "& .cardContainer ": {
            height: '100%',
            "& .inactiveCard": {
                minHeight: "100px",
                backgroundColor: "#d3d3d324"

            },
            "& .activeCard": {
                minHeight: "100px",
                backgroundColor: "#FFF"
            }
        },
        "& .cardTop": {
            // width: "100%",
            display: "flex",
            position: "relative",
            justifyContent: "center"
        },
        "& .cardStatus": {
            borderRadius: "0px 0px 32px 32px",
            display: "inline-flex",
            padding: "4px 32px",
        },
        // "& .cardStatusContainer": {
        //     width: "100%",
        //     display: "flex"
        // },
        "& .actionsContainer": {
            display: "flex",
            marginRight: "16px",
            marginTop: "16px",
            position: "absolute",
            right: "1%"
        },
        "& .action-buttons": {
            "& button": {
                "marginLeft": "8px !important",
                "width": "auto !important",
                "height": "auto !important",
                "padding": "0px !important",
            }
        },
        "& .moreActions": {
                filter: "invert(90%) sepia(0%) saturate(60%) hue-rotate(270deg) brightness(103%) contrast(98%)"
        },
        "& .active": {
            background: "rgba(15, 141, 72, 0.15)",
        },
        "& .inactive": {
            background: "rgba(188, 34, 0, 0.15)",
        },
        "& .cardContent": {
            borderTop: "4px solid rgba(15, 141, 72, 0.05)",
            padding: "8px 24px 16px 24px"
        },
        "& .subStatus": {
            display: "flex",
            flexWrap: "wrap",
            alignItems: 'center',
            marginTop: "8px"
        },
        "& .MuiCardHeader-content":{
            display:"flex",
            flexDirection:"column",
            justifyContent:"center"
        }
    }
});
