/***
 *
 * Controller class for user.
 * @file CustomScheduler.js
 * @description CustomScheduler component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useState, useEffect } from 'react';
import { useStyles } from './CustomScheduler.style.js';
import './CustomScheduler.scss';
import { Box, FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import StyledDateTimePicker from '../../StyledDateTimePicker/index.js';
import StyledButton from '../../StyledButton/index.js';
import StyledChip from '../../StyledChip/index.js';
import { ReactComponent as Cancel } from "../../../assets/icons/cancel.svg"
import CustomTypography from '../../CustomTypography/index.js';
import StyledTextfield from '../../StyledTextfield/index.js';
import InfoIcon from '../../../assets/icons/info.svg';
import ImageWrapper from '../../ImageWrapper/index.js';
import _ from 'lodash';

const CustomScheduler = ({ currentSchedule = {}, editClicked = false, scheduleHandler = () => {} }) => {
  const classes = useStyles();

  const [selectedType, setSelectedType] = useState("time");
  const [currentTime, setCurrentTime] = useState(null);
  const [timeList, setTimeList] = useState([]);
  const [timeDurationList, setTimeDurationList] = useState([]);
  const [intervalInMinutes, setIntervalInMinutes] = useState(1);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [error, setError] = useState(null);
  const[isFirstTime, setIsFirstTime] = useState(true);

  const handleSelectedType = (event) => {
    setSelectedType(event?.target?.value);
  }

  const getTimeFormat = (time) => {
    let hour = time?.$H
    let minute = time?.$m
    if (minute < 10) {
      minute = "0" + minute
    }
    if (hour < 10) {
      hour = "0" + hour
    }
    let timeFormat = {
      id: `${hour}${minute}`,
      label: hour + ":" + minute
    }
    return timeFormat
  }
  
  const saveTime = () => {
    let time = getTimeFormat(currentTime);
    if (timeList?.filter((t) => t?.id === time?.id)?.length === 0) {
      setTimeList([...timeList, time])
    }
    setCurrentTime(null);
  }

  const saveTimeDuration = () => {
    let startTimeFormat = getTimeFormat(startTime);
    let endTimeFormat = getTimeFormat(endTime);
    let timeduration = {
      id: startTimeFormat?.id + "-" + endTimeFormat?.id,
      label: startTimeFormat?.label + "-" + endTimeFormat?.label,
      interval: intervalInMinutes
    }
    if (timeDurationList?.filter((t) => t?.id === timeduration?.id)?.length === 0) {
      setTimeDurationList([...timeDurationList, timeduration])
    }
    setStartTime(null);
    setEndTime(null);
    setIntervalInMinutes(1);
  }

  const changeIntervalInMinutes = (value) => {
    if (value < 1 || value > 1440) {
      setError({ ...error, intervalInMinutes: "Duration can be between 1 and 1440 minutes" })
    }
    else {
      setError({ ...error, intervalInMinutes: null });
      setIntervalInMinutes(value);
    }
  }

  const deleteTime = (label, valueList, setter) => {
    const filteredData = valueList?.filter(t => t?.label !== label) || [];
    setter([...filteredData]);
    if(_.isEmpty(filteredData)) {
      scheduleHandler(null)
    }
  }

  useEffect(() => {
    if(timeList.length || timeDurationList.length) {
      scheduleHandler({type: "Time", timeOfDay: [...timeList, ...timeDurationList]})
      setIsFirstTime(false);
    }
  }, [timeList, timeDurationList]);

  useEffect(() => {
    if(currentSchedule && editClicked && isFirstTime) {
      const scheduleData = currentSchedule?.timeOfDay || [];
      let timeRangeData = [];
      let timeData = [];
      scheduleData.forEach((schedule) => {
        if(schedule.hasOwnProperty("interval")) {
          timeRangeData.push(schedule);
        }
        else {
          timeData.push(schedule);
        }
      })
      setTimeList([...timeData]);
      setTimeDurationList([...timeDurationList, ...timeRangeData]);
    }
  }, [currentSchedule]) 

  return (
    <Box className={classes?.CustomScheduler || ""} data-testid="CustomScheduler" sx={{ minHeight: "160px", minWidth: "570px" }} >
      <FormControl>
        <RadioGroup 
          row 
          aria-labelledby="schedule-radio-buttons-group-label" 
          name="schedule-radio-buttons-group"
          value={selectedType}
          onChange={handleSelectedType}
        >
          <FormControlLabel value="time" sx={{marginRight: '0.5rem'}} control={<Radio />} label="Set Time" />
          <ImageWrapper style={{ marginBottom: '0.5rem',marginRight: '2.5rem' }} enablePopOver hoverLabel={"Choose the time and click on the add button."} src={InfoIcon}/>
          <FormControlLabel disabled sx={{marginRight: '0.5rem'}} value="timeDuration" control={<Radio />} label="Set Time Range" />
          <ImageWrapper style={{ marginBottom: '0.5rem', opacity: '0.4' }} src={InfoIcon}/>
        </RadioGroup>
      </FormControl>
      {
        selectedType === "time"
        ? <Grid container item xs={12} spacing={1}>
          <Grid item xs={12} lg={4} md={4} >
            <StyledDateTimePicker
              picker="TimePicker"
              value={currentTime}
              placeholder="Select Time"
              onChange={(e) => { setCurrentTime(e?.target?.value) }} 
            />
          </Grid>
          <Grid item xs={12} lg={8} md={8} >
            <StyledButton onClick={saveTime} sx={{ height: "40px"}} disabled={currentTime ? false : true}> Add </StyledButton>
          </Grid>
        </Grid>
        : <Grid container item xs={12} spacing={1}>
          <Grid item xs={12} md={3} lg={3}  >
            <CustomTypography
              weight={400}
              color={"#333333"}
              size={14}
              lineHeight={17}
              content="Start Time"
              styles={{ marginBottom: "8px" }}
            />

            <StyledDateTimePicker
              picker="TimePicker"
              value={startTime}
              placeholder="Select Start Time"
              onChange={(e) => { setStartTime(e?.target?.value) }} />
          </Grid>

          <Grid item xs={12} md={3} lg={3}  >
            <CustomTypography
              weight={400}
              color={"#333333"}
              size={14}
              lineHeight={17}
              content="End Time"
              styles={{ marginBottom: "8px" }}
            />
            <StyledDateTimePicker
              picker="TimePicker"
              value={endTime}
              placeholder="Select End Time"
              onChange={(e) => { setEndTime(e?.target?.value) }} />
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <CustomTypography
              weight={400}
              color={"#333333"}
              size={14}
              lineHeight={17}
              content="Interval In Minutes"
              styles={{ marginBottom: "8px" }}
            />
            <StyledTextfield
              fullWidth
              error={error?.intervalInMinutes ? true : false}
              helperText={error?.intervalInMinutes}
              value={intervalInMinutes}
              type="number"
              inputProps={{ min: 1, max: 1440 }}
              onChange={(event) => { changeIntervalInMinutes(event?.target?.value) }}
            >
            </StyledTextfield>
          </Grid>

          {/* <Grid item xs={12} md={2} lg={2}>
          </Grid> */}
          <Box>
            <StyledButton onClick={saveTimeDuration} sx={{ mt: "30px", ml: 1, minHeight: "44px" }} disabled={startTime && endTime ? false : true}> Add </StyledButton>
          </Box>
        </Grid>
      }

      <Grid item xs={12}>
        {timeList?.map((t, ind) =>
          <StyledChip
            variant="outlined"
            key={ind}
            color="primary"
            label={t?.label}
            deleteIcon={<Cancel />}
            onDelete={(e) => { deleteTime(t?.label, timeList, setTimeList) }} 
          />
        )}
        {timeDurationList?.map((time, ind) =>
          <StyledChip
            variant="outlined"
            key={ind}
            color="primary"
            label={time?.interval === 1 ? time?.label : time?.label + ", Every " + time.interval + " Mins" }
            sx={{}}
            deleteIcon={<Cancel />}
            onDelete={(e) => { deleteTime(time?.label, timeDurationList, setTimeDurationList) }} />
        )}
      </Grid>
    </Box>
  )
};

CustomScheduler.propTypes = {};

CustomScheduler.defaultProps = {};

export default CustomScheduler;
