import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    StyledCheckBox: {
      
    },
    horizontal:{
        display:'flex',
        flexWrap:'wrap'
    },
    vertical:{
        width:'min-content',
    },
    checkBoxDiv:{
        display:'flex',
        alignItems:'center',
        justifyContent: 'space-between',
        marginBottom:'8px'
    },
    isLeft:{
        paddingRight: "8px"
    },
    isRight:{
        paddingLeft: "8px"
    }
});
