/***
 *
 * Controller class for user.
 * @file StyledBadge.js
 * @description StyledBadge component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React from 'react';
// import PropTypes from 'prop-types';
import './StyledBadge.scss';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';

const StyledBadge = styled(Badge)(({ theme, bgcolor }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: bgcolor || '#44b700',
    color: bgcolor || '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    width: 15,
    height: 15,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '5px',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  }
}));

// const StyledBadge = (props) => {
//   const classes = useStyles();

//   return (
//     <sBadge data-testid="StyledBadge" {...props}></sBadge>
//   )
// };

StyledBadge.propTypes = {};

StyledBadge.defaultProps = {};

export default StyledBadge;
