import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    DebugGateway: {

    },
    scrollBox: {
        minHeight: "360px",
        maxHeight: "360px",
        overflowY: "scroll",
        border: "1px solid rgba(224, 224, 224, 1)",
        borderRadius: "4px",
        padding: "8px",
        position: "relative",
        backgroundColor: "#000000",
        "& .CustomTypography": {
            fontFamily: "Consolas !important",
            color: "#FFFFFF !important",
            fontSize: "0.875rem !important",
            lineHeight: "1rem",
            fontWeight: "700"
        },
        "& .MuiFormLabel-root": {
            fontFamily: "Consolas !important",
            color: "#FFFFFF !important",
            fontSize: "0.875rem !important",
            marginBottom: "0px",
            height: "1rem",
            lineHeight: "1rem",
            fontWeight: 500,
            marginRight: "8px"

        },
        "& pre": { margin: "0px" }
    },
    loader: {
        position: "absolute",
        top: "5%",
        left: "5%",
        transform: "translate(-50%, -50%)",
    },
    executedCommands: {
        "& .spinner-3": {
            width: "30px"
        }
    },
    currentLine: {
        display: "flex",
        "& .MuiInputBase-input": {
            padding: "2px 0px 0px 0px !important",
            border: "none",
            backgroundColor: "#000000",
            fontFamily: "Consolas !important",
            color: "#FFFFFF !important",
            fontSize: "0.875rem !important",
            height: "1rem !important",
        },
        "& .MuiFormLabel-root": {
            fontSize: "0.875rem",
            lineHeight: "1rem",
            height: "1rem",
            fontWeight: 500,
            marginLeft: "8px",
            marginRight: "8px"

        },
        "& .Mui-focused": {
            border: "none"
        }
    }

});
