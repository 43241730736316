/***
 *
 * Controller class for user.
 * @file CodeSnippets.js
 * @description CodeSnippets component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './CodeSnippets.style.js';
import './CodeSnippets.scss';
import { Box } from '@mui/material';
// import { CopyBlock, dracula } from 'react-code-blocks';
// import { renderToString } from "react-dom/server";

const CodeSnippets = ({children}) => {
  const classes = useStyles()

    return (
        <Box className={classes?.CodeSnippets || ""} data-testid="CodeSnippets">
          {/* <CopyBlock
            language={"jsx"}
            text={"`" + React.createElement(children) + "`"}
            showLineNumbers={true}
            theme={dracula}
            wrapLines={true}
            codeBlock
          /> */}
          
          <Box sx={{ p: 5, background: "#f7f7f7" }}>
              {children}
          </Box>
        </Box>
    )
};

CodeSnippets.propTypes = {};

CodeSnippets.defaultProps = {};

export default CodeSnippets;
