import { API_URL } from '../config/appConfig'
import { API } from '.'
import { handleResponse } from '../helpers';
const accessToken = localStorage.getItem('miraie')?.accessToken;

const createAutomationRule = (payload) => {
    return API.post(`${API_URL.automation}/rules`, "createAutomationRule", payload).then((res) => {
        return handleResponse(res,true);
    });
}

const getAllAutomationRule = (spaceId = '') => {
    return API.get(`${API_URL.automation}/rules?tags=automation&spaceId=${spaceId}`, "getAllAutomationRule", {}).then((res) => {
        return handleResponse(res,true);
    });
}

const getAutomationRuleById = (ruleId) => {
    return API.get(`${API_URL.automation}/rules/${ruleId}`, "getAutomationRuleById", ruleId).then((res) => handleResponse(res,true));
}

const deleteAutomationRuleById = (ruleId)=> {
    return API.delete(`${API_URL.automation}/rules/${ruleId}`, "deleteAutomationRuleById", {authorization: accessToken}).then((res) => handleResponse(res,true));
}

const updateAutomationRule =(ruleId, payload) => {
    return API.put(`${API_URL.automation}/rules/${ruleId}`, "updateAutomationRule", payload).then((res) => {
        return handleResponse(res,true);
    });
}


export const automationService = {
    createAutomationRule,
    getAllAutomationRule,
    getAutomationRuleById,
    deleteAutomationRuleById,
    updateAutomationRule
};