import { makeStyles } from '@mui/styles';
export const temperatureSliderCss = { 
    width: "100px" ,"& .MuiSlider-rail": {
    background: 'linear-gradient(90deg, #FF0000 0.83%, #E9FA00 15.6%, #22FF01 32.13%, #00E5FF 46.59%, #1803FF 65.19%, #FF00E9 80.68%, #FF0030 100%)',
    height:'3px'
  },"& .MuiSlider-track":{
    height: '1px',
    border: 'none',
    color:'transparent'
    }}
export const useStyles = makeStyles({
    SceneDetails: {

    }

});
