import { Box, Grid, Typography } from '@mui/material';
import React,{ useState } from 'react';
import PageHeader from '../PageHeader';
import CustomTable from '../CustomTable';
import { history } from '../../helpers';
import FormWrapper from "../FormWrapper/index.js";
import DownloadIcon from '@mui/icons-material/Download';
import User from '../../assets/icons/user.svg';
import './BulkRegistration.scss';

const dummyData = [
  {
  id:11,
  name: "Bulk_01",
  uploadDate: "24/07/2022",
  uploadBy: "Ajit Kumar",
  actionss: <DownloadIcon/>
  },
  {
  id:12,
  name: "Bulk_02",
  uploadDate: "24/07/2022",
  uploadBy: "Ajit Kumar",
  actionss: <DownloadIcon/>
  },
  {
  id:13,
  name: "Bulk_03",
  uploadDate: "24/07/2022",
  uploadBy: "Ajit Kumar",
  actionss: <DownloadIcon/>
  }
];

const frmConfig = [
    {    section: { heading: null },
      columns: [
        {
          id: "6",
          type: "file",
          label: "",
          allowedFileType: '.csv,application/vnd.ms-excel"',
          name: "csv",
        //   required: true,
          format: ['xls', 'csv'],
          value: "",
        //   validation: {
        //     req: [true, 'img required'],
        //     fileSize: [1000, 'File size should be less than 1MB'], //// image size is 1MB
        //   },
          customMsg:{drag: "Upload a file, or Drag and Drop(supports .xls,.csv)"},
          placeholder: "Enter",       
          columnSize: 12,
          confirm:true
        },
      ]
  }
  ];


const BulkRegistration = () => {

const [formData, ] = useState({});

const goTo = (route) => {
history.push(route);
};

return (
    <>
          <Box sx={{ maxWidth: "100%" }}  data-testid="BulkRegistration">
            <PageHeader
              bckbtn={true}
              flright={false}
              pageHeaderStyle={{display:"flex" ,alignItems:'center', justifyContent:'space-between'}}
              backfunc={(e) => goTo(`/user-management/users`)}
              title={"Bulk User Registration"}
              icon={User} >
            </PageHeader>
      <Grid container item xs={12} >
        <FormWrapper customsx={{ formbtn: { justifyContent: 'flex-start' },imgDiv: {width: "100%"},outerdiv: {width: "100%"},formdiv: {width: "100%"}  }}
        
         formData={formData} formFields={frmConfig} />
      </Grid>
      <Box className="subHeading">
        <Typography style={{ fontWeight: 600, fontSize: "0.8em" }}variant="h6">View Bulk Registration</Typography>
      </Box>
      <br/>
      <CustomTable headerList={{
          name: { label: 'File Name',type: "link"},       
          uploadDate: { label: 'Upload Date', type: 'text' },
          uploadBy: { label: 'Uploaded By', type: 'text' },
          actionss: { label: 'Actions', type: 'image' },
        }} cellMatrix={dummyData} >
        </CustomTable>
          </Box>

        
    </>
       
     )}

BulkRegistration.propTypes = {};

BulkRegistration.defaultProps = {};

export default BulkRegistration;