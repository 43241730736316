export const ruleMessages = (item) => ({
    'rule-add-noDevicesSelected': 'Please select the device(s) to proceed further.',
    'rule-add-removeInactiveDevices': 'Please remove inactive item(s) marked in red color.',
    'rule-add-sensorConfiguration': 'Please select appropriate sensor configuration.',
    'rule-add-scheduleDetails': 'Please add schedule details to proceed further.',
    'rule-add-timeoutValueZero': 'Please enter the non zero Timeout value in second.',
    'rule-add-noSensorSelected': 'Please select the sensor(s) to proceed further.',
    'rule-get-noRuleFound': 'There is no rule(s) available, Please create a new rule.',
    'rule-get-noSensorFound': 'The defined rule does not have any sensor.',
    'rule-get-noScheduleFound': 'The defined rule does not have any schedule.',
    'rule-delete-confirmation': 'Are you sure you would like to delete the rule?',
    'rule-edit-statusEnable': 'Are you sure, you would like to enable the rule?',
    'rule-edit-statusDisable': 'Are you sure, you would like to disable the rule?',
    'rule-formValidation-ruleName': 'Rule name is required',
    'rule-formValidation-startDate': 'Start date is required',
    'rule-formValidation-expiryDate': 'Expiry date is required',
    'rule-formValidation-minLength': 'Rule name should have minimum 2 characters.',
    'rule-formValidation-maxLength': 'Rule name should not exceed more than 20 characters.',
    'rule-formValidation-alphaNumericStart': 'Rule name should only contain alphanumeric characters and dash(-).',
    "deleteAllActions-send-confirm": `Are you sure you would like to delete all ${item} actions?`,
    "deleteAction-send-confirm": `Are you sure you would like to delete ${item}?`,
    "rule-action-gainRange": "Gain should be between 0 to 100.",
    "rule-action-luxRange": "Lux should be between 0 to 12000.",
    "rule-action-luxRequired": "Lux is required.",
    "rule-action-gainRequired": "Gain is required."


})

export const ruleAPIMessages = {
    "ruleById-get-200": "Rule successfully retrieved.",
    "ruleById-get-201": "Rule successfully retrieved.",
    "ruleById-get-400": "Unable to retieve the Rule, please reverify the input and try again.",
    "ruleById-get-401": "Unable to retieve the Rule, please verify your access and try again.",
    "ruleById-get-404": "Unable to retieve the Rule, please try again later.",
    "ruleById-get-503": "Unable to retieve the Rule due to internal error, please try again later.",

    "rule-get-200": "Rule(s) successfully retrieved.",
    "rule-get-201": "Rule(s) successfully retrieved.",
    "rule-get-400": "Unable to retieve the Rule(s), please reverify the input and try again.",
    "rule-get-401": "Unable to retieve the Rule(s), please verify your access and try again.",
    "rule-get-404": "Unable to retieve the Rule(s), please try again later.",

    "rule-post-200": "Rule successfully created.",
    "rule-post-201": "Rule successfully created.",
    "rule-post-400": "Rule addition is failed, please reverify the input and try again.",
    "rule-post-401": "Rule addition is failed, please verify your access and try again.",

    "rule-put-200": "Rule successfully updated.",
    "rule-put-201": "Rule successfully updated.",
    "rule-put-400": "Rule edit operation is failed, please reverify the input and try again.",
    "rule-put-401": "Rule edit operation is failed, please verify your access and try again.",
    "rule-put-404": "Rule edit operation is failed, please try again later.",

    "rule-delete-200": "Rule successfully deleted.",
    "rule-delete-201": "Rule successfully deleted.",
    "rule-delete-401": "Rule delete operation is failed, please verify your access and try again.",
    "rule-delete-404": "Rule delete operation is failed, please try again later.",
}