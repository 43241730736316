import { API_URL } from '../config/appConfig'
import { API } from '.'
import { handleResponse } from '../helpers';
const accessToken = localStorage.getItem('miraie')?.accessToken;


const createTicket = async (payload) => {
    return await API.post(`${API_URL?.support}/tickets`, "createTicket", payload).then((res) => {
        return handleResponse(res, true);
    });;
}

const getTickets = async (payload) => {
    return await API.get(`${API_URL?.support}/tickets/search`, "getAllTickets", payload).then((res) => {
        return handleResponse(res, true);
    });;
}

const getTicketDetails = (ticketId) => {
    return API.get(`${API_URL?.support}/tickets/${ticketId}`, "getTicketDetails", ticketId).then((res) => {
        return handleResponse(res, true);
    });;
}

const updateTicket = (ticketId, payload) => {
    return API.put(`${API_URL?.support}/tickets/${ticketId}`, "updateTicket", payload).then((res) => {
        return handleResponse(res, true);
    });;
}

const getAssignees = () => {
    return API.get(`${API_URL?.support}/tickets/assignees`, "getAssignees", { authorization: accessToken }).then((res) => {
        return handleResponse(res, true);
    });;
}

const postComment = (ticketId, payload) => {
    return API.post(`${API_URL?.support}/tickets/${ticketId}/comment`, "postComment", payload).then((res) => {
        return handleResponse(res, true);
    });;
}

const subscribeTicket = (ticketId, payload) => {
    return API.post(`${API_URL?.support}/tickets/${ticketId}/subscribe`, "subscribeTicket", payload).then((res) => {
        return handleResponse(res, true);
    });;
}

const unsubscribeTicket = (ticketId, payload) => {
    return API.patch(`${API_URL?.support}/tickets/${ticketId}/subscribe`, "unSubscribeTicket", payload, {}).then((res) => {
        return handleResponse(res, true);
    });;
}

const addAttachments = (ticketId, payload) => {
    let formData = new FormData();
    formData.append("name", payload.name)
    formData.append("file", payload);
    return API.put(`${API_URL?.support}/tickets/${ticketId}/attachments`, `addAttachments`, formData, { headers: { 'Content-Type': 'application/json' } }, "upload").then((res) => {
        return handleResponse(res, true);
    });;

}

const deleteAttachment = (ticketId, attachmentId) => {
    return API.delete(`${API_URL?.support}/tickets/${ticketId}/attachments/${attachmentId}`, "deleteAttachment").then((res) => {
        return handleResponse(res, true);
    });;
}

const deleteComment = (ticketId, commentId) => {
    return API.delete(`${API_URL?.support}/tickets/${ticketId}/comment/${commentId}`, "deleteComment").then((res) => {
        return handleResponse(res, true);
    });;
}

const editComment = (ticketId, commentId, payload) => {
    return API.put(`${API_URL?.support}/tickets/${ticketId}/comment/${commentId}`, "editComment", payload).then((res) => {
        return handleResponse(res, true);
    });;
}

const getStatistics = async () => {
    return await API.get(`${API_URL?.support}/tickets/statistics`, "getStatistics", {}).then((res) => {
        return handleResponse(res, true);
    });;
}

const addAttachmentsToComment = (ticketId, commentId, payload) => {
    let formData = new FormData();
    formData.append("name", payload.name)
    formData.append("file", payload);
    return API.put(`${API_URL?.support}/tickets/${ticketId}/comments/${commentId}/attachments`, `addAttachmentsToComment`, formData, { headers: { 'Content-Type': 'application/json' } }, "upload").then((res) => {
        return handleResponse(res, true);
    });;

}

const deleteCommentAttachment = (ticketId, commentId, attachmentId) => {
    return API.delete(`${API_URL?.support}/tickets/${ticketId}/comments/${commentId}/attachments/${attachmentId}`, "deleteCommentAttachment").then((res) => {
        return handleResponse(res, true);
    });;
}

const getAttachment = (ticketId, attachmentId) => {
    return API.get(`${API_URL?.support}/tickets/${ticketId}/attachments/${attachmentId}`, "getAttachment", {}, { headers: { accept: "text/plain;charset=UTF-8" } }).then((res) => {
        return handleResponse(res, true);
    });;
}

const getCommentAttachment = (ticketId, commentId, attachmentId) => {
    return API.get(`${API_URL?.support}/tickets/${ticketId}/comments/${commentId}/attachments/${attachmentId}`, "getCommentAttachment", {}).then((res) => {
        return handleResponse(res, true);
    });;
}

const getTicketTypes = () => {
    return API.get(`${API_URL?.support}/tickets/entityTypes`, "getEntityTypes", {}).then((res) => {
        return handleResponse(res, true);
    });;
}

const getTicketStates = () => {
    return API.get(`${API_URL?.support}/tickets/states`, "getTicketStates", {}).then((res) => {
        return handleResponse(res, true);
    });;
}

const getTicketSeverities = () => {
    return API.get(`${API_URL?.support}/tickets/severities`, "getTicketSeverities", {}).then((res) => {
        return handleResponse(res, true);
    });;
}


export const ticketingService = {
    getTickets,
    createTicket,
    getAssignees,
    getTicketDetails,
    postComment,
    subscribeTicket,
    unsubscribeTicket,
    updateTicket,
    addAttachments,
    deleteAttachment,
    deleteComment,
    editComment,
    getStatistics,
    getAttachment,
    getCommentAttachment,
    addAttachmentsToComment,
    deleteCommentAttachment,
    getTicketTypes,
    getTicketStates,
    getTicketSeverities,
};