import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    GatewayStatistics: {
        "& .cardContent": {
            borderTop: "4px solid rgba(15, 141, 72, 0.05)",
        },
        "& .cardHeader": {
            padding: "20px",
            marginBottom: "10px"
        },
        "& .MuiPaper-root": {
            padding: "0px",
            borderRadius: "4px",
            border: "1px solid #E5E5E5",
            background: "#FFF"
        },
        "& .StyledDashboardCounter":{
            height:"100%"
        }

    }
});
