/***
 *
 * Controller class for user.
 * @file TmsLayout.js
 * @description TmsLayout component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import { Box, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './TmsLayout.style.js';
import TopBar from '../../components/TopBar/index.js';
import SideBar from '../../components/SideBar/index.js';
import Footer from '../../components/Footer/index.js';
import { getWindowDimensions } from '../../services/util.service.js';
import { useProps } from '../../hooks/useProps.js';
import { history } from '../../helpers';
import { defaultTenantName } from '../../config/appConfig.js';
import { useDispatch } from 'react-redux';
import { deviceActions } from '../../redux/actions/device.actions.js';

const TmsLayout = ({ children, expanded, setExpanded, mobileView, drawerWidth, ...props }) => {
  const classes = useStyles();
  const addProps = useProps;
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(deviceActions.getDeviceModels(defaultTenantName));
    // will open connection for each debug session
    // appConnections?.mqtt && setMqttClient(openMqttConnection(dispatch));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  return (
    <Box data-testid="TmsLayout" sx={{ maxWidth: "100%" }}>
      <TopBar />
      <Box sx={{ paddingLeft: "22px", paddingRight: "22px", backgroundColor: "#E5E5E5", position: "fixed", zIndex: "1099", width: "100%", maxWidth: "100vw", minHeight: "28px" }} />
      <SideBar expanded={expanded} onExpand={setExpanded} routes={props?.routes}>
        <Box className="" sx={{ mb: 1, minWidth: "245px", maxWidth: expanded ? `calc(100vw - ${drawerWidth + 24}px)` : `calc(100vw - ${(getWindowDimensions()?.width > 1024 ? 80 : (getWindowDimensions()?.width > 600 ? 94 : 76))}px)`, transition: ".28s" }}>
          <Paper elevation={0} square sx={{ minHeight: "calc(100vh - 155px)", width: "100%", padding: "24px 24px 40px", transition: ".28s", overflow: "hidden", ...(history?.location?.pathname === "/" || history?.location?.pathname === "/tenants" ? {} : { pt: { md: 3, xs: 2 }, px: { md: 3, sm: 2, xs: 1.5 }, pb: 5 }) }} style={{ ...(props?.style || {}) }}>
            {/* ----------------------- Protected Routes ----------------------- */}

            {/* <pre>{JSON.stringify(props?.routes, null, 2)}</pre> */}
            {addProps(children, { ...props })}

            {/* ---------------------------------------------------------------- */}
          </Paper>
        </Box>
        <Footer />
      </SideBar>
    </Box>
  )
};

TmsLayout.propTypes = {};

TmsLayout.defaultProps = {};

export default TmsLayout;
