import { API_URL } from '../config/appConfig'
import { API } from '../services'
import { handleResponse } from '../helpers';

const getAllScenesBySpace = (spaceId) => {
    return API.get(`${API_URL.device}/scenes/space/${spaceId}`, "getAllScenesBySpace", {}).then(handleResponse);
}
const getSceneDetails = (sceneId) => {
    return API.get(`${API_URL.device}/scenes/${sceneId}`, "getScene", {}).then(handleResponse);
}
const syncById = (id) => {
    return API.put(`${API_URL.device}/scenes/${id}/scenesync`, `sceneSyncById/${id}/scenesync`, {}).then((res) => {
        return handleResponse(res, {});
    });
}
const createScene = (payload) => {
    return API.post(`${API_URL.device}/scenes`, "createScene", payload)
        .then((res) => {
            return handleResponse(res, {});
        });
}
const editScene = (sceneId, payload) => {
    return API.put(`${API_URL.device}/scenes/${sceneId}`, "editScene", payload).then((res) => {
        return handleResponse(res, {});
    });
}
const getScenesByDeviceId = (deviceType, deviceId) => {
    return API.get(`${API_URL.device}/scenes/${deviceType}/${deviceId}?managedBy=${deviceType}`, `getScenesUnderDevice/${deviceId}`, {}).then(handleResponse);
}
const deleteScene = (sceneId) => {
    return API.delete(`${API_URL.device}/scenes/${sceneId}`, "deleteScene", {}).then((res) => {
        return handleResponse(res, {});
    });
}
const applyScene = (sceneId) => {
    return API.post(`${API_URL.device}/scenes/${sceneId}/activate`, "applyScene", {}).then((res) => {
        return handleResponse(res, {});
    });
}
const syncByDeviceId = (deviceId) => {
    return API.put(`${API_URL.device}/scenes/device/${deviceId}/scenesync`, `syncByDeviceId/${deviceId}/scenesync`, {}).then((res) => {
        return handleResponse(res, true);
    });
}
const deleteByDeviceId = (deviceId,deviceType) => {
    return API.delete(`${API_URL.device}/scenes/${deviceType}/${deviceId}`, `deleteByDeviceId/${deviceId}`, {}).then((res) => {
        return handleResponse(res, true);
    });
}

const getMultipleScenes = (payload) => {
    return API.get(`${API_URL.device}/scenes`, "getMultipleScenes", payload, {}, { headers: { 'Content-Type': 'application/json' } }).then(handleResponse);
  }

export const sceneService = {
    createScene,
    getAllScenesBySpace,
    getSceneDetails,
    getScenesByDeviceId,
    deleteScene,
    editScene,
    syncByDeviceId,
    deleteByDeviceId,
    syncById,
    applyScene,
    getMultipleScenes
};