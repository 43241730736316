import { dashboardType } from '../types/dashboard.type';

let initialState = {
    loading: false,
    powerConsumption: {},

}



export function dashboard(state = initialState, action) {
    switch (action?.type) {

        case dashboardType.GET_POWER_CONSUMPTION_SUCCESS:
            return {
                ...state,
                loading: false,
                powerConsumption: action.payload
            }
        default:
            return state
    }
}