/***
 *
 * Controller class for user.
 * @file StyledPopup.js
 * @description StyledPopup component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './StyledPopup.style.js';
import './StyledPopup.scss';
import { Box } from '@mui/material';
import StyledSpinner from "../StyledSpinner";
import CustomTypography from '../CustomTypography/index.js';
import ImageWrapper from '../ImageWrapper/index.js';
import StyledPopupWrapper from '../StyledPopupWrapper/index.js';
import timeoutIcon from '../../assets/icons/timeout.svg';
import successIcon from '../../assets/icons/success.svg';
import StyledButton from '../StyledButton/index.js';

const StyledPopup = ({ component = () => { }, data, closeButton, state, open, customsx = { label: {}, btns: {}, dialog: {}, box: {}, compo:{} }, ...props }) => {
  const classes = useStyles()
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    setOpenPopup(open);
  }, [open]);

  return (
    <StyledPopupWrapper
      // onClose={setOpenPopup(false)}
      customsx={{ ...customsx }}
      data-testid="StyledPopup"
      open={openPopup}
      title={data?.title || null}
      closeButton={closeButton || null}
      {...props}
    >
      <Box className={classes?.body || ""} sx={{ textAlign: "center", maxWidth: "400px" }}>
        {state &&
          <Box sx={{ mt: 2.5, display: "flex", alignItems: "center", justifyContent: "center", ...customsx.compo  }} >
            {(() => {
              switch (state) {
                case "pending":
                  return <StyledSpinner />
                case "timeout":
                  return <ImageWrapper src={timeoutIcon} />
                case "success":
                  return <ImageWrapper src={successIcon} />
                default:
                  return component
              }
            })()}
          </Box>
        }

        {data?.content &&
          <Box sx={{ mt: 2 }}>
            {data?.label &&
              <CustomTypography component="div" content={data?.label} styles={{ ...customsx.label }} weight={400} size={14} color={'rgba(0, 0, 0, 0.8)'} lineHeight={14.63} />
            }
            <CustomTypography component="div" content={data?.content} weight={400} size={14} color={'rgba(0, 0, 0, 0.8)'} lineHeight={14.63} />
          </Box>
        }
        {data?.actions &&
          <Box sx={{ mt: 2, ...customsx.btns }}>
            {data?.actions?.map((act, ind) => {
              return <StyledButton disabled={act?.disabled ? act?.disabled : false} variant={act?.variant || (["cancel", "no"].includes(act?.label?.toLowerCase()) ? "outlined" : "fill")} key={ind} type={act?.type || "button"} onClick={(event) => act?.onClick(event, data)} sx={{ mx: .5 }} style={{ ...act?.styles }}>{act?.label}</StyledButton>
            })}
          </Box>
        }
      </Box >
    </StyledPopupWrapper>
  )
};

StyledPopup.propTypes = {};

StyledPopup.defaultProps = {};

export default StyledPopup;