/***
 *
 * Controller class for user.
 * @file CameraDetails.js
 * @description CameraDetails component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './CameraDetails.style.js';
import './CameraDetails.scss';
import { Box } from '@mui/material';
import PageHeader from '../PageHeader/index.js';
import CameraIcon from '../../assets/icons/camera.png'
import { history } from '../../helpers/history.helper.js';
import CustomAccordion from '../CustomAccordion/index.js';
import StreamVideo from '../StreamVideo/index.js';
import ItemView from '../ItemView/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { getDeviceDetailHeaders } from '../../helpers/device.helper.js';
import { deviceActions } from '../../redux/actions/device.actions.js';
import axios from 'axios';

const cameraDetails = {
  "deviceId": "OOgG",
  "gwDeviceId": "NfnN",
  "spaceId": "mbCg",
  "name": "Camera-01",
  "modelNumber": "DS-2CV2Q21FD-IW",
  "fwVersion3rdParty": "1.2",
  "serialNumber": "serial",
  "macAddress": "45:23:32:32:65:12",
  "ipAddress": "10.2.3.4",
  "modelId": "810120",
  "category": "CAMERA",
  "brand": "PSN",
  "connectionType": "wired",
  "mqttTopics": {
    "control": "psn/OOgG/control/AnalogLightDriver/kDH8/jhqv/tmQ7/mbCg",
    "status": "psn/OOgG/status/AnalogLightDriver/kDH8/jhqv/tmQ7/mbCg",
    "connectionStatus": "psn/OOgG/connectionstatus/AnalogLightDriver/kDH8/jhqv/tmQ7/mbCg"
  },
  "configuration": {
    "lightId": "L07"
  },
  "sync": true,
  "deviceStatus": {
    "status": {
      "intensity": 0,
      "ts": 1717152355585
    },
    "lastUpdatedOn": "2024-05-31T10:45:57.219Z"
  },
  "status": "active",
  "createdOn": 1714733738897,
  "createdBy": "",
  "lastUpdatedOn": 1714733738896,
  "lastUpdatedBy": ""
}

const CameraDetails = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const deviceModels = useSelector(state => state?.devices?.deviceModels);
  const categories = useSelector(state => state?.devices?.deviceCategories);
  const streamUrl = useSelector(state => state?.devices?.deviceDetails);

  const [cameraUrl, setCameraUrl] = useState(null);

  const deviceModel = deviceModels?.find(m => m?.modelId === cameraDetails?.modelId);
  const deviceCategory = categories?.find(c => c?.category === cameraDetails?.category);

  const http = new XMLHttpRequest();

  useEffect(() => {

    http.onreadystatechange = () => {
      if (http?.readyState === 4 && http?.status === 200) {
        setCameraUrl(JSON?.parse(http?.responseText)?.data);
      }
      else if (http?.readyState === 4 && http?.status !== 200) {
      }
    }
    http?.open("GET", `http://13.201.136.61/start?deviceId=23kiqlkwslkjhd3maksoufwbej&resolution=360p`, true);
    http?.send();
    // dispatch(deviceActions?.getCameraStreamUrl());

    return () => {
      console.log("stopp")
      http.onreadystatechange = () => {
        if (http?.readyState === 4 && http?.status === 200) {
        }
        else if (http?.readyState === 4 && http?.status !== 200) {
        }
      }
      http?.open("GET", `http://13.201.136.61/stop?deviceId=23kiqlkwslkjhd3maksoufwbej`, true);
      http?.send();
      // dispatch(deviceActions?.stopCameraStream())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setCameraUrl(streamUrl?.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streamUrl])

  return (
    <Box className={classes?.CameraDetails || ""} data-testid="CameraDetails">
      <PageHeader
        bckbtn={true}
        title={"Camera-01"}
        icon={CameraIcon}
        backfunc={() => {
          history.goBack();
        }}
      // breadcrumbs={{ device: true }}
      // page={deviceType}
      // data={deviceDetails}
      ></PageHeader>
      <CustomAccordion
        title={"Device Details"}
      >
        <ItemView
          data={cameraDetails}
          headers={getDeviceDetailHeaders(cameraDetails?.category, cameraDetails, dispatch, deviceModel?.model, deviceCategory?.label)}
        />
      </CustomAccordion>
      <CustomAccordion
        title="Live stream"
      >
        <StreamVideo
          // width="100%"
          // height="100%"
          src={cameraUrl}
        ></StreamVideo>
      </CustomAccordion>
    </Box>
  )
};

CameraDetails.propTypes = {};

CameraDetails.defaultProps = {};

export default CameraDetails;
