import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme)=>({
    CustomAccordion: {
        '&.MuiAccordion-root': {
            borderRadius: 0,
            boxShadow: 'none',
            border: 'none',
            marginTop:'16px',
            '& .MuiAccordion-root:last-of-type':{
                marginTop:'16px'
            },
            '& .MuiAccordionSummary-root': {
                background: theme?.bg?.accordionTtitle || 'rgba(15, 141, 72, 0.05)',
                padding: theme?.padding?.accordionTtitle || '0 20px',
                fontWeight: theme?.fontWeight?.accordionTtitle || 700,
                fontSize: theme?.fontSize?.accordionTtitle || '0.875rem',
                lineHeight: theme?.lineHeight?.accordionTtitle || '15px',
                color: '#000000',
                '&.Mui-expanded': {
                    margin: 0,
                    minHeight: theme?.minHeight?.accordionTtitle || "48px"
                },
                '& .MuiAccordionSummary-content': {
                    margin: '12px 0',
                    '&.Mui-expanded': {
                        margin: "12px 0"
                    }
                }
            },
            '&.Mui-expanded':{
             borderTop:'1px solid #d6d6d6'
            }
        }
    },
    ContentBox: {
        // backgroundColor: theme?.bg?.accordionContent || "rgba(15, 141, 72, 0.02)"
    }
}));
