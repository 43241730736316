/***
 *
 * Controller class for user.
 * @file ForgotCred.js
 * @description ForgotCred component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import './ForgotCred.scss';
import { Box, Grid, InputAdornment } from '@mui/material';
import React, { useEffect } from 'react';
import ImageWrapper from '../../components/ImageWrapper';
// import PropTypes from 'prop-types';
import frame from '../../assets/img/login-bg.png';
import Miraie from '../../assets/logo/logo-miraie.png';
import CustomTypography from '../../components/CustomTypography';
import StyledTextfield from '../../components/StyledTextfield';
import { useState } from 'react';
import { ReactComponent as VisibilityOff } from '../../assets/icons/visibility-off.svg';
import { ReactComponent as VisibilityOn } from '../../assets/icons/visibility-on.svg';
import { useDispatch, useSelector } from 'react-redux';
import { appActions, userActions } from '../../redux/actions';
import _ from 'lodash';
import StyledSpinner from '../../components/StyledSpinner';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ErrorMessage from '../../components/ErrorMessage';
import { useHistory } from 'react-router-dom';
import { failure, getMobileNumber } from '../../helpers';
import { appTypes, authenticationTypes } from '../../redux/types';
import TenantLogo from '../../assets/logo/panasonic.png';
import StyledCountdown from '../../components/StyledCountdown';
import StyledButton from '../../components/StyledButton';

const ForgotCred = () => {
  const [value, setValue] = useState('');
  const [btnLabel, setBtnLabel] = useState('Generate OTP');
  const [step, setStep] = useState('enterEmail');
  const [pValueVisibility, setPValueVisibility] = useState(false);
  const [confirmPValueVisibility, setConfirmPValueVisibility] = useState(false);
  const [OTP, setOTP] = useState('');
  const [pValue, setPValue] = useState('');
  const [confirmPValue, setConfirmPValue] = useState('');
  const [passError, setPassError] = useState('');
  const [confPassError, setConfPassError] = useState('');
  const [otpTimer, setOtpTimer] = useState(null);
  const [correctOTP, setCorrectOTP] = useState(false);
  const [token, setToken] = useState('');
  const [timeOut, setTimeOutContent] = useState(false);
  const [selectOptionOtp, setSelectOptionOtp] = useState('');

  const requestedOTP = useSelector(state => state?.app?.otp);
  const validateOTP = useSelector(state => state?.app?.validateOtp);
  const resetPValue = useSelector(state => state?.authentication?.resetPassword);
  const authentication_error = useSelector(state => state?.authentication?.error)
  const loggingIn = useSelector(state => state.authentication.loading);
  const _webConfig = useSelector(state => state?.app?.webConfig)
  const _portal = useSelector(state => state?.app?.portal)

  const dispatch = useDispatch();
  const history = useHistory();

  const handleChange = (e) => {
    const name = e.target.name;

    if (name === 'emailOTP') {
      setValue(e.target.value);
    }
    if (name === 'otp') {
      setOTP(e.target.value);
    }
    if (name === 'pValue') {
      setPValue(e.target.value);
      setPassError('');
    }
    if (name === 'confirmPValue') {
      setConfirmPValue(e.target.value);
      setConfPassError('');
    }
  }

  const backtoLogin = () => {
    history.push('/login');
  }


  const getOTP = (cb = () => { }) => {
    dispatch(userActions.generateOTP('auth', { [selectOptionOtp]: value, transactionType: 'ResetPassword' }, cb));
  }


  const handleSubmit = (e, step) => {
    if (step === 'enterOTPoption') {
      if (selectOptionOtp) {
        getOTP(() => {
          setStep('generateOTP');
          setBtnLabel('Reset Password');
        });
      }

    }
    else if (step === 'confirmOTP') {
      dispatch(userActions.validateOTP('auth', { [selectOptionOtp]: value, transactionType: 'ResetPassword', otp: OTP }));
    }
    else if (step === 'confirmPassword') {
      if (_.isEmpty(pValue)) {
        setPassError('Password cannot be empty')
      }
      // if(_.isEmpty(confPassError)){
      //   setConfPassError('Confirm Password cannot be empty')
      // }
      if (pValue !== confirmPValue) {
        setConfPassError('Password does not match');
      }
      else {
        dispatch(userActions.resetCred({ password: pValue, token: token }));
      }
    }
  }
  useEffect(() => {
    dispatch({
      type: appTypes.CLEAR_VALIDATE_OTP,
      payload: {},
    })
    dispatch({
      type: appTypes.CLEAR_OTP,
      payload: {},
    })
    return () => {
      dispatch({
        type: appTypes.CLEAR_OTP,
        payload: {},
      })
    }
  }, [])

  useEffect(() => {
    if (value) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        setSelectOptionOtp('email');
      }
      else if ((/^(\+\d{1,3}[- ]?)?\d{10}$/).test(value)) {
        setSelectOptionOtp('mobile');
      }
      else {
        setSelectOptionOtp('username');
      }
    }
    else {
      setSelectOptionOtp('');
    }
  }, [value])

  useEffect(() => {
    if (selectOptionOtp === 'mobile') {
      const partition = getMobileNumber(value);
      if (!partition[0])
        setValue('+91' + value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOptionOtp])

  useEffect(() => {
    if (requestedOTP) {
      setOtpTimer(requestedOTP?.validityInSecs);
      setTimeOutContent(false);
    }
  }, [requestedOTP]);

  useEffect(() => {
    // console.log('validate otp===>',validateOTP);
    if (validateOTP) {
      setCorrectOTP(true);
      setOtpTimer(null);
      setToken(validateOTP?.token);
      setTimeOutContent(false);
    }
    else {
      setCorrectOTP(false);
    }
  }, [validateOTP])

  useEffect(() => {
    // console.log('validate password===>',resetPassword);
    if (resetPValue) {
      window.location.href = '/login';
      setPassError('');
      setConfPassError('');
      setPValue('');
      setConfirmPValue('');
      setOTP('');
      dispatch(userActions.emptyAuthState('RESET_PASSWORD_SUCCESS', null))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPValue])

  useEffect(() => {
    // console.log('auth error==>',authentication_error);
    if (authentication_error) {
      if (authentication_error?.generateOTP) {
        history.push('/login');
        dispatch(appActions.apiErrorMessage(authentication_error.generateOTP));
        dispatch(failure(authenticationTypes.RESET_PASSWORD_FAILURE, null));
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authentication_error])

  return (
    <Grid className="ForgotCred" data-testid="ForgotCred" container>
      <Grid item sm={6} style={{ display: "flex", justifyContent: "center", alignItems: "center", position: 'relative', backgroundImage: `url(${frame})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat", width: "100%", padding: "40px" }}>
        {/* <ImageWrapper src={frame} style={{ height: '100vh', width: '100%' }} alt='mirai-bg' /> */}
        <ImageWrapper component="Image" src={Miraie} sx={{ maxWidth: { md: "300px", sm: "250px", xs: "200px" }, width: "-webkit-fill-available" }} alt='mirai-logo' />
      </Grid>
      <Grid container flexDirection="row" item sm={6} style={{ background: '#ffffff', minHeight: "430px", position: 'relative' }} justifyContent="center" alignItems={'center'}>
        <Grid container item xs={12} sx={{ minHeight: "calc(100% - 70px)" }}>
          {
            (() => {
              switch (step) {
                case 'enterEmail':
                  return (
                    <Box className='boxContainer' >
                      <Box className='auth-heading'>
                        <Box sx={{ mb: 3, width: "100%", textAlign: 'left' }}><ImageWrapper src={_portal === "mss" ? _webConfig?.webApp?.logoBitmap : TenantLogo} width={160} /></Box>
                        <CustomTypography content="Recover your account" color="#1A202C" size={30} weight={800} lineHeight={36.57} />
                      </Box>
                      <Box className='custom-form-group'>
                        <Grid item xs={12}>
                          <label className="custom-form-control-label">{`Enter your User ID`}</label>
                        </Grid>
                        <Grid item xs={12}>
                          <StyledTextfield value={value} name="emailOTP" sx={{ width: 'auto', minWidth: { md: '350px', sm: '290px', xs: '280px' }, pb: '10px' }} onChange={handleChange} />
                        </Grid>

                      </Box>
                      <StyledButton disabled={_.isEmpty(value)} sx={{ width: '47%', mr: 1, mt: 1, p: 1, height: "45px" }} color={'primary'} onClick={(e) => { handleSubmit(e, 'enterOTPoption') }}  >
                        {loggingIn &&
                          <StyledSpinner size={22} sx={{ '& .sk-chase-dot::before': { backgroundColor: '#fff' }, marginRight: "16px" }} />
                        }
                        {btnLabel}
                      </StyledButton>
                      <StyledButton variant='outlined' sx={{ width: '47%', ml: 1, mt: 1, p: 1, height: "45px" }} color={'primary'} onClick={(e) => { backtoLogin() }}>Back To Login</StyledButton>
                    </Box>
                  )
                case 'generateOTP':
                  return (
                    <Box className='boxContainer' >
                      <Box className='auth-heading'>
                        <Box sx={{ mb: 3, width: "100%", textAlign: 'left' }}><ImageWrapper src={_portal === "mss" ? _webConfig?.webApp?.logoBitmap : TenantLogo} width={160} /></Box>
                        <CustomTypography content={`An OTP has been sent `} color="#2D3748" size={16} weight={400} lineHeight={19.5} />
                        <CustomTypography content={`Reset your password`} color="#1A202C" size={30} weight={800} lineHeight={36.57} />
                      </Box>
                      <Box className=''>
                        <Box className='custom-form-group'>
                          <Box display={'flex'} justifyContent='space-between' alignItems={'center'}>
                            <label className='custom-form-control-label'>OTP</label>
                            {
                              !correctOTP &&
                              <>
                                {otpTimer !== null && <StyledCountdown format='mm:ss' minutes={(otpTimer / 60)} seconds={otpTimer % 60} message={'Resend in'} completed={() => { setOtpTimer(null); setTimeOutContent(true) }} />}
                                {timeOut && <label onClick={() => { getOTP() }} className="custom-form-control-label pointer" style={{ color: "#0F8D48" }}>{'Resend OTP'}</label>}
                              </>
                            }
                          </Box>
                          <Box display={'flex'} flexDirection='column' position={'relative'}>
                            <StyledTextfield value={OTP} disabled={correctOTP} name="otp" sx={{ width: 'auto', minWidth: { md: '350px', sm: '290px', xs: '280px' } }} onChange={handleChange} placeholder="Enter..." />
                            {/* <StyledSpinner position={'absolute'} right="-50px" top="5px" /> */}
                            {correctOTP && <CheckOutlinedIcon sx={{ color: 'green', position: 'absolute', right: "-30px", top: "5px" }} />}
                            {!_.isEmpty(OTP) && !correctOTP && <StyledButton sx={{ mt: '5px' }} onClick={(e) => { handleSubmit(e, 'confirmOTP') }} size='small' >Confirm OTP</StyledButton>}
                          </Box>
                        </Box>
                        <Box className='custom-form-group'>
                          <label className='custom-form-control-label'>Password</label>
                          <StyledTextfield
                            id="passwordfield"
                            fullWidth
                            hiddenLabel
                            disabled={!correctOTP}
                            value={pValue}
                            onChange={handleChange}
                            type={!pValueVisibility ? "password" : 'text'}
                            name="pValue"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <StyledButton
                                    iconButton
                                    sx={{
                                      background: '#FFFFFF',
                                      border: '1px solid rgba(199, 199, 199, 0.5)',
                                      borderRadius: '4px',
                                      marginRight: '-9px',
                                      padding: '5px'
                                    }}
                                    edge="end"
                                    aria-label="Toggle password visibility"
                                    onClick={() => setPValueVisibility(!pValueVisibility)}
                                  >
                                    {!pValueVisibility ? <VisibilityOff /> : <VisibilityOn />}
                                  </StyledButton>
                                </InputAdornment>
                              ),
                            }} />
                          {!_.isEmpty(passError) && <ErrorMessage message={passError} />}
                        </Box>
                        <Box className='custom-form-group'>
                          <label className='custom-form-control-label'>Confirm Password</label>
                          <StyledTextfield
                            id="passwordfield"
                            fullWidth
                            hiddenLabel
                            disabled={!correctOTP}
                            value={confirmPValue}
                            onChange={handleChange}
                            type={!confirmPValueVisibility ? "password" : 'text'}
                            name="confirmPValue"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <StyledButton
                                    iconButton
                                    sx={{
                                      background: '#FFFFFF',
                                      border: '1px solid rgba(199, 199, 199, 0.5)',
                                      borderRadius: '4px',
                                      marginRight: '-9px',
                                      padding: '5px'
                                    }}
                                    edge="end"
                                    aria-label="Toggle password visibility"
                                    onClick={() => setConfirmPValueVisibility(!confirmPValueVisibility)}
                                  >
                                    {!confirmPValueVisibility ? <VisibilityOff /> : <VisibilityOn />}
                                  </StyledButton>
                                </InputAdornment>
                              ),
                            }} />
                          {!_.isEmpty(confPassError) && <ErrorMessage message={confPassError} />}
                        </Box>
                        <Box display="flex" gap={2} sx={{ mt: 4 }}>
                          <StyledButton sx={{ width: '100%', minWidth: "100px", height: "45px" }} color={'primary'} onClick={(e) => { handleSubmit(e, 'confirmPassword') }}  >
                            {/* {loggingIn && 
                              <StyledSpinner size={22} sx={{ '& .sk-chase-dot::before': {backgroundColor:'#fff'}, marginRight: "16px"}} />
                            } */}
                            {btnLabel}
                          </StyledButton>
                          <StyledButton variant='outlined' sx={{ width: '100%', minWidth: "100px", height: "45px" }} color={'primary'} onClick={(e) => { backtoLogin() }}>Back To Login</StyledButton>
                        </Box>
                      </Box>

                    </Box>
                  )
                default:
                  return (
                    <></>
                  )
              }
            })()
          }
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "60px" }}>
          <small>© 2024 - Panasonic Life Solutions India Pvt. Ltd. All rights reserved</small>
        </Grid>
      </Grid>
    </Grid>
  )
};

ForgotCred.propTypes = {};

ForgotCred.defaultProps = {};

export default ForgotCred;
