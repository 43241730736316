/***
 *
 * Controller class for user.
 * @file StyledTextEditor.js
 * @description StyledTextEditor component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useStyles } from './StyledTextEditor.style.js';
import './StyledTextEditor.scss';
import { Box } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ErrorMessage from '../ErrorMessage/index.js';

const StyledTextEditor = ({ id = "rte", name = "rte", value = "", placeholder = 'Write something...', error, message, helperText, width, style, disabled, onChange = () => { } }) => {
  const classes = useStyles()
  const [, setValue] = useState(value);
  var toolbarOptions = [
    [{ 'size': ['small', false, 'large', 'huge'] }, 'bold', 'italic', 'underline', 'strike', { 'list': 'ordered' }, { 'list': 'bullet' }, 'link'],

  ]

  const handleChange = (e) => {
    console.log(e);
    setValue(e);
    onChange({ target: { name: name, value: e } });
  }

  useEffect(() => {
    setValue(value);
  }, [value])

  return (
    <Box className={classes?.StyledTextEditor || ""} data-testid="StyledTextEditor">
      {disabled ? (
        <div className='input-disabled' dangerouslySetInnerHTML={{ __html: value }}></div>
      ) : (
        <>
          <ReactQuill
            id={id}
            name={name}
            style={{ ...style, color: disabled ? "gray" : "", width: width }}
            theme="snow"
            value={value}
            className={error ? 'errorQuill' : ''}
            placeholder={placeholder}
            onChange={handleChange}
            readOnly={disabled}
            modules={{ toolbar: toolbarOptions }}
          />
          {error && <ErrorMessage message={message} />}
        </>
      )}
    </Box>
  )
};

StyledTextEditor.propTypes = {};

StyledTextEditor.defaultProps = {};

export default StyledTextEditor;
