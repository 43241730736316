/***
 *
 * Controller class for user.
 * @file StyledGraph.js
 * @description StyledGraph component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 * 
 */

import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import './StyledGraph.scss';
import { Box } from '@mui/material';
import _ from 'lodash';
import { useStyles } from './StyledGraph.style.js';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Bar, Line, Pie } from 'react-chartjs-2';



const fnTooltip = (tooltipItems) => {
  let interval = tooltipItems && tooltipItems?.dataset && tooltipItems?.dataset?.interval
  let label = tooltipItems && tooltipItems?.label

  if (interval === "hourly") {
    return "Hour - " + label
  }
  else if (interval === "daily") {
    return "Day: " + label
  }
  else if (interval === "weekly") {
    return "Week: " + label
  }
  else if (interval === "monthly") {
    return "Month: " + label
  }
  else {
    return "0 kWh"
  }
}



const StyledGraph = ({ chartType, chartData, legend = { position: "top", title: { display: true, text: "" } }, sideTitle, dataRange = [
  { label: "Today", value: '20 Kw' },
  { label: "This week", value: '234 Kw' },
  { label: "This month", value: '13234 Kw' }
], ...props }) => {

  Chart.defaults.plugins.tooltip.enabled = true;

  const chartOptions = {
    layout: {},
    scales: {

      x: {
        grid: {
          display: false,
          tickColor: "black",
          offset: true
        },
        beginAtZero: true,
        // ticks: {
        //   callback: (index) => {
        //     return "zak";
        //   },
        // },
        // title: {
        //   display: true,
        //   text: "Days",
        //   font: {
        //     size: 16
        //   }
        // }
      },
      y: {
        grid: {
          display: false,
          tickColor: "black",
          offset: true
        },
        title: {
          display: true,
          text: sideTitle || "kWh",
          font: {
            size: 16
          }
        },
        beginAtZero: true,
        ticks: {
          callback: (value) => {
            return value;
          },
        }
      }
    },

    plugins: {
      labels: {
        render: () => { },
      },

      legend: {
        display: false,
        position: 'right',
        labels: {
          fontSize: 16,
          useBorderRadius: true,
          borderRadius: 10,
          usePointStyle: true,
          pointStyle: 'rect',
          pointStyleWidth: 40,
          padding: 15
        }
      },
      tooltip: {
        enabled: true,
        displayColors: false,
        callbacks: {
          title: function (tooltipItems) {
            return "Energy: " + (tooltipItems && tooltipItems[0] ? tooltipItems[0]?.formattedValue + " kWh" : "0 kWh")
          },

          label: function (tooltipItems) {
            return fnTooltip(tooltipItems)
          },
        },
      },
      ...props?.plugins
    }
  };

  const classes = useStyles()
  const [chartDataPoints, setChartDataPoints] = useState(chartData);
  const [dRange, setDRange] = useState(dataRange);

  useEffect(() => {
    if (dataRange) { setDRange(dataRange); }
    if (chartData) { setChartDataPoints(chartData); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData]);

  return (
    <Box className={classes?.CustomBarGraph || ""} data-testid="CustomBarGraph">
      {/* <Box>
        <List className={classes.AvailableList}>
          {dRange && dRange?.map((item, index) => {
            return <ListItem key={"sssddd---" + index}>{item?.label}: <span>{item?.value}</span></ListItem>
          })}
        </List>
      </Box> */}

      <Box>
        {chartDataPoints && !_.isEmpty(chartDataPoints) && (() => {
          switch (chartType) {
            case "line":
              return (
                <Line
                  data={chartDataPoints}
                  height={100}
                  options={chartOptions}
                />
              )
            case "pie":
              return (
                <Pie
                  data={chartDataPoints}
                  height={100}
                  plugins={[ChartDataLabels]}
                  options={{
                    aspectRatio: 1.8,
                    plugins: {
                      tooltip: {
                        callbacks: {
                          label: (context) => {
                            let total = 0;
                            context?.dataset?.data?.forEach(d => total = total + d);
                            return `${context?.label} : ${context?.parsed} (${((context?.parsed * 100) / total).toFixed(1)}%)`
                          }
                        }
                      },
                      legend: {
                        // display:false,
                        position: legend?.position,
                        title: {
                          display: legend?.title?.display,
                          text: legend?.title?.text,
                          color: "#4E4E4E",
                          font: {
                            family: "Montserrat",
                            weight: 500
                          }
                        },
                        labels: {
                          boxWidth: 12,
                          font: {
                            family: "Montserrat",
                            weight: 500
                          },
                          color: "#4E4E4E",
                          // generateLabels: (chart) => {
                          //   const labels = Chart?.controllers?.doughnut?.overrides?.plugins?.legend?.labels;
                          // }
                        },
                      },
                      datalabels: {
                        color: "#FFF",
                        font: {
                          // weight: "600",
                          family: "Montserrat",
                        },
                      }
                    }
                  }}
                />
              )
            default:
              return (<Bar
                data={chartDataPoints}
                height={100}
                options={chartOptions}
              />)
          }
        })()}

      </Box>
    </Box>
  )
};

StyledGraph.propTypes = {};

StyledGraph.defaultProps = {};

export default StyledGraph;
