/***
 *
 * Controller class for user.
 * @file Devices.js
 * @description Devices component
 * @author Naveen Kumar
 * @since 12 Oct 2021
 *
 */

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeviceList from "../../components/DeviceList";
import ControllerDetails from "../../components/ControllerDetails";
import { useQuery } from "../../hooks";
import { deviceActions } from "../../redux/actions";
// import PropTypes from 'prop-types';
import "./Devices.scss";
import CameraDetails from "../../components/CameraDetails";

const Devices = (props) => {
  const query = useQuery();
  const deviceId = query.get("id") || null;
  const page = query.get("pt") || null;
  const deviceCategory = query.get("category") || "VortexGateway";

  const dispatch = useDispatch();
  const gatewayData = useSelector((state) => state?.devices?.gatewayList);

  useEffect(() => {
    if (page === "gateway") {
    } else if (page === "controller") {
    } else if (page === "device") {
    } else {
      // dispatch(
      //   deviceActions?.getAllDevices({ category: deviceCategory }, "gateway")
      // );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page]);

  // useEffect(()=>{
  //   dispatch(deviceActions.deviceBreadCrumbs(deviceId, page, (deviceCategory.includes("gateway")?"gateway":(deviceCategory.includes("controller")?"controller":"device")), history));
  // },[deviceId])


  return (
    <div data-testid="Devices">
      {(() => {
        switch (page) {
          case "gateway":
          case "controller":
          case "aggregator":
          case "device":
            return (
              <ControllerDetails deviceId={deviceId} category={deviceCategory} mqttClient={props.mqttClient} />
            );
          default:
            return <DeviceList mqttClient={props.mqttClient} />;
        }
      })()}
    </div>
  );
};

Devices.propTypes = {};

Devices.defaultProps = {};

export default Devices;
