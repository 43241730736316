import { ButtonBase } from "@mui/material";
import { withStyles } from "@mui/styles";
import { makeStyles } from "@mui/styles";


export const useStyles = makeStyles(theme => ({
  ControllerDetails: {
    "& .identifyButton": {
      padding: "8px",
      "& .identifyIcon": {
        filter: "invert(37%) sepia(14%) saturate(5108%) hue-rotate(112deg) brightness(99%) contrast(88%)",
      },
    },
    "& .identifyButton:hover": {
      "& .identifyIcon": {
        filter: "brightness(0) saturate(100%) invert(99%) sepia(27%) saturate(5%) hue-rotate(191deg) brightness(101%) contrast(100%)",
      }
    },
    "& .deviceControlBox": {
      "background": "rgba(15, 141, 72, 0.02)",
      // "marginDown":"8px"
    },
    "& .deviceControlHeader": {
      "background": "rgba(15, 141, 72, 0.05)"
    },
    "& .toolbar": {
      "justify-content": "flex-end",
      "display":"flex",
      marginTop:"8px"
    },

    "& .channelDivider": {
      [theme?.breakpoints?.down('sm')]: {
        display: 'none',
      }
    },
    "& .Table":{
        "& .IntensitySlider": {
          [theme?.breakpoints?.down('sm')]: {
            "& .ControllerSlider": {
              flexDirection: "row",
              alignItems: "center",
              "& .labels": {
                marginBottom: "0px"
              }
            }
          }
      }
    },
    "& .action-buttons": {
      "& .delete": {
        filter: "invert(12%) sepia(80%) saturate(5694%) hue-rotate(24deg) brightness(98%) contrast(102%) !important"
      }
    }
  },
  deviceform: {
    "max-width": "650px"
  },
  // popup:
  // {
  //   '& .MuiDialog-paper': {
  //     minHeight:"900px"
  // },
  // }



}));

export const StyledButton = withStyles(() => ({
  root: {
    marginLeft: "20px",
    width: "128px",
    padding: "10px",
    fontSize: '1.125rem',
    color: "#000",
    fontWeight: "400",
    background: "#0F8D48",
    borderRadius: "5px"
  },
}))(ButtonBase);  