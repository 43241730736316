import { API } from '.'
import { handleResponse } from '../helpers';
import { API_URL } from '../config/appConfig';


const accessToken = localStorage.getItem('miraie')?.accessToken;
const login = async (username, password, portal = "mss", clientId = null, key = null) => {
    // const clientId = JSON.parse(getLocalItem('appConfig'))?.clientId;
    const payload = portal === "mss" ? { username, password, clientId } : { username, password };
    //using getIdenityUrl to access updated value of tenant_url
    const url = portal === "mss" ? `${API_URL["getIdentityUrl"]()}/auth/login` : `${API_URL["tmsUser"]}/system/auth/login`;
    return API.post(url, key ? key : "login", payload)
        .then((res) => handleResponse(res, true))
        .then(({ data, status }) => {
            let user = JSON.parse(JSON.stringify(data));
            const miraieStr = JSON.parse(localStorage.getItem("miraie"));
            let auth = miraieStr ? miraieStr : { app: { theme: "light" } };
            auth["accessToken"] = (user?.accessToken ? user?.accessToken : (user?.accesstoken ? user?.accesstoken : null));
            auth["refreshToken"] = (user?.refreshToken ? user?.refreshToken : (user?.refreshtoken ? user?.refreshtoken : null));
            // auth["portal"] = portal;

            delete user?.accessToken;
            delete user?.refreshToken;
            auth["user"] = user;

            // localStorage.setItem('miraie', JSON.stringify(miraie));
            // console.log('looocal',localStorage.getItem("miraie"),res);
            return { auth, code: status };
        });
}

const generateOTP = (type, payload, clientId = null) => {
    // const clientId = JSON.parse(getLocalItem('appConfig'))?.clientId;
    let apiId = payload?.apiId || 1;
    delete payload?.apiId;
    let basePayload = type === 'auth' ? { ...payload, clientId: clientId } : payload;
    return API.post(`${API_URL.getIdentityUrl()}/${type}/generateOtp`, `generateOtp-${type}-${apiId}`, { ...basePayload }).then(res => handleResponse(res, true));;
}


const validateOTP = (type, payload) => {
    let apiId = payload?.apiId || 1;
    delete payload?.apiId;
    return API.post(`${API_URL.getIdentityUrl()}/${type}/validateOtp`, `validateOtp-${type}-${apiId}`, { ...payload }).then(res => handleResponse(res, true));;
}

const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/";
    // if(!window.location.href.toString().substring('/login')) { /// if already at /login, no need to redirect again
    //     window.location.href="/";
    // }
}

const getAll = (size, portal = "mss") => {
    const url = `${portal === 'tms' ? API_URL.tmsUser : API_URL.user}${portal === "tms" ? "/system" : ""}/user${portal === "tms" ? "" : `?size=${size}&index=0`}`
    return API.get(url, "getAllUsers", {}).then(res => handleResponse(res, true));;
    // API.get(`${API_URL.user}/user`, "fetchUsers", {}).then(res=>handleResponse(res,true));;
    // return API.get(`${API_URL.user}/system/user`, "fetchUsers", {}).then(res=>handleResponse(res,true));;
}

const getById = (id, portal = "mss") => {
    const url = `${portal === 'tms' ? API_URL.tmsUser : API_URL.user}${portal === "tms" ? "/system" : ""}/user/${id}`
    return API.get(url, `getById-${id}`, {}).then(res => handleResponse(res, true));;
}

const register = async (user, portal = "mss", clientId = null) => {
    // const clientId = JSON.parse(getLocalItem('appConfig'))?.clientId;
    const payload = portal === "tms" ? { ...user } : { ...user, clientId: clientId }
    const url = `${portal === 'tms' ? API_URL.tmsUser : API_URL.user}${portal === "tms" ? "/system" : ""}/users`
    return API.post(url, 'register-user', payload).then(res => handleResponse(res, true));;
}

// const addUser = async (user) => {
//     const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(user)
//     };

//     return API.post(`/users/register`, requestOptions).then(res=>handleResponse(res,true));;
// }

const addUser = async (user, portal = "mss", clientId = null) => {
    // const clientId = JSON.parse(getLocalItem('appConfig'))?.clientId;
    const payload = portal === 'tms' ? { ...user } : { ...user, clientId: clientId }
    const url = `${portal === 'tms' ? API_URL.tmsUser : API_URL.user}${portal === "tms" ? "/system" : ""}/user`
    return API.post(url, "addUser", payload).then(res => handleResponse(res, true));;
}

const changeCred = async (user, clientId = null) => {
    // const clientId = JSON.parse(getLocalItem('appConfig'))?.clientId;
    return API.post(`/users/register`, "changePassword", { ...user, clientId: clientId }).then(res => handleResponse(res, true));;
}

const update = (user, id, portal = "mss") => {
    const url = `${portal === 'tms' ? API_URL.tmsUser : API_URL.user}${portal === "tms" ? "/system" : ""}/user/${id}`
    return API.put(url, "updateUser", { ...user }).then(res => handleResponse(res, true));;;
}

const updateUserRoles = (id, roles, portal = "mss") => {
    const url = portal === "tms" ? `${API_URL.tmsUser}/system/user/role/${id}` : `${API_URL.user}/user/${id}/role`
    return API.put(url, "updateUserRoles", roles).then(res => handleResponse(res, true));;;
}

const updateUserScopes = (id, scopes) => {
    return API.put(`${API_URL.permission}/scope/${id}`, "updateUserScopes", scopes).then(res => handleResponse(res, true));
}

const getSelfProfile = (token = null, portal = "mss") => {
    const url = `${portal === 'tms' ? API_URL.tmsUser : API_URL.getIdentityUrl()}${portal === "tms" ? "/system" : ""}/user/profile`
    return API.get(url, "getUserProfile", null, null, { headers: { 'Authorization': 'Bearer ' + token } }).then(res => handleResponse(res, true));;
}

const updateSelfProfile = (payload, portal = 'mss') => {
    const url = `${portal === 'tms' ? API_URL.tmsUser : API_URL.user}${portal === "tms" ? "/system" : ""}/user/profile`
    return API.put(url, "updateSelfProfile", { ...payload }).then(res => handleResponse(res, true));
}

// prefixed function name with underscore because delete is a reserved word in javascript
const _delete = (id) => {
    // const requestOptions = {
    //     method: 'DELETE',
    //     headers: authHeader()
    // };

    return API.delete(`${API_URL.user}/user/${id}`, "deleteuser", {}).then(res => handleResponse(res, true));;
}

const getUserScope = (userId) => {
    return API.get(`${API_URL.permission}/scope/${userId}`, `getUserScope/${userId}`).then(res => handleResponse(res, true));;
}

const resetCred = (payload) => {
    return API.post(`${API_URL.getIdentityUrl()}/auth/resetPassword`, `changePassword`, { authorization: accessToken, ...payload }).then(res => handleResponse(res, true));;
}

const changeSelfCred = (payload, portal = 'mss') => {
    const url = portal === 'tms' ? `${API_URL.tmsUser}/system/user/changepassword` : `${API_URL.getIdentityUrl()}/user/password`
    return API.put(url, `changeSelfCred`, { authorization: accessToken, ...payload }).then(res => handleResponse(res, true));;
}

const changeCredByUserId = (userId, payload, portal = "mss") => {
    const url = portal === 'tms' ? `${API_URL.tmsUser}/system/user/changepassword/${userId}` : `${API_URL.user}/user/${userId}/password`
    return API.put(url, `changePassword`, { ...payload }).then(res => handleResponse(res, true));;
}

const changeUserStatus = (userId, payload) => {
    return API.put(`${API_URL.user}/user/${userId}/status`, `changePassword`, { ...payload }).then(res => handleResponse(res, true));;
}

const getUserActivityLogs = (payload) => { //Mock API used-when available change it to actual API
    return API.get(`http://127.0.0.1:3010/api/appsupport/v1/activities/user/${payload?.userId}${payload?.queryString !== undefined ? payload?.queryString : ''}`).then(res => handleResponse(res, true));;
}


const getWebConfig = async (url, key = "getWebConfig") => {
    return API.get(
        `${API_URL.tenant}/tenant/webconfig?url=${url}`,
        key,
        {}
    ).then((res) => {
        return handleResponse(res, {});
    });
}

const getClientId = (tenantName) => {
    return API.get(`${API_URL.tmsUser}/clientId`, 'getClientId', {}).then((res) => {
        return handleResponse(res, {});
    });
}

export const userService = {
    login,
    logout,
    register,
    addUser,
    changeCred,
    getAll,
    getById,
    update,
    updateUserRoles,
    updateUserScopes,
    delete: _delete,
    getUserScope,
    generateOTP,
    validateOTP,
    resetCred,
    changeSelfCred,
    getSelfProfile,
    updateSelfProfile,
    changeCredByUserId,
    changeUserStatus,
    getUserActivityLogs,
    // getOpenSystemToken,
    getWebConfig,
    getClientId
};