import Power from "../assets/icons/power.svg";
import Intensity from "../assets/icons/intensity.svg";
import { getMessageStr } from '../helpers/message.helper.js';
import Lighting from "../assets/icons/bulb.svg";
import Sensor from "../assets/icons/sensor.svg";
import Switch from "../assets/icons/switch.svg";
import Receiver from "../assets/icons/router.svg";
import Gateway from "../assets/icons/singleantenna.svg";
import Aggregator from "../assets/icons/doubleantenna.svg";
import Controller from "../assets/icons/controller.svg";

export const deviceIcons = {
  VortexGateway: Gateway,
  RelayLightController: Controller,
  Aggregator: Aggregator,
  DaliLightDriver: Lighting,
  Receiver: Receiver,
  Sensor: Sensor,
  ODMSENSORHUB:Sensor,
  Switch: Switch,
};
deviceIcons.RelayLightDriver = deviceIcons.AnalogLightDriver = deviceIcons.DaliLightDriver;
deviceIcons.DaliAnalogLightController = deviceIcons.DaliLightController = deviceIcons.AnalogLightController = deviceIcons.RelayLightController;

export const CONTROLLER_CATEGORY = [
  {
    id: "DaliLightController",
    label: "DaliLightController"
  },
  {
    id: "DaliAnalogLightController",
    label: "DaliAnalogLightController"
  },
  {
    id: "RelayLightController",
    label: 'RelayLightController'
  },
  {
    id: "AnalogLightController",
    label: "AnalogLightController"
  }
]

export const AGGREGATOR_CATEGORY = [
  {
    id: "Aggregator",
    label: 'Aggregator'
  }
]

export const DEVICE_PHYSICAL_CONSTANTS = {
  intensity: {
    value: 60,
    min: 0,
    max: 100,
    label: "Intensity",
    icon: Intensity
  },
  kelvin: {
    value: 4000,
    min: 2500,
    max: 6500,
    label: "Temperature"
  },
  minPreset: {
    value: 0,
    min: 0,
    max: 100,
  },
  maxPreset: {
    value: 100,
    min: 0,
    max: 100,
  },
  preset: {
    value: [2, 10],
    min: 0,
    max: 100
  },
  minMaxRange: {
    value: [5000, 7500],
    min: 5000,
    max: 7500,
  },
  fadeTime: {
    list: [{ id: 'NO_FADE', label: '0 sec' }, { id: 'FADE_0p7SEC', label: '0.7 sec' }, { id: 'FADE_1p0SEC', label: '1.0 sec' }, { id: 'FADE_1p4SEC', label: '1.4 sec' }, { id: 'FADE_2p0SEC', label: '2.0 sec' }, { id: 'FADE_2p8SEC', label: '2.8 sec' }, { id: 'FADE_4p0SEC', label: '4.0 sec' }, { id: 'FADE_5p6SEC', label: '5.6 sec' }, { id: 'FADE_8p0SEC', label: '8.0 sec' }, { id: 'FADE_11p3SEC', label: '11.3 sec' }, { id: 'FADE_16p0SEC', label: '16.0 sec' }, { id: 'FADE_22p6SEC', label: '22.6 sec' }, { id: 'FADE_32p0SEC', label: '32.0 sec' }, { id: 'FADE_45p2SEC', label: '45.2 sec' }, { id: 'FADE_64p0SEC', label: '64.0 sec' }, { id: 'FADE_90p5SEC', label: '90.5 sec' }],
    value: { id: 'NO_FADE', label: '0s' }
  },
  fadeRate: {
    list: [{ id: 'FADE_357', label: '357' }, { id: 'FADE_253', label: '253' }, { id: 'FADE_179', label: '179' }, { id: 'FADE_126', label: '126' }, { id: 'FADE_89', label: '89' }, { id: 'FADE_63', label: '63' }, { id: 'FADE_44', label: '44' }, { id: 'FADE_31', label: '31' }, { id: 'FADE_22', label: '22' }, { id: 'FADE_15', label: '15' }, { id: 'FADE_11', label: '11' }, { id: 'FADE_7', label: '7' }, { id: 'FADE_5', label: '5' }, { id: 'FADE_4', label: '4' }, { id: 'FADE_3', label: '3' }],
    value: { id: 'FADE_357', label: '357' }
  },
  systemFailLevel: {
    value: 10,
    min: 0,
    max: 100

  },
  powerFailLevel: {
    value: 10,
    min: 0,
    max: 100
  },
  wattage: {
    value: 22,
    icon: Power
  },
  daliDriver: {
    value: { id: "DT2", label: "DT-2", key: 1 },
    list: [{ id: "DT2", label: "DT-2", key: 1 }, { id: "DT6", label: "DT-6", key: 2 }, { id: "DT8", label: "DT-8", key: 3 }]
  }

}

export const SWITCH_ACTION_TYPES = [{
  id: "singlePress",
  label: "Single Press"
},
{
  id: "doublePress",
  label: "Double Press"
},
{
  id: "longPress",
  label: "Long Press"
}];

export const SWITCH_FUNCTION_TYPES = [
  {
    id: "MASTER_ON_OFF",
    label: "Master ON/OFF"
  },
  {
    id: "LIGHT_DIM_UP",
    label: "Light Dim Up"
  },
  {
    id: "LIGHT_DIM_DOWN",
    label: "Light Dim Down"
  },
  {
    id: "SCENE_NXT",
    label: "Next Scene"
  },
  {
    id: "SCENE_PREV",
    label: "Previous Scene"
  },
  {
    id: "SET_SCENE",
    label: "Set Scene"
  },
  {
    id: "LIGHT_ON_OFF",
    label: "Lights ON/OFF"
  }
]

export const DEVICE_LOGS_FILTER_FORM_CONFIG = (validationMessages = {}) => {
  return ([
    {
      style: { marginBottom: '0px !important' },
      section: { heading: null },
      columns: [
        {
          id: "1",
          type: "datetimepicker",
          picker: "datePicker",
          label: "Start Date",
          name: "startDate",
          columnSize: 4,
          validation: {
            req: [true, validationMessages?.startDate],
          },
          required: true,
          disableFuture: true
        },
        {
          id: "2",
          type: "DateTimePicker",
          label: "End Date",
          disabled: true,
          name: "endDate",
          columnSize: 4,
          required: false,
          disableFuture: true
        },
        {
          id: "4",
          type: "select",
          label: "User",
          name: "userId",
          placeholder: "Select User",
          columnSize: 4,
          required: false,
        },
        {
          id: "3",
          type: "chipInput",
          label: "Type",
          name: "type",
          columnSize: 12,
          visibleIn: ["create"],
          options: []
        },

      ],
    }
  ])
};

export const ACTIVITY_LOGS_FILTER_FORM_OPTIONS = (users) => {
  return {
    dropdowns: {
      type: {
        list: [{ id: 'Connection status', label: 'Connection status' },
        { id: 'Firmware upgrade', label: 'Firmware upgrade' },
        { id: 'Commission operations', label: 'Commission operations' },
        { id: 'Control operations', label: 'Control operations' },
        { id: 'Status operations', label: 'Status operations' },
        { id: 'Reset operations', label: 'Reset operations' },
        { id: 'Groups', label: 'Groups' },
        { id: 'Scenes', label: 'Scenes' },
        { id: 'Areas', label: 'Areas' },
        { id: 'Rules', label: 'Rules' },
        { id: 'BLE key upgrade', label: 'BLE key upgrade' },
        { id: 'System log', label: 'System log' },
        { id: 'Move', label: 'Move' },
        ]

        // list: [
        //    "Connection status",
        //    "Firmware upgrade",
        //    "Commission operations",
        //    "Reset operations",
        //    "Groups",
        //    "Scenes",
        //    "Areas",
        //    "Rules",
        //    "Status",
        //    "Control Operations",
        //    "BLE key upgrade",
        //    "System log",
        //    "Move"
        //   ]
      },
      userId: {
        list: users?.map(u => {
          return { id: u?.userId, label: u?.name }
        })
      }
    }
  }
}

export const ACTIVITY_LOG_TYPES = {
  "Connection status": "connectionStatus",
  "Firmware upgrade": "firmwareUpgrade",
  "Commission operations": "commission",
  "Reset operations": "reset",
  "Groups": "group",
  "Scenes": "scene",
  "Areas": "area",
  "Rules": "rule",
  "Status operations": "status",
  "Control operations": "control",
  "BLE key upgrade": "bleKeyUpgrade",
  "System log": "log",
  "Move": "space",
}

